import * as React from 'react';

interface OwnProps {
	acs: Nullable<string>;
	samlResponse: Nullable<string>;
}

type Props = OwnProps;

const LMSForm: React.FC<Props> = (props) => {
	const { acs, samlResponse } = props;
	const formRef = React.useRef<Nullable<HTMLFormElement>>(null);

	const login = React.useCallback(async () => {
		formRef.current?.submit();
	}, []);

	React.useEffect(() => {
		if (!!acs && !!samlResponse) {
			login();
		}
	}, [acs, login, samlResponse]);

	return (
		<>
			{!!acs && !!samlResponse && (
				<form action={`${acs}`} autoComplete="off" id="sso" method="post" ref={formRef} style={{ display: 'none' }}>
					<input id="resp" name="SAMLResponse" type="hidden" value={`${samlResponse}`} />
				</form>
			)}
		</>
	);
};

export default LMSForm;
