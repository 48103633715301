import * as React from 'react';
import { compose } from 'redux';
import type { ConnectedProps } from 'react-redux';
import { connect } from 'react-redux';
import type { InjectedFormProps } from 'redux-form';
import { reduxForm } from 'redux-form';
import type { CustomRouteComponentProps } from 'react-router-dom';

import type AgencyCreateRM from 'ab-requestModels/agency/create.requestModel';

import type AgencyVM from 'ab-viewModels/agency/agency.viewModel';

import * as AgencyActions from 'af-actions/agency';

import AgencyForm from 'af-components/SharedForms/Resources/Agency';
import { validate } from 'af-components/SharedForms/Resources/Agency/validation';
import Breadcrumbs from 'af-components/Breadcrumbs';

import { AGENCY_CREATE } from 'af-constants/reduxForms';
import CLIENT from 'af-constants/routes/client';

import type { RootState } from 'af-reducers';

type FormProps = InjectedFormProps<AgencyCreateRM>;

type OwnProps = CustomRouteComponentProps;

type FormOwnProps = OwnProps & ConnectedProps<typeof connector>;

type Props = FormOwnProps & FormProps;

const CreateAgency: React.FC<Props> = (props) => {

	const { create, history, location: { state: { orgAlias } }, companyName, findList } = props;

	const [
		agencyList,
		setAgencyList,
	] = React.useState<Array<AgencyVM>>([]);

	React.useEffect(() => {
		getAgencyList();
		// eslint-disable-next-line react-hooks/exhaustive-deps
	}, []);

	const getAgencyList = async () => {
		const fetchedAgencyList = await findList();
		setAgencyList(fetchedAgencyList);
	};

	const createAgency = async (form: AgencyCreateRM) => {
		await create(form);
		history.replace(CLIENT.COMPANY.RESOURCES.AGENCY.LIST(orgAlias, companyName));
	};

	// eslint-disable-next-line @typescript-eslint/member-ordering
	const handleSubmit = props.handleSubmit(createAgency);

	return (
		<div className="form-segment">
			<Breadcrumbs
				items={[
					{ label: 'Temp Agencies', url: CLIENT.COMPANY.RESOURCES.AGENCY.LIST(orgAlias, companyName) },
					{ label: 'New Temp Agency' },
				]}
			/>
			<AgencyForm
				{...props}
				agencyId={null}
				agencyList={agencyList}
				onSubmit={handleSubmit}
			/>
		</div>
	);
};

function mapStateToProps(state: RootState) {
	const { companyData } = state.user;
	if (!companyData) {
		throw new Error('User not logged in');
	}

	return {
		companyName: companyData.name,
	};
}

function mapDispatchToProps() {
	return {
		create: AgencyActions.create,
		findList: AgencyActions.findList,
	};
}

const connector = connect(mapStateToProps, mapDispatchToProps());

const enhance = compose<React.ComponentClass<OwnProps>>(
	connector,
	reduxForm<AgencyCreateRM, FormOwnProps>({
		form: AGENCY_CREATE,
		validate,
	}),
	React.memo
);

export default enhance(CreateAgency);
