import * as React from 'react';

import type * as ResourceStatusesViewModel from 'ab-viewModels/resources/resourceStatuses.viewModel';

import LockedValue from 'af-components/LockedValue';

import { bemElement } from 'ab-utils/bem.util';

import Label from './Label';

interface OwnProps {
	iconStatus: string;
	items: ResourceStatusesViewModel.List;
	label: string;
	isLoading?: boolean;
}

type Props = OwnProps;

const LIST_CONTAINER_CLASS_NAME = bemElement('company-settings', 'feature-flag', ['indented']);
const LIST_CONTAINER_EMPTY_CLASS_NAME = `${bemElement('company-settings', 'empty')} ${LIST_CONTAINER_CLASS_NAME}`;

class ResourceStatusList extends React.PureComponent<Props> {

	static _getNames = (_status: ResourceStatusesViewModel.Item) => _status.name;

	render() {
		const { isLoading, items, label, iconStatus } = this.props;

		const value = isLoading ?
			<div className={LIST_CONTAINER_CLASS_NAME}><div className=" loading-col-4 loading-box-row standalone light" /></div> :
			(
				items?.length > 0
					? <div className={LIST_CONTAINER_CLASS_NAME}>{items.map(ResourceStatusList._getNames).join(', ')}</div>
					: <div className={LIST_CONTAINER_EMPTY_CLASS_NAME}>There are no statuses available.</div>
			);

		return (
			<LockedValue
				label={<Label iconStatus={iconStatus} label={label} />}
				value={value}
			/>
		);
	}
}

export default ResourceStatusList;
