import type { Dispatch, AnyAction } from 'redux';

import API from 'af-constants/routes/api';

import { http } from 'af-utils/http.util';
import { errorHandler } from 'af-utils/actions.util';

import type UnionTypeVM from 'ab-viewModels/unionType/unionType.viewModel';

import type { GetRootState } from 'af-reducers';

export function findAll() {
	return async (dispatch: Dispatch<AnyAction>, getState: GetRootState, { redirectTo }) => {
		const action = async () => {
			return await http.get<UnionTypeVM[]>(API.V1.UNION_TYPE.LIST);
		};
		return await errorHandler(action, dispatch, redirectTo);
	};
}
