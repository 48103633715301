import * as React from 'react';
import { Droppable } from 'react-beautiful-dnd';

import EmployeeList from 'af-root/scenes/Company/ScheduleBoard/DailyView/EmployeesDroppable/EmployeeList';

interface Props {
	droppableId: string;
	draggablePrefix: string; // to distinguish if it's board or toolbar dragged employee
	employeeIds: number[];
	isToolbar: boolean;
	isDisabled: boolean;
	className: string;
	startIndex: number;
	endIndex: number;
	title?: string;
	dueDate: string;
	isWorkOrderCanceled?: boolean;
	isCopyPlaceholder?: boolean;
	available?: boolean;
	isCardDisabled?: boolean;
	hasReason?: boolean;
	isDragAndDropDisabled: boolean;
	hasPermissionsToEditScheduleBoard: boolean;
}

const DroppableComponent: React.FC<Props> = (props) => {
	const {
		available,
		draggablePrefix,
		droppableId,
		dueDate,
		hasPermissionsToEditScheduleBoard,
		hasReason,
		isCardDisabled,
		isCopyPlaceholder,
		isDragAndDropDisabled,
		isToolbar,
		isWorkOrderCanceled,
		startIndex,
		title,
		className,
		employeeIds: _employeeIds,
		endIndex,
		isDisabled,
	} = props;

	const renderDroppableBody = React.useCallback((employeeIds: number[]) => {

		return (
			<>
				{title && <label className="droppable-title">{title}</label>}
				<EmployeeList
					available={available}
					draggablePrefix={draggablePrefix}
					droppableId={droppableId}
					dueDate={dueDate}
					employeeIds={employeeIds}
					hasPermissionsToEditScheduleBoard={hasPermissionsToEditScheduleBoard}
					hasReason={hasReason}
					isCardDisabled={!!isCardDisabled}
					isCopyPlaceholder={isCopyPlaceholder}
					isDragAndDropDisabled={isDragAndDropDisabled}
					isToolbar={isToolbar}
					isWorkOrderCanceled={isWorkOrderCanceled}
					startIndex={startIndex}
				/>
			</>
		);
	}, [
		available,
		draggablePrefix,
		droppableId,
		dueDate,
		hasPermissionsToEditScheduleBoard,
		hasReason,
		isCardDisabled,
		isCopyPlaceholder,
		isDragAndDropDisabled,
		isToolbar,
		isWorkOrderCanceled,
		startIndex,
		title,
	]);

	const employeeIds = _employeeIds.slice(startIndex, endIndex);

	// react-beautiful-dnd can not create Draggable and Droppable elements/
	// while we are dragging so in order to mimic the copied card, we render
	// div instead of a Draggable (same for Droppable in parent component)
	if (isCopyPlaceholder || isDragAndDropDisabled || !hasPermissionsToEditScheduleBoard) {
		return (
			<div className={className}>
				{renderDroppableBody(employeeIds)}
			</div>
		);
	}

	return (
		<Droppable
			droppableId={droppableId}
			isDropDisabled={isDisabled}
		>
			{(provided, snapshot) => {
				return (
					<div
						className={`${className} ${snapshot.isDraggingOver ? 'drag-over' : ''}`}
						ref={provided.innerRef}
					>
						{renderDroppableBody(employeeIds)}
						{provided.placeholder}
					</div>
				);
			}}
		</Droppable>
	);
};

export default React.memo(DroppableComponent);
