import * as React from 'react';
import Select from 'react-select';
import type { FormatOptionLabelMeta } from 'react-select/src/Select';
import type { ThemeConfig } from 'react-select/src/theme';
import type { OptionTypeBase, ValueType } from 'react-select/src/types';
import type { Option } from 'react-select/src/filters';
import type { StylesConfig } from 'react-select/src/styles';
import { nanoid } from 'nanoid';

import { UNIQUE_ID_SIZE } from 'ab-common/constants/value';

interface Props<OptionType extends OptionTypeBase> {
	placeholder?: string;
	className?: string;
	options: OptionType[];
	isSearchable?: boolean;
	isClearable?: boolean;
	defaultValue?: ValueType<OptionType, boolean>;
	value?: ValueType<OptionType, boolean>;
	clearOnSelect?: boolean;
	controlledValue?: boolean;
	menuPlacement?: 'top' | 'bottom';
	limited?: boolean;
	fixed?: boolean;
	isDisabled?: boolean;
	getOptionValue?: (option: OptionType) => string; // generaly option.id
	getOptionLabel?: (option: OptionType) => string; // generaly option.label
	onValueChange?: (selectedOption: ValueType<OptionType, boolean>) => Promise<void> | void;
	filterOption?: (option: Option, rawInput: string) => boolean;
	formatOptionLabel?: (option: OptionType, labelMeta: FormatOptionLabelMeta<OptionType, boolean>) => React.ReactNode;
	onBlur?: (selectedOption: ValueType<OptionType, boolean>) => Promise<void> | void;
}

const getTheme: ThemeConfig = (theme) => ({
	...theme,
	borderRadius: 2,
	colors: {
		...theme.colors,
		primary25: '#FEF4E9',
		primary50: '#FEF4E9',
		primary: '#FEF4E9',
	},
});

const customStyles: StylesConfig<OptionTypeBase, boolean> = {
	control: (base) => ({
		...base,
		boxShadow: '0 !important',
		'&:hover': {
			borderColor: '#FFA726',
		},
	}),
	option: (provided) => ({
		...provided,
		color: '#35383C',
	}),
};

interface DropdownStyle {
	top: number | 'auto';
	bottom: number | 'auto';
	left: number;
	width: number;
}

interface State<OptionType extends OptionTypeBase> {
	id: string;
	value: ValueType<OptionType, boolean>;
	dropdownStyle: DropdownStyle | undefined;
}

// eslint-disable-next-line @typescript-eslint/no-explicit-any
const getDefaultValue = (props: Props<any>) => {
	const { controlledValue, defaultValue, value } = props;
	if (controlledValue) {
		return value || null;
	} else {
		return defaultValue || null;
	}

};

export default class SelectField<OptionType extends OptionTypeBase> extends React.PureComponent<Props<OptionType>, State<OptionType>> {
	// eslint-disable-next-line @typescript-eslint/no-explicit-any
	static defaultProps: Partial<Props<any>> = { // cannot set class type parameter to static variable
		options: [],
		isSearchable: true,
		isClearable: true,
		clearOnSelect: false,
		menuPlacement: 'bottom',
		limited: false,
		isDisabled: false,
		controlledValue: false,
	};

	state: State<OptionType> = {
		value: getDefaultValue(this.props),
		id: nanoid(UNIQUE_ID_SIZE),
		dropdownStyle: undefined,
	};

	components = {
		IndicatorSeparator: null,
		DropdownIndicator: () => <span className="icon-down" />,
		ClearIndicator: (props) => {
			const {
				getStyles,
				innerProps: { ref, ...restInnerProps },
			} = props;
			return (
				<div
					{...restInnerProps}
					ref={ref}
					style={getStyles('clearIndicator', props)}
				>
					<span className="icon-close" />
				</div>
			);
		},
	};

	// eslint-disable-next-line @typescript-eslint/no-explicit-any
	static getDerivedStateFromProps(props: Props<any>, state: State<any>) {
		const { controlledValue, value } = props;
		return controlledValue && value !== state.value ? { value: props.value } : null;
	}

	onChange = (item: ValueType<OptionType, false>) => {
		const { onValueChange, clearOnSelect } = this.props;

		this.setState(() => ({ value: clearOnSelect ? null : item }));

		if (onValueChange) {
			onValueChange(item);
		}
	};

	onOpen = () => {
		const { fixed } = this.props;

		if (!fixed) {
			return;
		}

		const { id } = this.state;
		const {
			x: inputPositionX = 0,
			bottom: inputBottom = 0,
			width: inputWidth = 0,
		} = document.getElementById(id)?.getClientRects()?.[0] ?? {};

		this.setState(() => ({
			dropdownStyle: {
				top: inputBottom,
				bottom: 'auto',
				left: inputPositionX,
				width: inputWidth,
			},
		}));
	};

	onBlur = () => {
		const { onBlur } = this.props;
		if (onBlur) {
			onBlur(this.state.value);
		}
	};

	render() {
		const {
			placeholder,
			options,
			className,
			filterOption,
			isSearchable,
			isClearable,
			getOptionValue,
			getOptionLabel,
			formatOptionLabel,
			menuPlacement,
			limited,
			isDisabled,
			fixed,
		} = this.props;
		const { value, id, dropdownStyle } = this.state;

		let finalClassName = 'react-select-field';
		finalClassName = className ? `${finalClassName} ${className}` : finalClassName;
		finalClassName = limited ? `${finalClassName} react-select-field--limited` : finalClassName;
		finalClassName = fixed ? `${finalClassName} react-select-field--fixed` : finalClassName;

		const styles = fixed ? { ...customStyles, menu: (base) => ({ ...base, ...dropdownStyle }) } : customStyles;

		return (
			<Select
				className={finalClassName}
				classNamePrefix={className}
				components={this.components}
				filterOption={filterOption}
				formatOptionLabel={formatOptionLabel}
				getOptionLabel={getOptionLabel}
				getOptionValue={getOptionValue}
				id={id}
				isClearable={isClearable}
				isDisabled={isDisabled}
				isSearchable={isSearchable}
				menuPlacement={menuPlacement}
				onBlur={this.onBlur}
				onChange={this.onChange}
				onMenuOpen={this.onOpen}
				options={options}
				placeholder={placeholder}
				styles={styles}
				theme={getTheme}
				value={value}
			/>
		);
	}
}
