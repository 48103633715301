import type { Dispatch, AnyAction } from 'redux';
import { SubmissionError } from 'redux-form';

import API from 'af-constants/routes/api';

import { http } from 'af-utils/http.util';
import type { ErrorOverride } from 'af-utils/actions.util';
import { errorHandler } from 'af-utils/actions.util';

import { TableQuery } from 'ab-common/dataStructures/tableQuery';

import type { PurchaseOrderVM } from 'ab-viewModels/purchaseOrder/purchaseOrder.viewModel';

import type { CreateOrUpdatePurchaseOrderRM } from 'ab-requestModels/purchaseOrder/createOrUpdatePurchaseOrder.requestModel';

import type { GetRootState } from 'af-reducers';

import * as TableCsvAPI from 'ab-api/web/purchaseOrder/findPurchaseOrderTableCSV';
import * as TableAPI from 'ab-api/web/purchaseOrder/findPurchaseOrderTable';
import * as DeleteAPI from 'ab-api/web/purchaseOrder/deletePurchaseOrder';
import * as FindSingleAPI from 'ab-api/web/purchaseOrder/findPurchaseOrder';
import * as CreateAPI from 'ab-api/web/purchaseOrder/createPurchaseOrder';

export function findPurchaseOrder(purchaseOrderId: number) {
	return async (dispatch: Dispatch<AnyAction>, getState: GetRootState, { redirectTo }) => {
		const action = async () => {
			return await http.get<FindSingleAPI.W_PurchaseOrder_FindPurchaseOrder_VM>(
				FindSingleAPI.URL(purchaseOrderId)
			);
		};
		return await errorHandler(action, dispatch, redirectTo);
	};
}

export function deletePurchaseOrder(purchaseOrderId: number) {
	return async (dispatch: Dispatch<AnyAction>, getState: GetRootState, { redirectTo }) => {
		const action = async () => {
			return await http.delete<void>(
				DeleteAPI.URL(purchaseOrderId)
			);
		};
		return await errorHandler(action, dispatch, redirectTo);
	};
}

export function createPurchaseOrder(form: CreateAPI.W_PurchaseOrder_CreatePurchaseOrder_RM) {
	return async (dispatch: Dispatch<AnyAction>, getState: GetRootState, { redirectTo }) => {
		const action = async () => {
			return await http.post<CreateAPI.W_PurchaseOrder_CreatePurchaseOrder_VM>(
				CreateAPI.URL(),
				form
			);
		};
		const error: ErrorOverride = {
			err409: () => {
				throw new SubmissionError({ orderNumber: 'Order Number already taken. Try to Submit again' });
			},
		};
		return await errorHandler(action, dispatch, redirectTo, error);
	};
}

export function updatePurchaseOrder(form: CreateOrUpdatePurchaseOrderRM) {
	return async (dispatch: Dispatch<AnyAction>, getState: GetRootState, { redirectTo }) => {
		const action = async () => {
			return await http.put<PurchaseOrderVM>(
				API.V1.PURCHASE_ORDER.CREATE_OR_UPDATE(),
				form
			);
		};
		return await errorHandler(action, dispatch, redirectTo);
	};
}

/**
 * @param startDate `YYYY-MM-DD`
 * @param endDate `YYYY-MM-DD`
 */
export function findCompanyPurchaseOrderTableCSV(tableRM: TableQuery, startDate: string, endDate: string) {
	return async (dispatch: Dispatch<AnyAction>, getState: GetRootState, { redirectTo }) => {
		const action = async () => {
			const data = new TableQuery(tableRM);
			const result = await http.get<TableCsvAPI.W_PurchaseOrder_FindPurchaseOrderTableCsv_VM>(
				TableCsvAPI.URL(startDate, endDate, data)
			);

			return result.rows;
		};
		return await errorHandler(action, dispatch, redirectTo);
	};
}

/**
 * @param startDate `YYYY-MM-DD`
 * @param endDate `YYYY-MM-DD`
 */
export function findCompanyPurchaseOrderTable(tableRM: TableQuery, startDate: string, endDate: string) {
	return async (dispatch: Dispatch<AnyAction>, getState: GetRootState, { redirectTo }) => {
		const action = async () => {
			const data = new TableQuery(tableRM);
			return await http.get<TableAPI.W_PurchaseOrder_FindPurchaseOrderTable_VM>(
				TableAPI.URL(startDate, endDate, data)
			);
		};
		return await errorHandler(action, dispatch, redirectTo);
	};
}

