import * as React from 'react';
import { Row, Col } from 'react-bootstrap';

import type ContactVM from 'ab-viewModels/contact/contact';

import Dropdown from 'af-components/Controls/Dropdown';

interface OwnProps {
	contacts: ContactVM[];
	onSelect: (contact: ContactVM) => Promise<void>;
	loadContacts: () => Promise<void>;
}

type Props = OwnProps;

class ContactPicker extends React.PureComponent<Props> {

	// case when company name or title is empty string
	static getStringValue = (value: Nullable<string>) => {
		return value?.length ? value : 'N/A';
	};

	static renderSelectedContact = (contact: ContactVM) => <span>{contact?.fullName}</span>;

	static renderContactOption = (contact: ContactVM) => (
		<div className="dropdown-option">
			<span>{contact.fullName}</span>
			<span className="dropdown-option__details">
				{ContactPicker.getStringValue(contact.companyName)} | {ContactPicker.getStringValue(contact.title)}
			</span>
		</div>
	);

	static filterContactOption = (contact: ContactVM, searchText: string) => {
		const fullName = contact.fullName.toLocaleLowerCase();
		return fullName.includes(searchText.toLocaleLowerCase());
	};

	async componentDidMount() {
		await this.props.loadContacts();
	}

	render() {
		const { contacts, onSelect } = this.props;

		return (
			<Row className="row--padded">
				<Col sm={24}>
					<Dropdown<ContactVM>
						filterable={true}
						filterBy={ContactPicker.filterContactOption}
						id="contact-dropdown"
						label="Add to Contact"
						menuItemClassName="dropdown-menu-item"
						onValueChange={onSelect}
						options={contacts}
						renderMenuItem={ContactPicker.renderContactOption}
						renderSelected={ContactPicker.renderSelectedContact}
						withCaret={true}
					/>
				</Col>
			</Row>
		);
	}
}

export default ContactPicker;
