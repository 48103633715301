import * as React from 'react';
import type { DropResult } from 'react-beautiful-dnd';
import { DragDropContext } from 'react-beautiful-dnd';

import LoadingIndicator from 'af-components/LoadingIndicator';

import { useNotificationSnackbar } from 'af-root/hooks/useNotificationSnackbar';

import TreeTableHeader from './TreeTableHeader';
// TODO: uncomment after search functionality is implemented
// import TreeActionsHeader from './TreeActionsHeader';
import TreeTableBody from './TreeTableBody';
import styles from './styles.module.scss';

import type { AttachmentEntity, DirectoryEntity } from './types';

interface DirectoryAttachmentTreeType {
	dataTree: Nullable<DirectoryEntity[]>;
	columns: TypedColumn<DirectoryEntity>[];
	rowActions: RowActions;
	fetchData?: () => void;
	onFileUpload?: (directoryId: number, files: File[]) => void;
	onCopyToWOChange?: (attachmentId: number, copyToWorkOrder: boolean) => void;
	handleAttachmentParentChange: (attachmentId: number, currentParentId: number, futureParentId: number) => void;
	id: number;
	isReadOnly: boolean;
}

interface RowActionOptions {
	actionName: string;
	actionFunction: (original: DirectoryEntity | AttachmentEntity) => void;
	disabled?: boolean;
	show?: boolean;
}

interface RowActions {
	parentDirectory: RowActionOptions[];
	childDirectory: RowActionOptions[];
	attachmentLevel: RowActionOptions[];
}

interface TypedColumn<T> {
	header: string;
	accessor?: string;
	id?: string;
	className?: string;
	width?: number;
	/** We are requiring Cell to be defined so that proper refereces can be pulled in VMs */
	Cell?: (data: Omit<{ original: T; }, 'value'>) => React.ReactNode;
}

// For expandig and collapsing rows
const getAllDirectoriesId = (arrOfIds: number[], dataTree: Nullable<DirectoryEntity[]>) => {

	if (dataTree) {
		dataTree.map((_directory) => {
			if (_directory.directories) {
				getAllDirectoriesId(arrOfIds, _directory.directories);
			}

			arrOfIds.push(_directory.id);
		});
	}

	return arrOfIds;
};

const getDirectoryIds = (dataTree: Nullable<DirectoryEntity[]>) => {
	const ids = [];

	return getAllDirectoriesId(ids, dataTree);
};

const DirectoriesAttachmentTree = (props: DirectoryAttachmentTreeType) => {
	const {
		dataTree,
		columns,
		rowActions,
		fetchData,
		id,
		onFileUpload,
		onCopyToWOChange,
		handleAttachmentParentChange,
		isReadOnly,
	} = props;
	const [arrOfDirectoryIds, setArrOfDirectoryIds] = React.useState<number[]>([]);
	const notificationSnackbar = useNotificationSnackbar();

	const [visibleRows, setVisibleRows] = React.useState<number[]>(arrOfDirectoryIds);

	const toggleRowVisibility = React.useCallback((rowIds: number[]) => {
		const checkLength = rowIds.length;
		switch (checkLength) {
			case 0:
				return setVisibleRows([]);
			case 1:
				return setVisibleRows((prevVisibleRows) => {
					return prevVisibleRows?.includes(rowIds[0])
						? prevVisibleRows.filter((rowId) => rowId !== rowIds[0])
						: prevVisibleRows ? [...prevVisibleRows, rowIds[0]] : [];
				});
			default:
				return setVisibleRows(arrOfDirectoryIds);
		}
	}, [arrOfDirectoryIds]);

	React.useEffect(() => {
		const dirIds = getDirectoryIds(dataTree);
		setArrOfDirectoryIds(dirIds);
		setVisibleRows(dirIds);
	}, [dataTree]);

	const trimDroppableId = (droppableId: string) => droppableId.split('#').pop();

	const onDragEnd = React.useCallback(({ draggableId, source, destination }: DropResult) => {
		if (!source.droppableId || !destination?.droppableId) {
			notificationSnackbar.error('Destination unknown', new Date());
			return;
		}
		const sourceDirId = trimDroppableId(source.droppableId) ?? null;
		const destinationDirId = trimDroppableId(destination.droppableId) ?? null;

		if (sourceDirId && destinationDirId) {
			handleAttachmentParentChange(+draggableId, +sourceDirId, +destinationDirId);
		} else {
			notificationSnackbar.error('Unable to move attachment', new Date());
		}
	}, [handleAttachmentParentChange, notificationSnackbar]);

	return (
		<div className={`${styles.tree}`}>
			{/* TODO: uncomment after search functionality is implemented */}
			{/* <TreeActionsHeader /> */}
			<TreeTableHeader
				arrOfDirectoryIds={arrOfDirectoryIds}
				columns={columns}
				isReadOnly={isReadOnly}
				rowActions={rowActions}
				toggleRowVisibility={toggleRowVisibility}
				visibleRows={visibleRows}
			/>
			{
				dataTree && dataTree.length > 0 ?
					<DragDropContext onDragEnd={onDragEnd}>
						<TreeTableBody
							columns={columns}
							dataTree={dataTree}
							fetchData={fetchData}
							id={id}
							isReadOnly={isReadOnly}
							onCopyToWOChange={onCopyToWOChange}
							onFileUpload={onFileUpload}
							rowActions={rowActions}
							toggleRowVisibility={toggleRowVisibility}
							visibleRows={visibleRows}
						/>
					</DragDropContext>
					:
					<div className={`${styles['tree__loading-indicator']}`}>
						<LoadingIndicator color="orange" />
					</div>
			}
		</div>
	);
};

export default React.memo(DirectoriesAttachmentTree);
