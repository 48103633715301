import type { Dispatch, AnyAction } from 'redux';
import { SubmissionError } from 'redux-form';

import type { TableContent } from 'ab-common/dataStructures/tableContent';

import type { TableVM } from 'ab-viewModels/account/table.viewModel';
import type { ActiveVM } from 'ab-viewModels/account/active.viewModel';
import type { PreviewVM } from 'ab-viewModels/account/preview.viewModel';
import type { AccountViewModel } from 'ab-viewModels/account.viewModel';
import type MemberVM from 'ab-viewModels/account/member.viewModel';
import type { CSVBulkAccountVM } from 'ab-viewModels/account/upload.viewModel';
import type * as User from 'ab-viewModels/user.viewModel';
import type AccountOptionVM from 'ab-viewModels/account/option.viewModel';

import { TableQuery } from 'ab-common/dataStructures/tableQuery';

import type { CSVBulkAccountRM } from 'ab-requestModels/account/upload.requestModel';

import API from 'af-constants/routes/api';
import { ACCOUNT_EDIT, MEMBER_INVITE } from 'af-constants/reduxForms';

import * as FormUtil from 'ab-utils/form.util';

import type { HttpError } from 'af-utils/http.util';
import { http } from 'af-utils/http.util';
import type { ErrorOverride } from 'af-utils/actions.util';
import { errorHandler } from 'af-utils/actions.util';

import * as authenticationActionCreators from 'af-actions/authentication/authentication.actionCreators';
import * as companyActionCreators from 'af-actions/companies/companies.actionCreators';

import type { GetRootState } from 'af-reducers';

import * as ActivateAPI from 'ab-api/web/account/activate';
import * as AddExistingAPI from 'ab-api/web/account/addExisting';
import * as CreateAPI from 'ab-api/web/account/create';
import * as CSVExportAPI from 'ab-api/web/account/findAllForCsv';
import * as CSVUploadAPI from 'ab-api/web/account/uploadCSV';
import * as EditAPI from 'ab-api/web/account/edit';
import * as UpdateDigitalSignatureAPI from 'ab-api/web/account/updateDigitalSignature';
import * as SoftDeleteAPI from 'ab-api/web/account/softDelete';
import * as EditMemberAPI from 'ab-api/web/account/editMember';
import * as ResendEmailInviteAPI from 'ab-api/web/account/resendEmailInvite';
import * as ResendPhoneInviteAPI from 'ab-api/web/account/resendPhoneInvite';

/**
 * @param handler the action applied in cases of client errors
 */
function _handleAuthClientErrors(handler: (error: HttpError) => void): ErrorOverride {
	return {
		err400: handler,
		err404: handler,
	};
}

// #region CREATE

export function create(form: CreateAPI.W_Account_Create_RM) {
	return async (dispatch: Dispatch<AnyAction>, getState: GetRootState, { redirectTo }) => {

		const action = async () => {
			const options = {
				submitting: MEMBER_INVITE,
				headers: {
					'Accept': 'application/json',
					'Content-Type': 'multipart/form-data',
				},
			};
			const fd = FormUtil.getMultipartFormData(form, 'imageUrl');

			await http.post<void>(CreateAPI.URL(), fd, options);
		};

		return await errorHandler(action, dispatch, redirectTo);
	};
}

export function addExisting(form: AddExistingAPI.W_Account_AddExisting_RM) {
	return async (dispatch: Dispatch<AnyAction>, getState: GetRootState, { redirectTo }) => {

		const action = async () => {
			await http.post<void>(AddExistingAPI.URL(), form, { submitting: MEMBER_INVITE });
		};

		return await errorHandler(action, dispatch, redirectTo);
	};
}

// #endregion
// #region READ

export function findById(accountId: number) {
	return async (dispatch: Dispatch<AnyAction>, getState: GetRootState, { redirectTo }) => {

		const action = async () => {
			return await http.get<MemberVM>(API.V1.ACCOUNT.BY_ID(accountId));
		};

		return await errorHandler(action, dispatch, redirectTo);
	};
}

export function findActiveAccount() {
	return async (dispatch: Dispatch<AnyAction>, getState: GetRootState, { redirectTo }) => {

		const action = async () => {
			return await http.get<ActiveVM>(API.V1.ACCOUNT.ACTIVE);
		};

		return await errorHandler(action, dispatch, redirectTo);
	};
}

export function findAllForCompanyTable(tableRequestModel: TableQuery) {
	return async (dispatch: Dispatch<AnyAction>, getState: GetRootState, { redirectTo }) => {

		const action = async () => {
			const data = new TableQuery(tableRequestModel);
			return await http.get<TableContent<TableVM>>(API.V1.ACCOUNT.COMPANY_TABLE(data));
		};

		return await errorHandler(action, dispatch, redirectTo);
	};
}

export function findAllDeletedForCompanyTable(tableRequestModel: TableQuery = {} as TableQuery) {
	return async (dispatch: Dispatch<AnyAction>, getState: GetRootState, { redirectTo }) => {

		const action = async () => {
			const data = new TableQuery(tableRequestModel);
			return await http.get<TableContent<TableVM>>(API.V1.ACCOUNT.COMPANY_TABLE_DELETED(data));
		};

		return await errorHandler(action, dispatch, redirectTo);
	};
}

export function findAllForCompanyList(isDeleted?: boolean) {
	return async (dispatch: Dispatch<AnyAction>, getState: GetRootState, { redirectTo }) => {

		const action = async () => {
			const result = await http.get<CSVExportAPI.W_Account_FindAllForCSV_VM>(CSVExportAPI.URL(isDeleted));
			return result.rows;
		};

		return await errorHandler(action, dispatch, redirectTo);
	};
}

export function findAllForCompanyPreview() {
	return async (dispatch: Dispatch<AnyAction>, getState: GetRootState, { redirectTo }) => {

		const action = async () => {
			return await http.get<PreviewVM[]>(API.V1.ACCOUNT.ACCOUNTS_FOR_COMPANY);
		};

		return await errorHandler(action, dispatch, redirectTo);
	};
}

export function findAllAssignableForDeliverable() {
	return async (dispatch: Dispatch<AnyAction>, getState: GetRootState, { redirectTo }) => {
		const action = async () => {
			return await http.get<AccountViewModel[]>(API.V1.ACCOUNT.FIND_FOR_DELIVERABLE);
		};
		return await errorHandler(action, dispatch, redirectTo);
	};
}

export function findAllAssignableAsAccounting() {
	return async (dispatch: Dispatch<AnyAction>, getState: GetRootState, { redirectTo }) => {
		const action = async () => {
			return await http.get<AccountOptionVM[]>(API.V1.ACCOUNT.FIND_ACCOUNTING_OPTIONS);
		};
		return await errorHandler(action, dispatch, redirectTo);
	};
}

export function findAllAssignableAsTechnician() {
	return async (dispatch: Dispatch<AnyAction>, getState: GetRootState, { redirectTo }) => {
		const action = async () => {
			return await http.get<AccountOptionVM[]>(API.V1.ACCOUNT.FIND_TECHNICIAN_OPTIONS);
		};
		return await errorHandler(action, dispatch, redirectTo);
	};
}

export function findAllAssignableAsManagement() {
	return async (dispatch: Dispatch<AnyAction>, getState: GetRootState, { redirectTo }) => {
		const action = async () => {
			return await http.get<AccountOptionVM[]>(API.V1.ACCOUNT.FIND_MANAGEMENT_OPTIONS);
		};
		return await errorHandler(action, dispatch, redirectTo);
	};
}

// #endregion
// #region UPDATE

export function activate(accountId: number) {
	return async (dispatch: Dispatch<AnyAction>, getState: GetRootState, { redirectTo }) => {

		const action = async () => {
			await http.put<void>(ActivateAPI.URL(accountId));
		};

		return await errorHandler(action, dispatch, redirectTo);
	};
}

export function resendEmailInvite(accountId: number) {
	return async (dispatch: Dispatch<AnyAction>, getState: GetRootState, { redirectTo }) => {

		const action = async () => {
			await http.post<void>(ResendEmailInviteAPI.URL(accountId));
		};

		return await errorHandler(action, dispatch, redirectTo);
	};
}

export function resendPhoneInvite(accountId: number) {
	return async (dispatch: Dispatch<AnyAction>, getState: GetRootState, { redirectTo }) => {

		const action = async () => {
			await http.post<void>(ResendPhoneInviteAPI.URL(accountId));
		};

		return await errorHandler(action, dispatch, redirectTo);
	};
}

export function update(form: EditMemberAPI.W_Account_EditMember_RM) {
	return async (dispatch: Dispatch<AnyAction>, getState: GetRootState, { redirectTo }) => {

		const action = async () => {
			if (!form.id) {
				throw new Error('Account ID not provided');
			}

			const options = {
				headers: {
					'Accept': 'application/json',
					'Content-Type': 'multipart/form-data',
				},
			};
			const fd = FormUtil.getMultipartFormData(form, 'imageUrl');
			await http.put<void>(EditMemberAPI.URL(form.id), fd, options);
		};

		return await errorHandler(action, dispatch, redirectTo);
	};
}

export function updateActiveAccount(form: EditAPI.W_Account_Edit_RM) {
	return async (
		dispatch: Dispatch<authenticationActionCreators.AuthenticationAction>,
		getState: GetRootState, { redirectTo }
	) => {
		const action = async () => {

			const options = {
				submitting: ACCOUNT_EDIT,
				headers: {
					'Accept': 'application/json',
					'Content-Type': 'multipart/form-data',
				},
			};
			const fd = FormUtil.getMultipartFormData(form, 'image');
			const user = await http.put<EditAPI.W_Account_Edit_VM>(EditAPI.URL(), fd, options);
			const userData = {
				firstName: user.firstName,
				lastName: user.lastName,
				email: user.email,
				phoneNumber: user.phoneNumber,
				countryCode: user.countryCode,
				isDigitalSignatureEnabled: user.isDigitalSignatureEnabled,
				digitalSignatureUrl: user.digitalSignatureUrl,
				digitalSignatureId: user.digitalSignatureId,
			} as User.UserData;
			dispatch(authenticationActionCreators.UPDATE_USER(userData));

			return user;
		};
		const error: ErrorOverride = {
			..._handleAuthClientErrors((errorData) => {
				if (errorData?.response?.data) {
					throw new SubmissionError(errorData.response.data);
				} else {
					throw new SubmissionError({ _error: 'Edit user error' });
				}
			}),
		};
		return await errorHandler(action, dispatch, redirectTo, error);
	};
}

export function saveDigitalSignature(form: UpdateDigitalSignatureAPI.W_Account_UpdateDigitalSignature_RM) {
	return async (dispatch: Dispatch<AnyAction>, getState: GetRootState, { redirectTo }) => {

		const action = async () => {
			const signature = await http.patch<UpdateDigitalSignatureAPI.W_Account_UpdateDigitalSignature_VM>(UpdateDigitalSignatureAPI.URL(), form);
			const userData = {
				isDigitalSignatureEnabled: signature.isDigitalSignatureEnabled,
				digitalSignatureUrl: signature.digitalSignatureUrl,
				digitalSignatureId: signature.digitalSignatureId,
				showCreateDigitalSignature: signature.showCreateDigitalSignature,
			} as User.UserData;
			dispatch(authenticationActionCreators.UPDATE_USER(userData));
			return signature;
		};

		return await errorHandler(action, dispatch, redirectTo);
	};
}

// #endregion
// #region DELETE

export function remove(accountId: number) {
	return async (dispatch: Dispatch<AnyAction>, getState: GetRootState, { redirectTo }) => {

		const action = async () => {
			await http.delete<void>(SoftDeleteAPI.URL(accountId));
		};

		return await errorHandler(action, dispatch, redirectTo);
	};
}

// #endregion

// #region CSV IMPORT

export function validateCSV(members: CSVBulkAccountRM['accounts']) {
	return async (dispatch: Dispatch<companyActionCreators.CompanyAction>, getState: GetRootState, { redirectTo }) => {

		const action = async () => {

			const response = await http.post<CSVBulkAccountVM>(API.V1.ACCOUNT.CSV_VALIDATE, members);

			dispatch(companyActionCreators.SET_ACCEPTED_MEMBERS(response.valid));
			dispatch(companyActionCreators.SET_REJECTED_MEMBERS(response.invalid));
		};

		return await errorHandler(action, dispatch, redirectTo);
	};
}

export function upload(form: CSVUploadAPI.W_Account_UploadCSV_RM) {
	return async (dispatch: Dispatch<companyActionCreators.CompanyAction>, getState: GetRootState, { redirectTo }) => {

		const action = async () => {
			await http.post<void>(CSVUploadAPI.URL(), form);
		};

		return await errorHandler(action, dispatch, redirectTo);
	};
}

// #endregion
