import baseRoute from '../baseRoute';

export * from './viewModel';
export * from './queryParams';

type RouteDefinition = `${typeof baseRoute}/details/allocated${`?jobId=${number}&workOrderId=${number}` | ''}`;

export const ROUTE: RouteDefinition = `${baseRoute}/details/allocated`;

export const URL = (jobId: number, workOrderId: number): RouteDefinition => `${baseRoute}/details/allocated?jobId=${jobId}&workOrderId=${workOrderId}`;
