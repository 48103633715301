import * as React from 'react';
import { Button, FormControl } from 'react-bootstrap';

import CustomModal from 'af-components/CustomModal';
import SubmitButton from 'af-components/SubmitButton';
import type { DirectoryEntity } from 'af-components/DocumentsAttachmentsTree/types';

import styles from './styles.module.scss';

interface Props {
	parent: Nullable<DirectoryEntity>;
	closeModal: () => void;
	createDirectory: (parent: DirectoryEntity, name: string) => void;
}

const CreateNewDirectoryModal = React.memo((props: Props) => {
	const { parent, closeModal, createDirectory } = props;

	const [name, setName] = React.useState('');

	const baseClassName = 'modal';
	const headerClassName = `${baseClassName}__big-header__thin`;
	const bodyClassName = `${baseClassName}__body`;
	const inputFieldClassName = 'input-text-field';

	const showModal = !!parent;

	React.useEffect(() => {
		if (!showModal) {
			setName('');
		}
	}, [showModal]);

	const isDuplicateName = React.useMemo(() => {
		return parent?.directories.some((_dir) => _dir.name === name);
	}, [name, parent?.directories]);

	const handleNameChange = React.useCallback((event: React.ChangeEvent<HTMLInputElement>) => {
		setName(event.currentTarget.value);
	}, []);

	const createChildDirectory = React.useCallback(() => {
		if (!parent || !name) {
			return;
		}
		createDirectory(parent, name);
		closeModal();
	}, [closeModal, createDirectory, name, parent]);

	const resolvedInputClassName = React.useMemo(() => {
		return `${styles[inputFieldClassName]} ${isDuplicateName ? 'with-error' : ''}`;
	}, [isDuplicateName]);

	return (
		<>
			<CustomModal
				className={styles[baseClassName]}
				closeModal={closeModal}
				modalStyle="info"
				showModal={showModal}
				size="md"
			>
				<CustomModal.Header className={styles[headerClassName]}>
					<span>New Folder</span>
				</CustomModal.Header>
				<CustomModal.Body className={styles[bodyClassName]}>
					<FormControl
						className={resolvedInputClassName}
						onChange={handleNameChange}
						placeholder="New folder"
						type="text"
					/>
					{isDuplicateName && <div className={styles['duplicate-name-error']}>A Directory with that name already exists.</div>}
				</CustomModal.Body>
				<CustomModal.Footer>
					<Button
						onClick={closeModal}
						variant="info"
					>
						Cancel
					</Button>
					<SubmitButton disabled={isDuplicateName} label="Save" onClick={createChildDirectory.bind(this)} />
				</CustomModal.Footer>
			</CustomModal>
		</>
	);
});

export default CreateNewDirectoryModal;
