import * as React from 'react';
import { Dropdown as BootstrapDropdown } from 'react-bootstrap';

import SegmentLabel from 'af-components/SegmentLabel';

import { bemBlock } from 'ab-utils/bem.util';

import type { DropdownOptionType } from '..';

type SectionTitle = { [title in string]: string; };
type SectionOptions<T extends DropdownOptionType> = { [options in string]: T[]; };

export type SectionType<T extends DropdownOptionType> = SectionTitle & SectionOptions<T>;

export interface SectionListProps<T extends DropdownOptionType> {
	renderSectionHeader?: (section: SectionType<T>) => JSX.Element;
	sectionOptionsKey: keyof SectionType<T>;
	sections: SectionType<T>[];
	sectionTitleKey: keyof SectionType<T>;
	useSectionList: true;
}

interface SharedProps<T extends DropdownOptionType> {
	calculatedPropName: keyof T;
	defaultValue?: Nullable<T>;
	filterable: boolean;
	onChange: (item: string, options: T[]) => void;
	hasBlankOption: boolean;
	isLazyLoading: boolean;
	renderMenuItem: (item: Nullable<T>, index: number, searchText: string, onChangeHandler: (item: string) => void) => JSX.Element;
	searchText: string;
	selected: Nullable<T>;
}

type Props<T extends DropdownOptionType> = SectionListProps<T> & SharedProps<T>;

class SectionListMenu<T extends DropdownOptionType> extends React.PureComponent<Props<T>> {

	componentDidMount() {
		const { defaultValue, selected, calculatedPropName } = this.props;

		if (defaultValue && !selected) {
			this.handleChange(defaultValue[calculatedPropName]);
		}
	}

	static getRenderItemsReducer = <K extends DropdownOptionType>(
		renderMenuItem: (item: Nullable<K>, index: number, searchText: string, onChangeHandler: (item: string) => void) => JSX.Element,
		onChangeHandler: (item: string) => void,
		searchText: string
	) => {
		return (acc: JSX.Element[], option: K, index: number) => {
			const resultOption = renderMenuItem(option, index, searchText, onChangeHandler);
			if (resultOption) {
				acc.push(resultOption);
			}
			return acc;
		};
	};

	static getSectionElement = (header: JSX.Element, options: JSX.Element[], index: number) => {
		return (
			<div key={`section#${index + 1}`}>
				{header}
				{options}
			</div>
		);
	};

	getSectionsMapper = (key: string) => (section: SectionType<T>) => section[key];

	handleChange = (item: string) => {
		const { sections, sectionOptionsKey, onChange } = this.props;
		const sectionMapper = this.getSectionsMapper(sectionOptionsKey);
		const options = sections.flatMap(sectionMapper);
		onChange(item, options);
	};

	defaultRenderSectionHeader = (option: SectionType<T>) => {
		const { sectionTitleKey } = this.props as SectionListProps<T>;
		return option[sectionTitleKey];
	};

	renderSectionHeaderItem = (option: SectionType<T>): JSX.Element => {
		const { filterable } = this.props;
		const { renderSectionHeader } = this.props as SectionListProps<T>;

		const label = renderSectionHeader
			? renderSectionHeader(option)
			: this.defaultRenderSectionHeader(option);

		const className = bemBlock('dropdown-item__sticky', {
			filterable: filterable,
		});
		return (
			<BootstrapDropdown.Item
				className={className}
				disabled={true}
			>
				<SegmentLabel
					fullWidth={true}
					label={label}
					reduced="all"
				/>
			</BootstrapDropdown.Item>
		);
	};

	renderSection = (sectionElements: JSX.Element[], section: SectionType<T>, index: number) => {
		const { sectionOptionsKey, sectionTitleKey, searchText, renderMenuItem } = this.props;

		const options = section[sectionOptionsKey];
		const sectionTitle = section[sectionTitleKey];

		if (!options?.length) {
			return sectionElements;
		}
		if (!sectionTitle) {
			// eslint-disable-next-line no-console
			console.warn('[Dropdown]: `renderSectionList` Missing section title, skip render of section options');
			return;
		}

		const sectionHeader = this.renderSectionHeaderItem(section);
		const renderItemReducer = SectionListMenu.getRenderItemsReducer(renderMenuItem, this.handleChange, searchText);

		const sectionOptions = options.reduce(renderItemReducer, [] as JSX.Element[]);

		if (!sectionOptions.length) {
			return sectionElements;
		}

		sectionElements.push(SectionListMenu.getSectionElement(sectionHeader, sectionOptions, index));
		return sectionElements;
	};

	render() {
		const { sections, hasBlankOption, isLazyLoading, renderMenuItem, searchText } = this.props;

		if (!sections) {
			return null;
		}
		return (
			<>
				{hasBlankOption && !isLazyLoading && renderMenuItem(null, -1, searchText, this.handleChange)}
				{sections.reduce(this.renderSection, [] as JSX.Element[])}
			</>
		);
	}
}

export default SectionListMenu;
