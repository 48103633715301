import * as React from 'react';
import { compose } from 'redux';
import type { ConnectedProps } from 'react-redux';
import { connect } from 'react-redux';
import type { History, Location } from 'history';

import TimeFormat from 'acceligent-shared/enums/timeFormat';
import WorkOrderReviewStatus from 'acceligent-shared/enums/workOrderReviewStatus';

import * as TimeUtils from 'acceligent-shared/utils/time';

import type { RootState } from 'af-reducers';

import OrderInfoModal from 'af-root/scenes/Company/ScheduleBoard/Shared/OrderInfoModal';

import * as ScheduleBoardActions from 'af-actions/scheduleBoard';
import * as WorkOrderActions from 'af-actions/workOrder';
import * as FieldReportAccessActions from 'af-actions/fieldReportAccess';

import { convertWorkDays } from 'ab-viewModels/company.viewModel';
import type WorkOrderModalVM from 'ab-viewModels/scheduleBoardWorkOrderModal.viewModel';

import type { WorkOrderPublishForm, WorkOrderCancelForm, WorkOrderDelayForm, WorkOrderPauseForm } from 'ab-requestModels/workOrder.requestModel';

import * as WorkOrderUtil from 'af-utils/workOrder.util';

import OrderActionModals from './OrderActionModals';

import { useScheduleBoardModals } from 'af-root/hooks/useScheduleBoardModal';

interface OwnProps {
	cancelOrder: (workOrderId: string, cancelForm: WorkOrderCancelForm) => Promise<void>;
	pauseOrder: (workOrderId: string, pauseReason: WorkOrderPauseForm) => Promise<void>;
	resumeOrder: (workOrderId: string) => Promise<void>;
	companyName: string;
	deleteOrder: (workOrderId: string) => void;
	generatePreview: (workOrderId: string) => Promise<void>;
	hasPermissionsToEditScheduleBoard: boolean;
	history: History;
	location: Location;
	orgAlias: string;
	publishOrder: (workOrderId: string, publishForm?: WorkOrderPublishForm) => Promise<void>;
}

type Props = OwnProps & ConnectedProps<typeof connector>;

const OrderInfoModals: React.FC<Props> = (props) => {
	const {
		accountId,
		companyName,
		history,
		orgAlias,
		hasPermissionsToEditScheduleBoard = true,
		cancelOrder,
		deleteOrder,
		pauseOrder,
		resumeOrder,
		workDays = [],
		getNotificationTemplate,
		reinitializeCopyForm,
		lazyLoadWorkOrder,
		lazyLoadWorkOrderRevision,
		publishOrder,
		generatePreview,
		findAllWithAccess,
	} = props;

	const [workOrderDataId, setWorkOrderDataId] = React.useState<Nullable<number>>(null);
	const [notificationTemplate, setNotificationTemplate] = React.useState<Nullable<string>>(null);
	const [showCancelModal, setShowCancelModal] = React.useState(false);
	const [showCopyModal, setShowCopyModal] = React.useState(false);
	const [showDelayModal, setShowDelayModal] = React.useState(false);
	const [showDeleteModal, setShowDeleteModal] = React.useState(false);
	const [showNotificationPreviewModal, setShowNotificationPreviewModal] = React.useState(false);
	const [showNotifyParticipantsModal, setShowNotifyParticipantsModal] = React.useState(false);
	const [showWorkOrderModal, setShowWorkOrderModal] = React.useState(false);
	const [workOrderModal, setWorkOrderModal] = React.useState<Nullable<WorkOrderModalVM>>(null);
	const [workOrderModalRevision, setWorkOrderModalRevision] = React.useState<Nullable<WorkOrderModalVM>>(null);
	const [isRevisionOpened, setIsRevisionOpened] = React.useState(false);
	const [showCancelWarningModal, setShowCancelWarningModal] = React.useState(false);
	const [showPauseModal, setShowPauseModal] = React.useState(false);
	const [showResumeModal, setShowResumeModal] = React.useState(false);
	const [hasFRAccess, setHasFRAccess] = React.useState(false);

	const { workOrderModalId, setWorkOrderModalData } = useScheduleBoardModals();

	const loadWorkOrder = React.useCallback(async (workOrderId: number, openModal: boolean = false) => {
		const _workOrderModal = await lazyLoadWorkOrder(workOrderId);
		const _employees = await findAllWithAccess(workOrderId);
		setWorkOrderModal(_workOrderModal);
		setWorkOrderDataId(workOrderId);
		setShowWorkOrderModal(openModal ? true : showWorkOrderModal);
		setHasFRAccess(_employees.some((_employee) =>
			_employee.employee.accountId === accountId && _employee.isAllowed
		));
	}, [accountId, findAllWithAccess, lazyLoadWorkOrder, showWorkOrderModal]);

	React.useEffect(() => {
		const loadData = async () => {
			if (workOrderModalId) {
				await loadWorkOrder(workOrderModalId, true);
			}
		};
		loadData();
		// eslint-disable-next-line react-hooks/exhaustive-deps
	}, [workOrderModalId]); // Only call when workOrderModalId changes

	React.useEffect(() => {
		// FIXME: this is getting called every time WO modal is opened. Call it only after it is requested
		const loadNotificationTemplate = async () => {
			// if there is workOrderId then id is workOrderRevisionId
			if (workOrderModal) {
				const template = workOrderModal ? await getNotificationTemplate(workOrderModal.workOrderId ?? workOrderModal.id) : '';
				setNotificationTemplate(template);
			}
		};
		loadNotificationTemplate();
	}, [getNotificationTemplate, workOrderModal]);

	const setWorkOrderModalVisibility = React.useCallback((isVisible: boolean) => {
		if (!isVisible) {
			setWorkOrderModalRevision(null);
			setIsRevisionOpened(false);
			setShowWorkOrderModal(isVisible);
		} else {
			setShowWorkOrderModal(isVisible);
		}
	}, []);

	const closeOrderInfoModal = React.useCallback((reset?: boolean) => {
		setWorkOrderModalData(null);
		setWorkOrderModalVisibility(false);
		if (reset && location.search) {
			history.replace({
				pathname: location.pathname,
				search: '',
			});
		}
	}, [history, setWorkOrderModalData, setWorkOrderModalVisibility]);

	const openDeleteModal = React.useCallback(() => {
		setWorkOrderModalVisibility(false);
		setShowDeleteModal(true);
	}, [setShowDeleteModal, setWorkOrderModalVisibility]);

	const openCancelModal = React.useCallback(() => {
		setWorkOrderModalVisibility(false);

		if (workOrderModal?.reviewStatus === WorkOrderReviewStatus.FINAL) {
			setShowCancelWarningModal(true);
		} else {
			setShowCancelModal(true);
		}
	}, [setShowCancelModal, setShowCancelWarningModal, setWorkOrderModalVisibility, workOrderModal?.reviewStatus]);

	const openPauseModal = React.useCallback(() => {
		setWorkOrderModalVisibility(false);
		setShowPauseModal(true);
	}, [setShowPauseModal, setWorkOrderModalVisibility]);

	const openResumeModal = React.useCallback(() => {
		setWorkOrderModalVisibility(false);
		setShowResumeModal(true);
	}, [setShowResumeModal, setWorkOrderModalVisibility]);

	const openDelayModal = React.useCallback(() => {
		setWorkOrderModalVisibility(false);
		setShowDelayModal(true);
	}, [setShowDelayModal, setWorkOrderModalVisibility]);

	const openCopyModal = React.useCallback(() => {
		if (!workOrderModal) {
			throw new Error('Work Order modal not defined');
		}

		const { validOrders, finishedOrders, cancelledOrders } = WorkOrderUtil.groupWorkOrdersForCopy([workOrderModal]);
		reinitializeCopyForm(
			TimeUtils.addDays(workOrderModal.dueDate, 1).format(TimeFormat.DATE_ONLY),
			workOrderModal.dueDate,
			convertWorkDays(workDays),
			validOrders,
			finishedOrders,
			cancelledOrders,
			[workOrderModal.id],
			workOrderModal.code
		);

		setWorkOrderModalVisibility(false);
		setShowCopyModal(true);
	}, [reinitializeCopyForm, setShowCopyModal, setWorkOrderModalVisibility, workDays, workOrderModal]);

	const openNotifyParticipantsModal = React.useCallback(async () => {
		setWorkOrderModalVisibility(false);
		setShowNotifyParticipantsModal(true);
	}, [setShowNotifyParticipantsModal, setWorkOrderModalVisibility]);

	const openNotificationPreviewModal = React.useCallback(() => {
		setWorkOrderModalVisibility(false);
		setShowNotificationPreviewModal(true);
	}, [setShowNotificationPreviewModal, setWorkOrderModalVisibility]);

	const closeDelayModal = React.useCallback(() => {
		setWorkOrderModalVisibility(true);
		setShowDelayModal(false);
	}, [setShowDelayModal, setWorkOrderModalVisibility]);

	const selectWorkOrderRevision = React.useCallback(async (revisionId: number, isCurrentWorkOrder: boolean) => {

		let _workOrderModalRevision: Nullable<WorkOrderModalVM> = null;
		let _isRevisionOpened = false;
		if (isCurrentWorkOrder) {
			_workOrderModalRevision = await lazyLoadWorkOrder(revisionId);
		} else {
			_workOrderModalRevision = await lazyLoadWorkOrderRevision(revisionId);
			_isRevisionOpened = true;
		}
		setWorkOrderModalRevision(_workOrderModalRevision);
		setIsRevisionOpened(_isRevisionOpened);
	}, [lazyLoadWorkOrder, lazyLoadWorkOrderRevision]);

	const onPublishOrder = React.useCallback(async (workOrderId: number, form?: WorkOrderDelayForm) => {
		await publishOrder(`${workOrderId}`, form);

		if (showDelayModal) {
			closeDelayModal();
		}
	}, [closeDelayModal, publishOrder, showDelayModal]);

	const onGeneratePreview = React.useCallback(async () => {
		closeOrderInfoModal();
		await generatePreview(`${workOrderModalId ?? workOrderDataId ?? ''}`);
	}, [closeOrderInfoModal, generatePreview, workOrderModalId, workOrderDataId]);

	return workOrderModal ? (
		<>
			<OrderInfoModal
				actionsEnabled={true}
				closeOrderInfoModal={closeOrderInfoModal}
				companyName={companyName}
				generatePreview={onGeneratePreview}
				hasAccess={hasFRAccess}
				hasPermissionsToEditScheduleBoard={hasPermissionsToEditScheduleBoard}
				history={history}
				isRevisionOpened={isRevisionOpened}
				loadWorkOrder={loadWorkOrder}
				openCancelModal={openCancelModal}
				openCopyModal={openCopyModal}
				openDelayModal={openDelayModal}
				openDeleteModal={openDeleteModal}
				openNotificationPreviewModal={openNotificationPreviewModal}
				openNotifyParticipantsModal={openNotifyParticipantsModal}
				openPauseModal={openPauseModal}
				openResumeModal={openResumeModal}
				orgAlias={orgAlias}
				publishOrder={onPublishOrder}
				selectRevision={selectWorkOrderRevision}
				showModal={showWorkOrderModal}
				workOrder={workOrderModal}
				workOrderRevision={workOrderModalRevision}
			/>
			<OrderActionModals
				cancelOrder={cancelOrder}
				closeOrderInfoModal={closeOrderInfoModal}
				deleteOrder={deleteOrder}
				notificationTemplate={notificationTemplate ?? undefined}
				pauseOrder={pauseOrder}
				publishOrder={onPublishOrder}
				resumeOrder={resumeOrder}
				setCancelModalVisibility={setShowCancelModal}
				setCancelWarningModalVisibility={setShowCancelWarningModal}
				setCopyModalVisibility={setShowCopyModal}
				setDelayModalVisibility={setShowDelayModal}
				setDeleteModalVisibility={setShowDeleteModal}
				setNotificationPreviewModalVisibility={setShowNotificationPreviewModal}
				setNotifyParticipantsModalVisibility={setShowNotifyParticipantsModal}
				setPauseModalVisibility={setShowPauseModal}
				setResumeModalVisibility={setShowResumeModal}
				setWorkOrderModalVisibility={setWorkOrderModalVisibility}
				showCancelModal={showCancelModal}
				showCancelWarningModal={showCancelWarningModal}
				showCopyModal={showCopyModal}
				showDelayModal={showDelayModal}
				showDeleteModal={showDeleteModal}
				showNotificationPreviewModal={showNotificationPreviewModal}
				showNotifyParticipantsModal={showNotifyParticipantsModal}
				showPauseModal={showPauseModal}
				showResumeModal={showResumeModal}
				workOrder={workOrderModal}
			/>
		</>
	) : null;
};

const mapStateToProps = (state: RootState) => {
	const {
		company: { company },
		user: { companyData },
	} = state;

	return {
		workDays: company?.workDays,
		accountId: companyData?.accountId ?? null,
	};
};

const mapDispatchToProps = () => ({
	getNotificationTemplate: WorkOrderActions.getNotificationTemplate,
	lazyLoadWorkOrder: ScheduleBoardActions.lazyLoadWorkOrder,
	lazyLoadWorkOrderRevision: ScheduleBoardActions.lazyLoadWorkOrderRevision,
	reinitializeCopyForm: WorkOrderActions.reinitializeCopyForm,
	findAllWithAccess: FieldReportAccessActions.findAllByWorkOrderId,
});

const connector = connect(mapStateToProps, mapDispatchToProps());

const enhance = compose<React.ComponentType<OwnProps>>(
	React.memo,
	connector
);

export default enhance(OrderInfoModals);
