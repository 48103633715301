import Select from 'af-fields/SelectField';
import * as React from 'react';
import { Field, FieldArray } from 'redux-form';

import InvoiceBillingLookupType from 'acceligent-shared/enums/invoiceBillingLookupType';
import { EmailTypes } from 'acceligent-shared/enums/contactMethodType';
import UpsertContactStatus from 'acceligent-shared/enums/upsertContactStatus';

import CreateContactModal from 'af-components/SharedForms/Contacts/SelectContact/CreateContactModal';

import type { ContactForInvoicesVM } from 'ab-viewModels/contact/contactsForInvoices.viewModel';
import type ContactVM from 'ab-viewModels/contact/contact';

import type { OwnProps as BillingContactEmailsProps } from './BillingContactEmails';
import BillingContactEmails from './BillingContactEmails';
import type { BillingContact } from '../../EditWhenInvoiced/formModel';
import type { InvoiceBillingContactFM } from '../BillingContactArray';

import styles from './styles.module.scss';

const BillingContactSelect = Select as unknown as new () => Select<ContactForInvoicesVM>;

type Props = {
	billingContacts: ContactForInvoicesVM[];
	label: JSX.Element;
	fieldName: string;
	// eslint-disable-next-line @typescript-eslint/no-explicit-any
	change: (fieldName: string, value: Metadata | string | number | boolean | null) => void;
	currentContact: Nullable<InvoiceBillingContactFM>;
};

const getContactOptionValue = (_contact: ContactForInvoicesVM) => {
	return _contact.id;
};

function isContactForInvoicesVM(option: InvoiceBillingContactFM | ContactForInvoicesVM): option is ContactForInvoicesVM {
	return (option as ContactForInvoicesVM).emails !== undefined;
}

const mapContactEmails = ((_email, index) => <div className={styles['contact-menu-item__email']} key={index}>{_email.value}</div>);

const formatContactOptionLabel = (option: InvoiceBillingContactFM | ContactForInvoicesVM, { context }) => {
	if (isContactForInvoicesVM(option)) {
		const companyName = `${option.companyName ?? ''}`;
		const delimiter = (option.companyName && option.title) ? '|' : '';
		const title = `${option.title ?? ''}`;

		return (
			<div className={styles['contact-menu-item']}>
				<b>
					{option.fullName}
				</b>
				{(companyName || title) && (
					<div>
						{`${companyName} ${delimiter} ${title}`}
					</div>
				)}
				{option.emails.map(mapContactEmails)}
			</div>
		);
	}

	switch (option?.type) {
		case InvoiceBillingLookupType.EMAIL_ONLY: {
			if (context === 'menu') { // shouldn't happen
				return null;
			} else {
				return <b>{option.email}</b>;
			}
		}
		case InvoiceBillingLookupType.CONTACT: {
			if (context === 'menu') { // shouldn't happen
				return null;
			} else {
				const companyName = `${option.contact.companyName ?? ''}`;
				const delimiter = (option.contact.companyName && option.contact.title) ? '|' : '';
				const title = `${option.contact.title ?? ''}`;
				return (
					<div className={styles['contact-menu-selected']}>
						<b>
							{option.contact.fullName}
						</b>
						{(companyName || title) && (
							<span>
								{`${companyName} ${delimiter} ${title}`}
							</span>
						)}
					</div>
				);
			}
		}
		default: {
			return null;
		}
	}
};

const _filterContacts = (inputValue: string) =>
	(_contact: ContactForInvoicesVM) => {
		const lowerSearchText = inputValue.toLowerCase();
		const emailsJoined = _contact.emails.map((_email) => _email.value).join(' ');
		return !!_contact.fullName.toLowerCase().includes(lowerSearchText)
			|| !!_contact.companyName?.toLowerCase().includes(lowerSearchText)
			|| !!_contact.title?.toLowerCase().includes(lowerSearchText)
			|| emailsJoined.toLowerCase().includes(lowerSearchText);
	};

const _isInputPartialEmail = (_inputValue: Nullable<string>) => _inputValue?.includes('@') ?? false;

const BillingContactDropdown: React.FC<Props> = ({ billingContacts, label, fieldName, change, currentContact }) => {

	const [inputValue, setInputValue] = React.useState<Nullable<string>>(null);
	const [filteredOptions, setFilteredOptions] = React.useState<ContactForInvoicesVM[]>(billingContacts);
	const [showCreateContactModal, setShowCreateContactModal] = React.useState(false);
	const [inputValueToCreate, setInputValueToCreate] = React.useState<Nullable<string>>(null);

	const onInputChange = React.useCallback((_inputValue: string) => {
		setInputValue(_inputValue);
	}, []);

	React.useEffect(() => {
		if (inputValue) {
			setFilteredOptions(billingContacts.filter(_filterContacts(inputValue)));
		} else if (inputValue === '') {
			setFilteredOptions(billingContacts);
		}
	}, [billingContacts, inputValue]);

	const filterContact = React.useCallback(async (option: { data: ContactForInvoicesVM & { __isNew__?: boolean; }; }, searchText: string) => {
		if (!option || option.data.__isNew__) {
			return false;
		}

		const lowerSearchText = searchText.toLowerCase();
		const emailsJoined = option.data.emails.map((_email) => _email.value).join(' ');

		return !!option.data.fullName.toLowerCase().includes(lowerSearchText)
			|| !!option.data.companyName?.toLowerCase().includes(lowerSearchText)
			|| !!option.data.title?.toLowerCase().includes(lowerSearchText)
			|| !!emailsJoined.toLowerCase().includes(lowerSearchText);
	}, []);

	const renderContactInfo = React.useCallback(() => {

		if (currentContact?.type !== InvoiceBillingLookupType.CONTACT) {
			return null;
		}

		return (
			<div className={styles['contact-info']}>
				<FieldArray<BillingContactEmailsProps>
					component={BillingContactEmails}
					emails={currentContact.contact.emails}
					name={`${fieldName}.contact.contactEmailIds`}
				/>
			</div>
		);
	}, [currentContact, fieldName]);

	const onContactChange = React.useCallback((data: ContactForInvoicesVM) => {
		const hasOnlyOneEmail = data.emails.length === 1;

		const billingContact: BillingContact = {
			type: InvoiceBillingLookupType.CONTACT,
			contact: {
				contactId: data.id,
				companyName: data.companyName,
				emails: data.emails,
				fullName: data.fullName,
				title: data.title,
				contactEmailIds: hasOnlyOneEmail ? [data.emails[0].id] : [],
			},
		};
		change(fieldName, billingContact);
	}, [change, fieldName]);

	const isNewContactValid = React.useCallback(() => {
		return !!inputValue;
	}, [inputValue]);

	const onClear = React.useCallback(() => {
		change(fieldName, null);
	}, [change, fieldName]);

	const onBlur = React.useCallback(() => {
		if (inputValue) {
			change(fieldName, { type: InvoiceBillingLookupType.EMAIL_ONLY, email: inputValue });
		}
	}, [change, fieldName, inputValue]);

	const onCreateNew = React.useCallback((name: string) => {
		setInputValueToCreate(name);
		setShowCreateContactModal(true);
	}, []);

	const closeCreateContactModal = React.useCallback(() => {
		setShowCreateContactModal(false);
	}, []);

	const onCreatedNewContact = React.useCallback((newContact: ContactVM) => {
		setFilteredOptions([...billingContacts, newContact]);
		const hasOnlyOneEmail = newContact.emails.length === 1;
		const billingContact: BillingContact = {
			type: InvoiceBillingLookupType.CONTACT,
			contact: {
				contactId: newContact.id,
				companyName: newContact.companyName,
				emails: newContact.emails,
				fullName: newContact.fullName,
				title: newContact.title,
				contactEmailIds: hasOnlyOneEmail ? [newContact.emails[0].id] : [],
			},
		};
		change(fieldName, billingContact);
	}, [billingContacts, change, fieldName]);

	const resolveNewContactInitialValues = React.useMemo(() => {
		if (!inputValueToCreate) return {};

		if (_isInputPartialEmail(inputValueToCreate)) {
			return {
				emails: [{
					value: inputValueToCreate,
					type: EmailTypes.EMAIL_DEFAULT,
					status: UpsertContactStatus.ADDED,
				}],
			};
		} else {
			return { fullName: inputValueToCreate };
		}
	}, [inputValueToCreate]);

	return (
		<div className={styles['contact-container']}>
			<Field
				allowNew={true}
				component={BillingContactSelect}
				errorKey="email"
				filterOption={filterContact}
				formatOptionLabel={formatContactOptionLabel}
				getOptionValue={getContactOptionValue}
				inputValue={inputValue}
				isValidNewOption={isNewContactValid}
				label={label}
				name={fieldName}
				onBlur={onBlur}
				onClear={onClear}
				onCreateNew={onCreateNew}
				onInputChange={onInputChange}
				onValueChange={onContactChange}
				options={filteredOptions}
				placeholder="Choose Contact or Enter Email"
			/>
			{renderContactInfo()}
			{inputValueToCreate &&
				<CreateContactModal
					areEmailsRequired={true}
					closeModal={closeCreateContactModal}
					initialValues={resolveNewContactInitialValues}
					onCreateNew={onCreatedNewContact}
					showModal={showCreateContactModal}
				/>
			}
		</div>
	);
};

export default React.memo(BillingContactDropdown);
