import { isValidTextInput } from 'acceligent-shared/utils/text';

import type { DeliverableStatusRequestModel } from 'ab-requestModels/deliverableStatus.requestModel';
import type { DeliverableDataRequestModel } from 'ab-requestModels/deliverableData.requestModel';

export function validateStatus(values: DeliverableStatusRequestModel) {
	const errors: ValidationErrors = {};

	if (!isValidTextInput(values.name)) {
		errors.name = 'Name is required';
	}

	if (!isValidTextInput(values.abbreviation)) {
		errors.abbreviation = 'Abbreviation is required';
	}

	if (!values.statusColor) {
		errors.statusColor = 'Status color is required';
	}

	if (!values.textColor) {
		errors.textColor = 'Text color is required';
	}

	return errors;
}

export function validateData(values: DeliverableDataRequestModel) {
	const errors: ValidationErrors = {};

	if (!isValidTextInput(values.name)) {
		errors.name = 'Name is required';
	}

	if (!values.type) {
		errors.type = 'Type is required';
	}

	return errors;
}
