import * as React from 'react';
import { Col } from 'react-bootstrap';
import type { DropResult, DragStart } from 'react-beautiful-dnd';
import { DragDropContext } from 'react-beautiful-dnd';
import { nanoid } from 'nanoid';

import * as ReportBlockFieldEnum from 'acceligent-shared/enums/reportBlockField';
import ReportTypeBlockType from 'acceligent-shared/enums/reportTypeBlockType';
import ReportBlockType, { TotalBlockType } from 'acceligent-shared/enums/reportBlockType';
import OperandType from 'acceligent-shared/enums/operand';
import { MathOperations } from 'acceligent-shared/enums/operation';

import { isEqualShallow, omitObject } from 'acceligent-shared/utils/extensions';

import type { ReportTypeRM, ReportTypeBlockRM, ReportBlockFieldRM } from 'ab-requestModels/reportType/reportType.requestModel';
import { BillableWorkRM } from 'ab-requestModels/reportType/reportType.requestModel';

import type { EquipmentListViewModel } from 'ab-viewModels/equipmentList.viewModel';
import type { BillableWorkVM } from 'ab-viewModels/reportType/reportType.viewModel';
import type { ReportTypeVM } from 'ab-viewModels/reportType/reportType.viewModel';
import type { ReportBlockVM } from 'ab-viewModels/reportBlock/reportBlockMap.viewModel';
import type ReportBlockMapVM from 'ab-viewModels/reportBlock/reportBlockMap.viewModel';

import { filterMap, createExistsMap, flattenElements } from 'ab-utils/array.util';

import { DroppableZones, CustomBlocks, MainBlockZones } from 'ab-enums/reportTypeBuilder.enum';

import ConfirmationModal from 'af-components/ConfirmationModal';

import * as ReportTypeBuilderUtil from 'af-utils/reportTypeBuilder.util';

import { UNIQUE_ID_SIZE } from 'ab-common/constants/value';

import * as ValueConstants from '../../ReportBlock/values';
import DisplayTypeEnum, { getDisplayType } from '../../Shared/displayType.enum';
import type { CalculatedFieldOptionFormModel, BlockDropdownSection } from '../../Shared/formModel';
import { ReportTypeBlockFormModel, ReportBlockFormModel, ReportBlockFieldFormModel } from '../../Shared/formModel';

import BillableWorkFormModel from '../Modals/BillableWork/FormModel';
import type { BillableWorkDropdownOption } from '../Modals/BillableWork/types';
import CalculatedFieldModal from '../Modals/CalculatedFieldModal';
import EditBlockNameModal from '../Modals/EditBlockNameModal';
import type { AddReportTypeBlockForm } from '../Modals/ReportTypeBlockModal';
import ReportTypeBlockModal from '../Modals/ReportTypeBlockModal';
import type { FormHeaderPropsWithBillableWork, FormHeaderPropsWithoutBillableWork } from '../Shared/FormHeader';
import Header from '../Shared/FormHeader';
import Loading from '../Shared/FormLoading';
import type { HighlightedBillableWorkBlockFields } from '../Shared/values';
import { DISPLAY_TYPE_COL_SIZE, VALID_BILLABLE_WORK_TYPE_FIELDS, VALID_DEFINITION_FIELD_FIELDS } from '../Shared/values';

import BlockList from './BlockList';
import Preview from './Preview';
import TypeInfo from './TypeInfo';
import { validate } from './validation';

interface OwnProps {
	loading?: boolean;
	reportBlocksMap: ReportBlockMapVM['blockMap'];
	reportBlockFieldsMap: ReportBlockMapVM['fieldMap'];
	reportType: Nullable<ReportTypeVM>;
	hasBillableWork?: true;
	onSubmit: (reportType: ReportTypeRM) => void;
	listOfEquipment: Nullable<EquipmentListViewModel>;
	fetchListOfEquipment: () => void;
}

type Props = OwnProps;

interface RemoveReportTypeMainBlockConfirmationModalMeta {
	isPrimary: boolean;
	billableWorksForDelete: BillableWorkRM[];
}

interface RemoveReportTypeBlockConfirmationModalMeta {
	id: string;
	isPrimary: boolean;
	billableWorksForDelete: BillableWorkRM[];
}

interface State {
	isReportTypeDataInitialized: boolean;
	/** Report type block virtual ids in primary segment, without main block virtual id */
	primaryTypeBlockIds: string[];
	/** Main primary report type block virtual id */
	primaryTypeMainBlockId: Nullable<string>;
	/** Report type block virtual ids in secondary segment, without main block virtual id */
	secondaryTypeBlockIds: string[];
	/** Main secondary report type block virtual id */
	secondaryTypeMainBlockId: Nullable<string>;
	/** Map of report type blocks by virtual id */
	reportTypeBlocksByIdMap: { [id: string]: ReportTypeBlockFormModel; };
	/** Map of report blocks by virtual id */
	reportBlocksByIdMap: { [id: string]: ReportBlockFormModel; };
	/** Map of report block fields by virtual id */
	reportFieldsByIdMap: { [id: string]: ReportBlockFieldFormModel; };
	/** Upper total report type block virtual id */
	upperTotalBlockId: Nullable<string>;
	/** Lower total report type block virtual id */
	lowerTotalBlockId: Nullable<string>;
	billableWorks: BillableWorkRM[];
	reportTypeName: string;
	reportTypeDescription: Nullable<string>;
	modalDropZone: Nullable<DroppableZones>;
	displayType: DisplayTypeEnum;
	draggingElementSource: Nullable<DroppableZones>;
	/** Only makes a difference when on small screen */
	isPreviewInFocus: boolean;
	showPreviewToggle: boolean;
	fieldModalReportTypeBlockId: Nullable<string>;
	fieldModalFieldId: Nullable<string>;
	isFieldModalTotalBlock: Nullable<boolean>;
	isFieldModalBlockPrimary: Nullable<boolean>;
	showBlockNameModal: boolean;
	blockNameModalReportTypeBlockId: Nullable<string>;
	calculationFieldMap: { [fieldId: string]: true; };
	/** Modals */
	showReportTypeBlockModal: boolean;
	showConfirmationModal: boolean;
	showCalculatedFieldModal: boolean;
	calculatedFieldModalOptions: BlockDropdownSection<CalculatedFieldOptionFormModel>[];
	showRemoveReportTypeBlockConfirmationModal: boolean;
	showRemoveReportTypeMainBlockConfirmationModal: boolean;
	removeReportTypeMainBlockConfirmationModalMeta: Nullable<RemoveReportTypeMainBlockConfirmationModalMeta>;
	removeReportTypeBlockConfirmationModalMeta: Nullable<RemoveReportTypeBlockConfirmationModalMeta>;
	currentlyHighlightedFields: HighlightedBillableWorkBlockFields;
	definitionFieldFieldIds: string[];
	informationFieldFieldIds: string[];
	workTypeFieldIds: string[];
	workQuantityFieldIds: string[];
	billableWorkFieldsByIdMap: { [fieldId: string]: BillableWorkDropdownOption; };
	isValid: boolean;
}

// TODO: break this component into smaller ones
class ReportTypeCustomTypeForm extends React.PureComponent<Props, State> {

	state: State = {
		displayType: DisplayTypeEnum.DEFAULT,
		isReportTypeDataInitialized: false,
		primaryTypeBlockIds: [], // initialized in `initializeReportTypeData`
		secondaryTypeBlockIds: [], // initialized in `initializeReportTypeData`
		primaryTypeMainBlockId: null, // initialized in `initializeReportTypeData`
		secondaryTypeMainBlockId: null, // initialized in `initializeReportTypeData`
		reportTypeBlocksByIdMap: {},
		reportBlocksByIdMap: {},
		reportFieldsByIdMap: {},
		upperTotalBlockId: null, // initialized in `initializeReportTypeData`
		lowerTotalBlockId: null, // initialized in `initializeReportTypeData`
		reportTypeDescription: null, // initialized in `initializeReportTypeData`
		reportTypeName: '', // initialized in `initializeReportTypeData`
		billableWorks: [], // initialized in `initializeReportTypeData`
		showReportTypeBlockModal: false,
		modalDropZone: null,
		draggingElementSource: null,
		isPreviewInFocus: false,
		showPreviewToggle: false,
		showConfirmationModal: false,
		showCalculatedFieldModal: false,
		fieldModalReportTypeBlockId: null,
		fieldModalFieldId: null,
		isFieldModalTotalBlock: null,
		isFieldModalBlockPrimary: null,
		showBlockNameModal: false,
		blockNameModalReportTypeBlockId: null,
		calculatedFieldModalOptions: [],
		calculationFieldMap: {},
		currentlyHighlightedFields: {},
		definitionFieldFieldIds: [],
		informationFieldFieldIds: [],
		workTypeFieldIds: [],
		workQuantityFieldIds: [],
		billableWorkFieldsByIdMap: {},
		showRemoveReportTypeBlockConfirmationModal: false,
		showRemoveReportTypeMainBlockConfirmationModal: false,
		removeReportTypeBlockConfirmationModalMeta: null,
		removeReportTypeMainBlockConfirmationModalMeta: null,
		isValid: false,
	};

	static BILLABLE_WORK_OPTIONS_STATE_RECALCULATION_FIELDS: (keyof State)[] = [
		'lowerTotalBlockId',
		'primaryTypeBlockIds',
		'primaryTypeMainBlockId',
		'secondaryTypeBlockIds',
		'secondaryTypeMainBlockId',
		'upperTotalBlockId',
		'reportTypeBlocksByIdMap',
		'reportBlocksByIdMap',
		'reportFieldsByIdMap',
	];

	/** Maps report block field form models to RMs adding calculation field options */
	static getFieldFormModelToRequestModelMapper = (reportFieldsByIdMap: { [id: string]: ReportBlockFieldFormModel; }) => {
		return (id: string, index: number): ReportBlockFieldRM => {
			const field = reportFieldsByIdMap[id];
			return ReportBlockFieldFormModel.toReportTypeRM(field, index);
		};
	};

	static _getConfirmationModalTitle = (name: string) => `Are you sure you want to update Report Type (${name})?`;
	static _getConfirmationModalBody = (name: string) => (
		<>
			<div>{`Updating Report Type (${name}) will update all associations with Work Orders and the changes will be automatically generated in new Field Reports.`}</div>
			<div>Changes will not apply to already created Field Reports.</div>
		</>
	);

	/**
	 * Creates list of dropdown sections for calculated field dropdown options
	 * @param blocks list of report type blocks
	 * @returns returns list of block sections of type 'BlockDropdownSection'
	 */
	static createCalculatedFieldDropdownSections = (
		reportTypeBlockIds: string[],
		reportTypeBlocksByIdMap: { [id: string]: ReportTypeBlockFormModel; },
		reportBlocksByIdMap: { [id: string]: ReportBlockFormModel; },
		reportFieldsByIdMap: { [id: string]: ReportBlockFieldFormModel; }
	) => {
		return reportTypeBlockIds?.reduce((_acc, _id) => {
			const _typeBlock = reportTypeBlocksByIdMap[_id];
			if (!_typeBlock) {
				throw new Error('Missing report type block from map');
			}
			const _block = reportBlocksByIdMap[_typeBlock.reportBlockVirtualId];
			if (!_block) {
				throw new Error('Missing report block from map');
			}

			if (_block.reportBlockFieldIds) {
				const segment = ReportTypeBuilderUtil.getSegment(_typeBlock.isPrimary);
				const name = `${_typeBlock.isPrimary ? 'PRIMARY' : 'SECONDARY'} (${_block.name})`;
				const blockId = _block.id;
				const isPrimary = _typeBlock.isPrimary;

				const fields: CalculatedFieldOptionFormModel[] = [];
				for (const _fieldId of _block.reportBlockFieldIds) {
					const _field = reportFieldsByIdMap[_fieldId];

					if (ReportBlockFieldEnum.CalculationFieldTypes[_field.fieldType]) {
						fields.push({
							id: _field.virtualId,
							constant: null,
							type: OperandType.FIELD,
							index: fields.length,
							isOperandBlockInPrimarySegment: _typeBlock.isPrimary,
						});
					}
				}
				if (fields.length) {
					_acc.push({
						name,
						segment,
						blockId,
						isPrimary,
						fields,
					});
				}
			}
			return _acc;
		}, [] as BlockDropdownSection<CalculatedFieldOptionFormModel>[]);
	};

	/** Returns number of CALCULATED blocks */
	static getCalculatedBlockCount = (
		reportTypeBlockIds: string[],
		reportTypeBlocksByIdMap: { [id: string]: ReportTypeBlockFormModel; },
		reportBlocksByIdMap: { [id: string]: ReportBlockFormModel; }
	) => (
		reportTypeBlockIds.reduce((_acc, _id) => {
			const _typeBlock = reportTypeBlocksByIdMap[_id];
			const _block = reportBlocksByIdMap[_typeBlock.reportBlockVirtualId];

			if (_block.type === ReportBlockType.CALCULATED) {
				_acc += 1;
			}
			return _acc;
		}, 0)
	);

	static isBillableWorkRelatedToFields = (billableWork: BillableWorkRM, fields: { [id: string]: true; }) => {
		const { definitionFields, informationFields, workQuantityReportBlockFieldVirtualId, workTypeReportBlockFieldVirtualId } = billableWork;

		if ((workQuantityReportBlockFieldVirtualId && fields[workQuantityReportBlockFieldVirtualId]) || fields[workTypeReportBlockFieldVirtualId]) {
			return true;
		}
		// using for loop so we can do an early return
		for (const definitionField of definitionFields) {
			if (fields[definitionField.reportBlockFieldVirtualId]) {
				return true;
			}
		}
		// using for loop so we can do an early return
		for (const informationField of informationFields) {
			if (fields[informationField.reportBlockFieldVirtualId]) {
				return true;
			}
		}
		return false;
	};

	static filterBillableWorksWithFieldsForDelete = (billableWorks: BillableWorkRM[], fieldsForDelete: { [id: string]: true; }) => {
		return billableWorks.filter((_bw) => !ReportTypeCustomTypeForm.isBillableWorkRelatedToFields(_bw, fieldsForDelete));
	};

	static resolveBillableWorksWhichWillBeDeleted = (billableWorks: BillableWorkRM[], fieldsForDelete: { [id: string]: true; }) => {
		return billableWorks.filter((_bw) => ReportTypeCustomTypeForm.isBillableWorkRelatedToFields(_bw, fieldsForDelete));
	};

	static isValidBillableWorkTypeOption = (option: BillableWorkDropdownOption) => {
		if (!VALID_BILLABLE_WORK_TYPE_FIELDS[option.type]) {
			return false;
		}
		if (option.type === ReportBlockFieldEnum.Type.CALCULATED) {
			return option.operationType && MathOperations[option.operationType];
		}
		return true;
	};

	static isValidBillableWorkTypeQuantityOption = (option: BillableWorkDropdownOption) => {
		if (!ReportBlockFieldEnum.EQUIVALENT_BILLABLE_WORK_FIELD_TYPES[option.type]) {
			return false;
		}
		if (option.type === ReportBlockFieldEnum.Type.CALCULATED) {
			return option.operationType && MathOperations[option.operationType];
		}
		return true;
	};

	static isValidBillableWorkDefinitionOption = (option: BillableWorkDropdownOption) => {
		if (!VALID_DEFINITION_FIELD_FIELDS[option.type]) {
			return false;
		}
		if (option.type === ReportBlockFieldEnum.Type.CALCULATED) {
			return option.operationType && MathOperations[option.operationType];
		}
		return true;
	};

	componentDidMount() {
		this.updateWindowDimensions();
		window.addEventListener('resize', this.updateWindowDimensions);

		if (!this.props.loading) {
			this.initializeReportTypeData();
		}
	}

	componentDidUpdate(prevProps: Props, prevState: State) {
		if (prevProps.loading && !this.props.loading) {
			this.initializeReportTypeData();
		}
		// Validate if any of the following changes
		if (prevState.reportTypeBlocksByIdMap !== this.state.reportTypeBlocksByIdMap
			|| prevState.reportBlocksByIdMap !== this.state.reportBlocksByIdMap
			|| prevState.reportFieldsByIdMap !== this.state.reportFieldsByIdMap
			|| prevState.reportTypeName !== this.state.reportTypeName) {

			this.setState(() => ({
				isValid: validate(
					this.state.primaryTypeMainBlockId,
					this.state.primaryTypeBlockIds,
					this.state.secondaryTypeBlockIds,
					this.state.secondaryTypeMainBlockId,
					this.state.upperTotalBlockId,
					this.state.lowerTotalBlockId,
					this.state.reportTypeBlocksByIdMap,
					this.state.reportBlocksByIdMap,
					this.state.reportFieldsByIdMap,
					this.state.reportTypeName
				),
			}));
		}
		const dropdownOptionsRecalculationResult = this.calculateBillableWorkDropdownOptions(prevState);
		if (dropdownOptionsRecalculationResult.hasChanged) {
			this.setState(() => dropdownOptionsRecalculationResult.data);
		}
	}

	componentWillUnmount() {
		window.removeEventListener('resize', this.updateWindowDimensions);
	}

	// initialize state data
	initializeReportTypeData = () => {
		const { reportType, fetchListOfEquipment } = this.props;
		const { isReportTypeDataInitialized } = this.state;

		if (isReportTypeDataInitialized || !reportType) {
			return;
		}

		const [primaryTypeMainBlockId, ...primaryTypeBlockIds] = reportType.primaryBlockIds ?? [];
		const [secondaryTypeMainBlockId, ...secondaryTypeBlockIds] = reportType.secondaryBlockIds ?? [];

		if (Object.entries(reportType.reportFields ?? []).some(([, _field]) => _field.fieldType === ReportBlockFieldEnum.Type.EQUIPMENT)) {
			fetchListOfEquipment();
		}

		const reportTypeBlocksByIdMap = Object.entries(reportType.reportTypeBlocks ?? []).reduce((_acc, [id, _typeBlock]) => {
			_acc[id] = ReportTypeBlockFormModel.fromReportTypeVM(_typeBlock);
			return _acc;
		}, {} as State['reportTypeBlocksByIdMap']);

		const reportBlocksByIdMap = Object.entries(reportType.reportBlocks ?? []).reduce((_acc, [id, _block]) => {
			_acc[id] = ReportBlockFormModel.fromReportTypeViewModel(_block);
			return _acc;
		}, {} as State['reportBlocksByIdMap']);

		const {
			reportFieldsByIdMap,
			calculationFieldMap,
		} = Object.entries(reportType.reportFields ?? []).reduce((_acc, [id, _field]) => {
			_acc.reportFieldsByIdMap[id] = ReportBlockFieldFormModel.fromReportTypeVM(_field);
			if (_field.fieldType === ReportBlockFieldEnum.Type.CALCULATED) {
				_acc.calculationFieldMap[id] = true;
			}
			return _acc;
		}, { reportFieldsByIdMap: {}, calculationFieldMap: {} } as { reportFieldsByIdMap: State['reportFieldsByIdMap']; calculationFieldMap: State['calculationFieldMap']; });

		const isValid = validate(
			primaryTypeMainBlockId,
			reportType.primaryBlockIds,
			reportType.secondaryBlockIds,
			secondaryTypeMainBlockId,
			reportType.upperTotalBlockId,
			reportType.lowerTotalBlockId,
			reportTypeBlocksByIdMap,
			reportBlocksByIdMap,
			reportFieldsByIdMap,
			reportType.name
		);

		this.setState(() => ({
			primaryTypeBlockIds,
			secondaryTypeBlockIds,
			primaryTypeMainBlockId,
			secondaryTypeMainBlockId,
			upperTotalBlockId: reportType.upperTotalBlockId,
			lowerTotalBlockId: reportType.lowerTotalBlockId,
			reportTypeBlocksByIdMap,
			billableWorks: reportType.billableWorks?.map(BillableWorkFormModel.fromVMToRM) ?? [],
			reportTypeName: reportType.name,
			reportTypeDescription: reportType.description ?? null,
			reportFieldsByIdMap,
			reportBlocksByIdMap,
			calculationFieldMap,
			isReportTypeDataInitialized: true,
			isValid,
		}));
	};

	calculateBillableWorkDropdownOptions = (prevState?: State) => {
		if (prevState && isEqualShallow(this.state, prevState, ReportTypeCustomTypeForm.BILLABLE_WORK_OPTIONS_STATE_RECALCULATION_FIELDS)) {
			return { hasChanged: false } as const;
		}

		const {
			// lowerTotalBlockId,
			primaryTypeBlockIds,
			primaryTypeMainBlockId,
			secondaryTypeBlockIds,
			secondaryTypeMainBlockId,
			// upperTotalBlockId,
			reportTypeBlocksByIdMap,
			reportBlocksByIdMap,
			reportFieldsByIdMap,
			upperTotalBlockId,
			lowerTotalBlockId,
		} = this.state;

		const definitionFieldFieldIds: string[] = [];
		const informationFieldFieldIds: string[] = [];
		const workTypeFieldIds: string[] = [];
		const workQuantityFieldIds: string[] = [];
		const billableWorkFieldsByIdMap: { [id: string]: BillableWorkDropdownOption; } = {};

		type ReportTypeBlockIdSegmentIndex = { reportTypeBlockId: string; segmentIndex: number; };

		let reportTypeBlockIdsAcc: ReportTypeBlockIdSegmentIndex[] = [];

		if (!!upperTotalBlockId) {
			reportTypeBlockIdsAcc.push({ reportTypeBlockId: upperTotalBlockId, segmentIndex: 0 });
		}

		if (!!primaryTypeMainBlockId) {
			reportTypeBlockIdsAcc.push({ reportTypeBlockId: primaryTypeMainBlockId, segmentIndex: 0 });
		}

		if (!!primaryTypeBlockIds?.length) {
			reportTypeBlockIdsAcc = [...reportTypeBlockIdsAcc, ...primaryTypeBlockIds.map((_id) => ({
				reportTypeBlockId: _id,
				segmentIndex: 0,
			}))];
		}

		if (!!secondaryTypeMainBlockId) {
			reportTypeBlockIdsAcc.push({ reportTypeBlockId: secondaryTypeMainBlockId, segmentIndex: 1 });
		}

		if (!!secondaryTypeBlockIds?.length) {
			reportTypeBlockIdsAcc = [...reportTypeBlockIdsAcc, ...secondaryTypeBlockIds.map((_id) => ({
				reportTypeBlockId: _id,
				segmentIndex: 1,
			}))];
		}

		if (!!lowerTotalBlockId) {
			reportTypeBlockIdsAcc.push({ reportTypeBlockId: lowerTotalBlockId, segmentIndex: 1 });
		}

		for (const { reportTypeBlockId, segmentIndex } of reportTypeBlockIdsAcc) {
			const reportTypeBlock = reportTypeBlocksByIdMap[reportTypeBlockId];
			if (!reportTypeBlock) {
				throw new Error('Missing report type block');
			}
			const reportBlock = reportBlocksByIdMap[reportTypeBlock.reportBlockVirtualId];
			if (!reportBlock) {
				throw new Error('Missing report block');
			}

			for (const _fieldId of (reportBlock.reportBlockFieldIds ?? [])) {
				const field = reportFieldsByIdMap[_fieldId];

				const dropdownOption: BillableWorkDropdownOption = {
					virtualId: field.virtualId,
					name: field.name,
					type: field.fieldType,
					unit: field.unit,
					reportTypeBlockVirtualId: reportTypeBlock.virtualId,
					isInPrimarySegment: segmentIndex === 0,
					isTotalBlock: reportTypeBlock.type === ReportTypeBlockType.TOTAL,
					reportBlockName: reportBlock.name,
					operationType: field.operationType,
				};

				if (ReportTypeCustomTypeForm.isValidBillableWorkTypeOption(dropdownOption)) {
					workTypeFieldIds.push(dropdownOption.virtualId);
				}
				if (ReportTypeCustomTypeForm.isValidBillableWorkTypeQuantityOption(dropdownOption)) {
					workQuantityFieldIds.push(dropdownOption.virtualId);
				}
				if (ReportTypeCustomTypeForm.isValidBillableWorkDefinitionOption(dropdownOption)) {
					definitionFieldFieldIds.push(dropdownOption.virtualId);
				}
				informationFieldFieldIds.push(dropdownOption.virtualId);
				billableWorkFieldsByIdMap[dropdownOption.virtualId] = dropdownOption;
			}
		}

		return {
			hasChanged: true,
			data: {
				definitionFieldFieldIds,
				informationFieldFieldIds,
				workTypeFieldIds,
				workQuantityFieldIds,
				billableWorkFieldsByIdMap,
			},
		} as const;
	};

	updateWindowDimensions = () => {
		const updatedDisplayType = getDisplayType(window.innerWidth);
		const { displayType } = this.state;

		if (displayType !== updatedDisplayType) {
			this.setState(() => ({
				displayType: updatedDisplayType,
				showPreviewToggle: updatedDisplayType === DisplayTypeEnum.EXTRA_SMALL,
			}));
		}
	};

	/** Maps report type block Form Models to RMs and adds calculation field options */
	getReportTypeBlockFormModelToRequestModelMapper = (updateIndex: boolean) => {
		const { reportTypeBlocksByIdMap, reportBlocksByIdMap, reportFieldsByIdMap } = this.state;

		return (id: string, index: Nullable<number>): ReportTypeBlockRM => {
			const fieldMapper = ReportTypeCustomTypeForm.getFieldFormModelToRequestModelMapper(reportFieldsByIdMap);
			const typeBlock = reportTypeBlocksByIdMap[id];
			const reportBlock = reportBlocksByIdMap[typeBlock.reportBlockVirtualId];

			return {
				...typeBlock,
				reportTypeId: typeBlock.reportTypeId ?? undefined,
				reportBlockId: typeBlock.reportBlockId,
				index: updateIndex ? index : null,
				reportBlock: {
					...reportBlocksByIdMap[typeBlock.reportBlockVirtualId],
					reportBlockFields: (reportBlock.reportBlockFieldIds ?? []).map(fieldMapper),
				},
			};
		};
	};

	save = () => {
		const { onSubmit } = this.props;
		const {
			billableWorks,
			primaryTypeBlockIds,
			secondaryTypeBlockIds,
			primaryTypeMainBlockId,
			secondaryTypeMainBlockId,
			upperTotalBlockId,
			lowerTotalBlockId,
			reportTypeName,
			reportTypeDescription,
		} = this.state;

		const reportTypeBlocks: ReportTypeBlockRM[] = [];

		// Creates Report Type Block VM to RM mappers
		const reportTypeBlocksRMMapper = this.getReportTypeBlockFormModelToRequestModelMapper(true);
		const mainReportTypeBlocksRMMapper = this.getReportTypeBlockFormModelToRequestModelMapper(false);

		const primaryIds = flattenElements(primaryTypeMainBlockId, primaryTypeBlockIds);
		const secondaryIds = flattenElements(secondaryTypeMainBlockId, secondaryTypeBlockIds);

		reportTypeBlocks.push(...primaryIds.map(reportTypeBlocksRMMapper));
		reportTypeBlocks.push(...secondaryIds.map(reportTypeBlocksRMMapper));

		if (upperTotalBlockId) {
			reportTypeBlocks.push(mainReportTypeBlocksRMMapper(upperTotalBlockId, null));
		}
		if (lowerTotalBlockId) {
			reportTypeBlocks.push(mainReportTypeBlocksRMMapper(lowerTotalBlockId, null));
		}
		onSubmit({ name: reportTypeName, reportTypeBlocks, description: reportTypeDescription, billableWorks });
	};

	onDragStart = (dragData: DragStart) => {
		const { source: { droppableId } } = dragData;
		this.setState(() => ({ draggingElementSource: droppableId as DroppableZones }));
	};

	onDragEnd = ({ draggableId, source, destination }: DropResult) => {
		const {
			primaryTypeBlockIds,
			secondaryTypeBlockIds,
			upperTotalBlockId,
			lowerTotalBlockId,
			reportTypeBlocksByIdMap,
			reportBlocksByIdMap,
			reportFieldsByIdMap,
		} = this.state;
		const { reportBlocksMap } = this.props;
		this.setState(() => ({ draggingElementSource: null }));

		if (!destination) {
			return;
		}
		const { droppableId: sourceDroppableId, index: sourceIndex } = source;
		const { droppableId: destinationDroppableId, index: dropIndex } = destination;

		const isSamePrimaryDroppableZone = sourceDroppableId === DroppableZones.PRIMARY_LIST_ID && destinationDroppableId === DroppableZones.PRIMARY_LIST_ID;
		const isSameSecondaryDropZone = sourceDroppableId === DroppableZones.SECONDARY_LIST_ID && destinationDroppableId === DroppableZones.SECONDARY_LIST_ID;
		const isSameUpperTotalDropZone = sourceDroppableId === DroppableZones.UPPER_TOTAL_BLOCK_LIST_ID
			&& destinationDroppableId === DroppableZones.UPPER_TOTAL_BLOCK_LIST_ID;
		const isSameLowerTotalDropZone = sourceDroppableId === DroppableZones.LOWER_TOTAL_BLOCK_LIST_ID
			&& destinationDroppableId === DroppableZones.LOWER_TOTAL_BLOCK_LIST_ID;
		const isValidDropZone = destinationDroppableId === DroppableZones.PRIMARY_LIST_ID || destinationDroppableId === DroppableZones.SECONDARY_LIST_ID;
		const isSameDroppableIndex = sourceIndex === dropIndex;

		const draggedBlock = reportBlocksMap[+draggableId];

		if (draggedBlock && !!MainBlockZones[destinationDroppableId]) {
			// Adds main primary/secondary report type block using drag
			this.addReportTypeMainBlock(
				destinationDroppableId === DroppableZones.PRIMARY_MAIN_BLOCK_ID,
				draggedBlock
			);
		} else if (sourceDroppableId === DroppableZones.BLOCK_LIST_ID && isValidDropZone && draggedBlock) {
			// Adds any existing primary/secondary report type block using drag
			this.addReportTypeBlock(
				destinationDroppableId === DroppableZones.PRIMARY_LIST_ID,
				draggedBlock,
				+dropIndex
			);
		} else if (sourceDroppableId === DroppableZones.CUSTOM_BLOCK_LIST_ID && isValidDropZone && CustomBlocks.DroppableIdCustomBlock[draggableId]) {
			// Called when dragging Calculated block using drag
			const isPrimary = destinationDroppableId === DroppableZones.PRIMARY_LIST_ID;
			const ids = isPrimary ? primaryTypeBlockIds : secondaryTypeBlockIds;
			const calculatedBlockCount = ReportTypeCustomTypeForm.getCalculatedBlockCount(ids, reportTypeBlocksByIdMap, reportBlocksByIdMap);
			const name = this.getCustomBlockDefaultName(isPrimary, CustomBlocks.DroppableIdCustomBlock[draggableId], calculatedBlockCount);
			const blockContext = { name, isPrimary, virtualId: nanoid(UNIQUE_ID_SIZE) };
			const {
				reportBlock,
			} = ValueConstants.CustomBlockInitialValues[CustomBlocks.DroppableIdCustomBlock[draggableId]](blockContext);
			this.addReportTypeBlock(isPrimary, reportBlock, +dropIndex);
		} else if (!isSameDroppableIndex && (isSamePrimaryDroppableZone || isSameSecondaryDropZone)) {
			// Reorder report type blocks
			const typeBlockId = isSamePrimaryDroppableZone
				? primaryTypeBlockIds[sourceIndex]
				: secondaryTypeBlockIds[sourceIndex];

			const typeBlock = reportTypeBlocksByIdMap[typeBlockId];
			const newIds = [...(typeBlock.isPrimary ? primaryTypeBlockIds : secondaryTypeBlockIds)];

			const originalIndex = newIds.findIndex((_id) => _id === typeBlockId);
			if (originalIndex < 0) {
				throw new Error('Report type block does not exist');
			}

			// Swap elements
			newIds.splice(originalIndex, 1);
			newIds.splice(dropIndex, 0, typeBlockId);

			this.setState(() => ({
				primaryTypeBlockIds: typeBlock.isPrimary ? newIds : primaryTypeBlockIds,
				secondaryTypeBlockIds: !typeBlock.isPrimary ? newIds : secondaryTypeBlockIds,
			}));
		} else if ((isSameUpperTotalDropZone || isSameLowerTotalDropZone) && !isSameDroppableIndex) {
			// Called when reordering fields in Total block
			const isPrimary = destinationDroppableId === DroppableZones.UPPER_TOTAL_BLOCK_LIST_ID;
			const typeBlockId = isPrimary ? upperTotalBlockId : lowerTotalBlockId;
			const typeBlock = typeBlockId
				? reportTypeBlocksByIdMap[typeBlockId]
				: null;
			const block = typeBlock?.reportBlockVirtualId
				? reportBlocksByIdMap[typeBlock?.reportBlockVirtualId]
				: null;
			const fieldId = block?.reportBlockFieldIds?.[sourceIndex];
			const field = fieldId
				? reportFieldsByIdMap[fieldId]
				: null;
			if (!field) {
				throw new Error('Missing field when reordering fields in total block');
			}
			this.reorderFields(field, isPrimary, true, ReportTypeBuilderUtil.getSegment(isPrimary), dropIndex);
		} else if (ReportTypeBuilderUtil.isCalculatedBlockDroppableZone(destinationDroppableId) && destinationDroppableId === sourceDroppableId) {
			// Called when reordering fields in Calculated Block
			const { isPrimary, blockIndex } = ReportTypeBuilderUtil.parseCalculatedBlockDroppableId(destinationDroppableId);
			const typeBlockId = isPrimary ? primaryTypeBlockIds[blockIndex] : secondaryTypeBlockIds[blockIndex];
			const typeBlock = reportTypeBlocksByIdMap[typeBlockId];
			const block = reportBlocksByIdMap[typeBlock.reportBlockVirtualId];
			const fieldId = block?.reportBlockFieldIds?.[sourceIndex];
			const field = fieldId
				? reportFieldsByIdMap[fieldId]
				: null;
			if (!field) {
				throw new Error('Missing field when reordering fields in calculated block');
			}
			this.reorderFields(field, isPrimary, false, blockIndex, dropIndex);
		}
	};

	togglePreview = () => {
		this.setState((state: State) => ({ isPreviewInFocus: !state.isPreviewInFocus }));
	};

	openReportTypeBlockModal = (modalDropZone: DroppableZones) => {
		this.setState(() => ({ showReportTypeBlockModal: true, modalDropZone }));
	};

	closeReportTypeFieldModal = () => {
		this.setState(() => ({ showReportTypeBlockModal: false, modalDropZone: null }));
	};

	openCalculatedFieldModal = (isPrimary: boolean, isTotalBlock: boolean, blockId: Nullable<string>, fieldId: Nullable<string>) => {
		const {
			primaryTypeMainBlockId,
			secondaryTypeMainBlockId,
			primaryTypeBlockIds,
			secondaryTypeBlockIds,
			reportTypeBlocksByIdMap,
			reportBlocksByIdMap,
			reportFieldsByIdMap,
		} = this.state;

		const reportTypeBlockIds = flattenElements(primaryTypeMainBlockId, primaryTypeBlockIds, secondaryTypeMainBlockId, secondaryTypeBlockIds);

		const calculatedFieldModalOptions = ReportTypeCustomTypeForm.createCalculatedFieldDropdownSections(
			reportTypeBlockIds,
			reportTypeBlocksByIdMap,
			reportBlocksByIdMap,
			reportFieldsByIdMap
		);

		this.setState(() => ({
			showCalculatedFieldModal: true,
			calculatedFieldModalOptions,
			fieldModalReportTypeBlockId: blockId,
			fieldModalFieldId: fieldId,
			isFieldModalBlockPrimary: isPrimary,
			isFieldModalTotalBlock: isTotalBlock,
		}));
	};

	closeCalculatedFieldModal = () => {
		this.setState(() => ({
			showCalculatedFieldModal: false,
			fieldModalReportTypeBlockId: null,
			fieldModalFieldId: null,
			isFieldModalBlockPrimary: null,
			isFieldModalTotalBlock: null,
		}));
	};

	openConfirmationModal = () => {
		this.setState(() => ({ showConfirmationModal: true }));
	};

	closeConfirmationModal = () => {
		this.setState(() => ({ showConfirmationModal: false }));
	};

	openEditBlockNameModal = (reportTypeBlockId: string) => {
		this.setState(() => ({
			showBlockNameModal: true,
			blockNameModalReportTypeBlockId: reportTypeBlockId,
		}));
	};

	closeEditBlockNameModal = () => {
		this.setState(() => ({
			showBlockNameModal: false,
			blockNameModalReportTypeBlockId: null,
		}));
	};

	// Adds report type block using report type block modal
	submitReportTypeBlockModal = (form: AddReportTypeBlockForm) => {
		const { reportBlock } = form;
		const { reportBlocksMap, reportBlockFieldsMap } = this.props;
		const {
			modalDropZone,
			primaryTypeBlockIds,
			secondaryTypeBlockIds,
			reportTypeBlocksByIdMap,
			reportBlocksByIdMap,
			reportFieldsByIdMap,
			primaryTypeMainBlockId,
			secondaryTypeMainBlockId,
			calculationFieldMap,
		} = this.state;

		// Check which segment
		const isPrimary = modalDropZone === DroppableZones.PRIMARY_LIST_ID || modalDropZone === DroppableZones.PRIMARY_MAIN_BLOCK_ID;

		// Create new block
		const newReportBlock: ReportBlockFormModel = { ...reportBlock } as ReportBlockFormModel;

		// Create new report type block
		const reportTypeBlock: ReportTypeBlockFormModel = {
			reportBlockId: reportBlock.id ?? null,
			virtualId: nanoid(UNIQUE_ID_SIZE),
			reportBlockVirtualId: newReportBlock.virtualId,
			reportTypeId: null,
			isPrimary,
			type: ReportTypeBlockType.BLOCK,
		};

		// If calculated block
		if (newReportBlock.type === ReportBlockType.CALCULATED) {
			// Get number of calculated blocks in segment, used for initial name of block
			const ids = isPrimary ? primaryTypeBlockIds : secondaryTypeBlockIds;
			const calculatedBlockCount = ReportTypeCustomTypeForm.getCalculatedBlockCount(ids, reportTypeBlocksByIdMap, reportBlocksByIdMap);
			// Initial name of block = Name of main block + index of calculated block
			newReportBlock.name = this.getCustomBlockDefaultName(isPrimary, CustomBlocks.CustomBlock.CALCULATED, calculatedBlockCount);
			newReportBlock.virtualId = nanoid(UNIQUE_ID_SIZE);
			reportTypeBlock.type = ReportTypeBlockType.BLOCK;
			reportTypeBlock.reportBlockVirtualId = newReportBlock.virtualId;

			const newReportTypeBlocksByIdMap = { ...reportTypeBlocksByIdMap };
			const newReportBlocksByIdMap = { ...reportBlocksByIdMap };
			newReportTypeBlocksByIdMap[reportTypeBlock.virtualId] = reportTypeBlock;
			newReportBlocksByIdMap[newReportBlock.virtualId] = newReportBlock;

			// Add id
			const newIds = [...(isPrimary ? primaryTypeBlockIds : secondaryTypeBlockIds)];
			newIds.push(reportTypeBlock.virtualId);

			this.setState(() => ({
				primaryTypeBlockIds: isPrimary ? newIds : primaryTypeBlockIds,
				secondaryTypeBlockIds: !isPrimary ? newIds : secondaryTypeBlockIds,
				reportTypeBlocksByIdMap: newReportTypeBlocksByIdMap,
				reportBlocksByIdMap: newReportBlocksByIdMap,
			}));
		} else if (modalDropZone === DroppableZones.PRIMARY_LIST_ID || modalDropZone === DroppableZones.SECONDARY_LIST_ID) {
			// If block in segment, not main

			// Add id
			const newIds = [...(isPrimary ? primaryTypeBlockIds : secondaryTypeBlockIds)];
			newIds.push(reportTypeBlock.virtualId);

			const newReportTypeBlocksByIdMap = { ...reportTypeBlocksByIdMap };
			const newReportBlocksByIdMap = { ...reportBlocksByIdMap };
			const newReportFieldsByIdMap = { ...reportFieldsByIdMap };
			const newCalculationFieldMap = { ...calculationFieldMap };

			newReportTypeBlocksByIdMap[reportTypeBlock.virtualId] = reportTypeBlock;

			if (!reportBlock.id) {
				throw new Error('Missing report block id');
			}
			const selectedBlock = reportBlocksMap[reportBlock.id];

			const oldToVirtualIdMap = selectedBlock.reportBlockFieldIds.reduce((_acc, _fieldId) => {
				_acc[_fieldId] = nanoid(UNIQUE_ID_SIZE);
				return _acc;
			}, {} as { [id: number]: string; });

			const fieldVirtualIds: string[] = [];
			for (const _fieldId of (selectedBlock.reportBlockFieldIds ?? [])) {
				const field = reportBlockFieldsMap[_fieldId];
				const virtualId = oldToVirtualIdMap[_fieldId];
				if (field.fieldType === ReportBlockFieldEnum.Type.CALCULATED) {
					newCalculationFieldMap[virtualId] = true;
				}
				fieldVirtualIds.push(virtualId);
				newReportFieldsByIdMap[virtualId] = ReportBlockFieldFormModel.fromReportBlockMapVM(field, reportBlock.virtualId, oldToVirtualIdMap);
			}

			newReportBlock.reportBlockFieldIds = fieldVirtualIds;
			if (selectedBlock.completionReportBlockFieldId) {
				newReportBlock.completionFieldVirtualId = oldToVirtualIdMap[selectedBlock.completionReportBlockFieldId];
			}
			newReportBlocksByIdMap[newReportBlock.virtualId] = newReportBlock;

			this.setState(() => ({
				primaryTypeBlockIds: isPrimary ? newIds : primaryTypeBlockIds,
				secondaryTypeBlockIds: !isPrimary ? newIds : secondaryTypeBlockIds,
				reportTypeBlocksByIdMap: newReportTypeBlocksByIdMap,
				reportBlocksByIdMap: newReportBlocksByIdMap,
				reportFieldsByIdMap: newReportFieldsByIdMap,
				calculationFieldMap: newCalculationFieldMap,
			}));
		} else if (modalDropZone && !!MainBlockZones[modalDropZone]) {

			const primaryTypeMainBlock = primaryTypeMainBlockId
				? reportTypeBlocksByIdMap[primaryTypeMainBlockId]
				: null;
			const secondaryTypeMainBlock = secondaryTypeMainBlockId
				? reportTypeBlocksByIdMap[secondaryTypeMainBlockId]
				: null;

			if (!reportBlock.id) {
				throw new Error('Missing report block id');
			}

			if (reportBlock.id === primaryTypeMainBlock?.reportBlockId || reportBlock.id === secondaryTypeMainBlock?.reportBlockId) {
				return;
			}
			// Add main block
			const newReportTypeBlocksByIdMap = { ...reportTypeBlocksByIdMap };
			const newReportBlocksByIdMap = { ...reportBlocksByIdMap };
			const newCalculationFieldMap = { ...calculationFieldMap };

			newReportTypeBlocksByIdMap[reportTypeBlock.virtualId] = reportTypeBlock;

			const selectedBlock = reportBlocksMap[reportBlock.id];

			const oldToVirtualIdMap = selectedBlock.reportBlockFieldIds.reduce((_acc, _fieldId) => {
				_acc[_fieldId] = nanoid(UNIQUE_ID_SIZE);
				return _acc;
			}, {} as { [id: number]: string; });

			const newReportFieldsByIdMap = { ...reportFieldsByIdMap };
			const fieldVirtualIds: string[] = [];
			for (const _fieldId of (selectedBlock.reportBlockFieldIds ?? [])) {
				const field = reportBlockFieldsMap[_fieldId];
				const virtualId = oldToVirtualIdMap[_fieldId];
				if (field.fieldType === ReportBlockFieldEnum.Type.CALCULATED) {
					newCalculationFieldMap[virtualId] = true;
				}
				fieldVirtualIds.push(virtualId);
				newReportFieldsByIdMap[virtualId] = ReportBlockFieldFormModel.fromReportBlockMapVM(field, reportBlock.virtualId, oldToVirtualIdMap);
			}

			newReportBlock.reportBlockFieldIds = fieldVirtualIds;
			if (selectedBlock.completionReportBlockFieldId) {
				newReportBlock.completionFieldVirtualId = oldToVirtualIdMap[selectedBlock.completionReportBlockFieldId];
			}
			newReportBlocksByIdMap[newReportBlock.virtualId] = newReportBlock;

			this.setState(() => ({
				primaryTypeMainBlockId: modalDropZone === DroppableZones.PRIMARY_MAIN_BLOCK_ID ? reportTypeBlock.virtualId : primaryTypeMainBlockId,
				secondaryTypeMainBlockId: modalDropZone === DroppableZones.SECONDARY_MAIN_BLOCK_ID ? reportTypeBlock.virtualId : secondaryTypeMainBlockId,
				reportTypeBlocksByIdMap: newReportTypeBlocksByIdMap,
				reportBlocksByIdMap: newReportBlocksByIdMap,
				reportFieldsByIdMap: newReportFieldsByIdMap,
				calculationFieldMap: newCalculationFieldMap,
			}));
		}
	};

	addReportTypeBlock = (isPrimary: boolean, draggedBlock: ReportBlockVM, dropIndex: number) => {
		const { reportBlockFieldsMap } = this.props;
		const {
			primaryTypeBlockIds,
			secondaryTypeBlockIds,
			reportTypeBlocksByIdMap,
			reportBlocksByIdMap,
			reportFieldsByIdMap,
			calculationFieldMap,
		} = this.state;

		// Checks whether block already exists in report type segment
		const blockIds = isPrimary ? primaryTypeBlockIds : secondaryTypeBlockIds;
		const doesExist = blockIds.some((_id) => {
			const _block = reportTypeBlocksByIdMap[_id];
			return _block.reportBlockId && _block.reportBlockId === draggedBlock.id;
		});

		if (doesExist) {
			return;
		}

		const reportBlock = ReportBlockFormModel.fromListViewModel(draggedBlock);

		const reportTypeBlock = {
			reportBlockId: draggedBlock.id,
			reportBlockVirtualId: reportBlock.virtualId,
			reportTypeId: null,
			virtualId: nanoid(UNIQUE_ID_SIZE),
			isPrimary,
			type: ReportTypeBlockType.BLOCK,
		};

		// Add to report type blocks map
		const newReportTypeBlocksByIdMap = { ...reportTypeBlocksByIdMap };
		newReportTypeBlocksByIdMap[reportTypeBlock.virtualId] = reportTypeBlock;

		// Add fields
		const oldToVirtualIdMap = draggedBlock.reportBlockFieldIds.reduce((_acc, _fieldId) => {
			_acc[_fieldId] = nanoid(UNIQUE_ID_SIZE);
			return _acc;
		}, {} as { [id: number]: string; });

		const newReportFieldsByIdMap = { ...reportFieldsByIdMap };
		const fieldVirtualIds: string[] = [];
		const newLookups: { [id: string]: true; } = {};
		for (const _fieldId of (draggedBlock.reportBlockFieldIds ?? [])) {
			const field = reportBlockFieldsMap[_fieldId];
			const virtualId = oldToVirtualIdMap[_fieldId];
			if (field.fieldType === ReportBlockFieldEnum.Type.CALCULATED) {
				newLookups[virtualId] = true;
			}
			fieldVirtualIds.push(virtualId);
			newReportFieldsByIdMap[virtualId] = ReportBlockFieldFormModel.fromReportBlockMapVM(field, reportBlock.virtualId, oldToVirtualIdMap);
		}

		// Add to report blocks map
		const newReportBlocksByIdMap = { ...reportBlocksByIdMap };
		reportBlock.reportBlockFieldIds = fieldVirtualIds;
		if (draggedBlock.completionReportBlockFieldId) {
			reportBlock.completionFieldVirtualId = oldToVirtualIdMap[draggedBlock.completionReportBlockFieldId];
		}
		newReportBlocksByIdMap[reportBlock.virtualId] = reportBlock;

		// Add id
		const newIds = [...(isPrimary ? primaryTypeBlockIds : secondaryTypeBlockIds)];
		newIds.splice(dropIndex, 0, reportTypeBlock.virtualId);

		this.setState(() => ({
			primaryTypeBlockIds: isPrimary ? newIds : primaryTypeBlockIds,
			secondaryTypeBlockIds: !isPrimary ? newIds : secondaryTypeBlockIds,
			calculationFieldMap: newLookups ? { ...calculationFieldMap, ...newLookups } : calculationFieldMap,
			reportTypeBlocksByIdMap: newReportTypeBlocksByIdMap,
			reportBlocksByIdMap: newReportBlocksByIdMap,
			reportFieldsByIdMap: newReportFieldsByIdMap,
		}));
	};

	addReportTypeMainBlock = (isPrimary: boolean, draggedBlock: ReportBlockVM) => {
		const {
			primaryTypeMainBlockId,
			secondaryTypeMainBlockId,
			calculationFieldMap,
			reportTypeBlocksByIdMap,
			reportBlocksByIdMap,
			reportFieldsByIdMap,
		} = this.state;

		const primaryTypeMainBlock = primaryTypeMainBlockId
			? reportTypeBlocksByIdMap[primaryTypeMainBlockId]
			: null;
		const secondaryTypeMainBlock = secondaryTypeMainBlockId
			? reportTypeBlocksByIdMap[secondaryTypeMainBlockId]
			: null;

		if (draggedBlock.id === primaryTypeMainBlock?.reportBlockId || draggedBlock.id === secondaryTypeMainBlock?.reportBlockId) {
			return;
		}

		const { reportBlockFieldsMap } = this.props;

		// Get block form model
		const reportBlock = ReportBlockFormModel.fromListViewModel(draggedBlock);

		const mainBlock: ReportTypeBlockFormModel = {
			reportBlockId: reportBlock.id ?? null,
			virtualId: nanoid(UNIQUE_ID_SIZE),
			reportBlockVirtualId: reportBlock.virtualId,
			reportTypeId: null,
			isPrimary,
			type: ReportTypeBlockType.BLOCK,
		};

		// Add report type block
		const newReportTypeBlocksByIdMap = { ...reportTypeBlocksByIdMap };
		newReportTypeBlocksByIdMap[mainBlock.virtualId] = mainBlock;

		// Add fields
		const oldToVirtualIdMap = draggedBlock.reportBlockFieldIds.reduce((_acc, _fieldId) => {
			_acc[_fieldId] = nanoid(UNIQUE_ID_SIZE);
			return _acc;
		}, {} as { [id: number]: string; });

		const newReportFieldsByIdMap = { ...reportFieldsByIdMap };
		const fieldVirtualIds: string[] = [];
		const newLookups: { [id: string]: true; } = {};
		for (const _fieldId of (draggedBlock.reportBlockFieldIds ?? [])) {
			const field = reportBlockFieldsMap[_fieldId];
			const virtualId = oldToVirtualIdMap[_fieldId];
			if (field.fieldType === ReportBlockFieldEnum.Type.CALCULATED) {
				newLookups[virtualId] = true;
			}
			fieldVirtualIds.push(virtualId);
			newReportFieldsByIdMap[virtualId] = ReportBlockFieldFormModel.fromReportBlockMapVM(field, reportBlock.virtualId, oldToVirtualIdMap);
		}

		reportBlock.reportBlockFieldIds = fieldVirtualIds;
		if (draggedBlock.completionReportBlockFieldId) {
			reportBlock.completionFieldVirtualId = oldToVirtualIdMap[draggedBlock.completionReportBlockFieldId];
		}

		// Add report block
		const newReportBlocksByIdMap = { ...reportBlocksByIdMap };
		newReportBlocksByIdMap[reportBlock.virtualId] = reportBlock;

		this.setState(() => ({
			primaryTypeMainBlockId: isPrimary ? mainBlock.virtualId : (primaryTypeMainBlock?.virtualId ?? null),
			secondaryTypeMainBlockId: !isPrimary ? mainBlock.virtualId : (secondaryTypeMainBlock?.virtualId ?? null),
			calculationFieldMap: newLookups ? { ...calculationFieldMap, ...newLookups } : calculationFieldMap,
			reportTypeBlocksByIdMap: newReportTypeBlocksByIdMap,
			reportBlocksByIdMap: newReportBlocksByIdMap,
			reportFieldsByIdMap: newReportFieldsByIdMap,
		}));
	};

	removeReportTypeBlock = (id: string, isPrimary: boolean) => {
		const {
			billableWorks,
			upperTotalBlockId,
			lowerTotalBlockId,
			primaryTypeMainBlockId,
			secondaryTypeMainBlockId,
			primaryTypeBlockIds,
			secondaryTypeBlockIds,
			calculationFieldMap,
			reportTypeBlocksByIdMap,
			reportBlocksByIdMap,
			reportFieldsByIdMap,
		} = this.state;

		// Create new array of report type blocks
		const ids = isPrimary ? [...primaryTypeBlockIds] : [...secondaryTypeBlockIds];
		// Find report type block in array
		const index = ids.findIndex((_id) => _id === id);
		if (index < 0) {
			throw new Error('Report type block is missing');
		}

		// Remove Block from new array and return removed block id
		const reportTypeBlockId = ids.splice(index, 1);
		const reportBlockId = reportTypeBlocksByIdMap[reportTypeBlockId[0]].reportBlockVirtualId;
		const block = reportBlocksByIdMap[reportBlockId];
		const fieldIds = block.reportBlockFieldIds ?? [];

		// Remove report type block
		const newReportTypeBlocksByIdMap = omitObject(reportTypeBlocksByIdMap, reportTypeBlockId);
		// Remove report block
		const newReportBlockByIdMap = omitObject(reportBlocksByIdMap, [reportBlockId]);
		// Remove fields
		const newReportFieldsByIdMap = omitObject(reportFieldsByIdMap, fieldIds);
		// Remove calculated fields
		const newCalculationFieldMap = calculationFieldMap
			? omitObject(calculationFieldMap, fieldIds)
			: {};

		// Creates a map of fields in deleted block
		const fieldsForDelete = createExistsMap(fieldIds, (fieldId: string) => fieldId);

		const blockIds = flattenElements(upperTotalBlockId,
			lowerTotalBlockId,
			primaryTypeMainBlockId,
			secondaryTypeMainBlockId,
			isPrimary ? ids : primaryTypeBlockIds,
			!isPrimary ? ids : secondaryTypeBlockIds
		);
		// Passes blocks and deleted fields to update other references of block
		this.setState(() => {
			return {
				billableWorks: ReportTypeCustomTypeForm.filterBillableWorksWithFieldsForDelete(billableWorks, fieldsForDelete),
				...ReportTypeBuilderUtil.removeOptions(
					fieldsForDelete,
					blockIds,
					newReportTypeBlocksByIdMap,
					newReportBlockByIdMap,
					newReportFieldsByIdMap,
					newCalculationFieldMap
				),
			};
		});
	};

	removeReportTypeMainBlock = (isPrimary: boolean) => {
		const {
			billableWorks,
			primaryTypeMainBlockId,
			secondaryTypeMainBlockId,
			upperTotalBlockId,
			lowerTotalBlockId,
			primaryTypeBlockIds,
			secondaryTypeBlockIds,
			reportTypeBlocksByIdMap,
			reportBlocksByIdMap,
			reportFieldsByIdMap,
			calculationFieldMap,
		} = this.state;

		const reportTypeBlockId = isPrimary ? primaryTypeMainBlockId : secondaryTypeMainBlockId;
		if (!reportTypeBlockId) {
			throw new Error('Missing report type block id');
		}
		const reportTypeBlock = reportTypeBlocksByIdMap[reportTypeBlockId];
		const reportBlock = reportBlocksByIdMap[reportTypeBlock.reportBlockVirtualId];
		const fieldIds = (reportBlock?.reportBlockFieldIds ?? []);

		// Remove report type blocks
		const newReportTypeBlocksByIdMap = omitObject(reportTypeBlocksByIdMap, [reportTypeBlockId]);
		// Remove report blocks
		const newReportBlockByIdMap = omitObject(reportBlocksByIdMap, [reportTypeBlock.reportBlockVirtualId]);
		// Remove fields
		const newReportFieldsByIdMap = omitObject(reportFieldsByIdMap, fieldIds);
		// Remove calculated fields
		const newCalculationFieldMap = calculationFieldMap
			? omitObject(calculationFieldMap, fieldIds)
			: {};

		// Creates a map of fields in deleted block
		const fieldsForDelete = createExistsMap(fieldIds, (id: string) => id);

		const blockIds = flattenElements(upperTotalBlockId,
			lowerTotalBlockId,
			isPrimary ? secondaryTypeMainBlockId : primaryTypeMainBlockId,
			primaryTypeBlockIds,
			secondaryTypeBlockIds
		);
		// Passes blocks and deleted fields to update other references of block
		this.setState(
			() => ({
				primaryTypeMainBlockId: isPrimary ? null : primaryTypeMainBlockId,
				secondaryTypeMainBlockId: !isPrimary ? null : secondaryTypeMainBlockId,
				billableWorks: ReportTypeCustomTypeForm.filterBillableWorksWithFieldsForDelete(billableWorks, fieldsForDelete),
				...ReportTypeBuilderUtil.removeOptions(
					fieldsForDelete,
					blockIds,
					newReportTypeBlocksByIdMap,
					newReportBlockByIdMap,
					newReportFieldsByIdMap,
					newCalculationFieldMap
				),
			})
		);
	};

	showRemoveReportTypeMainBlockModal = (isPrimary: boolean) => {
		const { billableWorks, primaryTypeMainBlockId, secondaryTypeMainBlockId, reportTypeBlocksByIdMap, reportBlocksByIdMap } = this.state;

		const reportTypeBlockId = isPrimary ? primaryTypeMainBlockId : secondaryTypeMainBlockId;
		if (!reportTypeBlockId) {
			throw new Error('Missing report type block id');
		}
		const reportTypeBlock = reportTypeBlocksByIdMap[reportTypeBlockId];
		const reportBlock = reportBlocksByIdMap[reportTypeBlock.reportBlockVirtualId];
		const fieldIds = (reportBlock?.reportBlockFieldIds ?? []);

		// Creates a map of fields in deleted block
		const fieldsForDelete = createExistsMap(fieldIds, (id: string) => id);

		const billableWorksForDelete = ReportTypeCustomTypeForm.resolveBillableWorksWhichWillBeDeleted(billableWorks, fieldsForDelete);

		this.setState(() => ({
			removeReportTypeMainBlockConfirmationModalMeta: { isPrimary, billableWorksForDelete },
			showRemoveReportTypeMainBlockConfirmationModal: true,
		}));
	};

	closeRemoveReportTypeMainBlockModal = () => {
		this.setState(() => ({
			removeReportTypeMainBlockConfirmationModalMeta: null,
			showRemoveReportTypeMainBlockConfirmationModal: false,
		}));
	};

	confirmRemoveReportTypeMainBlock = () => {
		const { removeReportTypeMainBlockConfirmationModalMeta } = this.state;
		const { isPrimary } = removeReportTypeMainBlockConfirmationModalMeta!;
		this.removeReportTypeMainBlock(isPrimary);
	};

	showRemoveReportTypeBlockModal = (reportTypeBlockId: string, isPrimary: boolean) => {
		const {
			billableWorks,
			primaryTypeBlockIds,
			secondaryTypeBlockIds,
			reportTypeBlocksByIdMap,
			reportBlocksByIdMap,
		} = this.state;

		// Create new array of blocks
		const typeBlockIds = isPrimary ? [...primaryTypeBlockIds] : [...secondaryTypeBlockIds];
		const blockIndex = typeBlockIds.findIndex((_id) => _id === reportTypeBlockId);
		if (blockIndex < 0) {
			throw new Error('Could not find block');
		}

		// Remove Block from new array and return removed block
		const typeBlockId = typeBlockIds.splice(blockIndex, 1);
		const reportTypeBlock = typeBlockId[0]
			? reportTypeBlocksByIdMap[typeBlockId[0]]
			: null;
		const block = reportTypeBlock?.reportBlockVirtualId
			? reportBlocksByIdMap[reportTypeBlock?.reportBlockVirtualId]
			: null;
		const fieldIds = (block?.reportBlockFieldIds ?? []);

		// Creates a map of fields in deleted block
		const fieldsForDelete = createExistsMap(fieldIds, (id: string) => id);

		const billableWorksForDelete = ReportTypeCustomTypeForm.resolveBillableWorksWhichWillBeDeleted(billableWorks, fieldsForDelete);

		this.setState(() => ({
			removeReportTypeBlockConfirmationModalMeta: { id: reportTypeBlockId, isPrimary, billableWorksForDelete },
			showRemoveReportTypeBlockConfirmationModal: true,
		}));
	};

	closeRemoveReportTypeBlockModal = () => {
		this.setState(() => ({
			removeReportTypeBlockConfirmationModalMeta: null,
			showRemoveReportTypeBlockConfirmationModal: false,
		}));
	};

	confirmRemoveReportTypeBlock = () => {
		const { removeReportTypeBlockConfirmationModalMeta } = this.state;
		const { id, isPrimary } = removeReportTypeBlockConfirmationModalMeta!;
		this.removeReportTypeBlock(id, isPrimary);
	};

	getRemoveBlockConfirmationModalText = () => {
		const { removeReportTypeBlockConfirmationModalMeta, removeReportTypeMainBlockConfirmationModalMeta } = this.state;

		let text = 'Deleting this block will also delete the following Billable Work entries: ';
		const billableWorkList = (
			removeReportTypeBlockConfirmationModalMeta?.billableWorksForDelete
			?? removeReportTypeMainBlockConfirmationModalMeta?.billableWorksForDelete
			?? []
		);

		text += `${billableWorkList.map((_bw) => _bw.workName).join(', ')}.`;

		return (
			<>
				<div>{text}</div>
				<div>Are you sure you want to proceed?</div>
			</>
		);
	};

	fetchModalOptions = () => {
		const { modalDropZone, primaryTypeBlockIds, secondaryTypeBlockIds, reportTypeBlocksByIdMap } = this.state;
		const { reportBlocksMap } = this.props;

		if (modalDropZone === DroppableZones.PRIMARY_MAIN_BLOCK_ID || modalDropZone === DroppableZones.SECONDARY_MAIN_BLOCK_ID) {
			return filterMap(
				Object.values(reportBlocksMap),
				(_block) => _block.isMain,
				(_block) => ReportBlockFormModel.fromListViewModel(_block)
			);
		}

		const blockIds = modalDropZone === DroppableZones.PRIMARY_LIST_ID ? primaryTypeBlockIds : secondaryTypeBlockIds;
		const assignedBlocks = blockIds.reduce((_acc, _reportTypeBlockId) => {
			const _reportTypeBlock = reportTypeBlocksByIdMap[_reportTypeBlockId];
			if (_reportTypeBlock.reportBlockId) {
				_acc[_reportTypeBlock.reportBlockId] = true;
			}
			return _acc;
		}, {});

		const resultBlocks = filterMap(
			Object.values(reportBlocksMap),
			(_block) => !_block.isMain && !assignedBlocks[_block.id],
			(_block) => ReportBlockFormModel.fromListViewModel(_block)
		);
		// Adding Calculated block to list, so they can be added through Report Type Block Modal
		// Added nanoid as id because dropdown uses id as valueKey
		// Calculated Blocks imaginary blocks until submitted and don't have ids
		const customBlocks = Object.values(CustomBlocks.CustomBlock).map((_block) => {
			const { reportBlock } = ValueConstants.CustomBlockInitialValues[_block](
				{ name: CustomBlocks.CustomBlockLabel[_block], virtualId: nanoid(UNIQUE_ID_SIZE), uniqueId: nanoid(UNIQUE_ID_SIZE) }
			);
			return reportBlock;
		});
		return customBlocks.concat(resultBlocks);
	};

	rename = (name: string, description: Nullable<string>) => this.setState(() => ({ reportTypeName: name, reportTypeDescription: description }));

	/**
	 * Removes fields from calculated blocks or total blocks
	 */
	removeField = (reportTypeBlockId: string, fieldId: string) => {
		const {
			upperTotalBlockId,
			lowerTotalBlockId,
			primaryTypeBlockIds,
			secondaryTypeBlockIds,
			calculationFieldMap,
			reportTypeBlocksByIdMap,
			reportBlocksByIdMap,
			reportFieldsByIdMap,
			primaryTypeMainBlockId,
			secondaryTypeMainBlockId,
		} = this.state;

		const reportTypeBlock = reportTypeBlocksByIdMap[reportTypeBlockId];
		if (!reportTypeBlock) {
			throw new Error('Report type block not found');
		}
		const reportBlock = reportBlocksByIdMap[reportTypeBlock.reportBlockVirtualId];
		if (!reportBlock) {
			throw new Error('Report block not found');
		}
		// Remove field from block field id array
		const newReportBlock = {
			...reportBlock,
			reportBlockFieldIds: reportBlock.reportBlockFieldIds?.filter((_id) => _id !== fieldId) ?? null,
		};
		// Update block in block map
		const newReportBlocksByIdMap = {
			...reportBlocksByIdMap,
			[reportTypeBlock.reportBlockVirtualId]: newReportBlock,
		};

		const field = reportFieldsByIdMap[fieldId];
		if (!field) {
			throw new Error('Report block field not found');
		}

		// Remove field
		const newReportFieldsByIdMap = omitObject(reportFieldsByIdMap, [fieldId]);

		const blockIds = flattenElements(upperTotalBlockId,
			lowerTotalBlockId,
			primaryTypeMainBlockId,
			secondaryTypeMainBlockId,
			primaryTypeBlockIds,
			secondaryTypeBlockIds
		);
		const newCalculationFieldMap = calculationFieldMap
			? omitObject(calculationFieldMap, [fieldId])
			: {};

		// Passes blocks and deleted field id to update other references of field
		this.setState(() => ({
			...ReportTypeBuilderUtil.removeOptions(
				{ [fieldId]: true } as const,
				blockIds,
				reportTypeBlocksByIdMap,
				newReportBlocksByIdMap,
				newReportFieldsByIdMap,
				newCalculationFieldMap
			),
		}));
	};

	/**
	 * Reorders fields in calculated blocks or total block
	 */
	reorderFields = (field: ReportBlockFieldFormModel, isPrimary: boolean, isTotalBlock: boolean, blockIndex: number, fieldIndex: number) => {
		const {
			upperTotalBlockId,
			lowerTotalBlockId,
			primaryTypeBlockIds,
			reportTypeBlocksByIdMap,
			secondaryTypeBlockIds,
			reportBlocksByIdMap,
		} = this.state;

		let reportTypeBlockId: Nullable<string> = null;
		if (isTotalBlock) {
			if (isPrimary && upperTotalBlockId) {
				reportTypeBlockId = upperTotalBlockId;
			} else if (lowerTotalBlockId) {
				reportTypeBlockId = lowerTotalBlockId;
			}
		} else {
			if (isPrimary) {
				reportTypeBlockId = primaryTypeBlockIds[blockIndex];
			} else {
				reportTypeBlockId = secondaryTypeBlockIds[blockIndex];
			}
		}
		if (!reportTypeBlockId) {
			throw new Error('Missing report type block id');
		}
		const reportTypeBlock = reportTypeBlocksByIdMap[reportTypeBlockId];
		const reportBlock = reportBlocksByIdMap[reportTypeBlock.reportBlockVirtualId];

		const originalIndex = (reportBlock.reportBlockFieldIds ?? []).findIndex((_id) => _id === field.virtualId);
		if (originalIndex < 0) {
			throw Error('Field does not exist in block');
		}

		const newFieldIds = [...reportBlock.reportBlockFieldIds ?? []];

		// Swap elements
		newFieldIds.splice(originalIndex, 1);
		newFieldIds.splice(fieldIndex, 0, field.virtualId);

		// Update field array
		const newBlock = {
			...reportBlock,
			reportBlockFieldIds: newFieldIds,
		};

		// Update block in map
		const newReportBlocksByIdMap = { ...reportBlocksByIdMap };
		newReportBlocksByIdMap[newBlock.virtualId] = newBlock;

		this.setState(() => ({
			reportBlocksByIdMap: newReportBlocksByIdMap,
		}));
	};

	/**
	 * Creates or updates calculated field
	 */
	upsertField = (form: ReportBlockFieldFormModel, isPrimary: boolean, isTotalBlock: boolean, reportTypeBlockId: Nullable<string>) => {
		const {
			calculationFieldMap,
			lowerTotalBlockId,
			primaryTypeBlockIds,
			secondaryTypeBlockIds,
			upperTotalBlockId,
			reportTypeBlocksByIdMap,
			reportBlocksByIdMap,
			reportFieldsByIdMap,
			primaryTypeMainBlockId,
			secondaryTypeMainBlockId,
		} = this.state;

		// Fetch old field
		const oldField = reportFieldsByIdMap[form.virtualId];

		const newReportFieldsByIdMap = { ...reportFieldsByIdMap };

		const newCalculationFieldMap = { ...calculationFieldMap };

		let newUpperTotalBlockId = upperTotalBlockId;
		let newLowerTotalBlockId = lowerTotalBlockId;

		let reportBlockVirtualId: Nullable<string> = null;

		// If field in total block
		if (isTotalBlock) {
			const blockId = isPrimary ? upperTotalBlockId : lowerTotalBlockId;
			if (!blockId) {
				// Create new total block
				const totalReportBlock = ValueConstants.InitialTotalBlockReportBlockValues(nanoid(UNIQUE_ID_SIZE));
				const totalReportTypeBlock = ValueConstants.INITIAL_TOTAL_BLOCK_VALUES[isPrimary ? TotalBlockType.UPPER : TotalBlockType.LOWER](
					nanoid(UNIQUE_ID_SIZE),
					totalReportBlock.virtualId
				);

				if (isPrimary) {
					newUpperTotalBlockId = totalReportTypeBlock.virtualId;
				} else {
					newLowerTotalBlockId = totalReportTypeBlock.virtualId;
				}

				totalReportBlock.reportBlockFieldIds = [form.virtualId];
				reportTypeBlocksByIdMap[totalReportTypeBlock.virtualId] = totalReportTypeBlock;
				reportBlocksByIdMap[totalReportBlock.virtualId] = totalReportBlock;

				reportBlockVirtualId = totalReportBlock.virtualId;
			} else {
				// Add field to existing total block
				const totalReportTypeBlock = reportTypeBlocksByIdMap[blockId];
				const totalReportBlock = reportBlocksByIdMap[totalReportTypeBlock.reportBlockVirtualId];

				if (!oldField) {
					totalReportBlock.reportBlockFieldIds = [...(totalReportBlock.reportBlockFieldIds ?? []), form.virtualId];
					reportBlocksByIdMap[totalReportBlock.virtualId] = totalReportBlock;
				}

				reportBlockVirtualId = totalReportBlock.virtualId;
			}
		} else {
			// If field in report type
			if (!reportTypeBlockId) {
				throw new Error('Missing block index');
			}

			const reportTypeBlock = reportTypeBlocksByIdMap[reportTypeBlockId];
			const block = reportBlocksByIdMap[reportTypeBlock.reportBlockVirtualId];

			if (!oldField) {
				block.reportBlockFieldIds = [...(block.reportBlockFieldIds ?? []), form.virtualId];
				reportBlocksByIdMap[block.virtualId] = block;
			}

			reportBlockVirtualId = block.virtualId;
		}

		if (!reportBlockVirtualId) {
			throw new Error('Missing report block virtual id');
		}
		form.reportBlockVirtualId = reportBlockVirtualId;

		newReportFieldsByIdMap[form.virtualId] = form;

		newCalculationFieldMap[form.virtualId] = true;

		const blockIds = flattenElements(newUpperTotalBlockId,
			newLowerTotalBlockId,
			primaryTypeMainBlockId,
			secondaryTypeMainBlockId,
			primaryTypeBlockIds,
			secondaryTypeBlockIds
		);

		this.setState(() => ({
			...ReportTypeBuilderUtil.updateOptions(
				oldField,
				form,
				blockIds,
				reportTypeBlocksByIdMap,
				reportBlocksByIdMap,
				newReportFieldsByIdMap,
				newCalculationFieldMap
			),
		}));
	};

	/**
	 * Renames calculated block or total block
	 */
	editBlockName = (name: string, reportTypeBlockId: string) => {
		const { reportTypeBlocksByIdMap, reportBlocksByIdMap } = this.state;

		const reportTypeBlock = reportTypeBlocksByIdMap[reportTypeBlockId];
		const reportBlock = reportBlocksByIdMap[reportTypeBlock.reportBlockVirtualId];

		const newReportBlocksByIdMap = { ...reportBlocksByIdMap };

		newReportBlocksByIdMap[reportTypeBlock.reportBlockVirtualId] = ReportTypeBuilderUtil.changeBlockName(reportBlock, name);

		this.setState(() => ({ reportBlocksByIdMap: newReportBlocksByIdMap }));
	};

	/**
	 * Returns initial state of field for Calculated field modal
	 */
	getCalculatedFieldInitialState = (): Nullable<Partial<ReportBlockFieldFormModel>> => {
		const {
			showCalculatedFieldModal,
			fieldModalReportTypeBlockId,
			fieldModalFieldId,
			reportFieldsByIdMap,
		} = this.state;

		if (!showCalculatedFieldModal) {
			return null;
		}

		if (fieldModalReportTypeBlockId && fieldModalFieldId) {
			const field = reportFieldsByIdMap[fieldModalFieldId];
			return field;
		}
		return ValueConstants.PREDEFINED_INITIAL_VALUES[ReportBlockFieldEnum.Type.CALCULATED];
	};

	/**
	 * Returns current block name for edit block name modal
	 */
	getBlockNameModalInitialState = () => {
		const {
			blockNameModalReportTypeBlockId,
			reportTypeBlocksByIdMap,
			reportBlocksByIdMap,
		} = this.state;

		if (!blockNameModalReportTypeBlockId) {
			throw new Error('Missing block name id');
		}

		const reportTypeBlock = reportTypeBlocksByIdMap[blockNameModalReportTypeBlockId];
		const reportBlock = reportBlocksByIdMap[reportTypeBlock.reportBlockVirtualId];
		const name = reportBlock?.name ?? '';
		return { name };
	};

	/**
	 * Creates default name for custom blocks
	 * In case of calculated blocks Main block name + index
	 * */
	getCustomBlockDefaultName = (isPrimary: boolean, type: CustomBlocks.CustomBlock, index: number) => {
		const { primaryTypeMainBlockId, secondaryTypeMainBlockId, reportTypeBlocksByIdMap, reportBlocksByIdMap } = this.state;

		if (type === CustomBlocks.CustomBlock.CALCULATED) {
			let calculatedBlockPrefix = '';
			if (isPrimary && primaryTypeMainBlockId) {
				const reportTypeBlock = reportTypeBlocksByIdMap[primaryTypeMainBlockId];
				const reportBlock = reportBlocksByIdMap[reportTypeBlock.reportBlockVirtualId];
				calculatedBlockPrefix = reportBlock?.name ?? '';
			} else if (secondaryTypeMainBlockId) {
				const reportTypeBlock = reportTypeBlocksByIdMap[secondaryTypeMainBlockId];
				const reportBlock = reportBlocksByIdMap[reportTypeBlock.reportBlockVirtualId];
				calculatedBlockPrefix = reportBlock?.name ?? '';
			}
			return calculatedBlockPrefix ? `${calculatedBlockPrefix} Calculation${index > 0 ? ` ${index + 1}` : ''}` : `Calculation${index > 0 ? ` ${index + 1}` : ''}`;
		}
		return CustomBlocks.CustomBlockLabel[type];
	};

	setCurrentlyHighlightedReportFields = (currentlyHighlightedBillableWork: BillableWorkVM) => {
		const {
			definitionFields,
			informationFields,
			workQuantityReportBlockFieldVirtualId,
			workTypeReportBlockFieldVirtualId,
		} = currentlyHighlightedBillableWork;

		const newCurrentlyHighlightedFields: HighlightedBillableWorkBlockFields = {};
		newCurrentlyHighlightedFields[workTypeReportBlockFieldVirtualId] = true;
		if (workQuantityReportBlockFieldVirtualId) {
			newCurrentlyHighlightedFields[workQuantityReportBlockFieldVirtualId] = true;
		}
		[...definitionFields, ...informationFields].forEach((_field) => {
			newCurrentlyHighlightedFields[_field.reportBlockFieldVirtualId] = true;
		});

		this.setState({ currentlyHighlightedFields: newCurrentlyHighlightedFields });
	};

	handleAddBillableWork = (billableWork: BillableWorkRM) => {
		this.setState((state) => {
			const nextBillableWorks = [...state.billableWorks, billableWork];
			nextBillableWorks.sort(BillableWorkRM.sort);
			return { billableWorks: nextBillableWorks };
		});
	};

	handleDeleteBillableWork = (index: number) => {
		this.setState((state) => {
			const nextBillableWorks = [...state.billableWorks];
			nextBillableWorks.splice(index, 1);
			return { billableWorks: nextBillableWorks };
		});
	};

	handleEditBillableWork = (index: number, billableWork: BillableWorkRM) => {
		this.setState((state) => {
			const nextBillableWorks = [...state.billableWorks];
			nextBillableWorks.splice(index, 1, billableWork);
			nextBillableWorks.sort(BillableWorkRM.sort);
			return { billableWorks: nextBillableWorks };
		});
	};

	renderHeader = () => {
		const { hasBillableWork, reportType } = this.props;

		const {
			billableWorks,
			isPreviewInFocus,
			billableWorkFieldsByIdMap,
			reportTypeBlocksByIdMap,
			reportBlocksByIdMap,
			reportTypeDescription,
			reportTypeName,
			showPreviewToggle,
			definitionFieldFieldIds,
			informationFieldFieldIds,
			workQuantityFieldIds,
			workTypeFieldIds,
			isValid,
		} = this.state;

		const onSave = !!reportType?.id
			? this.openConfirmationModal
			: this.save;

		const billableWorkProps = hasBillableWork
			? {
				addBillableWork: this.handleAddBillableWork,
				billableWorks,
				definitionFieldFieldIds,
				editBillableWork: this.handleEditBillableWork,
				hasBillableWork: true,
				informationFieldFieldIds,
				reportTypeBlocksByIdMap,
				reportBlocksByIdMap,
				removeBillableWork: this.handleDeleteBillableWork,
				setCurrentlyHighlightedReportFields: this.setCurrentlyHighlightedReportFields,
				workQuantityFieldIds,
				workTypeFieldIds,
				billableWorkFieldsByIdMap,
			} as FormHeaderPropsWithBillableWork
			: { hasBillableWork: undefined } as FormHeaderPropsWithoutBillableWork;

		return (
			<Header
				description={reportTypeDescription}
				edit={this.rename}
				isPreviewInFocus={isPreviewInFocus}
				isSaveDisabled={isValid}
				onPreviewClick={this.togglePreview}
				onSaveClick={onSave}
				reportTypeName={reportTypeName}
				showPreviewToggle={showPreviewToggle}
				{...billableWorkProps}
			/>
		);
	};

	renderTypeInfo = () => {
		const {
			primaryTypeBlockIds,
			secondaryTypeBlockIds,
			primaryTypeMainBlockId,
			secondaryTypeMainBlockId,
			draggingElementSource,
			upperTotalBlockId,
			lowerTotalBlockId,
			displayType,
			reportTypeBlocksByIdMap,
			reportBlocksByIdMap,
			reportFieldsByIdMap,
		} = this.state;

		const showBlockList = !!DISPLAY_TYPE_COL_SIZE[displayType].BLOCK_LIST;

		return (
			<Col sm={DISPLAY_TYPE_COL_SIZE[displayType].TYPE_INFO}>
				{this.renderHeader()}
				<TypeInfo
					draggingElementSource={draggingElementSource}
					lowerTotalBlockId={lowerTotalBlockId}
					onAddBlock={this.openReportTypeBlockModal}
					openEditBlockNameModal={this.openEditBlockNameModal}
					openFieldModal={this.openCalculatedFieldModal}
					primaryIdList={primaryTypeBlockIds}
					primaryMainBlockId={primaryTypeMainBlockId}
					removeBlock={this.showRemoveReportTypeBlockModal}
					removeField={this.removeField}
					removeMainBlock={this.showRemoveReportTypeMainBlockModal}
					reportBlocksByIdMap={reportBlocksByIdMap}
					reportFieldsByIdMap={reportFieldsByIdMap}
					reportTypeBlocksByIdMap={reportTypeBlocksByIdMap}
					secondaryIdList={secondaryTypeBlockIds}
					secondaryMainBlockId={secondaryTypeMainBlockId}
					showBlocksList={showBlockList}
					upperTotalBlockId={upperTotalBlockId}
					upsertField={this.upsertField}
				/>
			</Col>
		);
	};

	renderPreview = () => {
		const {
			calculationFieldMap,
			primaryTypeBlockIds,
			secondaryTypeBlockIds,
			primaryTypeMainBlockId,
			secondaryTypeMainBlockId,
			upperTotalBlockId,
			lowerTotalBlockId,
			displayType,
			showPreviewToggle,
			currentlyHighlightedFields,
			reportTypeBlocksByIdMap,
			reportBlocksByIdMap,
			reportFieldsByIdMap,
		} = this.state;
		const { listOfEquipment } = this.props;

		return (
			<Col className="report-block-form__sticky-sidebar report-block-form__preview" sm={DISPLAY_TYPE_COL_SIZE[displayType].PREVIEW}>
				{showPreviewToggle && this.renderHeader()}
				<Preview
					calculationFieldMap={calculationFieldMap}
					currentlyHighlightedFields={currentlyHighlightedFields}
					listOfEquipment={listOfEquipment ?? null}
					lowerTotalBlockId={lowerTotalBlockId}
					primaryTypeBlockIds={primaryTypeBlockIds}
					primaryTypeMainBlockId={primaryTypeMainBlockId ?? null}
					reportBlockByIdMap={reportBlocksByIdMap}
					reportFieldsByIdMap={reportFieldsByIdMap}
					reportTypeBlockByIdMap={reportTypeBlocksByIdMap}
					secondaryTypeBlockIds={secondaryTypeBlockIds}
					secondaryTypeMainBlockId={secondaryTypeMainBlockId ?? null}
					upperTotalBlockId={upperTotalBlockId}
				/>
			</Col>
		);
	};

	render() {
		if (this.props.loading || !this.state.isReportTypeDataInitialized) {
			return <Loading />;
		}
		const {
			showReportTypeBlockModal,
			showPreviewToggle,
			isPreviewInFocus,
			displayType,
			showConfirmationModal,
			showCalculatedFieldModal,
			calculatedFieldModalOptions,
			showBlockNameModal,
			fieldModalReportTypeBlockId,
			isFieldModalBlockPrimary,
			isFieldModalTotalBlock,
			blockNameModalReportTypeBlockId,
			showRemoveReportTypeBlockConfirmationModal,
			showRemoveReportTypeMainBlockConfirmationModal,
			reportFieldsByIdMap,
		} = this.state;

		const { reportBlocksMap, reportType } = this.props;

		const showBlockList = !!DISPLAY_TYPE_COL_SIZE[displayType].BLOCK_LIST;
		const showTypeInfo = !!DISPLAY_TYPE_COL_SIZE[displayType].TYPE_INFO && (!isPreviewInFocus || !showPreviewToggle);
		const showPreview = !!DISPLAY_TYPE_COL_SIZE[displayType].PREVIEW && (isPreviewInFocus || !showPreviewToggle);

		return (
			<DragDropContext onDragEnd={this.onDragEnd} onDragStart={this.onDragStart}>
				<div className="report-block-form-container">
					{showBlockList &&
						<Col className="report-block-form__sticky-sidebar" sm={DISPLAY_TYPE_COL_SIZE[displayType].BLOCK_LIST}>
							<BlockList blocks={reportBlocksMap} />
						</Col>
					}
					{showTypeInfo && this.renderTypeInfo()}
					{showPreview && this.renderPreview()}
				</div>
				<ReportTypeBlockModal
					close={this.closeReportTypeFieldModal}
					loadOptions={this.fetchModalOptions}
					onSubmit={this.submitReportTypeBlockModal}
					show={showReportTypeBlockModal}
					valueKey="virtualId"
				/>
				<CalculatedFieldModal
					close={this.closeCalculatedFieldModal}
					fieldsByIdMap={reportFieldsByIdMap}
					firstInitialValues={this.getCalculatedFieldInitialState()}
					isPrimary={isFieldModalBlockPrimary ?? false}
					isTotalBlock={isFieldModalTotalBlock ?? false}
					onSubmit={this.upsertField}
					options={calculatedFieldModalOptions}
					reportTypeBlockId={fieldModalReportTypeBlockId}
					showModal={showCalculatedFieldModal}
				/>
				<EditBlockNameModal
					close={this.closeEditBlockNameModal}
					getInitialValues={this.getBlockNameModalInitialState}
					onSave={this.editBlockName}
					reportTypeBlockId={blockNameModalReportTypeBlockId}
					show={showBlockNameModal}
				/>
				{reportType &&
					<ConfirmationModal
						body={ReportTypeCustomTypeForm._getConfirmationModalBody(reportType.name)}
						closeModal={this.closeConfirmationModal}
						confirmAction={this.save}
						modalStyle={'warning'}
						showModal={showConfirmationModal && !!reportType?.id}
						size={'md'}
						title={ReportTypeCustomTypeForm._getConfirmationModalTitle(reportType.name)}
					/>
				}
				<ConfirmationModal
					body={this.getRemoveBlockConfirmationModalText()}
					closeModal={this.closeRemoveReportTypeBlockModal}
					confirmAction={this.confirmRemoveReportTypeBlock}
					confirmText="Yes"
					modalStyle="danger"
					showModal={showRemoveReportTypeBlockConfirmationModal}
					title="Delete Block?"
				/>
				<ConfirmationModal
					body={this.getRemoveBlockConfirmationModalText()}
					closeModal={this.closeRemoveReportTypeMainBlockModal}
					confirmAction={this.confirmRemoveReportTypeMainBlock}
					confirmText="Yes"
					modalStyle="danger"
					showModal={showRemoveReportTypeMainBlockConfirmationModal}
					title="Delete Block?"
				/>
			</DragDropContext>
		);
	}
}

export default ReportTypeCustomTypeForm;
