import * as React from 'react';
import type { CustomRouteComponentProps} from 'react-router-dom';
import { withRouter } from 'react-router-dom';
import { compose } from 'redux';
import type { ResolveThunks } from 'react-redux';
import { connect } from 'react-redux';
import { Field, formValueSelector } from 'redux-form';
import type { Option } from 'react-select/src/filters';
import { Row, Col } from 'react-bootstrap';

import type { RootState } from 'af-reducers';

import type { EquipmentCostEditableTree} from 'ab-viewModels/editableTree.viewModel';
import { EquipmentCostCategoryItem } from 'ab-viewModels/editableTree.viewModel';

import * as EquipmentCostActions from 'af-actions/equipmentCost';

import Select from 'af-fields/SelectField';

import ColorSquare from 'af-components/ColorSquare';

import TreeView, { SELECTABLE_LEVEL } from './TreeView';

const EquipmentCostSelect = Select as unknown as new () => Select<EquipmentCostCategoryItem>;

interface OwnProps {
	reduxFormName: string;
	reduxPropName: string;
	initialValuePropName: string;
	changeField: (field: string, value: string | number | EquipmentCostCategoryItem) => void;
}

interface StateProps {
	getCategoryValue: () => EquipmentCostCategoryItem;
}

interface DispatchProps {
	findAllByQuery: typeof EquipmentCostActions.findAllByQuery;
	findAllForCompanyEditableTree: typeof EquipmentCostActions.findAllForCompanyEditableTree;
}

type Props = OwnProps & StateProps & ResolveThunks<DispatchProps> & CustomRouteComponentProps<void>;

interface State {
	disableSearch: boolean;
	options: EquipmentCostCategoryItem[];
}

class SelectOrCreateTree extends React.PureComponent<Props, State> {

	state: State = {
		disableSearch: false,
		options: [],
	};

	async componentDidMount() {
		const { findAllForCompanyEditableTree } = this.props;
		const options = await findAllForCompanyEditableTree();
		const { items } = options;
		this.setState(() => ({ options: items }));
	}

	onCategoryChange = async () => {
		this.setState(() => ({
			disableSearch: false,
		}));
	};

	onCategorySelect = async (ecc: EquipmentCostEditableTree) => {
		const { changeField } = this.props;

		this.setState(() => ({ disableSearch: false }));

		changeField('category', EquipmentCostCategoryItem.fromTree(ecc));
	};

	onTreeViewToggle = async (isVisible: boolean) => {
		this.setState(() => ({ disableSearch: isVisible }));
	};

	onCreate = (item: EquipmentCostCategoryItem) => {
		if (item.level === SELECTABLE_LEVEL) {
			this.setState((state: State) => ({ options: [item, ...state.options] }));
		}
	};

	renderTreeViewActionButton = () => (
		<TreeView
			onCategorySelect={this.onCategorySelect}
			onCreate={this.onCreate}
			onToggle={this.onTreeViewToggle}
		/>
	);

	renderTypeaheadItem = (option: EquipmentCostCategoryItem, props: Metadata, index: number) => {
		const { name } = option;
		const title = (
			<span className="equipment-cost-upsert__selected-category" key={`title-${index}`}>
				<ColorSquare color={option.color} />
				{name}
			</span>
		);
		const subtitle = (
			<div key={`subtitle-${index}`}>
				<small>{(option.parents || []).join(' > ')} {'>'} {option.name}</small>
			</div>
		);
		return [
			title,
			subtitle,
		];
	};

	getOptionValue = (option: EquipmentCostEditableTree) => option.nodeId;

	filterBy = (option: Option, text: string): boolean => {
		const { name = '', parents = [] } = option.data as EquipmentCostCategoryItem;
		const searchParams = [name.toLowerCase(), ...parents.map((_parent: string) => _parent.toLowerCase())];
		const query = text ? text.toLowerCase() : '';
		return searchParams.some((_param: string) => _param.includes(query));
	};

	renderSelectedCategoryTree = (category: EquipmentCostCategoryItem) => {
		return (
			<>
				{category.parents &&
					<div className="equipment-cost-upsert__tree-level equipment-cost-upsert__tree-level--1">
						<span className="icon-folder_open" />
						{category.parents[0]}
					</div>
				}
				{category.parents &&
					<div className="equipment-cost-upsert__tree-level equipment-cost-upsert__tree-level--2">
						<span className="icon-folder_open" />
						{category.parents[1]}
					</div>
				}
				{category.name &&
					<div className="equipment-cost-upsert__tree-level equipment-cost-upsert__tree-level--3">
						<ColorSquare color={category.color} />
						{category.name}
					</div>
				}
			</>
		);
	};

	render() {
		const { disableSearch, options } = this.state;
		const { getCategoryValue } = this.props;
		const category = getCategoryValue();

		return (
			<Row className="row--padded row--flex">
				<Col className="equipment-cost-upsert__category-picker" sm={16}>
					<Field
						addonComponent={this.renderTreeViewActionButton}
						allowNew={false}
						component={EquipmentCostSelect}
						containerClassName="select-container"
						disableErrorMessage={true}
						filterOption={this.filterBy}
						formatOptionLabel={this.renderTypeaheadItem}
						getOptionValue={this.getOptionValue}
						isDisabled={disableSearch}
						name="category"
						onValueChange={this.onCategoryChange}
						options={options}
						placeholder="Enter Category *"
					/>
				</Col>
				<Col className="equipment-cost-upsert__tree" sm={8}>
					{category && this.renderSelectedCategoryTree(category)}
				</Col>
			</Row>
		);
	}
}

function mapStateToProps(state: RootState, ownProps: OwnProps): StateProps {
	const { reduxFormName } = ownProps;
	const selector = formValueSelector(reduxFormName);
	return {
		getCategoryValue: () => selector(state, 'category'),
	};
}

function mapDispatchToProps(): DispatchProps {
	return {
		findAllByQuery: EquipmentCostActions.findAllByQuery,
		findAllForCompanyEditableTree: EquipmentCostActions.findAllForCompanyEditableTree,
	};
}

const enhance = compose<React.ComponentClass<OwnProps>>(
	withRouter,
	connect(mapStateToProps, mapDispatchToProps())
);

export default enhance(SelectOrCreateTree);
