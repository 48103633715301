import * as React from 'react';
import type { ResolveThunks } from 'react-redux';
import { connect } from 'react-redux';

import * as DeliverableStatusActions from 'af-actions/deliverableStatus';

import DeliverableStatusLabel from 'af-components/DeliverableStatusLabel';
import type { TabProps, Column, RowInfo, ButtonData } from 'af-components/Table6';
import Table from 'af-components/Table6';
import EmptyCell from 'af-components/Table6/Cells/EmptyCell';

import type { DeliverableStatusRequestModel } from 'ab-requestModels/deliverableStatus.requestModel';

import type { DeliverableStatusViewModel } from 'ab-viewModels/deliverableStatusTable.viewModel';

import TableNameEnum from 'ab-enums/tableName.enum';
import TableButtonType from 'ab-enums/tableButtonType.enum';

import DeliverableStatusModal from './DeliverableStatusModal';
import ErrorModal from './ErrorInfoModal';

interface State {
	showStatusModal: boolean;
	isEdit: boolean;
	statusForEdit: Nullable<DeliverableStatusViewModel>;
	errorMessage: Nullable<string>;
}

interface OwnProps {
	companyName: string;
	orgAlias: string;
}

interface DispatchProps {
	findAllForCompanyTable: typeof DeliverableStatusActions.findAllJobStatusesForCompanyTable;
	deleteStatus: typeof DeliverableStatusActions.deleteStatus;
	bulkDelete: typeof DeliverableStatusActions.bulkDelete;
	create: typeof DeliverableStatusActions.create;
	edit: typeof DeliverableStatusActions.update;
}

type Props = ResolveThunks<DispatchProps> & OwnProps;

const DELETE_CONFIRMATION_BODY = (
	<>
		If deleted, they will no longer be assignable to new resources.
		<br />
		Resources already using them will not be affected.
		<br />
		This action cannot be undone.
	</>
);

class DeliverableStatus extends React.PureComponent<Props, State> {
	state: State = {
		showStatusModal: false,
		isEdit: false,
		statusForEdit: null,
		errorMessage: null,
	};

	columns: Column<DeliverableStatusViewModel>[] = [
		{
			Header: 'Status Name',
			accessor: 'name',
			Cell: ({ original }) => original.name,
		},
		{
			Header: 'Color & Abbr.',
			sortable: false,
			accessor: 'abbreviation',
			Cell: ({ original }) => (
				<DeliverableStatusLabel
					abbreviation={original.abbreviation}
					statusColor={original.statusColor}
					textColor={original.textColor}
				/>
			),
		},
		{
			Header: 'Description',
			accessor: 'description',
			Cell: ({ original }) => original.description ? original.description : <EmptyCell />,
		},
	];

	static deleteStatusModalTitle = (original: DeliverableStatusViewModel) => (`Are you sure you want to delete this Deliverable Status (${original.name})?`);
	static deleteStatusModalBody = () => (DELETE_CONFIRMATION_BODY);
	static deleteStatusModalText = () => ('Delete Deliverable Status');

	tabs = (): TabProps<DeliverableStatusViewModel>[] => {
		const { findAllForCompanyTable, bulkDelete } = this.props;

		const buttons: ButtonData[] = [
			{
				type: TableButtonType.PRIMARY,
				hasPermission: true,
				label: 'New Status',
				icon: 'plus',
				onClick: this.createStatus,
			},
		];

		return [
			{
				label: 'Deliverable status',
				columns: this.columns,
				selectable: true,
				hasSearchInput: true,
				buttons,
				fetch: findAllForCompanyTable,
				bulkDelete: bulkDelete,
				bulkDeleteConfirmationBody: DELETE_CONFIRMATION_BODY,
				onRowClick: this.onRowClick,
				rowActions: [
					{
						label: 'Edit',
						action: this.editStatus,
						shouldRefresh: true,
					},
					{
						label: 'Delete',
						action: this.deleteStatus,
						hasModal: true,
						modalTitle: DeliverableStatus.deleteStatusModalTitle,
						modalBody: DeliverableStatus.deleteStatusModalBody,
						modalText: DeliverableStatus.deleteStatusModalText,
						shouldRefresh: true,
					},
				],
			},

		];
	};

	onRowClick = ({ original }: RowInfo<DeliverableStatusViewModel>) => {
		this.editStatus(original);
	};

	deleteStatus = async (original: DeliverableStatusViewModel) => {
		const { deleteStatus } = this.props;
		await deleteStatus(original.id);
	};

	createStatus = async () => {
		this.openModal(false);
	};

	editStatus = (original: DeliverableStatusViewModel) => {
		this.openModal(true, original);
	};

	openModal = (isEdit: boolean, statusForEdit: Nullable<DeliverableStatusViewModel> = null) => {
		this.setState(() => ({ showStatusModal: true, isEdit, statusForEdit }));
	};

	closeModal = () => this.setState(() => ({ showStatusModal: false, isEdit: false, statusForEdit: null }));

	closeErrorModal = () => this.setState(() => ({ errorMessage: null }));

	submit = async (form: DeliverableStatusRequestModel) => {
		const { isEdit } = this.state;
		const { edit, create, orgAlias, companyName } = this.props;
		if (isEdit) {
			const response = await edit(form, companyName, orgAlias);
			if (!response) {
				throw new Error('Response unavailable');
			}
			if (!response.success) {
				this.setState(() => ({ errorMessage: response.details ?? 'Something went wrong while updating status.' }));
			}
		} else {
			form.isJobStatus = true;
			const response = await create(form, companyName, orgAlias);
			if (!response) {
				throw new Error('Response unavailable');
			}
			if (!response.success) {
				this.setState(() => ({ errorMessage: response.details ?? 'Something went wrong while creating new status.' }));
			}
		}
		this.closeModal();
	};

	render() {
		const { isEdit, showStatusModal, statusForEdit, errorMessage } = this.state;
		return (
			<>
				<div className="form-segment form-segment--maxi">
					<Table
						clearTableClassname={true}
						hideTabs={true}
						tableName={TableNameEnum.DELIVERABLE_RESOURCES}
						tabs={this.tabs()}
					/>
				</div>
				<DeliverableStatusModal
					initialValues={statusForEdit as DeliverableStatusRequestModel}
					isEdit={isEdit}
					isJobStatus={true}
					onCancel={this.closeModal}
					onSubmit={this.submit}
					showModal={showStatusModal}
				/>
				<ErrorModal
					closeModal={this.closeErrorModal}
					errorMessage={errorMessage}
					showModal={!!errorMessage}
				/>
			</>
		);
	}
}

function mapDispatchToProps(): DispatchProps {
	return {
		findAllForCompanyTable: DeliverableStatusActions.findAllJobStatusesForCompanyTable,
		deleteStatus: DeliverableStatusActions.deleteStatus,
		bulkDelete: DeliverableStatusActions.bulkDelete,
		create: DeliverableStatusActions.create,
		edit: DeliverableStatusActions.update,
	};
}

export default connect<null, DispatchProps, OwnProps>(null, mapDispatchToProps())(DeliverableStatus);
