import { isValidTextInput } from 'acceligent-shared/utils/text';

import type { EquipmentRequestModel } from 'ab-requestModels/equipment.requestModel';

export function validate(values: EquipmentRequestModel) {
	const errors: ValidationErrors = {};

	if (!values.equipmentCost) {
		errors.equipmentCost = 'Equipment Cost is required';
	}

	if (!isValidTextInput(values.code)) {
		errors.code = 'Equipment code is required';
	} else if (values.code.length > 8) {
		errors.code = 'Equipment code cannot have more than 8 characters';
	}

	return errors;
}
