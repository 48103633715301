import * as React from 'react';
import type { ConnectedProps } from 'react-redux';
import { connect } from 'react-redux';
import type { CustomRouteComponentProps } from 'react-router-dom';

import * as AccountActions from 'af-actions/accounts';

import BulkImport from 'af-components/BulkUpsert/Import';
import CodeField from 'af-components/CodeField';

import { MEMBERS_CSV_SAMPLE } from 'af-constants/csvSamples';
import CLIENT from 'af-constants/routes/client';

import type { CSVBulkAccountRM } from 'ab-requestModels/account/upload.requestModel';

import type { BulkCreateResponseViewModel } from 'ab-viewModels/csv.viewModel';

import type { RootState } from 'af-reducers';

import parse from './parse';

type Props = CustomRouteComponentProps & ConnectedProps<typeof connector>;

class CompanyMembersBulkUpload extends React.PureComponent<Props> {

	static MANDATORY_FIELDS = ['uniqueId', 'firstName', 'lastName'];

	manual = () => {
		return [
			'Import multiple users to your company using a CSV upload feature.',
			'Fields that should be located in the CSV are listed below.',
			'The ones marked with an asterisk are required and should not be left out.',
			'At least one of the fields with a plus sign should be provided per row.',
			'After uploading data, it will get validated. Rows that have incorrect or conflicting data will be ignored.',
			'You will be asked to customize preferences for every user that valid data was provided for.',
		];
	};

	fields = () => (
		<CodeField isBlue={true}>
			<b>uniqueId</b>                             | * | Unique ID <br />
			<b>email</b>                                | + | Email <br />
			<b>countryCode</b>                          | + | Country Code, e.g. US <br />
			<b>phoneNumber</b>                          | + | Mobile Phone <br />
			<b>firstName</b>                            | * | First Name <br />
			<b>lastName</b>                             | * | Last Name <br />
		</CodeField>
	);

	importBatch = async (data: CSVBulkAccountRM): Promise<void | BulkCreateResponseViewModel<void>> => {
		const { validateCSV, location: { state: { orgAlias } }, companyName, history } = this.props;

		if (!data.importedCount) {
			return {
				error: {
					uniqueId: 'No data provided.',
				},
				rejected: undefined,
				importedCount: 0,
				updatedCount: 0,
			};
		}

		await validateCSV(data.accounts);
		history.push(CLIENT.COMPANY.SETTINGS.MEMBERS.ROLES_PERMISSIONS(orgAlias, companyName));
	};

	render() {
		const { location: { state: { orgAlias } }, companyName } = this.props;
		return (
			<BulkImport
				fields={this.fields()}
				importBatch={this.importBatch}
				listRoute={CLIENT.COMPANY.SETTINGS.MEMBERS.TABLE(orgAlias, companyName)}
				mandatoryFields={CompanyMembersBulkUpload.MANDATORY_FIELDS}
				manual={this.manual()}
				parse={parse}
				sampleData={MEMBERS_CSV_SAMPLE}
				title="Users"
			/>
		);
	}
}

function mapStateToProps(state: RootState) {
	const { companyData } = state.user;
	if (!companyData) {
		throw new Error('User not logged in');
	}

	return {
		companyName: companyData.name,
	};
}

function mapDispatchToProps() {
	return {
		validateCSV: AccountActions.validateCSV,
	};
}

const connector = connect(mapStateToProps, mapDispatchToProps());

export default connector(CompanyMembersBulkUpload);
