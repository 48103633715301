import * as React from 'react';

import { ModalLink } from 'af-components/ModalNavigation/ModalRouter';

interface Props {
	page: string;
	icon: string;
}

const ModalHome: React.FC<Props> = (props) => {

	const {
		page,
		icon,
	} = props;

	return (
		<div className="modal-home__link-container">
			<span className={`${icon} home-icon`}></span>
			<ModalLink className="home-page-link" link={`/${page.toLowerCase().replaceAll(' ', '_')}`}>{page}</ModalLink>
		</div>
	);
};

export default React.memo(ModalHome);
