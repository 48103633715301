import * as React from 'react';
import type { CustomRouteComponentProps } from 'react-router-dom';
import type { ResolveThunks } from 'react-redux';
import { connect } from 'react-redux';

import * as CompanyActions from 'af-actions/companies';

import type { RootState } from 'af-reducers';

import type { CompanyViewModel } from 'ab-viewModels/company.viewModel';

import Breadcrumbs from 'af-components/Breadcrumbs';

import WorkOrderPreview from './WorkOrder/Preview';
import WorkOrderEdit from './WorkOrder/Edit';

import FieldReportPreview from './FieldReport/Preview';
import FieldReportEdit from './FieldReport/Edit';

interface CompanySettingsRouteProps {
	orgAlias: string;
	companyName: string;
}

interface DispatchProps {
	getCompany: typeof CompanyActions.getCompany;
}

interface StateProps {
	company: Nullable<CompanyViewModel>;
}

type OwnProps = CustomRouteComponentProps<CompanySettingsRouteProps>;

type Props = StateProps & ResolveThunks<DispatchProps> & OwnProps;

interface State {
	editWorkOrderSettings: boolean;
	editFieldReportSettings: boolean;
}

class CompanySettings extends React.Component<Props, State> {
	static defaultProps: Partial<Props> = {
		company: {} as CompanyViewModel,
	};

	state: State = {
		editWorkOrderSettings: false,
		editFieldReportSettings: false,
	};

	async componentDidMount() {
		const { getCompany } = this.props;
		await getCompany();
	}

	toggleWorkOrderSettingsEdit = () => {
		this.setState(
			(prevStatus: State) => ({ editWorkOrderSettings: !prevStatus.editWorkOrderSettings })
		);
	};

	toggleFieldReportSettingsEdit = () => {
		this.setState(
			(prevStatus: State) => ({ editFieldReportSettings: !prevStatus.editFieldReportSettings })
		);
	};

	render() {
		const { company } = this.props;
		const { editWorkOrderSettings, editFieldReportSettings } = this.state;

		if (!company) {
			// TODO: Loading
			return null;
		}

		return (
			<>
				<div className="form-segment">
					<Breadcrumbs items={[{ label: 'Work Order' }]} />
					<WorkOrderPreview
						company={company}
						toggleEdit={this.toggleWorkOrderSettingsEdit}
					/>
					<WorkOrderEdit
						isVisible={editWorkOrderSettings}
						toggleEdit={this.toggleWorkOrderSettingsEdit}
					/>
				</div>
				<div className="form-segment">
					<Breadcrumbs items={[{ label: 'Field Report' }]} />
					<FieldReportPreview
						company={company}
						toggleEdit={this.toggleFieldReportSettingsEdit}
					/>
					<FieldReportEdit
						isVisible={editFieldReportSettings}
						toggleEdit={this.toggleFieldReportSettingsEdit}
					/>
				</div>
			</>
		);
	}
}

function mapStateToProps(state: RootState): StateProps {
	const { company } = state.company;

	return {
		company,
	};
}

function mapDispatchToProps(): DispatchProps {
	return {
		getCompany: CompanyActions.getCompany,
	};
}

export default connect<StateProps, DispatchProps, OwnProps>(mapStateToProps, mapDispatchToProps())(CompanySettings);
