import * as React from 'react';
import type { ConnectedProps } from 'react-redux';
import { connect } from 'react-redux';
import { compose } from 'redux';

import type { TimeSheetsBulkUpdateRM } from 'ab-requestModels/timeSheet/timeSheetUpdate';
import type TimeSheetUpdateRM from 'ab-requestModels/timeSheet/timeSheetUpdate';
import type FieldWorkClassificationCodeListItemVM from 'ab-viewModels/fieldWorkClassificationCode/listItem';
import type { EquipmentListItemVM } from 'ab-viewModels/timeSplitEquipment/timeSplitEquipment';

import TimeSheetApprovalStatus from 'acceligent-shared/enums/timeSheetApprovalStatus';
import FieldReportAccessRoleEnum from 'acceligent-shared/enums/fieldReportAccessRole';
import type { WorkOrderReviewLevel } from 'acceligent-shared/enums/workOrderReviewStatus';
import type WorkOrderReviewStatus from 'acceligent-shared/enums/workOrderReviewStatus';
import type WorkOrderStatus from 'acceligent-shared/enums/workOrderStatus';

import * as TimeSheetUtils from 'acceligent-shared/utils/timeSheet';

import type TimeSheetTrackedEntriesVM from 'ab-viewModels/timeSheet/trackedEntries.viewModel';
import type { TimeSheetEntriesForTimelineVM } from 'ab-viewModels/timeSheet/timeSheetEntry.viewModel';
import type { TimeSheetVM } from 'ab-viewModels/timeSheet/timeSheet.viewModel';

import PagePermissions from 'ab-enums/pagePermissions.enum';

import type { RootState } from 'af-reducers';

import * as TimeSheetActions from 'af-actions/timeSheet';

import { isAllowed } from 'ab-utils/auth.util';

import Loading from './Loading';
import TimeSheetRow from './TimeSheetRow/TimeSheetRow';
import TimeZoneBanner from './TimeZoneBanner';

import styles from './styles.module.scss';
import TimeSheetsEditModal, { BulkOrSingleEditModal } from './TimeSheetEditModal';

import type SignatureForm from '../../Shared/SignatureModal/FormModel';
import TimeSheetVirtualRow from './TimeSheetVirtualRow';

interface OwnProps {
	accessRole: Nullable<FieldReportAccessRoleEnum>;
	dueDate: string;
	code: string;
	workOrderId: number;
	workRequestId: number;
	timeSheets: TimeSheetVM[];
	loading: boolean;
	activeAccounts: { [accountId: number]: true; };
	temporaryEmployeeAccounts: { [temporaryEmployeeId: number]: true; };
	areReportsReadOnly: boolean;
	areReportsFinalOrDone: boolean;
	onTimeSheetSubmit: (form: SignatureForm) => Promise<void>;
	onTimeSheetSave: (workOrderId: number, accountId: number, data: TimeSheetUpdateRM) => Promise<void>;
	onTimeSheetReject: () => Promise<void>;
	workOrderReviewStatus: WorkOrderReviewStatus;
	workOrderReviewLevel: WorkOrderReviewLevel;
	trackedEntries: Nullable<TimeSheetTrackedEntriesVM>;
	timeSheetEntriesForTimeline: Nullable<TimeSheetEntriesForTimelineVM>;
	timeZoneInUse: Nullable<string>;
	timeZone: Nullable<string>;
	workOrderStatus: WorkOrderStatus;
	changeTimeZone: () => void;
	isPaused: boolean;
	hasSIUnassignedPermission: boolean;
	classificationCodes: FieldWorkClassificationCodeListItemVM[];
	sections: Nullable<TimeSplitEntryEquipmentSections>[];
	canSeeTimeAllocations: boolean;
	associatedWRId: Nullable<number>;
}

type Props = OwnProps & ConnectedProps<typeof connector>;

type DuplicateAccumulator = {
	duplicates: { [name: string]: true; };
	tempEmpNames: { [name: string]: true; };
};

type TimeSplitEntryEquipmentSections = {
	title: string;
	options: EquipmentListItemVM[];
};

const duplicateReducer = (acc: DuplicateAccumulator, timeSheet: TimeSheetVM) => {
	if (timeSheet.temporaryEmployeeUniqueId) {
		if (acc.tempEmpNames[timeSheet.userFullName]) {
			acc.duplicates[timeSheet.userFullName] = true;
		} else {
			acc.tempEmpNames[timeSheet.userFullName] = true;
		}
	}
	return acc;
};

const TimeSheets: React.FC<Props> = (props) => {

	const {
		accessRole,
		dueDate,
		code,
		workOrderId,
		timeSheets,
		loading,
		activeAccounts,
		temporaryEmployeeAccounts,
		areReportsReadOnly,
		areReportsFinalOrDone,
		canSeeTimeAllocations,
		onTimeSheetSubmit,
		onTimeSheetSave,
		onTimeSheetReject,
		workOrderReviewStatus,
		workOrderReviewLevel,
		trackedEntries,
		timeSheetEntriesForTimeline,
		timeZoneInUse,
		timeZone,
		changeTimeZone,
		accountId,
		isManagerOrAdmin,
		hasTimeSheetOverlaps,
		isManagementAllowedToApprove,
		isManagementAllowedToEdit,
		isPaused,
		hasSIUnassignedPermission,
		workOrderStatus,
		classificationCodes,
		sections,
		workRequestId,
		associatedWRId,
	} = props;

	const [
		timeSheetInEdit,
		setTimeSheetInEdit,
	] = React.useState<Nullable<TimeSheetVM>>(null);
	const [
		isSuperintendent,
	] = React.useState<boolean>(accessRole === FieldReportAccessRoleEnum.SUPERINTENDENT || hasSIUnassignedPermission);

	const {
		duplicates,
	} = React.useMemo(() => {
		return timeSheets.reduce(duplicateReducer, { duplicates: {}, tempEmpNames: {} } as DuplicateAccumulator);
	}, [timeSheets]);

	const closeEditModal = React.useCallback(() => setTimeSheetInEdit(null), []);

	const openEditModal = React.useCallback((timeSheet: TimeSheetVM) => setTimeSheetInEdit(timeSheet), []);

	const onSaveTimeSheetEditing = React.useCallback(async (_workOrderId: number, data: TimeSheetsBulkUpdateRM) => {
		if (timeSheetInEdit) {
			if (Object.keys(data).length === 1) {
				const employeeId = Object.keys(data)[0];
				await onTimeSheetSave(_workOrderId, timeSheetInEdit.accountId, data[employeeId]);
			}
		} else {
			throw new Error('Can not save this time sheet');
		}
	}, [onTimeSheetSave, timeSheetInEdit]);

	const renderTimeSheetRow = React.useCallback((timeSheet: TimeSheetVM) => {
		const isOwner = timeSheet.accountId === accountId;

		const isAllowedToEditTimeSheet = TimeSheetUtils.isUserAllowedToEdit(
			timeSheet?.accountId === accountId,
			isManagerOrAdmin,
			isSuperintendent,
			isManagementAllowedToEdit
		);

		const isTimeSheetReadonly = !!timeSheet.superintendentApprovalStatus?.approvalStatus && TimeSheetUtils.shouldTsBeReadOnly(
			workOrderReviewStatus,
			timeSheet.superintendentApprovalStatus?.approvalStatus,
			isAllowedToEditTimeSheet
		);

		const isTimeSheetRejectable = !!timeSheet.superintendentApprovalStatus?.approvalStatus && TimeSheetUtils.shouldTSBeRejectable(
			workOrderReviewStatus,
			timeSheet.superintendentApprovalStatus?.approvalStatus,
			isManagerOrAdmin || isSuperintendent
		);

		const trackedEntry = trackedEntries?.entries?.find((entry) => entry.accountId === timeSheet.accountId) ?? null;

		const entries = timeSheetEntriesForTimeline?.timelineEntitiesAndOverlaps?.[timeSheet.accountId];
		const accountTimeSheetEntries = entries?.timelineEntities;
		const accountOverlaps = entries?.overlaps ?? null;
		const hasEntryChanges = entries?.hasChanges;

		const isDuplicate = !!duplicates[timeSheet.userFullName];

		return (
			<TimeSheetRow
				active={activeAccounts[timeSheet.accountId]}
				areReportsReadOnly={areReportsReadOnly || areReportsFinalOrDone}
				code={code}
				hasEntryChanges={hasEntryChanges}
				hasTimeSheetOverlaps={hasTimeSheetOverlaps}
				isDuplicateName={isDuplicate}
				isManagementAllowedToApprove={isManagementAllowedToApprove}
				isManagerOrAdmin={isManagerOrAdmin}
				isOwner={isOwner}
				isRejectable={isTimeSheetRejectable}
				isSuperintendent={isSuperintendent}
				isTemporaryEmployee={temporaryEmployeeAccounts[timeSheet.accountId] ?? false}
				isTimeSheetReadOnly={isTimeSheetReadonly || isPaused}
				key={timeSheet.accountId}
				onEdit={openEditModal}
				onTimeSheetReject={onTimeSheetReject}
				onTimeSheetSubmit={onTimeSheetSubmit}
				timeSheet={timeSheet}
				timeSheetEntities={accountTimeSheetEntries}
				timeSheetOverlaps={accountOverlaps}
				timeZoneInUse={timeZoneInUse}
				trackedEntry={trackedEntry}
				workOrderId={workOrderId}
				workOrderStatus={workOrderStatus}
			/>
		);
	}, [
		accountId,
		activeAccounts,
		areReportsReadOnly,
		areReportsFinalOrDone,
		code,
		duplicates,
		hasTimeSheetOverlaps,
		isManagementAllowedToApprove,
		isManagementAllowedToEdit,
		isManagerOrAdmin,
		isPaused,
		isSuperintendent,
		onTimeSheetReject,
		onTimeSheetSubmit,
		openEditModal,
		temporaryEmployeeAccounts,
		timeSheetEntriesForTimeline?.timelineEntitiesAndOverlaps,
		timeZoneInUse,
		trackedEntries?.entries,
		workOrderId,
		workOrderReviewStatus,
		workOrderStatus,
	]);

	const renderVirtualTimeSheetRow = React.useCallback((timeSheet: TimeSheetVM) => {

		const isOwner = timeSheet.accountId === accountId;

		const entries = timeSheetEntriesForTimeline?.timelineEntitiesAndOverlaps?.[timeSheet.accountId];
		const accountTimeSheetEntries = entries?.timelineEntities;
		const accountOverlaps = entries?.overlaps ?? null;

		return (
			<TimeSheetVirtualRow
				associatedJobId={associatedWRId!}
				isManagerOrAdmin={isManagerOrAdmin}
				isOwner={isOwner}
				isSuperintendent={isSuperintendent}
				isTemporaryEmployee={temporaryEmployeeAccounts[timeSheet.accountId] ?? false}
				key={timeSheet.accountId}
				onPreview={openEditModal}
				overlaps={accountOverlaps}
				timeSheet={timeSheet}
				timeSheetEntities={accountTimeSheetEntries}
				timeZoneInUse={timeZoneInUse}
				workOrderId={workOrderId}
			/>
		);
	}, [
		accountId,
		timeSheetEntriesForTimeline?.timelineEntitiesAndOverlaps,
		associatedWRId,
		isManagerOrAdmin,
		isSuperintendent,
		temporaryEmployeeAccounts,
		timeZoneInUse,
		workOrderId,
		openEditModal,
	]);

	if (loading) {
		return <Loading />;
	}

	const disabled = timeSheetInEdit?.superintendentApprovalStatus?.approvalStatus === TimeSheetApprovalStatus.APPROVED;
	const isAllowedToEditTimeSheet = TimeSheetUtils.isUserAllowedToEdit(
		timeSheetInEdit?.accountId === accountId,
		isManagerOrAdmin,
		isSuperintendent,
		isManagementAllowedToEdit
	);

	const isTimeSheetReadonly = !!timeSheetInEdit?.superintendentApprovalStatus?.approvalStatus && TimeSheetUtils.shouldTsBeReadOnly(
		workOrderReviewStatus,
		timeSheetInEdit?.superintendentApprovalStatus?.approvalStatus,
		isAllowedToEditTimeSheet
	);

	if (!timeSheets.length) {
		return (
			<div className={styles['empty-time-sheet']}>
				There are no time sheets for this report. Publish Work Order with employees or temporary employees to generate them.
			</div>
		);
	}

	return (
		<>
			{
				areReportsReadOnly &&
				<div className={styles.banner}>
					In order to edit Time Sheets, please reject Field Report, which will bring it back to edit mode.
				</div>
			}
			{
				!associatedWRId &&
				<div className="time-sheet-list">
					<TimeZoneBanner changeTimeZone={changeTimeZone} timeZoneInUse={timeZoneInUse} />
					{timeSheets.map(renderTimeSheetRow)}
				</div>
			}
			{
				!!associatedWRId &&
				<div className="time-sheet-list">
					{timeSheets.map(renderVirtualTimeSheetRow)}
				</div>
			}
			<TimeSheetsEditModal
				accessRole={accessRole}
				areAllTimeSheetsReadOnly={isTimeSheetReadonly || isPaused || disabled}
				areFRsReadOnly={areReportsReadOnly || areReportsFinalOrDone}
				bulkOrSingleEditModal={BulkOrSingleEditModal.SINGLE}
				canSeeTimeAllocations={canSeeTimeAllocations}
				classificationCodes={classificationCodes}
				close={closeEditModal}
				code={code}
				dueDate={dueDate}
				hasSIUnassignedPermission={hasSIUnassignedPermission}
				isPaused={isPaused}
				isSuperintendent={isSuperintendent}
				isVirtual={!!associatedWRId}
				save={onSaveTimeSheetEditing}
				sections={sections}
				showModal={timeSheetInEdit ? true : false}
				timeSheets={timeSheetInEdit ? [timeSheetInEdit] : null}
				timeZone={timeZone}
				timeZoneInUse={timeZoneInUse}
				workOrderId={workOrderId}
				workOrderReviewLevel={workOrderReviewLevel}
				workOrderReviewStatus={workOrderReviewStatus}
				workRequestId={workRequestId}
			/>
		</>
	);
};

function mapStateToProps(state: RootState) {
	const {
		user: {
			userData,
			companyData,
		},
		company: {
			company,
		},
	} = state;

	if (!userData || !companyData) {
		throw new Error('User not logged in');
	}

	const { role } = userData;
	const { accountId, isCompanyAdmin, permissions } = companyData;

	return {
		accountId,
		isManagerOrAdmin: isAllowed(PagePermissions.COMPANY.FIELD_REPORT.MANAGE, permissions, isCompanyAdmin, role),
		isManagementAllowedToApprove: company?.isFRManageAllowedToApproveTimeSheet ?? false,
		isManagementAllowedToEdit: company?.isFRManageAllowedToEditTimeSheet ?? false,
	};
}

function mapDispatchToProps() {
	return {
		hasTimeSheetOverlaps: TimeSheetActions.hasTimeSheetOverlaps,
	};
}

const connector = connect(mapStateToProps, mapDispatchToProps());

const enhance = compose<React.ComponentType<OwnProps>>(
	React.memo,
	connector
);

export default enhance(TimeSheets);
