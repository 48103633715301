import * as React from 'react';
import type { ConnectedProps } from 'react-redux';
import { connect } from 'react-redux';
import type { InjectedFormProps } from 'redux-form';
import { Field, FieldArray } from 'redux-form';
import type { CustomRouteComponentProps } from 'react-router-dom';
import { Row, Col, Button } from 'react-bootstrap';

import BlobStorageImageSizeContainer from 'acceligent-shared/enums/blobStorageImageSizeContainer';

import { MAX_EQUIPMENT_SPECIFICATION_LENGTH, DEFAULT_EQUIPMENT_IMAGE } from 'ab-common/constants/value';

import type { EquipmentRequestModel } from 'ab-requestModels/equipment.requestModel';

import type { EquipmentCostViewModel } from 'ab-viewModels/equipmentCost.viewModel';
import type { SkillViewModel } from 'ab-viewModels/skill.viewModel';
import type LocationOptionVM from 'ab-viewModels/location/option.viewModel';
import * as ResourceStatusesViewModel from 'ab-viewModels/resources/resourceStatuses.viewModel';

import * as EquipmentCostActions from 'af-actions/equipmentCost';
import * as EquipmentActions from 'af-actions/equipment';
import * as LocationActions from 'af-actions/location';
import * as SkillActions from 'af-actions/skill';

import Dropdown from 'af-fields/Dropdown';
import Input from 'af-fields/Input';
import Textarea from 'af-fields/Textarea';
import Checkbox from 'af-fields/Checkbox';
import type { OwnProps as MultiTagSelectProps } from 'af-fields/MultiTagSelect';
import MultiTagSelect from 'af-fields/MultiTagSelect';
import Select from 'af-fields/SelectField';

import SubmitButton from 'af-components/SubmitButton';
import ImageUpload from 'af-components/ImageUpload';
import ImageTag from 'af-components/Image';
import LabelWithPills from 'af-components/LockedPillsValue';
import BackButton from 'af-components/BackButton';

import SkillOption from '../Shared/SkillOption';
import SkillMultiValueLabel from '../Shared/SkillMultiValueLabel';

import { bemElement } from 'ab-utils/bem.util';

import * as Helpers from './helpers';

const EquipmentCostSelect = Select as unknown as new () => Select<EquipmentCostViewModel>;
const LocationSelect = Select as unknown as new () => Select<LocationOptionVM>;

interface PathParams {
	id?: string;
}

type FormProps = InjectedFormProps<EquipmentRequestModel>;

interface OwnProps {
	onSubmit: (form: EquipmentRequestModel) => Promise<void>;
	formActionWrapper: {
		selector: <T extends keyof EquipmentRequestModel>(fieldName: T) => EquipmentRequestModel[T];
	};
}

type Props = OwnProps & ConnectedProps<typeof connector> & CustomRouteComponentProps<PathParams> & FormProps;

interface State {
	companyEquipmentStatuses: ResourceStatusesViewModel.List;
	equipmentCost: Nullable<EquipmentCostViewModel>;
	frontImage: string | File | null;
	frontImageUrl: string | null;
	backImage: string | File | null;
	backImageUrl: string | null;
	hasReceivedInitialItem: boolean;
	deleteFrontImage: boolean;
	deleteBackImage: boolean;
	skillList: SkillViewModel[];
	equipmentCostOptions: EquipmentCostViewModel[];
	locationOptions: LocationOptionVM[];
	showFrontImageUpload: boolean;
	showBackImageUpload: boolean;
}

class EquipmentForm extends React.PureComponent<Props, State> {

	state: State = {
		companyEquipmentStatuses: [],
		equipmentCost: null,
		hasReceivedInitialItem: false,
		frontImage: null,
		frontImageUrl: null,
		backImage: null,
		backImageUrl: null,
		deleteFrontImage: false,
		deleteBackImage: false,
		skillList: [],
		equipmentCostOptions: [],
		locationOptions: [],
		showFrontImageUpload: false,
		showBackImageUpload: false,
	};

	static getDerivedStateFromProps(props: Props, state: State): Partial<State> | null {
		const { initialized, formActionWrapper } = props;
		const { hasReceivedInitialItem } = state;

		if (!hasReceivedInitialItem && initialized) {
			const equipmentCost = formActionWrapper.selector('equipmentCost');
			const frontImage = formActionWrapper.selector('frontImageUrl');
			const frontImageUrl = formActionWrapper.selector('frontImageUrl');
			const backImage = formActionWrapper.selector('backImageUrl');
			const backImageUrl = formActionWrapper.selector('backImageUrl');
			return {
				hasReceivedInitialItem: true,
				equipmentCost,
				frontImage,
				frontImageUrl,
				backImage,
				backImageUrl,
			};
		}

		return null;
	}

	async componentDidMount() {
		const { findAllStatusesForCompany, findAllSkillsByQuery, findAllEquipmentCostsByQuery, findAllLocations } = this.props;
		const [
			{ available, unavailable },
			skills,
			equipmentCostOptions,
			locationOptions,
		] = await Promise.all([
			findAllStatusesForCompany(),
			findAllSkillsByQuery(''),
			findAllEquipmentCostsByQuery(''),
			findAllLocations(),
		]);
		this.setState(() => ({
			companyEquipmentStatuses: [ResourceStatusesViewModel.Item.DEFAULT_AVAILABLE, ...available, ...unavailable],
			skillList: skills,
			equipmentCostOptions,
			locationOptions,
		}));
	}

	onSubmit = async (form: EquipmentRequestModel) => {
		const { onSubmit } = this.props;
		const { frontImage, backImage, deleteBackImage, deleteFrontImage } = this.state;

		form.frontImageUrl = frontImage as string;
		form.backImageUrl = backImage as string;
		form.deleteFrontImage = deleteFrontImage;
		form.deleteBackImage = deleteBackImage;
		await onSubmit(form);
	};

	// Image

	setFrontImage = (
		file: Nullable<File>,
		url: Nullable<string>,
		isDelete: boolean
	) => this.setState(() => ({ frontImage: file, frontImageUrl: url, deleteFrontImage: isDelete }));

	setBackImage = (
		file: Nullable<File>,
		url: Nullable<string>,
		isDelete: boolean
	) => this.setState(() => ({ backImage: file, backImageUrl: url, deleteBackImage: isDelete }));

	deleteFrontImage = () => this.setFrontImage(null, null, true);

	deleteBackImage = () => this.setBackImage(null, null, true);

	openFrontImageUpload = () => this.setState(() => ({ showFrontImageUpload: true }));
	closeFrontImageUpload = () => this.setState(() => ({ showFrontImageUpload: false }));

	openBackImageUpload = () => this.setState(() => ({ showBackImageUpload: true }));
	closeBackImageUpload = () => this.setState(() => ({ showBackImageUpload: false }));

	onFrontImageUploadSave = (frontImage: File, frontImageUrl: string) => this.setFrontImage(frontImage, frontImageUrl, false);

	onBackImageUploadSave = (backImage: File, backImageUrl: string) => this.setBackImage(backImage, backImageUrl, false);

	// Equipment Cost

	onEquipmentCostChange = (equipmentCost: EquipmentCostViewModel) => {
		const { change } = this.props;
		if (equipmentCost) {
			this.setState(() => ({ equipmentCost }));
		} else {
			change('location', null);
		}
	};

	// Location

	onLocationChange = (location: LocationOptionVM) => {
		const { change } = this.props;
		if (!location) {
			change('location', null);
		}
	};

	// Render

	render() {
		const {
			handleSubmit,
			submitting,
			invalid,
		} = this.props;
		const {
			companyEquipmentStatuses,
			frontImage,
			backImage,
			frontImageUrl,
			backImageUrl,
			equipmentCost,
			skillList,
			locationOptions,
			equipmentCostOptions,
			showFrontImageUpload,
			showBackImageUpload,
		} = this.state;

		const imageUploadButtonClassName = bemElement('image-upload', 'button');

		return (
			<form onSubmit={handleSubmit(this.onSubmit)}>
				<div className="form-box">
					<Row className="row--flex">
						<div className="avatar__container avatar__container--double">
							<ImageUpload
								closeModal={this.closeFrontImageUpload}
								onSave={this.onFrontImageUploadSave}
								showModal={showFrontImageUpload}
							/>
							<ImageUpload
								closeModal={this.closeBackImageUpload}
								onSave={this.onBackImageUploadSave}
								showModal={showBackImageUpload}
							/>
							<div className="avatar">
								<span className="avatar__label">Front</span>
								<ImageTag
									fallbackSrc={DEFAULT_EQUIPMENT_IMAGE}
									minSize={BlobStorageImageSizeContainer.SIZE_200X200}
									src={frontImageUrl}
									tryOriginal={true}
									tryRoot={true}
								/>
								<div className={bemElement('image-upload', 'buttons')}>
									<Button
										className={imageUploadButtonClassName}
										onClick={this.openFrontImageUpload}
										variant="info"
									>
										<strong className="icon-upload" />
									</Button>
									<Button
										className={imageUploadButtonClassName}
										disabled={!frontImage && !frontImageUrl}
										onClick={this.deleteFrontImage}
										variant="danger"
									>
										<strong className="icon-delete" />
									</Button>
								</div>
							</div>
							<div className="avatar">
								<span className="avatar__label">Back</span>
								<ImageTag
									fallbackSrc={DEFAULT_EQUIPMENT_IMAGE}
									minSize={BlobStorageImageSizeContainer.SIZE_200X200}
									src={backImageUrl}
									tryOriginal={true}
									tryRoot={true}
								/>
								<div className={bemElement('image-upload', 'buttons')}>
									<Button
										className={imageUploadButtonClassName}
										onClick={this.openBackImageUpload}
										variant="info"
									>
										<strong className="icon-upload" />
									</Button>
									<Button
										className={imageUploadButtonClassName}
										disabled={!backImage && !backImageUrl}
										onClick={this.deleteBackImage}
										variant="danger"
									>
										<strong className="icon-delete" />
									</Button>
								</div>
							</div>
						</div>
						<div className="avatar__neighbour">
							<Row className="row--padded">
								<Col md={8}>
									<Field
										component={Input}
										label="Equipment ID *"
										maxCharacters={8}
										name="code"
										placeholder="Enter Equipment ID"
										type="text"
									/>
								</Col>
								<Col md={8}>
									<Field
										component={Input}
										label="Equipment Specification"
										maxCharacters={MAX_EQUIPMENT_SPECIFICATION_LENGTH}
										name="specification"
										placeholder="Enter Equipment Specification"
										type="text"
									/>
								</Col>
								<Col md={8}>
									<Field
										component={Dropdown}
										id="equipmentStatusId"
										label="Current Status *"
										name="equipmentStatusId"
										options={companyEquipmentStatuses}
										renderMenuItem={Helpers.renderResourceStatusMenuItem}
										valueKey="id"
										withCaret={true}
									/>
								</Col>
							</Row>
							<Row className="row--padded-bottom">
								<Col md={8}>
									<Field
										component={EquipmentCostSelect}
										filterOption={Helpers.filterEquipmentCosts}
										formatOptionLabel={Helpers.formatEquipmentCostOptionLabel}
										getOptionLabel={Helpers.getEquipmentCostOptionLabel}
										getOptionValue={Helpers.getEquipmentCostOptionValue}
										label="Equipment Cost *"
										name="equipmentCost"
										onValueChange={this.onEquipmentCostChange}
										options={equipmentCostOptions}
										placeholder="Enter Equipment Cost"
									/>
								</Col>
								<Col md={8}>
									<Field
										component={LocationSelect}
										filterOption={Helpers.filterLocations}
										formatOptionLabel={Helpers.formatLocationOptionLabel}
										getOptionLabel={Helpers.getLocationOptionLabel}
										getOptionValue={Helpers.getLocationOptionValue}
										label="Home Office"
										name="location"
										onValueChange={this.onLocationChange}
										options={locationOptions}
										placeholder="Enter Home Office"
									/>
								</Col>
								<Col md={8}>
									<Field
										component={Checkbox}
										inline={true}
										label="Show on schedule board"
										name="showOnScheduleBoard"
									/>
								</Col>
							</Row>
						</div>
					</Row>
					{Helpers.renderSectionTitle('Contacts')}
					<Row>
						<Col md={6}>
							<Field
								component={Input}
								label="First Contact"
								name="primaryContact"
								placeholder="Enter First Contact"
								type="text"
							/>
						</Col>
						<Col md={6}>
							<Field
								component={Input}
								label="Second Contact"
								name="secondaryContact"
								placeholder="Enter Second Contact"
								type="text"
							/>
						</Col>
						<Col md={12} />
					</Row>
					{Helpers.renderSectionTitle('Needed Skills and Licenses')}
					<Row>
						<Col md={24}>
							<FieldArray<MultiTagSelectProps<SkillViewModel>>
								component={MultiTagSelect}
								getOptionLabel={Helpers.getSkillOptionLabel}
								getOptionValue={Helpers.getSkillOptionValue}
								label="Skills"
								MultiValueLabel={SkillMultiValueLabel}
								name="skills"
								Option={SkillOption}
								options={skillList}
								placeholder="Choose skills"
							/>

						</Col>
					</Row>
					<Row className="row--padded">
						<Col md={24}>
							<LabelWithPills
								colorKey="color"
								items={equipmentCost?.skills ?? []}
								label="Skills Defined by Equipment Cost"
								labelKey="name"
							/>
						</Col>
					</Row>
					<Row className="row--padded">
						<Col md={12}>
							<Field
								component={Textarea}
								controlCursor={true}
								label="Licenses"
								name="licenses"
								placeholder="Enter Needed Licenses"
							/>
						</Col>
						<Col sm={12} />
					</Row>
					<Row className="row--submit">
						<BackButton />
						<SubmitButton
							disabled={invalid}
							reduxFormSubmitting={submitting}
							variant="primary"
							variantDisabled="info"
						/>
					</Row>
				</div>
			</form>
		);
	}
}

function mapDispatchToProps() {
	return {
		findAllStatusesForCompany: EquipmentActions.findAllStatusesForCompany,
		findAllSkillsByQuery: SkillActions.findAllForCompanyByQuery,
		findAllEquipmentCostsByQuery: EquipmentCostActions.findAllByQuery,
		findAllLocations: LocationActions.findList,
	};
}

const connector = connect(null, mapDispatchToProps());

export default connector<React.ComponentClass<OwnProps>>(EquipmentForm);
