import * as React from 'react';
import { Dropdown } from 'react-bootstrap';

import ConfirmationModal from 'af-components/ConfirmationModal';

import { useToggle } from 'af-utils/react.util';

import type { SettingsItem as SettingsItemType } from '.';

interface OwnProps extends SettingsItemType {
	toggleCell: () => void;
	eventKey: number | string;
}

type Props = OwnProps;

function SettingsItem({ hasModal = false, disabled = false, ...restProps }: Props) {
	const {
		value: showConfirmationModal,
		setToTrue: openConfirmationModal,
		setToFalse: closeConfirmationModal,
	} = useToggle();

	const {
		label,
		modalStyle,
		eventKey,
		modalTitle,
		modalBody,
		modalText,
		beforeModalOpen,
		onModalClose,
		toggleCell,
		action,
	} = restProps;

	const confirmationAction = React.useCallback((event: React.MouseEvent<unknown>) => {
		toggleCell();
		action?.(event);
	}, [toggleCell, action]);

	const stopPropagation = React.useCallback((event: React.MouseEvent<unknown>) => {
		event?.stopPropagation();
		event?.nativeEvent?.stopImmediatePropagation();

		if (!hasModal) {
			confirmationAction(event);
		}
	}, [confirmationAction, hasModal]);

	const openModal = React.useCallback((event: React.MouseEvent<unknown>) => {
		event?.stopPropagation();
		event?.nativeEvent?.stopImmediatePropagation();
		beforeModalOpen?.();
		openConfirmationModal();
	}, [beforeModalOpen, openConfirmationModal]);

	const closeModal = React.useCallback(() => {
		closeConfirmationModal();
		onModalClose?.();
	}, [closeConfirmationModal, onModalClose]);

	return (
		<Dropdown.Item
			disabled={!!disabled}
			eventKey={eventKey?.toString()}
			onClick={(hasModal && !showConfirmationModal) ? openModal : stopPropagation}
			open={false}
		>
			{label}
			{hasModal &&
				<ConfirmationModal
					body={modalBody ?? undefined}
					closeModal={closeModal}
					confirmAction={confirmationAction}
					confirmText={modalText ?? undefined}
					modalStyle={modalStyle}
					showModal={showConfirmationModal}
					size="md"
					title={modalTitle}
				/>
			}
		</Dropdown.Item>
	);
}

export default React.memo(SettingsItem);
