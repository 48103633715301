import * as React from 'react';
import { connect } from 'react-redux';
import type { WrappedFieldProps} from 'redux-form';
import { formValueSelector, change } from 'redux-form';

import type { RootState } from 'af-reducers';

import type { Props as PlainMultiselectDropdownProps } from 'af-fields/PlainMultiselectDropdown';
import PlainMultiselectDropdown from 'af-fields/PlainMultiselectDropdown';

interface OwnProps extends WrappedFieldProps, PlainMultiselectDropdownProps {
	isStandalone?: boolean;
	withCaret?: boolean;
}

interface StateProps {
	selected: Metadata[];
}

interface DispatchProps {
	change: typeof change;
}

type Props = OwnProps & StateProps & DispatchProps;

class MultiselectDropdown extends React.Component<Props> {
	static defaultProps: Partial<Props> = {
		options: [],
		alwaysShowErrors: false,
		className: 'dropdown-field dropdown-field--multiselect',
		hasBlankOption: false,
		placeholder: '',
		disableErrorMessage: false,
		filterable: false,
		selected: [],
		isStandalone: false,
		withCaret: false,
	};

	handleChange = async (item, event) => {
		const {
			valueKey,
			labelKey,
			onValueChange,
			change: _change,
			input: { name },
			meta: { form },
			selected,
		} = this.props;

		const isChecked = event.target.checked;
		const calculatedPropName = valueKey ?? labelKey;
		let newSelected: Metadata[] = [];

		if (isChecked) {
			newSelected = selected.concat(item);
		} else {
			newSelected = selected.filter((_option) => _option[calculatedPropName] !== item[calculatedPropName]);
		}

		_change(form, name, newSelected);

		// on change callback
		if (onValueChange) {
			await onValueChange(newSelected);
		}
	};

	getDropdownClassName = () => {
		const { className, meta: { error, touched }, alwaysShowErrors } = this.props;

		return (touched || alwaysShowErrors) && error ? `${className} dropdown-field--with-error` : className;
	};

	getBlock = () => {
		const { alwaysShowErrors, disableErrorMessage, meta: { error, warning, touched } } = this.props;

		return (
			(touched || alwaysShowErrors) && !disableErrorMessage &&
			(
				(error && <span className="help-block"><span className="icon-info" /> {error}</span>) ||
				(warning && <span className="help-block text-orange"><span className="icon-info" /> {warning}</span>)
			)
		);
	};

	render() {
		return (
			<PlainMultiselectDropdown
				{...this.props}
				block={this.getBlock()}
				dropdownClassName={this.getDropdownClassName()}
				handleChange={this.handleChange}
			/>
		);
	}
}

const EMPTY_ARRAY = [];

function mapStateToProps(state: RootState, ownProps: OwnProps): StateProps {
	const { meta: { form }, input: { name } } = ownProps;
	const calculatedPropName = name;
	const selector = formValueSelector(form);
	const selected = selector(state, calculatedPropName) || EMPTY_ARRAY;

	return {
		selected,
	};
}

export default connect<StateProps, DispatchProps, OwnProps>(mapStateToProps, { change })(MultiselectDropdown);
