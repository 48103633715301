import WorkOrderStatus from 'acceligent-shared/enums/workOrderStatus';
import WorkRequestStatus from 'acceligent-shared/enums/workRequestStatus';

import type { WorkOrderStoreState } from 'af-models/workOrder.models';
import type { BaseWorkOrderModel, ValidityWorkOrderModel } from 'af-models/scheduleBoard.models';

import type WorkOrderModalViewModel from 'ab-viewModels/scheduleBoardWorkOrderModal.viewModel';

export const setWorkOrderLock = (state: WorkOrderStoreState, workOrderId: number, locked: boolean): WorkOrderStoreState => {
	return {
		...state,
		workOrders: {
			...state.workOrders,
			list: state.workOrders.list
				? state.workOrders.list.map((workOrder) => workOrder?.id === workOrderId ? { ...workOrder, locked } : workOrder)
				: null,
		},
		order: state.order?.id === workOrderId ? { ...state.order, locked } : state.order,
	};
};

export function groupWorkOrdersByValidity<T extends BaseWorkOrderModel>(workOrders: T[], validityCondition: (wo: T) => string): ValidityWorkOrderModel {
	return workOrders.reduce((_acc: ValidityWorkOrderModel, _wo: T) => {
		const validityLabel = validityCondition(_wo);
		_acc[validityLabel].push(_wo.code);
		return _acc;
	}, { validOrders: [], finishedOrders: [], cancelledOrders: [] });
}

export const groupWorkOrdersForCopy = (workOrders: WorkOrderModalViewModel[]): ValidityWorkOrderModel => {
	return workOrders.reduce((_acc: ValidityWorkOrderModel, _workOrder: WorkOrderModalViewModel) => {
		let label = 'validOrders';
		if (_workOrder.jobStatus === WorkRequestStatus.FINISHED) {
			label = 'finishedOrders';
		} else if (_workOrder.status === WorkOrderStatus.CANCELED) {
			label = 'cancelledOrders';
		}
		_acc[label].push(_workOrder.code);
		return _acc;
	}, { validOrders: [], finishedOrders: [], cancelledOrders: [] });
};

