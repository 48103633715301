import * as React from 'react';
import { Row, Col } from 'react-bootstrap';

import { formatPhoneNumber } from 'acceligent-shared/utils/phone';

import type AgencyVM from 'ab-viewModels/agency/agency.viewModel';

import LockedColorValue from 'af-components/LockedColorValue';
import LockedValue from 'af-components/LockedValue';

import CLIENT from 'af-routes/client';

import ResourcePreview from '../../Shared/ResourcePreview';

interface OwnProps {
	agency: Nullable<AgencyVM>;
	companyName: string;
	orgAlias: string;
}

type Props = OwnProps;

const AgencyPreview: React.FC<Props> = ({ orgAlias, companyName, agency }) => {

	const breadcrumbs = React.useMemo(() => {
		return [
			{ label: 'Temp Agencies', url: CLIENT.COMPANY.RESOURCES.AGENCY.LIST(orgAlias, companyName) },
			{ label: 'Preview' },
		];
	}, [orgAlias, companyName]);

	return (
		<ResourcePreview
			backToListLabel="Back to Temp Agencies List"
			backToListUrl={CLIENT.COMPANY.RESOURCES.AGENCY.LIST(orgAlias, companyName)}
			breadcrumbs={breadcrumbs}
			editUrl={agency && !agency.isDeleted
				? CLIENT.COMPANY.RESOURCES.AGENCY.EDIT(`${agency.id}`, orgAlias, companyName)
				: null}
		>
			<Row className="row--padded-top">
				<Col md={4} ><LockedValue label="Name" value={agency?.name} /></Col>
				<Col md={4} ><LockedValue label="Phone" value={agency?.phone ? formatPhoneNumber(agency?.phone) : '-'} /></Col>
			</Row>
			<Row className="row--padded-top">
				<Col md={4} >
					{agency?.color
						? <LockedColorValue color={agency?.color} label="Color" />
						: <></>
					}
				</Col>
				<Col md={4} ><LockedValue label="Email" value={agency?.email} /></Col>
			</Row>
			<Row className="row--padded-top">
				<Col md={4} />
				<Col md={4} ><LockedValue label="Website" value={agency?.website} /></Col>
			</Row>
		</ResourcePreview>
	);
};

export default React.memo(AgencyPreview);
