const settingsPrefix = '@settings';

export const DELIVERABLES_PERIOD = () => `${settingsPrefix}/deliverablesPeriod`;

export const DELIVERABLES_START_DATE = () => `${settingsPrefix}/deliverablesStartDate`;

export const DELIVERABLES_END_DATE = () => `${settingsPrefix}/deliverablesEndDate`;

export const PER_DIEM_REPORT_START_DATE = () => `${settingsPrefix}/accountingStartDate`; // cannot change value because of backwards compatibility

export const PER_DIEM_REPORT_END_DATE = () => `${settingsPrefix}/accountingEndDate`; // cannot change value because of backwards compatibility

export const WORK_ORDER_SELECTED_DUE_DATE = () => `${settingsPrefix}/workOrderSelectedDueDate`;

export const DAILY_TIP_SECTION_COLLAPSED = () => `${settingsPrefix}/dailyTipSectionCollapsed`;

export const WEEKLY_VIEW_START_DUE_DATE = () => `${settingsPrefix}/weeklyViewStartDueDate`;

export const WEEKLY_VIEW_END_DUE_DATE = () => `${settingsPrefix}/weeklyViewEndDueDate`;

export const CUSTOMER_SUPPORT_UNIQUE_ID = () => `${settingsPrefix}/customerSupportUniqueId`;

export const INVENTORY_TAB = () => `${settingsPrefix}/inventoryTab`;

export const TOAST = () => `${settingsPrefix}/toasts`;

export const WORK_ORDER_SORT = () => `${settingsPrefix}/scheduleBoardSort`;

export const PAYROLL_REPORT_START_DATE = () => `${settingsPrefix}/payrollReportStartDate`;

export const PAYROLL_REPORT_END_DATE = () => `${settingsPrefix}/payrollReportEndDate`;

export const PAYROLL_REPORT_PERIOD = () => `${settingsPrefix}/payrollReportPeriod`;

export const WORK_ORDERS_REPORTS_START_DATE = () => `${settingsPrefix}/workOrdersReportsStartDate`;

export const WORK_ORDERS_REPORTS_END_DATE = () => `${settingsPrefix}/workOrdersReportsEndDate`;

export const WORK_ORDERS_REPORTS_PERIOD = () => `${settingsPrefix}/workOrdersReportsPeriod`;

export const DELIVERABLE_RESOURCE_ACTIVE_TAB_ID = () => `${settingsPrefix}/deliverableResourceActiveTabId`;

export const EXPANDED_DELIVERABLE_ID = () => `${settingsPrefix}/expandedDeliverableId`;

export const EXPANDED_DELIVERABLE_SUBMISSION_ID = () => `${settingsPrefix}/expandedDeliverableSubmissionId`;

export const EXPANDED_DELIVERABLE_ASSIGNMENT_ID = () => `${settingsPrefix}/expandedDeliverableAssignmentId`;

export const EXPANDED_WORK_ORDER_ID = () => `${settingsPrefix}/expandedWorkOrderId`;

export const COMMUNICATION_HISTORY_START_DATE = () => `${settingsPrefix}/communicationHistoryStartDate`;

export const COMMUNICATION_HISTORY_END_DATE = () => `${settingsPrefix}/communicationHistoryEndDate`;

export const COMMUNICATION_HISTORY_PERIOD = () => `${settingsPrefix}/communicationHistoryPeriod`;

export const FIELD_REPORTS_TEXT_FILTER = () => `${settingsPrefix}/fieldReportsTextFilter`;

export const FIELD_REPORTS_ASSIGNMENTS_ID = () => `${settingsPrefix}/fieldReportsAssignmentsId`;

export const EMPLOYEE_STATUS_HISTORY_START_DATE = () => `${settingsPrefix}/employeeStatusHistoryStartDate`;

export const EMPLOYEE_STATUS_HISTORY_END_DATE = () => `${settingsPrefix}/employeeStatusHistoryEndDate`;

export const EMPLOYEE_STATUS_HISTORY_PERIOD = () => `${settingsPrefix}/employeeStatusHistoryPeriod`;

export const EQUIPMENT_STATUS_HISTORY_START_DATE = () => `${settingsPrefix}/equipmentStatusHistoryStartDate`;

export const EQUIPMENT_STATUS_HISTORY_END_DATE = () => `${settingsPrefix}/equipmentStatusHistoryEndDate`;

export const EQUIPMENT_STATUS_HISTORY_PERIOD = () => `${settingsPrefix}/equipmentStatusHistoryPeriod`;

export const JOB_WORK_SUMMARY_START_DATE = () => `${settingsPrefix}/jobWorkSummaryStartDate`;

export const JOB_WORK_SUMMARY_END_DATE = () => `${settingsPrefix}/jobWorkSummaryEndDate`;

export const JOB_WORK_SUMMARY_PERIOD = () => `${settingsPrefix}/jobWorkSummaryPeriod`;

export const PURCHASE_ORDER_START_DATE = () => `${settingsPrefix}/purchaseOrderStartDate`;

export const PURCHASE_ORDER_END_DATE = () => `${settingsPrefix}/purchaseOrderEndDate`;

export const PURCHASE_ORDER_PERIOD = () => `${settingsPrefix}/purchaseOrderPeriod`;

export const PURCHASE_ORDER_SEARCH_TEXT = () => `${settingsPrefix}/purchaseOrderSearchText`;

export const ORDER_SEARCH_TEXT = () => `${settingsPrefix}/orderSearchText`;

export const TOOL_REPAIR_SEARCH_TEXT = () => `${settingsPrefix}/ToolRepairSearchText`;

export const ORDER_PAGE_SIZE = () => `${settingsPrefix}/orderPageSize`;

export const TOOL_REPAIR_PAGE_SIZE = () => `${settingsPrefix}/ToolRepairPageSize`;

export const PURCHASE_ORDER_PAGE_NUMBER = () => `${settingsPrefix}/purchaseOrderPageNumber`;

export const ORDER_PAGE_NUMBER = () => `${settingsPrefix}/orderPageNumber`;

export const TOOL_REPAIR_PAGE_NUMBER = () => `${settingsPrefix}/ToolRepairPageNumber`;

export const INVOICES_START_DATE = () => `${settingsPrefix}/invoicesStartDate`;

export const INVOICES_END_DATE = () => `${settingsPrefix}/invoicesEndDate`;

export const INVOICES_PERIOD = () => `${settingsPrefix}/invoicesPeriod`;

export const ORDER_START_DATE = () => `${settingsPrefix}/orderStartDate`;

export const ORDER_END_DATE = () => `${settingsPrefix}/orderEndDate`;

export const ORDER_PERIOD = () => `${settingsPrefix}/orderPeriod`;

