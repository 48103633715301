import * as React from 'react';

import { bemElement } from 'ab-utils/bem.util';

import CustomModal from 'af-components/CustomModal';

import type { ReportSidebarInfo} from './helpers';
import { sidebarReportsMapper } from './helpers';

interface Props {
	show: boolean;
	open: () => void;
	close: () => void;
	sidebarReportsInfo: Nullable<ReportSidebarInfo[]>;
	currentReportIndex: Nullable<number>;
	reportsRefs: React.MutableRefObject<HTMLDivElement[]>;
}

const MobileSidebar: React.FC<Props> = (props) => {
	const { show, open, close, sidebarReportsInfo, currentReportIndex, reportsRefs } = props;

	const title = `Reports (${sidebarReportsInfo?.length})`;

	const onSidebarClose = React.useCallback(() => {
		close();
	}, [close]);

	const onSidebarReportClick = (index: number) => {
		return () => {
			reportsRefs.current[index].scrollIntoView();
			onSidebarClose();
		};
	};

	return (
		<div className="public-report__header__mobile-sidebar">
			<span className="icon-sort" onClick={open}/>
			<CustomModal
				className="public-report__header__mobile-sidebar__reports-list public-report__header__mobile-sidebar__close-modal"
				closeModal={close}
				id="mobile-sidebar"
				showModal={show}
			>
				<div className={bemElement('public-report__header__mobile-sidebar__reports-list', 'title-container')}>
					<div className={bemElement('public-report__header__mobile-sidebar__reports-list__title-container', 'report-count')}>
						{title}
					</div>
					<span className="icon-left" onClick={onSidebarClose}/>
				</div>
				{sidebarReportsInfo?.map(sidebarReportsMapper(currentReportIndex, onSidebarReportClick))}
			</CustomModal>
		</div>
	);
};

export default React.memo(MobileSidebar);
