import * as React from 'react';
import type { ResolveThunks } from 'react-redux';
import { connect } from 'react-redux';
import type { InjectedFormProps } from 'redux-form';
import { reduxForm } from 'redux-form';
import type { CustomRouteComponentProps } from 'react-router-dom';
import { compose } from 'redux';

import CountryCode from 'acceligent-shared/enums/countryCode';

import type SaleRequestModel from 'ab-requestModels/sale.requestModel';

import { SALE_CREATE } from 'af-constants/reduxForms';
import CLIENT from 'af-constants/routes/client';

import type { RootState } from 'af-reducers';

import * as SaleActions from 'af-actions/sale';

import CreateSaleForm from 'af-components/SharedForms/Resources/Sale';
import { validate, warn } from 'af-components/SharedForms/Resources/Sale/validation';
import Breadcrumbs from 'af-components/Breadcrumbs';

import { toRequestModelWithRawPhoneNumbers } from 'ab-utils/form.util';

type OwnProps = CustomRouteComponentProps<{ id: undefined; }>;

type FormProps = InjectedFormProps<SaleRequestModel, FormOwnProps>;

interface StateProps {
	companyName: string;
	initialValues: FormProps['initialValues'];
}

interface DispatchProps {
	createSale: typeof SaleActions.create;
}

type FormOwnProps = OwnProps & StateProps & ResolveThunks<DispatchProps>;

type Props = FormOwnProps & FormProps;

class CreateSale extends React.PureComponent<Props> {

	createSale = async (form: SaleRequestModel) => {
		const { createSale, location: { state: { orgAlias } }, companyName, history } = this.props;
		const data: SaleRequestModel = toRequestModelWithRawPhoneNumbers(form, 'phone', 'fax');
		await createSale(data);
		history.push(CLIENT.COMPANY.RESOURCES.SALE.LIST(orgAlias, companyName));
	};

	render() {
		const {
			location: { state: { orgAlias } },
			companyName,
		} = this.props;

		return (
			<div className="form-segment">
				<Breadcrumbs
					items={
						[
							{ label: 'Sale', url: CLIENT.COMPANY.RESOURCES.SALE.LIST(orgAlias, companyName) },
							{ label: 'New Sale' },
						]
					}
				/>
				<CreateSaleForm {...this.props} onSubmit={this.createSale} />
			</div>
		);
	}
}

function mapStateToProps(state: RootState): StateProps {
	const { companyData } = state.user;
	if (!companyData) {
		throw new Error('User not logged in');
	}

	return {
		companyName: companyData.name,
		initialValues: {
			faxCountryCode: CountryCode.US,
			phoneCountryCode: CountryCode.US,
		},
	};
}

function mapDispatchToProps(): DispatchProps {
	return {
		createSale: SaleActions.create,
	};
}

const enhance = compose<React.ComponentClass<OwnProps>>(
	connect<StateProps, DispatchProps, OwnProps>(mapStateToProps, mapDispatchToProps()),
	reduxForm<SaleRequestModel, FormOwnProps>({ form: SALE_CREATE, validate, warn, enableReinitialize: true })
);
export default enhance(CreateSale);
