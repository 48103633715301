import baseRoute from '../baseRoute';

export * from './viewModel';
export * from './queryParams';

type RouteDefinition = `${typeof baseRoute}/${number | ':jobId'}/workSummary/revenue${`?subJobId=${number}` | ''}`;

export const ROUTE: RouteDefinition = `${baseRoute}/:jobId/workSummary/revenue`;

export const URL = (jobId: number, subJobId?: number): RouteDefinition =>
	`${baseRoute}/${jobId}/workSummary/revenue${subJobId !== undefined ? `?subJobId=${subJobId}` as const : ''}`;
