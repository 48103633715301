import * as React from 'react';

import TimeSheetApprovalStatus from 'acceligent-shared/enums/timeSheetApprovalStatus';
import TimeSheetSignatureStatus from 'acceligent-shared/enums/timeSheetSignatureStatus';
import TimeFormat from 'acceligent-shared/enums/timeFormat';

import type { TimeSheetVM } from 'ab-viewModels/timeSheet/timeSheet.viewModel';

import { formatDate } from 'acceligent-shared/utils/time';

import { bemElement } from 'ab-utils/bem.util';

import ImageTag from 'af-components/Image';

import SignatureStatusLabel from '../../../Shared/SignatureStatusLabel';
import styles from './styles.module.scss';

interface OwnProps {
	signatureStatus: TimeSheetVM['employeeApprovalStatus'];
	approvalStatus: TimeSheetVM['superintendentApprovalStatus'];
	isOwner: boolean;
	isTemporaryEmployee: boolean;
}

type Props = OwnProps;

const TimeSheetSignatures: React.FC<Props> = ({ approvalStatus, signatureStatus, isOwner, isTemporaryEmployee }: Props) => {
	const {
		userName: approvedBy,
		userNameShort: approvedByShort,
		updatedAt: approvedAt,
		approvalStatus: timeSheetApprovalStatus,
		signatureUrl: approvalSignatureUrl,
		note: rejectionNote,
	} = approvalStatus;
	const {
		userName: signedBy,
		updatedAt: signedAt,
		signatureStatus: timeSheetSignatureStatus,
		signatureUrl,
	} = signatureStatus;

	const signatureDateClassName = bemElement(
		'time-sheet-signature-status-label',
		'date',
		{ 'block': !!signedBy }
	);

	const approvalDateClassName = bemElement(
		'time-sheet-signature-status-label',
		'date',
		{ 'block': !!approvedBy }
	);

	// If time sheet was not signed and SI still gave the approval
	const isSignedBySI = timeSheetSignatureStatus !== TimeSheetSignatureStatus.SIGNED
		&& timeSheetApprovalStatus === TimeSheetApprovalStatus.APPROVED && !!approvalSignatureUrl;

	const showSignatureStatus = (
		!!timeSheetSignatureStatus
		&& (timeSheetSignatureStatus !== TimeSheetSignatureStatus.UNSIGNED || !isOwner)
		&& !isTemporaryEmployee
	);
	const showApprovalStatus = timeSheetApprovalStatus && timeSheetApprovalStatus !== TimeSheetApprovalStatus.PENDING;

	const showSignature = timeSheetSignatureStatus === TimeSheetSignatureStatus.SIGNED && signatureUrl;
	const showImage = isSignedBySI && approvalSignatureUrl;

	return (
		<div>
			{showSignatureStatus &&
				<div className={styles['time-sheet-signature-status-label']}>
					{showSignature && <ImageTag className={styles.image} src={signatureUrl} />}
					<div>
						<SignatureStatusLabel status={timeSheetSignatureStatus} />
						{signedBy &&
							<span className={styles['signature-name']}>
								by {signedBy}
							</span>
						}
						{signedAt &&
							<span className={signatureDateClassName}>
								{formatDate(signedAt, TimeFormat.FULL_DATE, TimeFormat.ISO_DATETIME)}
							</span>
						}
						{isSignedBySI && <div className={styles['signature-superintendent']}>Signed By {approvedByShort}</div>}
					</div>
				</div>
			}
			{showApprovalStatus &&
				<div className={styles['time-sheet-signature-status-label']}>
					{showImage && <ImageTag className={styles.image} src={approvalSignatureUrl} />}
					<div>
						<SignatureStatusLabel status={timeSheetApprovalStatus} />
						{approvedBy &&
							<span className={styles['signature-name']}>
								by {approvedBy}
							</span>
						}
						{approvedAt &&
							<span className={approvalDateClassName}>
								{formatDate(approvedAt, TimeFormat.FULL_DATE, TimeFormat.ISO_DATETIME)}
							</span>
						}
					</div>
					{rejectionNote && <div className={styles['signature-superintendent']}>{rejectionNote}</div>}
				</div>
			}
		</div>
	);
};

export default React.memo(TimeSheetSignatures);
