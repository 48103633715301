import * as React from 'react';
import { Dropdown } from 'react-bootstrap';
import { useNavigate } from 'react-router-dom-v5-compat';

import type * as User from 'ab-viewModels/user.viewModel';

import CLIENT from 'af-constants/routes/client';

interface OwnProps {
	isPlatformAdminContext: boolean;
	organizationData: User.OrganizationData;
	onClose: () => void;
}

type Props = OwnProps;

const OrganizationItem: React.FC<Props> = (props) => {

	const { onClose, isPlatformAdminContext, organizationData } = props;

	const navigate = useNavigate();

	const _onClose = React.useCallback(() => {
		const link: string = isPlatformAdminContext
			? CLIENT.PLATFORM.ALL_ORGANIZATIONS(organizationData.alias)
			: CLIENT.ORGANIZATION.SETTINGS.INFO(organizationData.alias);
		navigate(link);
		onClose();
	}, [isPlatformAdminContext, navigate, onClose, organizationData.alias]);

	if (!organizationData) {
		return <></>;
	}

	const iconClassName = isPlatformAdminContext
		? 'icon-acceligent text-orange'
		: 'icon-work text-grey';

	return (
		<Dropdown.Item className="main-header__companies-dropdown__menu__item main-header__companies-dropdown__menu__organization" onClick={_onClose}>
			<span className={iconClassName} />
			<span className="main-header__companies-dropdown__menu__organization__name">
				{organizationData.name}
			</span>
		</Dropdown.Item>
	);
};

export default OrganizationItem;
