import * as React from 'react';
import { Button } from 'react-bootstrap';
import { Field } from 'redux-form';

import * as ReportBlockFieldEnum from 'acceligent-shared/enums/reportBlockField';
import type OperationType from 'acceligent-shared/enums/operation';
import { MinOperandCount } from 'acceligent-shared/enums/operation';
import type QuantityUnit from 'acceligent-shared/enums/quantityUnit';
import { QuantityUnitLabel } from 'acceligent-shared/enums/quantityUnit';
import OperandType from 'acceligent-shared/enums/operand';

import { bemElement } from 'ab-utils/bem.util';
import * as ReduxUtils from 'ab-utils/reduxForms.util';

import Dropdown from 'af-fields/Dropdown';
import Input from 'af-fields/Input';

import type { ReportBlockFieldFormModel, CalculatedFieldOptionFormModel, BlockDropdownSection} from '../formModel';
import { OPERATION_TYPE_FILTER } from '../formModel';

interface SharedProps {
	additionalFilter?: (option: ReportBlockFieldFormModel) => boolean;
	fieldName: string;
	fieldsByIdMap: { [fieldId: string]: ReportBlockFieldFormModel; };
	index: number;
	onChange: (index: number, value: CalculatedFieldOptionFormModel) => void;
	onRemove: (index: number) => void;
	operandCount: number;
	operation: Nullable<OperationType>;
	selectedFieldsMap: { [key: string]: number; };
	type: CalculatedFieldOptionFormModel['type'];
	unit: Nullable<QuantityUnit>;
}

/** Props for Calculated Field Options in Report Type Builder */
interface GroupedOptionProps extends SharedProps {
	grouped: true;
	options: BlockDropdownSection<CalculatedFieldOptionFormModel>[];
}

/** Props for Calculated Field Options in Report Block Builder */
interface SingleOptionProps extends SharedProps {
	grouped: false;
	options: CalculatedFieldOptionFormModel[];
}

type OwnProps = GroupedOptionProps | SingleOptionProps;

type Props = OwnProps;

/**
	 * Function that filters by operation type
	 */
const _filterByOperationType = (option: ReportBlockFieldFormModel, operation: Nullable<OperationType>) => {
	return !!operation && OPERATION_TYPE_FILTER[operation](option);
};

/**
 * Function that filters by selected fields
 * If field is not already selected or if selected make sure option index is equal to selected index
 */
const _filterBySelectedFields = (
	option: ReportBlockFieldFormModel,
	selectedUnit: Nullable<QuantityUnit>,
	selectedFieldsMap: { [key: string]: number; },
	index: number
) => {
	return (!(selectedFieldsMap[option.virtualId] !== undefined) || selectedFieldsMap[option.virtualId] === index)
		&& (selectedUnit === option.unit || !selectedUnit || !option.unit);
};

/**
 * Function that returns filter function for filtering options in Calculated Field Modal
 * @param operation selected operation type
 * @param unit unit of already selected field
 * @param selectedFieldsMap map of already selected fields
 * @param additionalFilter optional additional filter functions
 * @returns filter function
 */
const _getOptionFieldFilter = (
	operation: Nullable<OperationType>,
	unit: Nullable<QuantityUnit>,
	selectedFieldsMap: { [key: string]: number; },
	fieldsByIdMap: { [fieldId: string]: ReportBlockFieldFormModel; },
	additionalFilter?: (option: ReportBlockFieldFormModel) => boolean
) => {
	return (option: CalculatedFieldOptionFormModel, index: number) => {
		return option.id && _filterByOperationType(fieldsByIdMap[option.id], operation)
			&& _filterBySelectedFields(fieldsByIdMap[option.id], unit, selectedFieldsMap, index)
			&& (additionalFilter?.(fieldsByIdMap[option.id]) ?? true);
	};
};

const _getOptionLabel = (option: ReportBlockFieldFormModel) => {
	const fieldType = ReportBlockFieldEnum.PrimaryTypeNamed[option.fieldType] ?? '';
	const unit = option.unit ? QuantityUnitLabel[option.unit] : '';
	return `${fieldType} ${unit ? `[${unit}]` : ''}`;
};

const _renderSectionHeader = (section: BlockDropdownSection<string>) => {
	const className = bemElement('report-block-form-field-modal', 'dropdown-option', {
		primary: section.isPrimary ?? false,
		secondary: !section.isPrimary,
	});
	return (
		<div className={className}>
			<div className="report-block-form-field-modal__dropdown-option__item">
				{section.name}
			</div>
		</div>
	);
};

const _renderItem = (fieldName: string, label: string, blockName: Nullable<string>) => {
	return (
		<div className="report-block-form-field-modal__dropdown-option">
			{blockName && <div className={bemElement('report-block-form-field-modal__dropdown-option', 'item', ['block'])}>{blockName}</div>}
			<div className="report-block-form-field-modal__dropdown-option__item">{fieldName}</div>
			<div className={bemElement('report-block-form-field-modal__dropdown-option', 'item', ['meta'])}>
				{label}
			</div>
		</div>
	);
};

const _renderConstant = (fieldName: string) => {
	return (
		<Field
			component={Input}
			format={ReduxUtils.formatDecimalNumber}
			id={fieldName}
			label="Constant *"
			name={`${fieldName}.constant`}
			placeholder="Enter Constant Value"
			type="number"
		/>
	);
};

const CalculatedFieldOption: React.FC<Props> = (props: Props) => {
	const { operation, operandCount, fieldsByIdMap, unit, index, onRemove, onChange, fieldName, grouped, selectedFieldsMap, additionalFilter, type } = props;

	const [
		selectableFields,
		setSelectableFields,
	] = React.useState<CalculatedFieldOptionFormModel[] | BlockDropdownSection<CalculatedFieldOptionFormModel>[]>([]);

	const getFilterFunc = React.useCallback(() => {
		return _getOptionFieldFilter(operation, unit, selectedFieldsMap, fieldsByIdMap, additionalFilter);
	}, [operation, unit, selectedFieldsMap, fieldsByIdMap, additionalFilter]);

	React.useEffect(() => {
		let newFields: CalculatedFieldOptionFormModel[] | BlockDropdownSection<CalculatedFieldOptionFormModel>[] = [];
		const optionsFilter = getFilterFunc();
		if (props.grouped) {
			newFields = props.options.reduce<BlockDropdownSection<CalculatedFieldOptionFormModel>[]>((_acc, _section) => {
				const filteredFields = (_section?.fields ?? []).filter((_field) => optionsFilter(_field, props.index));
				if (filteredFields.length) {
					_acc.push({
						..._section,
						fields: filteredFields,
					});
				}
				return _acc;
			}, []);
		} else if (props.grouped === false) {
			newFields = props.options.reduce<CalculatedFieldOptionFormModel[]>((_acc, _field) => {
				if (optionsFilter(_field, props.index)) {
					_acc.push(_field);
				}
				return _acc;
			}, []);
		}
		setSelectableFields(newFields);
	}, [props.grouped, props.options, props.index, getFilterFunc]);

	const renderOptionItem = React.useCallback((option: CalculatedFieldOptionFormModel) => {
		const field = option.id ? fieldsByIdMap[option.id] : null;
		return field
			? _renderItem(field.name, _getOptionLabel(field), null)
			: null;
	}, [fieldsByIdMap]);

	const remove = React.useCallback(() => {
		onRemove(index);
	}, [index, onRemove]);

	const change = React.useCallback((value: CalculatedFieldOptionFormModel) => {
		onChange(index, { id: value.id, constant: null, type: OperandType.FIELD, index, isOperandBlockInPrimarySegment: value.isOperandBlockInPrimarySegment });
	}, [index, onChange]);

	const renderFieldDropdown = React.useCallback(() => {
		if (type === OperandType.CONSTANT) {
			return _renderConstant(fieldName);
		}
		if (grouped) {
			return (
				<Field
					className="report-block-form-field-modal__calculation-dropdown"
					component={Dropdown}
					fixed={true}
					id={fieldName}
					label={`Field #${index + 1}`}
					name={fieldName}
					onValueChange={change}
					placeholder="Select"
					renderMenuItem={renderOptionItem}
					renderSectionHeader={_renderSectionHeader}
					sectionOptionsKey="fields"
					sections={selectableFields}
					sectionTitleKey="name"
					useSectionList
					valueKey="id"
					withCaret={true}
				/>
			);
		}
		return (
			<Field
				className="report-block-form-field-modal__calculation-dropdown"
				component={Dropdown}
				fixed={true}
				id={fieldName}
				label={`Field #${index + 1}`}
				name={fieldName}
				onValueChange={change}
				options={selectableFields}
				placeholder="Select"
				renderMenuItem={renderOptionItem}
				valueKey="id"
				withCaret={true}
			/>
		);
	}, [change, fieldName, grouped, index, renderOptionItem, selectableFields, type]);

	return (
		<>
			{renderFieldDropdown()}
			{operation && operandCount > MinOperandCount[operation] &&
				<Button
					className="btn btn--flat btn--icon"
					onClick={remove}
				>
					<span className="icon-delete" />
				</Button>
			}
		</>
	);
};

export default React.memo(CalculatedFieldOption);
