import * as React from 'react';
import type { DraggableProvided, DraggableStateSnapshot } from 'react-beautiful-dnd';
import { Droppable, Draggable } from 'react-beautiful-dnd';
import { Row, Col } from 'react-bootstrap';
import type { CustomRouteComponentProps } from 'react-router-dom';

import { ColorPalette } from 'acceligent-shared/enums/color';

import type { TreeViewModel, EquipmentCostTreeDict } from 'ab-viewModels/equipmentCostTree.viewModel';
import type { EquipmentCostViewModel } from 'ab-viewModels/equipmentCost.viewModel';

import type { Item } from 'af-components/Table6/Cells/SettingsCell';
import SettingsCell from 'af-components/Table6/Cells/SettingsCell';
import LastUpdatedByCell from 'af-components/Table6/Cells/LastUpdatedByCell';
import ColorSquare from 'af-components/ColorSquare';
import SkillsCell from 'af-components/Table6/Cells/SkillsCell';
import Tooltip from 'af-components/Tooltip';

import CLIENT from 'af-constants/routes/client';

import * as FormattingUtils from 'ab-utils/formatting.util';

import * as ResourceUtil from 'af-utils/resources.util';
import * as EquipmentCostTreeUtil from 'ab-utils/equipmentCostTree.util';

interface OwnProps extends CustomRouteComponentProps {
	companyName: string;
	element: TreeViewModel;
	showSmallButtons: boolean;
	isHidden?: boolean;
	isNested?: boolean;
	index: number;
	level: number;
	activeNode: number;
	showScrollableTable: boolean;
	hasBottomBorder?: boolean;
	hasEquipmentCostChildren?: boolean;
	categoryColor?: string;
	deleteEquipmentCostCategory: (id: number, isCategory: boolean) => Promise<void>;
	deleteEquipmentCost: (id: number, isCategory: boolean) => Promise<void>;
	onToggle: (nodeId: number) => void;
	categoryNodes: Nullable<EquipmentCostTreeDict>;
	equipmentCostNodes: Nullable<EquipmentCostTreeDict>;
}

type Props = OwnProps;

interface DraggableProps extends Props {
	provided?: DraggableProvided;
	snapshot?: DraggableStateSnapshot;
}

const DELETE_CONFIRMATION_BODY = (
	<>
		This action cannot be undone.
	</>
);

class TreeElement extends React.PureComponent<Props> {

	static defaultProps: Partial<Props> = {
		isHidden: false,
		isNested: false,
		hasBottomBorder: false,
		hasEquipmentCostChildren: false,
	};

	_dragging: boolean = false;

	toggle = async () => {
		const { element, onToggle } = this.props;
		onToggle(element.nodeId);
	};

	renderDragHandle = () => {
		const { level, showScrollableTable } = this.props;
		return (level > 0 && !showScrollableTable)
			? <span className="icon-drag_indicator tree-table__drag-handle" />
			: null;
	};

	renderIcon = (): JSX.Element => {
		const { element, categoryColor, showScrollableTable, level } = this.props;

		const isOpen = element.toggled;
		const additionalClassName = showScrollableTable && level > 0 ? 'tree-table__scrollable-icon' : '';

		if (!element.isCategory || element.isEquipmentCostCategory) {
			return <ColorSquare className={additionalClassName} color={categoryColor && ColorPalette[categoryColor]} />;
		} else if (isOpen) {
			return <span className={`icon-collapse tree-table__expander ${additionalClassName}`} />;
		} else {
			return <span className={`icon-expand tree-table__expander ${additionalClassName}`} />;
		}
	};

	renderTreeElements = () => {
		const {
			element,
			companyName,
			categoryColor,
			level,
			match,
			location,
			isHidden,
			history,
			activeNode,
			deleteEquipmentCostCategory,
			deleteEquipmentCost,
			showSmallButtons,
			showScrollableTable,
			onToggle,
			categoryNodes,
			equipmentCostNodes,
		} = this.props;

		return element.children?.map((_element, _index) => {
			const node: TreeViewModel | undefined = _element.isCategory
				? categoryNodes?.[_element.nodeId]
				: equipmentCostNodes?.[_element.nodeId];

			if (!node) {
				throw new Error('Node not found');
			}

			const __element = {
				...node,
				children: _element.children,
				count: _element.isCategory ? EquipmentCostTreeUtil.countEquipmentCosts(_element.children ?? []) : null,
				hasEquipmentCostChildren: _element.hasEquipmentCostChildren,
			};

			return (
				<TreeElement
					activeNode={activeNode}
					categoryColor={categoryColor ?? (__element.data?.categoryColor ?? '')}
					categoryNodes={categoryNodes}
					companyName={companyName}
					deleteEquipmentCost={deleteEquipmentCost}
					deleteEquipmentCostCategory={deleteEquipmentCostCategory}
					element={__element}
					equipmentCostNodes={equipmentCostNodes}
					history={history}
					index={_index}
					isHidden={!!isHidden || !element.toggled}
					isNested={true}
					key={_index}
					level={level + 1}
					location={location}
					match={match}
					onToggle={onToggle}
					showScrollableTable={showScrollableTable}
					showSmallButtons={showSmallButtons}
				/>
			);
		});
	};

	renderTreeElementsDroppableZone = (): Nullable<JSX.Element> => {
		const { element } = this.props;

		if (!element?.children?.length) {
			return null;
		}

		const droppableId = ResourceUtil.generateEquipmentCostTreeNodeId(element);

		return (
			<Droppable
				droppableId={droppableId}
				type={droppableId}
			>
				{(provided) => {
					return (
						<div ref={provided.innerRef} >
							{this.renderTreeElements()}
							{provided.placeholder}
						</div>
					);
				}}
			</Droppable>
		);
	};

	renderCounter = (): Nullable<JSX.Element> => {
		const { element } = this.props;

		return (element.isCategory) ? (<span className="tree-table__node-counter">{element.count}</span>) : null;
	};

	renderEquipmentCostRow = () => {
		const { element: { data }, showSmallButtons } = this.props;

		const castedData = data as EquipmentCostViewModel;
		const valueCellClassName = `tree-table__value-cell ${showSmallButtons ? 'tree-table__value-cell--small' : ''}`;

		return (
			<>
				<Col md={5}>
					<SkillsCell skills={castedData.skills ?? []} />
				</Col>
				<Col className={valueCellClassName} md={2}>
					{FormattingUtils.moneyNormalizer(castedData.dailyCost) || '-'}
				</Col>
				<Col className={valueCellClassName} md={2}>
					{FormattingUtils.moneyNormalizer(castedData.weeklyCost) || '-'}
				</Col>
				<Col className={valueCellClassName} md={2}>
					{FormattingUtils.moneyNormalizer(castedData.monthlyCost) || '-'}
				</Col>
				<Col className={valueCellClassName} md={2}>
					{FormattingUtils.moneyNormalizer(castedData.mobCharge) || '-'}
				</Col>
				<Col className={valueCellClassName} md={2}>
					{FormattingUtils.moneyNormalizer(castedData.operatingCharge) || '-'}
				</Col>
				<Col className={valueCellClassName} md={2}>
					{FormattingUtils.moneyNormalizer(castedData.fuelCost) || '-'}
				</Col>
				<Col md={4}>
					<LastUpdatedByCell updatedAt={castedData.updatedAt} updatedBy={castedData.updatedBy} />
				</Col>
			</>
		);
	};

	renderEquipmentCostScrollableRow = () => {
		const { element: { data } } = this.props;

		const castedData = data as EquipmentCostViewModel;

		return (
			<div className="scroll-to-load__item">
				<div className="scroll-to-load__item-values-container">
					<div className="scroll-to-load__item-value">
						<div className="scroll-to-load__item-value-header">
							{this.renderIcon()}
							{this.renderName()}
						</div>
					</div>
					<div className="scroll-to-load__item-value">
						<div className="scroll-to-load__item-value-header">
							<b className="m-r-m m-l-s">Needed Skills:</b>
						</div>
						<div className="scroll-to-load__item-value-data">
							<SkillsCell skills={castedData.skills} />
						</div>
					</div>
					<div className="scroll-to-load__item-value">
						<div className="scroll-to-load__item-value-header">
							<b className="m-r-m m-l-s">Daily Cost:</b>
						</div>
						<div className="scroll-to-load__item-value-data">
							{FormattingUtils.moneyNormalizer(castedData.dailyCost) || '-'}
						</div>
					</div>
					<div className="scroll-to-load__item-value">
						<div className="scroll-to-load__item-value-header">
							<b className="m-r-m m-l-s">Weekly Cost:</b>
						</div>
						<div className="scroll-to-load__item-value-data">
							{FormattingUtils.moneyNormalizer(castedData.weeklyCost) || '-'}
						</div>
					</div>
					<div className="scroll-to-load__item-value">
						<div className="scroll-to-load__item-value-header">
							<b className="m-r-m m-l-s">Monthly Cost:</b>
						</div>
						<div className="scroll-to-load__item-value-data">
							{FormattingUtils.moneyNormalizer(castedData.monthlyCost) || '-'}
						</div>
					</div>
					<div className="scroll-to-load__item-value">
						<div className="scroll-to-load__item-value-header">
							<b className="m-r-m m-l-s">Mob Charge:</b>
						</div>
						<div className="scroll-to-load__item-value-data">
							{FormattingUtils.moneyNormalizer(castedData.mobCharge) || '-'}
						</div>
					</div>
					<div className="scroll-to-load__item-value">
						<div className="scroll-to-load__item-value-header">
							<b className="m-r-m m-l-s">Op Hour Charge:</b>
						</div>
						<div className="scroll-to-load__item-value-data">
							{FormattingUtils.moneyNormalizer(castedData.operatingCharge) || '-'}
						</div>
					</div>
					<div className="scroll-to-load__item-value">
						<div className="scroll-to-load__item-value-header">
							<b className="m-r-m m-l-s">Fuel Cost:</b>
						</div>
						<div className="scroll-to-load__item-value-data">
							{FormattingUtils.moneyNormalizer(castedData.fuelCost) || '-'}
						</div>
					</div>
					<div className="scroll-to-load__item-value">
						<div className="scroll-to-load__item-value-header">
							<b className="m-r-m m-l-s">Updated:</b>
						</div>
						<div className="scroll-to-load__item-value-data">
							<LastUpdatedByCell updatedAt={castedData.updatedAt} updatedBy={castedData.updatedBy} />
						</div>

					</div>
				</div>
			</div>
		);
	};

	renderEquipmentCosts = () => {
		const { element: { isCategory }, showScrollableTable } = this.props;

		if (isCategory) {
			return null;
		}

		return showScrollableTable ? this.renderEquipmentCostScrollableRow() : this.renderEquipmentCostRow();
	};

	deleteEquipmentCost = async () => {
		const { element, deleteEquipmentCost } = this.props;
		await deleteEquipmentCost(element._id, element.isCategory ?? false);
		this.setState({ showDeleteModal: false });
	};

	deleteCategory = () => {
		const { element, deleteEquipmentCostCategory } = this.props;
		deleteEquipmentCostCategory(element._id, element.isCategory ?? false);
	};

	onMouseDownStop = (event: React.MouseEvent<HTMLElement>) => {
		event.stopPropagation();
	};

	deleteModalTitle = (label: string) => {
		const { element } = this.props;
		return `Are you sure you want to delete this ${label} (${element.name})?`;
	};
	deleteModalText = (label: string) => (`Delete ${label}`);

	deleteEquipmentCostModalTitle = () => this.deleteModalTitle('Equipment cost');
	deleteEquipmentCostModalText = () => this.deleteModalText('Equipment cost');

	deleteEquipmentCostCategoryModalTitle = () => this.deleteModalTitle('Equipment cost category');
	deleteEquipmentCostCategoryModalText = () => this.deleteModalText('Equipment cost category');

	deleteEquipmentCostGroupModalTitle = () => this.deleteModalTitle('Equipment cost group');
	deleteEquipmentCostGroupModalText = () => this.deleteModalText('Equipment cost group');

	renderOptions = (): JSX.Element => {
		const { element } = this.props;
		const items: Item[] = [];

		if (element.level === 3) {
			items.push({
				label: 'Edit',
				action: this.goToEditScreen,
			});
			items.push({
				label: 'Delete',
				action: this.deleteEquipmentCost,
				hasModal: true,
				modalTitle: this.deleteEquipmentCostModalTitle(),
				modalBody: DELETE_CONFIRMATION_BODY,
				modalText: this.deleteEquipmentCostModalText(),
			});
		} else if (element.level === 2) {
			items.push({
				label: 'Delete',
				action: this.deleteCategory,
				hasModal: true,
				modalTitle: this.deleteEquipmentCostCategoryModalTitle(),
				modalBody: DELETE_CONFIRMATION_BODY,
				modalText: this.deleteEquipmentCostCategoryModalText(),
			});
		} else if (element.level === 1) {
			items.push({
				label: 'Delete',
				action: this.deleteCategory,
				hasModal: true,
				modalTitle: this.deleteEquipmentCostGroupModalTitle(),
				modalBody: DELETE_CONFIRMATION_BODY,
				modalText: this.deleteEquipmentCostGroupModalText(),
			});
		}

		return (
			<Col md={1} onMouseDown={this.onMouseDownStop}>
				{!!items.length && <SettingsCell isFirstHalf={true} items={items} rowIndex={0} />}
			</Col>
		);
	};

	goToEditScreen = () => {
		const { element, history, companyName, location: { state: { orgAlias } } } = this.props;
		history.push(CLIENT.COMPANY.RESOURCES.EQUIPMENT_COST.EDIT(element._id.toString(), orgAlias, companyName));
	};

	openDeleteModal = () => this.setState(() => ({ showDeleteModal: true }));

	closeDeleteModal = () => this.setState(() => ({ showDeleteModal: false }));

	getShortenText = (colWidth: number, indentPixels: number, textWidth: number) => {
		const { element } = this.props;
		const { name, matchLength, nameMatchStart } = element;
		const charsToTake = `${name}`.length * (colWidth - indentPixels - 40) / textWidth;

		const nameSub = name.substr(0, charsToTake);
		let nameText = <span>{nameSub}</span>;

		if (nameMatchStart && nameMatchStart > -1) {
			nameText = (
				<>
					{nameSub.substr(0, nameMatchStart)}
					<mark>{nameSub.substr(nameMatchStart, matchLength)}</mark>
					{nameSub.substr(nameMatchStart + (matchLength ?? 0))}
				</>
			);
		}
		return (
			<>
				{nameText}
				...
			</>
		);
	};

	renderName = (): JSX.Element => {
		const { element, showScrollableTable } = this.props;
		const { name, nodeId, matchLength, nameMatchStart } = element;

		let nameText = <span>{name}</span>;
		if (nameMatchStart && nameMatchStart > -1) {
			nameText = (
				<>
					{name.substr(0, nameMatchStart)}
					<mark>{name.substr(nameMatchStart, matchLength)}</mark>
					{name.substr(nameMatchStart + (matchLength ?? 0))}
				</>
			);
		}

		const text = (
			<>
				{nameText}
			</>
		);

		const paddingsAndNavWidth = 6 * 24;
		const containerWidth = Math.min(window.innerWidth, 1800);
		const colWidth = (containerWidth - paddingsAndNavWidth) * 10 / 24;
		const indentPixels = (element.indent + 1) * 24;
		const textWidth = `${name}`.length * 8;

		if ((colWidth - indentPixels - 40) > textWidth || showScrollableTable) {
			return <b className="m-l-m" id={`node${nodeId}`}>{text}</b>;
		}

		const shortenText = this.getShortenText(colWidth, indentPixels, textWidth);
		return (
			<Tooltip
				message={<b>{text}</b>}
				placement="top"
			>
				<b className="m-l-m" id={`node${nodeId}`}>{shortenText}</b>
			</Tooltip>
		);
	};

	getClassName = () => {
		const { isHidden, isNested, element, activeNode, hasBottomBorder } = this.props;

		let cn = `tree-table__item tree-table__item--indent-${element.indent}`;
		cn = isNested ? `${cn} tree-table__item--nested` : cn;
		cn = element.toggled ? `${cn} tree-table__item--open` : cn;
		cn = element.nodeId === activeNode ? `${cn} tree-table__item--focused` : cn;
		cn = hasBottomBorder && !element.toggled ? `${cn} tree-table__item--with-bottom-border` : cn;
		cn = isHidden ? `${cn} tree-table__item--hidden` : cn;

		return cn;
	};

	onMouseDown = () => {
		this._dragging = true;
	};

	onMouseMove = () => {
		const { element } = this.props;
		if (this._dragging) {
			if (element.toggled) {
				this.toggle();
			}
			this._dragging = false;
		}
	};

	onMouseUp = () => {
		const { showScrollableTable } = this.props;
		if (this._dragging || showScrollableTable) {
			this.toggle();
		}
		if (!showScrollableTable) {
			this._dragging = false;
		}
	};

	renderBody = (props) => {
		const { element, provided = {} as DraggableProvided, showScrollableTable } = props;

		const { isCategory } = element as TreeViewModel;

		return (
			<Row className={this.getClassName()}>
				<div
					ref={provided.innerRef}
					{...provided.draggableProps}
					{...provided.dragHandleProps}
					className="tree-table__item-inner-wrapper"
				>
					<span
						className={element.isHighlighted ? 'tree-table__item-content tree-table__item--highlighted' : 'tree-table__item-content'}
						onMouseDown={!showScrollableTable && element.isCategory ? this.onMouseDown : undefined}
						onMouseMove={!showScrollableTable && element.isCategory ? this.onMouseMove : undefined}
						onMouseUp={element.isCategory ? this.onMouseUp : undefined}
					>
						{isCategory || !showScrollableTable
							? (
								<Col className="tree-table__item-content-info" md={8}>
									{this.renderDragHandle()}
									{this.renderIcon()}
									{this.renderName()}
									{this.renderCounter()}
								</Col>
							)
							: null
						}
						{this.renderEquipmentCosts()}
						{this.renderOptions()}
					</span>
					{this.renderTreeElementsDroppableZone()}
				</div>
			</Row>
		);
	};

	render() {
		const { element, index, showScrollableTable } = this.props;
		if (element.level > 0 && !showScrollableTable) {
			return (
				<Draggable
					draggableId={ResourceUtil.generateEquipmentCostTreeNodeId(element)}
					index={index}
				>
					{(provided, snapshot) => React.createElement(this.renderBody, { ...this.props, provided, snapshot } as DraggableProps, null)}
				</Draggable>
			);
		}

		return this.renderBody(this.props);
	}
}

export default TreeElement;
