import type MessageStatus from 'acceligent-shared/enums/messageStatus';

import type MessageStatusHistoryBase from 'ab-domain/models/messageStatusHistory/base';

const MESSAGE_STATUS_HISTORY_TABLE_CSV_HEADER = [
	'Created At',
	'Status',
	'Metadata',
];

class MessageStatusHistoryTableVM {
	id: number;
	/** format: `ISO_DATETIME` */
	createdAt: string;
	status: MessageStatus;
	createdMetadata: Nullable<string>;
	invalidatedMetadata: Nullable<string>;

	constructor(historyItem: MessageStatusHistoryBase) {
		this.id = historyItem.id;
		this.createdAt = historyItem.createdAt.toISOString();
		this.status = historyItem.status;
		this.createdMetadata = historyItem.createdMetadata;
		this.invalidatedMetadata = historyItem.invalidatedMetadata;
	}

	private static _constructorMap = (item: MessageStatusHistoryBase) => new MessageStatusHistoryTableVM(item);

	static bulkConstructor = (items: MessageStatusHistoryBase[]) => items.map(MessageStatusHistoryTableVM._constructorMap);

	static toCSVData(viewModels: MessageStatusHistoryTableVM[]): string[][] {
		const header: string[] = [...MESSAGE_STATUS_HISTORY_TABLE_CSV_HEADER];

		const rows = viewModels.reduce((_acc, _status) => {
			_acc.push([
				_status.createdAt,
				_status.status,
				(_status.createdMetadata ?? _status.createdMetadata) ?? '-',
			]);
			return _acc;
		}, [] as string[][]);

		return [header, ...rows];
	}
}

export default MessageStatusHistoryTableVM;
