import * as React from 'react';
import type { WrappedFieldProps, WrappedFieldInputProps } from 'redux-form';
import { FormControl } from 'react-bootstrap';

import Label from 'af-components/LockedValue/Label';
import type { OwnProps as TooltipProps } from 'af-components/Tooltip';

const TEXT_AREA_LINE_HEIGHT = 16;

interface Props extends WrappedFieldProps, WrappedFieldInputProps {
	name: string;
	id: string;
	label: string;
	placeholder: string;
	disabled?: boolean;
	value: string;
	onValueChange?: (value: string) => void;
	showMaxCharactersLabel?: boolean;
	maxCharacters?: number;
	tooltipMessage?: TooltipProps['message'];
	className?: string;
}

interface State {
	rows: number;
	minRows: number;
	maxRows: number;
}

class ResizableTextarea extends React.Component<Props, State> {

	static defaultProps: Partial<Props> = {
		showMaxCharactersLabel: true,
		maxCharacters: 750,
		className: undefined,
	};

	state: State = {
		rows: 1,
		minRows: 1,
		maxRows: 10,
	};

	componentDidUpdate(prevProps: Props) {
		const { value } = this.props;

		if (!value && prevProps.value) {
			this.setState(() => ({ rows: 1 }));
		}
	}

	onChange = async (event) => {
		const { onValueChange } = this.props;

		const { minRows, maxRows } = this.state;

		const previousRows = event.target.rows;
		event.target.rows = minRows;

		const currentRows = Math.floor(event.target.scrollHeight / TEXT_AREA_LINE_HEIGHT);

		if (currentRows === previousRows) {
			event.target.rows = currentRows;
		}

		if (currentRows >= maxRows) {
			event.target.rows = maxRows;
			event.target.scrollTop = event.target.scrollHeight;
		}

		this.setState(() => ({ rows: Math.min(currentRows, maxRows) }));

		if (onValueChange) {
			onValueChange(event.target.value);
		}
	};

	render() {
		const {
			disabled,
			label,
			placeholder,
			showMaxCharactersLabel,
			maxCharacters,
			value,
			className,
			tooltipMessage,
		} = this.props;

		const { rows } = this.state;

		return (
			<div>
				<div className="input-header">
					<Label
						label={label}
						tooltipMessage={tooltipMessage}
						withMargin={true}
					/>
					{showMaxCharactersLabel && maxCharacters && <span className="input-description pull-right">{value.length}/{maxCharacters}</span>}
				</div>
				<FormControl
					as="textarea"
					autoComplete="off"
					className={className}
					disabled={disabled}
					maxLength={maxCharacters}
					onChange={this.onChange}
					onMouseEnter={this.onChange}
					placeholder={placeholder}
					rows={rows}
					value={value}
				/>
			</div>
		);
	}
}

export default ResizableTextarea;
