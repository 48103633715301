import * as React from 'react';
import type { ConnectedProps } from 'react-redux';
import { connect } from 'react-redux';
import type { CustomRouteComponentProps } from 'react-router-dom';
import { parse } from 'query-string';
import { compose } from 'redux';

import type { ReportConfirmationVM } from 'ab-viewModels/report/publicConfirmation.viewModel';
import type PublicReportConfirmationVM from 'ab-viewModels/report/publicConfirmation.viewModel';

import * as WorkOrderActions from 'af-actions/workOrder';

import Loading from 'af-components/DocumentViewer/Loading';

import { useToggle } from 'af-utils/react.util';

import SignatureModal from '../Shared/SignatureModal';
import ReportConfirmation from './ReportConfirmation';
import Footer from './Footer/index';
import Header from './Header';
import { DeviceType, useDeviceType } from './ReportConfirmation/helpers';
import MobileSignatureModal from './ReportConfirmation/MobileSignatureModal/index';
import Sidebar from './Sidebar';
import type { ReportSidebarInfo } from './Sidebar/helpers';

import { useNotificationSnackbar } from 'af-root/hooks/useNotificationSnackbar';
import { withTaskRecovery } from 'af-root/context/taskRecoveryContext';
import { useTaskRecovery } from 'af-root/hooks/useTaskRecovery';

import SignatureForm from '../Shared/SignatureModal/FormModel';
import { useLocation, useParams } from 'react-router-dom-v5-compat';

const ALLOWED_RETRIES = 4;

interface PathParams {
	/** Company name without spaces. This is a public page, so company data cannot be fetched from Redux. */
	companyName: string;
	publicLink: string;
}

type OwnProps = CustomRouteComponentProps<PathParams>;

const connector = connect(null, mapDispatchToProps());

type Props = OwnProps & ConnectedProps<typeof connector>;

const _reportsMapper = (
	publicReports: PublicReportConfirmationVM,
	reportRefs: React.MutableRefObject<HTMLDivElement[]>,
	onReportSigning: (_workOrderId: number) => () => void
) => {
	const hasMultipleReports = publicReports.reports.length > 1;
	return (report: ReportConfirmationVM, index: number) =>
		<ReportConfirmation
			company={publicReports.company}
			hasMultipleReports={hasMultipleReports}
			index={index}
			job={publicReports.job}
			key={report.workOrder.id}
			onReportSigning={onReportSigning}
			report={report}
			reportRefs={reportRefs}
		/>;
};

const _sidebarReportsInfoMapper = (report) => ({
	name: report.workOrder.code,
	hasCustomerSignature: report.customerSignatures.length > 0,
}) as ReportSidebarInfo;

const FieldReportsPublicReport: React.FC<Props> = (props: Props) => {
	const {
		fetchPublicReports,
		signPublicReportsAsCustomer,
		downloadPublicReportsPdf,
		findOrCreatePublicReportLink,
		getWebViewUrlWhenPdfIsReady,
	} = props;

	const params = useParams();
	const location = useLocation();
	const orgAlias = React.useMemo(() => location.state.orgAlias, [location.state.orgAlias]);
	const search = React.useMemo(() => location.search, [location.search]);
	const publicLink = React.useMemo(() => params.publicLink ?? '', [params.publicLink]);
	const companyName = React.useMemo(() => params.companyName ?? '', [params.companyName]);

	const [publicReports, setPublicReports] = React.useState<Nullable<PublicReportConfirmationVM>>(null);
	const [currentReportIndex, setCurrentReportIndex] = React.useState<Nullable<number>>(null);
	const [reportsToBeSigned, setReportsToBeSigned] = React.useState<Nullable<number[]>>(null);
	const [showHeader, setShowHeader] = React.useState(true);

	const reportsRefs = React.useRef<HTMLDivElement[]>([]);
	const contentRef = React.useRef<HTMLDivElement>(null);
	const prevScrollPosition = React.useRef(0);

	const notificationSnackbar = useNotificationSnackbar();
	const taskRecovery = useTaskRecovery();

	const deviceType = useDeviceType();
	const {
		value: showSignatureModal,
		setToFalse: closeSignatureModal,
		setToTrue: openSignatureModal,
	} = useToggle(false);

	const areReportsLoaded = React.useMemo(() => !!publicReports, [publicReports]);
	const allowCustomerSignature = React.useMemo(() => !!publicReports?.job?.allowCustomerSignature, [publicReports?.job?.allowCustomerSignature]);
	const isWebView = React.useMemo<boolean>(() => parse(search).webview === 'true', [search]);

	const fetchFieldReport = React.useCallback(async (_orgAlias: string, _companyName: string, _publicLink: string) => {
		const _publicReports = await fetchPublicReports(_orgAlias, _companyName, _publicLink);
		if (!_publicReports) {
			return;
		}
		setPublicReports(_publicReports);
	}, [fetchPublicReports, setPublicReports]);

	const fetchWebViewLink = React.useCallback(async () => {

		const result = await getWebViewUrlWhenPdfIsReady(publicLink, taskRecovery, ALLOWED_RETRIES);
		if (result.url) {
			return result.url;
		}
		return undefined;
	}, [getWebViewUrlWhenPdfIsReady, publicLink, taskRecovery]);

	const submitReportSignature = React.useCallback(async (signatureForm: SignatureForm) => {

		if (!reportsToBeSigned) {
			return;
		}
		if (!allowCustomerSignature) {
			throw new Error('Report is not signable');
		}

		const data = {
			workOrderIds: reportsToBeSigned,
			signatureData: SignatureForm.toCreateCustomerSignatureRM(signatureForm),
		};
		await signPublicReportsAsCustomer(orgAlias, companyName, publicLink, data);
		setReportsToBeSigned(null);

		if (deviceType !== DeviceType.MOBILE) {
			closeSignatureModal();
		}

	}, [reportsToBeSigned, allowCustomerSignature, signPublicReportsAsCustomer, orgAlias, companyName, publicLink, deviceType, closeSignatureModal]);

	const onReportSigning = React.useCallback((_workOrderId: number) => {
		return () => setReportsToBeSigned([_workOrderId]);
	}, []);

	const onAllReportSigning = React.useCallback(() => {
		if (!publicReports?.reports?.length) return;

		const allReportIds = publicReports.reports.map((report) => report.workOrder.id);

		setReportsToBeSigned(allReportIds);
	}, [publicReports?.reports]);

	const onCloseSignatureModal = React.useCallback(() => {
		setReportsToBeSigned(null);
		closeSignatureModal();
	}, [closeSignatureModal]);

	const printReport = React.useCallback(async () => {
		if (!areReportsLoaded || !publicReports?.reports) {
			return;
		}
		const hasMultipleReports = publicReports.reports.length > 1;

		let workOrderId: number = 0;
		let workOrderCode: string = '';
		if (hasMultipleReports && currentReportIndex) {
			workOrderId = publicReports.reports[currentReportIndex].workOrder.id;
			workOrderCode = publicReports.reports[currentReportIndex].workOrder.code;
		} else {
			workOrderId = publicReports.reports[0].workOrder.id;
			workOrderCode = publicReports.reports[0].workOrder.code;
		}

		const msg = notificationSnackbar.info('Started preparing for download');
		const _publicLink = await findOrCreatePublicReportLink([workOrderId]);
		const filename = `${workOrderCode}_report`;
		notificationSnackbar.removeNotificationSnackbar(msg);
		await downloadPublicReportsPdf(_publicLink, filename, notificationSnackbar, taskRecovery, ALLOWED_RETRIES);
	}, [
		areReportsLoaded,
		publicReports?.reports,
		findOrCreatePublicReportLink,
		currentReportIndex,
		downloadPublicReportsPdf,
		notificationSnackbar,
		taskRecovery]);

	const printAllReports = React.useCallback(async () => {
		const filename = `${publicReports?.job?.title}_report`;
		await downloadPublicReportsPdf(publicLink, filename, notificationSnackbar, taskRecovery, ALLOWED_RETRIES);
	}, [downloadPublicReportsPdf, publicLink, publicReports?.job?.title, notificationSnackbar, taskRecovery]);

	const onScroll = React.useCallback(() => {
		if (!contentRef.current) {
			return;
		}
		if (contentRef.current.scrollTop >= prevScrollPosition.current && deviceType === DeviceType.MOBILE) {
			setShowHeader(false);
		} else {
			setShowHeader(true);
		}
		prevScrollPosition.current = contentRef.current.scrollTop;
	}, [deviceType]);

	React.useEffect(() => {
		if (showSignatureModal) {
			// data will refresh once modal closes
			return;
		}
		fetchFieldReport(orgAlias, companyName, publicLink);
	}, [fetchFieldReport, orgAlias, companyName, publicLink, showSignatureModal]);

	React.useEffect(() => {
		if (!reportsToBeSigned) return;
		openSignatureModal();
	});

	React.useEffect(() => {
		const observers: IntersectionObserver[] = [];
		if (!reportsRefs.current) return;
		if (reportsRefs.current) {
			reportsRefs.current.forEach((ref, index) => {
				let threshold = 0.5;
				const elementHeight = ref.getBoundingClientRect().height;

				if (elementHeight > window.innerHeight) {
					const halfView = window.innerHeight / 2;
					threshold = halfView / elementHeight;
				}

				const observer = new IntersectionObserver(
					([entry]) => {
						if (entry.isIntersecting) {
							setCurrentReportIndex(index);
						}
					},
					{
						threshold,
					}
				);
				observers.push(observer);
				observer.observe(ref);
			});
		}
		return () => {
			for (const observer of observers) {
				observer.disconnect();
			}
		};
	}, [reportsRefs, areReportsLoaded]);

	const isMobile = React.useMemo(() => deviceType === DeviceType.MOBILE, [deviceType]);
	const noOfReports = React.useMemo(() => publicReports?.reports?.length ?? 0, [publicReports?.reports?.length]);
	const hasMultipleReports = React.useMemo(() => (noOfReports ?? 0) > 1, [noOfReports]);
	const isMobileOrTablet = React.useMemo(() => (isMobile || deviceType === DeviceType.TABLET), [deviceType, isMobile]);

	const sidebarReportsInfo: Nullable<ReportSidebarInfo[]> = React.useMemo(() =>
		publicReports?.reports?.map(_sidebarReportsInfoMapper) ?? null
		, [publicReports?.reports]);

	const noOfSignedReports = React.useMemo(() => sidebarReportsInfo?.filter((report) => report.hasCustomerSignature)?.length ?? null, [sidebarReportsInfo]);
	const areAllReportsSigned = React.useMemo(() => noOfSignedReports === noOfReports, [noOfReports, noOfSignedReports]);

	const showSidebar = React.useMemo(() => hasMultipleReports && !isMobileOrTablet, [hasMultipleReports, isMobileOrTablet]);

	const isBulkSign = React.useMemo(() => !!((reportsToBeSigned?.length ?? 0) > 1), [reportsToBeSigned?.length]);
	const successStepMessage = React.useMemo(() =>
		`You have approved the current state of the report${isBulkSign ? 's' : ''}. If any changes happen, you will be notified with a final version.`
		, [isBulkSign]);

	return (
		<div className="public-report" onScroll={onScroll} ref={contentRef}>
			{showSidebar &&
				<Sidebar
					currentReportIndex={currentReportIndex}
					noOfSignedReports={noOfSignedReports}
					reportsRefs={reportsRefs}
					sidebarReportsInfo={sidebarReportsInfo}
				/>
			}
			{showHeader && (
				<Header
					currentReportIndex={currentReportIndex}
					deviceType={deviceType}
					hasMultipleReports={hasMultipleReports}
					isWebView={isWebView}
					printAllReports={printAllReports}
					printReport={printReport}
					reportsRefs={reportsRefs}
					sidebarReportsInfo={sidebarReportsInfo}
				/>
			)}
			<div className="public-report__content-container">
				{areReportsLoaded
					? <div>
						{publicReports?.reports?.map(_reportsMapper(publicReports, reportsRefs, onReportSigning)) ?? []}
					</div>
					: <div className="public-report__loading">
						<Loading />
					</div>
				}
			</div>
			<Footer
				allowCustomerSignature={allowCustomerSignature}
				areAllReportsSigned={areAllReportsSigned}
				areReportsLoaded={areReportsLoaded}
				currentReportIndex={currentReportIndex ?? 0}
				deviceType={deviceType}
				fetchWebViewPdfURL={fetchWebViewLink}
				isWebview={isWebView}
				noOfReports={noOfReports}
				noOfSignedReports={noOfSignedReports}
				onAllReportSigning={onAllReportSigning}
				printAllReports={printAllReports}
				printReport={printReport}
				reportsRefs={reportsRefs}
			/>
			{isMobile ?
				<MobileSignatureModal
					closeModal={onCloseSignatureModal}
					keepValuesOnUnmount={true}
					onSubmit={submitReportSignature}
					showModal={showSignatureModal}
					successStepMessage={successStepMessage}
				/> :
				<SignatureModal
					closeModal={onCloseSignatureModal}
					keepValuesOnUnmount={true}
					onSubmit={submitReportSignature}
					showModal={showSignatureModal}
					showNameInput={true}
					title="Add Customer Signature"
				/>
			}
		</div>
	);
};

function mapDispatchToProps() {
	return {
		fetchPublicReports: WorkOrderActions.findPublicReports,
		signPublicReportsAsCustomer: WorkOrderActions.signPublicReportsAsCustomer,
		downloadPublicReportsPdf: WorkOrderActions.downloadPublicReportPDF,
		getWebViewUrlWhenPdfIsReady: WorkOrderActions.getWebViewUrlWhenPdfIsReady,
		findOrCreatePublicReportLink: WorkOrderActions.findOrCreatePublicGroupCode,
	};
}

const enhance = compose<React.ComponentType<OwnProps>>(
	connector,
	withTaskRecovery
);

export default enhance(FieldReportsPublicReport);
