import type { ColorPalette } from 'acceligent-shared/enums/color';
import EquipmentCostCategory, { EquipmentCostCategoryLabel } from 'acceligent-shared/enums/equipmentCostCategory';
import TimeFormat from 'acceligent-shared/enums/timeFormat';

import { formatDate } from 'acceligent-shared/utils/time';
import * as ScheduleBoardSharedUtils from 'ab-utils/scheduleBoard.util';

import type DailyEquipmentStatusBase from 'ab-domain/models/dailyEquipmentStatus/base';
import type EquipmentBase from 'ab-domain/models/equipment/base';
import type EquipmentStatusBase from 'ab-domain/models/equipmentStatus/base';
import type SkillBase from 'ab-domain/models/skill/base';

import type AccountBase from 'ab-domain/models/account/base';
import type UserBase from 'ab-domain/models/user/base';

import { FAKE_ACCOUNT_DATA } from 'acceligent-shared/constants/value';

import { getUserName } from 'acceligent-shared/utils/user';

interface UpdatedByViewModel {
	accountId?: number;
	userId?: number;
	firstName?: string;
	lastName?: string;
	fullName: string;
}

class UpdatedByAccountViewModel implements UpdatedByViewModel {
	accountId: number;
	userId: number;
	firstName: string;
	lastName: string;
	fullName: string;
	fullNameShort: string;

	constructor(account: Nullable<AccountBase>) {
		const accountObject = account ?? { id: FAKE_ACCOUNT_DATA.ACCOUNT_ID } as unknown as AccountBase;
		const accountUser = accountObject.user || { id: accountObject.userId || FAKE_ACCOUNT_DATA.USER_ID } as UserBase;

		this.accountId = accountObject.id;
		this.userId = accountObject.userId;
		this.firstName = accountUser.firstName;
		this.lastName = accountUser.lastName;
		this.fullName = getUserName(accountUser);
		this.fullNameShort = getUserName(accountUser, true);
	}
}

const CSV_REQUIRED_KEYS = [
	'ID',
	'color',
	'type',
	'group',
	'category',
	'subcategory',
	'homeOffice',
	'lastUpdateAt',
	'lastUpdateBy',
];

const COMPANY_CSV_REQUIRED_KEYS = [
	...CSV_REQUIRED_KEYS,
	'showOnScheduleBoard',
	'equipmentStatus',
	'skill.0.name',
	'skill.0.color',
];

const ORGANIZATION_CSV_REQUIRED_KEYS = [
	...CSV_REQUIRED_KEYS,
	'company',
];

class EquipmentStatusVM {
	name: string;

	constructor(status: EquipmentStatusBase) {
		this.name = status.name;
	}
}

class EquipmentListItemSkillItem {
	id: number;
	name: string;
	color: ColorPalette;

	constructor(skill: SkillBase) {
		this.id = skill.id;
		this.name = skill.name;
		this.color = skill.color;
	}
}

const EMPTY_SKILL = {
	id: null,
	name: '',
	color: null,
};

class EquipmentListItemVM {
	equipmentId: number;
	equipmentCostId: number;
	equipmentCostCategoryId: number;
	equipmentCostCategoryGroupId: Nullable<number>;
	locationId: Nullable<number>;

	code: string;
	specification: string;
	color: Nullable<ColorPalette>;
	type: EquipmentCostCategoryLabel;
	groupName: string;
	categoryName: string;
	subcategoryName: string;
	locationNickname: string;
	showOnScheduleBoard: boolean;

	equipmentStatus?: EquipmentStatusVM;
	updatedAt: Date;
	updatedBy: UpdatedByViewModel;

	skills: EquipmentListItemSkillItem[];
	companyName: string;

	constructor(equipment: EquipmentBase, dueDate: Date = new Date()) {
		const subcategoryProxy = equipment?.equipmentCost;
		const categoryProxy = subcategoryProxy?.category;
		const groupProxy = categoryProxy?.group;

		this.equipmentId = equipment.id;
		this.equipmentCostId = equipment.equipmentCostId;
		this.equipmentCostCategoryId = subcategoryProxy?.categoryId;
		this.equipmentCostCategoryGroupId = categoryProxy?.groupId;
		this.locationId = equipment.locationId;

		this.code = equipment?.code ?? '';
		this.specification = equipment?.specification ?? '';
		this.color = categoryProxy?.categoryColor;
		this.type = EquipmentCostCategoryLabel[groupProxy?.type ?? EquipmentCostCategory.EQUIPMENT];
		this.groupName = groupProxy?.name ?? EquipmentCostCategory.EQUIPMENT;
		this.categoryName = categoryProxy?.name ?? '';
		this.subcategoryName = subcategoryProxy?.subcategory ?? '';
		this.locationNickname = equipment?.location?.nickname ?? '';
		this.showOnScheduleBoard = equipment.showOnScheduleBoard;

		const dueDateParsed = formatDate(dueDate, TimeFormat.DB_DATE_ONLY);
		const _dailyEquipmentStatus = ScheduleBoardSharedUtils.getEquipmentDailyStatusForDay<EquipmentBase, DailyEquipmentStatusBase>(equipment, dueDateParsed);

		if (_dailyEquipmentStatus?.equipmentStatus) {
			this.equipmentStatus = new EquipmentStatusVM(_dailyEquipmentStatus.equipmentStatus);
		}

		this.updatedAt = equipment.updatedAt;
		this.updatedBy = new UpdatedByAccountViewModel(equipment.updatedBy);

		this.skills = [];
		const skillsDict: { [skillId: number]: SkillBase; } = {};
		const equipmentCostSkills = subcategoryProxy?.equipmentCostSkills ?? [];
		for (const _equipmentCostSkill of equipmentCostSkills) {
			skillsDict[_equipmentCostSkill.skillId] = _equipmentCostSkill.skill;
		}
		const equipmentSkills = equipment?.equipmentSkills ?? [];
		for (const _equipmentSkill of equipmentSkills) {
			skillsDict[_equipmentSkill.skillId] = _equipmentSkill.skill;
		}
		for (const _skill of Object.values(skillsDict)) {
			this.skills.push(new EquipmentListItemSkillItem(_skill));
		}
		this.companyName = equipment?.company?.name;
	}
}

export class EquipmentListViewModel {
	list: EquipmentListItemVM[];

	constructor(equipments: EquipmentBase[]) {
		this.list = equipments.map((_equipment: EquipmentBase) => new EquipmentListItemVM(_equipment));
	}

	static toCSVData(viewModel: EquipmentListViewModel, isOrganizationLevel: boolean = false): string[][] {
		let maxNumberOfSkills = 1;	// if skills are able to be shown on export, the first group of columns should always be visible
		const header: string[] = isOrganizationLevel ? [...ORGANIZATION_CSV_REQUIRED_KEYS] : [...COMPANY_CSV_REQUIRED_KEYS];

		if (!isOrganizationLevel) {
			viewModel.list.forEach((_item: EquipmentListItemVM) => {
				if (_item.skills.length > maxNumberOfSkills) {
					for (let _index = maxNumberOfSkills; _index < _item.skills.length; _index++) {
						header.push(`skill.${_index}.name`, `skill.${_index}.color`);
					}
					maxNumberOfSkills = _item.skills.length;
				}
			});
		}

		const rows: string[][] = viewModel.list.map((_item: EquipmentListItemVM) => {
			const _row: string[] = [
				`${_item.code} ${_item.specification}`,
				_item.color ?? '',
				_item.type,
				_item.groupName,
				_item.categoryName,
				_item.subcategoryName,
				_item.locationNickname,
				formatDate(_item.updatedAt, TimeFormat.FULL_DATE),	// NOTE: function must be called from FE to use the user's timezone
				_item.updatedBy.fullName,
			];
			if (isOrganizationLevel) {
				_row.push(_item.companyName);
			} else {
				_row.push(
					_item.showOnScheduleBoard ? 'Yes' : 'No',
					_item?.equipmentStatus?.name ?? ''
				);
				for (let _index = 0; _index < maxNumberOfSkills; _index++) {
					const _skill = _item.skills[_index] || EMPTY_SKILL;
					_row.push(_skill.name, _skill.color);
				}
			}
			return _row;
		});

		return [header, ...rows];
	}
}
