import type { ColorPalette } from 'acceligent-shared/enums/color';

import type { ScheduleBoardSettingsForm } from 'ab-requestModels/scheduleBoard.requestModel';

import { isValidPositiveInteger } from 'ab-utils/validation.util';

// time in seconds
const MINIMUM_ROTATION_TIME = 1;
const MAXIMUM_ROTATION_TIME = 120;

export function validate(values: ScheduleBoardSettingsForm) {
	const errors: ValidationErrors = {};

	if (
		!isValidPositiveInteger(values.displayViewRotationTime)
		|| values.displayViewRotationTime < MINIMUM_ROTATION_TIME
		|| values.displayViewRotationTime > MAXIMUM_ROTATION_TIME
	) {
		errors.displayViewRotationTime = `Values in range ${MINIMUM_ROTATION_TIME} - ${MAXIMUM_ROTATION_TIME} allowed`;
	}

	(values.skillColorGrid || []).forEach((row: ColorPalette[], i1: number) => {
		row.forEach((color: ColorPalette, i2: number) => {
			if (!color) {
				if (!errors.skillColorGrid) {
					errors.skillColorGrid = [];
				}
				if (!errors.skillColorGrid[i1]) {
					errors.skillColorGrid[i1] = [];
				}
				errors.skillColorGrid[i1][i2] = 'Color is required';
			}
		});
	});

	return errors;
}
