import * as React from 'react';
import type { ConnectedProps } from 'react-redux';
import { connect } from 'react-redux';
import { Field, formValueSelector } from 'redux-form';
import { compose } from 'redux';

import type AssignableResourceType from 'acceligent-shared/enums/assignableResourceType';

import type WorkOrderResourceLookupViewModel from 'ab-viewModels/workOrderResourceLookup.viewModel';
import type TemporaryEmployeeOptionVM from 'ab-viewModels/temporaryEmployee/temporaryEmployeeOption.viewModel';

import type { RootState } from 'af-reducers';

import { WORK_ORDER_FORM } from 'af-constants/reduxForms';

import Dropdown from 'af-fields/Dropdown';

import { getSelectedTemporaryEmployeeMap } from 'af-utils/workOrderModal.util';

import TemporaryLaborOptionItem from './TemporaryLaborOptionItem';

const _getCode = <T extends { code: string; }>(element: T) => element.code;

type TemporaryLaborOption = TemporaryEmployeeOptionVM & { disabled: boolean; };

interface OwnProps {
	disabled: boolean;
	dueDate: string;
	name: string;
	onLazyLoad: (isLazyLoaded: boolean) => void;
	propName: string;
	resetTimer: () => void;
	showResourceAssignConfirmationModal: (resourceType: AssignableResourceType) => void;
	temporaryEmployees: TemporaryEmployeeOptionVM[];
}

type Props = OwnProps & ConnectedProps<typeof connector>;

const getLaborStatusReducer = (selectedEmployeeIds: { [id: number]: true; }) => {
	return (acc: TemporaryLaborOption[], employee: TemporaryEmployeeOptionVM) => {
		acc.push({
			...employee,
			disabled: !!selectedEmployeeIds[employee.id],
		});
		return acc;
	};
};

const filterLaborItem = (option: TemporaryEmployeeOptionVM, searchText: string) => {
	if (!searchText) {
		return true;
	}
	const { firstName, lastName, formattedCode, assignments: _assignments } = option;
	const assignments = _assignments?.length ? ` - ${_assignments.map(_getCode).join(', ').toLowerCase()}` : '';

	const searchWords = searchText.replace(/\s\s+/g, ' ').toLowerCase().split(' ');
	const searchableFields = [
		firstName?.toLowerCase?.() ?? '',
		lastName?.toLowerCase?.() ?? '',
		formattedCode?.toLowerCase?.() ?? '',
		assignments,
	];

	for (const _searchWord of searchWords) {
		let _matched = false;
		for (const _value of searchableFields) {
			if (_value.includes(_searchWord)) {
				_matched = true;
				break;
			}
		}
		if (!_matched) {
			// All search words must match at least one filter by prop
			return false;
		}
	}
	return true;
};

const renderLaborOptionItem = (option: TemporaryEmployeeOptionVM, searchText: string) => {
	return (
		<TemporaryLaborOptionItem
			{...option}
			searchText={searchText}
			showAgency={true}
		/>
	);
};

const renderSelectedLaborOptionItem = (option: TemporaryEmployeeOptionVM, searchText: string) => {
	return (
		<TemporaryLaborOptionItem
			{...option}
			searchText={searchText}
		/>
	);
};

const mapEmployeesToOptions = (employees: TemporaryEmployeeOptionVM[], selectedEmployeeIds: { [id: number]: true; }) => {
	return employees.reduce(getLaborStatusReducer(selectedEmployeeIds), []);
};

const TemporaryLaborItem: React.FC<Props> = (props: Props) => {
	const { name, propName, disabled, onLazyLoad, temporaryEmployees, selectedTemporaryEmployeeIds } = props;

	const [options, setOptions] = React.useState<TemporaryLaborOption[]>();

	React.useEffect(() => {
		setOptions(mapEmployeesToOptions(temporaryEmployees ?? [], selectedTemporaryEmployeeIds));
	}, [temporaryEmployees, selectedTemporaryEmployeeIds]);

	return (
		<div className="resource-lookup__item">
			<Field
				component={Dropdown}
				disabled={disabled}
				filterable={true}
				filterBy={filterLaborItem}
				hideErrorText={true}
				id={name}
				isArrayField={true}
				isButtonFullWidth={true}
				isStandalone={true}
				name={name}
				onLazyLoad={onLazyLoad}
				options={options}
				placeholder="Choose temporary laborer"
				propName={propName}
				renderMenuItem={renderLaborOptionItem}
				renderSelected={renderSelectedLaborOptionItem}
				valueKey="id"
				withCaret={true}

			/>
		</div>
	);
};

const selector = formValueSelector(WORK_ORDER_FORM);

function mapStateToProps(state: RootState) {
	const selectedResourceLookups = selector(state, 'workOrderResourceLookups') as WorkOrderResourceLookupViewModel[];
	return {
		selectedTemporaryEmployeeIds: getSelectedTemporaryEmployeeMap(selectedResourceLookups),
	};
}

const connector = connect(mapStateToProps);

const enhance = compose<React.ComponentType<OwnProps>>(
	connector,
	React.memo
);

export default enhance(TemporaryLaborItem);
