import * as React from 'react';

import TableButtonType from 'ab-enums/tableButtonType.enum';

import { filterMap } from 'ab-utils/array.util';

import type { ButtonData } from '../../types';

import styles from '../styles.module.scss';

import ExportButton from './ExportButton';
import LinkButton from './LinkButton';
import PrimaryButton from './PrimaryButton';

interface OwnProps {
	buttons: ButtonData[];
	exportAsZip?: boolean;
}

type Props = OwnProps;

const buttonHasPermission = (button: ButtonData) => button.hasPermission;

const Buttons: React.FC<Props> = (props) => {

	const { exportAsZip = false, buttons } = props;

	const renderButton = React.useCallback(({ type, onClick, label, icon, actionKey, disabled }: ButtonData) => {

		const key = `${type}#${label}`;
		const buttonId = label ? `accqa__${label.split(' ').join('-').toLowerCase()}` : undefined;

		switch (type) {
			case TableButtonType.LINK:
				return (
					<LinkButton
						icon={icon}
						id={buttonId}
						key={key}
						label={label}
						onClick={onClick}
					/>
				);
			case TableButtonType.EXPORT:
				return (
					<ExportButton
						exportAsZip={exportAsZip}
						key={key}
						onClick={onClick}
					/>
				);
			case TableButtonType.PRIMARY:
			default:
				return (
					<PrimaryButton
						actionKey={actionKey}
						disabled={disabled}
						icon={icon}
						id={buttonId}
						key={key}
						label={label}
						onClick={onClick}
					/>
				);
		}
	}, [exportAsZip]);

	return (
		<div className={styles['action-header__filters__buttons']}>
			{filterMap(buttons, buttonHasPermission, renderButton)}
		</div>
	);
};

export default React.memo(Buttons);
