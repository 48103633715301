import * as React from 'react';

import type WorkOrderModalViewModel from 'ab-viewModels/scheduleBoardWorkOrderModal.viewModel';

import LockedValue from 'af-components/LockedValue';
import SegmentLabel from 'af-components/SegmentLabel';

import { renderWorkOrderInfoData } from '..';
import Changes from '../Changes';

interface Props {
	workOrderDataToShow: WorkOrderModalViewModel;
	currentWorkOrderData: WorkOrderModalViewModel;
}

class DeliverableSection extends React.PureComponent<Props> {
	sectionHasChanges = () => {
		const { workOrderDataToShow, currentWorkOrderData } = this.props;

		return (
			// Is WO even deliverable
			(
				!!workOrderDataToShow?.deliverableCodeStandard
				|| !!currentWorkOrderData.deliverableCodeStandard
				|| !!workOrderDataToShow?.deliverableFileFormat
				|| !!currentWorkOrderData.deliverableFileFormat
				|| !!workOrderDataToShow?.deliverableSoftware
				|| !!currentWorkOrderData.deliverableSoftware
			)
			// Have there been any changes
			&& (
				(workOrderDataToShow?.deliverableCodeStandard !== currentWorkOrderData.deliverableCodeStandard)
				|| (workOrderDataToShow?.deliverableFileFormat !== currentWorkOrderData.deliverableFileFormat)
				|| (workOrderDataToShow?.deliverableSoftware !== currentWorkOrderData.deliverableSoftware)
			)
		);
	};

	render() {
		const { workOrderDataToShow, currentWorkOrderData } = this.props;

		const sectionHasChanges = this.sectionHasChanges();

		if (
			!sectionHasChanges
			&& !workOrderDataToShow?.deliverableSoftware
			&& !workOrderDataToShow?.deliverableCodeStandard
			&& !workOrderDataToShow?.deliverableFileFormat
		) {
			return null;
		}

		return (
			<>
				<div className="work-order-info-modal__deliverables-container">
					<Changes modifiedData={sectionHasChanges} />
					<SegmentLabel label="Deliverabel data" />
					<div className="work-order-info-modal__deliverables">
						<div>
							<LockedValue
								label="Deliverable Software"
								value={renderWorkOrderInfoData(workOrderDataToShow?.deliverableSoftware, currentWorkOrderData?.deliverableSoftware)}
							/>
						</div>
						<div>
							<LockedValue
								label="Deliverable Code Standard"
								value={renderWorkOrderInfoData(workOrderDataToShow?.deliverableCodeStandard, currentWorkOrderData?.deliverableCodeStandard)}
							/>
						</div>
						<div>
							<LockedValue
								label="Deliverable File Format"
								value={renderWorkOrderInfoData(workOrderDataToShow?.deliverableFileFormat, currentWorkOrderData?.deliverableFileFormat)}
							/>
						</div>
					</div>
				</div>
				<hr />
			</>
		);
	}
}

export default DeliverableSection;
