import { isValidTextInput } from 'acceligent-shared/utils/text';

import type OrganizationCheckRequestModel from 'ab-requestModels/organizationCheck.requestModel';

export default function validate(values: OrganizationCheckRequestModel) {

	const errors: ValidationErrors = {};

	if (!isValidTextInput(values.alias)) {
		errors.alias = 'Alias is required';
	}

	return errors;
}
