import * as React from 'react';

import TimeFormat from 'acceligent-shared/enums/timeFormat';
import type TimeSheetEntryWorkType from 'acceligent-shared/enums/timeSheetEntryWorkType';
import { TimeSheetWorkTypeDisplayInfo } from 'acceligent-shared/enums/timeSheetEntryWorkType';

import * as TimeUtils from 'acceligent-shared/utils/time';

import { bemElement } from 'ab-utils/bem.util';

interface Props {
	/** ISO_DATETIME */
	startTime: string;
	/** ISO_DATETIME */
	endTime: Nullable<string>;
	workType: Nullable<TimeSheetEntryWorkType>;
	workOrderCode: Nullable<string>;
	isManagerOrAdmin: boolean;
}
const TimelineTooltip: React.FC<Props> = (props: Props) => {
	const { startTime, endTime, workType, workOrderCode, isManagerOrAdmin } = props;

	const durationInMinutes = endTime ? TimeUtils.getDiff(endTime, startTime, 'minutes', TimeFormat.ISO_DATETIME) : 0;
	const durationInHoursAndMinutes = TimeUtils.minutesToHoursAndMinutes(durationInMinutes);
	const occupiedMessage = isManagerOrAdmin ? `Occupied on ${workOrderCode}` : 'Occupied';

	return (
		<div className={bemElement('time-sheet-list__row__timeline__bar', 'tooltip')}>
			<div className={bemElement('time-sheet-list__row__timeline__bar', 'tooltip__work-type')}>
				{(workType && !workOrderCode)
					? <>
						<div>{TimeSheetWorkTypeDisplayInfo[workType].label}</div>
						<span className={`icon-${TimeSheetWorkTypeDisplayInfo[workType].icon}`} />
					</>
					: occupiedMessage
				}
			</div>
			<div className={bemElement('time-sheet-list__row__timeline__bar', 'tooltip__time')}>
				From:
				<span className={bemElement('time-sheet-list__row__timeline__bar', 'tooltip__time__value')}>
					{TimeUtils.formatDate(startTime, TimeFormat.FULL_DATE, TimeFormat.ISO_DATETIME)}
				</span>
			</div>
			<div className={bemElement('time-sheet-list__row__timeline__bar', 'tooltip__time')}>
				To:
				<span className={bemElement('time-sheet-list__row__timeline__bar', 'tooltip__time__value')}>
					{
						!!endTime
							? TimeUtils.formatDate(endTime, TimeFormat.FULL_DATE, TimeFormat.ISO_DATETIME)
							: 'Ongoing'
					}
				</span>

			</div>
			{
				!!endTime &&
				<div className={bemElement('time-sheet-list__row__timeline__bar', 'tooltip__time')}>
					Duration:
					<span>
						{durationInHoursAndMinutes}
					</span>
				</div>
			}
		</div>
	);
};

export default React.memo(TimelineTooltip);
