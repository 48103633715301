import * as React from 'react';
import { Link } from 'react-router-dom';
import { Row } from 'react-bootstrap';

import type { BreadcrumbItem } from 'af-components/Breadcrumbs';
import Breadcrumbs from 'af-components/Breadcrumbs';

interface OwnProps {
	editUrl?: Nullable<string>;
	editLabel?: string;
	backToListUrl?: string;
	backToListLabel?: string;
	breadcrumbs: BreadcrumbItem[];
	isSmall?: boolean;
}

type Props = React.PropsWithChildren<OwnProps>;

class ResourcePreview extends React.PureComponent<Props> {

	static defaultProps: Partial<Props> = {
		editLabel: 'Edit',
		isSmall: false,
	};

	render() {
		const { breadcrumbs, backToListUrl, editUrl, backToListLabel, editLabel, isSmall } = this.props;

		let segmentClassName = 'form-segment';
		segmentClassName = isSmall ? `${segmentClassName} form-segment--mini` : segmentClassName;

		return (
			<div className={segmentClassName}>
				<Breadcrumbs items={breadcrumbs} />
				<div className="form-box">
					{this.props.children}
					<Row className="row--submit">
						{backToListLabel && backToListUrl && <Link className="btn btn-info" to={backToListUrl}>{backToListLabel}</Link>}
						{editUrl && <Link className="btn btn-info" to={editUrl}>{editLabel}</Link>}
					</Row>
				</div>
			</div>
		);
	}

}

export default ResourcePreview;
