import * as React from 'react';

import CustomScrollbar from 'af-components/CustomScrollbar';

import { DEFAULT_EQUIPMENT_PLACEHOLDER } from 'ab-common/constants/value';

import UnavailableEquipment from './UnavailableEquipment';
import AvailableEquipment from './AvailableEquipment';

import ResourcePlaceholder from '../../ResourcePlaceholder';

interface OwnProps {
	dueDate: string;
	isDragAndDropDisabled: boolean;
	hasPermissionsToEditScheduleBoard: boolean;
}

type Props = OwnProps;

const EquipmentSection: React.FC<Props> = (props) => {
	const { dueDate, isDragAndDropDisabled, hasPermissionsToEditScheduleBoard } = props;

	return (
		<CustomScrollbar
			contentWrapperClassName="schedule-board-toolbar-column equipment"
			isHorizontalHidden={true}
			isVerticalHidden={true}
			scrollbarsClassName="schedule-board-toolbar-column-wrapper equipment"
		>
			<ResourcePlaceholder
				dueDate={dueDate}
				isPlaceholder={true}
				label="Equipment PH"
				uniqueCode={DEFAULT_EQUIPMENT_PLACEHOLDER}
			/>
			<AvailableEquipment
				dueDate={dueDate}
				hasPermissionsToEditScheduleBoard={hasPermissionsToEditScheduleBoard}
				isDragAndDropDisabled={isDragAndDropDisabled}
			/>
			<UnavailableEquipment
				dueDate={dueDate}
				hasPermissionsToEditScheduleBoard={hasPermissionsToEditScheduleBoard}
				isDragAndDropDisabled={isDragAndDropDisabled}
			/>
		</CustomScrollbar>
	);
};

export default React.memo(EquipmentSection);
