import * as React from 'react';
import type { CellContext } from '@tanstack/react-table';

import type AssociatedSubjobVM from 'ab-viewModels/workRequest/associatedSubjob.viewModel';

import { stateAbbreviation } from 'ab-enums/states.enum';

import Label from 'af-components/Label';
import SimpleTableControl from 'af-components/Controls/SimpleTable';
import type { Column } from 'af-components/Controls/SimpleTable/types';
import { tableBlueTextClass, tableCellTextClass, tableLightTextClass, tableUppercaseTextClass } from 'af-components/SharedPreviews/WorkRequest/helpers';
import type { TableProps } from 'af-components/Table/types';
import TextCell from 'af-components/Table/Cells/TextCell';

import styles from './styles.module.scss';

interface OwnProps {
	redirectToVirtualFieldReport: (subjob: AssociatedSubjobVM) => void;
	allocations: Nullable<AssociatedSubjobVM[]>;
	fetchAssociatedSubJobs: () => Promise<void>;
}

type Props = OwnProps;

const WorkOrderAssociatedSubjobData: React.FC<Props> = (props: Props) => {
	const {
		redirectToVirtualFieldReport,
		allocations,
		fetchAssociatedSubJobs,
	} = props;

	const onRedirect = React.useCallback((_cell: CellContext<AssociatedSubjobVM, number>) => () => {
		redirectToVirtualFieldReport(_cell.row.original);
	}, [redirectToVirtualFieldReport]);

	const renderLinkCell = React.useCallback(() =>
		(_cell: CellContext<AssociatedSubjobVM, number>) => {

			return <div className={styles['subjob-link']}>
				<span
					className="icon-external"
					onClick={onRedirect(_cell)}
				/>
			</div>;
		}, [onRedirect]);

	const renderTextCell = React.useCallback((isUppercase: boolean) => (cell: CellContext<AssociatedSubjobVM, string>) => {

		const value = cell.getValue();
		const baseStyle = isUppercase ? tableUppercaseTextClass : tableCellTextClass;
		const style = !!value ? baseStyle : tableLightTextClass;

		return (
			<TextCell
				className={style}
				value={value ?? 'N/A'}
			/>
		);
	}, []);

	const renderJobIdCell = React.useCallback(() => (cell: CellContext<AssociatedSubjobVM, string>) => {
		return (
			<>
				<Label
					className="subjob__margin"
					text="S"
				/>
				<TextCell
					className={tableBlueTextClass}
					value={cell.getValue() ?? 'N/A'}
				/>
			</>

		);
	}, []);

	const renderStateCell = React.useCallback(() => (cell: CellContext<AssociatedSubjobVM, string>) => {

		const cellValue = cell.getValue();
		const style = !!cellValue ? tableCellTextClass : tableLightTextClass;

		const value = cellValue
			? stateAbbreviation[cellValue] ?? cellValue
			: 'N/A';

		return (
			<TextCell
				className={style}
				value={value}
			/>
		);
	}, []);

	const columns: TableProps<AssociatedSubjobVM>['columns'] = React.useMemo(() => [
		{
			id: 'id',
			accessor: 'id',
			header: '',
			cell: renderLinkCell(),
			isFixed: false,
			enableSorting: false,
			size: 50,
		},
		{
			id: 'jobCode',
			accessor: 'jobCode',
			header: 'Job ID',
			cell: renderJobIdCell(),
			isFixed: false,
			enableSorting: false,
		},
		{
			id: 'title',
			accessor: 'title',
			header: 'Job Name',
			cell: renderTextCell(true),
			isFixed: false,
			enableSorting: false,
		},
		{
			id: 'customerCompany',
			accessor: 'customerCompany',
			header: 'Customer Company',
			cell: renderTextCell(true),
			isFixed: false,
			enableSorting: false,
		},
		{
			id: 'city',
			accessor: 'city',
			header: 'City',
			cell: renderTextCell(false),
			isFixed: false,
			enableSorting: false,
		},
		{
			id: 'state',
			accessor: 'state',
			header: 'State',
			cell: renderStateCell(),
			isFixed: false,
			enableSorting: false,
		},
		{
			id: 'office',
			accessor: 'office',
			header: 'Office',
			cell: renderTextCell(false),
			isFixed: false,
			enableSorting: false,
		},
		{
			id: 'projectManager',
			accessor: 'projectManager',
			header: 'Project Manager',
			cell: renderTextCell(true),
			isFixed: false,
			enableSorting: false,
		},
		{
			id: 'startDate',
			accessor: 'startDate',
			header: 'Start Date',
			cell: renderTextCell(false),
			isFixed: false,
			enableSorting: false,
		},
	], [renderJobIdCell, renderLinkCell, renderStateCell, renderTextCell]);

	const getData = React.useCallback(async () => {
		await fetchAssociatedSubJobs();
	}, [fetchAssociatedSubJobs]);

	React.useEffect(() => {
		getData();
	}, [getData]);

	if (allocations === null) {
		return null;
	}

	return (
		<div className={styles['associated-subjob-data-container']}>
			<span className={styles['associated-subjob-data-header']}>
				Associated Sub-job Data:
			</span>
			{
				allocations.length === 0 &&
				<span className={styles['associated-subjob-data-text']}>
					Data associated with different Sub-jobs will be shown here. You can do this from the "Associate with:" dropdown.
				</span>
			}
			{
				(!!allocations && allocations.length > 0) &&
				<SimpleTableControl<AssociatedSubjobVM>
					columns={columns as Column<AssociatedSubjobVM>[]}
					rows={allocations}
				/>
			}

		</div>
	);
};

export default React.memo(WorkOrderAssociatedSubjobData);
