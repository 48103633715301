import * as React from 'react';
import type { WrappedFieldArrayProps } from 'redux-form';
import { Button } from 'react-bootstrap';

import type { ContactForInvoicesVM } from 'ab-viewModels/contact/contactsForInvoices.viewModel';

import type { InvoiceFM } from '../CreateOrEdit/formModel';
import styles from '../styles.module.scss';
import BillingContactEdit from './BillingContactEdit';

export type InvoiceBillingContactFM = Required<InvoiceFM>['billingContacts'][0];

export type CustomFormErrors<Model> = { [K in keyof Model]: Model[K] extends object ? CustomFormErrors<Model[K]> : string };
export type BillingContactErrors = CustomFormErrors<InvoiceBillingContactFM[]>;

export interface OwnProps {
	change: (fieldName: string, value: Metadata | string | number | boolean | null) => void;
	billingContacts: Nullable<ContactForInvoicesVM[]>;
}

type Props = OwnProps & WrappedFieldArrayProps<Nullable<InvoiceBillingContactFM>>;

const BillingContactArray: React.FC<Props> = React.memo((props) => {
	const {
		fields,
		change,
		billingContacts,
	} = props;

	const onAdd = React.useCallback(() => {
		fields.push(null);
	}, [fields]);

	const onRemove = React.useCallback((index: number) => {
		fields.remove(index);
	}, [fields]);

	const renderBillingContact = React.useCallback((field: string, index: number) => {
		const billingContact = fields.get(index);

		return (
			<BillingContactEdit
				billingContacts={billingContacts ?? []}
				change={change}
				currentContact={billingContact}
				fieldName={field}
				index={index}
				key={index}
				onRemove={onRemove}
				orderNumber={index + 1}
			/>
		);
	}, [billingContacts, change, fields, onRemove]);

	return (
		<>
			{
				!!fields.length && (
					<>
						<div className={styles['billing-contacts__hint']}>
							Choose a contact from the existing list or create a new one.
							Alternatively, keep typing an email address (e.g., user.name@email.com) to use.
						</div>
						<div className={styles['billing-contacts__list__items']}>{
							fields.map(renderBillingContact)}
						</div>
					</>
				)
			}
			<Button className="btn btn--link" onClick={onAdd}>
				<span className="icon-plus" />
				<span>Add Billing Contact Email</span>
			</Button>
		</>
	);
});

export default BillingContactArray;
