import * as React from 'react';
import { compose } from 'redux';
import type { ResolveThunks } from 'react-redux';
import { connect } from 'react-redux';
import type { InjectedFormProps } from 'redux-form';
import { Field, FieldArray } from 'redux-form';
import { Row, Col, Button } from 'react-bootstrap';

import BlobStorageImageSizeContainer from 'acceligent-shared/enums/blobStorageImageSizeContainer';

import Input from 'af-fields/Input';
import Textarea from 'af-fields/Textarea';
import type { OwnProps as MultiTagSelectProps } from 'af-fields/MultiTagSelect';
import MultiTagSelect from 'af-fields/MultiTagSelect';

import SubmitButton from 'af-components/SubmitButton';
import ImageTag from 'af-components/Image';
import ImageUpload from 'af-components/ImageUpload';
import BackButton from 'af-components/BackButton';
import SegmentLabel from 'af-components/SegmentLabel';

import * as SkillActions from 'af-actions/skill';

import type { SkillViewModel } from 'ab-viewModels/skill.viewModel';

import type { EquipmentCostRequestModel } from 'ab-requestModels/equipmentCost.requestModel';

import { DEFAULT_EQUIPMENT_IMAGE } from 'ab-common/constants/value';

import Header from './Header';

import SkillOption from '../Shared/SkillOption';
import SkillMultiValueLabel from '../Shared/SkillMultiValueLabel';

import * as ReduxUtils from 'ab-utils/reduxForms.util';
import { bemElement } from 'ab-utils/bem.util';

interface OwnProps extends InjectedFormProps<EquipmentCostRequestModel> {
	formName: string;
	onSubmit: (form: EquipmentCostRequestModel) => Promise<void>;
}

interface DispatchProps {
	findAllForCompanyByQuery: typeof SkillActions.findAllForCompanyByQuery;
}

type ConnectOwnProps = OwnProps;

type Props = ConnectOwnProps & ResolveThunks<DispatchProps>;

interface State {
	image: File | string | null;
	imageUrl: string | null;
	deleteImage: boolean;
	hasReceivedInitialItem: boolean;
	skillList: SkillViewModel[];
	showUploadModal: boolean;
}

class EquipmentCostForm extends React.PureComponent<Props, State> {

	state: State = {
		image: null,
		imageUrl: null,
		deleteImage: false,
		hasReceivedInitialItem: false,
		skillList: [],
		showUploadModal: false,
	};

	static getDerivedStateFromProps(props: Props, state: State) {
		const { initialValues } = props;
		const { hasReceivedInitialItem } = state;

		return (!hasReceivedInitialItem && initialValues)
			? {
				hasReceivedInitialItem: true,
				imageUrl: initialValues.image as string,
			}
			: null;
	}

	async componentDidMount() {
		const { findAllForCompanyByQuery } = this.props;
		const skills = await findAllForCompanyByQuery('');
		this.setState(() => ({ skillList: skills }));
	}

	onSubmit = async (form: EquipmentCostRequestModel) => {
		const { onSubmit } = this.props;
		const { deleteImage, image } = this.state;

		form.image = image;
		form.deleteImage = deleteImage;
		await onSubmit(form);
	};

	deleteImage = () => this.setState(() => ({ image: null, imageUrl: null, deleteImage: true }));

	onImageUploadSave = (image: File, imageUrl: string) => {
		this.setState(() => ({
			image,
			imageUrl,
			deleteImage: false,
		}));
	};

	getOptionValue = (option: SkillViewModel) => `${option.id}`;

	getOptionLabel = (option: SkillViewModel) => option.name;

	openUploadModal = () => this.setState(() => ({ showUploadModal: true }));
	closeUploadModal = () => this.setState(() => ({ showUploadModal: false }));

	render() {
		const {
			handleSubmit,
			formName,
			change,
			submitting,
			invalid,
		} = this.props;
		const { image, imageUrl, skillList, showUploadModal } = this.state;

		const imageUploadButtonClassName = bemElement('image-upload', 'button');

		return (
			<form className="equipment-cost-upsert" onSubmit={handleSubmit(this.onSubmit)}>
				<div className="form-box">
					<Header
						changeField={change}
						formName={formName}
					/>
					<Row className="row row--flex">
						<div className="avatar__container">
							<ImageUpload
								closeModal={this.closeUploadModal}
								onSave={this.onImageUploadSave}
								showModal={showUploadModal}
							/>
							<div className="avatar">
								<ImageTag
									fallbackSrc={DEFAULT_EQUIPMENT_IMAGE}
									minSize={BlobStorageImageSizeContainer.SIZE_200X200}
									src={imageUrl}
									tryOriginal={true}
									tryRoot={true}
								/>
							</div>
							<div className={bemElement('image-upload', 'buttons')}>
								<Button
									className={imageUploadButtonClassName}
									onClick={this.openUploadModal}
									variant="info"
								>
									<strong className="icon-upload" />
								</Button>
								<Button
									className={imageUploadButtonClassName}
									disabled={!image && !imageUrl}
									onClick={this.deleteImage}
									variant="danger"
								>
									<strong className="icon-delete" />
								</Button>
							</div>
						</div>
						<div>
							<Row className="row--padded-top">
								<Col sm={24}>
									<Field
										component={Input}
										label="Name (Subcategory) *"
										name="subcategory"
										placeholder="Enter subcategory"
										type="text"
									/>
								</Col>
							</Row>
						</div>
					</Row>
					<Row>
						<Col md={24}>
							<SegmentLabel label="Costs" />
						</Col>
					</Row>
					<Row>
						<Col lg={4} md={8} sm={8}>
							<Field
								component={Input}
								format={ReduxUtils.formatDecimalNumber}
								isDollarValue={true}
								label="Daily cost"
								name="dailyCost"
								normalize={ReduxUtils.normalizeDecimalNumber}
								placeholder="Enter Daily cost"
								type="text"
							/>
						</Col>
						<Col lg={4} md={8} sm={8}>
							<Field
								component={Input}
								format={ReduxUtils.formatDecimalNumber}
								isDollarValue={true}
								label="Weekly cost"
								name="weeklyCost"
								normalize={ReduxUtils.normalizeDecimalNumber}
								placeholder="Enter Weekly cost"
								type="text"
							/>
						</Col>
						<Col lg={4} md={8} sm={8}>
							<Field
								component={Input}
								format={ReduxUtils.formatDecimalNumber}
								isDollarValue={true}
								label="Monthly cost"
								name="monthlyCost"
								normalize={ReduxUtils.normalizeDecimalNumber}
								placeholder="Enter Monthly cost"
								type="text"
							/>
						</Col>
						<Col lg={4} md={8} sm={8}>
							<Field
								component={Input}
								format={ReduxUtils.formatDecimalNumber}
								isDollarValue={true}
								label="Mob charge"
								name="mobCharge"
								normalize={ReduxUtils.normalizeDecimalNumber}
								placeholder="Enter Mob charge"
								type="text"
							/>
						</Col>
						<Col lg={4} md={8} sm={8}>
							<Field
								component={Input}
								format={ReduxUtils.formatDecimalNumber}
								isDollarValue={true}
								label="Operating charge"
								name="operatingCharge"
								normalize={ReduxUtils.normalizeDecimalNumber}
								placeholder="Enter Operating charge"
								type="text"
							/>
						</Col>
						<Col lg={4} md={8} sm={8}>
							<Field
								component={Input}
								format={ReduxUtils.formatDecimalNumber}
								isDollarValue={true}
								label="Fuel cost"
								name="fuelCost"
								normalize={ReduxUtils.normalizeDecimalNumber}
								placeholder="Enter Fuel cost"
								type="text"
							/>
						</Col>
					</Row>
					<Row>
						<Col sm={24}>
							<SegmentLabel label="Needed Skills and Licenses" />
						</Col>
					</Row>
					<Row>
						<Col md={12}>
							<FieldArray<MultiTagSelectProps<SkillViewModel>>
								component={MultiTagSelect}
								getOptionLabel={this.getOptionLabel}
								getOptionValue={this.getOptionValue}
								label="Skills"
								menuPlacement="top"
								MultiValueLabel={SkillMultiValueLabel}
								name="skills"
								Option={SkillOption}
								options={skillList}
								placeholder="Choose skills"
							/>
						</Col>
						<Col md={12}>
							<Field
								component={Textarea}
								controlCursor={true}
								label="Licenses"
								maxCharacters={750}
								name="licenses"
								placeholder="Enter Licenses"
								rows={3}
								showMaxCharactersLabel={true}
								type="text"
							/>
						</Col>
					</Row>
					<Row className="row--submit">
						<BackButton />
						<SubmitButton
							disabled={invalid}
							reduxFormSubmitting={submitting}
							variant="primary"
							variantDisabled="info"
						/>
					</Row>
				</div>
			</form>
		);
	}
}

function mapDispatchToProps(): DispatchProps {
	return {
		findAllForCompanyByQuery: SkillActions.findAllForCompanyByQuery,
	};
}

const enhance = compose<React.ComponentClass<OwnProps>>(
	connect<null, DispatchProps, ConnectOwnProps>(null, mapDispatchToProps())
);

export default enhance(EquipmentCostForm);
