import * as React from 'react';
import type { HandleThunkActionCreator } from 'react-redux';
import type { InjectedFormProps } from 'redux-form';
import { reduxForm } from 'redux-form';
import { Button } from 'react-bootstrap';

import type * as CompanyActions from 'af-actions/companies';

import type UserGroupRequestModel from 'ab-requestModels/userGroup.requestModel';

import SubmitButton from 'af-components/SubmitButton';
import CustomModal from 'af-components/CustomModal';

import { SPECIAL_EMAIL_NOTIFICATIONS } from 'af-constants/reduxForms';

import GeneralNotificationsForm from './Form';
import { validate } from './validation';
import type { SpecialEmailsForm} from '../formModel';
import { SpecialEmails } from '../formModel';

interface OwnProps {
	isVisible: boolean;
	userGroupsForCompany: UserGroupRequestModel[];
	editSpecialEmailNotifications: HandleThunkActionCreator<typeof CompanyActions.editSpecialEmailNotifications>;
	toggleEdit: () => void;
}

type Props = OwnProps & InjectedFormProps<SpecialEmailsForm, OwnProps>;

class SpecialEmailsComponent extends React.PureComponent<Props> {

	editCompanyFeatureFlags = async (form: SpecialEmailsForm) => {
		const { toggleEdit, editSpecialEmailNotifications } = this.props;

		await editSpecialEmailNotifications(SpecialEmails.fromForm(form));
		toggleEdit();
	};

	onClose = () => {
		const { toggleEdit, reset } = this.props;
		reset();
		toggleEdit();
	};

	render() {
		const { handleSubmit, isVisible, invalid, submitting } = this.props;

		return (
			<CustomModal
				closeModal={this.onClose}
				modalStyle="info"
				showModal={isVisible}
				size="xxl"
			>
				<CustomModal.Header
					closeModal={this.onClose}
					title="Edit Special Emails"
				/>
				<CustomModal.Body padding="none">
					<GeneralNotificationsForm
						{...this.props}
						formName={SPECIAL_EMAIL_NOTIFICATIONS}
						onSubmit={this.editCompanyFeatureFlags}
					/>
				</CustomModal.Body>
				<CustomModal.Footer>
					<Button
						id="accqa__notification-settings__special-emails__cancel"
						onClick={this.onClose}
						variant="info"
					>
						Cancel
					</Button>
					<SubmitButton
						disabled={invalid}
						id="accqa__notification-settings__special-emails__save"
						label="Save"
						onClick={handleSubmit(this.editCompanyFeatureFlags)}
						reduxFormSubmitting={submitting}
					/>
				</CustomModal.Footer>
			</CustomModal>
		);
	}
}

export default reduxForm<SpecialEmailsForm, OwnProps>({ form: SPECIAL_EMAIL_NOTIFICATIONS, enableReinitialize: true, validate })(SpecialEmailsComponent);
