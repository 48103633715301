import type ImportContactRM from 'ab-requestModels/contact/import';

import type { CSVRow, CSVData } from 'ab-viewModels/csv.viewModel';

const PROPS = {
	CONTACT: {
		FULL_NAME: 'fullName',
		COMPANY_NAME: 'companyName',
		CONTACT_ROLE: 'contactRole',
		ADDRESSES: 'addresses',
		PHONES: 'phones',
		EMAILS: 'emails',
	},
	ADDRESS: {
		AA1: 'aa1',
		AA2: 'aa2',
		AA3: 'aa3',
		COUNTRY: 'country',
		LATITUDE: 'latitude',
		LONGITUDE: 'longitude',
		LOCALITY: 'locality',
		POSTAL_CODE: 'postalCode',
		ROUTE: 'route',
		STREET: 'street',
		STREET_NUMBER: 'streetNumber',
		SUITE: 'suite',
	},
	PHONE: {
		COUNTRY_CODE: 'countryCode',
		VALUE: 'value',
		TYPE: 'type',
		EXTENSION: 'extension',
	},
	EMAIL: {
		VALUE: 'value',
	},
};

interface CSVIdToArrayIdxMapper {
	[csvId: string]: { idx: number; };
}

const parseRow = (row: CSVRow): ImportContactRM => {
	const cc = {
		fullName: '',
		companyName: '',
		addresses: [],
		phones: [],
		emails: [],
	} as ImportContactRM;
	const elementIdx = {} as CSVIdToArrayIdxMapper;

	Object.entries(row).forEach(([_column, _value]) => {
		if (!_value) {
			return;
		}

		const properties = _column.split('.');
		const [ccProp] = properties;

		switch (ccProp) {
			case PROPS.CONTACT.FULL_NAME:
			case PROPS.CONTACT.COMPANY_NAME:
			case PROPS.CONTACT.CONTACT_ROLE:
				cc[ccProp] = _value;
				break;
			case PROPS.CONTACT.ADDRESSES:
			case PROPS.CONTACT.EMAILS:
			case PROPS.CONTACT.PHONES:
				const [, elementId, elementProp] = properties;
				const elements = cc[ccProp] as Metadata[];
				const csvId = `${ccProp}.${elementId}`;

				if (!elementIdx[csvId]) {
					elementIdx[csvId] = { idx: elements.length };
					elements.push({});
				}

				const { idx } = elementIdx[csvId];

				switch (elementProp) {
					case PROPS.ADDRESS.LATITUDE:
					case PROPS.ADDRESS.LONGITUDE:
						elements[idx][elementProp] = parseFloat(_value);
						break;
					case PROPS.PHONE.COUNTRY_CODE:
					case PROPS.PHONE.VALUE:
					case PROPS.PHONE.TYPE:
					case PROPS.PHONE.EXTENSION:
					case PROPS.EMAIL.VALUE:
					case PROPS.ADDRESS.AA1:
					case PROPS.ADDRESS.AA2:
					case PROPS.ADDRESS.AA3:
					case PROPS.ADDRESS.COUNTRY:
					case PROPS.ADDRESS.LOCALITY:
					case PROPS.ADDRESS.ROUTE:
					case PROPS.ADDRESS.STREET:
					case PROPS.ADDRESS.POSTAL_CODE:
					case PROPS.ADDRESS.STREET_NUMBER:
					case PROPS.ADDRESS.SUITE:
					default:
						elements[idx][elementProp] = _value;
				}
				break;
		}
	});

	// set defaults:
	cc.addresses = cc.addresses.map((_adr) => ({
		..._adr,
		latitude: _adr.latitude || 0,
		longitude: _adr.longitude || 0,
	} as ImportContactRM['addresses'][0]));

	return cc;
};

export default (data: CSVData): ImportContactRM[] => data.map(parseRow);
