import * as React from 'react';
import { Button } from 'react-bootstrap';

import { bemElement } from 'ab-utils/bem.util';

import LoadingIndicator from 'af-components/LoadingIndicator';

interface Props {
	name: string;
	isWebView: boolean;
	handlePrintClick: () => void;
	fetchWebViewPdfURL?: () => Promise<string | undefined>;
	isWebViewFetchAvailable?: boolean;
	isPrintDisabled: boolean;
}

const PrintButton: React.FC<Props> = (props: Props) => {

	const { isWebView, handlePrintClick, isPrintDisabled, name, isWebViewFetchAvailable, fetchWebViewPdfURL } = props;

	let webViewName = '';
	if (isWebView) {
		webViewName = isWebViewFetchAvailable ? name : 'Preparing Export';
	}

	// We are sending url of report's PDF to our mobile app by using WebView's "postMessage" function to handle download there due to webview not supporting
	// download using blobUrl
	// See:
	// https://github.com/react-native-webview/react-native-webview/blob/master/docs/Guide.md#the-windowreactnativewebviewpostmessage-method-and-onmessage-prop
	const onWebViewPrintClick = React.useCallback(async () => {

		// eslint-disable-next-line @typescript-eslint/ban-ts-comment
		// @ts-ignore
		if (!window.ReactNativeWebView || !fetchWebViewPdfURL) {
			return;
		}

		const url = await fetchWebViewPdfURL();
		if (url) {
			// eslint-disable-next-line @typescript-eslint/ban-ts-comment
			// @ts-ignore
			window.ReactNativeWebView.postMessage(url);
		}

	}, [fetchWebViewPdfURL]);

	return (isWebView
		? <div className={bemElement('public-report__footer__print-sign--webview', 'print-button')}>
			<a className={`btn btn-info ${!isWebViewFetchAvailable ? 'disabled' : ''}`} onClick={onWebViewPrintClick}>
				{isPrintDisabled ? <LoadingIndicator color="black" size="small" /> : `${webViewName}`}
			</a>
		</div>
		: (
			<Button disabled={isPrintDisabled} onClick={handlePrintClick} variant="info">
				{isPrintDisabled ? <LoadingIndicator color="black" size="small" /> : `${name}`}
			</Button>
		)
	);
};

export default PrintButton;
