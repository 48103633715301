import type { Dispatch, AnyAction } from 'redux';

import API from 'af-constants/routes/api';

import { http } from 'af-utils/http.util';
import { errorHandler } from 'af-utils/actions.util';

import type ItemCategoryVM from 'ab-viewModels/itemCategory/itemCategory.viewModel';

import type ItemCategoryRM from 'ab-requestModels/itemCategory/itemCategory.requestModel';

import type { GetRootState } from 'af-reducers';

export function create(form: ItemCategoryRM) {
	return async (dispatch: Dispatch<AnyAction>, getState: GetRootState, { redirectTo }) => {
		const action = async () => {
			return await http.post<ItemCategoryVM>(API.V1.RESOURCES.ITEM_CATEGORY.CREATE, form);
		};
		return await errorHandler(action, dispatch, redirectTo);
	};
}

export function update(id: number, form: ItemCategoryRM) {
	return async (dispatch: Dispatch<AnyAction>, getState: GetRootState, { redirectTo }) => {
		const action = async () => {
			await http.put(API.V1.RESOURCES.ITEM_CATEGORY.EDIT(`${id}`), form);
		};
		return await errorHandler(action, dispatch, redirectTo);
	};
}

export function remove(id: number) {
	return async (dispatch: Dispatch<AnyAction>, getState: GetRootState, { redirectTo }) => {
		const action = async () => {
			await http.delete(API.V1.RESOURCES.ITEM_CATEGORY.REMOVE(`${id}`));
		};
		return await errorHandler(action, dispatch, redirectTo);
	};
}

export function findList() {
	return async (dispatch: Dispatch<AnyAction>, getState: GetRootState, { redirectTo }) => {
		const action = async () => {
			return await http.get<ItemCategoryVM[]>(API.V1.RESOURCES.ITEM_CATEGORY.FIND_LIST);
		};

		return await errorHandler(action, dispatch, redirectTo);
	};
}
