import * as React from 'react';
import type { ConnectedProps } from 'react-redux';
import { connect } from 'react-redux';
import { Field } from 'redux-form';

import * as WageRateActions from 'af-actions/wageRate';
import * as SkillActions from 'af-actions/skill';

import type { WageRateVM } from 'ab-viewModels/wageRate/wageRate.viewModel';
import type { SkillViewModel } from 'ab-viewModels/skill.viewModel';

import Dropdown from 'af-fields/Dropdown';
import MultiselectDropdown from 'af-fields/MultiselectDropdown';

import SkillsColorGrid from 'af-components/SkillsColorGrid';

import LaborPlaceholderOptionItem from './LaborPlaceholderOptionItem';
import LaborPlaceholderSkillOptionItem from './LaborPlaceholderSkillOptionItem';

interface OwnProps {
	name: string;
	propName: string;
	skillPropName: string;
	disabled: boolean;
	resetTimer: () => void;
}

type Props = OwnProps & ConnectedProps<typeof connector>;

interface State {
	skillOptions: SkillViewModel[];
	wageRateOptions: WageRateVM[];
}

class LaborPlaceholderItem extends React.PureComponent<Props, State> {

	static readonly PLACEHOLDER_FILTER_BY: (keyof WageRateVM)[] = ['type', 'wageClassification'];
	static readonly SKILL_FILTER_BY: (keyof SkillViewModel)[] = ['name', 'color'];

	state: State = {
		skillOptions: [],
		wageRateOptions: [],
	};

	static renderLaborPlaceholderOptionItem = (option: WageRateVM, searchText: string) => <LaborPlaceholderOptionItem {...option} searchText={searchText} />;

	static renderLaborPlaceholderSkillOptionItem = (option: SkillViewModel, searchText: string) => (
		<LaborPlaceholderSkillOptionItem {...option} searchText={searchText} />
	);

	static renderSelectedLaborPlaceholderSkillOptionItem = (skills: SkillViewModel[]) => <SkillsColorGrid skills={skills} withBackground={false} />;

	lazyLoadWageRates = async (isLazyLoaded: boolean) => {
		const { findAllWageRatesForCompany } = this.props;
		if (!isLazyLoaded) {
			const wageRateOptions = await findAllWageRatesForCompany();
			this.setState(() => ({ wageRateOptions }));
		}
	};

	lazyLoadSkills = async (isLazyLoaded: boolean) => {
		const { findAllSkillsForCompany } = this.props;
		if (!isLazyLoaded) {
			const skills = await findAllSkillsForCompany();
			this.setState(() => ({ skillOptions: Object.values(skills).flat() }));
		}
	};

	render() {
		const {
			name,
			propName,
			skillPropName,
			resetTimer,
			disabled,
		} = this.props;
		const { skillOptions, wageRateOptions } = this.state;

		return (
			<div className="resource-lookup__item resource-lookup__item--placeholder">
				<Field
					component={Dropdown}
					disabled={disabled}
					filterable={true}
					filterBy={LaborPlaceholderItem.PLACEHOLDER_FILTER_BY}
					hideErrorText={true}
					id={name}
					isArrayField={true}
					isStandalone={true}
					name={name}
					onLazyLoad={this.lazyLoadWageRates}
					onValueChange={resetTimer}
					options={wageRateOptions}
					placeholder="Choose labor placeholder"
					propName={propName}
					renderMenuItem={LaborPlaceholderItem.renderLaborPlaceholderOptionItem}
					valueKey="id"
					withCaret={true}
				/>
				<Field
					component={MultiselectDropdown}
					containerClassName="resource-lookup__item-skill"
					disabled={disabled}
					filterable={true}
					filterBy={LaborPlaceholderItem.SKILL_FILTER_BY}
					fixed={true}
					hideErrorText={true}
					id={skillPropName}
					isArrayField={true}
					isStandalone={true}
					name={skillPropName}
					onLazyLoad={this.lazyLoadSkills}
					onValueChange={resetTimer}
					options={skillOptions}
					renderMenuItem={LaborPlaceholderItem.renderLaborPlaceholderSkillOptionItem}
					renderSelected={LaborPlaceholderItem.renderSelectedLaborPlaceholderSkillOptionItem}
					valueKey="id"
					withCaret={true}
				/>
			</div>
		);
	}

}

function mapDispatchToProps() {
	return {
		findAllWageRatesForCompany: WageRateActions.findAllForCompany,
		findAllSkillsForCompany: SkillActions.findAllForCompany,
	};
}

const connector = connect(null, mapDispatchToProps());

export default connector(LaborPlaceholderItem);
