import * as ActionTypes from 'af-constants/actionTypes';

import type { GeneralStoreState } from 'af-models/general.models';

import type { GeneralAction } from 'af-actions/general';

export const initialState: GeneralStoreState = {
	isDialogFocused: false,
	isAppReady: false,
	showRefreshModal: false,
};
export default (state: GeneralStoreState = initialState, action: GeneralAction): GeneralStoreState => {
	switch (action.type) {
		case ActionTypes.APP_READY:
			return { ...state, isAppReady: true };
		case ActionTypes.SHOW_REFRESH_MODAL:
			return { ...state, showRefreshModal: true };
		case ActionTypes.HIDE_REFRESH_MODAL:
			return { ...state, showRefreshModal: false };
		default:
			return state;
	}
};
