import type QuantityUnitType from 'acceligent-shared/enums/quantityUnit';
import { QuantityUnitMap } from 'acceligent-shared/enums/quantityUnit';

import { MAX_BILLABLE_WORK_DEFINITION_FIELDS } from 'ab-common/constants/value';

import { convertUnits } from 'ab-utils/unitConversion.util';

const resolveCompanyWorkSummaryTableHeadersForCSVExport = (maxNumberOfDefFields: number, detailed: boolean) => {
	const defFields: string[] = [];
	for (let i = 0; i < maxNumberOfDefFields; i++) {
		defFields.push(`Definition ${i + 1}`);
	}

	const basicHeaders = [
		'Work Request',
		'WorkOrder',
		'Date',
		'Quantity',
		'Description',
		'Billing Code',
		'Unit',
		'Unit Price per billingCode Unit ($)',
		'Unit Price per quantity Unit ($)',
		'Revenue ($)',
	];

	const detailedHeaders = [
		'Work',
		'Type',
		...defFields,
	];

	return detailed ? [...basicHeaders, ...detailedHeaders] : basicHeaders;
};

const resolveMaxNumberOfDefFieldsForTable = (_jwss: CompanyWorkSummaryVM[]) => {

	const max = _jwss.reduce((_acc, _jws) => {
		let currentJwsMax = 0;
		for (let i = 0; i < MAX_BILLABLE_WORK_DEFINITION_FIELDS; i++) {
			if (!_jws?.[`definition${i + 1}`]) {
				break;
			}
			currentJwsMax++;
		}
		return (currentJwsMax > _acc) ? currentJwsMax : _acc;
	}, 0);
	return max;
};

const _calculateUnitPricePerQuantityUnit = (jws: CompanyWorkSummaryVM) => {
	if (!jws.unitPrice || !jws.unit || !jws?.selectedBillingCode?.unit) {
		return '';
	}

	if (QuantityUnitMap[jws.unit] === QuantityUnitMap[jws.selectedBillingCode.unit]) {
		return `${parseFloat(jws.unitPrice.toFixed(4))}`;
	}

	const convertedUnit = convertUnits(jws.unitPrice, QuantityUnitMap[jws.unit], QuantityUnitMap[jws.selectedBillingCode.unit]);

	return convertedUnit ? `${parseFloat(convertedUnit.toFixed(8))}` : '';
};

interface BillingCodeVM {
	id: number;
	unit: QuantityUnitType;
}

export class CompanyWorkSummaryVM {
	workRequest: string;
	workOrder: Nullable<string>;
	workOrderId: Nullable<number>;
	/** YYYY-MM-DD */
	startDate: string;
	quantity: number;
	description: Nullable<string>;
	billingCode: Nullable<string>;
	unit: Nullable<string>;
	selectedBillingCode: Nullable<BillingCodeVM>;
	unitPrice: Nullable<number>;
	revenue: number;
	work: Nullable<string>;
	type: Nullable<string>;
	typeValue: Nullable<string>;
	definition1: Nullable<string>;
	definition2: Nullable<string>;
	definition3: Nullable<string>;
	definition4: Nullable<string>;

	static toCSVData(data: CompanyWorkSummaryVM[], detailed: boolean): string[][] {
		const maxNumberOfDefFields = resolveMaxNumberOfDefFieldsForTable(data);
		const headers = resolveCompanyWorkSummaryTableHeadersForCSVExport(maxNumberOfDefFields, detailed);

		const rows: string[][] = data.map((_jws) => {
			const definitionFieldsValues: string[] = [];
			for (let i = 0; i < maxNumberOfDefFields; i++) {
				const defField = _jws?.[`definition${i + 1}`] ?? '';
				definitionFieldsValues.push(defField);
			}
			const basicInfo = [
				_jws.workRequest,
				_jws.workOrder ?? '',
				_jws.startDate,
				`${_jws.quantity}`,
				_jws.description ?? '',
				_jws.billingCode ?? '',
				_jws.unit ?? '',
				_jws.unitPrice ? `${parseFloat(_jws.unitPrice.toFixed(4))}` : '',
				_calculateUnitPricePerQuantityUnit(_jws),
				_jws.revenue.toFixed(2),
			];

			const detailedInfo = [
				_jws.work ?? '',
				_jws.type ?? '',
				...definitionFieldsValues,
			];

			return detailed ? [...basicInfo, ...detailedInfo] : basicInfo;
		});

		return [headers, ...rows];
	}
}
