import * as React from 'react';

import type { AccountViewModel } from 'ab-viewModels/account.viewModel';

import Pill from 'af-components/Pills/Pill';
import Tooltip from 'af-components/Tooltip';

interface OwnProps {
	onExcludeAccount: (index: number, accountId: number) => void;
	account: AccountViewModel;
	index: number;
	willReceiveNotification: boolean;
	hasEmail: boolean;
}

interface State {
	showModal: boolean;
}

type Props = OwnProps;

class AccountItem extends React.PureComponent<Props, State> {
	onExcludeAccount = () => {
		const { onExcludeAccount, index, account: { id: accountId } } = this.props;

		onExcludeAccount(index, accountId);
	};

	render() {
		const { account, willReceiveNotification, hasEmail } = this.props;

		const body = (
			<Pill
				isTransparent={!willReceiveNotification}
				item={account}
				labelKey="fullName"
				removeItem={this.onExcludeAccount}
				skipConfirmationModal={true}
			/>
		);

		if (willReceiveNotification) {
			return body;
		}

		return (
			<Tooltip
				className="tooltip--center-text"
				message={`${account.fullName} won't be notified (missing ${!hasEmail ? 'email' : 'mobile phone'})`}
				placement="top"
			>
				<div>{body}</div>
			</Tooltip>
		);
	}
}

export default AccountItem;
