import * as React from 'react';
import { FormControl, FormGroup } from 'react-bootstrap';
import type { ResolveThunks } from 'react-redux';
import { connect } from 'react-redux';

import * as ScheduleBoardActions from 'af-actions/scheduleBoard';

import type { RootState } from 'af-reducers';

interface OwnProps {
	searchText: string;
	changeSearchText: (event: Metadata) => void;
	clearSearchText: () => void;
}

interface StateProps {
	searchIndex: number;
	totalMatched: number;
}

interface DispatchProps {
	setFocusedSearchIndex: typeof ScheduleBoardActions.setMechanicViewFocusedSearchIndex;
}

type Props = OwnProps & StateProps & ResolveThunks<DispatchProps>;

class SearchFilter extends React.PureComponent<Props> {
	findPrevious = () => {
		const { searchIndex, totalMatched, setFocusedSearchIndex } = this.props;
		if (totalMatched <= 1) {
			return;
		}
		const newActiveSearchItemIndex = (searchIndex + totalMatched - 1) % totalMatched;

		setFocusedSearchIndex(newActiveSearchItemIndex);
	};

	findNext = () => {
		const { searchIndex, totalMatched, setFocusedSearchIndex } = this.props;
		if (totalMatched <= 1) {
			return;
		}
		const newActiveSearchItemIndex = (searchIndex + 1) % totalMatched;

		setFocusedSearchIndex(newActiveSearchItemIndex);
	};

	scrollNodeIntoView = (nodeId: string) => {
		if (nodeId) {
			const activeNode = document.getElementById(nodeId);
			const containerThatNeedsToBeScrolled = activeNode?.classList?.contains('draggable-table__row')
				? document.getElementById('available-equipment-scrollable-container')
				: document.getElementById('unavailable-equipment-scrollable-container');

			// 30 px above the active node.
			const positionToScrollTo = containerThatNeedsToBeScrolled!.scrollTop
				- containerThatNeedsToBeScrolled!.getBoundingClientRect().top
				+ activeNode!.getBoundingClientRect().top - 30;

			containerThatNeedsToBeScrolled!.scrollTo({ top: positionToScrollTo, behavior: 'smooth' });
		}
	};

	renderSearchNavigation = () => {
		const {
			clearSearchText,
			searchIndex,
			totalMatched,
		} = this.props;

		return (
			<span className="input-group-append">
				<div className="match">
					<span
						className="icon-left"
						onClick={this.findPrevious}
					/>
					<span>
						{searchIndex + 1}/{totalMatched}
					</span>
					<span
						className="icon-right"
						onClick={this.findNext}
					/>
				</div>
				<span
					className="icon-close"
					onClick={clearSearchText}
				/>
			</span>
		);
	};

	render() {
		const {
			searchText,
			changeSearchText,
			totalMatched,
		} = this.props;

		return (
			<div className="mechanic-view-search">
				<FormGroup>
					<div className="input-filter">
						<FormControl
							className="search-form"
							id="query"
							name="query"
							onChange={changeSearchText}
							placeholder="Search"
							type="text"
							value={searchText}
						/>
						{totalMatched > 1 && this.renderSearchNavigation()}
					</div>
				</FormGroup>
			</div>
		);
	}
}

function mapStateToProps(state: RootState): StateProps {
	const { focusedSearchIndex, matchedEquipmentIdMap } = state.scheduleBoard.mechanicView;

	return {
		searchIndex: focusedSearchIndex,
		totalMatched: Object.keys(matchedEquipmentIdMap).length,
	};
}

function mapDispatchToProps(): DispatchProps {
	return {
		setFocusedSearchIndex: ScheduleBoardActions.setMechanicViewFocusedSearchIndex,
	};
}

export default connect<StateProps, DispatchProps, OwnProps>(mapStateToProps, mapDispatchToProps())(SearchFilter);
