import { URL_REGEX } from 'acceligent-shared/constants/regex';

import type { FormErrors } from 'redux-form';

import type { ShortenUrlRequestModel } from 'ab-requestModels/shortenUrl.requestModel';

export function validate(values: ShortenUrlRequestModel): FormErrors<ShortenUrlRequestModel> {
	const errors: FormErrors<ShortenUrlRequestModel> = {};

	if (!values.url) {
		errors.url = 'Url is required';
	} else if (!URL_REGEX.test(values.url)) {
		errors.url = 'Url is invalid';
	}

	return errors;
}
