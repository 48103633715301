import * as React from 'react';
import * as qs from 'query-string';
import type { CustomRouteComponentProps } from 'react-router-dom';
import { Route } from 'react-router-dom';
import type { ConnectedProps } from 'react-redux';
import { connect } from 'react-redux';

import * as AuthenticationActions from 'af-actions/authentication';

import * as UserRequestModel from 'ab-requestModels/users.requestModel';

import CustomerSupport from 'af-components/CustomerSupport';

import CLIENT from 'af-constants/routes/client';

import type { RootState } from 'af-reducers';

import { isLmsExternalLogin, isLmsLogin, parseLmsExternalAuthnRequestId } from 'af-utils/actions.util';
import { toRawPhoneNumber } from 'ab-utils/phone.util';
import { getPlatformAdminDataFromStorage } from 'af-utils/localStorage.util';

import EmailForm from './EmailForm';
import PhoneNumberForm from './PhoneNumberForm';
import LMSForm from 'af-components/LMSForm';

type OwnProps = CustomRouteComponentProps;

type Props = OwnProps & ConnectedProps<typeof connector>;

interface State {
	isEmailForm: boolean;
	acs: Nullable<string>;
	samlResponse: Nullable<string>;
	loginAttempts: number;
}

const isAdminLoggedIn = (): boolean => {
	return !!getPlatformAdminDataFromStorage();
};

const isEmailFormCheck = (props: Props) => {
	const { isPhoneForm } = qs.parse(props.location.search) as { isPhoneForm: string; };
	return isPhoneForm !== 'true';
};

class Login extends React.PureComponent<Props, State> {

	state: State = {
		isEmailForm: isEmailFormCheck(this.props),
		acs: null,
		samlResponse: null,
		loginAttempts: 0,
	};

	async componentDidMount() {
		const { platformAdminOrganizationLogin, location: { state: { orgAlias } } } = this.props;
		if (isAdminLoggedIn()) {
			await platformAdminOrganizationLogin(orgAlias);
		}
	}

	emailLogin = async (form: UserRequestModel.UserCredentials) => {
		this.setState((previousState) => ({ loginAttempts: previousState.loginAttempts + 1 }));

		const { emailLogin, lmsEmailLogin, location: { state: { orgAlias } } } = this.props;
		if (!isLmsExternalLogin(this.props.location.search)) {
			const redirect = isLmsLogin(this.props.location.search) ? CLIENT.LMS(orgAlias) : undefined;
			await emailLogin(new UserRequestModel.UserEmailCredentials(form.email, form.password, orgAlias), redirect, this.state.loginAttempts + 1);
		} else {
			const authnRequestId = parseLmsExternalAuthnRequestId(this.props.location.search);
			const response = await lmsEmailLogin(new UserRequestModel.UserEmailLMSCredentials(form.email, form.password, orgAlias, authnRequestId));
			if (response) {
				const { acs, SAMLResponse } = response;
				this.setState({ acs, samlResponse: SAMLResponse });
			}
		}
	};

	phoneCodeRequest = async (form: UserRequestModel.UserCredentials) => {
		const { history, phoneCodeRequest, location: { state: { orgAlias } } } = this.props;
		const phoneNumber = toRawPhoneNumber(form.phoneNumber);
		await phoneCodeRequest(phoneNumber, form.countryCode, orgAlias);
		if (!isLmsExternalLogin(this.props.location.search)) {
			const loginCodeRoute = `${CLIENT.AUTH.LOGIN_CODE(orgAlias)}${isLmsLogin(this.props.location.search) ? '?lms' : ''}`;
			history.push(loginCodeRoute);
		} else {
			const authnRequestId = parseLmsExternalAuthnRequestId(this.props.location.search);
			history.push(`${CLIENT.AUTH.LOGIN_CODE(orgAlias)}?lmsExternal&authnRequestId=${authnRequestId}`);
		}
	};

	setEmailForm = () => this.setState(() => ({ isEmailForm: true }));

	setPhoneForm = () => this.setState(() => ({ isEmailForm: false }));

	renderCustomerSupportButton = () => <CustomerSupport isSidenavLink={false} location={this.props.location} />;

	render() {
		const { organizationPublicDetails, location: { state: { orgAlias } } } = this.props;
		const { isEmailForm, acs, samlResponse } = this.state;

		if (!organizationPublicDetails) {
			return null;
		}

		return (
			<>
				<div className="form-segment form-segment--mini">
					<div className="form-box form-box--standalone">
						<h2>Log into {organizationPublicDetails.name}</h2>
						<h4><b>{window.location.host}</b></h4>
						<div className="login-tabs">
							<span className={`login-tabs__tab ${isEmailForm ? 'login-tabs__tab--active' : ''}`} onClick={this.setEmailForm}>Email</span>
							<span className={`login-tabs__tab ${!isEmailForm ? 'login-tabs__tab--active' : ''}`} onClick={this.setPhoneForm}>Phone</span>
						</div>
						{isEmailForm ?
							<EmailForm onSubmit={this.emailLogin} orgAlias={orgAlias} /> :
							<PhoneNumberForm onSubmit={this.phoneCodeRequest} />
						}
						<Route component={this.renderCustomerSupportButton} />
					</div>
				</div>
				<LMSForm acs={acs} samlResponse={samlResponse} />
			</>
		);
	}
}

function mapStateToProps(state: RootState) {
	return {
		organizationPublicDetails: state.organization.publicDetails,
	};
}

function mapDispatchToProps() {
	return {
		emailLogin: AuthenticationActions.emailLogin,
		lmsEmailLogin: AuthenticationActions.lmsEmailLogin,
		phoneCodeRequest: AuthenticationActions.phoneCodeRequest,
		platformAdminOrganizationLogin: AuthenticationActions.platformAdminOrganizationLogin,
	};
}

const connector = connect(mapStateToProps, mapDispatchToProps());

export default connector(Login);
