import * as React from 'react';
import { Modal } from 'react-bootstrap';

interface OwnProps {
	className?: string;
	padding?: 'none' | 'vertical' | 'horizontal' | 'all' | 'all-but-top';
	margin?: 'none' | 'top' | 'double-top';
	onScroll?: (event: React.UIEvent<HTMLDivElement>) => void;
}

type Props = React.PropsWithChildren<OwnProps>;

const Body = React.forwardRef(function Body(props: Props, ref: React.ForwardedRef<HTMLDivElement>) {
	const { className, children, padding = 'all', margin = 'none', onScroll } = props;
	if (!children) {
		return null;
	}

	let cn = `modal-body--padded-${padding} modal-body--margin-${margin}`;
	cn = className ? `${cn} ${className}` : cn;
	cn = className ? `${cn} ${className}` : cn;

	return (
		<Modal.Body className={cn} onScroll={onScroll} ref={ref}>
			{children}
		</Modal.Body>
	);
});

export default React.memo(Body);
