import * as React from 'react';
import type { CustomRouteComponentProps } from 'react-router-dom';
import type { ResolveThunks } from 'react-redux';
import { connect } from 'react-redux';
import type { RootState } from 'af-reducers';
import { change } from 'redux-form';

import * as CompanyActions from 'af-actions/companies';

import DisabledFeatures from 'ab-common/environment/disabledFeatures';

import type { CompanyWorkDaysForm } from 'ab-requestModels/company.requestModel';
import type { CompanyRequestModel } from 'ab-requestModels/company.requestModel';

import type { CompanyViewModel } from 'ab-viewModels/company.viewModel';
import { convertWorkDays } from 'ab-viewModels/company.viewModel';

import Breadcrumbs from 'af-components/Breadcrumbs';

import CompanyPreview from './CompanyInfo/CompanyPreview';
import CompanyEdit from './CompanyInfo/CompanyEdit';
import EHSPreview from './EHS/EHSPreview';
import EHSEdit from './EHS/EHSEdit';
import CompanyPrintoutPreview from './Printouts/Preview';
import CompanyPrintoutEdit from './Printouts/Edit';
import CompanyWorkDaysPreview from './WorkDaysSettings/CompanyWorkDaysPreview';
import CompanyWorkDaysEdit from './WorkDaysSettings/CompanyWorkDaysEdit';
import EnableWorkRequestsCompanyPreview from './WorkRequests/Preview';
import EnableWorkRequestCompanyEdit from './WorkRequests/Edit';
import PurchaseOrderNumberPrefixPreview from './PurchaseOrders/Preview';
import PurchaseOrderNumberPrefixEdit from './PurchaseOrders/Edit';
import EnablePortalFunctionalitiesPreview from './Portal/Preview';
import EnablePortalFunctionalitiesEdit from './Portal/Edit';
import EnableInvoiceNotificationsPreview from './InvoiceNotifications/Preview';
import EnableInvoiceNotificationsCompanyEdit from './InvoiceNotifications/Edit';

interface CompanySettingsRouteProps {
	orgAlias: string;
}

type OwnProps = CustomRouteComponentProps<CompanySettingsRouteProps>;

interface DispatchProps {
	getCompany: typeof CompanyActions.getCompany;
	changeField: typeof change;
}

interface StateProps {
	company: Nullable<CompanyViewModel>;
	companyName: string;
	isCompanyAdmin: boolean;
	arePortalFunctionalitiesEnabled: boolean;
}

type Props = OwnProps & StateProps & ResolveThunks<DispatchProps>;

interface State {
	editBasicInfo: boolean;
	editWorkDaysInfo: boolean;
	editPrintouts: boolean;
	editEHS: boolean;
	editWorkRequests: boolean;
	editInvoiceNotifications: boolean;
	editPurchaseOrderNumberPrefix: boolean;
	editPortalFunctionalities: boolean;
}

class CompanySettings extends React.Component<Props, State> {
	static defaultProps: Partial<Props> = {
		company: {} as CompanyViewModel,
	};

	state: State = {
		editBasicInfo: false,
		editWorkDaysInfo: false,
		editPrintouts: false,
		editEHS: false,
		editWorkRequests: false,
		editInvoiceNotifications: false,
		editPurchaseOrderNumberPrefix: false,
		editPortalFunctionalities: false,
	};

	async componentDidMount() {
		const { getCompany } = this.props;

		await getCompany();
	}

	toggleBasicInfoEdit = async () => {
		this.setState((prevStatus: State) => ({ editBasicInfo: !prevStatus.editBasicInfo }));
	};

	toggleWorkDaysEdit = async () => {
		this.setState((prevStatus: State) => ({ editWorkDaysInfo: !prevStatus.editWorkDaysInfo }));
	};

	togglePrintoutsEdit = async () => {
		this.setState((prevStatus: State) => ({ editPrintouts: !prevStatus.editPrintouts }));
	};

	toggleEHSEdit = async () => {
		this.setState((prevStatus: State) => ({ editEHS: !prevStatus.editEHS }));
	};

	toggleWorkRequestsEdit = async () => {
		this.setState((prevStatus: State) => ({ editWorkRequests: !prevStatus.editWorkRequests }));
	};

	togglePortalFunctionalitiesEdit = async () => {
		this.setState((prevStatus: State) => ({ editPortalFunctionalities: !prevStatus.editPortalFunctionalities }));
	};

	toggleInvoiceNotificationsEdit = async () => {
		this.setState((prevStatus: State) => ({ editInvoiceNotifications: !prevStatus.editInvoiceNotifications }));
	};

	togglePurchaseOrderNumberPrefixEdit = async () => {
		this.setState((prevStatus: State) => ({ editPurchaseOrderNumberPrefix: !prevStatus.editPurchaseOrderNumberPrefix }));
	};

	render() {
		const {
			editBasicInfo,
			editWorkDaysInfo,
			editPrintouts,
			editEHS,
			editWorkRequests,
			editInvoiceNotifications,
			editPurchaseOrderNumberPrefix,
			editPortalFunctionalities,
		} = this.state;
		const { company, changeField, isCompanyAdmin, arePortalFunctionalitiesEnabled } = this.props;

		if (!company) {
			// TODO: loading
			return null;
		}

		return (
			<div className="form-segment">
				<Breadcrumbs items={[{ label: 'Company' }]} />
				<CompanyPreview company={company} toggleEdit={this.toggleBasicInfoEdit} />
				<CompanyEdit
					{...this.props}
					changeField={changeField}
					company={company}
					initialValues={{ ...company.address, ...company } as CompanyRequestModel}
					isVisible={editBasicInfo}
					toggleEdit={this.toggleBasicInfoEdit}
				/>
				<Breadcrumbs items={[{ label: 'Work Days' }]} />
				<CompanyWorkDaysPreview company={company} toggleEdit={this.toggleWorkDaysEdit} />
				<CompanyWorkDaysEdit
					{...this.props}
					company={company}
					initialValues={{ holidays: company.holidays, ...convertWorkDays(company.workDays) } as CompanyWorkDaysForm}
					isVisible={editWorkDaysInfo}
					toggleEdit={this.toggleWorkDaysEdit}
				/>
				<Breadcrumbs items={[{ label: 'Printouts' }]} />
				<CompanyPrintoutPreview company={company} toggleEdit={this.togglePrintoutsEdit} />
				<CompanyPrintoutEdit
					{...this.props}
					isVisible={editPrintouts}
					toggleEdit={this.togglePrintoutsEdit}
				/>
				{(!DisabledFeatures.Compliance && isCompanyAdmin) && (
					<>
						<Breadcrumbs items={[{ label: 'EHS' }]} />
						<EHSPreview company={company} toggleEdit={this.toggleEHSEdit} />
						<EHSEdit
							{...this.props}
							initialValues={{ ehsUrl: company.complianceEhsUrl }}
							isVisible={editEHS}
							toggleEdit={this.toggleEHSEdit}
						/>
					</>
				)}
				{(!DisabledFeatures.WorkRequest && isCompanyAdmin) && (
					<>
						<Breadcrumbs items={[{ label: 'Work Requests' }]} />
						<EnableWorkRequestsCompanyPreview areWorkRequestsEnabled={company.areWorkRequestsEnabled} toggleEdit={this.toggleWorkRequestsEdit} />
						<EnableWorkRequestCompanyEdit
							areWorkRequestsEnabled={company.areWorkRequestsEnabled}
							closeModal={this.toggleWorkRequestsEdit}
							showModal={editWorkRequests}
						/>
					</>
				)}
				{(!DisabledFeatures.Project && isCompanyAdmin) && (
					<>
						<Breadcrumbs items={[{ label: 'Portal Functionalities' }]} />
						<EnablePortalFunctionalitiesPreview
							arePortalFunctionalitiesEnabled={company.arePortalFunctionalitiesEnabled}
							toggleEdit={this.togglePortalFunctionalitiesEdit}
						/>
						<EnablePortalFunctionalitiesEdit
							arePortalFunctionalitiesEnabled={company.arePortalFunctionalitiesEnabled}
							closeModal={this.togglePortalFunctionalitiesEdit}
							showModal={editPortalFunctionalities}
						/>
					</>
				)}
				{(!DisabledFeatures.Invoices && isCompanyAdmin) && (
					<>
						<Breadcrumbs items={[{ label: 'Invoices' }]} />
						<EnableInvoiceNotificationsPreview
							isSendingInvoiceNotificationsEnabled={company.isSendingInvoiceNotificationsEnabled}
							toggleEdit={this.toggleInvoiceNotificationsEdit}
						/>
						<EnableInvoiceNotificationsCompanyEdit
							closeModal={this.toggleInvoiceNotificationsEdit}
							isSendingInvoiceNotificationsEnabled={company.isSendingInvoiceNotificationsEnabled}
							showModal={editInvoiceNotifications}
						/>
					</>
				)}
				{(!DisabledFeatures.PurchaseOrder && arePortalFunctionalitiesEnabled && isCompanyAdmin) && (
					<>
						<Breadcrumbs items={[{ label: 'Purchase Orders' }]} />
						<PurchaseOrderNumberPrefixPreview prefix={company.purchaseOrderNumberPrefix} toggleEdit={this.togglePurchaseOrderNumberPrefixEdit} />
						<PurchaseOrderNumberPrefixEdit
							closeModal={this.togglePurchaseOrderNumberPrefixEdit}
							currentPrefix={company.purchaseOrderNumberPrefix}
							showModal={editPurchaseOrderNumberPrefix}
						/>
					</>
				)}
			</div>
		);
	}
}

function mapStateToProps(state: RootState): StateProps {
	const { user: { companyData }, company: { company } } = state;
	if (!companyData) {
		throw new Error('User not logged in');
	}

	return {
		companyName: companyData.name,
		company,
		isCompanyAdmin: companyData.isCompanyAdmin,
		arePortalFunctionalitiesEnabled: companyData.arePortalFunctionalitiesEnabled,
	};
}

function mapDispatchToProps(): DispatchProps {
	return {
		getCompany: CompanyActions.getCompany,
		changeField: change,
	};
}

export default connect(mapStateToProps, mapDispatchToProps())(CompanySettings);
