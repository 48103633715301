import ResourceStatus from 'acceligent-shared/enums/resourceStatus';
import type { ColorPalette } from 'acceligent-shared/enums/color';
import EquipmentCostCategoryEnum from 'acceligent-shared/enums/equipmentCostCategory';
import TimePeriodSpan from 'acceligent-shared/enums/timePeriodSpan';

import type { TableData } from 'ab-common/dataStructures/tableContent';
import { TableContent } from 'ab-common/dataStructures/tableContent';

import type { TimePeriodRange } from 'acceligent-shared/utils/time';

import type EquipmentCostBase from 'ab-domain/models/equipmentCost/base';
import type EquipmentCostCategoryBase from 'ab-domain/models/equipmentCostCategory/base';

import type EquipmentUtilizationTableBase from 'ab-domain/views/equipmentUtilizationTable/base';

import type { TableQuery, TableSortBy } from 'ab-common/dataStructures/tableQuery';

import EquipmentUtilizationLevel from 'ab-enums/equipmentUtilizationLevel.enum';

import { somePredicates } from 'ab-utils/array.util';
import { buildTable } from 'ab-utils/table.util';
import { moneyNormalizer } from 'ab-utils/formatting.util';

// PRIVATE

/**
 * Groups tables (`TableViewModel`) first by a `groupingId` which is usually the parent id of all the tables' rows
 * (other groupings are also used, such as enums; rows usually have a property named `groupingId`),
 * and then by `timePeriodId` which represents the value of `timePeriodId` for all the rows in a single table.
 *
 * Each (`groupingId`, `timePeriodId`) has a single `TableViewModel` and all the tables are of the same type `T`.
 * In other words, all the tables in the dictionary represent the same level (e.g. `EquipmentUtilizationLevel`).
 */
type TablesDictionaryViewModel<T> = { [groupingId: string]: { [timePeriodId: string]: TableContent<T>; }; };

/**
 * Groups id arrays first by a `groupingId` which is the parent (or above) id of all the elements whose ids are in the array,
 * and then by `timePeriodId` which represents the value of `timePeriodId` of all the elements whose ids are in the array.
 *
 * Each (`groupingId`, `timePeriodId`) has a single array of ids. All of the arrays are of the same type (i.e. represent the same level).
 *
 * Used primarily for mapping elements to their grandparents for faster lookup (i.e. the `groupingId` represents the elements' grandparent id),
 * opposed to `TablesDictionaryViewModel` which usually maps element to their parent.
 *
 * **NOTE:**
 * A union of all the arrays with the same `groupingId` can usually be obtained by accessing `IdLookup[groupingId][ALL_TIME_PERIODS_ID]`.
 */
type IdLookup = { [groupingId: string]: { [timePeriodId: string]: number[]; }; };

/**
 * Parent of all Equipment Type elements
 */
const EQUIPMENT_TYPE_GROUPING_ID = 'ROOT';
/**
 * `timePeriodId` key that stores a union of all arrays with the same `groupingId` in `IdLookup`
 */
const ALL_TIME_PERIODS_ID = 'ALL_PERIODS';

/**
 * A mock used to construct of all EquipmentUtilizationTablesViewModel's TableViewModels
 * because all the tables have all their rows and a single page.
 */
const DEFAULT_TABLE_DATA: Partial<TableData> = {
	pages: 1,
};

const DEFAULT_EQUIPMENT_GROUP_SORT_BY: TableSortBy[] = [{ id: 'groupingId', desc: false }, { id: 'timePeriodId', desc: true }];
const DEFAULT_EQUIPMENT_SORT_BY: TableSortBy[] = [{ id: 'code', desc: false }, { id: 'timePeriodId', desc: true }];

const POSSIBLE_RESOURCE_STATUSES: string[] & ResourceStatus[] = [ResourceStatus.ACTIVE, ResourceStatus.DELETED];

/**
 * The constructed table has a single page and its rows are all of the list's elements
 */
function _listToTableViewModel<T>(list: T[], tableData: Partial<TableData> = DEFAULT_TABLE_DATA) {
	const totalCount = tableData.totalCount ?? list.length;
	const pages = tableData.pages ?? 1;
	return new TableContent(list, pages, totalCount);
}

/**
 * This function has side effects
 *
 * @param tablesDict will be mutated
 */
function _addDictToTablesDict<T>(
	tablesDict: TablesDictionaryViewModel<T>,
	timePeriodId: string,
	dict: { [groupingId: string]: T[]; },
	tableData: Partial<TableData>
) {
	for (const [_groupingId, _elements] of Object.entries(dict)) {
		if (!tablesDict[_groupingId]) {
			tablesDict[_groupingId] = {};
		}
		tablesDict[_groupingId][timePeriodId] = _listToTableViewModel(_elements, tableData);
	}
}

function _buildGrandchildIdsByParentLookup(
	childTablesByParent: TablesDictionaryViewModel<EquipmentUtilizationViewModelShared>,
	grandchildTablesByChild: TablesDictionaryViewModel<EquipmentUtilizationViewModelShared>
): IdLookup {
	const result: IdLookup = {};
	for (const _parentId of Object.keys(childTablesByParent)) {
		if (!result[_parentId]) {
			result[_parentId] = { [ALL_TIME_PERIODS_ID]: [] };
		}
		for (const _timePeriodId of Object.keys(childTablesByParent[_parentId])) {
			if (!result[_parentId][_timePeriodId]) {
				result[_parentId][_timePeriodId] = [];
			}
			for (const _childRow of childTablesByParent[_parentId][_timePeriodId].rows ?? []) {
				if (!grandchildTablesByChild[_childRow.id.toString()] || !grandchildTablesByChild[_childRow.id.toString()][_timePeriodId]) {
					continue;
				}
				for (const _grandchildRow of grandchildTablesByChild[_childRow.id.toString()][_timePeriodId].rows ?? []) {
					// NOTE: no need to check for duplicates because there shouldn't be any
					result[_parentId][_timePeriodId].push(_grandchildRow.id as number);
					result[_parentId][ALL_TIME_PERIODS_ID].push(_grandchildRow.id as number);
				}
			}
		}
	}
	return result;
}

function _getResourceStatusLikeText(text: string): Nullable<ResourceStatus> {
	if (!text) {
		return null;
	}
	for (const _status of POSSIBLE_RESOURCE_STATUSES) {
		if (_status.startsWith(text)) {
			return _status as ResourceStatus;
		}
	}
	return null;
}

function _getUtilizationRowsFilterByTextPredicate(text: string): Nullable<ArrayPredicate<EquipmentUtilizationViewModelShared>> {
	const trimmedText = (text || '').trim();
	if (!trimmedText) {
		return null;
	}
	const uppercaseText = trimmedText.toUpperCase();

	const filterFunctions: ((_e: EquipmentUtilizationViewModelShared) => boolean)[] = [
		(_e: EquipmentUtilizationViewModelShared) => _e.timePeriodId.toString().toUpperCase().includes(uppercaseText),
		(_e: EquipmentUtilizationViewModelShared) => EquipmentUtilizationGroupViewModelBase.isInstance(_e) && _e.name.toUpperCase().startsWith(uppercaseText),
		(_e: EquipmentUtilizationViewModelShared) => EquipmentUtilizationViewModel.isInstance(_e) && _e.code.toUpperCase().startsWith(uppercaseText),
		(_e: EquipmentUtilizationViewModelShared) => EquipmentUtilizationViewModel.isInstance(_e) && _e.specification?.toUpperCase().startsWith(uppercaseText),
	];

	if (trimmedText.length <= 2) {
		return somePredicates(filterFunctions);
	}
	// if length is more then 2 search the middle of the string, not just the start:
	filterFunctions[1] = (_e: EquipmentUtilizationViewModelShared) =>
		EquipmentUtilizationGroupViewModelBase.isInstance(_e) && _e.name.toUpperCase().includes(uppercaseText);
	filterFunctions[2] = (_e: EquipmentUtilizationViewModelShared) =>
		EquipmentUtilizationViewModel.isInstance(_e) && _e.code.toUpperCase().includes(uppercaseText);
	filterFunctions[3] = (_e: EquipmentUtilizationViewModelShared) =>
		EquipmentUtilizationViewModel.isInstance(_e) && _e.specification?.toUpperCase().includes(uppercaseText);

	if (trimmedText.length <= 3) {
		return somePredicates(filterFunctions);
	}
	// in case of user searching for "acti" or "dele"
	const resourceStatus = _getResourceStatusLikeText(text);
	if (resourceStatus) {
		filterFunctions.push((_e: EquipmentUtilizationViewModelShared) => _e.status === resourceStatus);
	}

	return somePredicates(filterFunctions);
}

function _filterUtilizationRowsByText(rows: EquipmentUtilizationViewModelShared[], text: string): EquipmentUtilizationViewModelShared[] {
	const predicate = _getUtilizationRowsFilterByTextPredicate(text);
	if (!predicate?.length) {
		return rows;
	}
	return rows.filter(predicate);
}

function _getRowsFromTablesDict<T>(groupingId: Nullable<string>, timePeriodId: Nullable<string>, dict: TablesDictionaryViewModel<T>): T[] {
	if (groupingId && timePeriodId) {
		return dict[groupingId][timePeriodId].rows ?? [];
	}
	if (groupingId) {
		return ([] as T[]).concat(...Object.values(dict[groupingId]).map((_table) => _table.rows ?? []));
	}
	if (timePeriodId) {
		return Object.values(dict).reduce((_result: T[], _dictByTimePeriod: { [timePeriodId: string]: TableContent<T>; }) => {
			_result.push(..._dictByTimePeriod[timePeriodId].rows ?? []);
			return _result;
		}, [] as T[]);
	}
	return Object.values(dict).reduce((_result: T[], _dictByTimePeriod: { [timePeriodId: string]: TableContent<T>; }) => {
		for (const _table of Object.values(_dictByTimePeriod)) {
			_table.rows && _result.push(..._table.rows);
		}
		return _result;
	}, [] as T[]);
}

// PUBLIC

const CSV_HEADER_EQUIPMENT_GROUP_BASE_KEYS = [
	'Year',
	'Scheduled',
	'Not Scheduled',
	'Not Available',
	'Daily Revenue',
	'Daily Cost',
	'Target Profit/Loss',
];

const CSV_HEADER_EQUIPMENT_KEYS = [
	'Equipment ID',
	'Year',
	'Scheduled',
	'Not Scheduled',
	'Not Available',
	'Daily Revenue',
	'Total Revenue',
	'Daily Cost',
	'Total Cost',
	'Target Profit/Loss',
];

const EQUIPMENT_COST_CATEGORY_TYPES: EquipmentCostCategoryEnum[] = Object.values(EquipmentCostCategoryEnum);

/**
 * Mocks EquipmentCostCategoryEnum elements as if they are EquipmentCostCategory root entities
 */
interface EquipmentCostCategoryType {
	id: EquipmentCostCategoryEnum;
	status: ResourceStatus.ACTIVE;
}

interface EquipmentUtilizationViewModelShared {
	id: number | EquipmentCostCategoryEnum;
	groupingId: number | EquipmentCostCategoryEnum | null;
	status: ResourceStatus;

	timePeriod: TimePeriodSpan;
	timePeriodId: number | string;

	/** does NOT exist for `EquipmentUtilizationViewModel` */
	equipmentCount?: number;

	/** does NOT exist for `EquipmentUtilizationViewModel` */
	daysAssignedSum?: number;
	/** does NOT exist for `EquipmentUtilizationViewModel` */
	daysAvailableSum?: number;
	/** does NOT exist for `EquipmentUtilizationViewModel` */
	daysUnavailableSum?: number;
	/** does NOT exist for `EquipmentUtilizationViewModel` */
	totalDaysSum?: number;

	/** value from query for `EquipmentUtilizationViewModel`, rounded average by `equipmentCount` otherwise, equals sum if no `equipmentCount` */
	daysAssigned: number;
	/** value from query for `EquipmentUtilizationViewModel`, rounded average by `equipmentCount` otherwise, equals sum if no `equipmentCount` */
	daysAvailable: number;
	/** value from query for `EquipmentUtilizationViewModel`, rounded average by `equipmentCount` otherwise, equals sum if no `equipmentCount` */
	daysUnavailable: number;
	/** shorthand for `daysAssigned + daysAvailable + daysUnavailable` */
	totalDays: number;

	/** `null` if `totalDays === 0`, average `totalRevenue` by `totalDays` */
	dailyRevenue: Nullable<number>;
	/** value from query for `EquipmentUtilizationViewModel`, sum of equipments' `totalRevenue` otherwise */
	totalRevenue: number;
	/** `null` if `totalDays === 0`, average `totalCost` by `totalDays` */
	dailyCost: Nullable<number>;
	/** value from query for `EquipmentUtilizationViewModel`, sum of equipments' `totalCost` otherwise */
	totalCost: number;
	/** difference between `totalRevenue` and `totalCost` */
	targetProfit: number;
}

abstract class EquipmentUtilizationGroupViewModelBase implements EquipmentUtilizationViewModelShared {
	id: number | EquipmentCostCategoryEnum;
	groupingId: number | EquipmentCostCategoryEnum | null;
	status: ResourceStatus;

	name: string;

	timePeriod: TimePeriodSpan;
	timePeriodId: number | string;

	equipmentCount: number;

	daysAssignedSum: number;
	daysAvailableSum: number;
	daysUnavailableSum: number;
	totalDaysSum: number;

	daysAssigned: number;
	daysAvailable: number;
	daysUnavailable: number;
	totalDays: number;

	dailyRevenue: Nullable<number>;
	totalRevenue: number;
	dailyCost: Nullable<number>;
	totalCost: number;
	targetProfit: number;

	constructor(
		group: EquipmentCostBase | EquipmentCostCategoryBase | EquipmentCostCategoryType,
		items: EquipmentUtilizationViewModelShared[],
		timePeriodId: number | string,
		timePeriod: TimePeriodSpan = TimePeriodSpan.YEAR
	) {
		this.id = group.id;
		this.status = group.status;

		this.timePeriod = timePeriod;
		this.timePeriodId = timePeriodId;

		this.equipmentCount = 0;

		this.daysAssignedSum = 0;
		this.daysAvailableSum = 0;
		this.daysUnavailableSum = 0;

		this.totalRevenue = 0;
		this.totalCost = 0;

		for (const _item of items) {
			if (this.id !== _item.groupingId || this.timePeriodId !== _item.timePeriodId || this.timePeriod !== _item.timePeriod) {
				continue;
			}
			this.equipmentCount += _item.equipmentCount === undefined ? 1 : _item.equipmentCount;	// only equipment should have equipmentCount undefined
			this.daysAssignedSum += _item.daysAssignedSum ?? _item.daysAssigned;
			this.daysAvailableSum += _item.daysAvailableSum ?? _item.daysAvailable;
			this.daysUnavailableSum += _item.daysUnavailableSum ?? _item.daysUnavailable;
			this.totalRevenue += _item.totalRevenue;
			this.totalCost += _item.totalCost;
		}
		this.totalDaysSum = this.daysAssignedSum + this.daysAvailableSum + this.daysUnavailableSum;

		this.daysAssigned = Math.round(this.daysAssignedSum / (this.equipmentCount || 1));
		this.daysAvailable = Math.round(this.daysAvailableSum / (this.equipmentCount || 1));
		this.daysUnavailable = Math.round(this.daysUnavailableSum / (this.equipmentCount || 1));
		this.totalDays = this.daysAssigned + this.daysAvailable + this.daysUnavailable;

		this.dailyRevenue = this.totalDays ? (this.totalRevenue / this.totalDays) : null;
		this.dailyCost = this.totalDays ? (this.totalCost / this.totalDays) : null;
		this.targetProfit = this.totalRevenue - this.totalCost;
	}

	static isInstance(viewModel: EquipmentUtilizationViewModelShared): viewModel is EquipmentUtilizationGroupViewModelBase {
		if (viewModel instanceof EquipmentUtilizationGroupViewModelBase) {
			return true;
		}
		const _viewModel = viewModel as EquipmentUtilizationGroupViewModelBase;
		return (!!_viewModel.name && _viewModel.equipmentCount !== undefined);
	}

	static toCSVData(viewModels: EquipmentUtilizationGroupViewModelBase[], firstHeaderKey: string): string[][] {
		const header: string[] = [firstHeaderKey, ...CSV_HEADER_EQUIPMENT_GROUP_BASE_KEYS];

		const rows: string[][] = viewModels.map((_entry: EquipmentCostCategoryTypeUtilizationViewModel) => {
			const _row: string[] = [
				_entry.name,
				_entry.timePeriodId.toString(),
				`${_entry.daysAssigned} days`,
				`${_entry.daysAvailable} days`,
				`${_entry.daysUnavailable} days`,
				`${moneyNormalizer(_entry.dailyRevenue)}`,
				`${moneyNormalizer(_entry.dailyCost)}`,
				`${moneyNormalizer(_entry.targetProfit)}`,
			];
			return _row;
		});

		return [header, ...rows];
	}
}

class EquipmentCostCategoryTypeUtilizationViewModel extends EquipmentUtilizationGroupViewModelBase {
	id: EquipmentCostCategoryEnum;

	constructor(
		equipmentCostCategoryType: EquipmentCostCategoryEnum,
		equipmentCostCategoryGroupList: EquipmentCostCategoryGroupUtilizationViewModel[],
		timePeriodId: number | string,
		timePeriod: TimePeriodSpan = TimePeriodSpan.YEAR
	) {
		super(
			{ id: equipmentCostCategoryType, status: ResourceStatus.ACTIVE },
			equipmentCostCategoryGroupList,
			timePeriodId,
			timePeriod
		);

		this.groupingId = null;
		this.name = equipmentCostCategoryType;
	}
}

class EquipmentCostCategoryGroupUtilizationViewModel extends EquipmentUtilizationGroupViewModelBase {
	id: number;

	constructor(
		equipmentCostCategory: EquipmentCostCategoryBase,
		equipmentCostCategoryList: EquipmentCostCategoryUtilizationViewModel[],
		timePeriodId: number | string,
		timePeriod: TimePeriodSpan = TimePeriodSpan.YEAR
	) {
		super(equipmentCostCategory, equipmentCostCategoryList, timePeriodId, timePeriod);

		this.groupingId = EquipmentCostCategoryEnum[equipmentCostCategory.type] || EquipmentCostCategoryEnum.EQUIPMENT;
		this.name = equipmentCostCategory.name;
	}
}

class EquipmentCostCategoryUtilizationViewModel extends EquipmentUtilizationGroupViewModelBase {
	id: number;
	color: Nullable<ColorPalette>;

	constructor(
		equipmentCostCategory: EquipmentCostCategoryBase,
		equipmentCostList: EquipmentCostUtilizationViewModel[],
		timePeriodId: number | string,
		timePeriod: TimePeriodSpan = TimePeriodSpan.YEAR
	) {
		super(equipmentCostCategory, equipmentCostList, timePeriodId, timePeriod);

		this.groupingId = equipmentCostCategory.groupId;
		this.name = equipmentCostCategory.name;
		this.color = equipmentCostCategory.categoryColor;
	}
}

class EquipmentCostUtilizationViewModel extends EquipmentUtilizationGroupViewModelBase {
	id: number;

	constructor(
		equipmentCost: EquipmentCostBase,
		equipmentList: EquipmentUtilizationViewModel[],
		timePeriodId: number | string,
		timePeriod: TimePeriodSpan = TimePeriodSpan.YEAR
	) {
		super(equipmentCost, equipmentList, timePeriodId, timePeriod);

		this.groupingId = equipmentCost.categoryId;
		this.name = equipmentCost.subcategory;
	}
}

class EquipmentUtilizationViewModel implements EquipmentUtilizationViewModelShared {
	id: number;
	groupingId: number;

	createdAt: Date;
	deletedAt: Nullable<Date>;
	status: ResourceStatus;

	timePeriod: TimePeriodSpan;
	timePeriodId: number | string;

	code: string;
	specification: string;

	daysAssigned: number;
	daysAvailable: number;
	daysUnavailable: number;
	totalDays: number;

	dailyRevenue: number;
	totalRevenue: number;
	dailyCost: number;
	totalCost: number;
	targetProfit: number;

	constructor(serviceModel: EquipmentUtilizationTableBase, timePeriodId: number | string, timePeriod: TimePeriodSpan = TimePeriodSpan.YEAR) {
		this.id = serviceModel.equipmentId;
		this.groupingId = serviceModel.equipmentCostId;

		this.createdAt = serviceModel.createdAt;
		this.deletedAt = serviceModel.deletedAt;
		this.status = serviceModel.status;

		this.timePeriod = timePeriod;
		this.timePeriodId = timePeriodId;

		this.code = serviceModel.code;
		this.specification = serviceModel.specification ?? '';

		this.daysAssigned = serviceModel.daysAssigned;
		this.daysAvailable = serviceModel.daysAvailable;
		this.daysUnavailable = serviceModel.daysUnavailable;
		this.totalDays = this.daysAssigned + this.daysAvailable + this.daysUnavailable;

		this.dailyRevenue = +serviceModel.dailyRevenue;
		this.totalRevenue = +serviceModel.totalRevenue;
		this.dailyCost = +serviceModel.dailyCost;
		this.totalCost = +serviceModel.totalCost;
		this.targetProfit = this.totalRevenue - this.totalCost;
	}

	static isInstance(viewModel: EquipmentUtilizationViewModelShared): viewModel is EquipmentUtilizationViewModel {
		if (viewModel instanceof EquipmentUtilizationViewModel) {
			return true;
		}
		if (viewModel.equipmentCount !== undefined) {
			return false;
		}
		const _viewModel = viewModel as EquipmentUtilizationViewModel;
		return (!!_viewModel.code && _viewModel.specification !== undefined);
	}

	static toCSVData(viewModels: EquipmentUtilizationViewModel[]): string[][] {
		const header: string[] = [...CSV_HEADER_EQUIPMENT_KEYS];

		const rows: string[][] = viewModels.map((_entry: EquipmentUtilizationViewModel) => {
			const _row: string[] = [
				`${_entry.code} ${_entry.specification}`,
				_entry.timePeriodId.toString(),
				`${_entry.daysAssigned} days`,
				`${_entry.daysAvailable} days`,
				`${_entry.daysUnavailable} days`,
				`${moneyNormalizer(_entry.dailyRevenue)}`,
				`${moneyNormalizer(_entry.totalRevenue)}`,
				`${moneyNormalizer(_entry.dailyCost)}`,
				`${moneyNormalizer(_entry.totalCost)}`,
				`${moneyNormalizer(_entry.targetProfit)}`,
			];
			return _row;
		});

		return [header, ...rows];
	}
}

type EquipmentUtilizationByCostDict = { [equipmentCostId: number]: EquipmentUtilizationViewModel[]; };
type EquipmentCostUtilizationByCategoryDict = { [equipmentCostCategoryId: number]: EquipmentCostUtilizationViewModel[]; };
type EquipmentCostCategoryUtilizationByGroupDict = { [equipmentCostCategoryGroupId: number]: EquipmentCostCategoryUtilizationViewModel[]; };
type EquipmentCostCategoryGroupUtilizationByTypeDict = { [equipmentCostCategoryType: string]: EquipmentCostCategoryGroupUtilizationViewModel[]; };

class EquipmentUtilizationViewModelsAggregate {
	equipmentByCostDict: EquipmentUtilizationByCostDict;
	equipmentCostByCategoryDict: EquipmentCostUtilizationByCategoryDict;
	equipmentCostCategoryByGroupDict: EquipmentCostCategoryUtilizationByGroupDict;
	equipmentCostGroupByTypeDict: EquipmentCostCategoryGroupUtilizationByTypeDict;
	equipmentTypeList: EquipmentCostCategoryTypeUtilizationViewModel[];

	constructor(
		equipmentServiceModels: EquipmentUtilizationTableBase[],
		equipmentCosts: EquipmentCostBase[],
		equipmentCostCategories: EquipmentCostCategoryBase[],
		timePeriodId: number | string,
		timePeriod: TimePeriodSpan = TimePeriodSpan.YEAR
	) {
		// NOTE: this will probably be called a loop, so avoid anonymous functions
		this.equipmentByCostDict = {};
		this.equipmentCostByCategoryDict = {};
		this.equipmentCostCategoryByGroupDict = {};
		this.equipmentCostGroupByTypeDict = {};
		this.equipmentTypeList = [];

		for (const _type of EQUIPMENT_COST_CATEGORY_TYPES) {
			// pre-fill to make sure that all types are present
			this.equipmentCostGroupByTypeDict[_type] = [];
		}
		for (const _serviceModel of equipmentServiceModels) {
			const _viewModel = new EquipmentUtilizationViewModel(_serviceModel, timePeriodId, timePeriod);
			if (!this.equipmentByCostDict[_serviceModel.equipmentCostId]) {
				this.equipmentByCostDict[_serviceModel.equipmentCostId] = [];
			}
			this.equipmentByCostDict[_serviceModel.equipmentCostId].push(_viewModel);
		}
		for (const _ec of equipmentCosts) {
			if (!this.equipmentByCostDict[_ec.id]) {
				continue;	// don't include if no equipment, replace with empty array assignment if this condition changes
			}
			const _viewModel = new EquipmentCostUtilizationViewModel(_ec, this.equipmentByCostDict[_ec.id], timePeriodId, timePeriod);
			if (_viewModel.equipmentCount === 0) {
				// don't include if no equipment, update condition above if this requirement changes
				// should never happen because of the previous continue condition, but it's here to revert back to another condition more easily
				continue;
			}
			if (!this.equipmentCostByCategoryDict[_ec.categoryId]) {
				this.equipmentCostByCategoryDict[_ec.categoryId] = [];
			}
			this.equipmentCostByCategoryDict[_ec.categoryId].push(_viewModel);
		}
		for (const _ecc of equipmentCostCategories) {
			if (_ecc.groupId === null) {
				continue;
			}
			if (!this.equipmentCostByCategoryDict[_ecc.id]) {
				continue;	// don't include if no equipment costs, replace with empty array assignment if this condition changes
			}
			const _viewModel = new EquipmentCostCategoryUtilizationViewModel(_ecc, this.equipmentCostByCategoryDict[_ecc.id], timePeriodId, timePeriod);
			if (_viewModel.equipmentCount === 0) {
				// don't include if no equipment, update condition above if this requirement changes
				// should never happen because of the previous continue condition, but it's here to revert back to another condition more easily
				continue;
			}
			if (!this.equipmentCostCategoryByGroupDict[_ecc.groupId]) {
				this.equipmentCostCategoryByGroupDict[_ecc.groupId] = [];
			}
			this.equipmentCostCategoryByGroupDict[_ecc.groupId].push(_viewModel);
		}
		for (const _ecc of equipmentCostCategories) {
			if (_ecc.groupId !== null) {
				continue;
			}
			if (!this.equipmentCostCategoryByGroupDict[_ecc.id]) {
				continue;	// don't include if no equipment categories, replace with empty array assignment if this condition changes
			}
			const _viewModel = new EquipmentCostCategoryGroupUtilizationViewModel(
				_ecc,
				this.equipmentCostCategoryByGroupDict[_ecc.id],
				timePeriodId,
				timePeriod
			);
			if (_viewModel.equipmentCount === 0) {
				// don't include if no equipment, update condition above if this requirement changes
				// should never happen because of the previous continue condition, but it's here to revert back to another condition more easily
				continue;
			}
			if (_viewModel.groupingId && !this.equipmentCostGroupByTypeDict[_viewModel.groupingId]) {
				// this should never happen
				console.error('[DATA ERROR]: Incorrect equipment type on for equipment category row at id: ', _ecc.id);
				continue;
			}
			_viewModel.groupingId && this.equipmentCostGroupByTypeDict[_viewModel.groupingId].push(_viewModel);
		}
		for (const _type of EQUIPMENT_COST_CATEGORY_TYPES) {
			if (this.equipmentCostGroupByTypeDict[_type].length === 0) {
				// don't include if no groups (i.e. no equipment), update condition above if this requirement changes
				continue;
			}
			this.equipmentTypeList.push(new EquipmentCostCategoryTypeUtilizationViewModel(
				_type,
				this.equipmentCostGroupByTypeDict[_type],
				timePeriodId,
				timePeriod
			));
		}
	}
}

export interface EquipmentUtilizationTableSelectionModel {
	level: EquipmentUtilizationLevel;
	equipmentType: Nullable<string>;
	equipmentGroupId: Nullable<string>;
	equipmentCategoryId: Nullable<string>;
	equipmentCostId: Nullable<string>;
	timePeriodId: Nullable<string>;
}

export class EquipmentUtilizationTablesViewModel {
	equipmentTablesByCost: TablesDictionaryViewModel<EquipmentUtilizationViewModel>;
	equipmentCostTablesByCategory: TablesDictionaryViewModel<EquipmentCostUtilizationViewModel>;
	equipmentCategoryTablesByGroup: TablesDictionaryViewModel<EquipmentCostCategoryUtilizationViewModel>;
	equipmentGroupTablesByType: TablesDictionaryViewModel<EquipmentCostCategoryGroupUtilizationViewModel>;
	equipmentTypeTables: TablesDictionaryViewModel<EquipmentCostCategoryTypeUtilizationViewModel>;
	equipmentIdsByCategory: IdLookup;
	equipmentCostIdsByGroup: IdLookup;
	equipmentCategoryIdByType: IdLookup;
	timePeriodIds: number[] | string[];
	timePeriodRanges?: TimePeriodRange[];

	constructor(
		data: EquipmentUtilizationViewModelsAggregate[],
		timePeriodIds: number[] | string[],
		timePeriodRanges?: TimePeriodRange[],
		tableData: Partial<TableData> = {}
	) {
		if (data.length !== timePeriodIds.length) {
			throw Error('Data and ids must match!');
		}
		this.equipmentTablesByCost = {};
		this.equipmentCostTablesByCategory = {};
		this.equipmentCategoryTablesByGroup = {};
		this.equipmentGroupTablesByType = {};
		this.equipmentTypeTables = { [EQUIPMENT_TYPE_GROUPING_ID]: {} };

		data.forEach((_viewModelsDict: EquipmentUtilizationViewModelsAggregate, _index: number) => {
			const _timePeriodId = timePeriodIds[_index].toString();
			_addDictToTablesDict(this.equipmentTablesByCost, _timePeriodId, _viewModelsDict.equipmentByCostDict, tableData);
			_addDictToTablesDict(this.equipmentCostTablesByCategory, _timePeriodId, _viewModelsDict.equipmentCostByCategoryDict, tableData);
			_addDictToTablesDict(this.equipmentCategoryTablesByGroup, _timePeriodId, _viewModelsDict.equipmentCostCategoryByGroupDict, tableData);
			_addDictToTablesDict(this.equipmentGroupTablesByType, _timePeriodId, _viewModelsDict.equipmentCostGroupByTypeDict, tableData);
			this.equipmentTypeTables[EQUIPMENT_TYPE_GROUPING_ID][_timePeriodId] = _listToTableViewModel(_viewModelsDict.equipmentTypeList);
		});

		this.equipmentIdsByCategory = _buildGrandchildIdsByParentLookup(this.equipmentCostTablesByCategory, this.equipmentTablesByCost);
		this.equipmentCostIdsByGroup = _buildGrandchildIdsByParentLookup(this.equipmentCategoryTablesByGroup, this.equipmentCostTablesByCategory);
		this.equipmentCategoryIdByType = _buildGrandchildIdsByParentLookup(this.equipmentTypeTables, this.equipmentCategoryTablesByGroup);

		this.timePeriodIds = timePeriodIds;
		this.timePeriodRanges = timePeriodRanges;
	}

	static getTable(
		tableRequest: TableQuery,
		selection: EquipmentUtilizationTableSelectionModel,
		data: Nullable<EquipmentUtilizationTablesViewModel>
	): Nullable<TableContent<EquipmentUtilizationViewModelShared>> {
		if (!data) {
			return null;
		}
		const rows = EquipmentUtilizationTablesViewModel.getSelectionRows(selection, data);
		const defaultSortBy = selection.level === EquipmentUtilizationLevel.EQUIPMENT ? DEFAULT_EQUIPMENT_SORT_BY : DEFAULT_EQUIPMENT_GROUP_SORT_BY;
		const sortBy: TableSortBy[] = !tableRequest.sortBy?.length ? defaultSortBy : tableRequest.sortBy;
		return buildTable({ ...tableRequest, sortBy }, rows, _filterUtilizationRowsByText);
	}

	static getSelectionRows(
		selection: EquipmentUtilizationTableSelectionModel,
		data: EquipmentUtilizationTablesViewModel
	): EquipmentUtilizationViewModelShared[] {
		if (selection.level === EquipmentUtilizationLevel.TYPE) {
			return _getRowsFromTablesDict(EQUIPMENT_TYPE_GROUPING_ID, selection.timePeriodId, data.equipmentTypeTables);
		}
		if (selection.level === EquipmentUtilizationLevel.GROUP) {
			return _getRowsFromTablesDict(selection.equipmentType, selection.timePeriodId, data.equipmentGroupTablesByType);
		}
		if (selection.level === EquipmentUtilizationLevel.CATEGORY) {
			let result: EquipmentCostCategoryUtilizationViewModel[] = [];
			if (!selection.equipmentGroupId && selection.equipmentType) {
				// get where grandparent is 'selection.equipmentType'
				const allCategoriesForTimePeriod = _getRowsFromTablesDict(null, selection.timePeriodId, data.equipmentCategoryTablesByGroup);
				const categoryLookup = data.equipmentCategoryIdByType[selection.equipmentType][selection.timePeriodId ?? ALL_TIME_PERIODS_ID];
				result = allCategoriesForTimePeriod.filter((_row) => categoryLookup.includes(_row.id));
			} else {
				// get where parent is 'selection.equipmentGroupId' (STANDARD BEHAVIOR)
				result = _getRowsFromTablesDict(selection.equipmentGroupId, selection.timePeriodId, data.equipmentCategoryTablesByGroup);
			}
			return result;
		}
		if (selection.level === EquipmentUtilizationLevel.EQUIPMENT_COST) {
			let result: EquipmentCostUtilizationViewModel[] = [];
			if (!selection.equipmentCategoryId && selection.equipmentGroupId) {
				// get where grandparent is 'selection.equipmentGroupId'
				const allCostsForTimePeriod = _getRowsFromTablesDict(null, selection.timePeriodId, data.equipmentCostTablesByCategory);
				const costLookup = data.equipmentCostIdsByGroup[selection.equipmentGroupId][selection.timePeriodId ?? ALL_TIME_PERIODS_ID];
				result = allCostsForTimePeriod.filter((_row) => costLookup.includes(_row.id));
			} else if (!selection.equipmentCategoryId && selection.equipmentType) {
				// get where great-grandparent is 'selection.equipmentType'
				const categoryLookup = data.equipmentCategoryIdByType[selection.equipmentType][selection.timePeriodId ?? ALL_TIME_PERIODS_ID];
				result = ([] as EquipmentCostUtilizationViewModel[]).concat(
					...categoryLookup.map((_categoryId) =>
						_getRowsFromTablesDict(_categoryId.toString(), selection.timePeriodId, data.equipmentCostTablesByCategory)
					)
				);
			} else {
				// get where parent is 'selection.equipmentCategoryId' (STANDARD BEHAVIOR)
				result = _getRowsFromTablesDict(selection.equipmentCategoryId, selection.timePeriodId, data.equipmentCostTablesByCategory);
			}
			return result;
		}
		if (selection.level === EquipmentUtilizationLevel.EQUIPMENT) {
			let result: EquipmentUtilizationViewModel[] = [];
			if (!selection.equipmentCostId && selection.equipmentCategoryId) {
				// get where grandparent is 'selection.equipmentCategoryId'
				const allEquipmentForTimePeriod = _getRowsFromTablesDict(null, selection.timePeriodId, data.equipmentTablesByCost);
				const equipmentLookup = data.equipmentIdsByCategory[selection.equipmentCategoryId][selection.timePeriodId ?? ALL_TIME_PERIODS_ID];
				result = allEquipmentForTimePeriod.filter((_row) => equipmentLookup.includes(_row.id));
			} else if (!selection.equipmentCostId && selection.equipmentGroupId) {
				// get where great-grandparent is 'selection.equipmentGroupId'
				const costLookup = data.equipmentCostIdsByGroup[selection.equipmentGroupId][selection.timePeriodId ?? ALL_TIME_PERIODS_ID];
				result = ([] as EquipmentUtilizationViewModel[]).concat(
					...costLookup.map((_costId) =>
						_getRowsFromTablesDict(_costId.toString(), selection.timePeriodId, data.equipmentTablesByCost)
					)
				);
			} else if (!selection.equipmentCostId && selection.equipmentType) {
				// get where great-great-grandparent is 'selection.equipmentType'
				const allEquipmentForTimePeriod = _getRowsFromTablesDict(null, selection.timePeriodId, data.equipmentTablesByCost);
				const categoryLookup = data.equipmentCategoryIdByType[selection.equipmentType][selection.timePeriodId ?? ALL_TIME_PERIODS_ID];
				const equipmentLookup: number[] = ([] as number[]).concat(
					...categoryLookup.map((_categoryId) => data.equipmentIdsByCategory[_categoryId][selection.timePeriodId ?? ALL_TIME_PERIODS_ID])
				);
				result = allEquipmentForTimePeriod.filter((_row) => equipmentLookup.includes(_row.id));
			} else {
				// get where parent is 'selection.equipmentCostId' (STANDARD BEHAVIOR)
				result = _getRowsFromTablesDict(selection.equipmentCostId, selection.timePeriodId, data.equipmentTablesByCost);
			}
			return result;
		}
		// this should never happen
		return [];
	}
}
