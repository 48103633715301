import * as React from 'react';
import { FormGroup, FormControl, InputGroup } from 'react-bootstrap';
import type { WrappedFieldProps } from 'redux-form';

import Label from 'af-components/LockedValue/Label';
import type { OwnProps as TooltipProps } from 'af-components/Tooltip';

export type Sizes = 'sm' | 'lg';

interface Props extends WrappedFieldProps {
	type: string;
	name: string;
	id?: string;
	label?: string;
	isRequired?: boolean;
	placeholder: string;
	disabled?: boolean;
	onValueChange: (value: string) => Promise<void>;
	min?: number;
	max?: number;
	step?: number;
	maxCharacters?: number;
	showCharNum?: boolean;
	addonBefore?: React.Component;
	addonAfter?: React.ReactNode;
	addonAfterClick?: () => void;
	addonAfter2?: React.Component;
	addonAfter2Click?: () => void;
	isDollarValue?: boolean;
	isPercentValue?: boolean;
	className?: string;
	hideErrorText?: boolean;
	additionalElement?: JSX.Element;
	inputSize?: Sizes;
	forceShowError?: boolean;
	tooltipMessage?: TooltipProps['message'];
	autoFocus?: boolean;
	onKeyDown?: (event: Metadata) => void;
	onFocus?: (event: Metadata) => void;
	onBlur?: (event: Metadata) => void;
	isCentered?: boolean;
	disableErrors?: boolean;
	inputGroupClassName?: string;
	isConnected?: boolean;
	isStandalone?: boolean;
	style?: React.CSSProperties;
	afterInputStyle?: React.CSSProperties;
	afterInputContent?: string;
	addonAfterUnstyled?: boolean;
}

class Input extends React.Component<Props> {
	static defaultProps: Partial<Props> = {
		showCharNum: false,
		hideErrorText: false,
		disableErrors: false,
		forceShowError: false,
		isConnected: false,
		isStandalone: false,
		maxCharacters: 750,
		addonAfterUnstyled: false,
	};

	/**
	 * @param propName Usually a form prop name, such as form.item[0].attribute
	 * @returns Hopefully unique ID for QA team to attach tests to
	 */
	static _generateQAId = (propName: string) => `accqa__${propName.replaceAll('[', '-').replaceAll('.', '__').replaceAll(']', '')}`;

	onValChange = async (event) => {
		const { onValueChange, input } = this.props;

		if (input.onChange) {
			input.onChange(event);
		}
		if (onValueChange) {
			onValueChange(event.target.value as string);
		}
	};

	getClassName = () => {
		const {
			className,
			isCentered,
			isDollarValue,
			isPercentValue,
			isConnected,
			meta: { touched, error },
			disableErrors,
		} = this.props;

		let cn = className ?? '';
		cn = isDollarValue ? `${cn} dollar-value` : cn;
		cn = isPercentValue ? `${cn} percent-value` : cn;
		cn = touched && !disableErrors && error ? `${cn} with-error` : cn;
		cn = isCentered ? `${cn} centered` : cn;
		cn = isConnected ? `${cn} connected-input` : cn;
		return cn;
	};

	onFocus = (event) => {
		const { input, onFocus } = this.props;

		input.onFocus(event);

		if (onFocus) {
			onFocus(event);
		}
	};

	onBlur = (event) => {
		const { input, onBlur } = this.props;

		input.onBlur(event);

		if (onBlur) {
			onBlur(event);
		}
	};

	render() {
		const {
			id,
			disabled,
			input,
			label,
			isRequired,
			type,
			addonBefore,
			addonAfter,
			addonAfterClick,
			addonAfter2,
			addonAfter2Click,
			hideErrorText,
			meta: { touched, error, warning },
			min,
			max,
			step,
			maxCharacters,
			showCharNum,
			style,
			afterInputStyle,
			afterInputContent,
			additionalElement,
			inputSize,
			forceShowError,
			tooltipMessage,
			autoFocus,
			onKeyDown,
			disableErrors,
			inputGroupClassName,
			isStandalone,
			addonAfterUnstyled,
			placeholder,
		} = this.props;

		const isTextField = type === 'text';

		const groupId = input.name ? Input._generateQAId(input.name) : undefined;

		return (
			<FormGroup className={isStandalone ? 'form-group--standalone' : ''} controlId={id}>
				<div className="input-header">
					<Label
						isRequired={isRequired}
						label={label}
						tooltipMessage={tooltipMessage}
						tooltipPlacement="top"
						withMargin={true}
					/>
					{showCharNum && isTextField && maxCharacters && <span className="input-description pull-right">{input.value.length}/{maxCharacters}</span>}
					{!!additionalElement && additionalElement}
				</div>
				<InputGroup className={inputGroupClassName} id={groupId}>
					{addonBefore && <InputGroup.Prepend><>{addonBefore}</></InputGroup.Prepend>}
					<FormControl
						{...input}
						autoComplete="off"
						autoFocus={autoFocus}
						className={this.getClassName()}
						disabled={disabled}
						max={max}
						maxLength={maxCharacters}
						min={min}
						onBlur={this.onBlur}
						onChange={this.onValChange}
						onFocus={this.onFocus}
						onKeyDown={onKeyDown}
						placeholder={placeholder}
						size={inputSize}
						step={step}
						style={style}
						type={type}
					/>
					{!!(afterInputStyle ?? afterInputContent) && <div className="after-input" style={afterInputStyle}>{afterInputContent}</div>}
					{addonAfter &&
						<InputGroup.Append
							className={`${addonAfterUnstyled ? 'input-group-append--unstyled' : ''} ${addonAfterClick ? 'pointer' : ''}`}
							onClick={addonAfterClick ?? undefined}
						>
							{addonAfter}
						</InputGroup.Append>
					}
					{addonAfter2 &&
						<InputGroup.Append
							className={addonAfter2Click ? 'pointer' : ''}
							onClick={addonAfter2Click ?? undefined}
						>
							<>{addonAfter2}</>
						</InputGroup.Append>
					}
				</InputGroup>
				<FormControl.Feedback />
				{!!(!disableErrors && !hideErrorText && (touched || forceShowError)) &&
					(
						(error && <span className="help-block"><span className="icon-info" /> {error}</span>) ||
						(warning && <span className="help-block text-orange"><span className="icon-info" /> {warning}</span>)
					)
				}
			</FormGroup>
		);
	}
}

export default Input;
