import * as React from 'react';
import type { ConnectedProps } from 'react-redux';
import { connect } from 'react-redux';

import * as TimeUtils from 'acceligent-shared/utils/time';

import TimeFormat from 'acceligent-shared/enums/timeFormat';
import TimePeriodRecurrence from 'acceligent-shared/enums/timePeriodRecurrence';

import type UnionTypeVM from 'ab-viewModels/unionType/unionType.viewModel';
import { PrevailingWagesTableRowVM } from 'ab-viewModels/accounting/prevailingWagesTable.viewModel';

import type { TableQuery } from 'ab-common/dataStructures/tableQuery';

import * as AccountingActions from 'af-actions/accounting';
import * as UnionTypeActions from 'af-actions/unionType';

import Breadcrumbs from 'af-components/Breadcrumbs';
import LoadingIndicator from 'af-components/LoadingIndicator';
import type { TabProps, Column, ButtonData } from 'af-components/Table6';
import Table from 'af-components/Table6';
import type ScrollToLoad from 'af-components/ScrollToLoad';
import type TableComponent from 'af-components/Table6/Table';
import EmptyCell from 'af-components/Table6/Cells/EmptyCell';
import DateFilter from 'af-components/DateFilter';

import TableNameEnum from 'ab-enums/tableName.enum';
import TableButtonType from 'ab-enums/tableButtonType.enum';

import { downloadCSV } from 'af-utils/csv.utils';
import type { RootState } from 'af-reducers';

interface State {
	unionTypes: Nullable<UnionTypeVM[]>;
	startDate: Date;
	endDate: Date;
	period: TimePeriodRecurrence;
	codeList: string[];
	columns: Column<PrevailingWagesTableRowVM>[];
}

type Props = ConnectedProps<typeof connector>;

const PERIOD_OPTIONS = [TimePeriodRecurrence.DAILY, TimePeriodRecurrence.WEEKLY, TimePeriodRecurrence.CUSTOM];

class PrevailingWage extends React.PureComponent<Props, State> {

	readonly tableColumns: Column<PrevailingWagesTableRowVM>[] = [
		{
			Header: 'Job ID',
			accessor: 'jobCode',
			sortable: false,
			width: 200,
			Cell: ({ original }) => original.jobCode ?? <EmptyCell />,
		},
		{
			Header: 'State',
			accessor: 'jobState',
			sortable: false,
			Cell: ({ original }) => original.jobState ?? <EmptyCell />,
		},
	];

	state: State = {
		unionTypes: null,
		startDate: new Date(),
		endDate: new Date(),
		period: TimePeriodRecurrence.DAILY,
		codeList: [],
		columns: this.tableColumns,
	};

	static BREADCRUMBS = [{ label: 'Prevailing Wages' }];

	private _tableRef: Nullable<TableComponent<PrevailingWagesTableRowVM>> = null;
	private _list: Nullable<ScrollToLoad<PrevailingWagesTableRowVM>> = null;

	static EmployeeCell = ({ value }: { value: Nullable<string[]>; }) => {
		return (
			<div>
				{value?.map?.((_emp, _index) => <div key={_index}>{_emp}</div>) ?? <EmptyCell message="None" />}
			</div>
		);
	};

	static mapCodeToColumn = (code: string): Column<PrevailingWagesTableRowVM> => ({
		Header: code,
		accessor: `classificationCodeMap[${code}]`,
		sortable: false,
		width: 200,
		Cell: ({ original }) => <PrevailingWage.EmployeeCell value={original.classificationCodeMap[code]} />,
	});

	async componentDidMount() {
		const { fetchUnionTypes } = this.props;
		const unionTypes = await fetchUnionTypes();

		this.setState(() => ({ unionTypes }));
	}

	loadData = (unionTypeId: number, tableRequestModel: TableQuery) => {
		const { fetchTable } = this.props;
		const { startDate, endDate } = this.state;

		const start = TimeUtils.formatDate(startDate, TimeFormat.DB_DATE_ONLY);
		const end = TimeUtils.formatDate(endDate, TimeFormat.DB_DATE_ONLY);
		return fetchTable(unionTypeId, tableRequestModel, start, end);
	};

	fetch = (unionTypeId: number) => async (tableRequestModel: TableQuery) => {
		const data = await this.loadData(unionTypeId, tableRequestModel);
		this.setState(() => ({ columns: [...this.tableColumns, ...(data.rows[0]?.columns.map(PrevailingWage.mapCodeToColumn) ?? [])] }));
		return data;
	};

	export = (unionTypeId: number) => async () => {
		const { companyName } = this.props;
		const { startDate, endDate, period } = this.state;

		const start = TimeUtils.formatDate(startDate, TimeFormat.DATE_ONLY);
		const end = TimeUtils.formatDate(endDate, TimeFormat.DATE_ONLY);

		const data = await this.loadData(unionTypeId, { includeAll: true } as TableQuery);
		const fileName = `${companyName}_prevailingWageReport_${start}${period !== TimePeriodRecurrence.DAILY ? `_${end}` : ''}.csv`;

		downloadCSV(PrevailingWagesTableRowVM.toCSVData(data.rows), fileName);
		return;
	};

	onTableMount = (table: TableComponent<PrevailingWagesTableRowVM>, list: ScrollToLoad<PrevailingWagesTableRowVM>) => {
		this._tableRef = table;
		this._list = list;
	};

	refresh = () => {
		if (this._tableRef) {
			this._tableRef.refreshTable();
		} else if (this._list) {
			this._list.refreshList();
		}
	};

	filterByDate = (startDate: Date, endDate: Date) => this.setState(() => ({ startDate, endDate }), this.refresh);

	changePeriod = (period: TimePeriodRecurrence, selected: Date) => {
		let startDate: Nullable<Date> = null;
		let endDate: Nullable<Date> = null;

		switch (period) {
			case TimePeriodRecurrence.WEEKLY:
				// FIXME: refactor so that moment is not exposed
				const selectedMoment = TimeUtils.parseMoment(selected);
				if (!selectedMoment) {
					throw new Error('Selected date not parseable');
				}
				startDate = selectedMoment.clone().startOf('week').toDate();
				endDate = selectedMoment.clone().endOf('week').toDate();
				break;
			case TimePeriodRecurrence.DAILY:
			case TimePeriodRecurrence.CUSTOM:
			default:
				startDate = selected;
				endDate = selected;
				break;
		}

		if (!startDate || !endDate) {
			throw new Error('Date range incorrectly defined');
		}

		this.setState(() => ({ period, startDate: startDate!, endDate: endDate! }), this.refresh);
	};

	renderFilter = () => {
		const { endDate, startDate, period } = this.state;

		// FIXME: not `field-report-orders-table`
		return (
			<div className="table-filter field-report-orders-table__filters">
				<DateFilter
					changePeriod={this.changePeriod}
					endDate={endDate}
					onChange={this.filterByDate}
					options={PERIOD_OPTIONS}
					period={period}
					startDate={startDate}
				/>
			</div>
		);
	};

	tabs = (): TabProps<PrevailingWagesTableRowVM>[] => {
		const { unionTypes } = this.state;

		return (unionTypes ?? []).map((_unionType) => ({
			buttons: [
				{
					type: TableButtonType.EXPORT,
					hasPermission: true,
					onClick: this.export(_unionType.id),
				},
			] as ButtonData[],
			label: _unionType.name ?? '',
			columns: this.state.columns,
			hasSearchInput: true,
			additionalFilter: this.renderFilter,
			fetch: this.fetch(_unionType.id),
		}));
	};

	render() {
		const { unionTypes } = this.state;

		if (!unionTypes) {
			return <LoadingIndicator />;
		}

		return (
			<div className="form-segment form-segment--maxi">
				<Breadcrumbs items={PrevailingWage.BREADCRUMBS} />
				<Table
					dynamicColumns={true}
					onMount={this.onTableMount}
					tableName={TableNameEnum.PREVAILING_WAGE_REPORT}
					tabs={this.tabs()}
				/>
			</div>
		);
	}
}

function mapStateToProps(state: RootState) {
	const { companyData } = state.user;
	if (!companyData) {
		throw new Error('User not logged in');
	}

	return {
		companyName: companyData.name,
	};
}

function mapDispatchToProps() {
	return {
		fetchUnionTypes: UnionTypeActions.findAll,
		fetchTable: AccountingActions.findPrevailingWagesTableWithClassificationCodes,
	};
}

const connector = connect(mapStateToProps, mapDispatchToProps());

export default connector(PrevailingWage);
