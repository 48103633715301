import * as React from 'react';
import { compose } from 'redux';
import type { ConnectedProps } from 'react-redux';
import { connect } from 'react-redux';
import { Field, formValueSelector } from 'redux-form';

import AssignableResourceType from 'acceligent-shared/enums/assignableResourceType';

import type { RootState } from 'af-reducers';

import { WORK_ORDER_FORM } from 'af-constants/reduxForms';

import type { EquipmentRM } from 'ab-dtos/requestModels/workOrder/upsert';

import type EquipmentViewModel from 'ab-viewModels/equipment.viewModel';
import type WorkOrderResourceLookupViewModel from 'ab-viewModels/workOrderResourceLookup.viewModel';

import { AVAILABLE_EQUIPMENT_STATUS } from 'ab-common/constants/equipment';

import Dropdown from 'af-fields/Dropdown';

import StatusLabel from 'af-components/StatusLabel';

import EquipmentOptionItem from './EquipmentOptionItem';

type EquipmentOption = EquipmentViewModel & { disabled: boolean; };
type EquipmentSection = { title: string; options: EquipmentOption[]; };

type EquipmentSectionsAvailabilityType = {
	sections: { [status: string]: EquipmentOption[]; };
	availability: { [status: string]: boolean; };
};

interface OwnProps {
	disabled: boolean;
	dueDate: string;
	equipment: EquipmentViewModel[];
	name: string;
	onLazyLoad: (isLazyLoaded: boolean) => void;
	propName: string;
	resetTimer: () => void;
	showResourceAssignConfirmationModal: (resourceType: AssignableResourceType) => void;
}

type Props = OwnProps & ConnectedProps<typeof connector>;

const FILTER_BY: (keyof EquipmentViewModel)[] = ['code', 'specification'];

const renderEquipmentOptionItem = (option: EquipmentViewModel | EquipmentRM, searchText: string) => <EquipmentOptionItem {...option} searchText={searchText} />;

const equipmentSectionMapper = ([title, options]: [string, EquipmentOption[]]): EquipmentSection => {
	return {
		title,
		options,
	};
};

const getEquipmentStatusReducer = (selectedEquipmentIds: { [equipmentId: number]: boolean; }) => {
	return (acc: EquipmentSectionsAvailabilityType, equipment: EquipmentViewModel) => {
		const { equipmentStatusName, isStatusAvailable } = equipment;
		const status = equipmentStatusName ?? AVAILABLE_EQUIPMENT_STATUS;

		if (!acc.sections[status]) {
			acc.sections[status] = [] as EquipmentOption[];
		}
		acc.sections[status].push({
			...equipment,
			disabled: !!selectedEquipmentIds[equipment.id],
		});
		acc.availability[status] = !!isStatusAvailable;
		return acc;
	};
};

const getOptions = (equipment: EquipmentViewModel[], selectedEquipmentIds: { [equipmentId: number]: boolean; }) => {
	const { sections, availability } = equipment.reduce(
		getEquipmentStatusReducer(selectedEquipmentIds),
		{ sections: {}, availability: {} } as EquipmentSectionsAvailabilityType
	);

	const options = Object.entries(sections)
		.map(equipmentSectionMapper);

	return {
		options,
		availability,
	};
};

const deriveStateFromProps = (equipment: EquipmentViewModel[], selectedResourceLookups: WorkOrderResourceLookupViewModel[]) => {
	const selectedResourceLookupsEquipmentIds = selectedResourceLookups.reduce((_acc, _resourceLookup) => {
		if (_resourceLookup.equipmentId) {
			_acc[_resourceLookup.equipmentId] = true;
		}
		return _acc;
	}, {} as { [equipmentId: string]: boolean; });
	return getOptions(equipment ?? [], selectedResourceLookupsEquipmentIds);
};

const EquipmentItem: React.FC<Props> = (props: Props) => {
	const { name, propName, disabled, onLazyLoad, resetTimer, showResourceAssignConfirmationModal, equipment, selectedResourceLookups } = props;

	const [options, setOptions] = React.useState<EquipmentSection[]>([]);
	const [availability, setAvailability] = React.useState<{ [status: string]: boolean; }>({});

	React.useEffect(() => {
		const {
			options: newOptions,
			availability: newAvailability,
		} = deriveStateFromProps(equipment, selectedResourceLookups);
		setOptions(newOptions);
		setAvailability(newAvailability);
		// eslint-disable-next-line react-hooks/exhaustive-deps
	}, []);

	React.useEffect(() => {
		const {
			options: newOptions,
			availability: newAvailability,
		} = deriveStateFromProps(equipment, selectedResourceLookups);
		setOptions(newOptions);
		setAvailability(newAvailability);
	}, [equipment, selectedResourceLookups]);

	const onChange = React.useCallback((option: EquipmentOption) => {
		if (!option.isStatusAvailable) {
			showResourceAssignConfirmationModal(AssignableResourceType.EQUIPMENT);
		}
		resetTimer();
	}, [resetTimer, showResourceAssignConfirmationModal]);

	const renderSectionHeader = React.useCallback((section: EquipmentSection) => {
		const equipmentStatusName = section.title;

		const available = availability[equipmentStatusName];
		return (
			<div className="resource-lookup__resource-status">
				<StatusLabel isAvailable={available ?? true} label={equipmentStatusName ?? AVAILABLE_EQUIPMENT_STATUS} />
			</div>
		);
	}, [availability]);

	return (
		<div className="resource-lookup__item">
			<Field
				component={Dropdown}
				disabled={disabled}
				filterable={true}
				filterBy={FILTER_BY}
				hideErrorText={true}
				id={name}
				isArrayField={true}
				isStandalone={true}
				name={name}
				onLazyLoad={onLazyLoad}
				onValueChange={onChange}
				placeholder="Choose equipment"
				propName={propName}
				renderMenuItem={renderEquipmentOptionItem}
				renderSectionHeader={renderSectionHeader}
				sectionOptionsKey="options"
				sections={options}
				sectionTitleKey="title"
				useSectionList
				valueKey="id"
				withCaret={true}

			/>
		</div>
	);
};

const selector = formValueSelector(WORK_ORDER_FORM);

function mapStateToProps(state: RootState) {
	const selectedResourceLookups = selector(state, 'workOrderResourceLookups') as WorkOrderResourceLookupViewModel[] || undefined;
	return {
		selectedResourceLookups,
	};
}

const connector = connect(mapStateToProps);

const enhance = compose<React.ComponentType<OwnProps>>(
	React.memo,
	connector
);

export default enhance(EquipmentItem);
