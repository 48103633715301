import * as React from 'react';
import { Field } from 'redux-form';
import { Form } from 'react-bootstrap';

import Checkbox from 'af-fields/Checkbox';

import { formSectionClass, formSectionClassColumn8, formSectionClassRow, formSectionTitleClass } from '../../helpers';

interface OwnProps {
	isDisabled: boolean;
}

type Props = OwnProps;
const CustomerSignatureSection: React.FC<Props> = (props) => {

	const {
		isDisabled,
	} = props;

	return (
		<Form className={formSectionClass}>
			<div className={formSectionTitleClass}>
				Customer Signature
			</div>
			<div className={formSectionClassRow}>
				<div className={formSectionClassColumn8}>
					<Field
						component={Checkbox}
						inline={true}
						isDisabled={isDisabled}
						isStandalone={true}
						label="Allow Customer Signature"
						name="allowCustomerSignature"
						propName="allowCustomerSignature"
					/>
				</div>
			</div>
		</Form>
	);
};

export default React.memo(CustomerSignatureSection);
