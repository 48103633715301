import * as React from 'react';
import { Route, Routes } from 'react-router-dom-v5-compat';

import WorkRequestPreviewTab from 'af-components/SharedPreviews/WorkRequest/Details';
import DirectoriesAttachments from 'af-components/SharedPreviews/WorkRequest/DirectoriesAttachments';
import JobHazardAssessment from 'af-components/SharedPreviews/WorkRequest/JobHazardAssessment';

import Error404 from 'af-root/scenes/Error/error404';

import RelatedWorkOrdersTab from './RelatedWorkOrdersTab';
import WorkSummary from './WorkSummary';
import Invoices from './Invoices';
import BillingCodesTab from './BillingCodesTab';
import JobPreview from './index';
import { JobPreviewTabRoutes } from './types';

const JobPreviewRouter: React.FC = () => {
	return (
		<Routes>
			<Route
				element={<JobPreview />}
			>
				<Route element={<WorkRequestPreviewTab />} index />
				<Route element={<WorkRequestPreviewTab />} path={JobPreviewTabRoutes.DETAILS} />
				<Route element={<BillingCodesTab />} path={JobPreviewTabRoutes.BILLING_CODES} />
				<Route element={<JobHazardAssessment />} path={JobPreviewTabRoutes.JOB_HAZARD_ASSESSMENT} />
				<Route element={<DirectoriesAttachments />} path={JobPreviewTabRoutes.ATTACHMENTS} />
				<Route element={<RelatedWorkOrdersTab />} path={JobPreviewTabRoutes.RELATED_WORK_ORDERS} />
				<Route element={<WorkSummary />} path={JobPreviewTabRoutes.WORK_SUMMARY} />
				<Route element={<Invoices />} path={JobPreviewTabRoutes.INVOICES} />
			</Route>
			<Route element={<Error404 />} path="*" />
		</Routes>
	);
};

export default React.memo(JobPreviewRouter);
