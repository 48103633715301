enum ScheduleBoardSettings {
	displayViewChangeTime = 'Display View day change at:',
	displayViewRotationTime = 'Display View rotation time:',
	cdlInSkillsGrid = 'Show CDL indicator in skills color grid',
	displayViewType = 'Section shown on Display View:',
	includeNonWorkingDaysOnDisplayView = 'Show nonworking days on Display View'
}

export enum ScheduleBoardSettingsHint {
	displayViewChangeTime = 'Time at which Display View switches to the next day.',
	displayViewRotationTime = 'Display View section rotation interval.',
	cdlInSkillsGrid = 'Display CDL as a skill in the top left corner of the skills grid. CDL as a skill can only be used in that place and its color cannot be changed. Having CDL B or C will not be displayed, while CDL A will be shown as a black indicator.',
	includeNonWorkingDaysOnDisplayView = 'Include non-working days when showing next day on Display View.',
	displayViewType = 'Which Schedule Board section is shown on Display View.',
	skillGrid = 'Which skill colors are shown on Schedule Board.'
}

export default ScheduleBoardSettings;
