import * as React from 'react';
import type { ConnectedProps } from 'react-redux';
import { connect } from 'react-redux';
import type { FormErrorsWithArray, WrappedFieldArrayProps } from 'redux-form';
import type { CellContext } from '@tanstack/react-table';

import * as TimeUtils from 'acceligent-shared/utils/time';

import type FileType from 'acceligent-shared/enums/fileType';
import type ResourceStatus from 'acceligent-shared/enums/resourceStatus';

import * as BlobFileUtil from 'ab-utils/blobFile.util';

import * as AttachmentActions from 'af-actions/attachment';

import type { SimpleTableRow } from 'af-fields/SimpleTable';
import SimpleTable from 'af-fields/SimpleTable';

import ImagePreviewModal from 'af-components/DocumentsAttachmentsTree/TreeTableBody/ImagePreviewModal';

import type { InvoiceFM } from '../EditWhenInvoiced/formModel';
import styles from '../EditWhenInvoiced/styles.module.scss';
import DocumentPreviewModal from './DocumentPreviewModal';

import { useNotificationSnackbar } from 'af-root/hooks/useNotificationSnackbar';

export type AttachmentFM = Required<InvoiceFM>['attachments'][0];

export type CustomFormErrors<Model> = { [K in keyof Model]: Model[K] extends object ? CustomFormErrors<Model[K]> : string };
export type InstallmentErrors = CustomFormErrors<AttachmentFM[]>;

interface AttachmentPreview {
	id: number;
	size: number;
	name: string;
	type: FileType;
	status: ResourceStatus;
	storageName: string;
	storageContainer: string;
	/** original version with presigned url */
	originalSrc: string;
	copyToWorkOrderFlag?: boolean;
	lastModifiedAt: Date;
	uploadedBy: Nullable<{ fullName: string; }>;
	directoryId?: number;
}

export interface OwnProps {
	errors: FormErrorsWithArray<InvoiceFM, string>;
	initialized: boolean;
	onDelete: (attachmentId: number) => void;
}

type Props = OwnProps & WrappedFieldArrayProps<AttachmentFM> & ConnectedProps<typeof connector>;

const AttachmentItems: React.FC<Props> = (props) => {
	const {
		fields,
		errors,
		initialized,
		downloadAttachment,
		downloadAttachmentForPreview,
		onDelete,
	} = props;

	const [modalPreviewDocVisible, setModalPreviewDocVisible] = React.useState(false);
	const [previewAttachment, setPreviewAttachment] = React.useState<Nullable<AttachmentPreview>>(null);
	const [previewData, setPreviewData] = React.useState<Buffer>();
	const notificationSnackbar = useNotificationSnackbar();

	const onPreviewDocumentClick = React.useCallback(async (attachment: AttachmentFM & SimpleTableRow) => {
		if (modalPreviewDocVisible) {
			setModalPreviewDocVisible(false);
			return;
		}
		const data = await downloadAttachmentForPreview(attachment.id);
		setPreviewData(data.data);
		setModalPreviewDocVisible(true);
	}, [downloadAttachmentForPreview, modalPreviewDocVisible]);

	const closePreview = React.useCallback(() => {
		setPreviewAttachment(null);
	}, []);

	const openAttachment = React.useCallback((attachment: AttachmentFM & SimpleTableRow) => {
		setPreviewAttachment(attachment);
	}, []);

	const renderPreview = React.useCallback((attachment: AttachmentFM & SimpleTableRow) => {

		if (BlobFileUtil.isImageFile(attachment.storageName)) {
			return (
				<span className={styles['installments__invoiced__list__item__preview__download-preview']}>
					<span className="icon-search pointer" onClick={openAttachment.bind(this, attachment)} />
				</span>
			);
		}

		if (BlobFileUtil.isPDFFile(attachment.storageName)) {
			return (
				<span className={styles['installments__invoiced__list__item__preview__download-preview']}>
					<span className="icon-search pointer" onClick={onPreviewDocumentClick.bind(this, attachment)} />
				</span>
			);
		}

		return (<></>);

	}, [onPreviewDocumentClick, openAttachment]);

	const renderDownload = React.useCallback((attachment: AttachmentFM & SimpleTableRow) => {
		const onDownloadAttachmentsClick = async (e: React.MouseEvent<HTMLElement, MouseEvent>) => {
			e.stopPropagation();
			const startMsg = notificationSnackbar.loading(`Started Downloading ${attachment.fileName}`);
			const attachmentId = attachment.id;
			await downloadAttachment(attachmentId, attachment.name);
			notificationSnackbar.removeNotificationSnackbar(startMsg);
			notificationSnackbar.success(`${attachment.fileName} downloaded`);
		};
		return (
			<span className={styles['installments__invoiced__list__item__preview__download-preview']} onClick={onDownloadAttachmentsClick.bind(this)}>
				<span className="icon-download pointer" />
			</span>
		);
	}, [downloadAttachment, notificationSnackbar]);

	const onDeleteAttachmentField = React.useCallback((index: number) => {
		const attachment = fields.get(index);
		onDelete(attachment.id);
	}, [fields, onDelete]);

	const columns = React.useMemo(() => [{
		id: 'fileName',
		cell: (_cell: CellContext<AttachmentFM & SimpleTableRow, string>) => {
			return _cell.getValue() ?? 'N/A';
		},
		header: 'File Name',
		accessor: 'fileName',
		enableSorting: true,
	},
	{
		id: 'preview',
		cell: (_cell: CellContext<AttachmentFM & SimpleTableRow, string>) => {
			return renderPreview(_cell.row.original);
		},
		header: 'Preview',
		accessor: 'preview',
	},
	{
		id: 'size',
		cell: (_cell: CellContext<AttachmentFM & SimpleTableRow, number>) => {
			return _cell.getValue() ? _cell.getValue() / 1000 + ' MB' : 'N/A';
		},
		header: 'Size',
		accessor: 'size',
		enableSorting: true,
	},
	{
		id: 'download',
		cell: (_cell: CellContext<AttachmentFM & SimpleTableRow, string>) => {
			return renderDownload(_cell.row.original);
		},
		header: 'Download',
		accessor: 'download',
	},
	{
		id: 'uploadedOn',
		cell: (_cell: CellContext<AttachmentFM & SimpleTableRow, string>) => {
			return TimeUtils.toDatabaseDateOnly(_cell.getValue()) ?? 'N/A';
		},
		header: 'Uploaded On',
		accessor: 'lastModifiedAt',
		enableSorting: true,
	},
	{
		id: 'uploadedBy',
		cell: (_cell: CellContext<AttachmentFM & SimpleTableRow, string>) => {
			const user = _cell.row.original.uploadedBy;
			return !user ? 'N/A' : user.fullName;
		},
		header: 'Uploaded By',
		accessor: 'uploadedBy',
		enableSorting: true,
	},
	], [renderDownload, renderPreview]);

	return (
		<>
			<SimpleTable
				allowDeleteOnly={true}
				allowEdit={true}
				columns={columns}
				errors={errors.attachments}
				fields={fields}
				initialized={initialized}
				onDelete={onDeleteAttachmentField}
			/>
			{previewAttachment && (
				<ImagePreviewModal
					attachment={previewAttachment}
					setModalVisible={closePreview}
					showModal={!!previewAttachment}
				/>
			)}
			{previewData &&
				<DocumentPreviewModal
					data={previewData}
					setModalVisible={setModalPreviewDocVisible}
					showModal={modalPreviewDocVisible}
				/>
			}
		</>
	);
};

function mapDispatchToProps() {
	return {
		downloadAttachment: AttachmentActions.downloadAttachment,
		downloadAttachmentForPreview: AttachmentActions.downloadAttachmentForPreview,
	};
}

const connector = connect(null, mapDispatchToProps());

export default connector(AttachmentItems);
