import * as React from 'react';

import WorkSummaryStatusEnum from 'acceligent-shared/enums/workSummaryStatus';

import { bemBlock } from 'ab-utils/bem.util';

import type WorkSummaryStatusVM from 'ab-viewModels/workOrder/workSummaryStatus.viewModel';

interface Props {
	status: WorkSummaryStatusVM;
}

const _resolveWorkSummaryStatus = (workSummaryStatus: WorkSummaryStatusEnum) => {
	switch (workSummaryStatus) {
		case WorkSummaryStatusEnum.DRAFT: {
			return (
				<span className={bemBlock('field-report__work-summary__status', { draft: true })}>
					Draft
				</span>
			);
		}
		case WorkSummaryStatusEnum.IN_PROGRESS: {
			return (
				<span className={bemBlock('field-report__work-summary__status', { 'in-progress': true })}>
					In Progress
				</span>
			);
		}
		case WorkSummaryStatusEnum.OUTDATED: {
			return (
				<span className={bemBlock('field-report__work-summary__status', { outdated: true })}>
					Outdated
				</span>
			);
		}
		case WorkSummaryStatusEnum.REVIEWED: {
			return (
				<span className={bemBlock('field-report__work-summary__status', { reviewed: true })}>
					Reviewed
				</span>
			);
		}
		case WorkSummaryStatusEnum.COMPLETED: {
			return (
				<span className={bemBlock('field-report__work-summary__status', { completed: true })}>
					Completed
				</span>
			);
		}
	}
};

const WorkSummaryStatus: React.FC<Props> = (props) => {
	const { status: { status, lastUpdatedAt, lastUpdatedBy } } = props;

	const updatedInfo = (lastUpdatedAt && lastUpdatedBy)
		? `${lastUpdatedAt} By ${lastUpdatedBy}`
		: '';

	return (
		<div className="field-report__work-summary__status-container">
			{_resolveWorkSummaryStatus(status)}
			<span>{updatedInfo}</span>
		</div>
	);
};

export default React.memo(WorkSummaryStatus);
