import * as React from 'react';
import type { ConnectedProps } from 'react-redux';
import { connect } from 'react-redux';
import { Link } from 'react-router-dom';
import { Button } from 'react-bootstrap';
import type { History } from 'history';

import WorkOrderStatus from 'acceligent-shared/enums/workOrderStatus';
import WorkRequestStatus from 'acceligent-shared/enums/workRequestStatus';
import TimeFormatEnum from 'acceligent-shared/enums/timeFormat';

import * as TimeUtils from 'acceligent-shared/utils/time';

import type { RootState } from 'af-reducers';

import TotalLaborStatistics from 'af-components/LaborStatistics/TotalLaborStatistics';
import LaborStatisticsPerLocation from 'af-components/LaborStatistics/LaborStatisticsPerLocation';

import Checkbox from 'af-components/Controls/Checkbox';

import WorkOrderCopyModal from 'af-root/scenes/Company/WorkOrders/Table/OrderCopyForm';
import NotifyAllParticipantsModal from 'af-root/scenes/Company/ScheduleBoard/Shared/NotifyAllParticipantsModal';

import { TemplateNotificationEnum } from 'ab-enums/notifyType.enum';
import PagePermissions from 'ab-enums/pagePermissions.enum';

import type WorkOrderCopyRM from 'ab-requestModels/workOrder/copyWorkOrders.requestModel';

import { convertWorkDays } from 'ab-viewModels/company.viewModel';
import type ScheduleBoardLaborStatistics from 'ab-viewModels/scheduleBoardLaborStatistics.viewModel';
import type ScheduleBoardWorkOrderViewModel from 'ab-socketModels/viewModels/scheduleBoard/scheduleBoardWorkOrder.viewModel';
import type { EmailAndSmsStatusViewModelMap, EmailAndSmsStatusViewModel } from 'ab-viewModels/emailAndSmsStatus.viewModel';
import type { DailyTipViewModel } from 'ab-viewModels/dailyTip.viewModel';
import type { NotificationStatusByEmployee, NotificationStatusByTemporaryEmployee } from 'ab-viewModels/notification.viewModel';

import * as ScheduleBoardActions from 'af-actions/scheduleBoard';
import * as WorkOrderActions from 'af-actions/workOrder';
import * as DailyTipActions from 'af-actions/dailyTip';
import * as NotifyActions from 'af-actions/notify';

import type { BaseWorkOrderModel } from 'af-models/scheduleBoard.models';

import CLIENT from 'af-constants/routes/client';

import { SCREEN_BREAKPOINT_M, SCREEN_BREAKPOINT_L } from 'af-constants/values';

import { filterMap } from 'ab-utils/array.util';
import * as ScheduleBoardUtil from 'af-utils/scheduleBoard.util';
import * as WorkOrderUtils from 'af-utils/workOrder.util';
import { setWorkOrderSelectedDueDate } from 'af-utils/settings.util';

import BigButton from './BigButton';
import SmallButton from './SmallButton';
import { isAllowed } from 'ab-utils/auth.util';

interface OwnProps {
	history: History;
	orgAlias: string;
	dueDate: string;
	companyName: string;
	isDragAndDropDisabled: boolean;
	hasPermissionsToEditScheduleBoard: boolean;
}

type Props = OwnProps & ConnectedProps<typeof connector>;

interface State {
	copyDayModalVisible: boolean;
	buttonDisplayLevel: number;
	notifyAllModalVisible: boolean;
	notificationStatusByEmployee: NotificationStatusByEmployee;
	notificationStatusByTemporaryEmployee: NotificationStatusByTemporaryEmployee;
}

class ToolbarInfo extends React.PureComponent<Props, State> {
	static defaultProps: Partial<Props> = {
		workDays: [],
		laborStatistics: {
			laborStatisticsPerLocation: undefined,
			totalLaborStatistics: undefined,
		},
		employees: {},
		dailyTip: {} as DailyTipViewModel,
	};

	state: State = {
		copyDayModalVisible: false,
		buttonDisplayLevel: 0,
		notifyAllModalVisible: false,
		notificationStatusByEmployee: {
			...(this.props.assignedCanceledNotificationStatuses ?? {}),
			...(this.props.assignedPublishedNotificationStatuses ?? {}),
		},
		notificationStatusByTemporaryEmployee: {
			...(this.props.assignedPublishedTempLaborNotificationStatuses ?? {}),
			...(this.props.assignedCanceledTempLaborNotificationStatuses ?? {}),
		},
	};

	componentDidMount() {
		this.updateWindowDimensions();
		window.addEventListener('resize', this.updateWindowDimensions);
	}

	componentDidUpdate(prevProps: Props) {
		this.updateWindowDimensions();

		const {
			assignedCanceledNotificationStatuses,
			assignedPublishedNotificationStatuses,
			assignedPublishedTempLaborNotificationStatuses,
			assignedCanceledTempLaborNotificationStatuses,
		} = this.props;

		if (prevProps.assignedCanceledNotificationStatuses !== assignedCanceledNotificationStatuses
			|| prevProps.assignedPublishedNotificationStatuses !== assignedPublishedNotificationStatuses
		) {
			this.setState(() => ({
				notificationStatusByEmployee: {
					...(assignedCanceledNotificationStatuses ?? {}),
					...(assignedPublishedNotificationStatuses ?? {}),
				},
			}));
		}

		if (prevProps.assignedPublishedTempLaborNotificationStatuses !== assignedPublishedTempLaborNotificationStatuses
			|| prevProps.assignedCanceledTempLaborNotificationStatuses !== assignedCanceledTempLaborNotificationStatuses
		) {
			this.setState(() => ({
				notificationStatusByTemporaryEmployee: {
					...(assignedPublishedTempLaborNotificationStatuses ?? {}),
					...(assignedCanceledTempLaborNotificationStatuses ?? {}),
				},
			}));
		}
	}

	componentWillUnmount() {
		const { isSelectMultipleEnabled, toggleWeeklyViewSelectMultiple, dueDate } = this.props;
		window.removeEventListener('resize', this.updateWindowDimensions);
		if (isSelectMultipleEnabled) {
			toggleWeeklyViewSelectMultiple(dueDate);
		}
	}

	updateWindowDimensions = () => {
		const { zoomLevel } = this.props;

		let buttonDisplayLevel = 0;

		if (window.innerWidth <= SCREEN_BREAKPOINT_M && zoomLevel > 1) {
			buttonDisplayLevel = 1;
		}

		if (window.innerWidth > SCREEN_BREAKPOINT_M && zoomLevel > 0) {
			buttonDisplayLevel = 2;
		}

		if (window.innerWidth > SCREEN_BREAKPOINT_L) {
			buttonDisplayLevel = 2;
		}

		if (buttonDisplayLevel !== this.state.buttonDisplayLevel) {
			this.setState(() => ({ buttonDisplayLevel }));
		}
	};

	openCopyDayModal = () => {
		const { workDays, isSelectMultipleEnabled, dueDate, reinitializeCopyForm, selectedWorkOrders, workOrders } = this.props;

		const _workOrders: BaseWorkOrderModel[] = isSelectMultipleEnabled ? selectedWorkOrders : Object.values(workOrders || {});

		const validityCondition = (wo: BaseWorkOrderModel) => {
			if (workOrders?.[wo.code]?.jobStatus === WorkRequestStatus.FINISHED) {
				return 'finishedOrders';
			} else if (workOrders?.[wo.code]?.status === WorkOrderStatus.CANCELED) {
				return 'cancelledOrders';
			} else {
				return 'validOrders';
			}
		};

		const { validOrders, finishedOrders, cancelledOrders } = WorkOrderUtils.groupWorkOrdersByValidity(_workOrders, validityCondition);

		const dueDateMoment = TimeUtils.parseMoment(dueDate);
		if (!dueDateMoment) {
			throw new Error('Due Date incorrectly formatted');
		}

		reinitializeCopyForm(
			dueDateMoment.add(1, 'days').format(TimeFormatEnum.DATE_ONLY),
			dueDate,
			convertWorkDays(workDays),
			validOrders,
			finishedOrders,
			cancelledOrders
		);
		this.setState(() => ({ copyDayModalVisible: true }));
	};

	closeCopyDayModal = () => {
		this.setState(() => ({ copyDayModalVisible: false }));
	};

	copyOrdersOnDay = async (copyForm: WorkOrderCopyRM) => {
		const {
			toggleDayWeeklyView,
			copyWorkOrder,
			isSelectMultipleEnabled,
			selectedWorkOrders,
			dueDate,
		} = this.props;

		if (isSelectMultipleEnabled) {
			const expandedCopyForm: WorkOrderCopyRM = {
				...copyForm,
				selectedOrdersIds: filterMap(selectedWorkOrders,
					(_workOrder) => _workOrder.status !== WorkOrderStatus.CANCELED && _workOrder.jobStatus !== WorkRequestStatus.FINISHED,
					({ id }) => id),
			};
			await copyWorkOrder(expandedCopyForm);
			toggleDayWeeklyView(dueDate, false);
		} else {
			await copyWorkOrder(copyForm);
		}
		this.closeCopyDayModal();
	};

	showToolbarForDay = () => {
		const { dueDate, toggleWeeklyViewSelectMultiple, setWeeklyViewDateWithToolbar, isSelectMultipleEnabled } = this.props;
		if (isSelectMultipleEnabled) {
			toggleWeeklyViewSelectMultiple(dueDate);
		}
		setWeeklyViewDateWithToolbar(dueDate);
	};

	selectMultiple = () => {
		const { toggleWeeklyViewSelectMultiple, dueDate } = this.props;
		toggleWeeklyViewSelectMultiple(dueDate);
	};

	onCheck = () => {
		const { isAllSelected, toggleDayWeeklyView, dueDate } = this.props;
		toggleDayWeeklyView(dueDate, !isAllSelected);
	};

	openNotifyAllModal = async () => {
		const { findAllWorkOrdersWithNotificationStatusesByDueDate, dueDate } = this.props;
		this.setState(() => ({ notifyAllModalVisible: true }));
		await findAllWorkOrdersWithNotificationStatusesByDueDate(dueDate);
	};

	closeNotifyAllModal = () => {
		this.setState(() => ({ notifyAllModalVisible: false }));
	};

	getPublishedWorkOrderList = () => {
		const { participantsEmailAndSmsStatuses, selectedWorkOrders } = this.props;
		return selectedWorkOrders.reduce<(EmailAndSmsStatusViewModel & ScheduleBoardWorkOrderViewModel)[]>((_acc, _wo) => {
			if (_wo.status === WorkOrderStatus.PUBLISHED) {
				_acc.push({ ..._wo, ...participantsEmailAndSmsStatuses[_wo.code] });
			}
			return _acc;
		}, []);
	};

	getLongestNotificationMessage = () => {
		const { workOrderList } = this.props;
		const notificationsLengths = workOrderList.map((_wo) => _wo?.notificationTemplateLength ?? 0);
		return Math.max(...notificationsLengths);
	};

	getCanceledWorkOrderList = () => {
		const { participantsEmailAndSmsStatuses, selectedWorkOrders } = this.props;
		return selectedWorkOrders.reduce<(ScheduleBoardWorkOrderViewModel & ScheduleBoardWorkOrderViewModel)[]>((_acc, _wo) => {
			if (_wo.status === WorkOrderStatus.CANCELED) {
				_acc.push({ ..._wo, ...participantsEmailAndSmsStatuses[_wo.code] });
			}
			return _acc;
		}, []);
	};

	getUnpublishedWorkOrderList = () => {
		const { selectedWorkOrders } = this.props;
		return selectedWorkOrders.filter((_wo) => _wo.status === WorkOrderStatus.DRAFT || _wo.status === WorkOrderStatus.OUTDATED);
	};

	goToCalculations = () => {
		const { scheduleBoardView, history, orgAlias, companyName, dueDate, clearDailyView, clearWeeklyView } = this.props;

		const dueDateMoment = TimeUtils.parseMoment(dueDate);
		if (!dueDateMoment) {
			throw new Error('Due date not provided');
		}

		setWorkOrderSelectedDueDate(dueDateMoment);
		ScheduleBoardUtil.clearScheduleBoardRedux(
			scheduleBoardView,
			clearDailyView,
			clearWeeklyView
		);

		history.push({
			pathname: CLIENT.COMPANY.SCHEDULE_BOARD.DAILY_METRICS(orgAlias, companyName),
			state: { fromWeeklyView: true, originUrl: CLIENT.COMPANY.SCHEDULE_BOARD.WEEKLY_VIEW(orgAlias, companyName) },
		});
	};

	notifyParticipants = async (
		workOrderEmployeeIdsForSms: number[],
		workOrderEmployeeIdsForEmail: number[],
		workOrderTemporaryEmployeeIdsForEmail: number[],
		workOrderTemporaryEmployeeIdsForSms: number[],
		message?: string,
		isEdited?: boolean
	) => {
		let customTipId: Nullable<number> = null;
		if (message) {
			customTipId = isEdited ? await this.createCustomDailyTip(message) : this.props.dailyTip.id;
		}
		const { sendTemplateNotification, dueDate: _dueDate } = this.props;
		await sendTemplateNotification({
			dueDate: TimeUtils.formatDate(_dueDate, TimeFormatEnum.DB_DATE_ONLY, TimeFormatEnum.DATE_ONLY),
			employeeEmailIds: workOrderEmployeeIdsForEmail,
			employeeSmsIds: workOrderEmployeeIdsForSms,
			tempEmployeeEmailIds: workOrderTemporaryEmployeeIdsForEmail,
			tempEmployeeSmsIds: workOrderTemporaryEmployeeIdsForSms,
			notificationType: TemplateNotificationEnum.PUBLISHED_WORK_ORDER,
			dailyTipId: customTipId,
		});
	};

	scheduleAutoNotify = async (
		workOrderEmployeeIdsForSms: number[],
		workOrderEmployeeIdsForEmail: number[],
		workOrderTemporaryEmployeeIdsForEmail: number[],
		workOrderTemporaryEmployeeIdsForSms: number[],
		message?: string
	) => {
		const { scheduleForAutoNotify, dueDate } = this.props;

		const customTipId = message ? await this.createCustomDailyTip(message) : undefined;
		await scheduleForAutoNotify({
			dueDate,
			employeeEmailIds: workOrderEmployeeIdsForEmail,
			employeeSmsIds: workOrderEmployeeIdsForSms,
			tempEmployeeEmailIds: workOrderTemporaryEmployeeIdsForEmail,
			tempEmployeeSmsIds: workOrderTemporaryEmployeeIdsForSms,
			notificationType: TemplateNotificationEnum.PUBLISHED_WORK_ORDER,
			dailyTipId: customTipId,
		});
	};

	createCustomDailyTip = async (message: string) => {
		const { create, dueDate } = this.props;
		const customTip = await create(message, dueDate, true) as DailyTipViewModel;
		return customTip.id;
	};

	render() {
		const {
			dueDate,
			companyName,
			orgAlias,
			isSelectMultipleEnabled,
			isAllSelected,
			isNoneSelected,
			laborStatistics,
			hasPermissionsToEditScheduleBoard,
			notificationsDisabled,
			autoNotifyAt,
			autoNotifyOnPublish,
			isLoading,
			isAllowedToViewProdData,
			isAllowedToEditProdData,
			employees,
			temporaryEmployees,
			workOrderResourceLookup,
			dailyTip: { message: dailyTipMessage = '' },
			initialCopyModalData,
			notifyTemporaryLabor,
		} = this.props;
		const {
			copyDayModalVisible,
			buttonDisplayLevel,
			notifyAllModalVisible,
			notificationStatusByEmployee,
			notificationStatusByTemporaryEmployee,
		} = this.state;

		const { totalLaborStatistics, laborStatisticsPerLocation } = laborStatistics ?? {};

		const isCopyEnabled = !isSelectMultipleEnabled || (isSelectMultipleEnabled && !isNoneSelected);
		const isNotifyEnabled = !isSelectMultipleEnabled || (isSelectMultipleEnabled && !isNoneSelected);

		return (
			<div className="schedule-board-week-day-wrapper">
				<div className="schedule-board-week-day">
					<div className="left-actions">
						<Link
							to={{
								pathname: CLIENT.COMPANY.SCHEDULE_BOARD.DAILY_VIEW(orgAlias, companyName),
								state: { selectedDate: dueDate },
							}}
						>
							<div className="schedule-board-day-name">{TimeUtils.getDayName(dueDate)}</div>
							<div className="schedule-board-date">{dueDate}</div>
						</Link>
						<Button onClick={this.showToolbarForDay} variant="info">
							Day Toolbar
						</Button>
					</div>
					{
						hasPermissionsToEditScheduleBoard &&
						<div className="right-actions">
							{
								isSelectMultipleEnabled &&
								<Checkbox
									extraClass="schedule-board-toolbar-checkbox"
									handleChange={this.onCheck}
									isChecked={!!isAllSelected}
								/>
							}
							<div>
								<BigButton
									className="m-0"
									displayLevel={buttonDisplayLevel}
									iconClass="icon-checkbox_multiple"
									isActive={isSelectMultipleEnabled}
									label="Select WOs"
									onClick={this.selectMultiple}
								/>
							</div>
							<div className="connected-actions">
								{!notificationsDisabled &&
									<SmallButton
										displayLevel={buttonDisplayLevel}
										iconClass="icon-notifications_active"
										isDisabled={!isNotifyEnabled}
										label="Notify"
										onClick={this.openNotifyAllModal}
									/>
								}
								<SmallButton
									displayLevel={buttonDisplayLevel}
									iconClass="icon-copy"
									isDisabled={!isCopyEnabled}
									isDouble={notificationsDisabled}
									label="Copy"
									onClick={this.openCopyDayModal}
								/>
							</div>
							{isAllowedToEditProdData &&
								<div className="m-t-s">
									<BigButton
										displayLevel={buttonDisplayLevel}
										iconClass="icon-draft"
										label="Prod Data"
										onClick={this.goToCalculations}
									/>
								</div>
							}
						</div>
					}
				</div>
				{isAllowedToViewProdData &&
					<>
						<div className="schedule-board-week-day-actions">
							<LaborStatisticsPerLocation isWeekly={true} laborStatisticsPerLocation={laborStatisticsPerLocation} />
						</div>
						<TotalLaborStatistics totalLaborStatistics={totalLaborStatistics} />
					</>
				}
				<WorkOrderCopyModal
					closeCopyModal={this.closeCopyDayModal}
					initialFormData={initialCopyModalData}
					isCopyMultipleModal={true}
					onSubmit={this.copyOrdersOnDay}
					showCopyModal={copyDayModalVisible}
				/>
				<NotifyAllParticipantsModal
					autoNotifyAt={autoNotifyAt ?? null}
					autoNotifyOnPublish={autoNotifyOnPublish}
					canceledOrders={notifyAllModalVisible ? this.getCanceledWorkOrderList() : []}
					closeModal={this.closeNotifyAllModal}
					dailyTip={dailyTipMessage}
					dueDate={dueDate}
					employeeMap={employees}
					isLoading={isLoading}
					notificationMessageLength={notifyAllModalVisible ? this.getLongestNotificationMessage() : 0}
					notificationStatusByEmployee={notificationStatusByEmployee}
					notificationStatusByTemporaryEmployee={notificationStatusByTemporaryEmployee}
					notifyParticipants={this.notifyParticipants}
					notifyTemporaryLabor={notifyTemporaryLabor}
					orders={notifyAllModalVisible ? this.getPublishedWorkOrderList() : []}
					scheduleAutoNotify={this.scheduleAutoNotify}
					showModal={notifyAllModalVisible}
					temporaryEmployeeMap={temporaryEmployees}
					unpublishedOrders={notifyAllModalVisible ? this.getUnpublishedWorkOrderList() : []}
					workOrderResourceLookup={workOrderResourceLookup}
				/>
			</div>
		);
	}
}

function mapStateToProps(state: RootState, ownProps: OwnProps) {
	const { dueDate } = ownProps;
	const {
		scheduleBoard: { weeklyViewSelectMultiple, workOrdersByDateDictionary },
		company: { company },
		user: { userData, companyData },
	} = state;
	if (!userData || !companyData) {
		throw new Error('User not logged in');
	}

	const workOrdersOnDay = workOrdersByDateDictionary[dueDate]?.workOrders;
	const workOrderResourceLookup = workOrdersByDateDictionary[dueDate]?.workOrderResourceLookups ?? {};
	const canceledWorkOrderResourceLookup = workOrdersByDateDictionary[dueDate]?.canceledWorkOrderResourceLookups ?? {};

	const isAllowedToViewProdData = isAllowed(
		PagePermissions.COMPANY.PROD_DATA.VIEW,
		companyData.permissions,
		companyData.isCompanyAdmin,
		userData.role
	);

	const isAllowedToEditProdData = isAllowed(
		PagePermissions.COMPANY.PROD_DATA.EDIT,
		companyData.permissions,
		companyData.isCompanyAdmin,
		userData.role
	);

	let isAllSelected = true;
	let isNoneSelected = true;
	let isSelectMultipleEnabled = false;
	const selectedWorkOrders: ScheduleBoardWorkOrderViewModel[] = [];

	if (weeklyViewSelectMultiple[dueDate]) {
		isSelectMultipleEnabled = true;
		if (workOrdersOnDay) {
			Object.values(workOrdersOnDay).forEach((_wo) => {
				if (isNoneSelected && weeklyViewSelectMultiple[dueDate][_wo.id]) {
					isNoneSelected = false;
				} else if (isAllSelected && !weeklyViewSelectMultiple[dueDate][_wo.id]) {
					isAllSelected = false;
				}
				if (weeklyViewSelectMultiple[dueDate][_wo.id]) {
					selectedWorkOrders.push(_wo);
				}
			});
		} else {
			isAllSelected = false;
			isNoneSelected = false;
		}
	} else {
		if (workOrdersOnDay) {
			selectedWorkOrders.push(...Object.values(workOrdersOnDay));
		}
	}
	const workOrdersOnDate = workOrdersByDateDictionary[dueDate];
	let laborStatistics: Nullable<ScheduleBoardLaborStatistics> = {
		laborStatisticsPerLocation: undefined,
		totalLaborStatistics: undefined,
	};
	if (workOrdersOnDate?.laborStatistics) {
		laborStatistics = ScheduleBoardUtil.ignoreUndefinedLocation(workOrdersOnDate.laborStatistics);
	}

	const selectedDayOfWeek = TimeUtils.parseMoment(dueDate)?.day();
	const dayBefore = selectedDayOfWeek ? selectedDayOfWeek - 1 : 6;

	const assignedPublishedNotificationStatuses = workOrdersOnDate?.assignedPublishedNotificationStatuses ?? null;
	const assignedCanceledNotificationStatuses = workOrdersOnDate?.assignedCanceledNotificationStatuses ?? null;
	const assignedPublishedTempLaborNotificationStatuses = workOrdersOnDate?.assignedPublishedTempLaborNotificationStatuses ?? null;
	const assignedCanceledTempLaborNotificationStatuses = workOrdersOnDate?.assignedCanceledTempLaborNotificationStatuses ?? null;

	const notificationSettings = company?.notification;
	const board = state.scheduleBoard;
	const workOrders = state.workOrder?.workOrders;

	return {
		isAllSelected,
		isNoneSelected,
		isSelectMultipleEnabled,
		isAllowedToViewProdData,
		isAllowedToEditProdData,
		selectedWorkOrders,
		workDays: company?.workDays,
		workOrderList: workOrders?.list ?? [],
		laborStatistics,
		zoomLevel: board?.zoomLevel,
		scheduleBoardView: board?.scheduleBoardView,
		workOrders: workOrdersOnDay,
		participantsEmailAndSmsStatuses: workOrders?.participantsEmailAndSmsStatuses ?? {} as EmailAndSmsStatusViewModelMap,
		isLoading: state.http.isFetching,
		notificationsDisabled: !notificationSettings?.isEnabled,
		autoNotifyOnPublish: notificationSettings?.notifyOnPublish ?? null,
		autoNotifyAt: notificationSettings?.notifyOnDay?.[dayBefore],
		workOrderResourceLookup: { ...workOrderResourceLookup, ...canceledWorkOrderResourceLookup },
		employees: board?.employees,
		temporaryEmployees: board?.temporaryEmployees,
		assignedPublishedNotificationStatuses,
		assignedCanceledNotificationStatuses,
		assignedPublishedTempLaborNotificationStatuses,
		assignedCanceledTempLaborNotificationStatuses,
		dailyTip: board?.workOrdersByDateDictionary?.[dueDate]?.dailyTip,
		initialCopyModalData: state.workOrder.copyWorkOrderModalData,
		notifyTemporaryLabor: company?.notification.notifyTemporaryLabor ?? false,
	};
}

function mapDispatchToProps() {
	return {
		activateMultiSelectMode: ScheduleBoardActions.activateMultiSelectMode,
		exportScheduleBoard: ScheduleBoardActions.exportScheduleBoard,
		clearDailyView: ScheduleBoardActions.clearDailyView,
		clearWeeklyView: ScheduleBoardActions.clearWeeklyView,
		toggleDayWeeklyView: ScheduleBoardActions.toggleDayWeeklyView,
		toggleWeeklyViewSelectMultiple: ScheduleBoardActions.toggleWeeklyViewSelectMultiple,
		setWeeklyViewDateWithToolbar: ScheduleBoardActions.setWeeklyViewDateWithToolbar,
		reinitializeCopyForm: WorkOrderActions.reinitializeCopyForm,
		findAllWorkOrdersWithNotificationStatusesByDueDate: WorkOrderActions.findAllWorkOrdersWithNotificationStatusesByDueDate,
		copyWorkOrder: WorkOrderActions.copyWorkOrder,
		create: DailyTipActions.create,
		scheduleForAutoNotify: NotifyActions.scheduleForAutoNotify,
		sendTemplateNotification: NotifyActions.sendTemplateNotificationMultipleWO,
	};
}

const connector = connect(mapStateToProps, mapDispatchToProps());

export default connector(ToolbarInfo);
