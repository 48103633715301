import * as React from 'react';

import type { CompanyViewModel } from 'ab-viewModels/company.viewModel';

import WorkOrderSettingsEnum, { WorkOrderSettingsHint } from 'ab-enums/workOrderSettings.enum';

import * as TimeOptionUtils from 'ab-utils/timeOption.util';

import PreviewItem from '../../Shared/PreviewItem';
import SettingPreview from '../../Shared/SettingPreview';

interface OwnProps {
	toggleEdit: (event: React.MouseEvent) => void;
	company: CompanyViewModel;
}

type Props = OwnProps;

class WorkOrderSettingsPreview extends React.PureComponent<Props> {

	static label = (start: number, end: number) => {
		return `${TimeOptionUtils.toValueString(start)} - ${TimeOptionUtils.toValueString(end)}`;
	};

	render() {
		const {
			toggleEdit,
			company: {
				fieldReportAccessDayDuration,
				isFRManageAllowedToApproveTimeSheet,
				isFRManageAllowedToEditTimeSheet,
				isFRManageLevelAllowedInReviewProcess,
				allowAllNonBillableShortcut,
			},
		} = this.props;

		return (
			<SettingPreview toggleEdit={toggleEdit}>
				<div className="company-settings__info-segment">
					<PreviewItem
						checked={true}
						hint={WorkOrderSettingsHint.fieldReportAccessDayDuration}
						indent={false}
						label={WorkOrderSettingsEnum.fieldReportAccessDayDuration}
						showCheckbox={false}
						value={`${fieldReportAccessDayDuration} day${fieldReportAccessDayDuration > 1 ? 's' : ''} after Work Order\'s date of work`}
					/>
					<PreviewItem
						checked={isFRManageAllowedToApproveTimeSheet}
						hint={WorkOrderSettingsHint.isFRManageAllowedToApproveTimeSheet}
						indent={false}
						label={WorkOrderSettingsEnum.isFRManageAllowedToApproveTimeSheet}
						showCheckbox={true}
					/>
					<PreviewItem
						checked={isFRManageAllowedToEditTimeSheet}
						hint={WorkOrderSettingsHint.isFRManageAllowedToEditTimeSheet}
						indent={false}
						label={WorkOrderSettingsEnum.isFRManageAllowedToEditTimeSheet}
						showCheckbox={true}
					/>
					<PreviewItem
						checked={isFRManageLevelAllowedInReviewProcess}
						hint={WorkOrderSettingsHint.isFRManageLevelAllowedInReviewProcess}
						indent={false}
						label={WorkOrderSettingsEnum.isFRManageLevelAllowedInReviewProcess}
						showCheckbox={true}
					/>
					<PreviewItem
						checked={allowAllNonBillableShortcut}
						hint={WorkOrderSettingsHint.allowAllNonBillableShortcut}
						indent={false}
						label={WorkOrderSettingsEnum.allowAllNonBillableShortcut}
						showCheckbox={true}
					/>
				</div>
			</SettingPreview>
		);
	}
}

export default WorkOrderSettingsPreview;
