import TimeFormat from 'acceligent-shared/enums/timeFormat';

import * as TimeUtils from 'acceligent-shared/utils/time';

import type DailyPerDiemTipBase from 'ab-domain/models/dailyPerDiemTip/base';

export class W_DailyPerDiemTip_Create_VM {
	id: number;
	companyId: number;
	message: string;
	date: string;

	constructor(dailyPerDiemTip: DailyPerDiemTipBase) {
		this.id = dailyPerDiemTip.id;
		this.companyId = dailyPerDiemTip.companyId;
		this.message = dailyPerDiemTip.message;
		this.date = TimeUtils.formatDate(dailyPerDiemTip.date, TimeFormat.DATE_ONLY, TimeFormat.DB_DATE_ONLY);
	}
}
