import * as React from 'react';

import type ScheduleBoardFilterGroup from 'acceligent-shared/enums/scheduleBoardFilterGroup';

import type { FilterVM } from 'ab-viewModels/scheduleBoard/filter';

import SegmentLabel from 'af-components/SegmentLabel';

import FilterItem from './FilterItem';

interface Props {
	label: string;
	options: FilterVM[];
	filterGroup: ScheduleBoardFilterGroup;
	onCheck: (value: FilterVM) => void;
	onCheckAll: (checked: boolean, filterGroup: ScheduleBoardFilterGroup) => void;
}

interface State {
	isExpanded: boolean;
}

export default class FilterGroup extends React.Component<Props, State> {

	state: State = {
		isExpanded: false,
	};

	renderFilterItem = (props: FilterVM) => {
		const { onCheck } = this.props;

		return (
			<FilterItem
				key={`${props.filterGroup}-${props.id}`}
				{...props}
				onCheck={onCheck}
			/>
		);
	};

	showAll = () => {
		const { onCheckAll, filterGroup } = this.props;
		onCheckAll(true, filterGroup);
	};

	hideAll = () => {
		const { onCheckAll, filterGroup } = this.props;
		onCheckAll(false, filterGroup);
	};

	toggleExpand = () => {
		this.setState(({ isExpanded }) => ({ isExpanded: !isExpanded }));
	};

	render() {
		const { label, options } = this.props;
		const { isExpanded } = this.state;

		let optionsClass = 'filter-modal__options';
		optionsClass = !isExpanded ? `${optionsClass} filter-modal__options--hidden` : optionsClass;

		return (
			<div className="filter-modal__group">
				<div className="filter-modal__group-header">
					<span className="filter-modal__group-header-label" onClick={this.toggleExpand}>
						<SegmentLabel label={label} />
						{isExpanded
							? <span className="btn--control icon-collapse" />
							: <span className="btn--control icon-expand" />
						}
					</span>
					<div className="filter-modal__group-header-buttons">
						{isExpanded &&
							<>
								<span className="btn--control" onClick={this.showAll}>Show All</span>
								<span className="btn--control" onClick={this.hideAll}>Hide All</span>
							</>
						}
					</div>
				</div>
				<div className={optionsClass}>
					{options.length ? options.map(this.renderFilterItem) : 'Not available.'}
				</div>
			</div>
		);
	}
}
