import * as React from 'react';

import type WorkRequestPreviewDetailsVM from 'ab-viewModels/workRequest/workRequestPreviewDetails.viewModel';

import LockedValue from 'af-components/LockedValue';

import { previewSectionClass, previewSectionClassColumn1, previewSectionClassColumn2, previewSectionClassRow, previewSectionTitleClass } from '../../../helpers';
import TrucksAndEquipmentBooleanValue from './TrucksAndEquipmentBooleanValue';

type Props = {
	workRequest: WorkRequestPreviewDetailsVM;
};

const TrucksAndEquipmentSection: React.FC<Props> = ({ workRequest }) => {
	return (
		<div className={previewSectionClass}>
			<div className={previewSectionTitleClass}>
				Trucks / Equipment
			</div>
			<div className={previewSectionClassRow}>
				<div className={previewSectionClassColumn1}>
					<TrucksAndEquipmentBooleanValue booleanValue={workRequest.jc80gpm} label="JC (80 gpm)" />
				</div>
				<div className={previewSectionClassColumn1}>
					<TrucksAndEquipmentBooleanValue booleanValue={workRequest.hjJetter} label="HJ Jetter" />
				</div>
				<div className={previewSectionClassColumn1}>
					<TrucksAndEquipmentBooleanValue booleanValue={workRequest.tvLateralReinstator} label="TV Lateral Reinstator" />
				</div>
				<div className={previewSectionClassColumn1}>
					<TrucksAndEquipmentBooleanValue booleanValue={workRequest.wb} label="WB" />
				</div>
				<div className={previewSectionClassColumn1}>
					<TrucksAndEquipmentBooleanValue booleanValue={workRequest.smallEasementMachine} label="Small Easement Machine" />
				</div>
				<div className={previewSectionClassColumn1}>
					<TrucksAndEquipmentBooleanValue booleanValue={workRequest.powerRodder} label="Power Rodder" />
				</div>

			</div>
			<div className={previewSectionClassRow}>
				<div className={previewSectionClassColumn1}>
					<TrucksAndEquipmentBooleanValue booleanValue={workRequest.sjc80gpm} label="SJC (80 gpm)" />
				</div>
				<div className={previewSectionClassColumn1}>
					<TrucksAndEquipmentBooleanValue booleanValue={workRequest.tvTruck} label="TV Truck" />
				</div>
				<div className={previewSectionClassColumn1}>
					<TrucksAndEquipmentBooleanValue booleanValue={workRequest.tvMTHTk} label="TV MTH Tk" />
				</div>
				<div className={previewSectionClassColumn1}>
					<TrucksAndEquipmentBooleanValue booleanValue={workRequest.hwBoiler} label="HW Boiler" />
				</div>
				<div className={previewSectionClassColumn1}>
					<TrucksAndEquipmentBooleanValue booleanValue={workRequest.bigEasementMachine} label="Big Easement Machine" />
				</div>
				<div className={previewSectionClassColumn1}>
					<TrucksAndEquipmentBooleanValue booleanValue={workRequest.reeferTruck} label="Reefer Truck" />
				</div>

			</div>
			<div className={previewSectionClassRow}>
				<div className={previewSectionClassColumn1}>
					<TrucksAndEquipmentBooleanValue booleanValue={workRequest.jcRecycler80} label="JC Recycler (80 gpm)" />
				</div>
				<div className={previewSectionClassColumn1}>
					<TrucksAndEquipmentBooleanValue booleanValue={workRequest.tvLateralTk} label="TV Lateral Tk" />
				</div>
				<div className={previewSectionClassColumn1}>
					<TrucksAndEquipmentBooleanValue booleanValue={workRequest.tvLaserTk} label="TV Laser Tk" />
				</div>
				<div className={previewSectionClassColumn1}>
					<TrucksAndEquipmentBooleanValue booleanValue={workRequest.pressureWash} label="Pressure Wash" />
				</div>
				<div className={previewSectionClassColumn1}>
					<TrucksAndEquipmentBooleanValue booleanValue={workRequest.arrowBoard} label="Arrow Board" />
				</div>
				<div className={previewSectionClassColumn1}>
					<TrucksAndEquipmentBooleanValue booleanValue={workRequest.lateralReinstatorTrailer} label="Lateral Reinstator Trailer" />
				</div>

			</div>
			<div className={previewSectionClassRow}>
				<div className={previewSectionClassColumn1}>
					<TrucksAndEquipmentBooleanValue booleanValue={workRequest.jjc120gpm} label="JJC (120 gpm)" />
				</div>
				<div className={previewSectionClassColumn1}>
					<TrucksAndEquipmentBooleanValue booleanValue={workRequest.tvGroutTk} label="TV Grout Tk" />
				</div>
				<div className={previewSectionClassColumn1}>
					<TrucksAndEquipmentBooleanValue booleanValue={workRequest.minicam} label="Minicam" />
				</div>
				<div className={previewSectionClassColumn1}>
					<TrucksAndEquipmentBooleanValue booleanValue={workRequest.airComp} label="Air Compressor" />
				</div>
				<div className={previewSectionClassColumn1}>
					<TrucksAndEquipmentBooleanValue booleanValue={workRequest.supportTk} label="Support Tk" />
				</div>
				<div className={previewSectionClassColumn1}>
					<TrucksAndEquipmentBooleanValue booleanValue={workRequest.steamCureTruck} label="Steam Cure Truck" />
				</div>

			</div>
			<div className={previewSectionClassRow}>
				<div className={previewSectionClassColumn1}>
					<TrucksAndEquipmentBooleanValue booleanValue={workRequest.jjc160gpm} label="JJC (160 gpm)" />
				</div>
				<div className={previewSectionClassColumn1}>
					<TrucksAndEquipmentBooleanValue booleanValue={workRequest.tvLatGroutTk} label="TV Lat Grout Tk" />
				</div>
				<div className={previewSectionClassColumn1}>
					<TrucksAndEquipmentBooleanValue booleanValue={workRequest.vacTk} label="Vac Tk" />
				</div>
				<div className={previewSectionClassColumn1}>
					<TrucksAndEquipmentBooleanValue booleanValue={workRequest.clamTk} label="Clam Tk" />
				</div>
				<div className={previewSectionClassColumn1}>
					<TrucksAndEquipmentBooleanValue booleanValue={workRequest.rollOffTk} label="Roll Off Tk" />
				</div>
				<div className={previewSectionClassColumn1}>
					<TrucksAndEquipmentBooleanValue booleanValue={workRequest.airTesting} label="Air Testing" />
				</div>
			</div>
			<div className={previewSectionClassRow}>
				<div className={previewSectionClassColumn1}>
					<TrucksAndEquipmentBooleanValue booleanValue={workRequest.jcHiRail} label="JC HiRail" />
				</div>
				<div className={previewSectionClassColumn1}>
					<TrucksAndEquipmentBooleanValue booleanValue={workRequest.tvSonarTk} label="TV Sonar Tk" />
				</div>
				<div className={previewSectionClassColumn1}>
					<TrucksAndEquipmentBooleanValue booleanValue={workRequest.hivacTk} label="HiVac Tk" />
				</div>
				<div className={previewSectionClassColumn1}>
					<TrucksAndEquipmentBooleanValue booleanValue={workRequest.bucketMach} label="Bucket Mach" />
				</div>
				<div className={previewSectionClassColumn1}>
					<TrucksAndEquipmentBooleanValue booleanValue={workRequest.pump} label="Pump" />
				</div>
				<div className={previewSectionClassColumn1}>
					<TrucksAndEquipmentBooleanValue booleanValue={workRequest.trackMachine} label="Track Machine" />
				</div>

			</div>
			<div className={previewSectionClassRow}>
				<div className={previewSectionClassColumn1}>
					<TrucksAndEquipmentBooleanValue booleanValue={workRequest.jetter} label="Jetter" />
				</div>
				<div className={previewSectionClassColumn1}>
					<TrucksAndEquipmentBooleanValue booleanValue={workRequest.tvPolaris} label="TV Polaris" />
				</div>
				<div className={previewSectionClassColumn1}>
					<TrucksAndEquipmentBooleanValue booleanValue={workRequest.hiRailvacTk} label="HiRail Vac Tk" />
				</div>
				<div className={previewSectionClassColumn1}>
					<TrucksAndEquipmentBooleanValue booleanValue={workRequest.mhRehabTk} label="MH Rehab Tk" />
				</div>
				<div className={previewSectionClassColumn1}>
					<TrucksAndEquipmentBooleanValue booleanValue={workRequest.pigLaunch} label="Pig Launch" />
				</div>
				<div className={previewSectionClassColumn1}>
					{workRequest.additionalTruckEquipmentField1Name &&
						<TrucksAndEquipmentBooleanValue
							booleanValue={workRequest.additionalTruckEquipmentField1Checked}
							label={workRequest.additionalTruckEquipmentField1Name}
						/>
					}
				</div>
			</div>
			<div className={previewSectionClassRow}>
				<div className={previewSectionClassColumn1}>
					<TrucksAndEquipmentBooleanValue booleanValue={workRequest.harbenJetter} label="Harben Jetter" />
				</div>
				<div className={previewSectionClassColumn1}>
					<TrucksAndEquipmentBooleanValue booleanValue={workRequest.tvLCRTk} label="TV LCR Tk" />
				</div>
				<div className={previewSectionClassColumn1}>
					<TrucksAndEquipmentBooleanValue booleanValue={workRequest.tanker} label="Tanker" />
				</div>
				<div className={previewSectionClassColumn1}>
					<TrucksAndEquipmentBooleanValue booleanValue={workRequest.microtrax} label="Microtrax" />
				</div>
				<div className={previewSectionClassColumn1}>
					<TrucksAndEquipmentBooleanValue booleanValue={workRequest.stoveMach} label="Stove Mach" />
				</div>
				<div className={previewSectionClassColumn1}>
					{workRequest.additionalTruckEquipmentField2Name &&
						<TrucksAndEquipmentBooleanValue
							booleanValue={workRequest.additionalTruckEquipmentField2Checked}
							label={workRequest.additionalTruckEquipmentField2Name}
						/>
					}
				</div>

			</div>
			<div className={previewSectionClassRow}>
				<div className={previewSectionClassColumn2}>
					<LockedValue label="Number Of People needed on Job" value={workRequest.numberOfEmployees} />
				</div>
			</div>
		</div>
	);
};

export default React.memo(TrucksAndEquipmentSection);
