import * as React from 'react';
import { compose } from 'redux';
import type { ConnectedProps } from 'react-redux';
import { connect } from 'react-redux';
import type { InjectedFormProps } from 'redux-form';
import { reduxForm } from 'redux-form';
import type { CustomRouteComponentProps } from 'react-router-dom';

import type { RootState } from 'af-reducers';

import type JobStatusRequestModel from 'ab-requestModels/jobStatus.requestModel';

import { JOB_STATUS_CREATE } from 'af-constants/reduxForms';
import CLIENT from 'af-constants/routes/client';

import * as JobStatusActions from 'af-actions/jobStatus';

import JobStatusForm from 'af-components/SharedForms/Resources/JobStatus';
import { validate } from 'af-components/SharedForms/Resources/JobStatus/validation';
import Breadcrumbs from 'af-components/Breadcrumbs';

type OwnProps = CustomRouteComponentProps<{ id?: string; }>;

type FormOwnProps = OwnProps & ConnectedProps<typeof connector>;
type Props = FormOwnProps & InjectedFormProps<JobStatusRequestModel, FormOwnProps>;

const Create: React.FC<Props> = (props) => {

	const {
		location: { state: { orgAlias } },
		history,
		companyName,
		createJobStatus,
	} = props;

	const handleCreateJobStatus = React.useCallback(async (form: JobStatusRequestModel) => {
		const isJobStatusCreated = await createJobStatus(form);

		if (isJobStatusCreated) {
			history.push(CLIENT.COMPANY.RESOURCES.JOB_STATUS.LIST(orgAlias, companyName));
		}
	}, [history, orgAlias, companyName, createJobStatus]);

	const items = React.useMemo(() => ([
		{ label: 'Job Statuses', url: CLIENT.COMPANY.RESOURCES.JOB_STATUS.LIST(orgAlias, companyName) },
		{ label: 'New Status' },
	]), [orgAlias, companyName]);

	return (
		<div className="form-segment">
			<Breadcrumbs
				items={items}
			/>
			<JobStatusForm
				{...props}
				onSubmit={handleCreateJobStatus}
			/>
		</div>
	);
};

const mapStateToProps = (state: RootState) => {
	const { companyData } = state.user;
	if (!companyData) {
		throw new Error('User not logged in');
	}

	return {
		companyName: companyData.name,
	};
};

const mapDispatchToProps = () => ({
	createJobStatus: JobStatusActions.create,
});

const connector = connect(mapStateToProps, mapDispatchToProps());

const enhance = compose<React.ComponentClass<OwnProps>>(
	connector,
	reduxForm<JobStatusRequestModel, FormOwnProps>({
		form: JOB_STATUS_CREATE,
		validate,
		enableReinitialize: true,
	}),
	React.memo
);

export default enhance(Create);
