import * as React from 'react';

import type { AddressModel } from 'ab-viewModels/scheduleBoardWorkOrderModal.viewModel';

interface OwnProps {
	address: AddressModel;
	isRemoved?: boolean;
}

type Props = OwnProps;

class Address extends React.PureComponent<Props> {
	static defaultProps: Partial<Props> = {
		isRemoved: false,
	};

	render() {
		const { address, isRemoved } = this.props;

		let className = 'work-order-info-modal__work-location';
		className = isRemoved ? `${className} work-order-info-modal__work-location--removed` : className;

		return (
			<div className={className}>
				<div>{address.streetAddress}</div>
				<div>{address.suite}</div>
				<div>{address.locality}</div>
				<div>{address.aa1}</div>
				<div>{address.postalCode}</div>
				<div>{address.postalOfficeBoxCode}</div>
				<div>{address.country}</div>
			</div>
		);
	}
}

export default Address;
