import * as React from 'react';
import { compose } from 'redux';
import type { CustomRouteComponentProps } from 'react-router-dom';
import type { ConnectedProps } from 'react-redux';
import { connect } from 'react-redux';

import type { RootState } from 'af-reducers';

import * as JobStatusActions from 'af-actions/jobStatus';

import type { JobStatusTableRowVM } from 'ab-viewModels/jobStatus/tableRow.viewModel';

import CLIENT from 'af-constants/routes/client';

import TableNameEnum from 'ab-enums/tableName.enum';
import TableButtonType from 'ab-enums/tableButtonType.enum';

import type { TabProps, Column, ButtonData } from 'af-components/Table6';
import Table from 'af-components/Table6';
import Breadcrumbs from 'af-components/Breadcrumbs';
import LabelWithColor from 'af-components/LabelWithColor';

type OwnProps = CustomRouteComponentProps;

type Props = OwnProps & ConnectedProps<typeof connector>;

const DELETE_CONFIRMATION_BODY = (
	<>
		If deleted, the job status(es) will no longer be assignable.
		<br />
		This action cannot be undone.
	</>
);

const deleteJobStatusModalTitle = (original: JobStatusTableRowVM) => `Are you sure you want to delete this Job Status (${original.name})?`;
const deleteJobStatusModalBody = () => DELETE_CONFIRMATION_BODY;
const deleteJobStatusModalText = () => 'Delete Job Status';

const BREADCRUMBS = [{ label: 'Job Status' }];

const COLUMNS: Column<JobStatusTableRowVM>[] = [
	{
		Header: 'Name',
		accessor: 'name',
		Cell: ({ original }) => <strong>{original.name}</strong>,
	},
	{
		Header: 'Description',
		accessor: 'description',
		Cell: ({ original }) => <LabelWithColor color={original.color} text={original.description} />,
	},
];

const JobStatusList: React.FC<Props> = (props) => {
	const {
		location: { state: { orgAlias } },
		history,
		companyName,
		findAllForCompanyTable,
		deleteJobStatusFromTable,
		bulkDeleteJobStatusFromTable,
	} = props;

	const previewJobStatus = React.useCallback(async (original: JobStatusTableRowVM) => {
		if (original.id) {
			history.push(CLIENT.COMPANY.RESOURCES.JOB_STATUS.PREVIEW(original.id.toString(), orgAlias, companyName));
		}
	}, [companyName, orgAlias, history]);

	const editJobStatus = React.useCallback(async (original: JobStatusTableRowVM) => {
		history.push(CLIENT.COMPANY.RESOURCES.JOB_STATUS.EDIT(original.id.toString(), orgAlias, companyName));
	}, [companyName, orgAlias, history]);

	const deleteJobStatus = React.useCallback(async (original: JobStatusTableRowVM) => {
		await deleteJobStatusFromTable(original.id);
	}, [deleteJobStatusFromTable]);

	const onRowClick = React.useCallback(({ original }: { original: JobStatusTableRowVM; }) => {
		if (original.id) {
			history.push(CLIENT.COMPANY.RESOURCES.JOB_STATUS.PREVIEW(original.id.toString(), orgAlias, companyName));
		}
	}, [companyName, orgAlias, history]);

	const tabs = React.useMemo((): TabProps<JobStatusTableRowVM>[] => {
		const buttons: ButtonData[] = [
			{
				type: TableButtonType.PRIMARY,
				hasPermission: true,
				label: 'New Job Status',
				icon: 'plus',
				onClick: async () => history.push(CLIENT.COMPANY.RESOURCES.JOB_STATUS.CREATE(orgAlias, companyName)),
			},
		];

		return [
			{
				label: 'Job Status',
				columns: COLUMNS,
				selectable: true,
				hasSearchInput: true,
				searchLabel: 'Job Status',
				buttons,
				fetch: findAllForCompanyTable,
				bulkDelete: bulkDeleteJobStatusFromTable,
				bulkDeleteConfirmationBody: DELETE_CONFIRMATION_BODY,
				onRowClick: onRowClick,
				rowActions: [
					{
						label: 'Preview',
						action: previewJobStatus,
						shouldRefresh: false,
					},
					{
						label: 'Edit',
						action: editJobStatus,
						shouldRefresh: false,
					},
					{
						label: 'Delete',
						action: deleteJobStatus,
						hasModal: true,
						modalTitle: deleteJobStatusModalTitle,
						modalBody: deleteJobStatusModalBody,
						modalText: deleteJobStatusModalText,
						shouldRefresh: true,
					},
				],
			},
		];
	}, [companyName, orgAlias, history, deleteJobStatus, editJobStatus, onRowClick, findAllForCompanyTable, previewJobStatus, bulkDeleteJobStatusFromTable]);

	return (
		<div className="form-segment form-segment--maxi">
			<Breadcrumbs items={BREADCRUMBS} />
			<Table
				tableName={TableNameEnum.JOB_STATUS}
				tabs={tabs}
			/>
		</div>
	);
};

const mapStateToProps = (state: RootState) => {
	const { companyData } = state.user;
	if (!companyData) {
		throw new Error('User not logged in');
	}

	return {
		companyName: companyData.name,
	};
};

const mapDispatchToProps = () => ({
	findAllForCompanyTable: JobStatusActions.findAllForCompanyTable,
	deleteJobStatusFromTable: JobStatusActions.deleteJobStatusFromTable,
	bulkDeleteJobStatusFromTable: JobStatusActions.bulkDeleteJobStatusFromTable,
});

const connector = connect(mapStateToProps, mapDispatchToProps());

const enhance = compose<React.ComponentClass<OwnProps>>(
	connector,
	React.memo
);

export default enhance(JobStatusList);
