import * as React from 'react';
import { Dropdown as BootstrapDropdown, FormGroup, FormControl, Button } from 'react-bootstrap';
import type { WrappedFieldProps } from 'redux-form';

import TimeFormat from 'acceligent-shared/enums/timeFormat';

import { isNullOrUndefined } from 'acceligent-shared/utils/extensions';
import * as TimeUtils from 'acceligent-shared/utils/time';

import { bemBlock, bemElement } from 'ab-utils/bem.util';

import Label from 'af-components/LockedValue/Label';
import Radio from 'af-components/Controls/Radio';

interface DropdownStyle {
	top: number | 'auto';
	bottom: number | 'auto';
	left: number;
	width: number | 'auto';
}

interface OwnProps {
	alwaysShowErrors?: boolean;
	className?: string;
	containerClassName?: string;
	containerId?: string;
	/** Disable displaying error message that is shown next to time picker */
	disableErrorMessage?: boolean;
	disabled?: boolean;
	dropdownClassName?: string;
	toggleClassName?: string;
	dropdownId?: string;
	fixed?: boolean;
	fullWidth?: boolean;
	id?: string;
	inputTimeFormat?: TimeFormat;
	label?: string;
	tooltipMessage?: string;
	withCaret?: boolean;
	/** value: ISO_DATETIME */
	onValueChange: (value: Nullable<string>) => void;
	/** If using redux form change function outside to explicitly set the date, disable input's default behaviour */
	handleChangeOutside?: boolean;
	/** It is used when validation of field doesn't come from form errors,
	 * but from some other place so we have to set error classname manually - necessary for timeSheetsForm */
	forceErrorClassname?: boolean;
	timeZoneInUse?: Nullable<string>;
}

interface State {
	dropup: boolean;
	dropdownStyle?: DropdownStyle;
	isOpen: boolean;
	hours: number;
	minutes: number;
}

type Props = OwnProps & WrappedFieldProps;

class TimePicker extends React.PureComponent<Props, State> {
	state: State = {
		dropup: false,
		dropdownStyle: undefined,
		isOpen: false,
		hours: 0,
		minutes: 0,
	};

	MAX_DROPDOWN_MENU_HEIGHT = 300 * 1.1; // 10% more

	_dropdownInput = React.createRef<HTMLDivElement>();
	_dropdownToggle = React.createRef<HTMLDivElement>();
	_hourInput = React.createRef<HTMLInputElement>();
	_minuteInput = React.createRef<HTMLInputElement>();

	private static parseDate = (value: string, format: TimeFormat | undefined) => TimeUtils.toDate(value ? value : new Date(), format);

	private static getTime = (value: string, format: TimeFormat | undefined) => {
		const date = TimePicker.parseDate(value, format);
		const hours = date?.getHours() ?? 0;
		const minutes = date?.getMinutes() ?? 0;

		return { hours, minutes };
	};

	componentDidMount() {
		const { input: { value }, inputTimeFormat } = this.props;

		const { hours, minutes } = TimePicker.getTime(value, inputTimeFormat);

		this.setState(() => ({ hours, minutes }));
	}

	componentDidUpdate(prevProps: Props) {
		const { input: { value }, inputTimeFormat } = this.props;

		if (value !== prevProps.input.value && !!value) {
			const { hours, minutes } = TimePicker.getTime(value, inputTimeFormat);
			this.setState(() => ({ hours, minutes }));
		} else if (value !== prevProps.input.value && !value) {
			this.setState(() => ({ hours: 0, minutes: 0 }));
		}
	}

	handleBlur = () => {
		const { input } = this.props;
		// eslint-disable-next-line @typescript-eslint/no-explicit-any
		(input as any).onBlur(input.value);
	};

	handleFocus = () => {
		const { input } = this.props;

		// eslint-disable-next-line @typescript-eslint/no-explicit-any
		(input as any).onFocus();
	};

	handleTimeInputFocus = (evt: React.FocusEvent<HTMLInputElement>) => {
		evt.currentTarget.select();
	};

	onToggle = (isOpen: boolean, e?, meta?) => {
		const { fixed } = this.props;

		if (isOpen) {
			this.handleFocus();
		}

		if (meta?.source === 'select') {
			return;
		}

		const nextState: Partial<State> = {};

		if (isOpen && this._dropdownToggle && this._dropdownToggle.current) {
			const { height: bodyHeight } = document.body.getClientRects()[0];
			const { y: dropdownTogglePositionY = 0 } = this._dropdownToggle.current.getClientRects()[0] ?? {};

			const isDropup = dropdownTogglePositionY > (bodyHeight - this.MAX_DROPDOWN_MENU_HEIGHT);

			if (fixed) {
				const {
					x: inputPositionX = 0,
					y: inputPositionY = 0,
					bottom: inputBottom = 0,
					height: inputHeight = 0,
				} = this._dropdownInput.current?.getClientRects()?.[0] ?? {};

				nextState.dropdownStyle = {
					top: isDropup ? 'auto' : inputBottom,
					bottom: isDropup ? bodyHeight - inputPositionY - inputHeight : 'auto',
					left: inputPositionX,
					width: 'auto',
				};
			} else {
				nextState.dropdownStyle = undefined;
			}
			if (this.state.dropup !== isDropup) {
				nextState.dropup = isDropup;
			}
		}
		nextState.isOpen = isOpen;

		this.setState(() => nextState as State);
	};

	getClassName = () => {
		const { className, meta: { error, touched }, alwaysShowErrors, forceErrorClassname } = this.props;

		let cn = 'dropdown-field';
		cn = className ? `${cn} ${className}` : cn;
		cn = (touched || alwaysShowErrors) && error || forceErrorClassname ? `${cn} dropdown-field--error` : cn;
		return cn;
	};

	renderSelected = () => {
		const { input: { value }, inputTimeFormat } = this.props;

		if (!value) {
			return '00:00';
		}

		return TimeUtils.formatDate(value, TimeFormat.TIME_ONLY_12H, inputTimeFormat);
	};

	getInputFormatted = (value: number) => {
		if (value < 10) {
			return `0${value}`;
		}
		return value;
	};

	getHoursAMPM = () => {
		const { hours } = this.state;
		if (hours > 12) {
			return hours - 12;
		}
		return hours || 12;
	};

	handleHoursChange = ({ currentTarget: { value: hoursValue } }: React.ChangeEvent<HTMLInputElement>) => {
		let nextHours = parseInt(hoursValue);
		if (!nextHours || nextHours < 0 || nextHours > 12) {
			nextHours = 0;
		}

		this.setState(({ hours }) => {
			if (nextHours + 12 === 24) {
				nextHours = 0;
			}
			return { hours: hours > 12 ? (nextHours + 12) : nextHours };
		});
	};

	handleMinutesChange = ({ currentTarget: { value: minutesValue } }: React.ChangeEvent<HTMLInputElement>) => {
		const minutes = parseInt(minutesValue) % 60;
		this.setState(() => ({ minutes }));
	};

	hourIncrement = () => this.setState(({ hours }) => ({ hours: (hours + 1) % 24 }));

	hourDecrement = () => this.setState(({ hours }) => ({ hours: (hours + 23) % 24 }));

	minuteIncrement = () => this.setState((state) => ({ minutes: (state.minutes + 1) % 60 }));

	minuteDecrement = () => this.setState((state) => ({ minutes: (state.minutes + 59) % 60 }));

	setAM = async () => this.setState(({ hours }) => {
		if (hours >= 12) {
			return { hours: hours - 12 };
		}
		return null;
	});

	setPM = async () => this.setState(({ hours }) => {
		if (hours < 12) {
			return { hours: hours + 12 };
		}
		return null;
	});

	handleCurrentTime = () => {
		const { input: { onChange }, onValueChange, handleChangeOutside, timeZoneInUse } = this.props;

		const currentTime = new Date();

		let nextValueFormatted = TimeUtils.formatDate(currentTime, TimeFormat.ISO_DATETIME);
		const browserOffset = -currentTime.getTimezoneOffset();
		const timeZoneOffset = !!timeZoneInUse
			? TimeUtils.getOffset(timeZoneInUse)
			: 0;

		if (!!timeZoneInUse) {
			nextValueFormatted = TimeUtils.offsetTime(nextValueFormatted, (timeZoneOffset - browserOffset), 'minutes', TimeFormat.ISO_DATETIME)!;
		}
		onValueChange?.(nextValueFormatted);

		if (!handleChangeOutside) {
			onChange(nextValueFormatted);
		}

		this.onToggle(false);
	};

	handleSave = () => {
		const {
			input: { onBlur, onChange, value },
			onValueChange,
			inputTimeFormat,
			handleChangeOutside,
		} = this.props;
		const { hours, minutes } = this.state;

		const nextValue = TimePicker.parseDate(value, inputTimeFormat);

		if (isNullOrUndefined(nextValue)) {
			return;
		}

		nextValue.setHours(hours);
		nextValue.setMinutes(minutes);
		nextValue.setSeconds(0);
		nextValue.setMilliseconds(0);

		const nextValueFormatted = TimeUtils.formatDate(nextValue, TimeFormat.ISO_DATETIME);
		onValueChange?.(nextValueFormatted);

		if (!handleChangeOutside) {
			onChange(nextValueFormatted);
			onBlur(nextValueFormatted);
		}

		this.onToggle(false);
	};

	handleClear = () => {
		const { input: { onBlur, onChange }, onValueChange } = this.props;

		onChange(null);
		onValueChange?.(null);
		onBlur(null);
		this.onToggle(false);
	};

	render() {
		const {
			alwaysShowErrors,
			containerClassName,
			containerId,
			disableErrorMessage,
			disabled,
			toggleClassName,
			dropdownClassName,
			dropdownId,
			fixed,
			fullWidth,
			id,
			label,
			meta: { error, warning, touched },
			tooltipMessage,
			withCaret,
		} = this.props;
		const { dropdownStyle, dropup, isOpen, hours, minutes } = this.state;

		const isAM = hours < 12;

		let dropdownMenuClassName = bemBlock('dropdown-menu', { 'scroll-container': true, 'full-width': !!fullWidth, 'fixed': !!fixed });
		dropdownMenuClassName = dropdownClassName ? `${dropdownMenuClassName} ${dropdownClassName}` : dropdownMenuClassName;

		const dropdownToggleClassName = bemBlock('dropdown-toggle', { 'with-caret': !!withCaret });

		const fromGroupClassName = containerClassName ?? '';

		return (
			<FormGroup className={fromGroupClassName} id={containerId}>
				<div className="input-header" ref={this._dropdownInput}>
					<Label
						label={label}
						tooltipMessage={tooltipMessage}
						tooltipPlacement="top"
						withMargin={true}
					/>
				</div>
				<BootstrapDropdown
					className={this.getClassName()}
					drop={dropup ? 'up' : undefined}
					id={id}
					onToggle={this.onToggle}
					show={isOpen}
				>
					<BootstrapDropdown.Toggle
						className={`${toggleClassName} ${dropdownToggleClassName}`}
						disabled={disabled}
					>
						<div className="dropdown-menu__selected" ref={this._dropdownToggle}>
							{this.renderSelected()}
						</div>
					</BootstrapDropdown.Toggle>
					<BootstrapDropdown.Menu
						className={dropdownMenuClassName}
						id={dropdownId}
						style={dropdownStyle}
					>
						<div className="time-picker">
							<div className={bemElement('time-picker', 'controls')}>
								<div className={bemElement('time-picker', 'controls__time-input-container')}>
									<span
										className={`${bemElement('time-picker', 'controls__shift-control')} icon-up`}
										onClick={this.hourIncrement}
									/>
									<input
										className={bemElement('time-picker', 'controls__time-input')}
										onChange={this.handleHoursChange}
										onFocus={this.handleTimeInputFocus}
										value={this.getInputFormatted(this.getHoursAMPM())}
									/>
									<span
										className={`${bemElement('time-picker', 'controls__shift-control')} icon-down`}
										onClick={this.hourDecrement}
									/>
								</div>
								<div className={bemElement('time-picker', 'controls__spacer')}>:</div>
								<div className={bemElement('time-picker', 'controls__time-input-container')}>
									<span
										className={`${bemElement('time-picker', 'controls__shift-control')} icon-up`}
										onClick={this.minuteIncrement}
									/>
									<input
										className={bemElement('time-picker', 'controls__time-input')}
										onChange={this.handleMinutesChange}
										onFocus={this.handleTimeInputFocus}
										value={this.getInputFormatted(minutes)}
									/>
									<span
										className={`${bemElement('time-picker', 'controls__shift-control')} icon-down`}
										onClick={this.minuteDecrement}
									/>
								</div>
								<div className={bemElement('time-picker', 'controls__ampm')}>
									<Radio
										checked={isAM}
										disabled={disabled}
										label="AM"
										onCheck={this.setAM}
									/>
									<Radio
										checked={!isAM}
										disabled={disabled}
										label="PM"
										onCheck={this.setPM}
									/>
								</div>
							</div>
							<div
								className={bemElement('time-picker', 'current-time-button')}
								onClick={this.handleCurrentTime}
							>
								<span className={`${bemElement('time-picker', 'current-time-button__icon')} icon-clock`} />
								<span>Use Current Time</span>
							</div>
							<div className={bemElement('time-picker', 'options')}>
								<Button onClick={this.handleClear} variant="info">Clear</Button>
								<Button onClick={this.handleSave} variant="primary">Save</Button>
							</div>
						</div>
					</BootstrapDropdown.Menu>
				</BootstrapDropdown>
				<FormControl.Feedback />
				{
					(touched || alwaysShowErrors) && !disableErrorMessage &&
					(
						(error && <span className="help-block"><span className="icon-info" /> {error}</span>) ||
						(warning && <span className="help-block text-orange"><span className="icon-info" /> {warning}</span>)
					)
				}
			</FormGroup >
		);
	}
}

export default TimePicker as React.ComponentClass<OwnProps>;
