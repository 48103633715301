import * as React from 'react';
import { Field, formValueSelector, getFormValues } from 'redux-form';
import type { ConnectedProps} from 'react-redux';
import { connect } from 'react-redux';

import BlobStorageContainer from 'acceligent-shared/enums/blobStorageContainer';

import type { DirectoryRM } from 'af-components/SharedForms/DirectoriesAndAttachments';
import DirectoriesAndAttachmentsForm from 'af-components/SharedForms/DirectoriesAndAttachments';
import type { AttachmentEntity } from 'af-components/DocumentsAttachmentsTree/types';

import { WORK_ORDER_FORM } from 'af-constants/reduxForms';

import type { RootState } from 'af-reducers';

import type WorkOrderFM from '../../formModel';

interface TypedColumn {
	header: string;
	id?: string;
	width?: number;
}

interface OwnProps {
	isReadOnly: boolean;
	change: (fieldName: string, value: unknown) => void;
}

type Props = OwnProps & ConnectedProps<typeof connector>;

const Attachments: React.FC<Props> = (props) => {
	const {
		attachments,
		change: stupidChangeWhichChanges,
		directories,
		uploadedAttachmentIds,
		uploadingAttachments,
		workOrderId,
		isReadOnly,
	} = props;

	const columns = React.useMemo<TypedColumn[]>(() => {
		const columnData = [
			{
				header: 'Category folder',
				id: 'categoryFolder',
				width: 6,
			},
			{
				header: 'Files',
				id: 'files',
				width: 1,
			},
			{
				header: 'Download',
				id: 'download',
				width: 1,
			},
		];
		if (!isReadOnly) {
			columnData.push({
				header: 'Upload',
				id: 'upload',
				width: 1,
			});
		}
		columnData.push({
			header: 'Preview',
			id: 'preview',
			width: 1,
		});
		columnData.push({
			header: 'Last modified',
			id: 'lastModified',
			width: 2,
		});
		return columnData;
	}, [isReadOnly]);

	// eslint-disable-next-line react-hooks/exhaustive-deps
	const change = React.useMemo(() => stupidChangeWhichChanges, []);
	// ignore deps - change changes for no reason

	const handleDirectoriesChanged = React.useCallback((changedDirectories: DirectoryRM[]) => {
		change('directories', changedDirectories);
	}, [change]);

	const handleAttachmentsChanged = React.useCallback((changedAttachments: AttachmentEntity) => {
		change('attachments', changedAttachments);
	}, [change]);

	const handleAttachmentsUpload = React.useCallback((attachmentIds: number[]) => {
		change('uploadedAttachmentIds', [...attachmentIds]);
	}, [change]);

	const handleAttachmentUploadStart = React.useCallback((correlationId: string) => {
		const nextUploadingAttachments = { ...uploadingAttachments };
		nextUploadingAttachments[correlationId] = true;
		change('uploadingAttachments', nextUploadingAttachments);
	}, [change, uploadingAttachments]);

	const handleAttachmentUploadEnd = React.useCallback((correlationId: string) => {
		const nextUploadingAttachments = { ...uploadingAttachments };
		delete nextUploadingAttachments[correlationId];
		change('uploadingAttachments', nextUploadingAttachments);
	}, [change, uploadingAttachments]);

	if (!workOrderId) {
		return null;
	}

	return (
		<Field
			attachments={attachments}
			component={DirectoriesAndAttachmentsForm}
			containerId={workOrderId}
			directories={directories}
			isReadOnly={isReadOnly}
			name="uploadingAttachments"
			onAttachmentsChanged={handleAttachmentsChanged}
			onAttachmentsUpload={handleAttachmentsUpload}
			onAttachmentUploadEnd={handleAttachmentUploadEnd}
			onAttachmentUploadStart={handleAttachmentUploadStart}
			onDirectoriesChanged={handleDirectoriesChanged}
			storageContainer={BlobStorageContainer.WO_ATTACHMENTS}
			tableColumns={columns}
			uploadedAttachmentIds={uploadedAttachmentIds}
		/>
	);
};

const selector = formValueSelector(WORK_ORDER_FORM);

function mapStateToProps(state: RootState) {
	const { user: { userData, companyData } } = state;
	if (!userData || !companyData) {
		throw new Error('User not logged in');
	}
	const workOrderId = selector(state, 'id') as Nullable<number>;
	const formData = getFormValues(WORK_ORDER_FORM)(state) as WorkOrderFM;

	return {
		workOrderId,
		attachments: formData.attachments,
		directories: formData.directories ?? null,
		uploadedAttachmentIds: formData.uploadedAttachmentIds ?? null,
		uploadingAttachments: formData.uploadingAttachments,
	};
}

const connector = connect(mapStateToProps);

export default connector(Attachments);
