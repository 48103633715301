import * as React from 'react';
import type { CustomRouteComponentProps } from 'react-router-dom';
import type { ResolveThunks } from 'react-redux';
import { connect } from 'react-redux';

import * as ScheduleBoardActions from 'af-actions/scheduleBoard';

import Loading from './Loading';

interface PathParams {
	personalPublicLink: string;
}

type OwnProps = CustomRouteComponentProps<PathParams>;

interface DispatchProps {
	confirmSmsNotification: typeof ScheduleBoardActions.confirmSmsNotification;
}

type Props = ResolveThunks<DispatchProps> & OwnProps;

interface State {
	date: string;
}

class SmsNotificationConfirmation extends React.PureComponent<Props, State> {
	state: State = {
		date: '',
	};

	async componentDidMount() {
		const { confirmSmsNotification, match: { params: { personalPublicLink } } } = this.props;
		const date = await confirmSmsNotification(personalPublicLink);
		this.setState(() => ({ date }));
	}

	render() {
		const { date } = this.state;
		if (!date) {
			return <Loading />;
		}

		return (
			<div className="form-segment form-segment--mini">
				<div className="form-box form-box--standalone form-box--padded">
					<div className="form-box__icon-container">
						<span className="form-box__icon form-box__icon--success icon-smiley" />
					</div>
					<h2>Thank you for confirming!</h2>
					<h4>You are not scheduled for work on <strong className="text-black">{date}</strong>.</h4>
				</div>
			</div>
		);
	}
}

function mapDispatchToProps(): DispatchProps {
	return {
		confirmSmsNotification: ScheduleBoardActions.confirmSmsNotification,
	};
}

export default connect<null, DispatchProps, OwnProps>(null, mapDispatchToProps())(SmsNotificationConfirmation);
