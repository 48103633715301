import * as React from 'react';
import { connect } from 'react-redux';

import type { RootState } from 'af-reducers';

import HeaderLoggedIn from 'af-components/Header/HeaderLoggedIn';
import HeaderLoggedOut from 'af-components/Header/HeaderLoggedOut';

export interface RouteParams {
	companyName?: string;
}

interface StateProps {
	isAuthenticated?: boolean;
}

type Props = StateProps;

class Header extends React.PureComponent<Props> {

	render(): JSX.Element {
		const { isAuthenticated } = this.props;

		return isAuthenticated
			? <HeaderLoggedIn />
			: <HeaderLoggedOut />;
	}

}

function mapStateToProps(state: RootState): StateProps {
	return {
		isAuthenticated: state.user.isAuthenticated,
	};
}

export default connect(mapStateToProps)(Header);
