export class TableContent<T> implements TableData {
	rows: T[];
	totalCount: number;
	pages: number;

	constructor(data: T[], pages: number, totalCount: number) {
		this.rows = data;
		this.totalCount = totalCount;
		this.pages = pages;
	}
}

export interface TableData {
	totalCount: number;
	pages: number;
}
