import * as React from 'react';
import type { HandleThunkActionCreator } from 'react-redux';
import type { InjectedFormProps } from 'redux-form';
import { reduxForm } from 'redux-form';

import type CrewTypeRequestModel from 'ab-requestModels/crewType.requestModel';

import type { CrewTypeViewModel } from 'ab-viewModels/crewType.viewModel';

import { CREW_TYPE_EDIT } from 'af-constants/reduxForms';

import type * as CrewTypeActions from 'af-actions/crewType';

import CrewTypeForm from 'af-components/SharedForms/Resources/CrewType';
import { validate } from 'af-components/SharedForms/Resources/CrewType/validation';

interface OwnProps {
	updateCrewType: HandleThunkActionCreator<typeof CrewTypeActions.update>;
	toggleEdit: (id: Nullable<number>) => void;
	crewType: CrewTypeViewModel;
	index: number;
}

type Props = OwnProps & InjectedFormProps<CrewTypeRequestModel, OwnProps>;

class CrewTypeEdit extends React.PureComponent<Props> {
	updateCrewType = async (form: CrewTypeRequestModel) => {
		const { crewType, toggleEdit, updateCrewType } = this.props;
		if (crewType.id) {
			await updateCrewType(crewType.id, form);
		}
		toggleEdit(null);
	};

	close = () => {
		const { toggleEdit } = this.props;
		toggleEdit(null);
	};

	render() {
		return (
			<CrewTypeForm {...this.props} close={this.close} onSubmit={this.updateCrewType} />
		);
	}
}

export default reduxForm<CrewTypeRequestModel, OwnProps>({
	form: CREW_TYPE_EDIT, validate, enableReinitialize: true, destroyOnUnmount: false,
})(CrewTypeEdit);
