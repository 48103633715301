import type { Dispatch, AnyAction } from 'redux';

import API from 'af-constants/routes/api';
import { RESOURCE_STATUS } from 'af-constants/reduxForms';

import type * as ResourceStatusesUpdateRequestModel from 'ab-requestModels/resources/resourceStatusesUpdate.requestModel';

import type * as ResourceStatusesViewModel from 'ab-viewModels/resources/resourceStatuses.viewModel';
import type { UnavailableEmployeeStatusVM } from 'ab-viewModels/employeeStatus.viewModel';

import type { HttpError } from 'af-utils/http.util';
import { http } from 'af-utils/http.util';
import type { ErrorOverride } from 'af-utils/actions.util';
import { errorHandler } from 'af-utils/actions.util';

import type { GetRootState } from 'af-reducers';

export function findAllForCompany() {
	return async (dispatch: Dispatch<AnyAction>, getState: GetRootState, { redirectTo }) => {
		const action = async () => {
			return await http.get<ResourceStatusesViewModel.Default>(API.V1.RESOURCES.EMPLOYEE_STATUS.LIST(), { submitting: RESOURCE_STATUS });
		};
		return await errorHandler(action, dispatch, redirectTo);
	};
}

export function updateAllForCompany(data: ResourceStatusesUpdateRequestModel.Default) {
	return async (dispatch: Dispatch<AnyAction>, getState: GetRootState, { redirectTo }) => {
		/** Most 40x errors can occur if updates were made on stale data, so just return the up to date list */
		const override: ErrorOverride = {
			err400: async (error: HttpError) => {
				console.error(error?.response?.data);	// there are multiple reasons for a bad request, log the error context
				return await findAllForCompany()(dispatch, getState, { redirectTo });
			},
			err404: async () => {
				return await findAllForCompany()(dispatch, getState, { redirectTo });
			},
		};
		const action = async () => {
			return await http.post<ResourceStatusesViewModel.Default>(API.V1.RESOURCES.EMPLOYEE_STATUS.LIST(), data);
		};
		return await errorHandler(action, dispatch, redirectTo, override);
	};
}

export function findAllUnavailable() {
	return async (dispatch: Dispatch<AnyAction>, getState: GetRootState, { redirectTo }) => {
		const action = async () => {
			return await http.get<UnavailableEmployeeStatusVM[]>(API.V1.RESOURCES.EMPLOYEE_STATUS.UNAVAILABLE());
		};
		return await errorHandler(action, dispatch, redirectTo);
	};
}
