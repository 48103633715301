import * as React from 'react';
import type { ConnectedProps } from 'react-redux';
import { connect } from 'react-redux';
import { compose } from 'redux';
import type { CustomRouteComponentProps } from 'react-router-dom';
import { Link } from 'react-router-dom';
import { Form, Row, Col } from 'react-bootstrap';
import type { InjectedFormProps} from 'redux-form';
import { reduxForm, formValueSelector, Field } from 'redux-form';

import type { RootState } from 'af-reducers';

import { PLATFORM_ORGANIZATION_NOTIFY } from 'af-constants/reduxForms';
import CLIENT from 'af-constants/routes/client';

import Dropdown from 'af-fields/Dropdown';

import type NotifyOrganizationRequestModel from 'ab-requestModels/organizationNotify.requestModel';

import * as CommunicationActions from 'af-actions/communication';

import NotifyUsersFields from 'af-components/SharedForms/NotifyUsers';
import SubmitButton from 'af-components/SubmitButton';
import Breadcrumbs from 'af-components/Breadcrumbs';
import SegmentLabel from 'af-components/SegmentLabel';
import { validate } from 'af-components/SharedForms/NotifyUsers/validations';

import { NotificationPriorityOptions } from 'ab-enums/notificationPriority.enum';

interface PathParams {
	clientAlias: string;
}

type OwnProps = CustomRouteComponentProps<PathParams>;

type FormOwnProps = OwnProps & ConnectedProps<typeof connector>;

type Props = FormOwnProps & InjectedFormProps<NotifyOrganizationRequestModel, FormOwnProps>;

class NotifyOrganizationEmployees extends React.PureComponent<Props> {

	submit = async (form: NotifyOrganizationRequestModel) => {
		const { notify, location: { state: { orgAlias } }, match: { params: { clientAlias } } } = this.props;
		await notify(form, clientAlias, orgAlias);
	};

	render() {
		const { handleSubmit, submitting, invalid, location: { state: { orgAlias } }, notificationPriority } = this.props;

		return (
			<div className="form-segment">
				<Breadcrumbs
					items={
						[
							{ label: 'Organizations', url: CLIENT.PLATFORM.ALL_ORGANIZATIONS(orgAlias) },
							{ label: 'Notify All Employees' },
						]
					}
				/>
				<Form className="form-box" onSubmit={handleSubmit(this.submit)}>
					<Row>
						<Col sm={24}>
							<SegmentLabel label="Settings" />
						</Col>
					</Row>
					<Row>
						<Col md={12}>
							<Field
								component={Dropdown}
								defaultValue={NotificationPriorityOptions[0]}
								id="notificationPriority"
								label="Choose notification priority"
								labelKey="name"
								name="notificationPriority"
								options={NotificationPriorityOptions}
								valueKey="id"
								withCaret={true}
							/>
						</Col>
						<Col md={12} />
					</Row>
					<NotifyUsersFields
						notificationPriority={notificationPriority}
					/>
					<Row className="row--submit">
						<Link
							className="btn btn-info"
							to={CLIENT.PLATFORM.ALL_ORGANIZATIONS(orgAlias)}
						>
							Cancel
						</Link>
						<SubmitButton
							disabled={invalid}
							label="Send"
							reduxFormSubmitting={submitting}
						/>
					</Row>
				</Form>
			</div>
		);
	}
}

function mapStateToProps(state: RootState) {
	return {
		notificationPriority: formValueSelector(PLATFORM_ORGANIZATION_NOTIFY)(state, 'notificationPriority'),
	};
}

function mapDispatchToProps() {
	return {
		notify: CommunicationActions.notifyAllForOrganization,
	};
}

const connector = connect(mapStateToProps, mapDispatchToProps());

const enhance = compose<React.ComponentClass<OwnProps>>(
	connector,
	reduxForm<NotifyOrganizationRequestModel, FormOwnProps>({
		form: PLATFORM_ORGANIZATION_NOTIFY,
		validate,
	})
);

export default enhance(NotifyOrganizationEmployees);
