import type LocationRequestModel from 'ab-requestModels/location.requestModel';

import type { LocationViewModel } from 'ab-viewModels/location/location.viewModel';

export const fromVMtoRM = (location: LocationViewModel): LocationRequestModel => {
	if (!location.address) {
		throw new Error('Location address not provided');
	}

	const _address = location?.address;
	return {
		...location,
		addressId: _address.id,
		street: _address.street,
		longitude: _address.longitude,
		latitude: _address.latitude,
		streetNumber: _address.streetNumber ?? undefined,
		route: _address.route ?? undefined,
		suite: _address.suite ?? undefined,
		locality: _address.city ?? undefined,
		aa1: _address.state ?? undefined,
		aa2: _address.aa2 ?? undefined,
		aa3: _address.aa3 ?? undefined,
		country: _address.country ?? undefined,
		postalCode: _address.zip ?? undefined,
		postalOfficeBoxCode: _address.postalOfficeBoxCode ?? undefined,
	};
};
