import * as React from 'react';
import { Draggable } from 'react-beautiful-dnd';

import Checkbox from 'af-components/Controls/Checkbox';

import type { ColumnSettingItem } from 'ab-viewModels/columnSettings.viewModel';

import styles from './styles.module.scss';

interface Props {
	item: ColumnSettingItem;
	index: number;
	onCheck: (item: ColumnSettingItem) => void;
}

export default class ColumnItem extends React.PureComponent<Props> {
	onCheck = () => {
		const { item, onCheck } = this.props;
		onCheck(item);
	};

	render() {
		const { item, index } = this.props;

		if (item.fixed) {
			const label = `${item.label} - fixed`;
			return (
				<div className={styles['table-settings__item']}>
					<span className={styles['table-settings__item__label']}>{label}</span>
				</div>
			);
		}

		return (
			<Draggable
				draggableId={item.name}
				index={index}
				isDragDisabled={item.fixed}
			>
				{(provided) => (
					<div
						className={styles['table-settings__item']}
						ref={provided.innerRef}
						{...provided.draggableProps}
						{...provided.dragHandleProps}
					>
						<>
							<span className="icon-drag_indicator" />
							<Checkbox
								handleChange={this.onCheck}
								isChecked={item.visible}
								isStandalone={true}
								label={item.label as string}
							/>
						</>
					</div>
				)}
			</Draggable>
		);
	}
}
