import * as React from 'react';
import type { WrappedFieldArrayProps } from 'redux-form';
import type { ConnectedProps } from 'react-redux';
import { connect } from 'react-redux';
import { Col, Row } from 'react-bootstrap';

import type { RootState } from 'af-reducers';

import CLIENT from 'af-routes/client';

import { getFullClientUrl } from 'af-utils/http.util';

import LinkCell from 'af-components/Table/Cells/LinkCell';
import type { SubjobFM } from 'af-components/SharedForms/Job/formModel';

import styles from './styles.module.scss';

export interface OwnProps {
	subjobs: SubjobFM[];
	orgAlias: string;
}

type Props = OwnProps & WrappedFieldArrayProps<SubjobFM> & ConnectedProps<typeof connector>;

const SubjobsArray: React.FC<Props> = React.memo((props) => {

	const {
		fields,
		subjobs,
		orgAlias,
		companyName,
	} = props;

	const renderSubjob = React.useCallback((field: SubjobFM) => {
		const path = getFullClientUrl(orgAlias, CLIENT.COMPANY.JOBS.PREVIEW(orgAlias, companyName, `${field.id}`));

		return (
			<Row key={field.jobCode}>
				<Col className={styles['job-cell']} sm={24}>
					<LinkCell label={''} path={path} />
					<div className={styles['subjob-flag']}>S</div>
					{field.jobCode}
				</Col>
			</Row>
		);
	}, [companyName, orgAlias]);

	React.useEffect(() => {
		subjobs?.forEach((subjob) => {
			if (fields.getAll().find((_sj) => _sj.id === subjob.id)) {
				return;
			}

			fields.push(
				{
					id: subjob.id,
					jobCode: subjob.jobCode,
				} as SubjobFM
			);
		});
		// eslint-disable-next-line react-hooks/exhaustive-deps
	}, []);

	const fieldSubjobs = React.useMemo(() => fields.getAll() ?? [], [fields]);

	return (
		<>
			{fieldSubjobs.map(renderSubjob)}
		</>
	);
});

function mapStateToProps(state: RootState) {
	const { companyData } = state.user;
	if (!companyData) {
		throw new Error('User not logged in');
	}

	return {
		companyName: companyData.name,
	};
}

const connector = connect(mapStateToProps);

export default connector(SubjobsArray);
