import * as React from 'react';
import type { ConnectedProps } from 'react-redux';
import { connect } from 'react-redux';
import type { CustomRouteComponentProps } from 'react-router-dom';
import { Link } from 'react-router-dom';
import type { InjectedFormProps} from 'redux-form';
import { reduxForm, Field } from 'redux-form';
import { Form, Row, Col } from 'react-bootstrap';

import * as OrganizationActions from 'af-actions/organizations';

import type OrganizationPublicDetailsViewModel from 'ab-viewModels/organizationPublicDetails.viewModel';

import type OrganizationCheckRequestModel from 'ab-requestModels/organizationCheck.requestModel';

import Input from 'af-fields/Input';

import SubmitButton from 'af-components/SubmitButton';

import CLIENT from 'af-routes/client';

import { ORGANIZATION_CHECK } from 'af-constants/reduxForms';

import validate from 'af-root/scenes/Authentication/ChooseOrganization/validations';

import type { RootState } from 'af-reducers';

import { getHostname } from 'af-utils/window.util';
import { compose } from 'redux';

type OwnProps = CustomRouteComponentProps;

type Props = OwnProps & ConnectedProps<typeof connector> & InjectedFormProps<OrganizationCheckRequestModel>;

class ChooseOrganization extends React.PureComponent<Props> {

	async componentDidMount() {
		const { fetchActiveOrganizations } = this.props;
		fetchActiveOrganizations();
	}

	checkAlias = async (form: OrganizationCheckRequestModel) => {
		const { checkOrganizationAlias } = this.props;

		return await checkOrganizationAlias(form.alias);
	};

	renderOrganizationSuggestion = (organization: OrganizationPublicDetailsViewModel, index: number): JSX.Element => {

		let className = 'form-box organization-suggestion';
		className = organization.isPlatformAdmin ? `${className} organization-suggestion--admin` : className;

		return (
			<a className={className} href={CLIENT.HOME(organization.alias)} key={index}>
				<h2>{organization.name}</h2>
				<h4 className="organization-suggestion__url">{organization.alias}.{getHostname()}</h4>
			</a>
		);
	};

	renderRecentOrganizations = (): Nullable<JSX.Element> => {
		const { multiplePublicDetails } = this.props;

		if (!multiplePublicDetails) {
			return null;
		}

		return (
			<div>
				<h3>Recent Organizations:</h3>
				{multiplePublicDetails.map((_organization, _index) => this.renderOrganizationSuggestion(_organization, _index))}
			</div>
		);
	};

	render(): JSX.Element {
		const { handleSubmit, submitting } = this.props;

		return (
			<div className="form-segment form-segment--mini">
				<div className="form-box form-box--standalone">
					<h2>Sign in to Your Organization</h2>
					<h4>Enter your Acceligent Organization alias</h4>
					<Form onSubmit={handleSubmit(this.checkAlias)}>
						<Row>
							<Col sm={24}>
								<Field
									afterInputContent={`.${getHostname()}`}
									afterInputStyle={{ height: '20px', zIndex: '3', paddingRight: '16px' }}
									component={Input}
									id="alias"
									name="alias"
									type="text"
								/>
							</Col>
						</Row>
						<Row className="row--padded">
							<Col sm={24}>
								<SubmitButton
									className="btn--full"
									disabled={submitting}
									label="Continue"
									reduxFormSubmitting={submitting}
								/>
							</Col>
						</Row>
						<Row className="row--padded">
							<Col sm={24}>
								Cannot remember? <Link className="text-blue" to={CLIENT.APP.ORGANIZATION_FORGOTTEN}>We can help!</Link>
							</Col>
						</Row>
					</Form>
				</div>
				{this.renderRecentOrganizations()}
			</div>
		);
	}
}

function mapStateToProps(state: RootState) {
	return {
		multiplePublicDetails: state.organization.multiplePublicDetails,
	};
}

function mapDispatchToProps() {
	return {
		checkOrganizationAlias: OrganizationActions.checkOrganizationAlias,
		fetchActiveOrganizations: OrganizationActions.fetchActiveOrganizations,
	};
}

const connector = connect(mapStateToProps, mapDispatchToProps());

const enhance = compose<React.ComponentClass<OwnProps>>(
	connector,
	reduxForm({ form: ORGANIZATION_CHECK, validate })
);

export default enhance(ChooseOrganization);
