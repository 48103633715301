import * as React from 'react';
import { compose } from 'redux';
import type { ResolveThunks } from 'react-redux';
import { connect } from 'react-redux';
import type { InjectedFormProps } from 'redux-form';
import { reduxForm } from 'redux-form';
import type { CustomRouteComponentProps } from 'react-router-dom';

import type { EquipmentCostRequestModel } from 'ab-requestModels/equipmentCost.requestModel';

import type * as User from 'ab-viewModels/user.viewModel';

import { EQUIPMENT_COST_CREATE } from 'af-constants/reduxForms';
import CLIENT from 'af-constants/routes/client';

import * as EquipmentCostActions from 'af-actions/equipmentCost';

import EquipmentCostForm from 'af-components/SharedForms/Resources/EquipmentCost';
import { validate } from 'af-components/SharedForms/Resources/EquipmentCost/validation';
import Breadcrumbs from 'af-components/Breadcrumbs';

import type { RootState } from 'af-reducers';

type FormProps = InjectedFormProps<EquipmentCostRequestModel>;

type OwnProps = CustomRouteComponentProps;

interface StateProps {
	companyData: User.CompanyData;
}

interface DispatchProps {
	create: typeof EquipmentCostActions.create;
}

type FormOwnProps = OwnProps & StateProps & ResolveThunks<DispatchProps>;

type Props = FormOwnProps & FormProps;

class Create extends React.PureComponent<Props> {

	createEquipmentCost = async (form: EquipmentCostRequestModel) => {
		const { create, location: { state: { orgAlias } }, companyData } = this.props;
		await create(form, companyData.name, orgAlias);
	};

	render() {
		const { location: { state: { orgAlias } }, companyData: { name: companyName } } = this.props;

		return (
			<div className="form-segment">
				<Breadcrumbs
					items={[
						{ label: 'Equipment Costs', url: CLIENT.COMPANY.RESOURCES.EQUIPMENT_COST.TREE(orgAlias, companyName) },
						{ label: 'New Equipment Cost' },
					]}
				/>
				<EquipmentCostForm
					{...this.props}
					formName={EQUIPMENT_COST_CREATE}
					onSubmit={this.createEquipmentCost}
				/>
			</div>
		);
	}
}

function mapStateToProps(state: RootState): StateProps {
	const { companyData } = state.user;
	if (!companyData) {
		throw new Error('User not logged in');
	}

	return {
		companyData,
	};
}

function mapDispatchToProps(): DispatchProps {
	return {
		create: EquipmentCostActions.create,
	};
}

const enhance = compose<React.ComponentClass<OwnProps>>(
	connect<StateProps, DispatchProps, OwnProps>(mapStateToProps, mapDispatchToProps()),
	reduxForm<EquipmentCostRequestModel, FormOwnProps>({
		form: EQUIPMENT_COST_CREATE,
		enableReinitialize: true,
		validate,
	})
);
export default enhance(Create);
