import type { EquipmentCSVRequestModel, EquipmentImportBatch } from 'ab-requestModels/equipment.requestModel';
import type { CSVRow, CSVData } from 'ab-viewModels/csv.viewModel';

const PROPS = {
	EQUIPMENT: {
		ID: 'id',
		SPECIFICATION: 'specification',
		CONTACT_1: 'primaryContact',
		CONTACT_2: 'secondaryContact',
		EQUIPMENT_STATUS: 'equipmentStatus',
		LICENSES: 'licenses',
		SKILLS: 'skills',
		EQUIPMENT_COST: 'equipmentCost',
	},
	EQUIPMENT_COST: {
		TYPE: 'type',
		GROUP: 'group',
		CATEGORY: 'category',
		CATEGORY_COLOR: 'categoryColor',
		SUBCATEGORY: 'subcategory',
		MOB_CHARGE: 'mobCharge',
		DAILY_COST: 'dailyCost',
		WEEKLY_COST: 'weeklyCost',
		MONTHLY_COST: 'monthlyCost',
		OPERATING_CHARGE: 'operatingCharge',
		FUEL_COST: 'fuelCost',
		LICENSES: 'licenses',
		SKILLS: 'skills',
	},
	SKILLS: {
		NAME: 'name',
		COLOR: 'color',
	},
};

interface CSVIdToArrayIdxMapper {
	[csvId: string]: { idx: number; };
}

const parseRow = (row: CSVRow): EquipmentCSVRequestModel => {
	const equipment: EquipmentCSVRequestModel = {
		equipmentCost: {
			type: '',
			group: '',
			category: '',
			categoryColor: '',
			subcategory: '',
			mobCharge: '0',
			dailyCost: '0',
			weeklyCost: '0',
			monthlyCost: '0',
			operatingCharge: '0',
			fuelCost: '0',
			licenses: '',
			skills: [],
		},
		id: '',
		specification: '',
		primaryContact: '',
		secondaryContact: '',
		equipmentStatus: '',
		licenses: '',
		skills: [],
	};

	const elementIdx = {} as CSVIdToArrayIdxMapper;
	const ecElementIdx = {} as CSVIdToArrayIdxMapper;

	Object.entries(row).forEach(([_column, _value]) => {
		if (!_value) {
			return;
		}

		const properties = _column.split('.');
		const [ccProp] = properties;

		switch (ccProp) {
			case PROPS.EQUIPMENT.ID:
			case PROPS.EQUIPMENT.CONTACT_1:
			case PROPS.EQUIPMENT.CONTACT_2:
			case PROPS.EQUIPMENT.EQUIPMENT_STATUS:
			case PROPS.EQUIPMENT.LICENSES:
			case PROPS.EQUIPMENT.SPECIFICATION:
				equipment[ccProp] = _value;
				break;
			case PROPS.EQUIPMENT.SKILLS:
				const [, elementId, elementProp] = properties;
				const elements = equipment[ccProp];
				const csvId = `${ccProp}.${elementId}`;

				if (!elementIdx[csvId]) {
					elementIdx[csvId] = { idx: elements.length };
					elements.push({});
				}
				const { idx } = elementIdx[csvId];

				switch (elementProp) {
					case PROPS.SKILLS.NAME:
					default:
						elements[idx][elementProp] = _value;
						break;
				}
				break;
			case PROPS.EQUIPMENT.EQUIPMENT_COST:
				const [, equipmentCostField] = properties;

				switch (equipmentCostField) {
					case PROPS.EQUIPMENT_COST.TYPE:
					case PROPS.EQUIPMENT_COST.GROUP:
					case PROPS.EQUIPMENT_COST.CATEGORY:
					case PROPS.EQUIPMENT_COST.CATEGORY_COLOR:
					case PROPS.EQUIPMENT_COST.SUBCATEGORY:
					case PROPS.EQUIPMENT_COST.LICENSES:
						equipment.equipmentCost[equipmentCostField] = _value;
						break;
					case PROPS.EQUIPMENT_COST.MOB_CHARGE:
					case PROPS.EQUIPMENT_COST.DAILY_COST:
					case PROPS.EQUIPMENT_COST.WEEKLY_COST:
					case PROPS.EQUIPMENT_COST.MONTHLY_COST:
					case PROPS.EQUIPMENT_COST.OPERATING_CHARGE:
					case PROPS.EQUIPMENT_COST.FUEL_COST:
						equipment.equipmentCost[equipmentCostField] = parseFloat(_value).toString();
						break;
					case PROPS.EQUIPMENT_COST.SKILLS:
						const [, , ecElementId, ecElementProp] = properties;
						const ecElements = equipment.equipmentCost[equipmentCostField];
						const ecCsvId = `${equipmentCostField}.${ecElementId}`;

						if (!ecElementIdx[ecCsvId]) {
							ecElementIdx[ecCsvId] = { idx: ecElements.length };
							ecElements.push({});
						}
						const { idx: ecIdx } = ecElementIdx[ecCsvId];

						ecElements[ecIdx][ecElementProp] = _value;
				}
		}
	});

	return equipment;
};

export default (data: CSVData): EquipmentImportBatch => ({ equipmentBatch: data.map(parseRow) });
