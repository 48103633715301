import type CountryCode from 'acceligent-shared/enums/countryCode';

import { formatPhoneNumber } from 'acceligent-shared/utils/phone';

export class W_Account_Edit_VM {
	id: Nullable<number>;
	firstName: string;
	lastName: string;
	phoneNumber: Nullable<string>;
	countryCode: Nullable<CountryCode>;
	email: Nullable<string>;
	extension: Nullable<string>;
	isDigitalSignatureEnabled: boolean;
	digitalSignatureUrl: Nullable<string>;
	digitalSignatureId: Nullable<number>;
	activeInLMS: boolean;

	constructor(
		id: number,
		firstName: string,
		lastName: string,
		phoneNumber: Nullable<string>,
		countryCode: Nullable<CountryCode>,
		email: Nullable<string>,
		extension: Nullable<string>,
		isDigitalSignatureEnabled: boolean,
		digitalSignatureId: Nullable<number>,
		digitalSignatureUrl: Nullable<string>,
		activeInLMS: boolean
	) {
		this.id = id;
		this.firstName = firstName;
		this.lastName = lastName;
		this.phoneNumber = formatPhoneNumber(phoneNumber);
		this.countryCode = countryCode;
		this.email = email;
		this.extension = extension;
		this.isDigitalSignatureEnabled = isDigitalSignatureEnabled;
		this.digitalSignatureId = isDigitalSignatureEnabled
			? digitalSignatureId
			: null;
		this.digitalSignatureUrl = isDigitalSignatureEnabled
			? digitalSignatureUrl
			: null;
		this.activeInLMS = activeInLMS;
	}
}

