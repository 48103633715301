import * as React from 'react';

import type { CompoundUnitEnum } from 'acceligent-shared/enums/quantityUnit';

import CompoundInput from 'af-fields/CompoundInput';

import fieldHoc from '../Shared/fieldHoc';
import Preview from '../Shared/CompoundTextPreview';

interface OwnProps {
	disabled: boolean;
	formName: string;
	name: string;
	unit: CompoundUnitEnum;
	tooltipMessage?: React.ComponentProps<typeof CompoundInput>['tooltipMessage'];
	onFocus?: () => void;
	onValueChange: () => void;
}

type Props = OwnProps;

const CompoundField: React.FC<Props> = (props) => {
	const {
		disabled,
		formName,
		name,
		tooltipMessage,
		unit,
		onValueChange,
		onFocus,
	} = props;
	return (
		<CompoundInput
			disabled={disabled}
			label={name}
			name={formName}
			onFocus={onFocus}
			onValueChange={onValueChange}
			tooltipMessage={tooltipMessage}
			unit={unit}
		/>
	);
};

export default fieldHoc(React.memo(CompoundField), Preview as React.FC);
