import * as ReportBlockFieldEnum from 'acceligent-shared/enums/reportBlockField';
import ReportBlockType from 'acceligent-shared/enums/reportBlockType';
import ReportTypeBlockType from 'acceligent-shared/enums/reportTypeBlockType';
import OperandType from 'acceligent-shared/enums/operand';

import { omitObject } from 'acceligent-shared/utils/extensions';

import { DroppableZones } from 'ab-enums/reportTypeBuilder.enum';

import type { ReportTypeBlockFormModel, ReportBlockFormModel, ReportBlockFieldFormModel } from 'af-root/scenes/Company/Settings/Reports/Shared/formModel';

// private types

const SegmentTypes = ['primary', 'secondary'];

interface RemoveFieldsAccumulator {
	newCalculationFieldsMap: { [id: string]: true; };
	newFieldsForDelete: { [fieldId: string]: true; };
	newReportFieldsByIdMap: { [id: string]: ReportBlockFieldFormModel; };
}

const CALCULATED_BLOCK_DROPPABLE_ID_REGEX = new RegExp(`((${DroppableZones.CALCULATED_BLOCK_FIELD_LIST_ID})#(${Object.values(SegmentTypes).join('|')})#([^#]+))(#([0-9]))?`);

// public types

export const initDefaultCompletionFieldFMValues = (virtualId: string, blockId: string): ReportBlockFieldFormModel => {
	return {
		virtualId,
		reportBlockVirtualId: blockId,
		name: 'Completed',
		valueType: ReportBlockFieldEnum.ValueType.BOOLEAN,
		defaultValue: null,
		imageFile: null,
		dimension: ReportBlockFieldEnum.Dimension.VERY_SMALL,
		isKeyParameter: false,
		unit: null,
		isVisibleToCustomer: true,
		isRequired: false,
		isUnique: false,
		hasTooltip: false,
		fieldType: ReportBlockFieldEnum.Type.COMPLETION,
		calculatedFieldOptions: null,
		isDescriptiveTextBold: false,
		operationType: null,
		icon: null,
	};
};

export const BLOCK_PREFIX = 'block';
export const FIELD_PREFIX = 'field';

// private functions

interface MapReportTypeBlocksToStateReducer {
	primaryTypeBlockIds: string[];
	secondaryTypeBlockIds: string[];
	reportTypeBlocksByIdMap: { [id: string]: ReportTypeBlockFormModel; };
	upperTotalBlockId: Nullable<string>;
	lowerTotalBlockId: Nullable<string>;
}
/**
 * Restores report type block array to Custom type form state
 */
const _mapReportTypeBlocksToState = (
	reportTypeBlockIds: string[],
	reportTypeBlocksByIdMap: { [id: string]: ReportTypeBlockFormModel; },
	reportBlocksByIdMap: { [id: string]: ReportBlockFormModel; },
	reportFieldsByIdMap: { [id: string]: ReportBlockFieldFormModel; },
	calculationFieldMap: { [id: string]: true; }
) => {

	const {
		primaryTypeBlockIds,
		secondaryTypeBlockIds,
		reportTypeBlocksByIdMap: newReportTypeBlocksByIdMap,
		upperTotalBlockId: newUpperTotalBlockId,
		lowerTotalBlockId: newLowerTotalBlockId,
	} = reportTypeBlockIds.reduce<MapReportTypeBlocksToStateReducer>((_acc, _id) => {
		const _reportTypeBlock = reportTypeBlocksByIdMap[_id];
		const _reportBlock = reportBlocksByIdMap[_reportTypeBlock.reportBlockVirtualId];

		if (!_reportBlock.isMain && _reportTypeBlock.type === ReportTypeBlockType.BLOCK && _reportTypeBlock.isPrimary) {
			_acc.primaryTypeBlockIds.push(_id);
		} else if (!_reportBlock.isMain && _reportTypeBlock.type === ReportTypeBlockType.BLOCK && !_reportTypeBlock.isPrimary) {
			_acc.secondaryTypeBlockIds.push(_id);
		}

		if (_reportTypeBlock.type === ReportTypeBlockType.TOTAL && _reportTypeBlock.isPrimary) {
			_acc.upperTotalBlockId = _id;
		} else if (_reportTypeBlock.type === ReportTypeBlockType.TOTAL && !_reportTypeBlock.isPrimary) {
			_acc.lowerTotalBlockId = _id;
		}
		return _acc;
	}, { primaryTypeBlockIds: [], secondaryTypeBlockIds: [], reportTypeBlocksByIdMap, upperTotalBlockId: null, lowerTotalBlockId: null });

	return {
		primaryTypeBlockIds,
		secondaryTypeBlockIds,
		reportTypeBlocksByIdMap: newReportTypeBlocksByIdMap,
		reportBlocksByIdMap,
		reportFieldsByIdMap,
		upperTotalBlockId: newUpperTotalBlockId,
		lowerTotalBlockId: newLowerTotalBlockId,
		calculationFieldMap,
	};
};

/**
 * Removes fields from Calculated blocks
 */
const _removeFieldsFromBlock = (
	reportTypeBlockIds: string[],
	reportTypeBlocksByIdMap: { [id: string]: ReportTypeBlockFormModel; },
	reportBlocksByIdMap: { [id: string]: ReportBlockFormModel; },
	deletedFields: { [fieldId: number]: true; }
) => {
	return reportTypeBlockIds.reduce((_acc, _reportTypeBlockId) => {
		const _reportTypeBlock = reportTypeBlocksByIdMap[_reportTypeBlockId];
		const _reportBlock = reportBlocksByIdMap[_reportTypeBlock.reportBlockVirtualId];
		if (_reportBlock.type === ReportBlockType.CALCULATED) {
			const newFieldIds = _reportBlock.reportBlockFieldIds?.filter((_id) => !deletedFields[_id]);
			// Remove blocks that are left without fields but not the ones that are already empty
			if (!newFieldIds?.length) {
				return _acc;
			}
			_acc.blocks[_reportBlock.virtualId] = {
				..._reportBlock,
				reportBlockFieldIds: newFieldIds,
			};
		} else {
			_acc.blocks[_reportBlock.virtualId] = _reportBlock;
		}

		_acc.ids.push(_reportTypeBlockId);
		_acc.typeBlocks[_reportTypeBlockId] = _reportTypeBlock;

		return _acc;
	}, { ids: [], blocks: {}, typeBlocks: {} } as {
		ids: string[];
		blocks: { [id: string]: ReportBlockFormModel; };
		typeBlocks: { [id: string]: ReportTypeBlockFormModel; };
	});
};

/**
 * Removes fields from calculation lookups
 */
const _removeFieldsFromLookups = (
	fieldsForDelete: { [fieldId: string]: true; },
	fieldsByIdMap: { [fieldId: string]: ReportBlockFieldFormModel; },
	calculationFieldsMap: { [id: string]: true; } = {}
) => {
	return Object.keys(calculationFieldsMap).reduce((_acc, _resultFieldId) => {
		const field = fieldsByIdMap[_resultFieldId];

		// Remove from calculation option lookups deleted fields
		const newFieldIds = field.calculatedFieldOptions?.filter((_option) => !!_option.id && !fieldsForDelete[_option.id]);
		if (newFieldIds?.length) {
			// If there are still calculation fields left in Calculation add it to new lookups map
			_acc.newCalculationFieldsMap[_resultFieldId] = true;
			if (newFieldIds.length !== field.calculatedFieldOptions?.length) {
				_acc.newReportFieldsByIdMap[_resultFieldId] = { ...field, calculatedFieldOptions: newFieldIds };
			}
		} else {
			// If there are no options left, add Calculated field to new map of deleted fields
			_acc.newFieldsForDelete[_resultFieldId] = true;
			_acc.newReportFieldsByIdMap = omitObject(_acc.newReportFieldsByIdMap, [_resultFieldId]);
		}
		return _acc;
	}, { newCalculationFieldsMap: {}, newFieldsForDelete: {}, newReportFieldsByIdMap: { ...fieldsByIdMap } } as RemoveFieldsAccumulator);
};

/**
 * Updates calculation lookups map with new field, or removes it if's unit or operation type changed
 */
const _updateLookups = (
	oldField: Nullable<ReportBlockFieldFormModel>,
	newField: ReportBlockFieldFormModel,
	fieldsByIdMap: { [fieldId: string]: ReportBlockFieldFormModel; },
	calculationFieldsMap: { [id: string]: true; }
) => {
	return Object.keys(calculationFieldsMap).reduce((_acc, _resultFieldId) => {
		const field = fieldsByIdMap[_resultFieldId];

		// Create new lookups
		const newCalculatedFieldOptions = field.calculatedFieldOptions ? [...field.calculatedFieldOptions] : [];
		// Find updated field index in current lookups
		const index = newCalculatedFieldOptions.findIndex((_option) => _option.id === oldField?.virtualId);
		// If the updated field is used in the operation
		if (index >= 0) {
			// If the field changed unit or operation type remove it from lookups
			if (oldField?.unit !== newField.unit || oldField.operationType !== newField.operationType) {
				newCalculatedFieldOptions.splice(index, 1);
			} else {
				// If it didn't change unit or operation, replace it in lookups with new field
				newCalculatedFieldOptions.splice(
					index,
					1,
					{
						id: newField.virtualId,
						constant: null,
						type: OperandType.FIELD,
						index,
						isOperandBlockInPrimarySegment: newCalculatedFieldOptions[index].isOperandBlockInPrimarySegment,
					}
				);
			}
		}
		if (newCalculatedFieldOptions.length) {
			// If the Calculation still has fields in it, add it to new lookups
			_acc.newCalculationFieldsMap[_resultFieldId] = true;
		} else {
			// If not add it to map of fields for deletion
			_acc.newFieldsForDelete[_resultFieldId] = true;
		}
		return _acc;
	}, { newCalculationFieldsMap: {}, newFieldsForDelete: {}, newReportFieldsByIdMap: { ...fieldsByIdMap } } as RemoveFieldsAccumulator);
};

// public functions

export const getSegment = (isPrimary: boolean) => isPrimary ? 0 : 1;

/**
 * Generates droppableId for a droppable zone inside a calculated block
 * @param isPrimary segment of report type
 * @param blockIndex block index inside segment
 */
export const generateCalculatedBlockDroppableId = (isPrimary: boolean, blockIndex: number) => {
	return `${DroppableZones.CALCULATED_BLOCK_FIELD_LIST_ID}#${isPrimary ? 'primary' : 'secondary'}#${blockIndex}`;
};

/**
 * Returns whether the droppable zone is inside of a calculated block
 * @param droppableId droppableId of calculated block inside report type builder
 */
export const isCalculatedBlockDroppableZone = (droppableId: string) => {
	const match = droppableId?.match(CALCULATED_BLOCK_DROPPABLE_ID_REGEX);
	return match?.[2] === DroppableZones.CALCULATED_BLOCK_FIELD_LIST_ID;
};

/**
 * Returns segment and block index of droppable zone inside report type builder
 * @param droppableId droppableId of calculated block inside report type builder
 */
export const parseCalculatedBlockDroppableId = (droppableId: string) => {
	// e.g. droppableId='calculated-block-field-list#primary#1'
	// 0=all, 1=calculated-block-field-list#primary#1, 2=calculated-block-field-list, 3=primary, 4=1
	const match = droppableId.match(CALCULATED_BLOCK_DROPPABLE_ID_REGEX);
	return {
		isPrimary: match?.[3] === 'primary',
		blockIndex: Number(match?.[4]),
	};
};

/**
 * Replaces name of block and returns new block
 * @param oldBlock block which name should be replaced
 * @param name new name of block
 */
export const changeBlockName = (oldBlock: ReportBlockFormModel, name: string) => {
	return {
		...oldBlock,
		name,
	};
};

/**
 * Recursively removes fields from list of report type blocks and calculated field operand lookups
 * @param fieldsForDelete list fields to remove from report type blocks
 * @param reportTypeBlocks list of report type blocks to remove fields from
 * @param calculationFieldsMap map of calculated field Ids and their operand lookups
 */
export const removeOptions = (
	fieldsForDelete: { [fieldId: string]: true; },
	reportTypeBlockIds: string[],
	reportTypeBlocksIdsMap: { [id: string]: ReportTypeBlockFormModel; },
	reportBlocksByIdMap: { [id: string]: ReportBlockFormModel; },
	reportFieldsByIdMap: { [id: string]: ReportBlockFieldFormModel; },
	calculationFieldsMap: { [id: string]: true; }
) => {
	if (!Object.keys(fieldsForDelete).length) {
		return _mapReportTypeBlocksToState(reportTypeBlockIds, reportTypeBlocksIdsMap, reportBlocksByIdMap, reportFieldsByIdMap, calculationFieldsMap);
	}

	// Remove fields for delete from calculation lookups
	// 'newFieldsForDelete' are Calculated fields that are left without Calculation Options
	const {
		newCalculationFieldsMap,
		newFieldsForDelete,
		newReportFieldsByIdMap,
	} = _removeFieldsFromLookups(fieldsForDelete, reportFieldsByIdMap, calculationFieldsMap);

	// Remove fields from Calculated blocks
	const {
		ids: newReportTypeBlockIds,
		typeBlocks: newReportTypeBlocksByIdMap,
		blocks: newReportBlocksByIdMap,
	} = _removeFieldsFromBlock(reportTypeBlockIds, reportTypeBlocksIdsMap, reportBlocksByIdMap, { ...newFieldsForDelete, ...fieldsForDelete });

	return removeOptions(
		newFieldsForDelete,
		newReportTypeBlockIds,
		newReportTypeBlocksByIdMap,
		newReportBlocksByIdMap,
		newReportFieldsByIdMap,
		newCalculationFieldsMap
	);
};

/**
 * Recursively updates calculation lookup map with new field value, or removes the field if it's type or operation type has changed
 * @param oldField old field object
 * @param newField new field object
 * @param reportTypeBlocks list of report type blocks to remove fields from
 * @param calculationFieldsMap map of calculated field Ids and their operand lookups
 */
export const updateOptions = (
	oldField: Nullable<ReportBlockFieldFormModel>,
	newField: ReportBlockFieldFormModel,
	reportTypeBlockIds: string[],
	reportTypeBlocksIdsMap: { [id: string]: ReportTypeBlockFormModel; },
	reportBlocksByIdMap: { [id: string]: ReportBlockFormModel; },
	reportFieldsByIdMap: { [id: string]: ReportBlockFieldFormModel; },
	calculationFieldsMap: { [id: string]: true; }
) => {
	// If update
	if (oldField) {
		// Update calculation lookups with new field
		const {
			newCalculationFieldsMap,
			newFieldsForDelete,
			newReportFieldsByIdMap,
		} = _updateLookups(oldField, newField, reportFieldsByIdMap, calculationFieldsMap);

		// Remove fields from Calculated blocks
		const {
			ids: newReportTypeBlockIds,
			typeBlocks: newReportTypeBlocksByIdMap,
			blocks: newReportBlocksByIdMap,
		} = _removeFieldsFromBlock(reportTypeBlockIds, reportTypeBlocksIdsMap, reportBlocksByIdMap, newFieldsForDelete);

		return removeOptions(
			newFieldsForDelete,
			newReportTypeBlockIds,
			newReportTypeBlocksByIdMap,
			newReportBlocksByIdMap,
			newReportFieldsByIdMap,
			newCalculationFieldsMap
		);
	}
	// If create just return blocks into state
	return _mapReportTypeBlocksToState(reportTypeBlockIds, reportTypeBlocksIdsMap, reportBlocksByIdMap, reportFieldsByIdMap, calculationFieldsMap);
};

/**
* Returns redux form field name of completion field
 * @param blockId id of field block
 * @param fieldId id of field
 * @returns string
 */
export const getCompletionFieldName = (blockId: string, fieldId: string) => `${BLOCK_PREFIX}#${blockId}.${FIELD_PREFIX}#${fieldId}`;
