import * as React from 'react';
import type { CustomRouteComponentProps } from 'react-router-dom';
import { compose } from 'redux';
import type { ConnectedProps } from 'react-redux';
import { connect } from 'react-redux';
import type { InjectedFormProps} from 'redux-form';
import { reduxForm, Field, FieldArray } from 'redux-form';
import { Form, Row, Col } from 'react-bootstrap';
import { Link } from 'react-router-dom';

import Textarea from 'af-fields/Textarea';

import SubmitButton from 'af-components/SubmitButton';
import Breadcrumbs from 'af-components/Breadcrumbs';

import * as AccountActions from 'af-actions/accounts';

import type { RootState } from 'af-reducers';

import { MEMBERS_INVITE_PERMISSIONS } from 'af-constants/reduxForms';
import CLIENT from 'af-constants/routes/client';

import type { BulkAccountRM } from 'ab-requestModels/account/upload.requestModel';

import { CSVBulkAccountVM } from 'ab-viewModels/account/upload.viewModel';

import type { OwnProps as MemberPermissionProps } from './MemberPermissions';
import MemberPermissions from './MemberPermissions';
import RejectedAccount from './RejectedAccount';

type OwnProps = CustomRouteComponentProps;

type FormProps = InjectedFormProps<BulkAccountRM>;

type Props = OwnProps & ConnectedProps<typeof connector> & FormProps;

class InviteCompanyMembers extends React.PureComponent<Props> {

	componentDidMount() {
		const {
			acceptedMembers,
			rejectedMembers,
			location: { state: { orgAlias } },
			companyName,
			history,
			initialize,
		} = this.props;

		if (!acceptedMembers.length && !rejectedMembers.length) {
			history.push(CLIENT.COMPANY.SETTINGS.MEMBERS.TABLE(orgAlias, companyName));
		} else {
			initialize(CSVBulkAccountVM.toRequestModel(acceptedMembers));
		}
	}

	submitRequest = async (form: BulkAccountRM) => {
		const { upload, location: { state: { orgAlias } }, companyName, history } = this.props;
		await upload(form);
		history.push(CLIENT.COMPANY.SETTINGS.MEMBERS.TABLE(orgAlias, companyName));
	};

	fieldComponent = ({ fields }) => {
		const { change } = this.props;

		return fields.map((_field: string, _index: number) => {
			return (
				<MemberPermissions
					account={fields.get(_index)}
					change={change}
					disabled={false}
					field={_field}
					index={_index}
					key={_index}
				/>
			);
		});
	};

	static renderRejectionReason = (account: CSVBulkAccountVM['invalid'][0], index: number) => {
		return (
			<RejectedAccount account={account} key={index} />
		);
	};

	renderRejectedMembers = () => {
		const { rejectedMembers } = this.props;

		if (!rejectedMembers?.length) {
			return null;
		}

		return (
			<div className="form-box user-upsert__rejected-members">
				<Row className="row--padded">
					<Col sm={24}>
						<span>
							Following rows have been ignored due to one or more issues.
							Highlighted fields are already being used or have been incorrectly provided.
						</span>
					</Col>
				</Row>
				<Row className="row--padded">
					<Col sm={24}>
						<table className="user-upsert__rejected-members-table">
							<tbody>
								{rejectedMembers.map(InviteCompanyMembers.renderRejectionReason)}
							</tbody>
						</table>
					</Col>
				</Row>
			</div>
		);
	};

	render() {
		const { handleSubmit, invalid, companyName, location: { state: { orgAlias } }, submitting, acceptedMembers } = this.props;

		return (
			<div className="form-segment">
				<Breadcrumbs
					items={
						[
							{ label: 'Users', url: CLIENT.COMPANY.SETTINGS.MEMBERS.TABLE(orgAlias, companyName) },
							{ label: 'Upload Users', url: CLIENT.COMPANY.SETTINGS.MEMBERS.TABLE(orgAlias, companyName) },
							{ label: 'Permissions' },
						]
					}
				/>
				{this.renderRejectedMembers()}
				<div className="form-box">
					<Form onSubmit={handleSubmit(this.submitRequest)}>
						<FieldArray<MemberPermissionProps>
							component={this.fieldComponent}
							name="accounts"
							props={{} as MemberPermissionProps}
						/>
						<Row className="row--padded-top">
							<Col sm={24}>
								<Field
									component={Textarea}
									label="Email Invite Message"
									name="message"
									showCharNum={true}
									type="text"
								/>
							</Col>
						</Row>
						<Row className="row--submit">
							<Link
								className="btn btn-info"
								to={CLIENT.COMPANY.SETTINGS.MEMBERS.TABLE(orgAlias, companyName)}
							>
								Cancel
							</Link>
							<SubmitButton
								disabled={invalid || submitting || !acceptedMembers.length}
								label="Submit"
								onClick={handleSubmit(this.submitRequest)}
								reduxFormSubmitting={submitting}
							/>
						</Row>
					</Form>
				</div>
			</div>
		);
	}

}

function mapStateToProps(state: RootState) {
	const { companyData } = state.user;
	if (!companyData) {
		throw new Error('User not logged in');
	}

	return {
		companyName: companyData.name,
		initialValues: state.company.acceptedMembers,
		acceptedMembers: state.company.acceptedMembers,
		rejectedMembers: state.company.rejectedMembers,
	};
}

function mapDispatchToProps() {
	return {
		upload: AccountActions.upload,
	};
}

const connector = connect(mapStateToProps, mapDispatchToProps());

const enhance = compose<React.ComponentClass<OwnProps>>(
	connector,
	reduxForm({ form: MEMBERS_INVITE_PERMISSIONS, pure: false })
);

export default enhance(InviteCompanyMembers);
