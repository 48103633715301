import * as React from 'react';
import { connect } from 'react-redux';

import NotificationStatusEnum from 'acceligent-shared/enums/notificationStatus';

import type EmployeeNotificationStatusEnum from 'ab-enums/employeeNotificationStatus.enum';

import type { RootState } from 'af-reducers';

import type ScheduleBoardEmployee from 'ab-viewModels/scheduleBoardEmployee.viewModel';
import type { DisplayViewEmployeeViewModel } from 'ab-viewModels/scheduleBoardDisplayView.viewModel';
import type { NotificationStatusViewModel } from 'ab-viewModels/notification.viewModel';

import AvailableEmployeeGroup from './EmployeeGroup/AvailableEmployeeGroup';
import UnavailableEmployeeGroup from './EmployeeGroup/UnavailableEmployeeGroup';

import * as ScheduleBoardUtils from 'ab-utils/scheduleBoard.util';

interface EmployeeGroup {
	groupId: number;
	statusName: Nullable<string>;
	isDeleted: boolean;
	employeeList: DisplayViewEmployeeViewModel[];
}

interface OwnProps {
	dueDate: string;
}

interface StateProps {
	availableEmployeeGroups: EmployeeGroup[];
	unavailableEmployeeGroups: EmployeeGroup[];
}

type Props = OwnProps & StateProps;

class LaborSection extends React.PureComponent<Props> {
	render() {
		const { availableEmployeeGroups, unavailableEmployeeGroups } = this.props;

		return (
			<div className="display-view-labor-section-wrapper">
				<div className="display-view-labor-section">
					<span className="display-view-section-title">LABOR (AVAILABLE)</span>
					<div className="display-view-groups-wrapper">
						{
							availableEmployeeGroups && availableEmployeeGroups.length > 0 && availableEmployeeGroups.map((_employeeGroup) =>
								_employeeGroup.isDeleted && !_employeeGroup.employeeList.length ? null : (
									<AvailableEmployeeGroup
										employeeList={_employeeGroup.employeeList}
										key={_employeeGroup.groupId}
										status={_employeeGroup.statusName}
									/>
								))
						}
					</div>
					<span className="display-view-section-title">LABOR (UNAVAILABLE)</span>
					<div className="display-view-groups-wrapper">
						{
							unavailableEmployeeGroups && unavailableEmployeeGroups.length > 0 && unavailableEmployeeGroups.map((_employeeGroup) =>
								_employeeGroup.isDeleted && !_employeeGroup.employeeList.length ? null : (
									<UnavailableEmployeeGroup
										employeeList={_employeeGroup.employeeList}
										key={_employeeGroup.groupId}
										status={_employeeGroup.statusName}
									/>
								))
						}
					</div>
				</div>
			</div>
		);
	}
}

function getToolbarEmployeeNotificationStatusForEmployee(
	employee: ScheduleBoardEmployee,
	employeeEmailStatusDictionary: Nullable<NotificationStatusViewModel>,
	showNotifyAvailableEmployees: boolean
): EmployeeNotificationStatusEnum | undefined {
	const { email, phoneNumber } = employee;

	const emailNotification = employeeEmailStatusDictionary?.emailStatus;
	const smsNotification = employeeEmailStatusDictionary?.smsStatus;
	const emailNotificationSentAt = employeeEmailStatusDictionary?.emailSentAt;
	const smsNotificationSentAt = employeeEmailStatusDictionary?.smsSentAt;
	const isPreviousNotification = employeeEmailStatusDictionary?.isPreviousRevision;

	const emailNotificationSent = email && emailNotification && emailNotification !== NotificationStatusEnum.SCHEDULED;
	const smsNotificationSent = phoneNumber && smsNotification && smsNotification !== NotificationStatusEnum.SCHEDULED;

	const toolbarNotificationSent = !!emailNotificationSent || !!smsNotificationSent;

	if (!showNotifyAvailableEmployees || !toolbarNotificationSent || employee.account.assignableAsSuperintendent) {
		return undefined;
	}

	return ScheduleBoardUtils.getEmployeeNotificationStatus(
		emailNotification ?? null,
		smsNotification ?? null,
		emailNotificationSentAt ?? null,
		smsNotificationSentAt ?? null,
		isPreviousNotification ?? undefined
	);
}

function mapStateToProps(state: RootState, ownProps: OwnProps): StateProps {
	const { dueDate } = ownProps;
	const { company } = state.company;

	if (!state.scheduleBoard.workOrdersByDateDictionary[dueDate]) {
		return {
			availableEmployeeGroups: [],
			unavailableEmployeeGroups: [],
		};
	}

	const workOrdersOnDateDict = state.scheduleBoard.workOrdersByDateDictionary[dueDate];

	if (!state.scheduleBoard.employees || !state.scheduleBoard.toolbarEmployeeGroupTitles) {
		throw new Error('SB equipment toolbar not defined');
	}

	const employeeEmailStatusDictionary = workOrdersOnDateDict?.availableNotificationStatuses ?? {};
	const showNotifyAvailableEmployees: boolean = company?.notification?.isEnabled ?? false;

	const availableEmployeeGroups = state.scheduleBoard.toolbarEmployeeGroupTitles.available.map(
		({ id: _empStatusId, title: _empStatusName, isDeleted }): EmployeeGroup => {
			const employeeList: DisplayViewEmployeeViewModel[] = [];
			const listOfEmployeeIdForStatusName = workOrdersOnDateDict?.toolbarEmployees?.available?.[_empStatusId] ?? [];
			for (const employeeId of listOfEmployeeIdForStatusName) {
				const employee = state.scheduleBoard.employees![employeeId];

				if (employee.showOnScheduleBoard) {
					employeeList.push({
						employeeId: employee.id,
						duplicateDisplayNameExists: employee.duplicateDisplayNameExists,
						firstName: employee.firstName,
						lastName: employee.lastName,
						account: employee.account,
						skills: employee.skills,
						cdlStatus: employee.cdlStatus,
						officeColor: employee?.office?.color,
						notificationStatus: getToolbarEmployeeNotificationStatusForEmployee(
							employee,
							employeeEmailStatusDictionary[employee.id],
							showNotifyAvailableEmployees
						) ?? null,
						numberOfAssignments: 0,
					});
				}
			}

			return { groupId: _empStatusId, statusName: _empStatusName, isDeleted, employeeList };
		}
	);

	const unavailableEmployeeGroups = state.scheduleBoard.toolbarEmployeeGroupTitles.unavailable.map(
		({ id: _empStatusId, title: _empStatusName, isDeleted }): EmployeeGroup => {
			const employeeList: DisplayViewEmployeeViewModel[] = [];
			const listOfEmployeeIdForStatusName = workOrdersOnDateDict?.toolbarEmployees?.unavailable?.[_empStatusId] ?? [];

			for (const employeeId of listOfEmployeeIdForStatusName) {
				const employee = state.scheduleBoard.employees![employeeId];

				if (employee.showOnScheduleBoard) {
					employeeList.push({
						employeeId: employee.id,
						duplicateDisplayNameExists: employee.duplicateDisplayNameExists,
						firstName: employee.firstName,
						lastName: employee.lastName,
						account: employee.account,
						skills: employee.skills,
						cdlStatus: employee.cdlStatus,
						officeColor: employee?.office?.color,
						notificationStatus: getToolbarEmployeeNotificationStatusForEmployee(
							employee,
							employeeEmailStatusDictionary[employee.id],
							showNotifyAvailableEmployees
						) ?? null,
						unavailabilityReason: workOrdersOnDateDict?.employeeUnavailabilityReason?.[employeeId]?.name ?? 'N/A',
						returnDate: workOrdersOnDateDict?.employeeReturnDate?.[employeeId] ?? 'N/A',
						numberOfAssignments: 0,
					});
				}
			}

			return { groupId: _empStatusId, statusName: _empStatusName, isDeleted, employeeList };
		}
	);

	// FIXME: remove calculation from mapStateToProps
	return {
		availableEmployeeGroups,
		unavailableEmployeeGroups,
	};
}

export default connect<StateProps, null, OwnProps>(mapStateToProps)(LaborSection);
