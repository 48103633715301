import * as React from 'react';
import type { CustomRouteComponentProps } from 'react-router-dom';
import type { ConnectedProps } from 'react-redux';
import { connect } from 'react-redux';

import * as SkillActions from 'af-actions/skill';

import type { SkillViewModel, GroupedSkills } from 'ab-viewModels/skill.viewModel';

import type { SkillRequestModel } from 'ab-requestModels/skill.requestModel';

import Loading from './Loading';
import SkillItem from '../SkillItem';
import SkillCreate from '../SkillCreate';

import Breadcrumbs from 'af-components/Breadcrumbs';

import { bemElement, bemBlock } from 'ab-utils/bem.util';

type OwnProps = CustomRouteComponentProps;

type Props = OwnProps & ConnectedProps<typeof connector>;

interface State {
	addingSkill: boolean;
	editingId: Nullable<number>;
	skills: Nullable<GroupedSkills>;
}

class Skills extends React.PureComponent<Props, State> {
	state: State = {
		addingSkill: false,
		editingId: null,
		skills: null,
	};

	async componentDidMount() {
		await this.load();
	}

	toggleAddingSkill = () => this.setState(() => ({ addingSkill: !this.state.addingSkill }));

	toggleEditingSkill = (id: number) => this.setState(() => ({ editingId: id }));

	load = async () => {
		const { findAllForCompany } = this.props;
		const skills = await findAllForCompany();
		this.setState(() => ({ skills }));
	};

	create = async (form: SkillRequestModel) => {
		const { createSkill } = this.props;
		await createSkill(form);
		await this.load();
	};

	update = async (skillId: number, form: SkillRequestModel) => {
		const { updateSkill } = this.props;
		await updateSkill(skillId, form);
		await this.load();
	};

	remove = async (skillId: number) => {
		const { deleteSkill } = this.props;
		await deleteSkill(skillId);
		await this.load();
	};

	render() {
		const { addingSkill, editingId, skills } = this.state;

		if (!skills) {
			return <Loading />;
		}

		const filteredSkillKeys = Object.keys(skills).filter((_ct) => !!skills[_ct].length);

		return (
			<div className="form-segment resources-pilled">
				<Breadcrumbs items={[{ label: 'Skills' }]} />
				<div className={bemBlock('form-box', ['full-padded'])}>
					{!filteredSkillKeys.length
						? <div className={bemElement('resources-pilled', 'empty')}>There are no skills available.</div>
						: <div className={bemElement('resources-pilled', 'list')}>
							{filteredSkillKeys.map((color, _index) =>
								<div
									className={bemElement('resources-pilled', 'color-list')}
									key={_index}
								>
									{skills[color].map((_skill: SkillViewModel, _i) => (
										<SkillItem
											deleteSkill={this.remove}
											editingId={editingId}
											index={_index}
											key={`skill_${color}-${_i}`}
											skill={_skill}
											toggleEditingSkill={this.toggleEditingSkill}
											updateSkill={this.update}
										/>
									))}
								</div>
							)}
						</div>
					}
					<div className={bemElement('resources-pilled', 'addition')}>
						{
							addingSkill ?
								<SkillCreate {...this.props} close={this.toggleAddingSkill} createSkill={this.create} /> :
								<span className={bemBlock('btn', ['link'])} onClick={this.toggleAddingSkill}>
									<span className="icon-plus" />
									<span>Add Skill</span>
								</span>
						}
					</div>
				</div>
			</div>
		);
	}
}

function mapDispatchToProps() {
	return {
		findAllForCompany: SkillActions.findAllForCompany,
		createSkill: SkillActions.create,
		updateSkill: SkillActions.update,
		deleteSkill: SkillActions.deleteSkill,
	};
}

const connector = connect(null, mapDispatchToProps());

export default connector<React.ComponentClass<OwnProps>>(Skills);
