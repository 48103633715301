import * as React from 'react';

import WorkOrderReviewStatus from 'acceligent-shared/enums/workOrderReviewStatus';

import ConfirmationModal from 'af-components/ConfirmationModal';

import SignatureModal from '../SignatureModal';

import SuccessModalBody from '../../FieldReportsList/Modals/Shared/SuccessModalBody';

import type SignatureForm from '../../Shared/SignatureModal/FormModel';

interface OwnProps {
	closeModal: () => void;
	onSubmit: (form: SignatureForm) => Promise<void>;
	isReadOnly: boolean;
	showModal: boolean;
	unsignedTimeSheets: string[];
	descriptionClassName?: string;
	isDigitalSignatureEnabled: boolean;
}

type Props = OwnProps;

interface State {
	showSignatureModal: boolean;
	showSuccessModal: boolean;
}

class ApproveModalHandler extends React.PureComponent<Props, State> {

	state: State = {
		showSignatureModal: this.props.showModal,
		showSuccessModal: false,
	};

	static MODAL_TITLE = 'Approve Report and Time Sheets';
	static APPROVE_TITLE = 'Field Reports Approved';
	static APPROVE_DESCRIPTION = 'Report has been successfully approved';
	static SIGNATURE_MODAL_DESCRIPTION = 'By signing, You acknowledge that You have reviewed the information provided in this Report, and that you approve its content. Report will be automatically sent to the next level for approval.';

	componentDidUpdate(prevProps: Props) {
		const { showModal } = this.props;
		if (prevProps.showModal !== showModal) {
			this.setState(() => ({ showSignatureModal: showModal, showSuccessModal: false }));
		}
	}

	approve = async (form: SignatureForm) => {
		const { onSubmit } = this.props;

		await onSubmit(form);

		this.setState(() => ({ showSignatureModal: false, showSuccessModal: true }));
	};

	onSuccessModalConfirm = () => {
		const { closeModal } = this.props;

		closeModal();
		this.setState(() => ({ showSuccessModal: false }));
	};

	renderSuccessModalBody = () => {
		const { isReadOnly } = this.props;

		return (
			<SuccessModalBody
				description={ApproveModalHandler.APPROVE_DESCRIPTION}
				isReadOnly={isReadOnly}
				reviewStatus={WorkOrderReviewStatus.APPROVED}
				title={ApproveModalHandler.APPROVE_TITLE}
			/>
		);
	};

	renderSignatureModalBody = () => {
		const { unsignedTimeSheets } = this.props;

		return unsignedTimeSheets.length ?
			`${ApproveModalHandler.SIGNATURE_MODAL_DESCRIPTION}\n\nSignature will be applied on the rejected Time Sheets for:\n\t${unsignedTimeSheets.join('\n\t')}`
			: ApproveModalHandler.SIGNATURE_MODAL_DESCRIPTION;
	};

	render() {
		const { closeModal, descriptionClassName, isDigitalSignatureEnabled } = this.props;
		const { showSignatureModal, showSuccessModal } = this.state;

		return (
			<>
				<SignatureModal
					closeModal={closeModal}
					description={this.renderSignatureModalBody()}
					descriptionClassName={descriptionClassName}
					onSubmit={this.approve}
					showDigitalSignature={isDigitalSignatureEnabled}
					showModal={showSignatureModal}
					showNameInput={false}
					title={ApproveModalHandler.MODAL_TITLE}

				/>
				<ConfirmationModal
					body={this.renderSuccessModalBody()}
					closeModal={this.onSuccessModalConfirm}
					confirmAction={this.onSuccessModalConfirm}
					confirmText="Done"
					modalStyle="info"
					showCancel={false}
					showModal={showSuccessModal}
					size="md"
					title={ApproveModalHandler.MODAL_TITLE}
				/>
			</>
		);
	}
}

export default ApproveModalHandler;
