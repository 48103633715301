import * as React from 'react';

import * as TimeUtils from 'acceligent-shared/utils/time';
import MemberInviteEnum from 'acceligent-shared/enums/memberInvite';

import { LOGIN_LOCKOUT_TIME } from 'ab-common/constants/value';

import Tooltip from 'af-components/Tooltip';

interface Props {
	isLocked: Nullable<boolean>;
	lastFailedPasswordLoginAttemptAt: Nullable<Date>;
	status: MemberInviteEnum;
}

const StatusCell = (props: Props) => {
	const { isLocked, lastFailedPasswordLoginAttemptAt, status } = props;
	const [, setReload] = React.useState(0);
	const timeNow = new Date();

	React.useEffect(() => {
		const interval = setInterval(() => setReload((reload) => reload + 1), 30000);
		return () => clearInterval(interval);
	  }, []);

	if (isLocked && lastFailedPasswordLoginAttemptAt && LOGIN_LOCKOUT_TIME - TimeUtils.getDiff(timeNow, lastFailedPasswordLoginAttemptAt, 'minutes') > 0) {
		const lockoutCountdown = LOGIN_LOCKOUT_TIME - TimeUtils.getDiff(timeNow, lastFailedPasswordLoginAttemptAt, 'minutes');
		const lockedTooltip = <div>
			Due to too many incorrect login attempts
			User's account has been temporarily locked.
			Locked User will be able to regain access in <b>{lockoutCountdown} minutes</b>.
		</div>;
		return  <Tooltip message={lockedTooltip} ><span className="text-red">Locked</span></Tooltip>;
	} else {
		switch (status) {
			case MemberInviteEnum.ACTIVE:
				return <span className="text-green">Active</span>;
			case MemberInviteEnum.PENDING:
				return <span className="text-orange">Pending</span>;
			case MemberInviteEnum.INACTIVE:
			default:
				return <span className="text-grey">Inactive</span>;
		}
	}
};

export default StatusCell;

