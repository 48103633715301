import type { Dispatch, AnyAction } from 'redux';

import type { TableContent } from 'ab-common/dataStructures/tableContent';

import type ReportTypeStatus from 'acceligent-shared/enums/reportTypeStatus';

import API from 'af-constants/routes/api';

import type { GetRootState } from 'af-reducers';

import type { ReportTypeVM } from 'ab-viewModels/reportType/reportType.viewModel';
import type { ReportTypeListVM } from 'ab-viewModels/reportType/reportTypeList.viewModel';
import type { CustomReportTypeTableRowVM } from 'ab-viewModels/reportType/reportTypeTable.viewModel';

import type { TableQuery } from 'ab-common/dataStructures/tableQuery';

import type { ReportTypeRM } from 'ab-requestModels/reportType/reportType.requestModel';

import { http } from 'af-utils/http.util';
import { errorHandler } from 'af-utils/actions.util';

export function findTable(tableRequestModel: TableQuery) {
	return async (dispatch: Dispatch<AnyAction>, getState: GetRootState, { redirectTo }) => {
		const action = async () => {
			return await http.get<TableContent<CustomReportTypeTableRowVM>>(API.V1.COMPANY.REPORT_TYPE.FIND_TABLE(tableRequestModel));
		};
		return await errorHandler(action, dispatch, redirectTo);
	};
}

export function findCustomShallowActiveList() {
	return async (dispatch: Dispatch<AnyAction>, getState: GetRootState, { redirectTo }) => {
		const action = async () => {
			return await http.get<ReportTypeListVM>(API.V1.COMPANY.REPORT_TYPE.FIND_SHALLOW_ACTIVE_LIST);
		};
		return await errorHandler(action, dispatch, redirectTo);
	};
}

export function findRequiredShallowActiveList() {
	return async (dispatch: Dispatch<AnyAction>, getState: GetRootState, { redirectTo }) => {
		const action = async () => {
			return await http.get<ReportTypeListVM>(API.V1.COMPANY.REPORT_TYPE.FIND_SHALLOW_ACTIVE_REQUIRED_LIST);
		};
		return await errorHandler(action, dispatch, redirectTo);
	};
}

export function findCustomById(id: number) {
	return async (dispatch: Dispatch<AnyAction>, getState: GetRootState, { redirectTo }) => {
		const action = async () => {
			return await http.get<ReportTypeVM>(API.V1.COMPANY.REPORT_TYPE.FIND_BY_ID(id));
		};
		return await errorHandler(action, dispatch, redirectTo);
	};
}

export function createCustom(form: ReportTypeRM) {
	return async (dispatch: Dispatch<AnyAction>, getState: GetRootState, { redirectTo }) => {
		const action = async () => {
			await http.post(API.V1.COMPANY.REPORT_TYPE.CREATE, form);
		};
		return await errorHandler(action, dispatch, redirectTo);
	};
}

export function editCustom(id: number, form: ReportTypeRM) {
	return async (dispatch: Dispatch<AnyAction>, getState: GetRootState, { redirectTo }) => {
		const action = async () => {
			await http.put(API.V1.COMPANY.REPORT_TYPE.EDIT(id), form);
		};
		return await errorHandler(action, dispatch, redirectTo);
	};
}

export function editCustomStatus(id: number, status: ReportTypeStatus) {
	return async (dispatch: Dispatch<AnyAction>, getState: GetRootState, { redirectTo }) => {
		const action = async () => {
			await http.put(API.V1.COMPANY.REPORT_TYPE.EDIT_STATUS(id), { status });
		};
		return await errorHandler(action, dispatch, redirectTo);
	};
}

export function deleteCustom(id: number) {
	return async (dispatch: Dispatch<AnyAction>, getState: GetRootState, { redirectTo }) => {
		const action = async () => {
			await http.delete(API.V1.COMPANY.REPORT_TYPE.DELETE(id));
		};
		return await errorHandler(action, dispatch, redirectTo);
	};
}
