import type * as TimeUtils from 'acceligent-shared/utils/time';

import { MomentDayOfWeekEnum } from 'acceligent-shared/enums/weekDay';

import type { WorkDayViewModel } from 'ab-viewModels/workDay/workDay.viewModel';
import type { TreeViewModel } from 'ab-viewModels/equipmentCostTree.viewModel';

import EquipmentCostTreeNodeLabel from 'ab-enums/equipmentCostTreeNodeLabel.enum';

type MomentType = ReturnType<typeof TimeUtils.normalizeDateToMoment>;

const _getEquipmentCostTreeNodeLabel = (element: TreeViewModel): EquipmentCostTreeNodeLabel => {
	if (element.isEquipmentCostCategory) {
		return EquipmentCostTreeNodeLabel.CATEGORY;
	} else if (element.isEquipmentCostGroup) {
		return EquipmentCostTreeNodeLabel.GROUP;
	} else if (element.isEquipmentCostType) {
		return EquipmentCostTreeNodeLabel.TYPE;
	} else {
		return EquipmentCostTreeNodeLabel.EQUIPMENT_COST;
	}
};

type ParsedEquipmentCostTreeNodeData = {
	label: EquipmentCostTreeNodeLabel;
	id: number;
	nodeId: number;
};

export const generateEquipmentCostTreeNodeId = (element: TreeViewModel): string => {
	const label = _getEquipmentCostTreeNodeLabel(element);
	return `${label}#${element._id}#${element.nodeId}`;
};

export const parseEquipmentCosTreeNodeId = (generatedId: string): ParsedEquipmentCostTreeNodeData => {
	const chunks = generatedId.split('#');
	const [label, id, nodeId] = chunks as [EquipmentCostTreeNodeLabel, number, number];
	return { label, id: +id, nodeId: +nodeId };
};

export const getWorkingWeekStartAndEnd = (workDays: WorkDayViewModel[], date: MomentType) => {
	const { start, end } = workDays.reduce((_acc, _wd: WorkDayViewModel) => {
		const _weekDay = MomentDayOfWeekEnum[_wd.weekDay];
		if (_weekDay < _acc.start) {
			_acc.start = _weekDay;
		} else if (_weekDay > _acc.end) {
			_acc.end = _weekDay;
		}
		return _acc;
	}, { start: 6, end: 0 } as { start: number; end: number; });
	return {
		workingWeekStart: date?.clone()?.day(start),
		workingWeekEnd: date?.clone()?.day(end),
	};
};

