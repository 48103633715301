import * as actionTypes from 'af-constants/actionTypes';

import type OrganizationPublicDetailsViewModel from 'ab-viewModels/organizationPublicDetails.viewModel';

export function ORGANIZATION_PUBLIC(payload: OrganizationPublicDetailsViewModel): OrganizationPublicDetails {
	return {
		type: actionTypes.ORGANIZATION_PUBLIC,
		payload,
	};
}

export interface OrganizationPublicDetails {
	type: typeof actionTypes.ORGANIZATION_PUBLIC;
	payload: OrganizationPublicDetailsViewModel;
}

export function MULTIPLE_ORGANIZATION_PUBLIC(payload: OrganizationPublicDetailsViewModel[]): MultipleOrganizationPublicDetails {
	return {
		type: actionTypes.MULTIPLE_ORGANIZATION_PUBLIC,
		payload,
	};
}
export interface MultipleOrganizationPublicDetails {
	type: typeof actionTypes.MULTIPLE_ORGANIZATION_PUBLIC;
	payload: OrganizationPublicDetailsViewModel[];
}

export type OrganizationAction = OrganizationPublicDetails | MultipleOrganizationPublicDetails;

