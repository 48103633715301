import * as React from 'react';

import type { useScheduleBoardModals } from 'af-root/hooks/useScheduleBoardModal';

import { ScheduleBoardModalContext, ScheduleBoardModalContextProvider } from 'af-root/context/scheduleBoardModalContext';

export default function withScheduleBoardModals<P extends object>(
	WrappedComponent: React.ComponentType<P & { scheduleBoardModals: ReturnType<typeof useScheduleBoardModals>; }>
): React.FC<P> {
	const WithScheduleBoard: React.FC<P> = (props: P) => {
		return (
			<ScheduleBoardModalContextProvider>
				<InnerComponent {...props} />
			</ScheduleBoardModalContextProvider>
		);
	};

	const InnerComponent: React.FC<P> = (props: P) => {
		const scheduleBoardModals = React.useContext(ScheduleBoardModalContext);

		return <WrappedComponent scheduleBoardModals={scheduleBoardModals} {...props as P} />;
	};

	return WithScheduleBoard;
}
