import * as React from 'react';
import { compose } from 'redux';
import { Dropdown } from 'react-bootstrap';
import type { ResolveThunks } from 'react-redux';
import { connect } from 'react-redux';
import { useLocation, useNavigate } from 'react-router-dom-v5-compat';

import CompaniesList from 'af-components/Header/HeaderCompanies/CompaniesList';
import OrganizationItem from 'af-components/Header/HeaderCompanies/OrganizationItem';

import CLIENT from 'af-constants/routes/client';

import type { RootState } from 'af-reducers';

import * as ScheduleBoardActions from 'af-actions/scheduleBoard';

import type * as User from 'ab-viewModels/user.viewModel';

import PagePermissions from 'ab-enums/pagePermissions.enum';

import { hasAnyStringPermission } from 'ab-utils/auth.util';

interface LogoData {
	mainIconStyle: React.CSSProperties;
	logoContent: string;
}

interface OwnProps {
	isCompanyContext: boolean;
	isPlatformAdminContext: boolean;
}

type ConnectOwnProps = OwnProps;

interface StateProps {
	userData: User.UserData;
	organizationData: User.OrganizationData;
	/** Nullable only in platform and organization level pages */
	companyData: Nullable<User.CompanyData>;
}

interface DispatchProps {
	reloadScheduleBoardResources: typeof ScheduleBoardActions.reloadScheduleBoardResources;
}

type Props = ConnectOwnProps & StateProps & ResolveThunks<DispatchProps>;

const HeaderCompanies: React.FC<Props> = (props) => {

	const navigate = useNavigate();
	const location = useLocation();

	const {
		companyData,
		userData,
		organizationData,
		isCompanyContext,
		isPlatformAdminContext,
		reloadScheduleBoardResources,
	} = props;

	const [isOpen, setIsOpen] = React.useState(false);

	const onToggle = React.useCallback((_isOpen: boolean) => {
		setIsOpen(_isOpen);
	}, []);

	const onClose = React.useCallback(() => {
		setIsOpen(false);
	}, []);

	const logOut = React.useCallback(() => {
		navigate(CLIENT.AUTH.LOGOUT(location.state.orgAlias));
	}, [location.state, navigate]);

	const createCompany = React.useCallback(() => {
		reloadScheduleBoardResources();
		navigate(CLIENT.COMPANY.CREATE(location.state.orgAlias));
	}, [location.state.orgAlias, navigate, reloadScheduleBoardResources]);

	const logoData: LogoData = React.useMemo(() => {
		const mainIconStyle: React.CSSProperties = {};

		let logoContent = '';
		if (isPlatformAdminContext) {
			const src = '/images/logo/ic_logo_symbol.svg';
			mainIconStyle.background = `#1C1E21 url('${src}') 9px center no-repeat`;
		} else if (!isCompanyContext) {
			const src = '/images/elements/ic_company_big.svg';
			mainIconStyle.background = `#F49904 url('${src}') center 9px no-repeat`;
		} else if (companyData?.logo) {
			mainIconStyle.background = `#1C1E21 url('${companyData.logo}') center center / cover no-repeat`;
		} else if (companyData?.name) {
			logoContent = companyData.name[0].toUpperCase();
		}

		return { mainIconStyle, logoContent };

	}, [companyData, isCompanyContext, isPlatformAdminContext]);

	const canCreateNewCompany: boolean = React.useMemo(() => {
		return organizationData &&
			hasAnyStringPermission(PagePermissions.COMPANY.CREATE, companyData?.permissions ?? [], companyData?.isCompanyAdmin ?? false, userData.role) &&
			!isPlatformAdminContext;
	}, [companyData?.isCompanyAdmin, companyData?.permissions, isPlatformAdminContext, organizationData, userData.role]);

	const toggleLogoClassName = React.useMemo(() => {
		const _toggleLogoClassName = 'main-header__companies-dropdown__toggle__logo';
		return logoData.logoContent ? `${_toggleLogoClassName} main-header__companies-dropdown__toggle__logo--letter` : _toggleLogoClassName;
	}, [logoData.logoContent]);

	return (
		<div className="main-header__companies-dropdown">
			<Dropdown
				id="accqa__companies-dropdown"
				onToggle={onToggle}
				show={isOpen}
			>
				<Dropdown.Toggle className="main-header__companies-dropdown__toggle">
					<div
						className={toggleLogoClassName}
						style={logoData.mainIconStyle}
					>
						{logoData.logoContent}
					</div>
					<div className="main-header__companies-dropdown__toggle__data">
						{isCompanyContext && companyData &&
							<span className="main-header__companies-dropdown__toggle__data__company" title={companyData.name}>{companyData.name}</span>
						}
						<span className="main-header__companies-dropdown__toggle__data__user">{userData.firstName} {userData.lastName}</span>
					</div>
				</Dropdown.Toggle>
				<Dropdown.Menu className="main-header__companies-dropdown__menu">
					<OrganizationItem
						isPlatformAdminContext={isPlatformAdminContext}
						onClose={onClose}
						organizationData={organizationData}
					/>
					<hr />
					<CompaniesList
						onClose={onClose}
					/>
					<hr />
					{
						canCreateNewCompany &&
						<Dropdown.Item className="main-header__companies-dropdown__menu__item" onClick={createCompany}>
							<span className="icon-plus" />
							<span>
								Add new company
							</span>
						</Dropdown.Item>
					}
					{canCreateNewCompany && <hr />}
					<Dropdown.Item className="main-header__companies-dropdown__menu__item" onClick={logOut}>
						<span className="icon-exit_to_app" />
						<span>
							Sign Out
						</span>
					</Dropdown.Item>
				</Dropdown.Menu>
			</Dropdown>
		</div>
	);
};

function mapStateToProps(state: RootState): StateProps {
	const { userData, companyData, organizationData } = state.user;
	if (!userData || !organizationData) {
		throw new Error('User not logged in');
	}

	return {
		userData,
		organizationData,
		companyData,
	};
}

function mapDispatchToProps(): DispatchProps {
	return {
		reloadScheduleBoardResources: ScheduleBoardActions.reloadScheduleBoardResources,
	};
}

const enhance = compose<React.ComponentType<OwnProps>>(
	connect<StateProps, DispatchProps, ConnectOwnProps>(mapStateToProps, mapDispatchToProps())
);

export default enhance(HeaderCompanies);
