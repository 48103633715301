import * as React from 'react';

import type { PreviewVM } from 'ab-viewModels/account/preview.viewModel';

import Pill from 'af-components/Pills/Pill';
import Tooltip from 'af-components/Tooltip';

interface OwnProps {
	removeAccount: (index: number) => void;
	index: number;
	account: PreviewVM;
	willReceiveNotification: boolean;
	hasEmail: boolean;
}

type Props = OwnProps;

class AccountPreview extends React.PureComponent<Props> {
	removeAccount = () => {
		const { removeAccount, index } = this.props;
		removeAccount(index);
	};

	render() {
		const { account, willReceiveNotification, hasEmail } = this.props;

		const body = (
			<Pill
				isTransparent={!willReceiveNotification}
				item={account}
				labelKey="fullName"
				removeItem={this.removeAccount}
				skipConfirmationModal={true}
			/>
		);

		if (willReceiveNotification) {
			return body;
		}

		return (
			<Tooltip
				className="tooltip--center-text"
				message={`${account.fullName} won't be notified (missing ${!hasEmail ? 'email' : 'mobile phone'})`}
				placement="top"
			>
				<div>{body}</div>
			</Tooltip>
		);
	}
}

export default AccountPreview;
