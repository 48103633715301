import { stateAbbreviation } from 'ab-enums/states.enum';

import type PrevailingWagesTableBase from 'ab-domain/views/prevailingWagesTable/base';

interface ClassificationCodeMapVM {
	[code: string]: string[];
}

export class PrevailingWagesTableRowVM {
	jobCode: string;
	jobState: Nullable<string>;
	classificationCodeMap: ClassificationCodeMapVM;
	/** Will always be one element for exception report */
	columns: string[];

	constructor(row: PrevailingWagesTableBase, columns: string[]) {
		this.jobCode = `${row.jobCode} ${stateAbbreviation[row.locationState] ?? ''}`;
		this.jobState = stateAbbreviation[row.jobState];
		this.classificationCodeMap = row.classificationCodeMap;
		this.columns = columns;
	}

	static bulkConstructor(rows: PrevailingWagesTableBase[], columns: string[]) {
		return rows.map((_row) => new PrevailingWagesTableRowVM(_row, columns));
	}

	static toCSVData(data: PrevailingWagesTableRowVM[]): Nullable<string>[][] {
		const classificationCodes = data[0]?.columns ?? [];
		const header = ['Job Id', 'State', ...classificationCodes, ...classificationCodes];

		const rows = data?.reduce((_acc, _item) => {
			let classificationCodeEmployeeCountMax = 0;
			for (let _index = 0; _index < classificationCodes.length; _index++) {
				const _employeesForClassificationCode = _item.classificationCodeMap?.[classificationCodes[_index]] ?? [];
				if (classificationCodeEmployeeCountMax < _employeesForClassificationCode.length) {
					classificationCodeEmployeeCountMax = _employeesForClassificationCode.length;
				}
			}
			for (let _index = 0; _index < classificationCodeEmployeeCountMax; _index++) {
				const _row = _index ? ['', ''] : [_item.jobCode, _item.jobState];
				for (let _ccIndex = 0; _ccIndex < classificationCodes.length; _ccIndex++) {
					const _employeesForClassificationCode = _item.classificationCodeMap?.[classificationCodes[_ccIndex]] ?? [];
					_row.push(_employeesForClassificationCode[_index] ?? '');
				}
				_acc.push(_row);
			}
			return _acc;
		}, [] as Nullable<string>[][]);

		return [header, ...rows];
	}
}
