import * as React from 'react';

import type { WorkOrderResourceLookupModel } from 'ab-viewModels/scheduleBoardWorkOrderModal.viewModel';

import Tooltip from 'af-components/Tooltip';
import ColorSquare from 'af-components/ColorSquare';

interface Props {
	resourceLookup: WorkOrderResourceLookupModel;
	removed: boolean;
}

class EquipmentPlaceholderItem extends React.PureComponent<Props> {
	render() {
		const { resourceLookup, removed } = this.props;
		const { name, color } = resourceLookup;

		return (
			removed ? (
				<Tooltip message="Removed" placement="right">
					<div className="work-order-info-modal__resource-item work-order-info-modal__resource-item--placeholder work-order-info-modal__resource-item--removed">
						<div>{name}</div>
						<ColorSquare color={color} />
					</div>
				</Tooltip>
			) : (
				<div className="work-order-info-modal__resource-item work-order-info-modal__resource-item--placeholder">
					<div>{name}</div>
					<ColorSquare color={color} />
				</div>
			)
		);
	}
}

export default EquipmentPlaceholderItem;
