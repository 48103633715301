import * as React from 'react';
import { Draggable } from 'react-beautiful-dnd';
import { connect } from 'react-redux';

import type { RootState } from 'af-reducers';
import { REMOVE_BLANK_WORK_ORDER } from 'af-constants/reduxForms';

import PlaceholderCard from 'af-root/scenes/Company/ScheduleBoard/Shared/Card/PlaceholderCard';

interface OwnProps {
	index: number;
	draggableId?: string;
	dueDate?: string;
	isDragAndDropDisabled: boolean;
	hasPermissionsToEditScheduleBoard: boolean;
	isPlaceholder?: boolean;
	removeBlankWorkOrder?: (dueDate: string, index: number) => Promise<void>;
}

interface StateProps {
	removeEnabled: boolean;
}

type Props = OwnProps & StateProps;

class BlankCard extends React.PureComponent<Props> {
	static defaultProps: Partial<Props> = {
		isPlaceholder: false,
		isDragAndDropDisabled: false,
		hasPermissionsToEditScheduleBoard: true,
	};

	removeBlankColumn = async () => {
		const { removeBlankWorkOrder, dueDate, index, removeEnabled } = this.props;

		if (!removeEnabled || !dueDate || !removeBlankWorkOrder) {
			return;
		}
		await removeBlankWorkOrder(dueDate, index);
	};

	render() {
		const { isDragAndDropDisabled, hasPermissionsToEditScheduleBoard, index, draggableId, removeEnabled, isPlaceholder } = this.props;

		return (
			<div className="schedule-board-card-wrapper">
				<div
					className={`schedule-board-arrow-index-container ${removeEnabled ? 'pointer' : ''}`}
					onClick={this.removeBlankColumn}
				>
					<div className="schedule-board-card-index">{index + 1}</div>
				</div>
				{
					isDragAndDropDisabled || !hasPermissionsToEditScheduleBoard ?
						(
							isPlaceholder && <PlaceholderCard />
						) : (
							<Draggable
								draggableId={draggableId ?? `${index}`} // Irrelevant draggable ID but we need to define it
								index={index}
								isDragDisabled={true}
							>
								{
									(provided) => {
										return (
											<div
												className={`${isPlaceholder ? 'schedule-board-card' : 'blank-card'}`}
												ref={provided.innerRef}
												{...provided.draggableProps}
												{...provided.dragHandleProps}
											>
												{isPlaceholder && <PlaceholderCard />}
											</div>
										);
									}
								}
							</Draggable>
						)}
			</div>
		);
	}
}

function mapStateToProps(state: RootState, ownProps: OwnProps): StateProps {
	const { isDragAndDropDisabled, hasPermissionsToEditScheduleBoard, isPlaceholder } = ownProps;
	const readOnly = isDragAndDropDisabled || !hasPermissionsToEditScheduleBoard;

	return {
		removeEnabled: !readOnly && !isPlaceholder && !state.http.submitting.includes(REMOVE_BLANK_WORK_ORDER),
	};
}

export default connect<StateProps, null, OwnProps>(mapStateToProps)(BlankCard);
