import * as React from 'react';
import type { ConnectedProps } from 'react-redux';
import { connect } from 'react-redux';
import { Col, Row } from 'react-bootstrap';

import { ColorPalette } from 'acceligent-shared/enums/color';

import * as KPIActions from 'af-actions/kpi';

import type { EquipmentCostUtilizationChartsViewModel } from 'ab-viewModels/equipmentCostUtilization.viewModel';
import type { EquipmentCostUtilizationViewModel } from 'ab-viewModels/equipmentUtilization.viewModel';

import BarMeter from 'af-components/BarMeter';
import Select from 'af-components/Controls/Select';

import InfoCardChart from '../UtilizationShared/InfoCardChart';

import { moneyNormalizer, prettyMoney } from 'ab-utils/formatting.util';
import { normalizePercentages } from 'ab-utils/number.util';
import { bemBlock, bemElement } from 'ab-utils/bem.util';

import { MONEY_FORMATTER } from 'af-utils/chart.util';

const NO_EQUIPMENT_COST_ID = -1;

interface OwnProps {
	selectedEquipmentCost: EquipmentCostUtilizationViewModel | undefined;
	equipmentCostOptions: EquipmentCostUtilizationViewModel[];
	changeEquipmentCost: (equipmentCostId: string) => void;
}

type Props = OwnProps & ConnectedProps<typeof connector>;

interface State {
	chartData: Nullable<EquipmentCostUtilizationChartsViewModel>;
}

function _getOptionValue(equipmentCost: EquipmentCostUtilizationViewModel) {
	return equipmentCost.id.toString();
}

class InfoCard extends React.PureComponent<Props, State> {
	static defaultProps: Partial<Props> = {
		selectedEquipmentCost: { id: NO_EQUIPMENT_COST_ID } as EquipmentCostUtilizationViewModel,
	};

	state: State = {
		chartData: null,
	};

	async componentDidMount() {
		const {
			fetchEquipmentCostUtilizationCharts,
			selectedEquipmentCost,
		} = this.props;

		if (!selectedEquipmentCost) {
			throw new Error('No Equipment cost selected');
		}

		const { id } = selectedEquipmentCost;

		if (id !== NO_EQUIPMENT_COST_ID) {
			const data = await fetchEquipmentCostUtilizationCharts(id);
			this.setState(() => ({ chartData: data }));
		}
	}

	async componentDidUpdate(prevProps: Props) {
		const {
			fetchEquipmentCostUtilizationCharts,
			selectedEquipmentCost,
		} = this.props;

		if (!selectedEquipmentCost || !prevProps.selectedEquipmentCost) {
			throw new Error('No Equipment cost selected');
		}

		const { id } = selectedEquipmentCost;
		const { chartData } = this.state;

		const prevId = prevProps.selectedEquipmentCost.id;
		const hasSelectedEquipmentCost = id !== NO_EQUIPMENT_COST_ID;
		const hasFetchedChart = !!chartData;

		if (hasSelectedEquipmentCost && (!hasFetchedChart || id !== prevId)) {
			const data = await fetchEquipmentCostUtilizationCharts(id);
			this.setState(() => ({ chartData: data }));
		}
	}

	onEquipmentCostSelect = (equipmentCost: EquipmentCostUtilizationViewModel) => {
		const { changeEquipmentCost } = this.props;
		changeEquipmentCost(equipmentCost.id.toString());
	};

	renderOptionLabel = (equipmentCost: EquipmentCostUtilizationViewModel, labelMeta: Metadata) => {
		if (labelMeta.context === 'value') {
			return equipmentCost.name;
		}

		const { selectedEquipmentCost } = this.props;

		if (selectedEquipmentCost && equipmentCost.name === selectedEquipmentCost.name) {
			return (
				<span className="text-black">
					<strong>{equipmentCost.name}</strong>&nbsp;&nbsp;&nbsp;<span>{equipmentCost.equipmentCount}</span>
				</span>
			);
		}
		return (
			<span>
				{equipmentCost.name}&nbsp;&nbsp;&nbsp;<span className="text-grey">{equipmentCost.equipmentCount}</span>
			</span>
		);
	};

	render() {
		const { selectedEquipmentCost, equipmentCostOptions } = this.props;
		if (!selectedEquipmentCost) {
			throw new Error('No equipment cost selected');
		}

		const { daysAssigned, daysAvailable, daysUnavailable, totalDays, totalRevenue, totalCost, targetProfit } = selectedEquipmentCost;
		const { chartData } = this.state;

		const entries = [
			{ label: 'Scheduled', value: daysAssigned, color: ColorPalette.GREEN },
			{ label: 'Not Scheduled', value: daysAvailable, color: ColorPalette.ORANGE },
			{ label: 'Not Available', value: daysUnavailable, color: ColorPalette.RED },
		];

		const [scheduledPercentage, notScheduledPercentage, notAvailablePercentage] = normalizePercentages([
			totalDays ? Math.round(100 * daysAssigned / totalDays) : 0,
			totalDays ? Math.round(100 * daysAvailable / totalDays) : 0,
			totalDays ? Math.round(100 * daysUnavailable / totalDays) : 0,
		]);

		return (
			<div className={`utilization-info-card ${bemBlock('form-box', ['padded-inv'])}`}>
				<Row>
					<Col lg={8} sm={12} xs={24}>
						{selectedEquipmentCost.id === NO_EQUIPMENT_COST_ID
							? (
								<div className={bemElement('utilization-info-card', 'equipment-title')}>
									<div className="utilization-info-card__equipment-title__single-value">
										<span className="text-grey">Loading...</span>
									</div>
								</div>
							) : (
								<Select
									className={bemElement('utilization-info-card', 'equipment-title')}
									defaultValue={selectedEquipmentCost}
									formatOptionLabel={this.renderOptionLabel}
									getOptionValue={_getOptionValue}
									isClearable={false}
									isSearchable={false}
									onValueChange={this.onEquipmentCostSelect}
									options={equipmentCostOptions}
								/>
							)
						}
						<BarMeter entries={entries} total={totalDays} />
						<div className={bemElement('utilization-info-card', 'group')}>
							<div className={bemElement('utilization-info-card', 'group-entry', ['padded-right'])}>
								<span className="group-title">Scheduled:</span>
								<span className="group-value text-green">{scheduledPercentage}%</span>
							</div>
							<div className={bemElement('utilization-info-card', 'group-entry', ['padded-right'])}>
								<span className="group-title">Not Scheduled:</span>
								<span className="group-value text-orange">{notScheduledPercentage}%</span>
							</div>
							<div className={bemElement('utilization-info-card', 'group-entry', ['padded-right'])}>
								<span className="group-title">Not Available:</span>
								<span className="group-value text-red">{notAvailablePercentage}%</span>
							</div>
							<div className={bemElement('utilization-info-card', 'group-entry', ['padded-verticaly'])}>
								<span className="group-title">Profit/Loss:</span>
								<span className="group-value">{moneyNormalizer(targetProfit)}</span>
							</div>
						</div>
					</Col>
					<Col className={bemElement('utilization-info-card', 'charts-row')} lg={16} sm={12} xs={24}>
						<div>
							{/** Average maintenance ratio */}
						</div>
						<InfoCardChart
							chartData={chartData?.averageDailyCostChart}
							footerLabel="Total Cost:"
							footerValue={moneyNormalizer(totalCost)}
							hAxisFormatter={MONEY_FORMATTER}
							hAxisLabel="Month"
							title="Average Daily Cost"
							vAxisFormatter={prettyMoney}
						/>
						<InfoCardChart
							chartData={chartData?.averageDailyRevenueChart}
							footerLabel="Total Revenue:"
							footerValue={moneyNormalizer(totalRevenue)}
							hAxisFormatter={MONEY_FORMATTER}
							hAxisLabel="Month"
							title="Average Daily Revenue"
							vAxisFormatter={prettyMoney}
						/>
					</Col>
				</Row>
			</div>
		);
	}
}

function mapDispatchToProps() {
	return {
		fetchEquipmentCostUtilizationCharts: KPIActions.fetchEquipmentCostUtilizationCharts,
	};
}

const connector = connect(null, mapDispatchToProps());

export default connector<React.ComponentClass<OwnProps>>(InfoCard);
