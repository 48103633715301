import * as React from 'react';
import { compose } from 'redux';
import type { ConnectedProps } from 'react-redux';
import { connect } from 'react-redux';
import type { CustomRouteComponentProps } from 'react-router-dom';

import type { EquipmentListViewModel } from 'ab-viewModels/equipmentList.viewModel';
import type ReportBlockMapVM from 'ab-viewModels/reportBlock/reportBlockMap.viewModel';
import type { ReportTypeVM } from 'ab-viewModels/reportType/reportType.viewModel';

import type { ReportTypeRM } from 'ab-requestModels/reportType/reportType.requestModel';

import * as EquipmentActions from 'af-actions/equipment';
import * as ReportTypeActions from 'af-actions/reportType';
import * as ReportBlockActions from 'af-actions/reportBlock';

import CLIENT from 'af-constants/routes/client';

import Breadcrumbs from 'af-components/Breadcrumbs';

import type { RootState } from 'af-reducers';

import ReportTypeForm from '../CustomTypeForm';

interface PathParams {
	id: string;
}

type OwnProps = CustomRouteComponentProps<PathParams>;

type Props = OwnProps & ConnectedProps<typeof connector>;

const EditReportType: React.FC<Props> = (props: Props) => {
	const {
		location: { state: { orgAlias } },
		companyName,
		fetchingBlocks,
		fetchEquipmentList,
		fetchReportBlocks,
		fetchReportType,
		match: { params: { id } },
		edit,
		history,
	} = props;

	const [blocks, setBlocks] = React.useState<ReportBlockMapVM>({ blockMap: {}, fieldMap: {} });
	const [reportType, setReportType] = React.useState<Nullable<ReportTypeVM>>(null);
	const [listOfEquipment, setListOfEquipment] = React.useState<Nullable<EquipmentListViewModel>>(null);

	React.useEffect(() => {
		const fetchData = async () => {
			const _reportType = await fetchReportType(+id);
			const _blocks = await fetchReportBlocks();
			setBlocks(_blocks);
			setReportType(_reportType);
		};
		fetchData();
	}, [fetchReportType, fetchReportBlocks, id]);

	const fetchListOfEquipment = React.useCallback(async () => {
		const equipment = await fetchEquipmentList();
		setListOfEquipment(equipment);
	}, [fetchEquipmentList]);

	const onEdit = React.useCallback(async (form: ReportTypeRM) => {
		await edit(+id, form);
		history.push(CLIENT.COMPANY.SETTINGS.REPORT.TABLE(orgAlias, companyName));
	}, [edit, id, history, orgAlias, companyName]);

	const breadcrumbs = React.useCallback(() => {
		return [
			{ label: 'Report Type Elements', url: CLIENT.COMPANY.SETTINGS.REPORT.TABLE(orgAlias, companyName) },
			{ label: 'Edit Report Type' },
		];
	}, [companyName, orgAlias]);

	return (
		<div className="form-segment">
			<Breadcrumbs items={breadcrumbs()} />
			<ReportTypeForm
				fetchListOfEquipment={fetchListOfEquipment}
				hasBillableWork
				listOfEquipment={listOfEquipment}
				loading={fetchingBlocks || !reportType}
				onSubmit={onEdit}
				reportBlockFieldsMap={blocks.fieldMap}
				reportBlocksMap={blocks.blockMap}
				reportType={reportType}
			/>
		</div>
	);
};

function mapStateToProps(state: RootState) {
	const { isFetching } = state.http;
	const { companyData } = state.user;
	if (!companyData) {
		throw new Error('User not logged in');
	}

	return {
		fetchingBlocks: isFetching,
		companyName: companyData.name,
	};
}

function mapDispatchToProps() {
	return {
		edit: ReportTypeActions.editCustom,
		fetchReportBlocks: ReportBlockActions.findList,
		fetchReportType: ReportTypeActions.findCustomById,
		fetchEquipmentList: EquipmentActions.findAllForCompanyList,
	};
}

const connector = connect(mapStateToProps, mapDispatchToProps());

const enhance = compose<React.ComponentClass<OwnProps>>(
	React.memo,
	connector
);

export default enhance(EditReportType);
