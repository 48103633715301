import * as React from 'react';
import type { CustomRouteComponentProps } from 'react-router-dom';
import type { ResolveThunks } from 'react-redux';
import { connect } from 'react-redux';
import type { FileRejection } from 'react-dropzone';

import type { TableContent } from 'ab-common/dataStructures/tableContent';

import TableNameEnum from 'ab-enums/tableName.enum';
import TableButtonType from 'ab-enums/tableButtonType.enum';

import * as DocumentActions from 'af-actions/document';
import * as AttachmentActions from 'af-actions/attachment';

import type DocumentRowVM from 'ab-viewModels/document/table.viewModel';

import type { OwnProps as TableOwnProps, TabProps, Column, ButtonData } from 'af-components/Table6';
import _Table from 'af-components/Table6';
import LastUpdatedByCell from 'af-components/Table6/Cells/LastUpdatedByCell';
import type TableComponent from 'af-components/Table6/Table';
import type ScrollToLoad from 'af-components/ScrollToLoad';
import Breadcrumbs from 'af-components/Breadcrumbs';

import { prettyBytes } from 'ab-utils/formatting.util';

import UploadDocumentModal from './UploadDocumentModal';

const Table = _Table as unknown as React.ComponentClass<TableOwnProps<DocumentRowVM>>;

const DELETE_CONFIRMATION_BODY = (
	<>
		You are about to delete selected documents.
		<br />
		Are you sure you want to continue.
	</>
);

type OwnProps = CustomRouteComponentProps;

interface StateProps {
	uploadProgress?: number;
}

interface DispatchProps {
	getDocuments: typeof DocumentActions.getDocuments;
	uploadDocuments: typeof DocumentActions.uploadDocuments;
	deleteDocument: typeof DocumentActions.deleteDocument;
	deleteAllDocuments: typeof DocumentActions.deleteAllDocuments;
	downloadAttachment: typeof AttachmentActions.downloadAttachment;
	downloadAttachmentsAsZip: typeof AttachmentActions.downloadAttachmentsAsZip;
}

type Props = OwnProps & StateProps & ResolveThunks<DispatchProps>;

interface State {
	filesError?: string;
	uploadingDocuments: File[];
	showModal: boolean;
	documents: TableContent<DocumentRowVM> | undefined;
}

class Documents extends React.Component<Props> {

	_table: Nullable<TableComponent<DocumentRowVM>> = null;
	_list: Nullable<ScrollToLoad<DocumentRowVM> & { refreshList: () => void; }> = null;

	columns: Column<DocumentRowVM>[] = [
		{
			Header: 'File Name',
			accessor: 'name',
			Cell: ({ original }) => (
				<a
					className="text-blue"
					href="#"
					onClick={this.downloadDocument.bind(this, original)}
				>
					{original.name}
				</a>
			),
		},
		{
			Header: 'Size',
			accessor: 'size',
			Cell: ({ original }) => <span>{prettyBytes(original.size)}</span>,
		},
		{
			Header: 'Uploaded',
			accessor: 'uploadedOn',
			headerClassName: 'align-right',
			width: 200,
			Cell: ({ original }) => <LastUpdatedByCell updatedAt={original.uploadedOn} updatedBy={original.uploadedByObject} />,
		},
	];

	state: State = {
		filesError: '',
		uploadingDocuments: [],
		showModal: false,
		documents: undefined,
	};

	private _timer: NodeJS.Timeout | undefined;

	async componentWillUnmount() {
		clearTimeout(this._timer);
	}

	tabs = (): TabProps<DocumentRowVM>[] => {
		const {
			getDocuments,
		} = this.props;

		const buttons: ButtonData[] = [
			{
				type: TableButtonType.LINK,
				hasPermission: true,
				label: 'Download All',
				icon: 'download',
				onClick: this.downloadAllDocuments,
			},
			{
				type: TableButtonType.LINK,
				hasPermission: true,
				label: 'Upload',
				icon: 'upload',
				onClick: this.openModal,
			},
		];

		return [
			{
				label: 'Documents',
				columns: this.columns,
				selectable: true,
				buttons,
				fetch: getDocuments,
				bulkDelete: this.deleteAllDocuments,
				bulkDeleteConfirmationBody: DELETE_CONFIRMATION_BODY,
				rowActions: [
					{
						label: 'Download',
						action: this.downloadDocument,
						shouldRefresh: false,
					},
					{
						label: 'Delete',
						action: this.deleteDocument,
						hasModal: true,
						modalTitle: this.deleteDocumentModalTitle,
						modalBody: this.deleteDocumentModalBody,
						modalText: this.deleteDocumentModalText,
						shouldRefresh: true,
					},
				],
			},

		];
	};

	deleteDocumentModalTitle = () => 'Delete Document';
	deleteDocumentModalBody = (original: DocumentRowVM) => (
		<>
			You are about to delete document {original.name}.
			<br />
			Are you sure you want to continue.
		</>
	);
	deleteDocumentModalText = () => 'Remove';

	uploadFiles = async (acceptedFiles: File[], fileRejections: FileRejection[]) => {
		const { uploadDocuments } = this.props;
		const { uploadingDocuments } = this.state;

		if (fileRejections.length > 0) {
			this.setState(() => ({ filesError: 'Following files are rejected: ' + fileRejections.map((r) => r.file.name + ' ') }), () => {
				this._timer = setTimeout(() => this.setState(() => ({ filesError: '' })), 7000);
			});
			this.closeModal();
			return;
		}
		try {
			this.setState(() => ({ uploadingDocuments: uploadingDocuments.concat(acceptedFiles) }), async () => {
				await uploadDocuments({ requestFiles: acceptedFiles });
				this.setState(() => ({ uploadingDocuments: uploadingDocuments.filter((d) => !acceptedFiles.includes(d)) }), this.refresh);
			});
		} catch ({ errors }) {
			if (errors?.files) {
				this.setState(() => ({ filesError: errors.files }));
				this._timer = setTimeout(() => this.setState(() => ({ filesError: '' })), 7000);
			}
		}
		this.closeModal();
	};

	deleteDocument = async ({ id, storageName }: DocumentRowVM) => {
		const { deleteDocument } = this.props;
		await deleteDocument(id, storageName);
		this.refresh();
	};

	downloadDocument = (original: DocumentRowVM) => {
		this.props.downloadAttachment(original.id, original.name);
	};

	downloadAllDocuments = async () => {
		const { downloadAttachmentsAsZip } = this.props;
		const { documents } = this.state;
		try {
			if (documents?.rows) {
				await downloadAttachmentsAsZip(documents.rows.map((att) => att.id), 'documents.zip');
			}
		} catch (e) {
			this.setState(() => ({ filesError: 'Cannot download files.' }));
			this._timer = setTimeout(() => this.setState(() => ({ filesError: '' })), 5000);
		}
	};

	deleteAllDocuments = async (ids: number[]) => {
		const { deleteAllDocuments } = this.props;

		await deleteAllDocuments(ids);
		this.refresh();
	};

	openModal = async () => {
		this.setState(() => ({ showModal: true }));
	};

	closeModal = () => {
		this.setState(() => ({ showModal: false }));
	};

	onTableMount = (table: TableComponent<DocumentRowVM>, list?: ScrollToLoad<DocumentRowVM>) => {
		this._table = table;
		this._list = list as ScrollToLoad<DocumentRowVM> & { refreshList: () => void; };
	};

	refresh = () => {
		if (this._table) {
			this._table.refreshTable();
		} else if (this._list) {
			this._list.refreshList();
		}
	};

	breadcrumbs = () => [{ label: 'Document' }];

	render() {
		const { filesError, showModal } = this.state;

		return (
			<div className="form-segment form-segment--maxi">
				<Breadcrumbs items={this.breadcrumbs()} />
				<Table
					onMount={this.onTableMount}
					tableName={TableNameEnum.DOCUMENTS}
					tabs={this.tabs()}
				/>
				<UploadDocumentModal
					error={filesError}
					onCancel={this.closeModal}
					onDrop={this.uploadFiles}
					showModal={showModal}
				/>
			</div>
		);
	}
}

function mapDispatchToProps(): DispatchProps {
	return {
		getDocuments: DocumentActions.getDocuments,
		uploadDocuments: DocumentActions.uploadDocuments,
		deleteDocument: DocumentActions.deleteDocument,
		deleteAllDocuments: DocumentActions.deleteAllDocuments,
		downloadAttachment: AttachmentActions.downloadAttachment,
		downloadAttachmentsAsZip: AttachmentActions.downloadAttachmentsAsZip,
	};
}

export default connect<null, DispatchProps, OwnProps>(null, mapDispatchToProps())(Documents);
