import { saveAs } from 'file-saver';
import type { Dispatch, AnyAction } from 'redux';
import type { FormAction } from 'redux-form';
import type { DropResult, DragStart } from 'react-beautiful-dnd';

import type EmployeeNightShiftAssignmentVM from 'acceligent-shared/dtos/socket/view/nightShift/nightShiftAssignment';
import type TemporaryEmployeeNightShiftAssignmentVM from 'acceligent-shared/dtos/socket/view/nightShift/temporaryEmployeeNighShiftAssignment';
import type { ScheduleBoardRemoveAllWorkOrderEmployeesVM, ScheduleBoardRemoveAllWorkOrderEquipmentVM } from 'acceligent-shared/dtos/socket/view/scheduleBoard/resources';
import type ScheduleBoardRemoveResourceAssignmentVM from 'acceligent-shared/dtos/socket/view/scheduleBoard/resources';
import type ScheduleBoardUpdateWorkOrderHasAttachmentsVM from 'acceligent-shared/dtos/socket/view/scheduleBoard/updateWorkOrderHasAttachments';

import type ScheduleBoardFilterVM from 'ab-viewModels/scheduleBoard/filter';

import FileType from 'acceligent-shared/enums/fileType';
import type UnavailabilityReasonScope from 'acceligent-shared/enums/unavailabilityReasonScope';
import WorkOrderStatus from 'acceligent-shared/enums/workOrderStatus';

import type { GetRootState } from 'af-reducers';

import * as scheduleBoardActionCreators from 'af-actions/scheduleBoard/scheduleBoard.actionCreators';

import type ScheduleBoardView from 'ab-enums/scheduleBoardView.enum';
import { DailyViewDragElementType } from 'ab-enums/scheduleBoardDragElementType.enum';
import type ScheduleBoardSortType from 'ab-enums/scheduleBoardSortType.enum';
import type { OrderByEnum, SortByEnum } from 'ab-enums/mechanicView.enum';

import type { BlankWorkOrderRequestModel } from 'ab-requestModels/workOrder.requestModel';
import type { ScheduleBoardDragDestinationData } from 'ab-requestModels/scheduleBoardDrag.requestModel';
import type ScheduleBoardMetricsRequestModel from 'ab-requestModels/scheduleBoardMetrics.requestModel';
import type ScheduleBoardEmployeePerDiemAssignmentRequestModel from 'ab-requestModels/scheduleBoardEmployeePerDiemAssignment.requestModel';
import type ScheduleBoardSetPerDiemForWorkOrdersRequestModel from 'ab-requestModels/scheduleBoardSetPerDiemsForWorkOrder.requestModel';
import type ScheduleBoardReturnDateRequestModel from 'ab-requestModels/scheduleBoardReturnDate.requestModel';

import type ScheduleBoardDeleteWorkOrderViewModel from 'ab-socketModels/viewModels/scheduleBoard/deleteWorkOrder.viewModel';
import type ScheduleBoardReorderWorkOrdersViewModel from 'ab-socketModels/viewModels/scheduleBoard/reorderWorkOrders.viewModel';
import type ScheduleBoardUpdateWorkOrderNoteViewModel from 'ab-socketModels/viewModels/scheduleBoard/updateWorkOrderNote.viewModel';
import type ScheduleBoardPauseWorkOrderViewModel from 'ab-socketModels/viewModels/scheduleBoard/pauseWorkOrder.viewModel';
import type ScheduleBoardResumeWorkOrderViewModel from 'ab-socketModels/viewModels/scheduleBoard/resumeWorkOrder.viewModel';
import type ScheduleBoardLockWorkOrderViewModel from 'ab-socketModels/viewModels/scheduleBoard/lockWorkOrder.viewModel';
import type ScheduleBoardUnlockWorkOrderViewModel from 'ab-socketModels/viewModels/scheduleBoard/unlockWorkOrder.viewModel';
import type ScheduleBoardRevertWorkOrderViewModel from 'ab-socketModels/viewModels/scheduleBoard/revertWorkOrder.viewModel';

import type { ScheduleBoardRejoinViewModel } from 'ab-viewModels/scheduleBoard.viewModel';
import type ScheduleBoardViewModel from 'ab-viewModels/scheduleBoard.viewModel';
import type ScheduleBoardResourcesViewModel from 'ab-viewModels/scheduleBoardResources.viewModel';
import type SocketConnectionCountViewModelModel from 'ab-viewModels/socketConnectionCount.viewModel';
import type ScheduleBoardAddResourceLookupAssignment from 'ab-viewModels/scheduleBoardAddResourceLookupAssignment.viewModel';
import type ScheduleBoardToolbarEmployeeUpdate from 'ab-socketModels/viewModels/scheduleBoard/scheduleBoardToolbarEmployeeUpdate.viewModel';
import type ScheduleBoardToolbarEquipmentUpdate from 'ab-socketModels/viewModels/scheduleBoard/scheduleBoardToolbarEquipmentUpdate.viewModel';
import type ScheduleBoardDragRequestModel from 'ab-requestModels/scheduleBoardDrag.requestModel';
import type WorkOrderIdAndDueDate from 'ab-viewModels/workOrderIdAndDueDate.viewModel';
import type WorkOrderCodeAndDueDate from 'ab-viewModels/workOrderCodeAndDueDate.viewModel';
import type ScheduleBoardUnavailabilityReason from 'ab-viewModels/scheduleBoardUnavailabilityReason.viewModel';
import type ScheduleBoardUpdateWorkOrders from 'ab-viewModels/scheduleBoardUpdateWorkOrders.viewModel';
import type ScheduleBoardWorkOrderViewModel from 'ab-socketModels/viewModels/scheduleBoard/scheduleBoardWorkOrder.viewModel';
import type ScheduleBoardCopiedWorkOrderViewModel from 'ab-viewModels/scheduleBoardCopiedWorkOrder.viewModel';
import type ScheduleBoardEmployee from 'ab-viewModels/scheduleBoardEmployee.viewModel';
import type ScheduleBoardTemporaryEmployee from 'ab-viewModels/scheduleBoardTemporaryEmployee.viewModel';
import type ScheduleBoardEquipment from 'ab-viewModels/scheduleBoardEquipment.viewModel';
import type ScheduleBoardEmployeeModalVM from 'ab-viewModels/scheduleBoardEmployeeModal.viewModel';
import type ScheduleBoardEquipmentModalVM from 'ab-viewModels/scheduleBoardEquipmentModal.viewModel';
import type ScheduleBoardTemporaryEmployeeModalVM from 'ab-viewModels/scheduleBoardTemporaryEmployeeModal.viewModel';
import type { ScheduleBoardLaborStatisticsForDay } from 'ab-viewModels/scheduleBoardLaborStatistics.viewModel';
import type { NotificationLookupViewModel } from 'ab-viewModels/notification.viewModel';
import type AddEquipmentDownDetails from 'ab-viewModels/equipment/addEquipmentDownDetails.viewModel';
import type { MechanicViewAvailableEquipmentDataVM, MechanicViewUnavailableEquipmentDataVM } from 'ab-viewModels/mechanicView/mechanicView.viewModel';
import type { EmployeeUnavailabilityDetailsVM } from 'ab-viewModels/dailyEmployeeStatus.viewModel';
import type ScheduleBoardNightShiftWorkOrder from 'ab-viewModels/scheduleBoardNightShiftWorkOrder.viewModel';
import type { AvailableToolbarResources } from 'ab-viewModels/scheduleBoardToolbarResources.viewModel';
import type ScheduleBoardUpdateCrewType from 'ab-viewModels/scheduleBoardUpdateCrewType.viewModel';
import type { DailyPerDiemTipViewModel } from 'ab-viewModels/dailyPerDiemTip.viewModel';
import type { DailyTipViewModel } from 'ab-viewModels/dailyTip.viewModel';

import type { SelectedWorkOrderModel, LaborResourceModal, ResourcePlaceholderModal } from 'af-models/scheduleBoard.models';
import type { CheckEmployeeInAvailableEmployeesNotificationModal, CheckAllInAvailableEmployeesNotificationModal } from 'af-models/employeeNotifyModal.model';

import { SCHEDULE_BOARD_MAX_ITEMS_IN_ROW } from 'ab-common/constants/scheduleBoard';
import API from 'af-constants/routes/api';
import {
	EXPORT_SCHEDULE_BOARD,
	REMOVE_BLANK_WORK_ORDER,
} from 'af-constants/reduxForms';

import * as FindRevisionModalDataAPI from 'ab-api/web/workOrder/findRevisionModalData';
import * as FindWorkOrderModalDataAPI from 'ab-api/web/workOrder/findWorkOrderModalData';

import { http } from 'af-utils/http.util';
import { errorHandler } from 'af-utils/actions.util';
import * as ScheduleBoardUtil from 'af-utils/scheduleBoard.util';
import type ScheduleBoardTemporaryEmployeeVM from 'ab-viewModels/scheduleBoardTemporaryEmployee.viewModel';

const DRAGGING_DISABLED_WO_STATUSES = {
	[WorkOrderStatus.LOCKED]: true,
	[WorkOrderStatus.CANCELED]: true,
} as const;

export function exportScheduleBoard(dueDate: string, withCalculations: boolean, onlyCurrentDay: boolean, fileType: FileType = FileType.XLSX) {
	return async (dispatch: Dispatch<AnyAction>, getState: GetRootState, { redirectTo }) => {

		const action = async () => {
			let url: Nullable<string>;
			if (fileType === FileType.XLSX) {
				url = API.V1.SCHEDULE_BOARD.EXPORT.XLSX(dueDate, withCalculations, onlyCurrentDay);
			} else if (fileType === FileType.PDF) {
				url = API.V1.SCHEDULE_BOARD.EXPORT.PDF(dueDate, withCalculations, onlyCurrentDay);
			} else {
				throw new Error('File type not supported');
			}
			const data = await http.get(url, { responseType: 'blob', submitting: EXPORT_SCHEDULE_BOARD });
			saveAs(data, `schedule_board_${dueDate}${fileType}`);
		};

		return await errorHandler(action, dispatch, redirectTo);
	};
}

export function exportScheduleBoardTemplate(dueDate: string) {
	return async (dispatch: Dispatch<AnyAction>, getState: GetRootState, { redirectTo }) => {

		const action = async () => {
			const url = API.V1.SCHEDULE_BOARD.EXPORT.TEMPLATE(dueDate);
			const data = await http.get(url, { responseType: 'blob', submitting: EXPORT_SCHEDULE_BOARD });
			saveAs(data, `schedule_board_${dueDate}.pdf`);
		};

		return await errorHandler(action, dispatch, redirectTo);
	};
}

export function getScheduleBoard(scheduleBoard: ScheduleBoardViewModel) {
	return (dispatch: Dispatch<scheduleBoardActionCreators.ScheduleBoardAction>) => {
		dispatch(scheduleBoardActionCreators.GET_SCHEDULE_BOARD(scheduleBoard));
	};
}

export function getScheduleBoardRejoin(scheduleBoard: ScheduleBoardRejoinViewModel) {
	return (dispatch: Dispatch<scheduleBoardActionCreators.ScheduleBoardAction>) => {
		dispatch(scheduleBoardActionCreators.GET_SCHEDULE_BOARD_REJOIN(scheduleBoard));
	};
}

export function getScheduleBoardResources(scheduleBoardResources: ScheduleBoardResourcesViewModel) {
	return (dispatch: Dispatch<scheduleBoardActionCreators.ScheduleBoardAction>) => {
		dispatch(scheduleBoardActionCreators.GET_SCHEDULE_BOARD_RESOURCES(scheduleBoardResources));
	};
}

export function reloadScheduleBoardResources() {
	return (dispatch: Dispatch<scheduleBoardActionCreators.ScheduleBoardAction>) => {
		dispatch(scheduleBoardActionCreators.RELOAD_SCHEDULE_BOARD_RESOURCES());
	};
}

export function reloadScheduleBoard() {
	return (dispatch: Dispatch<scheduleBoardActionCreators.ScheduleBoardAction>) => {
		dispatch(scheduleBoardActionCreators.RELOAD_SCHEDULE_BOARD());
	};
}

export function setScheduleBoardView(view: ScheduleBoardView) {
	return (dispatch: Dispatch<scheduleBoardActionCreators.ScheduleBoardAction>) => {
		dispatch(scheduleBoardActionCreators.SET_SCHEDULE_BOARD_VIEW(view));
	};
}

export function getWeeklyViewBoard(weeklyView: ScheduleBoardViewModel) {
	return (dispatch: Dispatch<scheduleBoardActionCreators.ScheduleBoardAction>) => {
		dispatch(scheduleBoardActionCreators.GET_WEEKLY_VIEW_SCHEDULE_BOARD(weeklyView));
	};
}

export function getSocketConnectionCount(connectionsCount: SocketConnectionCountViewModelModel) {
	return (dispatch: Dispatch<scheduleBoardActionCreators.ScheduleBoardAction>) => {
		dispatch(scheduleBoardActionCreators.GET_SOCKET_CONNECTION_COUNT(connectionsCount));
	};
}

export function lazyLoadWorkOrder(workOrderId: number) {
	return async (dispatch: Dispatch<AnyAction>, getState: GetRootState, { redirectTo }) => {

		const action = async () => {
			return await http.get<FindWorkOrderModalDataAPI.W_WorkOrder_FindWorkOrderModalForScheduleBoard_VM>(
				FindWorkOrderModalDataAPI.URL(workOrderId)
			);
		};

		return await errorHandler(action, dispatch, redirectTo);
	};
}

export function lazyLoadWorkOrderRevision(workOrderRevisionId: number) {
	return async (dispatch: Dispatch<AnyAction>, getState: GetRootState, { redirectTo }) => {

		const action = async () => {
			return await http.get<FindRevisionModalDataAPI.W_WorkOrder_FindWorkOrderRevisionModalForScheduleBoard_VM>(
				FindRevisionModalDataAPI.URL(workOrderRevisionId)
			);
		};

		return await errorHandler(action, dispatch, redirectTo);
	};
}

export function findAllFilters() {
	return async (dispatch: Dispatch<scheduleBoardActionCreators.ScheduleBoardAction>, getState: GetRootState, { redirectTo }) => {

		const action = async () => {
			return await http.get<ScheduleBoardFilterVM>(API.V1.SCHEDULE_BOARD.FILTERS());
		};

		return await errorHandler(action, dispatch, redirectTo);
	};
}

export function applyFilters(filters: ScheduleBoardFilterVM) {
	return (dispatch: Dispatch<scheduleBoardActionCreators.ScheduleBoardAction>) => {
		dispatch(scheduleBoardActionCreators.APPLY_SCHEDULE_BOARD_FILTERS(filters));
	};
}

export function clearFilters() {
	return (dispatch: Dispatch<scheduleBoardActionCreators.ScheduleBoardAction>) => {
		dispatch(scheduleBoardActionCreators.CLEAR_ALL_SCHEDULE_BOARD_FILTERS());
	};
}

export function activateMultiSelectMode() {
	return (dispatch: Dispatch<scheduleBoardActionCreators.ScheduleBoardAction>) => {
		dispatch(scheduleBoardActionCreators.ACTIVATE_SCHEDULE_BOARD_MULTI_SELECT_MODE());
	};
}

export function deactivateMultiSelectMode() {
	return (dispatch: Dispatch<scheduleBoardActionCreators.ScheduleBoardAction>) => {
		dispatch(scheduleBoardActionCreators.DEACTIVATE_SCHEDULE_BOARD_MULTI_SELECT_MODE());
		dispatch(scheduleBoardActionCreators.SET_SCHEDULE_BOARD_MULTI_SELECT_ORDERS([]));
	};
}

export function setShowNotesMode(active: boolean) {
	return (dispatch: Dispatch<scheduleBoardActionCreators.ScheduleBoardAction>) => {
		dispatch(scheduleBoardActionCreators.SET_SHOW_NOTES_MODE(active));
	};
}

export function selectWorkOrder(workOrder: SelectedWorkOrderModel) {
	return (dispatch: Dispatch<scheduleBoardActionCreators.ScheduleBoardAction>) => {
		dispatch(scheduleBoardActionCreators.ADD_ORDER_TO_SCHEDULE_BOARD_MULTI_SELECT(workOrder));
	};
}

export function deselectWorkOrder(workOrder: SelectedWorkOrderModel) {
	return (dispatch: Dispatch<scheduleBoardActionCreators.ScheduleBoardAction>) => {
		dispatch(scheduleBoardActionCreators.REMOVE_ORDER_FROM_SCHEDULE_BOARD_MULTI_SELECT(workOrder));
	};
}

export function setWorkOrdersSelection(workOrders: SelectedWorkOrderModel[] = []) {
	return (dispatch: Dispatch<scheduleBoardActionCreators.ScheduleBoardAction>) => {
		dispatch(scheduleBoardActionCreators.SET_SCHEDULE_BOARD_MULTI_SELECT_ORDERS(workOrders));
	};
}

/** updates schedule board droppable lists with same context */
export function updateScheduleBoardDroppableList(data: ScheduleBoardDragRequestModel) {
	return (dispatch: Dispatch<scheduleBoardActionCreators.ScheduleBoardAction>) => {
		dispatch(scheduleBoardActionCreators.UPDATE_SCHEDULE_BOARD_DROPPABLE_LIST(data));
	};
}

export function addToScheduleBoardDroppableList(data: ScheduleBoardDragRequestModel) {
	return (dispatch: Dispatch<scheduleBoardActionCreators.ScheduleBoardAction>) => {
		dispatch(scheduleBoardActionCreators.ADD_TO_SCHEDULE_BOARD_DROPPABLE_LIST(data));
	};
}

export function addBlankWorkOrder(dueDate: string, index: number) {
	return async (dispatch: Dispatch<scheduleBoardActionCreators.ScheduleBoardAction>, getState: GetRootState, { redirectTo }) => {
		const action = async () => {
			const data = { dueDate, index } as BlankWorkOrderRequestModel;
			await http.post(API.V1.SCHEDULE_BOARD.ADD_BLANK_WORK_ORDER(), data);
		};
		return await errorHandler(action, dispatch, redirectTo);
	};
}

export function removeBlankWorkOrder(dueDate: string, index: number) {
	return async (dispatch: Dispatch<scheduleBoardActionCreators.ScheduleBoardAction>, getState: GetRootState, { redirectTo }) => {
		const action = async () => {
			const data = { dueDate, index } as BlankWorkOrderRequestModel;
			await http.post(API.V1.SCHEDULE_BOARD.REMOVE_BLANK_WORK_ORDER(), data, { submitting: REMOVE_BLANK_WORK_ORDER });
		};
		return await errorHandler(action, dispatch, redirectTo);
	};
}

export function addBlankWorkOrderInRedux(data: BlankWorkOrderRequestModel) {
	return (dispatch: Dispatch<scheduleBoardActionCreators.ScheduleBoardAction>) => {
		dispatch(scheduleBoardActionCreators.ADD_BLANK_WORK_ORDER(data));
	};
}

export function removeBlankWorkOrderInRedux(data: BlankWorkOrderRequestModel) {
	return (dispatch: Dispatch<scheduleBoardActionCreators.ScheduleBoardAction>) => {
		dispatch(scheduleBoardActionCreators.REMOVE_BLANK_WORK_ORDER(data));
	};
}

export function updateScheduleBoardWorkOrderIndices(data: ScheduleBoardReorderWorkOrdersViewModel) {
	return (dispatch: Dispatch<scheduleBoardActionCreators.ScheduleBoardAction>) => {
		dispatch(scheduleBoardActionCreators.UPDATE_SCHEDULE_BOARD_WORK_ORDER_INDICES(data));
	};
}

export function updateScheduleBoardWorkOrders(data: ScheduleBoardUpdateWorkOrders) {
	return (dispatch: Dispatch<scheduleBoardActionCreators.ScheduleBoardAction>) => {
		dispatch(scheduleBoardActionCreators.UPDATE_SCHEDULE_BOARD_WORK_ORDERS(data));
	};
}

export function updateScheduleBoardWorkOrder(data: ScheduleBoardWorkOrderViewModel) {
	return (dispatch: Dispatch<scheduleBoardActionCreators.ScheduleBoardAction>) => {
		dispatch(scheduleBoardActionCreators.UPDATE_SCHEDULE_BOARD_WORK_ORDER(data));
	};
}

export function removeScheduleBoardWorkOrder(data: ScheduleBoardDeleteWorkOrderViewModel) {
	return (dispatch: Dispatch<scheduleBoardActionCreators.ScheduleBoardAction>) => {
		dispatch(scheduleBoardActionCreators.REMOVE_SCHEDULE_BOARD_WORK_ORDER(data));
	};
}

export function cancelScheduleBoardWorkOrder(data: ScheduleBoardDeleteWorkOrderViewModel) {
	return (dispatch: Dispatch<scheduleBoardActionCreators.ScheduleBoardAction>) => {
		dispatch(scheduleBoardActionCreators.CANCEL_SCHEDULE_BOARD_WORK_ORDER(data));
	};
}

export function pauseScheduleBoardWorkOrder(data: ScheduleBoardPauseWorkOrderViewModel) {
	return (dispatch: Dispatch<scheduleBoardActionCreators.ScheduleBoardAction>) => {
		dispatch(scheduleBoardActionCreators.PAUSE_SCHEDULE_BOARD_WORK_ORDER(data));
	};
}

export function resumeScheduleBoardWorkOrder(data: ScheduleBoardResumeWorkOrderViewModel) {
	return (dispatch: Dispatch<scheduleBoardActionCreators.ScheduleBoardAction>) => {
		dispatch(scheduleBoardActionCreators.RESUME_SCHEDULE_BOARD_WORK_ORDER(data));
	};
}

export function revertScheduleBoardWorkOrder(data: ScheduleBoardRevertWorkOrderViewModel) {
	return (dispatch: Dispatch<scheduleBoardActionCreators.ScheduleBoardAction>) => {
		dispatch(scheduleBoardActionCreators.REVERT_SCHEDULE_BOARD_WORK_ORDER(data));
	};
}

export function lockScheduleBoardWorkOrder(data: ScheduleBoardLockWorkOrderViewModel) {
	return (dispatch: Dispatch<scheduleBoardActionCreators.ScheduleBoardAction>) => {
		dispatch(scheduleBoardActionCreators.LOCK_SCHEDULE_BOARD_WORK_ORDER(data));
	};
}

export function unlockScheduleBoardWorkOrder(data: ScheduleBoardUnlockWorkOrderViewModel) {
	return (dispatch: Dispatch<scheduleBoardActionCreators.ScheduleBoardAction>) => {
		dispatch(scheduleBoardActionCreators.UNLOCK_SCHEDULE_BOARD_WORK_ORDER(data));
	};
}

export function copyMultipleScheduleBoardWorkOrder(data: ScheduleBoardCopiedWorkOrderViewModel[]) {
	return (dispatch: Dispatch<scheduleBoardActionCreators.ScheduleBoardAction>) => {
		dispatch(scheduleBoardActionCreators.COPY_MULTIPLE_WORK_ORDERS(data));
	};
}

export function updateScheduleBoardDate(date: string) {
	return (dispatch: Dispatch<scheduleBoardActionCreators.ScheduleBoardAction>) => {
		dispatch(scheduleBoardActionCreators.UPDATE_SCHEDULE_BOARD_DATE(date));
	};
}

export function updateDisplayViewDate(date: string) {
	return (dispatch: Dispatch<scheduleBoardActionCreators.ScheduleBoardAction>) => {
		dispatch(scheduleBoardActionCreators.UPDATE_DISPLAY_VIEW_DATE(date));
	};
}

export function updateScheduleBoardWeek(startDate: string, endDate: string) {
	return (dispatch: Dispatch<scheduleBoardActionCreators.ScheduleBoardAction>) => {
		dispatch(scheduleBoardActionCreators.UPDATE_SCHEDULE_BOARD_WEEK(startDate, endDate));
	};
}

export function updateScheduleBoardQuery(query: string = '') {
	return (dispatch: Dispatch<scheduleBoardActionCreators.ScheduleBoardAction>) => {
		dispatch(scheduleBoardActionCreators.UPDATE_SCHEDULE_BOARD_QUERY(query));
	};
}

export function setActiveSearchItemIndex(index: number) {
	return (dispatch: Dispatch<scheduleBoardActionCreators.ScheduleBoardAction>) => {
		dispatch(scheduleBoardActionCreators.SET_SB_ACTIVE_SEARCH_ITEM_INDEX(index));
	};
}

export function setMechanicViewSortBy(sortBy: SortByEnum, orderBy: OrderByEnum) {
	return (dispatch: Dispatch<scheduleBoardActionCreators.ScheduleBoardAction>) => {
		dispatch(scheduleBoardActionCreators.SET_MECHANIC_VIEW_SORT_BY(sortBy, orderBy));
	};
}

export function assignUnavailabilityReason(data: ScheduleBoardUnavailabilityReason) {
	return (dispatch: Dispatch<scheduleBoardActionCreators.ScheduleBoardAction>) => {
		dispatch(scheduleBoardActionCreators.ASSIGN_UNAVAILABILITY_REASON(data));
	};
}

export function clearUnavailabilityReason(itemId: number, scope: UnavailabilityReasonScope, dueDate: string) {
	return (dispatch: Dispatch<scheduleBoardActionCreators.ScheduleBoardAction>) => {
		dispatch(scheduleBoardActionCreators.CLEAR_UNAVAILABILITY_REASON(itemId, scope, dueDate));
	};
}

export function changeReturnDate(data: ScheduleBoardReturnDateRequestModel) {
	return (dispatch: Dispatch<scheduleBoardActionCreators.ScheduleBoardAction>) => {
		dispatch(scheduleBoardActionCreators.CHANGE_RETURN_DATE(data));
	};
}

export function updateEmployeePerDiem(data: ScheduleBoardEmployeePerDiemAssignmentRequestModel) {
	return (dispatch: Dispatch<scheduleBoardActionCreators.ScheduleBoardAction>) => {
		dispatch(scheduleBoardActionCreators.UPDATE_EMPLOYEE_PER_DIEM(data));
	};
}

export function setPerDiemForWorkOrders(data: ScheduleBoardSetPerDiemForWorkOrdersRequestModel) {
	return (dispatch: Dispatch<scheduleBoardActionCreators.ScheduleBoardAction>) => {
		dispatch(scheduleBoardActionCreators.SET_PER_DIEM_FOR_WORK_ORDERS(data));
	};
}

export function clearDailyView() {
	return (dispatch: Dispatch<scheduleBoardActionCreators.ScheduleBoardAction>) => {
		dispatch(scheduleBoardActionCreators.CLEAR_SCHEDULE_BOARD_DAILY_VIEW());
	};
}

export function clearWeeklyView() {
	return (dispatch: Dispatch<scheduleBoardActionCreators.ScheduleBoardAction>) => {
		dispatch(scheduleBoardActionCreators.CLEAR_SCHEDULE_BOARD_WEEKLY_VIEW());
	};
}

export function disableEquipmentItem(workOrderEquipmentId: number) {
	return (dispatch: Dispatch<scheduleBoardActionCreators.ScheduleBoardAction>) => {
		dispatch(scheduleBoardActionCreators.DISABLE_SCHEDULE_BOARD_EQUIPMENT_ITEM(workOrderEquipmentId));
	};
}

export function enableEquipmentItem(workOrderEquipmentId: number) {
	return (dispatch: Dispatch<scheduleBoardActionCreators.ScheduleBoardAction>) => {
		dispatch(scheduleBoardActionCreators.ENABLE_SCHEDULE_BOARD_EQUIPMENT_ITEM(workOrderEquipmentId));
	};
}

export function disableEmployeeItem(workOrderEmployeeId: number) {
	return (dispatch: Dispatch<scheduleBoardActionCreators.ScheduleBoardAction>) => {
		dispatch(scheduleBoardActionCreators.DISABLE_SCHEDULE_BOARD_EMPLOYEE_ITEM(workOrderEmployeeId));
	};
}

export function enableEmployeeItem(workOrderEmployeeId: number) {
	return (dispatch: Dispatch<scheduleBoardActionCreators.ScheduleBoardAction>) => {
		dispatch(scheduleBoardActionCreators.ENABLE_SCHEDULE_BOARD_EMPLOYEE_ITEM(workOrderEmployeeId));
	};
}

export function disableTemporaryEmployeeItem(workOrderTemporaryEmployeeId: number) {
	return (dispatch: Dispatch<scheduleBoardActionCreators.ScheduleBoardAction>) => {
		dispatch(scheduleBoardActionCreators.DISABLE_SCHEDULE_BOARD_TEMPORARY_EMPLOYEE_ITEM(workOrderTemporaryEmployeeId));
	};
}

export function enableTemporaryEmployeeItem(workOrderTemporaryEmployeeId: number) {
	return (dispatch: Dispatch<scheduleBoardActionCreators.ScheduleBoardAction>) => {
		dispatch(scheduleBoardActionCreators.ENABLE_SCHEDULE_BOARD_TEMPORARY_EMPLOYEE_ITEM(workOrderTemporaryEmployeeId));
	};
}

export function disableWorkOrderItem(data: WorkOrderCodeAndDueDate) {
	return (dispatch: Dispatch<scheduleBoardActionCreators.ScheduleBoardAction>) => {
		dispatch(scheduleBoardActionCreators.DISABLE_SCHEDULE_BOARD_WORK_ORDER_ITEM(data));
	};
}

export function enableWorkOrderItem(data: WorkOrderCodeAndDueDate) {
	return (dispatch: Dispatch<scheduleBoardActionCreators.ScheduleBoardAction>) => {
		dispatch(scheduleBoardActionCreators.ENABLE_SCHEDULE_BOARD_WORK_ORDER_ITEM(data));
	};
}

export function removeEmployeeAssignment(data: ScheduleBoardRemoveResourceAssignmentVM) {
	return (dispatch: Dispatch<scheduleBoardActionCreators.ScheduleBoardAction>) => {
		dispatch(scheduleBoardActionCreators.REMOVE_EMPLOYEE_ASSIGNMENT(data));
	};
}

export function addEmployeeAssignment(data: ScheduleBoardAddResourceLookupAssignment) {
	return (dispatch: Dispatch<scheduleBoardActionCreators.ScheduleBoardAction>) => {
		dispatch(scheduleBoardActionCreators.ADD_EMPLOYEE_ASSIGNMENT(data));
	};
}

export function removeEquipmentAssignment(data: ScheduleBoardRemoveResourceAssignmentVM) {
	return (dispatch: Dispatch<scheduleBoardActionCreators.ScheduleBoardAction>) => {
		dispatch(scheduleBoardActionCreators.REMOVE_EQUIPMENT_ASSIGNMENT(data));
	};
}

export function addEquipmentAssignment(data: ScheduleBoardAddResourceLookupAssignment) {
	return (dispatch: Dispatch<scheduleBoardActionCreators.ScheduleBoardAction>) => {
		dispatch(scheduleBoardActionCreators.ADD_EQUIPMENT_ASSIGNMENT(data));
	};
}

export function removePlaceholderAssignment(data: ScheduleBoardRemoveResourceAssignmentVM) {
	return (dispatch: Dispatch<scheduleBoardActionCreators.ScheduleBoardAction>) => {
		dispatch(scheduleBoardActionCreators.REMOVE_PLACEHOLDER_ASSIGNMENT(data));
	};
}

export function addPlaceholderAssignment(data: ScheduleBoardAddResourceLookupAssignment) {
	return (dispatch: Dispatch<scheduleBoardActionCreators.ScheduleBoardAction>) => {
		dispatch(scheduleBoardActionCreators.ADD_PLACEHOLDER_ASSIGNMENT(data));
	};
}

export function removeTemporaryEmployeeAssignment(data: ScheduleBoardRemoveResourceAssignmentVM) {
	return (dispatch: Dispatch<scheduleBoardActionCreators.ScheduleBoardAction>) => {
		dispatch(scheduleBoardActionCreators.REMOVE_TEMPORARY_EMPLOYEE_ASSIGNMENT(data));
	};
}

export function addTemporaryEmployeeAssignment(data: ScheduleBoardAddResourceLookupAssignment) {
	return (dispatch: Dispatch<scheduleBoardActionCreators.ScheduleBoardAction>) => {
		dispatch(scheduleBoardActionCreators.ADD_TEMPORARY_EMPLOYEE_ASSIGNMENT(data));
	};
}

export function addToolbarEmployee(data: ScheduleBoardToolbarEmployeeUpdate) {
	return (dispatch: Dispatch<scheduleBoardActionCreators.ScheduleBoardAction>) => {
		dispatch(scheduleBoardActionCreators.ADD_TOOLBAR_EMPLOYEE(data));
	};
}

export function addToolbarEmployeeFromDate(data: ScheduleBoardToolbarEmployeeUpdate) {
	return (dispatch: Dispatch<scheduleBoardActionCreators.ScheduleBoardAction>) => {
		dispatch(scheduleBoardActionCreators.ADD_TOOLBAR_EMPLOYEE_FROM_DATE(data));
	};
}

export function removeToolbarEmployee(data: ScheduleBoardToolbarEmployeeUpdate) {
	return (dispatch: Dispatch<scheduleBoardActionCreators.ScheduleBoardAction>) => {
		dispatch(scheduleBoardActionCreators.REMOVE_TOOLBAR_EMPLOYEE(data));
	};
}

export function removeToolbarEmployeeFromDate(data: ScheduleBoardToolbarEmployeeUpdate) {
	return (dispatch: Dispatch<scheduleBoardActionCreators.ScheduleBoardAction>) => {
		dispatch(scheduleBoardActionCreators.REMOVE_TOOLBAR_EMPLOYEE_FROM_DATE(data));
	};
}

export function removeMultipleToolbarEmployee(data: ScheduleBoardToolbarEmployeeUpdate[]) {
	return (dispatch: Dispatch<scheduleBoardActionCreators.ScheduleBoardAction>) => {
		dispatch(scheduleBoardActionCreators.REMOVE_MULTIPLE_TOOLBAR_EMPLOYEE(data));
	};
}

export function addToolbarEquipment(data: ScheduleBoardToolbarEquipmentUpdate) {
	return (dispatch: Dispatch<scheduleBoardActionCreators.ScheduleBoardAction>) => {
		dispatch(scheduleBoardActionCreators.ADD_TOOLBAR_EQUIPMENT(data));
	};
}

export function addToolbarEquipmentDownDetails(data: AddEquipmentDownDetails) {
	return (dispatch: Dispatch<scheduleBoardActionCreators.ScheduleBoardAction>) => {
		dispatch(scheduleBoardActionCreators.ADD_EQUIPMENT_DOWN_DETAILS(data));
	};
}

export function addToolbarEmployeeDownDetails(data: EmployeeUnavailabilityDetailsVM) {
	return (dispatch: Dispatch<scheduleBoardActionCreators.ScheduleBoardAction>) => {
		dispatch(scheduleBoardActionCreators.ADD_EMPLOYEE_DOWN_DETAILS(data));
	};
}

export function removeToolbarEquipment(data: ScheduleBoardToolbarEquipmentUpdate) {
	return (dispatch: Dispatch<scheduleBoardActionCreators.ScheduleBoardAction>) => {
		dispatch(scheduleBoardActionCreators.REMOVE_TOOLBAR_EQUIPMENT(data));
	};
}

export function removeMultipleToolbarEquipment(data: ScheduleBoardToolbarEquipmentUpdate[]) {
	return (dispatch: Dispatch<scheduleBoardActionCreators.ScheduleBoardAction>) => {
		dispatch(scheduleBoardActionCreators.REMOVE_MULTIPLE_TOOLBAR_EQUIPMENT(data));
	};
}

export function addToolbarEmployeeForAllDays(data: ScheduleBoardToolbarEmployeeUpdate) {
	return (dispatch: Dispatch<scheduleBoardActionCreators.ScheduleBoardAction>) => {
		dispatch(scheduleBoardActionCreators.ADD_TOOLBAR_EMPLOYEE_FOR_FOR_ALL_DAYS(data));
	};
}

export function removeToolbarEmployeeForAllDays(data: ScheduleBoardToolbarEmployeeUpdate) {
	return (dispatch: Dispatch<scheduleBoardActionCreators.ScheduleBoardAction>) => {
		dispatch(scheduleBoardActionCreators.REMOVE_TOOLBAR_EMPLOYEE_FOR_ALL_DAYS(data));
	};
}

export function addToolbarEquipmentForAllDays(data: ScheduleBoardToolbarEquipmentUpdate) {
	return (dispatch: Dispatch<scheduleBoardActionCreators.ScheduleBoardAction>) => {
		dispatch(scheduleBoardActionCreators.ADD_TOOLBAR_EQUIPMENT_FOR_ALL_DAYS(data));
	};
}

export function removeToolbarEquipmentForAllDays(data: ScheduleBoardToolbarEquipmentUpdate) {
	return (dispatch: Dispatch<scheduleBoardActionCreators.ScheduleBoardAction>) => {
		dispatch(scheduleBoardActionCreators.REMOVE_TOOLBAR_EQUIPMENT_FOR_ALL_DAYS(data));
	};
}

export function addWorkOrderResourceLookupToDict(data: ScheduleBoardAddResourceLookupAssignment) {
	return (dispatch: Dispatch<scheduleBoardActionCreators.ScheduleBoardAction>) => {
		dispatch(scheduleBoardActionCreators.ADD_WORK_ORDER_RESOURCE_LOOKUP_TO_DICTIONARY(data));
	};
}

export function addTemporaryEmployee(data: ScheduleBoardTemporaryEmployeeVM) {
	return (dispatch: Dispatch<scheduleBoardActionCreators.ScheduleBoardAction>) => {
		dispatch(scheduleBoardActionCreators.ADD_TEMPORARY_EMPLOYEE(data));
	};
}

export function removeAllWorkOrderEmployees(data: ScheduleBoardRemoveAllWorkOrderEmployeesVM) {
	return (dispatch: Dispatch<scheduleBoardActionCreators.ScheduleBoardAction>) => {
		dispatch(scheduleBoardActionCreators.REMOVE_ALL_WORK_ORDER_EMPLOYEES(data));
	};
}

export function removeAllWorkOrderEmployeeOnDrafts(data: ScheduleBoardRemoveAllWorkOrderEmployeesVM) {
	return (dispatch: Dispatch<scheduleBoardActionCreators.ScheduleBoardAction>) => {
		dispatch(scheduleBoardActionCreators.REMOVE_ALL_WORK_ORDER_EMPLOYEE_ON_DRAFTS(data));
	};
}

export function removeAllWorkOrderEquipment(data: ScheduleBoardRemoveAllWorkOrderEquipmentVM) {
	return (dispatch: Dispatch<scheduleBoardActionCreators.ScheduleBoardAction>) => {
		dispatch(scheduleBoardActionCreators.REMOVE_ALL_WORK_ORDER_EQUIPMENT(data));
	};
}

export function removeAllWorkOrderEquipmentOnDrafts(data: ScheduleBoardRemoveAllWorkOrderEquipmentVM) {
	return (dispatch: Dispatch<scheduleBoardActionCreators.ScheduleBoardAction>) => {
		dispatch(scheduleBoardActionCreators.REMOVE_ALL_WORK_ORDER_EQUIPMENT_ON_DRAFTS(data));
	};
}

export function updateEmployee(data: ScheduleBoardEmployee) {
	return (dispatch: Dispatch<scheduleBoardActionCreators.ScheduleBoardAction>) => {
		dispatch(scheduleBoardActionCreators.UPDATE_EMPLOYEE(data));
	};
}

export function updateTemporaryEmployee(data: ScheduleBoardTemporaryEmployee) {
	return (dispatch: Dispatch<scheduleBoardActionCreators.ScheduleBoardAction>) => {
		dispatch(scheduleBoardActionCreators.UPDATE_TEMPORARY_EMPLOYEE(data));
	};
}

export function updateEquipment(data: ScheduleBoardEquipment) {
	return (dispatch: Dispatch<scheduleBoardActionCreators.ScheduleBoardAction>) => {
		dispatch(scheduleBoardActionCreators.UPDATE_EQUIPMENT(data));
	};
}

export function setZoomLevel(zoomLevel: number) {
	return (dispatch: Dispatch<scheduleBoardActionCreators.ScheduleBoardAction>) => {
		dispatch(scheduleBoardActionCreators.SET_SCHEDULE_BOARD_ZOOM_LEVEL(zoomLevel));
	};
}

export function lockWorkOrder(data: WorkOrderIdAndDueDate) {
	return (dispatch: Dispatch<scheduleBoardActionCreators.ScheduleBoardAction>) => {
		dispatch(scheduleBoardActionCreators.LOCK_WORK_ORDER_ON_SCHEDULE_BOARD(data));
	};
}

export function unlockWorkOrder(data: WorkOrderIdAndDueDate) {
	return (dispatch: Dispatch<scheduleBoardActionCreators.ScheduleBoardAction>) => {
		dispatch(scheduleBoardActionCreators.UNLOCK_WORK_ORDER_ON_SCHEDULE_BOARD(data));
	};
}

export function findToolbarEmployeeById(employeeId: number, dueDate: string) {
	return async () => {
		return await http.get<ScheduleBoardEmployeeModalVM>(API.V1.SCHEDULE_BOARD.FIND_TOOLBAR_EMPLOYEE(employeeId, dueDate));
	};
}

export function findWorkOrderEmployeeById(workOrderEmployeeId: number, dueDate: string) {
	return async () => {
		return await http.get<ScheduleBoardEmployeeModalVM>(API.V1.SCHEDULE_BOARD.FIND_WORK_ORDER_EMPLOYEE(workOrderEmployeeId, dueDate));
	};
}

/**
 *
 * @param dueDate 'YYYY-MM-DD'
 */
export function findWorkOrderTemporaryEmployeeById(workOrderTemporaryEmployeeId: number, dueDate: string) {
	return async () => {
		return await http.get<ScheduleBoardTemporaryEmployeeModalVM>(
			API.V1.SCHEDULE_BOARD.FIND_WORK_ORDER_TEMPORARY_EMPLOYEE(workOrderTemporaryEmployeeId, dueDate)
		);
	};
}

export function findToolbarEquipmentById(equipmentId: number, dueDate: string) {
	return async () => {
		return await http.get<ScheduleBoardEquipmentModalVM>(API.V1.SCHEDULE_BOARD.FIND_TOOLBAR_EQUIPMENT(equipmentId, dueDate));
	};
}

export function findWorkOrderEquipmentById(workOrderEquipmentId: number, dueDate: string) {
	return async () => {
		return await http.get<ScheduleBoardEquipmentModalVM>(API.V1.SCHEDULE_BOARD.FIND_WORK_ORDER_EQUIPMENT(workOrderEquipmentId, dueDate));
	};
}

export function setLaborModalVisibility(open: boolean) {
	return (dispatch: Dispatch<scheduleBoardActionCreators.ScheduleBoardAction>) => {
		dispatch(scheduleBoardActionCreators.SET_LABOR_MODAL_VISIBILITY(open));
	};
}

export function setLaborModalData(data: LaborResourceModal) {
	return (dispatch: Dispatch<scheduleBoardActionCreators.ScheduleBoardAction>) => {
		dispatch(scheduleBoardActionCreators.SET_LABOR_MODAL_DATA(data));
	};
}

export function setEquipmentPlaceholderModalVisibility(open: boolean) {
	return (dispatch: Dispatch<scheduleBoardActionCreators.ScheduleBoardAction>) => {
		dispatch(scheduleBoardActionCreators.SET_EQUIPMENT_PLACEHOLDER_MODAL_VISIBILITY(open));
	};
}

export function setEquipmentPlaceholderModalData(data: ResourcePlaceholderModal) {
	return (dispatch: Dispatch<scheduleBoardActionCreators.ScheduleBoardAction>) => {
		dispatch(scheduleBoardActionCreators.SET_EQUIPMENT_PLACEHOLDER_MODAL_DATA(data));
	};
}

export function downloadRevisionAttachment(storageName: string, name: string) {
	return async (dispatch: Dispatch<AnyAction>, getState: GetRootState, { redirectTo }) => {

		const action = async () => {
			const data = await http.get(API.V1.WORK_ORDER.REVISIONS.ATTACHMENT_DOWNLOAD(storageName, name), { responseType: 'blob' });
			saveAs(data, name);
		};

		return await errorHandler(action, dispatch, redirectTo);
	};
}

export function setCopiedEmployeeId(id: Nullable<number>) {
	return (dispatch: Dispatch<scheduleBoardActionCreators.ScheduleBoardAction>) => {
		dispatch(scheduleBoardActionCreators.SET_COPIED_EMPLOYEE(id));
	};
}

export function setCopiedEquipmentId(id: Nullable<number>) {
	return (dispatch: Dispatch<scheduleBoardActionCreators.ScheduleBoardAction>) => {
		dispatch(scheduleBoardActionCreators.SET_COPIED_EQUIPMENT(id));
	};
}

export function setCopiedPlaceholderId(id: Nullable<number>) {
	return (dispatch: Dispatch<scheduleBoardActionCreators.ScheduleBoardAction>) => {
		dispatch(scheduleBoardActionCreators.SET_COPIED_PLACEHOLDER(id));
	};
}

export function setCopiedTemporaryEmployeeId(id: Nullable<number>) {
	return (dispatch: Dispatch<scheduleBoardActionCreators.ScheduleBoardAction>) => {
		dispatch(scheduleBoardActionCreators.SET_COPIED_TEMPORARY_EMPLOYEE(id));
	};
}

export function setWeeklyViewHorizontalScrollingPercentage(scrollPercentage: number) {
	return (dispatch: Dispatch<scheduleBoardActionCreators.ScheduleBoardAction>) => {
		dispatch(scheduleBoardActionCreators.SET_WEEKLY_VIEW_HORIZONTAL_SCROLLING_PERCENTAGE(scrollPercentage));
	};
}

export function setMetricsHorizontalScrollingPercentage(scrollPercentage: number) {
	return (dispatch: Dispatch<scheduleBoardActionCreators.ScheduleBoardAction>) => {
		dispatch(scheduleBoardActionCreators.SET_METRICS_HORIZONTAL_SCROLLING_PERCENTAGE(scrollPercentage));
	};
}

export function setCopiedWorkOrderCode(code: string) {
	return (dispatch: Dispatch<scheduleBoardActionCreators.ScheduleBoardAction>) => {
		dispatch(scheduleBoardActionCreators.SET_COPIED_WORK_ORDER(code));
	};
}

export function setCopiedResourcePlaceholder(dueDate: string, code: string | undefined, index?: number, clearPlaceholder?: boolean) {
	return (dispatch: Dispatch<scheduleBoardActionCreators.ScheduleBoardAction>) => {
		dispatch(scheduleBoardActionCreators.SET_COPIED_RESOURCE_PLACEHOLDER(dueDate, code, index, clearPlaceholder));
	};
}

export function setCopiedWorkOrderPlaceholder(dueDate: string, code: Nullable<string>, index?: number) {
	return (dispatch: Dispatch<scheduleBoardActionCreators.ScheduleBoardAction>) => {
		dispatch(scheduleBoardActionCreators.SET_COPIED_WORK_ORDER_PLACEHOLDER(dueDate, code, index));
	};
}

export function setWeeklyViewDateWithToolbar(dueDate: Nullable<string>) {
	return (dispatch: Dispatch<scheduleBoardActionCreators.ScheduleBoardAction>) => {
		dispatch(scheduleBoardActionCreators.SET_WEEKLY_VIEW_DATE_WITH_TOOLBAR(dueDate));
	};
}

export function toggleWeeklyViewSelectMultiple(dueDate: string) {
	return (dispatch: Dispatch<scheduleBoardActionCreators.ScheduleBoardAction>) => {
		dispatch(scheduleBoardActionCreators.TOGGLE_WEEKLY_VIEW_SELECT_MULTIPLE(dueDate));
	};
}

export function toggleWorkOrderWeeklyView(dueDate: string, workOrder: SelectedWorkOrderModel) {
	return (dispatch: Dispatch<scheduleBoardActionCreators.ScheduleBoardAction>) => {
		dispatch(scheduleBoardActionCreators.TOGGLE_WORK_ORDER_ON_WEEKLY_VIEW(dueDate, workOrder));
	};
}

export function toggleDayWeeklyView(dueDate: string, value: boolean) {
	return (dispatch: Dispatch<scheduleBoardActionCreators.ScheduleBoardAction>) => {
		dispatch(scheduleBoardActionCreators.TOGGLE_DAY_ON_WEEKLY_VIEW(dueDate, value));
	};
}

export function syncWorkOrderEmployeeNotificationStatus(data: NotificationLookupViewModel) {
	return (dispatch: Dispatch<scheduleBoardActionCreators.SyncNotificationStatus>) => {
		dispatch(scheduleBoardActionCreators.SYNC_NOTIFICATION_STATUS(data));
	};
}

export function saveWorkOrdersMetrics(form: ScheduleBoardMetricsRequestModel) {
	return async (dispatch: Dispatch<AnyAction>, getState: GetRootState, { redirectTo }) => {

		const action = async (): Promise<void> => {
			await http.put(API.V1.WORK_ORDER.SAVE_METRICS, form);
		};

		return await errorHandler(action, dispatch, redirectTo);
	};
}

export function updateWorkOrderHasAttachments(data: ScheduleBoardUpdateWorkOrderHasAttachmentsVM) {
	return (dispatch: Dispatch<scheduleBoardActionCreators.UpdateWorkOrderHasAttachments>) => {
		dispatch(scheduleBoardActionCreators.UPDATE_WORK_ORDER_HAS_ATTACHMENTS(data));
	};
}

export function updateWorkOrderNote(data: ScheduleBoardUpdateWorkOrderNoteViewModel) {
	return (dispatch: Dispatch<FormAction | scheduleBoardActionCreators.UpdateWorkOrderNote>) => {
		dispatch(scheduleBoardActionCreators.UPDATE_WORK_ORDER_NOTE(data));
	};
}

export function setRefreshModalVisibility(open: boolean) {
	return (dispatch: Dispatch<scheduleBoardActionCreators.SetRefreshModalVisibility>) => {
		dispatch(scheduleBoardActionCreators.SET_REFRESH_MODAL_VISIBILITY(open));
	};
}

export function confirmSmsNotification(personalPublicLink: string) {
	return async (dispatch: Dispatch<AnyAction>, getState: GetRootState, { redirectTo }) => {

		const action = async () => {
			return await http.get<string>(API.V1.SCHEDULE_BOARD.SMS_NOTIFICATION_CONFIRMATION(personalPublicLink));
		};

		return await errorHandler(action, dispatch, redirectTo);
	};
}

export function refreshAvailableEmployeesNotificationModal() {
	return (dispatch: Dispatch<scheduleBoardActionCreators.RefreshAvailableEmployeesNotificationModal>) => {
		dispatch(scheduleBoardActionCreators.REFRESH_AVAILABLE_EMPLOYEES_NOTIFICATION_MODAL());
	};
}

export function openAvailableEmployeesNotificationModal(groupId: number, dueDate: string) {
	return (dispatch: Dispatch<scheduleBoardActionCreators.OpenAvailableEmployeesNotificationModal>) => {
		dispatch(scheduleBoardActionCreators.OPEN_AVAILABLE_EMPLOYEES_NOTIFICATION_MODAL({ groupId, dueDate }));
	};
}

export function closeAvailableEmployeesNotificationModal() {
	return (dispatch: Dispatch<scheduleBoardActionCreators.CloseAvailableEmployeesNotificationModal>) => {
		dispatch(scheduleBoardActionCreators.CLOSE_AVAILABLE_EMPLOYEES_NOTIFICATION_MODAL());
	};
}

export function checkEmployeeInAvailableEmployeeModal(payload: CheckEmployeeInAvailableEmployeesNotificationModal) {
	return (dispatch: Dispatch<scheduleBoardActionCreators.CheckEmployeeInAvailableEmployeeModal>) => {
		dispatch(scheduleBoardActionCreators.CHECK_EMPLOYEE_IN_AVAILABLE_EMPLOYEE_MODAL(payload));
	};
}

export function checkAllInAvailableEmployeeModal(payload: CheckAllInAvailableEmployeesNotificationModal) {
	return (dispatch: Dispatch<scheduleBoardActionCreators.CheckAllInAvailableEmployeeModal>) => {
		dispatch(scheduleBoardActionCreators.CHECK_ALL_IN_AVAILABLE_EMPLOYEE_MODAL(payload));
	};
}

export function resetAllInAvailableEmployeeModal(payload: string) {
	return (dispatch: Dispatch<scheduleBoardActionCreators.ResetAllInAvailableEmployeeModal>) => {
		dispatch(scheduleBoardActionCreators.RESET_ALL_IN_AVAILABLE_EMPLOYEE_MODAL(payload));
	};
}

export function changeWorkOrderSort(workOrdersSort: ScheduleBoardSortType) {
	return (dispatch: Dispatch<scheduleBoardActionCreators.ScheduleBoardAction>) => {
		dispatch(scheduleBoardActionCreators.CHANGE_WORK_ORDERS_SORT(workOrdersSort));
	};
}

export function setLaborStatistics(payload: ScheduleBoardLaborStatisticsForDay) {
	return (dispatch: Dispatch<scheduleBoardActionCreators.ScheduleBoardAction>) => {
		dispatch(scheduleBoardActionCreators.SET_SCHEDULE_BOARD_LABOR_STATISTICS(payload));
	};
}

type OnDragStartReturn = {
	dragElementType: DailyViewDragElementType;
	dragElement: Nullable<ScheduleBoardDragRequestModel>;
};
export function onDragStart(
	/** if null, calculated from source */
	sourceDueDate: Nullable<string>,
	{ source, draggableId }: DragStart
) {
	return (dispatch: Dispatch<scheduleBoardActionCreators.ScheduleBoardAction>, getState: GetRootState): OnDragStartReturn => {
		// NOTE: only one action should be dispatched from this function for sake of performance

		const dueDate = sourceDueDate ?? ScheduleBoardUtil.getDueDateFromDroppableId(source.droppableId);
		if (!dueDate) {
			throw new Error('Due date not defined');
		}

		const result: OnDragStartReturn = {
			dragElementType: DailyViewDragElementType.INVALID,
			dragElement: null,
		};

		if (ScheduleBoardUtil.isDefaultLaborPlaceholder(draggableId)) {
			result.dragElementType = DailyViewDragElementType.DEFAULT_LABOR_PLACEHOLDER;
			dispatch(scheduleBoardActionCreators.SET_DRAGGING_LABOR_PLACEHOLDER(true));
			return result;
		}
		if (ScheduleBoardUtil.isDefaultEquipmentPlaceholder(draggableId)) {
			result.dragElementType = DailyViewDragElementType.DEFAULT_EQUIPMENT_PLACEHOLDER;
			dispatch(scheduleBoardActionCreators.SET_DRAGGING_EQUIPMENT_PLACEHOLDER(true));
			return result;
		}

		const scheduleBoardState = getState().scheduleBoard;
		const itemId = ScheduleBoardUtil.findItemId(scheduleBoardState, source.droppableId, source.index, draggableId);
		if (itemId === undefined) {
			throw new Error('Item not defined');
		}

		result.dragElement = {
			itemId: `${itemId}`,
			originalItemId: `${itemId}`,
			sourceDroppableId: source.droppableId,
			dueDate,
		};

		if (ScheduleBoardUtil.isWorkOrder(source.droppableId)) {
			result.dragElementType = DailyViewDragElementType.WORK_ORDER;
			dispatch(scheduleBoardActionCreators.SET_SCHEDULE_BOARD_DRAGGED_WORK_ORDER_CODE(`${itemId}`));
			return result;
		}
		if (ScheduleBoardUtil.isResource(source.droppableId)) {
			result.dragElementType = DailyViewDragElementType.INVALID_RESOURCE;
			const resourceId = +itemId;
			const resource = scheduleBoardState.workOrdersByDateDictionary[dueDate].workOrderResourceLookups[resourceId]
				?? scheduleBoardState.workOrdersByDateDictionary[dueDate].canceledWorkOrderResourceLookups[resourceId];

			if (resource.workOrderEmployeeId && resource.employeeId) {
				result.dragElementType = DailyViewDragElementType.WORK_ORDER_EMPLOYEE;
				result.dragElement.itemId = resource.workOrderEmployeeId;
				dispatch(scheduleBoardActionCreators.SET_SCHEDULE_BOARD_DRAGGED_WORK_ORDER_EMPLOYEE(resourceId, resource.employeeId));
				return result;
			}
			if (resource.workOrderEquipmentId && resource.equipmentId) {
				result.dragElementType = DailyViewDragElementType.WORK_ORDER_EQUIPMENT;
				result.dragElement.itemId = resource.workOrderEquipmentId;
				dispatch(scheduleBoardActionCreators.SET_SCHEDULE_BOARD_DRAGGED_WORK_ORDER_EQUIPMENT(resourceId, resource.equipmentId));
				return result;
			}
			if (resource.workOrderPlaceholderId) {
				result.dragElementType = DailyViewDragElementType.WORK_ORDER_PLACEHOLDER;
				result.dragElement.itemId = resource.workOrderPlaceholderId;
				dispatch(scheduleBoardActionCreators.SET_SCHEDULE_BOARD_DRAGGED_WORK_ORDER_PLACEHOLDER(resourceId, resource.workOrderPlaceholderId));
				return result;
			}
			if (resource.workOrderTemporaryEmployeeId && resource.temporaryEmployeeId) {
				result.dragElementType = DailyViewDragElementType.WORK_ORDER_TEMPORARY_EMPLOYEE;
				result.dragElement.itemId = resource.workOrderTemporaryEmployeeId;
				dispatch(scheduleBoardActionCreators.SET_SCHEDULE_BOARD_DRAGGED_WORK_ORDER_TEMPORARY_EMPLOYEE(resourceId, resource.temporaryEmployeeId));
				return result;
			}
			// this should never happen but left here to keep the logic consistent
			dispatch(scheduleBoardActionCreators.SET_SCHEDULE_BOARD_DRAGGED_RESOURCE_ID(resourceId));
			return result;
		}
		if (ScheduleBoardUtil.isEmployee(source.droppableId)) {
			result.dragElementType = DailyViewDragElementType.EMPLOYEE;
			dispatch(scheduleBoardActionCreators.SET_SCHEDULE_BOARD_DRAGGED_EMPLOYEE(+itemId));
			return result;
		}
		if (ScheduleBoardUtil.isEquipment(source.droppableId)) {
			result.dragElementType = DailyViewDragElementType.EQUIPMENT;
			dispatch(scheduleBoardActionCreators.SET_SCHEDULE_BOARD_DRAGGED_EQUIPMENT(+itemId));
			return result;
		}
		// this should never happen
		return result;
	};
}

type ConfirmationType = { [K in keyof OnDragEndReturn]?: (value: OnDragEndReturn[K]) => boolean; };
type OnDragEndReturn = {
	dragElementType: DailyViewDragElementType;
	dragElement: Nullable<ScheduleBoardDragRequestModel>;
	sourceDueDate: string;
	destinationDueDate: Nullable<string>;
	copiedWorkOrderId: Nullable<number>;
	destinationWorkOrderId: Nullable<number>;
	isRealCopy: boolean;	// indicates that an element was created from an assigned element and not the toolbar
};
export function onDragEnd(
	/** if null, dates are calculated from source and destination */
	sourceAndDestinationDueDate: Nullable<string>,
	{ source, destination: dest, draggableId }: DropResult,
	isCopying: boolean,
	copiedWorkOrderCode: Nullable<string> = null,
	isSingleRow: boolean = false,
	needConfirmation: ConfirmationType = {} as ConfirmationType
) {
	return (dispatch: Dispatch<scheduleBoardActionCreators.ScheduleBoardAction>, getState: GetRootState): OnDragEndReturn => {
		// NOTE: only one action should be dispatched from this function for sake of performance
		// EXCEPTIONS: setCopiedResourcePlaceholder and setCopiedWorkOrderPlaceholder

		const sourceDueDate = sourceAndDestinationDueDate ?? ScheduleBoardUtil.getDueDateFromDroppableId(source.droppableId);

		const scheduleBoardState = getState().scheduleBoard;

		const isCopyingWorkOrder = isCopying && copiedWorkOrderCode !== null;

		const sourceIndex = source.index;
		const sourceColumnIndex = !isSingleRow ? ScheduleBoardUtil.getColumnIndexFromDroppableId(source.droppableId) : 0;
		const sourceWorkOrdersOnDate = sourceDueDate && scheduleBoardState.workOrdersByDateDictionary[sourceDueDate];
		const isSourceWorkOrderCard = ScheduleBoardUtil.isWorkOrder(source.droppableId);
		const isSourceResource = ScheduleBoardUtil.isResource(source.droppableId);
		const isSourceEmployee = ScheduleBoardUtil.isEmployee(source.droppableId);
		const isSourceEquipment = ScheduleBoardUtil.isEquipment(source.droppableId);
		const isSourceToolbar = !ScheduleBoardUtil.isOnBoard(source.droppableId);
		const sourceUniqueCode = ScheduleBoardUtil.getUniqueCodeFromDroppableId(source.droppableId);
		const sourceWorkOrder = sourceUniqueCode && sourceWorkOrdersOnDate ? sourceWorkOrdersOnDate.workOrders[sourceUniqueCode] : null;

		let destination = dest;
		if (isSourceResource
			&& (sourceWorkOrder?.status && DRAGGING_DISABLED_WO_STATUSES[sourceWorkOrder?.status])
			&& !isCopying
		) {
			destination = source;
		}

		const isSourceEqualToDestination = source && destination && ScheduleBoardUtil.isSourceEqualToDestination(source.droppableId, destination.droppableId);

		const destinationDueDate = sourceAndDestinationDueDate ?? (destination && ScheduleBoardUtil.getDueDateFromDroppableId(destination.droppableId)) ?? null;

		if (!sourceDueDate) {
			throw new Error('Source due date not provided');
		}

		const result: OnDragEndReturn = {
			dragElementType: DailyViewDragElementType.INVALID,
			dragElement: null,
			sourceDueDate,
			destinationDueDate,
			copiedWorkOrderId: null,
			destinationWorkOrderId: null,
			isRealCopy: false,
		};

		const destinationColumnIndex = (!isSingleRow && destination) ? ScheduleBoardUtil.getColumnIndexFromDroppableId(destination.droppableId) : 0;
		const destinationUniqueCode = destination && ScheduleBoardUtil.getUniqueCodeFromDroppableId(destination.droppableId);
		const destinationWorkOrdersOnDate = !!destinationDueDate ? scheduleBoardState.workOrdersByDateDictionary[destinationDueDate] : null;
		const destinationWorkOrder = destinationUniqueCode && destinationWorkOrdersOnDate?.workOrders?.[destinationUniqueCode];
		const destinationAvailability = destination && destinationUniqueCode && ScheduleBoardUtil.getToolbarAvailabilityFromUniqueCode(destinationUniqueCode);
		const isDestinationBoard = destination && ScheduleBoardUtil.isOnBoard(destination.droppableId);
		const isDestinationWorkOrderCard = destination && ScheduleBoardUtil.isWorkOrder(destination.droppableId);
		const isDestinationAvailableEquipment = destination
			&& !isDestinationWorkOrderCard
			&& ScheduleBoardUtil.isEquipment(destination.droppableId)
			&& !!destinationAvailability && ScheduleBoardUtil.isAvailable(destinationAvailability);
		const isDestinationAvailableEmployee = destination
			&& !isDestinationWorkOrderCard
			&& ScheduleBoardUtil.isEmployee(destination.droppableId)
			&& !!destinationAvailability && ScheduleBoardUtil.isAvailable(destinationAvailability);

		const destinationData: ScheduleBoardDragDestinationData | undefined = destination
			? {
				destinationDroppableId: destination.droppableId,
				destinationIndex: destination.index,
				dueDate: destinationDueDate,
			}
			: undefined;

		if (ScheduleBoardUtil.isDefaultLaborPlaceholder(draggableId)) {
			result.dragElementType = DailyViewDragElementType.DEFAULT_LABOR_PLACEHOLDER;
			dispatch(scheduleBoardActionCreators.SET_DRAGGING_LABOR_PLACEHOLDER(false, destinationData));
			return result;
		}
		if (ScheduleBoardUtil.isDefaultEquipmentPlaceholder(draggableId)) {
			result.dragElementType = DailyViewDragElementType.DEFAULT_EQUIPMENT_PLACEHOLDER;
			dispatch(scheduleBoardActionCreators.SET_DRAGGING_EQUIPMENT_PLACEHOLDER(false, destinationData));
			return result;
		}

		let destinationIndex: Nullable<number> = null;

		if (destination) {
			// only destinationIndex calculation inside this block
			const startIndexForRow = sourceColumnIndex !== destinationColumnIndex && !!destinationWorkOrdersOnDate
				? ScheduleBoardUtil.getStartIndexForRow(destinationWorkOrdersOnDate.workOrdersRowDistribution, destinationColumnIndex)
				: 0;
			destinationIndex = destination.index;

			if (isSourceWorkOrderCard) {
				destinationIndex += startIndexForRow;
			} else if (isDestinationAvailableEquipment) {
				const destinationAvailabilityCode = ScheduleBoardUtil.getToolbarCodeFromUniqueCode(destinationUniqueCode);
				const equipment: AvailableToolbarResources[number] = !!destinationAvailabilityCode && !!sourceWorkOrdersOnDate
					? sourceWorkOrdersOnDate.toolbarEquipment.available[destinationAvailabilityCode] || []
					: [];
				const maxEquipmentPerColumn = Math.max(1, Math.ceil(equipment.length / 2));
				destinationIndex += destinationColumnIndex * maxEquipmentPerColumn;
			} else if (isDestinationAvailableEmployee) {
				const destinationAvailabilityCode = ScheduleBoardUtil.getToolbarCodeFromUniqueCode(destinationUniqueCode);
				const employees: AvailableToolbarResources[number] = !!destinationAvailabilityCode && !!sourceWorkOrdersOnDate
					? sourceWorkOrdersOnDate.toolbarEmployees.available[destinationAvailabilityCode] || []
					: [];
				const maxEmployeesPerColumn = Math.max(1, Math.ceil(employees.length / 2));
				destinationIndex += destinationColumnIndex * maxEmployeesPerColumn;
			} else {
				destinationIndex += destinationColumnIndex * SCHEDULE_BOARD_MAX_ITEMS_IN_ROW;
			}

			if (!isDestinationAvailableEquipment && startIndexForRow > 0 && sourceIndex < destinationIndex && sourceIndex < startIndexForRow) {
				destinationIndex -= 1;
			}
		}

		if (destinationWorkOrder && destinationDueDate) {
			result.destinationWorkOrderId = destinationWorkOrder.id;
			const isAssignFromToolbar = isSourceToolbar && isDestinationBoard;
			const isWorkOrderResourceCopy = isCopying && !isCopyingWorkOrder && !isSourceEqualToDestination;
			result.isRealCopy = result.isRealCopy || isWorkOrderResourceCopy;

			if (isAssignFromToolbar || isWorkOrderResourceCopy) {
				setCopiedResourcePlaceholder(destinationDueDate, destinationWorkOrder.code, destinationIndex ?? undefined)(dispatch);
			}
		}
		if (destination && destinationDueDate && isCopyingWorkOrder && sourceWorkOrdersOnDate) {
			const copiedWorkOrder = sourceWorkOrdersOnDate.workOrders[copiedWorkOrderCode];
			result.copiedWorkOrderId = copiedWorkOrder.id;
			result.isRealCopy = true;
			// sets the placeholder until the server finishes the copy request
			// NOTE: placeholder is cleared in the SocketEvent.V10.BE.SCHEDULE_BOARD.COPY_MULTIPLE_WORK_ORDER handler
			setCopiedWorkOrderPlaceholder(destinationDueDate, copiedWorkOrder.code, destinationIndex ?? undefined)(dispatch);
		}

		const itemId: number | string | undefined = ScheduleBoardUtil.findItemId(scheduleBoardState, source.droppableId, sourceIndex, draggableId);
		result.dragElement = {
			itemId: `${itemId}`,
			originalItemId: `${itemId}`,
			sourceDroppableId: source.droppableId,
			sourceIndex,
			destinationDroppableId: destination ? destination.droppableId : '', // If dropped outside, return empty
			destinationIndex: destinationIndex ?? undefined,
			dueDate: sourceDueDate,
			isCopying,
		};

		if (isSourceWorkOrderCard) {
			result.dragElementType = DailyViewDragElementType.WORK_ORDER;
			dispatch(scheduleBoardActionCreators.CLEAR_SCHEDULE_BOARD_DRAGGED_WORK_ORDER(result.dragElement));
			return result;
		} else {
			const dragElementForEvent = { ...result.dragElement };
			if (isSourceResource) {
				if (itemId === undefined || !sourceWorkOrdersOnDate) {
					throw new Error('Item must be defined');
				}

				result.dragElementType = DailyViewDragElementType.INVALID_RESOURCE;
				const resourceId = +itemId;
				const resource = sourceWorkOrdersOnDate.workOrderResourceLookups[resourceId]
					?? sourceWorkOrdersOnDate.canceledWorkOrderResourceLookups[resourceId];

				if (resource.workOrderEmployeeId && resource.employeeId) {
					result.dragElementType = DailyViewDragElementType.WORK_ORDER_EMPLOYEE;
					result.dragElement!.itemId = resource.workOrderEmployeeId;
					result.dragElement!.originalItemId = resource.employeeId;
				} else if (resource.workOrderEquipmentId && resource.equipmentId) {
					result.dragElementType = DailyViewDragElementType.WORK_ORDER_EQUIPMENT;
					result.dragElement!.itemId = resource.workOrderEquipmentId;
					result.dragElement!.originalItemId = resource.equipmentId;
				} else if (resource.workOrderPlaceholderId) {
					result.dragElementType = DailyViewDragElementType.WORK_ORDER_PLACEHOLDER;
					result.dragElement!.itemId = resource.workOrderPlaceholderId;
				} else if (resource.workOrderTemporaryEmployeeId) {
					result.dragElementType = DailyViewDragElementType.WORK_ORDER_TEMPORARY_EMPLOYEE;
					result.dragElement!.itemId = resource.workOrderTemporaryEmployeeId;
				}
			} else if (isSourceEmployee) {
				result.dragElementType = DailyViewDragElementType.EMPLOYEE;
			} else if (isSourceEquipment) {
				result.dragElementType = DailyViewDragElementType.EQUIPMENT;
			}
			const updateNow = Object.keys(needConfirmation).some((_key) => !needConfirmation[_key](result[_key]));
			if (updateNow) {
				dispatch(scheduleBoardActionCreators.CLEAR_SCHEDULE_BOARD_DRAGGED_RESOURCE(dragElementForEvent));
			}
			return result;
		}
	};
}

export function onResourceDragEndConfirmation(result: ScheduleBoardDragRequestModel) {
	return (dispatch: Dispatch<scheduleBoardActionCreators.ScheduleBoardAction>): void => {
		dispatch(scheduleBoardActionCreators.CLEAR_SCHEDULE_BOARD_DRAGGED_RESOURCE(result));
	};
}

export function onEquipmentDragEndCancellation(result: ScheduleBoardDragRequestModel) {
	return (dispatch: Dispatch<scheduleBoardActionCreators.ScheduleBoardAction>): void => {
		dispatch(scheduleBoardActionCreators.SET_SCHEDULE_BOARD_DRAGGED_EQUIPMENT(null));
		dispatch(scheduleBoardActionCreators.ENABLE_SCHEDULE_BOARD_EQUIPMENT_ITEM(+result.originalItemId));
	};
}

export function onEmployeeDragEndCancellation(result: ScheduleBoardDragRequestModel) {
	return (dispatch: Dispatch<scheduleBoardActionCreators.ScheduleBoardAction>): void => {
		dispatch(scheduleBoardActionCreators.SET_SCHEDULE_BOARD_DRAGGED_EMPLOYEE(null));
		dispatch(scheduleBoardActionCreators.ENABLE_SCHEDULE_BOARD_EMPLOYEE_ITEM(+result.originalItemId));
	};
}

type LoadedDates = { [T: string]: boolean; };
export function getLoadedDates() {
	return (dispatch: Dispatch<scheduleBoardActionCreators.ScheduleBoardAction>, getState: GetRootState) => {
		// FIXME: this is a selector, not an action
		return Object.keys(getState().scheduleBoard.workOrdersByDateDictionary).reduce((_acc: LoadedDates, _d: string) => {
			_acc[_d] = true;
			return _acc;
		}, {});
	};
}

/** @param dueDate `YYYY-MM-DD` */
export function loadAllAvailableForMechanicView(dueDate: string) {
	return async (dispatch: Dispatch<AnyAction>, getState: GetRootState, { redirectTo }) => {

		const action = async () => {
			const equipment = await http.get<MechanicViewAvailableEquipmentDataVM[]>(
				API.V1.RESOURCES.EQUIPMENT.MECHANIC_VIEW_AVAILABLE(dueDate)
			);
			dispatch(scheduleBoardActionCreators.SET_MECHANIC_VIEW_AVAILABLE_EQUIPMENT(equipment));
		};

		return await errorHandler(action, dispatch, redirectTo);
	};
}

/** @param dueDate `YYYY-MM-DD` */
export function loadAllUnavailableForMechanicView(dueDate: string) {
	return async (dispatch: Dispatch<AnyAction>, getState: GetRootState, { redirectTo }) => {

		const action = async () => {
			const equipment = await http.get<MechanicViewUnavailableEquipmentDataVM[]>(
				API.V1.RESOURCES.EQUIPMENT.MECHANIC_VIEW_UNAVAILABLE(dueDate)
			);
			dispatch(scheduleBoardActionCreators.SET_MECHANIC_VIEW_UNAVAILABLE_EQUIPMENT(equipment));
		};

		return await errorHandler(action, dispatch, redirectTo);
	};
}

export function setMechanicViewSearchQuery(searchQuery: string) {
	return (dispatch: Dispatch<scheduleBoardActionCreators.ScheduleBoardAction>): void => {
		dispatch(scheduleBoardActionCreators.SET_MECHANIC_VIEW_SEARCH_QUERY(searchQuery));
	};
}

export function setMechanicViewFocusedSearchIndex(index: number) {
	return (dispatch: Dispatch<scheduleBoardActionCreators.ScheduleBoardAction>): void => {
		dispatch(scheduleBoardActionCreators.SET_MECHANIC_VIEW_FOCUSED_SEARCH_INDEX(index));
	};
}

export function findNightShiftWorkOrders(dueDate: string) {
	return async (dispatch: Dispatch<scheduleBoardActionCreators.ScheduleBoardAction>, getState: GetRootState, { redirectTo }) => {

		const action = async () => {
			return await http.get<ScheduleBoardNightShiftWorkOrder[]>(API.V1.SCHEDULE_BOARD.FIND_NIGHT_SHIFT_WORK_ORDERS(dueDate));
		};

		return await errorHandler(action, dispatch, redirectTo);
	};
}

export function addEmployeeNightShiftAssignment(data: EmployeeNightShiftAssignmentVM) {
	return (dispatch: Dispatch<scheduleBoardActionCreators.ScheduleBoardAction>): void => {
		dispatch(scheduleBoardActionCreators.ADD_EMPLOYEE_NIGHT_SHIFT_ASSIGNMENT(data));
	};
}

export function removeEmployeeNightShiftAssignment(data: EmployeeNightShiftAssignmentVM) {
	return (dispatch: Dispatch<scheduleBoardActionCreators.ScheduleBoardAction>): void => {
		dispatch(scheduleBoardActionCreators.REMOVE_EMPLOYEE_NIGHT_SHIFT_ASSIGNMENT(data));
	};
}

export function addTemporaryEmployeeNightShiftAssignment(data: TemporaryEmployeeNightShiftAssignmentVM) {
	return (dispatch: Dispatch<scheduleBoardActionCreators.ScheduleBoardAction>): void => {
		dispatch(scheduleBoardActionCreators.ADD_TEMPORARY_EMPLOYEE_NIGHT_SHIFT_ASSIGNMENT(data));
	};
}

export function removeTemporaryEmployeeNightShiftAssignment(data: TemporaryEmployeeNightShiftAssignmentVM) {
	return (dispatch: Dispatch<scheduleBoardActionCreators.ScheduleBoardAction>): void => {
		dispatch(scheduleBoardActionCreators.REMOVE_TEMPORARY_EMPLOYEE_NIGHT_SHIFT_ASSIGNMENT(data));
	};
}

export function updateCrewType(data: ScheduleBoardUpdateCrewType) {
	return (dispatch: Dispatch<scheduleBoardActionCreators.ScheduleBoardAction>): void => {
		dispatch(scheduleBoardActionCreators.UPDATE_CREW_TYPE(data));
	};
}

export function updateDailyPerDiemTip(data: DailyPerDiemTipViewModel) {
	return (dispatch: Dispatch<scheduleBoardActionCreators.ScheduleBoardAction>): void => {
		dispatch(scheduleBoardActionCreators.UPDATE_DAILY_PER_DIEM_TIP(data));
	};
}

export function updateDailyTip(data: DailyTipViewModel) {
	return (dispatch: Dispatch<scheduleBoardActionCreators.ScheduleBoardAction>): void => {
		dispatch(scheduleBoardActionCreators.UPDATE_DAILY_TIP(data));
	};
}
