import type Priority from 'acceligent-shared/enums/priority';
import TimeFormat from 'acceligent-shared/enums/timeFormat';

import * as TimeUtils from 'acceligent-shared/utils/time';

import type { EquipmentDownRequestModel } from 'ab-requestModels/equipmentDown.requestModel';

export default class DownEquipmentFM {
	id?: number;
	/* MM-DD-YYYY */
	dueDate: string;
	priority: Priority;
	unavailableStatusId: number;
	unavailabilityReason: Nullable<UnavailabilityReasonFM>;
	note: Nullable<string>;
	showFullForm: boolean;
	/* MM-DD-YYYY */
	returnDate: Nullable<string>;
	currentDate: string;

	static toForm(rm: Nullable<EquipmentDownRequestModel>): Nullable<DownEquipmentFM> {
		return rm
			? {
				id: rm.id,
				note: rm.downNotes,
				priority: rm.priority,
				dueDate: TimeUtils.formatDate(new Date(), TimeFormat.DATE_ONLY, TimeFormat.DB_DATE_ONLY),
				unavailableStatusId: rm.statusId,
				unavailabilityReason: rm.unavailabilityReason
					? { name: rm.unavailabilityReason }
					: null,
				returnDate: rm.returnDate && TimeUtils.formatDate(rm.returnDate, TimeFormat.DATE_ONLY, TimeFormat.DB_DATE_ONLY),
				showFullForm: true,
				currentDate: rm.currentDate,
			}
			: null;
	}
}

interface UnavailabilityReasonFM {
	name: string;
}
