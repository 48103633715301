import type { ColorPalette } from 'acceligent-shared/enums/color';

import type JobStatusBase from 'ab-domain/models/jobStatus/base';

export default class JobStatusVM {
	id: number;
	name: string;
	description: Nullable<string>;
	color: ColorPalette;

	constructor(jobStatus: JobStatusBase) {
		this.id = jobStatus.id;
		this.name = jobStatus.name;
		this.description = jobStatus.description;
		this.color = jobStatus.color;
	}

	static bulkConstructor(jobStatuses: JobStatusBase[] = []): JobStatusVM[] {
		return jobStatuses.map(JobStatusVM._constructorMap);
	}

	private static _constructorMap = (jobStatus: JobStatusBase) => new JobStatusVM(jobStatus);
}
