import * as React from 'react';

import type { EmployeeOptionVM } from 'ab-viewModels/employee/option.viewModel';

import { AccountPermissionTemplateLabel } from 'ab-enums/accountPermissionTemplates.enum';

interface Props {
	option: EmployeeOptionVM;
	showUserRole?: boolean;
}

const DropdownEmployeeItem: React.FC<Props> = (props: Props) => {
	const { option, showUserRole } = props;

	if (!option) {
		return (<div className="clear-option" />);
	}

	const { firstName, lastName, formattedCode } = option;

	return (
		<div>
			<div className="text-hidden">{`${firstName} ${lastName} (${formattedCode})`}</div>
			{showUserRole && <small>{AccountPermissionTemplateLabel[option.accountTemplate]}</small>}
		</div>
	);
};

export default React.memo(DropdownEmployeeItem);
