import * as React from 'react';

import type { TimeSplitEntryVM } from 'ab-viewModels/timeSheet/timeSheet.viewModel';

import { ColorHex } from 'acceligent-shared/enums/color';

interface OwnProps {
	timeSplitEntries: TimeSplitEntryVM[];
}

type Props = OwnProps;

const TimeSplitEquipment: React.FC<Props> = (props) => {
	const { timeSplitEntries } = props;

	const renderTimeSplitEquipment = React.useCallback((timeSplitEntry: TimeSplitEntryVM) => {
		const { id, equipmentCode, equipmentColor, equipmentSpecification } = timeSplitEntry;

		const backgroundColor = equipmentColor ? ColorHex[equipmentColor] : undefined;

		return (
			<div key={id}>
				<span className="time-sheet-list__row__equipment-color-block" style={{ backgroundColor }} />
				<span>{equipmentCode ?? ' '}</span>
				{!!equipmentSpecification && (
					<span className="time-sheet-list__row__equipment-specification">({equipmentSpecification})</span>
				)}
			</div>
		);
	}, []);

	return (
		<>{timeSplitEntries.map(renderTimeSplitEquipment)}</>
	);
};

export default React.memo(TimeSplitEquipment);
