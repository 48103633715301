import * as React from 'react';
import type { ConnectedProps } from 'react-redux';
import { connect } from 'react-redux';
import { Col, Row } from 'react-bootstrap';

import { ColorPalette } from 'acceligent-shared/enums/color';

import * as KPIActions from 'af-actions/kpi';

import type { LaborUtilizationChartsViewModel } from 'ab-viewModels/laborUtilization.viewModel';

import BarMeter from 'af-components/BarMeter';

import InfoCardChart from '../UtilizationShared/InfoCardChart';

import { moneyNormalizer, prettyMoney } from 'ab-utils/formatting.util';
import { normalizePercentages } from 'ab-utils/number.util';
import { bemBlock, bemElement } from 'ab-utils/bem.util';

import { MONEY_FORMATTER } from 'af-utils/chart.util';

interface OwnProps {
	daysAssigned: number;
	daysAvailable: number;
	daysUnavailable: number;
	totalDays: number;
	totalRevenue: number;
	targetProfit: number;
}

type Props = OwnProps & ConnectedProps<typeof connector>;

interface State {
	averageDailyRevenueChart: Nullable<LaborUtilizationChartsViewModel['averageDailyRevenueChart']>;
}

class InfoCard extends React.PureComponent<Props, State> {
	state: State = {
		averageDailyRevenueChart: null,
	};

	async componentDidMount() {
		const data = await this.props.fetchLaborUtilizationCharts();
		this.setState(() => ({ averageDailyRevenueChart: data?.averageDailyRevenueChart }));
	}

	render() {
		const { daysAssigned, daysAvailable, daysUnavailable, totalDays, totalRevenue, targetProfit } = this.props;
		const { averageDailyRevenueChart } = this.state;

		const entries = [
			{ label: 'Scheduled', value: daysAssigned, color: ColorPalette.GREEN },
			{ label: 'Not Scheduled', value: daysAvailable, color: ColorPalette.ORANGE },
			{ label: 'Not Available', value: daysUnavailable, color: ColorPalette.RED },
		];

		const [scheduledPercentage, notScheduledPercentage, notAvailablePercentage] = normalizePercentages([
			totalDays ? Math.round(100 * daysAssigned / totalDays) : 0,
			totalDays ? Math.round(100 * daysAvailable / totalDays) : 0,
			totalDays ? Math.round(100 * daysUnavailable / totalDays) : 0,
		]);

		return (
			<div className={`utilization-info-card ${bemBlock('form-box', ['padded-inv'])}`}>
				<Row>
					<Col lg={8} sm={12} xs={24}>
						<span className={bemElement('utilization-info-card', 'labor-title')}>Labor</span>
						<BarMeter entries={entries} total={totalDays} />
						<div className={bemElement('utilization-info-card', 'group')}>
							<div className={bemElement('utilization-info-card', 'group-entry', ['padded-right'])}>
								<span className="group-title">Scheduled:</span>
								<span className="group-value text-green">{scheduledPercentage}%</span>
							</div>
							<div className={bemElement('utilization-info-card', 'group-entry', ['padded-right'])}>
								<span className="group-title">Not Scheduled:</span>
								<span className="group-value text-orange">{notScheduledPercentage}%</span>
							</div>
							<div className={bemElement('utilization-info-card', 'group-entry', ['padded-right'])}>
								<span className="group-title">Not Available:</span>
								<span className="group-value text-red">{notAvailablePercentage}%</span>
							</div>
							<div className={bemElement('utilization-info-card', 'group-entry', ['padded-verticaly'])}>
								<span className="group-title">Total Revenue:{/* TODO: update label once costs are added to profit calculation */}</span>
								<span className="group-value">{moneyNormalizer(targetProfit)}</span>
							</div>
						</div>
					</Col>
					<Col className={bemElement('utilization-info-card', 'charts-row')} lg={16} sm={12} xs={24}>
						<div>
							{/** Average hours per month */}
						</div>
						<div>
							{/** Average MHA per month */}
						</div>
						<InfoCardChart
							chartData={averageDailyRevenueChart}
							footerLabel="Total Revenue:"
							footerValue={moneyNormalizer(totalRevenue)}
							hAxisFormatter={MONEY_FORMATTER}
							hAxisLabel="Month"
							title="Average Daily Revenue"
							vAxisFormatter={prettyMoney}
						/>
					</Col>
				</Row>
			</div>
		);
	}
}

function mapDispatchToProps() {
	return {
		fetchLaborUtilizationCharts: KPIActions.fetchLaborUtilizationCharts,
	};
}

const connector = connect(null, mapDispatchToProps());

export default connector<React.ComponentClass<OwnProps>>(InfoCard);
