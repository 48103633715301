import type EmployeeNightShiftAssignmentVM from 'acceligent-shared/dtos/socket/view/nightShift/nightShiftAssignment';
import type TemporaryEmployeeNightShiftAssignmentVM from 'acceligent-shared/dtos/socket/view/nightShift/temporaryEmployeeNighShiftAssignment';
import type { ScheduleBoardRemoveAllWorkOrderEmployeesVM, ScheduleBoardRemoveAllWorkOrderEquipmentVM } from 'acceligent-shared/dtos/socket/view/scheduleBoard/resources';
import type ScheduleBoardRemoveResourceAssignmentVM from 'acceligent-shared/dtos/socket/view/scheduleBoard/resources';
import type ScheduleBoardUpdateWorkOrderHasAttachmentsVM from 'acceligent-shared/dtos/socket/view/scheduleBoard/updateWorkOrderHasAttachments';

import type ScheduleBoardFilterVM from 'ab-viewModels/scheduleBoard/filter';

import type UnavailabilityReasonScope from 'acceligent-shared/enums/unavailabilityReasonScope';

import * as actionTypes from 'af-constants/actionTypes';

import type { ScheduleBoardRejoinViewModel } from 'ab-viewModels/scheduleBoard.viewModel';
import type ScheduleBoardViewModel from 'ab-viewModels/scheduleBoard.viewModel';
import type SocketConnectionCountViewModelModel from 'ab-viewModels/socketConnectionCount.viewModel';
import type ScheduleBoardAddResourceLookupAssignment from 'ab-viewModels/scheduleBoardAddResourceLookupAssignment.viewModel';
import type ScheduleBoardToolbarEquipmentUpdate from 'ab-socketModels/viewModels/scheduleBoard/scheduleBoardToolbarEquipmentUpdate.viewModel';
import type ScheduleBoardToolbarEmployeeUpdate from 'ab-socketModels/viewModels/scheduleBoard/scheduleBoardToolbarEmployeeUpdate.viewModel';
import type { ScheduleBoardDragDestinationData } from 'ab-requestModels/scheduleBoardDrag.requestModel';
import type ScheduleBoardDragRequestModel from 'ab-requestModels/scheduleBoardDrag.requestModel';
import type ScheduleBoardUnavailabilityReason from 'ab-viewModels/scheduleBoardUnavailabilityReason.viewModel';
import type WorkOrderCodeAndDueDate from 'ab-viewModels/workOrderCodeAndDueDate.viewModel';
import type WorkOrderIdAndDueDate from 'ab-viewModels/workOrderIdAndDueDate.viewModel';
import type ScheduleBoardUpdateWorkOrders from 'ab-viewModels/scheduleBoardUpdateWorkOrders.viewModel';
import type ScheduleBoardWorkOrderViewModel from 'ab-socketModels/viewModels/scheduleBoard/scheduleBoardWorkOrder.viewModel';
import type ScheduleBoardCopiedWorkOrderViewModel from 'ab-viewModels/scheduleBoardCopiedWorkOrder.viewModel';
import type ScheduleBoardEmployee from 'ab-viewModels/scheduleBoardEmployee.viewModel';
import type ScheduleBoardTemporaryEmployee from 'ab-viewModels/scheduleBoardTemporaryEmployee.viewModel';
import type ScheduleBoardEquipment from 'ab-viewModels/scheduleBoardEquipment.viewModel';
import type ScheduleBoardResourcesViewModel from 'ab-viewModels/scheduleBoardResources.viewModel';
import type { ScheduleBoardLaborStatisticsForDay } from 'ab-viewModels/scheduleBoardLaborStatistics.viewModel';
import type { NotificationLookupViewModel } from 'ab-viewModels/notification.viewModel';
import type AddEquipmentDownDetails from 'ab-viewModels/equipment/addEquipmentDownDetails.viewModel';
import type { MechanicViewAvailableEquipmentDataVM, MechanicViewUnavailableEquipmentDataVM } from 'ab-viewModels/mechanicView/mechanicView.viewModel';
import type { EmployeeUnavailabilityDetailsVM } from 'ab-viewModels/dailyEmployeeStatus.viewModel';
import type ScheduleBoardUpdateCrewType from 'ab-viewModels/scheduleBoardUpdateCrewType.viewModel';
import type { DailyPerDiemTipViewModel } from 'ab-viewModels/dailyPerDiemTip.viewModel';
import type { DailyTipViewModel } from 'ab-viewModels/dailyTip.viewModel';

import type ScheduleBoardDeleteWorkOrderViewModel from 'ab-socketModels/viewModels/scheduleBoard/deleteWorkOrder.viewModel';
import type ScheduleBoardReorderWorkOrdersViewModel from 'ab-socketModels/viewModels/scheduleBoard/reorderWorkOrders.viewModel';
import type ScheduleBoardUpdateWorkOrderNoteViewModel from 'ab-socketModels/viewModels/scheduleBoard/updateWorkOrderNote.viewModel';
import type ScheduleBoardPauseWorkOrderViewModel from 'ab-socketModels/viewModels/scheduleBoard/pauseWorkOrder.viewModel';
import type ScheduleBoardResumeWorkOrderViewModel from 'ab-socketModels/viewModels/scheduleBoard/resumeWorkOrder.viewModel';
import type ScheduleBoardLockWorkOrderViewModel from 'ab-socketModels/viewModels/scheduleBoard/lockWorkOrder.viewModel';
import type ScheduleBoardUnlockWorkOrderViewModel from 'ab-socketModels/viewModels/scheduleBoard/unlockWorkOrder.viewModel';
import type ScheduleBoardRevertWorkOrderViewModel from 'ab-socketModels/viewModels/scheduleBoard/revertWorkOrder.viewModel';

import type { SelectedWorkOrderModel, ResourcePlaceholderModal } from 'af-models/scheduleBoard.models';
import type {
	OpenAvailableEmployeesNotificationModalData,
	CheckEmployeeInAvailableEmployeesNotificationModal,
	CheckAllInAvailableEmployeesNotificationModal,
} from 'af-models/employeeNotifyModal.model';

import type { BlankWorkOrderRequestModel } from 'ab-requestModels/workOrder.requestModel';
import type ScheduleBoardEmployeePerDiemAssignmentRequestModel from 'ab-requestModels/scheduleBoardEmployeePerDiemAssignment.requestModel';
import type ScheduleBoardSetPerDiemForWorkOrdersRequestModel from 'ab-requestModels/scheduleBoardSetPerDiemsForWorkOrder.requestModel';
import type ScheduleBoardReturnDateRequestModel from 'ab-requestModels/scheduleBoardReturnDate.requestModel';

import type ScheduleBoardView from 'ab-enums/scheduleBoardView.enum';
import type ScheduleBoardSortType from 'ab-enums/scheduleBoardSortType.enum';
import type { SortByEnum, OrderByEnum } from 'ab-enums/mechanicView.enum';
import type ScheduleBoardTemporaryEmployeeVM from 'ab-viewModels/scheduleBoardTemporaryEmployee.viewModel';

export interface GetScheduleBoard {
	type: typeof actionTypes.GET_SCHEDULE_BOARD;
	payload: ScheduleBoardViewModel;
}

export interface GetScheduleBoardRejoin {
	type: typeof actionTypes.GET_SCHEDULE_BOARD_REJOIN;
	payload: ScheduleBoardRejoinViewModel;
}

export interface SetScheduleBoardView {
	type: typeof actionTypes.SET_SCHEDULE_BOARD_VIEW;
	payload: ScheduleBoardView;
}

export interface GetWeeklyViewScheduleBoard {
	type: typeof actionTypes.GET_WEEKLY_VIEW_SCHEDULE_BOARD;
	payload: ScheduleBoardViewModel;
}

export interface AddBlankWorkOrder {
	type: typeof actionTypes.ADD_BLANK_WORK_ORDER;
	payload: BlankWorkOrderRequestModel;
}

export interface RemoveBlankWorkOrder {
	type: typeof actionTypes.REMOVE_BLANK_WORK_ORDER;
	payload: BlankWorkOrderRequestModel;
}

export interface GetSocketConnectionCount {
	type: typeof actionTypes.GET_SOCKET_CONNECTION_COUNT;
	payload: SocketConnectionCountViewModelModel;
}

export interface UpdateScheduleBoardDroppableList {
	type: typeof actionTypes.UPDATE_SCHEDULE_BOARD_DROPPABLE_LIST;
	payload: ScheduleBoardDragRequestModel;
}

export interface AddToScheduleBoardDroppableList {
	type: typeof actionTypes.ADD_TO_SCHEDULE_BOARD_DROPPABLE_LIST;
	payload: ScheduleBoardDragRequestModel;
}

export interface UpdateScheduleBoardWorkOrders {
	type: typeof actionTypes.UPDATE_SCHEDULE_BOARD_WORK_ORDERS;
	payload: ScheduleBoardUpdateWorkOrders;
}

export interface SyncNotificationStatus {
	type: typeof actionTypes.SYNC_NOTIFICATION_STATUS;
	payload: NotificationLookupViewModel;
}

export interface RemoveScheduleBoardWorkOrder {
	type: typeof actionTypes.REMOVE_SCHEDULE_BOARD_WORK_ORDER;
	payload: ScheduleBoardDeleteWorkOrderViewModel;
}

export interface CancelScheduleBoardWorkOrder {
	type: typeof actionTypes.CANCEL_SCHEDULE_BOARD_WORK_ORDER;
	payload: ScheduleBoardDeleteWorkOrderViewModel;
}

export interface PauseScheduleBoardWorkOrder {
	type: typeof actionTypes.PAUSE_SCHEDULE_BOARD_WORK_ORDER;
	payload: ScheduleBoardPauseWorkOrderViewModel;
}

export interface ResumeScheduleBoardWorkOrder {
	type: typeof actionTypes.RESUME_SCHEDULE_BOARD_WORK_ORDER;
	payload: ScheduleBoardResumeWorkOrderViewModel;
}

export interface RevertScheduleBoardWorkOrder {
	type: typeof actionTypes.REVERT_SCHEDULE_BOARD_WORK_ORDER;
	payload: ScheduleBoardRevertWorkOrderViewModel;
}

export interface LockScheduleBoardWorkOrder {
	type: typeof actionTypes.LOCK_SCHEDULE_BOARD_WORK_ORDER;
	payload: ScheduleBoardLockWorkOrderViewModel;
}

export interface UnlockScheduleBoardWorkOrder {
	type: typeof actionTypes.UNLOCK_SCHEDULE_BOARD_WORK_ORDER;
	payload: ScheduleBoardUnlockWorkOrderViewModel;
}

export interface UpdateScheduleBoardDate {
	type: typeof actionTypes.UPDATE_SCHEDULE_BOARD_DATE;
	payload: string;
}

export interface UpdateScheduleBoardWeek {
	type: typeof actionTypes.UPDATE_SCHEDULE_BOARD_WEEK;
	payload: {
		startDate: string;
		endDate: string;
	};
}

export interface UpdateScheduleBoardQuery {
	type: typeof actionTypes.UPDATE_SCHEDULE_BOARD_QUERY;
	payload: string;
}

export interface ClearUnavailabilityReason {
	type: typeof actionTypes.CLEAR_UNAVAILABILITY_REASON;
	payload: {
		itemId: number;
		scope: UnavailabilityReasonScope;
		dueDate: string;
	};
}

export interface AssignUnavailabilityReason {
	type: typeof actionTypes.ASSIGN_UNAVAILABILITY_REASON;
	payload: ScheduleBoardUnavailabilityReason;
}

export interface ClearScheduleBoardDailyView {
	type: typeof actionTypes.CLEAR_SCHEDULE_BOARD_DAILY_VIEW;
	payload: null;
}

export interface ClearScheduleBoardWeeklyView {
	type: typeof actionTypes.CLEAR_SCHEDULE_BOARD_WEEKLY_VIEW;
	payload: null;
}

export interface DisableScheduleBoardEquipmentItem {
	type: typeof actionTypes.DISABLE_SCHEDULE_BOARD_EQUIPMENT_ITEM;
	payload: number;
}

export interface EnableScheduleBoardEquipmentItem {
	type: typeof actionTypes.ENABLE_SCHEDULE_BOARD_EQUIPMENT_ITEM;
	payload: number;
}

export interface DisableScheduleBoardEmployeeItem {
	type: typeof actionTypes.DISABLE_SCHEDULE_BOARD_EMPLOYEE_ITEM;
	payload: number;
}

export interface EnableScheduleBoardEmployeeItem {
	type: typeof actionTypes.ENABLE_SCHEDULE_BOARD_EMPLOYEE_ITEM;
	payload: number;
}

export interface DisableScheduleBoardTemporaryEmployeeItem {
	type: typeof actionTypes.DISABLE_SCHEDULE_BOARD_TEMPORARY_EMPLOYEE_ITEM;
	payload: number;
}

export interface EnableScheduleBoardTemporaryEmployeeItem {
	type: typeof actionTypes.ENABLE_SCHEDULE_BOARD_TEMPORARY_EMPLOYEE_ITEM;
	payload: number;
}

export interface DisableScheduleBoardWorkOrderItem {
	type: typeof actionTypes.DISABLE_SCHEDULE_BOARD_WORK_ORDER_ITEM;
	payload: WorkOrderCodeAndDueDate;
}

export interface EnableScheduleBoardWorkOrderItem {
	type: typeof actionTypes.ENABLE_SCHEDULE_BOARD_WORK_ORDER_ITEM;
	payload: WorkOrderCodeAndDueDate;
}

export interface AddEmployeeAssignment {
	type: typeof actionTypes.ADD_EMPLOYEE_ASSIGNMENT;
	payload: ScheduleBoardAddResourceLookupAssignment;
}

export interface RemoveEmployeeAssignment {
	type: typeof actionTypes.REMOVE_EMPLOYEE_ASSIGNMENT;
	payload: ScheduleBoardRemoveResourceAssignmentVM;
}

export interface AddEquipmentAssignment {
	type: typeof actionTypes.ADD_EQUIPMENT_ASSIGNMENT;
	payload: ScheduleBoardAddResourceLookupAssignment;
}

export interface RemoveEquipmentAssignment {
	type: typeof actionTypes.REMOVE_EQUIPMENT_ASSIGNMENT;
	payload: ScheduleBoardRemoveResourceAssignmentVM;
}

export interface AddPlaceholderAssignment {
	type: typeof actionTypes.ADD_PLACEHOLDER_ASSIGNMENT;
	payload: ScheduleBoardAddResourceLookupAssignment;
}

export interface RemovePlaceholderAssignment {
	type: typeof actionTypes.REMOVE_PLACEHOLDER_ASSIGNMENT;
	payload: ScheduleBoardRemoveResourceAssignmentVM;
}

export interface AddTemporaryEmployeeAssignment {
	type: typeof actionTypes.ADD_TEMPORARY_EMPLOYEE_ASSIGNMENT;
	payload: ScheduleBoardAddResourceLookupAssignment;
}

export interface RemoveTemporaryEmployeeAssignment {
	type: typeof actionTypes.REMOVE_TEMPORARY_EMPLOYEE_ASSIGNMENT;
	payload: ScheduleBoardRemoveResourceAssignmentVM;
}

export interface AddToolbarEmployee {
	type: typeof actionTypes.ADD_TOOLBAR_EMPLOYEE;
	payload: ScheduleBoardToolbarEmployeeUpdate;
}

export interface AddToolbarEmployeeFromDate {
	type: typeof actionTypes.ADD_TOOLBAR_EMPLOYEE_FROM_DATE;
	payload: ScheduleBoardToolbarEmployeeUpdate;
}

export interface RemoveToolbarEmployee {
	type: typeof actionTypes.REMOVE_TOOLBAR_EMPLOYEE;
	payload: ScheduleBoardToolbarEmployeeUpdate;
}

export interface RemoveToolbarEmployeeFromDate {
	type: typeof actionTypes.REMOVE_TOOLBAR_EMPLOYEE_FROM_DATE;
	payload: ScheduleBoardToolbarEmployeeUpdate;
}

export interface AddToolbarEquipment {
	type: typeof actionTypes.ADD_TOOLBAR_EQUIPMENT;
	payload: ScheduleBoardToolbarEquipmentUpdate;
}

export interface RemoveToolbarEquipment {
	type: typeof actionTypes.REMOVE_TOOLBAR_EQUIPMENT;
	payload: ScheduleBoardToolbarEquipmentUpdate;
}

export interface AddToolbarEmployeeForAllDays {
	type: typeof actionTypes.ADD_TOOLBAR_EMPLOYEE_FOR_ALL_DAYS;
	payload: ScheduleBoardToolbarEmployeeUpdate;
}

export interface RemoveToolbarEmployeeForAllDays {
	type: typeof actionTypes.REMOVE_TOOLBAR_EMPLOYEE_FOR_ALL_DAYS;
	payload: ScheduleBoardToolbarEmployeeUpdate;
}

export interface AddToolbarEquipmentForAllDays {
	type: typeof actionTypes.ADD_TOOLBAR_EQUIPMENT_FOR_ALL_DAYS;
	payload: ScheduleBoardToolbarEquipmentUpdate;
}

export interface RemoveToolbarEquipmentForAllDays {
	type: typeof actionTypes.REMOVE_TOOLBAR_EQUIPMENT_FOR_ALL_DAYS;
	payload: ScheduleBoardToolbarEquipmentUpdate;
}
export interface AddWorkOrderResourceLookupToDict {
	type: typeof actionTypes.ADD_WORK_ORDER_RESOURCE_LOOKUP_TO_DICTIONARY;
	payload: ScheduleBoardAddResourceLookupAssignment;
}

export interface AddTemporaryEmployee {
	type: typeof actionTypes.ADD_TEMPORARY_EMPLOYEE;
	payload: ScheduleBoardTemporaryEmployeeVM;
}

export interface RemoveAllWorkOrderEmployees {
	type: typeof actionTypes.REMOVE_ALL_WORK_ORDER_EMPLOYEES;
	payload: ScheduleBoardRemoveAllWorkOrderEmployeesVM;
}

export interface RemoveWorkOrderEmployeeOnDrafts {
	type: typeof actionTypes.REMOVE_ALL_WORK_ORDER_EMPLOYEE_ON_DRAFTS;
	payload: ScheduleBoardRemoveAllWorkOrderEmployeesVM;
}

export interface RemoveWorkOrderEquipment {
	type: typeof actionTypes.REMOVE_ALL_WORK_ORDER_EQUIPMENT;
	payload: ScheduleBoardRemoveAllWorkOrderEquipmentVM;
}

export interface RemoveWorkOrderEquipmentOnDrafts {
	type: typeof actionTypes.REMOVE_ALL_WORK_ORDER_EQUIPMENT_ON_DRAFTS;
	payload: ScheduleBoardRemoveAllWorkOrderEquipmentVM;
}

export interface UpdateEmployee {
	type: typeof actionTypes.UPDATE_EMPLOYEE;
	payload: ScheduleBoardEmployee;
}

export interface UpdateTemporaryEmployee {
	type: typeof actionTypes.UPDATE_TEMPORARY_EMPLOYEE;
	payload: ScheduleBoardTemporaryEmployee;
}

export interface UpdateEquipment {
	type: typeof actionTypes.UPDATE_EQUIPMENT;
	payload: ScheduleBoardEquipment;
}

export interface SetScheduleBoardDraggedWorkOrderCode {
	type: typeof actionTypes.SET_SCHEDULE_BOARD_DRAGGED_WORK_ORDER_CODE;
	payload: string;
}

export interface SetScheduleBoardDraggedResourceId {
	type: typeof actionTypes.SET_SCHEDULE_BOARD_DRAGGED_RESOURCE_ID;
	payload: number;
}

export interface SetScheduleBoardDraggedEmployee {
	type: typeof actionTypes.SET_SCHEDULE_BOARD_DRAGGED_EMPLOYEE;
	payload: Nullable<number>;
}

export interface SetScheduleBoardDraggedEquipment {
	type: typeof actionTypes.SET_SCHEDULE_BOARD_DRAGGED_EQUIPMENT;
	payload: Nullable<number>;
}

export interface SetScheduleBoardDraggedWorkOrderEmployee {
	type: typeof actionTypes.SET_SCHEDULE_BOARD_DRAGGED_WORK_ORDER_EMPLOYEE;
	payload: {
		resourceId: number;
		employeeId: number;
	};
}

export interface SetScheduleBoardDraggedWorkOrderEquipment {
	type: typeof actionTypes.SET_SCHEDULE_BOARD_DRAGGED_WORK_ORDER_EQUIPMENT;
	payload: {
		resourceId: number;
		equipmentId: number;
	};
}

export interface SetScheduleBoardDraggedWorkOrderPlaceholder {
	type: typeof actionTypes.SET_SCHEDULE_BOARD_DRAGGED_WORK_ORDER_PLACEHOLDER;
	payload: {
		resourceId: number;
		placeholderId: number;
	};
}

export interface SetScheduleBoardDraggedWorkOrderTemporaryEmployee {
	type: typeof actionTypes.SET_SCHEDULE_BOARD_DRAGGED_WORK_ORDER_TEMPORARY_EMPLOYEE;
	payload: {
		resourceId: number;
		temporaryEmployeeId: number;
	};
}

export interface SetScheduleBoardZoomLevel {
	type: typeof actionTypes.SET_SCHEDULE_BOARD_ZOOM_LEVEL;
	payload: number;
}

export interface LockWorkOrderOnScheduleBoard {
	type: typeof actionTypes.LOCK_WORK_ORDER_ON_SCHEDULE_BOARD;
	payload: WorkOrderIdAndDueDate;
}

export interface UnlockWorkOrderOnScheduleBoard {
	type: typeof actionTypes.UNLOCK_WORK_ORDER_ON_SCHEDULE_BOARD;
	payload: WorkOrderIdAndDueDate;
}

export interface ClearAllFilters {
	type: typeof actionTypes.CLEAR_ALL_SCHEDULE_BOARD_FILTERS;
	payload: null;
}

export interface ApplyFilters {
	type: typeof actionTypes.APPLY_SCHEDULE_BOARD_FILTERS;
	payload: ScheduleBoardFilterVM;
}

interface GetScheduleBoardResources {
	type: typeof actionTypes.GET_SCHEDULE_BOARD_RESOURCES;
	payload: ScheduleBoardResourcesViewModel;
}

interface ReloadResources {
	type: typeof actionTypes.RELOAD_SCHEDULE_BOARD_RESOURCES;
	payload: null;
}

export function RELOAD_SCHEDULE_BOARD_RESOURCES(): ReloadResources {
	return {
		type: actionTypes.RELOAD_SCHEDULE_BOARD_RESOURCES,
		payload: null,
	};
}

export function GET_SCHEDULE_BOARD_RESOURCES(payload: ScheduleBoardResourcesViewModel): GetScheduleBoardResources {
	return {
		type: actionTypes.GET_SCHEDULE_BOARD_RESOURCES,
		payload,
	};
}

export function GET_SCHEDULE_BOARD(payload: ScheduleBoardViewModel): GetScheduleBoard {
	return {
		type: actionTypes.GET_SCHEDULE_BOARD,
		payload,
	};
}

export function GET_SCHEDULE_BOARD_REJOIN(payload: ScheduleBoardRejoinViewModel): GetScheduleBoardRejoin {
	return {
		type: actionTypes.GET_SCHEDULE_BOARD_REJOIN,
		payload,
	};
}

export function SET_SCHEDULE_BOARD_VIEW(payload: ScheduleBoardView): SetScheduleBoardView {
	return {
		type: actionTypes.SET_SCHEDULE_BOARD_VIEW,
		payload,
	};
}

export function GET_WEEKLY_VIEW_SCHEDULE_BOARD(payload: ScheduleBoardViewModel): GetWeeklyViewScheduleBoard {
	return {
		type: actionTypes.GET_WEEKLY_VIEW_SCHEDULE_BOARD,
		payload,
	};
}

export function ADD_BLANK_WORK_ORDER(payload: BlankWorkOrderRequestModel): AddBlankWorkOrder {
	return {
		type: actionTypes.ADD_BLANK_WORK_ORDER,
		payload,
	};
}

export function REMOVE_BLANK_WORK_ORDER(payload: BlankWorkOrderRequestModel): RemoveBlankWorkOrder {
	return {
		type: actionTypes.REMOVE_BLANK_WORK_ORDER,
		payload,
	};
}

export function GET_SOCKET_CONNECTION_COUNT(payload: SocketConnectionCountViewModelModel): GetSocketConnectionCount {
	return {
		type: actionTypes.GET_SOCKET_CONNECTION_COUNT,
		payload,
	};
}

export function UPDATE_SCHEDULE_BOARD_DROPPABLE_LIST(payload: ScheduleBoardDragRequestModel): UpdateScheduleBoardDroppableList {
	return {
		type: actionTypes.UPDATE_SCHEDULE_BOARD_DROPPABLE_LIST,
		payload,
	};
}

export function ADD_TO_SCHEDULE_BOARD_DROPPABLE_LIST(payload: ScheduleBoardDragRequestModel): AddToScheduleBoardDroppableList {
	return {
		type: actionTypes.ADD_TO_SCHEDULE_BOARD_DROPPABLE_LIST,
		payload,
	};
}

export interface UpdateScheduleBoardWorkOrderIndices {
	type: typeof actionTypes.UPDATE_SCHEDULE_BOARD_WORK_ORDER_INDICES;
	payload: ScheduleBoardReorderWorkOrdersViewModel;
}
export function UPDATE_SCHEDULE_BOARD_WORK_ORDER_INDICES(payload: ScheduleBoardReorderWorkOrdersViewModel): UpdateScheduleBoardWorkOrderIndices {
	return {
		type: actionTypes.UPDATE_SCHEDULE_BOARD_WORK_ORDER_INDICES,
		payload,
	};
}

export function UPDATE_SCHEDULE_BOARD_WORK_ORDERS(payload: ScheduleBoardUpdateWorkOrders): UpdateScheduleBoardWorkOrders {
	return {
		type: actionTypes.UPDATE_SCHEDULE_BOARD_WORK_ORDERS,
		payload,
	};
}

export interface UpdateScheduleBoardWorkOrder {
	type: typeof actionTypes.UPDATE_SCHEDULE_BOARD_WORK_ORDER;
	payload: ScheduleBoardWorkOrderViewModel;
}
export function UPDATE_SCHEDULE_BOARD_WORK_ORDER(payload: ScheduleBoardWorkOrderViewModel): UpdateScheduleBoardWorkOrder {
	return {
		type: actionTypes.UPDATE_SCHEDULE_BOARD_WORK_ORDER,
		payload,
	};
}

export function SYNC_NOTIFICATION_STATUS(payload: NotificationLookupViewModel): SyncNotificationStatus {
	return {
		type: actionTypes.SYNC_NOTIFICATION_STATUS,
		payload,
	};
}

export function REMOVE_SCHEDULE_BOARD_WORK_ORDER(payload: ScheduleBoardDeleteWorkOrderViewModel): RemoveScheduleBoardWorkOrder {
	return {
		type: actionTypes.REMOVE_SCHEDULE_BOARD_WORK_ORDER,
		payload,
	};
}

export function CANCEL_SCHEDULE_BOARD_WORK_ORDER(payload: ScheduleBoardDeleteWorkOrderViewModel): CancelScheduleBoardWorkOrder {
	return {
		type: actionTypes.CANCEL_SCHEDULE_BOARD_WORK_ORDER,
		payload,
	};
}

export function PAUSE_SCHEDULE_BOARD_WORK_ORDER(payload: ScheduleBoardPauseWorkOrderViewModel): PauseScheduleBoardWorkOrder {
	return {
		type: actionTypes.PAUSE_SCHEDULE_BOARD_WORK_ORDER,
		payload,
	};
}

export function RESUME_SCHEDULE_BOARD_WORK_ORDER(payload: ScheduleBoardResumeWorkOrderViewModel): ResumeScheduleBoardWorkOrder {
	return {
		type: actionTypes.RESUME_SCHEDULE_BOARD_WORK_ORDER,
		payload,
	};
}

export function REVERT_SCHEDULE_BOARD_WORK_ORDER(payload: ScheduleBoardRevertWorkOrderViewModel): RevertScheduleBoardWorkOrder {
	return {
		type: actionTypes.REVERT_SCHEDULE_BOARD_WORK_ORDER,
		payload,
	};
}

export function LOCK_SCHEDULE_BOARD_WORK_ORDER(payload: ScheduleBoardLockWorkOrderViewModel): LockScheduleBoardWorkOrder {
	return {
		type: actionTypes.LOCK_SCHEDULE_BOARD_WORK_ORDER,
		payload,
	};
}

export function UNLOCK_SCHEDULE_BOARD_WORK_ORDER(payload: ScheduleBoardUnlockWorkOrderViewModel): UnlockScheduleBoardWorkOrder {
	return {
		type: actionTypes.UNLOCK_SCHEDULE_BOARD_WORK_ORDER,
		payload,
	};
}

export function UPDATE_SCHEDULE_BOARD_DATE(payload: string): UpdateScheduleBoardDate {
	return {
		type: actionTypes.UPDATE_SCHEDULE_BOARD_DATE,
		payload,
	};
}

export function UPDATE_SCHEDULE_BOARD_WEEK(startDate: string, endDate: string): UpdateScheduleBoardWeek {
	return {
		type: actionTypes.UPDATE_SCHEDULE_BOARD_WEEK,
		payload: {
			startDate,
			endDate,
		},
	};
}

export function UPDATE_SCHEDULE_BOARD_QUERY(payload: string): UpdateScheduleBoardQuery {
	return {
		type: actionTypes.UPDATE_SCHEDULE_BOARD_QUERY,
		payload,
	};
}

export function CLEAR_UNAVAILABILITY_REASON(itemId: number, scope: UnavailabilityReasonScope, dueDate: string): ClearUnavailabilityReason {
	return {
		type: actionTypes.CLEAR_UNAVAILABILITY_REASON,
		payload: {
			itemId,
			scope,
			dueDate,
		},
	};
}

export function ASSIGN_UNAVAILABILITY_REASON(payload: ScheduleBoardUnavailabilityReason): AssignUnavailabilityReason {
	return {
		type: actionTypes.ASSIGN_UNAVAILABILITY_REASON,
		payload,
	};
}

export function CLEAR_SCHEDULE_BOARD_DAILY_VIEW(): ClearScheduleBoardDailyView {
	return {
		type: actionTypes.CLEAR_SCHEDULE_BOARD_DAILY_VIEW,
		payload: null,
	};
}

export function CLEAR_SCHEDULE_BOARD_WEEKLY_VIEW(): ClearScheduleBoardWeeklyView {
	return {
		type: actionTypes.CLEAR_SCHEDULE_BOARD_WEEKLY_VIEW,
		payload: null,
	};
}

export function DISABLE_SCHEDULE_BOARD_EQUIPMENT_ITEM(workOrderEquipmentId: number): DisableScheduleBoardEquipmentItem {
	return {
		type: actionTypes.DISABLE_SCHEDULE_BOARD_EQUIPMENT_ITEM,
		payload: workOrderEquipmentId,
	};
}

export function ENABLE_SCHEDULE_BOARD_EQUIPMENT_ITEM(workOrderEquipmentId: number): EnableScheduleBoardEquipmentItem {
	return {
		type: actionTypes.ENABLE_SCHEDULE_BOARD_EQUIPMENT_ITEM,
		payload: workOrderEquipmentId,
	};
}

export function DISABLE_SCHEDULE_BOARD_EMPLOYEE_ITEM(workOrderEmployeeId: number): DisableScheduleBoardEmployeeItem {
	return {
		type: actionTypes.DISABLE_SCHEDULE_BOARD_EMPLOYEE_ITEM,
		payload: workOrderEmployeeId,
	};
}

export function ENABLE_SCHEDULE_BOARD_EMPLOYEE_ITEM(workOrderEmployeeId: number): EnableScheduleBoardEmployeeItem {
	return {
		type: actionTypes.ENABLE_SCHEDULE_BOARD_EMPLOYEE_ITEM,
		payload: workOrderEmployeeId,
	};
}

export function DISABLE_SCHEDULE_BOARD_TEMPORARY_EMPLOYEE_ITEM(workOrderTemporaryEmployeeId: number): DisableScheduleBoardTemporaryEmployeeItem {
	return {
		type: actionTypes.DISABLE_SCHEDULE_BOARD_TEMPORARY_EMPLOYEE_ITEM,
		payload: workOrderTemporaryEmployeeId,
	};
}

export function ENABLE_SCHEDULE_BOARD_TEMPORARY_EMPLOYEE_ITEM(workOrderTemporaryEmployeeId: number): EnableScheduleBoardTemporaryEmployeeItem {
	return {
		type: actionTypes.ENABLE_SCHEDULE_BOARD_TEMPORARY_EMPLOYEE_ITEM,
		payload: workOrderTemporaryEmployeeId,
	};
}

export function DISABLE_SCHEDULE_BOARD_WORK_ORDER_ITEM(payload: WorkOrderCodeAndDueDate): DisableScheduleBoardWorkOrderItem {
	return {
		type: actionTypes.DISABLE_SCHEDULE_BOARD_WORK_ORDER_ITEM,
		payload,
	};
}

export function ENABLE_SCHEDULE_BOARD_WORK_ORDER_ITEM(payload: WorkOrderCodeAndDueDate): EnableScheduleBoardWorkOrderItem {
	return {
		type: actionTypes.ENABLE_SCHEDULE_BOARD_WORK_ORDER_ITEM,
		payload,
	};
}

export function ADD_EMPLOYEE_ASSIGNMENT(payload: ScheduleBoardAddResourceLookupAssignment): AddEmployeeAssignment {
	return {
		type: actionTypes.ADD_EMPLOYEE_ASSIGNMENT,
		payload,
	};
}

export function REMOVE_EMPLOYEE_ASSIGNMENT(payload: ScheduleBoardRemoveResourceAssignmentVM): RemoveEmployeeAssignment {
	return {
		type: actionTypes.REMOVE_EMPLOYEE_ASSIGNMENT,
		payload,
	};
}

export function ADD_EQUIPMENT_ASSIGNMENT(payload: ScheduleBoardAddResourceLookupAssignment): AddEquipmentAssignment {
	return {
		type: actionTypes.ADD_EQUIPMENT_ASSIGNMENT,
		payload,
	};
}

export function REMOVE_EQUIPMENT_ASSIGNMENT(payload: ScheduleBoardRemoveResourceAssignmentVM): RemoveEquipmentAssignment {
	return {
		type: actionTypes.REMOVE_EQUIPMENT_ASSIGNMENT,
		payload,
	};
}

export function ADD_PLACEHOLDER_ASSIGNMENT(payload: ScheduleBoardAddResourceLookupAssignment): AddPlaceholderAssignment {
	return {
		type: actionTypes.ADD_PLACEHOLDER_ASSIGNMENT,
		payload,
	};
}

export function REMOVE_PLACEHOLDER_ASSIGNMENT(payload: ScheduleBoardRemoveResourceAssignmentVM): RemovePlaceholderAssignment {
	return {
		type: actionTypes.REMOVE_PLACEHOLDER_ASSIGNMENT,
		payload,
	};
}

export function ADD_TEMPORARY_EMPLOYEE_ASSIGNMENT(payload: ScheduleBoardAddResourceLookupAssignment): AddTemporaryEmployeeAssignment {
	return {
		type: actionTypes.ADD_TEMPORARY_EMPLOYEE_ASSIGNMENT,
		payload,
	};
}

export function REMOVE_TEMPORARY_EMPLOYEE_ASSIGNMENT(payload: ScheduleBoardRemoveResourceAssignmentVM): RemoveTemporaryEmployeeAssignment {
	return {
		type: actionTypes.REMOVE_TEMPORARY_EMPLOYEE_ASSIGNMENT,
		payload,
	};
}

export function ADD_TOOLBAR_EMPLOYEE(payload: ScheduleBoardToolbarEmployeeUpdate): AddToolbarEmployee {
	return {
		type: actionTypes.ADD_TOOLBAR_EMPLOYEE,
		payload,
	};
}

export function ADD_TOOLBAR_EMPLOYEE_FROM_DATE(payload: ScheduleBoardToolbarEmployeeUpdate): AddToolbarEmployeeFromDate {
	return {
		type: actionTypes.ADD_TOOLBAR_EMPLOYEE_FROM_DATE,
		payload,
	};
}

export function REMOVE_TOOLBAR_EMPLOYEE(payload: ScheduleBoardToolbarEmployeeUpdate): RemoveToolbarEmployee {
	return {
		type: actionTypes.REMOVE_TOOLBAR_EMPLOYEE,
		payload,
	};
}

export function REMOVE_TOOLBAR_EMPLOYEE_FROM_DATE(payload: ScheduleBoardToolbarEmployeeUpdate): RemoveToolbarEmployeeFromDate {
	return {
		type: actionTypes.REMOVE_TOOLBAR_EMPLOYEE_FROM_DATE,
		payload,
	};
}

export function ADD_TOOLBAR_EQUIPMENT(payload: ScheduleBoardToolbarEquipmentUpdate): AddToolbarEquipment {
	return {
		type: actionTypes.ADD_TOOLBAR_EQUIPMENT,
		payload,
	};
}

export function REMOVE_TOOLBAR_EQUIPMENT(payload: ScheduleBoardToolbarEquipmentUpdate): RemoveToolbarEquipment {
	return {
		type: actionTypes.REMOVE_TOOLBAR_EQUIPMENT,
		payload,
	};
}

export function ADD_TOOLBAR_EMPLOYEE_FOR_FOR_ALL_DAYS(payload: ScheduleBoardToolbarEmployeeUpdate): AddToolbarEmployeeForAllDays {
	return {
		type: actionTypes.ADD_TOOLBAR_EMPLOYEE_FOR_ALL_DAYS,
		payload,
	};
}

export function REMOVE_TOOLBAR_EMPLOYEE_FOR_ALL_DAYS(payload: ScheduleBoardToolbarEmployeeUpdate): RemoveToolbarEmployeeForAllDays {
	return {
		type: actionTypes.REMOVE_TOOLBAR_EMPLOYEE_FOR_ALL_DAYS,
		payload,
	};
}

export function ADD_TOOLBAR_EQUIPMENT_FOR_ALL_DAYS(payload: ScheduleBoardToolbarEquipmentUpdate): AddToolbarEquipmentForAllDays {
	return {
		type: actionTypes.ADD_TOOLBAR_EQUIPMENT_FOR_ALL_DAYS,
		payload,
	};
}

export function REMOVE_TOOLBAR_EQUIPMENT_FOR_ALL_DAYS(payload: ScheduleBoardToolbarEquipmentUpdate): RemoveToolbarEquipmentForAllDays {
	return {
		type: actionTypes.REMOVE_TOOLBAR_EQUIPMENT_FOR_ALL_DAYS,
		payload,
	};
}

export function ADD_WORK_ORDER_RESOURCE_LOOKUP_TO_DICTIONARY(payload: ScheduleBoardAddResourceLookupAssignment): AddWorkOrderResourceLookupToDict {
	return {
		type: actionTypes.ADD_WORK_ORDER_RESOURCE_LOOKUP_TO_DICTIONARY,
		payload,
	};
}

export function ADD_TEMPORARY_EMPLOYEE(payload: ScheduleBoardTemporaryEmployeeVM): AddTemporaryEmployee {
	return {
		type: actionTypes.ADD_TEMPORARY_EMPLOYEE,
		payload,
	};
}

export function REMOVE_ALL_WORK_ORDER_EMPLOYEES(payload: ScheduleBoardRemoveAllWorkOrderEmployeesVM): RemoveAllWorkOrderEmployees {
	return {
		type: actionTypes.REMOVE_ALL_WORK_ORDER_EMPLOYEES,
		payload,
	};
}

export function REMOVE_ALL_WORK_ORDER_EMPLOYEE_ON_DRAFTS(payload: ScheduleBoardRemoveAllWorkOrderEmployeesVM): RemoveWorkOrderEmployeeOnDrafts {
	return {
		type: actionTypes.REMOVE_ALL_WORK_ORDER_EMPLOYEE_ON_DRAFTS,
		payload,
	};
}

export function REMOVE_ALL_WORK_ORDER_EQUIPMENT(payload: ScheduleBoardRemoveAllWorkOrderEquipmentVM): RemoveWorkOrderEquipment {
	return {
		type: actionTypes.REMOVE_ALL_WORK_ORDER_EQUIPMENT,
		payload,
	};
}

export function REMOVE_ALL_WORK_ORDER_EQUIPMENT_ON_DRAFTS(payload: ScheduleBoardRemoveAllWorkOrderEquipmentVM): RemoveWorkOrderEquipmentOnDrafts {
	return {
		type: actionTypes.REMOVE_ALL_WORK_ORDER_EQUIPMENT_ON_DRAFTS,
		payload,
	};
}

export function UPDATE_EMPLOYEE(payload: ScheduleBoardEmployee): UpdateEmployee {
	return {
		type: actionTypes.UPDATE_EMPLOYEE,
		payload,
	};
}

export function UPDATE_TEMPORARY_EMPLOYEE(payload: ScheduleBoardTemporaryEmployee): UpdateTemporaryEmployee {
	return {
		type: actionTypes.UPDATE_TEMPORARY_EMPLOYEE,
		payload,
	};
}

export function UPDATE_EQUIPMENT(payload: ScheduleBoardEquipment): UpdateEquipment {
	return {
		type: actionTypes.UPDATE_EQUIPMENT,
		payload,
	};
}

export function SET_SCHEDULE_BOARD_DRAGGED_WORK_ORDER_CODE(itemId: string): SetScheduleBoardDraggedWorkOrderCode {
	return {
		type: actionTypes.SET_SCHEDULE_BOARD_DRAGGED_WORK_ORDER_CODE,
		payload: itemId,
	};
}

export function SET_SCHEDULE_BOARD_DRAGGED_RESOURCE_ID(itemId: number): SetScheduleBoardDraggedResourceId {
	return {
		type: actionTypes.SET_SCHEDULE_BOARD_DRAGGED_RESOURCE_ID,
		payload: itemId,
	};
}

export function SET_SCHEDULE_BOARD_DRAGGED_EMPLOYEE(employeeId: Nullable<number>): SetScheduleBoardDraggedEmployee {
	return {
		type: actionTypes.SET_SCHEDULE_BOARD_DRAGGED_EMPLOYEE,
		payload: employeeId,
	};
}

export function SET_SCHEDULE_BOARD_DRAGGED_EQUIPMENT(equipmentId: Nullable<number>): SetScheduleBoardDraggedEquipment {
	return {
		type: actionTypes.SET_SCHEDULE_BOARD_DRAGGED_EQUIPMENT,
		payload: equipmentId,
	};
}

export function SET_SCHEDULE_BOARD_DRAGGED_WORK_ORDER_EMPLOYEE(resourceId: number, employeeId: number): SetScheduleBoardDraggedWorkOrderEmployee {
	return {
		type: actionTypes.SET_SCHEDULE_BOARD_DRAGGED_WORK_ORDER_EMPLOYEE,
		payload: { resourceId, employeeId },
	};
}

export function SET_SCHEDULE_BOARD_DRAGGED_WORK_ORDER_EQUIPMENT(resourceId: number, equipmentId: number): SetScheduleBoardDraggedWorkOrderEquipment {
	return {
		type: actionTypes.SET_SCHEDULE_BOARD_DRAGGED_WORK_ORDER_EQUIPMENT,
		payload: { resourceId, equipmentId },
	};
}

export function SET_SCHEDULE_BOARD_DRAGGED_WORK_ORDER_PLACEHOLDER(resourceId: number, placeholderId: number): SetScheduleBoardDraggedWorkOrderPlaceholder {
	return {
		type: actionTypes.SET_SCHEDULE_BOARD_DRAGGED_WORK_ORDER_PLACEHOLDER,
		payload: { resourceId, placeholderId },
	};
}

export function SET_SCHEDULE_BOARD_DRAGGED_WORK_ORDER_TEMPORARY_EMPLOYEE(
	resourceId: number,
	temporaryEmployeeId: number
): SetScheduleBoardDraggedWorkOrderTemporaryEmployee {
	return {
		type: actionTypes.SET_SCHEDULE_BOARD_DRAGGED_WORK_ORDER_TEMPORARY_EMPLOYEE,
		payload: { resourceId, temporaryEmployeeId },
	};
}

export function SET_SCHEDULE_BOARD_ZOOM_LEVEL(zoomLevel: number): SetScheduleBoardZoomLevel {
	return {
		type: actionTypes.SET_SCHEDULE_BOARD_ZOOM_LEVEL,
		payload: zoomLevel,
	};
}

export function LOCK_WORK_ORDER_ON_SCHEDULE_BOARD(payload: WorkOrderIdAndDueDate): LockWorkOrderOnScheduleBoard {
	return {
		type: actionTypes.LOCK_WORK_ORDER_ON_SCHEDULE_BOARD,
		payload,
	};
}

export function UNLOCK_WORK_ORDER_ON_SCHEDULE_BOARD(payload: WorkOrderIdAndDueDate): UnlockWorkOrderOnScheduleBoard {
	return {
		type: actionTypes.UNLOCK_WORK_ORDER_ON_SCHEDULE_BOARD,
		payload,
	};
}

export function CLEAR_ALL_SCHEDULE_BOARD_FILTERS(): ClearAllFilters {
	return {
		type: actionTypes.CLEAR_ALL_SCHEDULE_BOARD_FILTERS,
		payload: null,
	};
}

export function APPLY_SCHEDULE_BOARD_FILTERS(payload: ScheduleBoardFilterVM): ApplyFilters {
	return {
		type: actionTypes.APPLY_SCHEDULE_BOARD_FILTERS,
		payload,
	};
}

export interface ActivateMultiSelectMode {
	type: typeof actionTypes.ACTIVATE_SCHEDULE_BOARD_MULTI_SELECT_MODE;
	payload: null;
}
export function ACTIVATE_SCHEDULE_BOARD_MULTI_SELECT_MODE(): ActivateMultiSelectMode {
	return {
		type: actionTypes.ACTIVATE_SCHEDULE_BOARD_MULTI_SELECT_MODE,
		payload: null,
	};
}

export interface DeactivateMultiSelectMode {
	type: typeof actionTypes.DEACTIVATE_SCHEDULE_BOARD_MULTI_SELECT_MODE;
	payload: null;
}
export function DEACTIVATE_SCHEDULE_BOARD_MULTI_SELECT_MODE(): DeactivateMultiSelectMode {
	return {
		type: actionTypes.DEACTIVATE_SCHEDULE_BOARD_MULTI_SELECT_MODE,
		payload: null,
	};
}

export interface AddOrderToScheduleBoardMultiSelect {
	type: typeof actionTypes.ADD_ORDER_TO_SCHEDULE_BOARD_MULTI_SELECT;
	payload: SelectedWorkOrderModel;
}
export function ADD_ORDER_TO_SCHEDULE_BOARD_MULTI_SELECT(payload: SelectedWorkOrderModel): AddOrderToScheduleBoardMultiSelect {
	return {
		type: actionTypes.ADD_ORDER_TO_SCHEDULE_BOARD_MULTI_SELECT,
		payload,
	};
}

export interface RemoveOrderFromScheduleBoardMultiSelect {
	type: typeof actionTypes.REMOVE_ORDER_FROM_SCHEDULE_BOARD_MULTI_SELECT;
	payload: SelectedWorkOrderModel;
}
export function REMOVE_ORDER_FROM_SCHEDULE_BOARD_MULTI_SELECT(payload: SelectedWorkOrderModel): RemoveOrderFromScheduleBoardMultiSelect {
	return {
		type: actionTypes.REMOVE_ORDER_FROM_SCHEDULE_BOARD_MULTI_SELECT,
		payload,
	};
}

export interface SetScheduleBoardMultiSelectOrders {
	type: typeof actionTypes.SET_SCHEDULE_BOARD_MULTI_SELECT_ORDERS;
	payload: SelectedWorkOrderModel[];
}
export function SET_SCHEDULE_BOARD_MULTI_SELECT_ORDERS(payload: SelectedWorkOrderModel[]): SetScheduleBoardMultiSelectOrders {
	return {
		type: actionTypes.SET_SCHEDULE_BOARD_MULTI_SELECT_ORDERS,
		payload,
	};
}

export interface SetShowNotesMode {
	type: typeof actionTypes.SET_SHOW_NOTES_MODE;
	payload: boolean;
}
export function SET_SHOW_NOTES_MODE(payload: boolean): SetShowNotesMode {
	return {
		type: actionTypes.SET_SHOW_NOTES_MODE,
		payload,
	};
}

export interface CopiedEmployee {
	type: typeof actionTypes.SET_COPIED_EMPLOYEE;
	payload: Nullable<number>;
}
export function SET_COPIED_EMPLOYEE(payload: Nullable<number>): CopiedEmployee {
	return {
		type: actionTypes.SET_COPIED_EMPLOYEE,
		payload,
	};
}

export interface CopiedEquipment {
	type: typeof actionTypes.SET_COPIED_EQUIPMENT;
	payload: Nullable<number>;
}
export function SET_COPIED_EQUIPMENT(payload: Nullable<number>): CopiedEquipment {
	return {
		type: actionTypes.SET_COPIED_EQUIPMENT,
		payload,
	};
}

export interface CopiedPlaceholder {
	type: typeof actionTypes.SET_COPIED_PLACEHOLDER;
	payload: Nullable<number>;
}
export function SET_COPIED_PLACEHOLDER(payload: Nullable<number>): CopiedPlaceholder {
	return {
		type: actionTypes.SET_COPIED_PLACEHOLDER,
		payload,
	};
}

export interface CopiedTemporaryEmployee {
	type: typeof actionTypes.SET_COPIED_TEMPORARY_EMPLOYEE;
	payload: Nullable<number>;
}
export function SET_COPIED_TEMPORARY_EMPLOYEE(payload: Nullable<number>): CopiedTemporaryEmployee {
	return {
		type: actionTypes.SET_COPIED_TEMPORARY_EMPLOYEE,
		payload,
	};
}

export interface SetWeeklyViewHorizontalScrollingPercentage {
	type: typeof actionTypes.SET_WEEKLY_VIEW_HORIZONTAL_SCROLLING_PERCENTAGE;
	payload: number;
}
export function SET_WEEKLY_VIEW_HORIZONTAL_SCROLLING_PERCENTAGE(payload: number): SetWeeklyViewHorizontalScrollingPercentage {
	return {
		type: actionTypes.SET_WEEKLY_VIEW_HORIZONTAL_SCROLLING_PERCENTAGE,
		payload,
	};
}

export interface SetMetricsHorizontalScrollingPercentage {
	type: typeof actionTypes.SET_METRICS_HORIZONTAL_SCROLLING_PERCENTAGE;
	payload: number;
}
export function SET_METRICS_HORIZONTAL_SCROLLING_PERCENTAGE(payload: number): SetMetricsHorizontalScrollingPercentage {
	return {
		type: actionTypes.SET_METRICS_HORIZONTAL_SCROLLING_PERCENTAGE,
		payload,
	};
}

export interface CopiedWorkOrder {
	type: typeof actionTypes.SET_COPIED_WORK_ORDER;
	payload: string;
}
export function SET_COPIED_WORK_ORDER(payload: string): CopiedWorkOrder {
	return {
		type: actionTypes.SET_COPIED_WORK_ORDER,
		payload,
	};
}

export interface CopiedResourcePlaceholder {
	type: typeof actionTypes.SET_COPIED_RESOURCE_PLACEHOLDER;
	payload: {
		code: string | undefined;
		dueDate: string;
		index?: number;
		clearPlaceholder?: boolean;
	};
}
export function SET_COPIED_RESOURCE_PLACEHOLDER(
	dueDate: string,
	code: string | undefined,
	index: number | undefined,
	clearPlaceholder: boolean | undefined
): CopiedResourcePlaceholder {
	return {
		type: actionTypes.SET_COPIED_RESOURCE_PLACEHOLDER,
		payload: {
			dueDate,
			code,
			index,
			clearPlaceholder,
		},
	};
}

export interface CopiedWorkOrderPlaceholder {
	type: typeof actionTypes.SET_COPIED_WORK_ORDER_PLACEHOLDER;
	payload: {
		code: Nullable<string>;
		dueDate: string;
		index?: number;
	};
}
export function SET_COPIED_WORK_ORDER_PLACEHOLDER(dueDate: string, code: Nullable<string>, index: number | undefined): CopiedWorkOrderPlaceholder {
	return {
		type: actionTypes.SET_COPIED_WORK_ORDER_PLACEHOLDER,
		payload: {
			dueDate,
			code,
			index,
		},
	};
}

export interface SetWeeklyViewDateWithToolbar {
	type: typeof actionTypes.SET_WEEKLY_VIEW_DATE_WITH_TOOLBAR;
	payload: Nullable<string>;
}
export function SET_WEEKLY_VIEW_DATE_WITH_TOOLBAR(payload: Nullable<string>): SetWeeklyViewDateWithToolbar {
	return {
		type: actionTypes.SET_WEEKLY_VIEW_DATE_WITH_TOOLBAR,
		payload,
	};
}

export interface ToggleWeeklyViewSelectMultiple {
	type: typeof actionTypes.TOGGLE_WEEKLY_VIEW_SELECT_MULTIPLE;
	payload: string;
}
export function TOGGLE_WEEKLY_VIEW_SELECT_MULTIPLE(payload: string): ToggleWeeklyViewSelectMultiple {
	return {
		type: actionTypes.TOGGLE_WEEKLY_VIEW_SELECT_MULTIPLE,
		payload,
	};
}

export interface ToggleDayWeeklyView {
	type: typeof actionTypes.TOGGLE_DAY_ON_WEEKLY_VIEW;
	payload: {
		dueDate: string;
		selectValue: boolean;
	};
}
export function TOGGLE_DAY_ON_WEEKLY_VIEW(dueDate: string, selectValue: boolean): ToggleDayWeeklyView {
	return {
		type: actionTypes.TOGGLE_DAY_ON_WEEKLY_VIEW,
		payload: {
			dueDate,
			selectValue,
		},
	};
}

export interface ToggleWorkOrderWeeklyView {
	type: typeof actionTypes.TOGGLE_WORK_ORDER_ON_WEEKLY_VIEW;
	payload: {
		dueDate: string;
		workOrder: SelectedWorkOrderModel;
	};
}
export function TOGGLE_WORK_ORDER_ON_WEEKLY_VIEW(dueDate: string, workOrder: SelectedWorkOrderModel): ToggleWorkOrderWeeklyView {
	return {
		type: actionTypes.TOGGLE_WORK_ORDER_ON_WEEKLY_VIEW,
		payload: {
			dueDate,
			workOrder,
		},
	};
}

export interface ChangeReturnDate {
	type: typeof actionTypes.CHANGE_RETURN_DATE;
	payload: ScheduleBoardReturnDateRequestModel;
}
export function CHANGE_RETURN_DATE(payload: ScheduleBoardReturnDateRequestModel): ChangeReturnDate {
	return {
		type: actionTypes.CHANGE_RETURN_DATE,
		payload,
	};
}

export interface UpdateEmployeePerDiem {
	type: typeof actionTypes.UPDATE_EMPLOYEE_PER_DIEM;
	payload: ScheduleBoardEmployeePerDiemAssignmentRequestModel;
}
export function UPDATE_EMPLOYEE_PER_DIEM(payload: ScheduleBoardEmployeePerDiemAssignmentRequestModel): UpdateEmployeePerDiem {
	return {
		type: actionTypes.UPDATE_EMPLOYEE_PER_DIEM,
		payload,
	};
}

interface SetActiveSearchItemIndex {
	type: typeof actionTypes.SET_SB_ACTIVE_SEARCH_ITEM_INDEX;
	payload: number;
}
export function SET_SB_ACTIVE_SEARCH_ITEM_INDEX(payload: number): SetActiveSearchItemIndex {
	return {
		type: actionTypes.SET_SB_ACTIVE_SEARCH_ITEM_INDEX,
		payload,
	};
}

export interface UpdateWorkOrderHasAttachments {
	type: typeof actionTypes.UPDATE_WORK_ORDER_HAS_ATTACHMENTS;
	payload: ScheduleBoardUpdateWorkOrderHasAttachmentsVM;
}
export function UPDATE_WORK_ORDER_HAS_ATTACHMENTS(payload: ScheduleBoardUpdateWorkOrderHasAttachmentsVM): UpdateWorkOrderHasAttachments {
	return {
		type: actionTypes.UPDATE_WORK_ORDER_HAS_ATTACHMENTS,
		payload,
	};
}

export interface UpdateWorkOrderNote {
	type: typeof actionTypes.UPDATE_WORK_ORDER_NOTE;
	payload: ScheduleBoardUpdateWorkOrderNoteViewModel;
}
export function UPDATE_WORK_ORDER_NOTE(payload: ScheduleBoardUpdateWorkOrderNoteViewModel): UpdateWorkOrderNote {
	return {
		type: actionTypes.UPDATE_WORK_ORDER_NOTE,
		payload,
	};
}

export interface SetRefreshModalVisibility {
	type: typeof actionTypes.SET_REFRESH_MODAL_VISIBILITY;
	payload: boolean;
}
export function SET_REFRESH_MODAL_VISIBILITY(payload: boolean): SetRefreshModalVisibility {
	return {
		type: actionTypes.SET_REFRESH_MODAL_VISIBILITY,
		payload,
	};
}

export interface SetDraggingLaborPlaceholder {
	type: typeof actionTypes.SET_DRAGGING_LABOR_PLACEHOLDER;
	payload: {
		dragging: boolean;
		destinationData: Nullable<ScheduleBoardDragDestinationData>;
	};
}
export function SET_DRAGGING_LABOR_PLACEHOLDER(
	dragging: boolean,
	destinationData: Nullable<ScheduleBoardDragDestinationData> = null
): SetDraggingLaborPlaceholder {
	return {
		type: actionTypes.SET_DRAGGING_LABOR_PLACEHOLDER,
		payload: { dragging, destinationData },
	};
}

export interface SetDraggingEquipmentPlaceholder {
	type: typeof actionTypes.SET_DRAGGING_EQUIPMENT_PLACEHOLDER;
	payload: {
		dragging: boolean;
		destinationData: Nullable<ScheduleBoardDragDestinationData>;
	};
}
export function SET_DRAGGING_EQUIPMENT_PLACEHOLDER(
	dragging: boolean,
	destinationData: Nullable<ScheduleBoardDragDestinationData> = null
): SetDraggingEquipmentPlaceholder {
	return {
		type: actionTypes.SET_DRAGGING_EQUIPMENT_PLACEHOLDER,
		payload: { dragging, destinationData },
	};
}

export interface RefreshAvailableEmployeesNotificationModal {
	type: typeof actionTypes.REFRESH_AVAILABLE_EMPLOYEES_NOTIFICATION_MODAL;
	payload: null;
}
export function REFRESH_AVAILABLE_EMPLOYEES_NOTIFICATION_MODAL(): RefreshAvailableEmployeesNotificationModal {
	return {
		type: actionTypes.REFRESH_AVAILABLE_EMPLOYEES_NOTIFICATION_MODAL,
		payload: null,
	};
}

export interface OpenAvailableEmployeesNotificationModal {
	type: typeof actionTypes.OPEN_AVAILABLE_EMPLOYEES_NOTIFICATION_MODAL;
	payload: OpenAvailableEmployeesNotificationModalData;
}
export function OPEN_AVAILABLE_EMPLOYEES_NOTIFICATION_MODAL(payload: OpenAvailableEmployeesNotificationModalData): OpenAvailableEmployeesNotificationModal {
	return {
		type: actionTypes.OPEN_AVAILABLE_EMPLOYEES_NOTIFICATION_MODAL,
		payload,
	};
}

export interface SetLaborModalVisibility {
	type: typeof actionTypes.SET_LABOR_MODAL_VISIBILITY;
	payload: boolean;
}
export function SET_LABOR_MODAL_VISIBILITY(payload: boolean): SetLaborModalVisibility {
	return {
		type: actionTypes.SET_LABOR_MODAL_VISIBILITY,
		payload,
	};
}

export interface SetLaborModalData {
	type: typeof actionTypes.SET_LABOR_MODAL_DATA;
	payload: ResourcePlaceholderModal;
}
export function SET_LABOR_MODAL_DATA(payload: ResourcePlaceholderModal): SetLaborModalData {
	return {
		type: actionTypes.SET_LABOR_MODAL_DATA,
		payload,
	};
}

export interface CloseAvailableEmployeesNotificationModal {
	type: typeof actionTypes.CLOSE_AVAILABLE_EMPLOYEES_NOTIFICATION_MODAL;
	payload: null;
}
export function CLOSE_AVAILABLE_EMPLOYEES_NOTIFICATION_MODAL(): CloseAvailableEmployeesNotificationModal {
	return {
		type: actionTypes.CLOSE_AVAILABLE_EMPLOYEES_NOTIFICATION_MODAL,
		payload: null,
	};
}

export interface CheckEmployeeInAvailableEmployeeModal {
	type: typeof actionTypes.CHECK_EMPLOYEE_IN_AVAILABLE_EMPLOYEE_MODAL;
	payload: CheckEmployeeInAvailableEmployeesNotificationModal;
}
export function CHECK_EMPLOYEE_IN_AVAILABLE_EMPLOYEE_MODAL(payload: CheckEmployeeInAvailableEmployeesNotificationModal): CheckEmployeeInAvailableEmployeeModal {
	return {
		type: actionTypes.CHECK_EMPLOYEE_IN_AVAILABLE_EMPLOYEE_MODAL,
		payload,
	};
}

export interface SetEquipmentPlaceholderModalVisibility {
	type: typeof actionTypes.SET_EQUIPMENT_PLACEHOLDER_MODAL_VISIBILITY;
	payload: boolean;
}
export function SET_EQUIPMENT_PLACEHOLDER_MODAL_VISIBILITY(payload: boolean): SetEquipmentPlaceholderModalVisibility {
	return {
		type: actionTypes.SET_EQUIPMENT_PLACEHOLDER_MODAL_VISIBILITY,
		payload,
	};
}

export interface CheckAllInAvailableEmployeeModal {
	type: typeof actionTypes.CHECK_ALL_IN_AVAILABLE_EMPLOYEE_MODAL;
	payload: CheckAllInAvailableEmployeesNotificationModal;
}
export function CHECK_ALL_IN_AVAILABLE_EMPLOYEE_MODAL(payload: CheckAllInAvailableEmployeesNotificationModal): CheckAllInAvailableEmployeeModal {
	return {
		type: actionTypes.CHECK_ALL_IN_AVAILABLE_EMPLOYEE_MODAL,
		payload,
	};
}

export interface SetEquipmentPlaceholderModalData {
	type: typeof actionTypes.SET_EQUIPMENT_PLACEHOLDER_MODAL_DATA;
	payload: ResourcePlaceholderModal;
}
export function SET_EQUIPMENT_PLACEHOLDER_MODAL_DATA(payload: ResourcePlaceholderModal): SetEquipmentPlaceholderModalData {
	return {
		type: actionTypes.SET_EQUIPMENT_PLACEHOLDER_MODAL_DATA,
		payload,
	};
}

export interface ResetAllInAvailableEmployeeModal {
	type: typeof actionTypes.RESET_ALL_IN_AVAILABLE_EMPLOYEE_MODAL;
	payload: string;
}
export function RESET_ALL_IN_AVAILABLE_EMPLOYEE_MODAL(payload: string): ResetAllInAvailableEmployeeModal {
	return {
		type: actionTypes.RESET_ALL_IN_AVAILABLE_EMPLOYEE_MODAL,
		payload,
	};
}

interface ReloadScheduleBoard {
	type: typeof actionTypes.RELOAD_SCHEDULE_BOARD;
	payload: null;
}
export function RELOAD_SCHEDULE_BOARD(): ReloadScheduleBoard {
	return {
		type: actionTypes.RELOAD_SCHEDULE_BOARD,
		payload: null,
	};
}

interface UpdateDisplayViewDate {
	type: typeof actionTypes.UPDATE_DISPLAY_VIEW_DATE;
	payload: string;
}
export function UPDATE_DISPLAY_VIEW_DATE(payload: string): UpdateDisplayViewDate {
	return {
		type: actionTypes.UPDATE_DISPLAY_VIEW_DATE,
		payload,
	};
}

export interface ClearWorkOrdersByDate {
	type: typeof actionTypes.CLEAR_WORK_ORDERS_BY_DATE;
	payload: null;
}
export function CLEAR_WORK_ORDERS_BY_DATE(): ClearWorkOrdersByDate {
	return {
		type: actionTypes.CLEAR_WORK_ORDERS_BY_DATE,
		payload: null,
	};
}

interface ClearScheduleBoardDraggedWorkOrder {
	type: typeof actionTypes.CLEAR_SCHEDULE_BOARD_DRAGGED_WORK_ORDER;
	payload: ScheduleBoardDragRequestModel;
}
export function CLEAR_SCHEDULE_BOARD_DRAGGED_WORK_ORDER(dragElement: ScheduleBoardDragRequestModel): ClearScheduleBoardDraggedWorkOrder {
	return {
		type: actionTypes.CLEAR_SCHEDULE_BOARD_DRAGGED_WORK_ORDER,
		payload: dragElement,
	};
}

interface ClearScheduleBoardDraggedResource {
	type: typeof actionTypes.CLEAR_SCHEDULE_BOARD_DRAGGED_RESOURCE;
	payload: ScheduleBoardDragRequestModel;
}
export function CLEAR_SCHEDULE_BOARD_DRAGGED_RESOURCE(dragElement: ScheduleBoardDragRequestModel): ClearScheduleBoardDraggedResource {
	return {
		type: actionTypes.CLEAR_SCHEDULE_BOARD_DRAGGED_RESOURCE,
		payload: dragElement,
	};
}

export interface SetPerDiemForWorkOrders {
	type: typeof actionTypes.SET_PER_DIEM_FOR_WORK_ORDERS;
	payload: ScheduleBoardSetPerDiemForWorkOrdersRequestModel;
}
export function SET_PER_DIEM_FOR_WORK_ORDERS(payload: ScheduleBoardSetPerDiemForWorkOrdersRequestModel): SetPerDiemForWorkOrders {
	return {
		type: actionTypes.SET_PER_DIEM_FOR_WORK_ORDERS,
		payload,
	};
}

export interface ChangeScheduleBoardSort {
	type: typeof actionTypes.CHANGE_WORK_ORDERS_SORT;
	payload: ScheduleBoardSortType;
}
export function CHANGE_WORK_ORDERS_SORT(payload: ScheduleBoardSortType): ChangeScheduleBoardSort {
	return {
		type: actionTypes.CHANGE_WORK_ORDERS_SORT,
		payload,
	};
}

interface SetLaborStatistics {
	type: typeof actionTypes.SET_SCHEDULE_BOARD_LABOR_STATISTICS;
	payload: ScheduleBoardLaborStatisticsForDay;
}
export function SET_SCHEDULE_BOARD_LABOR_STATISTICS(payload: ScheduleBoardLaborStatisticsForDay): SetLaborStatistics {
	return {
		type: actionTypes.SET_SCHEDULE_BOARD_LABOR_STATISTICS,
		payload,
	};
}

interface CopyMultipleWorkOrders {
	type: typeof actionTypes.COPY_MULTIPLE_WORK_ORDERS;
	payload: ScheduleBoardCopiedWorkOrderViewModel[];
}
export function COPY_MULTIPLE_WORK_ORDERS(payload: ScheduleBoardCopiedWorkOrderViewModel[]): CopyMultipleWorkOrders {
	return {
		type: actionTypes.COPY_MULTIPLE_WORK_ORDERS,
		payload,
	};
}

interface RemoveMultipleEmployeesFromToolbar {
	type: typeof actionTypes.REMOVE_MULTIPLE_TOOLBAR_EMPLOYEE;
	payload: ScheduleBoardToolbarEmployeeUpdate[];
}
export function REMOVE_MULTIPLE_TOOLBAR_EMPLOYEE(payload: ScheduleBoardToolbarEmployeeUpdate[]): RemoveMultipleEmployeesFromToolbar {
	return {
		type: actionTypes.REMOVE_MULTIPLE_TOOLBAR_EMPLOYEE,
		payload,
	};
}

interface RemoveMultipleEquipmentFromToolbar {
	type: typeof actionTypes.REMOVE_MULTIPLE_TOOLBAR_EQUIPMENT;
	payload: ScheduleBoardToolbarEquipmentUpdate[];
}
export function REMOVE_MULTIPLE_TOOLBAR_EQUIPMENT(payload: ScheduleBoardToolbarEquipmentUpdate[]): RemoveMultipleEquipmentFromToolbar {
	return {
		type: actionTypes.REMOVE_MULTIPLE_TOOLBAR_EQUIPMENT,
		payload,
	};
}

export interface AddToolbarEquipmentDownDetails {
	type: typeof actionTypes.ADD_EQUIPMENT_DOWN_DETAILS;
	payload: AddEquipmentDownDetails;
}
export function ADD_EQUIPMENT_DOWN_DETAILS(payload: AddEquipmentDownDetails): AddToolbarEquipmentDownDetails {
	return {
		type: actionTypes.ADD_EQUIPMENT_DOWN_DETAILS,
		payload,
	};
}
export interface AddToolbarEmployeeDownDetails {
	type: typeof actionTypes.ADD_EMPLOYEE_DOWN_DETAILS;
	payload: EmployeeUnavailabilityDetailsVM;
}
export function ADD_EMPLOYEE_DOWN_DETAILS(payload: EmployeeUnavailabilityDetailsVM): AddToolbarEmployeeDownDetails {
	return {
		type: actionTypes.ADD_EMPLOYEE_DOWN_DETAILS,
		payload,
	};
}

interface SetMechanicViewSortBy {
	type: typeof actionTypes.SET_MECHANIC_VIEW_SORT_BY;
	payload: { sortBy: SortByEnum; orderBy: OrderByEnum; };
}
export function SET_MECHANIC_VIEW_SORT_BY(sortBy: SortByEnum, orderBy: OrderByEnum): SetMechanicViewSortBy {
	return {
		type: actionTypes.SET_MECHANIC_VIEW_SORT_BY,
		payload: { sortBy, orderBy },
	};
}

interface SetMechanicViewAvailableEquipment {
	type: typeof actionTypes.SET_MECHANIC_VIEW_AVAILABLE_EQUIPMENT;
	payload: MechanicViewAvailableEquipmentDataVM[];
}
export function SET_MECHANIC_VIEW_AVAILABLE_EQUIPMENT(
	equipment: MechanicViewAvailableEquipmentDataVM[]
): SetMechanicViewAvailableEquipment {
	return {
		type: actionTypes.SET_MECHANIC_VIEW_AVAILABLE_EQUIPMENT,
		payload: equipment,
	};
}

interface SetMechanicViewUnavailableEquipment {
	type: typeof actionTypes.SET_MECHANIC_VIEW_UNAVAILABLE_EQUIPMENT;
	payload: MechanicViewUnavailableEquipmentDataVM[];
}
export function SET_MECHANIC_VIEW_UNAVAILABLE_EQUIPMENT(
	equipment: MechanicViewUnavailableEquipmentDataVM[]
): SetMechanicViewUnavailableEquipment {
	return {
		type: actionTypes.SET_MECHANIC_VIEW_UNAVAILABLE_EQUIPMENT,
		payload: equipment,
	};
}

interface SetMechanicViewSearchIndex {
	type: typeof actionTypes.SET_MECHANIC_VIEW_FOCUSED_SEARCH_INDEX;
	payload: number;
}
export function SET_MECHANIC_VIEW_FOCUSED_SEARCH_INDEX(index: number): SetMechanicViewSearchIndex {
	return {
		type: actionTypes.SET_MECHANIC_VIEW_FOCUSED_SEARCH_INDEX,
		payload: index,
	};
}

interface SetMechanicViewSearchQuery {
	type: typeof actionTypes.SET_MECHANIC_VIEW_SEARCH_QUERY;
	payload: string;
}
export function SET_MECHANIC_VIEW_SEARCH_QUERY(searchQuery: string): SetMechanicViewSearchQuery {
	return {
		type: actionTypes.SET_MECHANIC_VIEW_SEARCH_QUERY,
		payload: searchQuery,
	};
}

interface AddEmployeeNightShiftAssignment {
	type: typeof actionTypes.ADD_EMPLOYEE_NIGHT_SHIFT_ASSIGNMENT;
	payload: EmployeeNightShiftAssignmentVM;
}
export function ADD_EMPLOYEE_NIGHT_SHIFT_ASSIGNMENT(data: EmployeeNightShiftAssignmentVM): AddEmployeeNightShiftAssignment {
	return {
		type: actionTypes.ADD_EMPLOYEE_NIGHT_SHIFT_ASSIGNMENT,
		payload: data,
	};
}

interface RemoveEmployeeNightShiftAssignment {
	type: typeof actionTypes.REMOVE_EMPLOYEE_NIGHT_SHIFT_ASSIGNMENT;
	payload: EmployeeNightShiftAssignmentVM;
}
export function REMOVE_EMPLOYEE_NIGHT_SHIFT_ASSIGNMENT(data: EmployeeNightShiftAssignmentVM): RemoveEmployeeNightShiftAssignment {
	return {
		type: actionTypes.REMOVE_EMPLOYEE_NIGHT_SHIFT_ASSIGNMENT,
		payload: data,
	};
}

interface AddTemporaryEmployeeNightShiftAssignment {
	type: typeof actionTypes.ADD_TEMPORARY_EMPLOYEE_NIGHT_SHIFT_ASSIGNMENT;
	payload: TemporaryEmployeeNightShiftAssignmentVM;
}
export function ADD_TEMPORARY_EMPLOYEE_NIGHT_SHIFT_ASSIGNMENT(data: TemporaryEmployeeNightShiftAssignmentVM): AddTemporaryEmployeeNightShiftAssignment {
	return {
		type: actionTypes.ADD_TEMPORARY_EMPLOYEE_NIGHT_SHIFT_ASSIGNMENT,
		payload: data,
	};
}

interface RemoveTemporaryEmployeeNightShiftAssignment {
	type: typeof actionTypes.REMOVE_TEMPORARY_EMPLOYEE_NIGHT_SHIFT_ASSIGNMENT;
	payload: TemporaryEmployeeNightShiftAssignmentVM;
}
export function REMOVE_TEMPORARY_EMPLOYEE_NIGHT_SHIFT_ASSIGNMENT(data: TemporaryEmployeeNightShiftAssignmentVM): RemoveTemporaryEmployeeNightShiftAssignment {
	return {
		type: actionTypes.REMOVE_TEMPORARY_EMPLOYEE_NIGHT_SHIFT_ASSIGNMENT,
		payload: data,
	};
}

interface UpdateCrewType {
	type: typeof actionTypes.UPDATE_CREW_TYPE;
	payload: ScheduleBoardUpdateCrewType;
}
export function UPDATE_CREW_TYPE(data: ScheduleBoardUpdateCrewType): UpdateCrewType {
	return {
		type: actionTypes.UPDATE_CREW_TYPE,
		payload: data,
	};
}

interface UpdateDailyPerDiemTip {
	type: typeof actionTypes.UPDATE_DAILY_PER_DIEM_TIP;
	payload: DailyPerDiemTipViewModel;
}
export function UPDATE_DAILY_PER_DIEM_TIP(data: DailyPerDiemTipViewModel): UpdateDailyPerDiemTip {
	return {
		type: actionTypes.UPDATE_DAILY_PER_DIEM_TIP,
		payload: data,
	};
}

interface UpdateDailyTip {
	type: typeof actionTypes.UPDATE_DAILY_TIP;
	payload: DailyTipViewModel;
}
export function UPDATE_DAILY_TIP(data: DailyTipViewModel): UpdateDailyTip {
	return {
		type: actionTypes.UPDATE_DAILY_TIP,
		payload: data,
	};
}

export type ScheduleBoardAction = (
	GetScheduleBoard
	| GetScheduleBoardResources | ReloadResources
	| GetScheduleBoardRejoin
	| ReloadScheduleBoard
	| GetWeeklyViewScheduleBoard
	| GetSocketConnectionCount
	| UpdateScheduleBoardDate | UpdateDisplayViewDate
	| UpdateScheduleBoardWeek
	| UpdateScheduleBoardQuery
	| EnableScheduleBoardEquipmentItem | DisableScheduleBoardEquipmentItem
	| EnableScheduleBoardEmployeeItem | DisableScheduleBoardEmployeeItem
	| EnableScheduleBoardTemporaryEmployeeItem | DisableScheduleBoardTemporaryEmployeeItem
	| RemoveEmployeeAssignment | AddEmployeeAssignment
	| AddTemporaryEmployeeAssignment | RemoveTemporaryEmployeeAssignment
	| SetScheduleBoardZoomLevel
	| UpdateScheduleBoardDroppableList
	| AddToolbarEmployee | RemoveToolbarEmployee | RemoveToolbarEmployeeFromDate | AddToolbarEmployeeFromDate
	| AddToolbarEquipment | RemoveToolbarEquipment | AddToolbarEquipmentDownDetails | AddToolbarEmployeeDownDetails
	| RemoveMultipleEmployeesFromToolbar | RemoveMultipleEquipmentFromToolbar
	| AddToolbarEmployeeForAllDays | RemoveToolbarEmployeeForAllDays | AddToolbarEquipmentForAllDays | RemoveToolbarEquipmentForAllDays
	| AddWorkOrderResourceLookupToDict
	| AddTemporaryEmployee
	| SetScheduleBoardDraggedEmployee | SetScheduleBoardDraggedEquipment
	| SetScheduleBoardDraggedWorkOrderEmployee | SetScheduleBoardDraggedWorkOrderEquipment
	| SetScheduleBoardDraggedWorkOrderPlaceholder | SetScheduleBoardDraggedWorkOrderTemporaryEmployee
	| ClearScheduleBoardDraggedWorkOrder | ClearScheduleBoardDraggedResource
	| RemoveEquipmentAssignment | AddEquipmentAssignment
	| AddPlaceholderAssignment | RemovePlaceholderAssignment
	| UpdateEmployee | UpdateTemporaryEmployee | UpdateEquipment
	| LockWorkOrderOnScheduleBoard | UnlockWorkOrderOnScheduleBoard
	| SetScheduleBoardDraggedWorkOrderCode
	| RemoveAllWorkOrderEmployees | RemoveWorkOrderEmployeeOnDrafts | RemoveWorkOrderEquipment | RemoveWorkOrderEquipmentOnDrafts
	| UpdateScheduleBoardWorkOrders
	| UpdateScheduleBoardWorkOrder
	| RemoveScheduleBoardWorkOrder
	| CopyMultipleWorkOrders
	| PauseScheduleBoardWorkOrder
	| ResumeScheduleBoardWorkOrder
	| RevertScheduleBoardWorkOrder
	| LockScheduleBoardWorkOrder
	| UnlockScheduleBoardWorkOrder
	| CancelScheduleBoardWorkOrder
	| EnableScheduleBoardWorkOrderItem | DisableScheduleBoardWorkOrderItem
	| ClearUnavailabilityReason | AssignUnavailabilityReason
	| AddToScheduleBoardDroppableList
	| SetScheduleBoardView
	| AddBlankWorkOrder | RemoveBlankWorkOrder
	| ClearAllFilters | ApplyFilters
	| ActivateMultiSelectMode | DeactivateMultiSelectMode
	| AddOrderToScheduleBoardMultiSelect | RemoveOrderFromScheduleBoardMultiSelect | SetScheduleBoardMultiSelectOrders
	| SetWeeklyViewHorizontalScrollingPercentage
	| SetMetricsHorizontalScrollingPercentage
	| CopiedEmployee
	| CopiedEquipment
	| CopiedPlaceholder
	| CopiedTemporaryEmployee
	| CopiedWorkOrder
	| CopiedWorkOrderPlaceholder
	| ChangeReturnDate
	| ClearScheduleBoardDailyView | ClearScheduleBoardWeeklyView
	| SetScheduleBoardDraggedResourceId
	| CopiedResourcePlaceholder
	| SetWeeklyViewDateWithToolbar | ToggleWeeklyViewSelectMultiple | ToggleDayWeeklyView | ToggleWorkOrderWeeklyView
	| SyncNotificationStatus
	| UpdateWorkOrderHasAttachments
	| SetShowNotesMode
	| SetLaborStatistics
	| SetActiveSearchItemIndex
	| SetRefreshModalVisibility
	| SetDraggingLaborPlaceholder | SetDraggingEquipmentPlaceholder
	| SetLaborModalVisibility | SetLaborModalData | SetEquipmentPlaceholderModalVisibility | SetEquipmentPlaceholderModalData
	| RefreshAvailableEmployeesNotificationModal | OpenAvailableEmployeesNotificationModal | CloseAvailableEmployeesNotificationModal
	| CheckEmployeeInAvailableEmployeeModal | CheckAllInAvailableEmployeeModal | ResetAllInAvailableEmployeeModal
	| ClearWorkOrdersByDate
	| UpdateWorkOrderNote
	| ChangeScheduleBoardSort
	| UpdateEmployeePerDiem | SetPerDiemForWorkOrders
	| SetMechanicViewSortBy
	| UpdateScheduleBoardWorkOrderIndices
	| SetMechanicViewAvailableEquipment
	| SetMechanicViewUnavailableEquipment
	| SetMechanicViewSearchIndex
	| SetMechanicViewSearchQuery
	| AddEmployeeNightShiftAssignment | RemoveEmployeeNightShiftAssignment
	| AddTemporaryEmployeeNightShiftAssignment | RemoveTemporaryEmployeeNightShiftAssignment
	| UpdateCrewType
	| UpdateDailyPerDiemTip
	| UpdateDailyTip
);
