import * as React from 'react';
import { Col } from 'react-bootstrap';

import AccountPermission from 'acceligent-shared/enums/accountPermission';

import type AccountRM from 'ab-requestModels/account/upsert.requestModel';

import PermissionField from '../PermissionField';
import type { GROUPS } from '../constants';
import { PERMISSIONS_PER_GROUP, GROUP_NAME, FLAG_IMPLICATIONS, LOCKING_IMPLICATIONS } from '../constants';

interface OwnProps {
	group: GROUPS;
	permissions: AccountRM['permissions'];
	disabled: boolean;
	assignableToWorkOrder: boolean;
	assignableAsSuperintendent: boolean;
	assignableAsAccounting: boolean;
	assignableAsManagement: boolean;
	assignableAsTechnician: boolean;
	fieldBase?: string;
	change: (field: string, value: boolean) => void;
}

type Props = OwnProps;

const PermissionGroup: React.FC<Props> = (props) => {
	const {
		group,
		disabled,
		permissions,
		assignableToWorkOrder,
		assignableAsSuperintendent,
		assignableAsAccounting,
		assignableAsManagement,
		assignableAsTechnician,
		fieldBase,
		change,
	} = props;

	const onSelectAll = React.useCallback(() => {
		if (disabled) {
			return;
		}
		for (const permission of PERMISSIONS_PER_GROUP[group]) {
			change(`permissions.${AccountPermission[permission]}`, true);
		}
	}, [group, disabled, change]);

	const onClearAll = React.useCallback(() => {
		if (disabled) {
			return;
		}
		for (const permission of PERMISSIONS_PER_GROUP[group]) {
			const isRequiredPermission = assignableToWorkOrder && FLAG_IMPLICATIONS.assignableToWorkOrder[permission]
				|| assignableAsSuperintendent && FLAG_IMPLICATIONS.assignableAsSuperintendent[permission]
				|| assignableAsAccounting && FLAG_IMPLICATIONS.assignableAsAccounting[permission]
				|| assignableAsAccounting && FLAG_IMPLICATIONS.assignableAsAccounting[permission]
				|| assignableAsTechnician && FLAG_IMPLICATIONS.assignableAsTechnician[permission];

			if (!isRequiredPermission) {
				change(`permissions.${AccountPermission[permission]}`, false);
			}
		}
	}, [group, disabled, change, assignableAsAccounting, assignableAsSuperintendent, assignableToWorkOrder, assignableAsTechnician]);

	const renderPermissionField = React.useCallback((permission: AccountPermission, index: number) => {
		const isRequiredPermission = (assignableToWorkOrder && FLAG_IMPLICATIONS.assignableToWorkOrder[permission])
			|| (assignableAsSuperintendent && FLAG_IMPLICATIONS.assignableAsSuperintendent[permission])
			|| (assignableAsAccounting && FLAG_IMPLICATIONS.assignableAsAccounting[permission])
			|| (assignableAsManagement && FLAG_IMPLICATIONS.assignableAsManagement[permission])
			|| (assignableAsTechnician && LOCKING_IMPLICATIONS.assignableAsTechnician[permission]);

		const field = fieldBase ? `${fieldBase}.permissions` : 'permissions';

		return (
			<PermissionField
				change={change}
				field={field}
				isDisabled={disabled || isRequiredPermission}
				key={index}
				permission={permission}
				permissions={permissions}
			/>
		);
	}, [assignableToWorkOrder,
		assignableAsSuperintendent,
		assignableAsAccounting,
		assignableAsManagement,
		assignableAsTechnician,
		fieldBase,
		change,
		disabled,
		permissions]);

	return (
		<Col sm={8}>
			<div className="member-permissions__permissions-box">
				<div className="member-permissions__permissions-box-header">
					<span className="member-permissions__permissions-box-title">{GROUP_NAME[group]}</span>
					<div className="member-permissions__controls-container">
						<span className="member-permissions__control" onClick={onSelectAll}>Select All</span>
						<span className="member-permissions__control" onClick={onClearAll}>Reset All</span>
					</div>
				</div>
				{PERMISSIONS_PER_GROUP[group]?.map(renderPermissionField)}
			</div>
		</Col>
	);
};

export default PermissionGroup;
