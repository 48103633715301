import * as React from 'react';
import { Col } from 'react-bootstrap';
import { Field } from 'redux-form';

import Input from 'af-fields/Input';

import { CUSTOMER_WORK_ORDER_MAX_CHARACTERS } from 'af-constants/values';

import Section from '../Shared/Section';

type CustomerProps = {
	disabled?: boolean;
};

const Customer: React.FC<CustomerProps> = (props: CustomerProps) => {
	const { disabled = false } = props;

	return (
		<Section label="Customer">
			<Col sm={12}>
				<Field
					component={Input}
					disabled={disabled}
					label="Customer Work Order"
					maxCharacters={CUSTOMER_WORK_ORDER_MAX_CHARACTERS}
					name="customerWorkOrder"
					showCharNum
					type="text"
				/>
			</Col>
		</Section>
	);
};

export default React.memo(Customer);
