import type { AccountRM } from 'ab-requestModels/account/updateActive.requestModel';

import type { ActiveVM } from 'ab-viewModels/account/active.viewModel';

import { toBase64ImageUrl } from 'ab-utils/image.util';

export const toRequestModel = async (account: ActiveVM): Promise<AccountRM> => {
	return {
		firstName: account.firstName,
		lastName: account.lastName,
		email: account.email,
		phoneNumber: account.phoneNumber,
		countryCode: account.countryCode,
		extension: account.extension,
		changePassword: false,
		isFinalized: account.isFinalized,
		changeSignature: false,
		isDigitalSignatureEnabled: account.isDigitalSignatureEnabled,
		signatureImage: account?.digitalSignatureUrl
			? await toBase64ImageUrl(account.digitalSignatureUrl)
			: null,
		oldPassword: null,
		newPassword: null,
		confirmNewPassword: null,
		deleteImage: false,
		image: null,
		imageUrl: null,
		activeInLMS: account.activeInLMS,
	};
};
