import { isValidFormattedPhoneNumber } from 'acceligent-shared/utils/phone';
import { EMAIL_REGEX } from 'acceligent-shared/constants/regex';

import type * as UserRequestModel from 'ab-requestModels/users.requestModel';

export function emailValidate(values: UserRequestModel.UserEmailCredentials) {
	const errors: ValidationErrors = {};

	if (!values.email) {
		errors.email = 'Email is required';
	} else if (!EMAIL_REGEX.test(values.email)) {
		errors.email = 'Invalid email address';
	}

	if (!values.password) {
		errors.password = 'Password required';
	}

	return errors;
}

export function phoneValidate(values: UserRequestModel.UserPhoneCredentials) {
	const errors: ValidationErrors = {};

	if (!values.countryCode) {
		errors.countryCode = 'Country code is required';
	}

	if (!values.phoneNumber) {
		errors.phoneNumber = 'Mobile phone is required';
	} else if (!isValidFormattedPhoneNumber(values.phoneNumber, values.countryCode)) {
		errors.phoneNumber = 'Invalid mobile phone';
	}

	return errors;
}
