import ResourceStatusEnum from 'acceligent-shared/enums/resourceStatus';

import type EmployeeStatusBase from 'ab-domain/models/employeeStatus/base';
import type EquipmentStatusBase from 'ab-domain/models/equipmentStatus/base';

type ResourceStatus = EmployeeStatusBase | EquipmentStatusBase;
type ResourceStatusList = EmployeeStatusBase[] | EquipmentStatusBase[];

export class Item {
	static DEFAULT_AVAILABLE: Item = Object.freeze({
		id: -1,
		name: 'Available',
		index: 0,
		available: true,
		isDeleted: false,
	});

	id: number;
	name: string;
	/** 1-based index */
	index: number;
	available: boolean;
	isDeleted: boolean;

	constructor(status: ResourceStatus) {
		if (!status.name || status.index === null) {
			throw new Error('Resource Status not valid');
		}

		this.id = status.id;
		this.name = status.name;
		this.index = status.index;
		this.available = status.available;
		this.isDeleted = status.status === ResourceStatusEnum.DELETED;
	}
}

export type List = Item[];

export class Default {
	available: List;
	unavailable: List;

	constructor(statuses: ResourceStatusList) {
		this.available = [];
		this.unavailable = [];
		for (const _status of statuses) {
			const item = new Item(_status);
			if (item.available) {
				this.available.push(item);
			} else {
				this.unavailable.push(item);
			}
		}
	}
}
