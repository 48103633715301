import * as React from 'react';
import { Row, Col } from 'react-bootstrap';

import TimeFormat from 'acceligent-shared/enums/timeFormat';

import * as TimeUtils from 'acceligent-shared/utils/time';

import type { LogViewModel } from 'ab-viewModels/log.viewModel';

import LockedValue from 'af-components/LockedValue';
import CodeField from 'af-components/CodeField';
import SegmentLabel from 'af-components/SegmentLabel';

interface Props {
	log: LogViewModel;
}

interface ChangedFieldWithValues {
	field: string;
	oldValue: Nullable<string>;
	newValue: Nullable<string>;
}

export default class LogPreviewFields extends React.PureComponent<Props> {
	render() {
		const { log } = this.props;
		const changedFieldsWithValues: ChangedFieldWithValues[] = this.prepareFields(log);

		return (
			<>
				<Row className="row--padded">
					<Col sm={6}><LockedValue label="Timestamp" value={TimeUtils.formatDate(log.timestamp, TimeFormat.FULL_DATE_LONG)} /></Col>
					<Col sm={18}><LockedValue label="By" value={log.userName} /></Col>
				</Row>
				<Row className="row--padded">
					<Col sm={6}><LockedValue label="Section" value={log.section} /></Col>
					<Col sm={6}><LockedValue label="Action Type" value={log.action} /></Col>
					<Col sm={12} />
				</Row>
				{
					(log.containsMultipleValues || log.field && log.field !== '-') &&
					<>
						<Row>
							<Col sm={24}>
								<SegmentLabel label="Field" />
							</Col>
						</Row>
						<Row>
							<Col sm={6}>Field name</Col>
							<Col sm={8}>Old Value</Col>
							<Col sm={8}>New Value</Col>
							<Col sm={2} />
						</Row>
						{
							changedFieldsWithValues.map((element) => {
								return (
									<Row key={element.field}>
										<Col sm={6}><LockedValue label="Field name" renderLabel={false} value={element.field?.toString()} /></Col>
										<Col sm={8}><LockedValue label="Old Value" renderLabel={false} value={element.oldValue?.toString()} /></Col>
										<Col sm={8}><LockedValue label="New Value" renderLabel={false} value={element.newValue?.toString()} /></Col>
										<Col sm={2} />
									</Row>
								);
							})
						}
					</>
				}
				<Row className="row--padded">
					<Col sm={24}>
						<CodeField isBlue={true}>
							{log.description}
						</CodeField>
					</Col>
				</Row>
			</>
		);
	}

	prepareFields = (log: LogViewModel): ChangedFieldWithValues[] => {
		if (log.containsMultipleValues) {
			const oldValues = log.oldValue ? JSON.parse(log.oldValue) : null;
			const newValues = log.newValue ? JSON.parse(log.newValue) : null;
			const concatenatedValues = { ...oldValues, ...newValues };
			return Object.keys(concatenatedValues).map((_key) => {
				return {
					field: _key,
					oldValue: oldValues ? oldValues[_key] : '-',
					newValue: newValues ? newValues[_key] : '-',
				};
			});
		} else {
			return [{
				field: log.field ?? '-',
				oldValue: log.oldValue ?? '-',
				newValue: log.newValue ?? '-',
			}];
		}
	};
}
