import type ReportGenerationProcessStatus from 'acceligent-shared/enums/reportGenerationProcessStatus';

export class W_WorkOrder_DownloadPrivateConfirmation_VM {
	status: ReportGenerationProcessStatus;
	error?: string[];
	url: Nullable<string>;

	constructor(
		status: ReportGenerationProcessStatus,
		error: string[] | undefined,
		url: Nullable<string>
	) {
		this.status = status;
		this.error = error;
		this.url = url;
	}
}
