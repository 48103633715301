import type AccountBase from 'ab-domain/models/account/base';
import type UserBase from 'ab-domain/models/user/base';

import { FAKE_ACCOUNT_DATA } from 'acceligent-shared/constants/value';

import { getUserName } from 'acceligent-shared/utils/user';

export interface UpdatedByViewModel {
	accountId?: number;
	userId?: number;
	firstName?: string;
	lastName?: string;
	fullName: string;
}

class UpdatedByAccountViewModel implements UpdatedByViewModel {
	accountId: number;
	userId: number;
	firstName: string;
	lastName: string;
	fullName: string;
	fullNameShort: string;

	constructor(account: Nullable<AccountBase>) {
		const accountObject = account ?? { id: FAKE_ACCOUNT_DATA.ACCOUNT_ID } as unknown as AccountBase;
		const accountUser = accountObject.user || { id: accountObject.userId || FAKE_ACCOUNT_DATA.USER_ID } as UserBase;

		this.accountId = accountObject.id;
		this.userId = accountObject.userId;
		this.firstName = accountUser.firstName;
		this.lastName = accountUser.lastName;
		this.fullName = getUserName(accountUser);
		this.fullNameShort = getUserName(accountUser, true);
	}

	static toViewModelPlain(accountId: number, fullName: string): UpdatedByAccountViewModel {
		return {
			accountId,
			fullName,
		} as UpdatedByAccountViewModel;
	}
}

export const UPDATED_BY_FAKE_USER = new UpdatedByAccountViewModel({
	id: FAKE_ACCOUNT_DATA.ACCOUNT_ID,
	userId: FAKE_ACCOUNT_DATA.USER_ID,
} as unknown as AccountBase);
