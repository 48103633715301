import type AutoNotifyOption from 'acceligent-shared/enums/autoNotifyOption';

import type NotificationSettingBase from 'ab-domain/models/notificationSetting/base';

import type NotificationPriority from 'ab-enums/notificationPriority.enum';

export interface GeneralNotifications {
	isEnabled: boolean;
	isEnabledAutoNotificationsOnWorkDays: boolean;
	notificationPriority: NotificationPriority;
}

export interface DefaultNotificationTimesForm {
	notifyOnMonday: string;
	notifyOnTuesday: string;
	notifyOnWednesday: string;
	notifyOnThursday: string;
	notifyOnFriday: string;
	notifyOnSaturday: string;
	notifyOnSunday: string;
}

export class DefaultNotificationTimes {
	notifyOnMonday: Nullable<number>;
	notifyOnTuesday: Nullable<number>;
	notifyOnWednesday: Nullable<number>;
	notifyOnThursday: Nullable<number>;
	notifyOnFriday: Nullable<number>;
	notifyOnSaturday: Nullable<number>;
	notifyOnSunday: Nullable<number>;

	constructor(notificationSetting: NotificationSettingBase) {
		this.notifyOnMonday = notificationSetting.notifyOnMonday;
		this.notifyOnTuesday = notificationSetting.notifyOnTuesday;
		this.notifyOnWednesday = notificationSetting.notifyOnWednesday;
		this.notifyOnThursday = notificationSetting.notifyOnThursday;
		this.notifyOnFriday = notificationSetting.notifyOnFriday;
		this.notifyOnSaturday = notificationSetting.notifyOnSaturday;
		this.notifyOnSunday = notificationSetting.notifyOnSunday;
	}

	static toFrom(model: DefaultNotificationTimes): { notifyOnDay: number[] | undefined; } {
		if (
			model.notifyOnSunday === null
			|| model.notifyOnMonday === null
			|| model.notifyOnTuesday === null
			|| model.notifyOnWednesday === null
			|| model.notifyOnThursday === null
			|| model.notifyOnFriday === null
			|| model.notifyOnSaturday === null
		) {
			return {
				notifyOnDay: undefined,
			};
		}

		return {
			notifyOnDay: [
				model.notifyOnSunday,
				model.notifyOnMonday,
				model.notifyOnTuesday,
				model.notifyOnWednesday,
				model.notifyOnThursday,
				model.notifyOnFriday,
				model.notifyOnSaturday,
			],
		};
	}
}

interface UserGroupRM {
	id: number;
	name: string;
}

export interface SpecialEmails {
	enableScheduleBoardEmail: boolean;
	notifyScheduleBoardEmail: Nullable<number>;
	scheduleBoardEmailUserGroup: Nullable<UserGroupRM>;

	enablePastWorkOrdersNotification: boolean;
	pastWorkOrdersNotificationUserGroup: Nullable<UserGroupRM>;

	enableNotifyYesterdaysBoard: boolean;
	notifyYesterdaysBoardAt: Nullable<number>;
	notifyYesterdaysBoardUserGroup: Nullable<UserGroupRM>;

	enableNotifyFailed: boolean;
	notifyFailedAt: Nullable<number>;
	notifyFailedUserGroup: Nullable<UserGroupRM>;

	notifyOnOrderCreation: boolean;
	notifyOnOrderCreationUserGroup: Nullable<UserGroupRM>;

	notifyForUnfinishedOrders: boolean;
	notifyForUnfinishedOrdersUserGroup: Nullable<UserGroupRM>;

	enableUnfinishedPurchaseOrderCreatedNotification: boolean;
	enablePurchaseOrderWithUnreceivedItemsNotification: boolean;

	enableInvoiceRemindersNotification: boolean;
	notifyInvoiceRemindersAt: Nullable<number>;
	notifyInvoiceRemindersFrom: string;
	notifyInvoiceRemindersUserGroup: Nullable<UserGroupRM>;

	enableItemLowQuantityNotification: boolean;
	itemLowQuantityNotificationUserGroup: Nullable<UserGroupRM>;

	enableDailyItemLowQuantityNotification: boolean;
	dailyItemLowQuantityNotificationUserGroup: Nullable<UserGroupRM>;

	enableToolRepairNotification: boolean;
	toolRepairNotificationUserGroup: Nullable<UserGroupRM>;

	notifyForOrdersTotalExpenseDaily: boolean;
	notifyForOrdersTotalExpenseDailyUserGroup: Nullable<UserGroupRM>;

	notifyForOrdersTotalExpenseWeekly: boolean;
	notifyForOrdersTotalExpenseWeeklyUserGroup: Nullable<UserGroupRM>;

	notifyForOrdersTotalExpenseMonthly: boolean;
	notifyForOrdersTotalExpenseMonthlyUserGroup: Nullable<UserGroupRM>;

	notifyForDuplicateOrdersMonthly: boolean;
	notifyForDuplicateOrdersMonthlyUserGroup: Nullable<UserGroupRM>;

	notifyForInexpensiveOrdersWeekly: boolean;
	notifyForInexpensiveOrdersWeeklyUserGroup: Nullable<UserGroupRM>;

	notifyForInexpensiveOrdersMonthly: boolean;
	notifyForInexpensiveOrdersMonthlyUserGroup: Nullable<UserGroupRM>;

	notifyForExpensiveOrdersWeekly: boolean;
	notifyForExpensiveOrdersWeeklyUserGroup: Nullable<UserGroupRM>;

	notifyForExpensiveOrdersMonthly: boolean;
	notifyForExpensiveOrdersMonthlyUserGroup: Nullable<UserGroupRM>;
}

export class AutomaticNotifications {
	notifyOnPublish: Nullable<AutoNotifyOption>;
	notifyOnCancel: Nullable<AutoNotifyOption>;
	notifyOnPause: boolean;
	notifyOnResume: boolean;
	notifyUnassignedEmployees: boolean;
	notifyMultipleAssignments: boolean;
	notifySupervisors: boolean;
	notifyOnReportReject: boolean;
	notifyOnTimeSheetReject: boolean;

	// Only on FE to evaluate checkbox
	enableAutoNotifyOnPublish?: boolean;
	enableAutoNotifyOnCancel?: boolean;

	enableNotifyQAQC: boolean;
	enableNotifyDeliverableAssignee: boolean;
	enableAutoNotifyDailyTips: boolean;

	static fromForm(form: AutomaticNotifications): AutomaticNotifications {
		return {
			notifyOnPublish: form.enableAutoNotifyOnPublish ? form.notifyOnPublish : null,
			notifyOnCancel: form.enableAutoNotifyOnCancel ? form.notifyOnCancel : null,
			notifyOnPause: form.notifyOnPause,
			notifyOnResume: form.notifyOnResume,
			notifyUnassignedEmployees: form.notifyUnassignedEmployees,
			notifyMultipleAssignments: form.notifyMultipleAssignments,
			notifySupervisors: form.notifySupervisors,
			enableNotifyQAQC: form.enableNotifyQAQC,
			enableNotifyDeliverableAssignee: form.enableNotifyDeliverableAssignee,
			enableAutoNotifyDailyTips: form.enableAutoNotifyDailyTips,
			notifyOnReportReject: form.notifyOnReportReject,
			notifyOnTimeSheetReject: form.notifyOnTimeSheetReject,
		};
	}
}

export interface TemporaryLaborNotificationsRM {
	notifyTemporaryLabor: boolean;
}
