import * as React from 'react';
import type { ResolveThunks } from 'react-redux';
import { connect } from 'react-redux';
import { compose } from 'redux';
import type { CustomRouteComponentProps } from 'react-router-dom';
import type { InjectedFormProps} from 'redux-form';
import { reduxForm, Field } from 'redux-form';
import { Form, Row, Col } from 'react-bootstrap';

import * as OrganizationActions from 'af-actions/organizations';

import type { OrganizationForgottenByEmailRequestModel } from 'ab-requestModels/organizationForgotten.requestModel';

import Input from 'af-fields/Input';

import SubmitButton from 'af-components/SubmitButton';

import { ORGANIZATION_FORGOTTEN } from 'af-constants/reduxForms';

import validate from 'af-root/scenes/Authentication/ForgotOrganization/validations';

interface DispatchProps {
	findForgottenOrganization: typeof OrganizationActions.findForgottenOrganization;
}

type OwnProps = CustomRouteComponentProps;

type Props = ResolveThunks<DispatchProps> & OwnProps & InjectedFormProps<OrganizationForgottenByEmailRequestModel>;

class ChooseOrganization extends React.Component<Props> {

	findOrganizations = async (form: OrganizationForgottenByEmailRequestModel) => {
		const { findForgottenOrganization } = this.props;

		await findForgottenOrganization(form);
	};

	render(): JSX.Element {
		const { handleSubmit, submitting } = this.props;

		return (
			<div className="form-segment form-segment--mini">
				<div className="form-box form-box--standalone">
					<h2>Forgot the Path to Your Organization?</h2>
					<h4>Enter the email address used for login</h4>
					<h4>We will email You where You need to go.</h4>
					<Form onSubmit={handleSubmit(this.findOrganizations)}>
						<Row>
							<Col sm={24}>
								<Field
									component={Input}
									id="email"
									label="Email"
									name="email"
									type="text"
								/>
							</Col>
						</Row>
						<Row className="row--padded">
							<Col sm={24}>
								<SubmitButton
									className="btn--full"
									disabled={submitting}
									label="Submit"
									reduxFormSubmitting={submitting}
								/>
							</Col>
						</Row>
					</Form>
				</div>
			</div>
		);
	}
}

function mapDispatchToProps(): DispatchProps {
	return {
		findForgottenOrganization: OrganizationActions.findForgottenOrganization,
	};
}

const enhance = compose<React.ComponentClass<OwnProps>>(
	connect<null, DispatchProps, OwnProps>(null, mapDispatchToProps()),
	reduxForm({ form: ORGANIZATION_FORGOTTEN, validate })
);

export default enhance(ChooseOrganization);
