import * as Err from 'restify-errors';

import * as TimeUtils from 'acceligent-shared/utils/time';
import * as CodeUtils from 'acceligent-shared/utils/codes';

import type QuantityUnitType from 'acceligent-shared/enums/quantityUnit';
import { QuantityUnitMap } from 'acceligent-shared/enums/quantityUnit';
import type WorkSummaryStatus from 'acceligent-shared/enums/workSummaryStatus';
import TimeFormat from 'acceligent-shared/enums/timeFormat';
import type * as ReportBlockFieldEnum from 'acceligent-shared/enums/reportBlockField';

import type JobWorkSummaryBase from 'ab-domain/models/jobWorkSummary/base';
import type BillingCodeBase from 'ab-domain/models/billingCode/base';

import type { JobWorkSummaryDataType } from 'ab-enums/jobWorkSummaryDataType.enum';

import { MAX_BILLABLE_WORK_DEFINITION_FIELDS, MAX_BILLABLE_WORK_INFORMATION_FIELDS } from 'ab-common/constants/value';

import { convertUnits } from 'ab-utils/unitConversion.util';

class BillingCodeVM {
	id: number;
	unit: QuantityUnitType;

	constructor(billingCode: BillingCodeBase) {
		this.id = billingCode.id;
		this.unit = billingCode.unit;
	}
}

const resolveJobWorkSummaryTableHeadersForCSVExport = (maxNumberOfDefFields: number, maxNumberOfInfoFields: number) => {
	const defFields: string[] = [];
	const infoFields: string[] = [];
	for (let i = 0; i < maxNumberOfDefFields; i++) {
		defFields.push(`Definition Field ${i + 1}`);
	}
	for (let i = 0; i < maxNumberOfInfoFields; i++) {
		infoFields.push(`Information Field ${i + 1}`);
	}

	return [
		'Billing Code',
		'Job Name',
		'Revenue',
		'Work Order',
		'Start Date',
		'Review Status',
		'Invoice Id',
		'Work',
		'Type',
		...defFields,
		...infoFields,
		'Quantity',
		'Unit',
		'Group',
		'Unit Price per billingCode Unit ($)',
		'Unit Price per quantity Unit ($)',
		'Description',
	];
};

const _resolveDefFieldFullValue = (value: string, name: Nullable<string>) => {
	if (!name) {
		return value;
	}

	return `${name}: ${value}`;
};

const _calculateUnitPricePerQuantityUnit = (jws: JobWorkSummaryVM) => {
	if (!jws.unitPrice || !jws.unit || !jws?.billingCode?.unit) {
		return '';
	}

	if (QuantityUnitMap[jws.unit] === QuantityUnitMap[jws.billingCode.unit]) {
		return `${parseFloat(jws.unitPrice.toFixed(4))}`;
	}

	const convertedUnit = convertUnits(jws.unitPrice, QuantityUnitMap[jws.unit], QuantityUnitMap[jws.billingCode.unit]);

	return convertedUnit ? `${parseFloat(convertedUnit.toFixed(8))}` : '';
};

export default class JobWorkSummaryVM {
	id: number;
	fieldReportId: Nullable<number>;
	workOrderId: Nullable<number>;
	workOrderCode: Nullable<string>;
	workRequestId: number;
	workRequest: string;
	billingCodeId: Nullable<number>;
	billingCode: Nullable<BillingCodeVM>;
	customerId: Nullable<string>;
	unitPrice: Nullable<number>;
	description: Nullable<string>;
	group: Nullable<string>;
	quantity: Nullable<number>;
	startDate: string;
	reviewStatus: Nullable<WorkSummaryStatus>;
	invoiceId: Nullable<string>;
	work: string;
	type: string;
	typeValue: Nullable<string>;
	typeFieldType: Nullable<ReportBlockFieldEnum.Type>;
	definitionField1Name: Nullable<string>;
	definitionField1Value: Nullable<string>;
	definitionField1Type: Nullable<ReportBlockFieldEnum.Type>;
	definitionField2Name: Nullable<string>;
	definitionField2Value: Nullable<string>;
	definitionField2Type: Nullable<ReportBlockFieldEnum.Type>;
	definitionField3Name: Nullable<string>;
	definitionField3Value: Nullable<string>;
	definitionField3Type: Nullable<ReportBlockFieldEnum.Type>;
	definitionField4Name: Nullable<string>;
	definitionField4Value: Nullable<string>;
	definitionField4Type: Nullable<ReportBlockFieldEnum.Type>;
	informationField1Value: Nullable<string>;
	informationField2Value: Nullable<string>;
	informationField3Value: Nullable<string>;
	informationField4Value: Nullable<string>;
	unit: Nullable<string>;
	revenue: Nullable<number>;
	originalData: Nullable<JobWorkSummaryVM>;
	dataType: JobWorkSummaryDataType;
	comment: Nullable<string>;

	constructor(jobWorkSummary: JobWorkSummaryBase) {
		if (!jobWorkSummary.workRequest) {
			throw new Err.InternalError('Include Work Request in Job Work Summary');
		}
		this.id = jobWorkSummary.id;
		this.fieldReportId = jobWorkSummary.fieldReportId ?? null;
		this.workOrderId = jobWorkSummary.workOrderId ?? null;
		this.workOrderCode = jobWorkSummary.workOrder ? CodeUtils.workOrderCode(jobWorkSummary.workOrder, jobWorkSummary.workOrder.workRequest) : null;
		this.workRequestId = jobWorkSummary.workRequestId;
		this.workRequest = jobWorkSummary.workRequest.jobCode;
		this.billingCodeId = jobWorkSummary.billingCodeId;
		this.billingCode = jobWorkSummary?.billingCode ?? null;
		this.customerId = jobWorkSummary.customerId;
		this.unitPrice = jobWorkSummary.unitPrice;
		this.description = jobWorkSummary.description;
		this.group = jobWorkSummary.group;
		this.quantity = jobWorkSummary.quantity;
		this.startDate = TimeUtils.formatDate(jobWorkSummary.startDate, TimeFormat.DB_DATE_ONLY);
		this.reviewStatus = jobWorkSummary.reviewStatus;
		this.invoiceId = jobWorkSummary.invoiceId;
		this.work = jobWorkSummary.work;
		this.type = jobWorkSummary.type;
		this.typeValue = jobWorkSummary.typeValue;
		this.typeFieldType = jobWorkSummary.typeFieldType;

		this.definitionField1Name = jobWorkSummary.definitionField1Name;
		this.definitionField1Value = jobWorkSummary.definitionField1Value;
		this.definitionField1Type = jobWorkSummary.definitionField1Type;
		this.definitionField2Name = jobWorkSummary.definitionField2Name;
		this.definitionField2Value = jobWorkSummary.definitionField2Value;
		this.definitionField2Type = jobWorkSummary.definitionField2Type;
		this.definitionField3Name = jobWorkSummary.definitionField3Name;
		this.definitionField3Value = jobWorkSummary.definitionField3Value;
		this.definitionField3Type = jobWorkSummary.definitionField3Type;
		this.definitionField4Name = jobWorkSummary.definitionField4Name;
		this.definitionField4Value = jobWorkSummary.definitionField4Value;
		this.definitionField4Type = jobWorkSummary.definitionField4Type;

		this.informationField1Value = JobWorkSummaryVM.resolveInformationField(jobWorkSummary, 1);
		this.informationField2Value = JobWorkSummaryVM.resolveInformationField(jobWorkSummary, 2);
		this.informationField3Value = JobWorkSummaryVM.resolveInformationField(jobWorkSummary, 3);
		this.informationField4Value = JobWorkSummaryVM.resolveInformationField(jobWorkSummary, 4);

		this.unit = jobWorkSummary.unit && QuantityUnitMap[jobWorkSummary.unit] ? QuantityUnitMap[jobWorkSummary.unit].replace('_', '/') : null;
		this.revenue = jobWorkSummary.revenue;
		this.originalData = jobWorkSummary.originalVersion ? new JobWorkSummaryVM(jobWorkSummary.originalVersion) : null;
		this.dataType = jobWorkSummary.dataType;
		this.comment = jobWorkSummary.comment;
	}

	static bulkConstructor = (jobWorkSummaries: JobWorkSummaryBase[]) => jobWorkSummaries.map((_jws) => new JobWorkSummaryVM(_jws));

	static resolveInformationField = (_jws: JobWorkSummaryBase, infoFieldIndex: number) => {
		const infoFieldValueKey = `informationField${infoFieldIndex}Value` as keyof JobWorkSummaryBase;
		const infoFieldNameKey = `informationField${infoFieldIndex}Name` as keyof JobWorkSummaryBase;
		const names = _jws[infoFieldNameKey];
		const values = _jws[infoFieldValueKey];

		if (!names || !values) {
			return null;
		}

		const valuesMap: Record<string, string[]> = {};

		(values as string[]).forEach((_value, _index) => {
			// if there is no name i.e. it's created from the Add Billable Work modal
			if (names?.[_index] === undefined) {
				!!valuesMap['']?.length
					? valuesMap[''].push(_value)
					: valuesMap[''] = [_value];
				return;
			}
			if (!valuesMap[names[_index]]) {
				valuesMap[names[_index]] = [values[_index] ?? 'N/A'];
			} else {
				valuesMap[names[_index]].push(values[_index] ?? 'N/A');
			}
		});

		return Object.keys(valuesMap).reduce((_acc, _curr) => {
			// if it has no name
			if (!_curr) {
				return valuesMap[_curr].join(', ');
			}

			_acc += `${_curr}: ${valuesMap[_curr].join(', ')}`;
			return _acc;
		}, '');
	};

	static resolveMaxNumberOfDefFieldsForTable = (_jwss: JobWorkSummaryVM[]) => {

		const max = _jwss.reduce((_acc, _jws) => {
			let currentJwsMax = 0;
			for (let i = 0; i < MAX_BILLABLE_WORK_DEFINITION_FIELDS; i++) {
				if (!_jws?.[`definitionField${i + 1}Value`]) {
					break;
				}
				currentJwsMax++;
			}
			return (currentJwsMax > _acc) ? currentJwsMax : _acc;
		}, 0);
		return max;
	};

	static resolveMaxNumberOfInfoFieldsForTable = (_jwss: JobWorkSummaryVM[]) => {

		const max = _jwss.reduce((_acc, _jws) => {
			let currentJwsMax = 0;
			for (let i = 0; i < MAX_BILLABLE_WORK_INFORMATION_FIELDS; i++) {
				if (!_jws?.[`informationField${i + 1}Value`]) {
					break;
				}
				currentJwsMax++;
			}
			return (currentJwsMax > _acc) ? currentJwsMax : _acc;
		}, 0);
		return max;
	};

	static toCSVData = (jobWorkSummaries: JobWorkSummaryVM[]): string[][] => {
		const maxNumberOfDefFields = JobWorkSummaryVM.resolveMaxNumberOfDefFieldsForTable(jobWorkSummaries);
		const maxNumberOfInfoFields = JobWorkSummaryVM.resolveMaxNumberOfInfoFieldsForTable(jobWorkSummaries);
		const headers = resolveJobWorkSummaryTableHeadersForCSVExport(maxNumberOfDefFields, maxNumberOfInfoFields);

		const rows: string[][] = jobWorkSummaries.map((_jws) => {
			const definitionFieldsValues: string[] = [];
			const informationalFieldsValues: string[] = [];
			for (let i = 0; i < maxNumberOfDefFields; i++) {
				const defField = !!_jws?.[`definitionField${i + 1}Value`]
					? _resolveDefFieldFullValue(_jws?.[`definitionField${i + 1}Value`], _jws?.[`definitionField${i + 1}Name`])
					: '';
				definitionFieldsValues.push(defField);
			}

			for (let i = 0; i < maxNumberOfInfoFields; i++) {
				informationalFieldsValues.push(_jws?.[`informationField${i + 1}Value`] ?? '');
			}

			return [
				_jws.customerId ?? '',
				_jws.workRequest,
				_jws.revenue ? `${_jws.revenue.toFixed(2)}` : '',
				_jws.workOrderCode ?? '',
				_jws.startDate,
				_jws.reviewStatus ?? '',
				_jws.invoiceId ?? '',
				_jws.work,
				_jws.type,
				...definitionFieldsValues,
				...informationalFieldsValues,
				_jws.quantity + '',
				_jws.unit ?? '',
				_jws.group ?? '',
				_jws.unitPrice ? `${parseFloat(_jws.unitPrice.toFixed(4))}` : '',
				_calculateUnitPricePerQuantityUnit(_jws),
				_jws.description ?? '',
			];
		});

		return [headers, ...rows];
	};
}
