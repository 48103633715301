import * as React from 'react';

import Tooltip from 'af-components/Tooltip';
import { ModalLink } from 'af-components/ModalNavigation/ModalRouter';
import { createHeadingLink } from 'af-utils/userGuideModal.util';

import type { SearchPageResults } from './types';
import { icons } from './routes';

interface Props {
	initialText?: string;
	onFilterTextChange: (filterText: string) => void;
	onSearch: (text: string) => void;
	searchLabel: string;
	dropdownSearchedResults?: SearchPageResults;
	getModalBodyBottomPositionForRecalculation: () => number | undefined;
}

interface LinkHeading {
	link: string;
	icon?: string;
	text: string;
}

interface LinkedHeadings {
	[heading: string]: {
		[heading: string]: {
			linkedHeading: LinkHeading;
		};
	};
}

interface LinkedHeadingsWithBreadcrumbs {
	[mainPage: string]: { // Section that is a main page
		[heading: string]: { // Either a page (orange heading) or a subpage (blue headings)
			headings: LinkHeading[]; // Subtitles (Bold non-colored headings)
			breadcrumbs: string[];
		};
	};
}

const scrollbarEndOffset = 10;

const SearchInput: React.FC<Props> = (props) => {
	const { onFilterTextChange, onSearch, searchLabel, dropdownSearchedResults, initialText, getModalBodyBottomPositionForRecalculation } = props;

	const [filterText, setFilterText] = React.useState(initialText ?? '');
	const [inputFocused, setInputFocused] = React.useState(false);
	const [isDropdownAllowed, setIsDropdownAllowed] = React.useState(false);
	const [dropdownHeight, setDropdownHeight] = React.useState(0);

	const preventDropdownHideRef = React.useRef<boolean>(false);
	const inputRef = React.useRef<HTMLInputElement | null>(null);
	const dropdownRef = React.useRef<HTMLDivElement | null>(null);
	const quickReleseCoords = React.useRef<{ x: number; y: number; }>(); // Needed because existing logic does not handle mouse click and relese on the scrollbar

	React.useEffect(() => {
		setFilterText(initialText ?? '');
	}, [initialText]);

	const handleFocus = React.useCallback(() => {
		setInputFocused(true);
		setIsDropdownAllowed(true);
	}, []);

	const handleBlur = React.useCallback(() => {
		setTimeout(() => {
			if (preventDropdownHideRef.current) {
				preventDropdownHideRef.current = false;
				return;
			}
			setInputFocused(false);
			setIsDropdownAllowed(true);
		}, 200);
	}, []);

	const handleClickInsideDropdown = React.useCallback(() => {
		preventDropdownHideRef.current = true;
		inputRef.current?.focus();
	}, []);

	React.useEffect(() => {
		const mouseDown = (e: MouseEvent) => {
			quickReleseCoords.current = { x: e.x, y: e.y };
		};

		const mouseUp = (e: MouseEvent) => {
			const boundingBox = dropdownRef.current?.getBoundingClientRect();
			if (!boundingBox || !quickReleseCoords.current) {
				return;
			}
			if (e.x !== quickReleseCoords.current.x || e.y !== quickReleseCoords.current.y) {
				return;
			}
			if (boundingBox.left + boundingBox.width - scrollbarEndOffset < quickReleseCoords.current.x &&
				boundingBox.left + boundingBox.width > quickReleseCoords.current.x &&
				boundingBox.top < quickReleseCoords.current.y &&
				boundingBox.top + boundingBox.height > quickReleseCoords.current.y
			) {
				preventDropdownHideRef.current = true;
				inputRef.current?.focus();
			}
		};
		window.addEventListener('mousedown', mouseDown);
		window.addEventListener('mouseup', mouseUp);

		return () => {
			window.removeEventListener('mousedown', mouseDown);
			window.removeEventListener('mouseup', mouseUp);
		};

	}, []);

	// We want it to execute after dropdown click
	const handleClickOnLink = React.useCallback(() => {
		setTimeout(() => {
			setFilterText('');
			onFilterTextChange('');
			preventDropdownHideRef.current = false;
			inputRef.current?.blur();
		}, 100);
	}, [onFilterTextChange]);

	const handleInputChange = React.useCallback((e: React.ChangeEvent<HTMLInputElement> | string) => {
		const currFilterText = typeof e === 'string' ? e : e.target.value;
		setFilterText(currFilterText);
		onFilterTextChange(currFilterText);
	}, [onFilterTextChange]);

	const _onSearch = React.useCallback(() => {
		onSearch(filterText);
	}, [filterText, onSearch]);

	const handleKeyDown = React.useCallback((e: React.KeyboardEvent<HTMLInputElement>) => {
		if (e.key === 'Enter') {
			_onSearch();
			inputRef.current?.blur();
		}
	}, [_onSearch]);

	const renderSearchIcon = React.useCallback(() => {
		return (
			<Tooltip message="Search" placement="bottom">
				<span className="icon-search" onClick={_onSearch} />
			</Tooltip>
		);
	}, [_onSearch]);

	const linkedHeadings = React.useMemo(() => {
		if (!dropdownSearchedResults) return {};
		const headingByPage: LinkedHeadings = {};
		for (const page of Object.keys(dropdownSearchedResults)) {
			if (!headingByPage[page]) {
				headingByPage[page] = {};
			}
			for (const searchResult of Object.values(dropdownSearchedResults[page])) {
				if (!searchResult.depth) {
					continue; // We only want headings
				}

				if (searchResult.subPage) {
					if (!headingByPage[page][searchResult.subPage]) {
						headingByPage[searchResult.page][searchResult.subPage] = {
							linkedHeading: {
								link: createHeadingLink(page, searchResult.subPage, 'subpage'),
								icon: icons[page],
								text: searchResult.subPage,
							},
						};
					}
					continue;
				}

				if (!headingByPage[page][searchResult.page]) {
					headingByPage[searchResult.page][searchResult.page] = {
						linkedHeading: {
							link: createHeadingLink(page, searchResult.page, 'page'),
							icon: icons[page],
							text: searchResult.page.split('_').join(' '),
						},
					};
				}
				continue;
			}

			if (Object.keys(headingByPage[page]).length === 0) {
				delete headingByPage[page];
			}
		}

		return headingByPage;
	}, [dropdownSearchedResults]);

	const renderHeadingLinks = React.useMemo(() => {
		const headingsByMainPage = linkedHeadings;

		return Object.values(headingsByMainPage).map((sections) => {
			return Object.values(sections).map((headingLinkData) => {
				return (
					<div className="user-guide__search-link" key={headingLinkData.linkedHeading.link}>
						<span className={`${headingLinkData.linkedHeading.icon}`}></span>
						<ModalLink link={`${headingLinkData.linkedHeading.link}`} onClick={handleClickOnLink}>
							<span className="heading">{headingLinkData.linkedHeading.text}</span>
						</ModalLink>
					</div>
				);
			});
		});

	}, [handleClickOnLink, linkedHeadings]);

	const subtitleSearchSections = React.useMemo(() => {
		if (!dropdownSearchedResults) return {};
		const _sections: LinkedHeadingsWithBreadcrumbs = {};
		for (const page of Object.keys(dropdownSearchedResults)) {
			if (!_sections[page]) {
				_sections[page] = {};
			}
			for (const searchResult of Object.values(dropdownSearchedResults[page])) {
				if (!searchResult.depth || !searchResult.subTitle || !searchResult.subTitle.toLowerCase().includes(filterText.toLowerCase())) {
					continue; // We only want subtitle headings
				}

				const breadcrumbs: string[] = [searchResult.page, searchResult.subPage].filter((section): section is string => typeof section === 'string');

				if (searchResult.subPage) {
					if (!_sections[page][searchResult.subPage]) {
						_sections[searchResult.page][searchResult.subPage] = {
							headings: [],
							breadcrumbs,
						};
					}
					_sections[searchResult.page][searchResult.subPage].headings.push({
						link: createHeadingLink(page, searchResult.subTitle, 'subtitle'),
						text: searchResult.subTitle,
					});
					continue;
				}

				if (!_sections[page][searchResult.page]) {
					_sections[searchResult.page][searchResult.page] = {
						headings: [],
						breadcrumbs,
					};
				}
				_sections[page][searchResult.page].headings.push({
					link: createHeadingLink(page, searchResult.subTitle, 'subtitle'),
					text: searchResult.subTitle,
				});
				continue;
			}

			if (Object.keys(_sections[page]).length === 0) {
				delete _sections[page];
			}
		}

		return _sections;

	}, [dropdownSearchedResults, filterText]);

	const renderSubtitleSearches = React.useMemo(() => {
		const _sections = subtitleSearchSections;

		return Object.keys(_sections).map((page) => {
			return (
				<div className="section-container" key={page}>
					{<div className="section">
						{page.split('_').join(' ').toUpperCase()}
					</div>}

					{/* The sections are main pages */}
					{Object.values(_sections[page]).map((section) => {

						{ /* Each section has some number of subtitles that are part of some heading sections that are shown as breadcrumbs*/ }
						return section.headings.map((subtitle) => {
							return (
								<div key={subtitle.text + section}>
									<div className="subtitle-container">
										<ModalLink link={`${subtitle.link}`} onClick={handleClickOnLink}>
											<span className="subtitle">{subtitle.text}</span>
										</ModalLink>
									</div>
									{section.breadcrumbs.length > 0 && <div className="subtitle-breadcrumbs">
										{section.breadcrumbs[0].split('_').join(' ')}
										{section.breadcrumbs.slice(1).map((crumb) => {
											return (
												<div key={crumb}>
													<span className="icon-right"></span>
													{crumb}
												</div>
											);
										})}
									</div>}
								</div>
							);
						});
					})}
				</div>
			);
		});

	}, [handleClickOnLink, subtitleSearchSections]);

	const showDropdown = React.useMemo(() => {
		return inputFocused && ((renderHeadingLinks ?? []).length > 0 || (renderSubtitleSearches ?? []).length > 0) && isDropdownAllowed;
	}, [inputFocused, isDropdownAllowed, renderHeadingLinks, renderSubtitleSearches]);

	React.useLayoutEffect(() => {
		const recalculateBy = (dropdownRef.current?.getBoundingClientRect().top ?? 0) + window.scrollY;
		setDropdownHeight((getModalBodyBottomPositionForRecalculation() ?? 0) - recalculateBy);
	}, [getModalBodyBottomPositionForRecalculation, showDropdown]);

	return (
		<div>
			<div className={'user-guide__search-input'} >
				<input
					onBlur={handleBlur}
					onChange={handleInputChange}
					onFocus={handleFocus}
					onKeyDown={handleKeyDown}
					placeholder={`Search ${searchLabel}`}
					ref={inputRef}
					type="search"
					value={filterText}
				/>
				{renderSearchIcon()}
			</div>
			{showDropdown && <div className="user-guide__search-dropdown-container">
				<div className="user-guide__search-dropdown"
					onClick={handleClickInsideDropdown}
					onScroll={handleClickInsideDropdown}
					ref={dropdownRef}
					style={{ height: dropdownHeight }}
				>
					<div>
						{renderHeadingLinks}
					</div>
					<div className="user-guide__search-subtitles">
						{renderSubtitleSearches}
					</div>
				</div>
			</div>}
		</div>
	);
};

export default React.memo(SearchInput);
