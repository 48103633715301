import { isValidFormattedPhoneNumber } from 'acceligent-shared/utils/phone';
import { isValidTextInput } from 'acceligent-shared/utils/text';

import type LocationRequestModel from 'ab-requestModels/location.requestModel';

import { isValidNumber } from 'ab-utils/validation.util';

import { UNKNOWN_LOCATION_NICKNAME } from 'ab-common/constants/value';

export function validate(values: LocationRequestModel) {
	const errors: ValidationErrors = {};

	if (!isValidTextInput(values.nickname)) {
		errors.nickname = 'A nickname is required';
	} else if (UNKNOWN_LOCATION_NICKNAME === values.nickname.toUpperCase()) {
		errors.nickname = 'A nickname is invalid';
	}

	if (!values.color) {
		errors.color = 'Color is required';
	}

	if (!values.faxCountryCode) {
		errors.faxCountryCode = 'Country code is required';
	}

	if (!isValidTextInput(values.fax)) {
		errors.fax = 'Fax is required';
	} else if (!isValidFormattedPhoneNumber(values.fax, values.faxCountryCode)) {
		errors.fax = 'Invalid fax';
	}

	if (!values.phoneCountryCode) {
		errors.phoneCountryCode = 'Country code is required';
	}

	if (!values.phone) {
		errors.phone = 'Mobile phone is required';
	} else if (!isValidFormattedPhoneNumber(values.phone, values.phoneCountryCode)) {
		errors.phone = 'Invalid mobile phone';
	}

	if (!isValidTextInput(values.street)) {
		errors.street = 'Location required';
	}

	if (!isValidTextInput(values.locality!)) {
		errors.locality = 'City required';
	}

	if (!isValidTextInput(values.aa1!)) {
		errors.aa1 = 'State required';
	}

	if (!isValidTextInput(values.postalCode!)) {
		errors.postalCode = 'Zip required';
	}

	if (!isValidTextInput(values.country!)) {
		errors.country = 'Country required';
	}

	if (!isValidNumber(values.latitude)) {
		errors.latitude = 'Latitude required';
	}

	if (!isValidNumber(values.longitude)) {
		errors.longitude = 'Longitude required';
	}

	return errors;
}
