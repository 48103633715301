import * as React from 'react';
import { FieldArray } from 'redux-form';
import { Row, Col } from 'react-bootstrap';

import LabelWithColor from 'af-components/LabelWithColor';

import type LocationDepartmentVM from 'ab-viewModels/location/locationDepartment.viewModel';

import type { DepartmentOwnProps } from './Department';
import Department from './Department';

type Props = {
	location: LocationDepartmentVM;
	index: number;
};

const Location = (props: Props) => {
	const { location, index } = props;

	const deletedDepartmentsIds = location.departments.reduce((departmentsIds, department) => {
		if (department.deletedAt) {
			departmentsIds.push(department.id);
		}
		return departmentsIds;
	}, [] as number[]);

	return (
		<>
			<Row>
				<Col md={6}>
					<LabelWithColor
						color={location.color}
						text={location.name}
					/>
				</Col>
				<Col md={2} />
				<Col md={4}>{index === 0 ? 'Current Stock' : null}</Col>
				<Col md={4}>{index === 0 ? 'Minimum Stock' : null}</Col>
				<Col md={4}>{index === 0 ? 'Maximum Stock' : null}</Col>
			</Row>
			<FieldArray<DepartmentOwnProps>
				component={Department}
				deletedDepartmentsIds={deletedDepartmentsIds}
				name={`location.${location.id}`}
			/>
		</>
	);
};

export default Location;
