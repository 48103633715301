import * as React from 'react';
import { Button } from 'react-bootstrap';
import { compose } from 'redux';
import type { InjectedFormProps, FormErrors} from 'redux-form';
import { Field, reduxForm, formValueSelector, getFormSyncErrors } from 'redux-form';
import type { ConnectedProps } from 'react-redux';
import { connect } from 'react-redux';

import { getId } from 'acceligent-shared/utils/array';

import WorkOrderReviewStatus, { WorkOrderReviewLevel } from 'acceligent-shared/enums/workOrderReviewStatus';

import type RejectReviewRM from 'ab-requestModels/workOrder/rejectReview';

import type { WorkOrderEmployeeVM } from 'ab-viewModels/workOrder/workOrderFieldReportCard.viewModel';

import CustomModal from 'af-components/CustomModal';
import SubmitButton from 'af-components/SubmitButton';

import Dropdown from 'af-fields/Dropdown';
import Textarea from 'af-fields/Textarea';

import type { RootState } from 'af-reducers';

import { REJECT_REPORT } from 'af-constants/reduxForms';
import { INPUT_FIELD_MAX_CHARACTERS } from 'af-constants/values';

import WorkOrderEmployeeSelect from './WorkOrderEmployeeSelect';

import { validateRejectForm as validate } from '../validations';

type Level = typeof RejectModal.OPTIONS[0];

export interface OwnProps {
	closeModal: () => void;
	getUsers: (targetLevel: WorkOrderReviewLevel) => Nullable<WorkOrderEmployeeVM[]>;
	onReject: (data: RejectReviewRM) => Promise<void>;
	reviewLevel: WorkOrderReviewLevel;
	reviewStatus: WorkOrderReviewStatus;
	showModal: boolean;
	workOrderCode: string;
}

type FormOwnProps = OwnProps & ConnectedProps<typeof connector>;
type Props = FormOwnProps & InjectedFormProps<RejectReviewRM, FormOwnProps>;

interface State {
	selectedLevel: WorkOrderReviewLevel;
	levelOptions: typeof RejectModal.OPTIONS;
	userOptions: Nullable<WorkOrderEmployeeVM[]>;
}

class RejectModal extends React.PureComponent<Props, State> {

	state: State = RejectModal.getInitialState(this.props);

	static readonly OPTIONS = [
		{ id: WorkOrderReviewLevel.LEVEL_0, label: 'Field Workers' },
		{ id: WorkOrderReviewLevel.LEVEL_1, label: 'Superintendent' },
		{ id: WorkOrderReviewLevel.LEVEL_2, label: 'Management' },
		{ id: WorkOrderReviewLevel.LEVEL_3, label: 'Accounting' },
	];

	static getFilter = (reviewStatus: WorkOrderReviewStatus, reviewLevel: WorkOrderReviewLevel, canRejectToManagement: boolean) => {
		if (reviewStatus === WorkOrderReviewStatus.APPROVED) {
			return (_option: typeof RejectModal.OPTIONS[0]) => {
				return canRejectToManagement
					? _option.id <= reviewLevel
					: _option.id <= reviewLevel && _option.id !== WorkOrderReviewLevel.LEVEL_2;
			};
		}
		return (_option: typeof RejectModal.OPTIONS[0]) => {
			return canRejectToManagement
				? _option.id < reviewLevel
				: _option.id < reviewLevel && _option.id !== WorkOrderReviewLevel.LEVEL_2;
		};
	};

	static getInitialReviewLevel = (reviewStatus: WorkOrderReviewStatus, reviewLevel: WorkOrderReviewLevel, canRejectToManagement: boolean) => {
		if (reviewStatus === WorkOrderReviewStatus.APPROVED) {
			return reviewLevel;
		}
		if (!canRejectToManagement && reviewLevel === WorkOrderReviewLevel.LEVEL_3) {
			return WorkOrderReviewLevel.LEVEL_1;
		}
		return reviewLevel - 1;
	};

	static getUsers = (reviewLevel: WorkOrderReviewLevel, users: WorkOrderEmployeeVM[]) => {
		return reviewLevel < WorkOrderReviewLevel.LEVEL_2
			? users
			: [];
	};

	componentDidUpdate(prevProps: Props) {
		const { showModal, destroy, initialize, reviewLevel } = this.props;

		if (showModal && !prevProps.showModal) {
			initialize(this.initForm());
		} else if (prevProps.showModal && !showModal) {
			destroy();
		}

		if (prevProps.showModal !== showModal || prevProps.reviewLevel !== reviewLevel) {
			this.setState(RejectModal.getInitialState(this.props));
		}
	}

	initForm = (): Partial<RejectReviewRM> => {
		const { getUsers, reviewLevel, reviewStatus, canRejectToManagement } = this.props;

		const targetLevel = RejectModal.getInitialReviewLevel(reviewStatus, reviewLevel, canRejectToManagement);
		const users = getUsers(targetLevel);

		return {
			targetLevel,
			assignedToIds: RejectModal.getUsers(targetLevel, users ?? []).map(getId),
		};
	};

	static getInitialState = (props: Props) => {
		const { reviewLevel, reviewStatus, getUsers, canRejectToManagement } = props;

		const selectedLevel = RejectModal.getInitialReviewLevel(reviewStatus, reviewLevel, canRejectToManagement);
		const users = getUsers(selectedLevel);

		return {
			selectedLevel,
			levelOptions: RejectModal.OPTIONS.filter(RejectModal.getFilter(reviewStatus, reviewLevel, canRejectToManagement)),
			userOptions: users,
			selectedUsers: RejectModal.getUsers(selectedLevel, users ?? []),
		};
	};

	reject = async (form: RejectReviewRM) => {
		const { onReject, closeModal } = this.props;

		await onReject(form);
		closeModal();
	};

	selectLevel = async (option: Level) => {
		const { change, getUsers } = this.props;

		const users = getUsers(option.id);
		const selectedUsers = RejectModal.getUsers(option.id, users ?? []);

		change('assignedToIds', selectedUsers?.map(getId));

		this.setState(() => ({
			selectedLevel: option.id,
			userOptions: users,
		}));
	};

	render() {
		const {
			anyTouched,
			assignedToIdsError,
			assignedToIds,
			change,
			closeModal,
			handleSubmit,
			invalid,
			reviewLevel,
			showModal,
			submitting,
			workOrderCode,
		} = this.props;
		const { levelOptions, userOptions, selectedLevel } = this.state;

		const disableSubmit = invalid || !!assignedToIdsError;

		return (
			<CustomModal
				className="report-type-modal"
				closeModal={closeModal}
				modalStyle="info"
				showModal={showModal}
				size="md"
			>
				<CustomModal.Header
					closeModal={closeModal}
					title="Reject Report"
				/>
				<CustomModal.Body>
					<div className="work-order-report-reject-modal__work-order">
						You are about to reject report for <strong>{workOrderCode}</strong>
					</div>
					<Field
						className="work-order-report-reject-modal__reject-reason"
						component={Textarea}
						label="Reason*"
						maxCharacters={INPUT_FIELD_MAX_CHARACTERS}
						name="rejectReason"
						placeholder="Add a reason"
						rows={3}
						showMaxCharactersLabel={true}
					/>
					<div className="work-order-report-reject-modal__level">
						<Field
							component={Dropdown}
							disabled={reviewLevel === WorkOrderReviewLevel.LEVEL_0}
							fixed={true}
							id="reject-field-report"
							label="Request changes from*"
							labelKey="label"
							name="targetLevel"
							onValueChange={this.selectLevel}
							options={levelOptions}
							valueKey="id"
							withCaret={true}
						/>
					</div>
					<WorkOrderEmployeeSelect
						anyTouched={anyTouched}
						assignedToIds={assignedToIds}
						assignedToIdsError={assignedToIdsError}
						change={change}
						selectedLevel={selectedLevel}
						users={userOptions}
					/>
				</CustomModal.Body>
				<CustomModal.Footer>
					<Button
						onClick={closeModal}
						variant="info"
					>
						Cancel
					</Button>
					<SubmitButton
						disabled={disableSubmit}
						label="Reject Report"
						onClick={handleSubmit(this.reject)}
						reduxFormSubmitting={submitting}
					/>
				</CustomModal.Footer>
			</CustomModal>
		);
	}
}

const formSelector = formValueSelector(REJECT_REPORT);
const getErrors = getFormSyncErrors(REJECT_REPORT);

function mapStateToProps(state: RootState) {
	const { company } = state.company;

	const {
		assignedToIds: assignedToIdsError,
	} = getErrors(state) as FormErrors<RejectReviewRM, string>;
	const assignedToIds = formSelector(state, 'assignedToIds') ?? [];

	return {
		assignedToIds,
		assignedToIdsError: assignedToIdsError as string,
		canRejectToManagement: company?.isFRManageLevelAllowedInReviewProcess ?? false,
	};
}

const connector = connect(mapStateToProps, null, null, {
	areStatesEqual: (nextState, prevState) => nextState.form === prevState.form,
});

const enhance = compose<React.ComponentClass<OwnProps>>(
	connector,
	reduxForm<RejectReviewRM, OwnProps>({ form: REJECT_REPORT, validate })
);

export default enhance(RejectModal);
