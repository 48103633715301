import type { Dispatch, AnyAction } from 'redux';

import type { TableContent } from 'ab-common/dataStructures/tableContent';

import { TableQuery } from 'ab-common/dataStructures/tableQuery';

import type { PerDiemWorkOrderEmployeeListViewModel } from 'ab-viewModels/perDiemWorkOrderEmployee.viewModel';
import type PerDiemWorkOrderEmployeeViewModel from 'ab-viewModels/perDiemWorkOrderEmployee.viewModel';

import API from 'af-constants/routes/api';

import { http } from 'af-utils/http.util';
import { errorHandler } from 'af-utils/actions.util';

import type { GetRootState } from 'af-reducers';

export function getPerDiemReportTable(tableRequestModel: TableQuery, startDate: string, endDate: string) {
	return async (dispatch: Dispatch<AnyAction>, getState: GetRootState, { redirectTo }) => {

		const action = async () => {
			const data = new TableQuery(tableRequestModel);
			return await http.get<TableContent<PerDiemWorkOrderEmployeeViewModel>>(API.V1.WORK_ORDER_EMPLOYEE.PER_DIEM.TABLE(data, startDate, endDate));
		};

		return await errorHandler(action, dispatch, redirectTo);
	};
}

export function getPerDiemReportForDateInterval(startDate: string, endDate: string) {
	return async (dispatch: Dispatch<AnyAction>, getState: GetRootState, { redirectTo }) => {

		const action = async () => {
			return await http.get<PerDiemWorkOrderEmployeeListViewModel>(API.V1.WORK_ORDER_EMPLOYEE.PER_DIEM.LIST(startDate, endDate));
		};

		return await errorHandler(action, dispatch, redirectTo);
	};
}
