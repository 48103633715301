import type { FormErrors } from 'redux-form';

import { URL_REGEX } from 'acceligent-shared/constants/regex';

import { isValidEmail } from 'acceligent-shared/utils/email';
import { isValidFormattedPhoneNumber } from 'acceligent-shared/utils/phone';
import { isValidTextInput } from 'acceligent-shared/utils/text';

import type AgencyCreateRM from 'ab-requestModels/agency/create.requestModel';

export function validate(form: AgencyCreateRM) {
	const errors: FormErrors<AgencyCreateRM> = {};

	if (!isValidTextInput(form.name)) {
		errors.name = 'Agency name is required.';
	}
	if (!form.color) {
		errors.color = 'Agency color is required';
	}

	if (!!form.email) {
		if (!isValidEmail(form.email)) {
			errors.email = 'Invalid E-mail';
		}
	}

	if (!!form.phone) {
		if (!isValidFormattedPhoneNumber(form.phone)) {
			errors.phone = 'Invalid phone number';
		}
	}

	if (!!form.website) {
		if (!URL_REGEX.test(form.website)) {
			errors.website = 'Invalid url';
		}
	}

	return errors;
}
