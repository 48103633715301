import baseRoute from '../baseRoute';

export * from './viewModel';
export * from './queryParams';

type RouteDefinition = `${typeof baseRoute}/csv/list${`?isDeleted=${boolean}` | ''}`;

export const ROUTE: RouteDefinition = `${baseRoute}/csv/list`;

export const URL = (isDeleted?: boolean): RouteDefinition =>
	`${baseRoute}/csv/list${isDeleted === undefined ? '' : `?isDeleted=${isDeleted}` as const}`;
