import * as React from 'react';
import { Link } from 'react-router-dom';
import { Row, Col, Form } from 'react-bootstrap';
import * as papaParse from 'papaparse';

import type ContactImportRM from 'ab-requestModels/contact/import';

import UploadStatus from 'ab-enums/uploadStatus.enum';

import Dropzone from 'af-components/Dropzone';
import CSVUploadMessage from 'af-components/Dropzone/CSVUploadMessage';
import Breadcrumbs from 'af-components/Breadcrumbs';
import SegmentLabel from 'af-components/SegmentLabel';

import type { CSVData, BulkCreateResponseViewModel } from 'ab-viewModels/csv.viewModel';

import type { EquipmentCostCSVRequestModel, EquipmentCostImportBatch } from 'ab-requestModels/equipmentCost.requestModel';
import type { EquipmentCSVRequestModel, EquipmentImportBatch } from 'ab-requestModels/equipment.requestModel';
import type { WageRateImportBatch, WageRateRequestModel } from 'ab-requestModels/wageRate.requestModel';
import type { CSVBulkAccountRM } from 'ab-requestModels/account/upload.requestModel';

import * as CSVUtils from 'af-utils/csv.utils';

/** All batch types */
type UploadBatch =
	ContactImportRM[]
	| EquipmentImportBatch
	| EquipmentCostImportBatch
	| WageRateImportBatch
	| CSVBulkAccountRM;

/** All response types */
type UploadResponse =
	EquipmentCostCSVRequestModel
	| EquipmentCSVRequestModel
	| EquipmentCostCSVRequestModel
	| WageRateRequestModel;

export type ImportBatchFunction = (batch: UploadBatch) => Promise<void | BulkCreateResponseViewModel<void | UploadResponse | ContactImportRM>>;
export type ParseFunction = (data: CSVData) => UploadBatch;

interface Props {
	backButtonLabel?: string;
	fields: JSX.Element;
	important?: string;
	listRoute: string;
	mandatoryFields?: string[];
	manual: (string | JSX.Element)[];
	notes?: string;
	parse: ParseFunction;
	renderInstructions: () => JSX.Element;
	title: string;
	uploadAction: ImportBatchFunction;
}

interface State {
	uploadStatus: UploadStatus;
	showInstructions: boolean;
	uploadingFileName: string;
	filesError: Nullable<JSX.Element>;
	updatedCount: number;
	importedCount: number;
}

class BulkUpload extends React.PureComponent<Props, State> {

	static defaultProps: Partial<Props> = {
		manual: [],
		notes: 'You can use the CSV sample as a reference and adjust yours accordingly.',
	};

	state: State = {
		uploadStatus: UploadStatus.INITIAL,
		showInstructions: false,
		uploadingFileName: '',
		filesError: null,
		updatedCount: 0,
		importedCount: 0,
	};

	static validateHeader = (fields: string[], mandatoryFields: string[]) => {
		let headerErrors: Record<string, string> | undefined = undefined;

		for (const _header of mandatoryFields) {
			if (!fields.includes(_header)) {
				headerErrors = {
					...(headerErrors ?? {}),
					[`${_header}`]: `Header is missing field ${_header}.`,
				};
			}
		}
		return headerErrors;
	};

	upload = async (accepted) => {
		const { mandatoryFields } = this.props;
		const fileReader = new FileReader();
		const [csvFile] = accepted;

		const fileReaderOnloadWrapper = async () => {
			return new Promise<CSVData>((resolve, reject) => {
				fileReader.onload = (e) => {
					const target = e.target as FileReader;
					const _result = target.result as string;
					const { errors, data, meta } = papaParse.parse(_result, { header: true, skipEmptyLines: true });
					try {
						if (mandatoryFields) {
							const headerErrors = BulkUpload.validateHeader(meta.fields ?? [], mandatoryFields);
							if (headerErrors) {
								reject(headerErrors);
							}
						}
						// UndetectableDelimiter appears when we try to upload a single-column csv (like members' emails)
						if (errors.length && (errors.length > 1 || errors[0].code !== 'UndetectableDelimiter')) {
							return reject({ contacts: 'CSV is invalid' });
						}
						return resolve(data as CSVData);

					} catch (error) {
						if (errors.length === 1 && errors[0].code === 'UndetectableDelimiter') {
							return;
						}
						throw error;
					}
				};

				fileReader.onerror = () => {
					try {
						return reject({ contacts: 'Something went wrong, try refreshing page' });
					} catch (error) {
						throw error;
					}
				};
			});
		};

		this.setState(() => ({
			uploadingFileName: csvFile.name,
			uploadStatus: UploadStatus.UPLOADING,
		}));

		const { uploadAction, parse } = this.props;
		try {
			fileReader.readAsText(csvFile);
			const data = await fileReaderOnloadWrapper();
			const sanitizedData = CSVUtils.sanitize(data);
			const parsedData = parse(sanitizedData);

			const importResult = await uploadAction(parsedData);

			if (!importResult) {
				// In some scenarios, we want a direct redirect after upload - we do not need to set any more state changes
				return;
			}

			const { updatedCount, importedCount, error } = importResult;

			if (error && Object.keys(error).length > 0) {
				// Manual error handling
				return this.setState(() => ({
					uploadStatus: UploadStatus.FAILURE,
					filesError: CSVUtils.formatErrorTooltip(error),
					importedCount: 0,
					updatedCount: 0,
				}));
			}
			return this.setState(() => ({
				uploadStatus: UploadStatus.SUCCESS,
				filesError: null,
				importedCount,
				updatedCount,
			}));
		} catch (err) {
			const { errors } = err;
			return this.setState(() => ({
				filesError: CSVUtils.formatErrorTooltip(errors || err),
				importedCount: 0,
				updatedCount: 0,
				uploadStatus: UploadStatus.FAILURE,
			}));
		}
	};

	toggleInstructions = () => this.setState(({ showInstructions }) => ({ showInstructions: !showInstructions }));

	renderManual = () => {
		const { manual } = this.props;

		return (
			<div className="bulk-upsert__manual">
				How to use:
				<br />
				{
					manual.map((_step: string, _index: number) => (
						<div key={`manualStep#${_index}`}>
							<span className="bulk-upsert__manual-step">{_index + 1}.</span>
							{_step}
						</div>
					))
				}
			</div>
		);
	};

	renderFields = () => {
		const { fields } = this.props;

		return (
			<div className="bulk-upsert__fields">
				{fields}
			</div>
		);
	};

	renderImportant = () => {
		const { important } = this.props;

		return (
			<div className="bulk-upsert__note">
				<span className="bulk-upsert__note-label">IMPORTANT:</span>
				{important}
			</div>
		);
	};

	renderNotes = () => {
		const { notes } = this.props;

		return (
			<div className="bulk-upsert__note">
				<span className="bulk-upsert__note-label">NOTE:</span>
				{notes}
			</div>
		);
	};

	render() {
		const {
			renderInstructions,
			backButtonLabel,
			listRoute,
			title,
		} = this.props;
		const {
			showInstructions,
			uploadStatus,
			uploadingFileName,
			updatedCount,
			importedCount,
			filesError,
		} = this.state;

		return (
			<div className="form-segment bulk-upsert">
				<Breadcrumbs
					items={
						[
							{ label: title, url: listRoute },
							{ label: `Upload ${title}` },
						]
					}
				/>
				<div className="form-box">
					<Row>
						<Col sm={24}>
							<SegmentLabel label="INSTRUCTIONS" />
						</Col>
					</Row>
					<Row className={showInstructions ? '' : 'row--padded-bottom'}>
						<Col className="bulk-upsert__instructions-header" sm={24}>
							<div className="bulk-upsert__instruction-text">
								{renderInstructions()}
							</div>
							<a
								className={`btn btn-toggle ${showInstructions ? 'active' : ''}`}
								onClick={this.toggleInstructions}
								type="button"
							>
								<span className={showInstructions ? 'icon-up' : 'icon-down'} />
								Instructions
							</a>
						</Col>
					</Row>
					{
						showInstructions &&
						<>
							<hr />
							<Row className="row--padded-bottom">
								<Col sm={24}>
									{this.renderManual()}
									{this.renderFields()}
									{this.renderNotes()}
									{this.renderImportant()}
								</Col>
							</Row>
						</>
					}
				</div>
				<Form>
					<div className="form-box">
						<Row>
							<Col sm={24}>
								<SegmentLabel label="Upload CSV" />
								<Dropzone onDrop={this.upload}>
									<CSVUploadMessage
										filesError={filesError}
										importedCount={importedCount}
										updatedCount={updatedCount}
										uploadingFileName={uploadingFileName}
										uploadStatus={uploadStatus}
									/>
								</Dropzone>
							</Col>
						</Row>
						<div className="form-box__after">
							<Link
								className="btn btn-info"
								to={listRoute}
							>
								{backButtonLabel ? backButtonLabel : `Back to ${title} table`}
							</Link>
						</div>
					</div>
				</Form>
			</div>
		);
	}
}

export default BulkUpload;
