import * as React from 'react';
import type { FieldArrayFieldsProps } from 'redux-form';

import type ContactMethodViewModel from 'ab-viewModels/contactMethod.viewModel';

import Checkbox from 'af-components/Controls/Checkbox';

import styles from './styles.module.scss';

type Props = {
	email: ContactMethodViewModel;
	fields: FieldArrayFieldsProps<number>;
	isSelected: boolean;
	disabled?: boolean;
};

const EmailCheckbox: React.FC<Props> = (props) => {
	const { email, isSelected, fields, disabled = false } = props;

	const onCheck = React.useCallback((e) => {
		const isChecked = e.target.checked;
		if (isChecked) {
			fields.push(email.id);
		} else {
			const index = fields.getAll().findIndex((_field) => _field === email.id);
			if (index >= 0) {
				fields.remove(index);
			}
		}
	}, [email.id, fields]);

	return (
		<div className={styles['email-checkbox']}>
			<Checkbox
				handleChange={onCheck}
				isChecked={isSelected}
				isDisabled={disabled}
			/>
			{email.value}
		</div>
	);
};

export default EmailCheckbox;
