import * as React from 'react';
import type { CustomRouteComponentProps } from 'react-router-dom';
import type { ResolveThunks } from 'react-redux';
import { connect } from 'react-redux';

import type { RootState } from 'af-reducers';

import * as LocationActions from 'af-actions/location';

import type { LocationTableRowVM } from 'ab-viewModels/location/tableRow.viewModel';

import CLIENT from 'af-constants/routes/client';

import SocketEvent from 'ab-enums/socketEvent.enum';
import TableNameEnum from 'ab-enums/tableName.enum';
import TableButtonType from 'ab-enums/tableButtonType.enum';

import type { TabProps, Column, ButtonData } from 'af-components/Table6';
import Table from 'af-components/Table6';
import type TableComponent from 'af-components/Table6/Table';
import AddressCell from 'af-components/Table6/Cells/AddressCell';
import LabelWithColor from 'af-components/LabelWithColor';
import Breadcrumbs from 'af-components/Breadcrumbs';

import type { SortModal as SortModalComponent } from './SortModal';
import SortModal from './SortModal';

import { formatPhoneNumber } from 'ab-utils/phone.util';

import socket from 'af-utils/socket.util';
import EmptyCell from 'af-components/Table/Cells/EmptyCell';

type OwnProps = CustomRouteComponentProps;

interface StateProps {
	companyName: string;
}

interface DispatchProps {
	deleteLocationFromTable: typeof LocationActions.deleteLocationFromTable;
	findAllForCompanyTable: typeof LocationActions.findAllForCompanyTable;
	bulkDeleteLocationFromTable: typeof LocationActions.bulkDeleteLocationFromTable;
}

type Props = ResolveThunks<DispatchProps> & StateProps & OwnProps;

const DELETE_CONFIRMATION_BODY = (
	<>
		If deleted, the location(s) will no longer be assignable.
		<br />
		This action cannot be undone.
	</>
);

class LocationList extends React.PureComponent<Props> {

	static readonly BREADCRUMBS = [{ label: 'Location' }];

	static readonly COLUMNS: Column<LocationTableRowVM>[] = [
		{
			Header: 'Sort Index',
			accessor: 'index',
			className: 'bold',
			Cell: ({ original }) => Number(original.index) + 1,
			width: 120,
		},
		{
			Header: 'Nickname',
			accessor: 'nickname',
			className: 'location-nickname-cell',
			Cell: ({ original }) => <LabelWithColor color={original.color} text={original.nickname} />,
		},
		{
			Header: 'Address',
			accessor: 'address',
			sortable: false,
			Cell: ({ original }) => original.address ? <AddressCell address={original.address} /> : <EmptyCell />,
		},
		{
			Header: 'Mobile Phone',
			accessor: 'phone',
			Cell: ({ original }) => formatPhoneNumber(original.phone, original.phoneCountryCode),
		},
		{
			Header: 'Fax',
			accessor: 'fax',
			Cell: ({ original }) => formatPhoneNumber(original.fax, original.faxCountryCode),
		},
	];

	private _sortModal = React.createRef<SortModalComponent>();
	private _table: Nullable<TableComponent<LocationTableRowVM>> = null;

	static deleteLocationModalTitle = (original: LocationTableRowVM) => `Are you sure you want to delete this Location (${original.nickname})?`;
	static deleteLocationModalBody = () => DELETE_CONFIRMATION_BODY;
	static deleteLocationModalText = () => 'Delete Location';

	openSortModal = async () => {
		if (this._sortModal.current) {
			this._sortModal.current.open();
		}
	};

	deleteLocation = async (original: LocationTableRowVM) => {
		const { deleteLocationFromTable } = this.props;
		socket.connection?.emit(SocketEvent.V2.FE.SCHEDULE_BOARD.SYNC_LABOR_STATISTICS);
		await deleteLocationFromTable(original.id);
	};

	editLocation = async (original: LocationTableRowVM) => {
		const { history, location: { state: { orgAlias } }, companyName } = this.props;
		history.push(CLIENT.COMPANY.RESOURCES.LOCATION.EDIT(original.id.toString(), orgAlias, companyName));
	};

	onMount = (table: TableComponent<LocationTableRowVM>) => {
		this._table = table;
	};

	onRowClick = ({ original }: { original: LocationTableRowVM; }) => {
		const { history, location: { state: { orgAlias } }, companyName } = this.props;
		if (original.id) {
			history.push(CLIENT.COMPANY.RESOURCES.LOCATION.PREVIEW(original.id.toString(), orgAlias, companyName));
		}
	};

	refreshTable = async () => {
		if (this._table) {
			this._table.refreshTable();
		}
	};

	tabs = (): TabProps<LocationTableRowVM>[] => {
		const {
			findAllForCompanyTable,
			bulkDeleteLocationFromTable,
			location: { state: { orgAlias } },
			companyName,
			history,
		} = this.props;

		const buttons: ButtonData[] = [
			{
				type: TableButtonType.LINK,
				hasPermission: true,
				label: 'Sort Indexes',
				icon: 'sort',
				onClick: this.openSortModal,
			},
			{
				type: TableButtonType.PRIMARY,
				hasPermission: true,
				label: 'New Location',
				icon: 'plus',
				onClick: async () => history.push(CLIENT.COMPANY.RESOURCES.LOCATION.CREATE(orgAlias, companyName)),
			},
		];

		return [
			{
				label: 'Location',
				columns: LocationList.COLUMNS,
				selectable: true,
				hasSearchInput: true,
				searchLabel: 'Location',
				buttons,
				fetch: findAllForCompanyTable,
				bulkDelete: bulkDeleteLocationFromTable,
				bulkDeleteConfirmationBody: DELETE_CONFIRMATION_BODY,
				onRowClick: this.onRowClick,
				rowActions: [
					{
						label: 'Edit',
						action: this.editLocation,
						shouldRefresh: false,
					},
					{
						label: 'Delete',
						action: this.deleteLocation,
						hasModal: true,
						modalTitle: LocationList.deleteLocationModalTitle,
						modalBody: LocationList.deleteLocationModalBody,
						modalText: LocationList.deleteLocationModalText,
						shouldRefresh: true,
					},
				],
			},
		];
	};

	render() {
		return (
			<div className="form-segment form-segment--maxi">
				<Breadcrumbs items={LocationList.BREADCRUMBS} />
				<Table
					onMount={this.onMount}
					tableName={TableNameEnum.LOCATION}
					tabs={this.tabs()}
				/>
				<SortModal
					ref={this._sortModal}
					refreshTable={this.refreshTable}
				/>
			</div>
		);
	}
}

function mapStateToProps(state: RootState): StateProps {
	const { companyData } = state.user;
	if (!companyData) {
		throw new Error('User not logged in');
	}

	return {
		companyName: companyData.name,
	};
}

function mapDispatchToProps(): DispatchProps {
	return {
		deleteLocationFromTable: LocationActions.deleteLocationFromTable,
		findAllForCompanyTable: LocationActions.findAllForCompanyTable,
		bulkDeleteLocationFromTable: LocationActions.bulkDeleteLocationFromTable,
	};
}

export default connect<StateProps, DispatchProps, OwnProps>(mapStateToProps, mapDispatchToProps())(LocationList);
