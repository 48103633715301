import * as React from 'react';
import { compose } from 'redux';
import type { DraggableProvided, DraggableStateSnapshot, DraggableElement } from 'react-beautiful-dnd';
import { Draggable } from 'react-beautiful-dnd';
import type { ConnectedProps } from 'react-redux';
import { connect } from 'react-redux';

import type { RootState } from 'af-reducers';

import * as ScheduleBoardActions from 'af-actions/scheduleBoard';

import ScheduleBoardView from 'ab-enums/scheduleBoardView.enum';

import { EMPLOYEE_PLACEHOLDER } from 'ab-common/constants/scheduleBoard';

import DraggableEmployee from './Draggable';
import DraggableEmployeeWithReason from './DraggableWithReason';
import DraggableLoadingPlaceholder from 'af-root/scenes/Company/ScheduleBoard/Shared/DraggableLoadingPlaceholder';

import { useScheduleBoardModals } from 'af-root/hooks/useScheduleBoardModal';

interface OwnProps {
	employeeId: number;
	draggableId: string;
	index: number;
	droppableId: string;
	isToolbar: boolean;
	dueDate: string;
	isWorkOrderCanceled?: boolean;
	isCopyPlaceholder?: boolean;
	available?: boolean;
	isCardDisabled?: boolean;
	isCalculationsView?: boolean;
	hasReason?: boolean;
	resourceId?: number;
	workOrderEmployeeId?: number;
	isDragAndDropDisabled: boolean;
	hasPermissionsToEditScheduleBoard: boolean;
	isPerDiem?: boolean;
	workOrderCode?: string;
	isWOLocked: boolean;
}

type Props = OwnProps & ConnectedProps<typeof connector>;

interface DraggableProps extends Props {
	provided?: DraggableProvided;
	snapshot?: DraggableStateSnapshot;
}

const EmployeeDraggable: React.FC<Props> = (props) => {
	const {
		draggableId,
		index,
		isDisabled,
		isCopyPlaceholder,
		isDragAndDropDisabled,
		hasPermissionsToEditScheduleBoard,
		isCalculationsView = false,
		findToolbarEmployeeById,
		findWorkOrderEmployeeById,
		isToolbar,
		employeeId,
		workOrderEmployeeId,
		dueDate,
	} = props;

	const { setEmployeeModalData, setEmployeeModalVisibility } = useScheduleBoardModals();

	const [isModalOpenedForCurrentEmployee, setIsModalOpenedForCurrentEmployee] = React.useState(false);

	const fetchModalData = React.useCallback(async () => {

		const employee = isToolbar
			? await findToolbarEmployeeById(employeeId, dueDate)
			: await findWorkOrderEmployeeById(workOrderEmployeeId!, dueDate);

		setEmployeeModalData(employee, dueDate);
		setEmployeeModalVisibility(true);
		setIsModalOpenedForCurrentEmployee(true);
	}, [
		dueDate,
		employeeId,
		findToolbarEmployeeById,
		findWorkOrderEmployeeById,
		isToolbar,
		setEmployeeModalData,
		setEmployeeModalVisibility,
		workOrderEmployeeId,
	]);

	React.useEffect(() => {
		if (isModalOpenedForCurrentEmployee && !isDisabled) {
			setEmployeeModalData(null, null);

			setIsModalOpenedForCurrentEmployee(false);
			fetchModalData();
		}
		// eslint-disable-next-line react-hooks/exhaustive-deps
	}, [isDisabled]); // Change only if isDisabled has changed

	const openModal = React.useCallback(() => {
		if (isCalculationsView) {
			return;
		}
		fetchModalData();
	}, [fetchModalData, isCalculationsView]);

	const renderDraggable = React.useCallback((draggableProps: DraggableProps) => {
		const {
			provided = {} as DraggableProvided,
			snapshot = {} as DraggableStateSnapshot,
		} = draggableProps;

		if (draggableProps.hasReason) {
			return (
				<DraggableEmployeeWithReason
					draggableProps={provided.draggableProps}
					dragHandleProps={provided.dragHandleProps}
					dueDate={draggableProps.dueDate}
					employeeId={draggableProps.employeeId}
					hasPermissionsToEditScheduleBoard={draggableProps.hasPermissionsToEditScheduleBoard}
					innerRef={provided.innerRef}
					isDragAndDropDisabled={draggableProps.isDragAndDropDisabled}
					isDragging={snapshot.isDragging}
					isPerDiem={draggableProps.isPerDiem}
					isToolbar={draggableProps.isToolbar}
					onClick={openModal}
				/>
			);
		}

		return (
			<DraggableEmployee
				draggableProps={provided.draggableProps}
				draggingOver={snapshot.draggingOver}
				dragHandleProps={provided.dragHandleProps}
				dueDate={draggableProps.dueDate}
				employeeId={draggableProps.employeeId}
				hasPermissionsToEditScheduleBoard={draggableProps.hasPermissionsToEditScheduleBoard}
				innerRef={provided.innerRef}
				isCardDisabled={!!draggableProps.isCardDisabled}
				isDragAndDropDisabled={draggableProps.isDragAndDropDisabled}
				isDragging={snapshot.isDragging}
				isDropAnimating={snapshot.isDropAnimating}
				isPerDiem={draggableProps.isPerDiem}
				isToolbar={draggableProps.isToolbar}
				isWOLocked={draggableProps.isWOLocked}
				isWorkOrderCanceled={draggableProps.isWorkOrderCanceled}
				onClick={openModal}
				resourceId={draggableProps.resourceId}
				workOrderCode={draggableProps.workOrderCode}
			/>
		);
	}, [openModal]);

	// loading indicator when assigning employee from toolbar
	if (employeeId === EMPLOYEE_PLACEHOLDER) {
		return <DraggableLoadingPlaceholder />;
	}

	// react-beautiful-dnd can not create Draggable and Droppable elements/
	// while we are dragging so in order to mimic the copied card, we render
	// div instead of a Draggable (same for Droppable in parent component)
	if (isCopyPlaceholder || !hasPermissionsToEditScheduleBoard || isDragAndDropDisabled) {
		return (
			<div>
				{renderDraggable(props)}
			</div>
		);
	}

	return (
		<Draggable
			draggableId={draggableId}
			index={index}
			isDragDisabled={isDisabled}
		>
			{(provided, snapshot) => (
				React.createElement(renderDraggable, { ...props, provided, snapshot }, null) as DraggableElement<DraggableProps>
			)}
		</Draggable>
	);
};

function mapStateToProps(state: RootState, ownProps: OwnProps) {
	const { employeeId, isToolbar, isCardDisabled, resourceId, dueDate } = ownProps;
	const {
		employees,
		draggedResourceId,
		draggedEmployeeId,
		weeklyViewDateWithToolbar,
		scheduleBoardView,
	} = state.scheduleBoard;

	let isDisabled: boolean = false;

	const employee = employees?.[employeeId];
	if (!isToolbar) {
		const disableDraggingWhenToolbarOpened = scheduleBoardView === ScheduleBoardView.WEEKLY_VIEW &&
			(!weeklyViewDateWithToolbar || weeklyViewDateWithToolbar !== dueDate);
		isDisabled = !!isCardDisabled ||
			!!disableDraggingWhenToolbarOpened ||
			!!(employee?.isDisabled && resourceId !== draggedResourceId);
	} else {
		// if employee is disabled and `draggedEmployeeId` is not set => it's dragged by another user or in another tab
		isDisabled = !!employee?.isDisabled && !draggedEmployeeId;
	}

	return {
		isDisabled,
	};
}

function mapDispatchToProps() {
	return {
		findToolbarEmployeeById: ScheduleBoardActions.findToolbarEmployeeById,
		findWorkOrderEmployeeById: ScheduleBoardActions.findWorkOrderEmployeeById,
	};
}

const connector = connect(mapStateToProps, mapDispatchToProps());

const enhance = compose<React.ComponentType<OwnProps>>(
	React.memo,
	connector
);

export default enhance(EmployeeDraggable);
