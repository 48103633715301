import * as React from 'react';

import type WorkOrderModalViewModel from 'ab-viewModels/scheduleBoardWorkOrderModal.viewModel';

import Changes from '../Changes';
import { renderWorkOrderInfoData } from '../';

import * as FormattingUtils from 'ab-utils/formatting.util';

import LockedValue from 'af-components/LockedValue';
import SegmentLabel from 'af-components/SegmentLabel';

interface OwnProps {
	workOrderDataToShow: WorkOrderModalViewModel;
	currentWorkOrderData: WorkOrderModalViewModel;
}

type Props = OwnProps;

class ProductionDataSection extends React.PureComponent<Props> {
	sectionHasChanges = () => {
		const { workOrderDataToShow, currentWorkOrderData } = this.props;

		return workOrderDataToShow?.revenue !== currentWorkOrderData?.revenue
			|| workOrderDataToShow?.manHourAverage !== currentWorkOrderData?.manHourAverage
			|| workOrderDataToShow?.jobHours !== currentWorkOrderData?.jobHours
			|| workOrderDataToShow?.shopHours !== currentWorkOrderData?.shopHours
			|| workOrderDataToShow?.travelHours !== currentWorkOrderData?.travelHours
			|| workOrderDataToShow?.workDescription !== currentWorkOrderData?.workDescription;
	};

	render() {
		const { workOrderDataToShow, currentWorkOrderData } = this.props;

		const isInternal = workOrderDataToShow?.isJobInternal;
		const sectionHasChanges = this.sectionHasChanges();

		return (
			!isInternal &&
			<>
				<hr />
				<div className="work-order-info-modal__production-data-container">
					<Changes modifiedData={sectionHasChanges} />
					<SegmentLabel label="Production data" />
					<div className="work-order-info-modal__production-data">
						<div>
							<LockedValue
								label="Revenue"
								value={renderWorkOrderInfoData(
									FormattingUtils.moneyNormalizer(workOrderDataToShow?.revenue),
									FormattingUtils.moneyNormalizer(currentWorkOrderData?.revenue)
								)}
							/>
						</div>
						<div>
							<LockedValue
								label="Man Hour Average"
								value={renderWorkOrderInfoData(
									FormattingUtils.moneyNormalizer(workOrderDataToShow?.manHourAverage),
									FormattingUtils.moneyNormalizer(currentWorkOrderData?.manHourAverage)
								)}
							/>
						</div>
						<div>
							<LockedValue
								label="Job Hours"
								value={renderWorkOrderInfoData(workOrderDataToShow?.jobHours ?? '-', currentWorkOrderData?.jobHours ?? '-')}
							/>
						</div>
					</div>
					<div className="work-order-info-modal__production-data">
						<div>
							<LockedValue
								label="Shop Hours"
								value={renderWorkOrderInfoData(workOrderDataToShow?.shopHours ?? '-', currentWorkOrderData?.shopHours ?? '-')}
							/>
						</div>
						<div>
							<LockedValue
								label="Travel Hours"
								value={renderWorkOrderInfoData(workOrderDataToShow?.travelHours ?? '-', currentWorkOrderData?.travelHours ?? '-')}
							/>
						</div>
						<div>
							<LockedValue
								label="Work Description"
								value={renderWorkOrderInfoData(workOrderDataToShow?.workDescription ?? '-', currentWorkOrderData?.workDescription ?? '-')}
							/>
						</div>
					</div>
				</div>
			</>
		);
	}
}

export default ProductionDataSection;
