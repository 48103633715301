import * as React from 'react';

import ScheduleBoardProperty from 'ab-enums/scheduleBoardProperty.enum';

import DraggableLoadingPlaceholder from 'af-root/scenes/Company/ScheduleBoard/Shared/DraggableLoadingPlaceholder';
import ResourceDraggable from 'af-root/scenes/Company/ScheduleBoard/Shared/ResourceDraggable';

import { RESOURCE_PLACEHOLDER } from 'ab-common/constants/scheduleBoard';

interface Props {
	droppableId: string;
	draggablePrefix: string; // to distinguish if it's board or toolbar dragged employee
	resourceIds: number[];
	startIndex: number;
	/** `MM-DD-YYYY` */
	dueDate: string;
	isCardDisabled: boolean;
	isCalculationsView: boolean;
	isWorkOrderCanceled: boolean;
	isCopyPlaceholder: boolean;
	isDragAndDropDisabled: boolean;
	hasPermissionsToEditScheduleBoard: boolean;
	ignorePlaceholders: boolean;
	workOrderCode: string;
	isWOLocked: boolean;
}

const ResourceList: React.FC<Props> = (props) => {
	const {
		startIndex,
		draggablePrefix,
		droppableId,
		dueDate,
		isCardDisabled,
		isCalculationsView,
		isWorkOrderCanceled,
		isCopyPlaceholder,
		resourceIds,
		isDragAndDropDisabled,
		hasPermissionsToEditScheduleBoard,
		ignorePlaceholders,
		workOrderCode,
		isWOLocked,
	} = props;

	return resourceIds.map((resourceId: number, index: number) => {

		if (resourceId === RESOURCE_PLACEHOLDER) {
			return <DraggableLoadingPlaceholder key={`${draggablePrefix}#${ScheduleBoardProperty.RESOURCE}#${dueDate}#${resourceId}`} />;
		}

		return (
			<ResourceDraggable
				draggablePrefix={draggablePrefix}
				droppableId={droppableId}
				dueDate={dueDate}
				hasPermissionsToEditScheduleBoard={hasPermissionsToEditScheduleBoard}
				ignorePlaceholders={ignorePlaceholders}
				index={startIndex + index}
				isCalculationsView={isCalculationsView}
				isCardDisabled={isCardDisabled}
				isCopyPlaceholder={isCopyPlaceholder}
				isDragAndDropDisabled={isDragAndDropDisabled}
				isWOLocked={isWOLocked}
				isWorkOrderCanceled={isWorkOrderCanceled}
				key={resourceId}
				resourceId={resourceId}
				workOrderCode={workOrderCode}
			/>
		);
	});
};

export default React.memo(ResourceList);
