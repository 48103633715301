import type { Dispatch, AnyAction } from 'redux';

import API from 'af-constants/routes/api';

import { http } from 'af-utils/http.util';
import { errorHandler } from 'af-utils/actions.util';

import type RepairLocationVM from 'ab-viewModels/repairLocation/repairLocation.viewModel';

import type RepairLocationRM from 'ab-requestModels/repairLocation/repairLocation.requestModel';

import type { GetRootState } from 'af-reducers';

export function create(form: RepairLocationRM) {
	return async (dispatch: Dispatch<AnyAction>, getState: GetRootState, { redirectTo }) => {
		const action = async () => {
			return await http.post<RepairLocationVM>(API.V1.TOOL_REPAIR.REPAIR_LOCATION.CREATE, form);
		};
		return await errorHandler(action, dispatch, redirectTo);
	};
}

export function findList() {
	return async (dispatch: Dispatch<AnyAction>, getState: GetRootState, { redirectTo }) => {
		const action = async () => {
			return await http.get<RepairLocationVM[]>(API.V1.TOOL_REPAIR.REPAIR_LOCATION.LIST);
		};

		return await errorHandler(action, dispatch, redirectTo);
	};
}
