import { PrioritySort } from 'acceligent-shared/enums/priority';

import { MechanicViewAvailableEquipmentDataVM, MechanicViewUnavailableEquipmentDataVM } from 'ab-viewModels/mechanicView/mechanicView.viewModel';

import type ScheduleBoardDragRequestModel from 'ab-requestModels/scheduleBoardDrag.requestModel';

import ScheduleBoardProperty from 'ab-enums/scheduleBoardProperty.enum';
import ScheduleBoardContext from 'ab-enums/scheduleBoardContext.enum';

import * as ArrayUtil from 'ab-utils/array.util';

import * as ScheduleBoardUtil from 'af-utils/scheduleBoard.util';

import { SCHEDULE_BOARD_TOOLBAR_AVAILABLE, SCHEDULE_BOARD_TOOLBAR_UNAVAILABLE } from 'ab-common/constants/scheduleBoard';

import { SortByEnum, OrderByEnum } from 'ab-enums/mechanicView.enum';

import type { MatchedEquipmentIdMap, MechanicViewStoreState } from 'af-models/scheduleBoard.models';

const AVAILABILITY_DIVIDER = '@@@';

interface UnavailableEquipmentStatusData {
	statusId: number;
	statusIndex: number;
}

type UnavailableEquipmentSortFn = (elementA: MechanicViewUnavailableEquipmentDataVM, elementB: MechanicViewUnavailableEquipmentDataVM) => number;

// groupId = locationId for available and statusId for unavailable
// TODO: Check if groupId is nullable or not. We seem to be sending nullable info in, but that might not be right
const _mapToolbarGroupToString = (groupId: number, isAvailable: boolean) => `${isAvailable ? SCHEDULE_BOARD_TOOLBAR_AVAILABLE : SCHEDULE_BOARD_TOOLBAR_UNAVAILABLE}${AVAILABILITY_DIVIDER}${groupId}`;

const _parsStringToLocation = (source: string): string => {
	return source.split(AVAILABILITY_DIVIDER)[1];
};

const _sortUnavailableByPriority: UnavailableEquipmentSortFn = (elementA, elementB) => PrioritySort[elementA.priority] - PrioritySort[elementB.priority];

const _sortUnavailableByLastUpdated: UnavailableEquipmentSortFn = (elementA, elementB) => {
	if (elementA.lastUpdatedOn && elementB.lastUpdatedOn) {
		const tA = new Date(elementA.lastUpdatedOn).getTime();
		const tB = new Date(elementB.lastUpdatedOn).getTime();
		return tA - tB;
	} else if (elementA.lastUpdatedOn) {
		return 1;
	} else if (elementB.lastUpdatedOn) {
		return -1;
	}
	return 0;
};

const _sortUnavailableByReturnDate: UnavailableEquipmentSortFn = (elementA, elementB) => {
	if (elementA.returnDate && elementB.returnDate) {
		const tA = new Date(elementA.returnDate).getTime();
		const tB = new Date(elementB.returnDate).getTime();
		return tA - tB;
	} else if (elementA.returnDate) {
		return 1;
	} else if (elementB.returnDate) {
		return -1;
	}
	return 0;
};

export const UNAVAILABLE_CONTAINER_ID = 'unavailable-equipment-container';
export const AVAILABLE_CONTAINER_ID = 'available-equipment-container';

export function generateAvailableDraggableId(equipment: MechanicViewAvailableEquipmentDataVM): string {
	const { id, locationId } = equipment;
	const _locationId = locationId ?? -1;

	const uniqueCode = _mapToolbarGroupToString(_locationId, true);
	const context = ScheduleBoardContext.TOOLBAR;

	return `${id}#${context}#${uniqueCode}#${_locationId}`;
}

export function generateUnavailableDraggableId(equipmentId: number, equipmentStatusDetails: UnavailableEquipmentStatusData): string {
	const uniqueCode = _mapToolbarGroupToString(equipmentStatusDetails?.statusId, false);
	const context = ScheduleBoardContext.TOOLBAR;

	return `${equipmentId}#${context}#${uniqueCode}#${equipmentStatusDetails?.statusIndex}`;
}

export function generateDroppableId(uniqueCode: number, dueDate: string, isAvailable: boolean) {
	return ScheduleBoardUtil.generateDroppableId(ScheduleBoardContext.TOOLBAR, ScheduleBoardProperty.EQUIPMENT, dueDate, uniqueCode.toString(), 0, isAvailable);
}

export function parseDraggable(draggableId: string, dueDate: string, isAvailable: boolean): ScheduleBoardDragRequestModel {
	const [id, context, source, index] = draggableId.split('#');
	const property = context === ScheduleBoardContext.BOARD ? ScheduleBoardProperty.RESOURCE : ScheduleBoardProperty.EQUIPMENT;
	const sourceDroppableId = ScheduleBoardUtil.generateDroppableId(
		context as ScheduleBoardContext,
		property,
		dueDate,
		_parsStringToLocation(source),
		0,
		isAvailable
	);

	return {
		itemId: id,
		originalItemId: id,
		sourceDroppableId,
		sourceIndex: +index,
		dueDate,
	};
}

export const getSortedUnavailableEquipmentIds = (data: MechanicViewUnavailableEquipmentDataVM[], orderBy: OrderByEnum, sortBy: SortByEnum) => {
	const direction = orderBy === OrderByEnum.ASCENDING ? 1 : -1;

	let sort: Nullable<UnavailableEquipmentSortFn>;

	if (sortBy === SortByEnum.PRIORITY) {
		sort = _sortUnavailableByPriority;
	} else if (sortBy === SortByEnum.LAST_UPDATED) {
		sort = _sortUnavailableByLastUpdated;
	} else {
		sort = _sortUnavailableByReturnDate;
	}

	return [...data]
		.sort((_elementA, _elementB) => direction * sort!(_elementA, _elementB))
		.map(ArrayUtil.getId);
};

export const calculateMatchedEquipmentData = (state: MechanicViewStoreState): Partial<MechanicViewStoreState> => {
	const { availableEquipment, unavailableEquipment, searchQuery } = state;

	const matchedEquipmentIdMap: MatchedEquipmentIdMap = {};
	const matchedEquipmentIds: number[] = [];

	for (const _equipment of availableEquipment) {
		if (MechanicViewAvailableEquipmentDataVM.matches(_equipment, searchQuery)) {
			matchedEquipmentIds.push(_equipment.id);
			matchedEquipmentIdMap[_equipment.id] = true;
		}
	}

	for (const _equipment of unavailableEquipment) {
		if (MechanicViewUnavailableEquipmentDataVM.matches(_equipment, searchQuery)) {
			matchedEquipmentIds.push(_equipment.id);
			matchedEquipmentIdMap[_equipment.id] = true;
		}
	}

	return { matchedEquipmentIds, matchedEquipmentIdMap };
};
