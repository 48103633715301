import type ToolBase from 'ab-domain/models/tool/base';

export class W_SmallTool_FindTakenSerialList_VM {
	items: W_SmallTool_FindTakenSerialList_VM_Single[];

	constructor(tool: ToolBase[]) {
		this.items = W_SmallTool_FindTakenSerialList_VM_Single.bulkConstructor(tool);
	}
}

class W_SmallTool_FindTakenSerialList_VM_Single {
	id: number;
	serialNumber: string;

	constructor(tool: ToolBase) {
		this.id = tool.id;
		this.serialNumber = tool.serialNumber;
	}

	private static _mapConstructor = (tool: ToolBase) => new W_SmallTool_FindTakenSerialList_VM_Single(tool);
	static bulkConstructor = (tools: ToolBase[]) => tools.map(W_SmallTool_FindTakenSerialList_VM_Single._mapConstructor);
}
