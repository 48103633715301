import { isValidTextInput } from 'acceligent-shared/utils/text';

import type SignatureForm from './FormModel';

export const validateSignatureForm = (values: SignatureForm) => {
	const errors: ValidationErrors = {};

	if (!isValidTextInput(values.fullName)) {
		errors.fullName = 'Full Name is required';
	}

	if (!values.signatureImage && !values.digitalSignatureId) {
		errors.signatureImage = 'Signature is required';
	}

	return errors;
};
