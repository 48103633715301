import * as React from 'react';

import type { DisplayViewEmployeeViewModel } from 'ab-viewModels/scheduleBoardDisplayView.viewModel';

import AvailableEmployeeItem from 'af-root/scenes/Company/ScheduleBoard/DisplayView/ResourceItems/EmployeeItems/AvailableEmployeeItem';

interface OwnProps {
	employeeGroup: DisplayViewEmployeeViewModel[];
}

type Props = OwnProps;

const AvailableEmployeeColumn: React.FC<Props> = (props: Props) => {
	const { employeeGroup } = props;

	return (
		<div className="display-view-available-column-wrapper">
			<div className="display-view-column">
				{
					employeeGroup.map((_employee) => (
						<AvailableEmployeeItem
							employee={_employee}
							hideMultipleAssignmentBadges={false}
							key={_employee.employeeId}
							wasInNightShift={false}
						/>
					))
				}
			</div>
		</div>
	);
};

export default React.memo(AvailableEmployeeColumn);
