import * as React from 'react';
import type { CustomRouteComponentProps } from 'react-router-dom';
import { Route } from 'react-router-dom';

interface Props {
	forRoute: string;
	renderComponent: (props: CustomRouteComponentProps) => React.ReactNode;
}

const FSBottomComponent: React.FC<Props> = (props: Props) => {
	const { forRoute, renderComponent } = props;

	return (
		<div className="autosave">
			<Route path={forRoute} render={renderComponent} />
		</div>
	);
};

export default React.memo(FSBottomComponent);
