import { isValidTextInput } from 'acceligent-shared/utils/text';

import type AddressRequestModel from 'ab-requestModels/address.requestModel';

export const validateAddress = (values: AddressRequestModel) => {
	const errors: ValidationErrors = {};

	if (!isValidTextInput(values?.street)) {
		errors.street = 'Street address is required';
	}

	if (!isValidTextInput(values?.locality)) {
		errors.locality = 'City is required';
	}

	if (!isValidTextInput(values?.aa1)) {
		errors.aa1 = 'State is required';
	}

	if (!isValidTextInput(values?.country)) {
		errors.country = 'Country is required';
	}

	if (!isValidTextInput(values?.postalCode)) {
		errors.postalCode = 'Zip Code is required';
	}

	if (
		values?.latitude
		&& (values.latitude > 90 || values.latitude < -90)
	) {
		errors.latitude = 'Latitude is not valid';
	}

	if (
		values?.longitude
		&& (values.longitude > 180 || values.longitude < -180)
	) {
		errors.longitude = 'Longitude is not valid';
	}

	return Object.keys(errors).length === 0 ? null : errors;
};
