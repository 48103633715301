import * as React from 'react';
import { Field } from 'redux-form';
import { Row, Col } from 'react-bootstrap';

import type LocationOptionVM from 'ab-viewModels/location/option.viewModel';
import type UserOptionVM from 'ab-viewModels/user/option.viewModel';

import Dropdown from 'af-fields/Dropdown';

import { useLazyLoad } from 'af-utils/react.util';

import type { ExistingUserFM } from './formModel';

interface OwnProps {
	loadLocations: () => Promise<LocationOptionVM[]>;
	loadUsers: () => Promise<UserOptionVM[]>;
	userChanged: (selectedUser: ExistingUserFM) => void;
}

type Props = OwnProps;

const renderUserOption = (option: UserOptionVM) => {
	const contact: string[] = [];
	if (option.email) {
		contact.push(option.email);
	}
	if (option.phoneNumber) {
		contact.push(option.phoneNumber);
	}
	return (
		<div>
			<div>{option.fullName}</div>
			<small>{contact.join(', ')}</small>
		</div>
	);
};

const renderLocationOption = (option: LocationOptionVM) => (
	<div>
		<div>{option.nickname}</div>
		<small>{option.street}</small>
	</div>
);
const renderSelectedUser = (option: UserOptionVM) => (<div>{option.fullName}</div>);
const renderSelectedLocation = (option: LocationOptionVM) => (<div>{option.nickname}</div>);

const LOCATION_FILTER_BY = ['nickname'];
const USER_FILTER_BY = ['fullName'];

const ExistingMember: React.FC<Props> = (props) => {
	const { loadLocations, loadUsers, userChanged } = props;

	const {
		options: userOptions,
		lazyLoad: lazyLoadUsers,
	} = useLazyLoad(loadUsers);

	const {
		options: locationOptions,
		lazyLoad: lazyLoadLocations,
	} = useLazyLoad(loadLocations);

	const onSelectUserChange = React.useCallback((selectedUser: ExistingUserFM) => {
		userChanged(selectedUser);
	}, [userChanged]);

	return (
		<Row className="row--padded-bottom">
			<Col sm={6}>
				<Field
					component={Dropdown}
					filterable={true}
					filterBy={USER_FILTER_BY}
					id="user_select"
					label="Select existing user *"
					name="existingUserId"
					onLazyLoad={lazyLoadUsers}
					onValueChange={onSelectUserChange}
					options={userOptions}
					placeholder="Select user"
					propName="existingUser"
					renderMenuItem={renderUserOption}
					renderSelected={renderSelectedUser}
					valueKey="id"
					withCaret={true}
				/>
			</Col>
			<Col sm={6}>
				<Field
					component={Dropdown}
					filterable={true}
					filterBy={LOCATION_FILTER_BY}
					id="location_select"
					label="Home Office"
					name="locationId"
					onLazyLoad={lazyLoadLocations}
					options={locationOptions}
					placeholder="Select Home Office"
					propName="location"
					renderMenuItem={renderLocationOption}
					renderSelected={renderSelectedLocation}
					valueKey="id"
					withCaret={true}
				/>
			</Col>
		</Row>
	);
};

export default React.memo(ExistingMember);
