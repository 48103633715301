import * as React from 'react';
import { compose } from 'redux';
import type { ConnectedProps } from 'react-redux';
import { connect } from 'react-redux';

import type { RootState } from 'af-reducers';

import CustomScrollbar from 'af-components/CustomScrollbar';

import DroppableComponent from './Droppable';

interface OwnProps {
	addBlankWorkOrder: (dueDate: string, index: number) => Promise<void>;
	droppableId: string;
	/** `MM-DD-YYYY` */
	dueDate: string;
	forceUnlockOrder: (workOrderId: string) => void;
	hasPermissionsToEditScheduleBoard: boolean;
	isDragAndDropDisabled: boolean;
	lastOpenedOrderCode: string;
	removeBlankWorkOrder: (dueDate: string, index: number) => Promise<void>;
}

type Props = OwnProps & ConnectedProps<typeof connector>;

const Board: React.FC<Props> = (props) => {
	const {
		addBlankWorkOrder,
		droppableId,
		dueDate,
		forceUnlockOrder,
		hasPermissionsToEditScheduleBoard,
		isDragAndDropDisabled,
		isEmpty,
		lastOpenedOrderCode,
		numberOfRows,
		removeBlankWorkOrder,
	} = props;

	let className = 'work-orders-droppable';
	className += isEmpty ? ' empty-droppable' : '';

	return (
		<CustomScrollbar
			contentWrapperClassName="schedule-board-cards-scrollable-container"
			scrollbarsClassName="schedule-board-cards-container-wrapper"
		>
			{Array(numberOfRows || 1).fill(1).map((i, index) => (
				<DroppableComponent
					addBlankWorkOrder={addBlankWorkOrder}
					className={className}
					droppableId={`${droppableId}#${index}`}
					dueDate={dueDate}
					forceUnlockOrder={forceUnlockOrder}
					hasPermissionsToEditScheduleBoard={hasPermissionsToEditScheduleBoard}
					isDragAndDropDisabled={isDragAndDropDisabled}
					key={`${droppableId}#${index}`}
					lastOpenedOrderCode={lastOpenedOrderCode}
					removeBlankWorkOrder={removeBlankWorkOrder}
					rowIndex={index}
				/>
			))}
		</CustomScrollbar>
	);
};

function mapStateToProps(state: RootState, ownProps: OwnProps) {
	const { dueDate } = ownProps;
	const workOrdersOnDateDict = state.scheduleBoard.workOrdersByDateDictionary[dueDate];

	return {
		numberOfRows: Object.keys(workOrdersOnDateDict?.workOrdersRowDistribution ?? {}).length,
		isEmpty: !workOrdersOnDateDict?.workOrdersOrdering?.length,
	};
}

const connector = connect(mapStateToProps);

const enhance = compose<React.ComponentType<OwnProps>>(
	React.memo,
	connector
);

export default enhance(Board);
