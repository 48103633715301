import * as React from 'react';
import { Overlay } from 'react-bootstrap';
import { nanoid } from 'nanoid';

import type { ColorPalette } from 'acceligent-shared/enums/color';

import SelectableColorSquare from 'af-components/SelectableColorSquare';

import { UNIQUE_ID_SIZE } from 'ab-common/constants/value';

import type { Position, Alignment } from './ColorsGrid';
import ColorsGrid from './ColorsGrid';

interface Props {
	value: ColorPalette;
	className?: string;
	disabled?: boolean;
	onChange?: (color: ColorPalette) => void;
	onFocus?: () => void;
	position: Position;
	align: Alignment;
	showAdditionalOptions?: boolean;
}

interface State {
	show: boolean;
	dropdownId: string;
}

export default class ColorPicker extends React.PureComponent<Props, State> {
	static defaultProps: Partial<Props> = {
		className: '',
		disabled: false,
		position: 'bottom',
		align: 'center',
		showAdditionalOptions: false,
	};

	state: State = {
		show: false,
		dropdownId: `color-palette-${nanoid(UNIQUE_ID_SIZE)}`, // Generate dropdown ID to allow finding it in DOM
	};

	overlayRef: React.RefObject<HTMLDivElement> | null = null;

	constructor(props: Props) {
		super(props);

		this.overlayRef = React.createRef();
	}

	onChange = (color: ColorPalette) => {
		const { onChange } = this.props;

		this.closePalette();
		onChange?.(color);
	};

	openPalette = () => {
		const { onFocus } = this.props;

		this.setState(() => ({ show: true }));

		if (onFocus) {
			onFocus();
		}
	};

	closePalette = () => this.setState(() => ({ show: false }));

	getLocation = () => {
		const menuItem = document.getElementById(this.state.dropdownId);
		let _x = 0;
		let _y = 0;
		if (menuItem) {
			const { x, y } = menuItem.getBoundingClientRect();
			_x = x;
			_y = y;
		}
		return {
			locationX: _x,
			locationY: _y,
		};
	};

	render() {
		const { value, className, disabled, position, showAdditionalOptions, align } = this.props;
		const { show } = this.state;
		const { locationX, locationY } = this.getLocation();

		return (
			<div className={`color-picker ${className}`} id={this.state.dropdownId}>
				<div className="color-picker__selection-container" onClick={!disabled ? this.openPalette : undefined} ref={this.overlayRef}>
					{value
						? <SelectableColorSquare color={value} selected={true} />
						: <div className="color-picker__no-selection" />
					}
				</div>
				<Overlay
					container={this.overlayRef?.current}
					onHide={this.closePalette}
					placement={position}
					rootClose={true}
					show={show}
					target={this.overlayRef?.current ?? null}
				>
					<ColorsGrid
						align={align}
						locationX={locationX}
						locationY={locationY}
						onChange={this.onChange}
						position={position}
						showAdditionalOptions={!!showAdditionalOptions}
						value={value}
					/>
				</Overlay>
			</div>
		);
	}
}
