export enum OrderCompletionStatusFilter {
	ALL = 'ALL',
	COMPLETED = 'COMPLETED',
	NOT_COMPLETED = 'NOT_COMPLETED'
}

export enum OrderCompletionStatusFilterText {
	ALL = 'All',
	COMPLETED = 'Completed',
	NOT_COMPLETED = 'Not Completed'
}

export type OrderCompletionStatusFilterOption = {
	id: OrderCompletionStatusFilter;
	name: OrderCompletionStatusFilterText;
};
