import * as React from 'react';

import type { UpdatedByViewModel} from 'ab-viewModels/account/updatedBy';
import { UPDATED_BY_FAKE_USER } from 'ab-viewModels/account/updatedBy';

import TimeFormatEnum from 'acceligent-shared/enums/timeFormat';

import { formatDate } from 'acceligent-shared/utils/time';

interface OwnProps {
	cancellationReason: string;
	updatedAt: Nullable<Date>;
	updatedBy: UpdatedByViewModel;
}

type Props = OwnProps;

class CanceledRibbon extends React.PureComponent<Props> {
	static defaultProps: Partial<Props> = {
		cancellationReason: '',
		updatedAt: null,
		updatedBy: UPDATED_BY_FAKE_USER,
	};

	render() {
		const { updatedAt, cancellationReason, updatedBy } = this.props;

		return (
			<div className="work-order-upsert__canceled-ribbon">
				<div className="work-order-upsert__ribbon-left">
					Canceled
				</div>
				<div className="work-order-upsert__ribbon-right">
					<span>"{cancellationReason}"</span>
					<span>
						<strong>{updatedAt && formatDate(updatedAt, TimeFormatEnum.DAY_WITH_DATE)}</strong> by <strong>{updatedBy.fullName}</strong>
					</span>
				</div>
			</div>
		);
	}
}

export default CanceledRibbon;
