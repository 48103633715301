import type { Dispatch, AnyAction } from 'redux';

import type { TableContent } from 'ab-common/dataStructures/tableContent';

import API from 'af-constants/routes/api';

import { LOADING_LOCATIONS } from 'af-constants/reduxForms';

import { http } from 'af-utils/http.util';
import { errorHandler } from 'af-utils/actions.util';

import type JobStatusRequestModel from 'ab-requestModels/jobStatus.requestModel';

import { TableQuery } from 'ab-common/dataStructures/tableQuery';

import type { JobStatusTableRowVM } from 'ab-viewModels/jobStatus/tableRow.viewModel';
import type { JobStatusViewModel } from 'ab-viewModels/jobStatus/jobStatus.viewModel';
import type JobStatusOptionVM from 'ab-viewModels/jobStatus/option.viewModel';

import type { GetRootState } from 'af-reducers';

export function create(form: JobStatusRequestModel) {
	return async (dispatch: Dispatch<AnyAction>, getState: GetRootState, { redirectTo }): Promise<boolean | undefined> => {

		const action = async () => {
			await http.post(API.V1.RESOURCES.JOB_STATUS.CREATE(), { ...form });
			return true;
		};

		return await errorHandler(action, dispatch, redirectTo);
	};
}

export function update(form: JobStatusRequestModel, id: number) {
	return async (dispatch: Dispatch<AnyAction>, getState: GetRootState, { redirectTo }) => {

		const action = async () => {
			if (!id) {
				throw new Error('Job Status ID not provided');
			}

			await http.put(API.V1.RESOURCES.JOB_STATUS.EDIT(id.toString()), { ...form });
		};

		return await errorHandler(action, dispatch, redirectTo);
	};
}

export function findById(id: string) {
	return async (dispatch: Dispatch<AnyAction>, getState: GetRootState, { redirectTo }) => {

		const action = async () => {
			return await http.get<JobStatusViewModel>(API.V1.RESOURCES.JOB_STATUS.FIND_BY_ID(id));
		};

		return await errorHandler(action, dispatch, redirectTo);
	};
}

export function deleteJobStatusFromTable(id: number) {
	return async (dispatch: Dispatch<AnyAction>, getState: GetRootState, { redirectTo }) => {

		const action = async () => {
			await http.delete(API.V1.RESOURCES.JOB_STATUS.DELETE(id.toString()));
		};

		return await errorHandler(action, dispatch, redirectTo);
	};
}

export function bulkDeleteJobStatusFromTable(ids: number[]) {
	return async (dispatch: Dispatch<AnyAction>, getState: GetRootState, { redirectTo }) => {

		const action = async () => {
			await http.delete(API.V1.RESOURCES.JOB_STATUS.BULK_DELETE, { jobStatusIds: ids });
		};

		return await errorHandler(action, dispatch, redirectTo);
	};
}

export function findList() {
	return async (dispatch: Dispatch<AnyAction>, getState: GetRootState, { redirectTo }) => {
		const action = async () => {
			return await http.get<JobStatusOptionVM[]>(API.V1.RESOURCES.JOB_STATUS.LIST, { submitting: LOADING_LOCATIONS });
		};
		return await errorHandler(action, dispatch, redirectTo);
	};
}

export function findAllForCompanyTable(tableRequestModel: TableQuery) {
	return async (dispatch: Dispatch<AnyAction>, getState: GetRootState, { redirectTo }) => {

		const action = async () => {
			const data = new TableQuery(tableRequestModel);
			return await http.get<TableContent<JobStatusTableRowVM>>(API.V1.RESOURCES.JOB_STATUS.TABLE(data));
		};

		return await errorHandler(action, dispatch, redirectTo);
	};
}
