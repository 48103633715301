import * as React from 'react';
import type { FormErrorsWithArray, WrappedFieldArrayProps } from 'redux-form';
import { Field } from 'redux-form';
import type { CellContext } from '@tanstack/react-table';

import OrderItemStatus from 'acceligent-shared/enums/orderItemStatus';
import { VendorPackageTypeLabel } from 'acceligent-shared/enums/vendorPackageType';
import { OrderItemStatusLabelingMap } from 'acceligent-shared/enums/orderItemStatus';
import BlobStorageImageSizeContainer from 'acceligent-shared/enums/blobStorageImageSizeContainer';

import { DEFAULT_EQUIPMENT_IMAGE } from 'ab-common/constants/value';

import type ItemOptionVM from 'ab-viewModels/item/itemOption.viewModel';

import Dropdown from 'af-fields/Dropdown';
import Input from 'af-fields/Input';
import Checkbox from 'af-fields/Checkbox';
import type { SimpleTableRow } from 'af-fields/SimpleTable';
import SimpleTableField from 'af-fields/SimpleTable';

import TextHighlight from 'af-components/TextHighlight';
import DropdownComponent from 'af-components/Controls/Dropdown';
import type { FooterButton, SimpleTableProps } from 'af-components/Controls/SimpleTable/types';
import ImageTag from 'af-components/Image';

import { useLazyLoad, useToggle } from 'af-utils/react.util';
import { dollarFormatter } from 'af-utils/format.util';

import type { DepartmentOption, OrderItemFM } from './formModel';
import type OrderUpsertFM from './formModel';
import styles from './styles.module.scss';

export type OwnProps = {
	errors: FormErrorsWithArray<OrderUpsertFM, string>;
	calculateAndSetTotal: (orderItems: OrderItemFM[]) => void;
	disabled: boolean;
	initialized: boolean;
	formValues: OrderUpsertFM;
	isInEditMode: boolean;
	setHasItemsInEditMode: (value: boolean) => void;
	itemsForStock: boolean;
	findItems: () => Promise<ItemOptionVM[]>;
	change: (field: string, value) => void;
	canManage: boolean;
};

type Props = WrappedFieldArrayProps<OrderUpsertFM['items'][0]> & OwnProps;

const ORDER_STATUS_ITEMS = Object.keys(OrderItemStatusLabelingMap).map((_status) => ({ id: _status, label: OrderItemStatusLabelingMap[_status] }));

const renderSelectedItemOption = (option: ItemOptionVM) => {
	return (
		<div key={option.id}>
			{option.name}
			{option.price ? dollarFormatter.format(option.price) : '-'}
		</div>
	);
};

const renderItemOption = (option: ItemOptionVM, searchText: string) => {
	return (
		<>
			<ImageTag
				fallbackSrc={DEFAULT_EQUIPMENT_IMAGE}
				minSize={BlobStorageImageSizeContainer.SIZE_50X50}
				src={option.imageUrl}
				tryOriginal={true}
				tryRoot={true}

			/>
			<div className={styles['order-form__menu-option']} key={option.id}>
				<div className={styles['order-form__menu-option__text']}>
					<span className={styles['order-form__menu-option__text__name']}>
						<TextHighlight searchText={searchText} text={option.name} />
						{option.packageType && <TextHighlight searchText={searchText} text={`(${VendorPackageTypeLabel[option.packageType]})`} />}
						<TextHighlight searchText={searchText} text={option.modelNumber} />
					</span>
					{option.price ? dollarFormatter.format(option.price) : '-'}
				</div>
				<div>
					<small className={styles['order-form__menu-option__sub-text']}>
						{option.vendorName && <TextHighlight searchText={searchText} text={option.vendorName} />}
						&nbsp;|&nbsp;
						{option.vendorPartNumber && <TextHighlight searchText={searchText} text={option.vendorPartNumber} />}
					</small>
				</div>
			</div >
		</>
	);
};

const renderDepartmentOption = (option: DepartmentOption) => {
	return (
		<div key={option.id}>
			{`${option.locationName} (${option.name}) In stock: ${option.currentStock}`}
		</div>
	);
};

const OrderItems: React.FC<Props> = (props) => {
	const {
		fields,
		errors,
		initialized,
		disabled,
		calculateAndSetTotal,
		formValues,
		isInEditMode,
		setHasItemsInEditMode,
		itemsForStock,
		findItems,
		change,
		canManage,
	} = props;

	const { lazyLoad: lazyLoadItems, options: itemOptions } = useLazyLoad(findItems);

	const [itemDropdownOptions, setItemDropdownOptions] = React.useState<ItemOptionVM[]>([]);

	const {
		value: showSelectItemDropdown,
		setToFalse: hideItemDropdown,
		setToTrue: showItemDropdown,
	} = useToggle(false);

	React.useEffect(() => {
		calculateAndSetTotal(fields.getAll());
	}, [calculateAndSetTotal, fields]);

	React.useEffect(() => {
		const currentItems = fields.getAll();
		const itemIds = currentItems?.map((i) => i.itemId) ?? [];
		if (itemOptions.length) {
			setItemDropdownOptions(itemsForStock ? itemOptions.filter((i) => !itemIds.includes(i.id)) : itemOptions);
		}
		// We want to reevaluate itemDropdownOptions only on itemOptions change
		// eslint-disable-next-line react-hooks/exhaustive-deps
	}, [itemOptions]);

	// Clears items when changing between regular and order for stock
	React.useEffect(() => {
		if (!isInEditMode && itemOptions) {
			fields.removeAll();

			// This ensures setting the clear items back into the dropdown option
			setItemDropdownOptions(itemOptions);
		}
		// We don't want to run this on fields changes
		// eslint-disable-next-line react-hooks/exhaustive-deps
	}, [itemsForStock]);

	const selectItem = React.useCallback((item: ItemOptionVM) => {
		const departmentForStockId = formValues?.departmentIdForStock;
		const itemDepartmentForStock = item.departmentOptions.find((d) => d.id === departmentForStockId);

		fields.push({
			id: new Date().getTime(), // we need some temporary numeric value that won't repeat if we add multiple same items
			orderItemId: null,
			itemId: item.id,
			itemName: item.name,
			quantity: 1,
			excludeFromTotalPrice: false,
			status: OrderItemStatus.UNFULFILLED,
			departmentId: itemsForStock ? departmentForStockId : null,
			fulfilledQuantity: 0,
			partNumber: item.partNumber,
			packageType: item.packageType,
			price: item.price ?? 0,
			departmentOptions: item.departmentOptions,
			itemDepartmentId: itemsForStock
				? itemDepartmentForStock?.itemDepartmentId ?? null
				: null,
			itemDepartment: (itemsForStock && itemDepartmentForStock)
				? {
					id: itemDepartmentForStock?.itemDepartmentId,
					currentStock: itemDepartmentForStock.currentStock,
					locationNickname: itemDepartmentForStock.locationName,
					departmentName: itemDepartmentForStock.name,
				}
				: null,
			comment: null,
		});

		if (itemsForStock) {
			const updatedOptions = itemDropdownOptions.filter((option) => option.id !== item.id);
			setItemDropdownOptions(updatedOptions);
		}

		setHasItemsInEditMode(true);
		hideItemDropdown();
	}, [fields, formValues?.departmentIdForStock, hideItemDropdown, itemDropdownOptions, itemsForStock, setHasItemsInEditMode]);

	// Use a ref to store the previous statuses
	const previousItemStatusesRef = React.useRef<string[] | undefined>();

	// We need this to make sure we only rerender columns when the item statuses change
	const itemStatuses = React.useMemo(() => {
		const newStatuses = formValues?.items?.map((item) => item.status).sort();

		if (previousItemStatusesRef.current?.join(',') !== newStatuses?.join(',')) {
			previousItemStatusesRef.current = newStatuses;
			return newStatuses;
		}
		return previousItemStatusesRef.current;
	}, [formValues?.items]);

	const getOrderItemStatus = React.useCallback((index: number) => {
		return formValues?.items[index]?.status ?? OrderItemStatus.UNFULFILLED;

		// We don't want anything more here, we specifically want to depend ONLY on statuses, because if we depend on anything more it will cause problems
		// as it will rerender input elements as we are putting the info in and we will lose focus
		// eslint-disable-next-line react-hooks/exhaustive-deps
	}, [itemStatuses]);

	const renderFulfilledQuantityField = React.useCallback((_cell: CellContext<OrderItemFM & SimpleTableRow, number>) => {
		const index = fields.getAll().findIndex((f) => f.id === _cell.row.original.id);
		const status = getOrderItemStatus(index);
		const showField = status === OrderItemStatus.PARTIAL_ORDER && _cell.getValue() !== 0;

		const field = _cell.row.original.isInEditMode && !disabled ?
			<Field
				component={Input}
				min={0}
				name={`items[${index}].fulfilledQuantity`}
				type="number"
			/> : _cell.getValue();

		return (
			showField &&
			<>
				{field}
			</>
		);
		// We don't want fields here
		// eslint-disable-next-line react-hooks/exhaustive-deps
	}, [disabled, getOrderItemStatus]);

	const getComment = React.useCallback((fieldIndex: number) => {
		return fields.get(fieldIndex)?.comment ?? 'N/A';
	}, [fields]);

	const getLocationName = React.useCallback((fieldIndex: number) => {
		return itemsForStock
			? formValues?.locationDepartmentForStock?.name
			: fields.get(fieldIndex)?.itemDepartment?.locationNickname;
	}, [fields, formValues?.locationDepartmentForStock?.name, itemsForStock]);

	const getDepartment = React.useCallback((fieldIndex: number) => {
		return {
			department: itemsForStock ? formValues?.locationDepartmentForStock : fields.get(fieldIndex)?.itemDepartment,
			departmentId: itemsForStock ? formValues?.locationDepartmentForStock?.departmentId : fields.get(fieldIndex)?.departmentId,
		};
	}, [fields, formValues?.locationDepartmentForStock, itemsForStock]);

	const getExtendedPrice = React.useCallback((fieldIndex: number) => {
		const field = fields.get(fieldIndex);
		if (!field) {
			return 0;
		}
		return (field.price ?? 0) * (field.quantity ?? 0);
	}, [fields]);

	const getDepartmentOptions = React.useCallback((fieldIndex: number) => {
		const currentField = fields.get(fieldIndex);
		if (!currentField) {
			return [];
		}
		const { departmentOptions, id: currentId, itemId: currentItemId } = currentField;

		// Collect all fields except the current one and match by itemId
		const otherSameItems = fields.getAll().filter(
			(field) => field.id !== currentId && field.itemId === currentItemId
		);

		const takenItemDepartmentsLookup = otherSameItems.reduce((acc, item) => {
			acc[item.itemDepartmentId ?? 0] = true;
			return acc;
		}, {} as { [itemDepartmentId: number]: true; });

		// Filter department options that are not used by other same items
		return departmentOptions?.filter(
			(option) => !takenItemDepartmentsLookup[option.itemDepartmentId]
		) ?? [];
	}, [fields]);

	const onDepartmentChange = React.useCallback((_cell: CellContext<OrderItemFM & SimpleTableRow, string>) =>
		(event: DepartmentOption) => {
			const index = _cell.row.original.index;
			const itemDepartmentId = +event?.itemDepartmentId;
			const department = _cell.row.original.departmentOptions.find((option) => option.itemDepartmentId === itemDepartmentId);

			if (department) {
				change(`items[${index}].itemDepartment`, {
					id: itemDepartmentId,
					currentStock: department.currentStock,
					locationNickname: department.locationName,
					departmentName: department.name,
				});
				change(`items[${index}].itemDepartmentId`, itemDepartmentId);
				change(`items[${index}].departmentId`, department.id);
			} else {
				change(`items[${index}].itemDepartment`, null);
				change(`items[${index}].itemDepartmentId`, null);
				change(`items[${index}].departmentId`, null);
			}
		}, [change]);

	const renderFromDepartmentField = React.useCallback((_cell: CellContext<OrderItemFM & SimpleTableRow, string>) => {
		const index = _cell.row.original.index ?? _cell.row.index;
		const { department, departmentId } = getDepartment(index);
		const locationName = getLocationName(index);
		const departmentOptions = getDepartmentOptions(index);

		if (_cell.row.original.isInEditMode && !itemsForStock) {
			return (
				<DropdownComponent<DepartmentOption>
					className={styles.dropdown}
					defaultValue={departmentOptions.find((option) => option.id === departmentId)}
					disabled={disabled || !canManage}
					filterable={true}
					filterBy={['locationName', 'name']}
					fixed={true}
					onValueChange={onDepartmentChange(_cell)}
					options={departmentOptions}
					renderMenuItem={renderDepartmentOption}
					valueKey="itemDepartmentId"
					withCaret={true}
				/>
			);
		}
		return <>{department ? `${locationName} (${department.departmentName})` : 'N/A'}</>;
		// We don't want getDepartment or getLocationName or departmentOptions here
		// User will have to mouse-click the field again for every character input
		// !! But we also want to make sure it recaches every time fields length changes !!
		// eslint-disable-next-line react-hooks/exhaustive-deps
	}, [itemsForStock, disabled, canManage, onDepartmentChange, fields?.getAll()?.length]);

	const renderSelectItemDropdown = React.useCallback(() => {
		return <div className={styles['item-table-footer__dropdown']}>

			<div>
				<DropdownComponent
					containerClassName={styles['item-dropdown']}
					filterable={true}
					filterBy={['name', 'partNumber', 'modelNumber', 'packageType', 'vendorName', 'vendorPartNumber']}
					fixed={true}
					id="item-dropdown"
					onLazyLoad={lazyLoadItems}
					onValueChange={selectItem}
					options={itemDropdownOptions}
					placeholder="Select From Inventory System"
					renderMenuItem={renderItemOption}
					renderSelected={renderSelectedItemOption}
					valueKey="id"
					withCaret={true}
				/>
			</div>
			<span className={`icon-close ${styles['item-table-footer__action']}`} onClick={hideItemDropdown} />
		</div>;
	}, [lazyLoadItems, selectItem, itemDropdownOptions, hideItemDropdown]);

	const columns: SimpleTableProps<OrderItemFM & SimpleTableRow>['columns'] = React.useMemo(() => {
		const baseColumns: SimpleTableProps<OrderItemFM & SimpleTableRow>['columns'] = [
			{
				id: 'quantity',
				size: 70,
				cell: (_cell: CellContext<OrderItemFM & SimpleTableRow, number>) => {
					if (_cell.row.original.isInEditMode && !disabled) {
						return (
							<Field
								component={Input}
								min={1}
								name={`${_cell.row.original.name}.quantity`}
								type="number"
							/>
						);
					}
					return _cell.getValue() ?? 'N/A';
				},
				header: 'Quantity',
				accessor: 'quantity',
				enableSorting: false,
			},
			{
				id: 'partNumber',
				cell: (_cell: CellContext<OrderItemFM & SimpleTableRow, string>) => {
					return _cell.row.original.partNumber ?? 'N/A';
				},
				header: 'Vendor Number',
				accessor: 'partNumber',
				enableSorting: true,
				size: 80,
			},
			{
				id: 'itemName',
				cell: (_cell: CellContext<OrderItemFM & SimpleTableRow, string>) => {
					return _cell.row.original.itemName ?? 'N/A';
				},
				header: 'Item Name',
				size: 300,
				accessor: 'itemName',
			},
			{
				id: 'itemType',
				size: 70,
				cell: (_cell: CellContext<OrderItemFM & SimpleTableRow, string>) => {
					return _cell.row.original.packageType ? VendorPackageTypeLabel[_cell.row.original.packageType] : 'N/A';
				},
				header: 'Item Type',
				accessor: 'packageType',
			},
			{
				id: 'currentStock',
				cell: (_cell: CellContext<OrderItemFM & SimpleTableRow, string>) => {
					const itemDepartment = _cell.row.original.itemDepartment;
					if (!itemDepartment) {
						return 'N/A';
					}

					const { currentStock } = itemDepartment;
					return `${currentStock}`;
				},
				header: 'Current Stock',
				accessor: 'currentStock',
				size: 50,
			},
			{
				id: 'price',
				size: 100,
				cell: (_cell: CellContext<OrderItemFM & SimpleTableRow, number>) => {
					return dollarFormatter.format(_cell.row.original.price ?? 0);
				},
				header: 'Price Each',
				accessor: 'price',
			},
			{
				id: 'fulfilledQuantity',
				size: 100,
				cell: renderFulfilledQuantityField,
				header: 'Received Quantity',
				accessor: 'fulfilledQuantity',
			},
			{
				id: 'excludeFromTotalPrice',
				size: 120,
				cell: (_cell: CellContext<OrderItemFM & SimpleTableRow, number>) => {
					const index = _cell.row.original.index;
					return (
						<Field
							component={Checkbox}
							id={`checkbox-${_cell.row.original.id}`}
							isDisabled={disabled || !_cell.row.original.isInEditMode}
							name={`items[${index}].excludeFromTotalPrice`}
						/>
					);
				},
				header: 'Exclude Price from Total',
				accessor: 'excludeFromTotalPrice',
			},
			{
				id: 'status',
				cell: (_cell: CellContext<OrderItemFM & SimpleTableRow, number>) => {
					if (_cell.row.original.isInEditMode) {
						return (
							<Field
								className={styles['dropdown-white-background']}
								component={Dropdown}
								disabled={disabled}
								fixed={true}
								labelKey="label"
								name={`${_cell.row.original.name}.status`}
								options={ORDER_STATUS_ITEMS}
								valueKey="id"
								withCaret={true}
							/>
						);
					}
					return OrderItemStatusLabelingMap[_cell.getValue()] ?? null;
				},
				header: 'Status',
				accessor: 'status',
				size: 150,
			},
			{
				id: 'extendedPrice',
				size: 100,
				cell: (_cell: CellContext<OrderItemFM & SimpleTableRow, number>) => {
					return <div>
						{
							dollarFormatter.format(_cell.row.original.name
								? getExtendedPrice(_cell.row.original.index)
								: _cell.row.original.price * _cell.row.original.quantity)
						}
					</div>;
				},
				header: 'Extended Price',
				accessor: 'extendedPrice',
			},
			{
				id: 'comment',
				size: 250,
				cell: (_cell: CellContext<OrderItemFM & SimpleTableRow, number>) => {
					const index = _cell.row.original.index;
					if (_cell.row.original.isInEditMode) {
						return (
							<Field
								component={Input}
								name={`items[${index}].comment`}
								type="text"
							/>
						);
					}
					return _cell.row.original.name ? getComment(_cell.row.original.index) : _cell.row.original.comment;
				},
				header: 'Comment',
				accessor: 'excludeFromTotalPrice',
			},
		];

		if (!itemsForStock) {
			baseColumns.splice(4, 0, {
				id: 'fromDepartment',
				size: 320,
				cell: renderFromDepartmentField,
				header: 'From Department',
				accessor: 'fulfilledQuantity',
			});
		}

		return baseColumns;

		// We don't want fields or getComment or getExtendedPrice here
		// User will have to mouse-click the field again for every character input
		// eslint-disable-next-line react-hooks/exhaustive-deps
	}, [renderFromDepartmentField, renderFulfilledQuantityField, disabled]);

	const footerButtons = React.useMemo(() => {
		if (disabled) {
			return undefined;
		}
		const resolvedFooterButtons = [{
			iconName: 'icon-plus',
			label: 'Add Item',
			onClick: showItemDropdown,
		}];

		return resolvedFooterButtons as FooterButton[];
	}, [disabled, showItemDropdown]);

	const renderTable = React.useCallback(() => {
		return (
			<SimpleTableField
				allowEdit={!disabled}
				columns={columns}
				emptyTableMessage='No items added. Press "+ Add" to add an item.'
				errors={errors.items}
				fields={fields}
				footerButtonsLeft={footerButtons}
				footerComponent={showSelectItemDropdown ? renderSelectItemDropdown : undefined}
				initialized={initialized}
				key={itemsForStock ? 'stock' : 'regular'} // This is important
				label="Order Items"
				notifyAreThereItemsInEditMode={setHasItemsInEditMode}
			/>
		);
	}, [
		columns,
		disabled,
		errors.items,
		fields,
		footerButtons,
		initialized,
		renderSelectItemDropdown,
		setHasItemsInEditMode,
		showSelectItemDropdown,
		itemsForStock,
	]);

	return (
		renderTable()
	);
};

export default React.memo(OrderItems);
