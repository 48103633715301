import type { Dispatch, AnyAction } from 'redux';

import API from 'af-constants/routes/api';

import { http } from 'af-utils/http.util';
import { errorHandler } from 'af-utils/actions.util';

import type WorkRequestDirectoryVM from 'ab-viewModels/directory/workRequestDirectory.viewModel';

import type { GetRootState } from 'af-reducers';

import * as CreateDirectoryAPI from 'ab-api/web/job/createDirectory';

// Attachments:
export function getWorkRequestDirectoriesAttachments(workRequestId: number) {
	return async (dispatch: Dispatch<AnyAction>, getState: GetRootState, { redirectTo }) => {
		const action = async () => {

			const directoriesWithAttachments = await http.get<WorkRequestDirectoryVM[]>(
				API.V1.JOB.ATTACHMENTS.DIRECTORIES_ATTACHMENTS(workRequestId)
			);
			return directoriesWithAttachments;
		};
		return await errorHandler(action, dispatch, redirectTo);
	};
}

export function createDirectory(jobId: number, parentDirectoryId: number, newDirName: string) {
	return async (dispatch: Dispatch<AnyAction>, getState: GetRootState, { redirectTo }) => {
		const action = async () => {
			const requestBody: CreateDirectoryAPI.W_Job_CreateDirectory_RM = { name: newDirName };
			await http.post<void>(CreateDirectoryAPI.URL(jobId, parentDirectoryId), requestBody);
		};

		return await errorHandler(action, dispatch, redirectTo);
	};
}

export function deleteDirectory(workRequestId: number, directoryId: number) {
	return async (dispatch: Dispatch<AnyAction>, getState: GetRootState, { redirectTo }) => {

		const action = async () => {
			await http.delete(API.V1.JOB.DELETE_DIRECTORY(workRequestId, directoryId));
		};

		return await errorHandler(action, dispatch, redirectTo);
	};
}
