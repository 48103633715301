import * as React from 'react';

import type { IndividualLaborStatisticsParsed } from 'ab-viewModels/scheduleBoardLaborStatistics.viewModel';

import { getColorTextClass } from 'ab-utils/color.util';
import { fraction, round } from 'ab-utils/number.util';

interface Props {
	label: string;
	laborStatistics: IndividualLaborStatisticsParsed;
	isTotals?: boolean;
}

export default class IndividualLaborStatistics extends React.PureComponent<Props> {
	static defaultProps: Partial<Props> = {
		label: '',
		laborStatistics: {} as IndividualLaborStatisticsParsed,
		isTotals: false,
	};

	render() {
		const { label, laborStatistics, isTotals } = this.props;
		const { color, assignedLaborCount = 0, crewsCount = 0, totalRevenue = 0, totalLaborCount = 0 } = laborStatistics;
		return (
			<div className={`statistics-carousel__office ${getColorTextClass(color, !isTotals)}`} >
				{!!label && <strong>{label}</strong>}
				<div className="statistics-carousel__stats-container">
					<div className="statistics-carousel__labor-stats">
						<span>{crewsCount} WOs</span>
						<span>{assignedLaborCount}/{totalLaborCount} <span className="icon-user" /></span>
					</div>
					<div className="statistics-carousel__revenue-stats">
						{/* Rounded because we expect big values */}
						<span>${round(totalRevenue)}</span>
						<span>${round(fraction(totalRevenue, assignedLaborCount) ?? 0)} pp</span>
					</div>
				</div>
			</div>
		);
	}
}
