import * as React from 'react';
import type { ConnectedProps } from 'react-redux';
import { connect } from 'react-redux';

import { FORBIDDEN_ALIASES } from 'ab-common/constants/value';

import type { RootState } from 'af-reducers';

import * as ScheduleBoardActions from 'af-actions/scheduleBoard';

import LoadingIndicator from 'af-components/LoadingIndicator';

import socket from 'af-utils/socket.util';
import { getCurrentOrgAlias } from 'af-utils/window.util';
import { getSignInForOrg } from 'af-utils/localStorage.util';

function mapStateToProps(state: RootState) {
	return {
		companyId: state?.user?.companyData?.id ?? undefined,
	};
}

function mapDispatchToProps() {
	return {
		reloadScheduleBoard: ScheduleBoardActions.reloadScheduleBoard,
	};
}

type Props = ConnectedProps<typeof connector>;

const connector = connect(mapStateToProps, mapDispatchToProps());

interface State {
	socketConnected: boolean;
	isCompanyChangeInProgress: boolean;
	authFailed: boolean;
}

function withSocketConnection(WrappedComponent: React.ComponentClass) {
	class WithSocketConnection extends React.Component<Props, State> {
		state: State = {
			socketConnected: socket.connection?.isConnected() ?? false,
			isCompanyChangeInProgress: false,
			authFailed: false,
		};

		private _orgAlias = getCurrentOrgAlias();

		constructor(props: Props) {
			super(props);

			// Socket init
			socket.initConnection(this.onSocketConnect, this.onSocketDisconnect, this.onSocketConnectError);
		}

		componentDidUpdate() {
			const { companyId, reloadScheduleBoard } = this.props;
			const { socketConnected, isCompanyChangeInProgress, authFailed } = this.state;

			const canChangeCompany = !isCompanyChangeInProgress && socketConnected && companyId !== undefined;
			const shouldChangeCompany = canChangeCompany && companyId !== socket.connection?.getData()?.companyId;

			if (shouldChangeCompany) {
				this.setState(() => ({ isCompanyChangeInProgress: true }), async () => {
					if (!this._orgAlias) {
						throw new Error('Organization not defined');
					}
					await socket.connection?.changeCompany({ companyId, orgAlias: this._orgAlias });
					reloadScheduleBoard();
					this.setState(() => ({ isCompanyChangeInProgress: false }));
				});
			}
			if (authFailed) {
				this.setState(() => ({ authFailed: false }));
			}
		}

		onSocketConnect = () => {
			this.setState(() => ({ socketConnected: true }));
		};

		onSocketDisconnect = () => {
			const { reloadScheduleBoard } = this.props;
			reloadScheduleBoard();
			this.setState(() => ({ socketConnected: false }));
			socket.initConnection(this.onSocketConnect, this.onSocketDisconnect, this.onSocketConnectError);
		};

		onSocketConnectError = () => {
			// called when socket returns 401 (token validate error)
			// handler clears storage, and this triggers rerender
			// once storage is clear, user will be redirected to login page
			this.setState(() => ({ authFailed: true }));
		};

		render() {
			const { socketConnected, isCompanyChangeInProgress } = this.state;

			if (
				!FORBIDDEN_ALIASES.includes(this._orgAlias!)
				&& !socketConnected
				&& !isCompanyChangeInProgress
				&& !!getSignInForOrg(this._orgAlias)?.companyId
			) {
				return (
					<div className="app__loading">
						<LoadingIndicator color="orange" />
					</div>
				);
			}

			return <WrappedComponent {...this.props} />;
		}
	}

	return connector(WithSocketConnection);
}

export default withSocketConnection;
