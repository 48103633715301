import type { Dispatch } from 'redux';

import * as generalActionCreators from 'af-actions/general/general.actionCreators';

import * as httpActions from 'af-reducers/http.reducer';

import { http } from 'af-utils/http.util';
import { errorHandler } from 'af-utils/actions.util';
import { getCurrentOrgAlias } from 'af-utils/window.util';
import { getSignInForOrg } from 'af-utils/localStorage.util';

import type { GetRootState } from 'af-reducers';

export const getCurrentUserEmailFromLocalStorage = () => {
	const _currentOrgAlias = getCurrentOrgAlias();
	return _currentOrgAlias
		? getSignInForOrg(_currentOrgAlias)?.email ?? null
		: null;
};

export function showRefreshModal() {
	return (dispatch: Dispatch<generalActionCreators.GeneralAction>) => {
		dispatch(generalActionCreators.SHOW_REFRESH_MODAL());
	};
}

export function hideRefreshModal() {
	return (dispatch: Dispatch<generalActionCreators.GeneralAction>) => {
		dispatch(generalActionCreators.HIDE_REFRESH_MODAL());
	};
}

export function cancelFileUpload() {
	return async (dispatch: Dispatch<httpActions.UploadProgress | httpActions.UploadProgressSize>, getState: GetRootState, { redirectTo }) => {

		const action = async () => {
			await http.cancel();
			dispatch(httpActions.UPLOAD_PROGRESS(0));
			dispatch(httpActions.UPLOAD_PROGRESS_SIZE(0));
		};

		return await errorHandler(action, dispatch, redirectTo);
	};
}
