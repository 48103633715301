import type { Dispatch, AnyAction } from 'redux';

import * as ReactGA from 'react-ga';

import { getCurrentUserEmailFromLocalStorage } from 'af-actions/general/general.actions';

import { errorHandler } from 'af-utils/actions.util';

import type { GetRootState } from 'af-reducers';

export function initializeGoogleAnalytics() {
	return async (dispatch: Dispatch<AnyAction>, getState: GetRootState, { redirectTo }) => {

		const action = async () => {

			if (!process.env.GOOGLE_ANALYTICS_TRACKING_ID) {
				throw new Error('Google analytics not set up, check GOOGLE_ANALYTICS_TRACKING_ID');
			}

			const email = getCurrentUserEmailFromLocalStorage();
			const gaOptions: ReactGA.GaOptions = email ? { userId: email } : {};

			ReactGA.initialize(process.env.GOOGLE_ANALYTICS_TRACKING_ID, {
				debug: true,
				titleCase: false,
				gaOptions,
			});

		};

		return await errorHandler(action, dispatch, redirectTo);
	};
}
