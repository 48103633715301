import * as React from 'react';

import type { ContactForInvoicesVM } from 'ab-viewModels/contact/contactsForInvoices.viewModel';

import BillingContactDropdown from './BillingContactDropdown';
import type { InvoiceBillingContactFM } from './BillingContactArray';
import styles from '../styles.module.scss';

interface OwnProps {
	fieldName: string;
	index: number;
	orderNumber: number;
	onRemove: (index: number) => void;
	billingContacts: ContactForInvoicesVM[];
	// eslint-disable-next-line @typescript-eslint/no-explicit-any
	change: (fieldName: string, value: Metadata | string | number | boolean | null) => void;
	currentContact: Nullable<InvoiceBillingContactFM>;
}

type Props = OwnProps;

const BillingContactEdit: React.FC<Props> = React.memo((props) => {
	const { index, onRemove, fieldName, orderNumber, billingContacts, change, currentContact } = props;

	const remove = React.useCallback(() => {
		onRemove(index);
	}, [onRemove, index]);

	const renderLabel = React.useCallback(() => {
		return (
			<span>
				Billing Contact Email
				{orderNumber}
				<span className={styles['billing-contacts__list__item__edit__asterisk']}>
					*
				</span>
			</span>
		);
	}, [orderNumber]);

	return (
		<div className={styles['billing-contacts__list__item__edit']}>
			<BillingContactDropdown
				billingContacts={billingContacts}
				change={change}
				currentContact={currentContact}
				fieldName={fieldName}
				label={renderLabel()}
			/>
			<span className={styles['billing-contacts__list__item__edit__actions']}>
				<span className={`${styles['billing-contacts__list__item__edit__actions--red']}`} onClick={remove} >
					<span className="icon-delete" />
					<span>
						Delete
					</span>
				</span>
			</span>
		</div>
	);
});

export default BillingContactEdit;
