import * as React from 'react';
import type { ResolveThunks } from 'react-redux';
import { connect } from 'react-redux';
import type { CustomRouteComponentProps } from 'react-router-dom';
import { Row, Col } from 'react-bootstrap';

import type FieldWorkClassificationCodePreviewVM from 'ab-viewModels/fieldWorkClassificationCode/preview';

import type { RootState } from 'af-reducers';

import * as FieldWorkClassificationCodeActions from 'af-actions/fieldWorkClassificationCode';

import CLIENT from 'af-constants/routes/client';

import LockedValue from 'af-components/LockedValue';
import type { BreadcrumbItem } from 'af-components/Breadcrumbs';

import ResourcePreview from '../../Shared/ResourcePreview';

interface PathParams {
	id: string;
}

type OwnProps = CustomRouteComponentProps<PathParams>;

interface DispatchProps {
	findById: typeof FieldWorkClassificationCodeActions.findById;
}

interface StateProps {
	companyName: string;
}

type Props = OwnProps & ResolveThunks<DispatchProps> & StateProps;

interface State {
	fieldWorkClassificationCode: Nullable<FieldWorkClassificationCodePreviewVM>;
}

class FieldWorkClassificationCodePreview extends React.PureComponent<Props, State> {
	state: State = {
		fieldWorkClassificationCode: null,
	};

	static getBreadcrumbs = (orgAlias: string, companyName: string): BreadcrumbItem[] => {
		return [
			{ label: 'Classification Codes', url: CLIENT.COMPANY.RESOURCES.FIELD_WORK_CLASSIFICATION_CODE.LIST(orgAlias, companyName) },
			{ label: 'Preview' },
		];
	};

	static LoadingContent = () => (
		<Row className="row--padded-top">
			<Col className="loading-box-field standalone" lg={6} md={8} />
			<Col className="loading-box-field standalone" lg={12} md={16} />
		</Row>
	);

	async componentDidMount() {
		const { findById, match: { params: { id } } } = this.props;
		const fieldWorkClassificationCode = await findById(+id);
		this.setState(() => ({ fieldWorkClassificationCode: fieldWorkClassificationCode ?? null }));
	}

	render() {
		const { location: { state: { orgAlias } }, companyName } = this.props;
		const { fieldWorkClassificationCode } = this.state;

		return (
			<ResourcePreview
				backToListLabel="Back to Classification Code List"
				backToListUrl={CLIENT.COMPANY.RESOURCES.FIELD_WORK_CLASSIFICATION_CODE.LIST(orgAlias, companyName)}
				breadcrumbs={FieldWorkClassificationCodePreview.getBreadcrumbs(orgAlias, companyName)}
				editUrl={!fieldWorkClassificationCode ? '#' : CLIENT.COMPANY.RESOURCES.FIELD_WORK_CLASSIFICATION_CODE.EDIT(`${fieldWorkClassificationCode.id}`, orgAlias, companyName)}
			>
				{!fieldWorkClassificationCode
					? <FieldWorkClassificationCodePreview.LoadingContent />
					: (
						<Row className="row--padded-top">
							<Col lg={6} md={8}><LockedValue label="Code" value={fieldWorkClassificationCode.code} /></Col>
							<Col lg={12} md={16}><LockedValue label="Description" value={fieldWorkClassificationCode.description} /></Col>
						</Row>
					)
				}
			</ResourcePreview>
		);
	}

}

function mapStateToProps(state: RootState): StateProps {
	const { companyData } = state.user;
	if (!companyData) {
		throw new Error('User not logged in');
	}

	return {
		companyName: companyData.name,
	};
}

function mapDispatchToProps(): DispatchProps {
	return {
		findById: FieldWorkClassificationCodeActions.findById,
	};
}

export default connect<StateProps, DispatchProps, OwnProps>(mapStateToProps, mapDispatchToProps())(FieldWorkClassificationCodePreview);
