import type { Dispatch, AnyAction } from 'redux';

import API from 'af-constants/routes/api';

import { http } from 'af-utils/http.util';
import { errorHandler } from 'af-utils/actions.util';

import type { SkillRequestModel } from 'ab-requestModels/skill.requestModel';

import type { SkillViewModel, GroupedSkills, SkillOptionViewModel } from 'ab-viewModels/skill.viewModel';

import type { GetRootState } from 'af-reducers';

export function create(form: SkillRequestModel) {
	return async (dispatch: Dispatch<AnyAction>, getState: GetRootState, { redirectTo }) => {

		const action = async () => {
			await http.post(API.V1.RESOURCES.SKILL.CREATE(), { ...form });
		};
		return await errorHandler(action, dispatch, redirectTo);
	};
}

export function update(skillId: number, form: SkillRequestModel) {
	return async (dispatch: Dispatch<AnyAction>, getState: GetRootState, { redirectTo }) => {

		const action = async () => {
			return await http.put(API.V1.RESOURCES.SKILL.EDIT(skillId.toString()), { ...form });
		};
		return await errorHandler(action, dispatch, redirectTo);
	};
}

export function deleteSkill(id: number) {
	return async (dispatch: Dispatch<AnyAction>, getState: GetRootState, { redirectTo }) => {

		const action = async () => {
			await http.delete(API.V1.RESOURCES.SKILL.DELETE(id.toString()));
		};
		return await errorHandler(action, dispatch, redirectTo);
	};
}

export function findAllForCompany() {
	return async (dispatch: Dispatch<AnyAction>, getState: GetRootState, { redirectTo }) => {

		const action = async () => {
			return await http.get<GroupedSkills>(API.V1.RESOURCES.SKILL.LIST(''));
		};
		return await errorHandler(action, dispatch, redirectTo);
	};
}

export function findAllForDropdown() {
	return async (dispatch: Dispatch<AnyAction>, getState: GetRootState, { redirectTo }) => {
		const action = async () => {
			return await http.get<SkillOptionViewModel[]>(API.V1.RESOURCES.SKILL.OPTIONS());
		};
		return await errorHandler(action, dispatch, redirectTo);
	};
}

export function findAllForCompanyByQuery(query: string) {
	return async (dispatch: Dispatch<AnyAction>, getState: GetRootState, { redirectTo }) => {

		const action = async () => {
			return await http.get<SkillViewModel[]>(API.V1.RESOURCES.SKILL.SEARCH(query));
		};
		return await errorHandler(action, dispatch, redirectTo);
	};
}
