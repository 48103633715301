import * as React from 'react';
import type { CustomRouteComponentProps } from 'react-router-dom';
import { Link, Route } from 'react-router-dom';

import Tooltip from 'af-components/Tooltip';

import { isAllowed } from 'ab-utils/auth.util';

import type * as User from 'ab-viewModels/user.viewModel';

interface OwnProps {
	visibleFor: Metadata;
	companyData: User.CompanyData;
	userData: User.UserData;
	icon?: string;
	label?: string;
	url?: string;
	imgActive?: string;
	imgInactive?: string;
	isActive?: boolean;
	onRedirect: () => void;
	renderComponentMethod?: Nullable<(props: CustomRouteComponentProps) => React.ReactNode>;
	isFeatureHidden?: boolean;
	showTooltip?: boolean;
}

type Props = OwnProps;

const SideNavLink: React.FC<Props> = (props: Props) => {

	const {
		visibleFor,
		companyData,
		isFeatureHidden,
		userData,
		url,
		label,
		icon,
		isActive,
		renderComponentMethod,
		showTooltip,
		onRedirect,
	} = props;

	const isVisible = () => {

		if (isFeatureHidden) {
			return false;
		}

		if (!visibleFor) {
			return true;
		}

		if (!companyData) {
			return true;
		}

		if (!companyData.permissions) {
			return true;
		}

		return isAllowed(visibleFor, companyData.permissions, companyData.isCompanyAdmin, userData.role);
	};

	const onClickRedirect = React.useCallback(() => {
		onRedirect();
	}, [onRedirect]);

	const renderBody = () => {

		return url ?
			<Link onClick={onClickRedirect} to={url}>
				<span className={`${icon} sidenav-icon`} />
				{label}
			</Link> :
			<a className="cursor-disabled">
				<span className={`${icon} sidenav-icon`} />
				{label}
			</a>;
	};

	if (!isVisible()) {
		return null;
	}

	if (renderComponentMethod) {
		return (
			<Route
				render={renderComponentMethod}
			/>
		);
	}

	return (
		<li className={`${!url ? 'cursor-disabled' : ''} ${isActive ? 'active' : ''}`}>
			{
				showTooltip
					? (
						<Tooltip
							className="tooltip--sidenav"
							message={label}
							placement="right"
						>
							{renderBody()}
						</Tooltip>
					)
					: renderBody()
			}
		</li>
	);
};

export default React.memo(SideNavLink);

