import * as React from 'react';
import { compose } from 'redux';
import type { ResolveThunks } from 'react-redux';
import { connect } from 'react-redux';
import { Dropdown } from 'react-bootstrap';

import * as ScheduleBoardActions from 'af-actions/scheduleBoard/scheduleBoard.actions';

import ScheduleBoardSortType from 'ab-enums/scheduleBoardSortType.enum';
import BrowserStorageEnum from 'ab-enums/browserStorage.enum';

import SortMenuItem from 'af-components/Menu/MenuItem';

import { withSettings, setScheduleBoardSort } from 'af-utils/settings.util';

import * as SettingsKeys from 'af-constants/settingsKeys';

interface SettingsProps {
	workOrdersSort: ScheduleBoardSortType;
}

interface DispatchProps {
	changeWorkOrderSort: typeof ScheduleBoardActions.changeWorkOrderSort;
}

type ConnectOwnProps = SettingsProps;
type Props = ConnectOwnProps & ResolveThunks<DispatchProps>;

interface State {
	workOrdersSort: ScheduleBoardSortType;
}

class SortMenu extends React.PureComponent<Props, State> {

	state: State = {
		workOrdersSort: this.props.workOrdersSort,
	};

	componentDidMount() {
		const { workOrdersSort, changeWorkOrderSort } = this.props;
		changeWorkOrderSort(workOrdersSort);
		setScheduleBoardSort(workOrdersSort);
		this.setState(() => ({ workOrdersSort }));
	}

	toggleDropdown = () => {
		document.dispatchEvent(new MouseEvent('click'));
	};

	sortByOfficeAndJobNumberAsc = () => {
		this.sortBy(ScheduleBoardSortType.OFFICE_AND_JOB_NUMBER_ASC);
	};

	sortByOfficeAndJobNumberDesc = () => {
		this.sortBy(ScheduleBoardSortType.OFFICE_AND_JOB_NUMBER_DESC);
	};

	enableFreeReordering = () => {
		this.sortBy(ScheduleBoardSortType.FREE_REORDERING);
	};

	sortBy = (sortBy: ScheduleBoardSortType) => {
		const { changeWorkOrderSort } = this.props;
		changeWorkOrderSort(sortBy);
		setScheduleBoardSort(sortBy);
		this.setState(() => ({ workOrdersSort: sortBy }));
		this.toggleDropdown();
	};

	render() {
		const { workOrdersSort } = this.state;

		return (
			<Dropdown className="schedule-board-actions-menu" id="accqa__schedule-board-sort-menu__button">
				<Dropdown.Toggle variant="info">
					<span className="icon-sort" />
					Sort
				</Dropdown.Toggle>
				<Dropdown.Menu className="dropdown-menu--fit-content" id="accqa__schedule-board-sort-menu__dropdown">
					<SortMenuItem
						action={this.sortByOfficeAndJobNumberAsc}
						isActive={workOrdersSort === ScheduleBoardSortType.OFFICE_AND_JOB_NUMBER_ASC}
						label="By Office And Job Number (Asc)"
					/>
					<SortMenuItem
						action={this.sortByOfficeAndJobNumberDesc}
						isActive={workOrdersSort === ScheduleBoardSortType.OFFICE_AND_JOB_NUMBER_DESC}
						label="By Office And Job Number (Desc)"
					/>
					<div className="dropdown-menu__divider" />
					<SortMenuItem
						action={this.enableFreeReordering}
						isActive={workOrdersSort === ScheduleBoardSortType.FREE_REORDERING}
						label="Enable Free Reordering"
					/>
				</Dropdown.Menu>
			</Dropdown>
		);
	}
}

function mapDispatchToProps(): DispatchProps {
	return {
		changeWorkOrderSort: ScheduleBoardActions.changeWorkOrderSort,
	};
}

const enhance = compose<React.ComponentClass>(
	withSettings<Props>(() => ([
		{
			key: SettingsKeys.WORK_ORDER_SORT(),
			mappedName: 'workOrdersSort',
			defaultValue: ScheduleBoardSortType.FREE_REORDERING,
			source: BrowserStorageEnum.SESSION_STORAGE,
		},
	])),
	connect<null, DispatchProps, ConnectOwnProps>(null, mapDispatchToProps())
);

export default enhance(SortMenu);
