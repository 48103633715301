import type { AnyAction } from 'redux';
import { combineReducers } from 'redux';
import type { FormStateMap } from 'redux-form';
import * as LogRocket from 'logrocket';  // don't convert to default import

import * as ActionTypes from 'af-constants/actionTypes';

import type { CompanyStoreState } from 'af-models/company.models';
import type { FieldReportStoreState } from 'af-models/fieldReport.models';
import type { GeneralStoreState } from 'af-models/general.models';
import type { HttpStoreState } from 'af-reducers/http.reducer';
import type { OrganizationStoreState } from 'af-models/organization.models';
import type { ScheduleBoardStoreState } from 'af-models/scheduleBoard.models';
import type { SettingsStoreState } from 'af-models/settings.models';
import type { UserStoreState } from 'af-models/user.models';
import type { WorkOrderStoreState } from 'af-models/workOrder.models';

import company from 'af-reducers/company.reducer';
import fieldReport from 'af-reducers/fieldReport.reducer';
import form from 'af-reducers/form.reducer';
import general, { initialState as generalInitialState } from 'af-reducers/general.reducer';
import http from 'af-reducers/http.reducer';
import organization from 'af-reducers/organization.reducer';
import scheduleBoard from 'af-reducers/scheduleBoard.reducer';
import settings from 'af-reducers/settings.reducer';
import user from 'af-reducers/user.reducer';
import workOrder from 'af-reducers/workOrder.reducer';

// ts-unused-exports:disable-next-line
export type GetRootState = () => RootState;

// ts-unused-exports:disable-next-line
export interface RootState {
	company: CompanyStoreState;
	fieldReport: FieldReportStoreState;
	form: FormStateMap;
	general: GeneralStoreState;
	http: HttpStoreState;
	organization: OrganizationStoreState;
	scheduleBoard: ScheduleBoardStoreState;
	settings: SettingsStoreState;
	user: UserStoreState;
	workOrder: WorkOrderStoreState;
}

// ts-unused-exports:disable-next-line
const combinedReducers = combineReducers<RootState>({
	company,
	fieldReport,
	form,
	general,
	http,
	organization,
	scheduleBoard,
	settings,
	user,
	workOrder,
});

// ts-unused-exports:disable-next-line
export default (state: RootState, action: AnyAction) => {
	if (action.type === ActionTypes.LOGOUT_USER) {
		// every state, except general.isAppReady has to be cleared on logout :)
		// check here for details https://youtu.be/74BzSTQCl_c?t=10
		state = { general: { ...generalInitialState, isAppReady: state.general.isAppReady } } as RootState;

		// end logrocket session
		LogRocket.startNewSession();
	}
	return combinedReducers(state, action);
};
