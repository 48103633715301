import * as React from 'react';
import { Navbar } from 'react-bootstrap';
import { NavLink, useLocation } from 'react-router-dom-v5-compat';
import type { ResolveThunks } from 'react-redux';
import { connect } from 'react-redux';

import CLIENT from 'af-constants/routes/client';

import { EXTERNAL_ALIASES } from 'ab-common/constants/value';

import * as OrganizationActions from 'af-actions/organizations';

interface DispatchProps {
	findOrganizationPublicDetails: typeof OrganizationActions.findOrganizationPublicDetails;
}

type Props = ResolveThunks<DispatchProps>;

const HeaderLoggedOut: React.FC<Props> = (props) => {

	const { findOrganizationPublicDetails } = props;

	const location = useLocation();
	const orgAlias = location.state.orgAlias;

	React.useEffect(() => {
		const initOrgDetails = async () => {
			if (!orgAlias || EXTERNAL_ALIASES.includes(orgAlias.toLowerCase())) {
				// Do not request details for pages that aren't expected to have any
				return;
			}
			await findOrganizationPublicDetails(orgAlias);
		};
		initOrgDetails();
	}, [findOrganizationPublicDetails, orgAlias]);

	return (
		<Navbar>
			<div className="logged-out-header">
				<NavLink
					end
					to={CLIENT.AUTH.LOGIN(orgAlias)}
				>
					<img className="main-logo" src="/images/logo/ic_logo_black.svg" />
				</NavLink>
			</div>
		</Navbar>
	);
};

function mapDispatchToProps(): DispatchProps {
	return {
		findOrganizationPublicDetails: OrganizationActions.findOrganizationPublicDetails,
	};
}

const connector = connect(null, mapDispatchToProps());

export default connector(HeaderLoggedOut);
