import type { TableQuery } from 'ab-common/dataStructures/tableQuery';
import baseRoute from '../baseRoute';

export * from './viewModel';
export * from './queryParams';

type RouteDefinition = `${typeof baseRoute}/prevailingWagesTable/unionTypes/${number | ':unionTypeId'}/withClassificationCodes${`?query=${string}&startDate=${string}&endDate=${string}` | ''}`;

export const ROUTE: RouteDefinition = `${baseRoute}/prevailingWagesTable/unionTypes/:unionTypeId/withClassificationCodes`;

export const URL = (unionTypeId: number, startDate: string, endDate: string, tableQuery: TableQuery): RouteDefinition =>
	`${baseRoute}/prevailingWagesTable/unionTypes/${unionTypeId}/withClassificationCodes?query=${encodeURIComponent((JSON.stringify(tableQuery)))}&startDate=${startDate}&endDate=${endDate}`;
