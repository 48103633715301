import * as React from 'react';
import { compose } from 'redux';
import type { ConnectedProps } from 'react-redux';
import { connect } from 'react-redux';
import type { InjectedFormProps } from 'redux-form';
import { reduxForm } from 'redux-form';
import type { CustomRouteComponentProps } from 'react-router-dom';

import type { WageRateRequestModel } from 'ab-requestModels/wageRate.requestModel';

import type { WageRateVM } from 'ab-viewModels/wageRate/wageRate.viewModel';

import { DEFAULT_WAGE_RATE_TYPE, DEFAULT_WAGE_RATE_CLASSIFICATION } from 'ab-common/constants/value';

import { WAGE_RATE_EDIT } from 'af-constants/reduxForms';
import CLIENT from 'af-constants/routes/client';

import type { RootState } from 'af-reducers';

import * as WageRateActions from 'af-actions/wageRate';

import EditWageRateForm from 'af-components/SharedForms/Resources/WageRate';
import { validate } from 'af-components/SharedForms/Resources/WageRate/validation';
import Breadcrumbs from 'af-components/Breadcrumbs';

interface PathParams {
	id: string;
}

type FormProps = InjectedFormProps<WageRateRequestModel>;

type OwnProps = CustomRouteComponentProps<PathParams>;

type Props = ConnectedProps<typeof connector> & OwnProps & FormProps;

class Create extends React.PureComponent<Props> {
	async componentDidMount() {
		const { findById, match: { params: { id } }, initialize } = this.props;
		const wageRate = await findById(id);
		initialize(wageRate);
	}

	editWageRate = async (form: WageRateRequestModel) => {
		const {
			update,
			location: { state: { orgAlias } },
			companyName,
		} = this.props;
		await update(form, companyName, orgAlias);
	};

	render() {
		const {
			match: { params: { id } },
			location: { state: { orgAlias } },
			companyName,
			initialValues: { type, wageClassification } = {} as WageRateVM,
			handleSubmit,
			invalid,
			submitting,
		} = this.props;

		const isDefaultWageRate = type === DEFAULT_WAGE_RATE_TYPE && wageClassification === DEFAULT_WAGE_RATE_CLASSIFICATION;

		return (
			<div className="form-segment">
				<Breadcrumbs
					items={[
						{ label: 'Wage Rates', url: CLIENT.COMPANY.RESOURCES.WAGE_RATE.LIST(orgAlias, companyName) },
						{ label: 'Preview', url: CLIENT.COMPANY.RESOURCES.WAGE_RATE.PREVIEW(id, orgAlias, companyName) },
						{ label: 'Edit' },
					]}
				/>
				<form onSubmit={handleSubmit(this.editWageRate)}>
					<EditWageRateForm
						disabled={invalid}
						isDefaultWageRate={isDefaultWageRate}
						submitting={submitting}
					/>
				</form>
			</div>
		);
	}
}

function mapStateToProps(state: RootState) {
	const { companyData } = state.user;
	if (!companyData) {
		throw new Error('User not logged in');
	}

	return {
		companyName: companyData.name,
	};
}

function mapDispatchToProps() {
	return {
		findById: WageRateActions.findById,
		update: WageRateActions.update,
	};
}

const connector = connect(mapStateToProps, mapDispatchToProps());

const enhance = compose<React.ComponentClass<OwnProps>>(
	connector,
	reduxForm({ form: WAGE_RATE_EDIT, validate })
);
export default enhance(Create);
