import type { DeliverableAssignmentRequestModel } from 'ab-requestModels/deliverable.requestModel';

import type { DeliverableAssignmentViewModel } from 'ab-viewModels/deliverable.viewModel';

import type { TextColor, ColorPalette } from 'acceligent-shared/enums/color';

interface DeliverableStatusFM {
	id: number;
	name: string;
	abbreviation: string;
	description: Nullable<string>;
	statusColor: ColorPalette;
	textColor: TextColor;
	notify: boolean;
	isCompleted: boolean;
	isJobStatus: boolean;
}

interface EmployeeFM {
	id: number;
	firstName: string;
	lastName: string;
	fullName: string;
}

export default class DeliverableAssignmentFM {
	id?: number;
	deliverableSubmissionId: number;
	statusId: Nullable<number>;
	status: Nullable<DeliverableStatusFM>;
	employeeId: Nullable<number>;
	employee: Nullable<EmployeeFM>;
	notes: Nullable<string>;

	static fromVMtoFM(vm: DeliverableAssignmentViewModel): DeliverableAssignmentFM {
		return {
			id: vm.id,
			statusId: vm.statusId,
			status: vm.status,
			deliverableSubmissionId: vm.deliverableSubmissionId,
			employeeId: vm.employeeId,
			employee: vm.employee,
			notes: vm.notes,
		};
	}

	static fromFMtoRM(fm: DeliverableAssignmentFM): DeliverableAssignmentRequestModel {
		if (!fm.employeeId || !fm.employee || !fm.status || !fm.statusId) {
			throw new Error('Form not filled out correctly');
		}

		return {
			id: fm.id,
			deliverableSubmissionId: fm.deliverableSubmissionId,
			statusId: fm.statusId,
			status: fm.status,
			employeeId: fm.employeeId,
			employee: fm.employee,
			notes: fm.notes,
		};
	}
}
