import * as React from 'react';

import TimePeriodRecurrence from 'acceligent-shared/enums/timePeriodRecurrence';

import DailyPicker from './DailyPicker';
import WeeklyPicker from './WeeklyPicker';
import MonthlyPicker from './MonthlyPicker';
import PeriodPicker from './PeriodPicker';

import { SCREEN_BREAKPOINT_S } from 'af-constants/values';

import MultipleOptionsButton from 'af-components/MultipleOptionsButton';
import { RectangleButton } from 'af-components/MultipleOptionsButton/RectangleButton';

const PERIOD_OPTIONS = [
	TimePeriodRecurrence.DAILY,
	TimePeriodRecurrence.WEEKLY,
	TimePeriodRecurrence.MONTHLY,
	TimePeriodRecurrence.CUSTOM,
];

const PERIOD_LABEL = {
	[TimePeriodRecurrence.DAILY]: 'Daily',
	[TimePeriodRecurrence.WEEKLY]: 'Weekly',
	[TimePeriodRecurrence.MONTHLY]: 'Monthly',
	[TimePeriodRecurrence.CUSTOM]: 'Custom',
	[TimePeriodRecurrence.ALL]: 'All',
};

interface OwnProps {
	startDate: Date;
	endDate: Date;
	period: TimePeriodRecurrence;
	options?: TimePeriodRecurrence[];
	maxCustomDays?: number;
	areButtonsSmall?: boolean;
	onChange: (start: Date, end: Date) => void;
	changePeriod: (period: TimePeriodRecurrence, selected: Date) => void;
}

interface State {
	selected: Date;
	areButtonsSmall: boolean;
}

type Props = OwnProps;

class DateFilter extends React.PureComponent<Props, State> {

	static defaultProps: Partial<Props> = {
		options: PERIOD_OPTIONS,
	};

	state: State = {
		selected: this.props.startDate,
		areButtonsSmall: this.props.areButtonsSmall ?? window.innerWidth < SCREEN_BREAKPOINT_S,
	};

	componentDidMount() {
		this.updateWindowDimensions();
		window.addEventListener('resize', this.updateWindowDimensions);
	}

	componentWillUnmount() {
		window.removeEventListener('resize', this.updateWindowDimensions);
	}

	updateWindowDimensions = () => {
		this.setState(() => ({ areButtonsSmall: this.props.areButtonsSmall ?? window.innerWidth < SCREEN_BREAKPOINT_S }));
	};

	setSelectedPeriod = (value: TimePeriodRecurrence) => {
		const { changePeriod } = this.props;
		const { selected } = this.state;

		changePeriod(value, selected);
	};

	renderPeriodPicker = () => {
		const { areButtonsSmall } = this.state;
		const { period, options } = this.props;

		return (
			<div className="table-filter__period">
				<MultipleOptionsButton>
					{
						options?.map((_item: TimePeriodRecurrence, _index: number) =>
							<RectangleButton
								action={this.setSelectedPeriod.bind(this, _item)}
								isActive={_item === period}
								isSquare={areButtonsSmall}
								key={_index}
								label={areButtonsSmall ? PERIOD_LABEL[_item].substring(0, 1) : PERIOD_LABEL[_item]}
								tooltipMessage={areButtonsSmall ? PERIOD_LABEL[_item] ?? undefined : undefined}
							/>
						)
					}
				</MultipleOptionsButton>
			</div>
		);
	};

	selectDay = (date: Date) => {
		this.props.onChange(date, date);
		this.setState(() => ({ selected: date }));
	};

	selectPeriod = (selected: Date, start: Date, end: Date) => {
		this.props.onChange(start, end);
		this.setState(() => ({ selected }));
	};

	renderDatePicker = () => {
		const { selected } = this.state;
		const { period, endDate, maxCustomDays } = this.props;

		switch (period) {
			case TimePeriodRecurrence.ALL:
				return (
					<></>
				);
			case TimePeriodRecurrence.DAILY:
				return (
					<DailyPicker
						onChange={this.selectDay}
						selected={selected}
					/>
				);
			case TimePeriodRecurrence.WEEKLY:
				return (
					<WeeklyPicker
						onChange={this.selectPeriod}
						selected={selected}
					/>
				);
			case TimePeriodRecurrence.MONTHLY:
				return (
					<MonthlyPicker
						onChange={this.selectPeriod}
						selected={selected}
					/>
				);
			case TimePeriodRecurrence.CUSTOM:
			default:
				return (
					<PeriodPicker
						end={endDate}
						maxDays={maxCustomDays}
						onChange={this.selectPeriod}
						start={selected}
					/>
				);
		}
	};

	render() {
		return (
			<div className="table-filter">
				{this.renderDatePicker()}
				{this.renderPeriodPicker()}
			</div>
		);
	}
}

export default DateFilter;
