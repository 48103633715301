import * as React from 'react';

import { useOutletContext } from 'react-router-dom-v5-compat';

function withOutletContext<OutletContext extends object, Props extends object | undefined>(
	WrappedComponent: React.ComponentType<Props & OutletContext>
): React.FC<Props> {
	const WithOutletContext: React.FC<Props> = (props) => {
		const context = useOutletContext<OutletContext>();
		const combinedProps = { ...context, ...props };

		return <WrappedComponent {...(combinedProps as Props & OutletContext)} />;
	};

	return WithOutletContext;
}

export default withOutletContext;
