import * as React from 'react';
import { Draggable } from 'react-beautiful-dnd';

import Checkbox from 'af-components/Controls/Checkbox';

import type { ColumnSettingItem } from 'ab-viewModels/columnSettings.viewModel';

import { bemElement } from 'ab-utils/bem.util';

interface Props {
	item: ColumnSettingItem;
	index: number;
	onCheck: (item: ColumnSettingItem) => void;
}

export default class ColumnItem extends React.PureComponent<Props> {
	onCheck = () => {
		const { item, onCheck } = this.props;
		onCheck(item);
	};

	render() {
		const { item, index } = this.props;

		return (
			<Draggable
				draggableId={item.name}
				index={index}
				isDragDisabled={item.fixed}
			>
				{(provided) => (
					<div
						className={bemElement('table-settings', 'item')}
						ref={provided.innerRef}
						{...provided.draggableProps}
						{...provided.dragHandleProps}
					>
						{item.fixed
							? (
								<>
									<span className="icon-drag_indicator text-grey" />
									<span className="text-grey p-l-xxs">fixed</span>
								</>
							) : (
								<>
									<span className="icon-drag_indicator" />
									<Checkbox
										handleChange={this.onCheck}
										isChecked={item.visible}
										isStandalone={true}
										label={item.label as string}
									/>
								</>
							)
						}
					</div>
				)}
			</Draggable>
		);
	}
}
