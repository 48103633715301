import * as React from 'react';
import type { WrappedFieldArrayProps } from 'redux-form';
import type { ConnectedProps } from 'react-redux';
import { connect } from 'react-redux';

import * as AccountActions from 'af-actions/accounts';

import AccountPreview from './AccountPreview';

import NotificationPriority from 'ab-enums/notificationPriority.enum';

import Dropdown from 'af-components/Controls/Dropdown';

import type { PreviewVM } from 'ab-viewModels/account/preview.viewModel';

import type NotifyCompanyRequestModel from 'ab-requestModels/companyNotify.requestModel';

interface OwnProps extends WrappedFieldArrayProps<PreviewVM> {
	fixed: boolean;
	notificationPriority: NotificationPriority;
	selectedUserGroups: NotifyCompanyRequestModel['userGroups'];
}

type Props = OwnProps & ConnectedProps<typeof connector>;

interface State {
	accounts: PreviewVM[];
	options: PreviewVM[];
}

class Accounts extends React.PureComponent<Props, State> {
	state: State = {
		accounts: [],
		options: [],
	};

	static renderOption = (option: PreviewVM) => <span>{option.fullName} ({option.uniqueId})</span>;

	static filterOption = (option: PreviewVM, rawInput: string) => {
		const { fullName, uniqueId } = option;

		return `${fullName} ${uniqueId}`.toLowerCase().includes(rawInput.toLowerCase());
	};

	static filterOptions = (
		allOptions: PreviewVM[],
		selectedGroups: NotifyCompanyRequestModel['userGroups'] = [],
		selectedAccounts: PreviewVM[] = []
	) => {

		const reduceAccounts = (_acc: Record<number, true>, _account: PreviewVM) => {
			_acc[_account.id] = true;
			return _acc;
		};
		const accounts = selectedAccounts.reduce(reduceAccounts, {});

		selectedGroups.forEach((_userGroup) => _userGroup.accounts?.reduce(reduceAccounts, accounts));

		return allOptions.filter((_account) => !accounts[_account.id]);
	};

	async componentDidMount() {
		const { findAllAccountsForCompany } = this.props;
		const accounts = await findAllAccountsForCompany();
		this.setState(() => ({ accounts }), this.setOptions);
	}

	setOptions = () => {
		const { selectedUserGroups, fields } = this.props;
		this.setState((state) => ({ options: Accounts.filterOptions(state.accounts, selectedUserGroups, fields.getAll()) }));
	};

	removeAccount = (index: number) => {
		const { fields } = this.props;
		fields.remove(index);
		this.setOptions();
	};

	onChange = async (option: PreviewVM) => {
		const { fields } = this.props;
		fields.push(option);
		this.setOptions();
	};

	render() {
		const { fields, notificationPriority, fixed } = this.props;
		const { options } = this.state;

		return (
			<>
				<div>
					<Dropdown<PreviewVM>
						filterable={true}
						filterBy={Accounts.filterOption}
						fixed={fixed}
						forcePlaceholder={true}
						isWhite={true}
						label="Additional Employees"
						onValueChange={this.onChange}
						options={options}
						placeholder="Select employees"
						renderMenuItem={Accounts.renderOption}
						valueKey="id"
						withBorder={true}
						withCaret={true}
					/>
				</div>
				<div className="pills">
					{!!fields.length && fields.map((_accountFieldName, _index) => {
						const _account = fields.get(_index);
						const hasEmail = !!_account.email;
						const hasPhoneNumber = !!_account.phoneNumber;

						const willReceiveNotification =
							(notificationPriority === NotificationPriority.EMAIL_ONLY && hasEmail)
							|| (notificationPriority === NotificationPriority.SMS_ONLY && hasPhoneNumber)
							|| [NotificationPriority.EMAIL_FIRST, NotificationPriority.SMS_FIRST, NotificationPriority.SEND_BOTH].includes(notificationPriority)
							&& (hasEmail || hasPhoneNumber);

						return (
							<AccountPreview
								account={_account}
								hasEmail={hasEmail}
								index={_index}
								key={`account#${_index}`}
								removeAccount={this.removeAccount}
								willReceiveNotification={willReceiveNotification}
							/>
						);
					}
					)}
					{!fields.length &&
						<div>No additional employees selected</div>
					}
				</div>
			</>
		);
	}
}

function mapDispatchToProps() {
	return {
		findAllAccountsForCompany: AccountActions.findAllForCompanyPreview,
	};
}

const connector = connect(null, mapDispatchToProps());

export default connector(Accounts);
