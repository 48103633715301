import * as React from 'react';
import { Button } from 'react-bootstrap';
import type { WrappedFieldArrayProps} from 'redux-form';
import { Field } from 'redux-form';

import Input from 'af-fields/Input';
import Checkbox from 'af-fields/Checkbox';

const MIN_OTHER_OPTIONS = 1;
const MIN_OPTIONS = 2;

type OwnProps = {
	allowCustomDropdownListValue: boolean;
};

type Props = OwnProps & WrappedFieldArrayProps<string>;

const _getMinLength = (allowCustomDropdownListValue: boolean) => {
	return allowCustomDropdownListValue ? MIN_OTHER_OPTIONS : MIN_OPTIONS;
};

const DropdownListOptions: React.FC<Props> = (props: Props) => {
	const { fields, allowCustomDropdownListValue } = props;

	const add = React.useCallback(() => {
		fields.push('');
	}, [fields]);

	React.useEffect(() => {
		const minLength = _getMinLength(allowCustomDropdownListValue);
		for (let i = fields.length ?? 0; i < minLength; i++) {
			add();
		}
		// eslint-disable-next-line react-hooks/exhaustive-deps
	}, []);

	const renderOption = React.useCallback((_field: string, _index: number) => {
		return (
			<div className="report-block-form-field-modal__option-item" key={_index}>
				<Field
					component={Input}
					label={`Option #${_index + 1}`}
					name={_field}
					placeholder="Enter Option"
					type="text"
				/>
				{fields.length > _getMinLength(allowCustomDropdownListValue) &&
					<Button
						className="btn btn--flat btn--icon"
						onClick={fields.remove.bind(this, _index)}
					>
						<span className="icon-delete" />
					</Button>
				}
			</div>
		);
	}, [fields.length, fields.remove, allowCustomDropdownListValue]);

	return (
		<div className="report-block-form-field-modal__options">
			{fields.map(renderOption)}
			<div className="report-block-form-field-modal__option-actions">
				<Button className="btn btn--link" onClick={add}>
					<span className="icon-plus" />
					<span>Add option</span>
				</Button>
				<Field
					component={Checkbox}
					isStandalone={true}
					label="Include User-defined 'Other' option"
					name="allowCustomDropdownListValue"
				/>
			</div>
		</div>
	);
};

export type DropdownListFieldArrayOwnProps = OwnProps;

export default React.memo(DropdownListOptions);
