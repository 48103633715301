import * as React from 'react';
import { Field, FieldArray } from 'redux-form';

import ReportTypeBlockType from 'acceligent-shared/enums/reportTypeBlockType';

import { bemBlock } from 'ab-utils/bem.util';

import Checkbox from 'af-components/Fields/Checkbox';
import Tooltip from 'af-components/Tooltip';

import * as ReportTypeBuilderUtils from 'af-utils/reportTypeBuilder.util';

import ReportBlock from './Block';
import type { OwnProps as ArrayProps } from './BlockArray';
import ReportBlockArray from './BlockArray';

import type { ReportBlockFieldFormModel } from '../formModel';
import type { HighlightedBillableWorkBlockFields } from '../../ReportType/Shared/values';
import type { EquipmentListViewModel } from 'ab-viewModels/equipmentList.viewModel';

interface OwnProps {
	completionFieldId?: Nullable<string>;
	currentlyHighlightedFields?: HighlightedBillableWorkBlockFields;
	filterVisibleToCustomer: boolean;
	form: string;
	formValues?: Record<string, string>[];
	hasCompletionStatus: boolean;
	highlightVisibleToCustomer?: boolean;
	id: string;
	isInPrimarySegment?: boolean;
	isMain: boolean;
	isRepeating: boolean;
	isSegmentExpanded?: boolean;
	name: string;
	onCalculatedFieldChange: (id: string, isRepeating: boolean) => void;
	onCompletionFieldChange?: (id: string, value: boolean) => void;
	onFieldValueChange?: (id: string, value: string, index: number) => void;
	onRemoveRepeatableFields?: (fields: Record<string, true>, index: number) => void;
	onToggleSegment?: () => void;
	reportBlockFieldIds: Nullable<string[]>;
	reportFieldsByIdMap: { [id: string]: ReportBlockFieldFormModel; };
	type?: ReportTypeBlockType;
	listOfEquipment?: Nullable<EquipmentListViewModel>;
}

type Props = OwnProps;

const _fieldMapReducer = (acc: Record<string, true>, id: string) => {
	acc[id] = true;
	return acc;
};

const ReportBlockPreview: React.FC<Props> = (props: Props) => {
	const {
		completionFieldId,
		currentlyHighlightedFields = {},
		filterVisibleToCustomer,
		formValues,
		hasCompletionStatus,
		highlightVisibleToCustomer = false,
		id,
		isInPrimarySegment = false,
		isMain,
		isRepeating,
		isSegmentExpanded = true,
		name,
		onCalculatedFieldChange,
		onCompletionFieldChange,
		onFieldValueChange,
		listOfEquipment,
		onRemoveRepeatableFields,
		onToggleSegment,
		reportBlockFieldIds,
		reportFieldsByIdMap,
		type,
	} = props;

	const onRemoveFields = React.useCallback((index: number) => {
		if (onRemoveRepeatableFields) {
			onRemoveRepeatableFields((reportBlockFieldIds ?? []).reduce(_fieldMapReducer, {}), index);
		}
	}, [reportBlockFieldIds, onRemoveRepeatableFields]);

	const onCalculationChange = React.useCallback((fieldId: string) => {
		onCalculatedFieldChange(fieldId, isRepeating);
	}, [onCalculatedFieldChange, isRepeating]);

	const onCompletionChange = React.useCallback((isChecked: boolean) => {
		if (completionFieldId && !!onCompletionFieldChange) {
			onCompletionFieldChange(completionFieldId, isChecked);
		} else {
			throw new Error('Trying to change completion status but it does not exist'); // Should never happen
		}
	}, [completionFieldId, onCompletionFieldChange]);

	const renderCollapseIcon = () => {
		return (
			<Tooltip message={`${isSegmentExpanded ? 'Hide' : 'Show'} Segment`}>
				<div className="btn btn--icon btn-info btn--small">
					<span className={`icon-${isSegmentExpanded ? 'up' : 'down'}`} onClick={onToggleSegment} />
				</div>
			</Tooltip>
		);
	};

	const renderTitle = () => {
		const className = bemBlock('report-block__title', {
			main: isMain || type === ReportTypeBlockType.TOTAL,
			collapsed: !isSegmentExpanded,
		});

		return (
			<div className={className}>
				<span className="report-block__title-name">
					<span>{name}</span>
				</span>
				{isMain && renderCollapseIcon()}
			</div>
		);
	};

	const renderFields = () => {
		const _fieldName = `${ReportTypeBuilderUtils.BLOCK_PREFIX}#${id}.values`;

		return (
			<div className="report-block__field-group">
				<div className="report-block__fields">
					{(isRepeating)
						? (
							<FieldArray<ArrayProps>
								component={ReportBlockArray}
								currentlyHighlightedFields={currentlyHighlightedFields}
								filterVisibleToCustomer={filterVisibleToCustomer}
								formValues={formValues}
								highlightVisibleToCustomer={highlightVisibleToCustomer}
								listOfEquipment={listOfEquipment}
								name={_fieldName}
								onCalculatedFieldChange={onCalculationChange}
								onFieldValueChange={onFieldValueChange}
								onRemoveRepeatableFields={onRemoveFields}
								reportBlockFieldIds={reportBlockFieldIds}
								reportFieldsByIdMap={reportFieldsByIdMap}
							/>
						)
						: (
							<ReportBlock
								currentlyHighlightedFields={currentlyHighlightedFields}
								filterVisibleToCustomer={filterVisibleToCustomer}
								formField={_fieldName}
								formValues={formValues}
								highlightVisibleToCustomer={highlightVisibleToCustomer}
								listOfEquipment={listOfEquipment}
								onCalculatedFieldChange={onCalculationChange}
								onFieldValueChange={onFieldValueChange}
								reportBlockFieldIds={reportBlockFieldIds}
								reportFieldsByIdMap={reportFieldsByIdMap}
							/>
						)
					}
					{
						hasCompletionStatus &&
						completionFieldId &&
						<Field
							component={Checkbox}
							extraClass="report-block__completion-status"
							inline={true}
							isStandalone={true}
							label="Completed"
							name={ReportTypeBuilderUtils.getCompletionFieldName(id, completionFieldId)}
							onValueChange={onCompletionChange}
						/>
					}
				</div>
			</div>
		);
	};

	const isEmpty = !reportBlockFieldIds?.length;
	const showFields = isSegmentExpanded;
	const showTitle = (isMain || showFields) && !isEmpty;
	const className = bemBlock('report-block', { collapsed: !isSegmentExpanded, inline: !isMain, secondary: !isInPrimarySegment, primary: !!isInPrimarySegment, small: true });

	return (
		<div className={className}>
			{isEmpty &&
				<div className="report-block__empty-preview">Add fields to see preview of your Report</div>
			}
			{showTitle && renderTitle()}
			{showFields && renderFields()}
		</div>
	);
};

export default React.memo(ReportBlockPreview);
