import * as React from 'react';
import type { HandleThunkActionCreator } from 'react-redux';
import type { InjectedFormProps } from 'redux-form';
import { reduxForm } from 'redux-form';

import type DivisionRequestModel from 'ab-requestModels/division.requestModel';

import type * as DivisionActions from 'af-actions/division';

import { DIVISION_CREATE } from 'af-constants/reduxForms';

import DivisionForm from 'af-components/SharedForms/Resources/Division';
import { validate } from 'af-components/SharedForms/Resources/Division/validation';

interface OwnProps {
	createDivision: HandleThunkActionCreator<typeof DivisionActions.create>;
	close: () => void;
}

type Props = OwnProps & InjectedFormProps<DivisionRequestModel, OwnProps>;

class DivisionCreate extends React.PureComponent<Props> {
	createDivision = async (form: DivisionRequestModel) => {
		const { close, createDivision } = this.props;
		await createDivision(form);
		close();
	};

	render() {
		return <DivisionForm onSubmit={this.createDivision} {...this.props} />;
	}
}

export default reduxForm<DivisionRequestModel, OwnProps>({ form: DIVISION_CREATE, validate, enableReinitialize: true })(DivisionCreate);
