import * as React from 'react';
import { compose } from 'redux';
import type { ConnectedProps } from 'react-redux';
import { connect } from 'react-redux';
import { useHistory, useParams } from 'react-router';
import { useLocation } from 'react-router-dom-v5-compat';

import * as SettingsUtils from 'af-utils/settings.util';

import * as SettingsKeys from 'af-constants/settingsKeys';

import CLIENT from 'af-routes/client';

import { isAllowed } from 'ab-utils/auth.util';

import PagePermissions from 'ab-enums/pagePermissions.enum';
import BrowserStorageEnum from 'ab-enums/browserStorage.enum';

import DisabledFeatures from 'ab-common/environment/disabledFeatures';

import Breadcrumbs from 'af-components/Breadcrumbs';
import TabNavigation from 'af-components/TabNavigation';

import type { RootState } from 'af-reducers';

import TruckTable from '../Equipment/Table';
import ItemTable from '../Item/Table';
import ToolTable from '../Tool/List';

const BREADCRUMBS = [{ label: 'Equipment' }];

const TAB_IDS = {
	EQUIPMENT: 0,
	SMALL_TOOL: 1,
	ACCESSORY: 2,
};

enum TAB_KEYS {
	EQUIPMENT = 'equipment',
	SMALL_TOOL = 'small-tool',
	ACCESSORY = 'accessory',
}

const TABS = [
	{ id: TAB_IDS.EQUIPMENT, label: 'Equipment' },
	{ id: TAB_IDS.SMALL_TOOL, label: 'Small tools' },
	{ id: TAB_IDS.ACCESSORY, label: 'Accessories' },
];

const TAB_MAP = {
	[TAB_IDS.EQUIPMENT]: TAB_KEYS.EQUIPMENT,
	[TAB_IDS.SMALL_TOOL]: TAB_KEYS.SMALL_TOOL,
	[TAB_IDS.ACCESSORY]: TAB_KEYS.ACCESSORY,
} as const;

const ID_MAP = {
	[TAB_KEYS.EQUIPMENT]: TAB_IDS.EQUIPMENT,
	[TAB_KEYS.SMALL_TOOL]: TAB_IDS.SMALL_TOOL,
	[TAB_KEYS.ACCESSORY]: TAB_IDS.ACCESSORY,
} as const;

interface SettingProps {
	tabId: number;
}

type Props = SettingProps & ConnectedProps<typeof connector>;

const EquipmentList: React.FC<Props> = (props) => {

	const {
		hasManageEquipmentPermission,
		hasViewSmallToolsPermission,
		hasViewAccessoriesPermission,
		companyName,
	} = props;

	const { tab } = useParams<{ tab?: keyof typeof ID_MAP; }>();
	const location = useLocation();
	const history = useHistory();

	const [disableTab] = React.useState(DisabledFeatures.Inventory);

	React.useEffect(() => {
		let defaultTab = TAB_IDS.ACCESSORY;

		if (!hasViewAccessoriesPermission) {
			defaultTab = TAB_IDS.EQUIPMENT;
			if (!hasManageEquipmentPermission) {
				defaultTab = TAB_IDS.SMALL_TOOL;
			}
		}

		// If the URL doesn't have a tab or it's invalid, replace with a valid default
		if (
			!tab
			|| !(tab in ID_MAP)
			|| (tab === TAB_KEYS.EQUIPMENT && !hasManageEquipmentPermission)
			|| (tab === TAB_KEYS.SMALL_TOOL && !hasViewSmallToolsPermission)
			|| (tab === TAB_KEYS.ACCESSORY && !hasViewAccessoriesPermission)
		) {
			history.replace(`${CLIENT.COMPANY.EQUIPMENT.LIST(location.state.orgAlias, companyName)}/${TAB_MAP[defaultTab]}`);
		}
	}, [hasManageEquipmentPermission, tab, history, location.state.orgAlias, companyName, hasViewAccessoriesPermission, hasViewSmallToolsPermission]);

	const activeTabId = React.useMemo(() => tab && tab in ID_MAP ? ID_MAP[tab] : TAB_IDS.EQUIPMENT, [tab]);

	const tabs = React.useMemo(() => (TABS.map((_tab) => {
		return {
			..._tab,
			hide: _tab.id === TAB_IDS.SMALL_TOOL && !hasViewSmallToolsPermission
				|| _tab.id === TAB_IDS.ACCESSORY && !hasViewAccessoriesPermission
				|| _tab.id === TAB_IDS.EQUIPMENT && !hasManageEquipmentPermission,
		};
	})), [hasManageEquipmentPermission, hasViewAccessoriesPermission, hasViewSmallToolsPermission]);

	const selectTab = React.useCallback((tabId: number) => {
		history.replace(`${CLIENT.COMPANY.EQUIPMENT.LIST(location.state.orgAlias, companyName)}/${TAB_MAP[tabId]}`);

		SettingsUtils.setItem(SettingsKeys.INVENTORY_TAB(), `${tabId}`, BrowserStorageEnum.LOCAL_STORAGE);
	}, [companyName, history, location.state.orgAlias]);

	const renderTab = React.useCallback(() => {
		switch (activeTabId) {
			case TAB_IDS.SMALL_TOOL:
				return <ToolTable />;
			case TAB_IDS.ACCESSORY:
				return <ItemTable />;
			case TAB_IDS.EQUIPMENT:
			default:
				return <TruckTable />;
		}
	}, [activeTabId]);

	return (
		<div>
			<Breadcrumbs items={BREADCRUMBS} />
			<div className="table-container table-container--tabbed">
				<div>
					{!disableTab && (
						<TabNavigation
							active={activeTabId}
							onClick={selectTab}
							tabs={tabs}
						/>
					)}
					{renderTab()}
				</div>
			</div>
		</div>
	);
};

function mapStateToProps(state: RootState) {
	const { userData, companyData } = state.user;
	if (!userData || !companyData) {
		throw new Error('User not logged in');
	}

	const hasManageEquipmentPermission: boolean = isAllowed(
		PagePermissions.COMPANY.EQUIPMENT.MANAGE,
		companyData.permissions,
		companyData.isCompanyAdmin,
		userData.role
	);

	const hasViewSmallToolsPermission: boolean = isAllowed(
		PagePermissions.COMPANY.EQUIPMENT.SMALL_TOOLS.VIEW,
		companyData.permissions,
		companyData.isCompanyAdmin,
		userData.role
	);

	const hasViewAccessoriesPermission: boolean = isAllowed(
		PagePermissions.COMPANY.EQUIPMENT.ACCESSORIES.VIEW,
		companyData.permissions,
		companyData.isCompanyAdmin,
		userData.role
	);

	return {
		hasManageEquipmentPermission,
		hasViewSmallToolsPermission,
		hasViewAccessoriesPermission,
		companyName: companyData.name,
	};
}

const connector = connect(mapStateToProps);

const enhance = compose<React.ComponentClass>(
	connector,
	SettingsUtils.withSettings<SettingProps>(() => ([
		{
			key: SettingsKeys.INVENTORY_TAB(),
			mappedName: 'tabId',
			normalize: (item: string) => DisabledFeatures.Inventory ? TAB_IDS.EQUIPMENT : +item, // override value if tabs are disabled
			defaultValue: TAB_IDS.EQUIPMENT,
			source: BrowserStorageEnum.LOCAL_STORAGE,
		},
	])));

export default enhance(EquipmentList);
