import type { TableQuery } from 'ab-common/dataStructures/tableQuery';
import baseRoute from '../baseRoute';

export * from './viewModel';
export * from './queryParams';

type RouteDefinition = `${typeof baseRoute}/workSummary${`?query=${string}&startDate=${string}&endDate=${string}` | ''}`;

export const ROUTE: RouteDefinition = `${baseRoute}/workSummary`;

export const URL = (startDate: string, endDate: string, tableQuery: TableQuery): RouteDefinition =>
	`${baseRoute}/workSummary?query=${encodeURIComponent((JSON.stringify(tableQuery)))}&startDate=${startDate}&endDate=${endDate}`;
