import * as React from 'react';
import { Route, Routes } from 'react-router-dom-v5-compat';

import Error404 from 'af-root/scenes/Error/error404';

import WorkRequestDetailsTab from './Details/index';
import JobHazardAssessmentFormTab from './JobHazardAssessment/index';
import AttachmentsFormTab from './Attachments/index';

import WorkRequestForm from './index';
import { WorkRequestFormTabRoutes } from './types';

const WorkRequestFormRouter: React.FC = () => {
	return (
		<Routes>
			<Route
				element={<WorkRequestForm />}
			>
				<Route element={<WorkRequestDetailsTab />} index />
				<Route element={<WorkRequestDetailsTab />} path={`/${WorkRequestFormTabRoutes.DETAILS}`} />
				<Route element={<JobHazardAssessmentFormTab />} path={`/${WorkRequestFormTabRoutes.JOB_HAZARD_ASSESSMENT}`} />
				<Route element={<AttachmentsFormTab />} path={`/${WorkRequestFormTabRoutes.ATTACHMENTS}`} />
			</Route>
			<Route element={<Error404 />} path="*" />
		</Routes>
	);

};

export default React.memo(WorkRequestFormRouter);
