import * as React from 'react';
import type { CustomRouteComponentProps } from 'react-router-dom';
import type { ConnectedProps } from 'react-redux';
import { connect } from 'react-redux';
import { Row, Col } from 'react-bootstrap';

import type { WageRateVM } from 'ab-viewModels/wageRate/wageRate.viewModel';

import CLIENT from 'af-constants/routes/client';

import type { RootState } from 'af-reducers';

import * as WageRateActions from 'af-actions/wageRate';

import LockedValue from 'af-components/LockedValue';

import * as FormattingUtils from 'ab-utils/formatting.util';

import ResourcePreview from '../../Shared/ResourcePreview';
import Loading from './Loading';

interface PathParams {
	id: string;
}

type OwnProps = CustomRouteComponentProps<PathParams>;

type Props = OwnProps & ConnectedProps<typeof connector>;

interface State {
	wageRate: Nullable<WageRateVM>;
}

class PreviewWageRate extends React.PureComponent<Props, State> {
	state: State = {
		wageRate: null,
	};

	async componentDidUpdate() {
		const { match: { params: { id } }, findById } = this.props;
		const { wageRate } = this.state;

		if (wageRate?.id !== +id) {
			const _wageRate = await findById(id);
			this.setState(() => ({ wageRate: _wageRate }));
		}
	}

	render() {
		const { companyName, match: { params: { id } }, location: { state: { orgAlias } } } = this.props;
		const { wageRate } = this.state;

		const breadcrumbs = [
			{ label: 'Wage Rates', url: CLIENT.COMPANY.RESOURCES.WAGE_RATE.LIST(orgAlias, companyName) },
			{ label: 'Preview' },
		];

		if (!wageRate || wageRate.id !== +id) {
			return (
				<Loading
					backToListLabel="Back"
					backToListUrl={CLIENT.COMPANY.RESOURCES.WAGE_RATE.LIST(orgAlias, companyName)}
					breadcrumbs={breadcrumbs}
					isSmall={true}
				/>
			);
		}

		return (
			<ResourcePreview
				backToListLabel="Back to Wage rate List"
				backToListUrl={CLIENT.COMPANY.RESOURCES.WAGE_RATE.LIST(orgAlias, companyName)}
				breadcrumbs={breadcrumbs}
				editUrl={CLIENT.COMPANY.RESOURCES.WAGE_RATE.EDIT(id.toString(), orgAlias, companyName)}
			>
				<Row className="row--padded">
					<Col sm={12}>
						<LockedValue defaultValue="N/A" label="Labor Type" value={wageRate.type} />
					</Col>
					<Col sm={12}>
						<LockedValue defaultValue="N/A" label="Wage Classification" value={wageRate.wageClassification} />
					</Col>
				</Row>
				<Row>
					<Col sm={24}>
						<LockedValue defaultValue="N/A" label="Hourly Rate" value={FormattingUtils.moneyNormalizer(wageRate.hourlyRate)} />
					</Col>
				</Row>
			</ResourcePreview>
		);
	}
}

function mapStateToProps(state: RootState) {
	const { companyData } = state.user;
	if (!companyData) {
		throw new Error('User not logged in');
	}

	return {
		companyName: companyData.name,
	};
}

function mapDispatchToProps() {
	return {
		findById: WageRateActions.findById,
	};
}

const connector = connect(mapStateToProps, mapDispatchToProps());

export default connector<React.ComponentClass<OwnProps>>(PreviewWageRate);
