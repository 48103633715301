import * as React from 'react';
import type { ConnectedProps } from 'react-redux';
import { connect } from 'react-redux';
import type { CustomRouteComponentProps } from 'react-router-dom';
import { compose } from 'redux';

import type AgencyVM from 'ab-viewModels/agency/agency.viewModel';
import type TemporaryEmployeeVM from 'ab-viewModels/temporaryEmployee/temporaryEmployee.viewModel';

import type { TableQuery } from 'ab-common/dataStructures/tableQuery';

import TableNameEnum from 'ab-enums/tableName.enum';

import * as AgencyActions from 'af-actions/agency';
import * as TemporaryEmployeeActions from 'af-actions/temporaryEmployee';
import * as WorkOrderActions from 'af-actions/workOrder';

import Breadcrumbs from 'af-components/Breadcrumbs';

import type { RootState } from 'af-reducers';

import RelatedWorkOrders from '../../Shared/RelatedWorkOrders';

import TemporaryEmployee from './TemporaryEmployee';
import Loading from './Loading';

interface PathParams {
	temporaryEmployeeId: string;
}

type OwnProps = CustomRouteComponentProps<PathParams>;

type Props = OwnProps & ConnectedProps<typeof connector>;

const PreviewTemporaryEmployee: React.FC<Props> = (props: Props) => {

	const {
		history,
		location: { state: { orgAlias } },
		companyName,
		findAgencyById,
		findEmployeeById,
		match: { params: { temporaryEmployeeId } },
		findWorkOrdersByTemporaryEmployeeId,
	} = props;

	const [
		agency,
		setAgency,
	] = React.useState<Nullable<AgencyVM>>(null);

	const [
		tempEmp,
		setTempEmp,
	] = React.useState<Nullable<TemporaryEmployeeVM>>(null);

	React.useEffect(() => {
		findEmployee();
		// eslint-disable-next-line react-hooks/exhaustive-deps
	}, []);

	React.useEffect(() => {
		if (tempEmp) {
			findAgency();
		}
		// eslint-disable-next-line react-hooks/exhaustive-deps
	}, [tempEmp]);

	const findAgency = async () => {
		if (!tempEmp) {
			throw new Error('Can\'t find agency for undefiend temporary employee');
		}
		const fetchedAgency = await findAgencyById(tempEmp.agencyId);

		if (!fetchedAgency) {
			throw new Error(`Agency with id ${tempEmp.agencyId} not found`);
		}
		setAgency(fetchedAgency);
	};

	const findEmployee = async () => {
		const fetchedEmp = await findEmployeeById(+temporaryEmployeeId);

		if (!fetchedEmp) {
			throw new Error(`Agency with id ${temporaryEmployeeId} not found`);
		}
		setTempEmp(fetchedEmp);
	};

	const fetchWorkOrders = React.useCallback(async (tableRequestModel: TableQuery) => {
		return await findWorkOrdersByTemporaryEmployeeId(+temporaryEmployeeId, tableRequestModel);
	}, [findWorkOrdersByTemporaryEmployeeId, temporaryEmployeeId]);

	return (
		<>
			{tempEmp ?
				<TemporaryEmployee
					agency={agency}
					companyName={companyName}
					employee={tempEmp}
					orgAlias={orgAlias}
				/>
				: <Loading />
			}
			<div className="form-segment">
				<Breadcrumbs items={[{ label: 'Related Work Orders' }]} />
				<RelatedWorkOrders
					companyName={companyName}
					csvName={`${companyName}_${tempEmp?.uniqueId}_work_orders.csv`}
					fetchWorkOrders={fetchWorkOrders}
					history={history}
					orgAlias={orgAlias}
					tableName={TableNameEnum.TEMPORARY_EMPLOYEE_WORK_ORDERS}
				/>
			</div>
		</>
	);
};

function mapStateToProps(state: RootState) {
	const { companyData } = state.user;
	if (!companyData) {
		throw new Error('User not logged in');
	}

	return {
		companyName: companyData.name,
	};
}

function mapDispatchToProps() {
	return {
		findAgencyById: AgencyActions.findById,
		findEmployeeById: TemporaryEmployeeActions.findById,
		findWorkOrdersByTemporaryEmployeeId: WorkOrderActions.findWorkOrdersByTemporaryEmployeeId,
	};
}

const connector = connect(mapStateToProps, mapDispatchToProps());

const enhance = compose<React.ComponentClass<OwnProps>>(
	connector,
	React.memo
);

export default enhance(PreviewTemporaryEmployee);
