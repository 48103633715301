import type * as FieldReportSyncVMs from 'acceligent-shared/dtos/socket/view/newFieldReport/sync';

import * as actionTypes from 'af-constants/actionTypes';

import type FieldReportVM from 'ab-viewModels/fieldReport/fieldReport.viewModel';
import type SocketConnectionCount from 'ab-viewModels/socketConnectionCount.viewModel';

import type { LockFieldReportBlockVM } from 'ab-socketModels/viewModels/fieldReport/lockFieldReportBlock.viewModel';
import type * as FieldReportSocketVM from 'ab-socketModels/viewModels/fieldReport/syncFieldReport.viewModel';

interface SetFieldReportBlockLocksAction {
	type: typeof actionTypes.SET_FIELD_REPORT_BLOCK_LOCKS;
	payload: Record<string, LockFieldReportBlockVM>;
}
export function SET_FIELD_REPORT_BLOCK_LOCK(lockData: Record<string, LockFieldReportBlockVM>): SetFieldReportBlockLocksAction {
	return {
		type: actionTypes.SET_FIELD_REPORT_BLOCK_LOCKS,
		payload: lockData,
	};
}

interface RemoveFieldReportBlockLockAction {
	type: typeof actionTypes.REMOVE_FIELD_REPORT_BLOCK_LOCK;
	payload: string;
}
export function REMOVE_FIELD_REPORT_BLOCK_LOCK(fieldReportBlockId: string): RemoveFieldReportBlockLockAction {
	return {
		type: actionTypes.REMOVE_FIELD_REPORT_BLOCK_LOCK,
		payload: fieldReportBlockId,
	};
}

interface SetNewFieldReportAction {
	type: typeof actionTypes.SET_FIELD_REPORT_NEW;
	payload: Nullable<FieldReportVM>;
}
export function SET_FIELD_REPORT_NEW(fieldReport: FieldReportVM | undefined): SetNewFieldReportAction {
	return {
		type: actionTypes.SET_FIELD_REPORT_NEW,
		payload: fieldReport ?? null,
	};
}

interface SetFieldReportBlockValueActionNew {
	type: typeof actionTypes.SET_FIELD_REPORT_BLOCK_VALUE_NEW;
	payload: FieldReportSyncVMs.SyncBlock;
}
export function SET_FIELD_REPORT_BLOCK_VALUE_NEW(fieldReportBlock: FieldReportSyncVMs.SyncBlock): SetFieldReportBlockValueActionNew {
	return {
		type: actionTypes.SET_FIELD_REPORT_BLOCK_VALUE_NEW,
		payload: fieldReportBlock,
	};
}

interface AddInstanceAction {
	type: typeof actionTypes.ADD_INSTANCE;
	payload: FieldReportSyncVMs.SyncAddInstance;
}
export function ADD_INSTANCE(fieldReportInstance: FieldReportSyncVMs.SyncAddInstance): AddInstanceAction {
	return {
		type: actionTypes.ADD_INSTANCE,
		payload: fieldReportInstance,
	};
}

interface AddSegmentAction {
	type: typeof actionTypes.ADD_SEGMENT;
	payload: FieldReportSyncVMs.SyncAddSegment;
}
export function ADD_SEGMENT(fieldReportSegment: FieldReportSyncVMs.SyncAddSegment): AddSegmentAction {
	return {
		type: actionTypes.ADD_SEGMENT,
		payload: fieldReportSegment,
	};
}

interface RemoveInstanceAction {
	type: typeof actionTypes.REMOVE_INSTANCE;
	payload: FieldReportSocketVM.RemoveInstance;
}
export function REMOVE_INSTANCE(fieldReportInstance: FieldReportSyncVMs.SyncRemoveInstance): RemoveInstanceAction {
	return {
		type: actionTypes.REMOVE_INSTANCE,
		payload: fieldReportInstance,
	};
}

interface RemoveSegmentAction {
	type: typeof actionTypes.REMOVE_SEGMENT;
	payload: FieldReportSocketVM.RemoveSegment;
}
export function REMOVE_SEGMENT(fieldReportSegment: FieldReportSyncVMs.SyncRemoveSegment): RemoveSegmentAction {
	return {
		type: actionTypes.REMOVE_SEGMENT,
		payload: fieldReportSegment,
	};
}

interface SetConnectionCountAction {
	type: typeof actionTypes.SET_CONNECTION_COUNT;
	payload: SocketConnectionCount;
}

export function SET_CONNECTION_COUNT(connectionCount: SocketConnectionCount): SetConnectionCountAction {
	return {
		type: actionTypes.SET_CONNECTION_COUNT,
		payload: connectionCount,
	};
}

interface SetFieldReportTypesAction {
	type: typeof actionTypes.SET_FIELD_REPORT_TYPES;
	payload: FieldReportSyncVMs.SyncFieldReportTypes;
}
export function SET_FIELD_REPORT_TYPES(fieldReportTypes: FieldReportSyncVMs.SyncFieldReportTypes): SetFieldReportTypesAction {
	return {
		type: actionTypes.SET_FIELD_REPORT_TYPES,
		payload: fieldReportTypes,
	};
}
interface SetActiveSearchItemIndexAction {
	type: typeof actionTypes.SET_FR_ACTIVE_SEARCH_ITEM_INDEX;
	payload: number;
}
export function SET_FR_ACTIVE_SEARCH_ITEM_INDEX(payload: number): SetActiveSearchItemIndexAction {
	return {
		type: actionTypes.SET_FR_ACTIVE_SEARCH_ITEM_INDEX,
		payload,
	};
}

export interface SetFieldReportBlockCompleted {
	type: typeof actionTypes.SET_FR_BLOCK_COMPLETED;
	payload: FieldReportSyncVMs.SyncBlockCompletedStatus;
}
export function SET_FIELD_REPORT_BLOCK_COMPLETED(event: FieldReportSyncVMs.SyncBlockCompletedStatus): SetFieldReportBlockCompleted {
	return {
		type: actionTypes.SET_FR_BLOCK_COMPLETED,
		payload: event,
	};
}

interface SetSegmentDeletingPayload {
	instanceIndex: number;
	segmentIndex: number;
}
export interface SetSegmentDeleting {
	type: typeof actionTypes.SET_SEGMENT_DELETING;
	payload: SetSegmentDeletingPayload;
}
export function SET_SEGMENT_DELETING(event: SetSegmentDeletingPayload): SetSegmentDeleting {
	return {
		type: actionTypes.SET_SEGMENT_DELETING,
		payload: event,
	};
}

export type FieldReportAction = (
	SetNewFieldReportAction
	| SetFieldReportBlockLocksAction | RemoveFieldReportBlockLockAction
	| AddInstanceAction | AddSegmentAction | RemoveInstanceAction | RemoveSegmentAction
	| SetConnectionCountAction
	| SetFieldReportTypesAction
	| SetActiveSearchItemIndexAction
	| SetFieldReportBlockCompleted
	| SetFieldReportBlockValueActionNew
	| SetSegmentDeleting
);
