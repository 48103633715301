import * as React from 'react';
import { nanoid } from 'nanoid';

import type { PhoneTypes, EmailTypes } from 'acceligent-shared/enums/contactMethodType';
import { PhoneTypeNames } from 'acceligent-shared/enums/contactMethodType';
import CountryCode from 'acceligent-shared/enums/countryCode';

import { formatPhoneNumber } from 'ab-utils/phone.util';

import { UNIQUE_ID_SIZE } from 'ab-common/constants/value';

import CopyValue from './Shared/CopyValue';

interface OwnProps {
	value: string;
	type: PhoneTypes | EmailTypes;
	showCopyToClipboard?: boolean;
	countryCode?: CountryCode;
	isNew?: boolean;
}

type Props = OwnProps;

interface State {
	/** html tag id used for copying value to clipboard */
	id: string;
}

class ContactEmailPreview extends React.PureComponent<Props, State> {
	static defaultProps: Partial<Props> = {
		showCopyToClipboard: false,
		countryCode: CountryCode.US,
		isNew: false,
	};

	state: State = {
		id: nanoid(UNIQUE_ID_SIZE),
	};

	render() {
		const { value, type, showCopyToClipboard, countryCode, isNew } = this.props;
		const { id } = this.state;
		return (
			<div className="contact-preview__method" id={id}>
				<span className="label label-default contact-preview__method-type">{PhoneTypeNames[type]}</span>
				<span className="contact-preview__method-value">{formatPhoneNumber(value, countryCode ?? null)}</span>
				{showCopyToClipboard && <CopyValue parentId={id} value={value} />}
				{isNew && <span className="contact-preview__new">NEW</span>}
			</div>
		);
	}
}

export default ContactEmailPreview;
