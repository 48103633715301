import type { FormattedAddress } from 'af-actions/addresses';

class AddressFormModel {
	aa1: string;
	aa2: string;
	aa3: string;
	country: string;
	latitude: number;
	longitude: number;
	locality: string;
	postalCode: string;
	route: string;
	street: string;
	streetNumber: string;

	constructor(address: FormattedAddress, longitude: number, latitude: number) {
		this.aa1 = address.aa1 ?? '';
		this.aa2 = address.aa2 ?? '';
		this.aa3 = address.aa3 ?? '';
		this.country = address.country ?? '';
		this.latitude = latitude;
		this.longitude = longitude;
		this.locality = address.locality ?? '';
		this.postalCode = address.postalCode ?? '';
		this.route = address.route ?? '';
		this.street = address.formattedAddress;
		this.streetNumber = address.streetNumber ?? '';
	}
}

export default AddressFormModel;
