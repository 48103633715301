import { TableContent } from 'ab-common/dataStructures/tableContent';

import { stateAbbreviation } from 'ab-enums/states.enum';

import type PrevailingWagesTableBase from 'ab-domain/views/prevailingWagesTable/base';

export class W_Accounting_FindPrevailingWagesTableWithClassificationCode_VM
	extends TableContent<W_Accounting_FindPrevailingWagesTableWithClassificationCode_VM_Row>{
	constructor(prevailingWageRow: PrevailingWagesTableBase[], columns: string[], pages: number, totalCount: number) {
		super(
			W_Accounting_FindPrevailingWagesTableWithClassificationCode_VM_Row.bulkConstructor(prevailingWageRow, columns),
			pages,
			totalCount
		);
	}
}

class W_Accounting_FindPrevailingWagesTableWithClassificationCode_VM_Row {
	jobCode: string;
	jobState: Nullable<string>;
	classificationCodeMap: W_Accounting_FindPrevailingWagesTableWithClassificationCode_VM_ClassificationCodeMap;
	/** Will always be one element for exception report */
	columns: string[];

	constructor(row: PrevailingWagesTableBase, columns: string[]) {
		this.jobCode = `${row.jobCode} ${stateAbbreviation[row.locationState] ?? ''}`;
		this.jobState = stateAbbreviation[row.jobState];
		this.classificationCodeMap = row.classificationCodeMap;
		this.columns = columns;
	}

	static bulkConstructor(rows: PrevailingWagesTableBase[], columns: string[]) {
		return rows.map((_row) => new W_Accounting_FindPrevailingWagesTableWithClassificationCode_VM_Row(_row, columns));
	}
}

interface W_Accounting_FindPrevailingWagesTableWithClassificationCode_VM_ClassificationCodeMap {
	[code: string]: string[];
}
