import * as React from 'react';
import type { WrappedFieldArrayProps } from 'redux-form';

import type ContactMethodViewModel from 'ab-viewModels/contactMethod.viewModel';
import EmailRadioButton from 'af-components/SharedForms/Contacts/ContactMethodPicker/EmailRadioButton';

import type { Props as ContactMethodPickerProps } from '.';

type Props = ContactMethodPickerProps & WrappedFieldArrayProps<number>;

interface State {
	selectedEmailId: Nullable<number>;
}

export default class EmailRadioButtonsContainer extends React.PureComponent<Props, State> {
	state = {
		selectedEmailId: null,
	};

	static getDerivedStateFromProps(props: Props): State {
		const { fields } = props;
		const selectedEmailId = fields.length > 0 ? fields.get(0) : null;
		return { selectedEmailId };
	}

	componentDidMount() {
		const { contact: { emails }, fields } = this.props;

		if (fields.length > 1) {
			const emailId = emails[0].id;
			fields.removeAll();
			fields.push(emailId);
		}
	}

	onSelect = async (email: ContactMethodViewModel) => {
		const { fields } = this.props;
		fields.removeAll();
		fields.push(email.id);
	};

	renderEmailRadioButton = (email: ContactMethodViewModel) => {
		const { disabled } = this.props;
		const { selectedEmailId } = this.state;

		return (
			<EmailRadioButton
				disabled={disabled}
				email={email}
				isSelected={email.id === selectedEmailId}
				key={email.id}
				onSelect={this.onSelect}
			/>
		);
	};

	render() {
		const { contact: { emails }, meta: { error } } = this.props;

		return (
			<>
				{emails?.map(this.renderEmailRadioButton)}
				{error && <span className="help-block"><span className="icon-info" /> {error}</span>}
			</>
		);
	}
}
