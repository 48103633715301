import * as React from 'react';
import { Route, Routes } from 'react-router-dom-v5-compat';

import Details from 'af-components/SharedForms/Job/Details';
import BillingCodes from 'af-components/SharedForms/Job/BillingCodes';
import JobHazardAssessment from 'af-components/SharedForms/Job/JobHazardAssessment';
import AttachementsTab from 'af-components/SharedForms/Job/AttachementsTab';

import Error404 from 'af-root/scenes/Error/error404';

import JobEdit from './index';
import { JobEditTabRoutes } from './types';

const JobEditRouter: React.FC = () => {
	return (
		<Routes>
			<Route
				element={<JobEdit />}
			>
				<Route element={<Details />} index />
				<Route element={<Details />} path={JobEditTabRoutes.DETAILS} />
				<Route element={<BillingCodes />} path={JobEditTabRoutes.BILLING_CODES} />
				<Route element={<JobHazardAssessment />} path={JobEditTabRoutes.JOB_HAZARD_ASSESSMENT} />
				<Route element={<AttachementsTab />} path={JobEditTabRoutes.ATTACHMENTS} />
			</Route>
			<Route element={<Error404 />} path="*" />
		</Routes>
	);
};

export default React.memo(JobEditRouter);
