import { TableContent } from 'ab-common/dataStructures/tableContent';

import type ToolStatus from 'acceligent-shared/enums/toolStatus';
import type ToolState from 'acceligent-shared/enums/toolState';
import TimeFormat from 'acceligent-shared/enums/timeFormat';

import * as TimeUtil from 'acceligent-shared/utils/time';

import type ToolTrackingRecordBase from 'ab-domain/models/toolTrackingRecord/base';

export class W_SmallTool_FindAllToolTrackingRecords_VM extends TableContent<W_SmallTool_FindAllToolTrackingRecords_VM_Item>{
	constructor(records: ToolTrackingRecordBase[], pages: number, totalCount: number) {
		super(
			W_SmallTool_FindAllToolTrackingRecords_VM_Item.bulkConstructor(records),
			pages,
			totalCount
		);
	}
}

class W_SmallTool_FindAllToolTrackingRecords_VM_Item {
	id: number;
	toolId: number;
	toolRepairId: Nullable<number>;
	equipmentId: Nullable<number>;
	locationId: Nullable<number>;
	status: ToolStatus;
	/** MM-DD-YYYY */
	validFrom: string;
	/** MM-DD-YYYY */
	validTo: Nullable<string>;
	locationName: string;
	trackingNumber: Nullable<string>;
	reason: Nullable<string>;
	state: ToolState;

	constructor(record: ToolTrackingRecordBase) {
		this.id = record.id;
		this.toolId = record.toolId;
		this.toolRepairId = record.toolRepairId;
		this.equipmentId = record.equipmentId;
		this.locationId = record.locationId;
		this.status = record.status;
		this.validFrom = TimeUtil.formatDate(record.validFrom, TimeFormat.DATE_ONLY, TimeFormat.ISO_DATETIME);
		this.validTo = !!record.validTo ? TimeUtil.formatDate(record.validTo, TimeFormat.DATE_ONLY, TimeFormat.ISO_DATETIME) : null;
		this.locationName = record.getLocationName();
		this.trackingNumber = record.trackingNumber;
		this.reason = record.reason;
		this.state = record.state;
	}

	private static _mapConstructor = (toolRepair: ToolTrackingRecordBase) => new W_SmallTool_FindAllToolTrackingRecords_VM_Item(toolRepair);

	static bulkConstructor = (tableData: ToolTrackingRecordBase[]) => tableData.map(W_SmallTool_FindAllToolTrackingRecords_VM_Item._mapConstructor);
}
