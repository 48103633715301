import * as React from 'react';
import { Col } from 'react-bootstrap';

import styles from './styles.module.scss';

interface OwnProps {
	redirectToFieldReport: () => void;
	redirectToWorkOrder: () => void;
	redirectToJob: (jobId: number) => void;
	jobId: number;
}

type Props = OwnProps;

const WorkOrderLinks: React.FC<Props> = (props) => {

	const {
		redirectToFieldReport,
		redirectToWorkOrder,
		redirectToJob,
		jobId,
	} = props;

	const jobRedirect = React.useCallback(() => {
		redirectToJob(jobId);
	}, [redirectToJob, jobId]);

	return (
		<Col sm={3}>
			<div className={styles.link} onClick={redirectToFieldReport}>
				<span className="icon-external" /> Original Field Report
			</div>
			<div className={styles.link} onClick={redirectToWorkOrder}>
				<span className="icon-search" /> Original Work Order
			</div>
			<div className={styles.link} onClick={jobRedirect}>
				<span className="icon-external" /> View Job
			</div>
		</Col>
	);
};

export default React.memo(WorkOrderLinks);
