import * as React from 'react';

import type { DisplayViewEquipmentViewModel } from 'ab-viewModels/scheduleBoardDisplayView.viewModel';

import AvailableEquipmentItem from 'af-root/scenes/Company/ScheduleBoard/DisplayView/ResourceItems/EquipmentItems/AvailableEquipmentItem';

interface OwnProps {
	equipmentGroup: DisplayViewEquipmentViewModel[];
}

type Props = OwnProps;

const AvailableEquipmentColumn: React.FC<Props> = (props: Props) => {
	const { equipmentGroup } = props;

	return (
		<div className="display-view-available-column-wrapper">
			<div className="display-view-column">
				{
					equipmentGroup.map((_equipment, _index) => (
						<AvailableEquipmentItem
							equipment={_equipment}
							hideMultipleAssignmentBadges={false}
							key={_index}
						/>
					))
				}
			</div>
		</div>
	);
};

export default React.memo(AvailableEquipmentColumn);
