import * as React from 'react';

import * as ReportBlockFieldEnum from 'acceligent-shared/enums/reportBlockField';
import { QuantityUnitMap } from 'acceligent-shared/enums/quantityUnit';

import Dropdown from 'af-components/Controls/Dropdown';
import Tooltip from 'af-components/Tooltip';

import { UnitClasses } from 'ab-enums/unitConversion.enum';

import { bemBlock, bemElement } from 'ab-utils/bem.util';
import { convertUnits } from 'ab-utils/unitConversion.util';

import { dollarFormatter } from 'af-utils/format.util';
import type { CellWidthsMap } from 'af-utils/react.util';

import type { BillingCodeVM } from 'ab-viewModels/job.viewModel';
import type { WorkSummaryDefinitionFieldVM } from 'ab-viewModels/fieldReport/workSummary.viewModel';
import type WorkSummaryVM from 'ab-viewModels/fieldReport/workSummary.viewModel';
import type SiblingSubjobVM from 'ab-viewModels/workRequest/siblingSubjob.viewModel';

import { WORK_SUMMARY_COLUMN_DEFINITION_MAP, createIndexedColumn, resolveStyleForColumn } from '../../values';
import { resolveHighlightGroupKey } from '../../helpers';
import { resolveFieldValue } from '../../Table/helpers';

import styles from './styles.module.scss';

interface Props {
	rowData: WorkSummaryVM;
	billingCodes: BillingCodeVM[];
	maxNumberOfDefinitionFields: number;
	isReadOnly: boolean;
	onRowHighlight: (groupToHighlight: Nullable<string>, groupKeyToHighlight: Nullable<string>) => void;
	highlightedGroup: Nullable<string>;
	cellWidths: CellWidthsMap;
	hasPreviouslyBeenSplit: boolean;
	isOfCurrentlyHighlightedGroupKey: boolean;
	isAlternativeOption: boolean;
	workSummaryDetailIds: number[];
	belongsToProject: boolean;
	subJobs: SiblingSubjobVM[];
	handleJobReallocation: (subJob: SiblingSubjobVM, workSummaryDetailIds: number[]) => Promise<void>;
}

export interface BillableWorkTableRowProps {
	isBillable: true;
	onBillingCodeSelect: (billingCode: Nullable<BillingCodeVM>, workSummaryDetailIds: number[]) => Promise<void>;
}

export interface NonBillableWorkTableRowProps {
	isBillable: false;
	onBillingCodeSelect?: never;
}

type OwnProps = Props & (BillableWorkTableRowProps | NonBillableWorkTableRowProps);

type DropdownOption = {
	id: number;
	customerId: string;
	unitPrice: Nullable<string>;
	description: string;
	group: Nullable<string>;
};

type DropdownOptionWithRowData = DropdownOption & {
	unit: Nullable<string>;
	rowUnit: Nullable<string>;
	rowQuantity: Nullable<number>;
};

const _delimitersRegEx = new RegExp('\/|-| ');

const _filterBillingCodes = (_option: BillingCodeVM, _searchText: string) => {
	_searchText.trim();
	const splitSearch = _searchText.toLowerCase().split(_delimitersRegEx).join('');
	const _customerIdToLowerCase = _option.customerId.toLowerCase().split(_delimitersRegEx).join('');
	const _descriptionToLowerCase = _option.description.toLowerCase().split(_delimitersRegEx).join('');

	if (!!splitSearch && _customerIdToLowerCase.includes(splitSearch) || _descriptionToLowerCase.includes(splitSearch)) {
		return true;
	}

	return false;
};

const _filterSubJobs = (option: SiblingSubjobVM, _searchText: string) => {
	return option.jobCode.includes(_searchText);
};

const _renderBillingCodeMenuItem = (_bc: DropdownOptionWithRowData) => {
	const convertedUnit = _bc.rowQuantity && _bc.rowUnit && _bc.unit
		? convertUnits(_bc.rowQuantity, QuantityUnitMap[_bc.rowUnit], QuantityUnitMap[_bc.unit]) : null;
	const convertedUnitLabel = convertedUnit && `${_bc.rowQuantity} ${_bc.rowUnit} = ${parseFloat(Number(convertedUnit).toFixed(4)).toString()} ${_bc.unit?.replace('_', '/')}`;
	const perUnitPriceLabel = `$ ${_bc.unitPrice} per ${_bc.unit?.replace('_', '/')} ${_bc.group ?? ''}`;

	return (
		<div className={bemElement('field-report__work-summary__table__row__data__cell', 'dropdown-menu-item')}>
			<b>{_bc.customerId}</b>
			<span><b>{perUnitPriceLabel}</b></span>
			{convertedUnit && (_bc.unit !== _bc.rowUnit)
				? <p className={styles.dropdown_card_subtitle}>{convertedUnitLabel}</p>
				: ''
			}
			<p>{_bc.description}</p>
		</div>
	);
};

const _renderNoBillingCodeMenuItem = (item: { disabled: true; label: string; }) => {
	return (
		<div className={bemElement('field-report__work-summary__table__row__data__cell', 'dropdown-menu-item')}>
			<p>
				{item.label}
			</p>
		</div>
	);
};

const _renderSubJobMenuItem = (subJob: SiblingSubjobVM) => {
	return (
		<div className={bemElement('field-report__work-summary__table__row__data__cell', 'dropdown-menu-item')}>
			<b>{subJob.jobCode}</b>
		</div>
	);
};

const _renderSelectedSubJobMenuItem = (subJob: SiblingSubjobVM) => <span>{subJob.jobCode}</span>;

const _renderSelectedDropdownMenuItem = (_billingCode: DropdownOption) => <span>{_billingCode.customerId}</span>;

const _renderSelectedDropdownMenuItemWithNoBillingCodes = ({ label }: { label: string; }) => <span>{label}</span>;

const _resolveTableCellClassName = (modifiers?) => {
	return bemElement('field-report__work-summary__table__row__data', 'cell', { ...modifiers });
};

const _fillWithEmptyFields = (fields: WorkSummaryDefinitionFieldVM[], maxNumberOfFields: number) => {
	const noOfExtraEmptyFields = maxNumberOfFields - (fields.length);
	let modifiedFields: Nullable<WorkSummaryDefinitionFieldVM>[] = [];

	if (noOfExtraEmptyFields) {
		const extraFields = new Array(noOfExtraEmptyFields).fill(null, 0, maxNumberOfFields);
		modifiedFields =
			fields
				? fields.concat(extraFields)
				: extraFields;
	} else {
		modifiedFields = fields;
	}

	return modifiedFields;
};

const BillableAndNonBillableWorkTableRow: React.FC<OwnProps> = (props) => {
	const {
		billingCodes,
		rowData,
		maxNumberOfDefinitionFields,
		isBillable,
		onBillingCodeSelect,
		onRowHighlight,
		highlightedGroup,
		cellWidths,
		isReadOnly,
		hasPreviouslyBeenSplit,
		isOfCurrentlyHighlightedGroupKey,
		isAlternativeOption,
		workSummaryDetailIds,
		belongsToProject,
		subJobs,
		handleJobReallocation,
	} = props;

	const {
		subJobId,
		billableWorkId,
		quantity,
		unit,
		work,
		type,
		definitionFields,
		billingCodeId,
		billingCodeUnit,
		unitPrice,
		total,
		description,
		customerId,
		billingGroup,
		typeFieldName,
		typeFieldType,
		workSummaryGroup,
	} = rowData;

	const groupKey = React.useMemo(() => resolveHighlightGroupKey({
		subJobId,
		billableWorkId,
		workType: type,
		definitionFields,
	}), [billableWorkId, definitionFields, subJobId, type]);

	const groupToHighlight = workSummaryGroup ?? groupKey;

	const onBillingCodeChange = React.useCallback(async (value: BillingCodeVM) => {
		onRowHighlight(groupToHighlight, groupKey);
		await onBillingCodeSelect?.(value, workSummaryDetailIds);
	}, [groupKey, groupToHighlight, onBillingCodeSelect, onRowHighlight, workSummaryDetailIds]);

	const onBillingCodeClear = React.useCallback(() => {
		onRowHighlight(groupToHighlight, groupKey);
		onBillingCodeSelect?.(null, workSummaryDetailIds);
	}, [groupKey, groupToHighlight, onBillingCodeSelect, onRowHighlight, workSummaryDetailIds]);

	const onSubJobChange = React.useCallback(async (subJob: SiblingSubjobVM) => {
		await handleJobReallocation?.(subJob, workSummaryDetailIds);
	}, [handleJobReallocation, workSummaryDetailIds]);

	const cellClassName = bemElement('field-report__work-summary__table__row__data', 'cell');
	const cellTextClassName = bemElement(cellClassName, 'text');

	const _definitionFieldsMapper = React.useCallback((_df: WorkSummaryDefinitionFieldVM, _index: number) => {
		const column = createIndexedColumn(WORK_SUMMARY_COLUMN_DEFINITION_MAP.DEFINITION_FIELD, _index);
		const formatted = _df ? `${_df.fieldName}: ${resolveFieldValue(_df.value, _df.fieldType)} ${_df.fieldUnit ?? ''}` : 'N/A';

		return (
			<span
				className={_resolveTableCellClassName()}
				key={_index}
				style={resolveStyleForColumn(column, cellWidths)}
			>
				<span className={cellTextClassName}>
					{formatted}
				</span>
			</span>
		);
	}, [cellTextClassName, cellWidths]);

	const filteredBillingCodeOptions = React.useMemo(
		() => billingCodes.reduce<DropdownOptionWithRowData[]>((_acc: DropdownOptionWithRowData[], _bc: BillingCodeVM) => {
			if (!unit
				|| (UnitClasses[QuantityUnitMap[_bc.unit]] && (UnitClasses[QuantityUnitMap[_bc.unit]] === UnitClasses[unit]) // if there is a defined conversion rate
					|| (QuantityUnitMap[_bc.unit] === unit)) // if units are the same
			) {
				_acc.push({
					customerId: _bc.customerId,
					id: _bc.id,
					description: _bc.description,
					unitPrice: _bc.unitPrice,
					group: _bc.group,
					unit: _bc.unit,
					rowUnit: rowData.unit,
					rowQuantity: rowData.quantity,
				});
			}
			return _acc;
		}, [])
		, [billingCodes, unit, rowData]);

	const selectedBillingCode = React.useMemo(
		() => billingCodes.find((_bc) => _bc.id === billingCodeId, {}),
		[billingCodeId, billingCodes]
	);

	const selectedBillingCodeOption = React.useMemo<Nullable<DropdownOption>>(() => {
		if (selectedBillingCode) {
			return {
				customerId: selectedBillingCode.customerId,
				id: selectedBillingCode.id,
				description: selectedBillingCode.description,
				unitPrice: selectedBillingCode.unitPrice,
				group: selectedBillingCode.group,
				unit: null,
				rowUnit: null,
				rowQuantity: 0,
			};
		}
		if (customerId && billingCodeId && description) {
			return {
				customerId,
				id: billingCodeId,
				unitPrice: unitPrice ? unitPrice + '' : '',
				description,
				group: billingGroup,
				unit: null,
				rowUnit: null,
				rowQuantity: 0,
			};
		}

		return null;
	}, [billingCodeId, billingGroup, customerId, description, selectedBillingCode, unitPrice]);

	const hasSelectableBillingCodes = filteredBillingCodeOptions.length > 0;

	const onRowClick = React.useCallback(() => onRowHighlight(groupToHighlight, groupKey), [groupKey, groupToHighlight, onRowHighlight]);

	const _renderDropdown = React.useCallback(() => {
		if (!hasSelectableBillingCodes) {
			return (
				<Dropdown
					className={bemElement('field-report__work-summary__table__row__data__cell', 'dropdown')}
					defaultValue={customerId ? { label: customerId } : undefined}
					onClear={onBillingCodeClear}
					options={[{ disabled: true, label: 'No billing codes found.' }]}
					placeholder="No billing codes found."
					renderMenuItem={_renderNoBillingCodeMenuItem}
					renderSelected={_renderSelectedDropdownMenuItemWithNoBillingCodes}
					withCaret={true}
				/>
			);
		}

		return (
			<Dropdown<DropdownOption>
				className={bemElement('field-report__work-summary__table__row__data__cell', 'dropdown')}
				defaultValue={selectedBillingCodeOption}
				disabled={isReadOnly}
				filterable={true}
				filterBy={_filterBillingCodes}
				labelKey="customerId"
				onClear={onBillingCodeClear}
				onValueChange={onBillingCodeChange}
				options={filteredBillingCodeOptions}
				placeholder="Add Billing Code"
				renderMenuItem={_renderBillingCodeMenuItem}
				renderSelected={_renderSelectedDropdownMenuItem}
				valueKey="id"
				withCaret={true}
			/>);

	}, [customerId, filteredBillingCodeOptions, hasSelectableBillingCodes, isReadOnly, onBillingCodeChange, onBillingCodeClear, selectedBillingCodeOption]);

	const renderBillingCode = React.useCallback(() => {
		if (isBillable) {
			return (
				<span
					className={_resolveTableCellClassName({ long: true, dropdown: true })}
					style={resolveStyleForColumn(WORK_SUMMARY_COLUMN_DEFINITION_MAP.CHARGE_RATE, cellWidths)}
				>
					{isReadOnly
						? <span className={cellTextClassName}>
							{customerId}
						</span>
						: _renderDropdown()
					}
				</span>
			);
		}

		return (
			<span
				className={_resolveTableCellClassName({ long: true, dropdown: true })}
				style={resolveStyleForColumn(WORK_SUMMARY_COLUMN_DEFINITION_MAP.BILLING_CODE, cellWidths)}
			>
				<span className={cellTextClassName}>
					{customerId}
				</span>
			</span>
		);

	}, [_renderDropdown, cellTextClassName, cellWidths, customerId, isBillable, isReadOnly]);

	const renderUnitPrice = React.useCallback(() => {
		if (!unit || !unitPrice) {
			return '';
		}
		const convertedUnitPrice = billingCodeUnit && unit !== billingCodeUnit
			? convertUnits(unitPrice, QuantityUnitMap[unit], QuantityUnitMap[billingCodeUnit])
			: null;

		const tooltipMessage = convertedUnitPrice
			? <>
				<div>Quantity tracked in {unit?.replace('_', '/')}</div>
				<div>Billing code in {billingCodeUnit?.replace('_', '/')}</div>
			</>
			: null;

		const renderValue = convertedUnitPrice
			? `$${parseFloat(convertedUnitPrice.toFixed(8))} per ${unit ? unit?.replace('_', '/') : 'N/A'} ($${parseFloat(unitPrice.toFixed(4))} per ${billingCodeUnit?.replace('_', '/')})`
			: `$${parseFloat(unitPrice.toFixed(4))} per ${unit ? unit?.replace('_', '/') : 'N/A'}`;

		return tooltipMessage
			? <Tooltip message={tooltipMessage}><span className={bemBlock(cellTextClassName, { 'align-right': true })}>{renderValue}</span></Tooltip>
			: renderValue;
	}, [unit, billingCodeUnit, unitPrice, cellTextClassName]);

	const { selectedSubJob, selectableSubJobs } = React.useMemo(() => {
		return (subJobs ?? []).reduce<{ selectedSubJob: Nullable<SiblingSubjobVM>; selectableSubJobs: SiblingSubjobVM[]; }>((_acc, _subJob) => {
			if (_subJob.id === subJobId) {
				_acc.selectedSubJob = _subJob;
			} else {
				_acc.selectableSubJobs.push(_subJob);
			}
			return _acc;
		}, { selectedSubJob: null, selectableSubJobs: [] });
	}, [subJobId, subJobs]);

	const renderReallocationDropdown = React.useCallback(() => {
		if (!subJobs?.length) {
			return null;
		}
		return (
			<Dropdown<SiblingSubjobVM>
				className={bemElement('field-report__work-summary__table__row__data__cell', 'dropdown')}
				defaultValue={selectedSubJob}
				disabled={isReadOnly}
				filterable={true}
				filterBy={_filterSubJobs}
				labelKey="jobCode"
				onValueChange={onSubJobChange}
				options={selectableSubJobs}
				placeholder="Reallocate"
				renderMenuItem={_renderSubJobMenuItem}
				renderSelected={_renderSelectedSubJobMenuItem}
				valueKey="id"
				withCaret={true}
			/>
		);
	}, [isReadOnly, onSubJobChange, selectableSubJobs, selectedSubJob, subJobs?.length]);

	const modifiedDefinitionFields = _fillWithEmptyFields(definitionFields, maxNumberOfDefinitionFields);

	const isHighlighted = (highlightedGroup === groupToHighlight);
	const indicatePreviousSplit = hasPreviouslyBeenSplit && isOfCurrentlyHighlightedGroupKey;

	const rowClassName = bemElement('field-report__work-summary__table', 'row__data', {
		highlighted: isHighlighted,
		split: indicatePreviousSplit,
		'billable-table': true,
		alternative: isAlternativeOption,
	});

	const workTypeAdjustedName = (typeFieldType === ReportBlockFieldEnum.Type.NUMERIC_ATTRIBUTE || typeFieldType === ReportBlockFieldEnum.Type.CALCULATED)
		? typeFieldName
		: `${typeFieldName}: ${resolveFieldValue(type, typeFieldType)}`;

	return (
		<div
			className={rowClassName}
			onClick={onRowClick}
		>
			<span className={_resolveTableCellClassName({ actions: true })}>
			</span>
			<span
				className={_resolveTableCellClassName()}
				style={resolveStyleForColumn(WORK_SUMMARY_COLUMN_DEFINITION_MAP.WORK, cellWidths)}
			>
				<span className={cellTextClassName}>
					{work}
				</span>
			</span>
			<span
				className={_resolveTableCellClassName()}
				style={resolveStyleForColumn(WORK_SUMMARY_COLUMN_DEFINITION_MAP.TYPE, cellWidths)}
			>
				<span className={cellTextClassName}>
					{workTypeAdjustedName}
				</span>
			</span>
			{modifiedDefinitionFields?.map(_definitionFieldsMapper)}
			<span
				className={_resolveTableCellClassName({ short: true })}
				style={resolveStyleForColumn(WORK_SUMMARY_COLUMN_DEFINITION_MAP.QUANTITY, cellWidths)}
			>
				<span className={bemBlock(cellTextClassName, { 'align-right': true })}>
					{quantity}
				</span>
			</span>
			<span
				className={_resolveTableCellClassName({ short: true })}
				style={resolveStyleForColumn(WORK_SUMMARY_COLUMN_DEFINITION_MAP.UNIT, cellWidths)}
			>
				<span className={cellTextClassName}>
					{unit && QuantityUnitMap[unit] ? QuantityUnitMap[unit].replace('_', '/') : 'N/A'}
				</span>
			</span>
			{renderBillingCode()}
			<span
				className={_resolveTableCellClassName()}
				style={resolveStyleForColumn(WORK_SUMMARY_COLUMN_DEFINITION_MAP.UNIT_PRICE, cellWidths)}
			>
				<span className={bemBlock(cellTextClassName, { 'align-right': true })}>
					{renderUnitPrice()}
				</span>
			</span>
			<span
				className={_resolveTableCellClassName({ long: true })}
				style={resolveStyleForColumn(WORK_SUMMARY_COLUMN_DEFINITION_MAP.DESCRIPTION, cellWidths)}
			>
				<span className={cellTextClassName}>
					{description}
				</span>
			</span>
			<span
				className={_resolveTableCellClassName()}
				style={resolveStyleForColumn(WORK_SUMMARY_COLUMN_DEFINITION_MAP.REVENUE, cellWidths)}
			>
				<span className={bemBlock(cellTextClassName, { 'align-right': true })}>
					{total !== null ? dollarFormatter.format(total) : ''}
				</span>
			</span>
			{belongsToProject && (
				<span
					className={_resolveTableCellClassName({ long: true, dropdown: true })}
					style={resolveStyleForColumn(WORK_SUMMARY_COLUMN_DEFINITION_MAP.REALLOCATE, cellWidths)}
				>
					{renderReallocationDropdown()}
				</span>
			)}
		</div>
	);
};

export default React.memo(BillableAndNonBillableWorkTableRow);
