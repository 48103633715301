import * as React from 'react';
import type { ResolveThunks } from 'react-redux';
import { connect } from 'react-redux';

import * as LMSActions from 'af-actions/learningManagementSystem';

interface DispatchProps {
	findCornerstoneResponse: typeof LMSActions.findCornerstoneResponse;
}

type Props = ResolveThunks<DispatchProps>;

const LMSLogin: React.FC<Props> = (props) => {
	const { findCornerstoneResponse } = props;
	const [acs, setAcs] = React.useState<Nullable<string>>(null);
	const [samlResponse, setSamlResponse] = React.useState<Nullable<string>>(null);
	const formRef = React.useRef<Nullable<HTMLFormElement>>(null);

	const initialize = React.useCallback(async () => {
		const res = await findCornerstoneResponse();
		if (res) {
			setAcs(res.acs);
			setSamlResponse(res.SAMLResponse);
		}
	}, [findCornerstoneResponse]);

	const login = React.useCallback(async () => {
		formRef.current?.submit();
	}, []);

	React.useEffect(() => {
		initialize();
	}, [initialize]);

	React.useEffect(() => {
		if (!!acs && !!samlResponse) {
			login();
		}
	}, [acs, login, samlResponse]);

	return (
		<>
			{!!acs && !!samlResponse && (
				<li style={{ display: 'none' }}>
					<form action={`${acs}`} autoComplete="off" id="sso" method="post" ref={formRef}>
						<input id="resp" name="SAMLResponse" type="hidden" value={`${samlResponse}`} />
					</form>
				</li>
			)}
		</>
	);
};

function mapDispatchToProps(): DispatchProps {
	return {
		findCornerstoneResponse: LMSActions.findCornerstoneResponse,
	};
}

export default connect<void, DispatchProps>(null, mapDispatchToProps())(LMSLogin);
