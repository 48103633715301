import * as React from 'react';

import type { CellInfo } from 'af-components/Table6';
import EmptyCell from './EmptyCell';

import { moneyNormalizer } from 'ab-utils/formatting.util';

type Props<T> = CellInfo<T>;

function MonetaryCell<T>({ value }: Props<T>) {
	return (
		<span className={value && value < 0 ? 'text-red' : ''}>
			{value
				? moneyNormalizer(value)
				: <EmptyCell />
			}
		</span>
	);
}

export default MonetaryCell;
