import * as React from 'react';
import type { ResolveThunks } from 'react-redux';
import { connect } from 'react-redux';

import DisabledOnAction from 'af-components/DisabledOnAction';
import ActionButton from 'af-components/DisabledOnAction/ActionButton';
import LoadingIndicator from 'af-components/LoadingIndicator';
import CustomModal from 'af-components/CustomModal';

import * as GeneralActions from 'af-actions/general';

interface OwnProps {
	showModal?: boolean;
	message: string;
	size?: 'sm' | 'md' | 'lg';
	onRefresh?: () => void;
}

interface DispatchProps {
	hideRefreshModal?: typeof GeneralActions.hideRefreshModal;
}

type Props = OwnProps & ResolveThunks<DispatchProps>;

class RefreshModal extends React.PureComponent<Props> {
	static defaultProps: Partial<Props> = {
		size: 'sm',
	};

	refreshPage = async () => {
		const { hideRefreshModal, onRefresh } = this.props;
		hideRefreshModal?.();
		if (onRefresh) {
			onRefresh();
		}
		window.location.reload();
	};

	render() {
		const { showModal, message, size } = this.props;

		return (
			<CustomModal
				className={`refresh-modal modal-${size}`}
				closeModal={this.refreshPage}
				showModal={showModal}
			>
				<CustomModal.Header
					title="Info"
				/>
				<CustomModal.Body>
					{message}
				</CustomModal.Body>
				<CustomModal.Footer>
					<DisabledOnAction
						activeElement={<span>Refresh</span>}
						component={ActionButton}
						inactiveElement={<LoadingIndicator />}
						onClick={this.refreshPage}
						variant="primary"
					/>
				</CustomModal.Footer>
			</CustomModal>
		);
	}
}

function mapDispatchToProps(): DispatchProps {
	return {
		hideRefreshModal: GeneralActions.hideRefreshModal,
	};
}

export default connect<null, DispatchProps, OwnProps>(null, mapDispatchToProps())(RefreshModal);
