import * as React from 'react';
import { compose } from 'redux';
import type { ConnectedProps } from 'react-redux';
import { connect } from 'react-redux';

import type { RootState } from 'af-reducers';

import EquipmentGroup from './EquipmentGroup';

interface OwnProps {
	dueDate: string;
	isDragAndDropDisabled: boolean;
	hasPermissionsToEditScheduleBoard: boolean;
}

type Props = OwnProps & ConnectedProps<typeof connector>;

const UnavailableEquipment: React.FC<Props> = (props) => {
	const {
		dueDate,
		equipmentStatuses = [],
		isDragAndDropDisabled,
		hasPermissionsToEditScheduleBoard,
	} = props;

	if (!equipmentStatuses?.length) {
		return null;
	}

	return (
		<div className="schedule-board-toolbar-equipment-row">
			<label>Equipment Unavailable</label>
			{
				equipmentStatuses.map(({ id: _eqStatusId, title: _eqStatus, isDeleted }) =>
				(
					<div className="schedule-board-toolbar-equipment-wrapper unavailable" key={_eqStatusId}>
						<div className="schedule-board-toolbar-equipment unavailable">
							<EquipmentGroup
								available={false}
								dueDate={dueDate}
								groupId={_eqStatusId}
								groupTitle={_eqStatus}
								hasPermissionsToEditScheduleBoard={hasPermissionsToEditScheduleBoard}
								hasReason={true}
								isDeleted={isDeleted}
								isDragAndDropDisabled={isDragAndDropDisabled}
							/>
						</div>
					</div>
				))
			}
		</div>
	);
};

function mapStateToProps(state: RootState) {
	return {
		equipmentStatuses: state?.scheduleBoard?.toolbarEquipmentGroupTitles?.unavailable,
	};
}

const connector = connect(mapStateToProps);

const enhance = compose<React.ComponentType<OwnProps>>(
	React.memo,
	connector
);

export default enhance(UnavailableEquipment);
