import type { Dispatch, AnyAction } from 'redux';

import type { TableContent } from 'ab-common/dataStructures/tableContent';

import type TemporaryEmployeeVM from 'ab-viewModels/temporaryEmployee/temporaryEmployee.viewModel';
import type TemporaryEmployeeTableVM from 'ab-viewModels/temporaryEmployee/table.viewModel';
import type TemporaryEmployeeOptionVM from 'ab-viewModels/temporaryEmployee/temporaryEmployeeOption.viewModel';
import type TemporaryEmployeeNameAvailableVM from 'ab-viewModels/temporaryEmployee/temporaryEmployeeNameAvailable.viewModel';

import type { TemporaryEmployeeNameAvailableRM } from 'ab-requestModels/temporaryEmployee.requestModel';
import type UpsertTemporaryEmployeeRM from 'ab-requestModels/temporaryEmployee.requestModel';

import { TableQuery } from 'ab-common/dataStructures/tableQuery';

import API from 'af-constants/routes/api';

import { http } from 'af-utils/http.util';
import { errorHandler } from 'af-utils/actions.util';

import type { GetRootState } from 'af-reducers';

export function findById(employeeId: number) {
	return async (dispatch: Dispatch<AnyAction>, getState: GetRootState, { redirectTo }) => {

		const action = async () => {
			return await http.get<TemporaryEmployeeVM>(API.V1.RESOURCES.TEMPORARY_EMPLOYEE.FIND_BY_ID(employeeId));
		};

		return await errorHandler(action, dispatch, redirectTo);
	};
}

export function findAllForCompanyTable(tableRequestModel: TableQuery) {
	return async (dispatch: Dispatch<AnyAction>, getState: GetRootState, { redirectTo }) => {

		const action = async () => {
			const data = new TableQuery(tableRequestModel);
			return await http.get<TableContent<TemporaryEmployeeTableVM>>(API.V1.RESOURCES.TEMPORARY_EMPLOYEE.COMPANY_TABLE(data));
		};

		return await errorHandler(action, dispatch, redirectTo);
	};
}

export function findAllDeletedForCompanyTable(tableRequestModel: TableQuery = {} as TableQuery) {
	return async (dispatch: Dispatch<AnyAction>, getState: GetRootState, { redirectTo }) => {

		const action = async () => {
			const data = new TableQuery(tableRequestModel);
			return await http.get<TableContent<TemporaryEmployeeTableVM>>(API.V1.RESOURCES.TEMPORARY_EMPLOYEE.COMPANY_TABLE_DELETED(data));
		};

		return await errorHandler(action, dispatch, redirectTo);
	};
}

/**
 *
 * @param dueDate 'YYYY-MM-DD'
 */
export function findAllForWorkOrdersForDueDate(dueDate: string) {
	return async (dispatch: Dispatch<AnyAction>, getState: GetRootState, { redirectTo }) => {

		const action = async () => {
			return await http.get<TemporaryEmployeeOptionVM[]>(API.V1.RESOURCES.TEMPORARY_EMPLOYEE.FIND_WORK_ORDER_TEMPORARY_EMPLOYEES_BY_DUE_DATE(dueDate));
		};
		return await errorHandler(action, dispatch, redirectTo);
	};
}

export function create(form: UpsertTemporaryEmployeeRM) {
	return async (dispatch: Dispatch<AnyAction>, getState: GetRootState, { redirectTo }) => {

		const action = async () => {
			await http.post(API.V1.RESOURCES.TEMPORARY_EMPLOYEE.CREATE, form);
		};

		return await errorHandler(action, dispatch, redirectTo);
	};
}

export function update(employeeId: number, form: UpsertTemporaryEmployeeRM) {
	return async (dispatch: Dispatch<AnyAction>, getState: GetRootState, { redirectTo }) => {

		const action = async () => {
			await http.put(API.V1.RESOURCES.TEMPORARY_EMPLOYEE.EDIT(employeeId), form);
		};

		return await errorHandler(action, dispatch, redirectTo);
	};
}

export function remove(employeeId: number) {
	return async (dispatch: Dispatch<AnyAction>, getState: GetRootState, { redirectTo }) => {

		const action = async () => {
			await http.delete(API.V1.RESOURCES.TEMPORARY_EMPLOYEE.DELETE(employeeId));
		};

		return await errorHandler(action, dispatch, redirectTo);
	};
}

export function bulkRemove(employeeIds: number[]) {
	return async (dispatch: Dispatch<AnyAction>, getState: GetRootState, { redirectTo }) => {

		const action = async () => {
			await http.delete(API.V1.RESOURCES.TEMPORARY_EMPLOYEE.BULK_DELETE, { employeeIds });
		};

		return await errorHandler(action, dispatch, redirectTo);
	};
}

export function reactivate(employeeId: number) {
	return async (dispatch: Dispatch<AnyAction>, getState: GetRootState, { redirectTo }) => {

		const action = async () => {
			await http.patch(API.V1.RESOURCES.TEMPORARY_EMPLOYEE.REACTIVATE(employeeId));
		};

		return await errorHandler(action, dispatch, redirectTo);
	};
}

export function isNameAvailable(request: TemporaryEmployeeNameAvailableRM) {
	return async (dispatch: Dispatch<AnyAction>, getState: GetRootState, { redirectTo }) => {
		const action = async () => {
			return await http.post<TemporaryEmployeeNameAvailableVM>(API.V1.RESOURCES.TEMPORARY_EMPLOYEE.IS_NAME_AVAILABLE, request);
		};
		return await errorHandler(action, dispatch, redirectTo);
	};
}
