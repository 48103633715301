import * as React from 'react';

import type FormModel from './formModel';

import type { MultiTagOptionProps } from 'af-fields/MultiTagSelect';

import Tag from 'af-components/Controls/Tag';

type Props = MultiTagOptionProps<FormModel['itemCategories'][0]>;

const ItemCategory = (props: Props) => {
	const { data, onRemove } = props;

	return (
		<Tag size="small">
			<span>{data.name}</span>
			<span className="icon-close" onMouseDown={onRemove} role="button" />
		</Tag>
	);
};

export default React.memo(ItemCategory);
