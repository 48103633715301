import type WorkSummaryDetailsBase from 'ab-domain/models/workSummaryDetails/base';

export class W_WorkSummary_GetAllocatedWorkSummaryDetails_VM {
	details: W_WorkSummary_GetAllocatedWorkSummaryDetails_VM_Detail[];

	constructor(wsds: WorkSummaryDetailsBase[]) {
		this.details = W_WorkSummary_GetAllocatedWorkSummaryDetails_VM_Detail.bulkConstructor(wsds);
	}
}

class W_WorkSummary_GetAllocatedWorkSummaryDetails_VM_Detail {
	id: number;
	workTypeFieldReportTypeId: number;
	workQuantityFieldReportTypeId: Nullable<number>;
	definitionFieldReportTypeIds: number[];

	constructor(wsd: WorkSummaryDetailsBase) {
		this.id = wsd.id;
		this.workTypeFieldReportTypeId = wsd.workTypeFieldReportBlockField.fieldReportBlock.fieldReportTypeId;
		this.workQuantityFieldReportTypeId = wsd.workQuantityFieldReportBlockField?.fieldReportBlock.fieldReportTypeId ?? null;
		this.definitionFieldReportTypeIds = wsd.definitionFields.map((df) => df.fieldReportBlockField.fieldReportBlock.fieldReportTypeId);
	}

	static bulkConstructor = (wsds: WorkSummaryDetailsBase[]) => wsds.map(W_WorkSummary_GetAllocatedWorkSummaryDetails_VM_Detail._constructorMap);

	private static _constructorMap = (wsd: WorkSummaryDetailsBase) => new W_WorkSummary_GetAllocatedWorkSummaryDetails_VM_Detail(wsd);
}
