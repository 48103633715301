import * as React from 'react';
import { Field } from 'redux-form';

import Input from 'af-fields/Input';
import Dropdown from 'af-fields/Dropdown';

import { getAttributeName } from 'ab-viewModels/workRequest/workRequestDetailsUpsert.viewModel';
import type { SaleFormViewModel } from 'ab-viewModels/sale/saleForm.viewModel';

import * as ReduxUtils from 'ab-utils/reduxForms.util';

import { formSectionClass, formSectionClassColumn2, formSectionClassRow, formSectionTitleClass } from '../../helpers';
import styles from '../../styles.module.scss';

type Props = {
	findSales: () => Promise<SaleFormViewModel[]>;
	isDisabled: boolean;
	salesRef: React.RefObject<HTMLDivElement>;
};

const SalesSection: React.FC<Props> = (props) => {
	const { findSales, isDisabled, salesRef } = props;
	const [sales, setSales] = React.useState<SaleFormViewModel[]>([]);

	const renderSalesMenuItem = React.useCallback(
		(_sale: SaleFormViewModel) =>
			<div className={styles['job-form__contact-dropdown-item']} key={_sale.id}>
				<div className={styles['job-form__contact-dropdown-item__main']}>
					{_sale.fullName}
				</div>
				<div className={styles['job-form__contact-dropdown-item__secondary']}>
					{_sale.title}
				</div>
			</div>,
		[]);

	const renderSelectedSalesLead = React.useCallback((_sale: SaleFormViewModel) => _sale.fullName, []);

	const lazyLoadSaleLeads = React.useCallback(async (isLazyLoaded: boolean) => {
		if (!isLazyLoaded) {
			const fetchedSales = await findSales();
			setSales(fetchedSales);
		}
	}, [findSales]);

	return (
		<div className={formSectionClass} ref={salesRef}>
			<div className={formSectionTitleClass}>
				Sales
			</div>
			<div className={formSectionClassRow}>
				<div className={formSectionClassColumn2}>
					<Field
						component={Input}
						disabled={isDisabled}
						id="salesId"
						label="Sales ID"
						name={getAttributeName('salesId')}
						placeholder="Enter Sales ID"
						type="text"
					/>
				</div>
				<div className={formSectionClassColumn2}>
					<Field
						component={Dropdown}
						disabled={isDisabled}
						id="salesLeadId"
						label="Sales Lead"
						name={getAttributeName('salesLeadId')}
						onLazyLoad={lazyLoadSaleLeads}
						options={sales}
						placeholder="Select Sales Lead"
						propName="salesLead"
						renderMenuItem={renderSalesMenuItem}
						renderSelected={renderSelectedSalesLead}
						valueKey="id"
						withCaret={true}
					/>
				</div>
				<div className={formSectionClassColumn2}>
					<Field
						component={Input}
						disabled={isDisabled}
						id="estimateTotal"
						isDollarValue={true}
						label="Estimate Total"
						name={getAttributeName('estimateTotal')}
						normalize={ReduxUtils.normalizeDecimalNumber}
						type="number"
					/>
				</div>
			</div>
		</div>
	);
};

export default React.memo(SalesSection);
