import * as React from 'react';
import type { CustomRouteComponentProps } from 'react-router-dom';
import type { ResolveThunks } from 'react-redux';
import { connect } from 'react-redux';

import type { RootState } from 'af-reducers';

import CLIENT from 'af-constants/routes/client';

import type { DeliverableViewModel } from 'ab-viewModels/deliverable.viewModel';
import type { DeliverableComment } from 'ab-viewModels/deliverableTable.viewModel';

import DeliverableFormDetails from 'af-components/DeliverableFormDetails';
import DeliverableForm from 'af-components/SharedForms/Deliverables/Deliverable';
import DeliverableFM from 'af-components/SharedForms/Deliverables/Deliverable/formModel';
import Breadcrumbs from 'af-components/Breadcrumbs';

import * as DeliverableActions from 'af-actions/deliverable';

import * as SettingsUtils from 'af-utils/settings.util';

interface PathParams {
	id: string;
}

interface StateProps {
	companyName: string;
}

interface DispatchProps {
	editDeliverable: typeof DeliverableActions.updateDeliverable;
	findDeliverable: typeof DeliverableActions.findById;
	createComment: typeof DeliverableActions.createDeliverableComment;
	findComments: typeof DeliverableActions.findDeliverableComments;
}

type OwnProps = CustomRouteComponentProps<PathParams>;
type Props = OwnProps & StateProps & ResolveThunks<DispatchProps>;

interface State {
	deliverable: DeliverableViewModel;
	comments: DeliverableComment[];
}

class Deliverable extends React.Component<Props, State> {
	state: State = {
		deliverable: {} as DeliverableViewModel,
		comments: [],
	};

	async componentDidMount() {
		const { match: { params: { id } }, findDeliverable, findComments } = this.props;
		const [deliverable, comments] = await Promise.all([findDeliverable(+id), findComments(+id)]);
		SettingsUtils.setExpandedDeliverableIds(id);
		this.setState(() => ({ deliverable, comments }));
	}

	loadComments = async () => {
		const { findComments, match: { params: { id } } } = this.props;
		const comments = await findComments(+id);
		this.setState(() => ({ comments }));
	};

	onBack = () => {
		const { history, companyName, location: { state: { orgAlias } } } = this.props;
		history.push(CLIENT.COMPANY.DELIVERABLE.DASHBOARD(orgAlias, companyName));
	};

	onSubmit = (form: DeliverableFM) => {
		const { editDeliverable, location: { state: { orgAlias } }, companyName } = this.props;
		editDeliverable(DeliverableFM.fromFMtoRM(form), orgAlias, companyName);
	};

	createComment = async (comment: string) => {
		const { createComment, match: { params: { id } } } = this.props;
		await createComment({ comment, deliverableId: +id });
		await this.loadComments();
	};

	render() {
		const {
			location: { state: { orgAlias } },
			companyName,
		} = this.props;
		const {
			deliverable,
			comments,
		} = this.state;

		return (
			<div className="form-segment deliverable-form">
				<Breadcrumbs
					items={[
						{ label: 'Deliverables', url: CLIENT.COMPANY.DELIVERABLE.DASHBOARD(orgAlias, companyName) },
						{ label: 'Edit' },
					]}
				/>
				<DeliverableFormDetails
					deliveryMethod={deliverable.deliveryMethod}
					deliveryTimeline={deliverable.deliveryTimeline}
					endDate={deliverable.endDate}
					jobCode={deliverable.jobCode}
					startDate={deliverable.startDate}
				/>
				<DeliverableForm
					comments={comments}
					createComment={this.createComment}
					initialValues={DeliverableFM.fromVMtoFM(deliverable)}
					onBack={this.onBack}
					onSubmit={this.onSubmit}
				/>
			</div>
		);
	}
}

const mapStateToProps = (state: RootState): StateProps => {
	const { companyData } = state.user;
	if (!companyData) {
		throw new Error('User not logged in');
	}

	return {
		companyName: companyData.name,
	};
};

function mapDispatchToProps(): DispatchProps {
	return {
		findComments: DeliverableActions.findDeliverableComments,
		createComment: DeliverableActions.createDeliverableComment,
		editDeliverable: DeliverableActions.updateDeliverable,
		findDeliverable: DeliverableActions.findById,
	};
}

export default connect<StateProps, DispatchProps, OwnProps>(mapStateToProps, mapDispatchToProps())(Deliverable);
