import * as React from 'react';
import { Field as ReduxField } from 'redux-form';

import type { EquipmentListItemVM } from 'acceligent-shared/dtos/mobile/view/timeSplitEquipment/timeSplitEquipment';
import type { BlockValueType } from 'acceligent-shared/utils/fieldReport';

import LockedValue from 'af-components/LockedValue';

import Dropdown from 'af-fields/Dropdown';

interface OwnProps {
	filterBy: (option: EquipmentListItemVM, searchText: string) => boolean;
	formName: string;
	isPreview: boolean;
	label: string;
	name: string;
	onFocus: () => void;
	onValueChange: (newValue: BlockValueType) => void;
	renderMenuItem: (entry: EquipmentListItemVM) => React.JSX.Element;
	sections: string[];
	selected: Nullable<number>;
	tooltipMessage?: React.ComponentProps<typeof Dropdown>['tooltipMessage'];
	disabled: boolean;
	readonlyEquipmentText?: string;
}

type Props = OwnProps;

const EquipmentDropdown: React.FC<Props> = (props) => {
	const {
		filterBy,
		isPreview,
		disabled,
		label,
		name,
		tooltipMessage,
		onValueChange,
		onFocus,
		renderMenuItem,
		sections,
		selected,
		readonlyEquipmentText,
	} = props;

	return (
		<>
			{isPreview ?
				<LockedValue label={label} value={readonlyEquipmentText ?? 'None'} />
				:
				<ReduxField
					className="field-report-block__field--other-dropdown"
					component={Dropdown}
					disabled={disabled}
					filterable={true}
					filterBy={filterBy}
					hasBlankOption={true}
					id={name}
					isStandalone={true}
					label={label}
					name={name}
					onFocus={onFocus}
					onValueChange={onValueChange}
					renderMenuItem={renderMenuItem}
					sectionOptionsKey="options"
					sections={sections}
					sectionTitleKey="title"
					selected={selected}
					tooltipMessage={tooltipMessage}
					useSectionList={true}
					value={selected}
					valueKey="id"
					withCaret={true}
				/>
			}
		</>
	);
};

export default React.memo(EquipmentDropdown);
