import * as React from 'react';
import { compose } from 'redux';
import type { ResolveThunks } from 'react-redux';
import { connect } from 'react-redux';
import type { InjectedFormProps } from 'redux-form';
import { reduxForm } from 'redux-form';
import type { CustomRouteComponentProps } from 'react-router-dom';
import { Row, Button } from 'react-bootstrap';

import type { CompanyRequestModel } from 'ab-requestModels/company.requestModel';

import * as CompanyActions from 'af-actions/companies';

import CreateCompanyForm from 'af-components/SharedForms/Company/BasicInfoForm';
import { validate } from 'af-components/SharedForms/Company/BasicInfoForm/validations';
import SubmitButton from 'af-components/SubmitButton';
import Breadcrumbs from 'af-components/Breadcrumbs';

import CLIENT from 'af-constants/routes/client';
import { COMPANY_CREATE } from 'af-constants/reduxForms';
import { useLocation, useNavigate } from 'react-router-dom-v5-compat';

interface RouteParams {
	orgAlias: string;
}

interface DispatchProps {
	createCompany: typeof CompanyActions.createCompany;
}

type OwnProps = CustomRouteComponentProps<RouteParams>;
type FormOwnProps = OwnProps & ResolveThunks<DispatchProps>;
type Props = FormOwnProps & InjectedFormProps<CompanyRequestModel, FormOwnProps>;

const CreateCompany: React.FC<Props> = (props) => {

	const {
		invalid,
		submitting,
		handleSubmit,
		createCompany,
	} = props;

	const navigate = useNavigate();
	const location = useLocation();
	const orgAlias = React.useMemo(() => location.state.orgAlias, [location.state.orgAlias]);

	const [image, setImage] = React.useState<string | File | null>(null);
	const [shouldDeleteImage, setShouldDeleteImage] = React.useState<boolean>(false);

	const _createCompany = React.useCallback(async (form: CompanyRequestModel) => {
		form.image = image;
		form.deleteImage = shouldDeleteImage;

		await createCompany(form, orgAlias);
	}, [createCompany, image, orgAlias, shouldDeleteImage]);

	const cancelCreate = React.useCallback(async () => {
		navigate(CLIENT.ORGANIZATION.SETTINGS.INFO(orgAlias));
	}, [navigate, orgAlias]);

	return (
		<div className="form-segment">
			<Breadcrumbs items={[{ label: 'Create new Company' }]} />
			<div className="form-box">
				<CreateCompanyForm
					{...props}
					formName={COMPANY_CREATE}
					image={image}
					setImage={setImage}
					setShouldDeleteImage={setShouldDeleteImage}
				/>
				<Row className="row--submit">
					<Button
						onClick={cancelCreate}
						variant="info"
					>
						Cancel
					</Button>
					<SubmitButton
						disabled={invalid}
						label="Save"
						onClick={handleSubmit(_createCompany)}
						reduxFormSubmitting={submitting}
						submitKey={COMPANY_CREATE}
					/>
				</Row>
			</div>
		</div>
	);
};

function mapDispatchToProps(): DispatchProps {
	return {
		createCompany: CompanyActions.createCompany,
	};
}

const enhance = compose<React.ComponentType<OwnProps>>(
	connect<null, DispatchProps, OwnProps>(null, mapDispatchToProps()),
	reduxForm<CompanyRequestModel, FormOwnProps>({ form: COMPANY_CREATE, validate })
);

export default enhance(CreateCompany);
