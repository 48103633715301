import * as React from 'react';
import type { InjectedFormProps } from 'redux-form';
import { Field } from 'redux-form';
import type { CustomRouteComponentProps } from 'react-router-dom';
import { Form, Row, Col } from 'react-bootstrap';

import type SaleRequestModel from 'ab-requestModels/sale.requestModel';

import SubmitButton from 'af-components/SubmitButton';
import BackButton from 'af-components/BackButton';

import Input from 'af-fields/Input';
import AddressField from 'af-fields/AddressField';

import { phoneNormalizer } from 'ab-utils/reduxForms.util';

interface PathParams {
	id?: string;
}

interface OwnProps extends CustomRouteComponentProps<PathParams>, InjectedFormProps<SaleRequestModel> {
	onSubmit: (form: SaleRequestModel) => void;
	companyName: string;
}

type Props = OwnProps;

class SaleForm extends React.PureComponent<Props> {
	render() {
		const {
			handleSubmit,
			submitting,
			invalid,
			form,
			onSubmit,
		} = this.props;

		return (
			<Form onSubmit={handleSubmit(onSubmit)}>
				<div className="form-box">
					<Row className="row--padded-top">
						<Col lg={12} md={16}>
							<Field
								component={Input}
								cursorControl={true}
								label="Full Name *"
								name="fullName"
								placeholder="Enter full name"
								type="text"
							/>
						</Col>
						<Col lg={6} md={8}>
							<Field
								component={Input}
								label="Title *"
								name="title"
								placeholder="Enter title"
								type="text"
							/>
						</Col>
					</Row>
					<Row>
						<Col lg={6} md={8}>
							<Field
								component={Input}
								cursorControl={true}
								label="Email *"
								name="email"
								placeholder="Enter email"
								type="text"
							/>
						</Col>
						<Col lg={6} md={8}>
							<Field
								component={Input}
								label="Mobile Phone *"
								name="phone"
								normalize={phoneNormalizer}
								placeholder="Enter mobile phone"
								type="text"
							/>
						</Col>
						<Col lg={6} md={8}>
							<Field
								component={Input}
								label="Fax *"
								name="fax"
								normalize={phoneNormalizer}
								placeholder="Enter fax"
								type="text"
							/>
						</Col>
					</Row>
					<Row>
						<AddressField
							aa1PropName="aa1"
							aa2PropName="aa2"
							aa3PropName="aa3"
							countryPropName="country"
							disableMap={true}
							formName={form}
							latitudePropName="latitude"
							localityPropName="locality"
							locationPropName="street"
							longitudePropName="longitude"
							postalCodePropName="postalCode"
							postalOfficeBoxPropName="postalOfficeBoxCode"
							routePropName="route"
							streetNumberPropName="streetNumber"
							suitePropName="suite"
						/>
					</Row>
					<Row className="row--submit">
						<BackButton />
						<SubmitButton
							disabled={invalid}
							reduxFormSubmitting={submitting}
							variant="primary"
						/>
					</Row>
				</div>
			</Form>
		);
	}
}

export default SaleForm;
