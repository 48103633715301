import type * as AWS from 'aws-sdk';

import { getDirectoryPath } from 'acceligent-shared/utils/blobStorage';

import { s3 } from '../infrastructure/configuration/blobStorage.config';

import { DEFAULT_SIGNED_URL_EXPIRE_TIME } from 'ab-common/constants/value';

type DirectoryType = string | string[];

/**
 * Generates presigned url which allows you to access the private file in s3 bucket
 * @param {string | string[]} directory - single or list of directories
 * @param {string} filename - name of the file
 * @param {number} expires - time in seconds
 */
export const generatePresignedGetUrl = (
	directory: DirectoryType,
	filename: string
): string => {
	if (!s3) {
		throw new Error('S3 Bucket not configured, check AWS_S3_BUCKET_NAME');
	}

	const bucketName = process.env.AWS_S3_BUCKET_NAME;
	const params = {
		Bucket: bucketName,
		Key: `${getDirectoryPath(directory)}/${filename}`,
		Expires: DEFAULT_SIGNED_URL_EXPIRE_TIME,
	};

	return s3.getSignedUrl('getObject', params);
};

export const fileExists = async (directory: DirectoryType, filename: string) => {
	const bucketName = process.env.AWS_S3_BUCKET_NAME;

	if (!bucketName || !s3) {
		console.info('S3 Bucket not configured, check AWS_S3_BUCKET_NAME');
		return null;
	}

	const params: AWS.S3.HeadObjectRequest = {
		Bucket: bucketName,
		Key: `${getDirectoryPath(directory)}/${filename}`,
	};

	try {
		await s3.headObject(params).promise();
	} catch {
		return false;
	}
	return true;
};
