import type { TableQuery } from 'ab-common/dataStructures/tableQuery';

import baseRoute from '../baseRoute';

export * from './viewModel';
export * from './queryParams';

type RouteDefinition = `${typeof baseRoute}/${number | ':employeeId'}/relatedWorkOrders${`?query=${string}&startDate=${string}&endDate=${string}` | ''}`;

export const ROUTE: RouteDefinition = `${baseRoute}/:employeeId/relatedWorkOrders`;

export const URL = (employeeId: number, startDate: string, endDate: string, tableQuery: TableQuery): RouteDefinition =>
	`${baseRoute}/${employeeId}/relatedWorkOrders?query=${encodeURIComponent((JSON.stringify(tableQuery)))}&startDate=${startDate}&endDate=${endDate}`;
