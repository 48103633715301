import * as React from 'react';

import type FormModel from './formModel';

import type { MultiTagOptionProps } from 'af-fields/MultiTagSelect';

import Tag from 'af-components/Controls/Tag';

type Props = MultiTagOptionProps<FormModel['itemCategories'][0]>;

const ItemCategoryOption = (props: Props) => {
	const { data, innerProps, getStyles } = props;

	return (
		<div style={getStyles('option', props)} {...innerProps}>
			<Tag size="small" transparent>
				<span>{data.name}</span>
			</Tag>
		</div>
	);
};

export default React.memo(ItemCategoryOption);
