import * as React from 'react';

import { DISPLAY_VIEW_NUMBER_OF_ITEMS_IN_COLUMN } from 'af-constants/values';

import type { DisplayViewEmployeeViewModel } from 'ab-viewModels/scheduleBoardDisplayView.viewModel';

import UnavailableEmployeeColumn from './UnavailableEmployeeColumn';

import { chunkArray } from 'ab-utils/array.util';

interface OwnProps {
	status: Nullable<string>;
	employeeList: DisplayViewEmployeeViewModel[];
}

type Props = OwnProps;

const UnavailableEmployeeGroup: React.FC<Props> = (props: Props) => {
	const { status, employeeList } = props;
	const employeeGroups = chunkArray<DisplayViewEmployeeViewModel>(employeeList, DISPLAY_VIEW_NUMBER_OF_ITEMS_IN_COLUMN);

	return (
		<div className="display-view-group">
			<div className="display-view-group-title">
				<img src="/images/elements/ic_state_incomplete.svg" />
				<span className="ellipsed display-view-group-title-text">
					{status}
				</span>
			</div>
			<div className="display-view-columns-wrapper">
				{
					employeeGroups.map((_employeeGroup, _index) => (
						<UnavailableEmployeeColumn employeeGroup={_employeeGroup} key={_index} />
					))
				}
			</div>
		</div>
	);
};

export default React.memo(UnavailableEmployeeGroup);
