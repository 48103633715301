import * as React from 'react';
import { compose } from 'redux';
import type { ConnectedProps } from 'react-redux';
import { connect } from 'react-redux';

import type { RootState } from 'af-reducers';

import EmployeeGroup from './EmployeeGroup';

interface OwnProps {
	dueDate: string;
	isDragAndDropDisabled: boolean;
	hasPermissionsToEditScheduleBoard: boolean;
}

type Props = OwnProps & ConnectedProps<typeof connector>;

const UnavailableEmployees: React.FC<Props> = (props) => {
	const {
		dueDate,
		employeeStatuses = [],
		isDragAndDropDisabled,
		hasPermissionsToEditScheduleBoard,
	} = props;

	if (!employeeStatuses?.length) {
		return null;
	}

	return (
		<div className="schedule-board-toolbar-employees-row">
			<label>Labor Unavailable</label>
			<div className="schedule-board-toolbar-employees-wrapper">
				<div className="schedule-board-toolbar-employees">
					{
						employeeStatuses.map(({ id: _empStatusId, title: _empStatus, isDeleted }) => (
							<EmployeeGroup
								available={false}
								dueDate={dueDate}
								groupId={_empStatusId}
								groupTitle={_empStatus}
								hasPermissionsToEditScheduleBoard={hasPermissionsToEditScheduleBoard}
								hasPermissionsToSendNotifications={false}
								hasReason={true}
								isDeleted={isDeleted}
								isDragAndDropDisabled={isDragAndDropDisabled}
								key={_empStatusId}
							/>
						))
					}
				</div>
			</div>
		</div>
	);
};

function mapStateToProps(state: RootState) {
	return {
		employeeStatuses: state?.scheduleBoard?.toolbarEmployeeGroupTitles?.unavailable,
	};
}

const connector = connect(mapStateToProps);

const enhance = compose<React.ComponentType<OwnProps>>(
	React.memo,
	connector
);

export default enhance(UnavailableEmployees);
