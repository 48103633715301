import baseRoute from '../baseRoute';

export * from './viewModel';
export * from './queryParams';

type RouteDefinition = `${typeof baseRoute}/condensed${`?startDate=${string}&endDate=${string}&page=${number}&limit=${number}&filterBy=${string}&sortBy=${string}&ascending=${boolean}` | ''}`;

export const ROUTE: RouteDefinition = `${baseRoute}/condensed`;

export const URL = (
	startDate: string,
	endDate: string,
	page: number,
	limit: number,
	filterText: string,
	sortBy: 'workOrderCode' | 'location' | 'dueDate' | 'reviewStatus',
	sortAsc: boolean
): RouteDefinition =>
	`${baseRoute}/condensed?startDate=${startDate}&endDate=${endDate}&page=${page}&limit=${limit}&filterBy=${encodeURIComponent(filterText)}&sortBy=${sortBy}&ascending=${sortAsc}`;
