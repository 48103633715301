import * as React from 'react';
import type { WrappedFieldArrayProps } from 'redux-form';

import type { AccountViewModel } from 'ab-viewModels/account.viewModel';

import AccountItem from './AccountItem';

import NotificationPriority from 'ab-enums/notificationPriority.enum';

export interface OwnProps {
	onExcludeAccount: (userGroupName: string, accountId: number) => void;
	userGroupName: string;
	notificationPriority: NotificationPriority;
}

interface State {
	showModal: boolean;
}

type Props = OwnProps & WrappedFieldArrayProps<AccountViewModel>;

class Accounts extends React.PureComponent<Props, State> {
	onExcludeAccount = (index: number, accountId: number) => {
		const { fields, onExcludeAccount, userGroupName } = this.props;
		fields.remove(index);
		onExcludeAccount(userGroupName, accountId);
	};

	render() {
		const { fields, notificationPriority } = this.props;
		return fields.map((_accountFieldName, _index) => {
			const _account = fields.get(_index);
			const hasEmail = !!_account.email;
			const hasPhoneNumber = !!_account.phoneNumber;

			const willReceiveNotification =
				(notificationPriority === NotificationPriority.EMAIL_ONLY && hasEmail)
				|| (notificationPriority === NotificationPriority.SMS_ONLY && hasPhoneNumber)
				|| [NotificationPriority.EMAIL_FIRST, NotificationPriority.SMS_FIRST, NotificationPriority.SEND_BOTH].includes(notificationPriority)
				&& (hasEmail || hasPhoneNumber);

			return (
				<AccountItem
					account={_account}
					hasEmail={hasEmail}
					index={_index}
					key={_account.uniqueId}
					onExcludeAccount={this.onExcludeAccount}
					willReceiveNotification={willReceiveNotification}
				/>
			);
		});
	}
}

export default Accounts;
