import type { CSVRow, CSVData } from 'ab-viewModels/csv.viewModel';

import type { CSVAccountRM, CSVBulkAccountRM } from 'ab-requestModels/account/upload.requestModel';

import { toRequestModelWithRawPhoneNumbers } from 'ab-utils/form.util';

const parseRow = (row: CSVRow): CSVAccountRM => {
	const member: CSVAccountRM = {
		email: '',
		phoneNumber: '',
		uniqueId: '',
		firstName: '',
		lastName: '',
	};

	Object.keys(row).forEach((_column) => {
		member[_column] = row[_column] ?? member[_column];
	});

	return toRequestModelWithRawPhoneNumbers(member, 'phoneNumber');
};

export default (data: CSVData): CSVBulkAccountRM => ({ accounts: data.map(parseRow), importedCount: data.length });
