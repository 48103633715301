import * as React from 'react';

import type { ColorPalette } from 'acceligent-shared/enums/color';

import type { SkillViewModel } from 'ab-viewModels/skill.viewModel';

import Pill from 'af-components/Pills/Pill';

interface OwnProps {
	skill: SkillViewModel;
	toEdit?: (id: number) => void;
	deleteSkill?: (id: number, color: string | ColorPalette) => void;
}

type Props = OwnProps;

export default class SkillPreview extends React.PureComponent<Props> {

	edit = () => {
		const { skill, toEdit } = this.props;
		if (toEdit) {
			toEdit(skill.id);
		}
	};

	deleteSkill = () => {
		const { skill, deleteSkill } = this.props;
		if (deleteSkill) {
			deleteSkill(skill.id, skill.color.toUpperCase());
		}
	};

	modalBody = (skill: SkillViewModel) => (
		<>
			You are about to delete skill <b>{skill.name}</b>.
			Are you sure you want to continue?
		</>
	);

	render() {
		const { skill, deleteSkill, toEdit } = this.props;

		return (
			<Pill
				color={skill.color}
				item={skill}
				labelKey="name"
				modalBody={this.modalBody}
				modalTitle="Delete Skill"
				onClick={toEdit ? this.edit : undefined}
				removeItem={deleteSkill ? this.deleteSkill : undefined}
			/>
		);
	}
}
