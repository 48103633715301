import * as React from 'react';
import { useOutletContext } from 'react-router-dom-v5-compat';

import styles from '../styles.module.scss';
import JobHazardSummarySection from './Sections/JobHazardSummarySection';
import PermitsRequiredSection from './Sections/PermitsRequired';
import SafetySection from './Sections/SafetySection';
import EnvironmentalProtectionSection from './Sections/EnvironmentalProtectionSection';
import PublicSafetySection from './Sections/PublicSafetySection';
import StatusSection from './Sections/StatusSection';
import type { WorkRequestFormOutletContext } from '../types';

const JobHazardAssessmentFormTab: React.FC = () => {
	const jobHazardSummaryRef = React.useRef<Nullable<HTMLDivElement>>(null);

	React.useEffect(() => {
		if (jobHazardSummaryRef.current) {
			jobHazardSummaryRef.current.scrollIntoView({ block: 'end' });
		}
	}, []);

	const { change, formName } = useOutletContext<WorkRequestFormOutletContext>();
	return (
		<div className={styles['work-request-form__tab-content']}>
			<JobHazardSummarySection jobHazardSummaryRef={jobHazardSummaryRef} />
			<PermitsRequiredSection />
			<SafetySection />
			<EnvironmentalProtectionSection />
			<PublicSafetySection />
			<StatusSection change={change} formName={formName} />
		</div>
	);
};

export default React.memo(JobHazardAssessmentFormTab);
