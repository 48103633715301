interface BaseModalRouteData {
	path: string;
	element: React.ReactElement;
}

export interface ModalRouteData extends BaseModalRouteData {
	children?: BaseModalRouteData[];
}

export interface NavigationManager {
	back(): boolean;
	forward(): boolean;
	navigate(uri: string): void;
	get currentUri(): string;
}

export class ModalNavigationManager implements NavigationManager {
	private _backStack: string[];
	private _forwardStack: string[];
	currentUri: string;

	constructor() {
		this._backStack = [];
		this._forwardStack = [];
		this.currentUri = '';
	}

	back(): boolean {
		if (this._backStack.length <= 1) return false;
		const uri: string = this._backStack.splice(-1, 1)[0];
		this._forwardStack.push(uri);
		this.currentUri = this._backStack[this._backStack.length - 1];
		return true;
	}

	forward(): boolean {
		if (this._forwardStack.length < 1) return false;
		const uri: string = this._forwardStack.splice(-1, 1)[0];
		this._backStack.push(uri);
		this.currentUri = this._backStack[this._backStack.length - 1];
		return true;
	}

	navigate(uri: string): void {
		this._forwardStack = [];
		if (uri !== this._backStack[this._backStack.length - 1]) {
			this._backStack.push(uri);
		}
		this.currentUri = uri;
	}
}
