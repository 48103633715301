import * as React from 'react';
import type { ConnectedProps } from 'react-redux';
import { connect } from 'react-redux';
import type { InjectedFormProps} from 'redux-form';
import { reduxForm, initialize } from 'redux-form';
import type { CustomRouteComponentProps } from 'react-router-dom';
import { compose } from 'redux';

import type JobStatusRequestModel from 'ab-requestModels/jobStatus.requestModel';

import type { RootState } from 'af-reducers';

import { JOB_STATUS_EDIT } from 'af-constants/reduxForms';

import * as JobStatusActions from 'af-actions/jobStatus';

import JobStatusForm from 'af-components/SharedForms/Resources/JobStatus';
import { validate } from 'af-components/SharedForms/Resources/JobStatus/validation';
import Breadcrumbs from 'af-components/Breadcrumbs';

import CLIENT from 'af-constants/routes/client';
import JobStatusFormModel from './formModel';

interface PathParams {
	id: string;
}

type OwnProps = CustomRouteComponentProps<PathParams>;

type FormOwnProps = OwnProps & ConnectedProps<typeof connector>;
type Props = FormOwnProps & InjectedFormProps<JobStatusFormModel, FormOwnProps>;

const Edit: React.FC<Props> = (props) => {

	const {
		location: { state: { orgAlias } },
		match: { params: { id } },
		history,
		companyName,
		findById,
		update,
		initializeForm,
	} = props;

	const fetchJobStatus = React.useCallback(async () => {
		const jobStatus = await findById(id);

		initializeForm(JOB_STATUS_EDIT, jobStatus);
	}, [id, initializeForm, findById]);

	React.useEffect(() => {
		fetchJobStatus();
	}, [fetchJobStatus]);

	const editJobStatus = React.useCallback(async (form: JobStatusFormModel) => {
		await update(JobStatusFormModel.toRM(form), form.id);
		history.push(CLIENT.COMPANY.RESOURCES.JOB_STATUS.LIST(orgAlias, companyName));
	}, [history, orgAlias, companyName, update]);

	const items = React.useMemo(() => ([
		{ label: 'Job Status', url: CLIENT.COMPANY.RESOURCES.JOB_STATUS.LIST(orgAlias, companyName) },
		{ label: 'Preview', url: CLIENT.COMPANY.RESOURCES.JOB_STATUS.PREVIEW(id, orgAlias, companyName) },
		{ label: 'Edit' },
	]), [id, orgAlias, companyName]);

	return (
		<div className="form-segment">
			<Breadcrumbs
				items={items}
			/>
			<JobStatusForm
				{...props}
				onSubmit={editJobStatus}
			/>
		</div>
	);
};

const mapStateToProps = (state: RootState) => {
	const { companyData } = state.user;
	if (!companyData) {
		throw new Error('User not logged in');
	}

	return {
		companyName: companyData.name,
	};
};

const mapDispatchToProps = () => ({
	findById: JobStatusActions.findById,
	update: JobStatusActions.update,
	initializeForm: initialize,
});

const connector = connect(mapStateToProps, mapDispatchToProps());

const enhance = compose<React.ComponentClass<OwnProps>>(
	connector,
	reduxForm<JobStatusRequestModel, FormOwnProps>({ form: JOB_STATUS_EDIT, validate }),
	React.memo
);

export default enhance(Edit);
