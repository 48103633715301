import { SubmissionError } from 'redux-form';
import type { Dispatch, AnyAction } from 'redux';
import { toast } from 'react-toastify';

import type CountryCode from 'acceligent-shared/enums/countryCode';
import UserPermission from 'acceligent-shared/enums/userPermission';

import { LOGIN_MAX_ATTEMPTS } from 'ab-common/constants/value';

import CLIENT from 'af-constants/routes/client';
import * as SettingsKeys from 'af-constants/settingsKeys';
import { USER_PASSWORD_FORGOTTEN } from 'af-constants/reduxForms';

import * as User from 'ab-viewModels/user.viewModel';

import type * as UserRequestModel from 'ab-requestModels/users.requestModel';

import type { GetRootState } from 'af-reducers';

import * as authenticationActionCreators from 'af-actions/authentication/authentication.actionCreators';
import * as generalActionCreators from 'af-actions/general/general.actionCreators';

import * as TextUtil from 'ab-utils/text.util';

import { USER_EMAIL_LOGIN, USER_PHONE_LOGIN } from 'af-constants/reduxForms';

import * as SettingsUtil from 'af-utils/settings.util';
import socket from 'af-utils/socket.util';
import { http } from 'af-utils/http.util';
import { setCompanyNameInPath } from 'af-utils/window.util';
import type { ErrorOverride } from 'af-utils/actions.util';
import { errorHandler, defaultRedirectUrl } from 'af-utils/actions.util';
import { identifyLogRocketSession } from 'af-utils/logRocket.util';
import * as LocalStorageUtil from 'af-utils/localStorage.util';
import NR from 'af-utils/newRelic.util';

import * as EmailLoginAPI from 'ab-api/web/authentication/emailLogin';
import * as RefreshAPI from 'ab-api/web/authentication/refresh';
import * as FinalizeAPI from 'ab-api/web/authentication/finalize';
import * as PasswordForgottenAPI from 'ab-api/web/authentication/passwordForgotten';
import * as PhoneLoginAPI from 'ab-api/web/authentication/phoneLogin';
import * as PlatformAdminOrganizationLoginAPI from 'ab-api/web/authentication/platformAdminOrganizationLogin';
import * as PhoneCodeRequestAPI from 'ab-api/web/authentication/phoneCodeRequest';
import * as ResetPasswordAPI from 'ab-api/web/authentication/resetPassword';
import * as LoginToLMSViaEmailAPI from 'ab-api/web/authentication/loginToLMSViaEmail';
import * as LoginToLMSViaPhoneAPI from 'ab-api/web/authentication/loginToLMSViaPhone';

const _nr = NR.init();

// Private Functions - non-exported

/**
 * According to security guidelines, authentication related client errors should be handled in the same way (i.e. output the same message).
 * This function assigns the given handler to status codes `400` and `404`.
 *
 * NOTE: The BE should conform with this notion as well if possible (e.g. always send a blank 400 error)
 *
 * @param handler the action applied in cases of client errors
 */
function _handleAuthClientErrors(handler: () => void): ErrorOverride {
	return {
		err400: handler,
		err404: handler,
	};
}

// Private Functions - exported only to af-actions

export const _logoutUser = (
	orgAlias: string,
	dispatch: Dispatch<authenticationActionCreators.AuthenticationAction | generalActionCreators.GeneralAction>
) => {
	LocalStorageUtil.removeSignInForOrg(orgAlias);
	_nr.setUserId(null);

	dispatch(authenticationActionCreators.LOGOUT_USER());
	dispatch(generalActionCreators.APP_READY());
};

export const _refreshUser = (
	response: User.UserViewModel,
	companyName: Nullable<string>,
	dispatch: Dispatch<authenticationActionCreators.AuthenticationAction | generalActionCreators.GeneralAction>,
	redirectTo: (url: string) => void,
	redirectAtEndUrl?: string
): void => {
	const user: User.UserData = response.user;
	const organization: User.OrganizationData = response.organization;
	let company: User.CompanyData | undefined;

	const existingSignIn: Nullable<User.SignInData> = LocalStorageUtil.getSignInForOrg(organization.alias);

	if (existingSignIn) {
		// There was a user previously signed into this organization. Was it the same one?
		if (existingSignIn.userId !== user.id) {
			// Data does not match. Clear localstorage and logout
			return _logoutUser(organization.alias, dispatch);
		}
	}

	if (companyName && !organization.companies.length) {
		// return 404, provided URL does not exist. No company exists
		return redirectTo(CLIENT.ERROR.ERR404());
	} else if (!companyName && !organization.companies.length) {
		// NOTE: This scenario should be skipped. I know it's not pretty :)
	} else if (companyName) {
		// We know where we must land (through direct URL)
		company = organization.companies.find((_company: User.CompanyData) =>
			(_company.name === companyName || TextUtil.removeSpaces(_company.name) === companyName)
		);

		if (!company) {
			// return 404, provided URL does not exist
			return redirectTo(CLIENT.ERROR.ERR404());
		}
	} else {
		if (existingSignIn?.companyId) {
			company = organization.companies.find((_company: User.CompanyData) => (_company.id === existingSignIn.companyId));
		}
		// Company could have been deleted - we need to continue with checks even if nothing was found in previous if statement
		if (!company) {
			company = organization.companies[0];
		}
	}

	const currentCompanyId: number | undefined = company?.id ?? undefined;
	const currentCompanyName: string | undefined = company?.name ? TextUtil.removeSpaces(company.name) : undefined;

	if (!user.token) {
		throw new Error('User token missing');
	}

	if (existingSignIn) {
		existingSignIn.companyId = currentCompanyId;
		existingSignIn.companyName = currentCompanyName;
		// we refresh the token every time we access the app (i.e. call refreshUser)
		existingSignIn.token = user.token;
	}
	const currentSignIn = existingSignIn && (user.isFinalized === existingSignIn.isFinalized) ? existingSignIn : new User.SignInData(
		organization.alias, user.id,
		user.token,
		organization.isPlatformAdmin,
		user.isFinalized,
		user.email,
		user.loginMethod,
		currentCompanyId,
		currentCompanyName
	);
	LocalStorageUtil.upsertSignInForOrg(organization.alias, currentSignIn);

	if (!redirectAtEndUrl) {
		const _companyName: string | undefined = company?.name;
		const reloadInProgress = currentCompanyName && _companyName && setCompanyNameInPath(organization.alias, _companyName);
		if (reloadInProgress) {
			// no point in initializing the state and socket if the page is gonna be reloaded
			return;
		}
	}

	identifyLogRocketSession(user, company);

	// will init connection with existing callbacks
	socket.getConnection();

	dispatch(authenticationActionCreators.SET_CURRENT_ORGANIZATION(organization));
	currentCompanyId && dispatch(authenticationActionCreators.SET_CURRENT_COMPANY(currentCompanyId));
	dispatch(authenticationActionCreators.LOGIN_USER(user));
	dispatch(generalActionCreators.APP_READY());

	const systemNotifications = response.systemNotifications;

	const seenNotifications = JSON.parse(SettingsUtil.getItem(SettingsKeys.TOAST()) ?? '{}') || {};

	(systemNotifications ?? []).forEach((_sn) => {
		if (!seenNotifications[_sn.id]) {
			toast[_sn.type.toLowerCase()](_sn.content, {
				onClose: () => (SettingsUtil.setToastAsSeen(_sn.id.toString())),
				autoClose: false,
			});
		}
	});

	if (redirectAtEndUrl) {
		redirectTo(redirectAtEndUrl);
	}
};

const _setCurrentCompany = (orgAlias: string, companyId: number, companyName: string, dispatch: Dispatch<AnyAction>): void => {
	LocalStorageUtil.updateSignInForOrg(orgAlias, { companyId, companyName });
	dispatch(authenticationActionCreators.SET_CURRENT_COMPANY(companyId));
};

// Public functions

export function finalize(form: FinalizeAPI.W_Authentication_Finalize_RM, organizationData: User.OrganizationData, orgAlias: string) {
	return async (
		dispatch: Dispatch<authenticationActionCreators.AuthenticationAction | generalActionCreators.GeneralAction>,
		getState: GetRootState, { redirectTo }
	) => {

		const action = async () => {
			const response = await http.put<FinalizeAPI.W_Authentication_Finalize_VM>(FinalizeAPI.URL(), form);

			if (response.user.isFinalized) {
				LocalStorageUtil.updateSignInForOrg(orgAlias, { isFinalized: true }, true);
			}

			_refreshUser(response, null, dispatch, redirectTo);

			dispatch(authenticationActionCreators.AUTH_SUCCESS(true));

			const companies = response?.organization?.companies ?? [];
			if (!companies.length && [UserPermission.OWNER, UserPermission.ADMIN].includes(response.user.role)) {
				return redirectTo(CLIENT.COMPANY.CREATE(orgAlias));
			}

			const firstCompany = companies?.[0];
			return redirectTo(defaultRedirectUrl(
				orgAlias,
				firstCompany?.name,
				firstCompany?.permissions ?? [],
				firstCompany?.isCompanyAdmin,
				response.user.role
			));
		};

		const error: ErrorOverride = {
			..._handleAuthClientErrors(() => {
				throw new SubmissionError({ password: 'Cannot be the same as previously used passwords' });
			}),
		};

		return await errorHandler(action, dispatch, redirectTo, error);
	};
}

export function emailLogin(form: EmailLoginAPI.W_Authentication_EmailLogin_RM, redirectUrl?: string, loginAttempts?: number) {
	return async (
		dispatch: Dispatch<authenticationActionCreators.AuthenticationAction | generalActionCreators.GeneralAction>,
		getState: GetRootState, { redirectTo }
	) => {

		const action = async () => {
			const response = await http.post<EmailLoginAPI.W_Authentication_EmailLogin_VM>(EmailLoginAPI.URL(), form, { submitting: USER_EMAIL_LOGIN });
			_refreshUser(response, null, dispatch, redirectTo, redirectUrl);
		};

		const error: ErrorOverride = {
			..._handleAuthClientErrors(() => {
				if (loginAttempts && loginAttempts > 1) {
					throw new SubmissionError({ email: `Email or password incorrect. After ${LOGIN_MAX_ATTEMPTS} unsuccessful attempts your account will be temporarily locked.` });
				} else {
					throw new SubmissionError({ email: 'Email or password incorrect.' });
				}
			}),
		};

		return await errorHandler(action, dispatch, redirectTo, error);
	};
}

export function phoneLogin(phoneNumber: string, countryCode: CountryCode, phoneCode: UserRequestModel.UserPhoneCode, orgAlias: string, redirectUrl?: string) {
	return async (
		dispatch: Dispatch<authenticationActionCreators.AuthenticationAction | generalActionCreators.GeneralAction>,
		getState: GetRootState, { redirectTo }
	) => {

		const action = async () => {
			const form: PhoneLoginAPI.W_Authentication_PhoneLogin_RM = {
				phoneNumber,
				countryCode,
				orgAlias,
				...phoneCode,
			};
			const response = await http.post<PhoneLoginAPI.W_Authentication_PhoneLogin_VM>(PhoneLoginAPI.URL(), form);
			_refreshUser(response, null, dispatch, redirectTo, redirectUrl);
			dispatch(authenticationActionCreators.SET_PENDING_PHONE_NUMBER(null));
		};

		const error: ErrorOverride = {
			..._handleAuthClientErrors(() => {
				throw new SubmissionError({ activationCode: 'Code incorrect or expired. Please try again.' });
			}),
			err503: () => {
				throw new SubmissionError({ activationCode: 'Code incorrect or expired. Please try again.' });
			},
		};

		return await errorHandler(action, dispatch, redirectTo, error);
	};
}

export function lmsEmailLogin(form: LoginToLMSViaEmailAPI.W_Authentication_LoginToLMSViaEmail_RM) {
	return async (
		dispatch: Dispatch<authenticationActionCreators.AuthenticationAction | generalActionCreators.GeneralAction>,
		getState: GetRootState, { redirectTo }
	) => {

		const action = async () => {
			return await http.post<LoginToLMSViaEmailAPI.W_Authentication_LoginToLMSViaEmail_VM>(
				LoginToLMSViaEmailAPI.URL(),
				form,
				{ submitting: USER_EMAIL_LOGIN }
			);
		};

		const error: ErrorOverride = {
			..._handleAuthClientErrors(() => {
				throw new SubmissionError({ email: 'Email or password incorrect.' });
			}),
		};

		return await errorHandler(action, dispatch, redirectTo, error);
	};
}

export function lmsPhoneLogin(
	phoneNumber: string,
	countryCode: CountryCode,
	phoneCode: UserRequestModel.UserPhoneCode,
	orgAlias: string,
	authnRequestId: string) {
	return async (
		dispatch: Dispatch<authenticationActionCreators.AuthenticationAction | generalActionCreators.GeneralAction>,
		getState: GetRootState, { redirectTo }
	) => {

		const action = async () => {
			const form: LoginToLMSViaPhoneAPI.W_Authentication_LoginToLMSViaPhone_RM = {
				phoneNumber,
				countryCode,
				orgAlias,
				...phoneCode,
				authnRequestId,
			};
			const response = await http.post<LoginToLMSViaPhoneAPI.W_Authentication_LoginToLMSViaPhone_VM>(LoginToLMSViaPhoneAPI.URL(), form);
			dispatch(authenticationActionCreators.SET_PENDING_PHONE_NUMBER(null));
			return response;
		};

		const error: ErrorOverride = {
			..._handleAuthClientErrors(() => {
				throw new SubmissionError({ activationCode: 'Code incorrect or expired. Please try again.' });
			}),
			err503: () => {
				throw new SubmissionError({ activationCode: 'Code incorrect or expired. Please try again.' });
			},
		};

		return await errorHandler(action, dispatch, redirectTo, error);
	};
}

export function platformAdminOrganizationLogin(orgAlias: string) {
	return async (
		dispatch: Dispatch<authenticationActionCreators.AuthenticationAction | generalActionCreators.GeneralAction>,
		getState: GetRootState, { redirectTo }
	) => {

		const action = async () => {
			const response = await http.get<PlatformAdminOrganizationLoginAPI.W_Authentication_PlatformAdminOrganizationLogin_VM>(
				PlatformAdminOrganizationLoginAPI.URL(orgAlias)
			);
			_refreshUser(response, null, dispatch, redirectTo);
		};

		const error: ErrorOverride = {
			// if the organization is not found we still want to redirect client to login page
			err404: async () => {
				_logoutUser(orgAlias, dispatch);
				redirectTo(CLIENT.AUTH.LOGIN(orgAlias));
			},
		};

		return await errorHandler(action, dispatch, redirectTo, error);
	};
}

export function phoneCodeRequest(phoneNumber: string, countryCode: CountryCode, orgAlias: string) {
	return async (dispatch: Dispatch<authenticationActionCreators.AuthenticationAction>, getState: GetRootState, { redirectTo }) => {

		const action = async () => {
			const form: PhoneCodeRequestAPI.W_Authentication_PhoneCodeRequest_RM = {
				phoneNumber,
				countryCode,
				orgAlias,
			};
			await http.post<void>(PhoneCodeRequestAPI.URL(), form, { submitting: USER_PHONE_LOGIN });
			dispatch(authenticationActionCreators.SET_PENDING_PHONE_NUMBER(form.phoneNumber));
		};

		const error: ErrorOverride = {
			..._handleAuthClientErrors(() => {
				throw new SubmissionError({ phoneNumber: 'Mobile phone incorrect.' });
			}),
		};

		return await errorHandler(action, dispatch, redirectTo, error);
	};
}

export function passwordForgotten(form: PasswordForgottenAPI.W_Authentication_PasswordForgotten_RM) {
	return async (dispatch: Dispatch<authenticationActionCreators.AuthenticationAction>, getState: GetRootState, { redirectTo }) => {

		const action = async () => {
			await http.post<void>(PasswordForgottenAPI.URL(), form, { submitting: USER_PASSWORD_FORGOTTEN });
			dispatch(authenticationActionCreators.SET_FORGOTTEN_EMAIL(form.email));
		};

		const error: ErrorOverride = {
			..._handleAuthClientErrors(() => {
				throw new SubmissionError({ phoneNumber: 'Email incorrect.' });
			}),
		};

		return await errorHandler(action, dispatch, redirectTo, error);
	};
}

export function refreshCurrentUser(orgAlias: string, companyName: string) {
	return async (
		dispatch: Dispatch<authenticationActionCreators.AuthenticationAction | generalActionCreators.GeneralAction>,
		getState: GetRootState, { redirectTo }
	) => {

		const action = async () => {
			const response = await http.get<RefreshAPI.W_Authentication_Refresh_VM>(RefreshAPI.URL());
			_refreshUser(response, companyName, dispatch, redirectTo);
			_nr.setUserId(`${response?.user?.id}`);
		};

		const error: ErrorOverride = {
			// if the user is not found we still want to redirect client to login page
			err404: async () => {
				_logoutUser(orgAlias, dispatch);
				redirectTo(CLIENT.AUTH.LOGIN(orgAlias));
			},
		};

		return await errorHandler(action, dispatch, redirectTo, error);
	};
}

export function resetPassword(form: ResetPasswordAPI.W_Authentication_ResetPassword_RM) {
	return async (dispatch: Dispatch<authenticationActionCreators.AuthenticationAction>, getState: GetRootState, { redirectTo }) => {

		const action = async () => {
			await http.post<void>(ResetPasswordAPI.URL(), form);
		};

		return await errorHandler(action, dispatch, redirectTo);
	};
}

export function isAuthenticated(orgAlias: string) {
	return async (dispatch: Dispatch<authenticationActionCreators.AuthenticationAction>, getState: GetRootState, { redirectTo }) => {

		const action = async () => {
			const currentSignIn = LocalStorageUtil.getSignInForOrg(orgAlias);
			const isFinalized: boolean = currentSignIn?.isFinalized ?? false;

			dispatch(currentSignIn ? authenticationActionCreators.AUTH_SUCCESS(isFinalized) : authenticationActionCreators.AUTH_FAIL());
		};

		return await errorHandler(action, dispatch, redirectTo);

	};
}

export function logout() {
	return async (dispatch: Dispatch<authenticationActionCreators.AuthenticationAction>, getState: GetRootState, { redirectTo }) => {

		const action = async () => {
			localStorage.clear();
			dispatch(authenticationActionCreators.LOGOUT_USER());
		};

		return await errorHandler(action, dispatch, redirectTo);
	};
}

export function setCurrentCompany(orgAlias: string, companyId: number, companyName: string) {
	return (dispatch: Dispatch<authenticationActionCreators.AuthenticationAction>) => {
		_setCurrentCompany(orgAlias, companyId, TextUtil.removeSpaces(companyName), dispatch);
	};
}
