import * as React from 'react';
import type { CustomRouteComponentProps } from 'react-router-dom';
import { compose } from 'redux';
import type { ConnectedProps } from 'react-redux';
import { connect } from 'react-redux';
import type { InjectedFormProps } from 'redux-form';
import { reduxForm } from 'redux-form';

import type UpsertTemporaryEmployeeRM from 'ab-requestModels/temporaryEmployee.requestModel';

import type AgencyVM from 'ab-viewModels/agency/agency.viewModel';

import * as TemporaryEmployeeActions from 'af-actions/temporaryEmployee';
import * as AgencyActions from 'af-actions/agency';

import type { RootState } from 'af-reducers';

import CLIENT from 'af-constants/routes/client';
import { TEMPORARY_EMPLOYEE_EDIT } from 'af-constants/reduxForms';

import Breadcrumbs from 'af-components/Breadcrumbs';

import { fromVMtoRM } from './formModel';

import TemporaryEmployeeForm from '../Form';
import { validate } from '../Shared/validations';

interface PathParams {
	temporaryEmployeeId: string;
}

type OwnProps = CustomRouteComponentProps<PathParams>;

type FormProps = InjectedFormProps<UpsertTemporaryEmployeeRM>;

type Props = OwnProps & ConnectedProps<typeof connector> & FormProps;

interface State {
	agencies: Nullable<AgencyVM[]>;
}

class TemporaryEmployeeEdit extends React.PureComponent<Props, State> {

	state: State = {
		agencies: null,
	};

	async componentDidMount() {
		const { getEmployee, findAllAgencies, match: { params: { temporaryEmployeeId } }, initialize } = this.props;

		const employee = await getEmployee(+temporaryEmployeeId);
		employee && initialize(fromVMtoRM(employee));

		const agencies = await findAllAgencies();
		this.setState(() => ({ agencies }));
	}

	submit = async (data: UpsertTemporaryEmployeeRM) => {
		const { editEmployee, match: { params: { temporaryEmployeeId } } } = this.props;

		await editEmployee(+temporaryEmployeeId, data);
		this.goBack();
	};

	goBack = () => {
		const { location: { state: { orgAlias } }, companyName, history } = this.props;
		history.push(CLIENT.COMPANY.RESOURCES.TEMPORARY_EMPLOYEE.LIST(orgAlias, companyName));
	};

	render() {
		const {
			companyName,
			location: { state: { orgAlias } },
		} = this.props;
		const { agencies } = this.state;

		return (
			<div className="form-segment">
				<Breadcrumbs
					items={
						[
							{ label: 'Temporary labor', url: CLIENT.COMPANY.RESOURCES.TEMPORARY_EMPLOYEE.LIST(orgAlias, companyName) },
							{ label: 'Edit Temporary Employee' },
						]
					}
				/>
				<div className="form-box">
					<TemporaryEmployeeForm
						{...this.props}
						agencies={agencies}
						companyName={companyName}
						onSubmit={this.submit}
					/>
				</div>
			</div>
		);
	}
}

function mapStateToProps(state: RootState) {
	const { companyData } = state.user;
	if (!companyData) {
		throw new Error('User not logged in');
	}

	return {
		companyName: companyData.name,
	};
}

function mapDispatchToProps() {
	return {
		getEmployee: TemporaryEmployeeActions.findById,
		editEmployee: TemporaryEmployeeActions.update,
		findAllAgencies: AgencyActions.findAll,
	};
}

const connector = connect(mapStateToProps, mapDispatchToProps());

const enhance = compose<React.ComponentClass<OwnProps>>(
	connector,
	reduxForm({ form: TEMPORARY_EMPLOYEE_EDIT, validate })
);

export default enhance(TemporaryEmployeeEdit);
