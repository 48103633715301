import * as React from 'react';
import { FormGroup } from 'react-bootstrap';
import type { WrappedFieldProps, WrappedFieldInputProps } from 'redux-form';

import type { Props as PlainCheckboxProps } from 'af-components/Controls/Checkbox';
import PlainCheckbox from 'af-components/Controls/Checkbox';

interface Props extends WrappedFieldProps, WrappedFieldInputProps, PlainCheckboxProps {
	onValueChange?: (value: boolean, propName: string) => void;
	propName?: string;
	grouped?: boolean;
}

class Checkbox extends React.Component<Props> {
	static defaultProps: Partial<Props> = {
		grouped: false,
	};

	handleChange = (event: React.ChangeEvent<HTMLInputElement>) => {
		const { input, propName, onValueChange } = this.props;
		input.onChange(event.target.checked);

		onValueChange?.(event.target.checked, propName!);
	};

	renderCheckbox = () => {
		const {
			label,
			id,
			inline,
			bsClass,
			extraClass,
			capitalize,
			isCondensed,
			isDisabled,
			isSmall,
			isStandalone,
			isStandaloneRow,
			labelId,
			tooltipMessage,
			input: { value: isChecked },
		} = this.props;

		return (
			<>
				<PlainCheckbox
					bsClass={bsClass}
					capitalize={capitalize}
					extraClass={extraClass}
					handleChange={this.handleChange}
					id={id}
					inline={inline}
					isChecked={isChecked}
					isCondensed={isCondensed}
					isDisabled={isDisabled}
					isSmall={isSmall}
					isStandalone={isStandalone}
					isStandaloneRow={isStandaloneRow}
					label={label}
					labelId={labelId}
					tooltipMessage={tooltipMessage}
				/>
				{/* Hidden div used for print out */}
				<div
					id={`print-${id}`}
					style={{ display: 'none' }}
				>
					{isChecked ? 'Yes' : 'No'}
				</div>
			</>

		);
	};

	render() {
		const { grouped } = this.props;

		if (grouped) {
			return (<FormGroup>{this.renderCheckbox()}</FormGroup>);
		}
		return this.renderCheckbox();
	}
}

export default Checkbox;
