import * as React from 'react';
import type { FormErrorsWithArray } from 'redux-form';
import { Field, type WrappedFieldArrayProps } from 'redux-form';

import type ImportBillingCodeRM from 'ab-requestModels/billingCode/importBillingCode.requestModel';

import type { SimpleTableRow } from 'af-fields/SimpleTable';
import SimpleTableField from 'af-fields/SimpleTable';
import type UnitEnum from 'acceligent-shared/enums/quantityUnit';

import type JobUpsertFM from '../../formModel';

import type { CellContext, Row } from '@tanstack/react-table';
import Input from 'af-fields/Input';
import { nanoid } from 'nanoid';
import { UNIQUE_ID_SIZE } from 'ab-common/constants/value';
import type { FooterButton } from 'af-components/Controls/SimpleTable/types';
import Dropdown from 'af-fields/Dropdown';
import { QuantityUnitLabel } from 'acceligent-shared/enums/quantityUnit';

import styles from './styles.module.scss';
import { formatDecimalNumberForDecimalPoints, normalizeDecimalNumberForDecimalPoints } from 'ab-utils/reduxForms.util';
import { BILLING_CODE_DECIMAL_POINTS } from 'af-constants/values';
import { dollarFormatterUnitPrice } from 'af-utils/format.util';

export type BillingCodeFM = Required<JobUpsertFM>['billingCodes'][0];

interface DropdownOption<T> {
	id: T;
	label: string;
}

export type CustomFormErrors<Model> = { [K in keyof Model]: Model[K] extends object ? CustomFormErrors<Model[K]> : string };
export type BillingCodeErrors = CustomFormErrors<BillingCodeFM[]>;

const quantityUnitOptions: DropdownOption<UnitEnum>[] = Object.keys(QuantityUnitLabel)
	.map((_unit: UnitEnum) => ({ id: _unit, label: QuantityUnitLabel[_unit] }));

const formatDecimalNumber4DecimalPlaces =
	(value: string | number): string => formatDecimalNumberForDecimalPoints(value, BILLING_CODE_DECIMAL_POINTS);

const normalizeDecimalNumber4DecimalPlaces = (
	value: string | number,
	previousValue: string
): string => normalizeDecimalNumberForDecimalPoints(value, previousValue, BILLING_CODE_DECIMAL_POINTS);

export interface OwnProps {
	change: (fieldName: string, value: Metadata | string | number | boolean | null) => void;
	importedBillingCodes: ImportBillingCodeRM[];
	billingCodeSyncErrors: FormErrorsWithArray<JobUpsertFM['billingCodes'][0], string>[];
	billingCodeSubmitErrors: FormErrorsWithArray<JobUpsertFM['billingCodes'][0], string>[];
	lineItemNumberCounter: Record<number, number>;
	customerIdCounter: Record<string, number>;
	initialized: boolean;
	openBulkImportModal: () => void;
}

type Props = OwnProps & WrappedFieldArrayProps<BillingCodeFM>;

const BillingCodeArray: React.FC<Props> = React.memo((props) => {
	const {
		fields,
		change,
		importedBillingCodes,
		billingCodeSyncErrors,
		billingCodeSubmitErrors,
		lineItemNumberCounter,
		customerIdCounter,
		initialized,
		openBulkImportModal,
	} = props;

	const onAdd = React.useCallback(() => {
		fields.push({ isInEditMode: true, id: nanoid(UNIQUE_ID_SIZE) } as BillingCodeFM & SimpleTableRow);
	}, [fields]);

	const errors = React.useMemo(() => {
		if (billingCodeSyncErrors) {
			return billingCodeSyncErrors;
		}

		return billingCodeSubmitErrors ?? [];
	}, [billingCodeSyncErrors, billingCodeSubmitErrors]);

	React.useEffect(() => {
		// Can't use spread operator on type BillingCodeVM[]
		for (let index = 0; index < importedBillingCodes.length; index++) {
			const billingCode = importedBillingCodes[index];

			fields.push(
				{
					id: nanoid(UNIQUE_ID_SIZE),
					customerId: billingCode.customerId,
					customerNumber: billingCode.customerNumber ?? null,
					ownerId: billingCode.ownerId ?? null,
					ownerNumber: billingCode.ownerNumber ?? null,
					unit: billingCode.unit,
					unitPrice: billingCode.unitPrice,
					bidQuantity: billingCode.bidQuantity ? `${billingCode.bidQuantity}` : null,
					group: billingCode.group ?? null,
					lineItemNumber: billingCode.lineItemNumber,
					description: billingCode.description,
				}
			);
		}
		// `billingCodes` intentionally disregarded as it would trigger a loop where fields would get updated until we're out of memory
		// eslint-disable-next-line react-hooks/exhaustive-deps
	}, [importedBillingCodes]);

	const clearUnit = React.useCallback((fieldName: string) => {
		() => {
			change(`${fieldName}.unit`, null);
		};
		// eslint-disable-next-line react-hooks/exhaustive-deps
	}, []);

	const filterUnits = React.useCallback((option: DropdownOption<UnitEnum>, searchText: string) => {
		return option.label.toLowerCase().includes(searchText);
	}, []);

	const columns = React.useMemo(() => [
		{
			id: 'lineItemNumber',
			accessor: 'lineItemNumber',
			header: 'Line Item No. *',
			enableSorting: true,
			cell: (_cell: CellContext<BillingCodeFM & SimpleTableRow, number>) => {
				if (_cell.row.original.isInEditMode) {
					return (
						<Field
							component={Input}
							id="lineItemNumber"
							name={`${_cell.row.original.name}.lineItemNumber`}
							placeholder="0"
							type="number"
						/>
					);
				}
				return _cell.getValue() ?? 'N/A';
			},
		},
		{
			id: 'customerNumber',
			accessor: 'customerNumber',
			header: 'Customer No.',
			enableSorting: true,
			cell: (_cell: CellContext<BillingCodeFM & SimpleTableRow, number>) => {
				if (_cell.row.original.isInEditMode) {
					return (
						<Field
							component={Input}
							id="customerNumber"
							name={`${_cell.row.original.name}.customerNumber`}
							placeholder="0"
							type="number"
						/>
					);
				}
				return _cell.getValue() ?? 'N/A';
			},
		},
		{
			id: 'customerId',
			accessor: 'customerId',
			header: 'Customer ID *',
			enableSorting: true,
			cell: (_cell: CellContext<BillingCodeFM & SimpleTableRow, number>) => {
				if (_cell.row.original.isInEditMode) {
					return (
						<Field
							component={Input}
							id="customerId"
							name={`${_cell.row.original.name}.customerId`}
							placeholder="0"
							type="text"
						/>
					);
				}
				return _cell.getValue() ?? 'N/A';
			},
		},
		{
			id: 'ownerNumber',
			accessor: 'ownerNumber',
			header: 'Owner Number',
			enableSorting: true,
			cell: (_cell: CellContext<BillingCodeFM & SimpleTableRow, number>) => {
				if (_cell.row.original.isInEditMode) {
					return (
						<Field
							component={Input}
							id="ownerNumber"
							name={`${_cell.row.original.name}.ownerNumber`}
							placeholder="0"
							type="text"
						/>
					);
				}
				return _cell.getValue() ?? 'N/A';
			},
		},
		{
			id: 'ownerId',
			accessor: 'ownerId',
			header: 'Owner ID',
			enableSorting: true,
			cell: (_cell: CellContext<BillingCodeFM & SimpleTableRow, number>) => {
				if (_cell.row.original.isInEditMode) {
					return (
						<Field
							component={Input}
							id="ownerId"
							name={`${_cell.row.original.name}.ownerId`}
							placeholder="Code"
							type="text"
						/>
					);
				}
				return _cell.getValue() ?? 'N/A';
			},
		},
		{
			id: 'bidQuantity',
			accessor: 'bidQuantity',
			header: 'Bid Qty',
			enableSorting: true,
			cell: (_cell: CellContext<BillingCodeFM & SimpleTableRow, number>) => {
				if (_cell.row.original.isInEditMode) {
					return (
						<Field
							component={Input}
							id="bidQuantity"
							name={`${_cell.row.original.name}.bidQuantity`}
							placeholder="0"
							type="number"
						/>
					);
				}
				return _cell.getValue() ?? 'N/A';
			},
		},
		{
			id: 'unit',
			accessor: 'unit',
			header: 'Unit *',
			enableSorting: true,
			cell: (_cell: CellContext<BillingCodeFM & SimpleTableRow, number>) => {
				if (_cell.row.original.isInEditMode) {
					return (
						<Field
							className={styles['unit-price']}
							component={Dropdown}
							filterable={true}
							filterBy={filterUnits}
							fixed={true}
							id="unit"
							labelKey="label"
							name={`${_cell.row.original.name}.unit`}
							onClear={clearUnit(_cell.row.original.name)}
							options={quantityUnitOptions}
							placeholder="Select Unit"
							valueKey="id"
							withCaret={true}
						/>
					);
				}
				return _cell.getValue() ?? 'N/A';
			},
		},
		{
			id: 'unitPrice',
			accessor: 'unitPrice',
			header: 'Unit Price *',
			enableSorting: true,
			cell: (_cell: CellContext<BillingCodeFM & SimpleTableRow, number>) => {
				if (_cell.row.original.isInEditMode) {
					return (
						<Field
							component={Input}
							format={formatDecimalNumber4DecimalPlaces}
							id="unitPrice"
							isDollarValue={true}
							name={`${_cell.row.original.name}.unitPrice`}
							normalize={normalizeDecimalNumber4DecimalPlaces}
							placeholder="0"
							type="text"
						/>
					);
				}
				return dollarFormatterUnitPrice().format(_cell.getValue());
			},
		},
		{
			id: 'group',
			accessor: 'group',
			header: 'Group',
			enableSorting: true,
			cell: (_cell: CellContext<BillingCodeFM & SimpleTableRow, number>) => {
				if (_cell.row.original.isInEditMode) {
					return (
						<Field
							component={Input}
							id="group"
							name={`${_cell.row.original.name}.group`}
							placeholder="Enter Group"
							type="text"
						/>
					);
				}
				return _cell.getValue() ?? 'N/A';
			},
		},
		{
			id: 'description',
			accessor: 'description',
			header: 'Description *',
			enableSorting: true,
			cell: (_cell: CellContext<BillingCodeFM & SimpleTableRow, number>) => {
				if (_cell.row.original.isInEditMode) {
					return (
						<Field
							component={Input}
							id="description"
							name={`${_cell.row.original.name}.description`}
							placeholder="Enter Description"
						/>
					);
				}
				return _cell.getValue() ?? 'N/A';
			},
			size: '2fr',
		},
	], [clearUnit, filterUnits]);

	const footerButtons = React.useMemo<FooterButton[]>(() => [{
		iconName: 'icon-plus',
		label: 'Add Billing Code',
		onClick: onAdd,
	}], [onAdd]);

	const headerButtons = React.useMemo<FooterButton[]>(() => [{
		iconName: 'icon-upload',
		label: 'CSV Import',
		onClick: openBulkImportModal,
	}], [openBulkImportModal]);

	const rowClassName = React.useCallback((_row: Row<BillingCodeFM>) => {
		if (lineItemNumberCounter[_row.original.lineItemNumber] > 1
			|| customerIdCounter[_row.original.customerId] > 1
		) {
			return styles['error-row'];
		}
		return '';
	}, [customerIdCounter, lineItemNumberCounter]);

	return (
		<SimpleTableField
			allowEdit={true}
			columns={columns}
			emptyTableMessage="No Billing Codes found."
			errors={errors}
			fields={fields}
			footerButtonsLeft={footerButtons}
			headerButtons={headerButtons}
			initialized={initialized}
			label="Billing Codes"
			rowClassName={rowClassName}
		/>
	);
});

export default React.memo(BillingCodeArray);
