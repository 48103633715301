import * as React from 'react';
import { Button } from 'react-bootstrap';
import { connect } from 'react-redux';
import type { FileRejection } from 'react-dropzone';

import type { RootState } from 'af-reducers';

import CustomModal from 'af-components/CustomModal';
import Dropzone from 'af-components/Dropzone';
import FileUploadMessage from 'af-components/Dropzone/FileUploadMessage';
import { ALLOWED_FILE_TYPES_MIME } from 'acceligent-shared/enums/fileType';

interface OwnProps {
	showModal: boolean;
	onCancel: () => void;
	error: string | undefined;
	onDrop: (acceptedFiles: File[], fileRejections: FileRejection[]) => void;
}

interface StateProps {
	uploadProgress: number;
}

type Props = OwnProps & StateProps;

class UploadDocumentModal extends React.PureComponent<Props> {

	render() {
		const {
			showModal,
			onCancel,
			uploadProgress,
			error,
			onDrop,
		} = this.props;

		const uploading = uploadProgress !== 100 && uploadProgress !== 0;

		const style: Partial<React.CSSProperties> | undefined = uploading
			? { padding: '1px', background: `linear-gradient(to right, #9FCAFF ${uploadProgress}%, transparent ${uploadProgress}% 100%)` }
			: undefined;

		return (
			<CustomModal
				closeModal={onCancel}
				modalStyle="info"
				showModal={showModal}
				size="md"
			>
				<CustomModal.Header
					closeModal={onCancel}
					title="Upload Document"
				/>
				<CustomModal.Body>
					<Dropzone
						allowedTypes={ALLOWED_FILE_TYPES_MIME}
						className="dropzone-element"
						onDrop={onDrop}
						style={style}
					>
						<FileUploadMessage
							error={error}
							uploading={uploading}
						/>
					</Dropzone>
				</CustomModal.Body>
				<CustomModal.Footer>
					<Button onClick={onCancel} variant="info">Cancel</Button>
				</CustomModal.Footer>
			</CustomModal>
		);
	}
}

function mapStateToProps(state: RootState): StateProps {
	return {
		uploadProgress: state.http.uploadProgress,
	};
}

export default connect<StateProps, null, OwnProps>(mapStateToProps)(UploadDocumentModal);
