import * as React from 'react';
import type { HandleThunkActionCreator } from 'react-redux';

import type { DivisionViewModel } from 'ab-viewModels/division.viewModel';

import DivisionPreview from 'af-root/scenes/Company/Resources/Division/DivisionPreview';
import DivisionEdit from 'af-root/scenes/Company/Resources/Division/DivisionEdit';

import type * as DivisionActions from 'af-actions/division';

interface OwnProps {
	division: DivisionViewModel;
	index: number;
	toggleEditingDivision: (id: number) => void;
	editingId: Nullable<number>;
	updateDivision: HandleThunkActionCreator<typeof DivisionActions.update>;
	deleteDivision: HandleThunkActionCreator<typeof DivisionActions.deleteDivision>;
}

type Props = OwnProps;

export default class DivisionItem extends React.PureComponent<Props> {

	render() {
		const { toggleEditingDivision, editingId, division, index, updateDivision, deleteDivision } = this.props;

		return editingId === division.id ?
			(
				<DivisionEdit
					division={division}
					index={index}
					initialValues={{
						name: division.name,
					}}
					toggleEdit={toggleEditingDivision}
					updateDivision={updateDivision}
				/>
			) : (
				<DivisionPreview
					deleteDivision={deleteDivision}
					division={division}
					toEdit={toggleEditingDivision}
				/>
			);
	}
}
