import * as React from 'react';
import { connect } from 'react-redux';
import type { InjectedFormProps } from 'redux-form';
import { FieldArray, formValueSelector } from 'redux-form';

import type { RootState } from 'af-reducers';

import type UserGroupsSelectionRequestModel from 'ab-requestModels/userGroupsSelection.requestModel';

import type { OwnProps as UserGroupsFieldOwnProps } from 'af-root/scenes/Company/Settings/Members/Shared/UserGroupsField';
import UserGroupsField from 'af-root/scenes/Company/Settings/Members/Shared/UserGroupsField';
import AccountsField from 'af-root/scenes/Company/Settings/Members/Shared/AccountsField';
import type { OwnProps as UserGroupsOwnProps } from 'af-root/scenes/Company/Communication/NotifyEmployees/UserGroups';
import UserGroups from 'af-root/scenes/Company/Communication/NotifyEmployees/UserGroups';

interface OwnProps {
	formName: string;
	change: InjectedFormProps<UserGroupsSelectionRequestModel>['change'];
	array: InjectedFormProps<UserGroupsSelectionRequestModel>['array'];
	userGroupError?: string;
	isModal?: boolean;
	initialGroupId?: string;
}

interface StateProps {
	selectedUserGroups: UserGroupsSelectionRequestModel['userGroups'];
	includedAccounts: NonNullable<UserGroupsSelectionRequestModel['includedAccounts']>;
	notificationPriority: NonNullable<UserGroupsSelectionRequestModel['notificationPriority']>;
}

type Props = OwnProps & StateProps;

class UserGroupsSelection extends React.Component<Props> {

	componentWillUnmount(): void {
		const { change } = this.props;
		change('userGroups', []);
		change('includedAccounts', []);
	}

	onExcludeAccount = (userGroupName: string, accountId: number) => {
		const { array, selectedUserGroups, includedAccounts } = this.props;

		// remove account from all the selected user groups
		const duplicateAccountIndices = selectedUserGroups?.reduce<number[][]>((_indices, _userGroup, _userGroupIndex) => {
			const _duplicateAccountIndex = _userGroup.accounts?.findIndex((_account) => _account.id === accountId && _userGroup.name !== userGroupName);
			if (_duplicateAccountIndex !== undefined && _duplicateAccountIndex > -1) {
				_indices.push([_userGroupIndex, _duplicateAccountIndex]);
			}
			return _indices;
		}, []);
		duplicateAccountIndices?.forEach(([userGroupIndex, accountIndex]) => array.remove(`userGroups[${userGroupIndex}].accounts`, accountIndex));

		// remove account from included accounts
		const _duplicateIncludedAccountIndex = (includedAccounts ?? []).findIndex((_account) => _account.id === accountId);
		if (_duplicateIncludedAccountIndex > -1) {
			array.remove('includedAccounts', _duplicateIncludedAccountIndex);
		}

		if (userGroupName) {
			array.push(`excludedAccounts.${userGroupName}`, accountId);
		}
	};

	onUserGroupRemove = (userGroupName: string) => {
		const { change } = this.props;
		change(`excludedAccounts.${userGroupName}`, []);
	};

	render() {
		const { selectedUserGroups, includedAccounts, notificationPriority, userGroupError, isModal, initialGroupId } = this.props;

		const className = isModal ? '' : 'user-groups-selection';
		return (
			<div className={className}>
				<FieldArray<UserGroupsFieldOwnProps>
					component={UserGroupsField}
					fixed={isModal}
					includeAccounts={true}
					initialGroupId={initialGroupId}
					label="Choose an existing user group you want to notify:"
					name="userGroups"
					onUserGroupRemove={this.onUserGroupRemove}
					skipConfirmationModal={true}
				/>
				<FieldArray<UserGroupsOwnProps>
					component={UserGroups}
					customError={userGroupError}
					includedAccounts={includedAccounts}
					name="userGroups"
					notificationPriority={notificationPriority}
					onExcludeAccount={this.onExcludeAccount}
				/>
				<FieldArray
					component={AccountsField}
					fixed={!!isModal}
					name="includedAccounts"
					notificationPriority={notificationPriority}
					selectedUserGroups={selectedUserGroups}
				/>
			</div>
		);
	}
}

function mapStateToProps(state: RootState, ownProps: OwnProps) {
	const { formName } = ownProps;
	return {
		selectedUserGroups: formValueSelector(formName)(state, 'userGroups'),
		includedAccounts: formValueSelector(formName)(state, 'includedAccounts'),
		notificationPriority: formValueSelector(formName)(state, 'notificationPriority'),
	};
}

export default connect<StateProps, null, OwnProps>(mapStateToProps)(UserGroupsSelection);
