import * as React from 'react';
import { compose } from 'redux';
import type { ConnectedProps } from 'react-redux';
import { connect } from 'react-redux';
import type { DropResult } from 'react-beautiful-dnd';
import { DragDropContext } from 'react-beautiful-dnd';
import type Scrollbars from 'react-custom-scrollbars';
import type { History } from 'history';

import * as TimeUtils from 'acceligent-shared/utils/time';

import type { RootState } from 'af-reducers';

import ScheduleBoardContext from 'ab-enums/scheduleBoardContext.enum';
import ScheduleBoardProperty from 'ab-enums/scheduleBoardProperty.enum';

import { SCROLL_EVENT_LOCK_TIMEOUT } from 'af-constants/values';

import CustomScrollbar from 'af-components/CustomScrollbar';
import WeeklyHorizontalScrollbar from 'af-root/scenes/Company/ScheduleBoard/WeeklyView/WeeklyViewContainer/WeeklyHorizontalScrollbar';
import Toolbar from 'af-root/scenes/Company/ScheduleBoard/Shared/Toolbar';
import ToolbarHeader from 'af-root/scenes/Company/ScheduleBoard/WeeklyView/WeeklyViewContainer/ToolbarHeader';
import DayView from 'af-root/scenes/Company/ScheduleBoard/WeeklyView/WeeklyViewContainer/DayView';

import * as ScheduleBoardActions from 'af-actions/scheduleBoard';

import * as ScheduleBoardUtil from 'af-utils/scheduleBoard.util';

interface OwnProps {
	addBlankWorkOrder: (dueDate: string, index: number) => Promise<void>;
	companyName: string;
	dates: string[];
	forceUnlockOrder: (dueDate: string, workOrderId: string) => void;
	hasPermissionsToEditScheduleBoard: boolean;
	hasPermissionsToSendNotifications: boolean;
	history: History;
	isDragAndDropDisabled: boolean;
	lastOpenedOrderCode: string;
	onDragEnd: (dragEvent: DropResult) => void;
	onDragStart: (dragEvent: DropResult) => void;
	orgAlias: string;
	removeBlankWorkOrder: (dueDate: string, index: number) => Promise<void>;
	scheduleAutoNotify: (dueDate: string, notifyByEmail: number[], notifyBySms: number[]) => void;
	sendNotification: (dueDate: string, notifyByEmail: number[], notifyBySms: number[]) => void;
}

type Props = OwnProps & ConnectedProps<typeof connector>;

const WeeklyViewContainer: React.FC<Props> = (props) => {
	const {
		addBlankWorkOrder,
		companyName,
		dates,
		forceUnlockOrder,
		hasPermissionsToEditScheduleBoard,
		hasPermissionsToSendNotifications,
		history,
		isDragAndDropDisabled,
		lastOpenedOrderCode,
		maxWorkOrdersPerDay,
		onDragEnd,
		onDragStart,
		orgAlias,
		removeBlankWorkOrder,
		scheduleAutoNotify,
		sendNotification,
		weeklyViewDateWithToolbar,
		weeklyViewSelectMultiple,
		setWeeklyViewHorizontalScrollingPercentage,
	} = props;

	const [hideScrollbars, setHideScrollbars] = React.useState(false);
	const [maxWidth, setMaxWidth] = React.useState(0); // max width of card containers for every day in weekly view
	const [verticalScrollbarRef, setVerticalScrollbarRef] = React.useState<Nullable<React.RefObject<Scrollbars>>>(null);
	const [onScrollEventLocked, setOnScrollEventLocked] = React.useState(false);
	const [onScrollEventLockTimer, setOnScrollEventLockTimer] = React.useState<Nullable<NodeJS.Timeout>>(null);
	const [scrollbarRefs, setScrollbarRefs] = React.useState<React.RefObject<Scrollbars>[]>([]);

	React.useEffect(() => {
		window.addEventListener('resize', onScrollUpdate);

		return () => {
			if (onScrollEventLockTimer) {
				clearTimeout(onScrollEventLockTimer);
			}
			window.removeEventListener('resize', onScrollUpdate);
		};
		// eslint-disable-next-line react-hooks/exhaustive-deps
	}, []); // on mount

	const onVerticalScrollMount = React.useCallback((scrollElement: React.RefObject<Scrollbars>) => {
		setVerticalScrollbarRef(scrollElement);
	}, []);

	const onScrollMount = React.useCallback((scrollElement: React.RefObject<Scrollbars>) => {
		if (scrollElement?.current) {
			setScrollbarRefs([...scrollbarRefs, scrollElement].filter(Boolean));
			const _hideScrollbars = scrollElement.current.getScrollWidth() <= scrollElement.current.getClientWidth();
			setHideScrollbars(hideScrollbars || _hideScrollbars);
			setMaxWidth(Math.max(maxWidth, scrollElement.current?.getScrollWidth() ?? 0));
		}
	}, [hideScrollbars, maxWidth, scrollbarRefs]);

	const onScrollUpdate = React.useCallback(() => {
		// find max width of horizontal scroll containers
		let _maxWidth = 0;
		let scrollBarLeftOffset = 0;
		let clientWidth = 0;
		let _hideScrollbars = false;

		scrollbarRefs.forEach(
			(_ref: React.RefObject<Scrollbars>) => {
				if (_ref.current) {
					_hideScrollbars = _hideScrollbars || _ref.current.getScrollWidth() <= _ref.current.getClientWidth();
					scrollBarLeftOffset = Math.max(scrollBarLeftOffset, (_ref.current.getScrollLeft() || 0));
					_maxWidth = Math.max(_maxWidth, (_ref.current.getScrollWidth() || 0));
					clientWidth = Math.max(clientWidth, (_ref.current.getClientWidth() || 0));
				}
			}
		);

		setHideScrollbars(_hideScrollbars);
		setMaxWidth(_maxWidth);
		const newScrollingPercentage = scrollBarLeftOffset / (_maxWidth - clientWidth);
		setWeeklyViewHorizontalScrollingPercentage(newScrollingPercentage);
	}, [scrollbarRefs, setWeeklyViewHorizontalScrollingPercentage]);

	const onHorizontalScroll = React.useCallback((scrollPercentage: number, width: number, force: boolean = false) => {
		if (!force && onScrollEventLocked) {
			return;
		}
		if (force && onScrollEventLockTimer) {
			clearTimeout(onScrollEventLockTimer);
		}
		setOnScrollEventLocked(true);
		setOnScrollEventLockTimer(setTimeout(() => {
			setOnScrollEventLocked(false);
		}, SCROLL_EVENT_LOCK_TIMEOUT));

		scrollbarRefs.forEach((_ref: React.RefObject<Scrollbars>) => {
			if (_ref.current) {
				_ref.current.scrollLeft(scrollPercentage * width);
			}
		});
		setWeeklyViewHorizontalScrollingPercentage(scrollPercentage);
	}, [onScrollEventLockTimer, onScrollEventLocked, scrollbarRefs, setWeeklyViewHorizontalScrollingPercentage]);

	return (
		<CustomScrollbar
			contentWrapperClassName="schedule-board-weekly-view-wrapper"
			onMount={onVerticalScrollMount}
		>
			{!hideScrollbars &&
				<WeeklyHorizontalScrollbar
					maxWidth={maxWidth}
					onHorizontalScroll={onHorizontalScroll}
					position="top"
					verticalScrollRef={verticalScrollbarRef}
				/>
			}
			<DragDropContext onDragEnd={onDragEnd} onDragStart={onDragStart}>
				{
					dates.map((_dueDate, _index) => {
						let className = _index === 0 ? 'start-day' : '';
						className = _index === dates.length - 1 ? 'end-day' : className;
						return (
							<DayView
								addBlankWorkOrder={addBlankWorkOrder}
								className={className}
								companyName={companyName}
								droppableId={ScheduleBoardUtil.generateDroppableId(
									ScheduleBoardContext.BOARD,
									ScheduleBoardProperty.WORK_ORDER, _dueDate, _dueDate
								)}
								dueDate={_dueDate}
								forceUnlockOrder={forceUnlockOrder}
								hasPermissionsToEditScheduleBoard={hasPermissionsToEditScheduleBoard}
								history={history}
								isDragAndDropDisabled={isDragAndDropDisabled}
								isWeeklyViewSelectMultiple={!!weeklyViewSelectMultiple && weeklyViewSelectMultiple !== TimeUtils.formatDate(_dueDate)}
								key={`weeklyView#${_dueDate}`}
								lastOpenedOrderCode={lastOpenedOrderCode}
								maxWorkOrdersPerDay={maxWorkOrdersPerDay}
								onHorizontalScroll={onHorizontalScroll}
								onHorizontalScrollMount={onScrollMount}
								onHorizontalScrollUpdate={onScrollUpdate}
								orgAlias={orgAlias}
								removeBlankWorkOrder={removeBlankWorkOrder}
								weeklyViewDateWithToolbar={weeklyViewDateWithToolbar}
							/>
						);
					})
				}
				{
					weeklyViewDateWithToolbar &&
					<div className="weekly-view-toolbar-wrapper">
						<ToolbarHeader dueDate={weeklyViewDateWithToolbar} />
						<Toolbar
							dueDate={weeklyViewDateWithToolbar}
							hasPermissionsToEditScheduleBoard={hasPermissionsToEditScheduleBoard}
							hasPermissionsToSendNotifications={hasPermissionsToSendNotifications}
							isDragAndDropDisabled={isDragAndDropDisabled}
							scheduleAutoNotify={scheduleAutoNotify}
							sendNotification={sendNotification}
						/>
					</div>
				}
			</DragDropContext>
			{!hideScrollbars &&
				<WeeklyHorizontalScrollbar
					maxWidth={maxWidth}
					onHorizontalScroll={onHorizontalScroll}
					position="bottom"
					verticalScrollRef={verticalScrollbarRef}
				/>
			}
		</CustomScrollbar>
	);
};

function mapStateToProps(state: RootState) {
	const { user: { userData, companyData }, scheduleBoard } = state;
	if (!userData || !companyData) {
		throw new Error('User not logged in');
	}

	return {
		weeklyViewDateWithToolbar: scheduleBoard.weeklyViewDateWithToolbar,
		weeklyViewSelectMultiple: Object.keys(scheduleBoard.weeklyViewSelectMultiple ?? {})[0],
		maxWorkOrdersPerDay: Math.max(0, ...Object.values(scheduleBoard.workOrdersByDateDictionary).map(({ workOrdersOrdering, workOrders }) => {
			return workOrdersOrdering.reduce((_sum, _workOrderCode) => {
				if (ScheduleBoardUtil.isBlankWorkOrderId(_workOrderCode)) {
					return _sum + 1;
				}
				return _sum + ScheduleBoardUtil.getColumnNumberForWorkOrder(workOrders[_workOrderCode]);
			}, 0);
		})),
		userData,
		companyData,
	};
}

function mapDispatchToProps() {
	return {
		setWeeklyViewHorizontalScrollingPercentage: ScheduleBoardActions.setWeeklyViewHorizontalScrollingPercentage,
	};
}

const connector = connect(mapStateToProps, mapDispatchToProps());

const enhance = compose<React.ComponentType<OwnProps>>(
	React.memo,
	connector
);

export default enhance(WeeklyViewContainer);
