import * as React from 'react';
import { Button } from 'react-bootstrap';
import type { ResolveThunks } from 'react-redux';
import { connect } from 'react-redux';

import { formatDate } from 'acceligent-shared/utils/time';

import TimeFormat from 'acceligent-shared/enums/timeFormat';

import * as TimeSheetActions from 'af-actions/timeSheet';

import CustomModal from 'af-components/CustomModal';
import SubmitButton from 'af-components/SubmitButton';

interface OwnProps {
	showModal: boolean;
	code: string;
	employeeFullName: string;
	dueDate: string;
	accountId: number;
	workOrderId: number;
	onSubmit?: () => void;
	close: () => void;
}

interface DispatchProps {
	reject: typeof TimeSheetActions.reject;
}

type Props = OwnProps & ResolveThunks<DispatchProps>;

interface State {
	reason: Nullable<string>;
	submitting: boolean;
}

class RejectModal extends React.PureComponent<Props, State> {
	state: State = {
		reason: null,
		submitting: false,
	};

	reject = () => {
		const { reject, workOrderId, accountId, onSubmit } = this.props;
		const { reason } = this.state;

		if (reason === null) {
			throw new Error('reason should be set');
		}

		this.setState(() => ({ submitting: true }), async () => {
			await reject(workOrderId, accountId, { reason });
			onSubmit?.();
			this.close();
			this.setState(() => ({ submitting: false }));
		});
	};

	close = () => {
		const { close } = this.props;
		this.setState(() => ({ reason: '' }));
		close();
	};

	onChange = (e) => {
		this.setState(() => ({ reason: e.target.value }));
	};

	render() {
		const {
			employeeFullName,
			dueDate,
			code,
			showModal,
		} = this.props;
		const { reason, submitting } = this.state;
		const disabled = !reason?.length;
		const date = formatDate(dueDate, TimeFormat.SB_DATE_LONG, TimeFormat.DB_DATE_ONLY);
		return (
			<CustomModal
				closeModal={this.close}
				showModal={showModal}
				size="md"
			>
				<CustomModal.Header
					closeModal={this.close}
					title="Reject Time Sheet"
				/>
				<CustomModal.Body>
					<span>
						Reject time sheet for <b>{employeeFullName}</b> on <b>{date}</b> for Work Order <b>{code}</b>?
					</span>
					<input
						className="form-control time-sheet-list__reject-modal-input"
						onChange={this.onChange}
						placeholder="Reason *"
					/>
				</CustomModal.Body>
				<CustomModal.Footer>
					<Button disabled={submitting} onClick={this.close} variant="info">Cancel</Button>
					<SubmitButton
						disabled={disabled}
						label="Reject"
						onClick={this.reject}
						reduxFormSubmitting={submitting}
					/>
				</CustomModal.Footer>
			</CustomModal>
		);
	}
}

function mapDispatchToProps(): DispatchProps {
	return {
		reject: TimeSheetActions.reject,
	};
}

export default connect<null, DispatchProps, OwnProps>(null, mapDispatchToProps())(RejectModal);
