import * as React from 'react';
import type { ResolveThunks } from 'react-redux';
import { connect } from 'react-redux';

import type { RootState } from 'af-reducers';

import TimeFormatEnum from 'acceligent-shared/enums/timeFormat';

import * as TimeUtils from 'acceligent-shared/utils/time';

import * as ScheduleBoardActions from 'af-actions/scheduleBoard';

import NavigationItem from 'af-components/Header/ScheduleBoardNavigation/NavigationItem';
import MultipleOptionsButton from 'af-components/MultipleOptionsButton';
import RectangleButton from 'af-components/MultipleOptionsButton/RectangleButton';
import ConnectionCounters from 'af-components/ConnectionCounters';

import { MAX_SCHEDULE_BOARD_ZOOM_LEVEL, MIN_SCHEDULE_BOARD_ZOOM_LEVEL } from 'ab-common/constants/scheduleBoard';
import { SCREEN_BREAKPOINT_M, SCREEN_BREAKPOINT_L } from 'af-constants/values';

import * as BrowserUtil from 'af-utils/browser.util';

interface StateProps {
	idleConnections: Nullable<number>;
	activeConnections: Nullable<number>;
	zoomLevel: number;
}

interface DispatchProps {
	setZoomLevel: typeof ScheduleBoardActions.setZoomLevel;
}

type Props = StateProps & ResolveThunks<DispatchProps>;

interface State {
	/** MM-DD-YY */
	date: string;
	isFullscreen: boolean;
}

class Header extends React.PureComponent<Props, State> {
	state: State = {
		isFullscreen: false,
		date: TimeUtils.getTodaysDate(),
	};

	_unregisterDayChangeHandler: Nullable<() => void> = null;

	componentDidMount() {
		this.updateWindowDimensions();
		window.addEventListener('resize', this.updateWindowDimensions);

		this.setupDayChangeHandler();
	}

	componentWillUnmount() {
		window.removeEventListener('resize', this.updateWindowDimensions);

		this._unregisterDayChangeHandler?.();
	}

	setupDayChangeHandler = () => {
		this._unregisterDayChangeHandler = TimeUtils.onDayChange(() => this.setState(
			() => ({ date: TimeUtils.getTodaysDate() }),
			this.setupDayChangeHandler
		));
	};

	updateWindowDimensions = () => {
		const { setZoomLevel } = this.props;

		let zoomLevel: Nullable<number> = null;
		if (window.innerWidth < SCREEN_BREAKPOINT_M) {
			zoomLevel = 2;
		} else if (window.innerWidth >= SCREEN_BREAKPOINT_M && window.innerWidth < SCREEN_BREAKPOINT_L) {
			zoomLevel = 1;
		} else {
			zoomLevel = 0;
		}
		setZoomLevel(zoomLevel);
	};

	zoomIn = () => {
		const { setZoomLevel, zoomLevel } = this.props;

		if (zoomLevel >= MAX_SCHEDULE_BOARD_ZOOM_LEVEL) {
			return;
		}

		setZoomLevel(zoomLevel + 1);
	};

	zoomOut = () => {
		const { setZoomLevel, zoomLevel } = this.props;

		if (zoomLevel <= MIN_SCHEDULE_BOARD_ZOOM_LEVEL) {
			return;
		}

		setZoomLevel(zoomLevel - 1);
	};

	fullScreenToggle = () => {
		const { isFullscreen } = this.state;
		BrowserUtil.toggleFullscreen();
		this.setState(() => ({ isFullscreen: !isFullscreen }));
	};

	render() {
		const {
			idleConnections,
			activeConnections,
		} = this.props;
		const { isFullscreen, date } = this.state;

		const [dayOfWeekFormatted, dateFormatted] = TimeUtils.formatDate(date, TimeFormatEnum.SB_DATE_LONG).split(',');
		const formattedDate = `${dayOfWeekFormatted},${dateFormatted.toUpperCase()}`;

		return (
			<div className="schedule-board-navigation">
				<div className="schedule-board-navigation-left">
					<div>
						<div className="navigation-title">
							Mechanic View
						</div>
						<ConnectionCounters activeCount={activeConnections} idleCount={idleConnections} />
					</div>
				</div>
				<div className="schedule-board-navigation-center">
					<span className="mechanic-view-navigation__date">{formattedDate}</span>
				</div>
				<div className="schedule-board-navigation-right">
					<MultipleOptionsButton>
						<RectangleButton
							action={this.zoomOut}
							isSquare={true}
							label={<span className="icon-minus" />}
							tooltipMessage="Zoom out"
							tooltipPlacement="bottom"
						/>
						<RectangleButton
							action={this.zoomIn}
							isSquare={true}
							label={<span className="icon-plus" />}
							tooltipMessage="Zoom in"
							tooltipPlacement="bottom"
						/>
						<RectangleButton
							action={this.fullScreenToggle}
							isSquare={true}
							label={<span className={isFullscreen ? 'icon-fullscreen_exit' : 'icon-fullscreen_enter'} />}
							tooltipMessage="Full screen"
							tooltipPlacement="bottom"
						/>
					</MultipleOptionsButton>
					<NavigationItem />
				</div>
			</div>
		);
	}
}

function mapStateToProps(state: RootState): StateProps {
	const {
		scheduleBoard: {
			idleConnections,
			activeConnections,
			zoomLevel,
		},
	} = state;

	return {
		idleConnections,
		activeConnections,
		zoomLevel,
	};
}

function mapDispatchToProps(): DispatchProps {
	return {
		setZoomLevel: ScheduleBoardActions.setZoomLevel,
	};
}

export default connect(mapStateToProps, mapDispatchToProps())(Header);
