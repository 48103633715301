import * as Err from 'restify-errors';

import { TableContent } from 'ab-common/dataStructures/tableContent';

import { isNullOrUndefined } from 'acceligent-shared/utils/extensions';

import type { TableQuery, TableSortBy } from 'ab-common/dataStructures/tableQuery';

import { isValidNumber } from 'ab-utils/validation.util';

/**
 *
 * @param request everything one might expect from a table request
 * @returns valid version of request object. Does not mutate it
 */
const _validateTableRequest = (request: TableQuery): TableQuery => {
	const { page, pageSize, sortBy } = request;
	const isPageValid = isValidNumber(page) && page >= 0;
	const isPageSizeValid = isValidNumber(pageSize);

	if (isPageSizeValid && isPageValid) {
		return {
			...request,
			sortBy: sortBy ? sortBy.filter((_sortItem) => (!!_sortItem.id && _sortItem.desc !== undefined)) : undefined,
		};
	}
	throw new Err.BadRequestError();
};

export const parseTableRequest = (query: Stringified<Encoded<TableQuery>>): TableQuery => {
	try {
		const _result = JSON.parse(decodeURIComponent(query));
		const result = _validateTableRequest(_result);
		return result;
	} catch (error) {
		throw new Err.BadRequestError();
	}
};

function getSortCallbackFromSortBy<T>(
	sortBy: TableSortBy,
	ignoreCase: boolean = true,
	isNullAlwaysLast: boolean = true,
	isNullLastInDesc: boolean = true
): Nullable<Parameters<T[]['sort']>[0]> {
	if (!sortBy.id) {
		return null;
	}
	return (rowA: T, rowB: T) => {
		const aIsGreater = sortBy.desc ? -1 : 1;
		const bIsGreater = -aIsGreater;

		let valueA = rowA[sortBy.id];
		let valueB = rowB[sortBy.id];
		if (ignoreCase && valueA && typeof valueA === 'string') {
			valueA = valueA.toUpperCase();
		}
		if (ignoreCase && valueB && typeof valueB === 'string') {
			valueB = valueB.toUpperCase();
		}
		const hasA = !isNullOrUndefined(valueA);
		const hasB = !isNullOrUndefined(valueB);

		if (!hasA && !hasB) {
			return 0;
		}
		if (!hasA) {
			if (isNullAlwaysLast) {
				return 1;
			}
			if (sortBy.desc !== isNullLastInDesc) {	// XOR
				return bIsGreater;
			}
			return aIsGreater;
		}
		if (!hasB) {
			if (isNullAlwaysLast) {
				return -1;
			}
			if (sortBy.desc !== isNullLastInDesc) {	// XOR
				return aIsGreater;
			}
			return bIsGreater;
		}
		if (valueA > valueB) {
			return aIsGreater;
		}
		if (valueA < valueB) {
			return bIsGreater;
		}
		return 0;
	};
}

export function buildTable<T>(
	tableRequest: TableQuery,
	allRows: T[],
	filterByTextFunction?: (rows: T[], text: string) => T[]
): Nullable<TableContent<T>> {
	if (!allRows) {
		return null;
	}
	let rows = [...allRows];

	if (filterByTextFunction && tableRequest.filterByText) {
		rows = filterByTextFunction(rows, tableRequest.filterByText);
	}

	// SORT
	tableRequest.sortBy?.forEach((_sortBy: TableSortBy) => {
		if (!_sortBy.id) {
			return;
		}

		const sortFn = getSortCallbackFromSortBy(_sortBy);
		if (sortFn) {
			rows.sort(sortFn);
		}
	});

	// PAGE & BUILD
	const totalCount = allRows.length;
	const pages = tableRequest.pageSize ? Math.ceil(rows.length / tableRequest.pageSize) : 1;
	const page = tableRequest.page;
	const tableRows = tableRequest.pageSize ? rows.slice(page * tableRequest.pageSize, (page + 1) * tableRequest.pageSize) : [];
	return new TableContent(tableRows, pages, totalCount);
}
