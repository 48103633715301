import * as React from 'react';
import { Button, Modal } from 'react-bootstrap';
import type { InjectedFormProps } from 'redux-form';
import { Field, reduxForm } from 'redux-form';
import ReactSignatureCanvas from 'react-signature-canvas';

import * as TimeUtils from 'acceligent-shared/utils/time';
import { isValidTextInput } from 'acceligent-shared/utils/text';

import TimeFormatEnum from 'acceligent-shared/enums/timeFormat';

import SubmitButton from 'af-components/SubmitButton';

import Input from 'af-fields/Input';

import { SIGNATURE_FIELD_REPORT } from 'af-constants/reduxForms';

import MimeType from 'ab-enums/mimeType.enum';

import { bemElement } from 'ab-utils/bem.util';

import type SignatureForm from '../../../Shared/SignatureModal/FormModel';

import SuccessStep from './SuccessStep';

const validateSignatureForm = (values: SignatureForm) => {
	const errors: ValidationErrors = {};

	if (!isValidTextInput(values.fullName)) {
		errors.fullName = 'Full Name is required';
	}

	if (!values.isSigned) {
		errors.isSigned = 'Signature is required';
	}

	return errors;
};

interface OwnProps {
	showModal: boolean;
	closeModal: () => void;
	onSubmit: (form: SignatureForm) => Promise<void>;
	keepValuesOnUnmount?: boolean;
	successStepMessage?: string;
}

type Props = OwnProps & InjectedFormProps<SignatureForm, OwnProps>;

const MobileSignatureModal: React.FC<Props> = (props: Props) => {
	const { showModal, closeModal, handleSubmit, invalid, submitting, change, destroy, reset, dirty, keepValuesOnUnmount, successStepMessage } = props;

	const canvasRef = React.useRef<ReactSignatureCanvas>(null);
	const [isSubmitted, setIsSubmitted] = React.useState(false);
	const [signatureDataPoints, setSignatureDataPoints] = React.useState<Nullable<SignaturePad.Point[][]>>(null);

	const submit = async (form: SignatureForm) => {
		const { onSubmit } = props;

		if (!form.isSigned) {
			return;
		}

		const signatureImage = canvasRef.current?.getTrimmedCanvas()?.toDataURL(MimeType.PNG);
		if (!signatureImage) {
			throw new Error('Failed to get signatureImage');
		}

		const data: SignatureForm = {
			...form,
			signatureImage,
			signedAt: TimeUtils.formatDate(new Date(), TimeFormatEnum.ISO_DATETIME),
		};

		await onSubmit(data);

		if (keepValuesOnUnmount) {
			const dataPoints = canvasRef.current?.toData();
			if (!dataPoints) {
				throw new Error('failed to get dataPoints');
			}
			setSignatureDataPoints(dataPoints);
		}
		setIsSubmitted(true);
	};

	React.useEffect(() => {
		if (!showModal) {
			if (!keepValuesOnUnmount) {
				destroy();
			}
			setIsSubmitted(false);
		} else {
			if (keepValuesOnUnmount && signatureDataPoints) {
				canvasRef.current?.fromData(signatureDataPoints);
			} else {
				reset();
			}
		}
		// eslint-disable-next-line react-hooks/exhaustive-deps
	}, [showModal, keepValuesOnUnmount, signatureDataPoints]);

	const beginSignature = React.useCallback(() => {
		change('isSigned', true);
		// eslint-disable-next-line react-hooks/exhaustive-deps
	}, []);

	const renderCanvas = React.useCallback(() => {
		return (
			<ReactSignatureCanvas
				canvasProps={{
					className: bemElement('public-report__mobile-signature-modal', 'canvas'),
				}}
				clearOnResize={false}
				onBegin={beginSignature}
				ref={canvasRef}
			/>
		);
	}, [beginSignature]);

	const clearCanvas = React.useCallback(() => {
		canvasRef.current?.clear();
		change('isSigned', false);
		// eslint-disable-next-line react-hooks/exhaustive-deps
	}, []);

	return (
		<Modal animation={false} className="public-report__mobile-signature-modal" onHide={closeModal} show={showModal}>
			<Modal.Header closeButton>
				<Modal.Title>
					Add Customer Signature
				</Modal.Title>
			</Modal.Header>
			{isSubmitted
				? <SuccessStep additionalMessage={successStepMessage} closeModal={closeModal} />
				: <>
					<Modal.Body>
						<div className={bemElement('public-report__mobile-signature-modal', 'full-name')}>
							<Field
								component={Input}
								name="fullName"
								placeholder="Signee Full Name *"
								type="text"
							/>
						</div>
						<div className={bemElement('public-report__mobile-signature-modal', 'canvas-container')}>
							<Field
								component={renderCanvas}
								controlCursor={true}
								id="isSigned"
								name="isSigned"
								withRef
							/>
						</div>
					</Modal.Body>
					<Modal.Footer>
						<div className={bemElement('public-report__mobile-signature-modal', 'footer')}>
							<Button
								className={bemElement('public-report__mobile-signature-modal', 'footer__button')}
								disabled={submitting}
								onClick={clearCanvas}
								variant="info"
							>
								Clear
							</Button>
							<SubmitButton
								className={bemElement('public-report__mobile-signature-modal', 'footer__button')}
								disabled={invalid || !dirty}
								label="Sign & Submit"
								onClick={handleSubmit(submit)}
								reduxFormSubmitting={submitting}
							/>
						</div>
					</Modal.Footer>
				</>
			}
		</Modal>
	);
};

export default reduxForm<SignatureForm, OwnProps>({
	form: SIGNATURE_FIELD_REPORT,
	validate: validateSignatureForm,
	destroyOnUnmount: false,
	enableReinitialize: true,
})(MobileSignatureModal);
