import * as React from 'react';
import { Button } from 'react-bootstrap';

import DisabledOnAction from 'af-components/DisabledOnAction';
import ActionButton from 'af-components/DisabledOnAction/ActionButton';
import LoadingIndicator from 'af-components/LoadingIndicator';
import type { ModalSize, ModalStyle } from 'af-components/CustomModal';
import CustomModal from 'af-components/CustomModal';

export interface OwnProps {
	showModal: boolean;
	closeModal: () => void;
	confirmAction: Nullable<(event: React.MouseEvent) => void | Promise<void>>;
	title: string | undefined | null;
	body: JSX.Element | string | undefined | null;
	footer?: JSX.Element;
	confirmText?: Nullable<string>;
	size?: ModalSize;
	modalStyle?: ModalStyle;
	closeText?: string;
	id?: string;
	hideOnConfirm?: boolean;
	disabled?: boolean;
	showCancel?: boolean;
}

type Props = OwnProps;

class ConfirmationModal extends React.PureComponent<Props> {

	static defaultProps: Partial<Props> = {
		modalStyle: 'danger',
		size: 'sm',
		closeText: 'Cancel',
		confirmText: 'Save',
		hideOnConfirm: true,
		showCancel: true,
	};

	onClick = async (event) => {
		const { confirmAction, closeModal, hideOnConfirm } = this.props;
		if (hideOnConfirm) {
			closeModal?.();
		}
		await confirmAction?.(event as React.MouseEvent);
	};

	render() {
		const { id, showModal, closeText, closeModal, confirmText, title, body, size, modalStyle, footer, disabled, showCancel } = this.props;

		return (
			<CustomModal
				closeModal={closeModal}
				id={id}
				modalStyle={modalStyle}
				showModal={showModal}
				size={size}
			>
				<CustomModal.Header
					closeModal={closeModal}
					title={title}
				/>
				<CustomModal.Body>
					{body}
				</CustomModal.Body>
				<CustomModal.Footer>
					{footer ??
						<>
							{showCancel &&
								<Button
									onClick={closeModal}
									variant="info"
								>
									{closeText}
								</Button>
							}
							<DisabledOnAction
								activeElement={<span>{confirmText}</span>}
								component={ActionButton}
								disabled={disabled}
								disabledElement={<LoadingIndicator />}
								inactiveElement={<LoadingIndicator />}
								onClick={this.onClick}
								variant={modalStyle === 'danger' ? 'danger' : 'primary'}
							/>
						</>
					}
				</CustomModal.Footer>
			</CustomModal>
		);
	}
}

export default ConfirmationModal;
