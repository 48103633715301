import * as React from 'react';
import { compose } from 'redux';
import type { ConnectedProps } from 'react-redux';
import { connect } from 'react-redux';
import type { DraggableProvided, DraggableStateSnapshot, DraggableElement } from 'react-beautiful-dnd';
import { Draggable } from 'react-beautiful-dnd';

import type { RootState } from 'af-reducers';

import * as ScheduleBoardActions from 'af-actions/scheduleBoard';

import ScheduleBoardView from 'ab-enums/scheduleBoardView.enum';

import { EQUIPMENT_PLACEHOLDER } from 'ab-common/constants/scheduleBoard';

import DraggableEquipment from './Draggable';
import DraggableEquipmentWithReason from './DraggableWithReason';
import DraggableLoadingPlaceholder from 'af-root/scenes/Company/ScheduleBoard/Shared/DraggableLoadingPlaceholder';

import { useScheduleBoardModals } from 'af-root/hooks/useScheduleBoardModal';

interface OwnProps {
	equipmentId: number;
	draggableId: string;
	index: number;
	dueDate: string;
	droppableId: string;
	isToolbar: boolean;
	isCopyPlaceholder?: boolean;
	isCardDisabled?: boolean;
	isCalculationsView?: boolean;
	isWorkOrderCanceled?: boolean;
	hasReason?: boolean;
	resourceId?: number;
	workOrderEquipmentId?: number;
	isDragAndDropDisabled: boolean;
	hasPermissionsToEditScheduleBoard: boolean;
	isWOLocked: boolean;
}

type Props = OwnProps & ConnectedProps<typeof connector>;

interface DraggableProps extends Props {
	provided?: DraggableProvided;
	snapshot?: DraggableStateSnapshot;
}

const EquipmentDraggable: React.FC<Props> = (props) => {

	const {
		findToolbarEquipmentById,
		findWorkOrderEquipmentById,
		equipmentId,
		isToolbar,
		workOrderEquipmentId,
		dueDate,
		isCalculationsView = false,
		draggableId,
		index,
		isDisabled,
		isCopyPlaceholder,
		isDragAndDropDisabled,
		hasPermissionsToEditScheduleBoard,
	} = props;

	const { setEquipmentModalData, setEquipmentModalVisibility } = useScheduleBoardModals();

	const [isModalOpenedForCurrentEquipment, setIsModalOpenedForCurrentEquipment] = React.useState(false);

	const fetchModalData = React.useCallback(async () => {
		const equipment = isToolbar
			? await findToolbarEquipmentById(equipmentId, dueDate)
			: await findWorkOrderEquipmentById(workOrderEquipmentId!, dueDate);

		setEquipmentModalData(equipment, dueDate);
		setEquipmentModalVisibility(true);
		setIsModalOpenedForCurrentEquipment(true);
	}, [
		dueDate,
		equipmentId,
		findToolbarEquipmentById,
		findWorkOrderEquipmentById,
		isToolbar,
		setEquipmentModalData,
		setEquipmentModalVisibility,
		workOrderEquipmentId,
	]);

	React.useEffect(() => {
		if (isModalOpenedForCurrentEquipment && !isDisabled) {
			setEquipmentModalData(null, null);
			setEquipmentModalVisibility(true);
			fetchModalData();
		}
		// eslint-disable-next-line react-hooks/exhaustive-deps
	}, [isDisabled]); // Only if isDisabled changed

	const openModal = React.useCallback(() => {
		if (isCalculationsView) {
			return;
		}
		fetchModalData();
	}, [fetchModalData, isCalculationsView]);

	const renderDraggable = React.useCallback((draggableProps) => {
		const {
			provided = {} as DraggableProvided,
			snapshot = {} as DraggableStateSnapshot,
		} = draggableProps;

		if (draggableProps.hasReason) {
			return (
				<DraggableEquipmentWithReason
					draggableProps={provided.draggableProps}
					dragHandleProps={provided.dragHandleProps}
					dueDate={draggableProps.dueDate}
					equipmentId={draggableProps.equipmentId}
					hasPermissionsToEditScheduleBoard={draggableProps.hasPermissionsToEditScheduleBoard}
					innerRef={provided.innerRef}
					isDragging={snapshot.isDragging}
					isToolbar={draggableProps.isToolbar}
					onClick={openModal}
				/>
			);
		}

		return (
			<DraggableEquipment
				draggableProps={provided.draggableProps}
				draggingOver={snapshot.draggingOver}
				dragHandleProps={provided.dragHandleProps}
				dueDate={draggableProps.dueDate}
				equipmentId={draggableProps.equipmentId}
				hasPermissionsToEditScheduleBoard={draggableProps.hasPermissionsToEditScheduleBoard}
				innerRef={provided.innerRef}
				isCardDisabled={draggableProps.isCardDisabled}
				isDragAndDropDisabled={draggableProps.isDragAndDropDisabled}
				isDragging={snapshot.isDragging}
				isDropAnimating={snapshot.isDropAnimating}
				isToolbar={draggableProps.isToolbar}
				isWOLocked={draggableProps.isWOLocked}
				isWorkOrderCanceled={draggableProps.isWorkOrderCanceled}
				onClick={openModal}
				resourceId={draggableProps.resourceId}
			/>
		);
	}, [openModal]);

	// loading indicator when assigning equipment from toolbar
	if (equipmentId === EQUIPMENT_PLACEHOLDER) {
		return <DraggableLoadingPlaceholder />;
	}

	// copy of dragged item in source droppable zone
	if (isCopyPlaceholder || isDragAndDropDisabled || !hasPermissionsToEditScheduleBoard) {
		return (
			<div>
				{renderDraggable(props)}
			</div>
		);
	}

	return (
		<Draggable
			draggableId={draggableId}
			index={index}
			isDragDisabled={isDisabled}
		>
			{(provided, snapshot) => (
				React.createElement(renderDraggable, { ...props, provided, snapshot }, null) as DraggableElement<DraggableProps>
			)}
		</Draggable>
	);
};

function mapStateToProps(state: RootState, ownProps: OwnProps) {
	const { equipmentId, isToolbar, isCardDisabled, resourceId, dueDate } = ownProps;
	const {
		equipment: equipmentDict,
		draggedResourceId,
		draggedEquipmentId,
		weeklyViewDateWithToolbar,
		scheduleBoardView,
	} = state.scheduleBoard;

	let isDisabled = false;

	const equipment = equipmentDict?.[equipmentId];

	if (!isToolbar) {
		const disableDraggingWhenToolbarOpened = scheduleBoardView === ScheduleBoardView.WEEKLY_VIEW &&
			(!weeklyViewDateWithToolbar || weeklyViewDateWithToolbar !== dueDate);
		isDisabled = !!isCardDisabled ||
			disableDraggingWhenToolbarOpened ||
			!!(equipment?.isDisabled && resourceId !== draggedResourceId);
	} else {
		// if equipment is disabled and `draggedEquipmentId` is not set => it's dragged by another user or in another tab
		isDisabled = !!equipment?.isDisabled && !draggedEquipmentId;
	}

	return {
		isDisabled,
	};
}

function mapDispatchToProps() {
	return {
		findWorkOrderEquipmentById: ScheduleBoardActions.findWorkOrderEquipmentById,
		findToolbarEquipmentById: ScheduleBoardActions.findToolbarEquipmentById,
	};
}

const connector = connect(mapStateToProps, mapDispatchToProps());

const enhance = compose<React.ComponentType<OwnProps>>(
	React.memo,
	connector
);

export default enhance(EquipmentDraggable);
