export class W_Account_UpdateDigitalSignature_VM {
	digitalSignatureId: Nullable<number>;
	digitalSignatureUrl: Nullable<string>;
	isDigitalSignatureEnabled: boolean;
	showCreateDigitalSignature: boolean;

	constructor(
		digitalSignatureId: Nullable<number>,
		digitalSignatureUrl: Nullable<string>,
		isDigitalSignatureEnabled: boolean,
		showCreateDigitalSignature: boolean
	) {
		this.digitalSignatureId = digitalSignatureId;
		this.digitalSignatureUrl = digitalSignatureUrl;
		this.isDigitalSignatureEnabled = isDigitalSignatureEnabled;
		this.showCreateDigitalSignature = showCreateDigitalSignature;
	}
}
