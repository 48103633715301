import * as React from 'react';
import { Row, Col } from 'react-bootstrap';

import TimeFormat from 'acceligent-shared/enums/timeFormat';
import type TimeSheetEntryWorkType from 'acceligent-shared/enums/timeSheetEntryWorkType';
import TimeSheetEntryType from 'acceligent-shared/enums/timeSheetEntryType';
import { TimeSheetWorkTypeDisplayInfo } from 'acceligent-shared/enums/timeSheetEntryWorkType';

import * as TimeUtils from 'acceligent-shared/utils/time';

import { bemElement } from 'ab-utils/bem.util';

interface OwnProps {
	createdAt: Nullable<string>;
	dataFilledBy: string;
	deletedAt: Nullable<string>;
	deletedBy: Nullable<string>;
	type: Nullable<TimeSheetEntryType>;
	startTime: Nullable<string>;
	endTime: Nullable<string>;
	workType: Nullable<TimeSheetEntryWorkType>;
	hasParents: boolean;
}

type Props = OwnProps;

const TimeSheetEntryHistoryInformationItem: React.FC<Props> = (props: Props) => {
	const { createdAt, dataFilledBy, deletedAt, deletedBy, startTime, endTime, workType, hasParents, type } = props;

	const isDeleted = !!deletedAt;

	const informationIcon = React.useMemo(() => {
		if (isDeleted) {
			return 'icon-delete';
		} else if (hasParents) {
			return 'icon-edit';
		} else if (type === TimeSheetEntryType.MANUAL) {
			return 'icon-plus';
		}
		return 'icon-time_card';
	}, [isDeleted, hasParents, type]);

	const informationDescription = React.useMemo(() => {
		if (isDeleted) {
			return `Deleted by ${deletedBy} at ${TimeUtils.formatDate(deletedAt, TimeFormat.FULL_DATE_LONG, TimeFormat.ISO_DATETIME)}`;
		} else if (hasParents && !!createdAt) {
			return `Changed by ${dataFilledBy} at ${TimeUtils.formatDate(createdAt, TimeFormat.FULL_DATE_LONG, TimeFormat.ISO_DATETIME)}`;
		} else if (type === TimeSheetEntryType.AUTOMATIC && !!createdAt) {
			return `Added automatically by ${dataFilledBy} at ${TimeUtils.formatDate(createdAt, TimeFormat.FULL_DATE_LONG, TimeFormat.ISO_DATETIME)}`;
		} else if (!!createdAt) {
			return `Added manually by ${dataFilledBy} at ${TimeUtils.formatDate(createdAt, TimeFormat.FULL_DATE_LONG, TimeFormat.ISO_DATETIME)}`;
		}
		return '';
	}, [isDeleted, deletedAt, hasParents, type, dataFilledBy, createdAt, deletedBy]);

	const workTypeIcon = workType
		? TimeSheetWorkTypeDisplayInfo[workType]
		: null;

	const {
		duration,
		timeSpan,
	} = React.useMemo(() => {
		const _duration = (startTime && endTime)
			? TimeUtils.minutesToHoursAndMinutes(TimeUtils.compare(endTime, startTime, 'minutes', TimeFormat.ISO_DATETIME))
			: '-';
		const startTimeLabel = startTime
			? TimeUtils.formatDate(startTime, TimeFormat.TIME_ONLY_12H, TimeFormat.ISO_DATETIME)
			: '';
		const endTimeLabel = endTime
			? TimeUtils.formatDate(endTime, TimeFormat.TIME_ONLY_12H, TimeFormat.ISO_DATETIME)
			: '';
		const _timeSpan = `${startTimeLabel} - ${endTimeLabel}`;
		return { duration: _duration, timeSpan: _timeSpan };
	}, [startTime, endTime]);

	return (
		<Row className="timeline-history-modal__information-row">
			<Col
				className="timeline-history-modal__information-item information-item"
				sm={1}
			>
				<span className={`timeline-history-modal__information-icon ${informationIcon}`} />
			</Col>
			<Col
				className="timeline-history-modal__information-item information-item"
				sm={11}
			>
				{informationDescription}
			</Col>
			<Col
				className={bemElement('timeline-history-modal', 'information-item', ['bold', 'end'])}
				sm={2}
			>
				{workTypeIcon?.label}
			</Col>
			<Col
				className={bemElement('timeline-history-modal', 'information-item', { deleted: isDeleted, plain: !isDeleted, end: true })}
				sm={6}
			>
				{timeSpan}
			</Col>
			<Col
				className={bemElement('timeline-history-modal', 'information-item', ['bold', 'end'])}
				sm={3}
			>
				{duration}
			</Col>
		</Row>
	);
};

export default React.memo(TimeSheetEntryHistoryInformationItem);
