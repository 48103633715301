import * as React from 'react';
import { Row } from 'react-bootstrap';

import type * as ResourceStatusesViewModel from 'ab-viewModels/resources/resourceStatuses.viewModel';

import SettingPreview from 'af-root/scenes/Company/Settings/Shared/SettingPreview';

import ResourceStatusList from './List';

interface OwnProps {
	toggleEdit: () => void;
	statuses?: ResourceStatusesViewModel.Default;
	isLoading?: boolean;
}

const DEFAULT_PROPS = {
	statuses: { available: [], unavailable: [] } as ResourceStatusesViewModel.Default,
};

type Props = OwnProps & typeof DEFAULT_PROPS;

class ResourceStatusesPreview extends React.PureComponent<Props> {
	static defaultProps = DEFAULT_PROPS;

	render() {
		const { isLoading, statuses: { available, unavailable }, toggleEdit } = this.props;

		return (
			<SettingPreview toggleEdit={toggleEdit}>
				<div className="company-settings__info-segment">
					<Row className="row--padded">
						<ResourceStatusList
							iconStatus="complete"
							isLoading={isLoading}
							items={available}
							label="Available"
						/>
					</Row>
					<Row className="row--padded">
						<ResourceStatusList
							iconStatus="incomplete"
							isLoading={isLoading}
							items={unavailable}
							label="Unavailable"
						/>
					</Row>
				</div>
			</SettingPreview>
		);
	}
}

export default ResourceStatusesPreview;
