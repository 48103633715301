import * as React from 'react';
import { compose } from 'redux';
import type { ConnectedProps } from 'react-redux';
import { connect } from 'react-redux';
import type { InjectedFormProps, FormErrorsWithArray } from 'redux-form';
import { reduxForm, formValueSelector, getFormSyncErrors } from 'redux-form';
import type { CustomRouteComponentProps } from 'react-router-dom';

import type { RootState } from 'af-reducers';

import { TOOL_REPAIR_CREATE } from 'af-constants/reduxForms';
import CLIENT from 'af-constants/routes/client';

import * as ToolRepairActions from 'af-actions/toolRepair';

import Breadcrumbs from 'af-components/Breadcrumbs';
import { useLocation, useNavigate } from 'react-router-dom-v5-compat';

import FormModel from '../Shared/formModel';
import Form from '../Shared/Form';
import { validate } from '../Shared/validation';

type OwnProps = InjectedFormProps<FormModel>;
type Props = ConnectedProps<typeof connector> & OwnProps & CustomRouteComponentProps;

const Create = (props: Props) => {
	const { create, companyName, externalRepair, errors } = props;
	const navigate = useNavigate();
	const location = useLocation();

	const createRepair = React.useCallback(async (form: FormModel) => {
		await create(FormModel.toRequestModel(form));
		navigate(CLIENT.COMPANY.TOOL_REPAIR.TABLE(location.state.orgAlias, companyName));
	}, [companyName, navigate, location.state.orgAlias, create]);

	return (
		<div className="form-segment">
			<Breadcrumbs
				items={[
					{ label: 'Tool Repairs', url: CLIENT.COMPANY.TOOL_REPAIR.TABLE(location.state.orgAlias, companyName) },
					{ label: 'New Request' },
				]}
			/>
			<Form
				{...props}
				errors={errors}
				externalRepair={externalRepair}
				onSubmit={createRepair}
			/>
		</div>
	);
};

const formSelector = formValueSelector(TOOL_REPAIR_CREATE);

interface FormActionWrapper {
	selector: <T extends keyof FormModel>(fieldName: T) => FormModel[T];
}
const formActionWrapper: Partial<FormActionWrapper> = {
	selector: undefined,
};

function mapStateToProps(state: RootState) {
	const { companyData } = state.user;
	if (!companyData) {
		throw new Error('User not logged in');
	}

	const errors = getFormSyncErrors(TOOL_REPAIR_CREATE)(state) as FormErrorsWithArray<FormModel, string>;

	formActionWrapper.selector = (fieldName: keyof FormModel) => formSelector(state, fieldName);

	return {
		externalRepair: formSelector(state, 'isExternal'),
		formActionWrapper: formActionWrapper as FormActionWrapper, // selector is no longer undefined
		companyName: companyData.name,
		errors,
	};
}

function mapDispatchToProps() {
	return {
		create: ToolRepairActions.create,
	};
}

const connector = connect(mapStateToProps, mapDispatchToProps());

const enhance = compose<React.ComponentClass>(
	React.memo,
	reduxForm({
		form: TOOL_REPAIR_CREATE,
		validate,
		enableReinitialize: true,
		initialValues: { items: [] },
	}),
	connector
);

export default enhance(Create);
