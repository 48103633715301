import * as React from 'react';
import { Row, Col } from 'react-bootstrap';

import type LocationDepartmentVM from 'ab-viewModels/location/locationDepartment.viewModel';

import SegmentLabel from 'af-components/SegmentLabel';

import DepartmentList from './LocationList';
import Vendor from './Vendor';

type Props = {
	locations: LocationDepartmentVM[];
	vendors: { label: string; value: number; }[];
	onVendorChange: (name: Nullable<string>) => void;
};

const Inventory = (props: Props) => {
	const { locations, vendors, onVendorChange } = props;

	return (
		<>
			<div className="user-upsert__section-label">
				<SegmentLabel label="Inventory" />
			</div>
			<Row>
				<Col md={12}><DepartmentList locations={locations} /></Col>
				<Col md={12}><Vendor onVendorChange={onVendorChange} vendors={vendors} /></Col>
			</Row>
		</>
	);
};

export default Inventory;
