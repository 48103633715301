import * as React from 'react';
import type { InjectedFormProps } from 'redux-form';
import { Field } from 'redux-form';

import type DivisionRequestModel from 'ab-requestModels/division.requestModel';

import MultipleOptionsButton from 'af-components/MultipleOptionsButton';
import RectangleButton from 'af-components/MultipleOptionsButton/RectangleButton';

import Input from 'af-fields/Input';
import Tooltip from 'af-components/Tooltip';

interface Props extends InjectedFormProps<DivisionRequestModel> {
	onSubmit: (form: DivisionRequestModel) => void;
	close: () => void;
}

export default class DivisionForm extends React.PureComponent<Props> {

	handleKeyDown = (e: React.KeyboardEvent) => {
		const { handleSubmit, onSubmit, submitting } = this.props;
		if (e.key === 'Enter' && e.shiftKey === false) {
			e.preventDefault();
			if (!submitting) {
				handleSubmit(onSubmit)(e);
			}
		}
	};

	close = () => {
		const { close } = this.props;
		close();
	};

	render() {
		const { handleSubmit, onSubmit } = this.props;

		return (
			<form className="color-picker__form">
				<Field
					autoFocus={true}
					centered={true}
					component={Input}
					cursorControl={true}
					hideErrorText={true}
					isStandalone={true}
					name="name"
					onKeyDown={this.handleKeyDown}
					placeholder="Enter Division Name"
					type="text"
				/>
				<MultipleOptionsButton isLeftFlat={true}>
					<RectangleButton
						action={handleSubmit(onSubmit)}
						isLeftOpen={true}
						isSquare={true}
						label={
							<Tooltip message="Submit" >
								<span className="icon-check" />
							</Tooltip>
						}
					/>
					<RectangleButton
						action={this.close}
						isSquare={true}
						label={
							<Tooltip message="Close" >
								<span className="icon-close" />
							</Tooltip>
						}
					/>
				</MultipleOptionsButton>
			</form>
		);
	}
}
