import * as React from 'react';
import type { CustomRouteComponentProps } from 'react-router-dom';
import type { ConnectedProps } from 'react-redux';
import { connect } from 'react-redux';

import TableNameEnum from 'ab-enums/tableName.enum';

import type AgencyTableItemVM from 'ab-viewModels/agency/tableItem.viewModel';

import * as AgencyActions from 'af-actions/agency';

import Breadcrumbs from 'af-components/Breadcrumbs';
import LabelWithColor from 'af-components/LabelWithColor';
import type { TabProps, Column, ButtonData, RowInfo, BulkAction } from 'af-components/Table6';
import Table from 'af-components/Table6';
import TableButtonType from 'ab-enums/tableButtonType.enum';

import CLIENT from 'af-routes/client';

import type { RootState } from 'af-reducers';

type OwnProps = CustomRouteComponentProps;

type Props = OwnProps & ConnectedProps<typeof connector>;

class Agencies extends React.PureComponent<Props> {

	columns: Column<AgencyTableItemVM>[] = [
		{
			Header: 'Name',
			accessor: 'name',
			Cell: ({ original }) => <LabelWithColor color={original.color} text={<strong>{original.name}</strong>} />,
			width: 250,
		},
		{
			Header: 'Member Number',
			accessor: 'membersNumber',
			Cell: ({ original }) => <div>{original.membersNumber}</div>,
		},
	];

	static readonly DEACTIVATE_ACTION_LABEL = (<><span className="icon-delete" />Deactivate</>);
	static readonly DEACTIVATE_MODAL_CONFIRM_TEXT = 'Deactivate';
	static readonly DEACTIVATE_MODAL_TITLE = 'Deactivate agencies';

	static deleteAgencyModalTitle = () => (
		'You are about to deactivate an agency. If you proceed, labor from this agency will stop being usable on work orders.'
	);

	static deleteAgencyModalBody = () => (
		<div>
			This action can be undone. Do you wish to continue?
		</div>
	);

	static deleteAgencyModalText = () => (
		'Deactivate'
	);

	static activateAgencyModalTitle = () => (
		'You are about to activate an agency.'
	);

	static activateAgencyModalBody = () => (
		<div>
			Do you wish to continue?
		</div>
	);

	static activateAgencyModalText = () => (
		'Activate'
	);

	previewAgency = ({ original }: RowInfo<AgencyTableItemVM>) => {
		this.goToPreviewPage(original);
	};

	goToEditPage = (original: AgencyTableItemVM) => {
		const { history, location: { state: { orgAlias } }, companyData: { name: companyName } } = this.props;
		history.push(CLIENT.COMPANY.RESOURCES.AGENCY.EDIT(original.id.toString(), orgAlias, companyName));
	};

	goToPreviewPage = (original: AgencyTableItemVM) => {
		const { history, location: { state: { orgAlias } }, companyData: { name: companyName } } = this.props;
		history.push(CLIENT.COMPANY.RESOURCES.AGENCY.PREVIEW(original.id.toString(), orgAlias, companyName));
	};

	deleteAgency = async (original: AgencyTableItemVM) => {
		const { id } = original;
		const { softDelete } = this.props;

		await softDelete(id);
	};

	bulkDeleteAgencies = async (ids: number[]) => {
		const { bulkSoftDelete } = this.props;
		await bulkSoftDelete(ids);
	};

	activateAgency = async (original: AgencyTableItemVM) => {
		const { id } = original;
		const { activate } = this.props;

		await activate(id);
	};

	bulkActions = (): BulkAction[] => {
		return [
			{
				onActionConfirmation: this.bulkDeleteAgencies,
				label: Agencies.DEACTIVATE_ACTION_LABEL,
				key: 'deactivate',
				modalType: 'warning',
				modalSize: 'md',
				modalTitle: () => Agencies.DEACTIVATE_MODAL_TITLE,
				confirmText: () => Agencies.DEACTIVATE_MODAL_CONFIRM_TEXT,
				actionConfirmationBody: (ids: number[]) => `Are you sure you want to deactivate ${ids.length} ${ids.length > 1 ? 'agencies' : 'agency'}`,
			},
		];
	};

	tabs = (): TabProps<AgencyTableItemVM>[] => {
		const {
			findAllForCompanyTable,
			findDeletedForCompanyTable,
			history,
			location: { state: { orgAlias } },
			companyData: { name: companyName },
		} = this.props;

		const buttons: ButtonData[] = [
			{
				type: TableButtonType.PRIMARY,
				hasPermission: true,
				label: 'New Agency',
				icon: 'plus',
				onClick: async () => history.push(CLIENT.COMPANY.RESOURCES.AGENCY.CREATE(orgAlias, companyName)),
			},
		];

		return [
			{
				label: 'Temp Agencies',
				columns: this.columns,
				selectable: true,
				hasSearchInput: true,
				searchLabel: 'Agencies',
				fetch: findAllForCompanyTable,
				buttons,
				onRowClick: this.previewAgency,
				additionalBulkActions: this.bulkActions(),
				rowActions: [{
					label: 'Edit',
					action: this.goToEditPage,
					shouldRefresh: false,
				},
				{
					label: 'Preview',
					action: this.goToPreviewPage,
					shouldRefresh: false,
				},
				{
					label: 'Deactivate',
					action: this.deleteAgency,
					shouldRefresh: true,
					hasModal: true,
					modalStyle: 'danger',
					modalBody: Agencies.deleteAgencyModalBody,
					modalText: Agencies.deleteAgencyModalText,
					modalTitle: Agencies.deleteAgencyModalTitle,
				}],
			},
			{
				label: 'Recently Deactivated',
				columns: this.columns,
				selectable: false,
				hasSearchInput: true,
				searchLabel: 'Agencies',
				buttons,
				fetch: findDeletedForCompanyTable,
				rowActions: [{
					label: 'Preview',
					action: this.goToPreviewPage,
					shouldRefresh: false,
				},
				{
					label: 'Activate',
					action: this.activateAgency,
					shouldRefresh: true,
					hasModal: true,
					modalStyle: 'danger',
					modalBody: Agencies.activateAgencyModalBody,
					modalText: Agencies.activateAgencyModalText,
					modalTitle: Agencies.activateAgencyModalTitle,
				}],
			},

		];
	};

	breadcrumbs = () => [{ label: 'Temp Agencies' }];

	render() {
		return (
			<div className="form-segment form-segment--maxi">
				<Breadcrumbs items={this.breadcrumbs()} />
				<Table
					tableName={TableNameEnum.AGENCY}
					tabs={this.tabs()}
				/>
			</div>
		);
	}
}

function mapStateToProps(state: RootState) {
	const { companyData } = state.user;
	if (!companyData) {
		throw new Error('User not logged in');
	}

	return {
		companyData,
	};
}

function mapDispatchToProps() {
	return {
		findAllForCompanyTable: AgencyActions.findAllForCompanyTable,
		softDelete: AgencyActions.softDelete,
		bulkSoftDelete: AgencyActions.bulkSoftDelete,
		findDeletedForCompanyTable: AgencyActions.findDeletedForCompanyActions,
		activate: AgencyActions.activate,
	};
}

const connector = connect(mapStateToProps, mapDispatchToProps());

export default connector(Agencies);
