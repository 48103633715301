import * as React from 'react';

import type { ReportConfirmationVM, PCCompanyVM, PCJobVM } from 'ab-viewModels/report/publicConfirmation.viewModel';

import TimeSheets from './TimeSheets';
import FieldReport from './FieldReport';
import CompanyHeader from './CompanyHeader';
import ReportHeader from './ReportHeader';
import Signatures from './Signatures';

interface OwnProps {
	report: ReportConfirmationVM;
	company: PCCompanyVM;
	job: PCJobVM;
	reportRefs: React.MutableRefObject<HTMLDivElement[]>;
	index: number;
	hasMultipleReports: boolean;
	onReportSigning: (_workOrderId: number) => () => void;
}

type Props = OwnProps;

const ReportConfirmation: React.FC<Props> = (props: Props) => {

	const {
		report,
		report: { timeSheets, fieldReports, workOrder },
		company,
		company: { logo, address, website, companyName, customPDFHeader },
		job,
		reportRefs,
		index,
		hasMultipleReports,
		onReportSigning,
	} = props;

	const setRef = React.useCallback((ref) => reportRefs.current[index] = ref, [index, reportRefs]);

	return (
		<div className="public-report__content" ref={setRef}>
			<CompanyHeader
				address={address}
				companyName={companyName}
				customPDFHeader={customPDFHeader}
				logo={logo}
				website={website}
			/>
			<ReportHeader
				company={company}
				job={job}
				report={report}
			/>
			<TimeSheets timeSheets={timeSheets} timezone={workOrder.timeZone} />
			{fieldReports.map((_fieldReport, _index) => <FieldReport fieldReport={_fieldReport} key={_index} />)}
			<Signatures
				allowCustomerSignature={job.allowCustomerSignature}
				hasMultipleReports={hasMultipleReports}
				onReportSigning={onReportSigning}
				report={report}
			/>
		</div>
	);
};

export default ReportConfirmation;
