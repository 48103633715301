import * as React from 'react';
import { Row } from 'react-bootstrap';

import type { CompanyViewModel } from 'ab-viewModels/company.viewModel';

import LockedValue from 'af-components/LockedValue';

import SettingPreview from '../../Shared/SettingPreview';

interface Props {
	toggleEdit: () => void;
	company: CompanyViewModel;
}

export default class EHSPreview extends React.Component<Props> {
	render() {
		const { toggleEdit, company } = this.props;

		return (
			<SettingPreview toggleEdit={toggleEdit}>
				<div className="company-settings__info-segment">
					<Row className="row--padded">
						<LockedValue
							label="Notion Compliance URL"
							value={company.complianceEhsUrl ?? <div className="company-settings__empty">No Compliance Documentation available.</div>}
						/>
					</Row>
				</div>
			</SettingPreview>
		);
	}
}
