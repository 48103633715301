import * as React from 'react';
import type { Moment } from 'moment';

import TimeFormat from 'acceligent-shared/enums/timeFormat';

import { formatDate } from 'acceligent-shared/utils/time';

import Tooltip from 'af-components/Tooltip';

import EmptyCell from './EmptyCell';

import { CUSTOMER_SERVICE_NAME_SHORT, CUSTOMER_SERVICE_NAME } from 'ab-common/constants/value';

interface OwnProps {
	date: Date | Moment | string | null;
	format: TimeFormat;
	withTime?: boolean;
	details?: Nullable<string>;
	isCustomerService?: boolean;
	isLeftAligned?: boolean;
	showPreposition?: boolean;
	dateSourceFormat?: TimeFormat;
}

type Props = OwnProps;

const DateCell = (props: Props) => {
	const {
		date,
		format,
		withTime = false,
		details,
		isCustomerService = false,
		isLeftAligned = false,
		showPreposition = true,
		dateSourceFormat,
	} = props;

	if (!date) {
		return <EmptyCell />;
	}

	let className = 'date-cell';
	className = isLeftAligned ? `${className} date-cell--left` : className;

	return (
		<div className={className}>
			<div>
				{formatDate(date, format, dateSourceFormat)}
			</div>
			{
				withTime &&
				<div className="date-cell__time">
					{formatDate(date, TimeFormat.TIME_ONLY_12H, dateSourceFormat)}
				</div>
			}
			{
				isCustomerService &&
				<div className="date-cell__additional-info">
					{showPreposition && 'by '}
					{CUSTOMER_SERVICE_NAME_SHORT}
					<Tooltip
						message={CUSTOMER_SERVICE_NAME}
						placement="bottom"
					>
						<span className="icon-help_fill" />
					</Tooltip>
				</div>
			}
			{
				(details && !isCustomerService) &&
				<div className="date-cell__additional-info">
					{showPreposition && 'by'} {details}
				</div>
			}
		</div >
	);
};

export default React.memo(DateCell);
