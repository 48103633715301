import * as React from 'react';
import { compose } from 'redux';
import type { ConnectedProps } from 'react-redux';
import { connect } from 'react-redux';

import { DefaultColor } from 'acceligent-shared/enums/color';

import type { RootState } from 'af-reducers';

import { setCopiedEquipmentId } from 'af-actions/scheduleBoard/scheduleBoard.actions';

import MultiAssignmentsBadge from 'af-root/scenes/Company/ScheduleBoard/Shared/MultiAssignmentsBadge';
import CopyProgressBar from 'af-root/scenes/Company/ScheduleBoard/Shared/CopyProgressBar';
import PressAndHoldAnchor from 'af-root/scenes/Company/ScheduleBoard/Shared/PressAndHoldAnchor';

import DownEquipmentBadge from '../DownEquipmentBadge';

import * as ColorUtils from 'ab-utils/color.util';

import { generateEquipmentSearchItemId, generateWorkOrderEquipmentSearchItemId, isInToolbar } from 'af-utils/scheduleBoard.util';

interface OwnProps {
	draggableProps: Metadata;
	dragHandleProps: Metadata;
	onClick: () => void;
	innerRef: string;
	equipmentId: number;
	resourceId: number;
	isToolbar: boolean;
	isDragging: boolean;
	isDragAndDropDisabled: boolean;
	hasPermissionsToEditScheduleBoard: boolean;
	dueDate: string;
	isCardDisabled: boolean;
	isWorkOrderCanceled?: boolean;
	isDropAnimating?: boolean;
	draggingOver?: string;
	isWOLocked: boolean;
}

type Props = OwnProps & ConnectedProps<typeof connector>;

const Draggable: React.FC<Props> = (props) => {
	const {
		draggableProps,
		dragHandleProps,
		innerRef,
		onClick,
		equipment,
		isToolbar,
		equipmentAssignmentCount = 0,
		isDragging,
		searchId,
		copiedEquipmentId,
		isWorkOrderCanceled,
		isDragAndDropDisabled,
		hasPermissionsToEditScheduleBoard,
		isDailyView,
		isToolbarShown,
		isDeletedResource,
		isAvailable,
		isWOLocked,
		isDropAnimating,
		draggingOver,
		isDisabled,
		isActive,
		setCopiedEquipmentId: _setCopiedEquipmentId,
	} = props;

	const [draggableItemClassName, setDraggableItemClassName] = React.useState('');

	React.useEffect(() => {
		if (!equipment) {
			return;
		}
		const { color, isFilteredOnToolbar, isFilteredOnBoard, isMatched } = equipment;

		let newDraggableItemClassName = 'sb-resource-item sb-resource-item--equipment';

		if (isDropAnimating && !isToolbar && (!draggingOver || isInToolbar(draggingOver))) { // FIXME: animation is still rendering when dropping item in toolbar
			newDraggableItemClassName += ' sb-resource-item--hidden';
		} else {
			newDraggableItemClassName += isDisabled && !isWorkOrderCanceled ? ' sb-resource-item--disabled' : '';
			newDraggableItemClassName += isMatched ? ' sb-resource-item--highlighted' : '';
			newDraggableItemClassName += isActive ? ' sb-resource-item--active' : '';
			newDraggableItemClassName += (isToolbar && isFilteredOnToolbar) || (!isToolbar && isFilteredOnBoard) ? ' filtered' : '';
			newDraggableItemClassName += ` ${ColorUtils.getColorBackgroundClass(color ?? DefaultColor.CATEGORY)}`;
			newDraggableItemClassName = newDraggableItemClassName.trim();
		}

		setDraggableItemClassName(newDraggableItemClassName);
	}, [draggableItemClassName, draggingOver, equipment, isActive, isDisabled, isDropAnimating, isToolbar, isWorkOrderCanceled]);

	const onHoldEnd = React.useCallback(() => {
		_setCopiedEquipmentId(equipment?.id ?? null);
	}, [_setCopiedEquipmentId, equipment?.id]);

	const onRelease = React.useCallback(() => {
		if (!isDragging) {
			setCopiedEquipmentId(null);
		}
	}, [isDragging]);

	const renderEquipmentDraggable = React.useCallback((isCopying: boolean, copyFinished: boolean) => {
		const { name, spec: specification } = equipment ?? {};

		const body = (
			<div className="sb-resource-item__content">
				<span className="sb-resource-item__full-name sb-resource-item__full-name--transparent-bg">{name}</span>
				{specification && <span className="sb-resource-item__specification">{specification}</span>}
			</div>
		);

		return isToolbar || equipment?.isDeleted ? body : (
			<>
				{body}
				<CopyProgressBar
					copyFinished={copyFinished}
					isCopying={isCopying}
				/>
			</>
		);
	}, [equipment, isToolbar]);

	const { id: equipmentId } = equipment ?? {};

	if (isDeletedResource) {
		return null;
	}

	return (
		<>
			<div
				id={searchId ?? undefined}
				ref={innerRef}
				{...draggableProps}
				{...dragHandleProps}
				className={draggableItemClassName}
			>
				<PressAndHoldAnchor
					onClick={onClick}
					onHoldEnd={onHoldEnd}
					onRelease={onRelease}
					regularAnchor={
						(!isToolbar && !isAvailable)
						|| isToolbar
						|| !hasPermissionsToEditScheduleBoard
						|| (isDragAndDropDisabled && !isWOLocked)
						|| (!isDailyView && !isToolbarShown)
					}
				>
					{renderEquipmentDraggable}
				</PressAndHoldAnchor>
				{!isAvailable && <DownEquipmentBadge />}
				{isAvailable && !isWorkOrderCanceled && equipmentAssignmentCount > 1 &&
					<MultiAssignmentsBadge count={equipmentAssignmentCount} />
				}
			</div>
			{isDragging && copiedEquipmentId && copiedEquipmentId === equipmentId && (
				<div className={draggableItemClassName}>
					{renderEquipmentDraggable(false, false)}
					{!isAvailable && <DownEquipmentBadge />}
					{isAvailable && !isWorkOrderCanceled && equipmentAssignmentCount > 1 &&
						<MultiAssignmentsBadge count={equipmentAssignmentCount} />
					}
				</div>
			)}
		</>
	);
};

function mapStateToProps(state: RootState, ownProps: OwnProps) {
	const {
		equipment: _equipmentDict,
		draggedEquipmentId,
		copiedEquipmentId,
		workOrdersByDateDictionary,
		dates,
		weeklyViewDateWithToolbar,
		searchResultItems,
		activeSearchItemIndex,
	} = state.scheduleBoard;
	const { equipmentId, dueDate, isCardDisabled, isToolbar, isWorkOrderCanceled, resourceId } = ownProps;

	const dataOnDate = workOrdersByDateDictionary?.[dueDate];
	const equipment = _equipmentDict?.[equipmentId] ?? undefined;

	let searchId: Nullable<string> = null;
	let isDeletedResource = false;
	if (!isToolbar && equipment) {
		const _resources = (isWorkOrderCanceled ? dataOnDate?.canceledWorkOrderResourceLookups : dataOnDate?.workOrderResourceLookups) ?? {};
		if (_resources[resourceId]) {
			searchId = _resources[resourceId].workOrderEquipmentId !== undefined
				? generateWorkOrderEquipmentSearchItemId(_resources[resourceId].workOrderEquipmentId!.toString())
				: null;
		} else {
			isDeletedResource = true;
		}
	} else {
		searchId = generateEquipmentSearchItemId(equipmentId.toString());
	}

	const isAvailable = !equipment?.id || !dataOnDate?.equipmentUnavailabilityDetails?.[equipment?.id];
	const isDraggedFromToolbarInOtherTab = isToolbar && !!equipment?.isDisabled && !draggedEquipmentId;

	return {
		equipment,
		isActive: searchResultItems[activeSearchItemIndex] === searchId,
		searchId,
		isAvailable,
		copiedEquipmentId,
		isDisabled: (!isCardDisabled && draggedEquipmentId === equipmentId) || isDraggedFromToolbarInOtherTab,
		equipmentAssignmentCount: dataOnDate?.equipmentAssignments?.[equipmentId]?.length ?? 0,
		isDailyView: dates.length === 1,
		isToolbarShown: weeklyViewDateWithToolbar === dueDate,
		isDeletedResource,
	};
}

function mapDispatchToProps() {
	return {
		setCopiedEquipmentId,
	};
}

const connector = connect(mapStateToProps, mapDispatchToProps());

const enhance = compose<React.ComponentClass<OwnProps>>(
	React.memo,
	connector
);

export default enhance(Draggable);
