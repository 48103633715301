import type { TimeSheetEntryRM, TimeSplitEntryRM, TimeAllocationEntryRM } from 'ab-requestModels/timeSheet/timeSheetUpdate';

import type { ColorPalette } from 'acceligent-shared/enums/color';
import type TimeSheetEntryWorkType from 'acceligent-shared/enums/timeSheetEntryWorkType';
import type TimeSheetEntryType from 'acceligent-shared/enums/timeSheetEntryType';
import TimeFormat from 'acceligent-shared/enums/timeFormat';

import { formatDate } from 'acceligent-shared/utils/time';

import type CondensedTableVM from 'ab-viewModels/workOrder/workOrderForReportsCondensedTable';

export class TimeSheetEntryFM {
	id?: number;
	workType: Nullable<TimeSheetEntryWorkType>;
	startDate: Nullable<string>;
	/** ISO_DATETIME */
	startTime: Nullable<string>;
	/** ISO_DATETIME */
	endTime: Nullable<string>;
	isInActiveShift: boolean;
	type: TimeSheetEntryType;
	equipmentId: Nullable<number>;

	constructor(entry: CondensedTableVM['employees'][0]['timeSheetEntries'][0]) {
		this.id = entry.id;
		this.workType = entry.workType;
		// Needs to be cast to date first before turning it into a time zone - accurate values
		// This usually doesn't need to be done, but here we're extracting *JUST* times or *JUST* date,
		// and that loses the context if not properly used
		this.startDate = formatDate(new Date(entry.startTime), TimeFormat.DATE_ONLY);
		this.startTime = formatDate(new Date(entry.startTime), TimeFormat.ISO_DATETIME);
		this.endTime = entry.endTime ? formatDate(new Date(entry.endTime), TimeFormat.ISO_DATETIME) : null;
		this.isInActiveShift = entry.isInActiveShift;
		this.type = entry.type;
		this.equipmentId = entry.equipmentId;
	}

	static bulkConstructor(entries: CondensedTableVM['employees'][0]['timeSheetEntries']) {
		return entries.map(TimeSheetEntryFM._mapConstructor);
	}

	private static _mapConstructor(entry: CondensedTableVM['employees'][0]['timeSheetEntries'][0]) {
		return new TimeSheetEntryFM(entry);
	}

	static toRequestModel(entry: TimeSheetEntryFM): TimeSheetEntryRM {
		const { workType, startTime } = entry;

		if (!workType || !startTime) {
			throw new Error('workType and startTime are required in this context');
		}

		return {
			id: entry.id,
			isInActiveShift: entry.isInActiveShift,
			type: entry.type,
			workType,
			startTime,
			endTime: entry.endTime,
			equipmentId: entry.equipmentId,
		};
	}
}

interface ClassificationCodeFM {
	id: number;
	code: string;
}

interface EquipmentFM {
	id: number;
	code: string;
	specification: Nullable<string>;
	color: ColorPalette;
}

export class TimeSplitEntryFM {
	id: number;
	time: number;
	classificationCodeId: Nullable<number>;
	classificationCode: Nullable<ClassificationCodeFM>;
	equipmentId: number;
	equipment: EquipmentFM;
	allocatedWorkRequestId: number;

	constructor(entry: CondensedTableVM['employees'][0]['timeSplitEntries'][0]) {
		this.id = entry.id;
		this.time = entry.time;
		this.classificationCodeId = entry.fieldWorkClassificationCodeId;
		this.classificationCode = {
			id: entry.fieldWorkClassificationCodeId,
			code: entry.classificationCode,
		};
		this.equipmentId = entry.equipmentId;
		this.equipment = {
			id: entry.equipmentId,
			code: entry.equipmentCode,
			specification: entry.equipmentSpecification,
			color: entry.color,
		};
		this.allocatedWorkRequestId = entry.allocatedWorkRequestId;
	}

	static bulkConstructor(entries: CondensedTableVM['employees'][0]['timeSplitEntries']) {
		return entries.map(TimeSplitEntryFM._mapConstructor);
	}

	private static _mapConstructor(entry: CondensedTableVM['employees'][0]['timeSplitEntries'][0]) {
		return new TimeSplitEntryFM(entry);
	}

	static toRequestModel(entry: TimeSplitEntryFM): TimeSplitEntryRM {
		return {
			equipmentId: entry.equipment?.id ?? null,
			fieldWorkClassificationCodeId: entry.classificationCode?.id ?? null,
			time: entry.time ?? 0,
			allocatedWorkRequestId: entry.allocatedWorkRequestId,
		};
	}
}

export class TimeAllocationEntryFM {
	id?: number;
	time: number;
	workType: TimeSheetEntryWorkType;
	allocatedWorkRequestId: number;

	constructor(entry: CondensedTableVM['employees'][0]['timeAllocationEntries'][0]) {
		this.id = entry.id;
		this.time = entry.time;
		this.workType = entry.workType;
		this.allocatedWorkRequestId = entry.allocatedWorkRequestId;
	}

	static bulkConstructor(entries: CondensedTableVM['employees'][0]['timeAllocationEntries']) {
		return entries.map(TimeAllocationEntryFM._mapConstructor);
	}

	private static _mapConstructor(entry: CondensedTableVM['employees'][0]['timeAllocationEntries'][0]) {
		return new TimeAllocationEntryFM(entry);
	}

	static resetAllocationEntriesForWorkType = (
		timeAllocationEntries: EmployeeDataFM['timeAllocationEntries'],
		workType: TimeSheetEntryWorkType,
		workRequestId: number,
		timeSheetEntriesDurationForWorkType: number
	) => {
		const updatedEntries = timeAllocationEntries.filter((entry) => entry.workType !== workType);
		if (timeSheetEntriesDurationForWorkType > 0) {
			const newAllocationEntry = { time: timeSheetEntriesDurationForWorkType, workType, allocatedWorkRequestId: workRequestId };
			updatedEntries.push(newAllocationEntry);
		}
		return updatedEntries;
	};

	static toRequestModel(entry: TimeAllocationEntryFM): TimeAllocationEntryRM {
		return {
			workType: entry.workType,
			time: entry.time,
			allocatedWorkRequestId: entry.allocatedWorkRequestId,
		};
	}
}

export type EmployeeDataFM = {
	timeSheetEntries: TimeSheetEntryFM[];
	timeSplitEntries: TimeSplitEntryFM[];
	timeAllocationEntries: TimeAllocationEntryFM[];
};

class EmployeeFM {
	[accountId: number]: EmployeeDataFM;

	static bulkConstructor(employees: CondensedTableVM['employees']) {
		return employees.reduce<EmployeeFM>((_acc, _employee) => {
			_acc[_employee.accountId] = {
				timeSheetEntries: TimeSheetEntryFM.bulkConstructor(_employee.timeSheetEntries ?? []),
				timeSplitEntries: TimeSplitEntryFM.bulkConstructor(_employee.timeSplitEntries ?? []),
				timeAllocationEntries: TimeAllocationEntryFM.bulkConstructor(_employee.timeAllocationEntries ?? []),
			};
			return _acc;
		}, {});
	}
}

class FormModel {
	workOrderMap: {
		[workOrderId: string]: EmployeeFM;
	};

	static bulkConstructor(data: CondensedTableVM[]) {
		const workOrderMap = data.reduce<FormModel['workOrderMap']>((_acc, _data) => {
			_acc[_data.id] = EmployeeFM.bulkConstructor(_data.employees);
			return _acc;
		}, {});
		return { workOrderMap };
	}
}

export default FormModel;
