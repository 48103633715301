import * as React from 'react';
import { compose } from 'redux';
import { Droppable } from 'react-beautiful-dnd';
import type { ConnectedProps } from 'react-redux';
import { connect } from 'react-redux';
import Scrollbars from 'react-custom-scrollbars';

import type { RootState } from 'af-reducers';

import Cards from './Cards';

interface ScrollRefObject {
	getValues: () => {
		scrollLeft: number;
		scrollWidth: number;
		clientWidth: number;
	};
}

interface OwnProps {
	addBlankWorkOrder: (dueDate: string, index: number) => Promise<void>;
	droppableId: string;
	dueDate: string;
	forceUnlockOrder: (workOrderId: string) => void;
	hasPermissionsToEditScheduleBoard: boolean;
	isDragAndDropDisabled: boolean;
	lastOpenedOrderCode: string;
	maxWorkOrdersPerDay: number;
	onHorizontalScroll: (scrollPercentage: number, width: number) => void;
	onHorizontalScrollMount: (scrollElement: React.RefObject<ScrollRefObject>) => void;
	onHorizontalScrollUpdate: () => void;
	removeBlankWorkOrder: (dueDate: string, index: number) => Promise<void>;
}

type Props = OwnProps & ConnectedProps<typeof connector>;

const _renderTrackHorizontal = () => <div className="track-horizontal display-none" />;

const _renderThumbHorizontal = () => <div className="thumb-horizontal display-none" />;

const Board: React.FC<Props> = (props) => {
	const {
		addBlankWorkOrder,
		copiedWorkOrderCode,
		draggedWorkOrderCode,
		droppableId,
		dueDate,
		forceUnlockOrder,
		hasPermissionsToEditScheduleBoard,
		isDragAndDropDisabled,
		lastOpenedOrderCode,
		maxWorkOrdersPerDay,
		removeBlankWorkOrder,
		weeklyViewDateWithToolbar,
		workOrdersOrdering = [],
		onHorizontalScroll,
		onHorizontalScrollMount,
		onHorizontalScrollUpdate,
	} = props;

	const scrollbars = React.useRef<Nullable<ScrollRefObject>>(null);

	// On mount
	React.useEffect(() => {
		if (onHorizontalScrollMount && scrollbars) {
			onHorizontalScrollMount(scrollbars);
		}
		// eslint-disable-next-line react-hooks/exhaustive-deps
	}, []);

	React.useEffect(() => {
		if (onHorizontalScrollUpdate) {
			onHorizontalScrollUpdate();
		}
	}, [onHorizontalScrollUpdate]);

	const handleScroll = React.useCallback(() => {
		if (onHorizontalScroll && scrollbars?.current) {
			const { scrollLeft, scrollWidth, clientWidth } = scrollbars.current.getValues();
			const scrollPercentage = scrollLeft / (scrollWidth - clientWidth);
			onHorizontalScroll(scrollPercentage, scrollWidth - clientWidth);
		}
	}, [onHorizontalScroll]);

	const disableDraggingWhenToolbarOpened: boolean = !!weeklyViewDateWithToolbar && weeklyViewDateWithToolbar !== dueDate;
	const copyingWorkOrder: boolean = !!copiedWorkOrderCode && !!draggedWorkOrderCode && workOrdersOrdering.includes(draggedWorkOrderCode);
	const isDropDisabled: boolean = disableDraggingWhenToolbarOpened || copyingWorkOrder;

	return (
		<Scrollbars
			autoHide={true}
			autoHideDuration={200}
			autoHideTimeout={200}
			className="schedule-board-week-day-cards-wrapper"
			onScroll={handleScroll}
			ref={scrollbars as React.LegacyRef<Scrollbars>}
			renderThumbHorizontal={_renderThumbHorizontal}
			renderTrackHorizontal={_renderTrackHorizontal}
			thumbMinSize={60}
			universal={true}
		>
			<Droppable
				direction="horizontal"
				droppableId={droppableId}
				isDropDisabled={isDropDisabled}
			>
				{(provided, snapshot) => {
					return (
						<div
							className={`schedule-board-week-cards-container-wrapper ${snapshot.isDraggingOver ? 'drag-over' : ''} cards-${maxWorkOrdersPerDay}`}
							ref={provided.innerRef}
						>
							{workOrdersOrdering.length ?
								<div className="schedule-board-week-cards-container">
									<Cards
										addBlankWorkOrder={addBlankWorkOrder}
										dueDate={dueDate}
										forceUnlockOrder={forceUnlockOrder}
										hasPermissionsToEditScheduleBoard={hasPermissionsToEditScheduleBoard}
										isDragAndDropDisabled={isDragAndDropDisabled}
										lastOpenedOrderCode={lastOpenedOrderCode}
										removeBlankWorkOrder={removeBlankWorkOrder}
									/>
									{provided.placeholder}
								</div> :
								<div className="schedule-board-week-no-work">
									No work assigned for this day.
								</div>
							}
						</div>
					);
				}}
			</Droppable>
		</Scrollbars>
	);
};

const mapStateToProps = (state: RootState, ownProps: OwnProps) => {
	const { dueDate } = ownProps;
	const {
		zoomLevel,
		weeklyViewDateWithToolbar,
		workOrdersByDateDictionary,
		copiedWorkOrderCode,
		draggedWorkOrderCode,
	} = state.scheduleBoard;

	return {
		zoomLevel: zoomLevel,
		weeklyViewDateWithToolbar,
		workOrdersOrdering: workOrdersByDateDictionary?.[dueDate]?.workOrdersOrdering,
		copiedWorkOrderCode,
		draggedWorkOrderCode,
	};
};

const connector = connect(mapStateToProps);

const enhance = compose<React.ComponentType<OwnProps>>(
	React.memo,
	connector
);

export default enhance(Board);
