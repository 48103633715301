import * as React from 'react';

import type ContactVM from 'ab-viewModels/contact/contact';

import NonEditableMap from 'af-components/Maps/NonEditableMap';
import Value from 'af-components/LockedValue/Value';
import Radio from 'af-components/Controls/Radio';

import { bemElement } from 'ab-utils/bem.util';

interface Props {
	address: ContactVM['addresses'][0];
	onSelect: () => Promise<void>;
	isSelected: boolean;
	disabled?: boolean;
}

interface State {
	isExpanded: boolean;
}

export default class SelectableAddress extends React.PureComponent<Props, State> {
	state: State = {
		isExpanded: false,
	};

	toggleExpanded = () => {
		this.setState({ isExpanded: !this.state.isExpanded });
	};

	render() {
		const { address: contactAddress, onSelect, isSelected, disabled } = this.props;
		const { isExpanded } = this.state;

		const location = { lat: contactAddress.address.latitude, lng: contactAddress.address.longitude };
		const {
			suite,
			city,
			state,
			street,
			zip,
			postalOfficeBoxCode,
			country,
		} = contactAddress.address;

		let rowClassName = 'contact__methods-row contact__methods-row--address';
		rowClassName = !isSelected ? `${rowClassName} contact__methods-row--not-selected` : rowClassName;

		return (
			<>
				<div className={rowClassName}>
					<div className="contact__method-control">
						<Radio
							checked={isSelected}
							disabled={disabled}
							onCheck={onSelect}
						/>
					</div>
					<Value
						value={street && (
							<div className="contact__method-item">
								{street}
								{location && <span className="icon-search" onClick={this.toggleExpanded} />}
							</div>
						)}
					/>
					<Value value={suite} />
					<Value value={city} />
					<Value value={state} />
					<Value value={zip} />
					<Value value={postalOfficeBoxCode} />
					<Value value={country} />
				</div>
				{isExpanded &&
					<div className={bemElement('contact', 'method-row', ['unbound'])}>
						<NonEditableMap location={location} />
					</div>
				}
			</>
		);
	}
}
