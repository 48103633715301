import baseRoute from '../baseRoute';

export * from './viewModel';
export * from './queryParams';

import type { W_Authentication_PlatformAdminOrganizationLogin_QP } from './queryParams';

type RouteDefinition = `${typeof baseRoute}/login/platformAdminOrganization${'' | `?${keyof W_Authentication_PlatformAdminOrganizationLogin_QP}=${string}`}`;

export const ROUTE: RouteDefinition = `${baseRoute}/login/platformAdminOrganization`;

export const URL = (orgAlias: string): RouteDefinition => `${baseRoute}/login/platformAdminOrganization?orgAlias=${orgAlias}`;
