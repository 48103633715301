import * as React from 'react';
import { compose } from 'redux';
import type { InjectedFormProps} from 'redux-form';
import { reduxForm, Field, destroy } from 'redux-form';
import { Button, Row } from 'react-bootstrap';

import SubmitButton from 'af-components/SubmitButton';
import CustomModal from 'af-components/CustomModal';

import Input from 'af-fields/Input';

import { EDIT_BLOCK_NAME } from 'af-constants/reduxForms';

import { validate } from './validation';

export type CalculatedBlockNameForm = { name: string; };

interface OwnProps {
	close: () => void;
	getInitialValues: () => CalculatedBlockNameForm;
	onSave: (name: string, reportTypeBlockId: string) => void;
	reportTypeBlockId: Nullable<string>;
	show: boolean;
}

type Props = OwnProps & InjectedFormProps<CalculatedBlockNameForm, OwnProps>;

const EditBlockNameModal: React.FC<Props> = (props: Props) => {
	const { show, close, invalid, onSave, handleSubmit, getInitialValues, initialize, reportTypeBlockId } = props;

	React.useEffect(() => {
		if (show) {
			initialize(getInitialValues());
		} else {
			destroy();
		}
	}, [show, getInitialValues, initialize]);

	const handleSave = React.useCallback((form: CalculatedBlockNameForm) => {
		if (!reportTypeBlockId) {
			throw new Error('Missing block reportTypeBlockId');
		}
		onSave(form.name, reportTypeBlockId);
		close();
	}, [onSave, close, reportTypeBlockId]);

	return (
		<CustomModal
			className="report-block-modal"
			closeModal={close}
			modalStyle="info"
			showModal={show}
			size="sm"
		>
			<CustomModal.Header
				closeModal={close}
				title="Edit Segment"
			/>
			<CustomModal.Body>
				<Row className="row--non-padded">
					<Field
						component={Input}
						label="Segment Name"
						name="name"
					/>
				</Row>
			</CustomModal.Body>
			<CustomModal.Footer>
				<Button onClick={close} variant="info">
					Cancel
				</Button>
				<SubmitButton
					disabled={invalid}
					label="Save"
					onClick={handleSubmit(handleSave)}
				/>
			</CustomModal.Footer>
		</CustomModal>
	);
};

const enhance = compose<React.ComponentType<OwnProps>>(
	React.memo,
	reduxForm<CalculatedBlockNameForm, OwnProps>({ form: EDIT_BLOCK_NAME, validate })
);

export default enhance(EditBlockNameModal);
