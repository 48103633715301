import * as React from 'react';

import { generateSiteContactAddressStringIdentifier } from 'af-utils/workOrderModal.util';

import type { ContactAddressModel } from 'ab-viewModels/scheduleBoardWorkOrderModal.viewModel';

interface Props {
	addresses: ContactAddressModel[];
	removedAddressDictionary?: { [addressStringIdentifier: string]: boolean; };
}

class SiteContactAddressList extends React.Component<Props> {
	static defaultProps: Partial<Props> = {
		addresses: [],
		removedAddressDictionary: {},
	};

	render() {
		const { addresses, removedAddressDictionary } = this.props;

		return (
			addresses.length > 0 &&
			<div className="work-order-info-modal__site-contact-info-container">
				<div className="work-order-info-modal__site-contact-location work-order-info-modal__site-contact-location--header">
					<div>Street Address</div>
					<div>Suite/Floor</div>
					<div>City</div>
					<div>State</div>
					<div>Zip</div>
					<div>Country</div>
				</div>
				{addresses.map((_address, _index) => (
					<div
						className={`work-order-info-modal__site-contact-location ${removedAddressDictionary?.[generateSiteContactAddressStringIdentifier(_address)] ? 'work-order-info-modal__site-contact-location--removed' : ''}`}
						key={`siteContactAddress#${_index}`}
					>
						<div>{_address.street}</div>
						<div>{_address.suite}</div>
						<div>{_address.city}</div>
						<div>{_address.stateAbbreviation}</div>
						<div>{_address.zip}</div>
						<div>{_address.country}</div>
					</div>
				))}
			</div>
		);
	}
}

export default SiteContactAddressList;
