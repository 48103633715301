import * as React from 'react';
import { Button } from 'react-bootstrap';
import type { ConnectedProps } from 'react-redux';
import { connect } from 'react-redux';
import { compose } from 'redux';

import PagePermissions from 'ab-enums/pagePermissions.enum';

import { isAllowed } from 'ab-utils/auth.util';

import type { RootState } from 'af-reducers/index';

import CustomModal from 'af-components/CustomModal';
import SubmitButton from 'af-components/SubmitButton';

import Radio from 'af-components/Controls/Radio';

import { useToggle } from 'af-utils/react.util';

import NewContactForm, { NewContactFormModel } from './NewContactForm';
import SavedContactForm, { SavedContactFormModel } from './SavedContactForm';
import type { SubmitActionCallbackData } from './types';

interface OwnProps {
	title: string;
	description: Nullable<JSX.Element>;
	action: (data: SubmitActionCallbackData, formName: string) => void;
	actionLabel: string;
	closeModal: () => void;
	showModal: boolean;
	goBack?: () => void;
}

type Props = OwnProps & ConnectedProps<typeof connector>;

const ContactActionModal: React.FC<Props> = (props) => {
	const {
		action,
		actionLabel,
		isSavedContactsFormEnabled,
		closeModal,
		description,
		getSubmitActionData,
		goBack,
		showModal,
		title,
	} = props;

	const {
		value: isNewContactForm,
		setToTrue: setFormToNewContact,
		setToFalse: setFormToSavedContact,
	} = useToggle(!isSavedContactsFormEnabled);

	/** equals `isNewContactForm` state, but used in case state is invalid (i.e. "false" when SavedContactForm is disabled) */
	const useNewContactForm = isNewContactForm || !isSavedContactsFormEnabled;
	const formName = useNewContactForm ? NewContactFormModel.FORM_NAME : SavedContactFormModel.FORM_NAME;

	const handleSubmit = React.useCallback(() => {
		const submitActionData = getSubmitActionData(useNewContactForm);
		action(submitActionData, formName);
	}, [action, getSubmitActionData, useNewContactForm, formName]);

	return (
		<CustomModal
			className="contact-action-modal"
			modalStyle="info"
			showModal={showModal}
			size="md"
		>
			<CustomModal.Header closeModal={closeModal} title={title} />
			<CustomModal.Body>
				<div className="contact-action-modal__description-container">
					{description}
				</div>
				<div className="contact-action-modal__form-switcher">
					{isSavedContactsFormEnabled && <Radio checked={!useNewContactForm} inline={true} label="Saved contact" onCheck={setFormToSavedContact} />}
					<Radio checked={useNewContactForm} inline={true} label="New contact" onCheck={setFormToNewContact} />
				</div>
				<div className="contact-action-modal__form-container">
					{useNewContactForm ? <NewContactForm /> : <SavedContactForm />}
				</div>
			</CustomModal.Body>
			<CustomModal.Footer>
				{goBack ? (<Button onClick={goBack} variant="info">Back</Button>) : null}
				<Button onClick={closeModal} variant="info">Cancel</Button>
				<SubmitButton label={actionLabel} onClick={handleSubmit} submitKey={formName} />
			</CustomModal.Footer>
		</CustomModal>
	);
};

function mapStateToProps(state: RootState) {
	const { user: { companyData, userData } } = state;
	if (!userData || !companyData) {
		throw new Error('User not logged in');
	}

	return {
		isSavedContactsFormEnabled: isAllowed(PagePermissions.COMPANY.CONTACTS.VIEW, companyData.permissions, companyData.isCompanyAdmin, userData.role),
		getSubmitActionData: ((isNewContactForm: boolean) => {
			return isNewContactForm ? NewContactFormModel.getSubmitActionData(state) : SavedContactFormModel.getSubmitActionData(state);
		}),
	};
}

const connector = connect(mapStateToProps, null, null, {
	areStatesEqual: (nextState, prevState) => nextState.form === prevState.form && nextState.user === prevState.user,
});

const enhance = compose<React.ComponentType<OwnProps>>(
	React.memo,
	connector
);

export type ContactActionModalSubmitActionCallbackData = SubmitActionCallbackData;
export type ContactActionModalProps = OwnProps;
export default enhance(ContactActionModal);
