import * as React from 'react';
import { compose } from 'redux';
import type { ResolveThunks } from 'react-redux';
import { connect } from 'react-redux';
import type { CustomRouteComponentProps } from 'react-router-dom';
import type { InjectedFormProps } from 'redux-form';
import { reduxForm } from 'redux-form';

import type ImportContactRM from 'ab-requestModels/contact/import';
import { EmailTypes, PhoneTypes } from 'acceligent-shared/enums/contactMethodType';

import * as ContactActions from 'af-actions/contacts';

import BulkImport from 'af-components/BulkUpsert/Import';
import CodeField from 'af-components/CodeField';

import { CONTACTS_CSV_SAMPLE } from 'af-constants/csvSamples';
import { CONTACT_BULK_IMPORT } from 'af-constants/reduxForms';

import CLIENT from 'af-constants/routes/client';

import parse from 'af-root/scenes/Company/Contacts/BulkUpload/parse';

import type { RootState } from 'af-reducers';

interface StateProps {
	companyName: string;
}

interface DispatchProps {
	importBatch: typeof ContactActions.importBatch;
}

type OwnProps = CustomRouteComponentProps;

type Props = OwnProps & StateProps & ResolveThunks<DispatchProps> & InjectedFormProps<ImportContactRM>;

class BulkUploadContactForm extends React.PureComponent<Props> {

	static MANDATORY_FIELDS = [
		'fullName',
		'addresses.0.street',
		'addresses.0.country',
		'addresses.0.locality',
		'addresses.0.postalCode',
		'addresses.0.aa1',
		'phones.0.countryCode',
		'phones.0.value',
		'phones.0.type',
		'emails.0.value',
	];

	static MANUAL = [
		'Import multiple contacts to your contacts list by using a CSV upload feature.',
		'Fields that should be located in the CSV are listed below.',
		'The ones marked with an asterisk are required and should not be left out.',
		'You can add multiple addresses / phones / emails by indexing them.',
		<>
			The fields with <code>.0.</code> pattern can used multiple times,
			and the index (in this case <code>0</code>) will connect multiple fields together.
		</>,
	];

	static FIELDS = (
		<CodeField isBlue={true}>
			<>
				<b>fullName</b>                 | * | Full Name <br />
				<b>companyName</b>              |   | Company Name <br />
				<b>contactRole</b>              |   | Contact Role <br />
				<b>addresses.0.latitude</b>     |   | Address - Latitude <em>(defaults to 0)</em> <br />
				<b>addresses.0.longitude</b>    |   | Address - Longitude <em>(defaults to 0)</em> <br />
				<b>addresses.0.street</b>       | * | Address - Street <br />
				<b>addresses.0.country</b>      | * | Address - Country <br />
				<b>addresses.0.locality</b>     | * | Address - City <br />
				<b>addresses.0.postalCode</b>   | * | Address - Postal Code <br />
				<b>addresses.0.aa1</b>          | * | Address - State <br />
				<b>addresses.0.aa2</b>          |   | Address - County <br />
				<b>addresses.0.route</b>        |   | Address - Route <br />
				<b>addresses.0.streetNumber</b> |   | Address - Street Number <br />
				<b>addresses.0.suite</b>        |   | Address - Suite / Floor <br />
				<b>phones.0.countryCode</b>     | * | Country Code - e.g. US<br />
				<b>phones.0.value</b>           | * | Mobile Phone - Number ((###) ###-####)<br />
				<b>phones.0.extension</b>       |   | Mobile Phone - Extension <br />
				<b>phones.0.type</b>            | * | Mobile Phone - Type ({Object.keys(PhoneTypes).join(', ')}) <br />
				<b>emails.0.value</b>           | * | Email <br />
			</>
		</CodeField>
	);

	importBatch = async (form: ImportContactRM[]) => {
		const { importBatch } = this.props;
		const data: ImportContactRM[] = form.map((_contact) => {
			const emails = _contact.emails.map((_email) => ({ ..._email, type: EmailTypes.EMAIL_DEFAULT }));
			return {
				..._contact,
				emails,
			};
		});
		return await importBatch(data);
	};

	render() {
		const { location: { state: { orgAlias } }, companyName } = this.props;

		return (
			<BulkImport
				fields={BulkUploadContactForm.FIELDS}
				importBatch={this.importBatch}
				listRoute={CLIENT.COMPANY.CONTACTS.LIST(orgAlias, companyName)}
				mandatoryFields={BulkUploadContactForm.MANDATORY_FIELDS}
				manual={BulkUploadContactForm.MANUAL}
				parse={parse}
				sampleData={CONTACTS_CSV_SAMPLE}
				title="Contacts"
			/>
		);
	}
}

function mapStateToProps(state: RootState): StateProps {
	const { companyData } = state.user;
	if (!companyData) {
		throw new Error('User not logged in');
	}

	return {
		companyName: companyData.name,
	};
}

function mapDispatchToProps(): DispatchProps {
	return {
		importBatch: ContactActions.importBatch,
	};
}

const enhance = compose<React.ComponentClass<OwnProps>>(
	connect(mapStateToProps, mapDispatchToProps()),
	reduxForm({
		form: CONTACT_BULK_IMPORT,
		enableReinitialize: true,
	})
);

export default enhance(BulkUploadContactForm);
