import * as React from 'react';
import type { InjectedFormProps} from 'redux-form';
import { reduxForm } from 'redux-form';

import { validate } from './validation';
import type { OwnProps } from './TagForm';
import TagForm from './TagForm';

type Props<T> = OwnProps<T> & InjectedFormProps<T, OwnProps<T>, T>;

class CreateTag<T> extends React.PureComponent<Props<T>> {
	createTag = async (form: T) => {
		const { close, onSubmit } = this.props;
		await onSubmit(form);
		close?.();
	};

	render() {
		return (
			<TagForm<T>
				{...this.props}
				onSubmit={this.createTag}
			/>
		);
	}
}

export default function <T>(props: OwnProps<T>) {
	const _validate = (form: T) => validate<T>(form, props);
	const Form = reduxForm<T, OwnProps<T>>({ validate: _validate, enableReinitialize: true })(CreateTag);
	return <Form {...props} />;
}
