import * as React from 'react';
import type { WrappedFieldArrayProps } from 'redux-form';
import { FieldArray } from 'redux-form';

import type UserGroupRequestModel from 'ab-requestModels/userGroup.requestModel';

import type { AccountViewModel } from 'ab-viewModels/account.viewModel';

import type { OwnProps as AccountsOwnProps } from './Accounts';
import Accounts from './Accounts';

import type NotificationPriority from 'ab-enums/notificationPriority.enum';

export interface OwnProps {
	customError?: string;
	onExcludeAccount: (userGroupName: string, accountId: number) => void;
	includedAccounts: Partial<AccountViewModel>[];
	notificationPriority: NotificationPriority;
}

interface State {
	employeesCount: number;
}

type Props = OwnProps & WrappedFieldArrayProps<UserGroupRequestModel>;

class UserGroups extends React.PureComponent<Props> {
	state = {
		employeesCount: 0,
	};

	static getDerivedStateFromProps(props: Props, oldState: State) {
		const includedAccountIds = props?.includedAccounts?.map((_account) => _account.id) ?? [];
		const userGroups: UserGroupRequestModel[] = props.fields.getAll() || [];
		const accountIds: number[] = userGroups.reduce<number[]>((_accountIds, _userGroup) => {
			_accountIds.push(...(_userGroup?.accounts?.map((_account) => _account.id) ?? []));
			return _accountIds;
		}, []);
		const employeesCount: number = new Set([...accountIds, ...includedAccountIds]).size;
		return oldState.employeesCount !== employeesCount ? { employeesCount } : null;
	}

	render() {
		const { fields, onExcludeAccount, notificationPriority, customError } = this.props;
		const { employeesCount } = this.state;

		return (
			<div className="user-groups-selected-employees">
				<div className="m-b-xs">The following <strong className="text-orange">{employeesCount}</strong> employees will be notified:</div>
				<div className="l-h-r f-s-s text-blue">* Duplicate employees will <strong>only be notified once.</strong></div>
				<div className="l-h-r f-s-s p-b-m text-blue">** Removing an employee, <strong>all</strong> the occurrences of that employee will be removed.</div>
				{fields.map((_userGroupFieldName, _index) => {
					const _userGroup = fields.get(_index);
					return (
						<div className="p-b-m" key={_userGroup.name}>
							<div className="m-b-s"><strong>{_userGroup.name}</strong></div>
							<div className="pills">
								<FieldArray<AccountsOwnProps>
									component={Accounts}
									name={`${_userGroupFieldName}.accounts`}
									notificationPriority={notificationPriority}
									onExcludeAccount={onExcludeAccount}
									userGroupName={_userGroup.name}
								/>
							</div>
						</div>
					);
				})}
				{customError && <span className="help-block"><span className="icon-info"></span>{customError}</span>}
			</div>
		);
	}
}

export default UserGroups;
