import * as React from 'react';

import type Priority from 'acceligent-shared/enums/priority';

import LockedValue from 'af-components/LockedValue';

import { previewSectionClass, previewSectionTitleClass, previewSectionClassRow, previewSectionClassColumn2 } from '../../helpers';

type Props = {
	jobCode: string;
	title: Nullable<string>;
	isInternal: boolean;
	customerCompanyName: Nullable<string>;
	customerReferencePO: Nullable<string>;
	isEmergencyJob: boolean;
	priority: Priority;
	statusName: Nullable<string>;
	projectManagerFullName: Nullable<string>;
	officeNickname: Nullable<string>;
	divisionName: Nullable<string>;
	isJobPreview: boolean;
};

const JobSummarySection: React.FC<Props> = (props) => {
	const {
		customerCompanyName,
		customerReferencePO,
		divisionName,
		isEmergencyJob,
		isInternal,
		jobCode,
		officeNickname,
		priority,
		projectManagerFullName,
		statusName,
		title,
		isJobPreview,
	} = props;

	return (
		<div className={previewSectionClass}>
			<div className={previewSectionTitleClass}>
				Job Summary
			</div>
			<div className={previewSectionClassRow}>
				<div className={previewSectionClassColumn2}>
					<LockedValue label={isJobPreview ? 'Job ID' : 'Work Request ID'} value={jobCode} />
				</div>
				<div className={previewSectionClassColumn2}>
					<LockedValue label="Job Title" value={title} />
				</div>
				<div className={previewSectionClassColumn2}>
					<LockedValue label="Internal Job" value={isInternal ? 'YES' : 'No'} />
				</div>
			</div>
			<div className={previewSectionClassRow}>
				<div className={previewSectionClassColumn2}>
					<LockedValue label="Customer Company" value={customerCompanyName} />
				</div>
				<div className={previewSectionClassColumn2}>
					<LockedValue label="Customer Reference/PO" value={customerReferencePO} />
				</div>
				<div className={previewSectionClassColumn2}>
					<LockedValue label="Emergency Job" value={isEmergencyJob ? 'YES' : 'No'} />
				</div>
			</div>
			<div className={previewSectionClassRow}>
				<div className={previewSectionClassColumn2}>
					<LockedValue label="Priority" value={priority} />
				</div>
				<div className={previewSectionClassColumn2}>
					<LockedValue label="Job Status" value={statusName} />
				</div>
			</div>
			<div className={previewSectionClassRow}>
				<div className={previewSectionClassColumn2}>
					<LockedValue label="Project Manager" value={projectManagerFullName} />
				</div>
				<div className={previewSectionClassColumn2}>
					<LockedValue label="Office" value={officeNickname} />
				</div>
				<div className={previewSectionClassColumn2}>
					<LockedValue label="Division" value={divisionName} />
				</div>
			</div>
		</div>
	);
};

export default React.memo(JobSummarySection);
