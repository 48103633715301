import * as React from 'react';
import type { InjectedFormProps} from 'redux-form';
import { reduxForm, Field } from 'redux-form';
import { Row, Form, Col, Button } from 'react-bootstrap';
import { compose } from 'redux';
import type { ResolveThunks } from 'react-redux';
import { connect } from 'react-redux';

import type { RootState } from 'af-reducers';

import * as CompanyActions from 'af-actions/companies';

import { EQUIPMENT_SETTINGS } from 'af-constants/reduxForms';

import CustomModal from 'af-components/CustomModal';
import SubmitButton from 'af-components/SubmitButton';

import Checkbox from 'af-fields/Checkbox';

import type { EquipmentSettingsRM } from 'ab-requestModels/equipment.requestModel';

import EquipmentSettings, { EquipmentSettingsHint } from 'ab-enums/equipmentSettings.enum';

interface OwnProps {
	isVisible: boolean;
	toggleEdit: () => void;
}

interface StateProps {
	initialValues: FormProps['initialValues'];
}

interface DispatchProps {
	editEquipmentSettings: typeof CompanyActions.editEquipmentSettings;
}

type FormOwnProps = OwnProps & StateProps & ResolveThunks<DispatchProps>;

type FormProps = InjectedFormProps<EquipmentSettingsRM, FormOwnProps>;

type Props = FormOwnProps & FormProps;

class EquipmentStatusSettingsEdit extends React.PureComponent<Props> {

	editEquipmentSettings = async (form: EquipmentSettingsRM) => {
		const { toggleEdit, editEquipmentSettings } = this.props;
		await editEquipmentSettings(form);
		toggleEdit();
	};

	render() {
		const { isVisible, toggleEdit, handleSubmit, invalid, submitting } = this.props;

		return (
			<CustomModal
				closeModal={toggleEdit}
				modalStyle="info"
				showModal={isVisible}
				size="lg"
			>
				<CustomModal.Header
					closeModal={toggleEdit}
					title="Edit Equipment Settings"
				/>
				<CustomModal.Body padding="none">
					<Form onSubmit={handleSubmit(this.editEquipmentSettings)}>
						<Row className="row--padded-bottom">
							<Col sm={24}>
								<Field
									component={Checkbox}
									id="isEquipmentAutomaticReturnDate"
									label={EquipmentSettings.isEquipmentAutomaticReturnDate}
									name="isEquipmentAutomaticReturnDate"
									tooltipMessage={EquipmentSettingsHint.isEquipmentAutomaticReturnDate}
								/>
							</Col>
						</Row>
					</Form>
				</CustomModal.Body>
				<CustomModal.Footer>
					<Button
						onClick={toggleEdit}
						variant="info"
					>
						Cancel
					</Button>
					<SubmitButton
						disabled={invalid}
						label="Save"
						onClick={handleSubmit(this.editEquipmentSettings)}
						reduxFormSubmitting={submitting}
					/>
				</CustomModal.Footer>
			</CustomModal>
		);
	}
}

function mapDispatchToProps(): DispatchProps {
	return {
		editEquipmentSettings: CompanyActions.editEquipmentSettings,
	};
}

function mapStateToProps(state: RootState): StateProps {
	const { company } = state.company;

	return {
		initialValues: { isEquipmentAutomaticReturnDate: company?.isEquipmentAutomaticReturnDate } as EquipmentSettingsRM,
	};
}

const enhance = compose<React.ComponentClass<OwnProps>>(
	connect<StateProps, DispatchProps>(mapStateToProps, mapDispatchToProps()),
	reduxForm<EquipmentSettingsRM, FormOwnProps>({ form: EQUIPMENT_SETTINGS, enableReinitialize: true })
);

export default enhance(EquipmentStatusSettingsEdit);
