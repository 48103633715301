import * as React from 'react';
import { compose } from 'redux';
import type { ConnectedProps } from 'react-redux';
import { connect } from 'react-redux';

import type { RootState } from 'af-reducers';

import { TOOLBAR_GROUP_DEFAULT_ID } from 'ab-common/constants/scheduleBoard';
import { AVAILABLE_EQUIPMENT_STATUS } from 'ab-common/constants/equipment';

import EquipmentGroup from './EquipmentGroup';

interface OwnProps {
	dueDate: string;
	isDragAndDropDisabled: boolean;
	hasPermissionsToEditScheduleBoard: boolean;
}

type Props = OwnProps & ConnectedProps<typeof connector>;

const AvailableEquipment: React.FC<Props> = (props) => {
	const {
		dueDate,
		locationItems = [],
		hasDefault = false,
		isDragAndDropDisabled,
		hasPermissionsToEditScheduleBoard,
	} = props;

	return (
		<div className="schedule-board-toolbar-equipment-row">
			<label>Equipment</label>
			<div className="schedule-board-toolbar-equipment-wrapper">
				<div className="schedule-board-toolbar-equipment">
					{
						locationItems?.reduce((acc: JSX.Element[], { id: _equipmentStatusId, title: _equipmentStatusName, isDeleted }) => {
							if (_equipmentStatusName) {
								acc.push(
									<EquipmentGroup
										available={true}
										dueDate={dueDate}
										groupId={_equipmentStatusId}
										groupTitle={_equipmentStatusName}
										hasPermissionsToEditScheduleBoard={hasPermissionsToEditScheduleBoard}
										isDeleted={isDeleted}
										isDragAndDropDisabled={isDragAndDropDisabled}
										key={_equipmentStatusId}
									/>
								);
							}
							return acc;

						}, [] as JSX.Element[])
					}
					{
						hasDefault &&
						<EquipmentGroup
							available={true}
							dueDate={dueDate}
							groupId={TOOLBAR_GROUP_DEFAULT_ID}
							groupTitle={AVAILABLE_EQUIPMENT_STATUS}
							hasPermissionsToEditScheduleBoard={hasPermissionsToEditScheduleBoard}
							isDeleted={false}
							isDragAndDropDisabled={isDragAndDropDisabled}
						/>
					}
				</div>
			</div>
		</div>
	);
};

function mapStateToProps(state: RootState, ownProps: OwnProps) {
	const { dueDate } = ownProps;

	if (!state.scheduleBoard.workOrdersByDateDictionary[dueDate]) {
		return {};
	}

	const { toolbarEquipment } = state?.scheduleBoard?.workOrdersByDateDictionary?.[dueDate];
	const { toolbarEquipmentGroupTitles } = state?.scheduleBoard;

	return {
		locationItems: toolbarEquipmentGroupTitles?.available,
		hasDefault: !!toolbarEquipment?.available?.[TOOLBAR_GROUP_DEFAULT_ID],
	};
}

const connector = connect(mapStateToProps);

const enhance = compose<React.ComponentType<OwnProps>>(
	React.memo,
	connector
);

export default enhance(AvailableEquipment);
