import * as React from 'react';
import { FormControl } from 'react-bootstrap';

import Label from 'af-components/LockedValue/Label';
import type { OwnProps as TooltipProps } from 'af-components/Tooltip';

interface OwnProps {
	label?: string;
	placeholder: string;
	value: string;
	disabled?: boolean;
	onValueChange?: (value: string) => void;
	showMaxCharactersLabel?: boolean;
	maxCharacters?: number;
	rows?: number;
	tooltipMessage?: TooltipProps['message'];
}

type Props = OwnProps;

class Textarea extends React.Component<Props> {
	static defautlProps: Partial<Props> = {
		showMaxCharactersLabel: true,
		maxCharacters: 750,
		rows: 4,
	};

	onChange = async (event) => {
		const { onValueChange } = this.props;

		if (onValueChange) {
			onValueChange(event.target.value);
		}
	};

	render() {
		const {
			disabled,
			label,
			placeholder,
			rows,
			showMaxCharactersLabel,
			maxCharacters,
			value,
			tooltipMessage,
		} = this.props;

		return (
			<div>
				<div className="input-header">
					<Label
						label={label}
						tooltipMessage={tooltipMessage}
						withMargin={true}
					/>
					{showMaxCharactersLabel && maxCharacters && <span className="input-description pull-right">{value.length}/{maxCharacters}</span>}
				</div>
				<FormControl
					as="textarea"
					autoComplete="off"
					disabled={disabled}
					maxLength={maxCharacters}
					onChange={this.onChange}
					placeholder={placeholder}
					rows={rows}
					value={value}
				/>
			</div>
		);
	}
}

export default Textarea;
