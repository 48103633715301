import * as React from 'react';
import type { LoadScriptProps } from '@react-google-maps/api';
import { useLoadScript } from '@react-google-maps/api';

import API from 'af-routes/api';

const LIBRARIES: LoadScriptProps['libraries'] = ['places'];

function GoogleScriptLoader(props) {
	const { isLoaded, loadError } = useLoadScript({
		googleMapsApiKey: API.GOOGLE.MAP,
		libraries: LIBRARIES,
	});

	const renderMap = () => {
		return props.children;
	};

	const renderLoading = () => <span>Loading Map</span>;

	if (loadError) {
		return null;
	}

	return isLoaded ? renderMap() : renderLoading();
}

export default React.memo(GoogleScriptLoader);
