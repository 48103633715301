import * as React from 'react';

import type { CompanyViewModel } from 'ab-viewModels/company.viewModel';

import CompanyFeatureFlagsEnum, { CompanyFeatureFlagsTips } from 'ab-enums/companyFeatureFlags.enum';

import ImageTag from 'af-components/Image';

import SettingPreview from '../../Shared/SettingPreview';
import PreviewItem from '../../Shared/PreviewItem';

interface Props {
	toggleEdit: () => void;
	company: CompanyViewModel;
}

export default class CompanyPreview extends React.Component<Props> {
	render() {
		const { toggleEdit, company } = this.props;
		const { customPDFHeaderUrl } = company;

		return (
			<SettingPreview toggleEdit={toggleEdit}>
				<div className="company-settings__info-segment">
					<PreviewItem
						checked={!!customPDFHeaderUrl}
						hint={CompanyFeatureFlagsTips.customPDFHeader}
						indent={false}
						label={CompanyFeatureFlagsEnum.customPDFHeader}
						showCheckbox={true}
					/>
					{!!customPDFHeaderUrl &&
						<div className="company-settings__printout-wrapper">
							<ImageTag
								className="company-settings__printout"
								src={customPDFHeaderUrl}
							/>
						</div>
					}
				</div>
			</SettingPreview>
		);
	}
}
