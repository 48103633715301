import * as ReportBlockFieldEnums from 'acceligent-shared/enums/reportBlockField';
import { OperationName, MinOperandCount, CalculationsWithUnit } from 'acceligent-shared/enums/operation';
import OperandType from 'acceligent-shared/enums/operand';

import { isValidTextInput } from 'acceligent-shared/utils/text';

import type { ReportBlockFormModel, ReportBlockFieldFormModel } from '../../Shared/formModel';

const MIN_OTHER_OPTIONS_NUM = 1;
const MIN_OPTIONS_NUM = 2;

export function validateReportBlock(values: ReportBlockFormModel) {
	const errors: ValidationErrors = {};

	if (!isValidTextInput(values.name)) {
		errors.name = 'Name is required';
	}

	if (!isValidTextInput(values.uniqueId)) {
		errors.uniqueId = 'Unique ID is required';
	}

	return errors;
}

export function validateReportBlockField(values: ReportBlockFieldFormModel, props) {
	const fieldsByIdMap = props.fieldsByIdMap;
	const errors: ValidationErrors = {};

	if (!isValidTextInput(values.name) && values.fieldType !== ReportBlockFieldEnums.Type.LINE_BREAK) {
		errors.name = 'Name is required';
	}

	if (!values.dimension) {
		errors.dimension = 'Dimension is required';
	}

	if (!values.fieldType) {
		errors.fieldType = 'Field Type is required';
	}

	const nameCount = (values.names ?? []).filter((_name) => _name === values.name);
	if (nameCount.length) {
		errors.name = 'Name must be unique.';
	}

	if (values.hasTooltip) {
		if (!values.tooltipText) {
			errors.tooltipText = 'Tooltip text must not be empty.';
		}
	}

	const minOptionsNum = values.allowCustomDropdownListValue ? MIN_OTHER_OPTIONS_NUM : MIN_OPTIONS_NUM;
	if (values.fieldType === ReportBlockFieldEnums.Type.DROPDOWN && (values.options?.length ?? 0) < minOptionsNum) {
		errors.fieldType = `Need at least ${minOptionsNum} options.`;
	}

	if (values.fieldType === ReportBlockFieldEnums.Type.DROPDOWN) {
		values.options?.forEach((_option, _index) => {
			if (!_option) {
				if (!errors.options) {
					errors.options = {};
				}
				errors.options[_index] = 'Option is required.';
			}
		});
	}

	if (ReportBlockFieldEnums.ImmutableFields[values.fieldType]
		&& values.fieldType === ReportBlockFieldEnums.Type.IMMUTABLE_IMAGE
		&& !values.defaultValue
	) {
		errors.defaultValue = 'Immutable image must contain default value';
	}

	if (
		ReportBlockFieldEnums.ImmutableFields[values.fieldType]
		&& values.fieldType === ReportBlockFieldEnums.Type.IMMUTABLE_TEXT
		&& !values.defaultValue
	) {
		errors.defaultValue = 'Descriptive text must not be empty';
	}

	if (values.fieldType === ReportBlockFieldEnums.Type.CALCULATED) {
		if (!values.operationType) {
			errors.operationType = 'Operation type is required';
		}

		if (values.operationType && (values.calculatedFieldOptions?.length ?? 0) < MinOperandCount[values.operationType]) {
			if (!errors.calculatedFieldOptions) {
				errors.calculatedFieldOptions = {};
			}
			errors.calculatedFieldOptions = `Need at least ${MinOperandCount[values.operationType]} operands for operation type ${OperationName[values.operationType]}.`;
		}

		if (values.unit && values.operationType && !CalculationsWithUnit[values.operationType]) {
			errors.operationType = `Operation type ${OperationName[values.operationType]} does not support units`;
		}

		const optionMap: { [key: string]: true; } = {};
		values.calculatedFieldOptions?.forEach((_option, _index) => {
			if (!_option.id) {
				if (!errors.calculatedFieldOptions) {
					errors.calculatedFieldOptions = {};
				}
				errors.calculatedFieldOptions[_index] = 'Operand is missing virtual id';
				return;
			}

			if (_option.type === OperandType.CONSTANT && !_option.constant) {
				if (!errors.calculatedFieldOptions) {
					errors.calculatedFieldOptions = {};
				}
				errors.calculatedFieldOptions[_index] = 'Option of type constant must have constant value';
				// If missing no need to continue
				return;
			}

			if (_option.type === OperandType.FIELD && _option.constant) {
				if (!errors.calculatedFieldOptions) {
					errors.calculatedFieldOptions = {};
				}
				errors.calculatedFieldOptions[_index] = 'Option of type field must not have constant value';
				// If missing no need to continue
				return;
			}

			if (_option.type === OperandType.CONSTANT) {
				return;
			}

			const _field = fieldsByIdMap[_option.id];
			if (!_field) {
				if (!errors.calculatedFieldOptions) {
					errors.calculatedFieldOptions = {};
				}
				errors.calculatedFieldOptions[_index] = 'Operand is missing from field map';
				return;
			}

			if (!_field?.name) {
				if (!errors.calculatedFieldOptions) {
					errors.calculatedFieldOptions = {};
				}
				errors.calculatedFieldOptions[_index] = 'Field name must not be empty';
			}
			if (_field.unit && values.operationType && CalculationsWithUnit[values.operationType] && _field.unit !== values.unit) {
				if (!errors.calculatedFieldOptions) {
					errors.calculatedFieldOptions = {};
				}
				errors.calculatedFieldOptions[_index] = 'Calculated field can only have one unit type';
			}
			if (!ReportBlockFieldEnums.CalculationFieldTypes[_field.fieldType]) {
				if (!errors.calculatedFieldOptions) {
					errors.calculatedFieldOptions = {};
				}
				errors.calculatedFieldOptions[_index] = 'Operand field must be of Important Field Type';
			}
			if (optionMap[_field.virtualId]) {
				if (!errors.calculatedFieldOptions) {
					errors.calculatedFieldOptions = {};
				}
				errors.calculatedFieldOptions[_index] = {};
				errors.calculatedFieldOptions[_index].name = 'Field is already used in calculation.';
			}
			optionMap[_field.virtualId] = true;
		});
	}

	if (values.fieldType !== ReportBlockFieldEnums.Type.CALCULATED) {
		if (!!values.operationType) {
			errors.operationType = 'Only Calculated Fields can have Operation Type';
		}

	}

	if (values.fieldType !== ReportBlockFieldEnums.Type.CALCULATED && values.fieldType !== ReportBlockFieldEnums.Type.DROPDOWN) {
		if (!!values.calculatedFieldOptions?.length) {
			errors.options = 'Only Calculated and Dropdown Fields can have options.';
		}
	}

	return errors;
}
