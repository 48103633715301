import * as React from 'react';

import * as TimeOption from 'acceligent-shared/utils/timeOption';

import LockedValue from 'af-components/LockedValue';

import type WorkOrderModalViewModel from 'ab-viewModels/scheduleBoardWorkOrderModal.viewModel';

import { renderWorkOrderInfoData } from '../';
import Changes from '../Changes';

interface Props {
	workOrderDataToShow: WorkOrderModalViewModel;
	currentWorkOrderData: WorkOrderModalViewModel;
}

class ShiftSection extends React.PureComponent<Props> {
	sectionHasChanges = () => {
		const { workOrderDataToShow, currentWorkOrderData } = this.props;

		return workOrderDataToShow?.shiftName?.toUpperCase() !== currentWorkOrderData?.shiftName?.toUpperCase()
			|| workOrderDataToShow?.timeToStart !== currentWorkOrderData?.timeToStart
			|| workOrderDataToShow?.timeToEnd !== currentWorkOrderData?.timeToEnd;
	};

	render() {
		const { workOrderDataToShow, currentWorkOrderData } = this.props;

		const sectionHasChanges = this.sectionHasChanges();

		return (
			<div className="work-order-info-modal__shift-container">
				{sectionHasChanges &&
					<Changes modifiedData={sectionHasChanges} />
				}
				<div className="work-order-info-modal__shift">
					<div>
						<LockedValue
							label="Shift"
							value={renderWorkOrderInfoData(workOrderDataToShow?.shiftName?.toUpperCase(), currentWorkOrderData?.shiftName?.toUpperCase())}
						/>
					</div>
					<div>
						<LockedValue
							label="Work Time Start"
							value={renderWorkOrderInfoData(
								TimeOption.format(workOrderDataToShow?.timeToStart),
								TimeOption.format(currentWorkOrderData?.timeToStart)
							)}
						/>
					</div>
					<div>
						<LockedValue
							label="Work Time End"
							value={renderWorkOrderInfoData(
								TimeOption.format(workOrderDataToShow?.timeToEnd),
								TimeOption.format(currentWorkOrderData?.timeToEnd)
							)}
						/>
					</div>
				</div>
			</div>
		);
	}
}

export default ShiftSection;
