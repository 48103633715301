import type { Dispatch, AnyAction } from 'redux';

import type { TimeSplitEntryRM } from 'ab-requestModels/timeSheet/timeSheetUpdate';

import type TimeSplitEntryVM from 'ab-viewModels/timeSplitEntry/timeSplitEntry';

import API from 'af-constants/routes/api';

import type { GetRootState } from 'af-reducers';

import { errorHandler } from 'af-utils/actions.util';
import { http } from 'af-utils/http.util';

export function findWorkOrderTimeSplitEntriesForAccount(workOrderId: number, accountId: number) {
	return async (dispatch: Dispatch<AnyAction>, getState: GetRootState, { redirectTo }) => {
		const action = async () => {
			return await http.get<TimeSplitEntryVM[]>(API.V1.TIME_SPLIT_ENTRY.FIND_WORK_ORDER_TIME_SPLIT_ENTRIES_FOR_ACCOUNT(workOrderId, accountId));
		};
		return await errorHandler(action, dispatch, redirectTo);
	};
}

export function updateWorkOrderTimeSplitEntriesForAccount(workOrderId: number, accountId: number, rm: TimeSplitEntryRM[]) {
	return async (dispatch: Dispatch<AnyAction>, getState: GetRootState, { redirectTo }) => {
		const action = async () => {
			return await http.post<void>(API.V1.TIME_SPLIT_ENTRY.UPDATE_WORK_ORDER_TIME_SPLIT_ENTRIES_FOR_ACCOUNT(workOrderId, accountId), rm);
		};
		return await errorHandler(action, dispatch, redirectTo);
	};
}
