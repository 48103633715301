import type QuantityUnitType from 'acceligent-shared/enums/quantityUnit';
import { isValidTextInput } from 'acceligent-shared/utils/text';

import type JobWorkSummaryVM from 'ab-viewModels/workRequest/jobWorkSummary.viewModel';

export class FormModel {
	/** Not really used but we need it for comparison when we send it back to BE */
	fieldReportId: Nullable<number>;
	workOrderId: Nullable<number>;
	date: string;
	work: Nullable<string>;
	type: Nullable<string>;
	definitionFields: string[];
	informationalFields: string[];
	quantity: Nullable<number>;
	unit: Nullable<string>;
	billingCodeId: Nullable<number>;
	billingCodeUnit: Nullable<QuantityUnitType>;
	customerId: Nullable<string>;
	group: Nullable<string>;
	unitPrice: Nullable<number>;
	description: Nullable<string>;
	revenue: Nullable<number>;
	comment: Nullable<string>;
	workRequestId: Nullable<number>;

	constructor(initial?: JobWorkSummaryVM) {
		this.fieldReportId = initial?.fieldReportId ?? null;
		this.workOrderId = initial?.workOrderId ?? null;
		this.date = initial?.startDate ?? '';
		this.work = initial?.work ?? null;
		this.type = !initial ? null : initial!.typeValue === initial!.type ? initial!.type : `${initial!.type}: ${initial!.typeValue}`;
		this.definitionFields = initial ? FormModel.resolveDefinitionFields(initial) : [];
		this.informationalFields = initial ? FormModel.resolveInformationalFields(initial) : [];
		this.quantity = initial?.quantity ?? null;
		this.unit = initial?.unit?.replace('/', '_') ?? null;
		this.billingCodeId = initial?.billingCodeId ?? null;
		this.billingCodeUnit = initial?.billingCode?.unit ?? null;
		this.customerId = initial?.customerId ?? null;
		this.group = initial?.group ?? null;
		this.unitPrice = initial?.unitPrice ?? null;
		this.description = initial?.description ?? null;
		this.revenue = initial?.revenue ?? null;
		this.comment = initial?.comment ?? null;
		this.workRequestId = initial?.workRequestId ?? null;
	}

	static validate = (form: FormModel) => {
		const errors: ValidationErrors = {};

		if (form.billingCodeId === null) {
			errors.billingCodeId = 'Billing Code is required.';
		}

		if (!isValidTextInput(form.work)) {
			errors.work = 'Work is required';
		}

		if (!isValidTextInput(form.type)) {
			errors.type = 'Type is required';
		}

		if (!form.quantity) {
			errors.quantity = 'Quantity is required.';
		}

		if (!form.unit) {
			errors.unit = 'Unit is required.';
		}

		if (form.definitionFields) {
			errors.definitionFields = [];
			for (let i = 0; i < form.definitionFields.length; i++) {
				if (!isValidTextInput(form.definitionFields[i])) {
					errors.definitionFields[i] = 'Definition field can\'t be empty.';
				}
			}

		}

		if (form.informationalFields) {
			errors.informationalFields = [];
			for (let i = 0; i < form.informationalFields.length; i++) {
				if (!isValidTextInput(form.informationalFields[i])) {
					errors.informationalFields[i] = 'Information field can\'t be empty.';
				}
			}
		}

		if (!form.date) {
			errors.date = 'Date is required.';
		}

		if (!form.workRequestId) {
			errors.workRequestId = 'Work Request ID is required';
		}
		return errors;
	};

	static resolveDefinitionFields = (_jws: JobWorkSummaryVM) => {
		const defFields: Nullable<string>[] = [_jws.definitionField1Value, _jws.definitionField2Value, _jws.definitionField3Value, _jws.definitionField4Value];

		return defFields.filter((_df) => !!_df) as string[];
	};

	static resolveInformationalFields = (_jws: JobWorkSummaryVM) => {
		const defFields: Nullable<string>[] =
			[_jws.informationField1Value, _jws.informationField2Value, _jws.informationField3Value, _jws.informationField4Value];

		return defFields.filter((_df) => !!_df) as string[];
	};
}
