import type { DeliverableAssignmentRequestModel } from 'ab-requestModels/deliverable.requestModel';

export const validate = (form: DeliverableAssignmentRequestModel) => {
	const errors: ValidationErrors = {};

	if (!form.statusId) {
		errors.statusId = 'Status is required';
	} else if (form.status.isJobStatus) {
		errors.statusId = 'Invalid Status selected.';
	}

	if (!form.employeeId) {
		errors.employeeId = 'Field Worker is required';
	}

	return errors;
};
