import * as React from 'react';
import type { History } from 'history';
import type { RowInfo } from 'react-table-6';

import type { TableContent } from 'ab-common/dataStructures/tableContent';

import Tooltip from 'af-components/Tooltip';
import type { Column } from 'af-components/Table6';
import EmptyCell from 'af-components/Table6/Cells/EmptyCell';
import LastUpdatedByCell from 'af-components/Table6/Cells/LastUpdatedByCell';
import StatusHistoryModal from 'af-components/DeliverableHistoryModal';
import DeliverableStatusLabel from 'af-components/DeliverableStatusLabel';
import DeliverableAssignmentHeader from 'af-components/DeliverableHistoryModal/DeliverableAssignmentHeader';
import type { TabProps } from 'af-components/Table6/IndentTable';
import IndentTable from 'af-components/Table6/IndentTable';

import type { DeliverableAssignmentTableViewModel } from 'ab-viewModels/deliverableTable.viewModel';

import TableNameEnum from 'ab-enums/tableName.enum';

import CLIENT from 'af-constants/routes/client';

interface OwnProps {
	companyName: string;
	orgAlias: string;
	history: History;
	data: TableContent<DeliverableAssignmentTableViewModel>;
	expandedDeliverableAssignmentId: number;
	clearExpandedState: () => void;
}

type Props = OwnProps;

interface State {
	showStatusHistoryModal: boolean;
	assignment: Nullable<DeliverableAssignmentTableViewModel>;
	expandedCleared: boolean;
}

class DeliverableGrandchildTable extends React.Component<Props, State> {
	state: State = {
		showStatusHistoryModal: false,
		assignment: {} as DeliverableAssignmentTableViewModel,
		expandedCleared: !this.props.expandedDeliverableAssignmentId,
	};

	columns: Column<DeliverableAssignmentTableViewModel>[] = [
		{
			Header: 'Status',
			accessor: 'status',
			sortable: false,
			Cell: ({ original }) => (
				original.status
					? (
						<DeliverableStatusLabel
							abbreviation={original.status.abbreviation}
							history={original.history}
							onClick={this.onStatusClick(original)}
							statusColor={original.status.statusColor}
							textColor={original.status.textColor}
						/>
					) : (
						<EmptyCell />
					)
			),
		},
		{
			Header: 'Updated',
			accessor: 'updatedAt',
			sortable: false,
			headerClassName: 'align-right',
			Cell: ({ original }) => <LastUpdatedByCell updatedAt={original.updatedAt} updatedBy={original.updatedBy} />,
		},
		{
			Header: 'Field Worker',
			sortable: false,
			accessor: 'employee',
			headerClassName: 'align-right',
			Cell: ({ original }) => <div className="align-right">{original.employee}</div> ?? <EmptyCell />,
		},
		{
			Header: 'Notes',
			accessor: 'notes',
			sortable: false,
			headerClassName: 'align-right',
			Cell: ({ original }) => (
				<div className="align-right">
					{original.notes
						? (
							<Tooltip message={original.notes}>
								<span className="icon-display_view notes-icon" />
							</Tooltip>
						)
						: <EmptyCell />
					}
				</div>
			),
		},
	];

	static getRowClassModifiers() {
		return ['blue'];
	}

	tab = (): TabProps<DeliverableAssignmentTableViewModel> => {
		return {
			columns: this.columns,
			fetch: this.fetch,
			getRowClassModifiers: DeliverableGrandchildTable.getRowClassModifiers,
			getHeaderClassModifiers: DeliverableGrandchildTable.getRowClassModifiers,
			onRowClick: this.onRowClick,
			rowActions: [
				{
					label: 'Edit',
					action: this.edit,
					shouldRefresh: false,
				},
			],
		};
	};

	onRowClick = (rowInfo: RowInfo) => {
		this.edit(rowInfo.original);
	};

	edit = (original: DeliverableAssignmentTableViewModel) => {
		const { orgAlias, companyName, history } = this.props;
		history.push(CLIENT.COMPANY.DELIVERABLE.ASSIGNMENT.EDIT(
			orgAlias,
			companyName,
			original.deliverableId.toString(),
			original.deliverableSubmissionId.toString(),
			original.id.toString()
		));
	};

	fetch = async () => this.props.data;

	onStatusClick = (original: DeliverableAssignmentTableViewModel) => {
		return (event: React.MouseEvent) => {
			event.stopPropagation();
			event.preventDefault();
			this.setState(() => ({ showStatusHistoryModal: true, assignment: original }));
		};
	};

	closeModal = () => {
		this.setState(() => ({ showStatusHistoryModal: false, assignment: null }));
	};

	isRowHighlighted = (row: DeliverableAssignmentTableViewModel): boolean => {
		const { expandedDeliverableAssignmentId } = this.props;
		const { expandedCleared } = this.state;
		return !expandedCleared && row.id === expandedDeliverableAssignmentId;
	};

	clearExpandedState = () => {
		const { clearExpandedState } = this.props;
		this.setState(() => ({ expandedCleared: true }));
		clearExpandedState();
	};

	render() {
		const { data } = this.props;
		const { showStatusHistoryModal, assignment } = this.state;
		return (
			<>
				<IndentTable
					getRowHighlighted={this.isRowHighlighted}
					indent={2}
					isLoaded={true}
					onScrollIntoNode={this.clearExpandedState}
					tab={this.tab()}
					table={data}
					tableName={TableNameEnum.DELIVERABLE_GRAND_CHILD_TABLE}
				/>
				{(showStatusHistoryModal && assignment) &&
					<StatusHistoryModal
						closeModal={this.closeModal}
						history={assignment.history}
						showModal={showStatusHistoryModal}
						statusInfo={
							<DeliverableAssignmentHeader
								assigneeFullName={assignment.employee}
								notes={assignment.notes}
								workOrderCode={assignment.workOrderCode}
							/>
						}
					/>
				}
			</>
		);
	}
}

export default DeliverableGrandchildTable;
