import { Button } from 'react-bootstrap';
import * as React from 'react';
import { compose } from 'redux';
import type { ConnectedProps } from 'react-redux';
import { connect } from 'react-redux';
import type { FormErrors, FormErrorsWithArray, InjectedFormProps } from 'redux-form';
import { reduxForm, getFormValues, formValueSelector, getFormSubmitErrors, getFormSyncErrors } from 'redux-form';
import { Outlet, useLocation, useNavigate, useParams } from 'react-router-dom-v5-compat';
import type { CustomLocationState } from 'react-router-dom';

import WorkRequestStatus from 'acceligent-shared/enums/workRequestStatus';
import TimeFormatEnum from 'acceligent-shared/enums/timeFormat';

import * as TimeUtils from 'acceligent-shared/utils/time';
import { isValidTextInput } from 'acceligent-shared/utils/text';

import { EMAIL_REGEX } from 'acceligent-shared/constants/regex';

import * as AttachmentActions from 'af-actions/attachment';
import * as JobActions from 'af-actions/jobs';
import * as WorkRequestActions from 'af-actions/workRequests';

import type { RootState } from 'af-reducers';

import * as FORMS from 'af-constants/reduxForms';
import CLIENT from 'af-constants/routes/client';

import { isAllowed } from 'ab-utils/auth.util';

import PagePermissions from 'ab-enums/pagePermissions.enum';

import { useToggle } from 'af-utils/react.util';

import Breadcrumbs from 'af-components/Breadcrumbs';
import ConfirmationModal from 'af-components/ConfirmationModal';
import SubmitButton from 'af-components/SubmitButton';
import TabNavigation from 'af-components/TabNavigation';
import type { SubjobFM } from 'af-components/SharedForms/Job/formModel';
import JobUpsertFM from 'af-components/SharedForms/Job/formModel';

import CopyModal from '../../Shared/CopyModal';

import { TABS } from '../helpers';
import type { FormJobOutletContext } from '../types';
import styles from '../styles.module.scss';
import { JobEditTabRoutes } from './types';

interface LocationState extends CustomLocationState {
	subjob?: SubjobFM;
	previousFormState?: JobUpsertFM;
}

type FormProps = InjectedFormProps<JobUpsertFM, FormOwnProps>;

type FormOwnProps = ConnectedProps<typeof connector>;
type Props = FormOwnProps & FormProps;

const _isInvalidBillingCode = (billingCodeError) => {
	return Object.keys(billingCodeError).length > 0;
};

const EditJob: React.FC<Props> = (props) => {

	const {
		companyName,
		handleSubmit,
		submitting,
		editJob,
		getJobForm,
		initialize,
		change,
		jobHazardAssessmentStatus,
		isProject,
		subjobs,
		projectId,
		currentFormValues,
		deletePendingAttachments,
		uploadedAttachmentIds,
		formJobCode,
		title,
		customerCompanyName,
		priority,
		office,
		jobCode,
		syncErrors,
		billToEmail,
		jobCodeSubmitError,
		findAllAvailableSubjobs,
		initialized,
		hasPermissionsToManageBillingCodes,
		billingCodesSubmitErrors,
	} = props;

	const { state } = useLocation();
	const { defaultDueDate = new Date(), redirectUrl, orgAlias, originUrl, previousFormState, subjob } = state as LocationState;
	const params = useParams();
	const navigate = useNavigate();
	const { jobId, '*': tabId } = params;

	const dueDate = React.useMemo(() =>
		props.dueDate ?? TimeUtils.formatDate(defaultDueDate, TimeFormatEnum.DB_DATE_ONLY, TimeFormatEnum.JS_TIME),
		[defaultDueDate, props.dueDate]);

	const activeTabId = React.useMemo(() => {
		switch (tabId) {
			case JobEditTabRoutes.DETAILS:
				return TABS[0].id;
			case JobEditTabRoutes.JOB_HAZARD_ASSESSMENT:
				return TABS[1].id;
			case JobEditTabRoutes.BILLING_CODES:
				return TABS[2].id;
			case JobEditTabRoutes.ATTACHMENTS:
				return TABS[3].id;
			default:
				return TABS[0].id;
		}
	}, [tabId]);
	const cameFromPreviewTab = React.useRef<string | undefined>(undefined);

	const [showCopyModal, setShowCopyModal] = React.useState(false);
	const [showSubmitConfirmationModal, setShowSubmitConfirmationModal] = React.useState(false);
	const [job, setJob] = React.useState<Nullable<JobUpsertFM>>();
	const [availableSubjobs, setAvailableSubjobs] = React.useState<SubjobFM[]>([]);
	const [selectedSubjob, setSelectedSubjob] = React.useState<Nullable<SubjobFM>>(null);
	const [subjobSearchText, setSubjobSearchText] = React.useState<string>('');

	const {
		value: showTurnToProjectConfirmationModal,
		setToTrue: openTurnToProjectConfirmationModal,
		setToFalse: hideTurnToProjectConfirmationModal,
	} = useToggle(false);

	const {
		value: showAddSubjobConfirmationModal,
		setToTrue: openAddSubjobConfirmationModal,
		setToFalse: hideAddSubjobConfirmationModal,
	} = useToggle(false);

	const {
		value: showSubmitWithSubjobsConfirmationModal,
		setToTrue: openSubmitWithSubjobsConfirmationModal,
		setToFalse: hideSubmitWithSubjobsConfirmationModal,
	} = useToggle(false);

	const {
		value: hasAddedSujobs,
		setToTrue: setAddedSujobs,
	} = useToggle(false);

	const {
		value: turnToProjectDisabled,
		setToTrue: disableTurnToProject,
	} = useToggle(isProject);

	const uploadedAttachmentIdsToDeleteRef = React.useRef<Nullable<number[]>>(null);

	const fetchAndInitializeData = React.useCallback(async () => {
		if (initialized) {
			return;
		}
		cameFromPreviewTab.current = tabId;
		const fetchedJob = await getJobForm(+jobId!);
		if (!fetchedJob) {
			throw new Error('Job not found');
		}

		setJob(fetchedJob);
		if (fetchedJob.status === WorkRequestStatus.FINISHED) {
			navigate(originUrl || CLIENT.COMPANY.JOBS.TABLE(orgAlias, companyName));
		} else {
			initialize(fetchedJob);
		}

		if (previousFormState) {
			// Directly handle the special case for 'subjobs'
			if (previousFormState.hasOwnProperty('subjobs')) {
				const updatedSubjobs = subjob ? [...(previousFormState.subjobs ?? []), subjob] : previousFormState.subjobs;
				change('subjobs', updatedSubjobs);
			}

			// Iterate over and update the rest of the form state
			Object.entries(previousFormState).forEach(([key, value]) => {
				if (key !== 'subjobs') { // Skip 'subjobs' since it's already handled
					change(key, value);
				}
			});
		}
		// eslint-disable-next-line react-hooks/exhaustive-deps
	}, [companyName, getJobForm, orgAlias, originUrl, initialized]);

	React.useEffect(() => {
		fetchAndInitializeData();
	}, [fetchAndInitializeData]);

	const clearPendingAttachments = React.useCallback(async () => {
		if (uploadedAttachmentIdsToDeleteRef.current?.length) {
			await deletePendingAttachments({ attachmentIds: uploadedAttachmentIdsToDeleteRef.current });
		}
	}, [deletePendingAttachments]);

	React.useEffect(() => {
		return () => {
			clearPendingAttachments();
		};
	}, [clearPendingAttachments]);

	React.useEffect(() => {
		uploadedAttachmentIdsToDeleteRef.current = uploadedAttachmentIds ?? null;
	}, [uploadedAttachmentIds]);

	const submit = React.useCallback(async (form: JobUpsertFM) => {
		if (!job) {
			return;
		}

		const changedFields: Partial<JobUpsertFM> = {};
		for (const _key of Object.keys(job)) {
			if (_key === 'isProject') {
				changedFields[_key] = form[_key];
				continue;
			}
			if (job[_key] !== form[_key]) {
				changedFields[_key] = form[_key];
				if (_key === 'workRequestAttachments') {
					changedFields.directories = form.directories;
					changedFields.uploadedAttachmentIds = form.uploadedAttachmentIds;
				}
			}
		}
		const rm = JobUpsertFM.toRM(changedFields);

		await editJob(rm, +jobId!);
		navigate(originUrl || CLIENT.COMPANY.JOBS.PREVIEW(orgAlias, companyName, jobId));
	}, [companyName, editJob, job, jobId, navigate, orgAlias, originUrl]);

	const closeTurnToProjectConfirmationModal = React.useCallback(async () => {
		change('isProject', false);
		hideTurnToProjectConfirmationModal();
	}, [change, hideTurnToProjectConfirmationModal]);

	const closeAddSubjobConfirmationModal = React.useCallback(async () => {
		hideAddSubjobConfirmationModal();
	}, [hideAddSubjobConfirmationModal]);

	const addSubjob = React.useCallback(() => {
		setAddedSujobs();
		if (selectedSubjob) {
			change('subjobs', [...(subjobs ?? []), selectedSubjob]);
			setAvailableSubjobs([...availableSubjobs.filter((_sj) => _sj.id !== selectedSubjob.id)]);
			setSelectedSubjob(null);
		}
	}, [availableSubjobs, change, selectedSubjob, setAddedSujobs, subjobs]);

	const turnToProject = React.useCallback(() => {
		change('isProject', true);
	}, [change]);

	const goToJobForm = React.useCallback(() => {
		// redirects user to job form. when user fills the form and save it, he/she will be
		// redirected back to original job form with new subjob and all other changes
		navigate(CLIENT.COMPANY.JOBS.CREATE(orgAlias, companyName), {
			state: {
				redirectUrl: CLIENT.COMPANY.JOBS.EDIT(orgAlias, companyName, jobId),
				defaultDueDate: TimeUtils.parseDate(dueDate),
				orgAlias,
				jobCode: subjobSearchText,
				previousFormState: currentFormValues,
			},
		});
	}, [companyName, currentFormValues, dueDate, jobId, navigate, orgAlias, subjobSearchText]);

	const handleSubjobSelect = React.useCallback((selectedOption: Nullable<SubjobFM>) => {
		setSelectedSubjob(selectedOption);
	}, []);

	const onTurnToProjectChange = React.useCallback((value: boolean) => {
		if (value) {
			openTurnToProjectConfirmationModal();
		}
	}, [openTurnToProjectConfirmationModal]);

	const onEditJob = React.useCallback(async (form: JobUpsertFM) => {
		if (!job) {
			return;
		}

		if (!form?.isInternal && job.isInternal) {
			setShowSubmitConfirmationModal(true);
		} else {
			await submit(form);
		}
	}, [job, submit]);

	const beforeEditJob = React.useCallback(async (form: JobUpsertFM) => {
		if (!hasAddedSujobs) {
			await onEditJob(form);
			return;
		} else {
			openSubmitWithSubjobsConfirmationModal();
		}
	}, [hasAddedSujobs, onEditJob, openSubmitWithSubjobsConfirmationModal]);

	const onBack = React.useCallback(() => {
		const goToPreviewTab = cameFromPreviewTab.current
			? CLIENT.COMPANY.JOBS.PREVIEW_TAB(orgAlias, companyName, jobId, cameFromPreviewTab.current)
			: CLIENT.COMPANY.JOBS.PREVIEW(orgAlias, companyName, jobId);
		navigate(originUrl || goToPreviewTab);
	}, [companyName, jobId, navigate, orgAlias, originUrl]);

	const onTabSelect = React.useCallback((newTabId: number) => {
		let tabName = '';
		switch (newTabId) {
			case TABS[0].id:
				tabName = JobEditTabRoutes.DETAILS;
				break;
			case TABS[1].id:
				tabName = JobEditTabRoutes.JOB_HAZARD_ASSESSMENT;
				break;
			case TABS[2].id:
				tabName = JobEditTabRoutes.BILLING_CODES;
				break;
			case TABS[3].id:
				tabName = JobEditTabRoutes.ATTACHMENTS;
				break;
			default:
				tabName = JobEditTabRoutes.DETAILS;
				break;
		}
		navigate(CLIENT.COMPANY.JOBS.EDIT_TAB(orgAlias, companyName, jobId, tabName));
	}, [companyName, jobId, navigate, orgAlias]);

	const openCopyJobModal = React.useCallback(() => {
		setShowCopyModal(true);
	}, []);

	const closeCopyJobModal = React.useCallback(() => {
		setShowCopyModal(false);
	}, []);

	const closeConfirmationModal = React.useCallback(() => {
		setShowSubmitConfirmationModal(false);
	}, []);

	const onBackToListButtonClick = React.useCallback(() => {
		navigate(CLIENT.COMPANY.JOBS.TABLE(orgAlias, companyName));
	}, [companyName, navigate, orgAlias]);

	const isBillToEmailValid = billToEmail ? EMAIL_REGEX.test(billToEmail) : true;

	const areJobsFieldsValid = React.useMemo(() => {
		return (
			!!formJobCode
			&& isBillToEmailValid
			&& !!title
			&& !!customerCompanyName
			&& !!priority
			&& !!office
			&& !jobCodeSubmitError
		);
	}, [formJobCode, isBillToEmailValid, title, customerCompanyName, priority, office, jobCodeSubmitError]);

	const tabsAdjustedForPermissions = React.useMemo(() => {
		const adjustedTabs: typeof TABS = [];
		for (const _tab of TABS) {
			if (!hasPermissionsToManageBillingCodes && _tab.id === TABS[2].id) {
				continue;
			}
			adjustedTabs.push(_tab);
		}
		return adjustedTabs;
	}, [hasPermissionsToManageBillingCodes]);

	const areBillingCodesValid = React.useMemo(() => !syncErrors?.billingCodes?.some(_isInvalidBillingCode), [syncErrors]);

	const renderTabLabel = React.useCallback((id: number) => {
		const areRequiredHazardAssessmentFieldsFilled = !!jobHazardAssessmentStatus;
		switch (id) {
			case TABS[0].id: {
				return (
					<div className={styles['job-form__tab']}>
						{!areJobsFieldsValid && <span className="icon-warning" />}
						Job
					</div>
				) as JSX.Element;
			}
			case TABS[1].id: {
				return (
					<div className={styles['job-form__tab']}>
						{!areRequiredHazardAssessmentFieldsFilled && <span className="icon-warning" />}
						Job Hazard Assessment
					</div>
				) as JSX.Element;
			}

			case TABS[2].id: {
				return (
					<div className={styles['job-form__tab']}>
						{!areBillingCodesValid && <span className="icon-warning" />}
						Billing Codes
					</div>
				) as JSX.Element;
			}
			case TABS[3].id: {
				return (
					<div className={styles['job-form__tab']}>
						Attachments
					</div>
				) as JSX.Element;
			}

			default: {
				return <></>;
			}
		}
	}, [jobHazardAssessmentStatus, areJobsFieldsValid, areBillingCodesValid]);

	React.useEffect(() => {
		if (isProject && !turnToProjectDisabled) {
			disableTurnToProject();
		}
	}, [disableTurnToProject, isProject, turnToProjectDisabled]);

	const fetchAvailableSubjobs = React.useCallback(async () => {
		const _subjobs = await findAllAvailableSubjobs(+jobId!);
		const subjobIds: number[] = subjobs?.map((_s) => _s.id) ?? [];
		const jobIds = [...subjobIds, jobId];
		setAvailableSubjobs(_subjobs.filter((_sj) => !jobIds.includes(_sj.id)));
	}, [findAllAvailableSubjobs, jobId, subjobs]);

	React.useEffect(() => {
		if (initialized) {
			fetchAvailableSubjobs();
		}
	}, [fetchAvailableSubjobs, initialized]);

	const isFormValid = React.useMemo(() => (
		isValidTextInput(formJobCode)
		&& isValidTextInput(title)
		&& isValidTextInput(customerCompanyName)
		&& !!priority
		&& !!office
		&& !!jobHazardAssessmentStatus
		&& isBillToEmailValid
		&& !syncErrors?.billingCodes?.some(_isInvalidBillingCode)
	), [formJobCode, title, customerCompanyName, priority, office, jobHazardAssessmentStatus, isBillToEmailValid, syncErrors?.billingCodes]);

	const context = React.useMemo<FormJobOutletContext>(() => ({
		availableSubjobs,
		change,
		disableTurnToProject,
		formName: FORMS.JOB_EDIT,
		goToJobForm,
		handleSubjobSelect,
		isProject,
		onTurnToProjectChange,
		openAddSubjobConfirmationModal,
		orgAlias,
		projectId,
		redirectOnSubmit: !!redirectUrl,
		selectedSubjob,
		setSubjobSearchText,
		subjobs,
		subjobSearchText,
		turnToProjectDisabled,
		workRequestId: jobId ? +jobId : undefined,
		initialized,
		billingCodesSyncErrors: syncErrors?.billingCodes ?? [],
		billingCodesSubmitErrors: billingCodesSubmitErrors ?? [],
	}), [
		availableSubjobs,
		change,
		disableTurnToProject,
		goToJobForm,
		handleSubjobSelect,
		isProject,
		jobId,
		onTurnToProjectChange,
		openAddSubjobConfirmationModal,
		orgAlias,
		projectId,
		redirectUrl,
		selectedSubjob,
		subjobSearchText,
		subjobs,
		turnToProjectDisabled,
		initialized,
		syncErrors.billingCodes,
		billingCodesSubmitErrors,
	]);

	if (!job) {
		return (
			<>
				<Breadcrumbs
					items={[
						{ label: 'Jobs', url: CLIENT.COMPANY.JOBS.TABLE(orgAlias, companyName) },
						{ label: 'Loading' },
						{ label: 'Edit' },
					]}
				/>
				<Button className={styles['job-form__back-to-list']} onClick={onBackToListButtonClick} variant="info">
					<span className="icon-left" />
					Back to List
				</Button>
			</>
		);
	}

	return (
		<>
			<Breadcrumbs
				items={[
					{ label: 'Jobs', url: CLIENT.COMPANY.JOBS.TABLE(orgAlias, companyName) },
					{ label: job.jobCode },
					{ label: 'Edit' },
				]}
			/>
			<Button className={styles['job-form__back-to-list']} onClick={onBackToListButtonClick} variant="info">
				<span className="icon-left" />
				Back to List
			</Button>
			<div className={styles['job-form__submit-section']}>
				<div className={styles['job-form__submit-section__hint']} />
				<Button onClick={onBack} variant="info">
					Cancel
				</Button>
				<Button onClick={openCopyJobModal} variant="info">
					Copy
				</Button>
				<SubmitButton
					disabled={!isFormValid}
					label="Save"
					onClick={handleSubmit(beforeEditJob)}
					reduxFormSubmitting={submitting}
				/>
			</div>
			<TabNavigation
				active={activeTabId}
				navigationClassName={styles['job-form__tabs-navigation']}
				onClick={onTabSelect}
				renderLabel={renderTabLabel}
				tabs={tabsAdjustedForPermissions}
			/>
			<Outlet context={context} />
			<CopyModal
				close={closeCopyJobModal}
				jobToCopyCode={job?.jobCode ?? ''}
				jobToCopyId={+jobId!}
				showModal={showCopyModal}
			/>
			<ConfirmationModal
				body="Changing this Job to External will make all associated Work Orders External and will be included in statistics."
				closeModal={closeConfirmationModal}
				confirmAction={handleSubmit(submit)}
				confirmText="Submit"
				modalStyle="danger"
				showModal={showSubmitConfirmationModal}
				title={'Submit Job?'}
			/>
			<ConfirmationModal
				body="Convert this Job to Project. This action is irreversible. Continue?"
				closeModal={closeTurnToProjectConfirmationModal}
				confirmAction={turnToProject}
				confirmText="Continue"
				modalStyle="grey-warning"
				showModal={showTurnToProjectConfirmationModal}
				title={'Convert Job to Project?'}
			/>
			<ConfirmationModal
				body={`Associate ${selectedSubjob?.jobCode} with ${jobCode}. Continue?`}
				closeModal={closeAddSubjobConfirmationModal}
				confirmAction={addSubjob}
				confirmText="Continue"
				modalStyle="grey-warning"
				showModal={showAddSubjobConfirmationModal}
				title={`Associate ${selectedSubjob?.jobCode} with ${jobCode}?`}
			/>
			<ConfirmationModal
				body={'You have associated Sub-jobs with this Project. After submiting the form Sub-jobs cannot be detached from the Project. Submit?'}
				closeModal={hideSubmitWithSubjobsConfirmationModal}
				confirmAction={handleSubmit(onEditJob)}
				confirmText="Submit"
				modalStyle="grey-warning"
				showModal={showSubmitWithSubjobsConfirmationModal}
				title={'Submit project with Sub-jobs?'}
			/>
		</>
	);
};

function mapStateToProps(state: RootState) {
	const { user: { companyData, userData } } = state;
	const formState = getFormValues(FORMS.JOB_EDIT);
	const formSelector = formValueSelector(FORMS.JOB_EDIT);
	const currentFormValues = getFormValues(FORMS.JOB_EDIT)(state) as JobUpsertFM;

	if (!userData || !companyData) {
		throw new Error('User not logged in');
	}

	const {
		jobHazardAssessmentStatus,
		isProject,
		subjobs,
		id: workRequestId,
		projectId,
		uploadedAttachmentIds,
		jobCode,
		title,
		customerCompanyName,
		priority,
		office,
		billToEmail,
	} = formState(state) as JobUpsertFM ?? ({} as Partial<JobUpsertFM>);

	const getSubmitErrors = getFormSubmitErrors(FORMS.JOB_EDIT);

	const { jobCode: jobCodeSubmitError, billingCodes: billingCodesSubmitErrors } = getSubmitErrors(state) as FormErrors<JobUpsertFM, string>;
	const syncErrors = getFormSyncErrors(FORMS.JOB_EDIT)(state) as FormErrorsWithArray<JobUpsertFM, string>;

	/** DB_DATE_ONLY "YYYY-MM-DD" */
	const dueDate: string = formSelector(state, 'startDate');

	const { isCompanyAdmin, permissions } = companyData;
	const { role } = userData;

	const hasPermissionsToManageBillingCodes = isAllowed(
		PagePermissions.COMPANY.JOBS.MANAGE_BILLING_CODES,
		permissions,
		isCompanyAdmin,
		role
	);

	return {
		companyName: companyData.name,
		jobHazardAssessmentStatus,
		formJobCode: jobCode,
		title,
		customerCompanyName,
		priority,
		office,
		isProject,
		subjobs,
		workRequestId,
		projectId,
		dueDate,
		currentFormValues,
		uploadedAttachmentIds,
		jobCode,
		billToEmail,
		jobCodeSubmitError,
		hasPermissionsToManageBillingCodes,
		syncErrors,
		billingCodesSubmitErrors: billingCodesSubmitErrors as unknown as FormErrorsWithArray<JobUpsertFM['billingCodes'][0], string>[],
	};
}

function mapDispatchToProps() {
	return {
		getJobForm: JobActions.getJobForm,
		editJob: JobActions.editJob,
		findAllAvailableSubjobs: WorkRequestActions.findAllAvailableSubjobs,
		deletePendingAttachments: AttachmentActions.deletePendingAttachments,
	};
}

const connector = connect(mapStateToProps, mapDispatchToProps());

const enhance = compose<React.ComponentClass>(
	reduxForm<JobUpsertFM, FormOwnProps>({
		form: FORMS.JOB_EDIT,
		validate: JobUpsertFM.validate,
		warn: JobUpsertFM.warn,
		shouldError: () => true, // show errors even when switching tabs
	}),
	connector
);

export default enhance(EditJob);
