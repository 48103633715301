import * as React from 'react';

import type JobReportListVM from 'ab-viewModels/report/jobReportList.viewModel';

import JobReport from './JobReport';

import styles from './styles.module.scss';

interface OwnProps {
	items: JobReportListVM[];
	onClearAll: () => void;
	onSelectAll: () => void;
	onValueChange: (id: number, value: boolean) => void;
	selectJobsMap: { [id: number]: boolean; };
	filterExists: boolean;
}

type Props = OwnProps;

const JobReportList: React.FC<Props> = (props) => {
	const { items, selectJobsMap, onValueChange, onSelectAll, onClearAll, filterExists } = props;

	const [selectedCount, setSelectedCount] = React.useState(0);

	React.useEffect(() => {
		const _selectedCount = Object.values(selectJobsMap).filter((_value) => _value).length;
		setSelectedCount(_selectedCount);
	}, [selectJobsMap]);

	const renderItem = React.useCallback((item: JobReportListVM, index: number) => {
		return (
			<JobReport
				isChecked={selectJobsMap[item.workRequestId] === undefined ? false : selectJobsMap[item.workRequestId]}
				item={item}
				key={index}
				onValueChange={onValueChange}
			/>
		);

	}, [onValueChange, selectJobsMap]);

	return (
		<div className={styles['job-report-list__container']}>
			<div className={styles['job-report-list__selected']}>{selectedCount} item(s) selected</div>
			<div className={styles['job-report-list__action-container']}>
				<div className={styles['job-report-list__action']} onClick={onSelectAll}>{filterExists ? 'Select Filtered' : 'Select All'}</div>
				<div className={styles['job-report-list__action']} onClick={onClearAll}>{filterExists ? 'Clear Filtered' : 'Clear All'}</div>
			</div>
			<div className={styles['job-report-list__list-container']}>
				{items.map(renderItem)}
			</div>
		</div>
	);
};

export default React.memo(JobReportList);
