import * as React from 'react';
import { Droppable } from 'react-beautiful-dnd';

import ScheduleBoardProperty from 'ab-enums/scheduleBoardProperty.enum';

import EquipmentDraggable from 'af-root/scenes/Company/ScheduleBoard/Shared/EquipmentDraggable';

interface Props {
	droppableId: string;
	draggablePrefix: string; // to distinguish if it's board or toolbar dragged equipment
	equipmentIds: number[];
	isToolbar: boolean;
	isDisabled: boolean;
	className: string;
	startIndex: number;
	endIndex: number;
	title?: string;
	dueDate: string;
	isWorkOrderCanceled?: boolean;
	isCopyPlaceholder?: boolean;
	isCardDisabled?: boolean;
	hasReason?: boolean;
	isDragAndDropDisabled: boolean;
	hasPermissionsToEditScheduleBoard: boolean;
}

const DroppableComponent: React.FC<Props> = (props) => {
	const {
		title,
		droppableId,
		startIndex,
		endIndex,
		isDisabled,
		equipmentIds: _equipmentIds,
		className,
		isCopyPlaceholder,
		isDragAndDropDisabled,
		hasPermissionsToEditScheduleBoard,
		draggablePrefix,
		isToolbar,
		dueDate,
		isCardDisabled,
		isWorkOrderCanceled,
		hasReason,
	} = props;

	const renderEquipment = React.useCallback((equipmentIds: number[], _startIndex: number) => {
		return equipmentIds.map((_id: number, _index: number) => {
			return (
				<EquipmentDraggable
					draggableId={`${draggablePrefix}#${ScheduleBoardProperty.EQUIPMENT}#${dueDate}#${_id}`}
					droppableId={droppableId}
					dueDate={dueDate}
					equipmentId={_id}
					hasPermissionsToEditScheduleBoard={hasPermissionsToEditScheduleBoard}
					hasReason={hasReason}
					index={_startIndex + _index}
					isCardDisabled={isCardDisabled}
					isCopyPlaceholder={isCopyPlaceholder}
					isDragAndDropDisabled={isDragAndDropDisabled}
					isToolbar={isToolbar}
					isWOLocked={false}
					isWorkOrderCanceled={isWorkOrderCanceled}
					key={`${draggablePrefix}#${ScheduleBoardProperty.EQUIPMENT}#${dueDate}#${_id}`}
				/>
			);
		});
	}, [
		draggablePrefix,
		droppableId,
		dueDate,
		hasPermissionsToEditScheduleBoard,
		hasReason,
		isCardDisabled,
		isCopyPlaceholder,
		isDragAndDropDisabled,
		isToolbar,
		isWorkOrderCanceled,
	]);

	const equipmentIds = _equipmentIds.slice(startIndex, endIndex);

	if (isCopyPlaceholder || isDragAndDropDisabled || !hasPermissionsToEditScheduleBoard) {
		return (
			<div className={className}>
				{title && <label className="droppable-title">{title}</label>}
				{renderEquipment(equipmentIds, startIndex)}
			</div>
		);
	}

	return (
		<Droppable
			droppableId={droppableId}
			isDropDisabled={isDisabled}
		>
			{(provided, snapshot) => {
				return (
					<div
						className={`${className} ${snapshot.isDraggingOver ? 'drag-over' : ''}`}
						ref={provided.innerRef}
					>
						{title && <label className="droppable-title">{title}</label>}
						{renderEquipment(equipmentIds, startIndex)}
						{provided.placeholder}
					</div>
				);
			}}
		</Droppable>
	);
};

export default React.memo(DroppableComponent);
