import * as React from 'react';
import { useOutletContext } from 'react-router-dom-v5-compat';

import DirectoriesAttachments from './DirectoriesAttachments';
import styles from '../styles.module.scss';

import { formSectionClass, formSectionClassColumn8 } from '../helpers';
import type { WorkRequestFormOutletContext } from '../types';

const AttachmentsFormTab: React.FC = () => {
	const { workRequestId, change } = useOutletContext<WorkRequestFormOutletContext>();
	return (
		<div className={styles['work-request-form__tab-content']}>
			<div className={formSectionClass}>
				<div className={formSectionClassColumn8}>
					{workRequestId
						? (
							<DirectoriesAttachments change={change} jobId={+workRequestId} />
						)
						: 'In order to attach files, work request has to be saved first.'
					}
				</div>
			</div>
		</div>
	);
};

export default React.memo(AttachmentsFormTab);
