import * as React from 'react';
import { compose } from 'redux';
import type { CustomRouteComponentProps } from 'react-router-dom';
import type { ConnectedProps } from 'react-redux';
import { connect } from 'react-redux';

import TimeFormatEnum from 'acceligent-shared/enums/timeFormat';

import * as TimeUtils from 'acceligent-shared/utils/time';

import type { LogViewModel } from 'ab-viewModels/log.viewModel';

import type { RootState } from 'af-reducers';

import * as LogActions from 'af-actions/log';
import * as CompanyActions from 'af-actions/companies';

import { useToggle } from 'af-utils/react.util';

import type { TabProps, Column, ButtonData, RowInfo } from 'af-components/Table6';
import Table from 'af-components/Table6';

import CLIENT from 'af-constants/routes/client';
import TableNameEnum from 'ab-enums/tableName.enum';
import TableButtonType from 'ab-enums/tableButtonType.enum';

import DateCell from 'af-components/Table6/Cells/DateCell';
import EmptyCell from 'af-components/Table6/Cells/EmptyCell';
import ValueChangeCell from 'af-components/Table6/Cells/ValueChangeCell';
import Breadcrumbs from 'af-components/Breadcrumbs';

import MultipleLogsDatePickerModule from './MultipleLogsDatePickerModule';

type OwnProps = CustomRouteComponentProps;

type Props = ConnectedProps<typeof connector> & OwnProps;

const breadcrumbs = [{ label: 'Logs' }];

const columns: Column<LogViewModel>[] = [
	{
		Header: 'Timestamp',
		accessor: 'timestamp',
		Cell: ({ original }) => (
			<DateCell
				date={original.timestamp}
				format={TimeFormatEnum.DATE_ONLY}
				isLeftAligned={true}
				withTime={true}
			/>
		),
	},
	{
		Header: 'Full Name',
		accessor: 'userName',
		Cell: ({ original }) => original.userName || <EmptyCell />,
	},
	{
		Header: 'Section',
		accessor: 'section',
		Cell: ({ original }) => {
			return (
				<div className="cell-multirow">
					<div>{original.section}</div>
					<div className="cell-additional-info">{original.action}</div>
				</div>
			);
		},
	},
	{
		Header: 'Description',
		accessor: 'description',
		Cell: ({ original }) => original.description ? <span className="cell-description">{original.description}</span> : <EmptyCell />,
	},
	{
		Header: 'Field',
		accessor: 'field',
		Cell: ({ original }) => {
			return (
				original.field || original.newValue || original.oldValue
					? (
						<ValueChangeCell
							label={original.field}
							newValue={original.newValue}
							oldValue={original.oldValue}
						/>
					)
					: <EmptyCell message="No change" />
			);
		},
	},
];

const Logs: React.FC<Props> = (props) => {
	const {
		download,
		fetch,
		location: { state: { orgAlias } },
		companyName,
		history,
		companyCreatedAt,
		getCompany,
		findLastBucketEntry,
	} = props;

	const {
		value: datePickerModalIsOpen,
		setToTrue: openDatePickerModal,
		setToFalse: closeDatePickerModal,
	} = useToggle(false);

	const [
		lastLogDate,
		setLastLogDate,
	] = React.useState<Nullable<Date>>(null);

	const onRowClick = React.useCallback(({ original }: RowInfo<LogViewModel>) => {
		if (original?.id) {
			history.push(CLIENT.COMPANY.SETTINGS.LOGS.PREVIEW(orgAlias, companyName, original.id.toString()));
		}
	}, [companyName, history, orgAlias]);

	const submit = React.useCallback((startDate: Date, endDate: Date) => {
		const fileName = `${orgAlias}.${companyName.replace(' ', '_')}.logs.zip`;

		download(fileName, startDate, endDate);
	}, [companyName, download, orgAlias]);

	const tabs: TabProps<LogViewModel>[] = React.useMemo(() => {

		const buttons: ButtonData[] = [
			{
				type: TableButtonType.EXPORT,
				hasPermission: true,
				onClick: async () => openDatePickerModal(),
			},
		];

		return [
			{
				label: 'Company Logs',
				columns: columns,
				selectable: false,
				hasSearchInput: true,
				searchLabel: 'Logs',
				buttons,
				fetch,
				onRowClick: onRowClick,
			},
		];
	}, [fetch, onRowClick, openDatePickerModal]);

	React.useEffect(() => {
		let isMounted = true;
		getCompany();

		const fetchLastLogDate = async () => {
			const lastEntry = await findLastBucketEntry();
			if (isMounted && lastEntry) {
				setLastLogDate(TimeUtils.parseDate(lastEntry.createdAt, TimeFormatEnum.DB_DATE_ONLY));
			}
		};

		fetchLastLogDate();

		return () => {
			isMounted = false;
		};
	}, [findLastBucketEntry, getCompany]);

	const parsedCompanyCreationDate = React.useMemo(
		() => companyCreatedAt ? TimeUtils.parseDate(companyCreatedAt, TimeFormatEnum.DB_DATE_ONLY) : null,
		[companyCreatedAt]
	);

	return (
		<div className="form-segment form-segment--maxi">
			<Breadcrumbs items={breadcrumbs} />
			<Table
				exportAsZip={true}
				tableName={TableNameEnum.LOG}
				tabs={tabs}
			/>
			<MultipleLogsDatePickerModule
				closeModal={closeDatePickerModal}
				companyCreationDate={parsedCompanyCreationDate}
				companyName={companyName}
				isOpen={datePickerModalIsOpen}
				lastLogDate={lastLogDate}
				onSubmit={submit}
			/>
		</div>
	);
};

function mapStateToProps(state: RootState) {
	const { company: { company }, user: { companyData } } = state;
	if (!companyData) {
		throw new Error('User not logged in');
	}

	return {
		companyName: companyData.name,
		companyCreatedAt: company?.createdAt,
	};
}

function mapDispatchToProps() {
	return {
		download: LogActions.downloadForCompany,
		fetch: LogActions.findAllForCompanyTable,
		findLastBucketEntry: LogActions.findLastBucketEntry,
		getCompany: CompanyActions.getCompany,
	};
}

const connector = connect(mapStateToProps, mapDispatchToProps());

const enhance = compose<React.ComponentType<OwnProps>>(
	connector,
	React.memo
);

export default enhance(Logs);
