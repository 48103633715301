/* eslint-disable react/no-find-dom-node */
import * as React from 'react';
import { Field } from 'redux-form';
import * as ReactDOM from 'react-dom';

import Input from 'af-fields/Input';

import type { AddressFieldRM } from 'ab-requestModels/fieldReport/fieldReport.requestModel';

import { useToggle } from 'af-utils/react.util';

import AddressModal from '../../../Modals/AddressModal';

import fieldHoc from '../Shared/fieldHoc';

import Preview from './Preview';

interface OwnProps {
	disabled: boolean;
	formName: string;
	isPreview: boolean;
	name: string;
	tooltipMessage?: React.ComponentProps<typeof Input>['tooltipMessage'];
	onFocus?: () => void;
	onValueChange: (value: AddressFieldRM) => void;
	change: (fieldName: string, value: AddressFieldRM | number) => void;
}

type Props = OwnProps;

const PIN_ICON = <span className="icon-location_pin field-report-block__field__address" />;

const AddressField: React.FC<Props> = (props) => {
	const {
		disabled,
		formName,
		name,
		tooltipMessage,
		onValueChange,
		onFocus,
		change,
	} = props;

	const {
		value: showModal,
		setToTrue: openModal,
		setToFalse: closeModal,
	} = useToggle(false);

	const input = React.useRef(null);

	React.useEffect(() => {
		if (disabled && showModal) {
			closeModal();
		}
	}, [disabled, showModal, closeModal]);

	const onFocusCallback = React.useCallback(() => {
		onFocus?.();
		const _input = (ReactDOM.findDOMNode(input.current) as Element).getElementsByTagName('input')[0];
		_input.blur();
		openModal();
	}, [onFocus, openModal]);

	const onChange = React.useCallback((address: AddressFieldRM) => {
		change(formName, address);
		if (formName.includes('dumpAddressId')) {
			change(formName.replace('dumpAddressId', 'dumpLatitude'), address.latitude);
			change(formName.replace('dumpAddressId', 'dumpLongitude'), address.longitude);
		}
		onValueChange(address);
	}, [formName, onValueChange, change]);

	return (
		<>
			<Field
				addonAfter={PIN_ICON}
				component={Input}
				disabled={disabled}
				label={name}
				name={`${formName}.street`}
				onFocus={onFocusCallback}
				placeholder={name}
				ref={input}
				tooltipMessage={tooltipMessage}
				type="text"
			/>
			<AddressModal
				closeModal={closeModal}
				fieldName={formName}
				onValueChange={onChange}
				showModal={showModal && !disabled}
			/>
		</>
	);
};

export default fieldHoc(React.memo(AddressField), Preview);
