import type AutoNotifyOption from 'acceligent-shared/enums/autoNotifyOption';
import { AutoNotifyOptionLabel } from 'acceligent-shared/enums/autoNotifyOption';
import { TimeOptionValues } from 'acceligent-shared/enums/timeOption';
import type NotificationPriority from 'acceligent-shared/enums/notificationPriority';

import CompanyFeatureFlagsEnum, { CompanyFeatureFlagsTips } from 'ab-enums/companyFeatureFlags.enum';
import { NotificationPriorityOptions } from 'ab-enums/notificationPriority.enum';

interface UserGroupVM {
	id: number;
	name: string;
}

class CompanyFeatureFlagsVM {
	isEnabled: boolean;
	isEnabledAutoNotificationsOnWorkDays: boolean;
	notifyOnPublish: AutoNotifyOption;
	notificationPriority: NotificationPriority;
	notifyOnCancel: AutoNotifyOption;
	notifyUnassignedEmployees: boolean;
	enableAutoNotifyOnPublish: boolean;
	enableAutoNotifyOnCancel: boolean;
	notifyOnPause: boolean;
	notifyOnResume: boolean;
	notifyScheduleBoardEmail: string;
	enableScheduleBoardEmail: boolean;
	enablePastWorkOrdersNotification: boolean;
	notifyOnMonday: string;
	notifyOnTuesday: string;
	notifyOnWednesday: string;
	notifyOnThursday: string;
	notifyOnFriday: string;
	notifyOnSaturday: string;
	notifyOnSunday: string;
	notifyMultipleAssignments: boolean;
	notifySupervisors: boolean;
	scheduleBoardEmailUserGroup: Nullable<UserGroupVM>;
	pastWorkOrdersNotificationUserGroup: Nullable<UserGroupVM>;
	enableItemLowQuantityNotification: boolean;
	itemLowQuantityNotificationUserGroup: Nullable<UserGroupVM>;
	enableDailyItemLowQuantityNotification: boolean;
	dailyItemLowQuantityNotificationUserGroup: Nullable<UserGroupVM>;
	notifyYesterdaysBoardAt: string;
	enableNotifyYesterdaysBoard: boolean;
	notifyYesterdaysBoardUserGroup: Nullable<UserGroupVM>;
	notifyFailedAt: string;
	enableNotifyFailed: boolean;
	notifyFailedUserGroup: Nullable<UserGroupVM>;
	enableNotifyQAQC: boolean;
	enableNotifyDeliverableAssignee: boolean;
	enableAutoNotifyDailyTips: boolean;
	notifyOnReportReject: boolean;
	notifyOnTimeSheetReject: boolean;
	notifyTemporaryLabor: boolean;
	enableUnfinishedPurchaseOrderCreatedNotification: boolean;
	enablePurchaseOrderWithUnreceivedItemsNotification: boolean;
	notifyInvoiceRemindersAt: string;
	notifyInvoiceRemindersFrom: string;
	notifyInvoiceRemindersUserGroup: Nullable<UserGroupVM>;
	enableInvoiceRemindersNotification: boolean;
	isSendingInvoiceNotificationsEnabled: boolean;
	enableToolRepairNotification: boolean;
	toolRepairNotificationUserGroup: Nullable<UserGroupVM>;
	notifyOnOrderCreation: boolean;
	notifyOnOrderCreationUserGroup: Nullable<UserGroupVM>;
	notifyForUnfinishedOrders: boolean;
	notifyForUnfinishedOrdersUserGroup: Nullable<UserGroupVM>;
	notifyForOrdersTotalExpenseDaily: boolean;
	notifyForOrdersTotalExpenseDailyUserGroup: Nullable<UserGroupVM>;
	notifyForOrdersTotalExpenseWeekly: boolean;
	notifyForOrdersTotalExpenseWeeklyUserGroup: Nullable<UserGroupVM>;
	notifyForOrdersTotalExpenseMonthly: boolean;
	notifyForOrdersTotalExpenseMonthlyUserGroup: Nullable<UserGroupVM>;
	notifyForDuplicateOrdersMonthly: boolean;
	notifyForDuplicateOrdersMonthlyUserGroup: Nullable<UserGroupVM>;
	notifyForInexpensiveOrdersWeekly: boolean;
	notifyForInexpensiveOrdersWeeklyUserGroup: Nullable<UserGroupVM>;
	notifyForInexpensiveOrdersMonthly: boolean;
	notifyForInexpensiveOrdersMonthlyUserGroup: Nullable<UserGroupVM>;
	notifyForExpensiveOrdersWeekly: boolean;
	notifyForExpensiveOrdersWeeklyUserGroup: Nullable<UserGroupVM>;
	notifyForExpensiveOrdersMonthly: boolean;
	notifyForExpensiveOrdersMonthlyUserGroup: Nullable<UserGroupVM>;
}

export class CompanyFeatureFlagsEntry {
	key: string;
	label: string;
	checked?: boolean;
	showStatusIcon?: boolean;
	indent?: boolean;
	value?: Nullable<string>;
	hint?: string;

	constructor(key: string, label: string, checked: boolean, value: Nullable<string>, indent: boolean = false, showCheckbox: boolean = true, hint?: string) {
		this.key = key;
		this.label = label;
		this.checked = checked;
		this.showStatusIcon = showCheckbox;
		this.indent = indent;
		this.value = value;
		this.hint = hint;
	}

	static enableNotificationsEntry(flags: CompanyFeatureFlagsVM) {
		const { isEnabled } = flags;
		return new CompanyFeatureFlagsEntry(
			'isEnabled',
			CompanyFeatureFlagsEnum.enableNotifications,
			isEnabled,
			null
		);
	}

	static enableAutomaticNotificationsOnWorkDaysEntry(flags: CompanyFeatureFlagsVM) {
		const { isEnabledAutoNotificationsOnWorkDays } = flags;
		return new CompanyFeatureFlagsEntry(
			'isEnabledAutoNotificationsOnWorkDays',
			CompanyFeatureFlagsEnum.enableAutomaticNotificationsOnWorkDays,
			isEnabledAutoNotificationsOnWorkDays,
			null,
			false,
			true,
			CompanyFeatureFlagsTips.enableAutomaticNotificationsOnWorkDays
		);
	}

	static autoNotifyAtEntry(day: number, autoNotifyAt: string, label: string) {
		return new CompanyFeatureFlagsEntry(
			`autoNotifyOnDay-${day}`,
			label,
			true,
			TimeOptionValues[autoNotifyAt],
			true,
			false
		);
	}

	static autoNotifyOnDaysEntries(flags: CompanyFeatureFlagsVM) {
		const {
			notifyOnMonday: autoNotifyOnMonday,
			notifyOnTuesday: autoNotifyOnTuesday,
			notifyOnWednesday: autoNotifyOnWednesday,
			notifyOnThursday: autoNotifyOnThursday,
			notifyOnFriday: autoNotifyOnFriday,
			notifyOnSaturday: autoNotifyOnSaturday,
			notifyOnSunday: autoNotifyOnSunday,
			isEnabled,
		} = flags;

		if (!isEnabled) {
			return [];
		}

		return [
			this.autoNotifyAtEntry(1, autoNotifyOnMonday, CompanyFeatureFlagsEnum.notifyOnMonday),
			this.autoNotifyAtEntry(2, autoNotifyOnTuesday, CompanyFeatureFlagsEnum.notifyOnTuesday),
			this.autoNotifyAtEntry(3, autoNotifyOnWednesday, CompanyFeatureFlagsEnum.notifyOnWednesday),
			this.autoNotifyAtEntry(4, autoNotifyOnThursday, CompanyFeatureFlagsEnum.notifyOnThursday),
			this.autoNotifyAtEntry(5, autoNotifyOnFriday, CompanyFeatureFlagsEnum.notifyOnFriday),
			this.autoNotifyAtEntry(6, autoNotifyOnSaturday, CompanyFeatureFlagsEnum.notifyOnSaturday),
			this.autoNotifyAtEntry(0, autoNotifyOnSunday, CompanyFeatureFlagsEnum.notifyOnSunday),
		];
	}

	static autoNotifyOnPublishEntry(flags: CompanyFeatureFlagsVM) {
		const { isEnabled, enableAutoNotifyOnPublish, notifyOnPublish } = flags;

		if (!isEnabled) {
			return null;
		}

		return new CompanyFeatureFlagsEntry(
			'notifyOnPublish',
			CompanyFeatureFlagsEnum.notifyOnPublish,
			enableAutoNotifyOnPublish,
			`at: ${AutoNotifyOptionLabel[notifyOnPublish]}`,
			false,
			true,
			CompanyFeatureFlagsTips.notifyOnPublish
		);
	}

	static autoNotifyOnCancelEntry(flags: CompanyFeatureFlagsVM) {
		const { isEnabled, notifyOnCancel, enableAutoNotifyOnCancel } = flags;

		if (!isEnabled) {
			return null;
		}

		return new CompanyFeatureFlagsEntry(
			'notifyOnCancel',
			CompanyFeatureFlagsEnum.notifyOnCancel,
			enableAutoNotifyOnCancel,
			`at: ${AutoNotifyOptionLabel[notifyOnCancel]}`,
			false,
			true,
			CompanyFeatureFlagsTips.notifyOnCancel
		);
	}

	static autoNotifyOnPauseEntry(flags: CompanyFeatureFlagsVM) {
		const { isEnabled, notifyOnPause } = flags;

		if (!isEnabled) {
			return null;
		}

		return new CompanyFeatureFlagsEntry(
			'notifyOnPause',
			CompanyFeatureFlagsEnum.notifyOnPause,
			isEnabled && !!notifyOnPause,
			'at: Immediate',
			false,
			true,
			CompanyFeatureFlagsTips.notifyOnPause
		);
	}

	static autoNotifyOnResumeEntry(flags: CompanyFeatureFlagsVM) {
		const { isEnabled, notifyOnResume } = flags;

		if (!isEnabled) {
			return null;
		}

		return new CompanyFeatureFlagsEntry(
			'notifyOnResume',
			CompanyFeatureFlagsEnum.notifyOnResume,
			isEnabled && !!notifyOnResume,
			'at: Immediate',
			false,
			true,
			CompanyFeatureFlagsTips.notifyOnResume
		);
	}

	static autoNotifyOnReportReject(flags: CompanyFeatureFlagsVM) {
		const { isEnabled, notifyOnReportReject } = flags;

		if (!isEnabled) {
			return null;
		}

		return new CompanyFeatureFlagsEntry(
			'notifyOnReportReject',
			CompanyFeatureFlagsEnum.notifyOnReportReject,
			isEnabled && !!notifyOnReportReject,
			'at: Immediate',
			false,
			true,
			CompanyFeatureFlagsTips.notifyOnReportReject
		);
	}

	static autoNotifyOnTimeSheetReject(flags: CompanyFeatureFlagsVM) {
		const { isEnabled, notifyOnTimeSheetReject } = flags;

		if (!isEnabled) {
			return null;
		}

		return new CompanyFeatureFlagsEntry(
			'notifyOnResume',
			CompanyFeatureFlagsEnum.notifyOnTimeSheetReject,
			isEnabled && !!notifyOnTimeSheetReject,
			'at: Immediate',
			false,
			true,
			CompanyFeatureFlagsTips.notifyOnTimeSheetReject
		);
	}

	static autoNotifyScheduleBoardEmail(flags: CompanyFeatureFlagsVM) {
		const { notifyScheduleBoardEmail, isEnabled, scheduleBoardEmailUserGroup, enableScheduleBoardEmail } = flags;

		if (!isEnabled) {
			return null;
		}

		return new CompanyFeatureFlagsEntry(
			'notifyScheduleBoardEmail',
			CompanyFeatureFlagsEnum.notifyScheduleBoardEmail,
			enableScheduleBoardEmail,
			`at: ${TimeOptionValues[notifyScheduleBoardEmail]}, to: ${scheduleBoardEmailUserGroup?.name ?? '-'}`,
			false,
			true,
			CompanyFeatureFlagsTips.notifyScheduleBoardEmail
		);
	}

	static autoNotifyUnfinishedPurchaseOrderCreated(flags: CompanyFeatureFlagsVM) {
		const {
			isEnabled,
			enableUnfinishedPurchaseOrderCreatedNotification,
		} = flags;

		if (!isEnabled) {
			return null;
		}

		return new CompanyFeatureFlagsEntry(
			'unfinishedPurchaseOrderCreatedNotification',
			CompanyFeatureFlagsEnum.unfinishedPurchaseOrderCreatedNotification,
			enableUnfinishedPurchaseOrderCreatedNotification,
			'at: Default Time',
			false,
			true,
			CompanyFeatureFlagsTips.unfinishedPurchaseOrderCreatedNotification
		);
	}

	static autoNotifyPurchaseOrderWithUnreceivedItems(flags: CompanyFeatureFlagsVM) {
		const {
			isEnabled,
			enablePurchaseOrderWithUnreceivedItemsNotification,
		} = flags;

		if (!isEnabled) {
			return null;
		}

		return new CompanyFeatureFlagsEntry(
			'purchaseOrderWithUnreceivedItems',
			CompanyFeatureFlagsEnum.purchaseOrderWithUnreceivedItems,
			enablePurchaseOrderWithUnreceivedItemsNotification,
			'at: Default Time',
			false,
			true,
			CompanyFeatureFlagsTips.purchaseOrderWithUnreceivedItems
		);
	}

	static autoNotifyInvoiceReminderNotification(flags: CompanyFeatureFlagsVM) {
		const {
			isEnabled,
			enableInvoiceRemindersNotification,
			notifyInvoiceRemindersAt,
			notifyInvoiceRemindersFrom,
			notifyInvoiceRemindersUserGroup,
		} = flags;

		if (!isEnabled) {
			return null;
		}

		return new CompanyFeatureFlagsEntry(
			'invoiceRemindersNotification',
			CompanyFeatureFlagsEnum.invoiceRemindersNotification,
			enableInvoiceRemindersNotification,
			`at: ${TimeOptionValues[notifyInvoiceRemindersAt]}, from: ${notifyInvoiceRemindersFrom}, to: ${notifyInvoiceRemindersUserGroup?.name ?? '-'}`,
			false,
			true,
			CompanyFeatureFlagsTips.invoiceRemindersNotification
		);
	}

	static autoNotifyFailedNotifications(flags: CompanyFeatureFlagsVM) {
		const { notifyFailedAt, isEnabled, notifyFailedUserGroup, enableNotifyFailed } = flags;

		if (!isEnabled) {
			return null;
		}

		return new CompanyFeatureFlagsEntry(
			'notifyFailedAt',
			CompanyFeatureFlagsEnum.notifyFailedNotifications,
			enableNotifyFailed,
			`at: ${TimeOptionValues[notifyFailedAt]}, to: ${notifyFailedUserGroup?.name ?? '-'}`,
			false,
			true,
			CompanyFeatureFlagsTips.notifyFailedNotifications
		);
	}

	static autoNotifyYesterdaysScheduleBoardEmail(flags: CompanyFeatureFlagsVM) {
		const { notifyYesterdaysBoardAt, isEnabled, notifyYesterdaysBoardUserGroup, enableNotifyYesterdaysBoard } = flags;

		if (!isEnabled) {
			return null;
		}

		return new CompanyFeatureFlagsEntry(
			'notifyYesterdaysBoardAt',
			CompanyFeatureFlagsEnum.notifyYesterdaysScheduleBoardEmail,
			enableNotifyYesterdaysBoard,
			`at: ${TimeOptionValues[notifyYesterdaysBoardAt]}, to: ${notifyYesterdaysBoardUserGroup?.name ?? '-'}`,
			false,
			true,
			CompanyFeatureFlagsTips.notifyYesterdaysScheduleBoardEmail
		);
	}

	static notifyPastWorkOrdersEmail(flags: CompanyFeatureFlagsVM) {
		const { enablePastWorkOrdersNotification, pastWorkOrdersNotificationUserGroup } = flags;
		return new CompanyFeatureFlagsEntry(
			'enablePastWorkOrdersNotificationUserGroup',
			CompanyFeatureFlagsEnum.enablePastWorkOrdersNotification,
			enablePastWorkOrdersNotification,
			`at: On Change, to: ${pastWorkOrdersNotificationUserGroup?.name ?? '-'}`,
			false,
			true,
			CompanyFeatureFlagsTips.enablePastWorkOrdersNotification
		);
	}

	static notifyOnItemDepartmentLowQuantity(flags: CompanyFeatureFlagsVM) {
		const { enableItemLowQuantityNotification, itemLowQuantityNotificationUserGroup } = flags;

		return new CompanyFeatureFlagsEntry(
			'enableItemDepartmentLowQuantityNotification',
			CompanyFeatureFlagsEnum.enableItemLowQuantityNotification,
			enableItemLowQuantityNotification,
			`at: On Change, to: ${itemLowQuantityNotificationUserGroup?.name ?? '-'}`,
			false,
			true,
			CompanyFeatureFlagsTips.enableItemLowQuantityNotification
		);
	}

	static autoNotifyOnItemDepartmentLowQuantity(flags: CompanyFeatureFlagsVM) {
		const { enableDailyItemLowQuantityNotification, dailyItemLowQuantityNotificationUserGroup } = flags;

		return new CompanyFeatureFlagsEntry(
			'enableDailyItemLowQuantityNotification',
			CompanyFeatureFlagsEnum.enableDailyItemLowQuantityNotification,
			enableDailyItemLowQuantityNotification,
			`at: Default Time, to: ${dailyItemLowQuantityNotificationUserGroup?.name ?? '-'}`,
			false,
			true,
			CompanyFeatureFlagsTips.enableDailyItemLowQuantityNotification
		);
	}

	static autoNotifyOnToolRepairModify(flags: CompanyFeatureFlagsVM) {
		const { enableToolRepairNotification, toolRepairNotificationUserGroup } = flags;

		return new CompanyFeatureFlagsEntry(
			'enableToolRepairNotification',
			CompanyFeatureFlagsEnum.enableToolRepairNotification,
			enableToolRepairNotification,
			`at: On Change, to: ${toolRepairNotificationUserGroup?.name ?? '-'}`,
			false,
			true,
			CompanyFeatureFlagsTips.enableToolRepairNotification
		);
	}

	static autoNotifyUnassignedEmployeesEntry(flags: CompanyFeatureFlagsVM) {
		const { isEnabled, notifyUnassignedEmployees } = flags;

		if (!isEnabled) {
			return null;
		}

		return new CompanyFeatureFlagsEntry(
			'notifyUnassignedEmployees',
			CompanyFeatureFlagsEnum.notifyUnassignedEmployees,
			isEnabled && !!notifyUnassignedEmployees,
			'at: Default Time',
			false,
			true,
			CompanyFeatureFlagsTips.notifyUnassignedEmployees
		);
	}

	static autoNotifyEmployeesWithMultipleAssignments(flags: CompanyFeatureFlagsVM) {
		const { isEnabled, notifyMultipleAssignments } = flags;

		if (!isEnabled) {
			return null;
		}

		return new CompanyFeatureFlagsEntry(
			'notifyMultipleAssignments',
			CompanyFeatureFlagsEnum.notifyMultipleAssignments,
			isEnabled && !!notifyMultipleAssignments,
			'at: Default Time',
			false,
			true,
			CompanyFeatureFlagsTips.notifyMultipleAssignments
		);
	}

	static autoNotifySupervisorEntry(flags: CompanyFeatureFlagsVM) {
		const { notifySupervisors, isEnabled } = flags;

		if (!isEnabled) {
			return null;
		}

		return new CompanyFeatureFlagsEntry(
			'notifySupervisors',
			CompanyFeatureFlagsEnum.notifySupervisors,
			isEnabled && notifySupervisors,
			'at: 30 min before Default Time',
			false,
			true,
			CompanyFeatureFlagsTips.notifySupervisors
		);
	}

	static notificationPriority(flags: CompanyFeatureFlagsVM) {
		const { isEnabled, notificationPriority } = flags;

		if (!isEnabled) {
			return null;
		}
		const notificationPriorityLabel = NotificationPriorityOptions.find(({ id }) => id === notificationPriority)?.name ?? null;

		return new CompanyFeatureFlagsEntry(
			'notificationPriority',
			'Notification priority: ',
			isEnabled,
			notificationPriorityLabel,
			false,
			false
		);
	}

	static autoNotifyQAQC(flags: CompanyFeatureFlagsVM) {
		const { enableNotifyQAQC, isEnabled } = flags;

		if (!isEnabled) {
			return null;
		}

		return new CompanyFeatureFlagsEntry(
			'enableNotifyQAQC',
			CompanyFeatureFlagsEnum.notifyQAQC,
			isEnabled && enableNotifyQAQC,
			'at: Default Time',
			false,
			true,
			CompanyFeatureFlagsTips.notifyQAQC
		);
	}

	static autoNotifyDeliverableAssignee(flags: CompanyFeatureFlagsVM) {
		const { enableNotifyDeliverableAssignee, isEnabled } = flags;

		if (!isEnabled) {
			return null;
		}

		return new CompanyFeatureFlagsEntry(
			'enableNotifyDeliverableAssignee',
			CompanyFeatureFlagsEnum.notifyDeliverableAssignee,
			isEnabled && enableNotifyDeliverableAssignee,
			'at: Immediate',
			false,
			true,
			CompanyFeatureFlagsTips.notifyDeliverableAssignee
		);
	}

	static notifyTemporaryLabor(flags: CompanyFeatureFlagsVM) {
		const { isEnabled, notifyTemporaryLabor } = flags;

		if (!isEnabled) {
			return null;
		}

		return new CompanyFeatureFlagsEntry(
			'notifyTemporaryLabor',
			CompanyFeatureFlagsEnum.notifyTemporaryLabor,
			isEnabled && notifyTemporaryLabor,
			null,
			false,
			true,
			CompanyFeatureFlagsTips.notifyTemporaryLabor
		);
	}

	static notifyOnOrderCreation(flags: CompanyFeatureFlagsVM) {
		const { isEnabled, notifyOnOrderCreation, notifyOnOrderCreationUserGroup } = flags;

		if (!isEnabled) {
			return null;
		}

		return new CompanyFeatureFlagsEntry(
			'notifyOnOrderCreation',
			CompanyFeatureFlagsEnum.notifyOnOrderCreation,
			notifyOnOrderCreation,
			`to: ${notifyOnOrderCreationUserGroup?.name ?? '-'}`,
			false,
			true,
			CompanyFeatureFlagsTips.notifyOnOrderCreation
		);
	}

	static notifyForUnfinishedOrders(flags: CompanyFeatureFlagsVM) {
		const { isEnabled, notifyForUnfinishedOrders, notifyForUnfinishedOrdersUserGroup } = flags;

		if (!isEnabled) {
			return null;
		}

		return new CompanyFeatureFlagsEntry(
			'notifyForUnfinishedOrders',
			CompanyFeatureFlagsEnum.notifyForUnfinishedOrders,
			notifyForUnfinishedOrders,
			`to: ${notifyForUnfinishedOrdersUserGroup?.name ?? '-'}`,
			false,
			true,
			CompanyFeatureFlagsTips.notifyForUnfinishedOrders
		);
	}

	static notifyForOrdersTotalExpenseDaily(flags: CompanyFeatureFlagsVM) {
		const { isEnabled, notifyForOrdersTotalExpenseDaily, notifyForOrdersTotalExpenseDailyUserGroup } = flags;

		if (!isEnabled) {
			return null;
		}

		return new CompanyFeatureFlagsEntry(
			'notifyForOrdersTotalExpenseDaily',
			CompanyFeatureFlagsEnum.notifyForOrdersTotalExpenseDaily,
			notifyForOrdersTotalExpenseDaily,
			`to: ${notifyForOrdersTotalExpenseDailyUserGroup?.name ?? '-'}`,
			false,
			true,
			CompanyFeatureFlagsTips.notifyForOrdersTotalExpenseDaily
		);
	}

	static notifyForOrdersTotalExpenseWeekly(flags: CompanyFeatureFlagsVM) {
		const { isEnabled, notifyForOrdersTotalExpenseWeekly, notifyForOrdersTotalExpenseWeeklyUserGroup } = flags;

		if (!isEnabled) {
			return null;
		}

		return new CompanyFeatureFlagsEntry(
			'notifyForOrdersTotalExpenseWeekly',
			CompanyFeatureFlagsEnum.notifyForOrdersTotalExpenseWeekly,
			notifyForOrdersTotalExpenseWeekly,
			`to: ${notifyForOrdersTotalExpenseWeeklyUserGroup?.name ?? '-'}`,
			false,
			true,
			CompanyFeatureFlagsTips.notifyForOrdersTotalExpenseWeekly
		);
	}

	static notifyForOrdersTotalExpenseMonthly(flags: CompanyFeatureFlagsVM) {
		const { isEnabled, notifyForOrdersTotalExpenseMonthly, notifyForOrdersTotalExpenseMonthlyUserGroup } = flags;

		if (!isEnabled) {
			return null;
		}

		return new CompanyFeatureFlagsEntry(
			'notifyForOrdersTotalExpenseMonthly',
			CompanyFeatureFlagsEnum.notifyForOrdersTotalExpenseMonthly,
			notifyForOrdersTotalExpenseMonthly,
			`to: ${notifyForOrdersTotalExpenseMonthlyUserGroup?.name ?? '-'}`,
			false,
			true,
			CompanyFeatureFlagsTips.notifyForOrdersTotalExpenseMonthly
		);
	}

	static notifyForDuplicateOrdersMonthly(flags: CompanyFeatureFlagsVM) {
		const { isEnabled, notifyForDuplicateOrdersMonthly, notifyForDuplicateOrdersMonthlyUserGroup } = flags;

		if (!isEnabled) {
			return null;
		}

		return new CompanyFeatureFlagsEntry(
			'notifyForDuplicateOrdersMonthly',
			CompanyFeatureFlagsEnum.notifyForDuplicateOrdersMonthly,
			notifyForDuplicateOrdersMonthly,
			`to: ${notifyForDuplicateOrdersMonthlyUserGroup?.name ?? '-'}`,
			false,
			true,
			CompanyFeatureFlagsTips.notifyForDuplicateOrdersMonthly
		);
	}

	static notifyForInexpensiveOrdersWeekly(flags: CompanyFeatureFlagsVM) {
		const { isEnabled, notifyForInexpensiveOrdersWeekly, notifyForInexpensiveOrdersWeeklyUserGroup } = flags;

		if (!isEnabled) {
			return null;
		}

		return new CompanyFeatureFlagsEntry(
			'notifyForInexpensiveOrdersWeekly',
			CompanyFeatureFlagsEnum.notifyForInexpensiveOrdersWeekly,
			notifyForInexpensiveOrdersWeekly,
			`to: ${notifyForInexpensiveOrdersWeeklyUserGroup?.name ?? '-'}`,
			false,
			true,
			CompanyFeatureFlagsTips.notifyForInexpensiveOrdersWeekly
		);
	}

	static notifyForInexpensiveOrdersMonthly(flags: CompanyFeatureFlagsVM) {
		const { isEnabled, notifyForInexpensiveOrdersMonthly, notifyForInexpensiveOrdersMonthlyUserGroup } = flags;

		if (!isEnabled) {
			return null;
		}

		return new CompanyFeatureFlagsEntry(
			'notifyForInexpensiveOrdersMonthly',
			CompanyFeatureFlagsEnum.notifyForInexpensiveOrdersMonthly,
			notifyForInexpensiveOrdersMonthly,
			`to: ${notifyForInexpensiveOrdersMonthlyUserGroup?.name ?? '-'}`,
			false,
			true,
			CompanyFeatureFlagsTips.notifyForInexpensiveOrdersMonthly
		);
	}

	static notifyForExpensiveOrdersWeekly(flags: CompanyFeatureFlagsVM) {
		const { isEnabled, notifyForExpensiveOrdersWeekly, notifyForExpensiveOrdersWeeklyUserGroup } = flags;

		if (!isEnabled) {
			return null;
		}

		return new CompanyFeatureFlagsEntry(
			'notifyForExpensiveOrdersWeekly',
			CompanyFeatureFlagsEnum.notifyForExpensiveOrdersWeekly,
			notifyForExpensiveOrdersWeekly,
			`to: ${notifyForExpensiveOrdersWeeklyUserGroup?.name ?? '-'}`,
			false,
			true,
			CompanyFeatureFlagsTips.notifyForExpensiveOrdersWeekly
		);
	}

	static notifyForExpensiveOrdersMonthly(flags: CompanyFeatureFlagsVM) {
		const { isEnabled, notifyForExpensiveOrdersMonthly, notifyForExpensiveOrdersMonthlyUserGroup } = flags;

		if (!isEnabled) {
			return null;
		}

		return new CompanyFeatureFlagsEntry(
			'notifyForExpensiveOrdersMonthly',
			CompanyFeatureFlagsEnum.notifyForExpensiveOrdersMonthly,
			notifyForExpensiveOrdersMonthly,
			`to: ${notifyForExpensiveOrdersMonthlyUserGroup?.name ?? '-'}`,
			false,
			true,
			CompanyFeatureFlagsTips.notifyForExpensiveOrdersMonthly
		);
	}
}

export class CompanyFeatureFlagEntries {
	general: (CompanyFeatureFlagsEntry | null)[];
	defaultSendTimes: (CompanyFeatureFlagsEntry | null)[];
	specialEmails: (CompanyFeatureFlagsEntry | null)[];
	workOrderNotifications: (CompanyFeatureFlagsEntry | null)[];
	temporaryLaborNotifications: (CompanyFeatureFlagsEntry | null)[];
	areNotificationsOn: boolean; // Redundant but useful

	constructor(flags: CompanyFeatureFlagsVM) {
		this.general = [
			CompanyFeatureFlagsEntry.enableNotificationsEntry(flags),
			CompanyFeatureFlagsEntry.enableAutomaticNotificationsOnWorkDaysEntry(flags),
			CompanyFeatureFlagsEntry.notificationPriority(flags),
		];

		this.defaultSendTimes = [
			...CompanyFeatureFlagsEntry.autoNotifyOnDaysEntries(flags),
		];

		this.specialEmails = [
			CompanyFeatureFlagsEntry.autoNotifyScheduleBoardEmail(flags),
			CompanyFeatureFlagsEntry.autoNotifyYesterdaysScheduleBoardEmail(flags),
			CompanyFeatureFlagsEntry.autoNotifyUnfinishedPurchaseOrderCreated(flags),
			CompanyFeatureFlagsEntry.autoNotifyPurchaseOrderWithUnreceivedItems(flags),
			CompanyFeatureFlagsEntry.notifyOnOrderCreation(flags),
			CompanyFeatureFlagsEntry.notifyForUnfinishedOrders(flags),
			CompanyFeatureFlagsEntry.notifyOnItemDepartmentLowQuantity(flags),
			CompanyFeatureFlagsEntry.autoNotifyOnItemDepartmentLowQuantity(flags),
			CompanyFeatureFlagsEntry.notifyForOrdersTotalExpenseDaily(flags),
			CompanyFeatureFlagsEntry.notifyForOrdersTotalExpenseWeekly(flags),
			CompanyFeatureFlagsEntry.notifyForOrdersTotalExpenseMonthly(flags),
			CompanyFeatureFlagsEntry.notifyForDuplicateOrdersMonthly(flags),
			CompanyFeatureFlagsEntry.notifyForInexpensiveOrdersWeekly(flags),
			CompanyFeatureFlagsEntry.notifyForInexpensiveOrdersMonthly(flags),
			CompanyFeatureFlagsEntry.notifyForExpensiveOrdersWeekly(flags),
			CompanyFeatureFlagsEntry.notifyForExpensiveOrdersMonthly(flags),
			CompanyFeatureFlagsEntry.autoNotifyFailedNotifications(flags),
			CompanyFeatureFlagsEntry.notifyPastWorkOrdersEmail(flags),
			CompanyFeatureFlagsEntry.autoNotifyOnToolRepairModify(flags),
		];
		if (flags.isSendingInvoiceNotificationsEnabled) {
			this.specialEmails.push(CompanyFeatureFlagsEntry.autoNotifyInvoiceReminderNotification(flags));
		}

		this.workOrderNotifications = [
			CompanyFeatureFlagsEntry.autoNotifyOnPublishEntry(flags),
			CompanyFeatureFlagsEntry.autoNotifyOnCancelEntry(flags),
			CompanyFeatureFlagsEntry.autoNotifyOnPauseEntry(flags),
			CompanyFeatureFlagsEntry.autoNotifyOnResumeEntry(flags),
			CompanyFeatureFlagsEntry.autoNotifyEmployeesWithMultipleAssignments(flags),
			CompanyFeatureFlagsEntry.autoNotifyUnassignedEmployeesEntry(flags),
			CompanyFeatureFlagsEntry.autoNotifySupervisorEntry(flags),
			CompanyFeatureFlagsEntry.autoNotifyQAQC(flags),
			CompanyFeatureFlagsEntry.autoNotifyDeliverableAssignee(flags),
			CompanyFeatureFlagsEntry.autoNotifyOnTimeSheetReject(flags),
			CompanyFeatureFlagsEntry.autoNotifyOnReportReject(flags),
		];

		this.areNotificationsOn = flags.isEnabled;

		this.temporaryLaborNotifications = [
			CompanyFeatureFlagsEntry.notifyTemporaryLabor(flags),
		];
	}
}
