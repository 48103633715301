import type PurchaseOrderItemCompletion from 'acceligent-shared/enums/purchaseOrderItemCompletion';
import type PurchaseOrderStatus from 'acceligent-shared/enums/purchaseOrderStatus';
import type PurchaseOrderTruckPosition from 'acceligent-shared/enums/purchaseOrderTruckPosition';
import TimeFormat from 'acceligent-shared/enums/timeFormat';

import type PurchaseOrderBase from 'ab-domain/models/purchaseOrder/base';
import type PurchaseOrderItemBase from 'ab-domain/models/purchaseOrderItem/base';

import { formatDate } from 'acceligent-shared/utils/time';
import { getUserName } from 'acceligent-shared/utils/user';

export class W_PurchaseOrder_CreatePurchaseOrder_VM {
	id: number;
	orderNumber: string;
	index: number;
	status: PurchaseOrderStatus;
	/** YYYY-MM-DD */
	dateSubmitted: string;
	/** YYYY-MM-DD */
	dateCompleted: string;
	vendorName: string;
	locationName: Nullable<string>;
	locationId: Nullable<number>;
	totalCost: number;
	equipmentCode: Nullable<string>;
	jobCode: Nullable<string>;
	shipVia: Nullable<string>;
	shipTo: Nullable<string>;
	vendorId: number;
	equipmentId: Nullable<number>;
	workRequestId: Nullable<number>;
	itemCategoryId: Nullable<number>;
	submittedById: Nullable<number>;
	submittedBy: Nullable<string>;
	requestedById: Nullable<number>;
	requestedBy: Nullable<string>;
	purchaseOrderItems: W_PurchaseOrder_CreatePurchaseOrder_VM_Item[];
	note: Nullable<string>;
	frontOrRearOfTruck: PurchaseOrderTruckPosition;

	constructor(row: PurchaseOrderBase) {
		this.id = row.id;
		this.orderNumber = row.orderNumber;
		this.status = row.status;
		this.dateSubmitted = formatDate(row.dateSubmitted, TimeFormat.DB_DATE_ONLY);
		this.dateCompleted = formatDate(row.dateCompleted, TimeFormat.DB_DATE_ONLY);
		this.vendorName = row.vendor.name;
		this.vendorId = row.vendor.id;
		this.locationName = row.location?.nickname ?? null;
		this.locationId = row.location?.id ?? null;
		this.equipmentCode = row.equipment?.code ?? null;
		this.jobCode = row.workRequest?.jobCode ?? null;
		this.shipVia = row.shipVia;
		this.shipTo = row.shipTo;
		this.equipmentId = row.equipmentId;
		this.workRequestId = row.workRequestId;
		this.itemCategoryId = row.itemCategoryId;
		this.submittedById = row.submittedById;
		this.requestedById = row.requestedById;
		this.requestedBy = getUserName(row.requestedBy?.user);
		this.purchaseOrderItems = W_PurchaseOrder_CreatePurchaseOrder_VM_Item.bulkConstructor(row.purchaseOrderItems);
		this.submittedById = row.submittedById;
		this.submittedBy = row.dateSubmitted ? getUserName(row.submittedBy?.user) : null;
		this.note = row.note;
		this.index = row.index;
		this.frontOrRearOfTruck = row.frontOrRearOfTruck;

		this.totalCost = row.total();
	}

	private static _constructorMap = (po: PurchaseOrderBase) => new W_PurchaseOrder_CreatePurchaseOrder_VM(po);

	static bulkConstructor = (rows: PurchaseOrderBase[]) => rows.map(W_PurchaseOrder_CreatePurchaseOrder_VM._constructorMap);
}

class W_PurchaseOrder_CreatePurchaseOrder_VM_Item {
	id: number;
	itemId: Nullable<number>;
	quantity: number;
	receivedQuantity: number;
	invoicedQuantity: number;
	comment: Nullable<string>;
	itemNumber: Nullable<string>;
	itemName: string;
	priceEach: number;
	itemsReceived: PurchaseOrderItemCompletion;
	itemsInvoiced: PurchaseOrderItemCompletion;

	constructor(item: PurchaseOrderItemBase) {
		this.id = item.id;
		this.itemId = item.itemId;
		this.quantity = +item.quantity;
		this.itemNumber = item.itemNumber;
		this.itemName = item.itemName;
		this.priceEach = +item.priceEach;
		this.itemsInvoiced = item.itemsInvoiced;
		this.itemsReceived = item.itemsReceived;
		this.comment = item.comment;
		this.receivedQuantity = +item.receivedQuantity;
		this.invoicedQuantity = +item.invoicedQuantity;
	}

	private static _constructorMap = (poi: PurchaseOrderItemBase) => new W_PurchaseOrder_CreatePurchaseOrder_VM_Item(poi);

	static bulkConstructor = (rows: PurchaseOrderItemBase[]) => rows.map(W_PurchaseOrder_CreatePurchaseOrder_VM_Item._constructorMap);
}
