import * as React from 'react';

import type { ContactMethodModel } from 'ab-viewModels/scheduleBoardWorkOrderModal.viewModel';

import LockedValue from 'af-components/LockedValue';

interface Props {
	emails: ContactMethodModel[];
	removedEmailDictionary?: { [emailValue: string]: boolean; };
}

class SiteContactEmailList extends React.PureComponent<Props> {
	static defaultProps: Partial<Props> = {
		removedEmailDictionary: {},
	};

	render() {
		const { emails, removedEmailDictionary } = this.props;

		return (
			emails.length > 0 &&
			<div className="work-order-info-modal__site-contact-info-container">
				<LockedValue
					label="Email"
					value={emails.map((_email, _index) => (
						<div
							className={`work-order-info-modal__site-contact-info ${removedEmailDictionary?.[_email.value] ? 'work-order-info-modal__site-contact-info--removed' : ''}`}
							key={`siteContactEmail#${_index}`}
						>
							{_email.value}
						</div>
					))}
				/>
			</div>
		);
	}
}

export default SiteContactEmailList;
