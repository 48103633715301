import * as React from 'react';

import DisabledOnAction from 'af-components/DisabledOnAction';
import ActionButton from 'af-components/DisabledOnAction/ActionButton';
import Tooltip from 'af-components/Tooltip';

import { bemBlock } from 'ab-utils/bem.util';

import type { ButtonData } from '../../types';

interface OwnProps {
	onClick: ButtonData['onClick'];
	showSmallButtons: boolean;
	exportAsZip?: boolean;
}

const ExportButton = ({ showSmallButtons, onClick, exportAsZip = false }: OwnProps) => {

	const exportButton = React.useMemo(() => (
		<Tooltip message={`Export as ${exportAsZip ? 'ZIP' : 'CSV'}`} placement="bottom">
			<span>
				<span className="icon-download" />
				{showSmallButtons ? null : `Export as ${exportAsZip ? 'ZIP' : 'CSV'}`}
			</span>
		</Tooltip>
	), [exportAsZip, showSmallButtons]);
	const exportButtonClass = bemBlock('btn', { link: true, icon: showSmallButtons});

	return (
		<DisabledOnAction
			activeClassName={exportButtonClass}
			activeElement={exportButton}
			component={ActionButton}
			inactiveClassName={exportButtonClass}
			inactiveElement={exportButton}
			onClick={onClick}
		/>
	);
};

export default React.memo(ExportButton);
