import * as React from 'react';

import type LocationRequestModel from 'ab-requestModels/location.requestModel';

import MultipleOptionsButton from 'af-components/MultipleOptionsButton';
import RectangleButton from 'af-components/MultipleOptionsButton/RectangleButton';

import Tooltip from 'af-components/Tooltip';

interface Props {
	onSubmit: (form: LocationRequestModel['departments'][0]) => void;
	close: () => void;
}

const DepartmentInput = ({ onSubmit, close }: Props) => {
	const [department, setDepartment] = React.useState('');
	const [hasError, setHasError] = React.useState(false);

	const handleInput = React.useCallback((e: React.ChangeEvent<HTMLInputElement>) => {
		setDepartment(e.target.value);
	}, []);

	const handleSubmit = React.useCallback(() => {
		if (department.trim()) {
			return onSubmit({ name: department });
		}
		setHasError(true);
	}, [department, onSubmit]);

	const handleEnter = React.useCallback((e: React.KeyboardEvent) => {
		if (e.key === 'Enter' && e.shiftKey === false) {
			e.preventDefault();
			handleSubmit();
		}
	}, [handleSubmit]);

	return (
		<form className="color-picker__form">
			<input
				className={`form-control pac-target-input ${hasError ? 'with-error' : ''}`}
				onChange={handleInput}
				onKeyDown={handleEnter}
				value={department}
			/>
			<MultipleOptionsButton isLeftFlat={true}>
				<RectangleButton
					action={handleSubmit}
					isLeftOpen={true}
					isSquare={true}
					label={
						<Tooltip message="Submit" >
							<span className="icon-check" />
						</Tooltip>
					}
				/>
				<RectangleButton
					action={close}
					isSquare={true}
					label={
						<Tooltip message="Close" >
							<span className="icon-close" />
						</Tooltip>
					}
				/>
			</MultipleOptionsButton>
		</form>
	);
};

export default DepartmentInput;
