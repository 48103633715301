import * as React from 'react';
import Scrollbars from 'react-custom-scrollbars';
import type { CustomRouteComponentProps } from 'react-router-dom';

import SideNavCollapse from 'af-components/SideNav/SideNavCollapse';
import SideNavLink from 'af-components/SideNav/SideNavLink';

import { LOCALSTORAGE_SIDEBAR } from 'af-constants/localstorage';
import { __ENVIRONMENT__, __STAGING__, __TESTING__ } from 'af-constants/values';

import type * as User from 'ab-viewModels/user.viewModel';

import type { PagePermissionsTree, PagePermissionType } from 'ab-enums/pagePermissions.enum';

export interface LinkProps {
	label: string;
	visibleFor?: PagePermissionType | PagePermissionsTree;
	url: Nullable<string>;
	icon: Nullable<string>;
	isActive: boolean;
	userData?: User.UserData;
	companyData?: User.CompanyData;
	isFeatureHidden?: boolean;
	component?: React.ComponentType;
}

export interface SeparatorProps {
	isHr: boolean;
}

interface OwnProps {
	links?: (LinkProps | SeparatorProps)[];
}

type Props = OwnProps;

interface State {
	minimized: boolean;
}

class SideNav extends React.Component<Props> {
	static defaultProps: Partial<Props> = {
		links: [],
	};

	state: State = {
		minimized: true,
	};

	async componentDidMount() {
		const lsItem = localStorage.getItem(LOCALSTORAGE_SIDEBAR);

		const defaultSidebarMinimized = lsItem ? JSON.parse(lsItem) : false;
		this.setState(() => ({ minimized: defaultSidebarMinimized }));
	}

	toggleSideNav = async () => {
		const lsItem = localStorage.getItem(LOCALSTORAGE_SIDEBAR);
		const defaultSidebarMinimized = lsItem ? JSON.parse(lsItem) : false;
		if (!defaultSidebarMinimized) {
			localStorage.setItem(LOCALSTORAGE_SIDEBAR, JSON.stringify(true));
		}
		this.setState(() => ({ minimized: !this.state.minimized }));
	};

	onRedirect = () => this.setState(() => ({ minimized: true }));

	renderSideNavLink = (prop, index, minimized) => {
		let renderMethod: Nullable<(props: CustomRouteComponentProps) => React.ReactNode> = null;
		if (prop.component) {
			renderMethod = (props: CustomRouteComponentProps) => <prop.component {...props} showTooltip={minimized} />;
		}

		return (
			<SideNavLink
				companyData={prop.companyData}
				icon={prop.icon}
				isActive={prop.isActive}
				isFeatureHidden={prop.isFeatureHidden}
				key={`${prop.label}_${index}`}
				label={prop.label}
				onRedirect={this.onRedirect}
				renderComponentMethod={renderMethod}
				showTooltip={minimized}
				url={prop.url}
				userData={prop.userData}
				visibleFor={prop.visibleFor}
			/>
		);
	};

	renderLogos() {
		if (__TESTING__ || __STAGING__) {
			return (
				<div>
					<img className="logo-small" src="/images/logo/ic_logo_symbol_white.svg" />
					<img className="logo-big" src={`/images/logo/ic_logo_white_${__ENVIRONMENT__}.svg`} />
				</div>
			);
		}
		return (
			<div>
				<img className="logo-small" src="/images/logo/ic_logo_symbol.svg" />
				<img className="logo-big" src="/images/logo/ic_logo_white.svg" />
			</div>
		);
	}

	render(): JSX.Element {
		const { links } = this.props;
		const { minimized } = this.state;

		return (
			<nav className={`sidenav ${minimized ? 'minimized' : ''}`}>
				<SideNavCollapse
					isMinimized={minimized}
					toggleSideNav={this.toggleSideNav}
				/>
				<Scrollbars
					autoHide={true}
					autoHideDuration={0}
					autoHideTimeout={0}
					thumbMinSize={0}
					universal={true}
				>
					<ul className="sidenav-menu">
						{links?.map((l: LinkProps & SeparatorProps, index) => l.isHr ? <hr key={`hr_${index}`} /> : this.renderSideNavLink(l, index, minimized))}
					</ul>
				</Scrollbars>
				<div className="sidenav-logo">
					{this.renderLogos()}
				</div>
			</nav>
		);
	}
}

export default SideNav;
