import * as React from 'react';
import type { Cell} from '@tanstack/react-table';
import { flexRender } from '@tanstack/react-table';

import type { TableModel } from './types';

interface Props<T> {
	cell: Cell<T, unknown>;
	isSubRowCell: boolean;
	dataCellStyleProps: React.CSSProperties;
	dataCellClassName: string;
}

function BaseCell<T = TableModel,>({ cell, isSubRowCell, dataCellStyleProps, dataCellClassName }: React.PropsWithChildren<Props<T>>) {
	if ((!isSubRowCell && cell.column.columnDef.meta?.isOmmited) || (isSubRowCell && cell.column.columnDef.meta?.subRowColumn?.isOmmited)) {
		return null;
	}

	return (
		<span
			className={dataCellClassName}
			key={cell.id}
			style={dataCellStyleProps}
		>
			{isSubRowCell
				? flexRender(
					cell.column.columnDef.meta?.subRowColumn?.cell ?? cell.column.columnDef.cell,
					cell.getContext())
				: flexRender(cell.column.columnDef.cell, cell.getContext())}
		</span>
	);
}

const propsAreEqual = (prevProps, nextProps) => {
	if (prevProps.cell !== nextProps.cell) {
		return false;
	}
	if (prevProps.dataCellClassName !== nextProps.dataCellClassName) {
		return false;
	}
	if (prevProps.isSubRowCell !== nextProps.isSubRowCell) {
		return false;
	}
	for (const _style of Object.keys(nextProps.dataCellStyleProps)) {
		if (prevProps.dataCellStyleProps[_style] !== nextProps.dataCellStyleProps[_style]) {
			return false;
		}
	}

	return true;
};

export default React.memo(BaseCell, propsAreEqual) as typeof BaseCell;
