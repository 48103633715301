import type { Dispatch, AnyAction } from 'redux';

import API from 'af-constants/routes/api';

import { http } from 'af-utils/http.util';
import { errorHandler } from 'af-utils/actions.util';

import * as dailyTipActionCreators from 'af-actions/dailyTip/dailyTip.actionCreators';

import type { DailyTipViewModel } from 'ab-viewModels/dailyTip.viewModel';

import type { GetRootState } from 'af-reducers';

import * as CreateAPI from 'ab-api/web/dailyTip/create';

export function findLastFiveDailyTips() {
	return async (dispatch: Dispatch<AnyAction>, getState: GetRootState, { redirectTo }) => {

		const action = async () => {
			return await http.get<DailyTipViewModel[]>(API.V1.DAILY_TIP.LAST_FIVE);
		};

		return await errorHandler(action, dispatch, redirectTo);
	};
}

export function findCurrentDailyTip(dueDate: string, dispatchData: boolean = true) {
	return async (dispatch: Dispatch<dailyTipActionCreators.DailyTipAction>, getState: GetRootState, { redirectTo }) => {

		const action = async (): Promise<DailyTipViewModel> => {
			const dailyTip = await http.get<DailyTipViewModel>(API.V1.DAILY_TIP.CURRENT(dueDate));
			if (dispatchData) {
				dispatch(dailyTipActionCreators.UPDATE_DAILY_TIP(dailyTip));
			}
			return dailyTip;
		};

		return await errorHandler(action, dispatch, redirectTo);
	};
}

export function create(message: string, dueDate: string, isCustom: boolean) {
	return async (dispatch: Dispatch<AnyAction>, getState: GetRootState, { redirectTo }) => {

		const action = async (): Promise<CreateAPI.W_DailyTip_Create_VM> => {
			const body: CreateAPI.W_DailyTip_Create_RM = { message, dueDate, isCustom };
			const dailyTip = await http.post<CreateAPI.W_DailyTip_Create_VM>(CreateAPI.URL(), body);
			return dailyTip;
		};

		return await errorHandler(action, dispatch, redirectTo);
	};
}
