import * as React from 'react';
import { Button, Row, Col } from 'react-bootstrap';
import type { InjectedFormProps} from 'redux-form';
import { reduxForm, Field } from 'redux-form';
import type { ResolveThunks } from 'react-redux';
import { connect } from 'react-redux';
import { compose } from 'redux';

import TimeFormatEnum from 'acceligent-shared/enums/timeFormat';
import { DeliverableReviewTypeEnum, DeliverableReviewTypeLabelEnum } from 'acceligent-shared/enums/deliverableReviewType';

import * as FORMS from 'af-constants/reduxForms';

import type { DeliverableStatusViewModel } from 'ab-viewModels/deliverableStatusTable.viewModel';

import type { BulkDeliverableSubmissionRequestModel } from 'ab-requestModels/deliverable.requestModel';

import * as DeliverableStatusActions from 'af-actions/deliverableStatus';
import * as DeliverableActions from 'af-actions/deliverable';

import CustomModal from 'af-components/CustomModal';
import StatusOption from 'af-components/SharedForms/Deliverables/Shared/StatusOption';
import SubmitButton from 'af-components/SubmitButton';

import Dropdown from 'af-fields/Dropdown';
import Input from 'af-fields/Input';
import DateInput from 'af-fields/DateInput';

interface ReviewTypeOption {
	id: DeliverableReviewTypeEnum;
	label: string;
}

interface OwnProps {
	showModal: boolean;
	closeModal: (refresh?: boolean) => void;
	selectedSubmissionIds: string[];
}

interface DispatchProps {
	findAllStatuses: typeof DeliverableStatusActions.findAllDataStatusesForCompany;
	bulkUpdate: typeof DeliverableActions.bulkUpdateDeliverableSubmission;
}

type FormProps = InjectedFormProps<BulkDeliverableSubmissionRequestModel, FormOwnProps>;

type FormOwnProps = OwnProps & ResolveThunks<DispatchProps>;

type Props = FormOwnProps & FormProps;

interface State {
	deliverableStatusesForCompany: DeliverableStatusViewModel[];
	submitting: boolean;
}

class StatusHistoryModal extends React.PureComponent<Props, State> {
	state: State = {
		deliverableStatusesForCompany: [],
		submitting: false,
	};

	static _reviewTypeOptions: ReviewTypeOption[] = Object.keys(DeliverableReviewTypeEnum)
		.map((_key: DeliverableReviewTypeEnum) => ({ id: _key, label: DeliverableReviewTypeLabelEnum[_key] }));

	componentDidUpdate(prevProps: Props) {
		const { showModal, destroy } = this.props;

		if (!showModal && prevProps.showModal) {
			destroy();
		}
	}

	renderStatusOption = (option: DeliverableStatusViewModel) => <StatusOption status={option} />;

	lazyLoadStatuses = async (isLazyLoaded: boolean) => {
		const { findAllStatuses } = this.props;
		if (!isLazyLoaded) {
			const statuses = await findAllStatuses();
			this.setState(() => ({ deliverableStatusesForCompany: statuses }));
		}
	};

	onSubmit = (form: BulkDeliverableSubmissionRequestModel) => {
		this.setState(() => ({ submitting: true }), async () => {
			const { selectedSubmissionIds, bulkUpdate, closeModal } = this.props;
			form.deliverableIds = selectedSubmissionIds.map((_id) => +_id);

			await bulkUpdate(form);
			this.setState(() => ({ submitting: false }));
			closeModal(true);
		});
	};

	closeModal = () => {
		this.props.closeModal();
	};

	render() {
		const { showModal, handleSubmit } = this.props;
		const { deliverableStatusesForCompany, submitting } = this.state;
		return (
			<CustomModal
				closeModal={this.closeModal}
				modalStyle="info"
				showModal={showModal}
				size="lg"
			>
				<CustomModal.Header
					closeModal={this.closeModal}
					title="Bulk Edit Deliverable Submissions"
				/>
				<CustomModal.Body>
					<Row>
						<Col sm={8}>
							<Field
								component={Dropdown}
								fixed={true}
								id="statusId"
								label="Status"
								name="statusId"
								onLazyLoad={this.lazyLoadStatuses}
								options={deliverableStatusesForCompany}
								propName="status"
								renderMenuItem={this.renderStatusOption}
								valueKey="id"
								withCaret={true}
							/>
						</Col>
						<Col sm={8}>
							<Field
								component={DateInput}
								dateFormat={TimeFormatEnum.DATE_ONLY}
								fixed={true}
								id="date"
								label="Submission Date"
								name="date"
								originalDateFormat={TimeFormatEnum.DATE_ONLY}
							/>
						</Col>
						<Col sm={8}>
							<Field
								component={Dropdown}
								fixed={true}
								id="reviewType"
								label="Review Type"
								labelKey="label"
								name="reviewType"
								options={StatusHistoryModal._reviewTypeOptions}
								valueKey="id"
								withCaret={true}
							/>
						</Col>
						<Col sm={12}>
							<Field
								component={Input}
								id="trackingNumber"
								label="Tracking number"
								name="trackingNumber"
								placeholder="Enter tracking number"
								type="text"
							/>
						</Col>
						<Col sm={12}>
							<Field
								component={Input}
								id="trackingLink"
								label="Tracking Link"
								name="trackingLink"
								placeholder="Enter tracking link"
								type="text"
							/>
						</Col>
					</Row>
				</CustomModal.Body>
				<CustomModal.Footer>
					<Button
						onClick={this.closeModal}
						variant="info"
					>
						Cancel
					</Button>
					<SubmitButton
						label="Save"
						onClick={handleSubmit(this.onSubmit)}
						reduxFormSubmitting={submitting}
						variant="primary"
					/>
				</CustomModal.Footer>
			</CustomModal>
		);
	}
}

function mapDispatchToProps(): DispatchProps {
	return {
		findAllStatuses: DeliverableStatusActions.findAllDataStatusesForCompany,
		bulkUpdate: DeliverableActions.bulkUpdateDeliverableSubmission,
	};
}

const enhance = compose<React.ComponentClass<OwnProps>>(
	connect<null, DispatchProps, OwnProps>(null, mapDispatchToProps()),
	reduxForm<BulkDeliverableSubmissionRequestModel, FormOwnProps>({ form: FORMS.BULK_DELIVERABLE_SUBMISSION, enableReinitialize: true })
);

export default enhance(StatusHistoryModal);
