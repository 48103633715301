/* eslint-disable react/no-find-dom-node */
import * as React from 'react';
import * as ReactDOM from 'react-dom';
import { Button, Row, Col } from 'react-bootstrap';
import type { MarkerProps} from '@react-google-maps/api';
import { GoogleMap, Marker, Autocomplete } from '@react-google-maps/api';
import { Field } from 'redux-form';
import type { ResolveThunks } from 'react-redux';
import { connect } from 'react-redux';

import GoogleScriptLoader from 'af-components/Maps/Shared/Loader';

import { DEFAULT_MAP_ZOOM_LEVEL_ON_CENTER } from 'af-constants/values';

import * as AddressesActions from 'af-actions/addresses';

import CustomModal from 'af-components/CustomModal';

import Input from 'af-fields/Input';

import AddressFormModel from './formModel';

interface OwnProps {
	showModal: boolean;
	fieldName: string;
	closeModal: () => void;
	onValueChange: (address: AddressFormModel) => void;
}

interface DispatchProps {
	getAddressByLatLng: typeof AddressesActions.getAddressByLatLng;
}

type Props = OwnProps & ResolveThunks<DispatchProps>;

interface State {
	marker: Nullable<MarkerProps>;
	zoom: number;
	center: google.maps.LatLngLiteral;
}

class AddressModal extends React.PureComponent<Props, State> {
	private _searchBox: google.maps.places.Autocomplete;
	private _latitudeBox: HTMLInputElement;
	private _longitudeBox: HTMLInputElement;

	static readonly autocompleteOptions = { componentRestrictions: { country: ['us', 'ca'] }, strictBounds: true };

	state: State = {
		marker: null,
		zoom: 3,
		center: { lat: 38.7099085, lng: -95.137812 },
	};

	componentDidUpdate(prevProps: Props) {
		if (!prevProps.showModal && this.props.showModal) {

		}
	}

	onAutocompleteMount = (field: google.maps.places.Autocomplete) => {
		if (!field) {
			return;
		}
		this._searchBox = field;
	};

	onLatitudeMount = (field) => {
		if (!field) {
			return;
		}
		this._latitudeBox = (ReactDOM.findDOMNode(field) as Element).getElementsByTagName('input')[0];
		this._latitudeBox.addEventListener('change', this.onLatitudeChange);
		this.checkMarker();
	};

	onLongitudeMount = (field) => {
		if (!field) {
			return;
		}
		this._longitudeBox = (ReactDOM.findDOMNode(field) as Element).getElementsByTagName('input')[0];
		this._longitudeBox.addEventListener('change', this.onLongitudeChange);
		this.checkMarker();
	};

	checkMarker = () => {
		const lat = this._latitudeBox?.value;
		const lng = this._longitudeBox?.value;
		if (lat && lng) {
			this.setMarker(+lat, +lng);
		}
	};

	onPlacesChanged = () => {
		const place = this._searchBox.getPlace();
		if (!place?.geometry?.location) {
			return;
		}

		const address = AddressesActions.formatAddress(place);
		const position = place.geometry.location;
		const formAddress = new AddressFormModel(address, position.lng(), position.lat());
		this.onValueChange(formAddress);
	};

	onLatitudeChange = async (event) => {
		const lat = parseFloat(event.target.value) || 0;
		const lng = +this._longitudeBox.value;
		this.getAddressFromLatLng(lat, lng);
	};

	onLongitudeChange = async (event) => {
		const lng = parseFloat(event.target.value) || 0;
		const lat = +this._latitudeBox.value;
		this.getAddressFromLatLng(lat, lng);
	};

	onMapClick = async ({ latLng }: google.maps.MapMouseEvent) => {
		if (!latLng) {
			throw new Error('Pin not defined');
		}
		await this.getAddressFromLatLng(latLng.lat(), latLng.lng());
	};

	getAddressFromLatLng = async (latitude: number, longitude: number) => {
		const { getAddressByLatLng } = this.props;
		const address = await getAddressByLatLng(latitude, longitude);
		const formAddress = new AddressFormModel(address, longitude, latitude);
		this.onValueChange(formAddress);
	};

	onValueChange = (address: AddressFormModel) => {
		const { onValueChange } = this.props;
		this.setMarker(address.latitude, address.longitude);
		onValueChange(address);
	};

	setMarker = (latitude: number, longitude: number) => {
		const latLng = new google.maps.LatLng(latitude, longitude);

		const marker = {
			position: latLng,
			defaultAnimation: 2,
			key: Date.now(),
		};

		this.setState({
			marker,
			zoom: DEFAULT_MAP_ZOOM_LEVEL_ON_CENTER,
			center: { lat: latitude, lng: longitude },
		});
	};

	render() {
		const { showModal, closeModal, fieldName } = this.props;
		const { marker, zoom, center } = this.state;

		return (
			<CustomModal
				closeModal={closeModal}
				modalStyle="info"
				showModal={showModal}
				size="lg"
			>
				<CustomModal.Header
					closeModal={closeModal}
					title="Find Location"
				/>
				<CustomModal.Body padding="vertical">
					<GoogleScriptLoader>
						<Row>
							<Col md={24}>
								<Autocomplete
									onLoad={this.onAutocompleteMount}
									onPlaceChanged={this.onPlacesChanged}
									options={AddressModal.autocompleteOptions}
								>
									<Field
										component={Input}
										defaultValue="N/A"
										label="Dump Site Address"
										name={`${fieldName}.street`}
										placeholder="Dump Site"
										type="text"
									/>
								</Autocomplete>
							</Col>
						</Row>
						<Row>
							<Col md={3}>
								<Field
									component={Input}
									defaultValue="N/A"
									label="Latitude"
									name={`${fieldName}.latitude`}
									ref={this.onLatitudeMount}
									type="number"
								/>
							</Col>
							<Col md={3}>
								<Field
									component={Input}
									defaultValue="N/A"
									label="Longitude"
									name={`${fieldName}.longitude`}
									ref={this.onLongitudeMount}
									type="number"
								/>
							</Col>
						</Row>
						<Row>
							<Col md={24}>
								<GoogleMap
									center={center}
									mapContainerStyle={{ height: '400px', width: '100%' }}
									onClick={this.onMapClick}
									options={{ scrollwheel: false }}
									zoom={zoom}
								>
									{!!marker && <Marker {...marker} />}
								</GoogleMap>
							</Col>
						</Row>
					</GoogleScriptLoader>
				</CustomModal.Body>
				<CustomModal.Footer>
					<Button
						onClick={closeModal}
						variant="primary"
					>
						Select
					</Button>
				</CustomModal.Footer>
			</CustomModal>
		);
	}
}

function mapDispatchToProps(): DispatchProps {
	return {
		getAddressByLatLng: AddressesActions.getAddressByLatLng,
	};
}

export default connect<null, DispatchProps, OwnProps>(null, mapDispatchToProps())(AddressModal);
