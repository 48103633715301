import * as React from 'react';

import TimeFormat from 'acceligent-shared/enums/timeFormat';

import type { TableContent } from 'ab-common/dataStructures/tableContent';

import type { TabProps, Column, OwnProps as TableOwnProps } from 'af-components/Table6';
import Table from 'af-components/Table6';

import TableNameEnum from 'ab-enums/tableName.enum';

import type { ContactNotificationHistoryTableVM } from 'ab-viewModels/contact/notifiactionHistoryTable.viewModel';

import type { TableQuery } from 'ab-common/dataStructures/tableQuery';

import DateCell from 'af-components/Table6/Cells/DateCell';

const ContactTable = Table as unknown as React.ComponentClass<TableOwnProps<ContactNotificationHistoryTableVM>>;

const UPDATED_BY_CELL_WIDTH = 190;

interface OwnProps {
	fetchTable: (tableRequest: TableQuery) => Promise<TableContent<ContactNotificationHistoryTableVM> | undefined>;
}

type Props = OwnProps;

class NotificationHistoryTable extends React.PureComponent<Props> {

	static savedColumns: Column<ContactNotificationHistoryTableVM>[] = [
		{
			Header: 'Notification',
			accessor: 'sentFor',
			sortable: false,
			Cell: ({ original }) => <span>{original.sentFor}</span>,
		},
		{
			Header: 'Sent',
			headerClassName: 'align-right',
			accessor: 'sentBy',
			sortable: false,
			width: UPDATED_BY_CELL_WIDTH,
			Cell: ({ original }) => (
				<DateCell
					date={original.sentAt}
					details={original.sentByFullName}
					format={TimeFormat.FULL_DATE_LONG}
					isCustomerService={!original.sentByFullName}
				/>
			),
		},
	];

	tabs = (): TabProps<ContactNotificationHistoryTableVM>[] => {
		const {
			fetchTable,
		} = this.props;

		return [
			{
				label: 'Contacts',
				columns: NotificationHistoryTable.savedColumns,
				hasSearchInput: true,
				fetch: fetchTable,
			},
		];
	};

	render() {
		return (
			<ContactTable
				tableName={TableNameEnum.CONTACT}
				tabs={this.tabs()}
			/>
		);
	}
}

export default NotificationHistoryTable;
