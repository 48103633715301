import { isValidTextInput } from 'acceligent-shared/utils/text';

import type { CopyJobFormModel } from './formModel';

export const validate = (form: CopyJobFormModel) => {
	const errors: ValidationErrors = {};

	if (!isValidTextInput(form?.jobCode)) {
		errors.jobCode = 'Job ID is required';
	}

	return errors;
};
