import * as React from 'react';
import type { CustomRouteComponentProps } from 'react-router-dom';
import type { ConnectedProps } from 'react-redux';
import { connect } from 'react-redux';

import * as WorkOrderActions from 'af-actions/workOrder';

import Loading from 'af-components/DocumentViewer/Loading';

interface PathParams {
	personalPublicLink: string;
}

type OwnProps = CustomRouteComponentProps<PathParams>;

type Props = OwnProps & ConnectedProps<typeof connector>;

const WorkOrderConfirmationParticipantRedirect: React.FC<Props> = (props) => {
	const { match: { params: { personalPublicLink } }, location: { state: { orgAlias } }, redirectParticipantToWorkOrderConfirmationPublic } = props;

	React.useEffect(() => {
		redirectParticipantToWorkOrderConfirmationPublic(personalPublicLink, orgAlias);
		// eslint-disable-next-line react-hooks/exhaustive-deps
	}, []);

	return <Loading />;
};

function mapDispatchToProps() {
	return {
		redirectParticipantToWorkOrderConfirmationPublic: WorkOrderActions.redirectParticipantToWorkOrderConfirmationPublic,
	};
}

const connector = connect(null, mapDispatchToProps());

export default connector(WorkOrderConfirmationParticipantRedirect);
