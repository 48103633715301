import type { Dispatch, AnyAction } from 'redux';

import type { TableContent } from 'ab-common/dataStructures/tableContent';

import API from 'af-constants/routes/api';
import CLIENT from 'af-constants/routes/client';

import { http } from 'af-utils/http.util';
import { errorHandler } from 'af-utils/actions.util';

import { TableQuery } from 'ab-common/dataStructures/tableQuery';

import type {
	DeliverableAssignmentRequestModel,
	DeliverableCommentRequestModel,
	DeliverableSubmissionRequestModel,
	BulkDeliverableSubmissionRequestModel,
	DeliverableRequestModel,
	DeliverableSubmissionCommentRequestModel,
} from 'ab-requestModels/deliverable.requestModel';

import type { DeliverableTableViewModel, DeliverableComment } from 'ab-viewModels/deliverableTable.viewModel';
import type { DeliverableViewModel, DeliverableAssignmentViewModel, DeliverableSubmissionViewModel } from 'ab-viewModels/deliverable.viewModel';

import type { GetRootState } from 'af-reducers';

export function findAllForCompanyTable(tableRequestModel: TableQuery, statusId: number, startDate: string, endDate: string, completed: boolean) {
	return async (dispatch: Dispatch<AnyAction>, getState: GetRootState, { redirectTo }) => {
		const action = async () => {
			const data = new TableQuery(tableRequestModel);
			return await http.get<TableContent<DeliverableTableViewModel>>(API.V1.DELIVERABLE.DASHBOARD(data, statusId, startDate, endDate, completed));
		};
		return await errorHandler(action, dispatch, redirectTo);
	};
}

export function findById(id: number) {
	return async (dispatch: Dispatch<AnyAction>, getState: GetRootState, { redirectTo }) => {
		const action = async () => {
			return await http.get<DeliverableViewModel>(API.V1.DELIVERABLE.DELIVERABLE(id));
		};
		return await errorHandler(action, dispatch, redirectTo);
	};
}

export function findDeliverableComments(id: number) {
	return async (dispatch: Dispatch<AnyAction>, getState: GetRootState, { redirectTo }) => {
		const action = async () => {
			return await http.get<DeliverableComment[]>(API.V1.DELIVERABLE.DELIVERABLE_COMMENTS(id));
		};
		return await errorHandler(action, dispatch, redirectTo);
	};
}

export function findDeliverableSubmissionComments(id: number) {
	return async (dispatch: Dispatch<AnyAction>, getState: GetRootState, { redirectTo }) => {
		const action = async () => {
			return await http.get<DeliverableComment[]>(API.V1.DELIVERABLE.SUBMISSION_COMMENTS(id));
		};
		return await errorHandler(action, dispatch, redirectTo);
	};
}

export function findSubmissionById(id: number) {
	return async (dispatch: Dispatch<AnyAction>, getState: GetRootState, { redirectTo }) => {
		const action = async () => {
			return await http.get<DeliverableSubmissionViewModel>(API.V1.DELIVERABLE.SUBMISSION(id));
		};
		return await errorHandler(action, dispatch, redirectTo);
	};
}

export function findAssignmentById(id: number) {
	return async (dispatch: Dispatch<AnyAction>, getState: GetRootState, { redirectTo }) => {
		const action = async () => {
			return await http.get<DeliverableAssignmentViewModel>(API.V1.DELIVERABLE.ASSIGNMENT(id));
		};
		return await errorHandler(action, dispatch, redirectTo);
	};
}

export function updateDeliverable(form: DeliverableRequestModel, orgAlias: string, companyName: string) {
	return async (dispatch: Dispatch<AnyAction>, getState: GetRootState, { redirectTo }) => {
		const action = async () => {
			await http.put(API.V1.DELIVERABLE.EDIT_DELIVERABLE(form.id), form);
			redirectTo(CLIENT.COMPANY.DELIVERABLE.DASHBOARD(orgAlias, companyName));
		};
		return await errorHandler(action, dispatch, redirectTo);
	};
}

export function createDeliverableComment(form: DeliverableCommentRequestModel) {
	return async (dispatch: Dispatch<AnyAction>, getState: GetRootState, { redirectTo }) => {
		const action = async () => {
			await http.post(API.V1.DELIVERABLE.CREATE_DELIVERABLE_COMMENT(form.deliverableId), form);
		};
		return await errorHandler(action, dispatch, redirectTo);
	};
}

export function createSubmissionComment(form: DeliverableSubmissionCommentRequestModel) {
	return async (dispatch: Dispatch<AnyAction>, getState: GetRootState, { redirectTo }) => {
		const action = async () => {
			await http.post(API.V1.DELIVERABLE.CREATE_SUBMISSION_COMMENT(form.deliverableSubmissionId), form);
		};
		return await errorHandler(action, dispatch, redirectTo);
	};
}

export function updateDeliverableSubmission(form: DeliverableSubmissionRequestModel, orgAlias: string, companyName: string) {
	return async (dispatch: Dispatch<AnyAction>, getState: GetRootState, { redirectTo }) => {
		const action = async () => {
			await http.put(API.V1.DELIVERABLE.EDIT_SUBMISSION(form.id), form);
			redirectTo(CLIENT.COMPANY.DELIVERABLE.DASHBOARD(orgAlias, companyName));
		};
		return await errorHandler(action, dispatch, redirectTo);
	};
}

export function bulkUpdateDeliverableSubmission(form: BulkDeliverableSubmissionRequestModel) {
	return async (dispatch: Dispatch<AnyAction>, getState: GetRootState, { redirectTo }) => {
		const action = async () => {
			await http.patch(API.V1.DELIVERABLE.BULK_EDIT_SUBMISSION(), form);
		};
		return await errorHandler(action, dispatch, redirectTo);
	};
}

export function createDeliverableAssignment(form: DeliverableAssignmentRequestModel, orgAlias: string, companyName: string) {
	return async (dispatch: Dispatch<AnyAction>, getState: GetRootState, { redirectTo }) => {
		const action = async () => {
			await http.post(API.V1.DELIVERABLE.CREATE_ASSIGNMENT, form);
			redirectTo(CLIENT.COMPANY.DELIVERABLE.DASHBOARD(orgAlias, companyName));
		};
		return await errorHandler(action, dispatch, redirectTo);
	};
}

export function updateDeliverableAssignment(form: DeliverableAssignmentRequestModel, orgAlias: string, companyName: string) {
	return async (dispatch: Dispatch<AnyAction>, getState: GetRootState, { redirectTo }) => {
		const action = async () => {
			if (!form.id) {
				throw new Error('Assignment ID not provided');
			}
			await http.put(API.V1.DELIVERABLE.EDIT_ASSIGNMENT(form.id), form);
			redirectTo(CLIENT.COMPANY.DELIVERABLE.DASHBOARD(orgAlias, companyName));
		};
		return await errorHandler(action, dispatch, redirectTo);
	};
}
