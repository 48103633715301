export const getTimeBetweenDateTimes = (_createdAt: Date, _completedAt: Date) => {

	// Get timestamps for createdAt and completedAt
	const createdAt = new Date(_createdAt).getTime();
	const completedAt = new Date(_completedAt).getTime();

	// Calculate the difference in milliseconds
	const diffInMs = completedAt - createdAt;

	// Convert milliseconds to days, hours, and minutes
	const diffInMinutes = Math.floor(diffInMs / (1000 * 60));
	const days = Math.floor(diffInMinutes / (60 * 24));
	const hours = Math.floor((diffInMinutes % (60 * 24)) / 60);
	const minutes = diffInMinutes % 60;
	return `${days} days ${hours} hours ${minutes} minutes`;
};
