import * as React from 'react';
import type { HandleThunkActionCreator } from 'react-redux';

import type { ColorPalette } from 'acceligent-shared/enums/color';

import type { CrewTypeViewModel } from 'ab-viewModels/crewType.viewModel';

import type * as CrewTypeActions from 'af-actions/crewType';

import CrewTypePreview from '../CrewTypePreview';
import CrewTypeEdit from '../CrewTypeEdit';

interface OwnProps {
	crewType: CrewTypeViewModel;
	index: number;
	toggleEditingCrewType: (id: number) => void;
	editingId: Nullable<number>;
	updateCrewType: HandleThunkActionCreator<typeof CrewTypeActions.update>;
	deleteCrewType: HandleThunkActionCreator<typeof CrewTypeActions.deleteCrewType>;
}

type Props = OwnProps;

export default class CrewTypeItem extends React.PureComponent<Props> {

	getInitialValues = () => {
		const { crewType } = this.props;
		return {
			name: crewType.name,
			color: crewType.color as ColorPalette,
		};
	};

	render() {
		const { toggleEditingCrewType, editingId, crewType, index, updateCrewType, deleteCrewType } = this.props;

		return editingId === crewType.id ?
			(
				<CrewTypeEdit
					crewType={crewType}
					index={index}
					initialValues={this.getInitialValues()}
					toggleEdit={toggleEditingCrewType}
					updateCrewType={updateCrewType}
				/>
			) : (
				<CrewTypePreview
					crewType={crewType}
					deleteCrewType={deleteCrewType}
					toEdit={toggleEditingCrewType}
				/>
			);
	}
}
