import * as React from 'react';
import type { FormAction } from 'redux-form';

import type ContactVM from 'ab-viewModels/contact/contact';

import AddressPicker from 'af-components/SharedForms/Contacts/AddressPicker';
import ContactMethodPicker from 'af-components/SharedForms/Contacts/ContactMethodPicker';

interface Props {
	addressFieldName: string;
	canPickAddress: boolean;
	canPickContactMethods: boolean;
	changeField: (formName: string, field: string, value: string | string[]) => FormAction;
	contactIdsSelector: (field: string) => number[];
	disabled?: boolean;
	emailFieldName: string;
	formName: string;
	phoneFieldName: string;
	selectedContact: ContactVM;
	useIn: string;
}

export default class ContactInfo extends React.PureComponent<Props> {

	render() {
		const {
			addressFieldName,
			canPickAddress,
			canPickContactMethods,
			changeField,
			contactIdsSelector,
			disabled,
			emailFieldName,
			formName,
			phoneFieldName,
			selectedContact,
			useIn,
		} = this.props;

		return (
			<>
				<div className="contact__info">
					{selectedContact.companyName &&
						<span>{selectedContact.companyName}</span>
					}
					{selectedContact.companyName && selectedContact.title &&
						<span> | </span>
					}
					{selectedContact.title &&
						<span>{selectedContact.title}</span>
					}
				</div>

				<div className="contact__methods">
					{canPickContactMethods &&
						<ContactMethodPicker
							change={changeField}
							contact={selectedContact}
							disabled={disabled}
							emailFieldName={emailFieldName}
							formName={formName}
							phoneFieldName={phoneFieldName}
							select={contactIdsSelector}
							useIn={useIn}
						/>
					}
					{canPickAddress &&
						<AddressPicker
							addressFieldName={addressFieldName}
							change={changeField}
							contact={selectedContact}
							disabled={disabled}
							formName={formName}
							select={contactIdsSelector}
							useIn={useIn}
						/>
					}
				</div>
			</>
		);
	}
}
