import * as React from 'react';
import { compose } from 'redux';
import { Button, Row, Col } from 'react-bootstrap';
import type { InjectedFormProps } from 'redux-form';
import { reduxForm, Field } from 'redux-form';
import type { ConnectedProps } from 'react-redux';
import { connect } from 'react-redux';

import TimeFormat from 'acceligent-shared/enums/timeFormat';

import * as TimeUtils from 'acceligent-shared/utils/time';

import { toBase64ImageUrl } from 'ab-utils/image.util';

import type { RootState } from 'af-reducers';

import CustomModal from 'af-components/CustomModal';
import SubmitButton from 'af-components/SubmitButton';

import Input from 'af-fields/Input';
import Signature from 'af-fields/Signature';

import { SIGNATURE_FIELD_REPORT } from 'af-constants/reduxForms';

import type { Tab } from './Tabs';
import Tabs from './Tabs';
import type SignatureForm from './FormModel';
import { validateSignatureForm as validate } from './validations';

const TABS: Tab[] = [
	{ id: 0, title: 'Digital Signature' },
	{ id: 1, title: 'Use a stylus or a mouse to sign' },
];

const DEFAULT_TAB = TABS[1].id;

const TAB_SIGNATURE_EDITABLE = {
	[TABS[0].id]: true,
	[TABS[1].id]: false,
};

type FormProps = InjectedFormProps<SignatureForm, FormOwnProps>;

interface OwnProps {
	closeModal: () => void;
	description?: string;
	onBack?: () => void;
	onSubmit: (form: SignatureForm) => Promise<void>;
	showDigitalSignature?: boolean;
	showModal: boolean;
	showNameInput?: boolean;
	title: string;
	descriptionClassName?: string;
	keepValuesOnUnmount?: boolean;
}

type FormOwnProps = OwnProps;

type Props = FormOwnProps & FormProps & ConnectedProps<typeof connector>;

const _getTabs = (showDigitalSignature: boolean) => {
	return showDigitalSignature
		? TABS
		: [TABS[1]];
};

const SignatureModal: React.FC<Props> = (props) => {
	const {
		showModal,
		destroy,
		change,
		isDigitalSignatureEnabled,
		digitalSignatureUrl,
		onSubmit,
		keepValuesOnUnmount,
		closeModal,
		description,
		handleSubmit,
		invalid,
		onBack,
		showNameInput,
		submitting,
		title,
		showDigitalSignature = false,
		digitalSignatureId,
		descriptionClassName,
	} = props;
	const [signatureDataPoints, setSignatureDataPoints] = React.useState<Nullable<string>>(null);
	const [activeTabId, setActiveTabId] = React.useState(DEFAULT_TAB);
	const [isSignatureReadonly, setIsSignatureReadonly] = React.useState<boolean>(TAB_SIGNATURE_EDITABLE[activeTabId]);
	const [tabs, setTabs] = React.useState<Tab[]>(_getTabs(showDigitalSignature && isDigitalSignatureEnabled));
	const [digitalSignature, setDigitalSignature] = React.useState<Nullable<string>>(null);

	React.useEffect(() => {
		(async () => {
			if (digitalSignatureUrl) {
				const image = await toBase64ImageUrl(digitalSignatureUrl);
				setDigitalSignature(image);
			}
		})();
	}, [digitalSignatureUrl]);

	React.useEffect(() => {
		if (!showModal && !signatureDataPoints) {
			destroy();
			setActiveTabId(TABS[1].id);
		}
	}, [showModal, destroy, signatureDataPoints]);

	React.useEffect(() => {
		setTabs(_getTabs(showDigitalSignature && isDigitalSignatureEnabled));
	}, [showDigitalSignature, isDigitalSignatureEnabled]);

	React.useEffect(() => {
		if (activeTabId === TABS[0].id && isDigitalSignatureEnabled && digitalSignature) {
			change('signatureImage', digitalSignature);
			change('digitalSignatureId', digitalSignatureId);
			setIsSignatureReadonly(true);
		} else {
			change('signatureImage', null);
			change('digitalSignatureId', null);
			setIsSignatureReadonly(false);
		}
		// eslint-disable-next-line react-hooks/exhaustive-deps
	}, [activeTabId, isDigitalSignatureEnabled, digitalSignature, digitalSignatureId]);

	const submit = React.useCallback(async (form: SignatureForm) => {
		const data: SignatureForm = activeTabId === TABS[0].id
			? {
				...form,
				signatureImage: null,
				signedAt: TimeUtils.formatDate(new Date(), TimeFormat.ISO_DATETIME),
			} : {
				...form,
				digitalSignatureId: null,
				signedAt: TimeUtils.formatDate(new Date(), TimeFormat.ISO_DATETIME),
			};

		if (keepValuesOnUnmount) {
			setSignatureDataPoints(form.signatureImage);
		}
		await onSubmit(data);
	}, [keepValuesOnUnmount, onSubmit, activeTabId]);

	const onTabSelect = React.useCallback((tabId: number) => {
		setActiveTabId(tabId);
	}, [setActiveTabId]);

	return (
		<CustomModal
			closeModal={closeModal}
			modalStyle="info"
			showModal={showModal}
			size="md"
		>
			<CustomModal.Header
				closeModal={closeModal}
				title={title}
			/>
			<CustomModal.Body>
				{!!description ? (
					<Row className="row--padded-bottom">
						<Col className={descriptionClassName}>
							{description}
						</Col>
					</Row>
				) : <></>}
				{!!showNameInput ? (
					<Row>
						<Col>
							<Field
								component={Input}
								id="fullName"
								name="fullName"
								placeholder="Full name*"
								type="text"
							/>
						</Col>
					</Row>
				) : <></>}
				<Row className="row--padded-bottom">
					<Tabs
						activeTabId={activeTabId}
						setActiveTabId={onTabSelect}
						tabs={tabs}
					/>
				</Row>
				<Row>
					<Field
						component={Signature}
						isReadonly={isSignatureReadonly}
						name="signatureImage"
					/>
				</Row>
			</CustomModal.Body>
			<CustomModal.Footer>
				{onBack && (
					<Button
						onClick={onBack}
						variant="info"
					>
						Back
					</Button>
				)}
				<Button
					onClick={closeModal}
					variant="info"
				>
					Cancel
				</Button>
				<SubmitButton
					disabled={invalid}
					label="Sign & Submit"
					onClick={handleSubmit(submit)}
					reduxFormSubmitting={submitting}
				/>
			</CustomModal.Footer>
		</CustomModal>
	);
};

function mapStateToProps(state: RootState) {
	const { userData } = state.user;

	return {
		isDigitalSignatureEnabled: userData?.isDigitalSignatureEnabled ?? false,
		digitalSignatureUrl: userData?.digitalSignatureUrl ?? null,
		digitalSignatureId: userData?.digitalSignatureId ?? null,
	};
}

const connector = connect(mapStateToProps);

const enhance = compose<React.ComponentType<OwnProps>>(
	React.memo,
	connector,
	reduxForm<SignatureForm, FormOwnProps>({
		form: SIGNATURE_FIELD_REPORT,
		enableReinitialize: true,
		validate,
	})
);

export default enhance(SignatureModal);
