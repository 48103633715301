import * as React from 'react';
import type { TypedRowInfo, PageSizeChangeFunction, SortingRule, Resize, Column, FinalState, Instance } from 'react-table-6';
import ReactTable, { ReactTableDefaults } from 'react-table-6';

import type { TableContent } from 'ab-common/dataStructures/tableContent';

import type { TableQuery } from 'ab-common/dataStructures/tableQuery';

import type TableSettingsRequestModel from 'ab-requestModels/tableSettings.requestModel';

import type { TabProps, TabsDefinition, ExpandedDefinition, ReactTableRef } from './types';
import { DEFAULT_REACT_TABLE_PROPS, SCROLL_TO_ROW_ID } from './constants';
import * as Helpers from './helpers';
import checkboxHOC from './withCheckboxHOC';
import PaginationComponent from './PaginationComponent';
import BulkActionsHeader from './BulkActionsHeader';

const CheckboxReactTable = checkboxHOC(ReactTable);

interface OwnProps<T> {
	dynamicColumns?: boolean;
	getActiveTabProps: Nullable<() => Nullable<TabProps<T>>>;
	keepResizing?: boolean;
	keepSort?: boolean;
	table: TableContent<T> | null | undefined;
	isLoaded: boolean;
	accountId: Nullable<number>;
	tableSettings: Nullable<TableSettingsRequestModel>;
	openTableSettingsModal: () => void;
	onPageSizeChange: PageSizeChangeFunction;
	onResizedChanged: (columns: Resize[]) => void;
	onSortChanged: (sort: SortingRule[]) => void;
	tabsData: TabsDefinition;
	tableRequestModel: TableQuery;
	parentFetch: (tableState: FinalState<T>, resetTableState?: boolean, resetLoadingState?: boolean) => Promise<void>;
	SubComponent?: (rowInfo: TypedRowInfo<T>) => React.ReactNode;
	onMount?: (table: ReactTableRef<T>) => void;
	expanded: ExpandedDefinition;
	getRowHighlighted?: (row: T) => boolean;
	onScrollIntoNode?: () => void;
	offsetHeight?: number;
}

type Props<T> = OwnProps<T>;

interface State {
	selection: number[];
	pages: number | undefined;
}
class Table<T> extends React.PureComponent<Props<T>, State> {

	static defaultProps: Partial<Props<unknown>> = {
		keepResizing: true,
		keepSort: true,
		dynamicColumns: true,
	};

	state: State = {
		pages: undefined,
		selection: [],
	};

	private CustomPagination = PaginationComponent as unknown as new () => PaginationComponent<T>;
	private _table: Nullable<ReactTableRef<T>> = null;
	private _wrappedTable: Nullable<ReactTableRef<T>> = null;

	private _renderColumns = Helpers.renderColumns;
	private _renderRows = Helpers.renderRows;

	static readonly NULL_ELEMENT = () => null;

	static scrollIntoRow = () => {
		const row = document.getElementById(SCROLL_TO_ROW_ID);
		row?.scrollIntoView({ block: 'center', behavior: 'smooth' });
	};

	static findActiveIndex = (tabsData: TabsDefinition) => {
		if (!tabsData) {
			return null; // should never happen
		}
		for (const tabKey in tabsData) {
			if (!tabsData.hasOwnProperty(tabKey)) {
				continue;
			}
			const tabIndex = +tabKey;
			if (!Number.isNaN(tabIndex) && tabsData[tabKey].isActive) {
				return tabIndex;
			}
		}
		return null;
	};

	componentDidUpdate(prevProps: Props<T>) {
		if (prevProps.tabsData === this.props.tabsData) {
			return;
		}
		const prevIndex = Table.findActiveIndex(prevProps.tabsData);
		const newIndex = Table.findActiveIndex(this.props.tabsData);
		if (prevIndex !== newIndex) {
			this.refreshTable();
		}
	}

	getTableState = (): Readonly<FinalState<T>> => {
		const { getActiveTabProps } = this.props;
		const { selectable } = getActiveTabProps?.() ?? {};
		if (selectable && this._wrappedTable) {
			return this._wrappedTable.state as unknown as Readonly<FinalState<T>>;
		}
		return this._table?.state as unknown as Readonly<FinalState<T>>;
	};

	refreshTable = async (resetLoadingState: boolean = true) => {
		const { parentFetch } = this.props;
		const tableState = this.getTableState();
		if (tableState) {
			await parentFetch(tableState, false, resetLoadingState);
		}
	};

	clearSelection = () => {
		this.setState(
			() => ({ selection: [] }),
			() => {
				this._table?.clearSelection?.();
				this.refreshTable();
			}
		);
	};

	defaultGetRowClassName = (state: FinalState<T>, rowInfo: TypedRowInfo<T & { isInactive?: boolean; }>) => rowInfo.original.isInactive ? 'inactive' : '';

	// event handlers:

	fetchRows = async (tableState: FinalState<T>, instance: Instance<T>) => {
		const { parentFetch } = this.props;
		// if not loaded, set table state but don't invoke fetch (necessary for table settings to be added to the state)
		parentFetch(instance.state);
	};

	onTableMount = (table: ReactTableRef<T>) => {
		const { onMount, getActiveTabProps } = this.props;
		const { selectable } = getActiveTabProps?.() ?? {};

		this._table = table;

		if (onMount && !selectable) {
			onMount(table);
		}
	};

	onWrappedTableMount = (table: ReactTableRef<T>) => {
		const { onMount } = this.props;

		this._wrappedTable = table;

		if (onMount) {
			onMount(table);
		}
	};

	onSelect = (selection: number[]) => this.setState(() => ({ selection }));

	refreshAction = async (action: (rowElement?: T) => Promise<void>, rowElement: T, shouldRefresh: boolean) => {
		await action(rowElement);
		if (shouldRefresh) {
			await this.refreshTable();
		}
	};

	// React Table Props:

	getTrProps = (state: FinalState<T>, rowInfo: TypedRowInfo<T>) => {
		const { onScrollIntoNode, getRowHighlighted, getActiveTabProps } = this.props;
		const activeTabProps = getActiveTabProps?.();
		if (!activeTabProps) {
			throw new Error('Active tab props not defined');
		}

		const { getRowClassName } = activeTabProps;

		const isHighlighted = getRowHighlighted?.(rowInfo.original) ?? false;
		if (isHighlighted) {
			// we need to set id before calling scrollIntoRow
			// currently, isHighlighted is true only for one row so setTimeout cb is fine like this
			// if isHighlighted can be true for multiple rows extract it
			setTimeout(() => {
				Table.scrollIntoRow();
				onScrollIntoNode?.();
			});
		}

		return {
			id: isHighlighted ? SCROLL_TO_ROW_ID : undefined,
			className: (getRowClassName ?? this.defaultGetRowClassName)(state, rowInfo),
		};
	};

	getTdProps = (state: FinalState<T>, rowInfo: TypedRowInfo<T>, column: Column) => {
		const activeTabProps = this.props.getActiveTabProps?.();
		if (!activeTabProps) {
			throw new Error('Active tab props not defined');
		}
		const { onRowClick } = activeTabProps;

		// onRowClick and onExpandChange don't like each other
		// add onRowClick event to the cell only if we have expander
		// add onRowClickEvent on every cell except expanders
		const addOnClickEvent = onRowClick && !!state.ExpanderComponent && !column.expander;

		return {
			onClick: addOnClickEvent
				? (event: React.MouseEvent<HTMLElement>) => {
					// ignore the row click action if clicked on settings-cell
					if (!event.currentTarget.className.includes('settings-cell')) {
						onRowClick(rowInfo);
					}
				}
				: undefined,
		};
	};

	// main render:

	render() {
		const {
			table,
			keepResizing,
			keepSort,
			isLoaded,
			getActiveTabProps,
			SubComponent,
			accountId,
			tableSettings,
			openTableSettingsModal,
			onPageSizeChange,
			onResizedChanged,
			onSortChanged,
			expanded,
			dynamicColumns,
			offsetHeight,
		} = this.props;

		const activeTabProps = getActiveTabProps?.();
		if (!activeTabProps) {
			throw new Error('Active tab props not defined');
		}

		const {
			columns,
			selectable,
			rowActions,
			onExpandedChange,
			bulkDelete,
			additionalBulkActions,
			hideTableHeader = false,
			hideTableFooter = false,
		} = activeTabProps;
		const { selection } = this.state;

		if (!table || !tableSettings) {
			throw new Error('Table settings not initialized');
		}

		const reactTableProps = {
			// eslint-disable-next-line @typescript-eslint/no-explicit-any
			ref: this.onTableMount as React.LegacyRef<any>,
			onMount: this.onWrappedTableMount,
			columns: this._renderColumns(
				columns,
				table?.rows ?? undefined,
				isLoaded,
				null,
				rowActions,
				this.refreshAction,
				accountId,
				tableSettings,
				openTableSettingsModal,
				!!dynamicColumns
			),
			data: this._renderRows(table.rows ?? undefined, isLoaded),
			pages: table.pages,
			getTrProps: this.getTrProps,
			getTdProps: this.getTdProps,
			onFetchData: this.fetchRows,
			pageSize: tableSettings.pageSize,
			sorted: tableSettings.sort,
			onPageSizeChange: onPageSizeChange,
			onResizedChange: keepResizing ? onResizedChanged : undefined,
			onSortedChange: keepSort ? onSortChanged : undefined,
			SubComponent,
			TheadComponent: hideTableHeader ? Table.NULL_ELEMENT : ReactTableDefaults.TheadComponent,
			TfootComponent: hideTableFooter ? Table.NULL_ELEMENT : ReactTableDefaults.TfootComponent,
			PaginationComponent: hideTableFooter ? Table.NULL_ELEMENT : this.CustomPagination,
		};

		return (
			<>
				<BulkActionsHeader
					additionalBulkActions={additionalBulkActions}
					bulkDelete={bulkDelete}
					clearSelection={this.clearSelection}
					isSelectable={!!selectable}
					selection={selection}
				/>
				{selectable ?
					<CheckboxReactTable
						{...DEFAULT_REACT_TABLE_PROPS}
						{...reactTableProps}
						keyField="id"
						onSelect={this.onSelect}
						selectType="checkbox"
					/> :
					<div
						style={{
							'--extra-table-offset': `${offsetHeight ?? 0}px`,
						} as React.CSSProperties}
					>
						<ReactTable
							{...DEFAULT_REACT_TABLE_PROPS}
							{...reactTableProps}
							expanded={expanded ?? undefined}
							onExpandedChange={onExpandedChange ?? undefined}
						/>
					</div>

				}
			</>
		);
	}
}

export default Table;
