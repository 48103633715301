import * as React from 'react';

import type { EquipmentListItemVM } from 'ab-viewModels/timeSplitEquipment/timeSplitEquipment';

import { ColorHex } from 'acceligent-shared/enums/color';

import { bemElement } from 'ab-utils/bem.util';

const EquipmentItem = (equipment: EquipmentListItemVM) => {
	const { code, color, specification } = equipment;
	return (
		<div className={bemElement('time-sheet-list__equipment-modal', 'equipment-item__overflow')}>
			<div
				className={bemElement('time-sheet-list__equipment-modal', 'equipment-item__overflow__color')}
				style={{ backgroundColor: ColorHex[color] }}
			/>
			<div className={bemElement('time-sheet-list__equipment-modal', 'equipment-item__overflow__code')}>
				{code}
			</div>
			{!!specification && (
				<div className={bemElement('time-sheet-list__equipment-modal', 'equipment-item__overflow__spec')}>
					({specification})
				</div>
			)}
		</div>
	);
};

export default EquipmentItem;
