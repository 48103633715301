import * as React from 'react';
import { compose } from 'redux';
import type { ResolveThunks, ConnectedProps } from 'react-redux';
import { connect } from 'react-redux';
import type { CustomRouteComponentProps } from 'react-router-dom';
import type { InjectedFormProps, FormErrors } from 'redux-form';
import { reduxForm, getFormSyncErrors, formValueSelector } from 'redux-form';

import type { ContactValidationErrors } from 'ab-requestModels/contact/upsert';
import type ContactRM from 'ab-requestModels/contact/upsert';

import { validateContact } from 'ab-validators/contact.validator';

import type { RootState } from 'af-reducers';

import * as ContactActions from 'af-actions/contacts';

import CLIENT from 'af-constants/routes/client';
import { CONTACT_CREATE } from 'af-constants/reduxForms';

import Breadcrumbs from 'af-components/Breadcrumbs';
import ContactForm from 'af-components/SharedForms/Contacts/ContactForm';
import { warn } from 'af-components/SharedForms/Contacts/ContactForm/validation';

interface StateProps {
	companyName: string;
	emailPhoneError?: string;
}

interface DispatchProps {
	createContact: typeof ContactActions.createContact;
}

type OwnProps = CustomRouteComponentProps;

type FormOwnProps = OwnProps & StateProps & ResolveThunks<DispatchProps>;

type Props = FormOwnProps & InjectedFormProps<ContactRM, FormOwnProps> & ConnectedProps<typeof connector>;

class CreateContact extends React.Component<Props> {

	createContact = async (form: ContactRM) => {
		const {
			createContact,
			location: { state: { orgAlias } },
			companyName,
			history,
		} = this.props;

		await createContact(form);
		history.push(CLIENT.COMPANY.CONTACTS.LIST(orgAlias, companyName));
	};

	breadcrumbs = () => {
		const {
			location: { state: { orgAlias } },
			companyName,
		} = this.props;
		return [
			{ label: 'Contacts', url: CLIENT.COMPANY.CONTACTS.LIST(orgAlias, companyName) },
			{ label: 'Create' },
		];
	};

	render() {
		return (
			<div className="form-segment">
				<Breadcrumbs items={this.breadcrumbs()} />
				<div className="form-box">
					<ContactForm
						{...this.props}
						initialValues={{ fullName: this.props.fullName }}
						onSubmit={this.createContact}
						removeOnDelete={true}
					/>
				</div>
			</div>
		);
	}
}

const getErrors = getFormSyncErrors(CONTACT_CREATE);

const formSelector = formValueSelector(CONTACT_CREATE);

function mapStateToProps(state: RootState) {
	const { companyData } = state.user;
	if (!companyData) {
		throw new Error('User not logged in');
	}

	const fullName = formSelector(state, 'fullName') as string;

	const {
		emailsPhones: emailPhoneError,
	} = getErrors(state) as FormErrors<ContactValidationErrors, string>;

	return {
		companyName: companyData.name,
		emailPhoneError: emailPhoneError as string,
		fullName,
	};
}

function mapDispatchToProps() {
	return {
		createContact: ContactActions.createContact,
	};
}

const connector = connect(mapStateToProps, mapDispatchToProps());

const enhance = compose<React.ComponentClass<OwnProps>>(
	connector,
	reduxForm<ContactRM, FormOwnProps>({
		form: CONTACT_CREATE,
		enableReinitialize: true,
		validate: validateContact,
		warn,
	})
);

export default enhance(CreateContact);
