import * as React from 'react';
import { connect } from 'react-redux';

import type { RootState } from 'af-reducers';

interface StateProps {
	organizationAlias: string;
	organizationName: string;
}

type OwnProps = void;
type Props = OwnProps & StateProps;

class OrganizationSettings extends React.PureComponent<Props> {

	render() {
		const { organizationAlias, organizationName } = this.props;

		return (
			<div className="account-details-container">
				<div className="account-details-content">
					<h1>Organization</h1>
					<p>
						Alias: {organizationAlias}
					</p>
					<p>
						Name: {organizationName}
					</p>
				</div>
			</div>
		);
	}
}

function mapStateToProps(state: RootState): StateProps {
	if (!state.user.organizationData) {
		throw new Error('Organization data not loaded');
	}

	return {
		organizationAlias: state.user.organizationData.alias,
		organizationName: state.user.organizationData.name,
	};
}

export default connect<StateProps, void, OwnProps>(mapStateToProps)(OrganizationSettings);
