import type { TextColor, ColorPalette } from 'acceligent-shared/enums/color';

import type * as DeliverableVM from 'ab-viewModels/deliverable.viewModel';
import type * as DeliverableRM from 'ab-requestModels/deliverable.requestModel';

interface DeliverableStatusFM {
	id: number;
	name: string;
	abbreviation: string;
	description: Nullable<string>;
	statusColor: ColorPalette;
	textColor: TextColor;
	notify: boolean;
	isCompleted: boolean;
	isJobStatus: boolean;
}

interface AccountFM {
	id: number;
	firstName: string;
	lastName: string;
	fullName: string;
}

export default class DeliverableFM {
	id: number;
	statusId: Nullable<number>;
	status: Nullable<DeliverableStatusFM>;
	deliverableAssigneeId: Nullable<number>;
	deliverableAssignee: Nullable<AccountFM>;
	notes: Nullable<string>;

	static fromVMtoFM(vm: DeliverableVM.DeliverableViewModel): DeliverableFM {
		return {
			id: vm.id,
			statusId: vm.statusId,
			status: vm.status,
			deliverableAssigneeId: vm.deliverableAssigneeId,
			deliverableAssignee: vm.deliverableAssignee,
			notes: vm.notes,
		};
	}

	static fromFMtoRM(fm: DeliverableFM): DeliverableRM.DeliverableRequestModel {
		if (!fm.statusId || !fm.status || !fm.deliverableAssignee || !fm.deliverableAssigneeId) {
			throw new Error('Form not properly filled out');
		}

		return {
			id: fm.id,
			statusId: fm.statusId,
			status: fm.status,
			deliverableAssigneeId: fm.deliverableAssigneeId,
			deliverableAssignee: fm.deliverableAssignee,
			notes: fm.notes,
		};
	}
}
