import * as React from 'react';
import type { CustomRouteComponentProps } from 'react-router-dom';
import { compose } from 'redux';
import type { ResolveThunks } from 'react-redux';
import { connect } from 'react-redux';
import type { InjectedFormProps } from 'redux-form';
import { reduxForm } from 'redux-form';

import type { RootState } from 'af-reducers';

import * as WageRateActions from 'af-actions/wageRate';

import BulkImport from 'af-components/BulkUpsert/Import';
import CodeField from 'af-components/CodeField';

import { WAGE_RATE_CSV_SAMPLE } from 'af-constants/csvSamples';
import { WAGE_RATE_IMPORT } from 'af-constants/reduxForms';

import type { WageRateRequestModel } from 'ab-requestModels/wageRate.requestModel';

import CLIENT from 'af-constants/routes/client';

import parse from 'af-root/scenes/Company/Resources/WageRate/BulkUpload/parse';

type OwnProps = CustomRouteComponentProps;

interface StateProps {
	companyName: string;
}
interface DispatchProps {
	importBatch: typeof WageRateActions.importBatch;
}

type FormOwnProps = OwnProps & StateProps & ResolveThunks<DispatchProps>;
type Props = FormOwnProps & InjectedFormProps<WageRateRequestModel, FormOwnProps>;

class WageRateBulkUpload extends React.PureComponent<Props> {

	static MANDATORY_FIELDS = ['type', 'wageClassification', 'hourlyRate'];

	static MANUAL = [
		'Import multiple equipment costs to your equipment costs list by using a CSV upload feature.',
		'Fields that should be located in the CSV are listed below.',
		'The ones marked with an asterisk are required and should not be left out.',
	];

	static FIELDS = (
		<CodeField isBlue={true}>
			<>
				<b>type</b>                            | * | Type <br />
				<b>wageClassification</b>              | * | Wage Classification <br />
				<b>hourlyRate</b>                      | * | Hourly Rate <br />
			</>
		</CodeField>
	);

	render() {
		const { importBatch, location: { state: { orgAlias } }, companyName } = this.props;
		return (
			<BulkImport
				fields={WageRateBulkUpload.FIELDS}
				importBatch={importBatch}
				listRoute={CLIENT.COMPANY.RESOURCES.WAGE_RATE.LIST(orgAlias, companyName)}
				mandatoryFields={WageRateBulkUpload.MANDATORY_FIELDS}
				manual={WageRateBulkUpload.MANUAL}
				parse={parse}
				sampleData={WAGE_RATE_CSV_SAMPLE}
				title="Wage Rates"
			/>
		);
	}
}

function mapStateToProps(state: RootState): StateProps {
	const { companyData } = state.user;
	if (!companyData) {
		throw new Error('User not logged in');
	}

	return {
		companyName: companyData.name,
	};
}

function mapDispatchToProps(): DispatchProps {
	return {
		importBatch: WageRateActions.importBatch,
	};
}

const enhance = compose<React.ComponentClass<OwnProps>>(
	connect<StateProps, DispatchProps, OwnProps>(mapStateToProps, mapDispatchToProps()),
	reduxForm<WageRateRequestModel, FormOwnProps>({
		form: WAGE_RATE_IMPORT,
		enableReinitialize: true,
	})
);
export default enhance(WageRateBulkUpload);
