import * as React from 'react';
import { compose } from 'redux';
import type { ConnectedProps} from 'react-redux';
import { connect } from 'react-redux';
import type { InjectedFormProps} from 'redux-form';
import { Field, reduxForm } from 'redux-form';
import { Button } from 'react-bootstrap';

import CustomModal from 'af-components/CustomModal';
import SubmitButton from 'af-components/SubmitButton';

import Input from 'af-fields/Input';
import Checkbox from 'af-fields/Checkbox';

import * as WorkRequestActions from 'af-actions/workRequests';

import { WORK_REQUEST_COPY } from 'af-constants/reduxForms';

import type { CopyWorkRequestForm} from '../Form/helpers';
import { validateCopyWorkRequestForm } from '../Form/helpers';
import styles from './styles.module.scss';

type OwnProps = {
	closeModal: () => void;
	showModal: boolean;
	currentWorkRequestCode: string;
	currentWorkRequestId: Nullable<number>;
	redirectToCopiedWorkRequestPage: (copiedWorkRequestId: number) => void;
};

type Props = OwnProps & ConnectedProps<typeof connector> & InjectedFormProps<CopyWorkRequestForm>;

const WorkRequestCopyModal: React.FC<Props> = (props) => {
	const {
		closeModal,
		showModal,
		initialize,
		currentWorkRequestCode,
		handleSubmit,
		copyWorkRequest,
		currentWorkRequestId,
		redirectToCopiedWorkRequestPage,
		submitting,
		valid,
	} = props;

	React.useEffect(() => {
		initialize({ keepAttachments: false, workRequestCode: `${currentWorkRequestCode} - copy` });
		// eslint-disable-next-line react-hooks/exhaustive-deps
	}, [currentWorkRequestCode]);

	const handleCopy = React.useCallback(async (form: CopyWorkRequestForm) => {
		if (!currentWorkRequestId) {
			return;
		}
		const copyResult = await copyWorkRequest(currentWorkRequestId, form);

		if (copyResult) {
			redirectToCopiedWorkRequestPage(copyResult.copiedWorkRequestId);
		}
	}, [copyWorkRequest, currentWorkRequestId, redirectToCopiedWorkRequestPage]);

	return (
		<CustomModal
			closeModal={closeModal}
			modalStyle="info"
			showModal={showModal}
			size="md"
		>
			<CustomModal.Header
				closeModal={closeModal}
				title="Copy Work Request"
			/>
			<CustomModal.Body>
				<div className={styles['copy-convert-modal']}>
					<div className={styles['copy-convert-modal__job-id']}>
						<Field
							component={Input}
							defaultValue={currentWorkRequestCode}
							label="Work Request ID *"
							name="workRequestCode"
							placeholder="Enter Work Request ID"
						/>
					</div>
					<Field
						component={Checkbox}
						id="keepAttachments"
						label="Copy Attachments to copied Work Request"
						name="keepAttachments"
					/>
				</div>
			</CustomModal.Body>
			<CustomModal.Footer>
				<Button
					onClick={closeModal}
					variant="info"
				>
					Cancel
				</Button>
				<SubmitButton
					disabled={!valid}
					label="Copy"
					onClick={handleSubmit(handleCopy)}
					reduxFormSubmitting={submitting}
				/>
			</CustomModal.Footer>
		</CustomModal>
	);
};

function mapDispatchToProps() {
	return {
		copyWorkRequest: WorkRequestActions.copy,
	};
}

const connector = connect(null, mapDispatchToProps());

const enhance = compose<React.ComponentType<OwnProps>>(
	connector,
	reduxForm({
		form: WORK_REQUEST_COPY,
		validate: validateCopyWorkRequestForm,
	}),
	React.memo
);

export default enhance(WorkRequestCopyModal);
