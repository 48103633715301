import * as React from 'react';
import { compose } from 'redux';
import type { ConnectedProps } from 'react-redux';
import { connect } from 'react-redux';
import { Button } from 'react-bootstrap';
import type { InjectedFormProps } from 'redux-form';
import { reduxForm } from 'redux-form';
import { Field, FieldArray } from 'redux-form';

import type CreateLaborPlaceholderForm from 'ab-requestModels/scheduleBoardCreateLaborPlaceholder.requestModel';

import type { SkillViewModel } from 'ab-viewModels/skill.viewModel';
import type { WageRateVM } from 'ab-viewModels/wageRate/wageRate.viewModel';

import Dropdown from 'af-fields/Dropdown';
import type { OwnProps as MultiTagSelectProps } from 'af-fields/MultiTagSelect';
import MultiTagSelect from 'af-fields/MultiTagSelect';

import * as WageRateActions from 'af-actions/wageRate';
import * as SkillActions from 'af-actions/skill';

import { LABOR_PLACEHOLDER_FORM } from 'af-constants/reduxForms';

import SubmitButton from 'af-components/SubmitButton';
import CustomModal from 'af-components/CustomModal';
import SkillOption from 'af-components/SharedForms/Resources/Shared/SkillOption';
import SkillMultiValueLabel from 'af-components/SharedForms/Resources/Shared/SkillMultiValueLabel';

import LaborPlaceholderOption from './LaborPlaceholderOption';
import { validate } from './validation';

interface OwnProps {
	resetTimer: () => void;
	onSubmit: (form: CreateLaborPlaceholderForm) => void;
	onClose: () => void;
	initialValues: { index: Nullable<number>; workOrderId: Nullable<number>; };
}

type FormOwnProps = OwnProps & ConnectedProps<typeof connector>;
type Props = FormOwnProps & InjectedFormProps<CreateLaborPlaceholderForm, FormOwnProps>;

const PLACEHOLDER_FILTER_BY: (keyof WageRateVM)[] = ['type', 'wageClassification'];

const getSkillOptionValue = (option: SkillViewModel) => option.id?.toString();

const getSkillOptionLabel = (option: SkillViewModel) => option.name;

const renderLaborPlaceholderOptionItem = (option: WageRateVM, searchText: string) => <LaborPlaceholderOption {...option} searchText={searchText} />;

const LaborPlaceholderForm: React.FC<Props> = (props: Props) => {
	const {
		findAllSkillsForCompany,
		findAllWageRatesForCompany,
		handleSubmit,
		initialize,
		initialValues,
		invalid,
		onClose,
		onSubmit,
		resetTimer,
		submitting,
	} = props;

	const [skillOptions, setSkillOptions] = React.useState<SkillViewModel[]>([]);
	const [wageRateOptions, setWageRateOptions] = React.useState<WageRateVM[]>([]);

	const fetchSkills = React.useCallback(async () => {
		const skills = await findAllSkillsForCompany();
		setSkillOptions(Object.values(skills).flat());
	}, [findAllSkillsForCompany]);

	// On mount
	React.useEffect(() => {
		initialize(initialValues);
		// eslint-disable-next-line react-hooks/exhaustive-deps
	}, []);

	React.useEffect(() => {
		fetchSkills();
	}, [fetchSkills]);

	const lazyLoadWageRates = React.useCallback(async (isLazyLoaded: boolean) => {
		if (!isLazyLoaded) {
			const _wageRateOptions = await findAllWageRatesForCompany();
			setWageRateOptions(_wageRateOptions ?? []);
		}
	}, [findAllWageRatesForCompany, setWageRateOptions]);

	return (
		<>
			<CustomModal.Body>
				<Field
					component={Dropdown}
					disabled={false}
					filterable={true}
					filterBy={PLACEHOLDER_FILTER_BY}
					fixed={true}
					hideErrorText={true}
					id="wageRateId"
					isArrayField={true}
					label="Wage Rate *"
					name="wageRateId"
					onLazyLoad={lazyLoadWageRates}
					onValueChange={resetTimer}
					options={wageRateOptions}
					propName="wageRate"
					renderMenuItem={renderLaborPlaceholderOptionItem}
					valueKey="id"
					withCaret={true}
				/>
				<FieldArray<MultiTagSelectProps<SkillViewModel>>
					component={MultiTagSelect}
					fixed={true}
					getOptionLabel={getSkillOptionLabel}
					getOptionValue={getSkillOptionValue}
					label="Skills"
					MultiValueLabel={SkillMultiValueLabel}
					name="skills"
					Option={SkillOption}
					options={skillOptions}
					placeholder="Choose skills"
				/>
			</CustomModal.Body>
			<CustomModal.Footer>
				<Button
					onClick={onClose}
					variant="info"
				>
					Cancel
				</Button>
				<SubmitButton
					disabled={invalid}
					label="Save Placeholder"
					onClick={handleSubmit(onSubmit)}
					reduxFormSubmitting={submitting}
					submitKey={LABOR_PLACEHOLDER_FORM}
				/>
			</CustomModal.Footer>
		</>
	);
};

function mapDispatchToProps() {
	return {
		findAllWageRatesForCompany: WageRateActions.findAllForCompany,
		findAllSkillsForCompany: SkillActions.findAllForCompany,
	};
}

const connector = connect(null, mapDispatchToProps());

const enhance = compose<React.ComponentClass<OwnProps>>(
	connector,
	reduxForm<CreateLaborPlaceholderForm, FormOwnProps>({ form: LABOR_PLACEHOLDER_FORM, validate }),
	React.memo
);

export default enhance(LaborPlaceholderForm);
