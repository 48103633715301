import type { FormErrors } from 'redux-form';

import type RejectReviewRM from 'ab-requestModels/workOrder/rejectReview';

import { WorkOrderReviewLevel } from 'acceligent-shared/enums/workOrderReviewStatus';

const _workOrderReviewLevels = Object.values(WorkOrderReviewLevel).filter((_enumValue) => !isNaN(+_enumValue));

export default function validate(values: RejectReviewRM) {
	const errors: FormErrors<RejectReviewRM, string> = {} as FormErrors<RejectReviewRM, string>;

	if (!_workOrderReviewLevels.includes(values.targetLevel)) {
		errors.targetLevel = 'Target level is required';
	}

	if (!values.rejectReason) {
		errors.rejectReason = 'Reject reason is required';
	}

	if (!values.assignedToIds || values.assignedToIds?.length === 0) {
		errors.assignedToIds = 'Please assign person responsible for fixing the report';
	}

	return errors;
}
