import * as React from 'react';
import { compose } from 'redux';
import type { HandleThunkActionCreator } from 'react-redux';
import type { InjectedFormProps} from 'redux-form';
import { reduxForm, Field } from 'redux-form';
import { Button, Col, Row } from 'react-bootstrap';

import type * as CompanyActions from 'af-actions/companies';

import Checkbox from 'af-fields/Checkbox';

import SubmitButton from 'af-components/SubmitButton';
import CustomModal from 'af-components/CustomModal';

import { TEMPORARY_LABOR_NOTIFICATIONS } from 'af-constants/reduxForms';

import CompanyFeatureFlagsEnum, { CompanyFeatureFlagsTips } from 'ab-enums/companyFeatureFlags.enum';

import { TemporaryLaborNotificationsForm } from '../formModel';

interface OwnProps {
	isVisible: boolean;
	toggleEdit: () => void;
	initialValues: TemporaryLaborNotificationsForm;
	editTemporaryLaborNotifications: HandleThunkActionCreator<typeof CompanyActions.editTemporaryLaborNotifications>;
}

type Props = OwnProps & InjectedFormProps<TemporaryLaborNotificationsForm, OwnProps>;

const TemporaryLaborNotificationsEdit: React.FC<Props> = (props) => {
	const { toggleEdit, editTemporaryLaborNotifications, reset, handleSubmit, isVisible, invalid, submitting, initialize, initialValues } = props;

	React.useEffect(() => {
		if (isVisible) {
			initialize(initialValues);
		}
		// eslint-disable-next-line react-hooks/exhaustive-deps
	}, [isVisible, initialValues]);

	const edit = async (form: TemporaryLaborNotificationsForm) => {
		await editTemporaryLaborNotifications(TemporaryLaborNotificationsForm.toRequestModel(form));
		toggleEdit();
	};

	const onClose = React.useCallback(() => {
		reset();
		toggleEdit();
	}, [reset, toggleEdit]);

	return (
		<CustomModal
			closeModal={onClose}
			modalStyle="info"
			showModal={isVisible}
			size="lg"
		>
			<CustomModal.Header
				closeModal={onClose}
				title="Edit Automatic Notifications"
			/>
			<CustomModal.Body padding="none">
				<Row className="row--padded-top row--padded-bottom">
					<Col sm={24}>
						<Field
							component={Checkbox}
							inline={true}
							isStandalone={true}
							label={CompanyFeatureFlagsEnum.notifyTemporaryLabor}
							labelId="accqa__notification-settings__temporary-labor__notify"
							name="notifyTemporaryLabor"
							tooltipMessage={CompanyFeatureFlagsTips.notifyTemporaryLabor}
							type="text"
						/>
					</Col>
				</Row>
			</CustomModal.Body>
			<CustomModal.Footer>
				<Button
					id="accqa__notification-settings__temporary-labor__cancel"
					onClick={onClose}
					variant="info"
				>
					Cancel
				</Button>
				<SubmitButton
					disabled={invalid}
					id="accqa__notification-settings__temporary-labor__save"
					label="Save"
					onClick={handleSubmit(edit)}
					reduxFormSubmitting={submitting}
				/>
			</CustomModal.Footer>
		</CustomModal>
	);
};

const enhance = compose<React.ComponentType<OwnProps>>(
	React.memo,
	reduxForm<TemporaryLaborNotificationsForm, OwnProps>({ form: TEMPORARY_LABOR_NOTIFICATIONS })
);

export default enhance(TemporaryLaborNotificationsEdit);
