import * as React from 'react';

import BlobStorageImageSizeContainer from 'acceligent-shared/enums/blobStorageImageSizeContainer';

import UI_LABELS from 'af-constants/uiLabels';

import type { CompanyViewModel } from 'ab-viewModels/company.viewModel';

import ImageTag from 'af-components/Image';

import SettingPreview from '../../Shared/SettingPreview';

interface Props {
	toggleEdit: () => void;
	company: CompanyViewModel;
}

export default class CompanyPreview extends React.Component<Props> {
	render() {
		const { toggleEdit, company } = this.props;

		return (
			<SettingPreview toggleEdit={toggleEdit}>
				<div className="company-settings__image-segment">
					{company.imageUrl ?
						<ImageTag
							className="company-settings__image"
							minSize={BlobStorageImageSizeContainer.SIZE_200X200}
							src={company.imageUrl}
							tryOriginal={true}
							tryRoot={true}
						/> :
						<div className="company-settings__image company-settings__image--default ">
							{company.name?.[0].toUpperCase()}
						</div>
					}
				</div>
				<div className="company-settings__info-segment">
					<div className="company-settings__graphic-bullet">
						<span className="icon-work" />
						<strong>{company.name}</strong>
					</div>
					<div className="company-settings__graphic-bullet">
						<span className="icon-location_pin" />
						<span>{company.address?.street}</span>
					</div>
					<div className="company-settings__graphic-bullet">
						<span className="icon-globe" />
						<span>{company.timezone}</span>
					</div>
					{
						company.website &&
						<div className="company-settings__graphic-bullet">
							<span className="icon-external" />
							<span>{company.website}</span>
						</div>
					}
					<div className="company-settings__graphic-bullet">
						<span className="icon-cost" />
						<span>{UI_LABELS.MONTH[company.fiscalYearStartMonth]} 1st</span>
					</div>
				</div>
			</SettingPreview>
		);
	}
}
