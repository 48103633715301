import * as React from 'react';

import { renderWorkOrderInfoData } from 'af-root/scenes/Company/ScheduleBoard/Shared/OrderInfoModal';

import type WorkOrderModalViewModel from 'ab-viewModels/scheduleBoardWorkOrderModal.viewModel';

interface Props {
	workOrderDataToShow: WorkOrderModalViewModel;
	currentWorkOrderData: WorkOrderModalViewModel;
}

class CurrentStatusSection extends React.PureComponent<Props> {
	render() {
		const { workOrderDataToShow, currentWorkOrderData } = this.props;

		return (
			<div className="work-order-info-modal__general-info">
				<div className="work-order-info-modal__general-info-left">
					<div className="work-order-info-modal__important-info">
						{renderWorkOrderInfoData(workOrderDataToShow?.customerName, currentWorkOrderData?.customerName)}
					</div>
					<div className="work-order-info-modal__important-info">
						{renderWorkOrderInfoData(workOrderDataToShow?.title, currentWorkOrderData?.title)}
					</div>
					<div className="work-order-info-modal__important-info">
						{renderWorkOrderInfoData(workOrderDataToShow?.workLocationAddress, currentWorkOrderData?.workLocationAddress)}
					</div>
				</div>
				<div className="work-order-info-modal__general-info-right">
					<div className="work-order-info-modal__important-info">
						{renderWorkOrderInfoData(workOrderDataToShow?.officeNickname ?? '-', currentWorkOrderData?.officeNickname ?? '-')}
					</div>
					<div className="work-order-info-modal__important-info">
						{renderWorkOrderInfoData(workOrderDataToShow?.divisionName ?? '-', currentWorkOrderData?.divisionName ?? '-')}
					</div>
					<div className="work-order-info-modal__info">
						Superintendent:
						<strong>
							{renderWorkOrderInfoData(workOrderDataToShow?.supervisor?.fullName ?? null, currentWorkOrderData?.supervisor?.fullName ?? null)}
						</strong>
					</div>
					{(workOrderDataToShow?.projectManager ?? currentWorkOrderData?.projectManager) &&
						<div className="work-order-info-modal__info">
							Project manager:
							<strong>
								{renderWorkOrderInfoData(
									workOrderDataToShow?.projectManager?.fullName ?? null,
									currentWorkOrderData?.projectManager?.fullName ?? null
								)}
							</strong>
						</div>
					}
				</div>
			</div>
		);
	}
}

export default CurrentStatusSection;
