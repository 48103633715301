import * as React from 'react';
import type { Cell} from '@tanstack/react-table';
import { flexRender } from '@tanstack/react-table';

import styles from './styles.module.scss';

type Props<T> = {
	cell: Cell<T, unknown>;
};

function BaseCell<T,>(props: React.PropsWithChildren<Props<T>>) {
	const { cell } = props;
	return (
		<span className={styles['simple-table__cell']}>
			{flexRender(cell.column.columnDef.cell, cell.getContext())}
		</span>
	);
}

export default React.memo(BaseCell) as typeof BaseCell;
