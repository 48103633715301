import * as React from 'react';
import type { ConnectedProps } from 'react-redux';
import { connect } from 'react-redux';
import { compose } from 'redux';

import type { RootState } from 'af-reducers';

import type { LaborStatisticsPerLocationParsed, IndividualLaborStatisticsParsed } from 'ab-viewModels/scheduleBoardLaborStatistics.viewModel';

import IndividualLaborStatistics from 'af-components/LaborStatistics/IndividualLaborStatistics';

import * as ScheduleBoardUtil from 'af-utils/scheduleBoard.util';

interface OwnProps {
	dueDate: string;
}

type Props = OwnProps & ConnectedProps<typeof connector>;

type State = {
	laborStatisticsPerLocation: Nullable<LaborStatisticsPerLocationParsed>;
	totalLaborStatistics: Nullable<IndividualLaborStatisticsParsed>;
};

class OfficeInfo extends React.PureComponent<Props> {

	state: State = {
		laborStatisticsPerLocation: null,
		totalLaborStatistics: null,
	};

	static getDerivedStateFromProps(props: Props): State | undefined {
		const { laborStatistics } = props;

		if (laborStatistics) {
			const _statistics = ScheduleBoardUtil.ignoreUndefinedLocation(laborStatistics);
			if (!_statistics) {
				throw new Error('Locations not provided');
			}

			const { laborStatisticsPerLocation, totalLaborStatistics } = _statistics;
			return {
				laborStatisticsPerLocation: laborStatisticsPerLocation ?? null,
				totalLaborStatistics: totalLaborStatistics ?? null,
			};
		}
	}

	renderLocation = (locationNickname: string) => {
		const { laborStatisticsPerLocation } = this.state;
		const locationStatistics = laborStatisticsPerLocation?.[locationNickname];

		return locationStatistics?.hidden
			? null
			: (
				<IndividualLaborStatistics
					key={locationNickname}
					label={locationNickname}
					laborStatistics={locationStatistics}
				/>
			);
	};

	render() {
		const { laborStatisticsPerLocation, totalLaborStatistics } = this.state;

		return (
			<>
				{Object.keys(laborStatisticsPerLocation ?? []).map(this.renderLocation)}
				{
					totalLaborStatistics &&
					<IndividualLaborStatistics
						label="Total"
						laborStatistics={totalLaborStatistics}
					/>
				}
			</>
		);
	}
}

function mapStateToProps(state: RootState, ownProps: OwnProps) {
	const { dueDate } = ownProps;

	return {
		laborStatistics: state.scheduleBoard?.workOrdersByDateDictionary?.[dueDate]?.laborStatistics,
	};
}

const connector = connect(mapStateToProps);

const enhance = compose<React.ComponentClass<OwnProps>>(
	connector
);

export default enhance(OfficeInfo);
