import * as React from 'react';

import type { ExtendedColorValue } from 'acceligent-shared/enums/color';

import ConfirmationModal from 'af-components/ConfirmationModal';
import ColorSquare from 'af-components/ColorSquare';
import Tooltip from 'af-components/Tooltip';

interface OwnProps {
	labelKey: string;
	item: Metadata;
	isLocked?: boolean;
	isTransparent?: boolean;
	skipConfirmationModal?: boolean;
	modalTitle?: string;
	index?: number;
	color?: Nullable<ExtendedColorValue>;
	modalBody?: (original: Metadata) => JSX.Element;
	removeItem?: (index?: number) => void;
	onClick?: () => void;
}

interface State {
	showModal: boolean;
}

type Props = OwnProps;

class Pill extends React.PureComponent<Props, State> {

	static defaultProps: Partial<Props> = {
		skipConfirmationModal: false,
		isLocked: false,
		isTransparent: false,
	};

	state: State = {
		showModal: false,
	};

	hideModal = () => this.setState(() => ({ showModal: false }));

	openModal = () => this.setState(() => ({ showModal: true }));

	removeItem = () => {
		const { removeItem, index } = this.props;
		removeItem?.(index);
	};

	render() {
		const {
			isTransparent,
			onClick,
			labelKey,
			skipConfirmationModal,
			item,
			modalTitle,
			modalBody,
			color,
			isLocked,
			removeItem,
		} = this.props;
		const { showModal } = this.state;

		let className = 'pills__item';
		className = isTransparent ? `${className} pills__item--transparent` : className;
		className = onClick ? `${className} pills__item--clickable` : className;

		return (
			<span className={className}>
				<span className="pills__label" onClick={onClick}>
					{color && <ColorSquare color={color} />}
					{item[labelKey]}
				</span>
				{
					!isLocked && removeItem &&
					<Tooltip message="Delete">
						<span
							className="icon-close"
							onClick={skipConfirmationModal ? this.removeItem : this.openModal}
						/>
					</Tooltip>
				}
				{
					!skipConfirmationModal && !isLocked &&
					<ConfirmationModal
						body={modalBody?.(item)}
						closeModal={this.hideModal}
						confirmAction={this.removeItem}
						confirmText="Remove"
						modalStyle="danger"
						showModal={showModal}
						title={modalTitle}
					/>
				}
			</span>
		);
	}
}

export default Pill;
