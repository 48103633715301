import * as React from 'react';

import SignatureModal from '../../Shared/SignatureModal';

import type SignatureForm from '../../Shared/SignatureModal/FormModel';

interface OwnProps {
	showModal: boolean;
	closeModal: () => void;
	submit: (form: SignatureForm) => Promise<void>;
}

type Props = OwnProps;

class SignTimeSheetModal extends React.PureComponent<Props> {

	closeModal = () => {
		const { closeModal } = this.props;
		closeModal();
	};

	render() {
		const { submit, showModal } = this.props;

		return (
			showModal &&
			<SignatureModal
				closeModal={this.closeModal}
				description="Submitting the time sheet requires your signature."
				onSubmit={submit}
				showModal={showModal}
				showNameInput={false}
				title="Sign Time Sheet"
			/>
		);
	}
}

export default SignTimeSheetModal as React.ComponentClass<OwnProps>;
