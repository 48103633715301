import { AdditionalColors } from 'acceligent-shared/enums/color';
import TimeFormat from 'acceligent-shared/enums/timeFormat';
import WorkOrderStatusEnum from 'acceligent-shared/enums/workOrderStatus';
import NotificationStatusEnum from 'acceligent-shared/enums/notificationStatus';

import * as ScheduleBoardSharedUtils from 'ab-utils/scheduleBoard.util';
import * as TimeUtils from 'acceligent-shared/utils/time';

import type EmployeeBase from 'ab-domain/models/employee/base';
import type EmployeeStatusBase from 'ab-domain/models/employeeStatus/base';
import type DailyEmployeeStatusBase from 'ab-domain/models/dailyEmployeeStatus/base';
import type WorkOrderResourceLookupBase from 'ab-domain/models/workOrderResourceLookup/base';
import type DailyEquipmentStatusBase from 'ab-domain/models/dailyEquipmentStatus/base';
import type EquipmentBase from 'ab-domain/models/equipment/base';

import {
	UNKNOWN_LOCATION_NICKNAME,
	DEFAULT_EQUIPMENT_PLACEHOLDER,
	DEFAULT_LABOR_PLACEHOLDER,
	REVISION_ALPHABET,
	FIRST_REVISION_BACKGROUND_COLOR_CLASS,
	OTHER_REVISION_BACKGROUND_COLOR_CLASS,
} from 'ab-common/constants/value';
import { AVAILABLE_EMPLOYEE_STATUS } from 'ab-common/constants/employee';
import { SCHEDULE_BOARD_MAX_ITEMS_IN_ROW, TOOLBAR_GROUP_DEFAULT_ID, SCHEDULE_BOARD_TOOLBAR_UNAVAILABLE, SCHEDULE_BOARD_TOOLBAR_AVAILABLE, WORK_ORDERS_PER_ZOOM_LEVEL } from 'ab-common/constants/scheduleBoard';

import EmployeeNotificationStatus from 'ab-enums/employeeNotificationStatus.enum';
import ScheduleBoardContext from 'ab-enums/scheduleBoardContext.enum';
import ScheduleBoardProperty from 'ab-enums/scheduleBoardProperty.enum';

import type { ScheduleBoardWorkOrdersViewModel, BlankWorkOrder, PlaceholderWorkOrder, ScheduleBoardColumnPlaceHolder } from 'ab-socketModels/viewModels/scheduleBoard/scheduleBoardWorkOrder.viewModel';
import type ScheduleBoardWorkOrderViewModel from 'ab-socketModels/viewModels/scheduleBoard/scheduleBoardWorkOrder.viewModel';
import type { ColumnNumbersForWorkOrder, LocationViewModel, WorkOrdersRowDistribution } from 'ab-viewModels/scheduleBoard.viewModel';
import type { ScheduleBoardWorkOrderResourceLookupsViewModel } from 'ab-socketModels/viewModels/scheduleBoard/scheduleBoardResourceLookup.viewModel';
import type { ScheduleBoardEmployeesViewModel } from 'ab-viewModels/scheduleBoardEmployee.viewModel';
import type ScheduleBoardEmployee from 'ab-viewModels/scheduleBoardEmployee.viewModel';
import type {
	EmployeeInfo, LaborStatisticsPerLocationUnparsed, LaborStatisticsPerLocationParsed,
	IndividualLaborStatisticsPerLocationUnparsed, IndividualLaborStatisticsParsed,
} from 'ab-viewModels/scheduleBoardLaborStatistics.viewModel';
import type ScheduleBoardLaborStatistics from 'ab-viewModels/scheduleBoardLaborStatistics.viewModel';
import type * as ScheduleBoardEmailLaborStatisticsModels from 'ab-viewModels/scheduleBoardEmailLaborStatistics.viewModel';
import type ScheduleBoardEmailLaborStatistics from 'ab-viewModels/scheduleBoardEmailLaborStatistics.viewModel';
import type { ScheduleBoardWorkOrder } from 'ab-viewModels/scheduleBoardEmailNotification.viewModel';
import { isEmployeeModel } from 'ab-viewModels/scheduleBoardEmailNotification.viewModel';
import type WorkOrderResourceLookupViewModel from 'ab-viewModels/workOrderResourceLookup.viewModel';
import type { NotificationStatusByEmployee } from 'ab-viewModels/notification.viewModel';
import type * as WorkOrderUpsertVM from 'ab-viewModels/workOrder/workOrderUpsert.viewModel';
import type { EmployeeModel } from 'ab-viewModels/scheduleBoardTemplate.viewModel';
import type { IndexByWorkOrderCodeDict } from 'ab-socketModels/viewModels/scheduleBoard/reorderWorkOrders.viewModel';

import { fraction, round } from 'ab-utils/number.util';
import { getColorTextClass } from 'ab-utils/color.util';

const scheduleBoardContextJoined = Object.keys(ScheduleBoardContext).join('|');
const DUE_DATE_FORMAT = '(\\d{2}\\-\\d{2}\\-\\d{4})'; // MM-DD-YYYY
const DROPPABLE_ID_REGEX = new RegExp(`((${scheduleBoardContextJoined})#(${Object.keys(ScheduleBoardProperty).join('|')})#${DUE_DATE_FORMAT}#([^#]+))(#([0-9]))?`);
const EQUIPMENT_REGEX = new RegExp(`(${scheduleBoardContextJoined})#(${ScheduleBoardProperty.EQUIPMENT})#${DUE_DATE_FORMAT}#\\w+`);
const EMPLOYEES_REGEX = new RegExp(`(${scheduleBoardContextJoined})#(${ScheduleBoardProperty.EMPLOYEE})#${DUE_DATE_FORMAT}#\\w+`);
const DEFAULT_LABOR_PLACEHOLDER_REGEX = new RegExp(`(${scheduleBoardContextJoined})#(${ScheduleBoardProperty.RESOURCE})#${DUE_DATE_FORMAT}#${DEFAULT_LABOR_PLACEHOLDER}`);
const DEFAULT_EQUIPMENT_PLACEHOLDER_REGEX = new RegExp(`(${scheduleBoardContextJoined})#(${ScheduleBoardProperty.RESOURCE})#${DUE_DATE_FORMAT}#${DEFAULT_EQUIPMENT_PLACEHOLDER}`);
const RESOURCE_REGEX = new RegExp(`(${scheduleBoardContextJoined})#(${ScheduleBoardProperty.RESOURCE})#${DUE_DATE_FORMAT}#\\w+`);
const WORK_ORDERS_REGEX = new RegExp(`(${ScheduleBoardContext.BOARD})#(${ScheduleBoardProperty.WORK_ORDER})#${DUE_DATE_FORMAT}#\\w+`);
const BLANK_WORK_ORDER_REGEX = new RegExp(`(${ScheduleBoardContext.BOARD})#(${ScheduleBoardProperty.WORK_ORDER})#${DUE_DATE_FORMAT}#BLANK#\\d+`);
const LOADING_PLACEHOLDER_REGEX = new RegExp(`(${ScheduleBoardContext.BOARD})#(${Object.keys(ScheduleBoardProperty).join('|')})#${DUE_DATE_FORMAT}#LOADING_PLACEHOLDER`);

const AVAILABILITY_DIVIDER = '@@@';

const TOOLBAR_UNIQUE_CODE_REGEX = new RegExp(`(${SCHEDULE_BOARD_TOOLBAR_AVAILABLE}|${SCHEDULE_BOARD_TOOLBAR_UNAVAILABLE})${AVAILABILITY_DIVIDER}(-?\\d+)`);

const _getWorkOrderOrdering = (workOrdersIndexDict: IndexByWorkOrderCodeDict) => {
	return Object.keys(workOrdersIndexDict)
		.map((workOrderCode) => ({ workOrderCode, index: workOrdersIndexDict[workOrderCode] }))
		.sort((wo1, wo2) => wo1.index - wo2.index)
		.map(({ workOrderCode }) => workOrderCode);
};

// {context}#{property}#{dueDate}#{uniqueCode}(#{columnNumber})?
// TOOLBAR context needs to have prefix set to 'available-' or 'unavailable-'
export const generateDroppableId = (
	context: ScheduleBoardContext,
	property: ScheduleBoardProperty,
	dueDate: string,
	uniqueCode: string,
	columnNumber?: number,
	isDroppableAvailable?: boolean
) => {
	const availability = isDroppableAvailable ? SCHEDULE_BOARD_TOOLBAR_AVAILABLE : SCHEDULE_BOARD_TOOLBAR_UNAVAILABLE;
	const code = context === ScheduleBoardContext.TOOLBAR ? `${availability}${AVAILABILITY_DIVIDER}${uniqueCode}` : uniqueCode;
	return `${context}#${property}#${dueDate}#${code}${columnNumber ? `#${columnNumber}` : ''}`;
};

// droppableId of 'board#employees#01-01-2019#JW-1234#2' is 'board#employees#01-01-2019#JW-1234'
// #2 is trimmed
export const getDroppableId = (droppableId: string) => {
	// e.g. droppableId='board#employees#01-01-2019#JW-1234'
	// 0=all, 1=board#employees#01-01-2019#JW-1234, 2=board, 3=employee, 4=01-01-2019, 5=JW-1234
	const match = droppableId.match(DROPPABLE_ID_REGEX);
	return match?.[1];
};

// context = board | toolbar
export const getContextFromDroppableId = (droppableId: string) => {
	// e.g. droppableId='board#employees#01-01-2019#JW-1234'
	// 0=all, 1=board#employees#01-01-2019#JW-1234, 2=board, 3=employee, 4=01-01-2019, 5=JW-1234
	const match = droppableId.match(DROPPABLE_ID_REGEX);
	return match?.[2];
};

// property = equipment | employee
export const getPropertyFromDroppableId = (droppableId: string): Nullable<ScheduleBoardProperty> => {
	// e.g. droppableId='board#employees#01-01-2019#JW-1234'
	// 0=all, 1=board#employees#01-01-2019#JW-1234, 2=board, 3=employee, 4=01-01-2019, 5=JW-1234
	const match = droppableId.match(DROPPABLE_ID_REGEX);
	return match && (match[3] as ScheduleBoardProperty);
};

// unique code = work order code | employee id | equipment id | just string
export const getDueDateFromDroppableId = (droppableId: string) => {
	// e.g. droppableId='board#employees#01-01-2019#JW-1234'
	// 0=all, 1=board#employees#01-01-2019#JW-1234, 2=board, 3=employee, 4=01-01-2019, 5=JW-1234
	const match = droppableId.match(DROPPABLE_ID_REGEX);
	return match?.[4];
};

// unique code = work order code | employee id | equipment id | just string
export const getUniqueCodeFromDroppableId = (droppableId: string) => {
	// e.g. droppableId='board#employees#01-01-2019#JW-1234'
	// 0=all, 1=board#employees#01-01-2019#JW-1234, 2=board, 3=employee, 4=01-01-2019, 5=JW-1234
	const match = droppableId.match(DROPPABLE_ID_REGEX);
	return match?.[5];
};

export const getColumnIndexFromDroppableId = (droppableId: string): number => {
	// e.g. droppableId='board#employees#JW-1234#2'
	// 0=all, 1=board#employees#JW-1234, 2=board, 3=employee, 4=01-01-2019, 5=JW-1234, 6=#2, 7=2
	const match = droppableId.match(DROPPABLE_ID_REGEX);
	return match?.[7]
		? parseInt(match[7], 10) ?? 0
		: 0;
};

export const getToolbarAvailabilityFromUniqueCode = (code: string) => {
	// e.g. droppableId='available@@@1'
	// 0=all, 1=available, 2=1
	const match = code.match(TOOLBAR_UNIQUE_CODE_REGEX);
	return match?.[1];
};

export const getToolbarCodeFromUniqueCode = (code: string) => {
	// e.g. droppableId='available@@@1'
	// 0=all, 1=available, 2=1
	const match = code.match(TOOLBAR_UNIQUE_CODE_REGEX);
	return match?.[2];
};

export const generateBlankWorkOrderId = (dueDate: string, index: number): string => {
	return `${ScheduleBoardContext.BOARD}#${ScheduleBoardProperty.WORK_ORDER}#${dueDate}#BLANK#${index}`;
};

export const generateLoadingPlaceholderDroppableId = (dueDate: string, property: ScheduleBoardProperty): string => {
	return `${ScheduleBoardContext.BOARD}#${property}#${dueDate}#LOADING_PLACEHOLDER`;
};

export const isBlankWorkOrderId = (droppableId: string = '') => {
	return BLANK_WORK_ORDER_REGEX.test(droppableId);
};

export const isLoadingPlaceholderDroppableId = (droppableId: string): boolean => {
	return LOADING_PLACEHOLDER_REGEX.test(droppableId);
};

// droppableId can have #2 meaning it's a second column in card (when more than 8 items in column)
export const isSourceEqualToDestination = (sourceDroppableId: string, destinationDroppableId: string) => {
	const srcDroppableId = getDroppableId(sourceDroppableId);
	const destDroppableId = getDroppableId(destinationDroppableId || '');
	return srcDroppableId === destDroppableId;
};

export const isInToolbar = (droppableId: string) => {
	if (!droppableId) {
		return false;
	}
	return getContextFromDroppableId(droppableId) === ScheduleBoardContext.TOOLBAR;
};

export const isOnBoard = (droppableId: string) => {
	if (!droppableId) {
		return false;
	}
	return getContextFromDroppableId(droppableId) === ScheduleBoardContext.BOARD;
};

export const isDefaultLaborPlaceholder = (droppableId: string) => DEFAULT_LABOR_PLACEHOLDER_REGEX.test(droppableId);

export const isDefaultEquipmentPlaceholder = (droppableId: string) => DEFAULT_EQUIPMENT_PLACEHOLDER_REGEX.test(droppableId);

export const isEquipment = (droppableId: string) => EQUIPMENT_REGEX.test(droppableId);

export const isEmployee = (droppableId: string) => EMPLOYEES_REGEX.test(droppableId);

export const isResource = (droppableId: string) => RESOURCE_REGEX.test(droppableId);

export const isWorkOrder = (droppableId: string) => WORK_ORDERS_REGEX.test(droppableId);

export const isAvailable = (available: string) => available === SCHEDULE_BOARD_TOOLBAR_AVAILABLE;

export const getAvailabilityLabel = (available: boolean) => available ? SCHEDULE_BOARD_TOOLBAR_AVAILABLE : SCHEDULE_BOARD_TOOLBAR_UNAVAILABLE;

export const canDrop = (sourceDroppableId: string, destinationDroppableId: string) => {
	const sourceProperty = getPropertyFromDroppableId(sourceDroppableId);
	const destinationProperty = getPropertyFromDroppableId(destinationDroppableId);
	return sourceProperty === destinationProperty
		|| (
			(sourceProperty && [ScheduleBoardProperty.EMPLOYEE, ScheduleBoardProperty.EQUIPMENT].includes(sourceProperty))
			&& destinationProperty === ScheduleBoardProperty.RESOURCE
		);
};

/**
 * Returns dictionary with `workOrderCode` as key and index as value.
 *
 * @param workOrders - list of work orders
 */
export function getWorkOrderIndexDict<T extends { index: Nullable<number>; }>(
	workOrders: T[],
	getKey: (_wo: T) => string | number
): IndexByWorkOrderCodeDict {
	return workOrders.reduce((_acc: IndexByWorkOrderCodeDict, _wo: T) => {
		const key = getKey(_wo);
		return Object.assign(_acc, { [key]: _wo.index });
	}, {});
}

/**
 * Adds blanks between neighbor WO codes with index difference greater than 1.
 */
export function addBlankWorkOrders(
	workOrderIndexDict: IndexByWorkOrderCodeDict,
	dueDate: string
): string[] {
	const workOrdersOrdering = _getWorkOrderOrdering(workOrderIndexDict);
	return workOrdersOrdering.reduce(
		(acc, workOrderCode) => {
			const workOrderIndex = workOrderIndexDict[workOrderCode];
			if (acc.length === 0) {
				if (workOrderIndex > 1) {
					Array(workOrderIndex - 1).fill(1).forEach((_, _index) => acc.push(generateBlankWorkOrderId(dueDate, _index)));
				}
			} else {
				const lastWoCode = acc[acc.length - 1];
				const lastIndex = workOrderIndexDict[lastWoCode];
				const diff = workOrderIndex - lastIndex - 1;
				const currentLength = acc.length;
				if (diff > 0) {
					Array(diff).fill(1).forEach((_, _index) => acc.push(generateBlankWorkOrderId(dueDate, currentLength + _index)));
				}
			}
			return [...acc, workOrderCode];
		}, [] as string[]);
}

interface RowDistributionData {
	columnNumbersDict: ColumnNumbersForWorkOrder;
	workOrdersRowDistribution: WorkOrdersRowDistribution;
}

const _isBlankWorkOrder = (wo: ScheduleBoardColumnPlaceHolder): wo is BlankWorkOrder => {
	return (wo as BlankWorkOrder).isBlank;
};

const _isPlaceholderWorkOrder = (wo: ScheduleBoardColumnPlaceHolder): wo is PlaceholderWorkOrder => {
	return (wo as PlaceholderWorkOrder).isPlaceholder;
};

export const getWorkOrdersPerRowDistribution = (
	workOrders: ScheduleBoardColumnPlaceHolder[],
	zoomLevel: number,
	date: string
): RowDistributionData => {
	const columnNumberForWorkOrder: ColumnNumbersForWorkOrder = workOrders.reduce(
		(acc: ColumnNumbersForWorkOrder, wo: ScheduleBoardColumnPlaceHolder, index: number) => {
			if (!wo) {
				return acc;
			}
			if (_isBlankWorkOrder(wo)) {
				return { ...acc, [generateBlankWorkOrderId(date, index)]: 1 };
			}
			if (_isPlaceholderWorkOrder(wo)) {
				return { ...acc, [generateLoadingPlaceholderDroppableId(date, ScheduleBoardProperty.WORK_ORDER)]: 1 };
			}
			const workOrder: ScheduleBoardWorkOrderViewModel = wo as ScheduleBoardWorkOrderViewModel;
			const columnNumber = getColumnNumberForWorkOrder(workOrder);
			return { ...acc, [workOrder.code]: columnNumber };
		},
		{} as ColumnNumbersForWorkOrder
	);
	const columnsPerRow = WORK_ORDERS_PER_ZOOM_LEVEL[zoomLevel];

	const workOrdersRowDistribution = Object.entries(columnNumberForWorkOrder).reduce((acc, [woCode, columnNumber]) => {
		const columnsInCurrentRow = acc.currentRowColumns + columnNumber;
		if (columnsInCurrentRow > columnsPerRow) {
			const nextRow = acc.row + 1;
			return { ...acc, row: nextRow, [nextRow]: [woCode], currentRowColumns: columnNumber };
		} else {
			return { ...acc, [acc.row]: [...(acc[acc.row] || []), woCode], currentRowColumns: columnsInCurrentRow };
		}
	}, { row: 0, currentRowColumns: 0 });

	return {
		columnNumbersDict: columnNumberForWorkOrder,
		workOrdersRowDistribution: Object.keys(workOrdersRowDistribution)
			.filter((_key) => !['row', 'currentRowColumns'].includes(_key))
			.map((_key) => workOrdersRowDistribution[_key]),
	};
};

export const getColumnNumberForWorkOrder = (workOrder: ScheduleBoardWorkOrderViewModel): number => {
	const workOrderResourceLookups = workOrder?.workOrderResourceLookups ?? [];
	const resourceLookupColumns = Math.ceil(workOrderResourceLookups.length / SCHEDULE_BOARD_MAX_ITEMS_IN_ROW);
	return Math.max(1, resourceLookupColumns);
};

/**
 * @param dueDate MM-DD-YYYY
 */
export const getEmployeeStatusForDay = (
	employee: EmployeeBase,
	dueDate: string,
	defaultValue: Partial<EmployeeStatusBase> = { name: AVAILABLE_EMPLOYEE_STATUS, available: true, id: TOOLBAR_GROUP_DEFAULT_ID }
): Partial<EmployeeStatusBase> => {
	const dueDateParsed = TimeUtils.toDatabaseDateOnly(dueDate, TimeFormat.DATE_ONLY);
	if (!dueDateParsed) {
		throw new Error('Due date in incorrect format');
	}

	const status = ScheduleBoardSharedUtils.getEmployeeDailyStatusForDay<EmployeeBase, DailyEmployeeStatusBase>(employee, dueDateParsed);
	return status?.employeeStatus ?? defaultValue;
};

export const getEmployeeStatusValidUntil = (employee: EmployeeBase, dueDate: string): Date | undefined => {
	if (!employee?.dailyEmployeeStatus) {
		return;
	}

	const targetDate: ReturnType<typeof TimeUtils.parseDateOnlyToMomentUtc> = TimeUtils.parseDateOnlyToMomentUtc(dueDate);
	const { validUntil } = employee.dailyEmployeeStatus.reduce((_acc, _des) => {
		const statusDate = TimeUtils.parseMoment(_des.date, TimeFormat.DB_DATE_ONLY);
		if (statusDate && targetDate && statusDate.isAfter(targetDate)) {
			if (!_acc.validUntil) {
				_acc.validUntil = statusDate;
			}
			if (statusDate.isBefore(_acc.validUntil)) {
				_acc.validUntil = statusDate;
			}
		}
		return _acc;
	}, {} as { validUntil: ReturnType<typeof TimeUtils.parseMoment>; });

	if (validUntil) {
		return validUntil.subtract(1, 'day').toDate();
	}
	return;
};

const _getInitLocationObject = (index: number | undefined = Infinity, hidden: boolean = false): IndividualLaborStatisticsPerLocationUnparsed => ({
	color: null,
	assignedLaborCount: {},
	totalLaborCount: 0,
	crews: {},
	totalRevenue: 0,
	index,
	hidden,
});

export const calculateLaborStatistics = (
	employees: Nullable<ScheduleBoardEmployeesViewModel>,
	workOrders: ScheduleBoardWorkOrdersViewModel,
	workOrderResourceLookups: ScheduleBoardWorkOrderResourceLookupsViewModel = {},
	locationsForCompany: LocationViewModel[] = []
): ScheduleBoardLaborStatistics => {
	const _locationsForCompany = locationsForCompany.filter((_loc: LocationViewModel) => !_loc.isDeleted);
	// task AP-2345 describes revenue section of labor statistics
	const initialLaborStatistics: LaborStatisticsPerLocationUnparsed = _locationsForCompany.reduce(
		(_acc, _location) => Object.assign(_acc, { [_location?.nickname]: _getInitLocationObject(_location?.index, !_location?.showInStatistics) }),
		{ [UNKNOWN_LOCATION_NICKNAME]: _getInitLocationObject() }
	);

	// get all nonCanceled work orders
	const allVisibleWorkOrders = Object.values(workOrders || {}).filter((_wo) => _wo.status !== WorkOrderStatusEnum.CANCELED && !_wo.isInternal);

	// helper function for getting employee objects from workOrderEmployeeId
	const getEmployee = (_resourceLookupId: number) => {
		const employeeId = workOrderResourceLookups?.[_resourceLookupId]?.employeeId;
		return (employeeId && employees)
			? employees[employeeId]
			: undefined;
	};

	const assignedLaborStatistics: LaborStatisticsPerLocationUnparsed = allVisibleWorkOrders
		.reduce((_acc: EmployeeInfo[], _wo: ScheduleBoardWorkOrderViewModel) => {
			// get needed information for each assigned employee in order to
			// group and count them for labor statistics
			const employeesWithoutSIandPM = _wo.workOrderResourceLookups?.filter((_resourceLookupId) => {
				const employee = getEmployee(_resourceLookupId);
				const isProjectManager = employee?.account?.assignableAsProjectManager;
				const isSuperintendent = employee?.account?.assignableAsSuperintendent;
				return !!employee && !isProjectManager && !isSuperintendent && !employee?.isDeleted;
			}) ?? [];
			const laborCount = employeesWithoutSIandPM.length;
			const workOrderRevenue = _wo.revenue ? +_wo.revenue || 0 : 0;
			const revenuePerPerson = laborCount ? (workOrderRevenue / laborCount) : 0;

			const employeeInfoList = employeesWithoutSIandPM.map((_resourceLookupId) => {
				const employee = getEmployee(_resourceLookupId);
				return ({
					officeNickname: employee?.office?.nickname ?? UNKNOWN_LOCATION_NICKNAME,
					officeColor: employee?.office?.color,
					employeeId: employee?.id,
					revenue: revenuePerPerson,
				} as EmployeeInfo);
			});
			_acc.push(...employeeInfoList);
			return _acc;
		}, [])
		// count assigned labor for each office
		.reduce((_acc: LaborStatisticsPerLocationUnparsed, _empInfo: EmployeeInfo) => {
			const { officeColor, officeNickname, employeeId, revenue } = _empInfo;
			if (!_acc[officeNickname]) {
				_acc[officeNickname] = _getInitLocationObject();
			}
			// make stats
			_acc[officeNickname].color = officeColor;
			_acc[officeNickname].totalRevenue += revenue;
			Object.assign(_acc[officeNickname].assignedLaborCount, { [employeeId]: true });
			return _acc;
		}, initialLaborStatistics);

	// count crews and revenue for each office
	const crewsLaborStatistics = allVisibleWorkOrders.reduce((_acc: LaborStatisticsPerLocationUnparsed, _wo: ScheduleBoardWorkOrderViewModel) => {
		const woOfficeNickname = _wo?.officeNickname ?? UNKNOWN_LOCATION_NICKNAME;
		if (!_acc[woOfficeNickname]) {
			_acc[woOfficeNickname] = _getInitLocationObject();
		}
		Object.assign(_acc[woOfficeNickname], { color: _wo?.officeColor });
		Object.assign(_acc[woOfficeNickname].crews, { [_wo.code]: true });
		const woWithoutFieldWorkers = !_wo.workOrderResourceLookups?.some((_resourceLookupId) => {
			const employee = getEmployee(_resourceLookupId);
			const isProjectManager = employee?.account?.assignableAsProjectManager;
			const isSuperintendent = employee?.account?.assignableAsSuperintendent;
			return !!employee && !isProjectManager && !isSuperintendent && !employee?.isDeleted;
		});
		if (woWithoutFieldWorkers && !!_wo.revenue) {
			_acc[woOfficeNickname].totalRevenue += +_wo.revenue;
		}
		return _acc;
	}, assignedLaborStatistics);

	// count total number of employees in each office that has assigned labor
	const laborStatisticsUnparsed: LaborStatisticsPerLocationUnparsed = Object.values(employees ?? {})
		.reduce((_acc: LaborStatisticsPerLocationUnparsed, _emp: ScheduleBoardEmployee) => {
			if (!!_emp.account && !_emp.account.assignableAsSuperintendent && !_emp.account.assignableAsProjectManager && !_emp.isDeleted) {
				const officeNickname = _emp?.office?.nickname ?? UNKNOWN_LOCATION_NICKNAME;
				if (!_acc[officeNickname]) {
					_acc[officeNickname] = _getInitLocationObject(_emp?.office?.index);
				}
				Object.assign(
					_acc[officeNickname],
					{
						color: _emp?.office?.color,
						totalLaborCount: (_acc?.[officeNickname]?.totalLaborCount ?? 0) + 1,
					}
				);
			}
			return _acc;
		}, crewsLaborStatistics);

	// sort offices by index
	const sortedNicknames = Object.keys(laborStatisticsUnparsed)
		.sort((_nickname1, _nickname2) => (laborStatisticsUnparsed[_nickname1]?.index ?? Infinity) - (laborStatisticsUnparsed[_nickname2]?.index ?? Infinity));

	// parse office statistics objects
	const sortedAndParsedLaborStatistics: LaborStatisticsPerLocationParsed = sortedNicknames.reduce(
		(_acc: LaborStatisticsPerLocationParsed, _officeNickname: string) => Object.assign(_acc, {
			[_officeNickname]: {
				color: laborStatisticsUnparsed[_officeNickname].color,
				assignedLaborCount: Object.keys(laborStatisticsUnparsed[_officeNickname].assignedLaborCount || {}).length,
				totalLaborCount: laborStatisticsUnparsed[_officeNickname].totalLaborCount,
				crewsCount: Object.keys(laborStatisticsUnparsed[_officeNickname].crews || {}).length,
				totalRevenue: laborStatisticsUnparsed[_officeNickname].totalRevenue,
				hidden: laborStatisticsUnparsed[_officeNickname].hidden,
			},
		}),
		{} as LaborStatisticsPerLocationParsed
	);

	// calculate total counts
	const totalLaborStatistics: IndividualLaborStatisticsParsed = Object.values(sortedAndParsedLaborStatistics).reduce(
		(_acc: IndividualLaborStatisticsParsed, _counts: IndividualLaborStatisticsParsed) => {
			_acc.crewsCount += _counts.crewsCount;
			_acc.assignedLaborCount += _counts.assignedLaborCount;
			_acc.totalLaborCount += _counts.totalLaborCount;
			_acc.totalRevenue += _counts.totalRevenue;
			return _acc;
		},
		{ color: AdditionalColors.GREY, crewsCount: 0, assignedLaborCount: 0, totalLaborCount: 0, totalRevenue: 0 } as IndividualLaborStatisticsParsed
	);

	return {
		laborStatisticsPerLocation: sortedAndParsedLaborStatistics,
		totalLaborStatistics,
	};
};

const _getInitLocationObjectForEmail = (
	officeNickname: string,
	index?: number,
	hidden: boolean = false
): ScheduleBoardEmailLaborStatisticsModels.IndividualLaborStatisticsPerLocationUnparsed => ({
	officeNickname,
	color: null,
	assignedLaborCount: {},
	totalLaborCount: 0,
	totalRevenue: 0,
	revenuePerPerson: 0,
	crews: {},
	index,
	hidden,
});

export const calculateLaborStatisticsForSBEmail = (
	employees: ScheduleBoardEmployeesViewModel,
	workOrders: ScheduleBoardWorkOrder[],
	locationsForCompany: LocationViewModel[] = [],
	showRevenue: boolean = false
): ScheduleBoardEmailLaborStatistics => {
	const _locationsForCompany = locationsForCompany.filter((_loc: LocationViewModel) => !_loc.isDeleted && _loc.showInStatistics);
	const initialLaborStatistics: ScheduleBoardEmailLaborStatisticsModels.LaborStatisticsPerLocationUnparsed = _locationsForCompany.reduce(
		(_acc, _location) => {
			return Object.assign(
				_acc,
				{
					[_location?.nickname]: _getInitLocationObjectForEmail(_location?.nickname, _location?.index, !_location?.showInStatistics),
				}
			);
		},
		{ [UNKNOWN_LOCATION_NICKNAME]: _getInitLocationObjectForEmail(UNKNOWN_LOCATION_NICKNAME) }
	);

	// get all nonCanceled work orders
	const allVisibleWorkOrders = workOrders.filter((_wo) => _wo.status !== WorkOrderStatusEnum.CANCELED && !_wo.isInternal);

	// helper function for getting employee objects from workOrderEmployeeId
	const getEmployee = (employeeId: number) => {
		return (employeeId && employees)
			? employees[employeeId]
			: undefined;
	};

	const assignedLaborStatistics: ScheduleBoardEmailLaborStatisticsModels.LaborStatisticsPerLocationUnparsed = allVisibleWorkOrders
		.reduce((_acc: ScheduleBoardEmailLaborStatisticsModels.EmployeeInfo[], _wo: ScheduleBoardWorkOrder) => {
			// get needed information for each assigned employee in order to
			// group and count them for labor statistics
			const employeesWithoutSuperintendents: EmployeeModel[] = (_wo.resources || []).filter(isEmployeeModel).filter((_resource): boolean => {
				const employee = getEmployee(_resource.employeeId);
				return !_resource.isProjectManager && !_resource.isSuperintendent && !employee?.isDeleted;
			});

			const laborCount = employeesWithoutSuperintendents.length;
			const workOrderRevenue = _wo.revenue ? +_wo.revenue || 0 : 0;
			const revenuePerPerson = laborCount ? (workOrderRevenue / laborCount) : 0;

			const employeeInfoList = employeesWithoutSuperintendents.map((_employee: EmployeeModel) => {
				const employee = getEmployee(_employee.employeeId);
				return ({
					officeNickname: employee?.office?.nickname ?? UNKNOWN_LOCATION_NICKNAME,
					officeColor: employee?.office?.color && getColorTextClass(employee.office.color),
					employeeId: employee?.id,
					revenue: revenuePerPerson,
				} as ScheduleBoardEmailLaborStatisticsModels.EmployeeInfo);
			});
			_acc.push(...employeeInfoList);
			return _acc;
		}, [])
		// count assigned labor for each office
		.reduce((_acc: ScheduleBoardEmailLaborStatisticsModels.LaborStatisticsPerLocationUnparsed, _empInfo: EmployeeInfo) => {
			const { officeColor, officeNickname, employeeId, revenue } = _empInfo;
			if (!_acc[officeNickname]) {
				_acc[officeNickname] = _getInitLocationObjectForEmail(officeNickname);
			}
			// make stats
			_acc[officeNickname].color = officeColor;
			_acc[officeNickname].totalRevenue += revenue;
			Object.assign(_acc[officeNickname].assignedLaborCount, { [employeeId]: true });
			return _acc;
		}, initialLaborStatistics);

	// count crews and revenue for each office
	const crewsLaborStatistics = allVisibleWorkOrders.reduce(
		(_acc: ScheduleBoardEmailLaborStatisticsModels.LaborStatisticsPerLocationUnparsed, _wo: ScheduleBoardWorkOrder) => {
			const woOfficeNickname = _wo?.officeNickname ?? UNKNOWN_LOCATION_NICKNAME;
			if (!_acc[woOfficeNickname]) {
				_acc[woOfficeNickname] = _getInitLocationObjectForEmail(woOfficeNickname);
			}
			Object.assign(_acc[woOfficeNickname], { color: getColorTextClass(_wo?.officeColor) });
			Object.assign(_acc[woOfficeNickname].crews, { [_wo.code]: true });
			return _acc;
		},
		assignedLaborStatistics
	);

	// count total number of employees in each office that has assigned labor
	const laborStatisticsUnparsed: ScheduleBoardEmailLaborStatisticsModels.LaborStatisticsPerLocationUnparsed = Object.values(employees || {})
		.reduce((_acc: ScheduleBoardEmailLaborStatisticsModels.LaborStatisticsPerLocationUnparsed, _emp: ScheduleBoardEmployee) => {
			if (!_emp.account.assignableAsSuperintendent && !_emp.account.assignableAsProjectManager && !_emp.isDeleted) {
				const officeNickname = _emp?.office?.nickname ?? UNKNOWN_LOCATION_NICKNAME;
				if (!_acc[officeNickname]) {
					_acc[officeNickname] = _getInitLocationObjectForEmail(officeNickname, _emp?.office?.index);
				}
				Object.assign(
					_acc[officeNickname],
					{
						color: _emp?.office?.color && getColorTextClass(_emp.office.color),
						totalLaborCount: (_acc?.[officeNickname]?.totalLaborCount ?? 0) + 1,
					}
				);
			}
			return _acc;
		}, crewsLaborStatistics);

	// sort offices by index
	const sortedNicknames = Object.keys(laborStatisticsUnparsed)
		.sort((_nickname1, _nickname2) => (laborStatisticsUnparsed[_nickname1]?.index ?? Infinity) - (laborStatisticsUnparsed[_nickname2]?.index ?? Infinity));

	const ignoreUndefined = true;
	// parse office statistics objects
	const sortedAndParsedLaborStatistics: ScheduleBoardEmailLaborStatisticsModels.LaborStatisticsPerLocationParsed = sortedNicknames.reduce(
		(_acc: ScheduleBoardEmailLaborStatisticsModels.LaborStatisticsPerLocationParsed, _officeNickname: string) => {
			if (ignoreUndefined && _officeNickname === UNKNOWN_LOCATION_NICKNAME) {
				return _acc;
			}
			return Object.assign(_acc, {
				[_officeNickname]: {
					officeNickname: _officeNickname,
					color: laborStatisticsUnparsed[_officeNickname].color,
					assignedLaborCount: Object.keys(laborStatisticsUnparsed[_officeNickname].assignedLaborCount || {}).length,
					totalLaborCount: laborStatisticsUnparsed[_officeNickname].totalLaborCount,
					totalRevenue: laborStatisticsUnparsed[_officeNickname].totalRevenue,
					crewsCount: Object.keys(laborStatisticsUnparsed[_officeNickname].crews || {}).length,
					hidden: laborStatisticsUnparsed[_officeNickname].hidden,
				},
			});
		},
		{} as ScheduleBoardEmailLaborStatisticsModels.LaborStatisticsPerLocationParsed
	);

	// calculate total counts
	const totalLaborStatistics: ScheduleBoardEmailLaborStatisticsModels.IndividualLaborStatisticsParsed = Object.values(sortedAndParsedLaborStatistics)
		.reduce((
			_acc: ScheduleBoardEmailLaborStatisticsModels.IndividualLaborStatisticsParsed,
			_counts: ScheduleBoardEmailLaborStatisticsModels.IndividualLaborStatisticsParsed
		) => {
			_counts.showRevenue = showRevenue;
			_counts.totalRevenue = round(_counts.totalRevenue);
			_counts.revenuePerPerson = round(fraction(_counts.totalRevenue, _counts.assignedLaborCount));
			_acc.crewsCount += _counts.crewsCount;
			_acc.assignedLaborCount += _counts.assignedLaborCount;
			_acc.totalLaborCount += _counts.totalLaborCount;
			_acc.totalRevenue += _counts.totalRevenue;
			return _acc;
		}, {
			officeNickname: 'Totals',
			color: getColorTextClass(AdditionalColors.GREY),
			crewsCount: 0,
			assignedLaborCount: 0,
			totalLaborCount: 0,
			totalRevenue: 0,
		} as ScheduleBoardEmailLaborStatisticsModels.IndividualLaborStatisticsParsed
		);
	totalLaborStatistics.showRevenue = showRevenue;
	totalLaborStatistics.totalRevenue = round(totalLaborStatistics.totalRevenue);
	totalLaborStatistics.revenuePerPerson = round(fraction(totalLaborStatistics.totalRevenue, totalLaborStatistics.assignedLaborCount));

	return {
		laborStatisticsPerLocation: Object.values(sortedAndParsedLaborStatistics),
		totalLaborStatistics,
	};
};

export const getEmployeeNotificationStatus = (
	emailStatus: Nullable<NotificationStatusEnum>,
	smsStatus: Nullable<NotificationStatusEnum>,
	emailSentAt: Nullable<Date>,
	smsSentAt: Nullable<Date>,
	isPreviousRevision: boolean | undefined = false
): EmployeeNotificationStatus | undefined => {

	if (isPreviousRevision) {
		return EmployeeNotificationStatus.OUTDATED;
	} else if (emailStatus === NotificationStatusEnum.OPENED) {
		return EmployeeNotificationStatus.OPENED;
	} else if (smsStatus === NotificationStatusEnum.OPENED) {
		return EmployeeNotificationStatus.OPENED;
	} else if (emailSentAt && smsSentAt && (smsStatus !== NotificationStatusEnum.SCHEDULED && emailStatus !== NotificationStatusEnum.SCHEDULED)) {
		if (emailSentAt > smsSentAt) {
			return emailStatus === NotificationStatusEnum.ERROR
				? EmployeeNotificationStatus.ERROR
				: EmployeeNotificationStatus.RECEIVED;
		} else if (emailSentAt < smsSentAt) {
			const successfulStatus = smsStatus === NotificationStatusEnum.QUEUED
				? EmployeeNotificationStatus.QUEUED
				: EmployeeNotificationStatus.RECEIVED;
			return smsStatus === NotificationStatusEnum.ERROR
				? EmployeeNotificationStatus.ERROR
				: successfulStatus;
		} else {
			return emailStatus === NotificationStatusEnum.ERROR || smsStatus === NotificationStatusEnum.ERROR
				? EmployeeNotificationStatus.ERROR
				: EmployeeNotificationStatus.RECEIVED;
		}
	} else if (emailSentAt && emailStatus !== NotificationStatusEnum.SCHEDULED) {
		return emailStatus === NotificationStatusEnum.ERROR
			? EmployeeNotificationStatus.ERROR
			: EmployeeNotificationStatus.RECEIVED;
	} else if (smsSentAt && smsStatus !== NotificationStatusEnum.SCHEDULED) {
		const successfulStatus = smsStatus === NotificationStatusEnum.QUEUED
			? EmployeeNotificationStatus.QUEUED
			: EmployeeNotificationStatus.RECEIVED;
		return smsStatus === NotificationStatusEnum.ERROR
			? EmployeeNotificationStatus.ERROR
			: successfulStatus;
	}
	return;
};

export const getGlobalParticipantNotificationStatusFromViewModel = (
	resources: Partial<WorkOrderResourceLookupViewModel | WorkOrderUpsertVM.Default['workOrderResourceLookups'][0]>[],
	notificationStatusMap: NotificationStatusByEmployee,
	excludedFromNotify: boolean
): Nullable<NotificationStatusEnum> => {
	if (!resources.length) {
		// set green check if there are no field workers
		return NotificationStatusEnum.OPENED;
	}

	const employeeWasNotified = excludedFromNotify && !resources.some(
		(_resource) => !!_resource.workOrderEmployeeId && !!notificationStatusMap[_resource.workOrderEmployeeId]
	);
	if (employeeWasNotified) {
		// work order is excluded from automatic notification and no one was manually notified
		return NotificationStatusEnum.OPENED;
	}

	let hasEveryoneOpened = true;
	let isStatusMissing = true;
	let isEveryoneNotified = true;
	const errorStatus = resources.find((_res) => {
		if (!_res?.workOrderEmployeeId) {
			return false;
		}
		const _status = notificationStatusMap[_res.workOrderEmployeeId];
		if (!_status || _status.isPreviousRevision) {
			isEveryoneNotified = false;
			return false;
		}
		isStatusMissing = false;
		if (_status.smsStatus !== NotificationStatusEnum.OPENED && _status.emailStatus !== NotificationStatusEnum.OPENED) {
			hasEveryoneOpened = false;
		}

		if (_status.smsStatus && _status.smsStatus === NotificationStatusEnum.ERROR) {
			return !_status.emailStatus || _status.emailStatus === NotificationStatusEnum.ERROR;
		} else {
			return _status.emailStatus === NotificationStatusEnum.ERROR;
		}
	});
	if (isStatusMissing || !isEveryoneNotified) {
		return null;
	}
	if (hasEveryoneOpened) {
		return NotificationStatusEnum.OPENED;
	}
	return errorStatus ? NotificationStatusEnum.ERROR : NotificationStatusEnum.DELIVERED;
};

export const getGlobalParticipantNotificationStatusFromModel = (
	resources: WorkOrderResourceLookupBase[],
	notificationStatusMap: NotificationStatusByEmployee,
	excludedFromNotify: boolean
): Nullable<NotificationStatusEnum> => {
	if (!resources.length) {
		// set green check if there are no field workers
		return NotificationStatusEnum.OPENED;
	}

	const employeeWasNotified = excludedFromNotify && !resources.some(
		(_resource) => !!_resource.workOrderEmployeeId && !!notificationStatusMap[_resource.workOrderEmployeeId]
	);
	if (employeeWasNotified) {
		// work order is excluded from automatic notification and no one was manually notified
		return NotificationStatusEnum.OPENED;
	}

	let hasEveryoneOpened = true;
	let isStatusMissing = true;
	let isEveryoneNotified = true;
	const errorStatus = resources.find((_res) => {
		if (!_res?.workOrderEmployeeId) {
			return false;
		}
		const _status = notificationStatusMap[_res.workOrderEmployeeId];
		if (!_status || _status.isPreviousRevision) {
			isEveryoneNotified = false;
			return false;
		}
		isStatusMissing = false;
		if (_status.smsStatus !== NotificationStatusEnum.OPENED && _status.emailStatus !== NotificationStatusEnum.OPENED) {
			hasEveryoneOpened = false;
		}
		return _status.smsStatus === NotificationStatusEnum.ERROR && _status.emailStatus === NotificationStatusEnum.ERROR;
	});
	if (isStatusMissing || !isEveryoneNotified) {
		return null;
	}
	if (hasEveryoneOpened) {
		return NotificationStatusEnum.OPENED;
	}
	return errorStatus ? NotificationStatusEnum.ERROR : NotificationStatusEnum.DELIVERED;
};

export const isFirstRevision = (revision: string) => revision === REVISION_ALPHABET[1];

export const getRevisionBackgroundColorClass = (revisionStringOrFirstRevisionFlag: string | boolean) => {
	const firstRevisionFlag = typeof revisionStringOrFirstRevisionFlag === 'boolean' ? revisionStringOrFirstRevisionFlag : isFirstRevision(revisionStringOrFirstRevisionFlag);
	return firstRevisionFlag ? FIRST_REVISION_BACKGROUND_COLOR_CLASS : OTHER_REVISION_BACKGROUND_COLOR_CLASS;
};

/**
 * After calling `findClosestDateInFutureByEquipmentAndDate`, use the return value as a param
 * @param date DB_DATE_ONLY, YYYY-MM-DD
 * @returns Date when the equipment changes status
 */
export const getEquipmentValidUntil = (date: Nullable<string>) => {
	if (!date) {
		return null;
	}
	// otherwise, return date that represents one day before date
	return TimeUtils.parseDateOnlyToMomentUtc(date, TimeFormat.DB_DATE_ONLY)?.subtract(1, 'day')?.toDate() ?? null;
};

/**
 * @param dueDate YYYY-MM-DD
 * TODO: check usage, employee probably shouldn't be nullable and return type should not consider undefined
 */
export const getEmployeeDailyStatusForDay = <E extends EmployeeBase, DES extends DailyEmployeeStatusBase>(employee: E, dueDate: string): Nullable<DES> => {
	if (!employee.dailyEmployeeStatus) {
		return null;
	}

	type Accumulator = { status: Nullable<DES>; fallbackStatus: Nullable<DES>; largestDateSmallerThanTarget: Nullable<Date>; };
	const targetDate = TimeUtils.parseDate(dueDate, TimeFormat.DB_DATE_ONLY);
	const { status, fallbackStatus } = (employee.dailyEmployeeStatus as DES[]).reduce<Accumulator>((_acc, _des) => {
		if (_acc.status) {
			return _acc;
		}

		const statusDate = TimeUtils.parseDate(_des.date, TimeFormat.DB_DATE_ONLY);

		if (TimeUtils.isBefore(statusDate, targetDate)) {
			if (!_acc.largestDateSmallerThanTarget || TimeUtils.isBefore(_acc.largestDateSmallerThanTarget, statusDate)) {
				_acc.fallbackStatus = _des;
				_acc.largestDateSmallerThanTarget = statusDate;
			}
		} else if (TimeUtils.isSame(statusDate, targetDate)) {
			_acc.status = _des;
		}
		return _acc;
	}, { status: null, fallbackStatus: null, largestDateSmallerThanTarget: null });

	return status ?? fallbackStatus;
};

/**
 * @param dueDate YYYY-MM-DD
 */
export const getEquipmentDailyStatusForDay = <E extends EquipmentBase, DES extends DailyEquipmentStatusBase>(equipment: E, dueDate: string): Nullable<DES> => {
	if (!equipment.dailyEquipmentStatus) {
		return null;
	}

	type Accumulator = { status: Nullable<DES>; fallbackStatus: Nullable<DES>; largestDateSmallerThanTarget: Nullable<Date>; };
	const targetDate = TimeUtils.parseDate(dueDate, TimeFormat.DB_DATE_ONLY);
	const { status, fallbackStatus } = (equipment.dailyEquipmentStatus as DES[]).reduce<Accumulator>((_acc, _des) => {
		if (_acc.status) {
			return _acc;
		}

		const statusDate = TimeUtils.parseDate(_des.date, TimeFormat.DB_DATE_ONLY);

		if (TimeUtils.isBefore(statusDate, targetDate)) {
			if (!_acc.largestDateSmallerThanTarget || TimeUtils.isBefore(_acc.largestDateSmallerThanTarget, statusDate)) {
				_acc.fallbackStatus = _des;
				_acc.largestDateSmallerThanTarget = statusDate;
			}
		} else if (TimeUtils.isSame(statusDate, targetDate)) {
			_acc.status = _des;
		}
		return _acc;
	}, { status: null, fallbackStatus: null, largestDateSmallerThanTarget: null });

	return status ?? fallbackStatus;
};
