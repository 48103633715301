import * as React from 'react';
import { compose } from 'redux';
import { Button, Row, Col } from 'react-bootstrap';
import type { ConnectedProps } from 'react-redux';
import { connect } from 'react-redux';

import AssignableResourceType from 'acceligent-shared/enums/assignableResourceType';
import { AdditionalColors } from 'acceligent-shared/enums/color';
import WorkOrderStatusEnum from 'acceligent-shared/enums/workOrderStatus';
import BlobStorageImageSizeContainer from 'acceligent-shared/enums/blobStorageImageSizeContainer';

import ImageTag from 'af-components/Image';
import CustomModal from 'af-components/CustomModal';
import SegmentLabel from 'af-components/SegmentLabel';
import LockedValue from 'af-components/LockedValue';
import LabelWithColor from 'af-components/LabelWithColor';

import Loading from 'af-root/scenes/Company/ScheduleBoard/Shared/LoadingModal';
import ResourceSkillList from 'af-root/scenes/Company/ScheduleBoard/Shared/ResourceModals/SkillsList';

import type ScheduleBoardEquipmentModalVM from 'ab-viewModels/scheduleBoardEquipmentModal.viewModel';
import type ScheduleBoardWorkOrderVM from 'ab-socketModels/viewModels/scheduleBoard/scheduleBoardWorkOrder.viewModel';
import type { ScheduleBoardOnDateViewModel } from 'ab-viewModels/scheduleBoardWorkOrdersOnDateView.viewModel';
import type ScheduleBoardViewModel from 'ab-viewModels/scheduleBoard.viewModel';

import { DEFAULT_EQUIPMENT_IMAGE } from 'ab-common/constants/value';
import { MAX_EQUIPMENT_ASSIGNMENTS_PER_DAY } from 'ab-common/constants/scheduleBoard';

import PagePermissions from 'ab-enums/pagePermissions.enum';

import { isAllowed } from 'ab-utils/auth.util';

import type { RootState } from 'af-reducers';

import { useScheduleBoardModals } from 'af-root/hooks/useScheduleBoardModal';

import AssignmentsModalSection from '../../AssignmentsModalSection';
import SettingsButton from './SettingsButton';
import styles from './styles.module.scss';

interface OwnProps {
	createEquipmentAssignment: (
		type: AssignableResourceType,
		date: string,
		workOrderId: number,
		isAvailable: boolean,
		resource: ScheduleBoardEquipmentModalVM
	) => boolean;
	hasPermissionsToEditScheduleBoard: boolean;
	removeEquipmentAssignment: (dueDate: string, workOrderId: number, workOrderCode: string, equipmentId: number) => void;
}

type Props = OwnProps & ConnectedProps<typeof connector>;

const _getStateToProps = (
	equipment: Nullable<ScheduleBoardEquipmentModalVM>,
	date: Nullable<string>,
	workOrdersByDateDictionary: ScheduleBoardViewModel['workOrdersByDateDictionary']
) => {
	if (!equipment || !date || !workOrdersByDateDictionary) {
		return {
			assignedWorkOrders: [],
			assignableWorkOrders: [],
		};
	}
	const workOrdersDictionary = workOrdersByDateDictionary[date];
	const assignedWorkOrderCodes = workOrdersDictionary?.equipmentAssignments?.[equipment?.id] ?? [];
	const assignedWorkOrders = assignedWorkOrderCodes
		.map((workOrderCode) => workOrdersDictionary?.workOrders?.[workOrderCode])
		.filter((_wo): _wo is ScheduleBoardOnDateViewModel['workOrders'][string] => (!!_wo));

	return {
		assignedWorkOrders,
		assignableWorkOrders: Object.keys(workOrdersDictionary?.workOrders ?? {})
			.reduce((_acc, _woCode) => {
				if (
					!assignedWorkOrderCodes.includes(_woCode)
					&& workOrdersDictionary?.workOrders?.[_woCode]?.status !== WorkOrderStatusEnum.CANCELED
					&& workOrdersDictionary?.workOrders?.[_woCode]?.status !== WorkOrderStatusEnum.LOCKED
					&& !workOrdersDictionary?.workOrders?.[_woCode]?.isPaused
				) {
					_acc.push(workOrdersDictionary!.workOrders[_woCode]);
				}
				return _acc;
			}, [] as ScheduleBoardWorkOrderVM[]),
	};
};
const EquipmentModal: React.FC<Props> = (props) => {
	const {
		createEquipmentAssignment,
		removeEquipmentAssignment,
		hasPermissionsToEditScheduleBoard,
		workOrdersByDateDictionary,
		isAllowedToManagePerDiems,
	} = props;

	const { equipment, equipmentModalDate, showEquipmentModal, setEquipmentModalVisibility, setEquipmentWOHistoryModalVisibility } = useScheduleBoardModals();

	const {
		assignableWorkOrders,
		assignedWorkOrders,
	} = React.useMemo(() => {
		return _getStateToProps(equipment, equipmentModalDate, workOrdersByDateDictionary);
	}, [equipment, equipmentModalDate, workOrdersByDateDictionary]);

	const closeModal = React.useCallback(() => {
		setEquipmentModalVisibility(false);
	}, [setEquipmentModalVisibility]);

	const createAssignment = React.useCallback(async (workOrderId: number) => {
		if (!hasPermissionsToEditScheduleBoard) {
			return false;
		}

		if (!equipment || !equipmentModalDate) {
			throw new Error('Equipment assignment not properly defined');
		}

		return await createEquipmentAssignment(
			AssignableResourceType.EQUIPMENT,
			equipmentModalDate,
			workOrderId,
			equipment.equipmentStatus?.available ?? true,
			equipment
		);
	}, [createEquipmentAssignment, equipmentModalDate, equipment, hasPermissionsToEditScheduleBoard]);

	const removeAssignment = React.useCallback((workOrderId: number, workOrderCode: string, itemId: number) => {
		if (!hasPermissionsToEditScheduleBoard) {
			return;
		}

		if (!equipmentModalDate) {
			throw new Error('Equipment assignment not properly defined');
		}

		removeEquipmentAssignment(equipmentModalDate, workOrderId, workOrderCode, itemId);
	}, [equipmentModalDate, hasPermissionsToEditScheduleBoard, removeEquipmentAssignment]);

	const openEquipmentWorkOrderHistoryModal = React.useCallback(() => {
		setEquipmentModalVisibility(false);
		setEquipmentWOHistoryModalVisibility(true);
	}, [setEquipmentModalVisibility, setEquipmentWOHistoryModalVisibility]);

	const renderDataModalContent = React.useCallback(() => {
		if (!equipment) {
			throw new Error('Equipment assignment not properly defined');
		}
		const { id, name, specification, equipmentCategory, equipmentStatus, frontImageUrl, backImageUrl, licenses, jobCode, skills } = equipment;

		const equipmentStatusName = equipmentStatus?.name ?? 'Available';

		const assignedInOffices = assignedWorkOrders.filter((wo) => !!wo.officeNickname);

		const scheduleBoardBaseClassName = 'schedule-board-modal';
		const headerClassName = `${scheduleBoardBaseClassName}__big-header`;
		const headerLeft = `${scheduleBoardBaseClassName}__big-header-left`;
		const headerRight = `${scheduleBoardBaseClassName}__big-header-right`;

		return (
			<>
				<CustomModal.Header className={`${styles[headerClassName]}`}>
					<div className={`${styles[headerLeft]} `}>
						<span className="schedule-board-modal__resource__header__title">
							{name}
							{specification
								? <span className="schedule-board-modal__resource__header__subtitle"> {specification}</span>
								: null
							}
						</span>
					</div>
					<div className={`${styles[headerRight]} `}>
						<LabelWithColor color={equipment.color ?? AdditionalColors.GREY} text={equipmentCategory} />
						<SettingsButton
							openEquipmentWorkOrderHistoryModal={openEquipmentWorkOrderHistoryModal}
						/>
					</div>
				</CustomModal.Header >
				<CustomModal.Body>
					<div className="schedule-board-modal__resource__row schedule-board-modal__resource__row--even">
						<div className="schedule-board-modal__resource__avatar-wrapper schedule-board-modal__resource__avatar-wrapper--non-marginated">
							<ImageTag
								className="schedule-board-modal__resource__avatar"
								fallbackSrc={DEFAULT_EQUIPMENT_IMAGE}
								minSize={BlobStorageImageSizeContainer.SIZE_200X200}
								src={frontImageUrl}
								tryOriginal={true}
								tryRoot={true}
							/>
						</div>
						<div className="schedule-board-modal__resource__avatar-wrapper schedule-board-modal__resource__avatar-wrapper--non-marginated">
							<ImageTag
								className="schedule-board-modal__resource__avatar"
								fallbackSrc={DEFAULT_EQUIPMENT_IMAGE}
								minSize={BlobStorageImageSizeContainer.SIZE_200X200}
								src={backImageUrl}
								tryOriginal={true}
								tryRoot={true}
							/>
						</div>
					</div>
					<hr />
					<div className="schedule-board-modal__resource__row">
						<SegmentLabel label="Requirements" />
						<Row className="row--non-padded">
							<Col md={6}>
								<LockedValue
									label="Needed Licenses"
									value={licenses}
								/>
							</Col>
							<Col md={18}>
								<ResourceSkillList skills={skills} title="Needed skills" />
							</Col>
						</Row>
					</div>
					<hr />
					<div className="schedule-board-modal__resource__row">
						<SegmentLabel label="Current Status" />
						<Row className="row--non-padded">
							<Col md={6}>
								<LockedValue
									label="Status"
									value={equipmentStatusName}
								/>
							</Col>
							<Col md={6}>
								<LockedValue
									label="Job"
									value={jobCode}
								/>
							</Col>
							<Col md={6}>
								<LockedValue
									label="Job Locations"
									value={assignedInOffices.length > 0
										? assignedInOffices.map((wo) => wo.officeNickname).join(', ')
										: '-'
									}
								/>
							</Col>
							<Col md={12} />
						</Row>
					</div>
					<hr />
					<AssignmentsModalSection
						assignableWorkOrders={assignableWorkOrders}
						assignedWorkOrders={assignedWorkOrders}
						createAssignment={createAssignment}
						isAllowedToManagePerDiems={isAllowedToManagePerDiems}
						isEditable={!equipment.isDeleted && hasPermissionsToEditScheduleBoard}
						itemId={id}
						maxItems={MAX_EQUIPMENT_ASSIGNMENTS_PER_DAY}
						removeAssignment={removeAssignment}
						showPerDiem={false}
					/>
				</CustomModal.Body>
				<CustomModal.Footer>
					<Button
						onClick={closeModal}
						variant="info"
					>
						Close
					</Button>
				</CustomModal.Footer>
			</>
		);
	}, [
		assignableWorkOrders,
		assignedWorkOrders,
		closeModal,
		createAssignment,
		equipment,
		hasPermissionsToEditScheduleBoard,
		openEquipmentWorkOrderHistoryModal,
		removeAssignment,
		isAllowedToManagePerDiems,
	]);

	const renderLoadingModalContent = React.useCallback(() => {
		return (
			<Loading onHide={closeModal} />
		);
	}, [closeModal]);

	return (
		<CustomModal
			className="resource-modal equipment"
			closeModal={closeModal}
			modalStyle="info"
			showModal={showEquipmentModal}
			size="xl"
		>
			{equipment ? renderDataModalContent() : renderLoadingModalContent()}
		</CustomModal>
	);
};

function mapStateToProps(state: RootState) {
	const { companyData, userData } = state.user;
	if (!userData || !companyData) {
		throw new Error('User not logged in');
	}

	const isAllowedToManagePerDiems: boolean = isAllowed(PagePermissions.COMPANY.PER_DIEMS, companyData.permissions, companyData.isCompanyAdmin, userData.role);

	return {
		isAllowedToManagePerDiems,
		workOrdersByDateDictionary: state.scheduleBoard.workOrdersByDateDictionary,
	};
}

const connector = connect(mapStateToProps);

const enhance = compose<React.ComponentType<OwnProps>>(
	React.memo,
	connector
);

export default enhance(EquipmentModal);
