import * as React from 'react';
import type { ConnectedProps } from 'react-redux';
import { connect } from 'react-redux';
import type { CustomRouteComponentProps } from 'react-router-dom';

import type { RootState } from 'af-reducers';

import CLIENT from 'af-constants/routes/client';

import PagePermissions from 'ab-enums/pagePermissions.enum';

import * as UserUtils from 'af-utils/user.util';

import FSN from './FSN';

type Props = CustomRouteComponentProps & ConnectedProps<typeof connector>;

const SettingsFSN: React.FC<Props> = (props: Props) => {
	const { companyData, userData, location: { state: { orgAlias }, pathname } } = props;
	const companyName = companyData.name;

	const links = React.useMemo(() => [
		{
			url: CLIENT.COMPANY.SETTINGS.ACCOUNT(orgAlias, companyName),
			label: 'Account',
			icon: 'icon-user',
			visibleFor: PagePermissions.COMPANY.SETTINGS.ACCOUNT,
			isActive: pathname.includes(CLIENT.COMPANY.SETTINGS.ACCOUNT(orgAlias, companyName)),
			isForceHidden: UserUtils.isAdmin(userData),
			id: 'accqa__fsn__account',
		},
		{
			url: CLIENT.COMPANY.SETTINGS.COMPANY(orgAlias, companyName),
			label: 'Company',
			icon: 'icon-work',
			visibleFor: PagePermissions.COMPANY.SETTINGS.COMPANY,
			isActive: pathname.includes(CLIENT.COMPANY.SETTINGS.COMPANY(orgAlias, companyName)),
			id: 'accqa__fsn__company',
		},
		{
			url: CLIENT.COMPANY.SETTINGS.SCHEDULE_BOARD(orgAlias, companyName),
			label: 'Schedule Board',
			icon: 'icon-schedule_board',
			visibleFor: PagePermissions.COMPANY.SETTINGS.SCHEDULE_BOARD,
			isActive: pathname.includes(CLIENT.COMPANY.SETTINGS.SCHEDULE_BOARD(orgAlias, companyName)),
			id: 'accqa__fsn__schedule-board',
		},
		{
			url: CLIENT.COMPANY.SETTINGS.WORK_ORDER(orgAlias, companyName),
			label: 'Work Order',
			icon: 'icon-work_order',
			visibleFor: PagePermissions.COMPANY.SETTINGS.WORK_ORDER,
			isActive: pathname.includes(CLIENT.COMPANY.SETTINGS.WORK_ORDER(orgAlias, companyName)),
			id: 'accqa__fsn__work-orders',
		},
		{
			url: CLIENT.COMPANY.SETTINGS.REPORT.TABLE(orgAlias, companyName),
			label: 'Field Report Elements',
			icon: 'icon-daily_report',
			visibleFor: PagePermissions.COMPANY.SETTINGS.REPORT_MANAGE,
			isActive: pathname.includes(CLIENT.COMPANY.SETTINGS.REPORT.ROOT(orgAlias, companyName)),
			id: 'accqa__fsn__field-report-elements',
		},
		{
			url: CLIENT.COMPANY.SETTINGS.NOTIFICATION(orgAlias, companyName),
			label: 'Notifications',
			icon: 'icon-notifications_active',
			visibleFor: PagePermissions.COMPANY.SETTINGS.NOTIFICATIONS,
			isActive: pathname.includes(CLIENT.COMPANY.SETTINGS.NOTIFICATION(orgAlias, companyName)),
			id: 'accqa__fsn__notifications',
		},
		{
			url: CLIENT.COMPANY.SETTINGS.MEMBERS.TABLE(orgAlias, companyName),
			label: 'Users',
			icon: 'icon-users',
			visibleFor: PagePermissions.COMPANY.SETTINGS.MEMBERS,
			isActive: pathname.includes(CLIENT.COMPANY.SETTINGS.MEMBERS.TABLE(orgAlias, companyName)),
			id: 'accqa__fsn__users',
		},
		{
			url: CLIENT.COMPANY.SETTINGS.USER_GROUPS.TABLE(orgAlias, companyName),
			label: 'User Groups',
			icon: 'icon-category',
			visibleFor: PagePermissions.COMPANY.SETTINGS.USER_GROUPS,
			isActive: pathname.includes(CLIENT.COMPANY.SETTINGS.USER_GROUPS.ROOT(orgAlias, companyName)),
			id: 'accqa__fsn__user-groups',
		},
		{
			url: CLIENT.COMPANY.SETTINGS.LOGS.TABLE(orgAlias, companyName),
			label: 'Logs',
			icon: 'icon-logs',
			visibleFor: PagePermissions.COMPANY.SETTINGS.LOGS,
			isActive: pathname.includes(CLIENT.COMPANY.SETTINGS.LOGS.TABLE(orgAlias, companyName)),
			id: 'accqa__fsn__logs',
		},
		{
			url: CLIENT.COMPANY.SETTINGS.EQUIPMENT(orgAlias, companyName),
			label: 'Equipment',
			icon: 'icon-equipment',
			visibleFor: PagePermissions.COMPANY.SETTINGS.EQUIPMENT,
			isActive: pathname.includes(CLIENT.COMPANY.SETTINGS.EQUIPMENT(orgAlias, companyName)),
			id: 'accqa__fsn__equipment',
		},
		{
			url: CLIENT.COMPANY.SETTINGS.LABOR(orgAlias, companyName),
			label: 'Labor',
			icon: 'icon-user',
			visibleFor: PagePermissions.COMPANY.SETTINGS.LABOR,
			isActive: pathname.includes(CLIENT.COMPANY.SETTINGS.LABOR(orgAlias, companyName)),
			id: 'accqa__fsn__labor',
		},
		// TODO: until we get back connected devices page
		// {
		// 	url: CLIENT.COMPANY.SETTINGS.CONNECTED_DEVICES(orgAlias, companyName),
		// 	label: 'Connected Devices',
		// 	visibleFor: PagePermissions.COMPANY.SETTINGS.ACCOUNT,
		// 	isActive: pathname.includes(CLIENT.COMPANY.SETTINGS.CONNECTED_DEVICES(orgAlias, companyName))
		// }
	], [orgAlias, userData, companyName, pathname]);

	return (
		<FSN
			infoLabel="Settings"
			isCompanyAdmin={companyData.isCompanyAdmin}
			links={links}
			permissions={companyData.permissions}
			scrollLabel="none"
			userRole={userData.role}
		/>
	);
};

function mapStateToProps(state: RootState) {
	const { userData, organizationData, companyData } = state.user;
	if (!userData || !companyData || !organizationData) {
		throw new Error('User not logged in');
	}

	return {
		organizationData,
		userData,
		companyData,
	};
}

const connector = connect(mapStateToProps);

export default connector(SettingsFSN);
