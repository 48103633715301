import type { ColorPalette, ExtendedColorValue} from 'acceligent-shared/enums/color';
import { DefaultColor } from 'acceligent-shared/enums/color';

import type SkillBase from 'ab-domain/models/skill/base';

export class SkillViewModel {
	id: number;
	name: string;
	color: ColorPalette;

	constructor(skill: SkillBase) {
		this.id = skill.id;
		this.name = skill.name;
		this.color = skill.color;
	}

	static bulkConstructor(skills: SkillBase[] = []): SkillViewModel[] {
		return skills.map(SkillViewModel._constructorMap);
	}

	private static _constructorMap = (_skill: SkillBase) => new SkillViewModel(_skill);
}

export type GroupedSkills = { [T in ColorPalette]?: SkillViewModel[] };

export function groupSkillsByColor(skills: SkillBase[] = []): GroupedSkills {
	return skills.reduce((_acc, _skill) => {
		const colorSkills = !!_acc[_skill.color] ? _acc[_skill.color].concat(new SkillViewModel(_skill)) : [new SkillViewModel(_skill)];
		return { ..._acc, [_skill.color]: colorSkills };
	}, {});
}

export class SkillOptionViewModel {
	id: number;
	name: string;
	color: ExtendedColorValue;
	checked?: boolean;

	constructor(skill: SkillBase | SkillOptionViewModel) {
		this.id = skill.id;
		this.name = skill.name;
		this.color = skill.color;
	}

	static toSkillOptions(skills: SkillBase[]): SkillOptionViewModel[] {
		return skills.map((_skill) => new SkillOptionViewModel(_skill));
	}
}

export const NO_SKILLS_OPTION: SkillOptionViewModel = {
	id: -1,
	name: 'NO SKILL',
	color: DefaultColor.NO_SKILL,
};
