import * as React from 'react';
import { compose } from 'redux';
import type { ConnectedProps } from 'react-redux';
import { connect } from 'react-redux';
import type { InjectedFormProps} from 'redux-form';
import { reduxForm, formValueSelector } from 'redux-form';

import type ItemCategoryRM from 'ab-requestModels/itemCategory/itemCategory.requestModel';

import type ItemCategoryVM from 'ab-viewModels/itemCategory/itemCategory.viewModel';

import { ITEM_CATEGORY_EDIT } from 'af-constants/reduxForms';

import Form from './CategoryForm';
import { validate } from './validate';
import type { RootState } from 'af-reducers';

interface OwnProps {
	update: (form: ItemCategoryRM) => Promise<void>;
	toggleEdit: (id: Nullable<number>) => void;
	category: ItemCategoryVM;
	categories: ItemCategoryVM[];
	id: Nullable<number>;
}

type Props = OwnProps & InjectedFormProps<ItemCategoryRM, OwnProps> & ConnectedProps<typeof connector>;

const CategoryEdit = (props: Props) => {
	const { category, toggleEdit, update, initialize, newName } = props;

	React.useEffect(() => {
		initialize(category);
		// eslint-disable-next-line react-hooks/exhaustive-deps
	}, []);

	const onUpdate = React.useCallback(async () => {
		await update({ name: newName });
		toggleEdit(null);
	}, [toggleEdit, update, newName]);

	const close = React.useCallback(() => {
		toggleEdit(null);
	}, [toggleEdit]);

	return (
		<Form {...props} close={close} formValue={newName} onSubmit={onUpdate} />
	);
};

function mapStateToProps(state: RootState) {
	return {
		newName: formValueSelector(ITEM_CATEGORY_EDIT)(state, 'name'),
	};
}

const connector = connect(mapStateToProps);

const enhance = compose<React.ComponentType<OwnProps>>(
	connector,
	reduxForm<ItemCategoryRM, OwnProps>({
		form: ITEM_CATEGORY_EDIT, validate, enableReinitialize: true,
	})
);

export default enhance(CategoryEdit);
