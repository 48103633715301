import * as React from 'react';

import type Priority from 'acceligent-shared/enums/priority';

interface OwnProps {
	priority: Priority;
}

type Props = OwnProps;

class PriorityCell extends React.PureComponent<Props> {
	render() {
		const { priority } = this.props;

		return (
			<div className={`priority-cell priority-cell--${priority.toLowerCase()}`}>
				{priority[0]}
			</div>
		);
	}
}

export default PriorityCell;
