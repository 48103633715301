import type ToolState from 'acceligent-shared/enums/toolState';
import type ToolStatus from 'acceligent-shared/enums/toolStatus';

import type ToolBase from 'ab-domain/models/tool/base';

export class W_SmallTool_FindById_VM {
	id: number;
	modelNumber: string;
	manufacturer: string;
	serialNumber: string;
	note: Nullable<string>;
	status: ToolStatus;
	location: W_SmallTool_FindById_VM_Location;
	toolType: W_SmallTool_FindById_VM_ToolType;
	hasActiveToolRepair: boolean;
	state: ToolState;

	constructor(tool: ToolBase) {
		this.id = tool.id;
		this.serialNumber = tool.serialNumber;
		this.manufacturer = tool.manufacturer;
		this.modelNumber = tool.modelNumber;
		this.location = new W_SmallTool_FindById_VM_Location(tool);
		this.note = tool.note;
		this.status = tool.status;
		this.toolType = new W_SmallTool_FindById_VM_ToolType(tool);
		this.hasActiveToolRepair = tool.toolRepairs.some((toolRepair) => toolRepair.completedAt === null);
		this.state = tool.state;
	}
}

class W_SmallTool_FindById_VM_Location {
	id: number;
	name: string;

	constructor(tool: ToolBase) {
		this.id = tool.locationId;
		this.name = tool.location.nickname;
	}

}

class W_SmallTool_FindById_VM_ToolType {
	id: number;
	name: string;

	constructor(tool: ToolBase) {
		this.id = tool.toolTypeId;
		this.name = tool.toolType.name;
	}
}
