import * as React from 'react';
import type { CustomRouteComponentProps } from 'react-router-dom';
import type { ConnectedProps } from 'react-redux';
import { connect } from 'react-redux';
import { Row, Col } from 'react-bootstrap';

import type SaleRequestModel from 'ab-requestModels/sale.requestModel';

import CLIENT from 'af-constants/routes/client';

import type { RootState } from 'af-reducers';

import * as SaleActions from 'af-actions/sale';

import TextControl from 'af-components/Controls/Text';

import ResourcePreview from '../../Shared/ResourcePreview';
import Loading from './Loading';

interface PathParams {
	id: string;
}

type OwnProps = CustomRouteComponentProps<PathParams>;

type Props = OwnProps & ConnectedProps<typeof connector>;

interface State {
	sale: Nullable<SaleRequestModel>;
}

class PreviewSale extends React.PureComponent<Props, State> {
	state: State = {
		sale: null,
	};

	async componentDidMount() {
		const { match: { params: { id } }, findById } = this.props;
		const sale = await findById(id);
		this.setState(() => ({ sale }));
	}

	render() {
		const { companyName, match: { params: { id } }, location: { state: { orgAlias } } } = this.props;
		const { sale } = this.state;

		const breadcrumbs = [
			{ label: 'Sales', url: CLIENT.COMPANY.RESOURCES.SALE.LIST(orgAlias, companyName) },
			{ label: 'Preview' },
		];

		if (!sale || sale.id !== +id) {
			return (
				<Loading
					backToListLabel="Back to Sale list"
					backToListUrl={CLIENT.COMPANY.RESOURCES.SALE.LIST(orgAlias, companyName)}
					breadcrumbs={breadcrumbs}
				/>
			);
		}

		return (
			<ResourcePreview
				backToListLabel="Back"
				backToListUrl={CLIENT.COMPANY.RESOURCES.SALE.LIST(orgAlias, companyName)}
				breadcrumbs={breadcrumbs}
				editUrl={CLIENT.COMPANY.RESOURCES.SALE.EDIT(id.toString(), orgAlias, companyName)}
			>
				<Row className="row--padded-top">
					<Col lg={12} md={16}>
						<TextControl bold defaultValue="N/A" label="Full Name" value={sale.fullName} />
					</Col>
					<Col lg={6} md={8}>
						<TextControl bold defaultValue="N/A" label="Title" value={sale.title} />
					</Col>
				</Row>
				<Row>
					<Col lg={6} md={8}>
						<TextControl bold defaultValue="N/A" label="Email" value={sale.email} />
					</Col>
					<Col lg={6} md={8}>
						<TextControl bold defaultValue="N/A" label="Mobile Phone" value={sale.phone} />
					</Col>
					<Col lg={6} md={8}>
						<TextControl bold defaultValue="N/A" label="Fax" value={sale.fax} />
					</Col>
				</Row>
				<Row>
					<Col xs={12}>
						<TextControl bold defaultValue="N/A" label="Address" value={sale.street} />
					</Col>
				</Row>
			</ResourcePreview>
		);
	}
}

function mapStateToProps(state: RootState) {
	const { companyData } = state.user;
	if (!companyData) {
		throw new Error('User not logged in');
	}

	return {
		companyName: companyData?.name,
	};
}

function mapDispatchToProps() {
	return {
		findById: SaleActions.findById,
	};
}

const connector = connect(mapStateToProps, mapDispatchToProps());

export default connector<React.ComponentClass<OwnProps>>(PreviewSale);
