import * as React from 'react';
import type { ConnectedProps } from 'react-redux';
import { connect } from 'react-redux';
import { Button } from 'react-bootstrap';
import type { History } from 'history';

import type { ScheduleBoardRemoveEmployeeAssignmentVM, ScheduleBoardRemoveEquipmentAssignmentVM, ScheduleBoardRemovePlaceholderAssignmentVM } from 'acceligent-shared/dtos/socket/view/scheduleBoard/resources';

import WorkOrderReviewStatus from 'acceligent-shared/enums/workOrderReviewStatus';
import { AdditionalColors, ColorHex } from 'acceligent-shared/enums/color';
import WorkOrderStatus from 'acceligent-shared/enums/workOrderStatus';
import TimeFormatEnum from 'acceligent-shared/enums/timeFormat';

import * as TimeUtils from 'acceligent-shared/utils/time';
import { shouldReportForAccountBeReadOnly } from 'acceligent-shared/utils/workOrderFieldReport';

import type ServerToClientHandlers from 'ab-socketModels/handlerModels/server';

import type { WorkOrderPublishForm } from 'ab-requestModels/workOrder.requestModel';

import type { AttachmentViewModel } from 'ab-viewModels/attachment.viewModel';
import type WorkOrderModalViewModel from 'ab-viewModels/scheduleBoardWorkOrderModal.viewModel';
import { isEmployee, isTemporaryEmployee } from 'ab-viewModels/scheduleBoardWorkOrderModal.viewModel';
import type ScheduleBoardWorkOrderViewModel from 'ab-socketModels/viewModels/scheduleBoard/scheduleBoardWorkOrder.viewModel';
import type ScheduleBoardAddResourceLookupAssignment from 'ab-viewModels/scheduleBoardAddResourceLookupAssignment.viewModel';
import type ScheduleBoardEmployee from 'ab-viewModels/scheduleBoardEmployee.viewModel';
import type ScheduleBoardTemporaryEmployee from 'ab-viewModels/scheduleBoardTemporaryEmployee.viewModel';

import PagePermissions from 'ab-enums/pagePermissions.enum';
import SocketEvent from 'ab-enums/socketEvent.enum';
import WorkOrderStatusRevision from 'ab-enums/workOrderStatusRevision';

import { isAllowed } from 'ab-utils/auth.util';
import { isFirstRevision } from 'ab-utils/scheduleBoard.util';

import socket from 'af-utils/socket.util';

import type { RootState } from 'af-reducers';

import BadgeCell from 'af-components/Table6/Cells/BadgeCell';
import Tooltip from 'af-components/Tooltip';
import CustomModal from 'af-components/CustomModal';
import StatusIcon from 'af-components/StatusIcon';
import LabelWithColor from 'af-components/LabelWithColor';
import SegmentLabel from 'af-components/SegmentLabel';
import ConfirmationModal from 'af-components/ConfirmationModal';
import LoadingIndicator from 'af-components/LoadingIndicator';

import Loading from 'af-root/scenes/Company/ScheduleBoard/Shared/LoadingModal';

import CurrentStatusSection from './Sections/CurrentStatusSection';
import ShiftSection from './Sections/ShiftSection';
import WorkLocationsSection from './Sections/WorkLocationsSection';
import SiteContactSection from './Sections/SiteContactSection';
import NotesSection from './Sections/NotesSection';
import ResourceLookupsSection from './Sections/ResourceLookupsSection';
import ReportsSection from './Sections/ReportsSection';
import ProductionDataSection from './Sections/ProductionDataSection';
import DeliverableSection from './Sections/DeliverableSection';
import RevisionDropdown from './RevisionDropdown';
import SettingsButton from './SettingsButton';
import EditButton from './EditButton';
import DirectoriesAndAttachments from './DirectoriesAndAttachments';
import type ScheduleBoardLockWorkOrderViewModel from 'ab-socketModels/viewModels/scheduleBoard/lockWorkOrder.viewModel';
import type ScheduleBoardUnlockWorkOrderViewModel from 'ab-socketModels/viewModels/scheduleBoard/unlockWorkOrder.viewModel';

type ReloadEventViewModels = ScheduleBoardWorkOrderViewModel
	| ScheduleBoardAddResourceLookupAssignment
	| ScheduleBoardRemovePlaceholderAssignmentVM
	| ScheduleBoardRemoveEquipmentAssignmentVM
	| ScheduleBoardRemoveEmployeeAssignmentVM
	| ScheduleBoardLockWorkOrderViewModel
	| ScheduleBoardUnlockWorkOrderViewModel;

interface OwnPropsBase {
	closeOrderInfoModal: (reset?: boolean) => void;
	companyName: string;
	hasPermissionsToEditScheduleBoard: boolean;
	history: History;
	loadWorkOrder: (workOrderId: number) => void;
	orgAlias: string;
	showModal: boolean;
	workOrder: Nullable<WorkOrderModalViewModel>;
	workOrderRevision: Nullable<WorkOrderModalViewModel>;
	isRevisionOpened: boolean;
	selectRevision: (revisionId: number, isCurrentWorkOrder: boolean) => void;
	actionsEnabled: boolean;
	hasAccess: boolean;
	disabled?: boolean;
}

type OptionalActions = {
	generatePreview: () => Promise<void>;
	openCancelModal: () => void;
	openCopyModal: (orderId: number, code: string) => void;
	openDelayModal: () => void;
	openDeleteModal: () => void;
	openPauseModal: () => void;
	openResumeModal: () => void;
	openNotificationPreviewModal: () => void;
	openNotifyParticipantsModal: () => void;
	publishOrder: (workOrderId: number, form?: WorkOrderPublishForm) => Promise<void>;
};

type OwnProps =
	| (OwnPropsBase & { actionsEnabled: true; } & Required<OptionalActions>)
	| (OwnPropsBase & { actionsEnabled: false; } & Partial<Record<keyof OptionalActions, undefined>>);

interface State {
	attachments: AttachmentViewModel[] | undefined;
	workOrderModal: Nullable<WorkOrderModalViewModel>;
	areReportsReadonly: boolean;
	rejectionModalOpen: boolean;
	rejectionModalText: Nullable<string>;
}

type Props = OwnProps & ConnectedProps<typeof connector>;

export const renderWorkOrderInfoData = (valueToShow: string | number | null, currentValue: string | number | null) => {
	if (valueToShow === currentValue) {
		return valueToShow;
	}

	return (
		<Tooltip message={`Current Value: ${currentValue?.toString()}`}>
			<span className="revision-highlight">{valueToShow}</span>
		</Tooltip>
	);
};

class OrderInfoModal extends React.Component<Props, State> {
	state: State = {
		attachments: undefined,
		workOrderModal: this.props.workOrder,
		areReportsReadonly: false,
		rejectionModalOpen: false,
		rejectionModalText: null,
	};

	static RELOAD_EVENTS = [
		SocketEvent.V2.BE.SCHEDULE_BOARD.UPDATE_WORK_ORDER_MODAL,
		SocketEvent.V2.BE.SCHEDULE_BOARD.PUBLISH_WORK_ORDER,
		SocketEvent.V2.BE.SCHEDULE_BOARD.CREATE_PLACEHOLDER_ASSIGNMENT,
		SocketEvent.V2.BE.SCHEDULE_BOARD.CREATE_EMPLOYEE_ASSIGNMENT,
		SocketEvent.V2.BE.SCHEDULE_BOARD.CREATE_EQUIPMENT_ASSIGNMENT,
		SocketEvent.V2.BE.SCHEDULE_BOARD.CREATE_RESOURCE_PLACEHOLDER,
		SocketEvent.V2.BE.SCHEDULE_BOARD.CREATE_TEMPORARY_EMPLOYEE_ASSIGNMENT,
		SocketEvent.V2.BE.SCHEDULE_BOARD.REMOVE_PLACEHOLDER_ASSIGNMENT,
		SocketEvent.V2.BE.SCHEDULE_BOARD.REMOVE_EQUIPMENT_ASSIGNMENT,
		SocketEvent.V2.BE.SCHEDULE_BOARD.REMOVE_EMPLOYEE_ASSIGNMENT,
		SocketEvent.V2.BE.SCHEDULE_BOARD.REMOVE_TEMPORARY_EMPLOYEE_ASSIGNMENT,
		SocketEvent.V2.BE.SCHEDULE_BOARD.LOCK_STATUS_WORK_ORDER,
		SocketEvent.V2.BE.SCHEDULE_BOARD.UNLOCK_STATUS_WORK_ORDER,
	];

	// Map of WorkOrder equality conditions for each reload socket event
	static EVENT_REFRESH_CONDITION: { [event: string]: (data: ReloadEventViewModels, workOrder: WorkOrderModalViewModel) => boolean; } = {
		[SocketEvent.V2.BE.SCHEDULE_BOARD.UPDATE_WORK_ORDER_MODAL]:
			(data: ScheduleBoardWorkOrderViewModel, workOrder: WorkOrderModalViewModel) => {
				return data.id === workOrder?.id;
			},
		[SocketEvent.V2.BE.SCHEDULE_BOARD.PUBLISH_WORK_ORDER]:
			(data: ScheduleBoardWorkOrderViewModel, workOrder: WorkOrderModalViewModel) => {
				return data.id === workOrder?.id;
			},
		[SocketEvent.V2.BE.SCHEDULE_BOARD.CREATE_PLACEHOLDER_ASSIGNMENT]:
			(data: ScheduleBoardAddResourceLookupAssignment, workOrder: WorkOrderModalViewModel) => {
				return data.workOrderResourceLookup?.workOrderCode === workOrder?.code;
			},
		[SocketEvent.V2.BE.SCHEDULE_BOARD.CREATE_EMPLOYEE_ASSIGNMENT]:
			(data: ScheduleBoardAddResourceLookupAssignment, workOrder: WorkOrderModalViewModel) => {
				return data.workOrderResourceLookup?.workOrderCode === workOrder?.code;
			},
		[SocketEvent.V2.BE.SCHEDULE_BOARD.CREATE_EQUIPMENT_ASSIGNMENT]:
			(data: ScheduleBoardAddResourceLookupAssignment, workOrder: WorkOrderModalViewModel) => {
				return data.workOrderResourceLookup?.workOrderCode === workOrder?.code;
			},
		[SocketEvent.V2.BE.SCHEDULE_BOARD.CREATE_RESOURCE_PLACEHOLDER]:
			(data: ScheduleBoardAddResourceLookupAssignment, workOrder: WorkOrderModalViewModel) => {
				return data.workOrderResourceLookup?.workOrderCode === workOrder?.code;
			},
		[SocketEvent.V2.BE.SCHEDULE_BOARD.REMOVE_PLACEHOLDER_ASSIGNMENT]:
			(data: ScheduleBoardRemovePlaceholderAssignmentVM, workOrder: WorkOrderModalViewModel) => {
				return data.workOrderCode === workOrder?.code;
			},
		[SocketEvent.V2.BE.SCHEDULE_BOARD.REMOVE_EQUIPMENT_ASSIGNMENT]:
			(data: ScheduleBoardRemoveEquipmentAssignmentVM, workOrder: WorkOrderModalViewModel) => {
				return data.workOrderCode === workOrder?.code;
			},
		[SocketEvent.V2.BE.SCHEDULE_BOARD.REMOVE_EMPLOYEE_ASSIGNMENT]:
			(data: ScheduleBoardRemoveEmployeeAssignmentVM, workOrder: WorkOrderModalViewModel) => {
				return data.workOrderCode === workOrder?.code;
			},
		[SocketEvent.V2.BE.SCHEDULE_BOARD.LOCK_STATUS_WORK_ORDER]:
			(data: ScheduleBoardLockWorkOrderViewModel, workOrder: WorkOrderModalViewModel) => {
				return data.workOrderId === workOrder?.id;
			},
		[SocketEvent.V2.BE.SCHEDULE_BOARD.UNLOCK_STATUS_WORK_ORDER]:
			(data: ScheduleBoardUnlockWorkOrderViewModel, workOrder: WorkOrderModalViewModel) => {
				return data.workOrderId === workOrder?.id;
			},
	};

	static workOrderFinalizedMessage = 'Work order can\'t be republished if it\'s finalized';
	static workOrderProblemMessage = 'Problem occurred while trying to publish WO. Please refresh and try again';

	// Map that holds socket event callback references
	private _eventCallBackMap: { [event: string]: () => void; } = {};

	/** Adding workOrder from props to state because of updateSubscriptionStatus */
	static getDerivedStateFromProps(props: Props): Partial<State> {
		return {
			workOrderModal: props.workOrder,
			areReportsReadonly: OrderInfoModal.isReadOnly(props),
		};
	}

	getRejectRepublishBody = (text: string) => (
		<>
			{text}
		</>
	);

	static isReadOnly = (props: Props) => {
		if (!props.workOrder) {
			return true;
		}

		return shouldReportForAccountBeReadOnly(
			props.workOrder.reviewStatus,
			props.workOrder.reviewLevel,
			props.workOrder.status,
			false, // don't care for special case
			props.workOrder.fieldReportAccessRole,
			props.hasPermissionToEditReports,
			props.isManagerOrAdmin,
			props.canEditInFinalizing
		);
	};

	componentDidMount() {
		OrderInfoModal.RELOAD_EVENTS.forEach(this.subscribeEvent);
		socket.connection?.subscribe(SocketEvent.V2.BE.SCHEDULE_BOARD.UPDATE_EMPLOYEE, this.updateSubscriptionStatus, true);
		socket.connection?.subscribe(SocketEvent.V2.BE.SCHEDULE_BOARD.UPDATE_TEMPORARY_EMPLOYEE, this.updateTemporaryEmployeeSubscriptionStatus, true);
	}

	componentWillUnmount() {
		OrderInfoModal.RELOAD_EVENTS.forEach((_event) => socket.connection?.removeHandler(_event, this._eventCallBackMap[_event]));
		socket.connection?.removeHandler(SocketEvent.V2.BE.SCHEDULE_BOARD.UPDATE_EMPLOYEE, this.updateSubscriptionStatus);
		socket.connection?.removeHandler(SocketEvent.V2.BE.SCHEDULE_BOARD.UPDATE_TEMPORARY_EMPLOYEE, this.updateTemporaryEmployeeSubscriptionStatus);
		this._eventCallBackMap = {};
	}

	// Registering multiple handlers, because they are also used in daily view
	// Binding event to callback, so we can get WorkOrder equality condition for event
	// Cause we are binding, storing callback reference to map, so they can be removed on Unmount
	subscribeEvent = <K extends keyof ServerToClientHandlers>(event: K) => {
		const callback = this.loadData.bind(this, event);
		this._eventCallBackMap[event] = callback;
		socket.connection?.subscribe(event, callback, true);
	};

	loadData = async (event: string, data: ReloadEventViewModels) => {
		const { loadWorkOrder, showModal } = this.props;
		const { workOrderModal } = this.state;
		if (showModal && workOrderModal && OrderInfoModal.EVENT_REFRESH_CONDITION[event](data, workOrderModal)) {
			loadWorkOrder(workOrderModal?.id);
		}
	};

	// since employees in notification modal are being pulled from workOrder.workOrderEmployees, they do not have up to date
	// subscriptionStatus. this ensures that if we have opened notifications modal and (un)subscribe from the sms
	// we will see live change
	updateSubscriptionStatus = (data: ScheduleBoardEmployee) => {
		const { workOrderModal } = this.state;

		let hasWorkOrderModalBeenUpdated = false;
		const newResourceLookups = workOrderModal?.workOrderResourceLookups?.map((_resourceLookup) => {
			if (_resourceLookup.workOrderEmployeeId
				&& _resourceLookup.notification
				&& isEmployee(_resourceLookup.notification)
				&& _resourceLookup.notification?.employeeId === data.id
			) {
				_resourceLookup.notification.subscriptionStatus = data.subscriptionStatus;
				hasWorkOrderModalBeenUpdated = true;
			}
			return _resourceLookup;
		});
		if (hasWorkOrderModalBeenUpdated) {
			this.setState({
				workOrderModal: {
					...workOrderModal!,
					workOrderResourceLookups: newResourceLookups ?? [],
				},
			});
		}
	};

	updateTemporaryEmployeeSubscriptionStatus = (data: ScheduleBoardTemporaryEmployee) => {
		const { workOrderModal } = this.state;

		let hasWorkOrderModalBeenUpdated = false;
		const newResourceLookups = workOrderModal?.workOrderResourceLookups?.map((_resourceLookup) => {
			if (_resourceLookup.workOrderTemporaryEmployeeId
				&& _resourceLookup.notification
				&& isTemporaryEmployee(_resourceLookup.notification)
				&& _resourceLookup.notification?.temporaryEmployeeId === data.id
			) {
				_resourceLookup.notification.subscriptionStatus = data.subscriptionStatus;
				hasWorkOrderModalBeenUpdated = true;
			}
			return _resourceLookup;
		});
		if (hasWorkOrderModalBeenUpdated) {
			this.setState({
				workOrderModal: {
					...workOrderModal!,
					workOrderResourceLookups: newResourceLookups ?? [],
				},
			});
		}
	};

	publishOrder = async () => {
		const { publishOrder, openDelayModal, closeOrderInfoModal, workOrder, actionsEnabled } = this.props;
		const { workOrderModal } = this.state;

		if (!actionsEnabled) {
			return;
		}

		if (!workOrder || !workOrderModal) {
			this.setState(() => ({
				rejectionModalOpen: true,
				rejectionModalText: OrderInfoModal.workOrderProblemMessage,
			}));
			return;
		}

		if (workOrder.reviewStatus === WorkOrderReviewStatus.FINAL) {
			this.setState(() => ({
				rejectionModalOpen: true,
				rejectionModalText: OrderInfoModal.workOrderFinalizedMessage,
			}));
			return;
		}

		if (workOrderModal.isLate && workOrderModal.isFirst) {
			openDelayModal();
		} else {
			await publishOrder(workOrderModal?.id);
			closeOrderInfoModal();
		}
	};

	openCancelModal = () => {
		const { openCancelModal, actionsEnabled } = this.props;

		if (!actionsEnabled) {
			return;
		}

		openCancelModal();
	};

	openNotifyParticipantsModal = () => {
		const { openNotifyParticipantsModal, actionsEnabled } = this.props;

		if (!actionsEnabled) {
			return;
		}

		openNotifyParticipantsModal();
	};

	openNotificationPreviewModal = () => {
		const { openNotificationPreviewModal, actionsEnabled } = this.props;

		if (!actionsEnabled) {
			return;
		}

		openNotificationPreviewModal();
	};

	openCopyModal = () => {
		const { openCopyModal, actionsEnabled } = this.props;
		const { workOrderModal } = this.state;

		if (!actionsEnabled) {
			return;
		}

		if (workOrderModal) {
			openCopyModal(workOrderModal.id, workOrderModal.code);
		}
	};

	onHide = () => {
		const { closeOrderInfoModal } = this.props;
		closeOrderInfoModal();
	};

	onClose = () => {
		const { closeOrderInfoModal } = this.props;
		closeOrderInfoModal(true);
	};

	openDeleteModal = () => {
		const { openDeleteModal, actionsEnabled } = this.props;
		if (!actionsEnabled) {
			return;
		}

		openDeleteModal();
	};

	openPauseModal = () => {
		const { openPauseModal, actionsEnabled } = this.props;
		if (!actionsEnabled) {
			return;
		}

		openPauseModal();
	};

	openResumeModal = () => {
		const { openResumeModal, actionsEnabled } = this.props;
		if (!actionsEnabled) {
			return;
		}

		openResumeModal();
	};

	closeRejectionModal = () => {
		this.setState(() => ({
			rejectionModalOpen: false,
			rejectionModalText: null,
		}));
	};

	renderCanceled() {
		const { workOrderRevision, isRevisionOpened } = this.props;
		const { workOrderModal } = this.state;
		const _workOrderDataToShow = isRevisionOpened ? workOrderRevision : workOrderModal;

		const updatedAt = TimeUtils.formatDate(workOrderModal?.updatedAt, TimeFormatEnum.DAY_WITH_DATE, TimeFormatEnum.ISO_DATETIME);
		return (
			<div className="work-order-info-modal__general-info work-order-info-modal__general-info--canceled">
				<div className="work-order-info-modal__general-info-left">
					<div className="work-order-info-modal__important-info">
						CANCELED
					</div>
				</div>
				<div className="work-order-info-modal__general-info-right">
					<div className="work-order-info-modal__info">
						"{_workOrderDataToShow?.cancellationReason ?? ''}"
					</div>
					<div className="work-order-info-modal__info">
						<strong>{updatedAt}</strong> by <strong>{workOrderModal?.updatedBy?.fullName}</strong>
					</div>
				</div>
			</div>
		);
	}

	renderPaused() {
		const { workOrderRevision, isRevisionOpened } = this.props;
		const { workOrderModal } = this.state;
		const _workOrderDataToShow = isRevisionOpened ? workOrderRevision : workOrderModal;

		const updatedAt = TimeUtils.formatDate(_workOrderDataToShow?.updatedAt, TimeFormatEnum.DAY_WITH_DATE, TimeFormatEnum.ISO_DATETIME);
		return (
			<div className="work-order-info-modal__general-info work-order-info-modal__general-info--paused">
				<div className="work-order-info-modal__general-info-left">
					<div className="work-order-info-modal__important-info">
						ON HOLD
					</div>
				</div>
				<div className="work-order-info-modal__general-info-right">
					<div className="work-order-info-modal__info">
						"{_workOrderDataToShow?.pauseReason}"
					</div>
					<div className="work-order-info-modal__info">
						<strong>{updatedAt}</strong> by <strong>{_workOrderDataToShow?.updatedBy?.fullName}</strong>
					</div>
				</div>
			</div>
		);
	}

	selectRevision = async (revisionId: number, isCurrentWorkOrder: boolean) => {
		const { selectRevision } = this.props;
		await selectRevision(revisionId, isCurrentWorkOrder);
	};

	renderDataModal = () => {
		const {
			companyName,
			hasPermissionsToEditScheduleBoard,
			history,
			isAllowedToViewProdData,
			isAllowedToViewReports,
			isRevisionOpened,
			notificationsDisabled,
			orgAlias,
			scheduleBoardView,
			workOrderRevision,
			showModal,
			generatePreview,
			actionsEnabled,
			attachmentsLocked,
		} = this.props;
		const { workOrderModal } = this.state;

		const workOrderStatus = workOrderModal?.status;
		const isLocked = !!workOrderModal?.locked;
		const isWorkOrderLoaded = !!workOrderModal?.workOrderId;
		const isReadOnly = !hasPermissionsToEditScheduleBoard || isWorkOrderLoaded || isRevisionOpened;
		const isWorkOrderLocked = workOrderStatus === WorkOrderStatus.LOCKED;

		const _workOrderDataToShow = isRevisionOpened ? workOrderRevision : workOrderModal;
		const isPaused = _workOrderDataToShow?.isPaused ?? _workOrderDataToShow?.workOrderStatus === WorkOrderStatusRevision.PAUSED;
		const isInternal = _workOrderDataToShow?.isJobInternal;
		const isCanceled = _workOrderDataToShow?.status === WorkOrderStatus.CANCELED;
		const showRevisionDropdown = workOrderStatus !== WorkOrderStatus.DRAFT;

		const isDraft = workOrderStatus === WorkOrderStatus.DRAFT;
		const displayStatusIcon = isRevisionOpened ? WorkOrderStatus.PUBLISHED : workOrderStatus;

		return (
			<CustomModal
				className="work-order-info-modal"
				closeModal={this.onClose}
				modalStyle="info"
				showModal={showModal}
				size="xl"
			>
				<CustomModal.Header
					className="work-order-info-modal__header"
				>
					<div className="work-order-info-modal__header-left">
						{!isCanceled &&
							<StatusIcon
								firstRevision={_workOrderDataToShow?.revision ? isFirstRevision(_workOrderDataToShow.revision) : false}
								isPaused={isPaused}
								notificationsEnabled={!notificationsDisabled}
								notificationStatus={_workOrderDataToShow?.notificationStatus}
								status={displayStatusIcon}
							/>
						}
						<Tooltip message={`${_workOrderDataToShow?.code}${_workOrderDataToShow?.revision && !isDraft ? `, Rev. ${_workOrderDataToShow?.revision}` : ''}${_workOrderDataToShow?.statusBasedRevision ? `.${_workOrderDataToShow.statusBasedRevision}` : ''}`}>
							<span className="work-order-info-modal__header-title">
								{_workOrderDataToShow?.code}
								{_workOrderDataToShow?.revision && !isDraft ? `, Rev. ${_workOrderDataToShow?.revision}` : ''}
								{_workOrderDataToShow?.statusBasedRevision ? `.${_workOrderDataToShow.statusBasedRevision}` : ''}
							</span>
						</Tooltip>
						{(!!isWorkOrderLocked || !!isPaused || !!isReadOnly) &&
							<span className="work-order-info-modal__header-read-only">Read-only</span>
						}
						{isInternal &&
							<BadgeCell
								badgeColor={ColorHex.BLACK}
								colorNegative={false}
								text="INTERNAL"
							/>
						}
					</div>
					<div className="work-order-info-modal__header-right">
						<div className="work-order-info-modal__header-details">
							<LabelWithColor
								color={isInternal ? AdditionalColors.BLACK.toLowerCase() : _workOrderDataToShow?.crewTypeColor ?? null}
								text={(isInternal ? _workOrderDataToShow?.customCrewType : _workOrderDataToShow?.crewTypeName)?.toUpperCase() ?? null}
							/>
						</div>
						{!isReadOnly && workOrderModal && scheduleBoardView &&
							<EditButton
								companyName={companyName}
								disabled={isLocked}
								onHide={this.onHide}
								orgAlias={orgAlias}
								scheduleBoardView={scheduleBoardView}
								text={isCanceled || isWorkOrderLocked || isPaused ? 'View' : 'Edit'}
								workOrderId={workOrderModal.id}
							/>
						}
						{!_workOrderDataToShow?.workOrderId &&
							actionsEnabled &&
							<SettingsButton
								additionalValidationCondition={workOrderModal?.isValid}
								code={workOrderModal?.code}
								isPaused={isPaused}
								jobStatus={workOrderModal?.jobStatus}
								notificationsDisabled={notificationsDisabled}
								openCancelModal={this.openCancelModal}
								openCopyModal={this.openCopyModal}
								openDeleteModal={this.openDeleteModal}
								openNotificationPreviewModal={this.openNotificationPreviewModal}
								openNotifyParticipantsModal={this.openNotifyParticipantsModal}
								openPauseModal={this.openPauseModal}
								openResumeModal={this.openResumeModal}
								previewOrder={generatePreview}
								publishOrder={this.publishOrder}
								readOnly={isReadOnly}
								status={workOrderStatus}
								validationState={workOrderModal?.validationState}
								workOrderId={workOrderModal?.id}
							/>
						}
					</div>
				</CustomModal.Header>
				<CustomModal.Body>
					{
						!workOrderModal || !_workOrderDataToShow
							? <LoadingIndicator />
							: <>
								{isCanceled && this.renderCanceled()}
								{isPaused && this.renderPaused()}
								{showRevisionDropdown &&
									<>
										<RevisionDropdown
											selectRevision={this.selectRevision}
											workOrder={workOrderModal}
										/>
										<hr />
									</>
								}
								<CurrentStatusSection
									currentWorkOrderData={workOrderModal}
									workOrderDataToShow={_workOrderDataToShow}
								/>
								<hr />
								<ShiftSection
									currentWorkOrderData={workOrderModal}
									workOrderDataToShow={_workOrderDataToShow}
								/>
								<hr />
								<WorkLocationsSection
									currentWorkOrderData={workOrderModal}
									workOrderDataToShow={_workOrderDataToShow}
								/>
								<hr />
								<SiteContactSection
									currentSiteContact={workOrderModal.siteContact}
									siteContactToShow={_workOrderDataToShow.siteContact}
								/>
								<hr />
								<DeliverableSection
									currentWorkOrderData={workOrderModal}
									workOrderDataToShow={_workOrderDataToShow}
								/>
								{/* Deliverable section has its own conditioned <hr /> */}
								<NotesSection
									currentWorkOrderData={workOrderModal}
									workOrderDataToShow={_workOrderDataToShow}
								/>
								<hr />
								<ResourceLookupsSection
									currentWorkOrderData={workOrderModal}
									workOrderDataToShow={_workOrderDataToShow}
								/>
								<hr />
								{isAllowedToViewReports &&
									<ReportsSection
										calculatedReportDisplayStatus={_workOrderDataToShow.calculatedReportDisplayStatus}
										companyName={companyName}
										history={history}
										isFRStatusOfInterest={_workOrderDataToShow.isFRStatusOfInterest}
										orgAlias={orgAlias}
										reviewStatus={_workOrderDataToShow.reviewStatus}
										workOrderId={_workOrderDataToShow.id}
										workOrderName={_workOrderDataToShow.code}
									/>
								}
								< hr />
								<SegmentLabel label="Attachments" />
								<DirectoriesAndAttachments
									forwardedWorkOrderId={workOrderModal.id}
									isReadOnly={!actionsEnabled || attachmentsLocked}
								/>
								{
									isAllowedToViewProdData &&
									<ProductionDataSection
										currentWorkOrderData={workOrderModal}
										workOrderDataToShow={_workOrderDataToShow}
									/>
								}
								{/* Production Data section has its own conditioned <hr /> */}
							</>
					}
				</CustomModal.Body>
				<CustomModal.Footer>
					<Button
						id="work-order-modal-close"
						onClick={this.onClose}
						variant="info"
					>
						Close
					</Button>
				</CustomModal.Footer>
			</CustomModal>
		);
	};

	renderLoadingModal = () => {
		const { showModal } = this.props;
		return (
			<CustomModal
				className="work-order-info-modal"
				closeModal={this.onClose}
				modalStyle="info"
				showModal={showModal}
				size="xl"
			>
				<Loading onHide={this.onClose} />
			</CustomModal>
		);
	};

	renderPublishRejectionModalContent = () => {
		const { rejectionModalText, rejectionModalOpen } = this.state;

		return (
			<ConfirmationModal
				body={this.getRejectRepublishBody(rejectionModalText ?? OrderInfoModal.workOrderProblemMessage)}
				closeModal={this.closeRejectionModal}
				confirmAction={this.closeRejectionModal}
				confirmText="Close"
				modalStyle="danger"
				showCancel={false}
				showModal={rejectionModalOpen}
				title="Work Order can't be published"
			/>
		);
	};

	render() {
		const { workOrderModal } = this.state;

		return (
			<>
				{workOrderModal ? this.renderDataModal() : this.renderLoadingModal()}
				{this.renderPublishRejectionModalContent()}
			</>
		);
	}
}

function mapStateToProps(state: RootState, props: OwnProps) {
	const {
		scheduleBoard: { scheduleBoardView },
		company: { company },
		user: { companyData, userData },
	} = state;
	const { status } = props.workOrder ?? {};
	const { hasAccess } = props;

	if (!userData || !companyData) {
		throw new Error('User not logged in');
	}

	const { isCompanyAdmin, permissions } = companyData;
	const { role } = userData;

	const isAllowedToViewProdData = isAllowed(PagePermissions.COMPANY.PROD_DATA.VIEW, permissions, isCompanyAdmin, role);
	const isManagerOrAdmin = isAllowed(PagePermissions.COMPANY.FIELD_REPORT.MANAGE, permissions, isCompanyAdmin, role);
	const canViewAll = isAllowed(PagePermissions.COMPANY.FIELD_REPORT.VIEW_ALL, permissions, isCompanyAdmin, role);
	const canEditInFinalizing = isAllowed(PagePermissions.COMPANY.FIELD_REPORT.MANAGE.FINALIZING, permissions, isCompanyAdmin, role);
	const hasPermissionToEditReports = isManagerOrAdmin || isAllowed(PagePermissions.COMPANY.FIELD_REPORT.FILL, permissions, isCompanyAdmin, role);

	const isAllowedToViewReports = (status !== WorkOrderStatus.DRAFT) && (canViewAll || isManagerOrAdmin || hasAccess);
	return {
		scheduleBoardView,
		isAllowedToViewProdData,
		isAllowedToViewReports,
		notificationsDisabled: !company?.notification?.isEnabled ?? false,
		isManagerOrAdmin,
		hasPermissionToEditReports,
		canEditInFinalizing,
		attachmentsLocked: status === WorkOrderStatus.CANCELED || status === WorkOrderStatus.LOCKED,
	};
}

const connector = connect(mapStateToProps);

export default connector(OrderInfoModal);
