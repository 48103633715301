import * as React from 'react';
import { compose } from 'redux';
import type { ConnectedProps } from 'react-redux';
import { connect } from 'react-redux';

import * as TimeUtils from 'acceligent-shared/utils/time';

import TimeFormat from 'acceligent-shared/enums/timeFormat';

import type { RootState } from 'af-reducers';

import * as ScheduleBoardActions from 'af-actions/scheduleBoard';

import { TOOLBAR_GROUP_DEFAULT_ID } from 'ab-common/constants/scheduleBoard';
import { AVAILABLE_EMPLOYEE_STATUS } from 'ab-common/constants/employee';

import EmployeeGroup from './EmployeeGroup';
import NotifyEmployeesModal from '../NotifyEmployeesModal';

interface OwnProps {
	/** MM-DD-YYYY */
	dueDate: string;
	isDragAndDropDisabled: boolean;
	hasPermissionsToEditScheduleBoard: boolean;
	hasPermissionsToSendNotifications: boolean;
	sendNotification: (dueDate: string, notifyByEmail: number[], notifyBySms: number[]) => void;
	scheduleAutoNotify: (dueDate: string, notifyByEmail: number[], notifyBySms: number[]) => void;
}

type Props = OwnProps & ConnectedProps<typeof connector>;

const AvailableEmployees: React.FC<Props> = (props) => {
	const {
		dueDate,
		employeeStatuses = [],
		hasDefault = false,
		sendNotification,
		isDragAndDropDisabled,
		hasPermissionsToEditScheduleBoard,
		hasPermissionsToSendNotifications,
		autoNotifyAt,
		autoNotifyUnassignedEmployees,
		scheduleAutoNotify,
		closeAvailableEmployeesNotificationModal,
		openAvailableEmployeesNotificationModal,
	} = props;

	const openNotifyModal = React.useCallback((groupId: number) => {
		openAvailableEmployeesNotificationModal(groupId, dueDate);
	}, [dueDate, openAvailableEmployeesNotificationModal]);

	return (
		<div className="schedule-board-toolbar-employees-row">
			<label>Labor</label>
			<div className="schedule-board-toolbar-employees-wrapper">
				<div className="schedule-board-toolbar-employees">
					{
						!!employeeStatuses && employeeStatuses.length > 0 ?
							employeeStatuses.map(({ id: _empStatusId, title: _empStatus, isDeleted }) => (
								<EmployeeGroup
									available={true}
									dueDate={dueDate}
									groupId={_empStatusId}
									groupTitle={_empStatus}
									hasPermissionsToEditScheduleBoard={hasPermissionsToEditScheduleBoard}
									hasPermissionsToSendNotifications={hasPermissionsToSendNotifications}
									isDeleted={isDeleted}
									isDragAndDropDisabled={isDragAndDropDisabled}
									key={_empStatusId}
									openNotifyModal={openNotifyModal}
								/>
							)) :
							(
								hasDefault &&
								<EmployeeGroup
									available={true}
									dueDate={dueDate}
									groupId={TOOLBAR_GROUP_DEFAULT_ID}
									groupTitle={AVAILABLE_EMPLOYEE_STATUS}
									hasPermissionsToEditScheduleBoard={hasPermissionsToEditScheduleBoard}
									hasPermissionsToSendNotifications={hasPermissionsToSendNotifications}
									isDeleted={false}
									isDragAndDropDisabled={isDragAndDropDisabled}
									openNotifyModal={openNotifyModal}
								/>
							)
					}
				</div>
			</div>
			<NotifyEmployeesModal
				autoNotifyAt={autoNotifyAt ?? null}
				autoNotifyUnassignedEmployees={!!autoNotifyUnassignedEmployees}
				closeModal={closeAvailableEmployeesNotificationModal}
				dueDate={dueDate}
				scheduleAutoNotify={scheduleAutoNotify}
				sendNotification={sendNotification}
			/>
		</div>
	);
};

function mapStateToProps(state: RootState, ownProps: OwnProps) {
	const { dueDate } = ownProps;
	const { company } = state.company;

	if (!state.scheduleBoard.workOrdersByDateDictionary[dueDate]) {
		return {};
	}
	const { toolbarEmployees } = state?.scheduleBoard?.workOrdersByDateDictionary?.[dueDate];
	const { toolbarEmployeeGroupTitles } = state?.scheduleBoard;

	const selectedDayOfWeek = TimeUtils.parseMoment(dueDate, TimeFormat.DATE_ONLY)?.day();
	const dayBefore = selectedDayOfWeek ? selectedDayOfWeek - 1 : 6;

	return {
		employeeStatuses: toolbarEmployeeGroupTitles?.available,
		hasDefault: !!toolbarEmployees.available[TOOLBAR_GROUP_DEFAULT_ID],
		autoNotifyAt: company?.notification?.notifyOnDay?.[dayBefore],
		autoNotifyUnassignedEmployees: company?.notification?.notifyUnassignedEmployees ?? false,
	};
}

function mapDispatchToProps() {
	return {
		openAvailableEmployeesNotificationModal: ScheduleBoardActions.openAvailableEmployeesNotificationModal,
		closeAvailableEmployeesNotificationModal: ScheduleBoardActions.closeAvailableEmployeesNotificationModal,
	};
}

const connector = connect(mapStateToProps, mapDispatchToProps());

const enhance = compose<React.ComponentType<OwnProps>>(
	React.memo,
	connector
);

export default enhance(AvailableEmployees);
