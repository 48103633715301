import * as React from 'react';
import type { DraggableProvided, DraggableStateSnapshot, DraggableElement } from 'react-beautiful-dnd';
import { Draggable } from 'react-beautiful-dnd';
import type { ConnectedProps } from 'react-redux';
import { connect } from 'react-redux';

import TimeFormat from 'acceligent-shared/enums/timeFormat';

import * as TimeUtils from 'acceligent-shared/utils/time';

import type { RootState } from 'af-reducers';

import * as ScheduleBoardActions from 'af-actions/scheduleBoard';

import { useScheduleBoardModals } from 'af-root/hooks/useScheduleBoardModal';

import DraggableTemporaryEmployee from './Draggable';

interface OwnProps {
	temporaryEmployeeId: number;
	draggableId: string;
	index: number;
	droppableId: string;
	/** `MM-DD-YYYY` */
	dueDate: string;
	isWorkOrderCanceled?: boolean;
	isCopyPlaceholder?: boolean;
	isCardDisabled?: boolean;
	isCalculationsView?: boolean;
	resourceId?: number;
	workOrderTemporaryEmployeeId?: number;
	isDragAndDropDisabled: boolean;
	workOrderCode?: string;
	isWOLocked: boolean;
}

type Props = OwnProps & ConnectedProps<typeof connector>;

interface DraggableProps extends Props {
	provided?: DraggableProvided;
	snapshot?: DraggableStateSnapshot;
}

const TemporaryEmployeeDraggable: React.FC<Props> = (props) => {
	const {
		draggableId,
		index,
		isDisabled,
		isWorkOrderCanceled,
		isCopyPlaceholder,
		isDragAndDropDisabled,
		isCalculationsView = false,
		temporaryEmployeeId,
		findWorkOrderTemporaryEmployeeById,
		workOrderTemporaryEmployeeId,
		dueDate,
	} = props;

	const { setTemporaryEmployeeModalData } = useScheduleBoardModals();

	const [isModalOpenedForCurrentEmployee, setIsModalOpenedForCurrentEmployee] = React.useState(false);

	const fetchModalData = React.useCallback(async () => {
		if (!workOrderTemporaryEmployeeId) {
			throw new Error('Resource draggable is missing workOrderTemporaryEmployeeId');
		}

		const _dueDate = TimeUtils.formatDate(dueDate, TimeFormat.DB_DATE_ONLY, TimeFormat.DATE_ONLY);
		const employee = await findWorkOrderTemporaryEmployeeById(workOrderTemporaryEmployeeId, _dueDate);

		setTemporaryEmployeeModalData(employee, dueDate);
		setIsModalOpenedForCurrentEmployee(true);
	}, [dueDate, findWorkOrderTemporaryEmployeeById, setTemporaryEmployeeModalData, workOrderTemporaryEmployeeId]);

	React.useEffect(() => {
		if (isModalOpenedForCurrentEmployee && !isDisabled) {
			setTemporaryEmployeeModalData(null, null);
			setIsModalOpenedForCurrentEmployee(false);
			fetchModalData();
		}
		// eslint-disable-next-line react-hooks/exhaustive-deps
	}, [isDisabled]); // Reload only on isDisabled change

	const openModal = React.useCallback(() => {
		if (isCalculationsView) {
			return;
		}
		fetchModalData();
	}, [fetchModalData, isCalculationsView]);

	const renderDraggable = React.useCallback((draggableProps: DraggableProps) => {
		const {
			isCardDisabled,
			resourceId,
			workOrderCode,
			provided = {} as DraggableProvided,
			snapshot = {} as DraggableStateSnapshot,
			isWOLocked,
		} = draggableProps;

		if (!resourceId) {
			throw new Error('Resource ID not provided');
		}

		return (
			<DraggableTemporaryEmployee
				draggableProps={provided.draggableProps}
				draggingOver={snapshot.draggingOver}
				dragHandleProps={provided.dragHandleProps}
				dueDate={dueDate}
				innerRef={provided.innerRef}
				isCardDisabled={!!isCardDisabled}
				isDragAndDropDisabled={isDragAndDropDisabled}
				isDragging={snapshot.isDragging}
				isDropAnimating={snapshot.isDropAnimating}
				isWOLocked={isWOLocked}
				isWorkOrderCanceled={isWorkOrderCanceled}
				onClick={openModal}
				resourceId={resourceId}
				temporaryEmployeeId={temporaryEmployeeId}
				workOrderCode={workOrderCode}
			/>
		);
	}, [dueDate, isDragAndDropDisabled, isWorkOrderCanceled, openModal, temporaryEmployeeId]);

	// react-beautiful-dnd can not create Draggable and Droppable elements/
	// while we are dragging so in order to mimic the copied card, we render
	// div instead of a Draggable (same for Droppable in parent component)
	if (isCopyPlaceholder || isDragAndDropDisabled) {
		return (
			<div>
				{renderDraggable(props)}
			</div>
		);
	}

	return (
		<Draggable
			draggableId={draggableId}
			index={index}
			isDragDisabled={!!isDisabled}
		>
			{(provided, snapshot) => (
				React.createElement(renderDraggable, { ...props, provided, snapshot }, null) as DraggableElement<DraggableProps>
			)}
		</Draggable>
	);
};

function mapStateToProps(state: RootState, ownProps: OwnProps) {
	const { temporaryEmployeeId } = ownProps;
	const { temporaryEmployees, draggedTemporaryEmployeeId } = state.scheduleBoard;

	const temporaryEmployee = temporaryEmployees?.[temporaryEmployeeId];

	return {
		isDisabled: temporaryEmployee?.isDisabled && !draggedTemporaryEmployeeId,
	};
}

function mapDispatchToProps() {
	return {
		findWorkOrderTemporaryEmployeeById: ScheduleBoardActions.findWorkOrderTemporaryEmployeeById,
	};
}

const connector = connect(mapStateToProps, mapDispatchToProps());

export default connector(TemporaryEmployeeDraggable);
