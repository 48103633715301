import * as React from 'react';
import type { CustomRouteComponentProps } from 'react-router-dom';
import type { ResolveThunks } from 'react-redux';
import { connect } from 'react-redux';

import type { RootState } from 'af-reducers';

import * as EquipmentCostActions from 'af-actions/equipmentCost';

import LastUpdatedByCell from 'af-components/Table6/Cells/LastUpdatedByCell';
import type { TabProps, Column, ButtonData } from 'af-components/Table6';
import Table from 'af-components/Table6';
import LabelWithColor from 'af-components/LabelWithColor';
import SkillsCell from 'af-components/Table6/Cells/SkillsCell';
import Breadcrumbs from 'af-components/Breadcrumbs';

import type { EquipmentCostTableRowViewModel } from 'ab-viewModels/equipmentCostTable.viewModel';

import CLIENT from 'af-constants/routes/client';

import TableNameEnum from 'ab-enums/tableName.enum';
import TableButtonType from 'ab-enums/tableButtonType.enum';

import * as FormattingUtils from 'ab-utils/formatting.util';

type OwnProps = CustomRouteComponentProps;

interface StateProps {
	companyName: string;
}

interface DispatchProps {
	findAllForCompanyTree: typeof EquipmentCostActions.findAllForCompanyTree;
	softDeleteEquipmentCost: typeof EquipmentCostActions.softDeleteEquipmentCost;
	findAllForCompanyTable: typeof EquipmentCostActions.findAllForCompanyTable;
}

type Props = ResolveThunks<DispatchProps> & StateProps & OwnProps;

const TYPE_COLUMN_MIN_WIDTH = 40;
const UPDATED_COLUMN_WIDTH = 200;

const DELETE_CONFIRMATION_BODY = (
	<>
		If deleted, the equipment cost(s) will no longer be assignable to new Equipment.
		<br />
		Equipment already using it will not be affected.
		<br />
		This action cannot be undone.
	</>
);

class EquipmentCostList extends React.PureComponent<Props> {

	static readonly BREADCRUMBS = [{ label: 'Equipment Cost' }];

	static readonly COLUMNS: Column<EquipmentCostTableRowViewModel>[] = [
		{
			Header: 'Type',
			accessor: 'category.group.type',
			minWidth: TYPE_COLUMN_MIN_WIDTH,
			resizable: false,
			Cell: ({ original }) => original.category?.group?.type?.[0],
		},
		{
			Header: 'Group',
			accessor: 'category.group.name',
			Cell: ({ original }) => original.category?.group?.name,
		},
		{
			Header: 'Category',
			accessor: 'category.name',
			Cell: ({ original }) => original.category?.name,
		},
		{
			Header: 'Subcategory',
			accessor: 'subcategory',
			Cell: ({ original }) => <LabelWithColor color={original.categoryColor} text={original.subcategory} />,
		},
		{
			Header: 'Skills',
			accessor: 'skills',
			sortable: false,
			Cell: ({ original }) => <SkillsCell skills={original.skills} />,
		},
		{
			Header: 'Daily Cost',
			accessor: 'dailyCost',
			Cell: ({ original }) => `${FormattingUtils.moneyNormalizer(original.dailyCost)}/DY`,
		},
		{
			Header: 'Operating Hour Charge',
			accessor: 'operatingCharge',
			Cell: ({ original }) => `${FormattingUtils.moneyNormalizer(original.operatingCharge)}/HR`,
		},
		{
			Header: 'Fuel Cost',
			accessor: 'fuelCost',
			Cell: ({ original }) => `${FormattingUtils.moneyNormalizer(original.fuelCost)}/HR`,
		},
		{
			Header: 'Updated',
			width: UPDATED_COLUMN_WIDTH,
			headerClassName: 'align-right',
			accessor: 'updatedAt',
			Cell: ({ original }) => <LastUpdatedByCell updatedAt={original.updatedAt} updatedBy={original.updatedBy} />,
		},
	];

	static deleteEquipmentCostModalTitle = () => 'Are you sure you want to delete this Equipment Cost?';
	static deleteEquipmentCostModalBody = () => DELETE_CONFIRMATION_BODY;
	static deleteEquipmentCostModalText = () => 'Delete Equipment Cost';

	async componentDidMount() {
		const { findAllForCompanyTree } = this.props;
		await findAllForCompanyTree();
	}

	deleteEquipmentCost = async (original: EquipmentCostTableRowViewModel) => {
		const { softDeleteEquipmentCost } = this.props;
		await softDeleteEquipmentCost(original.id);
	};

	tabs = (): TabProps<EquipmentCostTableRowViewModel>[] => {
		const {
			findAllForCompanyTable,
			location: { state: { orgAlias } },
			companyName,
			history,
		} = this.props;

		const buttons: ButtonData[] = [
			{
				type: TableButtonType.LINK,
				hasPermission: true,
				label: 'CSV Import',
				icon: 'upload',
				onClick: async () => history.push(CLIENT.COMPANY.RESOURCES.EQUIPMENT_COST.BULK_UPLOAD(orgAlias, companyName)),
			},
			{
				type: TableButtonType.PRIMARY,
				hasPermission: true,
				label: 'New Equipment Cost',
				icon: 'plus',
				onClick: async () => history.push(CLIENT.COMPANY.RESOURCES.EQUIPMENT_COST.CREATE(orgAlias, companyName)),
			},
		];

		return [
			{
				label: 'Equipment Cost',
				columns: EquipmentCostList.COLUMNS,
				selectable: true,
				hasSearchInput: true,
				searchLabel: 'Equipment Cost',
				buttons,
				fetch: findAllForCompanyTable,
				bulkDeleteConfirmationBody: DELETE_CONFIRMATION_BODY,
				rowActions: [
					{
						label: 'Delete',
						action: this.deleteEquipmentCost,
						hasModal: true,
						modalTitle: EquipmentCostList.deleteEquipmentCostModalTitle,
						modalBody: EquipmentCostList.deleteEquipmentCostModalBody,
						modalText: EquipmentCostList.deleteEquipmentCostModalText,
						shouldRefresh: true,
					},
				],
			},
		];
	};

	render() {
		return (
			<div className="form-segment form-segment--maxi">
				<Breadcrumbs items={EquipmentCostList.BREADCRUMBS} />
				<Table
					tableName={TableNameEnum.EQUIPMENT_COST}
					tabs={this.tabs()}
				/>
			</div>
		);
	}
}

function mapStateToProps(state: RootState): StateProps {
	const { companyData } = state.user;
	if (!companyData) {
		throw new Error('User not logged in');
	}

	return {
		companyName: companyData.name,
	};
}

function mapDispatchToProps(): DispatchProps {
	return {
		findAllForCompanyTree: EquipmentCostActions.findAllForCompanyTree,
		softDeleteEquipmentCost: EquipmentCostActions.softDeleteEquipmentCost,
		findAllForCompanyTable: EquipmentCostActions.findAllForCompanyTable,
	};
}

export default connect<StateProps, DispatchProps, OwnProps>(mapStateToProps, mapDispatchToProps())(EquipmentCostList);
