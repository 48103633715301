import type RejectReviewRM from 'ab-requestModels/workOrder/rejectReview';

import { WorkOrderReviewLevel } from 'acceligent-shared/enums/workOrderReviewStatus';

import { isValidTextInput } from 'acceligent-shared/utils/text';

export const validateRejectForm = (values: RejectReviewRM) => {
	const errors: ValidationErrors = {};

	if (!isValidTextInput(values.rejectReason)) {
		errors.rejectReason = 'Reject reason is required';
	}

	if (values.targetLevel < WorkOrderReviewLevel.LEVEL_0) {
		errors.targetLevel = 'Target level is required';
	}

	// Allow rejecting if there are no field workers
	if (values.targetLevel !== WorkOrderReviewLevel.LEVEL_0 && !values.assignedToIds?.length) {
		errors.assignedToIds = 'Reject to is required';
	}

	return errors;
};
