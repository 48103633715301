import baseRoute from '../baseRoute';

export * from './viewModel';
export * from './queryParams';

type RouteDefinition = `${typeof baseRoute}/jobPayroll${`?startDate=${string}&endDate=${string}` | ''}`;

export const ROUTE: RouteDefinition = `${baseRoute}/jobPayroll`;

export const URL = (startDate: string, endDate: string): RouteDefinition =>
	`${baseRoute}/jobPayroll?startDate=${startDate}&endDate=${endDate}`;
