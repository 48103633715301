import * as React from 'react';
import type { WrappedFieldArrayProps } from 'redux-form';
import { Draggable } from 'react-beautiful-dnd';

import FormFieldArrayStatusEnum from 'acceligent-shared/enums/formFieldArrayStatus';

import type { ReportTypeListVM } from 'ab-viewModels/reportType/reportTypeList.viewModel';

import ReportType from './ReportType';

import type WorkOrderFM from '../../formModel';

export interface OwnProps {
	hasReorderReportTypesPermission: boolean;
	selector: (fieldName: string) => unknown;
	resetTimer: () => void;
	loadReportTypes: () => Promise<ReportTypeListVM>;
	change: (fieldName: string, value: unknown) => void;
	isEditable: boolean;
	disabled?: boolean;
}

interface State {
	reportTypeOptions: ReportTypeListVM;
	reportTypes: Nullable<ReportTypeListVM>;
}

type Props = OwnProps & WrappedFieldArrayProps<WorkOrderFM['workOrderReportTypeLookup'][0]>;

class ReportTypeArray extends React.PureComponent<Props, State> {

	state: State = {
		reportTypes: null,
		reportTypeOptions: [],
	};

	static reduceSelectedReportTypes = (acc: { [reportTypeId: number]: true; }, item: WorkOrderFM['workOrderReportTypeLookup'][0]) => {
		if (item.status !== FormFieldArrayStatusEnum.REMOVED && item.reportTypeLookup.reportTypeId) {
			acc[item.reportTypeLookup.reportTypeId] = true;
		}
		return acc;
	};

	filterSelectableReportTypesStateSetter = (state: State) => {
		const { reportTypes } = state;
		const { selector } = this.props;

		if (!reportTypes) {
			return { reportTypeOptions: [] };
		}

		const selectedReportTypes = (selector('workOrderReportTypeLookup') ?? []) as WorkOrderFM['workOrderReportTypeLookup'];
		const selectedReportTypeMap = selectedReportTypes.reduce(ReportTypeArray.reduceSelectedReportTypes, {});

		return {
			reportTypeOptions: reportTypes.filter((_rt) => !selectedReportTypeMap[_rt.id]),
		};
	};

	filterSelectableReportTypes = () => this.setState(this.filterSelectableReportTypesStateSetter);

	lazyLoadReportTypes = async () => {
		const { loadReportTypes } = this.props;

		const reportTypes = await loadReportTypes();
		this.setState(() => ({ reportTypes }), this.filterSelectableReportTypes);
	};

	onAdd = () => {
		const { resetTimer, fields } = this.props;

		fields.insert(fields.length, {
			status: FormFieldArrayStatusEnum.ADDED,
			reportTypeLookup: {
				index: fields.length,
			} as WorkOrderFM['workOrderReportTypeLookup'][0]['reportTypeLookup'],
		});

		this.filterSelectableReportTypes();
		resetTimer();
	};

	onRemove = (index: number) => {
		const { resetTimer, fields, change } = this.props;

		const reportType = fields.get(index);

		if (reportType.status === FormFieldArrayStatusEnum.ADDED) {
			fields.remove(index);
		} else {
			change(`workOrderReportTypeLookup[${index}].status`, FormFieldArrayStatusEnum.REMOVED);
		}

		this.filterSelectableReportTypes();
		resetTimer();
	};

	onChange = (index: number, value: ReportTypeListVM[0]) => {
		const { resetTimer, fields, change } = this.props;

		const reportType = fields.get(index);

		change(`workOrderReportTypeLookup[${index}].reportTypeLookup.id`, value.id);
		change(`workOrderReportTypeLookup[${index}].reportTypeLookup.reportTypeId`, value.id);
		change(`workOrderReportTypeLookup[${index}].reportTypeLookup.name`, value.name);
		change(`workOrderReportTypeLookup[${index}].reportTypeLookup.index`, index);

		if (reportType.status !== FormFieldArrayStatusEnum.ADDED) {
			change(`workOrderReportTypeLookup[${index}].status`, FormFieldArrayStatusEnum.EDITED);
		}

		this.filterSelectableReportTypes();
		resetTimer();
	};

	renderDraggable = (field: string, index: number) => {
		const { selector, hasReorderReportTypesPermission, isEditable, disabled = false } = this.props;
		const { reportTypeOptions } = this.state;
		return (
			<Draggable
				draggableId={`${index}`}
				index={index}
				isDragDisabled={!hasReorderReportTypesPermission || disabled}
				key={index}
			>
				{(provided) => (
					<div
						{...provided?.draggableProps}
						{...provided?.dragHandleProps}
						className="work-order-upsert__report-type-item"
						ref={provided?.innerRef}
						style={provided.draggableProps.style}
					>
						<ReportType
							fieldName={field}
							hasReorderReportTypesPermission={hasReorderReportTypesPermission}
							index={index}
							isEditable={isEditable}
							lazyLoadOptions={this.lazyLoadReportTypes}
							onChange={this.onChange}
							onRemove={this.onRemove}
							reportTypes={reportTypeOptions}
							selector={selector}
						/>
					</div>
				)}
			</Draggable>
		);
	};

	render() {
		const { fields, isEditable, disabled = false } = this.props;
		return (
			<>
				{fields.map(this.renderDraggable)}
				{
					isEditable && !disabled &&
					<span className="btn btn--link work-order-upsert__add-report-type" onClick={this.onAdd}>
						<span className="icon-plus" />
						Add Field Report Type
					</span>
				}
			</>
		);
	}
}

export default ReportTypeArray;
