import * as React from 'react';
import { compose } from 'redux';
import type { ConnectedProps } from 'react-redux';
import { connect } from 'react-redux';
import type { InjectedFormProps, FormErrorsWithArray } from 'redux-form';
import { reduxForm, formValueSelector, getFormSyncErrors } from 'redux-form';
import type { CustomRouteComponentProps } from 'react-router-dom';
import { useLocation, useNavigate, useParams } from 'react-router-dom-v5-compat';

import type { RootState } from 'af-reducers';

import { TOOL_REPAIR_UPDATE } from 'af-constants/reduxForms';
import CLIENT from 'af-constants/routes/client';

import * as ToolRepairActions from 'af-actions/toolRepair';

import Breadcrumbs from 'af-components/Breadcrumbs';
import Loading from 'af-components/Loading';

import FormModel from '../Shared/formModel';
import Form from '../Shared/Form';
import { validate } from '../Shared/validation';

type Props = ConnectedProps<typeof connector> & InjectedFormProps<FormModel> & CustomRouteComponentProps;

const Edit = (props: Props) => {
	const { update, findById, companyName, externalRepair, initialize, errors } = props;
	const [loading, setLoading] = React.useState(false);
	const navigate = useNavigate();
	const location = useLocation();
	const { id } = useParams();

	React.useEffect(() => {
		if (!id) {
			navigate(CLIENT.COMPANY.TOOL_REPAIR.TABLE(location.state.orgAlias, companyName));
		}
		setLoading(true);
	}, [companyName, navigate, location.state.orgAlias, id]);

	React.useEffect(() => {
		if (loading) {
			const loadStuff = async () => {
				const toolRepair = await findById(parseInt(id!, 10));
				initialize(new FormModel(toolRepair));
				setLoading(false);
			};
			loadStuff();
		}
	}, [findById, id, initialize, loading]);

	const updateRepair = React.useCallback(async (form: FormModel) => {
		await update(parseInt(id!, 10), FormModel.toRequestModel(form));
		navigate(CLIENT.COMPANY.TOOL_REPAIR.TABLE(location.state.orgAlias, companyName));
	}, [update, id, navigate, location.state.orgAlias, companyName]);

	if (loading) {
		return <Loading />;
	}

	return (
		<div className="form-segment">
			<Breadcrumbs
				items={[
					{ label: 'Tool Repairs', url: CLIENT.COMPANY.TOOL_REPAIR.TABLE(location.state.orgAlias, companyName) },
					{ label: 'Request' },
				]}
			/>
			<Form
				{...props}
				errors={errors}
				externalRepair={externalRepair}
				onSubmit={updateRepair}
			/>
		</div>
	);
};

const formSelector = formValueSelector(TOOL_REPAIR_UPDATE);

interface FormActionWrapper {
	selector: <T extends keyof FormModel>(fieldName: T) => FormModel[T];
}
const formActionWrapper: Partial<FormActionWrapper> = {
	selector: undefined,
};

function mapStateToProps(state: RootState) {
	const { companyData } = state.user;
	if (!companyData) {
		throw new Error('User not logged in');
	}

	formActionWrapper.selector = (fieldName: keyof FormModel) => formSelector(state, fieldName);
	const errors = getFormSyncErrors(TOOL_REPAIR_UPDATE)(state) as FormErrorsWithArray<FormModel, string>;

	return {
		externalRepair: formSelector(state, 'isExternal'),
		formActionWrapper: formActionWrapper as FormActionWrapper, // selector is no longer undefined
		companyName: companyData.name,
		errors,
	};
}

function mapDispatchToProps() {
	return {
		update: ToolRepairActions.update,
		findById: ToolRepairActions.findById,
	};
}

const connector = connect(mapStateToProps, mapDispatchToProps());

const enhance = compose<React.ComponentClass>(
	React.memo,
	reduxForm({
		form: TOOL_REPAIR_UPDATE,
		validate,
		keepDirtyOnReinitialize: true,
		enableReinitialize: true,
	}),
	connector
);

export default enhance(Edit);
