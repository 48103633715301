/* eslint-disable react/no-find-dom-node */
import * as React from 'react';
import { Button } from 'react-bootstrap';
import type { ConnectedProps } from 'react-redux';
import { connect } from 'react-redux';

import type { SignatureFieldVM } from 'acceligent-shared/utils/fieldReport';

import * as FieldReportActions from 'af-actions/fieldReport';

import { useToggle } from 'af-utils/react.util';

import SignatureModal from '../../../../../Shared/SignatureModal';
import SignatureForm from '../../../../../Shared/SignatureModal/FormModel';

import fieldHoc from '../Shared/fieldHoc';

import Preview from './Preview';
import SignatureStatus from './SignatureStatus';

interface OwnProps {
	onValueChange: (value: SignatureFieldVM) => void;
	onFocus?: () => void;
	fieldReportId: number;
	value: Nullable<SignatureFieldVM>;
	name: string;
	deleteSignature: (signature: SignatureFieldVM) => void;
	disabled?: boolean;
}

type Props = OwnProps & ConnectedProps<typeof connector>;

const SignatureField: React.FC<Props> = (props) => {
	const {
		disabled,
		onFocus,
		name,
		onValueChange,
		uploadSignature,
		fieldReportId,
		value,
		deleteSignature,
	} = props;
	const {
		value: showModal,
		setToFalse: closeModal,
		setToTrue: openModal,
	} = useToggle(false);

	React.useEffect(() => {
		if (disabled && showModal) {
			closeModal();
		}
	}, [disabled, showModal, closeModal]);

	const openModalCallback = React.useCallback(() => {
		onFocus?.();
		openModal();
	}, [onFocus, openModal]);

	const onChange = React.useCallback(async (form: SignatureForm) => {
		const _value = await uploadSignature(SignatureForm.toSignatureRM(form), fieldReportId);
		onValueChange(_value);
		closeModal();
	}, [onValueChange, closeModal, uploadSignature, fieldReportId]);

	const onDeleteSignature = React.useCallback(async () => {
		onFocus?.();
		if (value) {
			deleteSignature(value);
		}
	}, [value, deleteSignature, onFocus]);

	const renderSigned = React.useCallback(() => {
		return (
			<SignatureStatus
				name={name}
				value={value}
			>
				<Button
					disabled={disabled}
					onClick={onDeleteSignature}
					variant="link"
				>
					<span className="icon icon-close" />
					Remove {name}
				</Button>
			</SignatureStatus >
		);
	}, [name, value, disabled, onDeleteSignature]);

	const renderUnsigned = React.useCallback(() => {
		return (
			<>
				{name}
				<Button
					disabled={disabled}
					onClick={openModalCallback}
					variant="info"
				>
					Sign
				</Button>
			</>
		);
	}, [name, disabled, openModalCallback]);

	return (
		<>
			{value?.signedAt ? renderSigned() : renderUnsigned()}
			{showModal &&
				<SignatureModal
					closeModal={closeModal}
					onSubmit={onChange}
					showModal={showModal}
					showNameInput={false}
					title={`Sign ${name}`}
				/>}
		</>
	);
};

function mapDispatchToProps() {
	return {
		uploadSignature: FieldReportActions.uploadBlockFieldSignature,
	};
}

const connector = connect(null, mapDispatchToProps());

export default fieldHoc(connector(React.memo(SignatureField)), Preview as React.FC);
