import * as React from 'react';
import type { ConnectedProps } from 'react-redux';
import { connect } from 'react-redux';
import type { CustomRouteComponentProps } from 'react-router-dom';

import type { ReportBlockFieldRM, ReportBlockRM } from 'ab-requestModels/reportBlock/reportBlock.requestModel';

import * as ReportBlockActions from 'af-actions/reportBlock';

import CLIENT from 'af-constants/routes/client';

import Breadcrumbs from 'af-components/Breadcrumbs';

import type { RootState } from 'af-reducers';

import ReportBlockForm from '../Form';

import { ReportBlockFieldFormModel, ReportBlockFormModel } from '../../Shared/formModel';

type OwnProps = CustomRouteComponentProps;

type Props = OwnProps & ConnectedProps<typeof connector>;

const CreateReportBlock: React.FC<Props> = (props) => {

	const {
		reportBlock,
		fields,
		createReportBlock,
		history,
		location: { state: { orgAlias } },
		companyName,
		editReportBlock,
	} = props;

	const [refreshing, setRefreshing] = React.useState<boolean>(false);

	const create = React.useCallback(async (fieldsArray: ReportBlockFieldRM[]) => {
		if (reportBlock) {
			await createReportBlock({ block: reportBlock, fields: fieldsArray });
			history.push(CLIENT.COMPANY.SETTINGS.REPORT.TABLE(orgAlias, companyName));
		} else {
			throw new Error('Report block missing on create report block action');
		}
	}, [companyName, createReportBlock, history, orgAlias, reportBlock]);

	const editBlock = React.useCallback(async (form: ReportBlockRM, formFields: ReportBlockFieldRM[]) => {
		setRefreshing(true);
		await editReportBlock(form, formFields);
		setRefreshing(false);
	}, [editReportBlock]);

	const reportBlockFormModel = React.useMemo(() => (reportBlock && ReportBlockFormModel.fromReportBlockRequestModel(reportBlock)), [reportBlock]);
	const reportBlockFields = React.useMemo(
		() => {
			return (fields?.length && reportBlock) ? ReportBlockFieldFormModel.bulkFromReportBlockRM(fields, reportBlock?.virtualId) : [];
		}, [fields, reportBlock]);

	return (
		<div className="form-segment">
			<Breadcrumbs
				items={
					[
						{ label: 'Report Block', url: CLIENT.COMPANY.SETTINGS.REPORT.TABLE(orgAlias, companyName) },
						{ label: 'Create' },
					]
				}
			/>
			{
				reportBlockFormModel &&
				<ReportBlockForm
					fields={reportBlockFields}
					loading={refreshing}
					onEditBlock={editBlock}
					onSubmit={create}
					reportBlock={reportBlockFormModel}
				/>
			}
		</div>
	);
};

function mapStateToProps(state: RootState) {
	const { reportBlock } = state.settings;
	const { companyData } = state.user;
	if (!companyData) {
		throw new Error('User not logged in');
	}

	return {
		reportBlock: reportBlock.newReportBlock,
		fields: reportBlock.fields,
		companyName: companyData.name,
	};
}

function mapDispatchToProps() {
	return {
		createReportBlock: ReportBlockActions.create,
		editReportBlock: ReportBlockActions.setNewReportBlock,
	};
}

const connector = connect(mapStateToProps, mapDispatchToProps());

export default connector(CreateReportBlock);
