import * as React from 'react';
import { compose } from 'redux';
import type { ConnectedProps } from 'react-redux';
import { connect } from 'react-redux';
import type { InjectedFormProps } from 'redux-form';
import { reduxForm } from 'redux-form';
import type { CustomRouteComponentProps } from 'react-router-dom';

import type AgencyUpdateRM from 'ab-requestModels/agency/update.requestModel';

import type AgencyVM from 'ab-viewModels/agency/agency.viewModel';

import * as AgencyActions from 'af-actions/agency';

import AgencyForm from 'af-components/SharedForms/Resources/Agency';
import { validate } from 'af-components/SharedForms/Resources/Agency/validation';
import Breadcrumbs from 'af-components/Breadcrumbs';

import { AGENCY_EDIT } from 'af-constants/reduxForms';
import CLIENT from 'af-constants/routes/client';

import type { RootState } from 'af-reducers';

interface PathParams {
	id: string;
}

type FormProps = InjectedFormProps<AgencyUpdateRM>;

type OwnProps = CustomRouteComponentProps<PathParams>;

type FormOwnProps = OwnProps & ConnectedProps<typeof connector>;

type Props = FormOwnProps & FormProps;

const EditAgency: React.FC<Props> = (props) => {

	const {
		update,
		history,
		location: { state: { orgAlias } },
		companyName,
		match: { params: { id } },
		initialize,
		findById,
		findList,
	} = props;

	const [
		agencyList,
		setAgencyList,
	] = React.useState<Array<AgencyVM>>([]);

	React.useEffect(() => {
		initializeAgency();
		getAgencyList();
		// eslint-disable-next-line react-hooks/exhaustive-deps
	}, []);

	const initializeAgency = async () => {
		const agency = await findById(+id);
		initialize({
			name: agency?.name,
			color: agency?.color,
			email: agency?.email ?? undefined,
			phone: agency?.phone ?? undefined,
			website: agency?.website ?? undefined,
		});
	};

	const getAgencyList = async () => {
		const fetchedAgencyList = await findList();
		setAgencyList(fetchedAgencyList ?? []);
	};

	const updateAgency = async (form: AgencyUpdateRM) => {
		await update(+id, form);
		history.push(CLIENT.COMPANY.RESOURCES.AGENCY.LIST(orgAlias, companyName));
	};

	// eslint-disable-next-line @typescript-eslint/member-ordering
	const handleSubmit = props.handleSubmit(updateAgency);

	return (
		<div className="form-segment">
			<Breadcrumbs
				items={[
					{ label: 'Temp Agencies', url: CLIENT.COMPANY.RESOURCES.AGENCY.LIST(orgAlias, companyName) },
					{ label: 'Edit Temp Agency' },
				]}
			/>
			<AgencyForm
				{...props}
				agencyId={+id}
				agencyList={agencyList}
				onSubmit={handleSubmit}
			/>
		</div>
	);
};

function mapStateToProps(state: RootState) {
	const { companyData } = state.user;
	if (!companyData) {
		throw new Error('User not logged in');
	}

	return {
		companyName: companyData.name,
	};
}

function mapDispatchToProps() {
	return {
		update: AgencyActions.update,
		findById: AgencyActions.findById,
		findList: AgencyActions.findList,
	};
}

const connector = connect(mapStateToProps, mapDispatchToProps());

const enhance = compose<React.ComponentClass<OwnProps>>(
	connector,
	reduxForm<AgencyUpdateRM, FormOwnProps>({
		form: AGENCY_EDIT,
		validate,
	}),
	React.memo
);

export default enhance(EditAgency);
