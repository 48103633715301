import * as React from 'react';

import { useOutletContext } from 'react-router-dom-v5-compat';

import type { FormJobOutletContext } from 'af-root/scenes/Company/Jobs/Form/types';

import DirectoriesAttachments from 'af-components/SharedForms/Job/Details/Attachments/DirectoriesAttachments';

import { formSectionClass, formSectionClassColumn8, formSectionClassRow } from './helpers';

const AttachmentsTab: React.FC = () => {
	const { change, workRequestId } = useOutletContext<FormJobOutletContext>();
	return (
		<div className={formSectionClass}>
			<div className={formSectionClassRow}>
				<div className={formSectionClassColumn8}>
					{workRequestId
						? (
							<DirectoriesAttachments change={change} jobId={workRequestId} />
						)
						: 'In order to attach files, work request has to be saved first.'
					}
				</div>
			</div>
		</div>
	);
};

export default React.memo(AttachmentsTab);
