import type { Dispatch, AnyAction } from 'redux';

import type { TableContent } from 'ab-common/dataStructures/tableContent';

import API from 'af-constants/routes/api';

import { http } from 'af-utils/http.util';
import { errorHandler } from 'af-utils/actions.util';

import type SaleRequestModel from 'ab-requestModels/sale.requestModel';

import { TableQuery } from 'ab-common/dataStructures/tableQuery';

import type { SaleTableRowVM } from 'ab-viewModels/sale/tableRow.viewModel';

import { toRequestModelWithFormattedPhoneNumbers } from 'ab-utils/form.util';

import type { GetRootState } from 'af-reducers';

import * as BulkDeleteSalesAPI from 'ab-api/web/sale/bulkSoftDelete';
import * as CreateSaleAPI from 'ab-api/web/sale/create';
import * as DeleteSalesAPI from 'ab-api/web/sale/softDelete';
import * as FindOptionsForDropdownAPI from 'ab-api/web/sale/dropdownOptions';
import * as UpdateSalesAPI from 'ab-api/web/sale/update';

export function create(form: CreateSaleAPI.W_Sale_Create_RM) {
	return async (dispatch: Dispatch<AnyAction>, getState: GetRootState, { redirectTo }) => {

		const action = async () => {
			await http.post<void>(CreateSaleAPI.URL(), { ...form });
		};

		return await errorHandler(action, dispatch, redirectTo);
	};
}

export function update(form: SaleRequestModel) {
	return async (dispatch: Dispatch<AnyAction>, getState: GetRootState, { redirectTo }) => {

		const action = async () => {
			if (!form.id) {
				throw new Error('Sales ID not provided');
			}

			const requestBody: UpdateSalesAPI.W_Sale_Update_RM = { ...form };
			await http.put<void>(UpdateSalesAPI.URL(form.id), requestBody);
		};

		return await errorHandler(action, dispatch, redirectTo);
	};
}

export function findById(id: string) {
	return async (dispatch: Dispatch<AnyAction>, getState: GetRootState, { redirectTo }) => {

		const action = async () => {
			const sale: SaleRequestModel = await http.get(API.V1.RESOURCES.SALE.FIND_BY_ID(id));
			return toRequestModelWithFormattedPhoneNumbers(sale, ['phone', 'phoneCountryCode'], ['fax', 'faxCountryCode']);
		};

		return await errorHandler(action, dispatch, redirectTo);
	};
}

export function deleteSaleFromTable(id: number) {
	return async (dispatch: Dispatch<AnyAction>, getState: GetRootState, { redirectTo }) => {

		const action = async () => {
			await http.delete<void>(DeleteSalesAPI.URL(id));
		};

		return await errorHandler(action, dispatch, redirectTo);
	};
}

export function bulkDeleteSaleFromTable(ids: number[]) {
	return async (dispatch: Dispatch<AnyAction>, getState: GetRootState, { redirectTo }) => {

		const action = async () => {
			const requestBody: BulkDeleteSalesAPI.W_Sale_BulkSoftDelete_RM = { saleIds: ids };
			await http.delete<void>(BulkDeleteSalesAPI.URL(), requestBody);
		};

		return await errorHandler(action, dispatch, redirectTo);
	};
}

export function findAllForCompanyTable(tableRequestModel: TableQuery) {
	return async (dispatch: Dispatch<AnyAction>, getState: GetRootState, { redirectTo }) => {

		const action = async () => {
			const data = new TableQuery(tableRequestModel);
			return await http.get<TableContent<SaleTableRowVM>>(API.V1.RESOURCES.SALE.TABLE(data));
		};

		return await errorHandler(action, dispatch, redirectTo);
	};
}

export function findAllForOrganizationTable(tableRequestModel: TableQuery) {
	return async (dispatch: Dispatch<AnyAction>, getState: GetRootState, { redirectTo }) => {

		const action = async () => {
			const data = new TableQuery(tableRequestModel);
			return await http.get<TableContent<SaleTableRowVM>>(API.V1.RESOURCES.SALE.TABLE(data));
		};

		return await errorHandler(action, dispatch, redirectTo);
	};
}

export function findAllForForm() {
	return async (dispatch: Dispatch<AnyAction>, getState: GetRootState, { redirectTo }) => {

		const action = async () => {
			const result = await http.get<FindOptionsForDropdownAPI.W_Sale_DropdownOptions_VM>(FindOptionsForDropdownAPI.URL());
			return result.items;
		};

		return await errorHandler(action, dispatch, redirectTo);
	};
}
