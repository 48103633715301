import * as React from 'react';
import { Button, Modal } from 'react-bootstrap';

import TimeFormat from 'acceligent-shared/enums/timeFormat';

import * as TimeUtils from 'acceligent-shared/utils/time';

import type ScheduleBoardNightShiftWorkOrder from 'ab-viewModels/scheduleBoardNightShiftWorkOrder.viewModel';

import CustomModal from 'af-components/CustomModal';

import WorkOrderCard from '../../DisplayView/Board/Cards/WorkOrderCard';

interface OwnProps {
	dueDate: string;
	workOrders: Nullable<ScheduleBoardNightShiftWorkOrder[]>;
	closeModal: () => void;
}

type Props = OwnProps;

function renderWorkOrder(workOrder: ScheduleBoardNightShiftWorkOrder) {
	return (
		<WorkOrderCard
			hideIndex
			hideMultipleAssignmentBadges
			key={workOrder.id}
			notificationsEnabled={false}
			workOrder={workOrder}
		/>
	);
}

const NightShiftWorkOrdersModal: React.FC<Props> = (props: Props) => {
	const { closeModal, dueDate, workOrders } = props;

	const dueDateFormatted = React.useMemo(() => TimeUtils.formatDate(dueDate, TimeFormat.SB_DATE_WEEK_LONG, TimeFormat.DB_DATE_ONLY), [dueDate]);

	return (
		<CustomModal
			className="night-shift-work-orders-modal"
			closeModal={closeModal}
			modalStyle="info"
			showModal={!!workOrders}
			size="xl"
		>
			<CustomModal.Header closeModal={closeModal}>
				<Modal.Title className="night-shift-work-orders-modal--title">Night Shifts, {dueDateFormatted}</Modal.Title>
			</CustomModal.Header>
			<CustomModal.Body>
				<div className="night-shift-board">
					<div className="display-view-work-orders-wrapper">
						{workOrders?.map(renderWorkOrder) ?? null}
					</div>
				</div>
			</CustomModal.Body>
			<CustomModal.Footer>
				<Button
					onClick={closeModal}
					variant="info"
				>
					Close
				</Button>
			</CustomModal.Footer>
		</CustomModal>
	);
};

export default React.memo(NightShiftWorkOrdersModal);
