import * as React from 'react';
import type { ResolveThunks } from 'react-redux';
import { connect } from 'react-redux';

import * as LMSActions from 'af-actions/learningManagementSystem';

interface DispatchProps {
	findCornerstoneResponse: typeof LMSActions.findCornerstoneResponse;
}

type Props = ResolveThunks<DispatchProps>;

const LearningManagementSystem: React.FC<Props> = (props) => {
	const { findCornerstoneResponse } = props;
	const [acs, setAcs] = React.useState<Nullable<string>>(null);
	const [samlResponse, setSamlResponse] = React.useState<Nullable<string>>(null);

	const getSamlRespose = React.useCallback(async () => {
		const res = await findCornerstoneResponse();
		setAcs(res.acs);
		setSamlResponse(res.SAMLResponse);
	}, [findCornerstoneResponse]);

	const login = React.useCallback(async () => {
		await getSamlRespose();
		document.forms[0].submit();
	}, [getSamlRespose]);

	return (
		<>
			<li>
				<form action={`${acs}`} autoComplete="off" id="sso" method="post" target="_blank">
					<input id="resp" name="SAMLResponse" type="hidden" value={`${samlResponse}`} />
				</form>
				<div onClick={login}>
					<span className={'icon-lms sidenav-icon'} />
					Learning Management System
				</div>
			</li>
		</>
	);
};

function mapDispatchToProps(): DispatchProps {
	return {
		findCornerstoneResponse: LMSActions.findCornerstoneResponse,
	};
}

export default connect<void, DispatchProps>(null, mapDispatchToProps())(LearningManagementSystem);
