import type { Dispatch, AnyAction } from 'redux';

import type TableSettingsViewModel from 'ab-viewModels/tableSettings.viewModel';

import type TableSettingsRequestModel from 'ab-requestModels/tableSettings.requestModel';

import API from 'af-constants/routes/api';

import { http } from 'af-utils/http.util';
import { errorHandler } from 'af-utils/actions.util';

import type { GetRootState } from 'af-reducers';

export function findTableSettings(tableName: string) {
	return async (dispatch: Dispatch<AnyAction>, getState: GetRootState, { redirectTo }) => {

		const action = async () => {
			return await http.get<TableSettingsViewModel>(API.V1.TABLE_SETTINGS.FIND(tableName));
		};

		return await errorHandler(action, dispatch, redirectTo);
	};
}

export function updateTableSettings(settings: TableSettingsRequestModel) {
	return async (dispatch: Dispatch<AnyAction>, getState: GetRootState, { redirectTo }) => {

		const action = async () => {
			return await http.put<TableSettingsRequestModel>(API.V1.TABLE_SETTINGS.UPDATE(settings.tableName), settings);
		};

		return await errorHandler(action, dispatch, redirectTo);
	};
}
