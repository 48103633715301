import * as React from 'react';

import SubmitButton from 'af-components/SubmitButton';
import Tooltip from 'af-components/Tooltip';

import { bemBlock } from 'ab-utils/bem.util';

import type { ButtonData } from '../../types';

interface OwnProps {
	actionKey: ButtonData['actionKey'];
	icon: ButtonData['icon'];
	label: string | undefined;
	onClick: ButtonData['onClick'];
	id?: string;
	disabled?: boolean;
}

const PrimaryButton = ({ label, icon, actionKey, onClick, id, disabled }: OwnProps) => {
	const showIcon = !!icon;

	const primaryButtonLabel = (
		<Tooltip message={label} placement="bottom">
			{showIcon ? <span className={`icon-${icon}`} /> : <span>{label}</span>}
		</Tooltip>
	);
	const primaryButtonClass = bemBlock('btn', { icon: showIcon });

	return (
		<SubmitButton
			className={primaryButtonClass}
			disabled={disabled}
			id={id}
			label={primaryButtonLabel}
			onClick={onClick}
			submitKey={actionKey}
			variant="primary"
		/>
	);
};

export default React.memo(PrimaryButton);
