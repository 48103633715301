import * as React from 'react';
import { Link } from 'react-router-dom';

import { ColorPalette } from 'acceligent-shared/enums/color';
import type UserPermission from 'acceligent-shared/enums/userPermission';

import PagePermissions from 'ab-enums/pagePermissions.enum';

import CLIENT from 'af-routes/client';

import { isAllowed } from 'ab-utils/auth.util';

import ColorSquare from 'af-components/ColorSquare';
import SegmentLabel from 'af-components/SegmentLabel';

import MultiAssignmentsBadge from '../MultiAssignmentsBadge';
import DownEquipmentBadge from '../DownEquipmentBadge';

interface OwnProps {
	orgAlias: string;
	companyName: string;
	isAdmin: boolean;
	accountPermissions: string[];
	userPermission: UserPermission;
}

type Props = OwnProps;

interface State {
	isNameHovered: boolean;
	isSpecificationHovered: boolean;
	isEquipmentCostHovered: boolean;
	isDownEquipmentHovered: boolean;
	isMultiAssignmentsHovered: boolean;
}

class EquipmentCardLegend extends React.PureComponent<Props, State> {

	state: State = {
		isNameHovered: false,
		isSpecificationHovered: false,
		isEquipmentCostHovered: false,
		isDownEquipmentHovered: false,
		isMultiAssignmentsHovered: false,
	};

	onNameHover = (isHovered: boolean) => {
		this.setState(() => ({ isNameHovered: isHovered }));
	};

	renderName = () => {
		const { isNameHovered } = this.state;

		let className = 'legend-modal__resource-card-label legend-modal__resource-card-label--equipment';
		className = isNameHovered ? `${className} legend-modal__hovered-partial` : className;

		return (
			<span className={className}>
				EQ0001
			</span>
		);
	};

	onSpecificationHover = (isHovered: boolean) => {
		this.setState(() => ({ isSpecificationHovered: isHovered }));
	};

	renderSpecification = () => {
		const { isSpecificationHovered } = this.state;

		let className = 'legend-modal__resource-card-label legend-modal__resource-card-label--specification';
		className = isSpecificationHovered ? `${className} legend-modal__hovered-partial` : className;

		return (
			<span className={className}>
				(SP1)
			</span>
		);
	};

	onEquipmentCostHover = (isHovered: boolean) => {
		this.setState(() => ({ isEquipmentCostHovered: isHovered }));
	};

	renderEquipmentCost = () => {
		const { isEquipmentCostHovered } = this.state;

		let className = 'legend-modal__resource-card-label';
		className = isEquipmentCostHovered ? `${className} legend-modal__hovered-partial` : className;

		return (
			<>
				<span className={className}>
					Utility Vehicle
				</span>
				<span className={className}>
					<ColorSquare color={ColorPalette.PURPLE} />
				</span>
			</>
		);
	};

	onDownEquipmentHover = (isHovered: boolean) => {
		this.setState(() => ({ isDownEquipmentHovered: isHovered }));
	};

	renderDownEquipment = () => {
		const { isDownEquipmentHovered } = this.state;

		return isDownEquipmentHovered ? <DownEquipmentBadge /> : null;
	};

	onMultiAssignmentsHover = (isHovered: boolean) => {
		this.setState(() => ({ isMultiAssignmentsHovered: isHovered }));
	};

	renderMultiAssignments = () => {
		const {
			isNameHovered,
			isSpecificationHovered,
			isDownEquipmentHovered,
		} = this.state;

		const isAnythingHovered = isNameHovered || isSpecificationHovered || isDownEquipmentHovered;

		return (
			<MultiAssignmentsBadge
				count={2}
				isOpaque={isAnythingHovered}
			/>
		);
	};

	render() {
		const {
			orgAlias,
			companyName,
			accountPermissions,
			isAdmin,
			userPermission,
		} = this.props;
		const {
			isNameHovered,
			isSpecificationHovered,
			isEquipmentCostHovered,
			isDownEquipmentHovered,
			isMultiAssignmentsHovered,
		} = this.state;

		const isAnythingHoveredCard = isNameHovered || isSpecificationHovered || isDownEquipmentHovered || isMultiAssignmentsHovered;
		const isAnythingHoveredPlaceholder = isNameHovered
			|| isSpecificationHovered
			|| isDownEquipmentHovered
			|| isMultiAssignmentsHovered
			|| isEquipmentCostHovered;

		let resourceCardClass = 'legend-modal__resource-card legend-modal__resource-card--equipment';
		resourceCardClass = isEquipmentCostHovered ? `${resourceCardClass} legend-modal__hovered-partial` : resourceCardClass;

		const resourcePlaceholderCardClass = 'legend-modal__resource-card legend-modal__resource-card--placeholder';

		const isAllowedToChangeEquipment = isAllowed(PagePermissions.COMPANY.EQUIPMENT, accountPermissions, isAdmin, userPermission);
		const isAllowedToChangeEquipmentCost = isAllowed(PagePermissions.COMPANY.RESOURCES.EQUIPMENT_COST, accountPermissions, isAdmin, userPermission);

		return (
			<div className="legend-modal__segment">
				<SegmentLabel label="Equipment Card" />
				<div className="legend-modal__segment-content">
					<div className="legend-modal__resource-card-container">
						<div className={resourceCardClass}>
							{isAnythingHoveredCard && <div className="legend-modal__resource-card-curtain" />}
							{this.renderName()}
							{this.renderSpecification()}
							{this.renderMultiAssignments()}
							{this.renderDownEquipment()}
						</div>
						<div className={resourcePlaceholderCardClass}>
							{isAnythingHoveredPlaceholder && <div className="legend-modal__resource-card-curtain" />}
							{this.renderEquipmentCost()}
						</div>
					</div>
					<div className="legend-modal__description-container">
						<div
							onMouseEnter={this.onNameHover.bind(this, true)}
							onMouseLeave={this.onNameHover.bind(this, false)}
						>
							<div className="legend-modal__description">
								Equipment name
								{isAllowedToChangeEquipment &&
									<div className="legend-modal__description legend-modal__description--details">
										Equipment
										<Link
											className="btn--control"
											to={CLIENT.COMPANY.EQUIPMENT.LIST(orgAlias, companyName)}
										>
											<span className="icon-settings" />
										</Link>
									</div>
								}
							</div>
						</div>
						<div
							className="legend-modal__description"
							onMouseEnter={this.onSpecificationHover.bind(this, true)}
							onMouseLeave={this.onSpecificationHover.bind(this, false)}
						>
							Equipment specification
						</div>
						<div
							onMouseEnter={this.onEquipmentCostHover.bind(this, true)}
							onMouseLeave={this.onEquipmentCostHover.bind(this, false)}
						>
							<div className="legend-modal__description">
								Equipment Cost
								{isAllowedToChangeEquipmentCost &&
									<div className="legend-modal__description legend-modal__description--details">
										Equipment Costs
										<Link
											className="btn--control"
											to={CLIENT.COMPANY.RESOURCES.EQUIPMENT_COST.TREE(orgAlias, companyName)}
										>
											<span className="icon-settings" />
										</Link>
									</div>
								}
							</div>
							<div className="legend-modal__description legend-modal__description--additional">
								Indicated by the background color of the card
							</div>
						</div>
						<div
							className="legend-modal__description"
							onMouseEnter={this.onDownEquipmentHover.bind(this, true)}
							onMouseLeave={this.onDownEquipmentHover.bind(this, false)}
						>
							Unavailability
						</div>
						<div
							className="legend-modal__description"
							onMouseEnter={this.onMultiAssignmentsHover.bind(this, true)}
							onMouseLeave={this.onMultiAssignmentsHover.bind(this, false)}
						>
							Daily assignments count
						</div>
					</div>
				</div>
			</div>
		);
	}
}

export default EquipmentCardLegend;
