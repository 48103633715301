import * as React from 'react';
import { Row, Col, Button } from 'react-bootstrap';
import type { WrappedFieldArrayProps } from 'redux-form';

import UpsertContactStatusEnum from 'acceligent-shared/enums/upsertContactStatus';

import AddressField from 'af-components/SharedForms/Address/AddressField';

import type AddressFM from './formModel';

interface AddressRemoveOnDeleteProps extends SharedOwnProps {
	removeOnDelete: true;
}

interface AddressUpdateOnDeleteProps extends SharedOwnProps {
	removeOnDelete: false;
	selector: (fieldName: string) => AddressFM;
}

interface SharedOwnProps {
	markers?: { lat: number; lng: number; }[];
	formName: string;
	onAddressRemove?: (address: AddressFM) => void;
	disableMap?: boolean;
	canBeEmpty?: boolean;
	disabled?: boolean;
	change: (fieldName: string, value: UpsertContactStatusEnum) => void;
}

export type OwnProps = AddressRemoveOnDeleteProps | AddressUpdateOnDeleteProps;

type Props = OwnProps & WrappedFieldArrayProps<AddressFM>;

class Addresses extends React.PureComponent<Props> {
	static defaultProps: Partial<Props> = {
		disableMap: true,
		canBeEmpty: false,
		disabled: false,
	};

	addAddress = () => {
		this.props.fields.push({ status: UpsertContactStatusEnum.ADDED } as AddressFM);
	};

	updateContactMethod = (index: number, fieldName: string) => {
		const { change, fields } = this.props;

		const field = fields.get(index);
		if (field.status === UpsertContactStatusEnum.OLD) {
			change(`${fieldName}.status`, UpsertContactStatusEnum.EDITED);
		}
	};

	removeAddress = (index: number) => {
		const { fields, onAddressRemove, change, removeOnDelete } = this.props;
		const removedAddress = fields.get(index);
		if (removeOnDelete) {
			fields.remove(index);
		} else {
			change(`${fields.name}[${index}].status`, UpsertContactStatusEnum.REMOVED);
		}
		if (removedAddress.status !== UpsertContactStatusEnum.ADDED && onAddressRemove) {
			onAddressRemove(removedAddress);
		}
	};

	renderAddressField = (address: string, index: number) => {
		const { formName, disableMap, canBeEmpty, markers, disabled, fields, removeOnDelete } = this.props;

		if (removeOnDelete === false) {
			const { selector } = this.props;
			if (selector(address).status === UpsertContactStatusEnum.REMOVED) {
				return null;
			}
		}

		return (
			<AddressField
				aa1PropName={`${address}.address.aa1`}
				aa2PropName={`${address}.address.aa2`}
				aa3PropName={`${address}.address.aa3`}
				countryPropName={`${address}.address.country`}
				disabled={disabled}
				disableMap={!!disableMap || !!disabled}
				disableRemove={(!canBeEmpty && fields.length === 1) || disabled}
				formName={formName}
				index={index}
				key={index}
				latitudePropName={`${address}.address.latitude`}
				localityPropName={`${address}.address.locality`}
				locationPropName={`${address}.address.street`}
				longitudePropName={`${address}.address.longitude`}
				marker={!!markers?.length ? { position: { ...markers[index] } } : undefined}
				onValueUpdate={this.updateContactMethod.bind(this, index, address)}
				postalCodePropName={`${address}.address.postalCode`}
				postalOfficeBoxPropName={`${address}.address.postalOfficeBoxCode`}
				removeAddress={this.removeAddress}
				routePropName={`${address}.address.route`}
				streetNumberPropName={`${address}.address.streetNumber`}
				suitePropName={`${address}.address.suite`}
			/>
		);
	};
	render() {
		const { fields, meta: { error, submitFailed }, disabled } = this.props;

		return (
			<>
				<div className="addresses">
					{fields.map(this.renderAddressField)}
				</div>
				<Row className="addresses__add">
					{!disabled &&
						<Col sm={12}>
							<Button className="btn btn--link" onClick={this.addAddress}>
								<span className="icon-plus" />
								<span>Add Address</span>
							</Button>
							{submitFailed && error && <span>{error}</span>}
						</Col>
					}
				</Row>
			</>
		);
	}
}

export default Addresses;
