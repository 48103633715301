import type { CustomFormErrors, FormErrors, FormErrorsWithArray } from 'redux-form';

import InvoiceStatus from 'acceligent-shared/enums/invoiceStatus';
import TimeFormat from 'acceligent-shared/enums/timeFormat';
import type FileType from 'acceligent-shared/enums/fileType';
import type ResourceStatus from 'acceligent-shared/enums/resourceStatus';
import InvoiceBillingLookupType from 'acceligent-shared/enums/invoiceBillingLookupType';
import type { PhoneTypes } from 'acceligent-shared/enums/contactMethodType';
import { EmailTypes, EmailTypesArray } from 'acceligent-shared/enums/contactMethodType';

import * as TimeUtils from 'acceligent-shared/utils/time';
import { isValidTextInput } from 'acceligent-shared/utils/text';
import { isValidEmail } from 'acceligent-shared/utils/email';
import { filterContactMethod } from 'acceligent-shared/utils/contact';

import InvoiceStatusDisplay from 'ab-enums/invoiceStatusDisplay.enum';

import type InvoiceRM from 'ab-requestModels/invoice/invoiceUpsert.requestModel';

import type InvoiceVM from 'ab-viewModels/workRequest/invoice.viewModel';

interface UserInfoFM {
	accountId?: Nullable<number>;
	userId?: number;
	firstName?: string;
	lastName?: string;
	fullName: string;
}
interface AttachmentFM {
	id: number;
	size: number;
	fileName: string;
	type: FileType;
	status: ResourceStatus;
	storageName: string;
	storageContainer: string;
	/** original version with presigned url */
	originalSrc: string;
	lastModifiedAt: Date;
	uploadedBy: Nullable<UserInfoFM>;
}

class ContactMethodFM {
	id: number;
	contactId: number;
	type: PhoneTypes | EmailTypes;
	value: string;

	constructor(contactMethod) {
		this.id = contactMethod.id;
		this.contactId = contactMethod.contactId;
		this.type = contactMethod.type;
		this.value = contactMethod.value;
	}

	static toList(contactMethods, type: typeof EmailTypes | typeof PhoneTypes): ContactMethodFM[] {
		return contactMethods
			.filter((_method) => _method && Object.keys(type).includes(_method.type))
			.map((_method) => new ContactMethodFM(_method));
	}
}

export class ContactFM {
	id: number;
	contactId: number;
	title: Nullable<string>;
	fullName: string;
	companyName: Nullable<string>;
	emails: ContactMethodFM[];
	contactEmailIds: number[];

	constructor(_contactLookup) {
		if (!_contactLookup) {
			return;
		}

		const _contact = _contactLookup?.contact;

		this.id = _contactLookup.id;
		this.contactId = _contactLookup.contactId;
		this.title = _contact?.title;
		this.fullName = _contact?.fullName;
		this.companyName = _contact?.companyName;

		this.emails = ContactMethodFM.toList(_contact?.contactMethods ?? [], EmailTypes);

		this.contactEmailIds = filterContactMethod(_contactLookup.contactLookupMethods, EmailTypesArray);
	}

	static bulkConstructor = (_contactLookups) => _contactLookups.map((__contactLookup) => new ContactFM(__contactLookup));
}

type BillingEmail = {
	type: InvoiceBillingLookupType.EMAIL_ONLY;
	email: string;
};

type BillingContact = {
	contact: ContactFM;
	type: InvoiceBillingLookupType.CONTACT;
};

type InvoiceBillingContactFM = { id?: number; } & (BillingEmail | BillingContact);

const validateInvoiceBillingContact = (form: Nullable<InvoiceBillingContactFM>): FormErrors<InvoiceBillingContactFM> => {
	const errors: Nullable<Record<string, unknown>> = {};

	switch (form?.type) {
		case InvoiceBillingLookupType.CONTACT: {
			if (!form.contact.contactEmailIds.length) {
				errors.type = InvoiceBillingLookupType.CONTACT;
				errors.contact = {
					contactEmailIds: ['At least one Email must be selected.'],
				};
			}
			break;
		}
		case InvoiceBillingLookupType.EMAIL_ONLY: {
			if (!form.email.includes('@')) {
				errors.type = InvoiceBillingLookupType.EMAIL_ONLY;
				errors.email = 'Please use email format: user.name@email.com';
			} else if (!isValidEmail(form.email)) {
				errors.type = InvoiceBillingLookupType.EMAIL_ONLY;
				errors.email = 'Invalid Email.';
			}
			break;
		}
		default: {
			errors.email = 'No Contact selected';
			break;
		}
	}

	return errors as FormErrors<InvoiceBillingContactFM>;
};

export class InvoiceFM {
	id?: number;
	invoiceCode: Nullable<string>;
	/** YYYY-MM-DD */
	dateCreated: Nullable<string>;
	/** YYYY-MM-DD */
	dueDate: Nullable<string>;
	/** YYYY-MM-DD */
	invoicingDate: Nullable<string>;
	status: InvoiceStatusDisplay;
	note: Nullable<string>;
	sendReminderOnInvoice: boolean;
	excludeFromAutomaticReminders: boolean;
	totalAmount: Nullable<number>;
	paidAmount: Nullable<number>;
	outstandingDebt: Nullable<number>;
	retainedAmount: Nullable<number>;
	billingContacts: Nullable<InvoiceBillingContactFM>[];
	attachments: AttachmentFM[];
	uploadedAttachmentIds?: number[];
	uploadingAttachments?: Record<string, true>;

	static _resolveInvoiceStatusForRM(invoiceStatus: InvoiceStatusDisplay): InvoiceStatus {
		return invoiceStatus === InvoiceStatusDisplay.DRAFT ? InvoiceStatus.DRAFT : InvoiceStatus.INVOICED;
	}

	static getAttributeName = (attribute: keyof InvoiceFM) => attribute;

	static fromVMtoFM(vm: InvoiceVM): InvoiceFM {
		return {
			id: vm.id,
			invoiceCode: vm.invoiceCode,
			status: vm.status,
			dateCreated: vm.dateCreated,
			dueDate: vm.dueDate,
			invoicingDate: vm.invoicingDate,
			totalAmount: vm.totalAmount,
			paidAmount: vm.paidAmount,
			outstandingDebt: vm.outstandingDebt,
			retainedAmount: vm.retainedAmount,
			note: vm.note,
			excludeFromAutomaticReminders: vm.excludeFromAutomaticReminders,
			sendReminderOnInvoice: vm.sendReminderOnInvoice,
			billingContacts: vm.billingContacts ?? [],
			attachments: vm.attachments.map((_att) => ({ ..._att, fileName: _att.name })),
			uploadedAttachmentIds: vm.uploadedAttachmentIds ?? [],
		};
	}

	static fromFMtoRM(fm: InvoiceFM): InvoiceRM {
		if (!fm.invoiceCode || !fm.dateCreated || !fm.dueDate || !fm.status || fm.totalAmount === undefined || fm.totalAmount === null) {
			throw new Error('Form not filled out correctly');
		}

		return {
			id: fm.id,
			invoiceCode: fm.invoiceCode,
			status: InvoiceFM._resolveInvoiceStatusForRM(fm.status),
			dateCreated: fm.dateCreated,
			dueDate: fm.dueDate,
			invoicingDate: fm.invoicingDate,
			totalAmount: fm.totalAmount.toString(),
			retainedAmount: fm.retainedAmount?.toString() ?? '0',
			note: fm.note,
			sendReminderOnInvoice: fm.sendReminderOnInvoice,
			excludeFromAutomaticReminders: fm.excludeFromAutomaticReminders,
			uploadedAttachmentIds: fm.uploadedAttachmentIds ?? [],
			billingContacts: fm.billingContacts?.reduce((_acc, _invoiceFM) => {
				if (!_invoiceFM) {
					return _acc;
				}

				switch (_invoiceFM.type) {
					case InvoiceBillingLookupType.EMAIL_ONLY: {
						_acc.push({
							id: _invoiceFM.id,
							type: InvoiceBillingLookupType.EMAIL_ONLY,
							email: _invoiceFM.email,
						});
						break;
					}
					case InvoiceBillingLookupType.CONTACT: {
						_acc.push({
							id: _invoiceFM.id,
							type: InvoiceBillingLookupType.CONTACT,
							contact: {
								id: _invoiceFM.contact.id,
								contactId: _invoiceFM.contact.contactId,
								contactEmailIds: _invoiceFM.contact.contactEmailIds,
							},
						});
						break;
					}
				}

				return _acc;
			}, [] as InvoiceRM['billingContacts']) ?? [],
		};
	}

	static fromVMtoRM(vm: InvoiceVM): InvoiceRM {
		if (!vm.invoiceCode || !vm.dateCreated || !vm.dueDate || !vm.status || vm.totalAmount === undefined || vm.totalAmount === null) {
			throw new Error('Form not filled out correctly');
		}

		return {
			id: vm.id,
			invoiceCode: vm.invoiceCode,
			status: InvoiceFM._resolveInvoiceStatusForRM(vm.status),
			dateCreated: vm.dateCreated,
			dueDate: vm.dueDate,
			invoicingDate: vm.invoicingDate,
			totalAmount: vm.totalAmount.toString(),
			retainedAmount: vm.retainedAmount?.toString() ?? '0',
			note: vm.note,
			sendReminderOnInvoice: vm.sendReminderOnInvoice,
			excludeFromAutomaticReminders: vm.excludeFromAutomaticReminders ?? false,
			uploadedAttachmentIds: vm.uploadedAttachmentIds ?? [],
			billingContacts: vm.billingContacts.map<InvoiceRM['billingContacts'][0]>((_invoiceFM) => {
				switch (_invoiceFM.type) {
					case InvoiceBillingLookupType.EMAIL_ONLY: {
						return {
							id: _invoiceFM.id,
							type: InvoiceBillingLookupType.EMAIL_ONLY,
							email: _invoiceFM.email,
						};
					}
					case InvoiceBillingLookupType.CONTACT: {
						return {
							id: _invoiceFM.id,
							type: InvoiceBillingLookupType.CONTACT,
							contact: {
								id: _invoiceFM.contact.id,
								contactId: _invoiceFM.contact.contactId,
								contactEmailIds: _invoiceFM.contact.contactEmailIds,
							},
						};
					}
				}
			}) ?? [],
		};
	}

	static _checkBillingContactErrors = (_error) => !Object.keys(_error ?? {}).length;

	static validate = (form: InvoiceFM): FormErrors<InvoiceFM> => {

		const errors: CustomFormErrors<InvoiceFM> = {};

		if (!isValidTextInput(form?.invoiceCode)) {
			errors.invoiceCode = 'Invoice ID is required';
		}

		if (!form.dateCreated) {
			errors.dateCreated = 'Date created is required';
		} else if (form.dateCreated && !TimeUtils.isDateInCorrectFormat(form.dateCreated, TimeFormat.DB_DATE_ONLY)) {
			errors.dateCreated = 'Date created is not in YYYY-MM-DD format';
		}
		if (!form.dueDate) {
			errors.dueDate = 'Due date is required';
		} else if (form.dueDate && !TimeUtils.isDateInCorrectFormat(form.dueDate, TimeFormat.DB_DATE_ONLY)) {
			errors.dueDate = 'Due date is not in YYYY-MM-DD format';
		}

		if (form.totalAmount === undefined || form.totalAmount === null || form.totalAmount === 0) {
			errors.totalAmount = 'Total amount must be entered';
		}

		errors.billingContacts = form?.billingContacts?.reduce((_acc, _entry) => {
			const validatedEntry = validateInvoiceBillingContact(_entry);
			_acc.push(validatedEntry);
			return _acc;
		}, [] as FormErrors<InvoiceBillingContactFM>[]);

		// redux-form is not recognizing empty object as no error
		if (errors.billingContacts?.every(InvoiceFM._checkBillingContactErrors)) {
			errors.billingContacts = undefined;
		}

		if (!!form.uploadingAttachments && Object.keys(form.uploadingAttachments).length) {
			errors.uploadingAttachments = 'Attachment upload in progress.';
		}

		return errors as FormErrors<InvoiceFM>;
	};

	static _hasInvalidBillingContact = (_billingContactError) => {
		return !!_billingContactError ? !!Object.keys(_billingContactError).length : false;
	};

	static saveFormEnabled = (form: InvoiceFM, errors: FormErrorsWithArray<InvoiceFM, string>): boolean => {
		if (!form) {
			return false;
		}
		if (!form.invoiceCode || !form.dateCreated || !form.dueDate || form.totalAmount === undefined || form.totalAmount === null) {
			return false;
		}

		const areBillingContactsInvalid = errors.billingContacts?.some(InvoiceFM._hasInvalidBillingContact);

		if (areBillingContactsInvalid) {
			return false;
		}

		const thereAreAttachmentsInUpload = !!form.uploadingAttachments && Object.keys(form.uploadingAttachments).length;

		return !thereAreAttachmentsInUpload;
	};

}
