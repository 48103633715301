import * as React from 'react';
import { connect } from 'react-redux';
import type { CustomRouteComponentProps } from 'react-router-dom';

import type { RootState } from 'af-reducers';

import Tooltip from 'af-components/Tooltip';

import IntercomInbox from './IntercomInbox';
import Upscope from './Upscope';

interface OwnProps {
	isSidenavLink?: boolean;
	showTooltip?: boolean;
}

interface StateProps {
	email: string;
	displayName: string;
}

type Props = StateProps & OwnProps & CustomRouteComponentProps;

interface State {
	isHovered: boolean;
	isActive: boolean;
}

class CustomerSupport extends React.Component<Props, State> {

	static defaultProps: Partial<Props> = {
		isSidenavLink: true,
		showTooltip: true,
	};

	state = {
		isHovered: false,
		isActive: false,
	};

	enter = () => this.setState(() => ({ isHovered: true }));

	leave = () => this.setState(() => ({ isHovered: false }));

	onShow = () => this.setState(() => ({ isActive: true }));

	onHide = () => this.setState(() => ({ isActive: false }));

	render() {
		const { email, displayName, isSidenavLink, showTooltip } = this.props;
		const { isActive, isHovered } = this.state;

		const customerSupportButton = process.env.INTERCOM_INBOX_ID
			? (
				<>
					<Upscope displayName={displayName} />
					<IntercomInbox
						appId={process.env.INTERCOM_INBOX_ID}
						color={!!isHovered || isActive ? 'orange' : 'grey'}
						displayName={displayName}
						email={email}
						hideDefaultLauncher={isSidenavLink}
						onHide={this.onHide}
						onShow={this.onShow}
					/>
				</>
			)
			: null;

		if (isSidenavLink) {
			const itemContent = showTooltip
				? (
					<Tooltip
						className="tooltip--sidenav"
						message="Customer Support"
						placement="right"
					>
						{customerSupportButton}
					</Tooltip>
				)
				: customerSupportButton;

			const customerSupportItem = (
				<li
					className={isHovered || isActive ? 'active' : undefined}
					onMouseEnter={this.enter}
					onMouseLeave={this.leave}
				>
					{itemContent}
				</li>
			);
			return customerSupportItem;
		}
		return customerSupportButton;
	}
}

function mapStateToProps(state: RootState, ownProps: OwnProps & CustomRouteComponentProps): StateProps {
	const { location: { state: { orgAlias } } } = ownProps;

	const firstName = state?.user?.userData?.firstName ?? '';
	const lastName = state?.user?.userData?.lastName ?? '';
	const email = state?.user?.userData?.email ?? '';

	const userName = `${firstName} ${lastName}`.trim();
	return {
		email,
		displayName: userName && email ? `${userName}, ${email} [${orgAlias}]` : `User at the Login Page [${orgAlias}]`,
	};
}

export default connect<StateProps, null, OwnProps>(mapStateToProps)(CustomerSupport);
