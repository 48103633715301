import * as constants from 'af-constants/actionTypes';

import type * as User from 'ab-viewModels/user.viewModel';
import type { CompanyViewModel } from 'ab-viewModels/company.viewModel';

export interface LoginUser {
	type: typeof constants.LOGIN_USER;
	payload: User.UserData;
}
export function LOGIN_USER(payload: User.UserData): LoginUser {
	return {
		type: constants.LOGIN_USER,
		payload,
	};
}

export interface UpdateUser {
	type: typeof constants.UPDATE_USER;
	payload: User.UserData;
}
export function UPDATE_USER(payload: User.UserData): UpdateUser {
	return {
		type: constants.UPDATE_USER,
		payload,
	};
}

export interface LogoutUser {
	type: typeof constants.LOGOUT_USER;
}
export function LOGOUT_USER(): LogoutUser {
	return {
		type: constants.LOGOUT_USER,
	};
}

export interface AuthenticationSuccess {
	type: typeof constants.AUTH_SUCCESS;
	payload: boolean;
}
export function AUTH_SUCCESS(payload: boolean): AuthenticationSuccess {
	return {
		type: constants.AUTH_SUCCESS,
		payload,
	};
}

export interface AuthenticationFail {
	type: typeof constants.AUTH_FAIL;
}

export function AUTH_FAIL(): AuthenticationFail {
	return {
		type: constants.AUTH_FAIL,
	};
}

export interface SetCurrentCompany {
	type: typeof constants.SET_CURRENT_COMPANY;
	companyId: number;
}
export function SET_CURRENT_COMPANY(companyId: number): SetCurrentCompany {
	return {
		type: constants.SET_CURRENT_COMPANY,
		companyId,
	};
}

export interface UpdateCurrentCompany {
	type: typeof constants.UPDATE_CURRENT_COMPANY;
	payload: CompanyViewModel;
}
export function UPDATE_CURRENT_COMPANY(payload: CompanyViewModel): UpdateCurrentCompany {
	return {
		type: constants.UPDATE_CURRENT_COMPANY,
		payload,
	};
}

export interface SetCurrentOrganization {
	type: typeof constants.SET_CURRENT_ORGANIZATION;
	payload: User.OrganizationData;
}
export function SET_CURRENT_ORGANIZATION(payload: User.OrganizationData): SetCurrentOrganization {
	return {
		type: constants.SET_CURRENT_ORGANIZATION,
		payload,
	};
}

export interface SetForgottenEmail {
	type: typeof constants.SET_FORGOTTEN_EMAIL;
	payload: string;
}
export function SET_FORGOTTEN_EMAIL(payload: string): SetForgottenEmail {
	return {
		type: constants.SET_FORGOTTEN_EMAIL,
		payload,
	};
}

export interface SetPendingPhoneNumber {
	type: typeof constants.SET_PENDING_PHONE_NUMBER;
	payload: Nullable<string>;
}
export function SET_PENDING_PHONE_NUMBER(payload: Nullable<string>): SetPendingPhoneNumber {
	return {
		type: constants.SET_PENDING_PHONE_NUMBER,
		payload,
	};
}

export type AuthenticationAction = LoginUser
| UpdateUser
| LogoutUser
| AuthenticationSuccess
| AuthenticationFail
| SetCurrentCompany
| UpdateCurrentCompany
| SetCurrentOrganization
| SetForgottenEmail
| SetPendingPhoneNumber;
