import * as React from 'react';
import type { CustomRouteComponentProps } from 'react-router-dom';
import type { ResolveThunks } from 'react-redux';
import { connect } from 'react-redux';

import type * as ResourceStatusesUpdateRequestModel from 'ab-requestModels/resources/resourceStatusesUpdate.requestModel';

import type * as ResourceStatusesViewModel from 'ab-viewModels/resources/resourceStatuses.viewModel';

import * as EquipmentActions from 'af-actions/equipment';

import { RESOURCE_STATUS } from 'af-constants/reduxForms';

import type { RootState } from 'af-reducers';

import Breadcrumbs from 'af-components/Breadcrumbs';

import ResourceStatusesPreview from '../Shared/ResourceStatusesPreview';
import ResourceStatusesModal from '../Shared/ResourceStatusesModal';

interface DispatchProps {
	findAllStatusesForCompany: typeof EquipmentActions.findAllStatusesForCompany;
	updateAllStatusesForCompany: typeof EquipmentActions.updateAllStatusesForCompany;
}

type OwnProps = CustomRouteComponentProps;

interface StateProps {
	isLoading: boolean;
}

type Props = OwnProps & StateProps & ResolveThunks<DispatchProps>;

interface State {
	isEditMode: boolean;
	statuses: ResourceStatusesViewModel.Default | undefined;
}

class EquipmentStatuses extends React.Component<Props, State> {

	state: State = {
		isEditMode: false,
		statuses: undefined,
	};

	async componentDidMount() {
		const { findAllStatusesForCompany } = this.props;
		const statuses = await findAllStatusesForCompany();
		this.setState(() => ({ statuses }));
	}

	getStatuses = (): ResourceStatusesViewModel.Default => {
		const { statuses } = this.state;
		if (!statuses) {
			throw new Error('Statuses not defined');
		}

		return statuses;
	};

	handleSubmitUpdate = async (data: ResourceStatusesUpdateRequestModel.Default) => {
		const { updateAllStatusesForCompany } = this.props;
		const statuses = await updateAllStatusesForCompany(data);
		this.setState(() => ({ statuses }));
	};

	toggleEditMode = () => {
		this.setState((prevStatus: State) => ({ isEditMode: !prevStatus.isEditMode }));
	};

	render() {
		const {
			isEditMode,
			statuses,
		} = this.state;

		const { isLoading } = this.props;

		return (
			<div className="form-segment">
				<Breadcrumbs items={[{ label: 'Equipment Status' }]} />
				<ResourceStatusesPreview
					isLoading={isLoading}
					statuses={statuses}
					toggleEdit={this.toggleEditMode}
				/>
				<ResourceStatusesModal
					close={this.toggleEditMode}
					getStatuses={this.getStatuses}
					isVisible={isEditMode}
					submit={this.handleSubmitUpdate}
				/>
			</div>
		);
	}
}

function mapDispatchToProps(): DispatchProps {
	return {
		findAllStatusesForCompany: EquipmentActions.findAllStatusesForCompany,
		updateAllStatusesForCompany: EquipmentActions.updateAllStatusesForCompany,
	};
}

function mapStateToProps(state: RootState): StateProps {
	return {
		isLoading: state.http.submitting.includes(RESOURCE_STATUS),
	};
}

export default connect<StateProps, DispatchProps, OwnProps>(mapStateToProps, mapDispatchToProps())(EquipmentStatuses);
