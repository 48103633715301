import type JobHazardAssessmentPreviewVM from 'ab-viewModels/workRequest/jobHazardAssessmentPreview.viewModel';

import type { WorkRequestOutletContext } from 'af-components/SharedPreviews/WorkRequest/Details/types';

export type WorkRequestPreviewOutletContext = {
	isJobPreview: boolean;
	orgAlias: string;
	workRequest: WorkRequestOutletContext;
	jobHazardAssessment: JobHazardAssessmentPreviewVM;
	hideAttachedToWO: boolean;
	hideCopyToWO: boolean;
	jobId: string;
};

export enum WorkRequestPreviewTabs {
	DETAILS = 'details',
	JOB_HAZARD_ASSESSMENT = 'jobHazardAssessment',
	ATTACHMENTS = 'attachments',
}
