import * as React from 'react';
import type { CustomRouteComponentProps} from 'react-router-dom';
import { withRouter } from 'react-router-dom';
import { compose } from 'redux';
import type { ResolveThunks } from 'react-redux';
import { connect } from 'react-redux';
import DatePicker from 'react-datepicker';
import enUs from 'date-fns/locale/en-US';

import TimeFormatEnum from 'acceligent-shared/enums/timeFormat';

import * as TimeUtils from 'acceligent-shared/utils/time';

import BrowserStorageEnum from 'ab-enums/browserStorage.enum';

import * as SettingsKeys from 'af-constants/settingsKeys';
import { SCREEN_BREAKPOINT_M } from 'af-constants/values';

import type { RootState } from 'af-reducers';

import * as ScheduleBoardActions from 'af-actions/scheduleBoard';

import { withSettings, setWorkOrderSelectedDueDate, setWeeklyViewEndDueDate, setWeeklyViewStartDueDate } from 'af-utils/settings.util';
import { getWorkingWeekStartAndEnd } from 'af-utils/resources.util';

import MultipleOptionsButton from 'af-components/MultipleOptionsButton';
import RectangleButton from 'af-components/MultipleOptionsButton/RectangleButton';

import WeeklyDatePicker from './WeeklyDatePicker';

type MomentType = ReturnType<typeof TimeUtils.normalizeDateToMoment>;

interface OwnProps {
	refreshConnectionCount: (dates: string[]) => void;
}

interface DispatchProps {
	updateScheduleBoardWeek: typeof ScheduleBoardActions.updateScheduleBoardWeek;
	closeToolbar: typeof ScheduleBoardActions.setWeeklyViewDateWithToolbar;
}

interface StateProps {
	weeklyViewToolbarOnDate: Nullable<string>;
	workingWeekStart: MomentType;
	workingWeekEnd: MomentType;
}

interface SettingsProps extends OwnProps, CustomRouteComponentProps {
	date: MomentType;
	selectedDate: MomentType;
	startDate: MomentType;
	endDate: MomentType;
}

type ConnectOwnProps = SettingsProps;
type Props = ConnectOwnProps & StateProps & ResolveThunks<DispatchProps>;

interface State {
	startDate: MomentType;
	endDate: MomentType;
	selectedDate: MomentType;
	weekDays: Date[];
	areButtonsHidden: boolean;
}

class WeeklyViewDatePicker extends React.PureComponent<Props, State> {
	state: State = {
		startDate: this.props.startDate,
		endDate: this.props.endDate,
		selectedDate: this.props.selectedDate,
		weekDays: [],
		areButtonsHidden: false,
	};

	async componentDidMount() {
		const { selectedDate, location: { state: { selectedDate: selectedDateParam } } } = this.props;

		const date = (selectedDateParam && TimeUtils.normalizeDateToMoment(selectedDateParam)) || selectedDate;
		this.onDateSelect(date);
		this.updateWindowDimensions();
		window.addEventListener('resize', this.updateWindowDimensions);
	}

	componentWillUnmount() {
		window.removeEventListener('resize', this.updateWindowDimensions);
	}

	updateWindowDimensions = () => {
		const { areButtonsHidden } = this.state;
		const _areButtonsHidden = window.innerWidth < SCREEN_BREAKPOINT_M;
		if (areButtonsHidden !== _areButtonsHidden) {
			this.setState(() => ({ areButtonsHidden: _areButtonsHidden }));
		}
	};

	onWeekChange = (startDate: string, endDate: string): void => {
		const { updateScheduleBoardWeek, closeToolbar } = this.props;
		closeToolbar(null);
		updateScheduleBoardWeek(startDate, endDate);
	};

	onPreviousDate = () => this.onDateSelect(this.state.selectedDate?.subtract(1, 'weeks') ?? null);

	onNextDate = () => this.onDateSelect(this.state.selectedDate?.add(1, 'weeks') ?? null);

	onDatePickerChange = (date: Date) => this.onDateSelect(TimeUtils.parseMoment(date));

	onDateSelect = async (date: MomentType) => {
		if (!date) {
			throw new Error('Date incorrectly selected');
		}

		const { refreshConnectionCount } = this.props;
		const startDate = date.clone().startOf('week');
		const endDate = date.clone().endOf('week');
		const weekDays: Date[] = [];

		refreshConnectionCount(this.state.weekDays.map((_date: Date) => TimeUtils.formatDate(_date)));

		for (let i = 0; i < 7; i++) {
			weekDays.push(startDate.clone().add(i, 'days').toDate());
		}

		this.setState(() => ({ selectedDate: date, startDate, endDate, weekDays }), () => {
			this.onWeekChange(startDate.format(TimeFormatEnum.DATE_ONLY), endDate.format(TimeFormatEnum.DATE_ONLY));

			setWorkOrderSelectedDueDate(date);
			setWeeklyViewStartDueDate(startDate);
			setWeeklyViewEndDueDate(endDate);
		});
	};

	render() {
		const { startDate, endDate, weekDays, selectedDate } = this.state;

		return (
			<div className="schedule-board-datepicker">
				<div className="schedule-board-date-range-picker">
					<div className="schedule-board-datepicker-input week-picker">
						<DatePicker
							className="form-control"
							customInput={<WeeklyDatePicker endDate={endDate} startDate={startDate} />}
							dateFormat={TimeUtils.datePickerFormat(TimeFormatEnum.DAY_WITH_DATE)}
							dropdownMode="select"
							highlightDates={weekDays}
							locale={enUs}
							onChange={this.onDatePickerChange}
							selected={selectedDate?.toDate()}
							showMonthDropdown={true}
							showYearDropdown={true}
							todayButton="Today"
						/>
					</div>
					<MultipleOptionsButton isLeftFlat={true}>
						<RectangleButton
							action={this.onPreviousDate}
							isSquare={true}
							label={<span className="icon-left" />}
							tooltipMessage="Previous Week"
							tooltipPlacement="bottom"
						/>
						<RectangleButton
							action={this.onNextDate}
							isSquare={true}
							label={<span className="icon-right" />}
							tooltipMessage="Next Week"
							tooltipPlacement="bottom"
						/>
					</MultipleOptionsButton>
				</div>
			</div>
		);
	}
}

function mapDispatchToProps(): DispatchProps {
	return {
		updateScheduleBoardWeek: ScheduleBoardActions.updateScheduleBoardWeek,
		closeToolbar: ScheduleBoardActions.setWeeklyViewDateWithToolbar,
	};
}

function mapStateToProps(state: RootState, ownProps: ConnectOwnProps): StateProps {
	const { startDate, endDate, date } = ownProps;
	const { company } = state.company;

	let workingWeekStart: MomentType;
	let workingWeekEnd: MomentType;
	if (startDate && endDate) {
		workingWeekStart = startDate;
		workingWeekEnd = endDate;
	} else if (company?.workDays?.length) {
		const { workingWeekStart: _wws, workingWeekEnd: _wwe } = getWorkingWeekStartAndEnd(company.workDays, date);
		workingWeekStart = _wws ?? null;
		workingWeekEnd = _wwe ?? null;
	} else {
		if (!date) {
			throw new Error('Date is expected');
		}
		workingWeekStart = date.clone().startOf('week');
		workingWeekEnd = date.clone().endOf('week');
	}
	return {
		weeklyViewToolbarOnDate: state.scheduleBoard.weeklyViewDateWithToolbar,
		workingWeekStart,
		workingWeekEnd,
	};
}

const enhance = compose<React.ComponentClass<OwnProps>>(
	withRouter,
	withSettings<SettingsProps>(() => ([
		{
			key: SettingsKeys.WORK_ORDER_SELECTED_DUE_DATE(),
			mappedName: 'date',
			normalize: TimeUtils.normalizeDateToMoment,
			defaultValue: TimeUtils.toMomentUtc(new Date()),
			source: BrowserStorageEnum.SESSION_STORAGE,
		},
		{
			key: SettingsKeys.WEEKLY_VIEW_START_DUE_DATE(),
			mappedName: 'startDate',
			normalize: TimeUtils.normalizeDateToMoment,
			defaultValue: undefined,
			source: BrowserStorageEnum.SESSION_STORAGE,
		},
		{
			key: SettingsKeys.WEEKLY_VIEW_END_DUE_DATE(),
			mappedName: 'endDate',
			normalize: TimeUtils.normalizeDateToMoment,
			defaultValue: undefined,
			source: BrowserStorageEnum.SESSION_STORAGE,
		},
		{
			key: SettingsKeys.WORK_ORDER_SELECTED_DUE_DATE(),
			mappedName: 'selectedDate',
			normalize: TimeUtils.normalizeDateToMoment,
			defaultValue: TimeUtils.toMomentUtc(new Date()),
			source: BrowserStorageEnum.SESSION_STORAGE,
		},
	])),
	connect<StateProps, DispatchProps, ConnectOwnProps>(mapStateToProps, mapDispatchToProps())
);

export default enhance(WeeklyViewDatePicker);
