import * as React from 'react';
import { withRouter } from 'react-router';
import type { CustomRouteComponentProps } from 'react-router-dom';
import type { InjectedFormProps, FormErrors } from 'redux-form';
import { reduxForm, getFormSyncErrors, formValueSelector } from 'redux-form';
import { compose } from 'redux';
import type { ConnectedProps } from 'react-redux';
import { connect } from 'react-redux';
import { Button } from 'react-bootstrap';

import type { ContactValidationErrors } from 'ab-requestModels/contact/upsert';
import type ContactRM from 'ab-requestModels/contact/upsert';
import type ContactVM from 'ab-viewModels/contact/contact';

import type { RootState } from 'af-reducers';

import { CONTACT_CREATE_MODAL } from 'af-constants/reduxForms';

import * as ContactActions from 'af-actions/contacts';

import SubmitButton from 'af-components/SubmitButton';
import CustomModal from 'af-components/CustomModal';

import ContactForm from '../ContactForm';
import { warn } from '../ContactForm/validation';
import { validateContact } from './validate';

export interface OwnProps {
	showModal: boolean;
	initialValues: Partial<ContactRM>;
	closeModal: () => void;
	onCreateNew: (form: ContactVM) => void;
	areEmailsRequired?: boolean;
}

type ConnectOwnProps = OwnProps & CustomRouteComponentProps;

type FormProps = InjectedFormProps<ContactRM>;

type Props = ConnectOwnProps & ConnectedProps<typeof connector> & FormProps;

class CreateNewContactModal extends React.PureComponent<Props> {
	onSave = async (form: ContactRM) => {
		const { createContact, closeModal, onCreateNew } = this.props;

		const contact = await createContact(form);
		onCreateNew(contact);
		closeModal();
	};

	render() {
		const { handleSubmit, closeModal, showModal, invalid, submitting, companyName } = this.props;

		return (
			<CustomModal
				className="contact-create-modal"
				closeModal={closeModal}
				modalStyle="info"
				showModal={showModal}
				size="xl"
			>
				<CustomModal.Header
					closeModal={closeModal}
					title="Create Contact"
				/>
				<CustomModal.Body>
					<ContactForm
						{...this.props}
						companyName={companyName}
						hideButtons={true}
						onSubmit={this.onSave}
						removeOnDelete={true}
					/>
				</CustomModal.Body>
				<CustomModal.Footer>
					<Button
						onClick={closeModal}
						variant="info"
					>
						Close
					</Button>
					<SubmitButton
						disabled={invalid}
						label="Save"
						onClick={handleSubmit(this.onSave)}
						reduxFormSubmitting={submitting}
						submitKey={CONTACT_CREATE_MODAL}
					/>
				</CustomModal.Footer>
			</CustomModal>
		);
	}
}

const getErrors = getFormSyncErrors(CONTACT_CREATE_MODAL);

const formSelector = formValueSelector(CONTACT_CREATE_MODAL);

function mapStateToProps(state: RootState, ownProps: OwnProps) {
	const { companyData } = state.user;
	if (!companyData) {
		throw new Error('User not logged in');
	}

	const fullName = formSelector(state, 'fullName') as string;

	const {
		emailsPhones: emailPhoneError,
	} = getErrors(state) as FormErrors<ContactValidationErrors, string>;

	return {
		initialValues: ownProps.initialValues,
		companyName: companyData.name,
		fullName,
		emailPhoneError: emailPhoneError as string,
	};
}
function mapDispatchToProps() {
	return {
		createContact: ContactActions.createContact,
	};
}

const connector = connect(mapStateToProps, mapDispatchToProps());

const enhance = compose<React.ComponentClass<OwnProps>>(
	withRouter,
	connector,
	reduxForm({
		form: CONTACT_CREATE_MODAL,
		enableReinitialize: true,
		validate: validateContact,
		warn,
	})
);

export default enhance(CreateNewContactModal);
