import * as React from 'react';
import { FieldArray } from 'redux-form';

import JobContact from 'af-components/SharedForms/Contacts/ContactWrapper';

import { getAttributeName } from 'ab-viewModels/workRequest/workRequestDetailsUpsert.viewModel';

import styles from '../../../styles.module.scss';
import { formSectionClass, formSectionClassColumn8, formSectionClassRow, formSectionTitleClass } from '../../../helpers';
import AdditionalBillingInformation from './AdditionalBillingInformation';
import type { OwnProps as AdditionalContactsOwnProps } from './AdditionalContacts';
import AdditionalContacts from './AdditionalContacts';

type Props = {
	formName: string;
	isDisabled: boolean;
};

const ContactsSection: React.FC<Props> = ({ formName, isDisabled }) => {

	return (
		<div className={formSectionClass}>
			<div className={formSectionTitleClass}>
				Contacts
			</div>
			<div className={formSectionClassRow}>
				<div className={formSectionClassColumn8}>
					Customer Contact
					<JobContact
						className={styles['job-form__contact']}
						contactLabel="Enter Customer Contact"
						disabled={isDisabled}
						fieldName={getAttributeName('customerContact')}
						formName={formName}
					/>
				</div>
			</div>
			<div className={formSectionClassRow}>
				<div className={formSectionClassColumn8}>
					Site Contact
					<JobContact
						className={styles['job-form__contact']}
						contactLabel="Enter Site Contact"
						disabled={isDisabled}
						fieldName={getAttributeName('siteContact')}
						formName={formName}
					/>
				</div>
			</div>
			<div className={formSectionClassRow}>
				<div className={formSectionClassColumn8}>
					Billing Contact
					<JobContact
						className={styles['job-form__contact']}
						contactLabel="Enter Billing Contact"
						disabled={isDisabled}
						fieldName={getAttributeName('billingContact')}
						formName={formName}
					/>
				</div>
			</div>
			<AdditionalBillingInformation />
			<FieldArray<AdditionalContactsOwnProps>
				component={AdditionalContacts}
				formName={formName}
				name={getAttributeName('additionalContacts')}
			/>
		</div>
	);
};

export default React.memo(ContactsSection);
