import * as React from 'react';

import type { AttachmentEntity, DirectoryEntity } from '../types';

import styles from './styles.module.scss';

interface RowActionOptions {
	actionName: string;
	actionFunction: (original: DirectoryEntity | AttachmentEntity) => void;
	disabled?: boolean;
	show?: boolean;
}

interface RowActions {
	parentDirectory: RowActionOptions[];
	childDirectory: RowActionOptions[];
	attachmentLevel: RowActionOptions[];
}

interface TypedColumn {
	header: string;
	accessor?: string;
	id?: string;
	className?: string;
	width?: number;
}

interface TemporaryType {
	columns: TypedColumn[];
	rowActions: RowActions;
	toggleRowVisibility: (arg0: number[]) => void;
	visibleRows: number[];
	arrOfDirectoryIds: number[];
	isReadOnly?: boolean;
}

const TreeTableHeader = React.memo((props: TemporaryType) => {
	const { columns, rowActions, toggleRowVisibility, visibleRows, arrOfDirectoryIds, isReadOnly = false } = props;

	return (
		<>
			<div className={`${styles['tree-table-header']}`}>
				<div className={`${styles['expand-icon']}`}>
					{visibleRows.length === arrOfDirectoryIds.length ?
						<div className="icon-collapse" onClick={toggleRowVisibility.bind(this, [])} />
						:
						<div className="icon-expand_all" onClick={toggleRowVisibility.bind(this, arrOfDirectoryIds)} />
					}
				</div>
				{
					columns.map((column) => {
						{
							const dynamicClassName = `cell-flex-${column.width}`;
							return <div className={`${styles[dynamicClassName]}`} key={column.id}>
								{column.header}
							</div>;
						}
					})
				}
				{
					(rowActions && !isReadOnly) &&
					<div className={`${styles['cell-flex-1']}`} />
				}
			</div >
		</>
	);
});

export default TreeTableHeader;
