import * as React from 'react';
import type { ResolveThunks } from 'react-redux';
import { connect } from 'react-redux';
import type { CustomRouteComponentProps } from 'react-router-dom';
import { Row, Col } from 'react-bootstrap';

import type { LocationViewModel } from 'ab-viewModels/location/location.viewModel';

import type { RootState } from 'af-reducers';

import * as LocationActions from 'af-actions/location';

import CLIENT from 'af-constants/routes/client';

import LockedValue from 'af-components/LockedValue';
import LockedColorValue from 'af-components/LockedColorValue';
import AddressRowPreview from 'af-components/ContactMethodPreview/AddressPreview';

import ResourcePreview from '../../Shared/ResourcePreview';
import Loading from './Loading';
import SmallToolTable from './SmallToolTable';

interface PathParams {
	id: string;
}

type OwnProps = CustomRouteComponentProps<PathParams>;

interface DispatchProps {
	findById: typeof LocationActions.findById;
}

interface StateProps {
	companyName: string;
}

interface State {
	location: Nullable<LocationViewModel>;
}

type Props = OwnProps & ResolveThunks<DispatchProps> & StateProps;

class Preview extends React.Component<Props, State> {

	state: State = {
		location: null,
	};

	async componentDidMount() {
		const { findById, match: { params: { id } } } = this.props;
		const location = await findById(id);
		this.setState(() => ({ location }));
	}

	formatShowOnScheduleBoard = (showOnScheduleBoard: boolean) => showOnScheduleBoard ? 'Yes' : 'No';

	render() {
		const { location: { state: { orgAlias } }, companyName } = this.props;
		const { location } = this.state;

		const breadcrumbs = [
			{ label: 'Locations', url: CLIENT.COMPANY.RESOURCES.LOCATION.LIST(orgAlias, companyName) },
			{ label: 'Preview' },
		];

		return location
			? (
				<>
					<ResourcePreview
						backToListLabel="Back to Location List"
						backToListUrl={CLIENT.COMPANY.RESOURCES.LOCATION.LIST(orgAlias, companyName)}
						breadcrumbs={breadcrumbs}
						editUrl={CLIENT.COMPANY.RESOURCES.LOCATION.EDIT(`${location.id}`, orgAlias, companyName)}
					>
						<Row className="row--padded-top">
							<Col md={8}><LockedValue label="Nickname" value={location.nickname} /></Col>
							<Col md={8}><LockedColorValue color={location.color} label="Color" /></Col>
							<Col md={8}><LockedValue label="Visible in Labor Statistics" value={this.formatShowOnScheduleBoard(location.showInStatistics)} /></Col>
						</Row>
						<Row className="row--padded">
							<Col md={8}><LockedValue label="Mobile Phone" value={location.phone} /></Col>
							<Col md={8}><LockedValue label="Fax" value={location.fax} /></Col>
						</Row>
						<AddressRowPreview address={location.address} />
					</ResourcePreview>
					<SmallToolTable
						companyName={companyName}
						orgAlias={orgAlias}
						toolTrackingRecords={location.toolTrackingRecords}
					/>
				</>
			)
			: <Loading />;
	}

}

function mapStateToProps(state: RootState): StateProps {
	const { companyData } = state.user;
	if (!companyData) {
		throw new Error('User not logged in');
	}

	return {
		companyName: companyData.name,
	};
}

function mapDispatchToProps(): DispatchProps {
	return {
		findById: LocationActions.findById,
	};
}

export default connect<StateProps, DispatchProps, OwnProps>(mapStateToProps, mapDispatchToProps())(Preview);
