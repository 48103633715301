import * as React from 'react';
import type { WrappedFieldArrayProps, FieldArrayElementRenderer } from 'redux-form';
import { Field } from 'redux-form';

import Checkbox from 'af-fields/Checkbox';
import { phoneNormalizer } from 'ab-utils/reduxForms.util';

import type { SavedContactMethodFormModel } from './formModel';

interface OwnProps {
	isPhone?: boolean;
}

type Props = OwnProps & WrappedFieldArrayProps<SavedContactMethodFormModel>;

const SavedContactMethodFieldArray: React.FC<Props> = (props) => {
	const { isPhone = false } = props;

	const _renderSavedContactMethodField: FieldArrayElementRenderer<SavedContactMethodFormModel> = React.useCallback((field, index, fields) => {
		const { value } = fields.get(index);
		const label = isPhone ? phoneNormalizer(value, '') : value;

		return (
			<Field
				component={Checkbox}
				inline={false}
				key={value}
				label={label}
				name={`${field}.isUsed`}
			/>
		);
	}, [isPhone]);

	return <>{props.fields.map(_renderSavedContactMethodField)}</>;
};

export type SavedContactMethodFieldArrayOwnProps = OwnProps;
export default SavedContactMethodFieldArray;
