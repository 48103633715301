import type { FormErrors } from 'redux-form';

import { EmailTypes, PhoneTypes } from 'acceligent-shared/enums/contactMethodType';
import UpsertContactStatus from 'acceligent-shared/enums/upsertContactStatus';
import { isValidEmail } from 'acceligent-shared/utils/email';
import { isValidFormattedPhoneNumber } from 'acceligent-shared/utils/phone';
import { isValidTextInput } from 'acceligent-shared/utils/text';

import type UpsertContactRM from 'ab-requestModels/contact/upsert';

import type { OwnProps as CreateContactModalProps } from './CreateContactModal';

type PhoneRM = UpsertContactRM['phones'][0];
type EmailRM = UpsertContactRM['emails'][0];
type AddressRM = UpsertContactRM['addresses'][0]['address'];

function _filterRemovedValues(contact: PhoneRM | EmailRM) {
	return contact.status !== UpsertContactStatus.REMOVED;
}

function _validateEmails(emails: UpsertContactRM['emails']) {

	const emailErrors: ValidationErrors[] = [];

	type EmailMap = { [email: string]: true; };
	const existingMails: EmailMap = {};
	const existingTypes: EmailMap = {};

	emails.filter(_filterRemovedValues).forEach((_email, _index) => {
		const error = {} as ValidationErrors;

		if (!isValidEmail(_email.value)) {
			error.value = 'Invalid email.';
		} else if (existingMails[_email.value]) {
			error.value = 'Email must be unique';
		} else {
			existingMails[_email.value] = true;
		}

		if (!_email.type) {
			error.type = 'Type is required.';
		} else if (!EmailTypes[_email.type]) {
			error.type = 'Invalid type.';
		} else if (existingTypes[_email.type]) {
			error.type = 'Type must be unique.';
		} else {
			existingTypes[_email.type] = true;
		}

		if (Object.keys(error).length) {
			emailErrors[_index] = error;
		}
	});

	if (emailErrors.length) {
		return emailErrors;
	}
}

function _validatePhones(phones: UpsertContactRM['phones']) {

	const phoneErrors: ValidationErrors[] = [];

	type PhoneMap = { [phone: string]: true; };
	const existingPhones: PhoneMap = {};
	const existingTypes: PhoneMap = {};

	phones.filter(_filterRemovedValues).forEach((_phone, _index) => {
		const error = {} as FormErrors<PhoneRM>;

		if (!isValidFormattedPhoneNumber(_phone.value)) {
			error.value = 'Phone is invalid.';
		} else if (existingPhones[_phone.value]) {
			error.value = 'Phone must be unique';
		} else {
			existingPhones[_phone.value] = true;
		}

		if (!_phone.type) {
			error.type = 'Type is required.';
		} else if (!PhoneTypes[_phone.type]) {
			error.type = 'Invalid type.';
		} else if (existingTypes[_phone.type]) {
			error.type = 'Type must be unique.';
		} else {
			existingTypes[_phone.type] = true;
		}

		if (Object.keys(error).length) {
			phoneErrors[_index] = error;
		}
	});

	if (phoneErrors.length) {
		return phoneErrors;
	}
}

function _validateAddress(addresses: UpsertContactRM['addresses']) {
	const addressErrors: ValidationErrors[] = [];

	addresses?.forEach((_contactAddress, _index) => {
		const { address: _address } = _contactAddress;
		const error = {} as FormErrors<AddressRM>;

		if (!_address?.street) {
			error.street = 'Street is required.';
		}
		if (!_address?.locality) {
			error.locality = 'City is required.';
		}
		if (!_address?.aa1) {
			error.aa1 = 'State is required.';
		}
		if (!_address?.postalCode) {
			error.postalCode = 'Zip is required.';
		}
		if (!_address?.country) {
			error.country = 'Country is required.';
		}

		if (Object.keys(error).length) {
			addressErrors[_index] = { address: error } as FormErrors<UpsertContactRM['addresses'][0]>;
		}
	});

	if (addressErrors.length) {
		return addressErrors;
	}
}

export function validateContact(contact: UpsertContactRM, props: CreateContactModalProps) {
	const errors = {} as ValidationErrors;

	if (!isValidTextInput(contact.fullName)) {
		errors.fullName = 'Full Name is required.';
	}

	if (props.areEmailsRequired && !contact.emails?.length) {
		errors.emails = 'Email Address is Required.';
	}

	if (contact.emails?.length) {
		const emailErrors = _validateEmails(contact.emails);
		if (emailErrors) {
			errors.emails = emailErrors;
		}
	}

	if (contact.phones?.length) {
		const phoneErrors = _validatePhones(contact.phones);
		if (phoneErrors) {
			errors.phones = phoneErrors;
		}
	}

	const addressErrors = _validateAddress(contact.addresses);
	if (addressErrors) {
		errors.addresses = addressErrors;
	}

	return errors;
}
