import * as React from 'react';
import { Field } from 'redux-form';

import Textarea from 'af-fields/Textarea';

import JobUpsertFM from '../../formModel';
import { formSectionClass, formSectionClassColumn8, formSectionClassRow, formSectionTitleClass } from '../../helpers';

const JOB_HAZARD_SUMMARY_MAX_LENGTH = 600;

type Props = {
	jobHazzardSummaryRef: React.MutableRefObject<Nullable<HTMLDivElement>>;
};

const JobHazardSummarySection: React.FC<Props> = ({ jobHazzardSummaryRef }) => {
	return (
		<div className={formSectionClass} ref={jobHazzardSummaryRef}>
			<div className={formSectionTitleClass}>
				Job Hazard Summary
			</div>
			<div className={formSectionClassRow}>
				<div className={formSectionClassColumn8}>
					<Field
						component={Textarea}
						maxCharacters={JOB_HAZARD_SUMMARY_MAX_LENGTH}
						name={JobUpsertFM.getAttributeName('hazardSummary')}
						placeholder="Enter text"
						rows={4}
						showMaxCharactersLabel={true}
					/>
				</div>
			</div>
		</div>
	);
};

export default React.memo(JobHazardSummarySection);
