import * as React from 'react';
import { Badge } from 'react-bootstrap';
import type { FieldArrayFieldsProps } from 'redux-form';

import UI_LABELS from 'af-constants/uiLabels';

import Checkbox from 'af-components/Controls/Checkbox';

import { formatPhoneNumber } from 'ab-utils/phone.util';

interface Props {
	phone: Metadata;
	fields: FieldArrayFieldsProps<Metadata>;
	disabled?: boolean;
}

export default class PhoneCheckbox extends React.PureComponent<Props> {

	getFieldIndex = () => {
		const { fields, phone } = this.props;

		let phoneIndex = -1;
		fields.forEach((tcc, index) => {
			const p = fields.get(index);
			if (p === phone.id) {
				phoneIndex = index;
			}
		});
		return phoneIndex;
	};

	onCheck = (e) => {
		const { fields, phone } = this.props;
		const isChecked = e.target.checked;
		if (isChecked) {
			fields.push(phone.id);
		} else {
			const phoneIndex = this.getFieldIndex();
			if (phoneIndex >= 0) {
				fields.remove(phoneIndex);
			}
		}
	};

	render() {
		const { phone, disabled } = this.props;
		const isChecked = this.getFieldIndex() >= 0;

		let rowClassName = 'contact__methods-row';
		rowClassName = !isChecked ? `${rowClassName} contact__methods-row--not-selected` : rowClassName;

		return (
			<div className={rowClassName}>
				<div className="contact__method-control">
					<Checkbox
						handleChange={this.onCheck}
						id={phone.id}
						isChecked={isChecked}
						isDisabled={disabled}
						isStandalone={true}
					/>
				</div>
				<div>
					<Badge
						className="contact__method-item-label"
					>
						{UI_LABELS.PHONE_TYPES[phone.type]}
					</Badge>
					<strong>{formatPhoneNumber(phone.value, phone.countryCode)}</strong>
				</div>
			</div>
		);
	}
}
