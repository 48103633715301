import * as React from 'react';

import type DeliverableDataTypeEnum from 'acceligent-shared/enums/deliverableDataType';

import type { DeliverableDataSingleViewModel } from 'ab-viewModels/deliverableData.viewModel';

import type { DeliverableDataRequestModel } from 'ab-requestModels/deliverableData.requestModel';

import Tag from 'af-components/Tag';

import { validateData as validate } from './validation';

import { bemElement } from 'ab-utils/bem.util';

interface OwnProps {
	type: DeliverableDataTypeEnum;
	list: DeliverableDataSingleViewModel[];
	label: string;
	onCreate: (form: DeliverableDataRequestModel) => void;
	onEdit: (form: DeliverableDataRequestModel) => void;
	onDelete: (form: DeliverableDataRequestModel) => void;
}

type Props = OwnProps;

interface State {
	createNew: boolean;
}

class DeliverableDataTypeList extends React.Component<Props, State> {
	state: State = {
		createNew: false,
	};

	openInput = () => {
		this.setState(() => ({ createNew: true }));
	};

	closeInput = () => {
		this.setState(() => ({ createNew: false }));
	};

	createNewValue = () => {
		const { onCreate, type } = this.props;
		onCreate({ name: 'test', type });
		this.closeInput();
	};

	onCreate = (form: DeliverableDataRequestModel) => {
		const { onCreate, type } = this.props;
		form.type = type;
		onCreate(form);
	};

	render() {
		const { type, list, label, onEdit, onDelete } = this.props;

		return (
			<div className={bemElement('deliverable-data', 'type-list')}>
				<div className={bemElement('deliverable-data', 'type-label')}>{label}</div>
				<Tag<DeliverableDataRequestModel>
					form={type}
					inline={false}
					labelKey="name"
					onCreate={this.onCreate}
					onDelete={onDelete}
					onEdit={onEdit}
					tagGroupName="Value"
					tags={list}
					validate={validate}
					valueKey="id"
				/>
			</div>
		);
	}
}

export default DeliverableDataTypeList;
