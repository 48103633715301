import * as React from 'react';
import { Modal } from 'react-bootstrap';

import Header from './Header';
import Body from './Body';
import Footer from './Footer';
import RefBody from './RefBody';

export type ModalStyle = 'danger' | 'info' | 'warning' | 'grey-warning';
export type ModalSize = 'sm' | 'md' | 'lg' | 'xl' | 'xxl';

interface OwnProps {
	closeModal?: () => void;
	modalStyle: ModalStyle;
	showModal: boolean;
	size: ModalSize;
	className?: string;
	id?: string;
	isContentSelectable?: boolean;
	onEntered?: () => void;
}

type Props = React.PropsWithChildren<OwnProps>;

class CustomModal extends React.PureComponent<Props> {
	static Header = Header;
	static Body = Body;
	static Footer = Footer;
	static RefBody = RefBody;

	static defaultProps: Partial<Props> = {
		isContentSelectable: true,
		closeModal: () => { /* */ },
	};

	render() {
		const {
			id,
			showModal,
			closeModal,
			modalStyle,
			size,
			className,
			children,
			isContentSelectable,
			onEntered,
		} = this.props;

		let cn = `${modalStyle}-modal modal--${size}`;
		cn = className ? `${cn} ${className}` : cn;
		cn = !isContentSelectable ? `${cn} modal--non-selectable` : cn;
		cn = showModal ? `${cn} modal--visible` : cn;

		return (
			<Modal
				backdrop={true}
				className={cn}
				id={id}
				onEntered={onEntered}
				onHide={closeModal}
				scrollable={true}
				show={showModal}
			>
				{children}
			</Modal>
		);
	}
}

export default CustomModal;
