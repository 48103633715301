import * as React from 'react';

import type { ImportBatchFunction, ParseFunction } from 'af-components/BulkUpsert/Upload';
import BulkUpload from 'af-components/BulkUpsert/Upload';

import * as CSVUtils from 'af-utils/csv.utils';

interface Props {
	importBatch: ImportBatchFunction;
	mandatoryFields?: string[];
	manual: (string | JSX.Element)[];
	fields: JSX.Element;
	sampleData: string[][];
	listRoute: string;
	title: string;
	parse: ParseFunction;
	important?: string;
	notes?: string;
	backButtonLabel?: string;
}

class BulkImport extends React.PureComponent<Props> {
	static defaultProps: Partial<Props> = {
		important: 'This process does not update, but only create entries.',
		notes: 'You can find a sample of what the CSV should look like and adjust yours accordingly',
	};

	renderDownloadCSV = () => {
		const { sampleData } = this.props;
		return (
			<a
				className="btn--upload"
				download="sample.csv"
				href={CSVUtils.generateCSVHref(sampleData)}
			>
				Download the sample CSV file
			</a>
		);
	};

	renderInstructions = () => {
		return (
			<span>
				You can use the CSV import tool below to upload large amounts of data into your Acceligent web application. The data must
				be formatted correctly in order for the upload to work. {this.renderDownloadCSV()} and read more about it in the instructions.
			</span>
		);
	};

	render() {
		const {
			parse,
			title,
			listRoute,
			backButtonLabel,
			mandatoryFields,
			manual,
			fields,
			important,
			notes,
		} = this.props;
		return (
			<BulkUpload
				backButtonLabel={backButtonLabel}
				fields={fields}
				important={important}
				listRoute={listRoute}
				mandatoryFields={mandatoryFields}
				manual={manual}
				notes={notes}
				parse={parse}
				renderInstructions={this.renderInstructions}
				title={title}
				uploadAction={this.props.importBatch}
			/>
		);
	}
}

export default BulkImport;
