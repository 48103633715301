import type CDLStatus from 'acceligent-shared/enums/cdlStatus';
import type { ColorPalette } from 'acceligent-shared/enums/color';
import type SubscriptionStatus from 'acceligent-shared/enums/subscriptionStatus';

import type { SearchableModel } from 'ab-common/dataStructures/scheduleBoardQuery';

import { getUserName } from 'acceligent-shared/utils/user';
import { formatPhoneNumber } from 'acceligent-shared/utils/phone';
import * as TextUtil from 'acceligent-shared/utils/text';

import type EmployeeBase from 'ab-domain/models/employee/base';
import type LocationBase from 'ab-domain/models/location/base';
import type SkillBase from 'ab-domain/models/skill/base';
import type AccountBase from 'ab-domain/models/account/base';

import type { ScheduleBoardQueryMatchingFunction } from 'ab-common/dataStructures/scheduleBoardQuery';

import * as TextBEUtil from 'ab-utils/text.util';
import { addToArrayIfExists } from 'ab-utils/array.util';

class SkillVM {
	id: number;
	name: string;
	color: ColorPalette;

	constructor(skill: SkillBase) {
		this.id = skill.id;
		this.name = skill.name;
		this.color = skill.color;
	}

	static bulkConstructor = (skills: SkillBase[]) => skills.map(SkillVM._constructorMap);
	private static _constructorMap = (_skill: SkillBase) => new SkillVM(_skill);
}

class ScheduleBoardOfficeVM {
	id: number;
	nickname: string;
	color: ColorPalette;
	index: number;

	constructor(location: LocationBase) {
		this.id = location.id;
		this.nickname = location.nickname;
		this.color = location.color;
		this.index = location.index;
	}
}

interface UserVM {
	id: number;
	fullName: string;
	email: Nullable<string>;
	phoneNumber: Nullable<string>;
}

class AccountVM {
	id: number;
	fullName: string;
	assignableToWorkOrder: boolean;
	assignableAsSuperintendent: boolean;
	assignableAsProjectManager: boolean;
	assignableAsQAQC: boolean;
	user: UserVM;

	constructor(account: AccountBase) {
		this.id = account.id;
		this.fullName = getUserName(account.user);
		this.assignableToWorkOrder = account.assignableToWorkOrder;
		this.assignableAsSuperintendent = account.assignableAsSuperintendent;
		this.assignableAsProjectManager = account.assignableAsProjectManager;
		this.assignableAsQAQC = account.assignableAsQAQC;
		this.user = {
			id: account.user.id,
			fullName: getUserName(account.user),
			email: account.user.email,
			phoneNumber: account.user.phoneNumber ? formatPhoneNumber(account.user.phoneNumber) : null,
		};
	}
}

export default class ScheduleBoardEmployee implements SearchableModel {
	static matches: ScheduleBoardQueryMatchingFunction<ScheduleBoardEmployee> = TextBEUtil.matches;

	id: number;
	firstName: string;
	lastName: string;
	skills: SkillVM[];
	office: Nullable<ScheduleBoardOfficeVM>;
	isDisabled: boolean; // do not need to initialize them on the server side
	isFilteredOnBoard: boolean; // do not need to initialize them on the server side
	isFilteredOnToolbar: boolean; // do not need to initialize them on the server side
	isMatched?: boolean;
	currentLocation: string;
	isDeleted: boolean;
	email: Nullable<string>;
	phoneNumber: Nullable<string>;
	account: AccountVM;
	emailInvalidatedAt: Nullable<Date>;
	phoneInvalidatedAt: Nullable<Date>;
	/** subscription status, name stripped in order to save lots of bytes on sch.board */
	subscriptionStatus: SubscriptionStatus;
	wageRate: string;
	showOnScheduleBoard: boolean;
	cdlStatus: CDLStatus;

	duplicateDisplayNameExists?: boolean;

	_desc: string;

	constructor(employee: EmployeeBase) {
		const _employeeUser = employee?.account?.user;

		this.id = employee.id;
		this.firstName = _employeeUser?.firstName;
		this.lastName = _employeeUser?.lastName;

		this.skills = employee.skills ? SkillVM.bulkConstructor(employee.skills) : [];
		this.office = employee?.account?.location && new ScheduleBoardOfficeVM(employee.account.location);
		this.wageRate = employee?.wageRate?.type;
		this.isDeleted = !!employee.isDeleted;

		this.email = _employeeUser?.email;
		this.phoneNumber = _employeeUser?.phoneNumber;
		this.account = employee?.account && new AccountVM(employee.account);
		this.emailInvalidatedAt = _employeeUser?.emailInvalidatedAt;
		this.phoneInvalidatedAt = _employeeUser?.phoneInvalidatedAt;
		this.subscriptionStatus = _employeeUser?.subscriptionStatus;
		// variations of fullName
		const searchableLiterals = [`${this.firstName} ${this.lastName}`];

		addToArrayIfExists(searchableLiterals, employee?.wageRate?.type);
		addToArrayIfExists(searchableLiterals, employee?.wageRate?.wageClassification);

		this._desc = searchableLiterals.join(' ');
		this.isMatched = false;
		this.showOnScheduleBoard = employee.showOnScheduleBoard;
		this.cdlStatus = employee.cdlStatus;
	}

	static getDisplayName(employee: ScheduleBoardEmployee): string {
		const { firstName, lastName, duplicateDisplayNameExists } = employee;
		return TextUtil.capitalizeText(`${firstName?.[0]}${firstName && duplicateDisplayNameExists ? firstName[1] : ''} ${lastName}`);
	}

	static getDisplayData(employee: ScheduleBoardEmployee): ScheduleBoardEmployeeDisplayData {
		const { firstName: _firstName, lastName, duplicateDisplayNameExists } = employee;
		return {
			firstName: TextUtil.capitalizeText(_firstName && (duplicateDisplayNameExists ? _firstName.slice(0, 2) : _firstName[0])),
			lastName: TextUtil.capitalizeText(lastName),
		};
	}
}

type ScheduleBoardEmployeeDisplayData = { firstName: string; lastName: string; };

export type ScheduleBoardEmployeesViewModel = { [employeeId: number]: ScheduleBoardEmployee; };

export type EmployeeAssignments = { [employeeId: number]: string[]; }; // [employeeId]: workOrderCode[]
