import * as React from 'react';

import { ColorPalette } from 'acceligent-shared/enums/color';

import type { SkillViewModel } from 'ab-viewModels/skill.viewModel';
import type { EmployeeTableVM } from 'ab-viewModels/employee/table.viewModel';
import type * as ScheduleBoardEmployeeSkillViewModel from 'ab-viewModels/scheduleBoardResourceSkill.viewModel';

import ColorSquare from 'af-components/ColorSquare';
import Tooltip from 'af-components/Tooltip';
import EmptyCell from './EmptyCell';

interface Props {
	skills: ScheduleBoardEmployeeSkillViewModel.Single[] | SkillViewModel[] | EmployeeTableVM['skills']; // Depending on the table
}

type ColorsDict = { [color in SkillViewModel['color']]?: string[]; };

const renderMessage = (colors: ColorsDict) => (
	Object.keys(colors).map((_color, _i1) => {
		return (
			<div className="skills-cell__popover-row" key={_i1}>
				<ColorSquare color={ColorPalette[_color]} />
				<span>{colors[_color].join(', ')}</span>
			</div>
		);
	})
);

const SkillsCell = ({ skills }: Props) => {
	if (!skills.length) {
		return <EmptyCell message="None" />;
	}

	const colors: ColorsDict = {};
	skills.forEach((_skill: SkillViewModel) => {
		const skillColor = colors[_skill.color];
		colors[_skill.color] = skillColor ? [...skillColor, _skill.name] : [_skill.name];
	});

	return (
		<Tooltip message={renderMessage(colors)} placement="left">
			<div className="skills-cell">
				{
					Object.keys(colors).map((_color, _index) => (
						<ColorSquare color={ColorPalette[_color]} key={_index} />
					))
				}
			</div>
		</Tooltip>
	);
};

export default React.memo(SkillsCell);
