import * as React from 'react';

import AccountPermissionTemplate from 'acceligent-shared/enums/accountPermissionTemplate';

interface OwnProps {
	companyStatus: Record<string, AccountPermissionTemplate>;
}

type Props = OwnProps;

const CompanyStatusCell: React.FC<Props> = (props: Props) => {
	const { companyStatus } = props;

	const renderCompanyStatus = React.useCallback((_companyName: string, _companyStatus: AccountPermissionTemplate) => {
		const status = _companyStatus === AccountPermissionTemplate.DELETED
			? <span className="text-grey">Deleted</span>
			: <span className="text-green">Active</span>;
		return (
			<div>
				{status} - {_companyName}
			</div>
		);
	}, []);

	return <div className={'company-status-cell'}>
		{Object.keys(companyStatus).map((companyName: string) => renderCompanyStatus(companyName, companyStatus[companyName]))}
	</div>;
};

export default CompanyStatusCell;
