import * as React from 'react';
import type { CustomRouteComponentProps } from 'react-router-dom';
import type { ConnectedProps } from 'react-redux';
import { connect } from 'react-redux';

import * as DivisionActions from 'af-actions/division';

import type { DivisionViewModel, GroupedDivisions } from 'ab-viewModels/division.viewModel';

import type DivisionRequestModel from 'ab-requestModels/division.requestModel';

import Breadcrumbs from 'af-components/Breadcrumbs';

import Loading from './Loading';
import DivisionItem from '../DivisionItem';
import DivisionCreate from '../DivisionCreate';

import { bemElement, bemBlock } from 'ab-utils/bem.util';

type OwnProps = CustomRouteComponentProps;

type Props = OwnProps & ConnectedProps<typeof connector>;

interface State {
	addingCrewType: boolean;
	divisions: Nullable<GroupedDivisions>;
	editingId: Nullable<number>;
}

class Divisions extends React.PureComponent<Props, State> {
	state: State = {
		addingCrewType: false,
		divisions: null,
		editingId: null,
	};

	async componentDidMount() {
		await this.load();
	}

	load = async () => {
		const { findAllForCompany } = this.props;
		const divisions = await findAllForCompany();
		this.setState(() => ({ divisions }));
	};

	create = async (form: DivisionRequestModel) => {
		const { createDivision } = this.props;
		await createDivision(form);
		await this.load();
	};

	update = async (id: number, form: DivisionRequestModel) => {
		const { updateDivision } = this.props;
		await updateDivision(id, form);
		await this.load();
	};

	remove = async (id: number) => {
		const { deleteDivision } = this.props;
		await deleteDivision(id);
		await this.load();
	};

	toggleAddingCrewType = () => this.setState(() => ({ addingCrewType: !this.state.addingCrewType }));

	toggleEditingDivision = (id: number) => this.setState(() => ({ editingId: id }));

	render() {
		const { divisions, addingCrewType, editingId } = this.state;

		if (!divisions) {
			return <Loading />;
		}

		const filteredDivisionKeys = Object.keys(divisions).filter((_divisionKey) => !!divisions[_divisionKey].length);

		return (
			<div className="form-segment resources-pilled">
				<Breadcrumbs items={[{ label: 'Divisions' }]} />
				<div className={bemBlock('form-box', ['full-padded'])}>
					{!filteredDivisionKeys.length
						? <div className={bemElement('resources-pilled', 'empty')}>There are no divisions available.</div>
						: <div className={bemElement('resources-pilled', 'list')}>
							{filteredDivisionKeys.map((_divisionKey, _index) =>
								<div
									className={bemElement('resources-pilled', 'color-list')}
									key={_index}
								>
									{divisions[_divisionKey].map((_division: DivisionViewModel, _i) => (
										<DivisionItem
											deleteDivision={this.remove}
											division={_division}
											editingId={editingId}
											index={_index}
											key={_i}
											toggleEditingDivision={this.toggleEditingDivision}
											updateDivision={this.update}
										/>
									))}
								</div>
							)}
						</div>
					}
					<div className={bemElement('resources-pilled', 'addition')}>
						{addingCrewType ?
							<DivisionCreate {...this.props} close={this.toggleAddingCrewType} createDivision={this.create} /> :
							<span className={bemBlock('btn', ['link'])} onClick={this.toggleAddingCrewType}>
								<span className="icon-plus" />
								<span>Add Division</span>
							</span>
						}
					</div>
				</div>
			</div>
		);
	}
}

function mapDispatchToProps() {
	return {
		findAllForCompany: DivisionActions.findAllForCompany,
		createDivision: DivisionActions.create,
		updateDivision: DivisionActions.update,
		deleteDivision: DivisionActions.deleteDivision,
	};
}

const connector = connect(null, mapDispatchToProps());

export default connector<React.ComponentClass<OwnProps>>(Divisions);
