import * as React from 'react';

import type { CompanyFeatureFlagsEntry } from 'ab-viewModels/companyFeatureFlags.viewModel';

import PreviewItem from '../../Shared/PreviewItem';
import SettingPreview from '../../Shared/SettingPreview';

interface Props {
	toggleEdit: () => void;
	specialEmails: (Nullable<CompanyFeatureFlagsEntry>)[];
}

export default class SpecialEmailsPreview extends React.PureComponent<Props> {

	renderItem = (featureFlag: Nullable<CompanyFeatureFlagsEntry>, index: number): JSX.Element => {
		const _featureFlag = featureFlag ?? {} as CompanyFeatureFlagsEntry;
		return (
			<PreviewItem
				checked={!!_featureFlag.checked}
				hint={_featureFlag.hint}
				id={`accqa__notification-settings__special-emails__${_featureFlag.key}`}
				indent={!!_featureFlag.indent}
				key={`${_featureFlag.label}${index}`}
				label={_featureFlag.label}
				showCheckbox={!!_featureFlag.showStatusIcon}
				value={_featureFlag.value ?? undefined}
			/>
		);
	};

	render() {
		const { toggleEdit, specialEmails } = this.props;

		return (
			<SettingPreview
				containerId="accqa__notification-settings__special-emails__container"
				editButtonId="accqa__notification-settings__special-emails__edit-button"
				toggleEdit={toggleEdit}
			>
				<div className="company-settings__info-segment">
					{
						specialEmails.map((_featureFlag: CompanyFeatureFlagsEntry, _index: number) => (
							this.renderItem(_featureFlag, _index))
						)
					}
				</div>
			</SettingPreview>
		);
	}
}
