import * as React from 'react';
import type { History } from 'history';

import Toolbar from './Toolbar';
import Board from './Board';

interface OwnProps {
	addBlankWorkOrder: (dueDate: string, index: number) => Promise<void>;
	className: string;
	companyName: string;
	droppableId: string;
	dueDate: string;
	forceUnlockOrder: (dueDate: string, workOrderId: string) => void;
	hasPermissionsToEditScheduleBoard: boolean;
	history: History;
	isDragAndDropDisabled: boolean;
	isWeeklyViewSelectMultiple: boolean;
	lastOpenedOrderCode: string;
	maxWorkOrdersPerDay: number;
	onHorizontalScroll: (scrollPercentage: number, width: number) => void;
	onHorizontalScrollMount: (scrollElement: Metadata) => void;
	onHorizontalScrollUpdate: () => void;
	orgAlias: string;
	removeBlankWorkOrder: (dueDate: string, index: number) => Promise<void>;
	weeklyViewDateWithToolbar: Nullable<string>;
}

type Props = OwnProps;

const DayView: React.FC<Props> = (props) => {
	const {
		addBlankWorkOrder,
		className,
		companyName,
		droppableId,
		dueDate,
		hasPermissionsToEditScheduleBoard,
		history,
		isDragAndDropDisabled,
		isWeeklyViewSelectMultiple,
		lastOpenedOrderCode,
		maxWorkOrdersPerDay,
		onHorizontalScroll,
		onHorizontalScrollMount,
		onHorizontalScrollUpdate,
		orgAlias,
		removeBlankWorkOrder,
		weeklyViewDateWithToolbar,
		forceUnlockOrder,
	} = props;

	const forceUnlockWorkOrder = React.useCallback((workOrderId: string) => {
		forceUnlockOrder(dueDate, workOrderId);
	}, [dueDate, forceUnlockOrder]);

	const lockForToolbar: boolean = !!weeklyViewDateWithToolbar && weeklyViewDateWithToolbar !== dueDate;
	const lock: boolean = isWeeklyViewSelectMultiple || lockForToolbar;

	return (
		<div className={`schedule-board-week-row ${className}`}>
			{lock && <div className="day-curtain" />}
			<Toolbar
				companyName={companyName}
				dueDate={dueDate}
				hasPermissionsToEditScheduleBoard={hasPermissionsToEditScheduleBoard}
				history={history}
				isDragAndDropDisabled={isDragAndDropDisabled}
				orgAlias={orgAlias}
			/>
			<Board
				addBlankWorkOrder={addBlankWorkOrder}
				droppableId={droppableId}
				dueDate={dueDate}
				forceUnlockOrder={forceUnlockWorkOrder}
				hasPermissionsToEditScheduleBoard={hasPermissionsToEditScheduleBoard}
				isDragAndDropDisabled={isDragAndDropDisabled}
				lastOpenedOrderCode={lastOpenedOrderCode}
				maxWorkOrdersPerDay={maxWorkOrdersPerDay}
				onHorizontalScroll={onHorizontalScroll}
				onHorizontalScrollMount={onHorizontalScrollMount}
				onHorizontalScrollUpdate={onHorizontalScrollUpdate}
				removeBlankWorkOrder={removeBlankWorkOrder}
			/>
		</div>
	);
};

export default React.memo(DayView);
