import * as React from 'react';

import type ToolVM from 'ab-viewModels/tool/option.viewModel';

import styles from './styles.module.scss';

type Props = ToolVM;

const ToolLabel = (option: Props) => {
	return (
		<div className={styles['tool-option']}>
			<span>{option.serialNumber}</span>
			<span className={styles['tool-option__type']}>{option.toolType}</span>
		</div>
	);
};

export default ToolLabel;
