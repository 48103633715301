import * as TimeUtils from 'acceligent-shared/utils/time';

import TimeFormatEnum from 'acceligent-shared/enums/timeFormat';
import type SystemNotificationType from 'acceligent-shared/enums/systemNotificationType';
import type { TimeOptionValues } from 'acceligent-shared/enums/timeOption';

import type SystemNotificationBase from 'ab-domain/models/systemNotification/base';

import * as TimeOptionUtils from 'acceligent-shared/utils/timeOption';

class SystemNotificationRequestModel {
	id: number;
	type: SystemNotificationType;
	content: string;
	startsAt: Date;
	startsAtDate?: string; // only on unparsed version
	startsAtTime?: TimeOptionValues; // only on unparsed version
	endsAt: Date;
	endsAtDate?: string; // only on unparsed version
	endsAtTime?: TimeOptionValues; // only on unparsed version

	constructor(unparsedForm: SystemNotificationRequestModel) {
		if (!unparsedForm.startsAtDate || !unparsedForm.startsAtTime || !unparsedForm.endsAtDate || !unparsedForm.endsAtTime) {
			throw new Error('Times and dates not provided');
		}

		const startsAtTime = TimeOptionUtils.toNumber(unparsedForm.startsAtTime);
		const endsAtTime = TimeOptionUtils.toNumber(unparsedForm.endsAtTime);
		if (!startsAtTime || !endsAtTime) {
			throw new Error('Times in incorrect format');
		}

		this.type = unparsedForm.type;
		this.content = unparsedForm.content;

		const startsAtMoment = TimeUtils.parseMoment(unparsedForm.startsAtDate, TimeFormatEnum.DATE_ONLY);
		const endsAtMoment = TimeUtils.parseMoment(unparsedForm.endsAtDate, TimeFormatEnum.DATE_ONLY);
		if (!startsAtMoment || !endsAtMoment) {
			throw new Error('Times in incorrect format');
		}

		this.startsAt = startsAtMoment.add(startsAtTime, 'minutes').toDate();
		this.endsAt = endsAtMoment.add(endsAtTime, 'minutes').toDate();
	}

	static fromModelToRequestModel(systemNotification: SystemNotificationBase): SystemNotificationRequestModel {
		return {
			id: systemNotification.id,
			type: systemNotification.type,
			content: systemNotification.content,
			startsAt: systemNotification.startsAt,
			endsAt: systemNotification.endsAt,
		};
	}

	static fromModelListToRequestModelList(systemNotifications: SystemNotificationBase[]): SystemNotificationRequestModel[] {
		return systemNotifications.map((_sn) => this.fromModelToRequestModel(_sn));
	}

}

export default SystemNotificationRequestModel;
