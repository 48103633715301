import * as React from 'react';
import { Row, Col } from 'react-bootstrap';
import type { ConnectedProps } from 'react-redux';
import { connect } from 'react-redux';
import { compose } from 'redux';
import type { InjectedFormProps} from 'redux-form';
import { reduxForm, Field } from 'redux-form';

import { phoneNormalizer } from 'ab-utils/reduxForms.util';

import type { RootState } from 'af-reducers/index';

import Input from 'af-fields/Input';
import Checkbox from 'af-fields/Checkbox';

import { NewContactFormModel } from './formModel';

type OwnProps = EmptyObject;

type Props = OwnProps & InjectedFormProps<NewContactFormModel> & ConnectedProps<typeof connector>;

const NewContactForm: React.FC<Props> = (props) => {
	const { isEmailUsed, isPhoneUsed } = props;

	return (
		<div className="contact-action-modal__new-contact-form">
			<Row>
				<Col sm={24}>
					Use to send Reports:
				</Col>
			</Row>
			<Row>
				<Row className="contact-action-modal__new-contact-form__input-row">
					<Col sm={6}>
						<Field
							component={Checkbox}
							inline={true}
							label="Email"
							name="isEmailUsed"
						/>
					</Col>
					<Col sm={18}>
						{isEmailUsed &&
							<Field
								component={Input}
								name="email"
								placeholder="Enter Email"
								type="text"
							/>
						}
					</Col>
				</Row>
				<Row className="contact-action-modal__new-contact-form__input-row">
					<Col sm={6}>
						<Field
							component={Checkbox}
							inline={true}
							label="Phone"
							name="isPhoneUsed"
						/>
					</Col>
					<Col sm={18}>
						{isPhoneUsed &&
							<Field
								component={Input}
								id="phoneNumber"
								name="phoneNumber"
								normalize={phoneNormalizer}
								placeholder="Enter Phone Number"
								type="text"
							/>
						}
					</Col>
				</Row>
			</Row>
		</div>
	);
};

function mapStateToProps(state: RootState) {
	const { isEmailUsed, isPhoneUsed } = NewContactFormModel.selector(state, 'isEmailUsed', 'isPhoneUsed');

	return {
		isEmailUsed,
		isPhoneUsed,
	};
}

const connector = connect(mapStateToProps);

const enhance = compose<React.ComponentType<OwnProps>>(
	React.memo,
	connector,
	reduxForm({ form: NewContactFormModel.FORM_NAME, validate: NewContactFormModel.validate })
);

export { NewContactFormModel } from './formModel';
export default enhance(NewContactForm);
