import * as React from 'react';
import { Field, getFormValues } from 'redux-form';
import type { ConnectedProps } from 'react-redux';
import { connect } from 'react-redux';

import BlobStorageContainer from 'acceligent-shared/enums/blobStorageContainer';

import type { AttachmentEntity } from 'af-components/DocumentsAttachmentsTree/types';

import { WORK_REQUEST_FORM } from 'af-constants/reduxForms';

import type { RootState } from 'af-reducers';

import type { DirectoryRM, Column } from 'af-components/SharedForms/DirectoriesAndAttachments';
import DirectoriesAndAttachmentsForm from 'af-components/SharedForms/DirectoriesAndAttachments';

import type WorkRequestFM from '../formModel';

const COLUMNS: Column[] = [
	{
		header: 'Category folder',
		id: 'categoryFolder',
		width: 6,
	},
	{
		header: 'Files',
		id: 'files',
		width: 1,
	},
	{
		header: 'Download',
		id: 'download',
		width: 1,
	},
	{
		header: 'Upload',
		id: 'upload',
		width: 1,
	},
	{
		header: 'Preview',
		id: 'preview',
		width: 1,
	},
	{
		header: 'Last modified',
		id: 'lastModified',
		width: 2,
	},
];

interface OwnProps {
	jobId: number;
	change: (fieldName: string, value: unknown) => void;
}

type Props = OwnProps & ConnectedProps<typeof connector>;

const Attachments: React.FC<Props> = (props) => {
	const {
		attachments,
		change: stupidChangeWhichChanges,
		directories,
		uploadedAttachmentIds,
		uploadingAttachments,
		jobId,
	} = props;

	// eslint-disable-next-line react-hooks/exhaustive-deps
	const change = React.useMemo(() => stupidChangeWhichChanges, []);
	// ignore deps - change changes for no reason

	const handleDirectoriesChanged = React.useCallback((changedDirectories: DirectoryRM[]) => {
		change('directories', changedDirectories);
	}, [change]);

	const handleAttachmentsChanged = React.useCallback((changedAttachments: AttachmentEntity) => {
		change('workRequestAttachments', changedAttachments);
	}, [change]);

	const handleAttachmentsUpload = React.useCallback((attachmentIds: number[]) => {
		change('uploadedAttachmentIds', [...attachmentIds]);
	}, [change]);

	const handleAttachmentUploadStart = React.useCallback((correlationId: string) => {
		const nextUploadingAttachments = { ...uploadingAttachments };
		nextUploadingAttachments[correlationId] = true;
		change('uploadingAttachments', nextUploadingAttachments);
	}, [change, uploadingAttachments]);

	const handleAttachmentUploadEnd = React.useCallback((correlationId: string) => {
		const nextUploadingAttachments = { ...uploadingAttachments };
		delete nextUploadingAttachments[correlationId];
		change('uploadingAttachments', nextUploadingAttachments);
	}, [change, uploadingAttachments]);

	if (!jobId) {
		return null;
	}

	return (
		<Field
			attachments={attachments}
			component={DirectoriesAndAttachmentsForm}
			containerId={jobId}
			directories={directories}
			name="uploadingAttachments"
			onAttachmentsChanged={handleAttachmentsChanged}
			onAttachmentsUpload={handleAttachmentsUpload}
			onAttachmentUploadEnd={handleAttachmentUploadEnd}
			onAttachmentUploadStart={handleAttachmentUploadStart}
			onDirectoriesChanged={handleDirectoriesChanged}
			storageContainer={BlobStorageContainer.WR_ATTACHMENTS}
			tableColumns={COLUMNS}
			uploadedAttachmentIds={uploadedAttachmentIds}
		/>
	);
};

function mapStateToProps(state: RootState) {
	const { user: { userData, companyData } } = state;
	if (!userData || !companyData) {
		throw new Error('User not logged in');
	}
	const formData = getFormValues(WORK_REQUEST_FORM)(state) as WorkRequestFM;

	return {
		attachments: formData?.workRequestAttachments ?? null,
		directories: formData?.directories ?? null,
		uploadedAttachmentIds: formData?.uploadedAttachmentIds ?? null,
		uploadingAttachments: formData?.uploadingAttachments,
	};
}

const connector = connect(mapStateToProps);

export default connector(Attachments);
