export type WorkRequestFormOutletContext = {
	// eslint-disable-next-line @typescript-eslint/no-explicit-any
	change: (field: string, value: any) => void;
	workRequestId?: string;
	formName: string;
};

export enum WorkRequestFormTabRoutes {
	DETAILS = 'details',
	JOB_HAZARD_ASSESSMENT = 'jobHazardAssessment',
	ATTACHMENTS = 'attachments',
}
