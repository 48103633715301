import * as React from 'react';
import { compose } from 'redux';

import EmployeeSection from './EmployeeSection';
import EquipmentSection from './EquipmentSection';
import DailyTipSection from './DailyTipSection';

import BrowserStorageEnum from 'ab-enums/browserStorage.enum';

import * as SettingsKeys from 'af-constants/settingsKeys';

import { withSettings } from 'af-utils/settings.util';

interface OwnProps {
	dueDate: string;
	isDragAndDropDisabled: boolean;
	hasPermissionsToEditScheduleBoard: boolean;
	hasPermissionsToSendNotifications: boolean;
	sendNotification: (dueDate: string, notifyByEmail: number[], notifyBySms: number[]) => void;
	scheduleAutoNotify: (dueDate: string, notifyByEmail: number[], notifyBySms: number[]) => void;
}

interface SettingsProps {
	isTipsSectionCollapsed: boolean;
}

type Props = OwnProps & SettingsProps;

const Toolbar: React.FC<Props> = (props) => {
	const {
		dueDate,
		sendNotification,
		isDragAndDropDisabled,
		hasPermissionsToEditScheduleBoard,
		hasPermissionsToSendNotifications,
		scheduleAutoNotify,
	} = props;

	const [isTipsSectionCollapsed, setIsTipsSectionCollapsed] = React.useState(props.isTipsSectionCollapsed);
	const setDailyTipsSectionCollapsed = React.useCallback(
		(value: boolean) => setIsTipsSectionCollapsed(value), [setIsTipsSectionCollapsed]);

	return (
		<div className="schedule-board-toolbar">
			<div className={`resources ${isTipsSectionCollapsed ? 'resources--extended' : ''}`}>
				<EmployeeSection
					dueDate={dueDate}
					hasPermissionsToEditScheduleBoard={hasPermissionsToEditScheduleBoard}
					hasPermissionsToSendNotifications={hasPermissionsToSendNotifications}
					isDragAndDropDisabled={isDragAndDropDisabled}
					scheduleAutoNotify={scheduleAutoNotify}
					sendNotification={sendNotification}
				/>
				<EquipmentSection
					dueDate={dueDate}
					hasPermissionsToEditScheduleBoard={hasPermissionsToEditScheduleBoard}
					isDragAndDropDisabled={isDragAndDropDisabled}
				/>
			</div>
			<DailyTipSection
				dueDate={dueDate}
				isCollapsed={isTipsSectionCollapsed}
				setDailyTipsSectionCollapsed={setDailyTipsSectionCollapsed}
			/>
		</div>
	);
};

const enhance = compose<React.ComponentType<OwnProps>>(
	withSettings<SettingsProps>(() => ([
		{
			key: SettingsKeys.DAILY_TIP_SECTION_COLLAPSED(),
			mappedName: 'isTipsSectionCollapsed',
			normalize: (value: string) => value === 'true',
			defaultValue: false,
			source: BrowserStorageEnum.LOCAL_STORAGE,
		},
	])),
	React.memo
);

export default enhance(Toolbar);
