import * as React from 'react';
import type { DraggableProvided } from 'react-beautiful-dnd';
import type { ResolveThunks } from 'react-redux';
import { connect } from 'react-redux';

import CDLStatus from 'acceligent-shared/enums/cdlStatus';
import type { ExtendedColorValue } from 'acceligent-shared/enums/color';
import { DefaultColor } from 'acceligent-shared/enums/color';
import type NotificationStatusEnum from 'acceligent-shared/enums/notificationStatus';

import type { RootState } from 'af-reducers';

import type EmployeeNotificationStatus from 'ab-enums/employeeNotificationStatus.enum';
import PagePermissions from 'ab-enums/pagePermissions.enum';

import type ScheduleBoardEmployeeViewModel from 'ab-viewModels/scheduleBoardEmployee.viewModel';

import { setCopiedEmployeeId } from 'af-actions/scheduleBoard/scheduleBoard.actions';

import MultiAssignmentsBadge from 'af-root/scenes/Company/ScheduleBoard/Shared/MultiAssignmentsBadge';
import CopyProgressBar from 'af-root/scenes/Company/ScheduleBoard/Shared/CopyProgressBar';
import PressAndHoldAnchor from 'af-root/scenes/Company/ScheduleBoard/Shared/PressAndHoldAnchor';

import { isAllowed } from 'ab-utils/auth.util';
import * as ScheduleBoardUtils from 'ab-utils/scheduleBoard.util';

import { generateEmployeeSearchItemId, generateWorkOrderEmployeeSearchItemId, isInToolbar } from 'af-utils/scheduleBoard.util';

import DownEmployeeBadge from '../DownEmployeeBadge';
import NightShiftBadge from '../NightShiftBadge';

import EmployeeDraggableBadgesContainer from './BadgesContainer';
import DraggableItemBody from './DraggableItemBody';

interface OwnProps extends DraggableProvided {
	dueDate: string;
	employeeId: number;
	isDragging: boolean;
	isPerDiem?: boolean;
	isToolbar: boolean;
	isWorkOrderCanceled?: boolean;
	onClick: () => void;
	isDragAndDropDisabled: boolean;
	hasPermissionsToEditScheduleBoard: boolean;
	resourceId?: number;
	isCardDisabled: boolean;
	workOrderCode?: string;
	isDropAnimating?: boolean;
	draggingOver?: string;
	isWOLocked: boolean;
}

interface StateProps {
	copiedEmployeeId: Nullable<number>;
	employee: ScheduleBoardEmployeeViewModel;
	employeeAssignmentCount: number;
	isAvailable: boolean;
	isActive: boolean;
	searchId: Nullable<string>;
	isAllowedToManagePerDiems: boolean;
	isDailyView: boolean;
	isDisabled: boolean;
	isShowNotifyAvailableEmployeesDisabled: boolean;
	isToolbarShown: boolean;
	isDeletedResource: boolean;
	notificationStatus: EmployeeNotificationStatus | undefined;
	toolbarNotificationSent: boolean;
	toolBarNotificationStatus?: EmployeeNotificationStatus;
	wasInNightShift: boolean;
}

interface DispatchProps {
	setCopiedEmployeeId: typeof setCopiedEmployeeId;
}

type Props = OwnProps & StateProps & ResolveThunks<DispatchProps>;

const Draggable: React.FC<Props> = (props) => {
	const [draggableItemClassName, setDraggableItemClassName] = React.useState<string>('');
	const [officeColor, setOfficeColor] = React.useState<ExtendedColorValue>(DefaultColor.LOCATION);
	const [showStatusIcon, setShowStatusIcon] = React.useState<boolean>(false);

	const {
		isPerDiem,
		isAllowedToManagePerDiems,
		isAvailable,
		draggableProps,
		dragHandleProps,
		innerRef,
		onClick,
		employee,
		notificationStatus,
		isToolbar,
		employeeAssignmentCount,
		isDragging,
		copiedEmployeeId,
		searchId,
		isWorkOrderCanceled,
		toolBarNotificationStatus,
		isDragAndDropDisabled,
		hasPermissionsToEditScheduleBoard,
		isDailyView,
		isToolbarShown,
		isDeletedResource,
		wasInNightShift,
		isWOLocked,
		isDropAnimating,
		draggingOver,
		isDisabled,
		isActive,
		isShowNotifyAvailableEmployeesDisabled,
		toolbarNotificationSent,
		setCopiedEmployeeId: _setCopiedEmployeeId,
	} = props;

	React.useEffect(() => {
		let newDraggableItemClassName = 'sb-resource-item sb-resource-item--employee';
		const { isMatched, cdlStatus, isFilteredOnToolbar, isFilteredOnBoard } = employee;

		if (isDropAnimating && !isToolbar && (!draggingOver || isInToolbar(draggingOver))) {
			newDraggableItemClassName += ' sb-resource-item--hidden';
		} else {
			newDraggableItemClassName += isDisabled && !isWorkOrderCanceled ? ' sb-resource-item--disabled' : '';
			newDraggableItemClassName += isMatched ? ' sb-resource-item--highlighted' : '';
			newDraggableItemClassName += isActive ? ' sb-resource-item--active' : '';
			newDraggableItemClassName += cdlStatus === CDLStatus.NO_CDL ? ' sb-resource-item--no-cdl' : '';
			newDraggableItemClassName += (isToolbar && isFilteredOnToolbar) || (!isToolbar && isFilteredOnBoard) ? ' filtered' : '';
		}
		newDraggableItemClassName = newDraggableItemClassName.trim();

		if (newDraggableItemClassName !== draggableItemClassName) {
			setDraggableItemClassName(newDraggableItemClassName);
		}
	}, [draggableItemClassName, draggingOver, employee, isActive, isDisabled, isDropAnimating, isToolbar, isWorkOrderCanceled, props]);

	React.useEffect(() => {
		const newOfficeColor = employee?.office?.color;

		if (newOfficeColor !== officeColor) {
			setOfficeColor(newOfficeColor ?? DefaultColor.LOCATION);
		}
	}, [officeColor, employee?.office?.color]);

	React.useEffect(() => {
		const newShowStatusIcon = !isShowNotifyAvailableEmployeesDisabled &&
			isToolbar &&
			isAvailable &&
			toolbarNotificationSent &&
			!props.employee?.account.assignableAsSuperintendent;

		if (newShowStatusIcon !== showStatusIcon) {
			setShowStatusIcon(newShowStatusIcon);
		}
	}, [
		isToolbar,
		isAvailable,
		props.employee?.account.assignableAsSuperintendent,
		, showStatusIcon,
		isShowNotifyAvailableEmployeesDisabled,
		toolbarNotificationSent,
	]);

	const onHoldEnd = React.useCallback(() => {
		_setCopiedEmployeeId(employee.id);
	}, [_setCopiedEmployeeId, employee.id]);

	const onRelease = React.useCallback(() => {
		if (!isDragging) {
			_setCopiedEmployeeId(null);
		}
	}, [_setCopiedEmployeeId, isDragging]);

	const renderEmployeeDraggable = React.useCallback((isCopying: boolean, copyFinished: boolean) => {
		const body = (
			<div className="sb-resource-item__content">
				<DraggableItemBody
					employee={employee}
					hasPermissionsToEditScheduleBoard={hasPermissionsToEditScheduleBoard}
					isAllowedToManagePerDiems={isAllowedToManagePerDiems}
					isDragAndDropDisabled={isDragAndDropDisabled}
					isPerDiem={isPerDiem}
					officeColor={officeColor}
				/>
			</div>
		);

		return isToolbar || !employee.account.assignableToWorkOrder ? body : (
			<>
				{body}
				<CopyProgressBar
					copyFinished={copyFinished}
					isCopying={isCopying}
				/>
			</>
		);
	}, [employee, hasPermissionsToEditScheduleBoard, isAllowedToManagePerDiems, isDragAndDropDisabled, isPerDiem, isToolbar, officeColor]);

	if (isDeletedResource) {
		return null;
	}

	const useRegularAnchor = isToolbar || (isDragAndDropDisabled && !isWOLocked) || !hasPermissionsToEditScheduleBoard || (!isDailyView && !isToolbarShown);
	const showMultiAssignmentsBadge = !isWorkOrderCanceled && employeeAssignmentCount > 1;
	const _showStatusIcon = showStatusIcon && toolBarNotificationStatus;
	const isDraggingCopiedEmployee = isDragging && copiedEmployeeId && copiedEmployeeId === employee.id;

	return (
		<>
			<div
				id={searchId ?? undefined}
				ref={innerRef}
				{...draggableProps}
				{...dragHandleProps}
				className={draggableItemClassName}
			>
				{notificationStatus && <div className={`icon-notification_status ${notificationStatus.toLowerCase()}`} />}
				{_showStatusIcon && <div className={`icon-notification_status ${toolBarNotificationStatus.toLowerCase()}`} />}
				<PressAndHoldAnchor
					onClick={onClick}
					onHoldEnd={onHoldEnd}
					onRelease={onRelease}
					regularAnchor={useRegularAnchor}
				>
					{renderEmployeeDraggable}
				</PressAndHoldAnchor>
				<EmployeeDraggableBadgesContainer>
					{showMultiAssignmentsBadge && <MultiAssignmentsBadge count={employeeAssignmentCount} disableAutoPositioning />}
					{wasInNightShift && <NightShiftBadge />}
				</EmployeeDraggableBadgesContainer>
			</div>
			{isDraggingCopiedEmployee && (
				<div className={draggableItemClassName}>
					{notificationStatus && <div className={`icon-notification_status ${notificationStatus.toLowerCase()}`} />}
					{_showStatusIcon && <div className={`icon-notification_status ${toolBarNotificationStatus.toLowerCase()}`} />}
					{renderEmployeeDraggable(false, false)}
					{!isAvailable && <DownEmployeeBadge />}
					{showMultiAssignmentsBadge && <MultiAssignmentsBadge count={employeeAssignmentCount} />}
				</div>
			)}
		</>
	);
};

function mapStateToProps(state: RootState, ownProps: OwnProps): StateProps {
	const {
		employees,
		draggedEmployeeId,
		workOrdersByDateDictionary,
		copiedEmployeeId,
		dates,
		weeklyViewDateWithToolbar,
		activeSearchItemIndex,
		searchResultItems,
	} = state.scheduleBoard;
	const { companyData, userData } = state.user;
	const { company } = state.company;
	const { dueDate, workOrderCode, isToolbar, isWorkOrderCanceled, resourceId, employeeId, isCardDisabled } = ownProps;

	if (!userData || !companyData) {
		throw new Error('User not logged in');
	}

	const employee = employees?.[employeeId];

	if (!employee) {
		throw new Error(`Employee ${employeeId} not defined`);
	}

	const { email = undefined, phoneNumber = undefined } = employee ?? {};

	const isAllowedToManagePerDiems: boolean = isAllowed(PagePermissions.COMPANY.PER_DIEMS, companyData.permissions, companyData.isCompanyAdmin, userData.role);

	let notificationStatus: EmployeeNotificationStatus | undefined = undefined;
	let searchId: Nullable<string> = null;
	let isDeletedResource = false;
	const notificationStatusMap = workOrdersByDateDictionary?.[dueDate]?.availableNotificationStatuses;
	const employeeNightShiftAssignments = workOrdersByDateDictionary?.[dueDate]?.employeeNightShiftAssignments;

	const emailNotification: Nullable<NotificationStatusEnum> = notificationStatusMap?.[employeeId]?.emailStatus ?? null;
	const smsNotification: Nullable<NotificationStatusEnum> = notificationStatusMap?.[employeeId]?.smsStatus ?? null;
	const emailNotificationSentAt: Nullable<Date> = notificationStatusMap?.[employeeId]?.emailSentAt ?? null;
	const smsNotificationSentAt: Nullable<Date> = notificationStatusMap?.[employeeId]?.smsSentAt ?? null;
	const toolbarNotificationSent = !!(email && !!emailNotification) || !!(phoneNumber && !!smsNotification);

	if (!isToolbar && employee) {
		const dataOnDate = workOrdersByDateDictionary?.[dueDate];
		const _woResources = !!workOrderCode
			? dataOnDate?.workOrders?.[workOrderCode]?.workOrderResourceLookups ?? []
			: [];
		const _resources = isWorkOrderCanceled ? dataOnDate?.canceledWorkOrderResourceLookups : dataOnDate?.workOrderResourceLookups;
		const _notificationStatusMap = isWorkOrderCanceled
			? dataOnDate?.assignedCanceledNotificationStatuses
			: dataOnDate?.assignedPublishedNotificationStatuses;
		const _resourceId = _woResources.find((_wor: number) => {
			if (_resources?.[_wor]?.employeeId === employeeId) {
				return true;
			}
			return false;
		});
		if (resourceId && _resourceId && _resources?.[resourceId]) {
			searchId = _resources[resourceId]?.workOrderEmployeeId?.toString() !== undefined
				? generateWorkOrderEmployeeSearchItemId(_resources[resourceId].workOrderEmployeeId!.toString())
				: null;
			const status = _resources[resourceId]?.workOrderEmployeeId ? _notificationStatusMap?.[_resources[resourceId].workOrderEmployeeId!] : null;
			notificationStatus = ScheduleBoardUtils.getEmployeeNotificationStatus(
				status?.emailStatus ?? null,
				status?.smsStatus ?? null,
				status?.emailSentAt ?? null,
				status?.smsSentAt ?? null,
				!!status?.isPreviousRevision
			);
		} else {
			isDeletedResource = true;
		}
	} else {
		searchId = generateEmployeeSearchItemId(employeeId.toString());
	}

	const isDraggedFromToolbarInOtherTab = isToolbar && employee.isDisabled && !draggedEmployeeId;
	const dataOnDate = workOrdersByDateDictionary?.[dueDate];
	const isAvailable = !dataOnDate?.employeeUnavailabilityDetails?.[employee.id];

	return {
		employee,
		searchId,
		isAvailable,
		isActive: searchResultItems[activeSearchItemIndex] === searchId,
		isAllowedToManagePerDiems,
		copiedEmployeeId,
		isDisabled: (!isCardDisabled && draggedEmployeeId === employee.id) || isDraggedFromToolbarInOtherTab,
		notificationStatus,
		toolbarNotificationSent,
		employeeAssignmentCount: (workOrdersByDateDictionary[dueDate].employeeAssignments[employeeId] || []).length,
		toolBarNotificationStatus: ScheduleBoardUtils.getEmployeeNotificationStatus(
			emailNotification,
			smsNotification,
			emailNotificationSentAt,
			smsNotificationSentAt
		),
		isShowNotifyAvailableEmployeesDisabled: !(company?.notification?.isEnabled ?? false),
		isDailyView: dates.length === 1,
		isToolbarShown: weeklyViewDateWithToolbar === dueDate,
		isDeletedResource,
		wasInNightShift: !!employeeNightShiftAssignments?.[employee.id],
	};
}

function mapDispatchToProps(): DispatchProps {
	return {
		setCopiedEmployeeId,
	};
}

export default connect<StateProps, DispatchProps, OwnProps>(mapStateToProps, mapDispatchToProps())(Draggable);
