export class W_Job_FindUserGroupAndPMForInvoicing_VM {
	userGroupId: Nullable<number>;
	userGroupName: Nullable<string>;
	pmEmail: Nullable<string>;
	pmFirstName: Nullable<string>;
	pmLastName: Nullable<string>;

	constructor(
		userGroupId: Nullable<number>,
		userGroupName: Nullable<string>,
		pmEmail: Nullable<string>,
		pmFirstName: Nullable<string>,
		pmLastName: Nullable<string>
	) {
		this.userGroupId = userGroupId;
		this.userGroupName = userGroupName;
		this.pmEmail = pmEmail;
		this.pmFirstName = pmFirstName;
		this.pmLastName = pmLastName;
	}

}
