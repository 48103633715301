import * as React from 'react';

import type { PCFRTypeVM, PCFRBlockVM } from 'ab-viewModels/report/publicConfirmation.viewModel';

import * as FieldReportUtils from 'ab-utils/fieldReport.util';

import FieldReportBlocks from './FieldReportBlocks';

interface OwnProps {
	fieldReportType: PCFRTypeVM;
}

type Props = OwnProps;

const FieldReportType: React.FC<Props> = (props: Props) => {
	const {
		reportTypeName,
		fieldReportBlocks,
		index,
	} = props.fieldReportType;

	const [segmentCountMap, instanceCount] = React.useMemo(() => {
		const _segmentCountMap = FieldReportUtils.getSegmentCount(fieldReportBlocks);
		const _instanceCount = Object.keys(_segmentCountMap).length;
		return [_segmentCountMap, _instanceCount];
	}, [fieldReportBlocks]);

	const _renderFieldReportBlock = React.useCallback((_block: PCFRBlockVM) => {
		const segmentCount = _block.instanceIndex !== null ? segmentCountMap[_block.instanceIndex] : 0;

		return (
			<FieldReportBlocks
				fieldReportBlock={_block}
				instanceCount={instanceCount}
				key={`${_block.index}#${_block.segmentIndex}`}
				segmentCount={segmentCount}
			/>
		);
	}, [segmentCountMap, instanceCount]);

	return (
		<div key={`report-type-${index}`}>
			<div className="public-report__segment__title">
				{reportTypeName}
			</div>
			{fieldReportBlocks.map(_renderFieldReportBlock)}
		</div>
	);
};

export default React.memo(FieldReportType);
