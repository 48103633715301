import * as React from 'react';
import { Row, Col } from 'react-bootstrap';
import type { CellContext } from '@tanstack/react-table';

import SimpleTableControl from 'af-components/Controls/SimpleTable';
import type { SimpleTableProps } from 'af-components/Controls/SimpleTable/types';

import type { OrderItemVM } from 'ab-viewModels/toolRepair/preview.viewModel';
import type ToolRepairVM from 'ab-viewModels/toolRepair/preview.viewModel';

import styles from './styles.module.scss';

interface OwnProps {
	toolRepair: ToolRepairVM;
}

type Props = OwnProps;

const InternalRepairSection = (props: Props) => {
	const {
		toolRepair,
	} = props;

	const columns: SimpleTableProps<OrderItemVM>['columns'] = React.useMemo(() => [{
		id: 'quantity',
		cell: (_cell: CellContext<OrderItemVM, number>) => {
			return _cell.getValue();
		},
		header: 'Quantity',
		accessor: 'quantity',
		size: 80,
		enableSorting: false,
	},
	{
		id: 'vendorNumber',
		cell: (_cell: CellContext<OrderItemVM, string>) => _cell.getValue() ?? 'N/A',
		header: 'Vendor Number',
		size: 200,
		accessor: 'vendorNumber',
		enableSorting: true,
	},
	{
		id: 'itemName',
		cell: (_cell: CellContext<OrderItemVM, string>) => {
			return _cell.getValue();
		},
		header: 'Item Name',
		accessor: 'itemName',
		size: 300,
		enableSorting: false,
	},
	{
		id: 'itemType',
		cell: (_cell: CellContext<OrderItemVM, string>) => _cell.getValue() ?? 'N/A',
		header: 'Item Type',
		size: 100,
		accessor: 'itemType',
		enableSorting: true,
	},
	{
		id: 'department',
		cell: (_cell: CellContext<OrderItemVM, string>) => {
			return <div>{_cell.row.original.locationName} ({_cell.row.original.departmentName})</div>;
		},
		header: 'Department',
		size: 250,
		accessor: 'department',
		enableSorting: false,
	},
	{
		id: 'price',
		cell: (_cell: CellContext<OrderItemVM, number>) => {
			return _cell.getValue() ?? 0;
		},
		header: 'Price Each',
		size: 80,
		accessor: 'price',
		enableSorting: false,
	},
	{
		id: 'extendedPrice',
		cell: (_cell: CellContext<OrderItemVM, number>) => {
			return _cell.row.original.price * _cell.row.original.quantity;
		},
		header: 'Extended Price',
		size: 80,
		accessor: 'extendedPrice',
	},
	], []);

	return (
		<div className={styles.section}>
			<div className={styles['section-title']}>Internal Repair</div>
			<Row>
				<Col md={6}>
					<div className={styles['grey-text']}>Technician name</div>
					<div className={styles['field-value']}>
						{
							!!toolRepair.repairedBy
								? `${toolRepair.repairedBy.fullName}`
								: 'N/A'
						}
					</div>
					<div className={styles['grey-text']}>Internal Comment</div>
					<div className={styles['field-value']}>{toolRepair.internalComment}</div>
				</Col>
				<Col md={18}>
					<SimpleTableControl columns={columns} label="Items Used For Repair" rows={toolRepair.items} />
				</Col>
			</Row>
		</div>
	);
};

export default React.memo(InternalRepairSection);
