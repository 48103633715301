import type JobStatusRequestModel from 'ab-requestModels/jobStatus.requestModel';
import type { ColorPalette } from 'acceligent-shared/enums/color';

export default class JobStatusFormModel {
	id: number;
	name: string;
	description: Nullable<string>;
	color: ColorPalette;

	static toRM(form: JobStatusFormModel): JobStatusRequestModel {
		return {
			name: form.name,
			description: form.description,
			color: form.color,
		};
	}
}
