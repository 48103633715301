import * as React from 'react';
import type { ConnectedProps } from 'react-redux';
import { connect } from 'react-redux';

import BlobStorageImageSizeContainer from 'acceligent-shared/enums/blobStorageImageSizeContainer';
import FieldReportBlockType, { FieldReportBlocksTotals } from 'acceligent-shared/enums/fieldReportBlockType';

import type { BlockValueType } from 'acceligent-shared/utils/fieldReport';

import * as ArrayUtils from 'acceligent-shared/utils/array';

import type { RootState } from 'af-reducers';

import * as FieldReportActions from 'af-actions/fieldReport';

import { bemBlock, bemElement } from 'ab-utils/bem.util';

import Tooltip from 'af-components/Tooltip';
import Checkbox from 'af-components/Controls/Checkbox';
import ImageTag from 'af-components/Image';

import { DEFAULT_EMPLOYEE_IMAGE } from 'ab-common/constants/value';

import Field from './Field';
import RepeatableField from './RepeatableField';
import Header from './Headers/Header';
import { getFormName } from '../helpers';
import type { RequestQueue } from '../../helpers';

interface OwnProps {
	fieldReportId: number;
	instanceIndex: number;
	segmentIndex: number;
	fieldReportTypeId: number;
	isPreview: boolean;
	blockId: string;
	hidden: boolean;
	showInstanceCount: boolean;
	showSegmentCount: boolean;
	secondaryCount: number;
	highlightedBlockId: Nullable<string>;
	change: (fieldName: string, value: BlockValueType) => void;
	toggleSegment: (isPrimary: boolean) => void;
	hideBlock: (fieldReportBlockId: string, isPrimary: boolean) => void;
	lock: (blockId: string) => void;
	unlock: () => void;
	forceUnlock: (blockId: string) => void;
	removable: boolean;
	focusedBlockId: Nullable<number>;
	setFieldToFocus: (ref: HTMLDivElement) => void;
	removeFocusedField: () => void;
	requestQueue: RequestQueue;
}

type Props = OwnProps & ConnectedProps<typeof connector>;

interface State {
	collapsed: boolean;
	completedToggled: boolean;
	isMatch: boolean;
	isActiveMatch: boolean;
}

class Block extends React.PureComponent<Props, State> {
	state: State = {
		collapsed: false,
		completedToggled: false,
		isMatch: false,
		isActiveMatch: false,
	};

	componentDidUpdate(prevProps: Props) {
		const { fieldReportBlock: { completed } } = this.props;
		const { completedToggled } = this.state;

		if (completedToggled && completed !== prevProps.fieldReportBlock.completed) {
			this.setState(() => ({ completedToggled: false }));
		}
	}

	lock = () => {
		const { blockId, lock } = this.props;
		lock(blockId);
	};

	toggleSecondaries = () => {
		const { fieldReportBlock, toggleSegment } = this.props;
		toggleSegment(fieldReportBlock.isPrimary);
		this.setState((state) => ({ collapsed: !state.collapsed }));
	};

	hide = () => {
		const { hideBlock, fieldReportBlock } = this.props;
		hideBlock(fieldReportBlock.id, fieldReportBlock.isMain);
	};

	handleComplete = () => {
		const { fieldReportBlock, fieldReportId, changeCompletedStatus, focusedBlockId, removeFocusedField, requestQueue } = this.props;
		this.setState(() => ({ completedToggled: true }), () => {
			requestQueue.add(() => changeCompletedStatus(fieldReportId, +fieldReportBlock.id, !fieldReportBlock.completed));
			if (focusedBlockId) {
				removeFocusedField();
			}
		});
	};

	renderHeader = () => {
		const {
			blockId,
			showSegmentCount,
			showInstanceCount,
			secondaryCount,
			hidden,
			fieldReportTypeId,
			highlightedBlockId,
			lockData,
			removable,
			unlock,
			forceUnlock,
			isPreview,
			fieldReportId,
		} = this.props;

		return (
			<Header
				blockId={blockId}
				fieldReportId={fieldReportId}
				fieldReportTypeId={fieldReportTypeId}
				forceUnlock={forceUnlock}
				hidden={hidden}
				hide={this.hide}
				highlighted={highlightedBlockId === blockId}
				isPreview={isPreview}
				locked={!!lockData}
				removable={removable}
				secondaryCount={secondaryCount}
				showInstanceCount={showInstanceCount}
				showSegmentCount={showSegmentCount}
				unlock={unlock}
			/>
		);
	};

	addRepeatableFields = () => {
		const { addRepeatableFields, fieldReportId, blockId, requestQueue } = this.props;

		requestQueue.add(() => addRepeatableFields(fieldReportId, blockId));
	};

	removeRepeatableFields = (index: number) => {
		const { removeRepeatableFields, fieldReportId, blockId } = this.props;

		removeRepeatableFields(fieldReportId, blockId, index);
	};

	renderField = (fieldId: string, key: number) => {
		const {
			change,
			fieldReportTypeId,
			blockId,
			lockData,
			isPreview,
			fieldReportId,
			focusedBlockId,
			setFieldToFocus,
			removeFocusedField,
			requestQueue,
		} = this.props;

		return (
			<Field
				blockId={blockId}
				change={change}
				disabled={!!lockData}
				fieldId={fieldId}
				fieldName={getFormName(fieldId)}
				fieldReportId={fieldReportId}
				fieldReportTypeId={fieldReportTypeId}
				focusedBlockId={focusedBlockId}
				isPreview={isPreview}
				key={key}
				onFocus={this.lock}
				removeFocusedField={removeFocusedField}
				requestQueue={requestQueue}
				setFieldToFocus={setFieldToFocus}
			/>
		);
	};

	renderFields = (index: number) => {
		const {
			fieldReportBlock,
			change,
			fieldReportTypeId,
			blockId,
			lockData,
			isPreview,
			fieldReportId,
			focusedBlockId,
			setFieldToFocus,
			removeFocusedField,
			requestQueue,
		} = this.props;
		const { repeatingCount, fields, isJustifiable } = fieldReportBlock;

		return (
			<RepeatableField
				blockId={blockId}
				change={change}
				disabled={!!lockData}
				fieldReportId={fieldReportId}
				fieldReportTypeId={fieldReportTypeId}
				fields={fields}
				focusedBlockId={focusedBlockId}
				index={index}
				isJustifiable={isJustifiable}
				isPreview={isPreview}
				key={index}
				onFocus={this.lock}
				remove={this.removeRepeatableFields}
				removeFocusedField={removeFocusedField}
				repeatingCount={repeatingCount}
				requestQueue={requestQueue}
				setFieldToFocus={setFieldToFocus}
			/>
		);
	};

	renderHiddenBlock = () => {
		const className = bemElement('field-report-block', 'body', { hidden: true });

		return (
			<div className={className} onClick={this.hide}>
				<span>Click To Show Row Data.</span>
			</div>
		);
	};

	renderBody = () => {
		const { fieldReportBlock, reportBlock, hidden, lockData, isPreview } = this.props;
		const { repeatingCount, fields, isJustifiable } = fieldReportBlock;
		const { isRepeating } = reportBlock;

		if (hidden && !fieldReportBlock.isMain) {
			return this.renderHiddenBlock();
		}

		const disabled = !!lockData;
		const className = bemElement('field-report-block', 'body', { repeating: isRepeating });
		const fieldWrapperClassName = bemElement('field-report-block', 'field-array', { justifiable: isJustifiable, preview: isPreview });

		return (
			<div className={className}>
				<div className="field-report-block__block">
					{isRepeating ?
						ArrayUtils.range(0, repeatingCount).map(this.renderFields)
						:
						<div className={fieldWrapperClassName}>{fields.map(this.renderField)}</div>
					}
				</div>
				{(isRepeating && !disabled && !isPreview) &&
					<div className="field-report-block__add" onClick={this.addRepeatableFields}>
						<span className="icon-plus" />
						<span>Add</span>
					</div>
				}
			</div>
		);
	};

	renderExpander = () => {
		const { fieldReportBlock, hidden, blockId, highlightedBlockId, lockData } = this.props;
		const { isMain, isPrimary } = fieldReportBlock;
		const { collapsed: showFullSegment, isActiveMatch } = this.state;
		if (!isMain || fieldReportBlock.type !== FieldReportBlockType.BLOCK || (hidden && !fieldReportBlock.isMain)) {
			return null;
		}

		const className = bemElement('field-report-block', 'expander', {
			primary: isPrimary,
			highlighted: highlightedBlockId === blockId,
			locked: !!lockData,
			active: isActiveMatch,
		});
		return (
			<div className={className}>
				<Tooltip message={`${showFullSegment ? 'Hide' : 'Show'} Segment`}>
					<div className="btn btn--icon btn-info" onClick={this.toggleSecondaries}>
						<span className={`icon-${showFullSegment ? 'down' : 'up'}`} />
					</div>
				</Tooltip>
			</div>
		);
	};

	renderFooter = () => {
		const { reportBlock, hidden, fieldReportBlock, lockData, isPreview } = this.props;
		const { completedToggled } = this.state;
		const { hasCompletionStatus } = reportBlock;
		const { isMain, completed } = fieldReportBlock;

		if (!hasCompletionStatus || (hidden && !isMain)) {
			return null;
		}

		const isChecked = completedToggled ? !completed : !!completed;

		return (
			<div className="field-report-block__footer">
				<Checkbox
					handleChange={this.handleComplete}
					isChecked={isChecked}
					isDisabled={!!lockData || isPreview}
					label="Completed"
				/>
			</div>
		);
	};

	renderLockMessage = () => {
		const { lockData } = this.props;
		const icon = lockData.isWeb ? 'icon-laptop' : 'icon-smartphone';
		return (
			<div className="lock-info">
				<div className="lock-info__title">
					<span>NOW EDITING</span>
					<span className={icon} />
				</div>
				<div className="lock-info__user">
					<ImageTag
						className="lock-info__user__image"
						fallbackSrc={DEFAULT_EMPLOYEE_IMAGE}
						minSize={BlobStorageImageSizeContainer.SIZE_50X50}
						src={lockData.imageUrl}
						tryOriginal={true}
						tryRoot={true}
					/>
					<div>
						<div className="lock-info__user__name">{lockData.fullName}</div>
						<div className="lock-info__user__role">{lockData.wageRateClassification}</div>
					</div>
				</div>
			</div>
		);
	};

	renderBlock = () => {
		const { fieldReportBlock, reportBlock, lockData, highlightedBlockId, blockId } = this.props;
		const { isMatch, isActiveMatch } = this.state;
		if (!fieldReportBlock) {
			return null;
		}
		const { isMain, isPrimary } = fieldReportBlock;
		const { hasCompletionStatus } = reportBlock;

		const isPrimaryBlock = isPrimary || FieldReportBlocksTotals.includes(fieldReportBlock.type);

		const className = bemBlock('field-report-block', {
			main: isMain,
			primary: isPrimaryBlock,
			secondary: !isPrimaryBlock,
			completable: hasCompletionStatus,
			locked: !!lockData,
			highlighted: highlightedBlockId === blockId,
			match: isMatch,
			active: isActiveMatch,
			'primary-total-block': fieldReportBlock.type === FieldReportBlockType.UPPER_TOTAL,
			'secondary-total-block': fieldReportBlock.type === FieldReportBlockType.LOWER_TOTAL,
		});

		return (
			<div className={className} id={blockId}>
				{this.renderHeader()}
				{this.renderExpander()}
				{this.renderBody()}
				{this.renderFooter()}
			</div>
		);
	};

	render() {
		const { lockData, fieldReportBlock: { isMain } } = this.props;
		return !lockData ?
			this.renderBlock()
			:
			(
				<Tooltip
					className={bemBlock('lock-info-popover', { main: isMain })}
					message={this.renderLockMessage()}
					useOverlayTrigger={false}
				>
					{this.renderBlock()}
				</Tooltip>
			);
	}
}

function mapStateToProps(state: RootState, ownProps: OwnProps) {
	const { fieldReport: { fieldReport, locks, activeSearchItemIndex } } = state;
	const { blockId } = ownProps;

	const fieldReportBlock = fieldReport?.fieldReportBlockMap?.[blockId];
	if (!fieldReportBlock) {
		throw new Error('fieldReportBlock not found');
	}

	return {
		fieldReportBlock,
		reportBlock: fieldReport?.blockMap?.[fieldReportBlock?.reportBlockId],
		lockData: locks?.[blockId],
		activeSearchItemIndex,
	};
}

function mapDispatchToProps() {
	return {
		changeCompletedStatus: FieldReportActions.updateFieldReportBlockCompletedStatus,
		addRepeatableFields: FieldReportActions.addRepeatableFields,
		removeRepeatableFields: FieldReportActions.removeRepeatableFields,
	};
}

const connector = connect(mapStateToProps, mapDispatchToProps());

export default connector(Block);
