import type { Dispatch, AnyAction } from 'redux';

import API from 'af-constants/routes/api';

import { http } from 'af-utils/http.util';
import { errorHandler } from 'af-utils/actions.util';

import type { DailyPerDiemTipViewModel } from 'ab-viewModels/dailyPerDiemTip.viewModel';

import type { GetRootState } from 'af-reducers';

import * as CreateAPI from 'ab-api/web/dailyPerDiemTip/create';

export function findLastFiveDailyPerDiemTips() {
	return async (dispatch: Dispatch<AnyAction>, getState: GetRootState, { redirectTo }) => {

		const action = async () => {
			return await http.get<DailyPerDiemTipViewModel[]>(API.V1.DAILY_PER_DIEM_TIP.LAST_FIVE);
		};

		return await errorHandler(action, dispatch, redirectTo);
	};
}

export function create(message: string, dueDate: string) {
	return async (dispatch: Dispatch<AnyAction>, getState: GetRootState, { redirectTo }) => {

		const action = async (): Promise<void> => {
			const body: CreateAPI.W_DailyPerDiemTip_Create_RM = { message, dueDate };
			await http.post<CreateAPI.W_DailyPerDiemTip_Create_VM>(CreateAPI.URL(), body);
		};

		return await errorHandler(action, dispatch, redirectTo);
	};
}
