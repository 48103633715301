export class W_WorkOrderReport_DownloadPublicReport_VM {
	total: number;
	pending?: number;
	preparing?: number;
	generating?: number;
	saving?: number;
	done?: number;
	retrying?: number;
	merging?: number;
	/** Generic errors in the process, e.g. merging */
	error?: string[];
	/** Errors list that shows what WO-s failed generating */
	resourceErrors?: string[];
	url: Nullable<string>;

	constructor(
		total: number,
		pending: number | undefined,
		preparing: number | undefined,
		generating: number | undefined,
		saving: number | undefined,
		done: number | undefined,
		retrying: number | undefined,
		merging: number | undefined,
		error: string[] | undefined,
		resourceErrors: string[] | undefined,
		url: Nullable<string>
	) {
		this.total = total;
		this.pending = pending;
		this.preparing = preparing;
		this.generating = generating;
		this.saving = saving;
		this.done = done;
		this.retrying = retrying;
		this.merging = merging;
		this.error = error;
		this.resourceErrors = resourceErrors;
		this.url = url;
	}
}
