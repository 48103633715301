import * as React from 'react';

import type ScheduleBoardEquipmentModalVM from 'ab-viewModels/scheduleBoardEquipmentModal.viewModel';
import type ScheduleBoardEmployeeModalVM from 'ab-viewModels/scheduleBoardEmployeeModal.viewModel';
import type ScheduleBoardTemporaryEmployeeModalVM from 'ab-viewModels/scheduleBoardTemporaryEmployeeModal.viewModel';

export type ScheduleBoardModalsProps = {
	scheduleBoardModals: ScheduleBoardModalContextType;
};

export interface ScheduleBoardModalContextType {
	showEquipmentModal: boolean;
	equipment: Nullable<ScheduleBoardEquipmentModalVM>;
	/** format `MM-DD-YYYY` **/
	equipmentModalDate: Nullable<string>;

	showEmployeeModal: boolean;
	employee: Nullable<ScheduleBoardEmployeeModalVM>;
	/** format `MM-DD-YYYY` **/
	employeeModalDate: Nullable<string>;

	temporaryEmployee: Nullable<ScheduleBoardTemporaryEmployeeModalVM>;
	/** format `MM-DD-YYYY` **/
	temporaryEmployeeModalDate: Nullable<string>;

	workOrderNoteModalCode: Nullable<string>;
	/** format `MM-DD-YYYY` **/
	workOrderNoteModalDate: Nullable<string>;

	workOrderModalId: Nullable<number>;

	showEmployeeWOHistoryModal: boolean;
	showEquipmentWOHistoryModal: boolean;

	setEquipmentModalData: (equipment: Nullable<ScheduleBoardEquipmentModalVM>, /** format `MM-DD-YYYY` **/ date: Nullable<string>) => void;
	setEquipmentModalVisibility: (isVisible: boolean) => void;
	setEmployeeModalData: (employee: Nullable<ScheduleBoardEmployeeModalVM>, /** format `MM-DD-YYYY` **/ date: Nullable<string>) => void;
	setEmployeeModalVisibility: (isVisible: boolean) => void;
	setTemporaryEmployeeModalData: (
		temporaryEmployee: Nullable<ScheduleBoardTemporaryEmployeeModalVM>,
		/** format `MM-DD-YYYY` **/
		date: Nullable<string>) => void;
	setWorkOrderNoteModalData: (workOrderCode: Nullable<string>, /** format `MM-DD-YYYY` **/ date: Nullable<string>) => void;
	setWorkOrderModalData: (workOrderId: Nullable<number>) => void;

	setEmployeeWOHistoryModalVisibility: (isVisible: boolean) => void;
	setEquipmentWOHistoryModalVisibility: (isVisible: boolean) => void;

}

const defaultValue: ScheduleBoardModalContextType = {
	showEquipmentModal: false,
	equipment: null,
	equipmentModalDate: null,

	showEmployeeModal: false,
	employee: null,
	employeeModalDate: null,

	temporaryEmployee: null,
	temporaryEmployeeModalDate: null,

	workOrderNoteModalCode: null,
	workOrderNoteModalDate: null,

	workOrderModalId: null,

	showEmployeeWOHistoryModal: false,
	showEquipmentWOHistoryModal: false,

	// eslint-disable-next-line @typescript-eslint/no-unused-vars
	setEquipmentModalData: (equipment: Nullable<ScheduleBoardEquipmentModalVM>, date: Nullable<string>) => { return; },
	// eslint-disable-next-line @typescript-eslint/no-unused-vars
	setEquipmentModalVisibility: (isVisible: boolean) => { return; },
	// eslint-disable-next-line @typescript-eslint/no-unused-vars
	setEmployeeModalData: (employee: Nullable<ScheduleBoardEmployeeModalVM>, date: Nullable<string>) => { return; },
	// eslint-disable-next-line @typescript-eslint/no-unused-vars
	setEmployeeModalVisibility: (isVisible: boolean) => { return; },
	// eslint-disable-next-line @typescript-eslint/no-unused-vars
	setTemporaryEmployeeModalData: (temporaryEmployee: Nullable<ScheduleBoardTemporaryEmployeeModalVM>, date: Nullable<string>) => { return; },
	// eslint-disable-next-line @typescript-eslint/no-unused-vars
	setWorkOrderNoteModalData: (workOrderCode: Nullable<string>, /** format `MM-DD-YYYY` **/ date: Nullable<string>) => { return; },
	// eslint-disable-next-line @typescript-eslint/no-unused-vars
	setWorkOrderModalData: (workOrderId: Nullable<number>) => { return; },
	// eslint-disable-next-line @typescript-eslint/no-unused-vars
	setEmployeeWOHistoryModalVisibility: (isVisible: boolean) => { return; },
	// eslint-disable-next-line @typescript-eslint/no-unused-vars
	setEquipmentWOHistoryModalVisibility: (isVisible: boolean) => { return; },
};

export const ScheduleBoardModalContext = React.createContext<ScheduleBoardModalContextType>(defaultValue);

export const ScheduleBoardModalContextProvider = ({ children }) => {
	// Equipment modal
	const [equipment, setEquipment] = React.useState<Nullable<ScheduleBoardEquipmentModalVM>>(defaultValue.equipment);
	const [equipmentModalDate, setEquipmentModalDate] = React.useState<Nullable<string>>(defaultValue.equipmentModalDate);
	const [showEquipmentModal, setShowEquipmentModal] = React.useState(defaultValue.showEquipmentModal);

	// Employee modal
	const [employee, setEmployee] = React.useState<Nullable<ScheduleBoardEmployeeModalVM>>(defaultValue.employee);
	const [employeeModalDate, setEmployeeModalDate] = React.useState<Nullable<string>>(defaultValue.employeeModalDate);
	const [showEmployeeModal, setShowEmployeeModal] = React.useState(defaultValue.showEmployeeModal);

	// Temporary employee modal
	const [temporaryEmployee, setTemporaryEmployee] = React.useState<Nullable<ScheduleBoardTemporaryEmployeeModalVM>>(defaultValue.temporaryEmployee);
	const [temporaryEmployeeModalDate, setTemporaryEmployeeModalDate] = React.useState(defaultValue.temporaryEmployeeModalDate);

	// Work order note modal
	const [workOrderNoteModalCode, setWorkOrderNoteModalCode] = React.useState<Nullable<string>>(defaultValue.workOrderNoteModalCode);
	const [workOrderNoteModalDate, setWorkOrderNoteModalDate] = React.useState<Nullable<string>>(defaultValue.workOrderNoteModalDate);

	// Work order modal
	const [workOrderModalId, setWorkOrderModalId] = React.useState<Nullable<number>>(defaultValue.workOrderModalId);

	const [showEmployeeWOHistoryModal, setShowEmployeeWOHistoryModal] = React.useState(defaultValue.showEmployeeWOHistoryModal);
	const [showEquipmentWOHistoryModal, setShowEquipmentWOHistoryModal] = React.useState(defaultValue.showEquipmentWOHistoryModal);

	const setEquipmentModalData = React.useCallback((equipmentModalData: Nullable<ScheduleBoardEquipmentModalVM>, date: Nullable<string>) => {
		setEquipment(equipmentModalData);
		setEquipmentModalDate(date);
	}, []);
	const setEquipmentModalVisibility = React.useCallback((isVisible: boolean) => {
		setShowEquipmentModal(isVisible);
	}, []);
	const setEmployeeModalData = React.useCallback((employeeModalData: Nullable<ScheduleBoardEmployeeModalVM>, date: Nullable<string>) => {
		setEmployee(employeeModalData);
		setEmployeeModalDate(date);
	}, []);
	const setEmployeeModalVisibility = React.useCallback((isVisible: boolean) => {
		setShowEmployeeModal(isVisible);
	}, []);
	const setTemporaryEmployeeModalData = React.useCallback((
		temporaryEmployeeModalData: Nullable<ScheduleBoardTemporaryEmployeeModalVM>,
		date: Nullable<string>
	) => {
		setTemporaryEmployee(temporaryEmployeeModalData);
		setTemporaryEmployeeModalDate(date);
	}, []);
	const setWorkOrderNoteModalData = React.useCallback((code: Nullable<string>, date: Nullable<string>) => {
		setWorkOrderNoteModalCode(code);
		setWorkOrderNoteModalDate(date);
	}, []);
	const setWorkOrderModalData = React.useCallback((workOrderId: Nullable<number>) => { setWorkOrderModalId(workOrderId); }, []);

	const setEmployeeWOHistoryModalVisibility = React.useCallback((isVisible: boolean) => setShowEmployeeWOHistoryModal(isVisible), []);
	const setEquipmentWOHistoryModalVisibility = React.useCallback((isVisible: boolean) => setShowEquipmentWOHistoryModal(isVisible), []);

	const value: ScheduleBoardModalContextType = React.useMemo(() => ({
		equipment,
		equipmentModalDate,
		showEquipmentModal,
		employee,
		employeeModalDate,
		showEmployeeModal,
		temporaryEmployee,
		temporaryEmployeeModalDate,
		workOrderNoteModalCode,
		workOrderNoteModalDate,
		workOrderModalId,
		setEquipmentModalData,
		setEquipmentModalVisibility,
		setEmployeeModalData,
		setEmployeeModalVisibility,
		setTemporaryEmployeeModalData,
		setWorkOrderNoteModalData,
		setWorkOrderModalData,
		showEmployeeWOHistoryModal,
		showEquipmentWOHistoryModal,
		setEmployeeWOHistoryModalVisibility,
		setEquipmentWOHistoryModalVisibility,
	}), [
		employee,
		employeeModalDate,
		equipment,
		equipmentModalDate,
		setEmployeeModalData,
		setEmployeeModalVisibility,
		setEquipmentModalData,
		setEquipmentModalVisibility,
		setTemporaryEmployeeModalData,
		setWorkOrderModalData,
		setWorkOrderNoteModalData,
		showEmployeeModal,
		showEquipmentModal,
		temporaryEmployee,
		temporaryEmployeeModalDate,
		workOrderModalId,
		workOrderNoteModalCode,
		workOrderNoteModalDate,
		showEmployeeWOHistoryModal,
		showEquipmentWOHistoryModal,
		setEmployeeWOHistoryModalVisibility,
		setEquipmentWOHistoryModalVisibility,
	]);

	return (
		<ScheduleBoardModalContext.Provider value={value} >
			{children}
		</ScheduleBoardModalContext.Provider >
	);
};
