import * as React from 'react';
import { compose } from 'redux';
import type { ConnectedProps } from 'react-redux';
import { connect } from 'react-redux';
import type { InjectedFormProps } from 'redux-form';
import { reduxForm, formValueSelector, getFormValues } from 'redux-form';
import type { CustomRouteComponentProps } from 'react-router-dom';

import type { RootState } from 'af-reducers';

import { ITEM_CREATE } from 'af-constants/reduxForms';
import CLIENT from 'af-constants/routes/client';

import * as ItemActions from 'af-actions/item';

import Breadcrumbs from 'af-components/Breadcrumbs';

import FormModel from '../Shared/formModel';
import Form from '../Shared/form';
import { validate } from '../Shared/validation';

type Props = CustomRouteComponentProps & ConnectedProps<typeof connector> & InjectedFormProps<FormModel>;

const Create = (props: Props) => {
	const { history, create, location: { state: { orgAlias } }, companyName } = props;

	const createItem = React.useCallback(async (form: FormModel) => {
		await create(FormModel.toRequestModel(form));
		history.push(CLIENT.COMPANY.EQUIPMENT.ITEM.LIST(orgAlias, companyName));
	}, [companyName, create, history, orgAlias]);

	return (
		<div className="form-segment">
			<Breadcrumbs
				items={[
					{ label: 'Equipment', url: CLIENT.COMPANY.EQUIPMENT.ITEM.LIST(orgAlias, companyName) },
					{ label: 'Accessory' },
				]}
			/>
			<Form
				{...props}
				onSubmit={createItem}
			/>
		</div>
	);
};

const formSelector = formValueSelector(ITEM_CREATE);

interface FormActionWrapper {
	selector: <T extends keyof FormModel>(fieldName: T) => FormModel[T];
}
const formActionWrapper: Partial<FormActionWrapper> = {
	selector: undefined,
};

const getForm = getFormValues(ITEM_CREATE);

function mapStateToProps(state: RootState) {
	const { companyData } = state.user;
	if (!companyData) {
		throw new Error('User not logged in');
	}

	formActionWrapper.selector = (fieldName: keyof FormModel) => formSelector(state, fieldName);

	return {
		formActionWrapper: formActionWrapper as FormActionWrapper, // selector is no longer undefined
		companyName: companyData.name,
		formValues: getForm(state) as Record<string, string>[],  // Not directly used, but if missing component won't rerender when it should because of specific way this form works
	};
}

function mapDispatchToProps() {
	return {
		create: ItemActions.create,
	};
}

const connector = connect(mapStateToProps, mapDispatchToProps());

const enhance = compose(
	connector,
	reduxForm({
		form: ITEM_CREATE,
		validate,
		enableReinitialize: true,
	})
);

export default enhance(Create);
