import * as React from 'react';

import type RejectReviewRM from 'ab-requestModels/workOrder/rejectReview';

import WorkOrderReviewStatus, { WorkOrderReviewLevel } from 'acceligent-shared/enums/workOrderReviewStatus';

import { filterMap } from 'acceligent-shared/utils/array';

import type { WorkOrderEmployeeVM } from 'ab-viewModels/workOrder/workOrderFieldReportCard.viewModel';

import ConfirmationModal from 'af-components/ConfirmationModal';

import SuccessModalBody from '../../FieldReportsList/Modals/Shared/SuccessModalBody';

import type { OwnProps as RejectModalProps } from './RejectModal';
import RejectModal from './RejectModal';

interface OwnProps extends RejectModalProps {
	accountants: WorkOrderEmployeeVM[];
	employees: WorkOrderEmployeeVM[];
	managers: WorkOrderEmployeeVM[];
	superintendents: WorkOrderEmployeeVM[];
}

type Props = ExcludeFrom<OwnProps, { 'getUsers'; }>;

interface State {
	showRejectModal: boolean;
	showSuccessModal: boolean;
	assignedTo: Nullable<string[]>;
}

class RejectModalHandler extends React.PureComponent<Props, State> {

	state: State = {
		showRejectModal: this.props.showModal,
		showSuccessModal: false,
		assignedTo: null,
	};

	static readonly SUCCESS_MODAL_TITLE = 'Report has been rejected';

	static getDescription = (woCode: string, rejectedTo: Nullable<string>) => {
		return (
			<div>
				<span>Report for <strong>{woCode}</strong> has been rejected {rejectedTo && 'to'}</span>
				<div><strong>{rejectedTo}</strong></div>
			</div>
		);
	};

	static renderSuccessModalBody = (workOrderCode: string, assignedTo: Nullable<string[]>) => {
		return (
			<SuccessModalBody
				description={RejectModalHandler.getDescription(workOrderCode, assignedTo?.join(', ') ?? null)}
				isReadOnly={false}
				reviewStatus={WorkOrderReviewStatus.REJECTED}
				title={RejectModalHandler.SUCCESS_MODAL_TITLE}
			/>
		);
	};

	componentDidUpdate(prevProps: Props) {
		const { showModal } = this.props;

		if (prevProps.showModal !== showModal) {
			this.setState(() => ({ showRejectModal: showModal }));
		}
	}

	getUsers = (targetLevel: WorkOrderReviewLevel) => {
		const { employees, superintendents, accountants, managers } = this.props;

		switch (targetLevel) {
			case WorkOrderReviewLevel.LEVEL_0:
				return employees;
			case WorkOrderReviewLevel.LEVEL_1:
				return superintendents;
			case WorkOrderReviewLevel.LEVEL_2:
				return managers;
			case WorkOrderReviewLevel.LEVEL_3:
				return accountants;
			default:
				return [];
		}
	};

	onRejectModalConfirm = async (data: RejectReviewRM) => {
		const { onReject } = this.props;

		await onReject(data);

		const targetLevel = data.targetLevel;
		const rejectIds = data.assignedToIds;

		const assignedTo = filterMap(
			this.getUsers(targetLevel),
			(_user) => rejectIds?.includes(_user.id) ?? false,
			(_user) => _user.fullName
		);
		this.setState(() => ({ showRejectModal: false, showSuccessModal: true, assignedTo }));
	};

	onSuccessModalConfirm = () => {
		const { closeModal } = this.props;

		closeModal();
		this.setState(() => ({ showSuccessModal: false, assignedTo: null }));
	};

	render() {
		const { closeModal, reviewLevel, reviewStatus, workOrderCode } = this.props;
		const { showRejectModal, showSuccessModal, assignedTo } = this.state;

		return (
			<>
				<RejectModal
					closeModal={closeModal}
					getUsers={this.getUsers}
					onReject={this.onRejectModalConfirm}
					reviewLevel={reviewLevel}
					reviewStatus={reviewStatus}
					showModal={showRejectModal}
					workOrderCode={workOrderCode}
				/>
				<ConfirmationModal
					body={RejectModalHandler.renderSuccessModalBody(workOrderCode, assignedTo)}
					closeModal={this.onSuccessModalConfirm}
					confirmAction={this.onSuccessModalConfirm}
					confirmText="Done"
					modalStyle="info"
					showCancel={false}
					showModal={showSuccessModal}
					size="md"
					title="Reject Report"
				/>
			</>
		);
	}
}

export default RejectModalHandler;
