import * as React from 'react';
import type { ConnectedProps } from 'react-redux';
import { connect } from 'react-redux';
import type { InjectedFormProps } from 'redux-form';
import { reduxForm, formValueSelector } from 'redux-form';
import type { CustomRouteComponentProps } from 'react-router-dom';
import { compose } from 'redux';

import type { EquipmentRequestModel } from 'ab-requestModels/equipment.requestModel';

import { EQUIPMENT_EDIT } from 'af-constants/reduxForms';

import type { RootState } from 'af-reducers';

import * as EquipmentActions from 'af-actions/equipment';

import Breadcrumbs from 'af-components/Breadcrumbs';

import EditEquipmentForm from 'af-components/SharedForms/Resources/Equipment';

import { validate } from 'af-components/SharedForms/Resources/Equipment/validation';

import CLIENT from 'af-constants/routes/client';

interface PathParams {
	id: string;
}

type OwnProps = CustomRouteComponentProps<PathParams>;

type FormProps = InjectedFormProps<EquipmentRequestModel>;

type Props = OwnProps & ConnectedProps<typeof connector> & FormProps;

class Edit extends React.Component<Props> {

	async componentDidMount() {
		const { findById, match: { params: { id } }, initialize } = this.props;
		const equipment = await findById(id);
		initialize(equipment);
	}

	editEquipment = async (form: EquipmentRequestModel) => {
		const { history, update, location: { state: { orgAlias } }, companyName, match: { params: { id } } } = this.props;
		await update(form);
		history.push(CLIENT.COMPANY.EQUIPMENT.PREVIEW(id, orgAlias, companyName));
	};

	render() {
		const { location: { state: { orgAlias } }, companyName, match: { params: { id } } } = this.props;
		return (
			<div className="form-segment">
				<Breadcrumbs
					items={
						[
							{ label: 'Equipment', url: `${CLIENT.COMPANY.EQUIPMENT.TABLE(orgAlias, companyName)}` },
							{ label: 'Preview', url: CLIENT.COMPANY.EQUIPMENT.PREVIEW(id, orgAlias, companyName) },
							{ label: 'Edit' },
						]
					}
				/>
				<EditEquipmentForm {...this.props} onSubmit={this.editEquipment} />
			</div>
		);
	}

}

const formSelector = formValueSelector(EQUIPMENT_EDIT);

interface FormActionWrapper {
	selector: <T extends keyof EquipmentRequestModel>(fieldName: T) => EquipmentRequestModel[T];
}
const formActionWrapper: Partial<FormActionWrapper> = {
	selector: undefined,
};

function mapStateToProps(state: RootState) {
	const { companyData } = state.user;
	if (!companyData) {
		throw new Error('User not logged in');
	}

	formActionWrapper.selector = (fieldName: keyof EquipmentRequestModel) => formSelector(state, fieldName);

	return {
		formActionWrapper: formActionWrapper as FormActionWrapper, // selector is no longer undefined
		companyName: companyData.name,
		selector: (fieldName: keyof EquipmentRequestModel) => formSelector(state, fieldName),
	};
}

function mapDispatchToProps() {
	return {
		findById: EquipmentActions.findById,
		update: EquipmentActions.update,
	};
}

const connector = connect(mapStateToProps, mapDispatchToProps());

const enhance = compose<React.ComponentClass<OwnProps>>(
	connector,
	reduxForm({ form: EQUIPMENT_EDIT, validate })
);
export default enhance(Edit);
