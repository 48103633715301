import * as React from 'react';
import { Button } from 'react-bootstrap';
import type { FormErrors } from 'redux-form';

import type { ColorPalette } from 'acceligent-shared/enums/color';

import { TAG } from 'af-constants/reduxForms';

import CreateTag from './CreateTag';
import EditTag from './EditTag';
import PreviewTag from './PreviewTag';

interface OwnProps<T> {
	tags: T[];
	labelKey: string;
	valueKey: string;
	color?: ColorPalette;
	tagGroupName: string;
	form?: string;
	inline?: boolean;
	onCreate: (form: T) => void;
	onEdit: (form: T) => void;
	onDelete: (tag: T) => void;
	validate: (values: T) => FormErrors<T>;
}

type Props<T> = OwnProps<T>;

interface State<T> {
	editTag: boolean;
	createTag: boolean;
	tagForEdit: T | undefined;
}

export default class Tag<T> extends React.PureComponent<Props<T>, State<T>> {
	static defaultProps: Partial<Props<Metadata>> = {
		inline: true,
		form: TAG,
	};

	state: State<T> = {
		editTag: false,
		createTag: false,
		tagForEdit: undefined,
	};

	toggleEditTag = (tag: T | undefined) => {
		this.setState((state: State<T>) => ({
			tagForEdit: state.editTag ? undefined : tag,
			editTag: !state.editTag,
			createTag: false,
		}));
	};

	toggleCreateTag = () => {
		this.setState((state: State<T>) => ({
			createTag: !state.createTag,
			editTag: false,
			tagForEdit: undefined,
		}));
	};

	createTag = async (form: T) => this.props.onCreate(form);

	editTag = async (form: T) => this.props.onEdit(form);

	deleteTag = (tag: T) => {
		const { onDelete } = this.props;
		onDelete(tag);
	};

	renderPreview = (tag: T, index: number) => {
		const { labelKey, color, valueKey, tagGroupName } = this.props;
		const { editTag, tagForEdit } = this.state;
		if (editTag && tag[valueKey] === tagForEdit?.[valueKey]) {
			return this.renderEdit(tag, index);
		}

		return (
			<PreviewTag
				color={color}
				key={index}
				label={tag[labelKey]}
				onDelete={this.deleteTag.bind(this, tag)}
				onEdit={this.toggleEditTag.bind(this, tag)}
				title={`Delete ${tagGroupName}`}
			/>
		);
	};

	renderEdit = (tag: T, index: number) => {
		const { validate, tagGroupName, form } = this.props;
		return (
			<EditTag<T>
				close={undefined}
				form={form}
				initialValues={tag}
				key={index}
				onSubmit={this.editTag}
				placeholder={tagGroupName}
				toggleEdit={this.toggleEditTag.bind(this, undefined)}
				validate={validate}
			/>
		);
	};

	renderCreateTag = () => {
		const { validate, tagGroupName, form } = this.props;
		const { createTag } = this.state;

		if (!createTag) {
			return (
				<Button className="btn--link" onClick={this.toggleCreateTag}>
					<span className="icon-plus" />
					<span>Add {tagGroupName}</span>
				</Button>
			);
		}

		return (
			<CreateTag<T>
				close={this.toggleCreateTag}
				form={form}
				onSubmit={this.createTag}
				placeholder={tagGroupName}
				validate={validate}
			/>
		);
	};

	render() {
		const { tags, inline, tagGroupName } = this.props;
		return (
			<div>
				<div className="text-and-color-resource-list">
					{!tags.length
						? <div className="no-text-and-color-resources">There are currently no {tagGroupName} added</div>
						: (
							<div className={`text-and-color-resource-color-list ${inline ? '' : 'column'}`}>
								{tags.map(this.renderPreview)}
							</div>
						)
					}
				</div>
				<div className="create-tag">
					{this.renderCreateTag()}
				</div>
			</div>
		);
	}
}
