import * as React from 'react';
import type { WrappedFieldArrayProps } from 'redux-form';

import type { WorkOrderEmployeeVM } from 'ab-viewModels/workOrder/workOrderFieldReportCard.viewModel';

import WorkOrderEmployeeCheckBox from './WorkOrderEmployeeCheckbox';

export interface OwnProps {
	change: (key: string, value: Nullable<number[]>) => void;
	employees: Nullable<WorkOrderEmployeeVM[]>;
	error?: string;
	propName: string;
	selector: (key: string) => number[];
}

type Props = OwnProps & WrappedFieldArrayProps<WorkOrderEmployeeVM>;

const WorkOrderEmployees: React.FC<Props> = (props: Props) => {
	const { employees, error } = props;

	const renderEmployeeCheckbox = (employee: WorkOrderEmployeeVM, index: number) => {
		const { selector, change, propName } = props;

		return (
			<WorkOrderEmployeeCheckBox
				change={change}
				employee={employee}
				index={index}
				key={index}
				name={propName}
				selector={selector}
			/>
		);
	};

	return (
		<>
			{employees?.map(renderEmployeeCheckbox)}
			{error && <span className="help-block"><span className="icon-info" />{error}</span>}
		</>
	);
};

export default React.memo(WorkOrderEmployees);
