import { isValidTextInput } from 'acceligent-shared/utils/text';

import type { EmployeeRM } from 'ab-requestModels/employee/update.requestModel';

export function validate(values: EmployeeRM) {
	const errors: ValidationErrors = {};

	if (!values.wageRate) {
		errors.wageRate = 'Wage Rate is required';
	}

	if (!values.accountId) {
		errors.accountId = 'User is required';
	}

	if (!isValidTextInput(values.fullName)) {
		errors.fullName = 'User is required';
	}

	if (!values.cdlStatus) {
		errors.cdlStatus = 'CDL status is required';
	}

	return errors;
}
