import * as React from 'react';
import { Button } from 'react-bootstrap';

import { bemElement } from 'ab-utils/bem.util';

import ConfirmationModal from 'af-components/ConfirmationModal';

import { useToggle } from 'af-utils/react.util';

import type { BulkAction } from './types';

const DELETE_CONFIRMATION_BODY = 'This action cannot be undone.';

interface OwnProps {
	additionalBulkActions?: BulkAction[];
	bulkDelete?: (ids: number[]) => void;
	clearSelection: () => void;
	isSelectable: boolean;
	selection: number[];
}

type Props = OwnProps;

const getBulkDeleteAction = (bulkDelete: BulkAction['onActionConfirmation']): BulkAction => {
	return {
		onActionConfirmation: bulkDelete,
		label: (<><span className="icon-delete" /> Delete</>),
		key: 'delete',
		modalType: 'danger',
		modalSize: 'md',
		modalTitle: (selection: number[]) => `Are you sure you want to delete ${selection.length} row${selection.length > 1 ? 's' : ''} from table?`,
		confirmText: (selection: number[]) => `Delete (${selection.length})`,
		actionConfirmationBody: () => DELETE_CONFIRMATION_BODY,
	};
};

const BulkActionsHeader: React.FC<Props> = (props: Props) => {
	const { isSelectable, selection, bulkDelete, additionalBulkActions, clearSelection } = props;

	const {
		value: showBulkActionConfirmationModal,
		setToTrue: openBulkActionConfirmationModal,
		setToFalse: hideBulkActionConfirmationModal,
	} = useToggle(false);

	const [currentAction, setCurrentAction] = React.useState<Nullable<BulkAction>>(null);
	const [modalBody, setModalBody] = React.useState<Nullable<JSX.Element | string>>(null);
	const [confirmText, setConfirmText] = React.useState<Nullable<string>>(null);
	const [modalTitle, setModalTitle] = React.useState<Nullable<string>>(null);

	const isSelectDisabled: boolean = !selection.length;
	const showAdditionalHeader = isSelectable && !isSelectDisabled && (bulkDelete ?? !!additionalBulkActions?.length);
	const headerAdditionalClassName = bemElement('table-container', 'header-additional', [showAdditionalHeader ? 'expanded' : 'collapsed']);

	const shouldDisplayAdditionalActions = !!additionalBulkActions?.length;

	const bulkActionSelected = React.useCallback(async () => {
		if (!selection?.length || !currentAction?.onActionConfirmation) {
			hideBulkActionConfirmationModal();
			return;
		}
		await currentAction.onActionConfirmation(selection);
		hideBulkActionConfirmationModal();
		clearSelection();
	}, [currentAction, clearSelection, hideBulkActionConfirmationModal, selection]);

	const onClose = React.useCallback(() => {
		hideBulkActionConfirmationModal();
		setCurrentAction(null);
		setModalBody(null);
		setConfirmText(null);
		setModalTitle(null);
	}, [hideBulkActionConfirmationModal]);

	const bulkDeleteAction = React.useMemo(() => {
		return bulkDelete ? getBulkDeleteAction(bulkDelete) : null;
	}, [bulkDelete]);

	const getOnClick = (action: BulkAction) => {
		return () => {
			openBulkActionConfirmationModal();
			setCurrentAction(action);
			setModalBody(action.actionConfirmationBody(selection));
			setConfirmText(action.confirmText(selection));
			setModalTitle(action.modalTitle(selection));
		};
	};

	const renderAction = (action: BulkAction) => {
		return (
			<div key={action.key}>
				<Button
					className="btn--white"
					disabled={!selection.length}
					onClick={getOnClick(action)}
					variant="default"
				>
					{action.label}
				</Button>
			</div>
		);
	};

	if (!showAdditionalHeader) {
		return null;
	}

	return (
		<div className={headerAdditionalClassName}>
			{bulkDeleteAction && renderAction(bulkDeleteAction)}
			{shouldDisplayAdditionalActions && additionalBulkActions.map(renderAction)}
			<ConfirmationModal
				body={modalBody ?? undefined}
				closeModal={onClose}
				confirmAction={currentAction?.onActionConfirmation && bulkActionSelected.bind(this, currentAction?.onActionConfirmation)}
				confirmText={confirmText ?? undefined}
				modalStyle={currentAction?.modalType}
				showModal={showBulkActionConfirmationModal}
				size={currentAction?.modalSize}
				title={modalTitle ?? undefined}
			/>
		</div>
	);
};

export default React.memo(BulkActionsHeader);
