import * as React from 'react';
import type { ConnectedProps } from 'react-redux';
import { connect } from 'react-redux';
import { compose } from 'redux';
import type { CustomRouteComponentProps } from 'react-router-dom';
import { Link } from 'react-router-dom';
import { Form, Row, Col } from 'react-bootstrap';
import type { InjectedFormProps} from 'redux-form';
import { reduxForm, Field, FieldArray } from 'redux-form';

import * as UserGroupActions from 'af-actions/userGroup';

import type { RootState } from 'af-reducers';

import { USER_GROUP_FORM } from 'af-constants/reduxForms';
import CLIENT from 'af-constants/routes/client';

import type UserGroupRequestModel from 'ab-requestModels/userGroup.requestModel';

import Input from 'af-fields/Input';
import SubmitButton from 'af-components/SubmitButton';
import Accounts from './Accounts';

import { validate } from './validations';
import Breadcrumbs from 'af-components/Breadcrumbs';

interface PathParams {
	userGroupId: string;
}

type FormProps = InjectedFormProps<UserGroupRequestModel, FormOwnProps>;

type OwnProps = CustomRouteComponentProps<PathParams>;

type FormOwnProps = OwnProps & ConnectedProps<typeof connector>;
type Props = FormOwnProps & FormProps;

class UserGroup extends React.PureComponent<Props> {

	async componentDidMount() {
		const { findById, match: { params: { userGroupId } }, initialize } = this.props;
		if (userGroupId) {
			const userGroup = await findById(+userGroupId);
			initialize(userGroup);
		}
	}

	submit = async (form: UserGroupRequestModel) => {
		const {
			history,
			editUserGroup,
			createUserGroup,
			location: { state: { orgAlias } },
			companyData: { name: companyName },
		} = this.props;

		if (form.id) {
			await editUserGroup(form);
		} else {
			await createUserGroup(form);
		}

		history.push(CLIENT.COMPANY.SETTINGS.USER_GROUPS.TABLE(orgAlias, companyName));
	};

	render() {
		const {
			match: { params: { userGroupId } },
			handleSubmit,
			submitting,
			invalid,
			location: { state: { orgAlias } },
			companyData: { name: companyName },
		} = this.props;

		return (
			<div className="form-segment">
				<Breadcrumbs
					items={
						[
							{ label: 'User Groups', url: CLIENT.COMPANY.SETTINGS.USER_GROUPS.TABLE(orgAlias, companyName) },
							{ label: userGroupId ? 'Edit' : 'Create' },
						]
					}
				/>
				<div className="form-box">
					<Form className="user-groups-upsert" onSubmit={handleSubmit(this.submit)}>
						<Row className="row--padded-top">
							<Col sm={12}>
								<Field
									component={Input}
									label="User Group Name *"
									name="name"
									placeholder="Enter User Group Name"
								/>
							</Col>
							<Col sm={12} />
						</Row>
						<Row>
							<Col sm={12}>
								<span className="control-label">Choose existing users you want to assign to the user group:</span>
								<FieldArray<void>
									component={Accounts}
									name="accounts"
								/>
							</Col>
							<Col sm={12} />
						</Row>
						<Row className="row--submit">
							<Link
								className="btn btn-info"
								to={CLIENT.COMPANY.SETTINGS.USER_GROUPS.TABLE(orgAlias, companyName)}
							>
								Back
							</Link>
							<SubmitButton
								disabled={invalid}
								label="Save"
								reduxFormSubmitting={submitting}
							/>
						</Row>
					</Form>
				</div>
			</div>
		);
	}
}

function mapStateToProps(state: RootState) {
	const { companyData } = state.user;
	if (!companyData) {
		throw new Error('User not logged in');
	}

	return {
		companyData,
	};
}

function mapDispatchToProps() {
	return {
		findById: UserGroupActions.findById,
		editUserGroup: UserGroupActions.editUserGroup,
		createUserGroup: UserGroupActions.createUserGroup,
	};
}

const connector = connect(mapStateToProps, mapDispatchToProps());

const enhance = compose<React.ComponentClass<OwnProps>>(
	connector,
	reduxForm<UserGroupRequestModel, FormOwnProps>({ form: USER_GROUP_FORM, destroyOnUnmount: true, validate })
);
export default enhance(UserGroup);
