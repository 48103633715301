import TimeFormat from 'acceligent-shared/enums/timeFormat';
import WorkOrderStatus from 'acceligent-shared/enums/workOrderStatus';
import { TimeSheetInternalApprovalStatus } from 'acceligent-shared/enums/timeSheetApprovalStatus';

import * as TimeUtils from 'acceligent-shared/utils/time';
import { groupBy } from 'acceligent-shared/utils/array';

import { TableContent } from 'ab-common/dataStructures/tableContent';

import { stateAbbreviation } from 'ab-enums/states.enum';

import { formatDecimalNumber } from 'ab-utils/formatting.util';

import type JobPayrollTableBase from 'ab-domain/views/jobPayrollTable/base';

export class W_Accounting_FindJobPayrollTable_VM extends TableContent<W_Accounting_FindJobPayrollTable_VM_Row>{
	constructor(results: JobPayrollTableBase[], startDate: string, endDate: string) {
		const rows = W_Accounting_FindJobPayrollTable_VM_Row.bulkConstructor(results, startDate, endDate);

		super(
			rows,
			1,
			rows.length
		);
	}
}

class W_Accounting_FindJobPayrollTable_VM_Row {
	jobId: number;
	calculatedJobCode: string;
	jobTitle: string;

	divisionName: string;

	allCrewIsInternal: boolean;

	childTable: TableContent<W_Accounting_FindJobPayrollTable_VM_ChildTable>;

	private constructor(dbRowsForJob: JobPayrollTableBase[], weekReferenceDate: string, showWeekIndicators?: boolean) {
		const firstRow = dbRowsForJob[0];

		this.jobId = firstRow.jobId;
		this.calculatedJobCode = firstRow.calculatedJobCode;
		this.jobTitle = firstRow.jobTitle;
		this.divisionName = firstRow.divisionName ?? '';
		this.allCrewIsInternal = firstRow.crewIsInternal;

		const childTableRows = W_Accounting_FindJobPayrollTable_VM_ChildTable.bulkConstructor(dbRowsForJob, weekReferenceDate, showWeekIndicators);
		this.childTable = new TableContent(childTableRows, 1, childTableRows.length);
	}

	/** NOTE: `dbRows` is assumed to be ordered by (job, workOrder, user, date), as described in {@link JobPayrollTableBase} */
	static bulkConstructor(dbRows: JobPayrollTableBase[], startDate: string, endDate: string): W_Accounting_FindJobPayrollTable_VM_Row[] {
		if (!dbRows?.length) {
			return [];
		}
		const showWeekIndicators = (
			TimeUtils.dayOfWeek(startDate, TimeFormat.DB_DATE_ONLY) === 0
			&& TimeUtils.getDiff(endDate, startDate, 'days', TimeFormat.DB_DATE_ONLY) === 6
		);
		const result: W_Accounting_FindJobPayrollTable_VM_Row[] = [];

		const firstRow = dbRows[0];
		let currentJobId: number = firstRow.jobId;
		let currentUserId: number = firstRow.userId;
		let currentWorkOrderId: number = firstRow.workOrderId;
		/** All rows for one (workOrder, user) grouping, i.e. all originating data belongs to the same TimeSheet */
		let dbRowsForCurrentWorkOrderUser: JobPayrollTableBase[] = [firstRow];
		/** All rows for one job, but with each (workOrder, user) grouping having normalized time values */
		let dbRowsForCurrentJobNormalized: JobPayrollTableBase[] = [];

		for (const _dbRow of dbRows) {
			if (_dbRow === firstRow) {
				// Already assigned all values for first row
				continue;
			}
			if (currentUserId !== _dbRow.userId || currentWorkOrderId !== _dbRow.workOrderId) {
				dbRowsForCurrentJobNormalized.push(...dbRowsForCurrentWorkOrderUser);

				// Reset (workOrder, user) grouping:
				currentUserId = _dbRow.userId;
				currentWorkOrderId = _dbRow.workOrderId;
				dbRowsForCurrentWorkOrderUser = [];
			}
			if (currentJobId !== _dbRow.jobId) {
				const dbRowsForCurrentJobNormalizedInRange = dbRowsForCurrentJobNormalized.filter(W_Accounting_FindJobPayrollTable_VM_Row._isNotOutOfDateRange);
				if (!!dbRowsForCurrentJobNormalizedInRange.length) {
					result.push(new W_Accounting_FindJobPayrollTable_VM_Row(dbRowsForCurrentJobNormalizedInRange, startDate, showWeekIndicators));
				}

				// Reset job:
				currentJobId = _dbRow.jobId;
				dbRowsForCurrentJobNormalized = [];
			}

			dbRowsForCurrentWorkOrderUser.push(_dbRow);
		}
		// Loop logic for final grouping:
		dbRowsForCurrentJobNormalized.push(...dbRowsForCurrentWorkOrderUser);
		const dbRowsForCurrentJobNormalizedInRange = dbRowsForCurrentJobNormalized.filter(W_Accounting_FindJobPayrollTable_VM_Row._isNotOutOfDateRange);
		if (!!dbRowsForCurrentJobNormalizedInRange.length) {
			result.push(new W_Accounting_FindJobPayrollTable_VM_Row(dbRowsForCurrentJobNormalizedInRange, startDate, showWeekIndicators));
		}

		return result;
	}

	/**
	 * Returns `true` if row is in queried Payroll Report date range and should be included in the final result.
	 * Includes rows in date range or if it overflows into the following week (FW suffix)
	 *
	 * **DO NOT** exclude rows before calculations between rows (such as {@link _normalizeTimeValues})
	 * to ensure same results for same data between differing date ranges.
	 */
	private static _isNotOutOfDateRange = (entry: JobPayrollTableBase) => entry.shouldShow;

}

class W_Accounting_FindJobPayrollTable_VM_ChildTable {
	// Computed time range columns:

	/** `YYYY-MM-DD`, time date value based on notification settings timezone */
	localDateValue: string;

	// WorkOrder columns:

	workOrderId: number;
	/** `YYYY-MM-DD` */
	workOrderDueDate: string;
	isWorkOrderCanceled: boolean;

	/** `customCrewType` of WO if internal, otherwise `CrewType.name` */
	crewName: string;

	// WorkOrder x Computed time range columns:

	/**
	 * `WorkOrder['code']` (the middle part of the `calculated_work_order_code` string)
	 * plus a shift, i.e. day indicator (see implementation)
	 *
	 * @see {@link https://acceligent.atlassian.net/browse/AP-6711 AP-6711} for some special cases of how shits should be assigned.
	 */
	workOrderShift: string;
	workOrderWeeklyCode: string;

	// Account columns:

	userId: number;
	userUniqueId: string;
	userFullName: string;

	accountId: number;
	accountLocationAddressState: Nullable<string>;

	// TimeSheet & TimeSplit columns:

	timeSheetId: number;

	isTimeSheetSigned: boolean;
	isTimeSheetApproved: boolean;
	timeSheetNote: Nullable<string>;

	hasClassificationCode: boolean;

	/** `timeSplitFWCCCode` ?? `timeSplitsWithZeroTimeFWCCCodesText` ?? null */
	fieldWorkClassificationCode: Nullable<string>;
	/** `timeSplitEquipmentIdCodesText` ?? `timeSplitsWithZeroTimeEquipmentIdCodesText` ?? null */
	equipmentIdCodes: Nullable<string>;

	/** `#.##` */
	jobHours: string;
	totalJobHours: string;
	/** `#.##` */
	breakHours: string;
	totalBreakHours: string;
	/** `#.##` */
	shopHours: string;
	totalShopHours: string;
	/** `#.##` */
	travelHours: string;
	totalTravelHours: string;
	/** `#.##` */
	totalHours: string;
	totalHoursPerWO: string;

	/** list of all possible shift indicators, currently: none (`''`), `'N'` or `'NN'` */
	private static readonly SHIFTS = ['', 'N', 'NN'];
	private static readonly LAST_WEEK_SHIFT = 'LW';
	private static readonly NEXT_WEEK_SHIFT = 'FW';
	/** shift indicator for unexpected cases, should never happen */
	private static readonly SHIFT_OUT_OF_RANGE = 'ERR';

	private constructor(
		dbRow: JobPayrollTableBase,
		weekReferenceDate: string,
		showWeekIndicators?: boolean,
		showTotalEmployee?: boolean,
		showTotalWO?: boolean
	) {
		this.localDateValue = dbRow.localDateValue;

		this.workOrderId = dbRow.workOrderId;
		this.workOrderDueDate = dbRow.workOrderDueDate;
		this.isWorkOrderCanceled = dbRow.workOrderStatus === WorkOrderStatus.CANCELED;
		this.crewName = dbRow.crewName;

		const localDateInWorkOrderIndex = W_Accounting_FindJobPayrollTable_VM_ChildTable._calculateDateIndex(
			dbRow.firstLocalDateValue,
			dbRow.localDateValue,
			dbRow.timezone
		);
		const lastWeekIndicatorIndex = W_Accounting_FindJobPayrollTable_VM_ChildTable._calculateWeekIndex(
			weekReferenceDate,
			dbRow.firstLocalDateValue,
			dbRow.timezone
		);
		const nextWeekIndicatorIndex = W_Accounting_FindJobPayrollTable_VM_ChildTable._calculateWeekIndex(
			weekReferenceDate,
			dbRow.localDateValue,
			dbRow.timezone
		);

		const workOrderCodeShort = dbRow.workOrderCode < 10 ? `0${dbRow.workOrderCode}` : dbRow.workOrderCode.toString();
		const shiftIndicator = W_Accounting_FindJobPayrollTable_VM_ChildTable.SHIFTS[localDateInWorkOrderIndex]
			?? W_Accounting_FindJobPayrollTable_VM_ChildTable.SHIFT_OUT_OF_RANGE;
		const nextWeekIndicator = (showWeekIndicators && nextWeekIndicatorIndex > 0) ? W_Accounting_FindJobPayrollTable_VM_ChildTable.NEXT_WEEK_SHIFT : '';
		const lastWeekIndicator = (showWeekIndicators && lastWeekIndicatorIndex < 0) ? W_Accounting_FindJobPayrollTable_VM_ChildTable.LAST_WEEK_SHIFT : '';
		this.workOrderShift = `${workOrderCodeShort}${shiftIndicator}${lastWeekIndicator}${nextWeekIndicator}`;

		const workOrderWeeklyCodeShort = dbRow.workOrderWeeklyCode < 10 ? `0${dbRow.workOrderWeeklyCode}` : dbRow.workOrderWeeklyCode?.toString();
		this.workOrderWeeklyCode = `${workOrderWeeklyCodeShort}${shiftIndicator}${lastWeekIndicator}${nextWeekIndicator}`;

		this.userId = dbRow.userId;
		this.userUniqueId = dbRow.userUniqueId;
		this.userFullName = dbRow.userFullName;
		this.accountId = dbRow.accountId;
		this.accountLocationAddressState = stateAbbreviation[dbRow.accountLocationAddressAa1] ?? null;

		this.timeSheetId = dbRow.timeSheetId;
		this.isTimeSheetSigned = !!dbRow.timeSheetSignatureId;
		this.isTimeSheetApproved = dbRow.timeSheetApprovalStatus === TimeSheetInternalApprovalStatus.APPROVED;
		this.timeSheetNote = dbRow.timeSheetNote;
		this.hasClassificationCode = !!dbRow.timeSplitFWCCId;
		this.fieldWorkClassificationCode = dbRow.timeSplitFWCCCode ?? dbRow.timeSplitsWithZeroTimeFWCCCodesText ?? null;
		if (!dbRow.timeSplitFWCCId && !!this.fieldWorkClassificationCode) {
			this.fieldWorkClassificationCode = `[MISSING] ${this.fieldWorkClassificationCode}`;
		}
		this.equipmentIdCodes = dbRow.timeSplitEquipmentIdCodesText ?? null;

		this.jobHours = formatDecimalNumber(dbRow.jobTime / 60);
		this.totalJobHours = showTotalEmployee ? formatDecimalNumber(dbRow.totalJobTime / 60) : '';
		this.breakHours = formatDecimalNumber(dbRow.breakTime / 60);
		this.totalBreakHours = showTotalEmployee ? formatDecimalNumber(dbRow.totalBreakTime / 60) : '';
		this.shopHours = formatDecimalNumber(dbRow.shopTime / 60);
		this.totalShopHours = showTotalEmployee ? formatDecimalNumber(dbRow.totalShopTime / 60) : '';
		this.travelHours = formatDecimalNumber(dbRow.travelTime / 60);
		this.totalTravelHours = showTotalEmployee ? formatDecimalNumber(dbRow.totalTravelTime / 60) : '';

		this.totalHours = formatDecimalNumber(dbRow.totalHours / 60);
		this.totalHoursPerWO = showTotalWO ? formatDecimalNumber(dbRow.totalHoursPerWO / 60) : '';
	}

	static bulkConstructor = (dbRowsForJob: JobPayrollTableBase[], weekReferenceDate: string, showWeekIndicators?: boolean) => {
		/*
			Bear with me here. If there is no excess job time on a NULL CC payroll entry, meaning all the job time has
			been covered by the classification code splits we want to take the travel, shop and break times from that
			entry and merely display them on the first non-NULL CC entry. We do this in the presentational layer only,
			meaning this view model.
		*/
		const nullCCEntriesToOmit: Record<string, true> = {};

		// here we create daily groups of entries for a single user working on a single work order on a given day
		const payrollEntriesByDateKey = groupBy(dbRowsForJob, W_Accounting_FindJobPayrollTable_VM_ChildTable._payrollEntryDateKey);

		for (const key of Object.keys(payrollEntriesByDateKey)) {
			// we get the daily entries
			const dateEntries = payrollEntriesByDateKey[key];
			if (dateEntries.length > 1) {
				// we find the first non NULL CC entry
				const firstNonNullCCEntry = dateEntries.find((_entry) => _entry.timeSplitFWCCId !== null);
				if (!firstNonNullCCEntry) {
					continue;
				}
				const nullCCEntries = dateEntries.filter((_entry) => _entry.timeSplitFWCCId === null);
				// we find all the NULL CC entries, there could ptentially be more than one because of old data
				if (nullCCEntries.some((_entry) => (
					_entry.jobTime !== 0
					|| !!_entry.timeSplitEquipmentIdCodesText
					|| !!_entry.timeSplitsWithZeroTimeFWCCCodesText)
				)) {
					// if we find at least one NULL CC entry which has unallocated job time we skip the whole reassignment procedure
					continue;
				}
				for (const nullCCEntry of nullCCEntries) {
					// at this point all the NULL CC entries have a job time of 0 and their non job times can be moved over
					// to the first non NULL CC entry
					firstNonNullCCEntry.shopTime += nullCCEntry.shopTime;
					firstNonNullCCEntry.travelTime += nullCCEntry.travelTime;
					firstNonNullCCEntry.breakTime += nullCCEntry.breakTime;
					firstNonNullCCEntry.totalHours += (nullCCEntry.shopTime + nullCCEntry.travelTime + nullCCEntry.breakTime);
					// we mark that we're not showing these NULL CC entries anymore
					nullCCEntriesToOmit[key] = true;
				}
			}
		}

		const filteredDbRowsForJob = dbRowsForJob.filter((dbRow) => (
			!!dbRow.timeSplitFWCCId
			|| !nullCCEntriesToOmit[W_Accounting_FindJobPayrollTable_VM_ChildTable._payrollEntryDateKey(dbRow)]
		));

		const childTableRows: W_Accounting_FindJobPayrollTable_VM_ChildTable[] = [];
		for (let i = 0; i < filteredDbRowsForJob.length; i++) {
			const dbRow = filteredDbRowsForJob[i];
			childTableRows.push(new W_Accounting_FindJobPayrollTable_VM_ChildTable(
				dbRow,
				weekReferenceDate,
				showWeekIndicators,
				dbRow.userId !== filteredDbRowsForJob[i + 1]?.userId || dbRow.workOrderId !== filteredDbRowsForJob[i + 1]?.workOrderId,
				dbRow.workOrderId !== filteredDbRowsForJob[i + 1]?.workOrderId
			));
		}
		return childTableRows;
	};

	private static _payrollEntryDateKey = (entry: JobPayrollTableBase) => {
		return JSON.stringify([
			entry.workOrderId,
			entry.accountId,
			entry.localDateValue,
		]);
	};

	private static _calculateDateIndex(reference: string, date: string, timezone: Nullable<string>) {
		const referenceMidnight = timezone
			? TimeUtils.parseMomentTimezone(reference, timezone, TimeFormat.DB_DATE_ONLY, true).toDate()
			: TimeUtils.parseDate(reference, TimeFormat.DB_DATE_ONLY);

		return Math.floor(TimeUtils.getDiff(date, referenceMidnight, 'day', TimeFormat.ISO_DATETIME));
	}

	private static _calculateWeekIndex(reference: string, date: string, timezone: Nullable<string>) {
		const referenceMidnight = timezone
			? TimeUtils.parseMomentTimezone(reference, timezone, TimeFormat.DB_DATE_ONLY, true).toDate()
			: TimeUtils.parseDate(reference, TimeFormat.DB_DATE_ONLY);

		const parsedDate = timezone
			? TimeUtils.parseMomentTimezone(date, timezone, TimeFormat.DB_DATE_ONLY, true).toDate()
			: TimeUtils.parseDate(date, TimeFormat.DB_DATE_ONLY);

		const referenceWeekStart = TimeUtils.positionDate(referenceMidnight, 'start', 'week', timezone ?? undefined);
		const dateWeekStart = TimeUtils.positionDate(parsedDate, 'start', 'week', timezone ?? undefined);

		return TimeUtils.getDiff(dateWeekStart, referenceWeekStart, 'week', TimeFormat.ISO_DATETIME);
	}
}

