import type ContactLookupMethodBase from 'ab-domain/models/contactLookupMethod/base';
import type ContactMethodBase from 'ab-domain/models/contactMethod/base';
import type ContactLookupBase from 'ab-domain/models/contactLookup/base';

import { PhoneTypes, EmailTypes, EmailTypesArray, PhoneTypesArray } from 'acceligent-shared/enums/contactMethodType';

import { filterMap } from './array.util';
import type ContactBase from 'ab-domain/models/contact/base';

export const filterContactMethod = (methods: (ContactLookupMethodBase)[] = [], types: string[] = []) => {
	return filterMap(
		methods,
		(_wrcMethod) => _wrcMethod.contactMethod && types.includes(_wrcMethod.contactMethod.type),
		(_wrcMethod) => _wrcMethod.contactMethodId
	);
};

export const selectOneOrEmpty = (ids: number[] = []) => {
	return ids.length > 0 ? [ids[0]] : [];
};

type ContactMethodDict = Record<number, boolean>;
type ContactMethodGroups = { phoneDict: ContactMethodDict; emailDict: ContactMethodDict; };
export const groupContactMethodIds = (contact: ContactLookupBase): ContactMethodGroups => {
	const contactEmailIds = filterContactMethod(contact.contactLookupMethods, EmailTypesArray);
	const contactPhoneIds = filterContactMethod(contact.contactLookupMethods, PhoneTypesArray);

	const phoneDict = contactPhoneIds.reduce(
		(_acc: ContactMethodDict, id) => Object.assign(_acc, { [id]: true }),
		{}
	);
	const emailDict = contactEmailIds.reduce(
		(_acc: ContactMethodDict, id) => Object.assign(_acc, { [id]: true }),
		{}
	);

	return { phoneDict, emailDict };
};

type ActiveContactMethods<P, E> = { emails: E[]; phoneNumbers: P[]; };

export function getActiveContactMethods<P, E>(
	contact: Nullable<ContactLookupBase>,
	mapPhoneNumber: (cm: ContactMethodBase) => P,
	mapEmail: (cm: ContactMethodBase) => E
): ActiveContactMethods<P, E> {
	if (!contact) {
		return { emails: [], phoneNumbers: [] };
	}
	const { phoneDict, emailDict } = groupContactMethodIds(contact);

	return contact.contact.contactMethods.reduce<ActiveContactMethods<P, E>>((_acc, _contactMethod) => {
		if (!!PhoneTypes[_contactMethod.type] && phoneDict[_contactMethod.id]) {
			_acc.phoneNumbers.push(mapPhoneNumber(_contactMethod));
		} else if (!!EmailTypes[_contactMethod.type] && emailDict[_contactMethod.id]) {
			_acc.emails.push(mapEmail(_contactMethod));
		}
		return _acc;
	}, { emails: [], phoneNumbers: [] });
}

export interface MultipleValueLogData {
	id: number;
	value: string;
}
export const extractContactMethodsAndAddresses = (contact: ContactBase) => {
	const contactEmails: MultipleValueLogData[] = [];
	const contactPhones: MultipleValueLogData[] = [];
	contact.contactMethods?.forEach((_contactMethod) => {
		return EmailTypesArray.includes(_contactMethod.type) ?
			contactEmails.push({ id: _contactMethod.id, value: _contactMethod.value })
			: PhoneTypesArray.includes(_contactMethod.type) ?
				contactPhones.push({ id: _contactMethod.id, value: _contactMethod.value })
				: null;
	});

	const contactAddresses: MultipleValueLogData[] = [];
	contact.addresses.forEach((_add) => {
		let wholeAddress = _add.address.street;
		if (!wholeAddress.includes(',')) {
			wholeAddress = _add.address.street + ', ' + _add.address.locality + ', ' + _add.address.aa1 + ' ' + _add.address.postalCode + ', ' + _add.address.country;
		}
		contactAddresses.push({ id: _add.addressId, value: `${wholeAddress}, suite ${_add.address.suite ?? '-'}, PO Box ${_add.address.postalOfficeBoxCode ?? '-'}` });
	});

	return ({
		contactEmails,
		contactPhones,
		contactAddresses,
	});
};
