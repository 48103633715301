import * as React from 'react';

import type { DivisionViewModel } from 'ab-viewModels/division.viewModel';

import Pill from 'af-components/Pills/Pill';

interface OwnProps {
	division: DivisionViewModel;
	toEdit?: (id: number) => void;
	deleteDivision?: (id: number) => void;
}

type Props = OwnProps;

export default class DivisionPreview extends React.PureComponent<Props> {

	edit = () => {
		const { division, toEdit } = this.props;
		if (toEdit) {
			toEdit(division.id);
		}
	};

	deleteDivision = () => {
		const { deleteDivision, division } = this.props;
		if (deleteDivision) {
			deleteDivision(division.id);
		}
	};

	modalBody = (division: DivisionViewModel) => (
		<>
			You are about to delete division <b>{division.name}</b>.
			Are you sure you want to continue?
		</>
	);

	render() {
		const { division, toEdit, deleteDivision } = this.props;

		return (
			<Pill
				item={division}
				labelKey="name"
				modalBody={this.modalBody}
				modalTitle="Delete Division"
				onClick={toEdit ? this.edit : undefined}
				removeItem={deleteDivision ? this.deleteDivision : undefined}
			/>
		);
	}
}
