import * as React from 'react';
import { Button } from 'react-bootstrap';
import type { ConnectedProps} from 'react-redux';
import { connect } from 'react-redux';

import CompanyFeatureFlagsEnum, { CompanyFeatureFlagsTips } from 'ab-enums/companyFeatureFlags.enum';

import CustomModal from 'af-components/CustomModal';
import SubmitButton from 'af-components/SubmitButton';
import Checkbox from 'af-components/Controls/Checkbox';

import * as CompanyActions from 'af-actions/companies';

import PreviewItem from '../../Shared/PreviewItem';

type OwnProps = {
	showModal: boolean;
	closeModal: () => void;
	isSendingInvoiceNotificationsEnabled: boolean;
};

type Props = OwnProps & ConnectedProps<typeof connector>;

const EnableInvoiceNotificationsCompanyEdit: React.FC<Props> = (
	{ closeModal, showModal, isSendingInvoiceNotificationsEnabled, editEnableInvoiceNotifications }
) => {
	const [enableInvoiceNotifications, setEnableInvoiceNotifications] = React.useState(isSendingInvoiceNotificationsEnabled);

	const renderLabel = React.useCallback(() => {
		return (
			<PreviewItem
				checked={!!isSendingInvoiceNotificationsEnabled}
				hint={CompanyFeatureFlagsTips.invoiceNotifications}
				indent={false}
				label={CompanyFeatureFlagsEnum.isSendingInvoiceNotificationsEnabled}
				showCheckbox={false}
			/>
		);
	}, [isSendingInvoiceNotificationsEnabled]);

	const handleChange = React.useCallback((event) => {
		setEnableInvoiceNotifications(event.target.checked);
	}, []);

	const handleSubmit = React.useCallback(async () => {
		await editEnableInvoiceNotifications({ isSendingInvoiceNotificationsEnabled: enableInvoiceNotifications });
		closeModal();
	}, [editEnableInvoiceNotifications, enableInvoiceNotifications, closeModal]);

	return (
		<CustomModal
			closeModal={closeModal}
			modalStyle="info"
			showModal={showModal}
			size="lg"
		>
			<CustomModal.Header
				closeModal={closeModal}
				title="Edit Invoice Notifications"
			/>
			<CustomModal.Body>
				<Checkbox
					handleChange={handleChange}
					isChecked={enableInvoiceNotifications}
					label={renderLabel()}
				/>
			</CustomModal.Body>
			<CustomModal.Footer>
				<Button onClick={closeModal} variant="info">Cancel</Button>
				<SubmitButton
					label="Save"
					onClick={handleSubmit}
				/>
			</CustomModal.Footer>
		</CustomModal>
	);
};

function mapDispatchToProps() {
	return {
		editEnableInvoiceNotifications: CompanyActions.editEnableInvoiceNotifications,
	};
}

const connector = connect(null, mapDispatchToProps());

export default connector(EnableInvoiceNotificationsCompanyEdit);
