import type { NotificationSnackbarTypes} from 'ab-enums/notificationSnackbarContext.enum';
import { NotificationActionTypes } from 'ab-enums/notificationSnackbarContext.enum';

interface NotificationData {
	id: number;
	content: string;
	type: NotificationSnackbarTypes;
	date?: Date;
}

interface NotificationSnackbarStoreState {
	notifications: NotificationData[];
}

interface ActionType {
	payload: NotificationData;
	type: NotificationActionTypes;
}

export const notificationSnackbarReducer = (state: NotificationSnackbarStoreState, action: ActionType): NotificationSnackbarStoreState => {
	switch (action.type) {
		case NotificationActionTypes.ADD_NOTIFICATION:
			return {
				notifications: [...state.notifications, action.payload],
			};
		case NotificationActionTypes.DELETE_NOTIFICATION:
			const updatedNotifications = state.notifications.filter(
				(notification: NotificationData) => notification.id !== action.payload.id
			);
			return {
				notifications: updatedNotifications,
			};
		case NotificationActionTypes.UPDATE_CONTENT:
			return {
				notifications: state.notifications.map((_notification) => {
					if (_notification.id === action.payload.id) {
						return action.payload;
					}
					return _notification;
				}),
			};
		default:
			return state;
	}
};
