import type { Dispatch, AnyAction } from 'redux';

import type ToolState from 'acceligent-shared/enums/toolState';

import { http } from 'af-utils/http.util';
import { errorHandler } from 'af-utils/actions.util';

import { TableQuery } from 'ab-common/dataStructures/tableQuery';

import * as CreateAPI from 'ab-api/web/smallTool/create';
import * as ChangeLocationAPI from 'ab-api/web/smallTool/changeLocation';
import * as ChangeToolStateAPI from 'ab-api/web/smallTool/changeToolState';
import * as UpdateAPI from 'ab-api/web/smallTool/update';
import * as DeleteAPI from 'ab-api/web/smallTool/deleteTool';
import * as BulkDeleteAPI from 'ab-api/web/smallTool/bulkDelete';
import * as FindTakenSerialListAPI from 'ab-api/web/smallTool/findTakenSerialList';
import * as FindOptionListAPI from 'ab-api/web/smallTool/findOptionList';
import * as FindSmallToolTableAPI from 'ab-api/web/smallTool/findSmallToolTable';
import * as FindAllToolTrackingRecordsAPI from 'ab-api/web/smallTool/findAllToolTrackingRecords';
import * as FindByIdAPI from 'ab-api/web/smallTool/findById';

import type { GetRootState } from 'af-reducers';

export function create(form: CreateAPI.W_SmallTool_Create_RM) {
	return async (dispatch: Dispatch<AnyAction>, getState: GetRootState, { redirectTo }) => {
		const action = async () => {
			await http.post<void>(CreateAPI.URL(), form);
		};
		return await errorHandler(action, dispatch, redirectTo);
	};
}

export function update(id: number, data: UpdateAPI.W_SmallTool_Update_RM) {
	return async (dispatch: Dispatch<AnyAction>, getState: GetRootState, { redirectTo }) => {
		const action = async () => {
			await http.put<void>(UpdateAPI.URL(id), data);
		};
		return await errorHandler(action, dispatch, redirectTo);
	};
}

export function findById(id: number) {
	return async (dispatch: Dispatch<AnyAction>, getState: GetRootState, { redirectTo }) => {
		const action = async () => {
			return await http.get<FindByIdAPI.W_SmallTool_FindById_VM>(FindByIdAPI.URL(id));
		};
		return await errorHandler(action, dispatch, redirectTo);
	};
}

export function findTakenSerialList() {
	return async (dispatch: Dispatch<AnyAction>, getState: GetRootState, { redirectTo }) => {
		const action = async () => {
			const response = await http.get<FindTakenSerialListAPI.W_SmallTool_FindTakenSerialList_VM>(FindTakenSerialListAPI.URL());
			return response.items;
		};

		return await errorHandler(action, dispatch, redirectTo);
	};
}

export function deleteTool(id: number) {
	return async (dispatch: Dispatch<AnyAction>, getState: GetRootState, { redirectTo }) => {
		const action = async () => {
			await http.delete<void>(DeleteAPI.URL(id));
		};
		return await errorHandler(action, dispatch, redirectTo);
	};
}

export function findAllForTable(tableRequestModel: TableQuery) {
	return async (dispatch: Dispatch<AnyAction>, getState: GetRootState, { redirectTo }) => {
		const action = async () => {
			const data = new TableQuery(tableRequestModel);
			return await http.get<FindSmallToolTableAPI.W_SmallTool_FindSmallToolTable_VM>(FindSmallToolTableAPI.URL(data));
		};

		return await errorHandler(action, dispatch, redirectTo);
	};
}

export function findAllForList() {
	return async (dispatch: Dispatch<AnyAction>, getState: GetRootState, { redirectTo }) => {
		const action = async () => {
			const response = await http.get<FindOptionListAPI.W_SmallTool_FindOptionList_VM>(FindOptionListAPI.URL());
			return response.items;
		};

		return await errorHandler(action, dispatch, redirectTo);
	};
}

export function fetchToolHistoryTable(tableRequestModel: TableQuery, toolId: number) {
	return async (dispatch: Dispatch<AnyAction>, getState: GetRootState, { redirectTo }) => {
		const action = async () => {
			const data = new TableQuery(tableRequestModel);
			return await http.get<FindAllToolTrackingRecordsAPI.W_SmallTool_FindAllToolTrackingRecords_VM>(FindAllToolTrackingRecordsAPI.URL(toolId, data));
		};

		return await errorHandler(action, dispatch, redirectTo);
	};
}

export function changeLocation(form: ChangeLocationAPI.W_SmallTool_ChangeLocation_RM, toolId: number) {
	return async (dispatch: Dispatch<AnyAction>, getState: GetRootState, { redirectTo }) => {
		const action = async () => {
			return await http.put<void>(ChangeLocationAPI.URL(toolId), form);
		};

		return await errorHandler(action, dispatch, redirectTo);
	};
}

export function changeToolState(toolId: number, state: ToolState) {
	return async (dispatch: Dispatch<AnyAction>, getState: GetRootState, { redirectTo }) => {
		const action = async () => {
			const body: ChangeToolStateAPI.W_SmallTool_ChangeToolState_RM = { state };
			return await http.put<void>(ChangeToolStateAPI.URL(toolId), body);
		};

		return await errorHandler(action, dispatch, redirectTo);
	};
}

export function bulkDeleteTools(ids: number[]) {
	return async (dispatch: Dispatch<AnyAction>, getState: GetRootState, { redirectTo }) => {
		const action = async () => {
			const body: BulkDeleteAPI.W_SmallTool_BulkDelete_RM = { toolIds: ids };
			await http.delete<void>(BulkDeleteAPI.URL(), body);
		};

		return await errorHandler(action, dispatch, redirectTo);
	};
}
