import ReportBlockType from 'acceligent-shared/enums/reportBlockType';

import type { ReportTypeBlockFormModel, ReportBlockFormModel, ReportBlockFieldFormModel } from '../../Shared/formModel';

/** Checks whether field names in block are unique but only for CALCULATED blocks */
const _areFieldNamesInBlockUnique = (block: ReportBlockFormModel, reportFieldsByIdMap: { [id: string]: ReportBlockFieldFormModel; }) => {
	if (block.type !== ReportBlockType.CALCULATED) {
		return true;
	}
	const fieldNameMap: { [name: string]: true; } = {};
	for (const _fieldId of (block.reportBlockFieldIds ?? [])) {
		const _field = reportFieldsByIdMap[_fieldId];
		if (fieldNameMap[_field.name]) {
			return false;
		}
		fieldNameMap[_field.name] = true;
	}
	return true;
};

/**
	 * Validates report type RM
	 * Not Valid if:
	 * 1. There is no primary main block
	 * 2. There is no report type name
	 * 3, There are blocks in secondary segment but no Main block in secondary segment
	 * 4. If there exists a block without fields or if field names in block are not unique
	 * 5. Field names in upper total block are not unique
	 * 6. Field names in lower total block are not unique
	*/
export const validate = (
	primaryTypeMainBlockId: Nullable<string>,
	primaryTypeBlockIds: string[],
	secondaryTypeBlockIds: string[],
	secondaryTypeMainBlockId: Nullable<string>,
	upperTotalBlockId: Nullable<string>,
	lowerTotalBlockId: Nullable<string>,
	reportTypeBlocksByIdMap: { [id: string]: ReportTypeBlockFormModel; },
	reportBlocksByIdMap: { [id: string]: ReportBlockFormModel; },
	reportFieldsByIdMap: { [id: string]: ReportBlockFieldFormModel; },
	reportTypeName: string
) => {
	return !primaryTypeMainBlockId
		|| !reportTypeName?.length
		|| (!!secondaryTypeBlockIds.length && !secondaryTypeMainBlockId)
		|| [...(primaryTypeBlockIds ?? []), ...(secondaryTypeBlockIds ?? [])]
			.some((_id) => {
				const _typeBlock = reportTypeBlocksByIdMap[_id];
				const _block = reportBlocksByIdMap[_typeBlock.reportBlockVirtualId];
				return !_block.reportBlockFieldIds?.length || !_areFieldNamesInBlockUnique(_block, reportFieldsByIdMap);
			})
		|| (!!upperTotalBlockId
			&& !_areFieldNamesInBlockUnique(reportBlocksByIdMap[reportTypeBlocksByIdMap[upperTotalBlockId].reportBlockVirtualId], reportFieldsByIdMap)
		)
		|| (!!lowerTotalBlockId
			&& !_areFieldNamesInBlockUnique(reportBlocksByIdMap[reportTypeBlocksByIdMap[lowerTotalBlockId].reportBlockVirtualId], reportFieldsByIdMap)
		);
};
