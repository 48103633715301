import * as React from 'react';
import type { StaticContext } from 'react-router';
import type { CustomRouteComponentProps } from 'react-router-dom';
import type { ResolveThunks} from 'react-redux';
import { connect } from 'react-redux';

import type { RootState } from 'af-reducers';

import DeliverableFormDetails from 'af-components/DeliverableFormDetails';
import DeliverableAssignmentForm from 'af-components/SharedForms/Deliverables/Assignment';
import Breadcrumbs from 'af-components/Breadcrumbs';

import * as DeliverableActions from 'af-actions/deliverable';

import type { DeliverableSubmissionViewModel, DeliverableViewModel } from 'ab-viewModels/deliverable.viewModel';

import type { DeliverableAssignmentRequestModel } from 'ab-requestModels/deliverable.requestModel';

import CLIENT from 'af-constants/routes/client';

import * as SettingsUtils from 'af-utils/settings.util';

interface PathParams {
	submissionId: string;
	deliverableId: string;
}

interface StateProps {
	companyName: string;
}

interface DispatchProps {
	createAssignment: typeof DeliverableActions.createDeliverableAssignment;
	findDeliverable: typeof DeliverableActions.findById;
	findSubmission: typeof DeliverableActions.findSubmissionById;
}

type OwnProps = CustomRouteComponentProps<PathParams, StaticContext>;

type Props = OwnProps & StateProps & ResolveThunks<DispatchProps>;

interface State {
	deliverable: DeliverableViewModel;
	submission: DeliverableSubmissionViewModel;
}

class DeliverableAssignment extends React.Component<Props, State> {
	state: State = {
		deliverable: {} as DeliverableViewModel,
		submission: {} as DeliverableSubmissionViewModel,
	};

	async componentDidMount() {
		const { match: { params: { deliverableId, submissionId } }, findDeliverable, findSubmission } = this.props;
		const [deliverable, submission] = await Promise.all([findDeliverable(+deliverableId), findSubmission(+submissionId)]);
		SettingsUtils.setExpandedDeliverableIds(deliverableId, submissionId);
		this.setState(() => ({ deliverable, submission }));
	}

	onSubmit = (form: DeliverableAssignmentRequestModel) => {
		const { createAssignment, match: { params: { submissionId } }, location: { state: { orgAlias } }, companyName } = this.props;
		form.deliverableSubmissionId = +submissionId;
		createAssignment(form, orgAlias, companyName);
	};

	onBack = () => {
		const { history, location: { state: { orgAlias } }, companyName } = this.props;
		history.push(CLIENT.COMPANY.DELIVERABLE.DASHBOARD(orgAlias, companyName));
	};

	render() {
		const {
			location: { state: { orgAlias } },
			companyName,
		} = this.props;
		const { deliverable, submission } = this.state;

		return (
			<div className="form-segment deliverable-form">
				<Breadcrumbs
					items={[
						{ label: 'Deliverables', url: CLIENT.COMPANY.DELIVERABLE.DASHBOARD(orgAlias, companyName) },
						{ label: 'Deliverable Submission - Assignment' },
					]}
				/>
				<DeliverableFormDetails
					deliveryMethod={deliverable.deliveryMethod}
					deliveryTimeline={deliverable.deliveryTimeline}
					dueDate={submission.dueDate}
					endDate={deliverable.endDate}
					jobCode={deliverable.jobCode}
					startDate={deliverable.startDate}
					submissionCode={submission.submissionCode}
					workOrderCode={submission.workOrderCode}
				/>
				<DeliverableAssignmentForm
					onBack={this.onBack}
					onSubmit={this.onSubmit}
				/>
			</div>
		);
	}
}

const mapStateToProps = (state: RootState): StateProps => {
	const { companyData } = state.user;
	if (!companyData) {
		throw new Error('User not logged in');
	}
	return {
		companyName: companyData.name,
	};
};

function mapDispatchToProps(): DispatchProps {
	return {
		createAssignment: DeliverableActions.createDeliverableAssignment,
		findSubmission: DeliverableActions.findSubmissionById,
		findDeliverable: DeliverableActions.findById,
	};
}

export default connect<StateProps, DispatchProps, OwnProps>(mapStateToProps, mapDispatchToProps())(DeliverableAssignment);
