import * as LogRocket from 'logrocket';

import type { UserData, CompanyData } from 'ab-viewModels/user.viewModel';

export const identifyLogRocketSession = (user: UserData, activeCompany?: CompanyData): void => {
	LogRocket.identify(user.id.toString(), {
		firstName: user.firstName,
		lastName: user.lastName,
		email: (user.email ?? user.phoneNumber)!, // He has one or the other, that's why we're comfortable casting
		companyName: activeCompany?.name ?? '',
	});
};
