import * as React from 'react';
import { compose } from 'redux';
import type { CustomRouteComponentProps } from 'react-router-dom';
import type { ConnectedProps } from 'react-redux';
import { connect } from 'react-redux';
import type { InjectedFormProps } from 'redux-form';
import { reduxForm } from 'redux-form';

import type UpsertTemporaryEmployeeRM from 'ab-requestModels/temporaryEmployee.requestModel';

import type AgencyVM from 'ab-viewModels/agency/agency.viewModel';

import Breadcrumbs from 'af-components/Breadcrumbs';

import CLIENT from 'af-constants/routes/client';
import { TEMPORARY_EMPLOYEE_CREATE } from 'af-constants/reduxForms';

import type { RootState } from 'af-reducers';

import * as TemporaryEmployeeActions from 'af-actions/temporaryEmployee';
import * as AgencyActions from 'af-actions/agency';

import TemporaryEmployeeForm from '../Form';
import { validate } from '../Shared/validations';

type OwnProps = CustomRouteComponentProps;

type FormProps = OwnProps & InjectedFormProps<UpsertTemporaryEmployeeRM>;

type Props = FormProps & ConnectedProps<typeof connector>;

interface State {
	agencies: Nullable<AgencyVM[]>;
}

class CreateTemporaryEmployee extends React.PureComponent<Props, State> {

	state: State = {
		agencies: null,
	};

	async componentDidMount() {
		const { findAllAgencies } = this.props;

		const agencies = await findAllAgencies();
		this.setState(() => ({ agencies }));
	}

	submit = async (data: UpsertTemporaryEmployeeRM) => {
		const { create } = this.props;

		await create(data);
		this.goBack();
	};

	goBack = () => {
		const { location: { state: { orgAlias } }, companyName, history } = this.props;
		history.push(CLIENT.COMPANY.RESOURCES.TEMPORARY_EMPLOYEE.LIST(orgAlias, companyName));
	};

	render() {
		const { companyName, location: { state: { orgAlias } } } = this.props;
		const { agencies } = this.state;

		return (
			<div className="form-segment">
				<Breadcrumbs
					items={
						[
							{ label: 'Temporary labor', url: CLIENT.COMPANY.RESOURCES.TEMPORARY_EMPLOYEE.LIST(orgAlias, companyName) },
							{ label: 'Add Temporary Employee' },
						]
					}
				/>
				<div className="form-box">
					<TemporaryEmployeeForm
						{...this.props}
						agencies={agencies}
						companyName={companyName}
						onSubmit={this.submit}
					/>
				</div>
			</div>
		);
	}
}

function mapStateToProps(state: RootState) {
	const { companyData } = state.user;
	if (!companyData) {
		throw new Error('User not logged in');
	}

	return {
		companyName: companyData.name,
	};
}

function mapDispatchToProps() {
	return {
		create: TemporaryEmployeeActions.create,
		findAllAgencies: AgencyActions.findAll,
	};
}

const connector = connect(mapStateToProps, mapDispatchToProps());

const enhance = compose<React.ComponentClass<OwnProps>>(
	connector,
	reduxForm<UpsertTemporaryEmployeeRM, FormProps>({ form: TEMPORARY_EMPLOYEE_CREATE, validate })
);

export default enhance(CreateTemporaryEmployee);
