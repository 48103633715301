import * as React from 'react';
import { compose } from 'redux';
import { useHistory } from 'react-router';
import { Button, Row, Col } from 'react-bootstrap';
import type { ConnectedProps } from 'react-redux';
import { connect } from 'react-redux';

import { ColorHex } from 'acceligent-shared/enums/color';

import type { JobSelectForInvoicesViewModel } from 'ab-viewModels/jobTable.viewModel';

import * as JobActions from 'af-actions/jobs';

import CustomModal from 'af-components/CustomModal';
import SubmitButton from 'af-components/SubmitButton';
import * as DropdownSelector from 'af-components/Controls/Dropdown';
import BadgeCell from 'af-components/Table6/Cells/BadgeCell';

import CLIENT from 'af-routes/client';

interface OwnProps {
	closeModal: () => void;
	showModal: boolean;
	orgAlias: string;
	companyName: string;
	descriptionClassName?: string;
}

type FormOwnProps = OwnProps;

type Props = FormOwnProps & ConnectedProps<typeof connector>;

const highlightText = (text: string, highlight: string) => {
	const _lowerText = text?.toLowerCase();
	const _lowerHighLight = highlight?.toLowerCase();
	const index = _lowerText.indexOf(_lowerHighLight);

	if (!highlight || index === -1) {
		return <span>{text}</span>;
	}
	const _highlightText = text.slice(index, index + highlight.length);

	return (
		<>
			{text.split(_highlightText).map((_subtext, _index, _list) => (
				<span key={_index}>
					{_subtext}{_index + 1 !== _list.length && <mark>{_highlightText}</mark>}
				</span>)
			)}
		</>
	);
};

interface PreviewBillingContactVM {
	selectedContactMethodEmails: string[];
}

class SelectJobFM {
	id: number;
	jobCode: string;
	isInternal: boolean;
	title: Nullable<string>;
	customerCompany: Nullable<string>;
	customerFormatted: Nullable<string>;
	travelLocationShort: Nullable<string>;
	billingContact: Nullable<PreviewBillingContactVM>;

	constructor(job: JobSelectForInvoicesViewModel) {
		this.id = job.id;
		this.jobCode = job.jobCode;
		this.isInternal = job.isInternal;
		this.title = job.title;
		this.customerCompany = job.customerCompany;
		this.customerFormatted = job.customerFormatted;
		this.travelLocationShort = job.travelLocationShort;
		this.billingContact = job.billingContact;
	}

	private static _constructorMap = (job: JobSelectForInvoicesViewModel) => new SelectJobFM(job);

	static bulkConstructor = (jobs: JobSelectForInvoicesViewModel[]) => jobs.map(SelectJobFM._constructorMap);

}

const InvoiceJobSelectModal: React.FC<Props> = (props) => {
	const {
		showModal,
		closeModal,
		descriptionClassName,
		loadJobs,
		getUserGroupAndPM,
		orgAlias,
		companyName,
	} = props;

	const [jobsToSelect, setJobsToSelect] = React.useState<SelectJobFM[]>([]);
	const [selectedJob, setSelectedJob] = React.useState<Nullable<SelectJobFM>>(null);
	const [jobSearchText, setJobSearchText] = React.useState<string>('');

	const history = useHistory();

	const fetchJobs = React.useCallback(async () => {
		const jobs = await loadJobs();
		setJobsToSelect(SelectJobFM.bulkConstructor(jobs));
	}, [loadJobs]);

	React.useEffect(() => {
		if (showModal) {
			fetchJobs();
		} else {
			setSelectedJob(null);
		}
		// eslint-disable-next-line react-hooks/exhaustive-deps
	}, [showModal]);

	const goToNewInvoice = React.useCallback(async () => {
		if (selectedJob) {
			const usrGroupPM = await getUserGroupAndPM(selectedJob.id);
			history.push({
				pathname: CLIENT.COMPANY.JOBS.INVOICE_CREATE(orgAlias, companyName, selectedJob.id.toString()),
				state: {
					jobCode: selectedJob.jobCode,
					billingEmailsToPrefill: selectedJob.billingContact?.selectedContactMethodEmails ?? [],
					backToAccountingPage: true,
					userGroupAndPM: usrGroupPM,
				},
			});
		} else {
			throw new Error('There is no selected job');
		}
	}, [companyName, selectedJob, history, orgAlias, getUserGroupAndPM]);

	const renderJobMenuItem = React.useCallback((option: SelectJobFM, searchText: string) => {
		const { jobCode, customerCompany, customerFormatted, travelLocationShort, isInternal } = option;

		return (
			<div className="work-order-upsert__job-picker-option" key={option.id}>
				<div>
					<strong>{highlightText(jobCode, searchText)}</strong>
					<small><br /></small>
					{!!customerCompany && <small>{highlightText(customerCompany, searchText)}</small>}
					{!!customerFormatted &&
						<small>
							{(!!customerCompany) && ' |'} {highlightText(customerFormatted, searchText)}
						</small>
					}
					{!!travelLocationShort &&
						<small>
							{(!!customerFormatted || !!customerCompany) && ' |'} {highlightText(travelLocationShort, searchText)}
						</small>
					}
				</div>
				{isInternal &&
					<BadgeCell
						badgeColor={ColorHex.BLACK}
						colorNegative={false}
						text="INTERNAL"
					/>
				}
			</div>
		);
	}, []);

	const renderSelectedItem = React.useCallback(() => {
		return <>{selectedJob?.jobCode ? `${selectedJob.jobCode}` : ''}</>;
	}, [selectedJob]);

	const filterJobOption = React.useCallback((option: SelectJobFM, rawInput: string) => {
		const { jobCode, title, customerCompany, customerFormatted, travelLocationShort } = option;

		if (rawInput !== jobSearchText) {
			setJobSearchText(rawInput);
		}

		return `${jobCode} ${title} ${customerCompany} ${customerFormatted} ${travelLocationShort}`.toLowerCase().includes(rawInput.toLowerCase());
	}, [setJobSearchText, jobSearchText]);

	const handleSelect = React.useCallback((option: SelectJobFM) => {
		setSelectedJob(option);
	}, []);

	return (
		<CustomModal
			closeModal={closeModal}
			modalStyle="info"
			showModal={showModal}
			size="md"
		>
			<CustomModal.Header
				closeModal={closeModal}
				title="Select Job for Invoicing"
			/>
			<CustomModal.Body>
				<Row className="row--padded-bottom">
					<Col className={descriptionClassName}>
						Select a job for which you would like to create an invoice.
					</Col>
				</Row>
				<Row className="row--padded-bottom">
					<DropdownSelector.default<SelectJobFM>
						defaultValue={selectedJob}
						filterable={true}
						filterBy={filterJobOption}
						fixed={true}
						hasBlankOption={false}
						id={'jobsDropdown'}
						labelKey="jobCode"
						onValueChange={handleSelect}
						options={jobsToSelect}
						renderMenuItem={renderJobMenuItem}
						renderSelected={renderSelectedItem}
						valueKey="id"
						withBorder={true}
						withCaret={true}
					/>
				</Row>
			</CustomModal.Body>
			<CustomModal.Footer>
				<Button
					onClick={closeModal}
					variant="info"
				>
					Cancel
				</Button>
				<SubmitButton
					disabled={!selectedJob}
					label="Continue"
					onClick={goToNewInvoice}
				/>
			</CustomModal.Footer>
		</CustomModal>
	);
};

function mapDispatchToProps() {
	return {
		loadJobs: JobActions.getAllJobCodesForInvoices,
		getUserGroupAndPM: JobActions.getUserGroupAndPM,
	};
}

const connector = connect(null, mapDispatchToProps());

const enhance = compose<React.ComponentClass<OwnProps>>(
	React.memo,
	connector
);

export default enhance(InvoiceJobSelectModal);
