import * as React from 'react';
import { Droppable, Draggable } from 'react-beautiful-dnd';
import { FormControl, FormGroup } from 'react-bootstrap';

import PlainCheckbox from 'af-components/Controls/Checkbox';

import type { ReportBlockVM } from 'ab-viewModels/reportBlock/reportBlockMap.viewModel';
import type ReportBlockMapVM from 'ab-viewModels/reportBlock/reportBlockMap.viewModel';

import { bemBlock, bemElement } from 'ab-utils/bem.util';

import { DroppableZones, CustomBlocks } from 'ab-enums/reportTypeBuilder.enum';

interface OwnProps {
	blocks: ReportBlockMapVM['blockMap'];
}

type Props = OwnProps;

const CUSTOM_BLOCKS = Object.keys(CustomBlocks.CustomBlock);

const ReportTypeCustomTypeFormBlockList: React.FC<Props> = (props: Props) => {
	const { blocks } = props;

	const [search, setSearch] = React.useState<string>('');
	const [showPrimary, setShowPrimary] = React.useState<boolean>(false);
	const [showSecondary, setShowSecondary] = React.useState<boolean>(false);
	const [primaryList, setPrimaryList] = React.useState<Nullable<ReportBlockVM[]>>(null);
	const [secondaryList, setSecondaryList] = React.useState<Nullable<ReportBlockVM[]>>(null);

	React.useEffect(() => {
		const primaryBlocks: ReportBlockVM[] = [];
		const secondaryBlocks: ReportBlockVM[] = [];
		for (const _block of Object.values(blocks)) {
			if (_block.isMain) {
				primaryBlocks.push(_block);
			} else {
				secondaryBlocks.push(_block);
			}
		}

		if (!search.length) {
			setPrimaryList(primaryBlocks);
			setSecondaryList(secondaryBlocks);
		} else {
			const predicate = (_block: ReportBlockVM) => _block.name.toLowerCase().includes(search.toLowerCase());
			setPrimaryList(primaryBlocks.filter(predicate));
			setSecondaryList(secondaryBlocks.filter(predicate));
		}
	}, [blocks, search]);

	const filterByText = React.useCallback((event) => {
		const _search = event.target?.value ?? '';
		setSearch(_search);
	}, []);

	const clearSearch = React.useCallback(() => {
		setSearch('');
	}, []);

	const togglePrimary = React.useCallback(() => {
		setShowPrimary(!showPrimary);
	}, [showPrimary]);

	const toggleSecondary = React.useCallback(() => {
		setShowSecondary(!showSecondary);
	}, [showSecondary]);

	const renderDraggable = (item: ReportBlockVM, index: number) => {
		const className = bemBlock('report-block-form__draggable-label', { main: item.isMain });
		const nameClassName = bemBlock('report-block-form__draggable-label__name', { main: item.isMain });
		const uniqueIdClassName = bemBlock('report-block-form__draggable-label__uniqueId', { main: item.isMain });
		return (
			<Draggable
				draggableId={item.id.toString()}
				index={index}	// important
				key={index}
			>
				{(provided) => (
					<div
						{...provided?.draggableProps}
						{...provided?.dragHandleProps}
						className="report-block-form__draggable-field"
						ref={provided?.innerRef}
					>
						<div className={className}>
							<span className={nameClassName}>{item.name}</span>
							<span className={uniqueIdClassName}>{item.uniqueId}</span>
						</div>
					</div>
				)}
			</Draggable>
		);
	};

	const renderCustomBlock = (block: CustomBlocks.CustomBlock, index: number) => {
		const className = bemBlock('report-block-form__draggable-label', { main: false });

		return (
			<Draggable
				draggableId={CustomBlocks.CustomBlockDroppableId[block]}
				index={index}
				key={CustomBlocks.CustomBlockDroppableId[block]}
			>
				{(provided) => (
					<div
						{...provided?.draggableProps}
						{...provided?.dragHandleProps}
						className="report-block-form__draggable-field"
						ref={provided?.innerRef}
					>
						<span className={className}>{CustomBlocks.CustomBlockLabel[block]}</span>
					</div>
				)}
			</Draggable>
		);
	};

	const renderFilters = () => {
		return (
			<div className="report-block-form__block-list__filter">
				<div className="report-block-form__block-list__filter__search-filter">
					<FormGroup>
						<div className="input-filter">
							<FormControl
								className="search-form"
								onChange={filterByText}
								placeholder="Search"
								value={search}
							/>
							{search.length > 1 &&
								<span className="input-group-append">
									<span className="icon-close" onClick={clearSearch} />
								</span>
							}
						</div>
					</FormGroup>
				</div>
				<div>
					<PlainCheckbox
						handleChange={togglePrimary}
						inline={true}
						isChecked={showPrimary}
						isStandalone={true}
						label="Primary Blocks"
					/>
				</div>
				<div>
					<PlainCheckbox
						handleChange={toggleSecondary}
						inline={true}
						isChecked={showSecondary}
						isStandalone={true}
						label="Secondary Blocks"
					/>
				</div>
			</div>
		);
	};

	const filterApplied = showPrimary || showSecondary;
	return (
		<>
			<div className="report-block-form__title report-block-form__block-list__title">
				Report Block List
			</div>
			{renderFilters()}
			<div className="report-block-form report-block-form__block-list__list">
				{(!filterApplied || showPrimary) && (
					<Droppable direction="vertical" droppableId={DroppableZones.MAIN_BLOCK_LIST_ID} isDropDisabled={true}>
						{(droppableProvided) => (
							<div className="report-block-form__block_group" ref={droppableProvided.innerRef}>
								{primaryList?.map(renderDraggable)}
								{droppableProvided.placeholder}
							</div>
						)}
					</Droppable>
				)}
				{(!filterApplied || showSecondary) && (
					<Droppable direction="vertical" droppableId={DroppableZones.BLOCK_LIST_ID} isDropDisabled={true}>
						{(droppableProvided) => (
							<div className="report-block-form__block_group" ref={droppableProvided.innerRef}>
								{secondaryList?.map(renderDraggable)}
								{droppableProvided.placeholder}
							</div>
						)}
					</Droppable>
				)}
				<div className={bemElement('report-block-form', 'title', ['padding-top'])}>
					Custom
				</div>
				<Droppable direction="vertical" droppableId={DroppableZones.CUSTOM_BLOCK_LIST_ID} isDropDisabled={true}>
					{(droppableProvided) => (
						<div className="report-block-form__block_group" ref={droppableProvided.innerRef}>
							{CUSTOM_BLOCKS.map(renderCustomBlock)}
							{droppableProvided.placeholder}
						</div>
					)}
				</Droppable>
			</div>
		</>
	);
};

export default React.memo(ReportTypeCustomTypeFormBlockList);
