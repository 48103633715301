import * as React from 'react';
import type { WrappedFieldArrayProps } from 'redux-form';
import { Field } from 'redux-form';

import Textarea from 'af-fields/Textarea';

import type JobUpsertFM from '../../../formModel';
import { formSectionClassColumn6, formSectionClassRow } from '../../../helpers';

import styles from '../../../styles.module.scss';

type Props = WrappedFieldArrayProps<JobUpsertFM['permitsRequired']>;

const Permits: React.FC<Props> = (props) => {
	const { fields } = props;

	const onAddClick = React.useCallback(() => {
		fields.push(null);
	}, [fields]);

	const onRemoveClick = React.useCallback((index: number) => () => fields.remove(index), [fields]);

	const renderPermit = (_permit: string, _index: number) => {
		return (
			<div className={formSectionClassRow} key={_permit}>
				<div className={formSectionClassColumn6}>
					<Field
						component={Textarea}
						maxCharacters={300}
						name={_permit}
						placeholder="Enter Text"
						rows={1}
						showMaxCharactersLabel={true}
						type="text"
					/>
				</div>
				<div className={styles['job-form__delete-button']}>
					<span className="icon-delete" onClick={onRemoveClick(_index)} />
				</div>
			</div>
		);
	};

	return <>
		{fields.map(renderPermit)}
		<div
			className={styles['job-form__add-field-button']}
			onClick={onAddClick}
		>
			<span className="icon-plus" />
			<span>
				Add Permit
			</span>
		</div>
	</>;
};

export default Permits;
