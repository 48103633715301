import * as React from 'react';
import { compose } from 'redux';
import type { History } from 'history';

import { getShortDayName } from 'acceligent-shared/utils/time';
import * as TimeUtils from 'acceligent-shared/utils/time';

import TimePeriodRecurrence from 'acceligent-shared/enums/timePeriodRecurrence';
import WorkOrderStatus from 'acceligent-shared/enums/workOrderStatus';

import { TableQuery } from 'ab-common/dataStructures/tableQuery';

import { WorkOrderTableViewModel } from 'ab-viewModels/workOrderTable.viewModel';

import type TableNameEnum from 'ab-enums/tableName.enum';
import TableButtonType from 'ab-enums/tableButtonType.enum';
import BrowserStorageEnum from 'ab-enums/browserStorage.enum';

import { formatDecimalNumber } from 'ab-utils/formatting.util';

import { downloadCSV } from 'af-utils/csv.utils';
import * as SettingsUtils from 'af-utils/settings.util';
import { setItemWithFormatter, setItem } from 'af-utils/settings.util';

import * as SettingsKeys from 'af-constants/settingsKeys';

import EmptyCell from 'af-components/Table6/Cells/EmptyCell';
import type { Column, TabProps } from 'af-components/Table6';
import Table from 'af-components/Table6';
import type TableComponent from 'af-components/Table6/Table';
import ResourceCell from 'af-components/Table6/Cells/ResourceCell';
import DateFilter from 'af-components/DateFilter';
import type InfiniteScroll from 'af-components/ScrollToLoad';
import type ScrollToLoad from 'af-components/ScrollToLoad';
import LabelWithColor from 'af-components/LabelWithColor';

import CLIENT from 'af-routes/client';

interface SettingProps {
	startDate: Date;
	endDate: Date;
	period: TimePeriodRecurrence;
}

interface OwnProps {
	csvName: string;
	fetchWorkOrders: (tableRequestModel: TableQuery, startDate: Date, endDate: Date) => Promise<TableResult<WorkOrderTableViewModel>>;
	tableName: TableNameEnum;
	history: History;
	companyName: string;
	orgAlias: string;
}

type Props = OwnProps & SettingProps;

function setWOStartDate(date: Date = new Date()): void {
	setItemWithFormatter(SettingsKeys.WORK_ORDERS_REPORTS_START_DATE(), date, TimeUtils.formatDate, BrowserStorageEnum.SESSION_STORAGE);
}

function setWOEndDate(date: Date = new Date()): void {
	setItemWithFormatter(SettingsKeys.WORK_ORDERS_REPORTS_END_DATE(), date, TimeUtils.formatDate, BrowserStorageEnum.SESSION_STORAGE);
}

function setWOPeriod(period: TimePeriodRecurrence): void {
	setItem(SettingsKeys.WORK_ORDERS_REPORTS_PERIOD(), period, BrowserStorageEnum.LOCAL_STORAGE);
}

class RelatedWorkOrders extends React.PureComponent<Props> {
	state: SettingProps = {
		startDate: this.props.startDate,
		endDate: this.props.endDate,
		period: this.props.period,
	};

	private _tableRef: Nullable<TableComponent<WorkOrderTableViewModel>> = null;
	private _list: Nullable<ScrollToLoad<WorkOrderTableViewModel>> = null;

	columns: Column<WorkOrderTableViewModel>[] = [
		{
			Header: 'Work Order',
			accessor: 'workOrderCode',
			id: 'code',
			className: 'text-strong',
			width: 220,
			Cell: ({ original }) => (
				< span > {original.code}{original.revision ? `, REV ${original.revision}` : ''}</span >
			),
		},
		{
			Header: 'Job Title',
			accessor: 'title',
			id: 'title',
			width: 140,
			Cell: ({ original }) => (
				<span>{original.jobTitle}</span>
			),
		},
		{
			Header: 'Status',
			accessor: 'status',
			id: 'status',
			width: 100,
			Cell: ({ original }) => {
				switch (original.status) {
					case WorkOrderStatus.CANCELED:
						return <span className="text-red text-strong">{original.status}</span>;
					default:
						return <span className="text-black">{original.status}</span>;
				}
			},
		},
		{
			Header: 'Date of Work',
			accessor: 'dueDate',
			width: 160,
			Cell: ({ original }) => <span>{getShortDayName(original.dueDate)}, {original.dueDate}</span>,
		},
		{
			Header: 'Crew',
			accessor: 'code',
			width: 80,
			Cell: ({ original }) => original.crewNumber,
		},
		{
			Header: 'Crew Type',
			accessor: 'crewType',
			width: 120,
			Cell: ({ original }) => original.crewType ? <LabelWithColor color={original.crewColor} text={original.crewType} /> : <EmptyCell />,
		},
		{
			Header: 'Superintendent',
			accessor: 'supervisor.account.user.firstName',
			width: 125,
			Cell: ({ original }) => original.supervisor?.fullName ?? <EmptyCell />,
		},
		{
			Header: 'Project Manager',
			accessor: 'projectManager.account.user.firstName',
			width: 130,
			Cell: ({ original }) => original.projectManager?.fullName ?? <EmptyCell />,
		},
		{
			Header: 'Revenue',
			accessor: 'revenue',
			width: 100,
			Cell: ({ original }) => original.revenue ? `$ ${formatDecimalNumber(original.revenue)}` : <EmptyCell />,
		},
		{
			Header: 'Man-hours',
			accessor: 'manHourAverage',
			width: 100,
			Cell: ({ original }) => original.manHourAverage ? `$ ${formatDecimalNumber(original.manHourAverage)}` : <EmptyCell />,
		},
		{
			accessor: 'equipmentAndLabor',
			Header: 'Equip./Labor/Temp. Labor',
			className: 'text-center',
			sortable: false,
			width: 100,
			Cell: ({ original }) => {
				return (
					<ResourceCell
						original={original}
					/>
				);
			},
		},
		{
			accessor: 'woLink',
			Header: 'Field Report',
			sortable: false,
			width: 120,
			Cell: ({ original }) => {
				switch (original.status) {
					case WorkOrderStatus.DRAFT:
						return <EmptyCell />;
					default:
						return (
							<div className="work-order-info-modal__report-link" onClick={this.openWoReport.bind(null, original.id)}>
								<div className="work-order-info-modal__icon-text">View</div> < span className="icon-external link-cell__icon" />
							</div >
						);
				}
			},
		},
	];

	openWoReport = (workOrderId: number) => {
		const { history, orgAlias, companyName } = this.props;
		history.push(CLIENT.COMPANY.FIELD_REPORT.ALL_REPORTS(workOrderId.toString(), orgAlias, companyName));
	};

	tabs = (): TabProps<WorkOrderTableViewModel>[] => {
		return [
			{
				label: 'Work Orders',
				columns: this.columns,
				selectable: false,
				hasSearchInput: true,
				additionalFilter: this.renderFilter,
				searchLabel: 'Work Orders',
				fetch: this.fetchWorkOrders,
				buttons: [{
					type: TableButtonType.EXPORT,
					hasPermission: true,
					onClick: this.onDownloadCSVClick,
				}],
			},
		];
	};

	onTableMount = (table: TableComponent<WorkOrderTableViewModel>, list: InfiniteScroll<WorkOrderTableViewModel>) => {
		this._tableRef = table;
		this._list = list;
	};

	fetchWorkOrders = async (tableRequestModel: TableQuery) => {
		const { fetchWorkOrders } = this.props;
		const { startDate, endDate } = this.state;

		return await fetchWorkOrders(tableRequestModel, startDate, endDate);
	};

	onDownloadCSVClick = async () => {
		const { fetchWorkOrders } = this.props;
		const { startDate, endDate } = this.state;
		let { csvName } = this.props;

		const tableRequestModel = new TableQuery({
			pageSize: 0,
			page: 0,
			sortBy: [{ id: 'dueDate', desc: true }, { id: 'code', desc: false }],
			filterByText: '',
			includeAll: true,
		});
		const result = await fetchWorkOrders(tableRequestModel, startDate, endDate);
		const csvData = WorkOrderTableViewModel.toCSVData(result.rows);

		if (!csvName.endsWith('.csv')) {
			csvName = `${csvName}.csv`;
		}

		downloadCSV(csvData, csvName);
	};

	refresh = () => {
		if (this._tableRef) {
			this._tableRef.refreshTable();
		} else if (this._list) {
			this._list.refreshList();
		}
	};

	filterByDate = (startDate: Date, endDate: Date) => {
		this.setState(
			() => ({ startDate, endDate }),
			() => {
				setWOStartDate(startDate);
				setWOEndDate(endDate);
				this.refresh();
			}
		);
	};

	changePeriod = (period: TimePeriodRecurrence, selected: Date) => {
		let startDate: Date | undefined, endDate: Date | undefined;

		const selectedMoment = TimeUtils.parseMoment(selected);
		if (!selectedMoment) {
			throw new Error('Failed to parse selectedMoment');
		}

		switch (period) {
			case TimePeriodRecurrence.MONTHLY:
				startDate = selectedMoment.clone().startOf('month').toDate() ?? null;
				endDate = selectedMoment.clone().endOf('month').toDate() ?? null;
				break;
			case TimePeriodRecurrence.WEEKLY:
				startDate = selectedMoment.clone().startOf('week').toDate() ?? null;
				endDate = selectedMoment.clone().endOf('week').toDate() ?? null;
				break;
			case TimePeriodRecurrence.DAILY:
			case TimePeriodRecurrence.CUSTOM:
			default:
				startDate = selected;
				endDate = selected;
				break;
		}

		this.setState(
			() => ({ period, startDate, endDate }),
			() => {
				setWOStartDate(startDate);
				setWOEndDate(endDate);
				setWOPeriod(period);
				this.refresh();
			}
		);
	};

	renderFilter = () => {
		const { endDate, startDate, period } = this.state;

		return (
			<div className="table-filter field-report-orders-table__filters">
				<DateFilter changePeriod={this.changePeriod} endDate={endDate} onChange={this.filterByDate} period={period} startDate={startDate} />
			</div>
		);
	};

	render() {
		const { tableName } = this.props;

		return (
			<Table
				hideTabs={true}
				onMount={this.onTableMount}
				tableName={tableName}
				tabs={this.tabs()}
			/>
		);
	}
}

const enhance = compose<React.ComponentClass<OwnProps>>(
	SettingsUtils.withSettings<SettingProps>(() => ([
		{
			key: SettingsKeys.WORK_ORDERS_REPORTS_START_DATE(),
			mappedName: 'startDate',
			normalize: TimeUtils.normalizeDateToDate,
			defaultValue: new Date(),
			source: BrowserStorageEnum.LOCAL_STORAGE,
		},
		{
			key: SettingsKeys.WORK_ORDERS_REPORTS_END_DATE(),
			mappedName: 'endDate',
			normalize: TimeUtils.normalizeDateToDate,
			defaultValue: new Date(),
			source: BrowserStorageEnum.LOCAL_STORAGE,
		},
		{
			key: SettingsKeys.WORK_ORDERS_REPORTS_PERIOD(),
			mappedName: 'period',
			defaultValue: TimePeriodRecurrence.DAILY,
			source: BrowserStorageEnum.LOCAL_STORAGE,
		},
	]))
);

export default enhance(RelatedWorkOrders);
