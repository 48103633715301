import * as React from 'react';
import type { ConnectedProps } from 'react-redux';
import { connect } from 'react-redux';
import { Button } from 'react-bootstrap';
import type { Dispatch } from 'redux';
import { compose } from 'redux';
import type { InjectedFormProps } from 'redux-form';
import { reduxForm, formValueSelector, getFormValues } from 'redux-form';

import WorkOrderStatus from 'acceligent-shared/enums/workOrderStatus';
import FormFieldArrayStatusEnum from 'acceligent-shared/enums/formFieldArrayStatus';

import * as TimeUtils from 'acceligent-shared/utils/time';

import type { RootState } from 'af-reducers';

import * as WorkOrderActions from 'af-actions/workOrder';
import * as ScheduleBoardActions from 'af-actions/scheduleBoard';

import { http } from 'af-utils/http.util';
import * as ScheduleBoardUtil from 'af-utils/scheduleBoard.util';
import { isEmptyNumber } from 'af-utils/validation.util';

import { WORK_ORDER_FORM } from 'af-constants/reduxForms';
import ScheduleBoardPropertyEnum from 'ab-enums/scheduleBoardProperty.enum';

import SubmitButton from 'af-components/SubmitButton';
import ConfirmationModal from 'af-components/ConfirmationModal';

import CancelModal from '../Modals/Cancel';
import WorkOrderFM from '../formModel';
import TimeFormat from 'acceligent-shared/enums/timeFormat';

interface OwnProps {
	workOrderId: number | undefined;
	status: WorkOrderStatus;
	hasJob: boolean;
	isCreate: boolean;
	isFetching: boolean;
	resetTimer: () => void;
	redirect: (checkIfFormIsDirty?: boolean) => void;
	isJobChanged: boolean;
}

type Props = OwnProps & ConnectedProps<typeof connector> & InjectedFormProps<WorkOrderFM>;

const enum MODAL_ACTION {
	PUBLISH = 'PUBLISH',
	SAVE = 'SAVE'
}

const CHANGE_JOB_WARNING_HEADER = 'Job change detected';

const WorkOrderFormActions: React.FC<Props> = (props: Props) => {

	const {
		formData,
		status,
		isFinalized,
		redirect,
		workOrderId,
		setPlaceholder,
		startSubmitting,
		stopSubmitting,
		create,
		update,
		save,
		publish,
		isJobChanged,
		isCreate,
		cancellationReason,
		cancel,
		deleteWorkOrder,
		isSubmitting,
		invalid,
		isFetching,
		dirty,
		hasJob,
		resetTimer,
		code,
	} = props;

	const [showCancelModal, setShowCancelModal] = React.useState(false);
	const [showDeleteModal, setShowDeleteModal] = React.useState(false);
	const [showUpdateFieldReportModal, setShowUpdateFieldReportModal] = React.useState(false);
	const [publishing, setPublishing] = React.useState(false);
	const [canceling, setCanceling] = React.useState(false);
	const [saving, setSaving] = React.useState(false);
	const [showChangeJobWarningModal, setShowChangeJobWarningModal] = React.useState(false);
	const [modalAction, setModalAction] = React.useState<Nullable<MODAL_ACTION>>(null);
	const [showMultiActionModal, setShowMultiActionModal] = React.useState(false);

	const openCancelModal = React.useCallback(() => {
		setShowCancelModal(true);
	}, []);

	const closeCancelModal = React.useCallback(() => {
		setShowCancelModal(false);
	}, []);

	const openDeleteModal = React.useCallback(() => {
		setShowDeleteModal(true);
	}, []);

	const closeDeleteModal = React.useCallback(() => {
		setShowDeleteModal(false);
	}, []);

	const openChangeJobWarningModal = React.useCallback(() => {
		setShowChangeJobWarningModal(true);
	}, []);

	const closeChangeJobWarningModal = React.useCallback(() => {
		setShowChangeJobWarningModal(false);
	}, []);

	const closeUpdateFieldReportModal = React.useCallback(() => {
		setShowUpdateFieldReportModal(false);
	}, []);

	const closeMultiActionModal = React.useCallback(() => {
		setShowMultiActionModal(false);
	}, []);

	const wasLookupUpdated = React.useCallback(() => {
		const form = formData;
		return form?.workOrderReportTypeLookup?.find((_lookup) => _lookup.status !== FormFieldArrayStatusEnum.OLD);
	}, [formData]);

	const handleSave = React.useCallback(async (updateExistingReports: boolean) => {
		const form: Nullable<WorkOrderFM> = formData;
		if (!form) {
			return;
		}
		const rm = WorkOrderFM.toRequestModel(form);

		rm.updateExistingReports = updateExistingReports;

		if (!workOrderId) {
			const { dueDate, index } = rm;
			const _code = ScheduleBoardUtil.generatePlaceholderDroppableId(dueDate, ScheduleBoardPropertyEnum.WORK_ORDER);
			setPlaceholder(
				TimeUtils.formatDate(dueDate, TimeFormat.DATE_ONLY, TimeFormat.DB_DATE_ONLY),
				_code,
				!isEmptyNumber(index!) ? index! - 1 : undefined
			);
		}

		startSubmitting(WORK_ORDER_FORM); // used for loading indicator
		if (!workOrderId) {
			await create(rm);
		} else {
			await update(workOrderId, rm);
		}
		save();
		stopSubmitting(WORK_ORDER_FORM);
	}, [create, formData, save, setPlaceholder, startSubmitting, stopSubmitting, update, workOrderId]);

	const saveWorkOrder = React.useCallback(async (updateExistingReports: boolean) => {

		setSaving(true);
		await handleSave(updateExistingReports);
		setSaving(false);
		redirect(false);
	}, [handleSave, redirect]);

	const publishWorkOrder = React.useCallback(async (updateExistingReports: boolean) => {

		if (!workOrderId) {
			throw new Error('No WO to save');
		}

		setPublishing(true);
		await handleSave(updateExistingReports);
		await publish(workOrderId);
		setPublishing(false);
		redirect(false);
	}, [handleSave, publish, redirect, workOrderId]);

	const handlePublish = React.useCallback(async () => {

		if (isJobChanged && !isCreate) {
			setModalAction(MODAL_ACTION.PUBLISH);
			openChangeJobWarningModal();
		} else {
			await publishWorkOrder(false);
		}

	}, [isCreate, isJobChanged, openChangeJobWarningModal, publishWorkOrder]);

	const selectActionModal = React.useCallback(() => {

		const requiresLookupModal = !isFinalized && wasLookupUpdated() && status !== WorkOrderStatus.DRAFT && !isCreate;
		const requiresJobChangedModal = isJobChanged && !isCreate;

		if (requiresLookupModal && requiresJobChangedModal) {
			setShowMultiActionModal(true);
			return true;
		} else if (requiresLookupModal) {
			setShowUpdateFieldReportModal(true);
			return true;
		} else if (requiresJobChangedModal) {
			openChangeJobWarningModal();
			return true;
		} else {
			return false;
		}
	}, [isCreate, isFinalized, isJobChanged, openChangeJobWarningModal, status, wasLookupUpdated]);

	const handleRepublishClick = React.useCallback(async () => {

		setModalAction(MODAL_ACTION.PUBLISH);

		const isModalSelected = selectActionModal();
		if (!isModalSelected) {
			await publishWorkOrder(false);
		}

	}, [publishWorkOrder, selectActionModal]);

	const handleFieldReportUpdate = React.useCallback(async (updateExistingReports: boolean) => {

		if (modalAction === MODAL_ACTION.PUBLISH) {
			await publishWorkOrder(updateExistingReports);
		} else if (modalAction === MODAL_ACTION.SAVE) {
			await saveWorkOrder(updateExistingReports);
		}

	}, [modalAction, publishWorkOrder, saveWorkOrder]);

	const continueWithFieldReportUpdate = React.useCallback(async () => {

		await handleFieldReportUpdate(true);

	}, [handleFieldReportUpdate]);

	const continueWithoutFieldReportUpdate = React.useCallback(async () => {

		await handleFieldReportUpdate(false);

	}, [handleFieldReportUpdate]);

	const handleSaveClick = React.useCallback(async () => {

		setModalAction(MODAL_ACTION.SAVE);

		const isModalSelected = selectActionModal();
		if (!isModalSelected) {
			await saveWorkOrder(false);
		}

	}, [selectActionModal, saveWorkOrder]);

	const handleJobChangeConfirmation = React.useCallback(async () => {

		if (modalAction === MODAL_ACTION.PUBLISH) {
			await publishWorkOrder(false);
		} else if (modalAction === MODAL_ACTION.SAVE) {
			await saveWorkOrder(false);
		}

	}, [saveWorkOrder, modalAction, publishWorkOrder]);

	const handleJobChangeAndLookupChangeConfirmation = React.useCallback(async () => {

		if (modalAction === MODAL_ACTION.PUBLISH) {
			await publishWorkOrder(true);
		} else if (modalAction === MODAL_ACTION.SAVE) {
			await saveWorkOrder(true);
		}
	}, [modalAction, publishWorkOrder, saveWorkOrder]);

	const handleCancel = React.useCallback(async () => {
		if (!workOrderId) {
			throw new Error('No WO to cancel');
		}

		setCanceling(true);
		const date = TimeUtils.formatDate(new Date(), TimeFormat.DATE_ONLY);
		await cancel(workOrderId, { cancellationReason, date });
		setCanceling(false);
		closeCancelModal();
		redirect(false);
	}, [cancel, cancellationReason, closeCancelModal, redirect, workOrderId]);

	const handleDelete = React.useCallback(async () => {
		if (!workOrderId) {
			throw new Error('No WO to delete');
		}

		await deleteWorkOrder(+workOrderId);
	}, [deleteWorkOrder, workOrderId]);

	const CHANGE_JOB_WARNING_BODY = React.useMemo(() => {
		return (
			<>
				Changing the job will not remove current attachments, even if they belong to previous job.
				<br />
				This action will also add attachments from newly selected job.
			</>
		);
	}, []);

	const UPDATE_FIELD_REPORT_MODAL_BODY = React.useMemo(() => {
		return (
			<>
				Changes have been made to how report types should be used. Would You like to also update existing field reports for this work order?
				<br />
				<strong>IMPORTANT:</strong> Proceeding might result in irreversible loss of information
			</>
		);
	}, []);

	const UPDATE_FIELD_REPORT_AND_CHANGE_JOB_WARNING_BODY = React.useMemo(() => {
		return (
			<>
				Changes have been made to how report types should be used. This action will also update existing field reports for this work order.
				<br />
				<strong>IMPORTANT:</strong> Proceeding might result in irreversible loss of information
				<br />
				<br />
				Changing the job will not remove current attachments, even if they belong to previous job. This action will
				also add attachments from newly selected job.
			</>
		);
	}, []);

	const DELETE_MODAL_BODY = React.useMemo(() => {
		return (
			<>
				Are you sure you want to delete this Work Order?
				<br />
				This action cannot be undone.
			</>
		);
	}, []);

	const updateFieldReportModalFooter = React.useMemo(() => {
		return (
			<>
				<Button onClick={closeUpdateFieldReportModal} variant="info">
					Cancel
				</Button>
				<Button onClick={continueWithoutFieldReportUpdate} variant="primary">
					No
				</Button>
				<Button onClick={continueWithFieldReportUpdate} variant="danger">
					Yes
				</Button>
			</>
		);
	}, [closeUpdateFieldReportModal, continueWithFieldReportUpdate, continueWithoutFieldReportUpdate]);

	const renderButtons = React.useMemo(() => {
		if (status === WorkOrderStatus.CANCELED) {
			return null;
		}

		return (
			<div className="work-order-upsert__sticky-actions">
				{!isCreate && status === WorkOrderStatus.DRAFT &&
					<>
						<Button
							disabled={isSubmitting}
							onClick={openDeleteModal}
							variant="info"
						>
							Delete
						</Button>
						<SubmitButton
							disabled={isSubmitting || invalid || isFetching}
							label="Publish"
							onClick={handlePublish}
							reduxFormSubmitting={publishing}
						/>
					</>
				}
				{(status === WorkOrderStatus.PUBLISHED || status === WorkOrderStatus.OUTDATED) &&
					<Button
						disabled={isSubmitting}
						onClick={openCancelModal}
						variant="danger"
					>
						Cancel Order
					</Button>
				}
				{(status === WorkOrderStatus.OUTDATED || (isJobChanged && !isCreate && status !== WorkOrderStatus.DRAFT)) &&
					<SubmitButton
						disabled={isSubmitting || invalid || isFetching}
						label="Republish"
						onClick={handleRepublishClick}
						reduxFormSubmitting={publishing}
					/>
				}
				{(!isJobChanged || isCreate || status === WorkOrderStatus.DRAFT) &&
					<SubmitButton
						className="save-work-order-btn"
						disabled={isSubmitting || isFetching || !dirty || invalid || !hasJob}
						label="Save"
						onClick={handleSaveClick}
						reduxFormSubmitting={saving}
						type="button"
					/>
				}
			</div>
		);
	}, [
		handleRepublishClick,
		dirty,
		hasJob,
		invalid,
		isCreate,
		isFetching,
		isJobChanged,
		isSubmitting,
		openCancelModal,
		openDeleteModal,
		handleSaveClick,
		publishing,
		saving,
		status,
		handlePublish,
	]);

	const renderModals = React.useMemo(() => {
		if (!code) {
			return null;
		}

		return (
			<>
				<CancelModal
					cancel={handleCancel}
					cancellationReason={cancellationReason}
					closeModal={closeCancelModal}
					code={code}
					resetTimer={resetTimer}
					showModal={showCancelModal}
					submitting={canceling}
				/>
				<ConfirmationModal
					body={DELETE_MODAL_BODY}
					closeModal={closeDeleteModal}
					confirmAction={handleDelete}
					confirmText="Delete Work Order"
					modalStyle="danger"
					showModal={showDeleteModal}
					title={`Delete Work Order (${code})?`}
				/>
				<ConfirmationModal
					body={UPDATE_FIELD_REPORT_MODAL_BODY}
					closeModal={closeUpdateFieldReportModal}
					footer={updateFieldReportModalFooter}
					modalStyle="danger"
					showModal={showUpdateFieldReportModal}
					size="md"
					title="Update Existing Field Reports"
				/>
				<ConfirmationModal
					body={CHANGE_JOB_WARNING_BODY}
					closeModal={closeChangeJobWarningModal}
					closeText="Cancel"
					confirmAction={handleJobChangeConfirmation}
					confirmText="I understand"
					modalStyle="warning"
					showModal={showChangeJobWarningModal}
					title={CHANGE_JOB_WARNING_HEADER}
				/>
				<ConfirmationModal
					body={UPDATE_FIELD_REPORT_AND_CHANGE_JOB_WARNING_BODY}
					closeModal={closeMultiActionModal}
					closeText="Cancel"
					confirmAction={handleJobChangeAndLookupChangeConfirmation}
					confirmText="I understand"
					modalStyle="danger"
					showModal={showMultiActionModal}
					size="md"
					title="Update Existing Field Reports and change job"
				/>
			</>
		);
	}, [
		canceling,
		cancellationReason,
		closeCancelModal,
		closeChangeJobWarningModal,
		closeDeleteModal,
		code,
		handleCancel,
		handleDelete,
		resetTimer,
		showCancelModal,
		showChangeJobWarningModal,
		showDeleteModal,
		showUpdateFieldReportModal,
		updateFieldReportModalFooter,
		handleJobChangeConfirmation,
		showMultiActionModal,
		closeMultiActionModal,
		handleJobChangeAndLookupChangeConfirmation,
		closeUpdateFieldReportModal,
		UPDATE_FIELD_REPORT_MODAL_BODY,
		CHANGE_JOB_WARNING_BODY,
		UPDATE_FIELD_REPORT_AND_CHANGE_JOB_WARNING_BODY,
		DELETE_MODAL_BODY,
	]);

	return (
		<>
			{renderButtons}
			{renderModals}
		</>
	);
};

const selector = formValueSelector(WORK_ORDER_FORM);

function mapStateToProps(state: RootState) {
	const { workOrder, http: { submitting } } = state;

	const formData = getFormValues(WORK_ORDER_FORM)(state) as WorkOrderFM;

	return {
		isSubmitting: !!submitting.length,
		code: workOrder.order?.code,
		isFinalized: workOrder.order?.isFinalized,
		cancellationReason: selector(state, 'cancellationReason'),
		formData,
	};
}

function mapDispatchToProps() {
	return {
		publish: WorkOrderActions.publishWorkOrder,
		create: WorkOrderActions.create,
		update: WorkOrderActions.update,
		save: WorkOrderActions.saveWorkOrder,
		cancel: WorkOrderActions.cancelWorkOrder,
		deleteWorkOrder: WorkOrderActions.deleteWorkOrder,
		setPlaceholder: ScheduleBoardActions.setCopiedWorkOrderPlaceholder,
		startSubmitting: (formName: string) => (dispatch: Dispatch) => dispatch(http.startSubmitting(formName)),
		stopSubmitting: (formName: string) => (dispatch: Dispatch) => dispatch(http.endSubmitting(formName)),
	};
}

const connector = connect(mapStateToProps, mapDispatchToProps());

const enhance = compose<React.ComponentClass<OwnProps>>(
	connector,
	reduxForm<WorkOrderFM>({ form: WORK_ORDER_FORM })
);

export default enhance(WorkOrderFormActions);
