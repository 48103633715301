import * as React from 'react';

import type { EquipmentUtilizationViewModelShared, EquipmentUtilizationViewModel } from 'ab-viewModels/equipmentUtilization.viewModel';

import CLIENT from 'af-constants/routes/client';

import type { OwnProps as TableOwnProps, TabProps, Column } from 'af-components/Table6';
import _Table from 'af-components/Table6';
import type TableComponent from 'af-components/Table6/Table';
import MonetaryCell from 'af-components/Table6/Cells/MonetaryCell';
import EmptyCell from 'af-components/Table6/Cells/EmptyCell';
import type ScrollToLoad from 'af-components/ScrollToLoad';

import WorkingCell from '../UtilizationShared/WorkingCell';

const Table = _Table as unknown as React.ComponentClass<TableOwnProps<EquipmentUtilizationViewModel>>;

interface OwnProps {
	tableName: string;
	orgAlias: string;
	companyName: string;
	timePeriodLabel: string;
	redirectTo: (url: string) => void;
	onMount?: (table: TableComponent<EquipmentUtilizationViewModel>, list: ScrollToLoad<EquipmentUtilizationViewModel>) => void;
	getSharedTabProps: () => Omit<TabProps<EquipmentUtilizationViewModelShared>, 'columns'>;
}

type Props = OwnProps;

class EquipmentUtilizationTable extends React.PureComponent<Props> {

	readonly columns: Column<EquipmentUtilizationViewModel>[] = [
		{
			Header: 'Equipment ID',
			accessor: 'code',
			sortable: true,
			Cell: ({ original }) => (
				<span>
					<strong>{original.code}</strong> {original.specification ? <span className="small-label m-l-s">{original.specification}</span> : null}
				</span>
			),
		},
		{
			Header: undefined,	// set in `tabs`
			accessor: 'timePeriodId',
			sortable: true,
			Cell: ({ original }) => original.timePeriodId || <EmptyCell />,
		},
		{
			Header: 'Working',
			accessor: 'daysAssigned',
			sortable: true,
			Cell: WorkingCell,
		},
		{
			Header: 'Daily Revenue',
			accessor: 'dailyRevenue',
			sortable: true,
			Cell: MonetaryCell,
		},
		{
			Header: 'Total revenue',
			accessor: 'totalRevenue',
			sortable: true,
			Cell: MonetaryCell,
		},
		{
			Header: 'Daily Cost',
			accessor: 'dailyCost',
			sortable: true,
			Cell: MonetaryCell,
		},
		{
			Header: 'Total Cost',
			accessor: 'totalCost',
			sortable: true,
			Cell: MonetaryCell,
		},
		{
			Header: 'Target Profit/Loss',
			accessor: 'targetProfit',
			sortable: true,
			Cell: MonetaryCell,
		},
	];

	onRowClick = ({ original }: { original: EquipmentUtilizationViewModel; }) => {
		if (original?.id) {
			this.goToEquipmentPreview(original);
		}
	};

	goToEquipmentPreview = (data: EquipmentUtilizationViewModel) => {
		const { redirectTo, orgAlias, companyName } = this.props;
		redirectTo(CLIENT.COMPANY.EQUIPMENT.PREVIEW(data.id.toString(), orgAlias, companyName));
	};

	goToEquipmentStatusHistory = (data: EquipmentUtilizationViewModel) => {
		const { redirectTo, orgAlias, companyName } = this.props;
		redirectTo(CLIENT.COMPANY.EQUIPMENT.STATUS_HISTORY(data.id.toString(), orgAlias, companyName));
	};

	tabs = (): TabProps<EquipmentUtilizationViewModel>[] => {
		const { getSharedTabProps, timePeriodLabel } = this.props;

		this.columns[1].Header = timePeriodLabel;

		return [{
			...(getSharedTabProps() as Omit<TabProps<EquipmentUtilizationViewModel>, 'columns'>),
			columns: this.columns,
			onRowClick: this.onRowClick,
			rowActions: [
				{
					label: 'Preview',
					action: this.goToEquipmentPreview,
					shouldRefresh: false,
				},
				{
					label: 'Status History',
					action: this.goToEquipmentStatusHistory,
					shouldRefresh: false,
				},
			],
		}];
	};

	render() {
		const { tableName, onMount } = this.props;

		return (
			<Table
				onMount={onMount}
				tableName={tableName}
				tabs={this.tabs()}
			/>
		);
	}
}

export default EquipmentUtilizationTable;
