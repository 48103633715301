import type { TableQuery } from 'ab-common/dataStructures/tableQuery';

import baseRoute from '../baseRoute';

export * from './viewModel';
export * from './queryParams';

type RouteDefinition = `${typeof baseRoute}/${number | ':toolId'}/history${`?query=${string}` | ''}`;

export const ROUTE: RouteDefinition = `${baseRoute}/:toolId/history`;

export const URL = (toolId: number, tableRequestModel: TableQuery): RouteDefinition =>
	`${baseRoute}/${toolId}/history?query=${encodeURIComponent((JSON.stringify(tableRequestModel)))}`;
