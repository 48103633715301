import * as React from 'react';
import type { WrappedFieldArrayProps } from 'redux-form';

import EmailCheckbox from 'af-components/SharedForms/Contacts/ContactMethodPicker/EmailCheckbox';

import type { BillingContact } from '../../EditWhenInvoiced/formModel';

import styles from './styles.module.scss';

export type OwnProps = {
	emails: BillingContact['contact']['emails'];
};

type Props = WrappedFieldArrayProps<BillingContact['contact']['contactEmailIds'][0]> & OwnProps;

const BillingContactEmails: React.FC<Props> = ({ fields, emails }) => {
	const emailIdsSet = React.useMemo(() => new Set(fields.getAll()), [fields]);
	const renderBillingContactEmail = React.useCallback((email, _index: number) => {
		return (
			<EmailCheckbox
				disabled={emails.length === 1}
				email={{ ...email, countryCode: null, extension: null }}
				fields={fields}
				isSelected={emailIdsSet.has(email.id)}
				key={_index}
			/>
		);
	}, [emailIdsSet, emails.length, fields]);

	return (
		<>
			{emails.map(renderBillingContactEmail)}
			{!emailIdsSet.size && (
				<span className={styles['contact-emails-error']}>
					<span className="icon-info" />
					<span>
						At least one email must be selected.
					</span>
				</span>
			)}
		</>
	);
};

export default BillingContactEmails;
