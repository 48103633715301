import * as React from 'react';
import type { InjectedFormProps} from 'redux-form';
import { Field, FieldArray } from 'redux-form';
import { Form, Row, Col } from 'react-bootstrap';

import { weekDays } from 'acceligent-shared/enums/weekDay';

import { getAllHolidaysForNextYears } from 'acceligent-shared/utils/time';

import Checkbox from 'af-fields/Checkbox';

import type { CompanyWorkDaysForm } from 'ab-requestModels/company.requestModel';

import type { CompanyViewModel } from 'ab-viewModels/company.viewModel';
import { HolidayOption } from 'ab-viewModels/holidaySettings.viewModel';

import { uniqueBy } from 'ab-utils/array.util';

import type { OwnProps as HolidayOwnProps } from './Holidays';
import HolidaysComponent from './Holidays';

interface OwnProps extends InjectedFormProps<CompanyWorkDaysForm> {
	onSubmit: (form: CompanyWorkDaysForm) => void;
	formName: string;
	company: CompanyViewModel;
}

type Props = OwnProps;

interface State {
	holidays: HolidayOption[];
}

export default class WorkDaysCompanyForm extends React.PureComponent<Props, State> {

	state: State = {
		holidays: [],
	};

	componentDidMount() {
		const holidays = getAllHolidaysForNextYears(4);
		const holidayNames: HolidayOption[] = Array.from(new Set(holidays.map((h) => new HolidayOption(h.name))));
		const alreadySelected: string[] = this.props.initialValues.holidays?.map((h) => h.name) ?? [];
		const filteredAndDistinct = uniqueBy(
			holidayNames.filter((h) => !alreadySelected.includes(h.name)),
			'name'
		);
		this.setState(() => ({ holidays: filteredAndDistinct }));
	}

	onSubmit = (form: CompanyWorkDaysForm) => {
		const { onSubmit } = this.props;
		onSubmit(form);
	};

	onValueChange = (holiday: HolidayOption) => {
		this.setState(
			(state: State) => ({ holidays: state.holidays.filter((h) => h.name !== holiday.name) })
		);
	};

	removeHoliday = (holiday: HolidayOption) => {
		this.setState((state) => ({ holidays: state.holidays.concat(holiday) }));
	};

	render() {
		const { handleSubmit, error } = this.props;
		const { holidays } = this.state;

		return (
			<Form className="update-workdays" onSubmit={handleSubmit(this.onSubmit)}>
				<Row className="row--padded">
					<Col className="update-workdays__days" md={6}>
						{weekDays.map((_d, _index) => (
							<Field
								component={Checkbox}
								inline={true}
								isStandalone={true}
								key={_index}
								label={_d}
								name={_d.toLowerCase()}
								type="text"
							/>
						))}
					</Col>
					<Col md={18}>
						<FieldArray<HolidayOwnProps>
							component={HolidaysComponent}
							name="holidays"
							onHolidayRemove={this.removeHoliday}
							onValueChange={this.onValueChange}
							options={holidays}
						/>
					</Col>
				</Row>
				{error && <span className="help-block"><span className="icon-info" /> {error}</span>}
			</Form>
		);
	}
}
