import * as React from 'react';

import type { ReportSidebarInfo} from './helpers';
import { sidebarReportsMapper } from './helpers';

interface Props {
	sidebarReportsInfo: Nullable<ReportSidebarInfo[]>;
	reportsRefs: React.MutableRefObject<HTMLDivElement[]>;
	currentReportIndex: Nullable<number>;
	noOfSignedReports: Nullable<number>;
}

const Sidebar: React.FC<Props> = (props) => {
	const { sidebarReportsInfo, reportsRefs, currentReportIndex, noOfSignedReports } = props;

	const numberOfReports = sidebarReportsInfo?.length;

	const onSidebarReportClick = (index: number) => {
		return () => reportsRefs.current[index].scrollIntoView();
	};

	const areAllSigned = (noOfSignedReports === numberOfReports);

	return (
		<div className="public-report__sidebar">
			<div className={`public-report__sidebar__signed-number ${areAllSigned ? 'text-green' : ''}`}>
				<>
					Signed: <span>{`${noOfSignedReports} / ${numberOfReports}`}</span>
				</>
			</div>
			{currentReportIndex !== null && (
				<div className="public-report__sidebar__title">
					{`Report: ${currentReportIndex + 1} / ${numberOfReports}`}
				</div>
			)}
			{sidebarReportsInfo?.map(sidebarReportsMapper(currentReportIndex, onSidebarReportClick))}
		</div>
	);
};

export default Sidebar;
