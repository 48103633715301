import * as React from 'react';
import type { Table } from '@tanstack/react-table';

import type TableSettingsRequestModel from 'ab-requestModels/tableSettings.requestModel';

import type { DropdownOptionType } from 'af-components/Controls/Dropdown';
import Dropdown from 'af-components/Controls/Dropdown';

import styles from './styles.module.scss';

interface RowsPerPageDropdown extends DropdownOptionType {
	label: number;
}

interface Props<T> {
	table: Table<T>;
	updateTableSettings: (settings: TableSettingsRequestModel) => Promise<void>;
	tableSettings: Nullable<TableSettingsRequestModel>;
	pageSize?: number;
	pageCount: number;
	savePageNumber: (pageNumber: number) => void;
}

const PAGE_SIZES = [5, 10, 20, 25, 50, 100, 500];

function PaginationFooter<T,>(props: React.PropsWithChildren<Props<T>>) {
	const { table, tableSettings, updateTableSettings, pageSize, pageCount, savePageNumber } = props;

	const onPageChange = React.useCallback((e) => {
		const page = Number(e.target.value) - 1;
		table.setPageIndex(page);
		savePageNumber(page);
	}, [savePageNumber, table]);

	const onPreviousClick = React.useCallback(() => {
		if (table.getCanPreviousPage()) {
			savePageNumber(table.getState().pagination.pageIndex - 1);
		}
		table.previousPage();
	}, [savePageNumber, table]);

	const onNextClick = React.useCallback(() => {
		if (table.getCanNextPage()) {
			savePageNumber(table.getState().pagination.pageIndex + 1);
		}
		table.nextPage();
	}, [savePageNumber, table]);

	const pageSizeOptions: RowsPerPageDropdown[] = React.useMemo(() => PAGE_SIZES.map((_ps) => {
		return {
			disabled: false,
			onClick: async () => {
				if (!tableSettings) return;
				table.setPageSize(Number(_ps));
				updateTableSettings({ ...tableSettings, pageSize: Number(_ps) });
			},
			label: _ps,
		};
	}), [table, tableSettings, updateTableSettings]);

	const selectedOption = React.useMemo(() => pageSizeOptions.find((_pgo) => _pgo.label === pageSize), [pageSize, pageSizeOptions]);

	const pageCountToShow = React.useMemo(() => {
		if (pageCount === 0 || isNaN(pageCount)) {
			return 1;
		}

		return pageCount;
	}, [pageCount]);

	return (
		<div className={styles['pagination-footer']}>
			Rows per page
			<Dropdown
				className={styles['pagination-footer__dropdown']}
				defaultValue={selectedOption}
				labelKey="label"
				options={pageSizeOptions}
				withBorder={true}
				withCaret={true}
			/>
			Page
			<input
				onChange={onPageChange}
				type="number"
				value={table.getState().pagination.pageIndex + 1}
			/>
			of {pageCountToShow}
			<button
				className={!table.getCanPreviousPage() ? styles.disabled : undefined}
				disabled={!table.getCanPreviousPage()}
				onClick={onPreviousClick}
			>
				<span className="icon-left" />
			</button>
			<button
				className={!table.getCanNextPage() ? styles.disabled : undefined}
				disabled={!table.getCanNextPage()}
				onClick={onNextClick}
			>
				<span className="icon-right" />
			</button>
		</div>
	);
}

export default PaginationFooter;
