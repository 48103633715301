import * as React from 'react';

import type { SkillViewModel } from 'ab-viewModels/skill.viewModel';

import type { MultiTagOptionProps } from 'af-fields/MultiTagSelect';

import Tag from 'af-components/Controls/Tag';

type Props = MultiTagOptionProps<SkillViewModel>;

const SkillOption = (props: Props) => {
	const { data, innerProps, getStyles } = props;

	return (
		<div style={getStyles('option', props)} {...innerProps}>
			<Tag color={data.color} size="small" transparent>
				<span>{data.name}</span>
			</Tag>
		</div>
	);
};

export default SkillOption;
