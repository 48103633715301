import * as React from 'react';
import { compose } from 'redux';
import type { ConnectedProps } from 'react-redux';
import { connect } from 'react-redux';

import type { RootState } from 'af-reducers';

import EquipmentDroppable from 'af-root/scenes/Company/ScheduleBoard/DailyView/EquipmentDroppable';

import ScheduleBoardContext from 'ab-enums/scheduleBoardContext.enum';
import ScheduleBoardProperty from 'ab-enums/scheduleBoardProperty.enum';

import { UNKNOWN_LOCATION_NICKNAME } from 'ab-common/constants/value';

import * as ScheduleBoardUtil from 'af-utils/scheduleBoard.util';

interface OwnProps {
	dueDate: string;
	available: boolean;
	groupTitle: Nullable<string>;
	groupId: number;
	hasReason?: boolean;
	isDeleted: boolean;
	isDragAndDropDisabled: boolean;
	hasPermissionsToEditScheduleBoard: boolean;
}

type Props = OwnProps & ConnectedProps<typeof connector>;

const EquipmentGroup: React.FC<Props> = (props) => {
	const {
		equipmentIds = [],
		dueDate,
		isDeleted,
		hasArrow,
		available = true,
		groupTitle = UNKNOWN_LOCATION_NICKNAME,
		groupId,
		hasReason = false,
		isDragAndDropDisabled,
		hasPermissionsToEditScheduleBoard,
		disabled,
	} = props;

	if (isDeleted && equipmentIds.length === 0) {
		return null;
	}

	return (
		<div className="schedule-board-toolbar-equipment-group" key={groupId}>
			{
				hasArrow &&
				<div className="schedule-board-arrow-container">
					<div className={`schedule-board-arrow ${hasArrow ? '--visible' : '--invisible'}`} />
				</div>
			}
			<div className="group-title">
				{
					available ?
						<img src="/images/elements/ic_state_complete.svg" /> :
						<img src="/images/elements/ic_state_incomplete.svg" />
				}
				<span>{groupTitle}</span>
			</div>
			<hr />
			<EquipmentDroppable
				disabled={disabled || isDeleted}
				draggablePrefix={ScheduleBoardContext.TOOLBAR}
				droppableId={ScheduleBoardUtil.generateDroppableId(ScheduleBoardContext.TOOLBAR, ScheduleBoardProperty.EQUIPMENT, dueDate, `${groupId}`, undefined, available)}
				dueDate={dueDate}
				equipmentIds={equipmentIds}
				hasPermissionsToEditScheduleBoard={hasPermissionsToEditScheduleBoard}
				hasReason={hasReason}
				isDragAndDropDisabled={isDragAndDropDisabled}
				isToolbar={true}
			/>
		</div>
	);
};

function mapStateToProps(state: RootState, ownProps: OwnProps) {
	const { dueDate, available, groupId } = ownProps;
	const { equipment, workOrdersByDateDictionary } = state.scheduleBoard;

	const availability = ScheduleBoardUtil.getAvailabilityLabel(available);
	const equipmentIds = workOrdersByDateDictionary[dueDate]?.toolbarEquipment[availability][groupId];

	const hasArrow = equipmentIds?.some((_id) => equipment?.[_id]?.isMatched) ?? false;

	return {
		disabled: !state.scheduleBoard.draggedEquipmentId,
		hasArrow,
		equipmentIds: equipmentIds?.filter((_equId) => equipment?.[_equId]?.showOnScheduleBoard ?? false) ?? [],
	};
}

const connector = connect(mapStateToProps);

const enhance = compose<React.ComponentType<OwnProps>>(
	React.memo,
	connector
);

export default enhance(EquipmentGroup);
