import * as React from 'react';
import { Button, Table } from 'react-bootstrap';
import type { ResolveThunks } from 'react-redux';
import { connect } from 'react-redux';

import TimeFormat from 'acceligent-shared/enums/timeFormat';

import * as TimeOptionUtil from 'acceligent-shared/utils/timeOption';
import * as TimeUtils from 'acceligent-shared/utils/time';

import type { RootState } from 'af-reducers';

import SubmitButton from 'af-components/SubmitButton';
import TableHeader from 'af-components/NotifyModal/TableHeader';
import EmployeeList from 'af-components/NotifyModal/EmployeeList';

import * as ScheduleBoardActions from 'af-actions/scheduleBoard';

import CustomModal from 'af-components/CustomModal';

import type { EmployeeNotifyModalState } from 'af-models/employeeNotifyModal.model';

import type { NOTIFY_EMPLOYEE_TYPE } from 'af-constants/values';

import type { NotificationStatusByEmployee } from 'ab-viewModels/notification.viewModel';

interface OwnProps {
	/** MM-DD-YYYY */
	dueDate: string;
	autoNotifyAt: Nullable<number>;
	autoNotifyUnassignedEmployees: boolean;
	closeModal: () => void;
	sendNotification: (dueDate: string, notifyByEmail: number[], notifyBySms: number[]) => void;
	scheduleAutoNotify: (dueDate: string, notifyByEmail: number[], notifyBySms: number[]) => void;
}

interface StateProps {
	showModal: boolean;
	hasAlreadyNotifiedEmployees: boolean;
	employees: EmployeeNotifyModalState[];
	submitDisabled: boolean;
	emailNumber: number;
	smsNumber: number;
	participantsEmailAndSmsStatus: NotificationStatusByEmployee;
}

interface DispatchProps {
	resetAllInAvailableEmployeeModal: typeof ScheduleBoardActions.resetAllInAvailableEmployeeModal;
	checkAllInAvailableEmployeeModal: typeof ScheduleBoardActions.checkAllInAvailableEmployeeModal;
	checkEmployeeInAvailableEmployeeModal: typeof ScheduleBoardActions.checkEmployeeInAvailableEmployeeModal;
	refreshAvailableEmployeesNotificationModal: typeof ScheduleBoardActions.refreshAvailableEmployeesNotificationModal;
}

type Props = StateProps & OwnProps & ResolveThunks<DispatchProps>;

interface State {
	isSending: boolean;
}

class NotifyParticipantsModal extends React.Component<Props, State> {
	state: State = {
		isSending: false,
	};

	componentDidUpdate(nextProps: Props) {
		if (nextProps.showModal && nextProps.participantsEmailAndSmsStatus !== this.props.participantsEmailAndSmsStatus) {
			this.props.refreshAvailableEmployeesNotificationModal();
			this.setState(() => ({ isSending: false }));
			this.resetState();
		}
	}

	resetState = () => {
		const { resetAllInAvailableEmployeeModal, dueDate } = this.props;
		resetAllInAvailableEmployeeModal(dueDate);
		this.setState(() => ({ isSending: false }));
	};

	onCancel = () => {
		this.resetState();
		this.props.closeModal();
	};

	onSubmit = () => {
		const { employees, sendNotification, dueDate } = this.props;

		this.setState(() => ({ isSending: true }));
		const sendEmailToIds = employees.filter((_e) => _e.shouldSendEmail).map((_e) => _e.id);
		const sendSMSToIds = employees.filter((_e) => _e.shouldSendSms).map((_e) => _e.id);
		sendNotification(dueDate, sendEmailToIds, sendSMSToIds);
		this.onCancel();
	};

	onSchedule = () => {
		const { employees, scheduleAutoNotify, dueDate } = this.props;

		this.setState(() => ({ isSending: true }));
		const sendEmailToIds = employees.filter((_e) => _e.shouldSendEmail).map((_e) => _e.id);
		const sendSMSToIds = employees.filter((_e) => _e.shouldSendSms).map((_e) => _e.id);
		scheduleAutoNotify(dueDate, sendEmailToIds, sendSMSToIds);
		this.onCancel();
	};

	checkAll = (value: boolean, type: NOTIFY_EMPLOYEE_TYPE) => {
		const { checkAllInAvailableEmployeeModal } = this.props;
		checkAllInAvailableEmployeeModal({ value, type });
		this.setState(() => ({ isSending: false }));

	};

	onCheck = (employeeId: number, type: NOTIFY_EMPLOYEE_TYPE) => {
		const { checkEmployeeInAvailableEmployeeModal } = this.props;
		checkEmployeeInAvailableEmployeeModal({ employeeId, type });
	};

	render() {
		const {
			showModal,
			dueDate,
			autoNotifyAt,
			employees,
			hasAlreadyNotifiedEmployees,
			autoNotifyUnassignedEmployees, submitDisabled,
			emailNumber,
			smsNumber,
		} = this.props;
		const { isSending } = this.state;

		const subtitle = `Notify unassigned available employees on ${dueDate}`;
		const sendLabel = hasAlreadyNotifiedEmployees ? 'Resend' : 'Send';
		const dateBefore = TimeUtils.parseMoment(dueDate, TimeFormat.DATE_ONLY)?.subtract(1, 'day')?.format(TimeFormat.DATE_ONLY_SHORT_YEAR);

		return (
			<CustomModal
				closeModal={this.onCancel}
				modalStyle="info"
				showModal={showModal}
				size="xl"
			>
				<CustomModal.Header
					closeModal={this.onCancel}
					title={subtitle}
				/>
				<CustomModal.Body>
					<Table>
						<TableHeader checkAll={this.checkAll} showSubscribedColumns={!autoNotifyUnassignedEmployees} />
						<tbody>
							<EmployeeList
								employees={employees}
								isSending={isSending}
								onCheck={this.onCheck}
								showSubscribedColumns={!autoNotifyUnassignedEmployees}
								subscribedAt={`${dateBefore}, ${TimeOptionUtil.format(autoNotifyAt)}`}
							/>
						</tbody>
					</Table>
					<div className="question">
						Send <b><span className="text-orange">{emailNumber}</span></b> e-mail notifications
						and <b><span className="text-orange">{smsNumber}</span></b> SMS notifications to free laborers?
					</div>
					{autoNotifyUnassignedEmployees
						? <div>Automatic notifications will be sent at {TimeOptionUtil.format(autoNotifyAt)}, {dateBefore}</div>
						: <></>
					}
				</CustomModal.Body>
				<CustomModal.Footer>
					<Button
						id="accqa__schedule-board__notify__cancel"
						onClick={this.onCancel}
						variant="info"
					>
						Close
					</Button>
					<SubmitButton
						disabled={submitDisabled || isSending}
						id="accqa__schedule-board__notify__send"
						label={isSending ? 'Sending' : sendLabel}
						onClick={this.onSubmit}
					/>
					{!autoNotifyUnassignedEmployees &&
						<SubmitButton
							disabled={submitDisabled || isSending}
							id="accqa__schedule-board__notify__schedule-send"
							label={`Notify at ${dateBefore}, ${TimeOptionUtil.format(autoNotifyAt)}`}
							onClick={this.onSchedule}
						/>}
				</CustomModal.Footer>
			</CustomModal>
		);
	}
}

function mapStateToProps(state: RootState, ownProps: OwnProps): StateProps {
	const { workOrdersByDateDictionary, availableEmployeesNotificationModal } = state.scheduleBoard;
	const { employees: employeesDict, employeeIds, isOpen, hasAlreadyNotifiedEmployees } = availableEmployeesNotificationModal;
	const { dueDate } = ownProps;
	const { availableNotificationStatuses } = workOrdersByDateDictionary[dueDate];

	const employees = employeeIds.map((_empId) => employeesDict[_empId]);
	let submitDisabled = true;
	let emailNumber = 0;
	let smsNumber = 0;
	employees.forEach((_e) => {
		if (_e.shouldSendEmail || _e.shouldSendSms) {
			submitDisabled = false;
		}

		if (_e.shouldSendEmail) {
			emailNumber += 1;
		}

		if (_e.shouldSendSms) {
			smsNumber += 1;
		}
	});

	return {
		employees,
		hasAlreadyNotifiedEmployees,
		participantsEmailAndSmsStatus: availableNotificationStatuses,
		showModal: isOpen,
		submitDisabled,
		emailNumber,
		smsNumber,
	};
}

function mapDispatchToProps(): DispatchProps {
	return {
		resetAllInAvailableEmployeeModal: ScheduleBoardActions.resetAllInAvailableEmployeeModal,
		checkAllInAvailableEmployeeModal: ScheduleBoardActions.checkAllInAvailableEmployeeModal,
		checkEmployeeInAvailableEmployeeModal: ScheduleBoardActions.checkEmployeeInAvailableEmployeeModal,
		refreshAvailableEmployeesNotificationModal: ScheduleBoardActions.refreshAvailableEmployeesNotificationModal,
	};
}

export default connect<StateProps, DispatchProps, OwnProps>(mapStateToProps, mapDispatchToProps())(NotifyParticipantsModal);
