import type { AnyAction, Dispatch } from 'redux';

import type { GetRootState } from 'af-reducers';

import { errorHandler } from 'af-utils/actions.util';
import { http } from 'af-utils/http.util';

import * as GetAllocatedWorkSummaryDetailsAPI from 'ab-api/web/workSummary/getAllocatedWorkSummaryDetails';
import * as AssignGroupNameAPI from 'ab-api/web/workSummary/assignGroupName';
import * as AssignBillingCodeAPI from 'ab-api/web/workSummary/assignBillingCode';
import * as UpdateBillableStatusAPI from 'ab-api/web/workSummary/updateBillableStatus';
import * as BulkUpdateToNonBillableAPI from 'ab-api/web/workSummary/bulkUpdateToNonBillable';
import * as ReallocateToSubjobAPI from 'ab-api/web/workSummary/reallocateToSubjob';
import * as ReallocateAllBillableAPI from 'ab-api/web/workSummary/reallocateAllBillable';
import * as ReallocateAllNonBillableAPI from 'ab-api/web/workSummary/reallocateAllNonBillable';

export function updateBillingStatuses(workSummaryDetailsRM: UpdateBillableStatusAPI.W_WorkSummary_UpdateBillableStatus_RM) {
	return async (dispatch: Dispatch<AnyAction>, getState: GetRootState, { redirectTo }) => {

		const action = async () => {
			await http.put<void>(UpdateBillableStatusAPI.URL(), workSummaryDetailsRM);
		};

		return await errorHandler(action, dispatch, redirectTo);
	};
}

export function bulkUpdateBillingStatuses(data: BulkUpdateToNonBillableAPI.W_WorkSummary_BulkUpdateToNonBillable_RM) {
	return async (dispatch: Dispatch<AnyAction>, getState: GetRootState, { redirectTo }) => {

		const action = async () => {
			await http.put<void>(BulkUpdateToNonBillableAPI.URL(), data);
		};

		return await errorHandler(action, dispatch, redirectTo);
	};
}

export function updateGroupNames(workSummaryDetailsAssignGroupNameRM: AssignGroupNameAPI.W_WorkSummary_AssignGroupName_RM) {
	return async (dispatch: Dispatch<AnyAction>, getState: GetRootState, { redirectTo }) => {

		const action = async () => {
			await http.put<void>(AssignGroupNameAPI.URL(), workSummaryDetailsAssignGroupNameRM);
		};

		return await errorHandler(action, dispatch, redirectTo);
	};
}

export function assignBillingCode(workSummaryWithBillingCode: AssignBillingCodeAPI.W_WorkSummary_AssignBillingCode_RM) {
	return async (dispatch: Dispatch<AnyAction>, getState: GetRootState, { redirectTo }) => {

		const action = async () => {
			await http.put<void>(AssignBillingCodeAPI.URL(), workSummaryWithBillingCode);
		};

		return await errorHandler(action, dispatch, redirectTo);
	};
}

export function findAllocatedWorkSummaryDetails(jobId: number, workOrderId: number) {
	return async (dispatch: Dispatch<AnyAction>, getState: GetRootState, { redirectTo }) => {

		const action = async () => {
			const result = await http.get<GetAllocatedWorkSummaryDetailsAPI.W_WorkSummary_GetAllocatedWorkSummaryDetails_VM>(
				GetAllocatedWorkSummaryDetailsAPI.URL(jobId, workOrderId)
			);

			return result.details;
		};

		return await errorHandler(action, dispatch, redirectTo);
	};
}

export function reallocateToSubJob(data: ReallocateToSubjobAPI.W_WorkSummary_ReallocateToSubjob_RM) {
	return async (dispatch: Dispatch<AnyAction>, getState: GetRootState, { redirectTo }) => {

		const action = async () => {
			await http.put<void>(ReallocateToSubjobAPI.URL(), data);
		};

		return await errorHandler(action, dispatch, redirectTo);
	};
}

export function reallocateAllBillableToSubJob(data: ReallocateAllBillableAPI.W_WorkSummary_ReallocateAllBillable_RM) {
	return async (dispatch: Dispatch<AnyAction>, getState: GetRootState, { redirectTo }) => {

		const action = async () => {
			await http.put<void>(ReallocateAllBillableAPI.URL(), data);
		};

		return await errorHandler(action, dispatch, redirectTo);
	};
}

export function reallocateAllNonBillableToSubJob(data: ReallocateAllNonBillableAPI.W_WorkSummary_ReallocateAllNonBillable_RM) {
	return async (dispatch: Dispatch<AnyAction>, getState: GetRootState, { redirectTo }) => {

		const action = async () => {
			await http.put<void>(ReallocateAllNonBillableAPI.URL(), data);
		};

		return await errorHandler(action, dispatch, redirectTo);
	};
}
