import * as React from 'react';
import { Button, Table } from 'react-bootstrap';

import AutoNotifyOption from 'acceligent-shared/enums/autoNotifyOption';
import type { ExtendedColorValue } from 'acceligent-shared/enums/color';
import WorkOrderStatus from 'acceligent-shared/enums/workOrderStatus';
import TimeFormat from 'acceligent-shared/enums/timeFormat';

import * as TimeOption from 'acceligent-shared/utils/timeOption';
import * as TimeUtils from 'acceligent-shared/utils/time';

import SubmitButton from 'af-components/SubmitButton';
import TableHeader from 'af-components/NotifyModal/TableHeader';
import ModalFooter from 'af-components/NotifyModal/ModalFooter';
import EmployeeList from 'af-components/NotifyModal/EmployeeList';
import TemporaryEmployeeList from 'af-components/NotifyModal/TemporaryEmployeeList';
import Textarea from 'af-components/Fields/PlainTextArea';
import Checkbox from 'af-components/Controls/Checkbox';
import CustomModal from 'af-components/CustomModal';
import Tooltip from 'af-components/Tooltip';

import Loading from 'af-root/scenes/Company/ScheduleBoard/Shared/LoadingModal';

import type ScheduleBoardWorkOrderViewModel from 'ab-socketModels/viewModels/scheduleBoard/scheduleBoardWorkOrder.viewModel';
import type { ScheduleBoardWorkOrderResourceLookupsViewModel } from 'ab-socketModels/viewModels/scheduleBoard/scheduleBoardResourceLookup.viewModel';
import type { ScheduleBoardEmployeesViewModel } from 'ab-viewModels/scheduleBoardEmployee.viewModel';
import type { ScheduleBoardTemporaryEmployeesVM } from 'ab-viewModels/scheduleBoardTemporaryEmployee.viewModel';
import type { NotificationStatusByEmployee, NotificationStatusByTemporaryEmployee } from 'ab-viewModels/notification.viewModel';
import BasicCrewType from 'ab-common/dataStructures/basicCrewType';

import type { EmployeeNotifyModalState, NotifyModalBaseViewModel, TempEmployeeNotifyModalState} from 'af-models/employeeNotifyModal.model';
import { getNotifyModalEmployeeFromEmployee, getNotifyModalTemporaryEmployeeFromTemporaryEmployee } from 'af-models/employeeNotifyModal.model';

import { getColorBackgroundClass } from 'ab-utils/color.util';

import { NOTIFY_MODAL_COL_SPAN, NOTIFY_EMPLOYEE_TYPE, DAILY_TIP_MAX_LENGTH } from 'af-constants/values';

import { bemBlock, bemElement } from 'ab-utils/bem.util';
import * as ColorUtils from 'ab-utils/color.util';

const SMS_LENGTH_LIMIT = 1550; // it's 1600 but just to be safe we'll set it to 1550

interface OwnProps {
	orders: ScheduleBoardWorkOrderViewModel[];
	canceledOrders: ScheduleBoardWorkOrderViewModel[];
	unpublishedOrders: ScheduleBoardWorkOrderViewModel[];
	showModal: boolean;
	/** `MM-DD-YYYY` */
	dueDate: string;
	isLoading: boolean;
	autoNotifyOnPublish: Nullable<AutoNotifyOption>;
	autoNotifyAt: Nullable<number>;
	dailyTip?: string;
	workOrderResourceLookup: ScheduleBoardWorkOrderResourceLookupsViewModel;
	employeeMap: Nullable<ScheduleBoardEmployeesViewModel>;
	temporaryEmployeeMap: Nullable<ScheduleBoardTemporaryEmployeesVM>;
	notificationStatusByEmployee: NotificationStatusByEmployee;
	notificationStatusByTemporaryEmployee: NotificationStatusByTemporaryEmployee;
	notificationMessageLength: number;
	closeModal: () => void;
	notifyTemporaryLabor: boolean;
	notifyParticipants: (
		workOrderEmployeeIdsForSms: number[],
		workOrderEmployeeIdsForEmail: number[],
		workOrderTemporaryEmployeeIdsForEmail: number[],
		workOrderTemporaryEmployeeIdsForSms: number[],
		message?: string,
		isEdited?: boolean
	) => void;
	scheduleAutoNotify: (
		workOrderEmployeeIdsForSms: number[],
		workOrderEmployeeIdsForEmail: number[],
		workOrderTemporaryEmployeeIdsForEmail: number[],
		workOrderTemporaryEmployeeIdsForSms: number[],
		message?: string
	) => void;
}

type Props = OwnProps;

const renderColorBox = (locationColor: Nullable<string>, locationNickname: Nullable<string>) => {
	if (!locationNickname) {
		return <span className={`color-square ${ColorUtils.getColorBackgroundClass(locationColor, true)}`} />;
	}

	return (
		<Tooltip
			message={locationNickname}
			placement="top"
		>
			<span className={`color-square ${ColorUtils.getColorBackgroundClass(locationColor, true)}`} />
		</Tooltip>
	);
};

const renderOrderHeader = (code: string, title: string, customer: Nullable<string>, color: Nullable<ExtendedColorValue>, isExtraWide: boolean) => {
	const width = isExtraWide ? 4 : 2;
	return (
		<tr className={`order-header ${getColorBackgroundClass(color)}`}>
			<td colSpan={NOTIFY_MODAL_COL_SPAN.CREW + (width * NOTIFY_MODAL_COL_SPAN.SEND_TIME) + (2 * NOTIFY_MODAL_COL_SPAN.CHECKBOX)}>
				<span>{title} | {code} | {customer}</span>
			</td>
		</tr>
	);
};

const findForResend = (employee: EmployeeNotifyModalState | TempEmployeeNotifyModalState) => {
	return (employee.shouldSendEmail && !!employee.lastEmailSentAt) || (employee.shouldSendSms && !!employee.lastSmsSentAt);
};

const resetReducer = <K extends NotifyModalBaseViewModel>(acc: { [T in number]: K }, employee: K) => {
	acc[employee.id] = {
		...employee,
		shouldSendEmail: false,
		shouldSendSms: false,
	};
	return acc;
};

interface NotificationIdsReducer {
	emailIds: number[];
	smsIds: number[];
}

const notificationIdReducer = (acc: NotificationIdsReducer, employee: EmployeeNotifyModalState | TempEmployeeNotifyModalState) => {
	if (employee.shouldSendEmail) {
		acc.emailIds.push(employee.id);
	}
	if (employee.shouldSendSms) {
		acc.smsIds.push(employee.id);
	}
	return acc;
};

const NotifyAllParticipantsModal: React.FC<Props> = (props) => {
	const {
		autoNotifyAt,
		autoNotifyOnPublish,
		canceledOrders,
		closeModal,
		dailyTip = '',
		dueDate,
		employeeMap,
		isLoading,
		notificationMessageLength,
		notificationStatusByEmployee,
		notificationStatusByTemporaryEmployee,
		notifyParticipants,
		orders,
		scheduleAutoNotify,
		showModal,
		temporaryEmployeeMap,
		unpublishedOrders,
		workOrderResourceLookup,
		notifyTemporaryLabor,
	} = props;

	const [employees, setEmployees] = React.useState<{ [T in number]: EmployeeNotifyModalState }>([]);
	const [temporaryEmployees, setTemporaryEmployees] = React.useState<{ [T in number]: TempEmployeeNotifyModalState }>([]);
	const [isSending, setIsSending] = React.useState(false);
	const [isResend, setIsResend] = React.useState(false);
	const [includeTip, setIncludeTip] = React.useState(true);
	const [isMessageEdited, setIsMessageEdited] = React.useState(false);
	const [message, setMessage] = React.useState('');

	React.useEffect(() => {
		setMessage(isMessageEdited ? message : dailyTip);
	}, [dailyTip, isMessageEdited, message]);

	React.useEffect(() => {
		if (!showModal) {
			return;
		}
		if (!orders?.length && !canceledOrders?.length) {
			return;
		}
		let shouldResend = false;
		const {
			employeesState,
			temporaryEmployeesState,
		} = [...orders, ...canceledOrders].reduce((_acc, _order) => {
			const shouldNotifyTempLabor = notifyTemporaryLabor && !_order.excludeTempLaborFromNotify;

			for (const _wor of _order.workOrderResourceLookups) {
				const resource = workOrderResourceLookup?.[_wor];
				if (!!resource?.workOrderEmployeeId && !!resource.employeeId) {
					const employee = employeeMap?.[resource.employeeId];
					const existingEmployee = employees?.[resource.workOrderEmployeeId];
					if (
						(existingEmployee?.shouldSendEmail && existingEmployee?.lastEmailSentAt)
						|| (existingEmployee?.shouldSendSms && existingEmployee?.lastSmsSentAt)
					) {
						shouldResend = true;
					}
					if (employee) {
						_acc.employeesState[resource.workOrderEmployeeId] = {
							...getNotifyModalEmployeeFromEmployee(
								resource.workOrderEmployeeId, employee,
								notificationStatusByEmployee?.[resource.workOrderEmployeeId]
							),
							shouldSendEmail: existingEmployee?.email ? existingEmployee.shouldSendEmail : false,
							shouldSendSms: existingEmployee?.phoneNumber ? existingEmployee.shouldSendSms : false,
						};
					}
				} else if (shouldNotifyTempLabor && !!resource?.workOrderTemporaryEmployeeId && !!resource.temporaryEmployeeId) {
					const temporaryEmployee = temporaryEmployeeMap?.[resource.temporaryEmployeeId];
					const existingTemporaryEmployee = temporaryEmployees?.[resource.workOrderTemporaryEmployeeId];
					if (
						(existingTemporaryEmployee?.shouldSendEmail && existingTemporaryEmployee?.lastEmailSentAt)
						|| (existingTemporaryEmployee?.shouldSendSms && existingTemporaryEmployee?.lastSmsSentAt)
					) {
						shouldResend = true;
					}
					if (temporaryEmployee) {
						_acc.temporaryEmployeesState[resource.workOrderTemporaryEmployeeId] = {
							...getNotifyModalTemporaryEmployeeFromTemporaryEmployee(
								resource.workOrderTemporaryEmployeeId, temporaryEmployee,
								notificationStatusByTemporaryEmployee?.[resource.workOrderTemporaryEmployeeId]
							),
							shouldSendEmail: existingTemporaryEmployee?.email ? existingTemporaryEmployee.shouldSendEmail : false,
							shouldSendSms: existingTemporaryEmployee?.phoneNumber ? existingTemporaryEmployee.shouldSendSms : false,
						};
					}
				}
			}
			return _acc;
		}, {
			employeesState: {},
			temporaryEmployeesState: {},
		} as {
			employeesState: { [T: number]: EmployeeNotifyModalState; };
			temporaryEmployeesState: { [T: number]: TempEmployeeNotifyModalState; };
		});

		setEmployees(employeesState);
		setTemporaryEmployees(temporaryEmployeesState);
		setIsResend(shouldResend);
		// eslint-disable-next-line react-hooks/exhaustive-deps
	}, [
		canceledOrders,
		employeeMap,
		notificationStatusByEmployee,
		notificationStatusByTemporaryEmployee,
		orders,
		temporaryEmployeeMap,
		workOrderResourceLookup,
		showModal,
	]);

	const dateBefore = React.useMemo(() => {
		return TimeUtils.formatDate(TimeUtils.addDays(dueDate, -1, TimeFormat.DATE_ONLY), TimeFormat.DATE_ONLY);
	}, [dueDate]);

	const submitDisabled = React.useMemo(() => {
		return [...Object.values(employees), ...Object.values(temporaryEmployees)].every(((_e) => !_e.shouldSendEmail && !_e.shouldSendSms));
	}, [employees, temporaryEmployees]);

	const {
		emailNumber,
		smsNumber,
	} = React.useMemo(() => {
		return [...Object.values(employees), ...Object.values(temporaryEmployees)].reduce((_acc, _emp) => {
			if (_emp.shouldSendEmail) {
				_acc.emailNumber += 1;
			}
			if (_emp.shouldSendSms) {
				_acc.smsNumber += 1;
			}
			return _acc;
		}, { emailNumber: 0, smsNumber: 0 });
	}, [employees, temporaryEmployees]);

	const resetState = React.useCallback(() => {
		setEmployees(Object.values(employees).reduce(resetReducer, {} as { [T in number]: EmployeeNotifyModalState }));
		setTemporaryEmployees(Object.values(temporaryEmployees).reduce(resetReducer, {} as { [T in number]: TempEmployeeNotifyModalState }));
		setIsResend(false);
		setIsSending(false);
		setMessage(dailyTip ?? '');
	}, [employees, temporaryEmployees, dailyTip]);

	const onHide = React.useCallback(() => {
		resetState();
		closeModal();
	}, [resetState, closeModal]);

	const onSubmit = React.useCallback(async () => {
		setIsSending(true);
		const {
			emailIds: employeeEmailIds,
			smsIds: employeeSmsIds,
		} = Object.values(employees).reduce(notificationIdReducer, { emailIds: [], smsIds: [] });

		const {
			emailIds: temporaryEmployeeEmailIds,
			smsIds: temporaryEmployeeSmsIds,
		} = Object.values(temporaryEmployees).reduce(notificationIdReducer, { emailIds: [], smsIds: [] });

		const _message = includeTip && isMessageEdited ? message : undefined;
		await notifyParticipants(employeeSmsIds, employeeEmailIds, temporaryEmployeeEmailIds, temporaryEmployeeSmsIds, _message, isMessageEdited);
		await onHide();
	}, [employees, temporaryEmployees, includeTip, message, isMessageEdited, notifyParticipants, onHide]);

	const onSchedule = React.useCallback(async () => {
		setIsSending(true);

		const {
			emailIds: employeeEmailIds,
			smsIds: employeeSmsIds,
		} = Object.values(employees).reduce(notificationIdReducer, { emailIds: [], smsIds: [] });

		const {
			emailIds: temporaryEmployeeEmailIds,
			smsIds: temporaryEmployeeSmsIds,
		} = Object.values(temporaryEmployees).reduce(notificationIdReducer, { emailIds: [], smsIds: [] });

		const _message = includeTip && isMessageEdited && message.length ? message : undefined;
		await scheduleAutoNotify(employeeSmsIds, employeeEmailIds, temporaryEmployeeEmailIds, temporaryEmployeeSmsIds, _message);
		await onHide();
	}, [employees, temporaryEmployees, includeTip, isMessageEdited, message, onHide, scheduleAutoNotify]);

	const checkAll = React.useCallback(async (value: boolean, type: NOTIFY_EMPLOYEE_TYPE) => {
		let _isResend = false;

		const _employees = Object.values(employees).reduce((_acc, _e) => {
			if (
				(NOTIFY_EMPLOYEE_TYPE.EMAIL && value && _e.email && _e.lastEmailSentAt)
				|| (NOTIFY_EMPLOYEE_TYPE.SMS && value && _e.phoneNumber && _e.lastSmsSentAt)
			) {
				_isResend = true;
			}
			_acc[_e.id] = {
				..._e,
				shouldSendEmail: type === NOTIFY_EMPLOYEE_TYPE.EMAIL ? (_e.email && value) : _e.shouldSendEmail,
				shouldSendSms: type === NOTIFY_EMPLOYEE_TYPE.SMS ? (_e.phoneNumber && value) : _e.shouldSendSms,
			};
			return _acc;
		}, {});
		const _temporaryEmployees = Object.values(temporaryEmployees).reduce((_acc, _e) => {
			if (
				(NOTIFY_EMPLOYEE_TYPE.EMAIL && value && _e.email && _e.lastEmailSentAt)
				|| (NOTIFY_EMPLOYEE_TYPE.SMS && value && _e.phoneNumber && _e.lastSmsSentAt)
			) {
				_isResend = true;
			}
			_acc[_e.id] = {
				..._e,
				shouldSendEmail: type === NOTIFY_EMPLOYEE_TYPE.EMAIL ? (_e.email && value) : _e.shouldSendEmail,
				shouldSendSms: type === NOTIFY_EMPLOYEE_TYPE.SMS ? (_e.phoneNumber && value) : _e.shouldSendSms,
			};
			return _acc;
		}, {});
		setEmployees(_employees);
		setTemporaryEmployees(_temporaryEmployees);
		setIsResend(_isResend);
	}, [employees, temporaryEmployees]);

	const onEmployeeCheck = React.useCallback((employeeId: number, type: NOTIFY_EMPLOYEE_TYPE) => {
		setEmployees({
			...employees,
			[employeeId]: {
				...employees[employeeId],
				shouldSendEmail: type === NOTIFY_EMPLOYEE_TYPE.EMAIL
					? !employees[employeeId].shouldSendEmail
					: employees[employeeId].shouldSendEmail,
				shouldSendSms: type === NOTIFY_EMPLOYEE_TYPE.SMS ? !employees[employeeId].shouldSendSms : employees[employeeId].shouldSendSms,
			},
		});
		setIsResend(!Object.values(employees).find(findForResend));
	}, [employees]);

	const onTemporaryEmployeeCheck = React.useCallback((temporaryEmployeeId: number, type: NOTIFY_EMPLOYEE_TYPE) => {
		setTemporaryEmployees({
			...temporaryEmployees,
			[temporaryEmployeeId]: {
				...temporaryEmployees[temporaryEmployeeId],
				shouldSendEmail: type === NOTIFY_EMPLOYEE_TYPE.EMAIL
					? !temporaryEmployees[temporaryEmployeeId].shouldSendEmail
					: temporaryEmployees[temporaryEmployeeId].shouldSendEmail,
				shouldSendSms: type === NOTIFY_EMPLOYEE_TYPE.SMS
					? !temporaryEmployees[temporaryEmployeeId].shouldSendSms
					: temporaryEmployees[temporaryEmployeeId].shouldSendSms,
			},
		});
		setIsResend(!Object.values(temporaryEmployees).find(findForResend));
	}, [temporaryEmployees]);

	const editMessage = React.useCallback((msg: string) => {
		if (msg.length < DAILY_TIP_MAX_LENGTH) {
			setMessage(msg);
			setIsMessageEdited(msg !== dailyTip);
		}
	}, [dailyTip]);

	const toggleTip = React.useCallback(() => {
		setIncludeTip(!includeTip);
	}, [includeTip]);

	const renderWorkOrder = React.useCallback((_order: ScheduleBoardWorkOrderViewModel, _index: number) => {
		const {
			code,
			title,
			customer,
			workOrderResourceLookups,
			supervisor,
			crewTypeColor,
			isInternal,
		} = _order;

		if (!supervisor) {
			throw new Error('Supervisor not defined');
		}
		const { firstName, lastName, locationColor, locationNickname } = supervisor;

		const {
			employeeList,
			temporaryEmployeeList,
		} = workOrderResourceLookups.reduce((_acc, _wor) => {
			if (workOrderResourceLookup?.[_wor]?.workOrderEmployeeId) {
				_acc.employeeList.push(employees[workOrderResourceLookup[_wor].workOrderEmployeeId!]);
			} else if (workOrderResourceLookup?.[_wor]?.workOrderTemporaryEmployeeId) {
				_acc.temporaryEmployeeList.push(temporaryEmployees[workOrderResourceLookup[_wor].workOrderTemporaryEmployeeId!]);
			}
			return _acc;
		}, {
			employeeList: [],
			temporaryEmployeeList: [],
		} as { employeeList: EmployeeNotifyModalState[]; temporaryEmployeeList: TempEmployeeNotifyModalState[]; });

		const isExtraWide = autoNotifyOnPublish !== AutoNotifyOption.DEFAULT_TIME;
		const width = isExtraWide ? 4 : 2;

		const _crewTypeColor = isInternal
			? BasicCrewType.internal(null).color
			: (crewTypeColor ?? BasicCrewType.UNDEFINED.color);
		return (
			<tbody key={_index}>
				{renderOrderHeader(code, title, customer, _crewTypeColor, isExtraWide)}
				<tr>
					<td className="crew-cell" colSpan={NOTIFY_MODAL_COL_SPAN.CREW}>
						<strong>Supervisor</strong>
					</td>
					<td colSpan={(width * NOTIFY_MODAL_COL_SPAN.SEND_TIME) + (2 * NOTIFY_MODAL_COL_SPAN.CHECKBOX)} />
				</tr>
				<tr className="non-notifiable-row">
					<td className={bemBlock('crew-cell')} colSpan={NOTIFY_MODAL_COL_SPAN.CREW}>
						<div className={`role ${bemElement('crew-cell', 'crew-member')}`}>
							{renderColorBox(locationColor ?? null, locationNickname ?? null)}
							<span className="m-l-s">{firstName} {lastName}</span>
						</div>
					</td>
					<td className="crew-cell" colSpan={100 - NOTIFY_MODAL_COL_SPAN.CREW}>
						<div>
							<span>Cannot notify superintendents</span>
						</div>
					</td>
				</tr>
				<tr>
					<td className="crew-cell" colSpan={NOTIFY_MODAL_COL_SPAN.CREW}>
						<strong>Employees</strong>
					</td>
					<td colSpan={(width * NOTIFY_MODAL_COL_SPAN.SEND_TIME) + (2 * NOTIFY_MODAL_COL_SPAN.CHECKBOX)} />
				</tr>
				<EmployeeList
					employees={employeeList}
					isSending={isSending}
					onCheck={onEmployeeCheck}
					showSubscribedColumns={autoNotifyOnPublish !== AutoNotifyOption.DEFAULT_TIME}
					subscribedAt={`${dateBefore}, ${TimeOption.format(autoNotifyAt)}`}
				/>
				<TemporaryEmployeeList
					employees={temporaryEmployeeList}
					isSending={isSending}
					onCheck={onTemporaryEmployeeCheck}
					showSubscribedColumns={autoNotifyOnPublish !== AutoNotifyOption.DEFAULT_TIME}
					subscribedAt={`${dateBefore}, ${TimeOption.format(autoNotifyAt)}`}
				/>
			</tbody>
		);
	}, [
		autoNotifyAt,
		autoNotifyOnPublish,
		employees,
		isSending,
		dateBefore,
		temporaryEmployees,
		workOrderResourceLookup,
		onEmployeeCheck,
		onTemporaryEmployeeCheck,
	]);

	const renderInvalidWorkOrder = React.useCallback((_order: ScheduleBoardWorkOrderViewModel, _index: number) => {
		const {
			code,
			title,
			customer,
			crewTypeColor,
			isInternal,
			status,
		} = _order;
		const isExtraWide = autoNotifyOnPublish !== AutoNotifyOption.DEFAULT_TIME;
		const width = isExtraWide ? 4 : 2;

		const _crewTypeColor = isInternal
			? BasicCrewType.internal(null).color
			: (crewTypeColor ?? BasicCrewType.UNDEFINED.color);
		return (
			<tbody key={_index}>
				{renderOrderHeader(code, title, customer, _crewTypeColor, isExtraWide)}
				<tr>
					<td colSpan={NOTIFY_MODAL_COL_SPAN.CREW + (width * NOTIFY_MODAL_COL_SPAN.SEND_TIME) + (2 * NOTIFY_MODAL_COL_SPAN.CHECKBOX)}>
						Cannot notify employees on {status === WorkOrderStatus.DRAFT ? 'draft' : 'outdated'} Work Order
					</td>
				</tr>
			</tbody>
		);
	}, [autoNotifyOnPublish]);

	const renderWarning = React.useCallback(() => {
		const tip = message.length ? message : dailyTip;
		const messageLength = includeTip ? notificationMessageLength + (tip?.length ?? 0) : notificationMessageLength;

		if (messageLength <= SMS_LENGTH_LIMIT) {
			return null;
		}

		return (
			<div className="text-red">
				Some messages could exceed 1600 characters and SMS may not be sent. Try reducing daily tip or work order notes.
			</div>
		);
	}, [dailyTip, includeTip, message, notificationMessageLength]);

	const renderDataModalContent = React.useCallback(() => {

		const sendLabel = isResend ? 'Resend' : 'Send';

		return (
			<>
				<CustomModal.Header
					closeModal={onHide}
					title="Notify Participants"
				/>
				<CustomModal.Body>
					<Table>
						<TableHeader checkAll={checkAll} showSubscribedColumns={autoNotifyOnPublish !== AutoNotifyOption.DEFAULT_TIME} />
						{orders.map(renderWorkOrder)}
						{canceledOrders.map(renderWorkOrder)}
						{unpublishedOrders.map(renderInvalidWorkOrder)}
					</Table>
				</CustomModal.Body>
				<div className="daily-tip-modal resizable-container">
					<div className="content-form">
						<Checkbox
							handleChange={toggleTip}
							inline={false}
							isChecked={includeTip}
							isStandalone={true}
							label="Include Custom Message with Notification (daily tip is used by default)"
						/>
						<Textarea
							disabled={!includeTip}
							maxCharacters={DAILY_TIP_MAX_LENGTH}
							onValueChange={editMessage}
							placeholder="Enter Message"
							rows={2}
							showMaxCharactersLabel={true}
							value={message}
						/>
					</div>
					<div className="content-footer">
						<ModalFooter
							description="of work orders scheduled"
							dueDate={dueDate}
							numberOfMails={emailNumber}
							numberOfSms={smsNumber}
						/>
						{autoNotifyOnPublish === AutoNotifyOption.DEFAULT_TIME &&
							<div>Automatic notifications will be sent at {TimeOption.format(autoNotifyAt)}, {dateBefore}</div>
						}
						{renderWarning()}
					</div>
				</div>
				<CustomModal.Footer>
					<Button
						id="accqa__schedule-board__notify__cancel"
						onClick={onHide}
						variant="info"
					>
						Close
					</Button>
					<SubmitButton
						disabled={submitDisabled || isSending}
						id="accqa__schedule-board__notify__send"
						label={isSending ? 'Sending' : sendLabel}
						onClick={onSubmit}
					/>
					{autoNotifyOnPublish !== AutoNotifyOption.DEFAULT_TIME &&
						<SubmitButton
							disabled={submitDisabled || isSending}
							id="accqa__schedule-board__notify__schedule-send"
							label={`Notify at ${dateBefore}, ${TimeOption.format(autoNotifyAt)}`}
							onClick={onSchedule}
						/>}
				</CustomModal.Footer>
			</>
		);
	}, [
		autoNotifyAt,
		autoNotifyOnPublish,
		checkAll,
		editMessage,
		includeTip,
		isResend,
		isSending,
		message,
		dueDate,
		onHide,
		onSchedule,
		dateBefore,
		onSubmit,
		orders,
		renderInvalidWorkOrder,
		submitDisabled,
		renderWarning,
		renderWorkOrder,
		toggleTip,
		canceledOrders,
		unpublishedOrders,
		emailNumber,
		smsNumber,
	]);

	const renderLoadingModalContent = React.useCallback(() => {
		return (
			<Loading onHide={closeModal} />
		);
	}, [closeModal]);

	if (!orders || !showModal) {
		return null;
	}

	return (
		<CustomModal
			closeModal={onHide}
			modalStyle="info"
			showModal={showModal}
			size="xl"
		>
			{(isLoading && !isSending) ? renderLoadingModalContent() : renderDataModalContent()}
		</CustomModal>
	);
};

export default React.memo(NotifyAllParticipantsModal);
