import * as React from 'react';
import { Badge } from 'react-bootstrap';

import type { ContactMethodModel } from 'ab-viewModels/scheduleBoardWorkOrderModal.viewModel';

import UI_LABELS from 'af-constants/uiLabels';

import { formatPhoneNumber } from 'ab-utils/phone.util';

import LockedValue from 'af-components/LockedValue';

interface Props {
	phones: ContactMethodModel[];
	removedPhoneDictionary?: { [phoneValue: string]: boolean; };
}

class SiteContactPhoneNumberList extends React.Component<Props> {
	static defaultProps: Partial<Props> = {
		phones: [],
		removedPhoneDictionary: {},
	};

	render() {
		const { phones, removedPhoneDictionary } = this.props;

		return (
			phones.length > 0 &&
			<div className="work-order-info-modal__site-contact-info-container">
				<LockedValue
					label="Mobile Phone"
					value={phones.map((_phone, _index) => (
						<div
							className={`work-order-info-modal__site-contact-info ${removedPhoneDictionary?.[_phone.value] ? 'work-order-info-modal__site-contact-info--removed' : ''}`}
							key={`siteContactPhones#${_index}`}
						>
							<Badge>{UI_LABELS.PHONE_TYPES[_phone.type]}</Badge>
							{formatPhoneNumber(_phone.value, _phone.countryCode ?? null)}
						</div>
					))}
				/>
			</div>
		);
	}
}

export default SiteContactPhoneNumberList;
