import * as React from 'react';
import type { CellContext } from '@tanstack/react-table';

import TimeFormatEnum from 'acceligent-shared/enums/timeFormat';
import type ToolRepairTrackingRecordStatusDetails from 'acceligent-shared/enums/toolRepairTrackingRecordStatusDetails';
import { ToolRepairTrackingRecordStatusDetailsLabel } from 'acceligent-shared/enums/toolRepairTrackingRecordStatusDetails';

import { formatDate } from 'acceligent-shared/utils/time';

import type { TrackingRecordVM } from 'ab-viewModels/toolRepair/preview.viewModel';
import type ToolRepairVM from 'ab-viewModels/toolRepair/preview.viewModel';

import SimpleTableControl from 'af-components/Controls/SimpleTable';
import type { SimpleTableProps } from 'af-components/Controls/SimpleTable/types';

import styles from './styles.module.scss';
import StatusLabel from '../Shared/StatusLabel';

type OwnProps = {
	toolRepair: ToolRepairVM;
};

type Props = OwnProps;

const TrackingHistorySection = (props: Props) => {
	const {
		toolRepair,
	} = props;

	const renderStatusCell = React.useCallback((_cell: CellContext<TrackingRecordVM, string>) => {
		const status = _cell.row.original.status;

		return <StatusLabel forTable={true} status={status} />;
	}, []);

	const renderStatusValidCell = React.useCallback((_cell: CellContext<TrackingRecordVM, number>) => {
		const statusValidFrom = formatDate(_cell.row.original.validFrom, TimeFormatEnum.FULL_DATE);
		const statusValidTo = _cell.row.original.validTo
			? formatDate(_cell.row.original.validTo, TimeFormatEnum.FULL_DATE)
			: null;

		return (
			<div className={styles['valid-cell-container']}>
				<div className={styles['grey-text']}>from</div>
				<div className={styles['date-value']}>{statusValidFrom}</div>
				{
					!!statusValidTo &&
					<>
						<div className={styles['grey-text']}>to</div>
						<div className={styles['date-value']}>{statusValidTo}</div>
					</>
				}
			</div>
		);
	}, []);

	const columns: SimpleTableProps<TrackingRecordVM>['columns'] = React.useMemo(() => [{
		id: 'status',
		cell: renderStatusCell,
		header: 'Status',
		accessor: 'status',
		enableSorting: false,
		size: 130,
	},
	{
		id: 'statusDetails',
		cell: (_cell: CellContext<TrackingRecordVM, ToolRepairTrackingRecordStatusDetails>) => {
			return <div className={styles['table-text']}>{ToolRepairTrackingRecordStatusDetailsLabel[_cell.getValue()]}</div>;
		},
		header: 'Status Details',
		accessor: 'statusDetails',
		enableSorting: false,
		size: 100,
	},
	{
		id: 'statusValid',
		cell: renderStatusValidCell,
		header: 'Status Valid',
		accessor: 'statusValid',
		enableSorting: false,
		size: 300,
	},
	{
		id: 'locationName',
		cell: (_cell: CellContext<TrackingRecordVM, number>) => {
			return <div className={styles['table-text']}>{_cell.getValue()}</div>;
		},
		header: 'Location',
		accessor: 'locationName',
		enableSorting: false,
	},
	], [renderStatusCell, renderStatusValidCell]);

	const rows = React.useMemo(() => {
		return toolRepair.trackingRecords.sort((a, b) => new Date(b.validFrom).getTime() - new Date(a.validFrom).getTime());
	}, [toolRepair.trackingRecords]);

	return (
		<div className={styles.section}>
			<div className={styles['section-title']}>Repair Tracking History</div>
			<SimpleTableControl columns={columns} rows={rows} />
		</div>
	);
};

export default React.memo(TrackingHistorySection);
