import type { WorkOrderTableViewModel } from 'ab-viewModels/workOrderTable.viewModel';
import type JobHazardAssessmentPreviewVM from 'ab-viewModels/workRequest/jobHazardAssessmentPreview.viewModel';
import type { PreviewBillingContactVM } from 'ab-viewModels/workRequest/workRequestPreviewDetails.viewModel';

import type TableComponent from 'af-components/Table6/Table';
import type InfiniteScroll from 'af-components/ScrollToLoad';
import type { TabProps } from 'af-components/Table6';
import type { JobOutletContext } from 'af-components/SharedPreviews/WorkRequest/Details/types';

export type JobPreviewOutletContext = JobOutletContext & {
	jobId: number;
	jobHazardAssessment: JobHazardAssessmentPreviewVM;
	onTableMount: (table: TableComponent<WorkOrderTableViewModel>, list: InfiniteScroll<WorkOrderTableViewModel>) => void;
	relatedWorkOrdersTableTabs: TabProps<WorkOrderTableViewModel>[];
	jobCode: string;
	billingContact: Nullable<PreviewBillingContactVM>;
	isSendingInvoiceNotificationsEnabled: boolean;
};

export enum JobPreviewTabRoutes {
	DETAILS = 'details',
	BILLING_CODES = 'billingCodes',
	JOB_HAZARD_ASSESSMENT = 'jobHazardAssessment',
	ATTACHMENTS = 'attachments',
	RELATED_WORK_ORDERS = 'relatedWorkOrders',
	WORK_SUMMARY = 'workSummary',
	INVOICES = 'invoices',
}
