import type { Dispatch, AnyAction } from 'redux';
import { saveAs } from 'file-saver';

import type { TableContent } from 'ab-common/dataStructures/tableContent';

import * as TimeUtils from 'acceligent-shared/utils/time';

import TimeFormat from 'acceligent-shared/enums/timeFormat';

import API from 'af-constants/routes/api';
import { DOWNLOAD_LOGS } from 'af-constants/reduxForms';

import { TableQuery } from 'ab-common/dataStructures/tableQuery';

import type { LogViewModel } from 'ab-viewModels/log.viewModel';

import { http } from 'af-utils/http.util';
import { errorHandler } from 'af-utils/actions.util';

import type { GetRootState } from 'af-reducers';

import type { LogFileViewModel } from 'ab-viewModels/logFileViewModel';

export function findById(logId: string) {
	return async (dispatch: Dispatch<AnyAction>, getState: GetRootState, { redirectTo }) => {

		const action = async () => {
			return await http.get<LogViewModel>(API.V1.LOGS.FIND_BY_ID(logId));
		};
		return await errorHandler(action, dispatch, redirectTo);
	};
}

export function findAllForCompanyTable(tableRequestModel: TableQuery) {
	return async (dispatch: Dispatch<AnyAction>, getState: GetRootState, { redirectTo }) => {

		const action = async () => {
			const data = new TableQuery(tableRequestModel);
			return await http.get<TableContent<LogViewModel>>(API.V1.LOGS.COMPANY_TABLE(data));
		};

		return await errorHandler(action, dispatch, redirectTo);
	};
}

export function findAllForOrganizationTable(tableRequestModel: TableQuery) {
	return async (dispatch: Dispatch<AnyAction>, getState: GetRootState, { redirectTo }) => {

		const action = async () => {
			const data = new TableQuery(tableRequestModel);
			return await http.get<TableContent<LogViewModel>>(API.V1.LOGS.ORGANIZATION_TABLE(data));
		};

		return await errorHandler(action, dispatch, redirectTo);
	};
}

export function downloadForCompany(filename: string, startDate: Date, endDate: Date) {
	return async (dispatch: Dispatch<AnyAction>, getState: GetRootState, { redirectTo }) => {

		const action = async () => {
			const startDateString = TimeUtils.formatDate(startDate, TimeFormat.DB_DATE_ONLY);
			const endDateString = TimeUtils.formatDate(endDate, TimeFormat.DB_DATE_ONLY);

			const data = await http.get(API.V1.LOGS.COMPANY_DOWNLOAD(startDateString, endDateString), { responseType: 'blob', submitting: DOWNLOAD_LOGS });
			saveAs(data, filename);
		};

		return await errorHandler(action, dispatch, redirectTo);
	};
}

export function downloadForOrganization(filename: string) {
	return async (dispatch: Dispatch<AnyAction>, getState: GetRootState, { redirectTo }) => {

		const action = async () => {
			const data = await http.get(API.V1.LOGS.ORGANIZATION_DOWNLOAD, { responseType: 'blob' });
			saveAs(data, filename);
		};

		return await errorHandler(action, dispatch, redirectTo);
	};
}

export function findLastBucketEntry() {
	return async (dispatch: Dispatch<AnyAction>, getState: GetRootState, { redirectTo }) => {

		const action = async () => {
			return await http.get<LogFileViewModel>(API.V1.LOGS.FIND_LAST_BUCKET_ENTRY);
		};
		return await errorHandler(action, dispatch, redirectTo);
	};
}
