import * as React from 'react';

import { AdditionalColors } from 'acceligent-shared/enums/color';
import TimeFormat from 'acceligent-shared/enums/timeFormat';
import WorkOrderStatusEnum from 'acceligent-shared/enums/workOrderStatus';

import * as TimeUtils from 'acceligent-shared/utils/time';

import { DISPLAY_VIEW_NUMBER_OF_RESOURCES_IN_WORK_ORDER_COLUMN } from 'af-constants/values';

import * as ColorUtils from 'ab-utils/color.util';
import { isFirstRevision } from 'ab-utils/scheduleBoard.util';
import type { EmployeeNightShiftAssignmentMapVM, TemporaryEmployeeNightShiftAssignmentMapVM } from 'ab-utils/nightShift.util';

import type { DisplayViewWorkOrderViewModel } from 'ab-viewModels/scheduleBoardDisplayView.viewModel';

import StatusIcon from 'af-components/StatusIcon';
import Tooltip from 'af-components/Tooltip';

import Resources from './Resources';
import NightShiftBadge from '../../../Shared/NightShiftBadge';
import { isEqualShallow } from 'acceligent-shared/utils/extensions';

const DEFAULT_EMPLOYEE_NIGHT_SHIFT_ASSIGNMENTS = {};
const DEFAULT_TEMPORARY_EMPLOYEE_NIGHT_SHIFT_ASSIGNMENTS = {};

interface Props {
	employeeNightShiftAssignments?: EmployeeNightShiftAssignmentMapVM;
	hideIndex: boolean;
	hideMultipleAssignmentBadges: boolean;
	notificationsEnabled: boolean;
	temporaryEmployeeNightShiftAssignments?: TemporaryEmployeeNightShiftAssignmentMapVM;
	workOrder: DisplayViewWorkOrderViewModel;
}

interface State {
	nightShiftWorkStartDateFormatted: Nullable<string>;
	nightShiftWorkEndDateFormatted: Nullable<string>;
}

class WorkOrderCard extends React.PureComponent<Props, State> {

	state: State = {
		...(WorkOrderCard._computeNightShiftDates(this.props).result ?? { nightShiftWorkStartDateFormatted: null, nightShiftWorkEndDateFormatted: null }),
	};

	static _computeNightShiftDates = (props: Props, prevProps?: Props) => {
		if (prevProps && isEqualShallow(props, prevProps, ['workOrder'])) {
			return { hasChanged: false, result: null };
		}

		const { dueDate, timeToStart, timeToEnd } = props.workOrder;

		const daysToAdd = timeToStart && timeToEnd && timeToStart > timeToEnd ? 1 : 0;
		const nightShiftWorkEndDate = TimeUtils.addDays(TimeUtils.parseDate(dueDate, TimeFormat.DB_DATE_ONLY), daysToAdd);
		const nightShiftWorkStartDateFormatted = TimeUtils.formatDate(dueDate, TimeFormat.SHORT_DATE, TimeFormat.DB_DATE_ONLY);
		const nightShiftWorkEndDateFormatted = TimeUtils.formatDate(nightShiftWorkEndDate, TimeFormat.SHORT_DATE);

		return {
			hasChanged: true,
			result: {
				nightShiftWorkStartDateFormatted: nightShiftWorkStartDateFormatted ?? null,
				nightShiftWorkEndDateFormatted: nightShiftWorkEndDateFormatted ?? null,
			},
		};
	};

	componentDidUpdate(prevProps: Readonly<Props>): void {
		const { hasChanged, result } = WorkOrderCard._computeNightShiftDates(this.props, prevProps);
		if (hasChanged) {
			this.setState(() => result);
		}
	}

	getHeaderBackgroundColorClassName = (): string => {
		const { isInternal, crewTypeColor } = this.props.workOrder;

		if (isInternal) {
			return '';
		}

		return crewTypeColor
			? ColorUtils.getColorBackgroundClass(crewTypeColor)
			: ColorUtils.getColorBackgroundClass(AdditionalColors.GREY);
	};

	getCardCode = (): JSX.Element => {
		const { workOrder } = this.props;
		const { isInternal, customCrewType, codeStripped, revision, statusBasedRevision } = workOrder;

		const isDraft = workOrder.status === WorkOrderStatusEnum.DRAFT;

		const cardCode: Nullable<string> = isInternal
			? customCrewType
			: `${codeStripped}${revision && !isDraft ? ` REV ${revision}` : ''}${statusBasedRevision ? `.${statusBasedRevision}` : ''}`;

		return (
			<span title={cardCode ?? ''}>
				{cardCode}
			</span>
		);
	};

	renderClassNameIf = (show: boolean, className: string): string => {
		return show ? className : '';
	};

	getNumberOfColumnsForResources = (): number => {
		const { workOrder } = this.props;

		const numberOfResources = workOrder?.resources?.length;

		if (!numberOfResources) {
			return 1;
		}

		return Math.ceil(numberOfResources / DISPLAY_VIEW_NUMBER_OF_RESOURCES_IN_WORK_ORDER_COLUMN);
	};

	render() {
		const {
			workOrder,
			notificationsEnabled,
			hideIndex,
			hideMultipleAssignmentBadges,
			employeeNightShiftAssignments,
			temporaryEmployeeNightShiftAssignments,
		} = this.props;
		const { nightShiftWorkEndDateFormatted, nightShiftWorkStartDateFormatted } = this.state;

		const {
			index,
			customer,
			jobTitle,
			location,
			projectManager,
			supervisor,
			notes,
			resources,
			status,
			revision,
			statusIconNotificationStatus,
			isInternal,
			isNightShift,
			workStart,
			workEnd,
			isPaused,
			jobStatusColor,
			jobStatusName,
			isUnion,
			isPrevailingWage,
			projectJobCode,
		} = workOrder;

		const isWorkOrderCanceled = workOrder.status === WorkOrderStatusEnum.CANCELED;

		return (
			<div className={`display-view-work-order-wrapper-width-${this.getNumberOfColumnsForResources()}`}>
				{!hideIndex && <div className="display-view-card-index">{index + 1}</div>}
				<div className="display-view-card">
					<div className={`${this.renderClassNameIf(!!projectJobCode, 'project')}`}>
						<div className={`display-view-card-order-info ${this.renderClassNameIf(isInternal, 'internal-card')}`}>
							<div className={`display-view-card-crew ${this.getHeaderBackgroundColorClassName()}`}>
								{
									!!jobStatusColor &&
									<div className="display-view-card-job-status" >
										<Tooltip
											message={`${jobStatusName}`}
											placement="top-start"
										>
											<div className="display-view-card-job-tooltip" style={{ backgroundColor: jobStatusColor ?? undefined }} />
										</Tooltip>
									</div>
								}
								{
									!isWorkOrderCanceled &&
									<div className="display-view-card-status">
										<div className="display-board-card-status-icon">
											<StatusIcon
												firstRevision={isFirstRevision(revision)}
												isPaused={isPaused}
												notificationsEnabled={notificationsEnabled}
												notificationStatus={statusIconNotificationStatus}
												status={status}
											/>
										</div>
									</div>
								}
								<div className="display-view-card-code ellipsed">
									{this.getCardCode()}
								</div>
								{
									!!projectJobCode &&
									<div className="display-view-card-project-code">
										{projectJobCode}
									</div>
								}
							</div>
							{
								(isUnion || isPrevailingWage) &&
								<div
									className="display-view-card-flags"
								>
									{
										isUnion &&
										<div className="display-view-card-flag">U</div>
									}
									{
										isPrevailingWage &&
										<div className="display-view-card-flag">PW</div>
									}
								</div>
							}
							{
								isWorkOrderCanceled
									?
									<div className="display-board-card-canceled ellipsed">
										<span>CANCELED</span>
									</div>
									:
									<div className="display-view-card-address ellipsed">
										<div className="display-view-card-address-content">
											<span>{customer}</span>
											<br />
											<span>{jobTitle}</span>
											<br />
											<span>{location}</span>
										</div>
									</div>
							}
							<div className="display-view-card-supervisor ellipsed">
								{`${projectManager} / ${supervisor}`}
							</div>
							<div className="display-view-card-shop-work">
								<div className="shift-item centered">
									{isNightShift && <NightShiftBadge />}
									{!isNightShift ? (
										<span className="display-view-card-shift ellipsed">
											{workStart} - {workEnd}
										</span>
									) : (
										<>
											<div className="display-view-card-shift ellipsed">
												{nightShiftWorkStartDateFormatted} {workStart}
											</div>
											<div className="display-view-card-shift ellipsed">
												{nightShiftWorkEndDateFormatted} {workEnd}
											</div>
										</>
									)}
								</div>
							</div>
						</div>
						<Resources
							employeeNightShiftAssignments={employeeNightShiftAssignments ?? DEFAULT_EMPLOYEE_NIGHT_SHIFT_ASSIGNMENTS}
							hideMultipleAssignmentBadges={hideMultipleAssignmentBadges}
							resources={resources}
							temporaryEmployeeNightShiftAssignments={
								temporaryEmployeeNightShiftAssignments ?? DEFAULT_TEMPORARY_EMPLOYEE_NIGHT_SHIFT_ASSIGNMENTS
							}
						/>
						{
							notes &&
							<div className="display-view-card-notes">
								{notes}
							</div>
						}
					</div>
				</div>
			</div>
		);
	}
}

export default WorkOrderCard;
