import * as React from 'react';
import type { ResolveThunks } from 'react-redux';
import { connect } from 'react-redux';

import { ColorHex } from 'acceligent-shared/enums/color';
import BlobStorageImageSizeContainer, { getImageSize } from 'acceligent-shared/enums/blobStorageImageSizeContainer';

import * as BlobStorageUtil from 'acceligent-shared/utils/blobStorage';

import * as AttachmentActions from 'af-actions/attachment';

import { DEFAULT_IMAGE_PLACEHOLDER } from 'ab-common/constants/value';

interface OwnProps {
	className?: string;
	fallbackSrc?: string;
	minSize?: BlobStorageImageSizeContainer;
	onClick?: (event: React.MouseEvent<HTMLImageElement, MouseEvent>) => void;
	src: Nullable<string>;
	tryOriginal?: boolean;
	tryRoot?: boolean;
}

interface DispatchProps {
	getPresignedGetUrl: typeof AttachmentActions.getPresignedGetUrl;
	resizeImage: typeof AttachmentActions.resizeImage;
}

interface State {
	imgSources: string[];
	style?: {
		height: number;
		width: number;
		backgroundColor: string;
	};
}

type Props = OwnProps & ResolveThunks<DispatchProps>;

class ImageTag extends React.PureComponent<Props, State> {

	static defaultProps: Partial<Props> = {
		tryOriginal: false,
		tryRoot: false,
		fallbackSrc: DEFAULT_IMAGE_PLACEHOLDER,
	};

	static LAZY_LOADING = { loading: 'lazy' } as const;

	state: State = {
		imgSources: [], // max length = 3, src, originalSrc and fallbackSrc
	};

	_imageRef = React.createRef<HTMLImageElement>();
	_image: Nullable<HTMLImageElement> = null;
	_fallbackImageAttempt: Nullable<string> = null;
	_currentImageIndex: number = 0;

	static getDerivedStateFromProps(props: Props) {
		const imgSources: string[] = [];
		if (props.src) {
			imgSources.push(props.src);

			if (props.tryOriginal) {
				// image src is always from s3 original folder because we store it in db that way
				const parseResults = BlobStorageUtil.parseStorageUrl(props.src);
				if (parseResults) {
					let { directories } = parseResults;
					const { filename } = parseResults;
					directories = BlobStorageUtil.replaceDirectorySize(directories, BlobStorageImageSizeContainer.ORIGINAL);
					const originalImageSrc = BlobStorageUtil.getStorageUrl(directories, filename);
					imgSources.push(originalImageSrc);
				}
			}

			if (props.tryRoot) {
				const parseResults = BlobStorageUtil.parseStorageUrl(props.src);
				if (parseResults) {
					const { directories, filename } = parseResults;
					// ['wo_attachments', 'images', ...]
					const rootDirectory = directories.shift();
					if (!rootDirectory) {
						throw new Error('Image Directory Error');
					}
					const rootImageSrc = BlobStorageUtil.getStorageUrl(rootDirectory, filename);
					imgSources.push(rootImageSrc);
				}
			}
		}

		// add fallback src
		if (props.fallbackSrc) {
			imgSources.push(props.fallbackSrc);
		}

		const minSize: { style?: { width: number; height: number; backgroundColor: string; }; } = { style: undefined };
		if (props.minSize) {
			const { width, height } = getImageSize(props.minSize);
			minSize.style = {
				width,
				height,
				backgroundColor: ColorHex.GREY_BACKGROUND,
			};
		}

		return {
			imgSources,
			...minSize,
		};
	}

	componentDidMount() {
		this._image = this._imageRef.current;
		this.loadImage();
	}

	componentDidUpdate(prevProps: Props, prevState: State) {
		const { imgSources } = this.state;
		const { src } = this.props;
		const didImageChange = prevProps.src !== src;
		const didSourceChange = prevState.imgSources.length !== imgSources.length;
		if (didImageChange || didSourceChange) {
			this._currentImageIndex = 0;
		}
		this.loadImage();
	}

	nextImageIndex = () => {
		const { imgSources } = this.state;
		return this._currentImageIndex < imgSources.length - 1 ? (this._currentImageIndex + 1) : this._currentImageIndex;
	};

	loadImage = () => {
		const { fallbackSrc, getPresignedGetUrl, resizeImage } = this.props;
		const { imgSources } = this.state;

		if (!this._image) {
			throw new Error('Image not initialized');
		}

		this._image.src = imgSources[this._currentImageIndex];

		this._image.onload = () => this._image!.onerror = null;
		this._image.onerror = async () => {
			if (this._currentImageIndex === 0 && imgSources[this._currentImageIndex]) {
				// try to resize original src
				const missingImageSrc = imgSources[this._currentImageIndex];
				const parseResult = BlobStorageUtil.parseStorageUrl(missingImageSrc);
				if (parseResult?.size) {
					await resizeImage(
						BlobStorageUtil.getDirectoryPath(parseResult.directories),
						parseResult.filename,
						parseResult.size,
						true
					);
				}
			} else if (this._currentImageIndex === imgSources.length - 1) {
				// If all imageSources failed, set fallback
				this._image!.src = fallbackSrc!;
				return;
			}
			// second attempt, try to sign url
			const src = imgSources[this._currentImageIndex];

			const parseResult = BlobStorageUtil.parseStorageUrl(src);
			if (!parseResult) {
				throw new Error('Image incorrectly signed');
			}

			const presignedUrl = await getPresignedGetUrl(BlobStorageUtil.getDirectoryPath(parseResult.directories), parseResult.filename);
			this._image!.src = presignedUrl!;

			this._currentImageIndex = this.nextImageIndex();
		};
	};

	render() {
		const { className, onClick } = this.props;
		const { style } = this.state;

		return (
			<img
				className={className}
				onClick={onClick}
				ref={this._imageRef}
				style={style}
				{...ImageTag.LAZY_LOADING}
			/>
		);
	}
}

function mapDispatchToProps(): DispatchProps {
	return {
		getPresignedGetUrl: AttachmentActions.getPresignedGetUrl,
		resizeImage: AttachmentActions.resizeImage,
	};
}

export default connect<null, DispatchProps, OwnProps>(null, mapDispatchToProps())(ImageTag);
