import * as React from 'react';
import { Row, Col } from 'react-bootstrap';

import TimeFormat from 'acceligent-shared/enums/timeFormat';

import * as TimeUtils from 'acceligent-shared/utils/time';

import type ReportListForBulkSendItemVM from 'ab-viewModels/report/listForBulkSendItem.viewModel';

import LabelWithColor from 'af-components/LabelWithColor';
import Checkbox from 'af-components/Controls/Checkbox';

interface OwnProps {
	isChecked: boolean;
	item: ReportListForBulkSendItemVM;
	setItemIsChecked: (item: ReportListForBulkSendItemVM, value: boolean) => void;
}

type Props = OwnProps;

const SendMultipleReportsModalReportListItem: React.FC<Props> = ({ item, isChecked, setItemIsChecked }) => {
	const { workOrderId, workOrderCodeStripped, crewTypeColor, crewTypeName, dueDate } = item;

	const handleCheck = React.useCallback(() => setItemIsChecked(item, !isChecked), [item, isChecked, setItemIsChecked]);

	return (
		<Row className="field-report-orders-table__send-multiple-reports-modal__reports-list-item" key={workOrderId}>
			<Col sm={10}>
				<Checkbox
					handleChange={handleCheck}
					isChecked={isChecked}
					label={<strong>{workOrderCodeStripped}</strong>}
				/>
			</Col>
			<Col sm={10}>
				<LabelWithColor color={crewTypeColor} text={crewTypeName} />
			</Col>
			<Col sm={4}>
				{TimeUtils.formatDate(dueDate, TimeFormat.DATE_ONLY, TimeFormat.DB_DATE_ONLY)}
			</Col>
		</Row>
	);
};

export default React.memo(SendMultipleReportsModalReportListItem);
