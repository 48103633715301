import type { ColorPalette } from 'acceligent-shared/enums/color';
import EquipmentCostCategoryEnum, { EquipmentCostCategoryLabel } from 'acceligent-shared/enums/equipmentCostCategory';

import type EquipmentCostCategoryBase from 'ab-domain/models/equipmentCostCategory/base';
import type EquipmentCostBase from 'ab-domain/models/equipmentCost/base';

// This is where all View Models for TreeViews for Treebeard should be
// These view models need to immediately load everything
// For optimized versions used in main Resource pages, please use separate ViewModels

export class EquipmentCostCategoryItem {
	level: number;
	nodeId: number;
	name: string;
	color: Nullable<ColorPalette>;
	parents: string[];

	constructor(ecc: EquipmentCostCategoryBase, level: number, parents: string[] = []) {
		this.level = level;
		this.nodeId = ecc.id;
		this.name = ecc.name;
		this.color = ecc.categoryColor;
		this.parents = [EquipmentCostCategoryLabel[ecc.type], ...parents];
	}

	static completeList(ecc: EquipmentCostCategoryBase[], level: number = 1, parents: string[] = []) {
		return ecc.reduce((_acc: EquipmentCostCategoryItem[], _ecc: EquipmentCostCategoryBase) => {
			if (_ecc.categories) {
				_acc = _acc.concat(this.completeList(_ecc.categories, level + 1, [...parents, _ecc.name]));
			} else {
				_acc.push(new EquipmentCostCategoryItem(_ecc, level, parents));
			}
			return _acc;
		}, []);
	}

	static fromTree(leaf: EquipmentCostEditableTree): EquipmentCostCategoryItem {

		return {
			level: leaf.level,
			nodeId: leaf.nodeId,
			name: leaf.name,
			color: leaf.color,
			parents: leaf.parents,
		};
	}

}

export class EquipmentCostEditableTree extends EquipmentCostCategoryItem {
	// Attributes used for data transfer
	isCategory: boolean;
	count: number;
	children: Nullable<EquipmentCostEditableTree[]>;

	// Attributes only used on FE:
	active?: boolean;
	toggled?: boolean;

	// Attributes only used for form
	toCreate?: boolean;
	parent?: EquipmentCostCategoryItem;

	static completeTree(ecc: EquipmentCostCategoryBase[]): EquipmentCostEditableTree[] {

		// Attribute names should match enum names, otherwise it won't have a clean implementation
		const rootNodes = {
			EQUIPMENT: [] as EquipmentCostCategoryBase[],
			SMALL_TOOL: [] as EquipmentCostCategoryBase[],
			ACCESSORY: [] as EquipmentCostCategoryBase[],
		};

		ecc.forEach((_ecc: EquipmentCostCategoryBase) => {
			rootNodes[_ecc.type].push(_ecc);
		});

		// We are iterating through ordered list
		return Object.keys(EquipmentCostCategoryEnum).map((_key: string, _index: number) => {
			return this.toRootTypes(rootNodes[_key], _key, _index);
		});
	}

	static toRootTypes(categories: EquipmentCostCategoryBase[], name: string, index: number): EquipmentCostEditableTree {
		return {
			name: EquipmentCostCategoryLabel[name],
			nodeId: -(index + 1), // Negative values are definitely not taken, 0 might be
			level: 0,
			isCategory: true,
			count: categories.length,
			children: this.toCategories(categories, [EquipmentCostCategoryLabel[name]]),
			color: null,
			parents: [],
		};
	}

	static toCategories(categories: EquipmentCostCategoryBase[], parents: string[]): EquipmentCostEditableTree[] {
		return categories.map((_category: EquipmentCostCategoryBase) => this.toCategory(_category, parents));
	}

	static toCategory(category: EquipmentCostCategoryBase, parents: string[]): EquipmentCostEditableTree {
		const isLastLevel = !category.categories;

		const childParentsValue: string[] = [...parents, category.name];

		return {
			name: category.name,
			nodeId: category.id,
			level: isLastLevel ? 2 : 1,
			isCategory: true,
			count: isLastLevel ? category.equipmentCosts.length : category.categories.length,
			children: isLastLevel
				? this.toEquipmentCosts(category.equipmentCosts, category.categoryColor, childParentsValue)
				: this.toCategories(category.categories, childParentsValue),
			color: category.categoryColor,
			parents,
		};
	}

	static toEquipmentCosts(equipmentCosts: EquipmentCostBase[], color: Nullable<ColorPalette>, parents: string[]): EquipmentCostEditableTree[] {
		return equipmentCosts.map((_equipmentCost: EquipmentCostBase) => this.toEquipmentCost(_equipmentCost, color, parents));
	}

	static toEquipmentCost(equipmentCost: EquipmentCostBase, color: Nullable<ColorPalette>, parents: string[]): EquipmentCostEditableTree {
		return {
			name: equipmentCost.subcategory,
			nodeId: equipmentCost.id,
			level: 3,
			isCategory: false,
			count: 0,
			children: [],
			color,
			parents,
		};
	}

}

export class EquipmentCostTreeStructure {
	items: EquipmentCostCategoryItem[];
	nodes: EquipmentCostEditableTree[];

	constructor(ecc: EquipmentCostCategoryBase[]) {
		// We want list of all of them, but have a flag that will prevent anything but level 3s being selected
		this.items = EquipmentCostCategoryItem.completeList(ecc);
		this.nodes = EquipmentCostEditableTree.completeTree(ecc);
	}
}

export class CreateEquipmentCostTreeNode {
	item: EquipmentCostCategoryItem;
	node: EquipmentCostEditableTree;

	constructor(ecc: EquipmentCostCategoryBase) {
		const level = ecc.groupId ? 2 : 1;
		const parents = ecc.groupId && ecc.group?.name ? [ecc.group.name] : [];
		this.item = new EquipmentCostCategoryItem(ecc, level, parents);
		this.node = {
			name: ecc.name,
			nodeId: ecc.id,
			level,
			isCategory: true,
			count: 0,
			children: [],
			color: ecc.categoryColor,
			parents: this.item.parents,
		};
	}
}
