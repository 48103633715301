import * as React from 'react';
import { compose } from 'redux';
import type { ResolveThunks } from 'react-redux';
import { connect } from 'react-redux';
import type { CustomRouteComponentProps } from 'react-router-dom';
import type { InjectedFormProps } from 'redux-form';
import { reduxForm } from 'redux-form';

import EquipmentCostCategoryEnum from 'acceligent-shared/enums/equipmentCostCategory';

import type { RootState } from 'af-reducers';

import * as EquipmentCostActions from 'af-actions/equipmentCost';

import BulkImport from 'af-components/BulkUpsert/Import';
import CodeField from 'af-components/CodeField';
import ColorLegend from 'af-components/ColorLegend';

import { EQUIPMENT_COST_CSV_SAMPLE } from 'af-constants/csvSamples';
import { EQUIPMENT_COST_IMPORT } from 'af-constants/reduxForms';

import type { EquipmentCostCSVRequestModel } from 'ab-requestModels/equipmentCost.requestModel';

import CLIENT from 'af-constants/routes/client';

import parse from 'af-root/scenes/Company/Resources/EquipmentCost/BulkUpload/parse';

type OwnProps = CustomRouteComponentProps;

interface StateProps {
	companyName: string;
}

interface DispatchProps {
	importBatch: typeof EquipmentCostActions.importBatch;
}

type FormOwnProps = OwnProps & StateProps & ResolveThunks<DispatchProps>;
type Props = FormOwnProps & InjectedFormProps<EquipmentCostCSVRequestModel, FormOwnProps>;

class EquipmentCostBulkUpload extends React.PureComponent<Props> {

	static MANDATORY_FIELDS = ['type', 'group', 'category', 'categoryColor', 'subcategory'];

	manual = () => {
		return [
			'Import multiple equipment costs to your equipment costs list by using a CSV upload feature.',
			'Fields that should be located in the CSV are listed below.',
			'The ones marked with an asterisk are required and should not be left out.',
		];
	};

	fields = () => (
		<>
			<CodeField isBlue={true}>
				<>
					<b>type</b>                             | * | Type ( {Object.keys(EquipmentCostCategoryEnum).join(',')} )<br />
					<b>group</b>                            | * | Equipment Cost Group <br />
					<b>category</b>                         | * | Equipment Cost Category <br />
					<b>categoryColor</b>                    | * | Category Color (from color legend) <br />
					<b>subcategory</b>                      | * | Subcategory <br />
					<b>mobCharge</b>                        |   | Mob Charge (default 0) <br />
					<b>dailyCost</b>                        |   | Daily Cost (default 0) <br />
					<b>weeklyCost</b>                       |   | Weekly Cost (default 0) <br />
					<b>monthlyCost</b>                      |   | Monthly Cost (default 0) <br />
					<b>operatingCharge</b>                  |   | Operating Hour Charge (default 0) <br />
					<b>fuelCost</b>                         |   | Fuel Cost (default 0) <br />
					<b>licenses</b>                         |   | Licenses <br />
					<b>skills.0.name</b>                    |   | Skill name <br />
					<b>skills.0.color</b>                   |   | Skill color (from color legend) <br />
				</>
			</CodeField>
			<ColorLegend />
		</>
	);

	render() {
		const { importBatch, location: { state: { orgAlias } }, companyName } = this.props;

		return (
			<BulkImport
				fields={this.fields()}
				importBatch={importBatch}
				listRoute={CLIENT.COMPANY.RESOURCES.EQUIPMENT_COST.TREE(orgAlias, companyName)}
				mandatoryFields={EquipmentCostBulkUpload.MANDATORY_FIELDS}
				manual={this.manual()}
				parse={parse}
				sampleData={EQUIPMENT_COST_CSV_SAMPLE}
				title="Equipment Costs"
			/>
		);
	}
}

function mapStateToProps(state: RootState): StateProps {
	const { companyData } = state.user;
	if (!companyData) {
		throw new Error('User not logged in');
	}

	return {
		companyName: companyData.name,
	};
}

function mapDispatchToProps(): DispatchProps {
	return {
		importBatch: EquipmentCostActions.importBatch,
	};
}

const enhance = compose<React.ComponentClass<OwnProps>>(
	connect<StateProps, DispatchProps, OwnProps>(mapStateToProps, mapDispatchToProps()),
	reduxForm<EquipmentCostCSVRequestModel, FormOwnProps>({
		form: EQUIPMENT_COST_IMPORT,
		enableReinitialize: true,
	})
);

export default enhance(EquipmentCostBulkUpload);
