import * as React from 'react';
import type { InjectedFormProps} from 'redux-form';
import { Field } from 'redux-form';
import { Form, Row, Col } from 'react-bootstrap';

import Checkbox from 'af-fields/Checkbox';

import type { GeneralNotifications } from 'ab-requestModels/notificationSettings.requestModel';

import CompanyFeatureFlagsEnum, { CompanyFeatureFlagsTips } from 'ab-enums/companyFeatureFlags.enum';
import { NotificationPriorityOptions } from 'ab-enums/notificationPriority.enum';

import Dropdown from 'af-fields/Dropdown';

interface OwnProps {
	onSubmit: (form: GeneralNotifications) => Promise<void>;
}

type Props = OwnProps & InjectedFormProps<GeneralNotifications>;

const FeatureFlagsCompanyForm: React.FC<Props> = (props) => {
	const { handleSubmit, onSubmit } = props;

	const submit = React.useCallback(async (form: GeneralNotifications) => {
		await onSubmit(form);
	}, [onSubmit]);

	return (
		<Form onSubmit={handleSubmit(submit)}>
			<Row className="row--padded">
				<Col sm={24}>
					<Field
						component={Checkbox}
						inline={true}
						isStandalone={true}
						label={CompanyFeatureFlagsEnum.enableNotifications}
						labelId="accqa__notification-settings__general__enable"
						name="isEnabled"
						type="text"
					/>
				</Col>
			</Row>
			<Row className="row--padded">
				<Col sm={24}>
					<Field
						component={Checkbox}
						inline={true}
						isStandalone={true}
						label={CompanyFeatureFlagsEnum.enableAutomaticNotificationsOnWorkDays}
						labelId="accqa__notification-settings__general__enable"
						name="isEnabledAutoNotificationsOnWorkDays"
						tooltipMessage={CompanyFeatureFlagsTips.enableAutomaticNotificationsOnWorkDays}
						type="text"
					/>
				</Col>
			</Row>
			<Row className="row--padded">
				<Col md={12}>
					<Field
						component={Dropdown}
						fixed={true}
						id="notificationPriority"
						isStandalone={true}
						label="Notification priority"
						labelKey="name"
						name="notificationPriority"
						options={NotificationPriorityOptions}
						valueKey="id"
						withCaret={true}
					/>
				</Col>
				<Col sm={12} />
			</Row>
		</Form>
	);
};

export default React.memo(FeatureFlagsCompanyForm);
