function getDefinitionFieldValue(definitionField: { value: Nullable<string>; index: number; }) {
	return definitionField.value;
}

function sortDefinitionFields(_a: { index: number; }, _b: { index: number; }) {
	return _a.index - _b.index;
}

interface ResolveHighlightGroupKey {
	subJobId: number;
	billableWorkId: number;
	workType: Nullable<string>;
	definitionFields: { value: Nullable<string>; index: number; }[];
}

export function resolveHighlightGroupKey(event: ResolveHighlightGroupKey) {
	const { billableWorkId, workType, definitionFields, subJobId } = event;

	return JSON.stringify([
		billableWorkId,
		workType,
		[...definitionFields].sort(sortDefinitionFields).map(getDefinitionFieldValue),
		subJobId,
	]);
}
