import * as React from 'react';
import { compose } from 'redux';
import type { ConnectedProps } from 'react-redux';
import { connect } from 'react-redux';
import type { InjectedFormProps} from 'redux-form';
import { reduxForm, Field, Form, getFormValues } from 'redux-form';
import { Row, Col, Button, Image } from 'react-bootstrap';

import { ALLOWED_IMAGE_TYPES, ALLOWED_IMAGE_TYPES_STR } from 'acceligent-shared/enums/fileType';
import { isValidEmail } from 'acceligent-shared/utils/email';

import type { RootState } from 'af-reducers';

import * as AccountActions from 'af-actions/accounts';

import type * as User from 'ab-viewModels/user.viewModel';

import type { AccountRM } from 'ab-requestModels/account/updateActive.requestModel';

import { ACCOUNT_EDIT } from 'af-constants/reduxForms';

import Checkbox from 'af-fields/Checkbox';
import Input from 'af-fields/Input';
import Signature from 'af-fields/Signature';

import SubmitButton from 'af-components/SubmitButton';
import CustomModal from 'af-components/CustomModal';
import Tooltip from 'af-components/Tooltip';

import { PASSWORD_CATEGORIES, PASSWORD_LENGTH_MIN } from 'ab-common/constants/value';

import PagePermissions from 'ab-enums/pagePermissions.enum';

import { phoneNormalizer } from 'ab-utils/reduxForms.util';
import { toRequestModelWithRawPhoneNumbers } from 'ab-utils/form.util';
import { bemElement } from 'ab-utils/bem.util';
import { isAllowed } from 'ab-utils/auth.util';

import type { ActiveUserVM } from 'ab-viewModels/user/activeUser.viewModel';

import { validate } from './validations';
import AccountEditSegment from './AccountEditSegment';

import styles from './styles.module.scss';

type FormProps = InjectedFormProps<AccountRM, FormOwnProps>;

interface OwnProps {
	initialValues?: FormProps['initialValues'];
	isVisible: boolean;
	onSubmit: (data: ActiveUserVM) => void;
	toggleEdit: () => void;
	userData: User.UserData;
}

type FormOwnProps = OwnProps & ConnectedProps<typeof connector>;

type Props = FormOwnProps & FormProps;

enum PwCategories {
	UPPERCASE = 'uppercase letters',
	LOWERCASE = 'lowercase letters',
	NUMBERS = 'numbers',
	SYMBOLS = 'symbols'
}

const AccountEdit: React.FC<Props> = (props) => {
	const {
		initialValues,
		isVisible,
		onSubmit,
		toggleEdit,
		userData,
		editAccount,
		initialize,
		handleSubmit,
		change,
		changePassword,
		accountEditable,
		isPasswordEditable,
		changeSignature,
		isDigitalSignatureEnabled,
		error,
		invalid,
		reset,
		submitting,
		formData,
	} = props;

	const [image, setImage] = React.useState<Nullable<string | File>>(null);
	const [imageUrl, setImageUrl] = React.useState<Nullable<string>>(null);
	const [deleteImage, setDeleteImage] = React.useState(false);
	const [uploadError, setUploadError] = React.useState<Nullable<string>>(null);
	const [hasReceivedInitialItem, setHasReceivedInitialItem] = React.useState(false);
	const [isDigitalSignatureExpanded, setIsDigitalSignatureExpanded] = React.useState(false);

	const inputElementRef = React.useRef<HTMLInputElement>(null);

	React.useEffect(() => {
		if (isVisible) {
			initialValues && initialize(initialValues);
		}
		// eslint-disable-next-line react-hooks/exhaustive-deps
	}, [isVisible]);

	React.useEffect(() => {
		if (!hasReceivedInitialItem && userData) {
			setHasReceivedInitialItem(true);
			setImage(userData.imageUrl);
			setImageUrl(userData.imageUrl);
		}
	}, [hasReceivedInitialItem, userData]);

	const onInputClick = React.useCallback(() => {
		setUploadError(null);
		if (inputElementRef.current) {
			inputElementRef.current.click();
		}
	}, [inputElementRef]);

	const onDeleteImage = React.useCallback(() => {
		setImage(null);
		setImageUrl(null);
		setDeleteImage(true);
		if (inputElementRef.current?.value) {
			inputElementRef.current.value = '';
		}
	}, [inputElementRef]);

	const changeImage = React.useCallback((event) => {
		const [file] = event.target.files as File[];

		if (file) {
			if (ALLOWED_IMAGE_TYPES.includes(file.type)) {
				const reader = new FileReader();
				reader.onloadend = () => {
					const _result = reader.result as string;
					setImage(file);
					setImageUrl(_result);
					setDeleteImage(true);

				};
				reader.readAsDataURL(file);
			} else {
				setUploadError(`Invalid file format. Only ${ALLOWED_IMAGE_TYPES_STR} allowed.`);
			}
		}
	}, []);

	const setPasswordChange = React.useCallback((value: boolean) => {
		change('changePassword', value);
	}, [change]);

	const setSignatureChange = React.useCallback((value: boolean) => {
		change('changeSignature', value);
	}, [change]);

	const submit = React.useCallback(async (form: AccountRM) => {
		const data = toRequestModelWithRawPhoneNumbers(form, 'phoneNumber');
		data.image = image;
		data.deleteImage = deleteImage;
		const result = await editAccount(data);
		onSubmit(result);
		setPasswordChange(false);
	}, [deleteImage, editAccount, image, onSubmit, setPasswordChange]);

	const togglePassword = React.useCallback(() => {
		setPasswordChange(!changePassword);
		setIsDigitalSignatureExpanded(false);
	}, [changePassword, setPasswordChange]);

	const onIsDigitalSignatureToggle = React.useCallback(() => {
		if (!changeSignature) {
			setSignatureChange(true);
		}
	}, [setSignatureChange, changeSignature]);

	const toggleDigitalSignature = React.useCallback(() => {
		setPasswordChange(false);
		setIsDigitalSignatureExpanded(!isDigitalSignatureExpanded);
	}, [isDigitalSignatureExpanded, setPasswordChange]);

	const onClose = React.useCallback(() => {
		reset();
		toggleEdit();
	}, [toggleEdit, reset]);

	const onSignatureChange = React.useCallback(() => {
		setSignatureChange(true);
	}, [setSignatureChange]);

	const renderDigitalSignatureEdit = React.useCallback(() => {
		return (
			<Row>
				<Col sm={24}>
					<Field
						component={Signature}
						name="signatureImage"
						onChange={onSignatureChange}
					/>
				</Col>
			</Row>
		);
	}, [onSignatureChange]);

	const renderPasswordEdit = React.useCallback(() => {
		if (!changePassword) {
			return null;
		}

		return (
			<Row>
				<Col sm={12}>
					{userData.isFinalized &&
						<Field
							component={Input}
							label="Old Password *"
							name="oldPassword"
							placeholder="Old Password"
							type="password"
						/>
					}
					<Field
						component={Input}
						disableErrors={!(formData?.submitErrors && Object.keys(formData.submitErrors).length)}
						label="New Password *"
						name="newPassword"
						placeholder="New Password"
						type="password"
					/>
					<Field
						component={Input}
						label="Repeat New Password *"
						name="confirmNewPassword"
						placeholder="Repeat New Password"
						type="password"
					/>
				</Col>
				<Col sm={12} >
					<div className={`${styles.pwRequirementsElement}`}>Password requirements:</div>
					<div className={`${styles.pwRequirementsContainer}`}>{
						formData?.values?.newPassword?.length >= PASSWORD_LENGTH_MIN ?
							<span className={`${styles.icon} icon-check_circle text-green`}/> :
							<span className={`${styles.icon} icon-dot ${styles.textGrey}`}/>
						}
						<p>At least { PASSWORD_LENGTH_MIN } characters long</p>
					</div>
					<div>Contains characters from&nbsp;<strong>at least three</strong>&nbsp;of these categories:</div>
					{Object.keys(PwCategories).map((key) => {
						return <div className={`${styles.pwRequirementsContainer}`} key={key}>
							{ validateCategory(formData?.values?.newPassword, PwCategories[key]) ?
								<span className={`${styles.icon} icon-check_circle text-green`}/> :
								<span className={`${styles.icon} icon-dot ${styles.textGrey}`}/>
							}
							<p>{PwCategories[key]}</p>
						</div>;
					})}
				</Col>
			</Row>
		);
	}, [changePassword, userData.isFinalized, formData]);

	const imageUploadButtonClassName = bemElement('image-upload', 'button');

	return (
		<CustomModal
			closeModal={onClose}
			modalStyle="info"
			showModal={isVisible}
			size="lg"
		>
			<CustomModal.Header
				closeModal={onClose}
				title="Edit Account"
			/>
			<CustomModal.Body padding="none">
				<Form onSubmit={handleSubmit(submit)}>
					<Row className="upsert-account__image-row row--padded-top">
						<Col sm={3}>
							{image && imageUrl
								? <Image className={bemElement('company-settings', 'image')} roundedCircle={true} src={imageUrl} />
								: (
									<div className={bemElement('company-settings', 'image', ['default'])}>
										<span className="icon-user" />
									</div>
								)
							}
						</Col>
						<Col className={bemElement('image-upload', 'buttons')} sm={21}>
							<Tooltip message="Upload">
								<Button
									className={imageUploadButtonClassName}
									disabled={!accountEditable}
									onClick={onInputClick}
									variant="info"
								>
									<strong className="icon-upload" />
								</Button>
							</Tooltip>
							<Tooltip message="Delete">
								<Button
									className={imageUploadButtonClassName}
									disabled={!image || !accountEditable}
									onClick={onDeleteImage}
									variant="danger"
								>
									<strong className="icon-delete" />
								</Button>
							</Tooltip>
							<input accept={ALLOWED_IMAGE_TYPES} className="display-none" onChange={changeImage} ref={inputElementRef} type="file" />
						</Col>
					</Row>
					{uploadError &&
						<Row>
							<Col sm={24}>
								<span className="help-block">{uploadError}</span>
							</Col>
						</Row>
					}
					<Row className="row--padded-top">
						<Col sm={12}>
							<Field
								component={Input}
								disabled={!accountEditable}
								label="First Name *"
								name="firstName"
								placeholder="Enter First Name"
								type="text"
							/>
						</Col>
						<Col sm={12}>
							<Field
								component={Input}
								disabled={!accountEditable}
								label="Last Name *"
								name="lastName"
								placeholder="Enter Last Name"
								type="text"
							/>
						</Col>
					</Row>
					<Row className="row--padded-top">
						<Col sm={12}>
							<Field
								component={Input}
								disabled={!accountEditable}
								label="Email"
								name="email"
								placeholder="Email"
								type="text"
							/>
						</Col>
						<Col sm={8}>
							<Field
								component={Input}
								disabled={!accountEditable}
								label="Mobile Phone"
								name="phoneNumber"
								normalize={phoneNormalizer}
								placeholder="Mobile Phone"
								type="text"
							/>
						</Col>
						<Col sm={4}>
							<Field
								component={Input}
								disabled={!accountEditable}
								label="Extension"
								name="extension"
								placeholder="Extension"
								type="text"
							/>
						</Col>
					</Row>
					<Field
						component={Input}
						name="changePassword"
						type="hidden"
					/>
					<Row>
						<Col sm={24}>
							<Field
								component={Checkbox}
								inline={true}
								label="Enable Digital Signature"
								name="isDigitalSignatureEnabled"
								onValueChange={onIsDigitalSignatureToggle}
							/>
						</Col>
					</Row>
					<AccountEditSegment
						isExpanded={isDigitalSignatureExpanded}
						isVisible={isDigitalSignatureEnabled}
						label="Edit digital signature"
						renderContent={renderDigitalSignatureEdit}
						toggle={toggleDigitalSignature}
					/>
					<AccountEditSegment
						isExpanded={changePassword}
						isVisible={isPasswordEditable}
						label="Change Password"
						renderContent={renderPasswordEdit}
						toggle={togglePassword}
					/>
					{error && <span className="help-block"><span className="icon-info" /> {error}</span>}
				</Form>
			</CustomModal.Body>
			<CustomModal.Footer>
				<Button
					onClick={onClose}
					variant="info"
				>
					Cancel
				</Button>
				<SubmitButton
					disabled={invalid}
					label="Save"
					onClick={handleSubmit(submit)}
					reduxFormSubmitting={submitting}
					submitKey={ACCOUNT_EDIT}
				/>
			</CustomModal.Footer>
		</CustomModal>
	);
};

const getForm = getFormValues(ACCOUNT_EDIT);

function validateCategory(values: string | undefined, category: PwCategories): boolean {
	const isValid = values && PASSWORD_CATEGORIES[category].test(values);
	return !!isValid;
}

function mapStateToProps(state: RootState) {
	const { userData, companyData } = state.user;
	if (!userData || !companyData) {
		throw new Error('User not logged in');
	}

	const formData = getForm(state) as AccountRM;
	if (!formData) {
		return;
	}

	return {
		validEmail: isValidEmail(formData.email ?? ''),
		changePassword: formData.changePassword,
		isPasswordEditable: !!userData?.email || !!formData.email,
		isDigitalSignatureEnabled: formData.isDigitalSignatureEnabled,
		changeSignature: formData.changeSignature,
		accountEditable: isAllowed(
			PagePermissions.COMPANY.SETTINGS.MEMBERS.MANAGE,
			companyData.permissions ?? [],
			companyData.isCompanyAdmin,
			userData.role
		),
		formData: state.form.editAccount,
	};
}

function mapDispatchToProps() {
	return {
		editAccount: AccountActions.updateActiveAccount,
	};
}

const connector = connect(mapStateToProps, mapDispatchToProps());

const enhance = compose<React.ComponentClass<OwnProps>>(
	React.memo,
	connector,
	reduxForm<AccountRM, FormOwnProps>({ form: ACCOUNT_EDIT, validate })
);

export default enhance(AccountEdit);
