import { EmailTypesArray, PhoneTypesArray } from 'acceligent-shared/enums/contactMethodType';
import type { ColorPalette } from 'acceligent-shared/enums/color';
import type { PhoneTypes, EmailTypes } from 'acceligent-shared/enums/contactMethodType';
import type CountryCode from 'acceligent-shared/enums/countryCode';
import type FieldReportAccessRole from 'acceligent-shared/enums/fieldReportAccessRole';
import type FileType from 'acceligent-shared/enums/fileType';
import type WorkOrderStatus from 'acceligent-shared/enums/workOrderStatus';
import type WorkRequestStatus from 'acceligent-shared/enums/workRequestStatus';
import type { WorkOrderReviewLevel } from 'acceligent-shared/enums/workOrderReviewStatus';
import type WorkOrderReviewStatus from 'acceligent-shared/enums/workOrderReviewStatus';
import type SubscriptionStatus from 'acceligent-shared/enums/subscriptionStatus';
import type BlobStorageContainer from 'acceligent-shared/enums/blobStorageContainer';
import type NotificationStatusEnum from 'acceligent-shared/enums/notificationStatus';

import * as TimeUtils from 'acceligent-shared/utils/time';

import type { CalculatedReportDisplayStatus } from 'acceligent-shared/enums/reportDisplayStatus';

import type WorkOrderRevisionBase from 'ab-domain/models/workOrderRevision/base';
import type UserBase from 'ab-domain/models/user/base';
import type ContactMethodBase from 'ab-domain/models/contactMethod/base';

import type ValidationState from 'ab-enums/validationState.enum';
import type WorkOrderStatusRevision from 'ab-enums/workOrderStatusRevision';

import * as UserUtil from 'ab-utils/user.util';
import * as CodeUtil from 'ab-utils/codes.util';

type GroupedContactMethods = {
	emails: ContactMethodVM[];
	phones: ContactMethodVM[];
};

class ContactMethodVM {
	id: number;
	contactId: number;
	type: EmailTypes | PhoneTypes;
	value: string;
	countryCode: Nullable<CountryCode>;
	extension: Nullable<string>;

	constructor(contactMethod: ContactMethodBase) {
		this.id = contactMethod.id;
		this.contactId = contactMethod.contactId;
		this.type = contactMethod.type;
		this.value = contactMethod.value;
		this.countryCode = contactMethod.countryCode;
		this.extension = contactMethod.extension;
	}

	static groupedBulkConstructor(contactMethods: ContactMethodBase[]): GroupedContactMethods {
		const result: GroupedContactMethods = {
			phones: [],
			emails: [],
		};

		for (const _cm of contactMethods) {
			if (PhoneTypesArray.indexOf(_cm.type) !== -1) {
				result.phones.push(new ContactMethodVM(_cm));
			} else if (EmailTypesArray.indexOf(_cm.type) !== -1) {
				result.emails.push(new ContactMethodVM(_cm));
			}
		}

		return result;
	}

}

interface UpdatedByViewModel {
	accountId?: number;
	userId?: number;
	firstName?: string;
	lastName?: string;
	fullName: string;
}

class ScheduleBoardWorkOrderModalViewModel {
	id: number;
	code: string;
	index: number;
	workOrderId?: Nullable<number>;
	revision: string;
	statusBasedRevision: string;

	title: string;
	jobStatus: WorkRequestStatus;
	isJobInternal: boolean;
	jobNotes: Nullable<string>;

	publicLink: Nullable<string>;
	/** MM-DD-YYYY */
	dueDate: string;
	status: WorkOrderStatus;
	cancellationReason: Nullable<string>;
	notificationStatus: Nullable<NotificationStatusEnum>;
	isPaused: boolean;
	pauseReason: Nullable<string>;

	isFirst?: boolean;
	isLate?: boolean;
	delayReason?: string;

	notes: Nullable<string>;
	scopeOfWork: Nullable<string>;

	crewTypeName: Nullable<string>;
	crewTypeColor: Nullable<ColorPalette>;
	customCrewType: Nullable<string>;

	shiftName: string;
	timeToStart: Nullable<number>;
	timeToEnd: Nullable<number>;

	projectManager: Nullable<UserModel>;
	supervisor: Nullable<UserModel>;

	siteContact: Nullable<WorkOrderRevisionContactModel>;
	workLocationAddress: Nullable<string>;

	officeNickname: Nullable<string>;
	officeColor: Nullable<ColorPalette>;
	divisionName: Nullable<string>;
	customerName: Nullable<string>;

	addresses: Nullable<AddressModel[]>;
	workOrderResourceLookups: WorkOrderResourceLookupModel[];
	workOrderStatus: Nullable<WorkOrderStatusRevision>;

	revenue: Nullable<string>;
	manHourAverage: Nullable<string>;
	jobHours: Nullable<number>;
	shopHours: Nullable<number>;
	travelHours: Nullable<number>;
	workDescription: Nullable<string>;

	deliverableSoftware: Nullable<string>;
	deliverableCodeStandard: Nullable<string>;
	deliverableFileFormat: Nullable<string>;
	deliverableDeliveryMethod: Nullable<string>;
	deliverableDeliveryTimeline: Nullable<string>;
	deliverableAssignee: Nullable<UserModel>;
	deliverableNotes: Nullable<string>;

	updatedAt: Date;
	updatedBy: Nullable<UpdatedByViewModel>;

	validationState: ValidationState;
	isValid: boolean;

	locked: boolean;

	reviewStatus: WorkOrderReviewStatus;
	reviewLevel: WorkOrderReviewLevel;
	fieldReportAccessRole: Nullable<FieldReportAccessRole>;
	calculatedReportDisplayStatus: Nullable<CalculatedReportDisplayStatus>;
	isFRStatusOfInterest: boolean;

	constructor(workOrderRevision: WorkOrderRevisionBase) {
		this.code = CodeUtil.workOrderCodeFromRevision(workOrderRevision);
		this.revision = CodeUtil.revisionCode(workOrderRevision.revision);
		this.workOrderStatus = workOrderRevision.workOrderStatus;

		this.title = workOrderRevision.jobTitle;
		this.jobStatus = workOrderRevision.jobStatus;
		this.isJobInternal = workOrderRevision.isJobInternal;
		this.jobNotes = workOrderRevision.jobNotes;

		this.publicLink = workOrderRevision.publicLink;

		this.dueDate = TimeUtils.formatDate(workOrderRevision.dueDate);

		this.notes = workOrderRevision.notes;
		this.scopeOfWork = workOrderRevision.scopeOfWork;

		this.crewTypeName = workOrderRevision.crewTypeName;
		this.crewTypeColor = workOrderRevision.crewTypeColor;
		this.customCrewType = workOrderRevision.customCrewType;

		this.shiftName = workOrderRevision.shiftName;
		this.timeToStart = workOrderRevision.timeToStart;
		this.timeToEnd = workOrderRevision.timeToEnd;

		this.supervisor = workOrderRevision.supervisor;
		this.projectManager = workOrderRevision.projectManager;

		this.siteContact = workOrderRevision.siteContact;
		this.workLocationAddress = workOrderRevision.workLocationAddress;

		this.officeNickname = workOrderRevision.officeNickname;
		this.officeColor = workOrderRevision.officeColor;
		this.divisionName = workOrderRevision.divisionName;
		this.customerName = workOrderRevision.customerName;

		this.workOrderResourceLookups = workOrderRevision.workOrderResourceLookups;
		this.addresses = workOrderRevision.addresses;

		this.revenue = workOrderRevision.revenue;
		this.manHourAverage = workOrderRevision.manHourAverage;
		this.jobHours = workOrderRevision.jobHours;
		this.shopHours = workOrderRevision.shopHours;
		this.travelHours = workOrderRevision.travelHours;
		this.workDescription = workOrderRevision.workDescription;

		this.deliverableCodeStandard = workOrderRevision.deliverableCodeStandard;
		this.deliverableFileFormat = workOrderRevision.deliverableFileFormat;
		this.deliverableSoftware = workOrderRevision.deliverableSoftware;
		this.deliverableDeliveryMethod = workOrderRevision.deliverableDeliveryMethod;
		this.deliverableDeliveryTimeline = workOrderRevision.deliverableDeliveryTimeline;
		this.deliverableAssignee = workOrderRevision.deliverableAssignee;
		this.deliverableNotes = workOrderRevision.deliverableNotes;
	}
}

export class UserModel {
	/** **Note**: it should be null only for revisions created before https://acceligent.atlassian.net/browse/AP-4224 was merged. */
	id: Nullable<number>;
	fullName: string;
	email: Nullable<string>;
	phoneNumber: Nullable<string>;
	countryCode: Nullable<CountryCode>;

	constructor(user: Nullable<UserBase>) {
		if (!user) {
			return;
		}

		this.id = user?.id;
		this.fullName = UserUtil.getFullName(user);
		this.email = user?.email;
		this.phoneNumber = user?.phoneNumber;
		this.countryCode = user?.countryCode;
	}
}

export type AddressModel = {
	latitude: number;
	longitude: number;
	streetAddress: string;
	suite: Nullable<string>;
	locality: Nullable<string>;
	aa1: Nullable<string>;
	postalCode: Nullable<string>;
	postalOfficeBoxCode: Nullable<string>;
	country: Nullable<string>;
};

export type WorkOrderRevisionContactModel = {
	id: number;
	name: string;
	companyName: string;
	companyRole: string;
	emails: ContactMethodModel[];
	phones: ContactMethodModel[];
	addresses: ContactAddressModel[];
};

export type ContactMethodModel = {
	type: EmailTypes | PhoneTypes;
	value: string;
	countryCode?: CountryCode;
};

export type ContactAddressModel = {
	street: string;
	suite: Nullable<string>;
	city: Nullable<string>;
	stateAbbreviation: Nullable<string>;
	zip: Nullable<string>;
	country: Nullable<string>;
};

type NotificationModelBase = {
	fullName: string;
	email: Nullable<string>;
	officeNickname: string | undefined;
	phoneNumber: Nullable<string>;
	countryCode: Nullable<CountryCode>;
	emailInvalidatedAt: Nullable<Date>;
	phoneInvalidatedAt: Nullable<Date>;
	subscriptionStatus: SubscriptionStatus | undefined;
	smsStatus: NotificationStatusEnum | undefined;
	smsSentAt: Date | undefined;
	emailStatus: NotificationStatusEnum | undefined;
	emailSentAt: Date | undefined;
	emailErrorMessage: string | undefined;
	smsErrorMessage: string | undefined;
	notificationType: string | undefined;
};

interface EmployeeNotificationModel extends NotificationModelBase {
	employeeId: number;
}

interface TemporaryEmployeeNotificationModel extends NotificationModelBase {
	temporaryEmployeeId: number;
}

type NotificationModel = EmployeeNotificationModel | TemporaryEmployeeNotificationModel;

export function isEmployee(employee: NotificationModel): employee is EmployeeNotificationModel {
	return 'employeeId' in employee && !!employee.employeeId;
}

export function isTemporaryEmployee(temporaryEmployee: NotificationModel): temporaryEmployee is TemporaryEmployeeNotificationModel {
	return 'temporaryEmployeeId' in temporaryEmployee && !!temporaryEmployee.temporaryEmployeeId;
}

export type WorkOrderResourceLookupModel = {
	id: number;
	index: number;
	name: string;
	color: Nullable<ColorPalette>;
	skills?: ColorPalette[];
	notification?: Nullable<NotificationModel>;
	workOrderEmployeeId?: number;
	employeeId?: number;
	workOrderEquipmentId?: number;
	equipmentId?: number;
	workOrderTemporaryEmployeeId?: number;
	temporaryEmployeeId?: number;
	wageRateId?: number;
	equipmentCostId?: number;
	isAvailable: boolean;
};

export interface AttachmentModel {
	size: number;
	uploadedBy: string;
	uploadedOn: Date;
	name: string;
	type: FileType;
	storageName: string;
	storageContainer: BlobStorageContainer;
}

export default ScheduleBoardWorkOrderModalViewModel;
