import * as React from 'react';

import { useToggle } from 'af-utils/react.util';

import Dropdown from 'af-components/Controls/Dropdown';

import { DeviceType } from './ReportConfirmation/helpers';
import MobileSidebar from './Sidebar/MobileSidebar';
import type { ReportSidebarInfo } from './Sidebar/helpers';

interface Props {
	deviceType: DeviceType;
	printReport: () => Promise<void>;
	sidebarReportsInfo: Nullable<ReportSidebarInfo[]>;
	isWebView: boolean;
	currentReportIndex: Nullable<number>;
	reportsRefs: React.MutableRefObject<HTMLDivElement[]>;
	hasMultipleReports: boolean;
	printAllReports: () => Promise<void>;
}

const resolveSingleReportOptions = (printReport: () => Promise<void>) => [{ label: 'Print', onClick: printReport }];

const resolveMultipleReportsOptions = (printReport: () => Promise<void>, printAllReports: () => Promise<void>) =>
	[
		{ label: 'Print', onClick: printReport },
		{ label: 'Print All Reports', onClick: printAllReports },
		{ label: 'Close', onClick: window.close },
	];

const Header: React.FC<Props> = (props: Props) => {
	const { deviceType, printReport, sidebarReportsInfo, currentReportIndex, reportsRefs, hasMultipleReports, printAllReports, isWebView } = props;
	const { value: showMobileSidebar, setToTrue: openMobileSidebar, setToFalse: closeMobileSidebar } = useToggle(false);

	const options = hasMultipleReports
		? resolveMultipleReportsOptions(printReport, printAllReports)
		: resolveSingleReportOptions(printReport);

	const isMobileOrTablet = (deviceType === DeviceType.MOBILE || deviceType === DeviceType.TABLET);
	const showActionsDropdown = isMobileOrTablet && !isWebView;

	return (
		<div className="public-report__header">
			{(isMobileOrTablet && hasMultipleReports) && (
				<MobileSidebar
					close={closeMobileSidebar}
					currentReportIndex={currentReportIndex}
					open={openMobileSidebar}
					reportsRefs={reportsRefs}
					show={showMobileSidebar}
					sidebarReportsInfo={sidebarReportsInfo}
				/>
			)}
			<img className="public-report__header__logo" src="/images/logo/ic_logo_black.svg"/>
			{showActionsDropdown &&
				<Dropdown
					containerClassName="public-report__header__actions"
					id="actions"
					isActionDropdown={true}
					labelKey="label"
					options={options}
				/>
			}
		</div>
	);
};

export default Header;
