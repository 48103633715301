import * as React from 'react';

import WorkOrderReviewStatus from 'acceligent-shared/enums/workOrderReviewStatus';

import type { WorkOrderReviewHistoryItemVM } from 'ab-viewModels/workOrder/workOrderFieldReportCard.viewModel';

import { bemElement } from 'ab-utils/bem.util';

interface OwnProps {
	workOrderReviewHistoryItem: Nullable<WorkOrderReviewHistoryItemVM>;
}

type Props = OwnProps;

const WorkOrderRejectionReason: React.FC<Props> = (props: Props) => {
	const { workOrderReviewHistoryItem } = props;

	if (!workOrderReviewHistoryItem) {
		return null;
	}

	const status = workOrderReviewHistoryItem.newStatus;
	if (!workOrderReviewHistoryItem.rejectReason) {
		return null;
	}

	const itemClassName = bemElement('field-report-work-order-info-card__header', 'reject-reason', {
		red: status === WorkOrderReviewStatus.REJECTED,
		grey: status === WorkOrderReviewStatus.IN_REVIEW,
	});
	return (
		<div className="field-report-work-order-info-card__header__reject-reason-container">
			<span className="field-report-work-order-info-card__header__reject-title">
				Rejection Reason:
				<span className={itemClassName}>
					{workOrderReviewHistoryItem.rejectReason}
				</span>
			</span>
		</div>
	);
};

export default React.memo(WorkOrderRejectionReason);
