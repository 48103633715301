import * as React from 'react';
import { Dropdown } from 'react-bootstrap';

import { useToggle } from 'af-utils/react.util';

import SettingsItem from './SettingsItem';
import type { Item, NonCascadeItem} from './types';
import { isDividerItem, isCascadeItem } from './types';

export { isDividerItem, Item, SettingsItem, NonCascadeItem } from './types';

interface OwnProps {
	items: Item[];
	rowIndex: number;
	disabled?: boolean;
	isFirstHalf: boolean;
}

type Props = OwnProps;

const SettingsActionCell = ({ disabled = false, isFirstHalf, items }: Props) => {
	const { value: open, toggle } = useToggle(false);

	const renderNonCascadeItem = React.useCallback((item: NonCascadeItem, index: number) => {
		if (isDividerItem(item)) {
			return <Dropdown.Divider key={`item-divider#${index}`} />;
		}

		return (
			<SettingsItem
				{...item}
				eventKey={index}
				key={`${item.label}${index}`}
				toggleCell={toggle}
			/>
		);
	}, [toggle]);

	const renderItem = React.useCallback((item: Item, index: number) => {
		if (!isCascadeItem(item)) {
			return renderNonCascadeItem(item, index);
		}

		return (
			<Dropdown className="dropdown-cascade" key={`cascade#${index}`}>
				<Dropdown.Toggle className="dropdown-toggle--cascade">
					<span>{item.label}</span>
					<span className="icon-right"></span>
				</Dropdown.Toggle>
				<Dropdown.Menu className="dropdown-menu--fit-content dropdown-menu--cascade-left">
					{item.items.map(renderNonCascadeItem)}
				</Dropdown.Menu>
			</Dropdown>
		);
	}, [renderNonCascadeItem]);

	if (disabled) {
		return (
			<div className="setting-dropdown dropdown btn-group">
				<button>
					<span className="icon-actions" />
				</button>
			</div>
		);
	}

	return (
		<Dropdown
			className="setting-dropdown"
			drop={!isFirstHalf ? 'up' : undefined}
			onToggle={toggle}
			show={open}
		>
			<Dropdown.Toggle className="btn btn-toggle setting-dropdown__toggle">
				<span className="icon-actions" />
			</Dropdown.Toggle>
			<Dropdown.Menu className="setting-dropdown__menu">
				{items.map(renderItem)}
			</Dropdown.Menu>
		</Dropdown>
	);
};

export default React.memo(SettingsActionCell);
