import * as React from 'react';
import { useOutletContext } from 'react-router-dom-v5-compat';

import type { FormJobOutletContext } from 'af-root/scenes/Company/Jobs/Form/types';

import styles from '../styles.module.scss';
import JobHazardSummarySection from './Sections/JobHazardSummarySection';
import PermitsRequiredSection from './Sections/PermitsRequired';
import SafetySection from './Sections/SafetySection';
import EnvironmentalProtectionSection from './Sections/EnvironmentalProtectionSection';
import PublicSafetySection from './Sections/PublicSafetySection';
import StatusSection from './Sections/StatusSection';

const JobHazardAssessmentFormTab: React.FC = () => {
	const { change, formName } = useOutletContext<FormJobOutletContext>();
	const jobHazzardSummaryRef = React.useRef<Nullable<HTMLDivElement>>(null);

	React.useEffect(() => {
		if (jobHazzardSummaryRef.current) {
			jobHazzardSummaryRef.current.scrollIntoView({ block: 'end' });
		}
	}, []);

	return (
		<div className={styles['job-form__tab-content']}>
			<JobHazardSummarySection jobHazzardSummaryRef={jobHazzardSummaryRef} />
			<PermitsRequiredSection />
			<SafetySection />
			<EnvironmentalProtectionSection />
			<PublicSafetySection />
			<StatusSection change={change} formName={formName} />
		</div>
	);
};

export default React.memo(JobHazardAssessmentFormTab);
