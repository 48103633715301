import * as React from 'react';
import { Field } from 'redux-form';

import Checkbox from 'af-fields/Checkbox';

interface OwnProps {
	isActivated: boolean;
}

type Props = OwnProps;

const LMSActivation: React.FC<Props> = ({ isActivated }) => {

	const [activateChecked, setActivateChecked] = React.useState(isActivated);

	const handleChange = React.useCallback(() => {
		setActivateChecked(!activateChecked);
	}, [activateChecked]);

	return (
		<div className="user-upsert__invite">
			<Field
				component={Checkbox}
				inline={true}
				isDisabled={false}
				isStandalone={true}
				label="Active in LMS"
				name="activeInLMS"
				onChange={handleChange}
				tooltipMessage="Selecting the checkbox will allow the user to log into the learning management system."
				type="text"
			/>
		</div>
	);
};

export default React.memo(LMSActivation);
