import * as React from 'react';
import { Button } from 'react-bootstrap';
import type { CustomRouteComponentProps } from 'react-router-dom';
import { withRouter } from 'react-router-dom';
import { compose } from 'redux';
import type { ConnectedProps } from 'react-redux';
import { connect } from 'react-redux';
import { formValueSelector } from 'redux-form';

import { DEFAULT_TIME_DURATION_MINUTE_ROUNDING_INTERVAL } from 'acceligent-shared/constants/value';

import TimeFormat from 'acceligent-shared/enums/timeFormat';
import { TimeSheetInternalApprovalStatus } from 'acceligent-shared/enums/timeSheetApprovalStatus';
import FieldReportAccessRole from 'acceligent-shared/enums/fieldReportAccessRole';
import TimeSheetSignatureStatus from 'acceligent-shared/enums/timeSheetSignatureStatus';
import WorkOrderReviewStatus, { WorkOrderReviewLevel } from 'acceligent-shared/enums/workOrderReviewStatus';
import WorkOrderStatus from 'acceligent-shared/enums/workOrderStatus';
import TimeSheetEntryWorkType from 'acceligent-shared/enums/timeSheetEntryWorkType';

import { UPDATED_BY_FAKE_USER } from 'ab-viewModels/account/updatedBy';
import type FieldWorkClassificationCodeListItemVM from 'ab-viewModels/fieldWorkClassificationCode/listItem';
import type TimeSplitEquipmentVM from 'ab-viewModels/timeSplitEquipment/timeSplitEquipment';
import type RejectReviewRM from 'ab-requestModels/workOrder/rejectReview';
import type { TimeSplitEntryRM, TimeSheetEntryRM, TimeAllocationEntryRM } from 'ab-requestModels/timeSheet/timeSheetUpdate';
import type TimeSheetUpdateRM from 'ab-requestModels/timeSheet/timeSheetUpdate';

import * as TimeUtils from 'acceligent-shared/utils/time';
import * as ReportDisplayStatusUtils from 'acceligent-shared/utils/workOrderReport';
import { filterMap } from 'acceligent-shared/utils/array';
import { shouldReportForAccountBeReadOnly } from 'acceligent-shared/utils/workOrderFieldReport';

import type CondensedTableVM from 'ab-viewModels/workOrder/workOrderForReportsCondensedTable';
import type AccountOptionsVM from 'ab-viewModels/account/option.viewModel';
import type { WorkOrderEmployeeVM } from 'ab-viewModels/workOrder/workOrderFieldReportCard.viewModel';
import type FieldReportAccessVM from 'ab-viewModels/fieldReportAccess/fieldReportAccessByWorkOrder.viewModel';
import type OccupiedSlotsForWorkOrderVM from 'ab-viewModels/timeSheet/occupiedSlotsForWorkOrder.viewModel';
import type TimeSheetTrackedEntriesVM from 'ab-viewModels/timeSheet/trackedEntries.viewModel';
import type WorkOrderHasOverlappingEntryVM from 'ab-viewModels/timeSheet/workOrderHasOverlappingEntry.viewModel';

import type SyncWorkOrderReviewStatusVM from 'ab-socketModels/viewModels/workOrder/syncReviewStatus.viewModel';
import type ReloadTimeSheetsVM from 'ab-socketModels/viewModels/timesheet/reloadTimeSheet.viewModel';

import type { SignatureRequestModel } from 'ab-requestModels/signature.requestModel';
import type FinalizeReviewRM from 'ab-requestModels/workOrder/finalizeReview.requestModel';
import type SubmitWorkOrderForReviewRM from 'ab-requestModels/workOrder/submitForReview.requestModel';
import type ApproveWorkOrderRM from 'ab-requestModels/workOrder/approveWorkOrder.requestModel';

import SocketEvent from 'ab-enums/socketEvent.enum';
import PagePermissions from 'ab-enums/pagePermissions.enum';

import { bemBlock } from 'ab-utils/bem.util';
import type { Differences } from 'ab-utils/workOrder.util';
import { isAllowed } from 'ab-utils/auth.util';

import * as WorkOrderActions from 'af-actions/workOrder';

import type { RootState } from 'af-reducers';

import { useLazyLoad, useToggle } from 'af-utils/react.util';
import socket from 'af-utils/socket.util';
import * as FieldReportUtil from 'af-utils/fieldReport.util';

import CLIENT from 'af-constants/routes/client';
import * as FORMS from 'af-constants/reduxForms';

import Tooltip from 'af-components/Tooltip';
import WarningModal from 'af-components/ConfirmationModal';
import ConfirmationModal from 'af-components/ConfirmationModal';

import DisplayReviewStatusLabel from '../../Shared/DisplayReviewStatusLabel';
import ApproveModal from '../../Shared/ApproveModal';
import RejectModal from '../../Shared/RejectModal';
import FinalizeModal from '../../Shared/FinalizeModal';
import OutdatedChangesModal from '../../FieldReportsList/TimeSheets/OutdatedChangesModal';
import SignatureForm from '../../Shared/SignatureModal/FormModel';

import EmployeeRow from './EmployeeRow';
import type { EmployeeDataFM } from './formModel';
import type FormModel from './formModel';
import { hasWorkOrderInvalidEquipmentTime, hasWorkOrderInvalidAllocationTime } from './helpers';

interface OwnProps {
	index: number;
	data: CondensedTableVM;
	companyName: string;
	fieldReportAccessRole: Nullable<FieldReportAccessRole>;
	classificationCodeList: FieldWorkClassificationCodeListItemVM[];
	accounting: AccountOptionsVM[];
	management: AccountOptionsVM[];
	isAccounting: boolean;
	isManagement: boolean;
	hasPermissionToEdit: boolean;
	hasPermissionToFinalize: boolean;
	currentAccountId: number;
	refresh: (workOrderId: number, index: number) => void;
	refetch: () => void;
	loadEquipment: (workOrderId: number) => Promise<TimeSplitEquipmentVM>;
	rejectWorkOrder: (workOrderId: number, form: RejectReviewRM) => Promise<void>;
	approveWorkOrder: (workOrderId: number, form: ApproveWorkOrderRM) => Promise<void>;
	finalizeWorkOrder: (workOrderId: number, form: FinalizeReviewRM) => Promise<void>;
	updateTimeSheet: (workOrderId: number, accountId: number, form: TimeSheetUpdateRM) => Promise<void>;
	updateEquipment: (workOrderId: number, accountId: number, form: TimeSplitEntryRM[]) => Promise<void>;
	/** value: ISO_DATETIME */
	change: (fieldName: string, value: string) => void;
	findEmployeesWithAccess: (workOrderId: number) => Promise<FieldReportAccessVM[]>;
	submitForReview: (workOrderId: number, form: SubmitWorkOrderForReviewRM) => Promise<void>;
	getOccupiedSlots: (workOrderId: number, accountId: number) => Promise<OccupiedSlotsForWorkOrderVM>;
	hasWorkOrderOverlaps: (workOrderId: number) => Promise<WorkOrderHasOverlappingEntryVM>;
	findTrackedEntriesForWorkOrder: (workOrderId: number) => Promise<TimeSheetTrackedEntriesVM>;
	endShiftsForWorkOrder: (workOrderId: number) => Promise<void>;
}

type Props = OwnProps & CustomRouteComponentProps & ConnectedProps<typeof connector>;

const isTimeSheetRejected = (timeSheet: CondensedTableVM['employees'][0]) => timeSheet.approvalStatus === TimeSheetInternalApprovalStatus.REJECTED;
const isTimeSheetSigned = (timeSheet: CondensedTableVM['employees'][0]) => timeSheet.signatureStatus === TimeSheetSignatureStatus.SIGNED;

type TotalTimes = {
	totalJobDuration: number;
	totalShopDuration: number;
	totalTravelDuration: number;
	totalBreakDuration: number;
};

const calculateTotalTimes = (result: TotalTimes, entry: TimeSheetEntryRM) => {
	if (!entry.startTime || (!entry.id && !entry.endTime)) {
		return result;
	}

	// if end time is null and it has an id this is a tracked entry
	const duration = TimeUtils.getDiff(entry.endTime ?? new Date(), entry.startTime, 'minutes', TimeFormat.ISO_DATETIME);

	switch (entry.workType) {
		case TimeSheetEntryWorkType.BREAK:
			result.totalBreakDuration += duration;
			break;
		case TimeSheetEntryWorkType.JOB:
			result.totalJobDuration += duration;
			break;
		case TimeSheetEntryWorkType.SHOP:
			result.totalShopDuration += duration;
			break;
		case TimeSheetEntryWorkType.TRAVEL:
			result.totalTravelDuration += duration;
			break;
	}
	return result;
};

const SYNC_WARNING_BODY = 'Changes have been made to the Field Report you are currently viewing. Refresh to continue.';
const SYNC_WARNING_HEADER = 'Changes have been made';

const FINALIZE_WARNING_BODY = (
	<>
		<span>You cannot finalize Field Report and Time Sheets. </span>
		<span>Total equipment time exceeds total job time. </span>
		<span>Please resolve error and proceed with Finalize. </span>
	</>
);
const FINALIZE_WARNING_HEADER = 'Total Equipment time is not valid';

const ALLOCATIONS_WARNING_BODY = (
	<>
		<span>You cannot approve Field Report and Time Sheets. </span>
		<span>Total allocation times per work type don't correspond to total times per work type. </span>
		<span>Please resolve error using field report full view and then proceed with approving.</span>
	</>
);
const ALLOCATIONS_WARNING_HEADER = 'Total allocation times aren\'t valid';

const APPROVE_WARNING_BODY = (
	<>
		<span>You cannot submit Field Report and Time Sheets. </span>
		<span>Time sheet entries overlap with each other. </span>
		<span>Please resolve error and proceed with Submit. </span>
	</>
);
const APPROVE_WARNING_HEADER = 'Time Sheets are not valid';

const APPROVE_ACTIVE_SHIFT_WARNING_BODY = (
	<>
		<span>You are about to approve all Field Reports. </span>
		<span>Some of the Time Cards are still recording time and this action will end those shifts. </span>
		<span>Do you want to proceed?</span>
	</>
);
const APPROVE_ACTIVE_SHIFT_WARNING_HEADER = 'End all shifts?';

const CHECK_WORK_SUMMARY_MODAL_BODY = (
	<>
		<span>Please check the work summary because not all billable work has a billing code assigned. </span>
		<span>All items in the Work Summary table should either have a billing code or marked as non-billable.</span>
	</>
);

const CondensedRow: React.FC<Props> = (props) => {
	const {
		isAccounting,
		isManagement,
		hasPermissionToEdit,
		hasPermissionToFinalize,
		data,
		accounting,
		management,
		fieldReportAccessRole,
		classificationCodeList,
		loadEquipment,
		history,
		location: { state: { orgAlias } },
		companyName,
		currentAccountId,
		rejectWorkOrder,
		approveWorkOrder,
		finalizeWorkOrder,
		updateTimeSheet,
		updateEquipment,
		change,
		findEmployeesWithAccess,
		submitForReview,
		refresh,
		getOccupiedSlots,
		hasWorkOrderOverlaps,
		findTrackedEntriesForWorkOrder,
		endShiftsForWorkOrder,
		index,
		accountData,
		refetch,
		fetchOutdatedWorkOrderDifferences,
		checkIfCanCompleteWorkSummaries,
		isDigitalSignatureEnabled,
		isAccountingOrAdmin,
	} = props;
	const { options: equipmentOptions, lazyLoad: lazyLoadEquipment } = useLazyLoad(loadEquipment, data.id);
	const { options: employeeOptions, lazyLoad: lazyLoadEmployees } = useLazyLoad(findEmployeesWithAccess, data.id);

	const {
		value: showApproveModal,
		setToFalse: closeApproveModal,
		setToTrue: openApproveModal,
	} = useToggle(false);

	const {
		value: showApproveActiveShiftWarningModal,
		setToTrue: openApproveActiveShiftWarningModal,
		setToFalse: closeApproveActiveShiftWarningModal,
	} = useToggle(false);

	const {
		value: showRejectModal,
		setToFalse: closeRejectModal,
		setToTrue: openRejectModal,
	} = useToggle(false);

	const {
		value: showFinalizeModal,
		setToFalse: closeFinalizeModal,
		setToTrue: openFinalizeModal,
	} = useToggle(false);

	const {
		value: updateRequired,
		setToTrue: setUpdateRequiredToTrue,
		setToFalse: setUpdateRequiredToFalse,
	} = useToggle(false);

	const {
		value: showWarningModal,
		setToTrue: openWarningModal,
		setToFalse: closeWarningModal,
	} = useToggle(false);

	const {
		value: showOutdatedModal,
		setToTrue: openOutdatedModal,
		setToFalse: closeOutdatedModal,
	} = useToggle(false);

	const {
		value: showSubmitWarningModal,
		setToTrue: openSubmitWarningModal,
		setToFalse: closeSubmitWarningModal,
	} = useToggle(false);

	const {
		value: showFinalizeWarningModal,
		setToTrue: openFinalizeWarningModal,
		setToFalse: closeFinalizeWarningModal,
	} = useToggle(false);

	const {
		value: showAllocationsWarningModal,
		setToTrue: openAllocationsWarningModal,
		setToFalse: closeAllocationsWarningModal,
	} = useToggle(false);

	const {
		value: revertOutdatedOnApprove,
		setToTrue: enableRevert,
		setToFalse: disableRevert,
	} = useToggle(false);

	const {
		value: showCheckWorkSummaryModal,
		setToTrue: openCheckWorkSummaryModal,
		setToFalse: closeCheckWorkSummaryModal,
	} = useToggle(false);

	const [editingMap, setEditingMap] = React.useState<Record<string, boolean>>({});
	const [occupiedSlotsMap, setOccupiedSlotsMap] = React.useState<Record<string, OccupiedSlotsForWorkOrderVM>>({});
	const [workOrderOutdatedDifferences, setWorkOrderOutdatedDifferences] = React.useState<Nullable<Differences>>(null);

	const loadOccupiedSlotsAction = React.useCallback(async () => {
		const loadedSlots: Record<string, OccupiedSlotsForWorkOrderVM> = await data.employees.reduce(async (_acc, { accountId }) => {
			const result = await _acc;

			if (!accountId) {
				return result;
			}

			const accountSlots = await getOccupiedSlots(data.id, accountId);
			result[accountId] = accountSlots;
			return result;
		}, Promise.resolve({}));
		setOccupiedSlotsMap(loadedSlots);
	}, [getOccupiedSlots, data]);

	React.useEffect(() => {
		lazyLoadEquipment(false);
		lazyLoadEmployees(false);
		loadOccupiedSlotsAction();

		FieldReportUtil.joinFieldReportList(data.id);
		if (socket.connection) {
			socket.connection.subscribe(SocketEvent.V2.BE.FIELD_REPORT_LIST.FIELD_REPORT_LIST_UPDATED, triggerReportsRefresh, true);
			socket.connection.subscribe(SocketEvent.V2.BE.FIELD_REPORT_LIST.UPDATE_INTERNAL_REVIEW_STATUS, triggerReportUpdate, true);
			socket.connection.subscribe(SocketEvent.V2.BE.FIELD_REPORT_LIST.UPDATE_TIME_SHEET, triggerSheetUpdate, true);
		}

		return () => {
			if (socket.connection) {
				FieldReportUtil.leaveFieldReportList(data.id);
				socket.connection.unsubscribe(SocketEvent.V2.BE.FIELD_REPORT_LIST.FIELD_REPORT_LIST_UPDATED);
				socket.connection.unsubscribe(SocketEvent.V2.BE.FIELD_REPORT_LIST.UPDATE_INTERNAL_REVIEW_STATUS);
				socket.connection.unsubscribe(SocketEvent.V2.BE.FIELD_REPORT_LIST.UPDATE_TIME_SHEET);
			}
		};
		// eslint-disable-next-line react-hooks/exhaustive-deps
	}, []);

	const getDifferences = React.useCallback(async () => {
		const diffs = await fetchOutdatedWorkOrderDifferences(data.id);
		setWorkOrderOutdatedDifferences(diffs);
	}, [data.id, fetchOutdatedWorkOrderDifferences]);

	React.useEffect(() => {
		if (data.status === WorkOrderStatus.OUTDATED) {
			getDifferences();
		} else {
			setWorkOrderOutdatedDifferences(null);
		}
	}, [data.status, getDifferences]);

	const refreshData = React.useCallback(async () => {
		refresh(data.id, index);
		setUpdateRequiredToFalse();
		closeWarningModal();
		loadOccupiedSlotsAction();
	}, [data, index, refresh, setUpdateRequiredToFalse, closeWarningModal, loadOccupiedSlotsAction]);

	const triggerReportsRefresh = ({ workOrderId }: { workOrderId: number; }, { socketId }: { socketId: string; }) => {
		if (workOrderId !== data.id) {
			return;
		}
		if (socketId === socket.connection?.getId()) {
			return;
		}
		refreshData();
	};

	const triggerReportUpdate = ({ workOrderId }: SyncWorkOrderReviewStatusVM, { socketId }: { socketId: string; }) => {
		if (workOrderId !== data.id) {
			return;
		}
		if (socketId === socket.connection?.getId()) {
			refreshData();
		} else {
			setUpdateRequiredToTrue();
		}
	};

	const triggerSheetUpdate = ({ workOrderId }: ReloadTimeSheetsVM, { socketId }: { socketId: string; }) => {
		if (workOrderId !== data.id || socketId === socket.connection?.getId()) {
			return;
		}
		setUpdateRequiredToTrue();
	};

	React.useEffect(() => {
		if (updateRequired && Object.values(editingMap).some((inEdit) => inEdit)) {
			openWarningModal();
		}
	}, [updateRequired, editingMap, openWarningModal]);

	const isFinalizing = React.useMemo(() => {
		return data.reviewLevel === WorkOrderReviewLevel.LEVEL_3 && data.reviewStatus === WorkOrderReviewStatus.APPROVED;
	}, [data]);

	const isForSubmit = React.useMemo(() => {
		return data.reviewStatus === WorkOrderReviewStatus.DRAFT || data.reviewStatus === WorkOrderReviewStatus.REJECTED;
	}, [data]);

	const isDone = React.useMemo(() => {
		return data.reviewStatus === WorkOrderReviewStatus.FINAL;
	}, [data]);

	const hasPermissionsForLevel = React.useMemo(() => {
		return isAccounting || (isManagement && data.reviewLevel < WorkOrderReviewLevel.LEVEL_3);
	}, [isAccounting, isManagement, data]);

	const accountingOptions: WorkOrderEmployeeVM[] = React.useMemo(() => {
		return accounting.map((_user) => ({
			..._user,
			uniqueId: _user.user.uniqueId,
			isLastSignee: _user.id === data.lastApprovedById,
		}));
	}, [accounting, data]);

	const managementOptions: WorkOrderEmployeeVM[] = React.useMemo(() => {
		return management.map((_user) => ({
			..._user,
			uniqueId: _user.user.uniqueId,
			isLastSignee: _user.id === data.lastApprovedById,
		}));
	}, [management, data]);

	const { employees, superintendents } = React.useMemo(() => {
		return employeeOptions.reduce((_result, _employee) => {
			const employee = {
				id: _employee.employee.accountId,
				fullName: _employee.employee.fullName,
				uniqueId: _employee.employee.uniqueId,
				isLastSignee: _employee.employee.accountId === data.lastApprovedById,
			};
			if (_employee.role === FieldReportAccessRole.FIELD_WORKER) {
				_result.employees.push(employee);
			} else if (_employee.role === FieldReportAccessRole.SUPERINTENDENT) {
				_result.superintendents.push(employee);
			}

			return _result;
		}, { employees: [], superintendents: [] } as { employees: WorkOrderEmployeeVM[]; superintendents: WorkOrderEmployeeVM[]; });
	}, [employeeOptions, data]);

	const hasCurrentUserRejectedTimeSheet = React.useMemo(
		() => data.employees.some((_ts) => isTimeSheetRejected(_ts) && _ts.accountId === currentAccountId), [data, currentAccountId]
	);
	const hasUnsignedTimeSheet = React.useMemo(() => !data.employees.some(isTimeSheetSigned), [data]);
	const unsignedTimeSheets = React.useMemo(() => {
		if (!hasUnsignedTimeSheet) {
			return [];
		}
		return filterMap(data.employees, isTimeSheetRejected, (_employee) => _employee.fullName);
	}, [data, hasUnsignedTimeSheet]);

	const isOfInterest = React.useMemo(() => {
		return !!fieldReportAccessRole
			&& !!data.calculatedReportDisplayStatus
			&& !!ReportDisplayStatusUtils.isCalculatedReportDisplayStatusOfInterestForRole(
				fieldReportAccessRole,
				data.calculatedReportDisplayStatus,
				currentAccountId,
				data.assignedToIds.filter(Boolean),
				hasCurrentUserRejectedTimeSheet
			);
	}, [data, fieldReportAccessRole, currentAccountId, hasCurrentUserRejectedTimeSheet]);

	const updateTimeAllocations = React.useCallback((timeSheetEntries: TimeSheetEntryRM[]) => {
		const summedTimes = timeSheetEntries.reduce(calculateTotalTimes, {
			totalJobDuration: 0,
			totalShopDuration: 0,
			totalTravelDuration: 0,
			totalBreakDuration: 0,
		});
		const timeAllocationEntries: TimeAllocationEntryRM[] = [];
		if (summedTimes.totalBreakDuration > 0) {
			timeAllocationEntries.push({
				time: TimeUtils.roundTimeDurationToInterval(summedTimes.totalBreakDuration, DEFAULT_TIME_DURATION_MINUTE_ROUNDING_INTERVAL),
				workType: TimeSheetEntryWorkType.BREAK,
				allocatedWorkRequestId: data.workRequestId,
			});
		}
		if (summedTimes.totalJobDuration > 0) {
			timeAllocationEntries.push({
				time: TimeUtils.roundTimeDurationToInterval(summedTimes.totalJobDuration, DEFAULT_TIME_DURATION_MINUTE_ROUNDING_INTERVAL),
				workType: TimeSheetEntryWorkType.JOB,
				allocatedWorkRequestId: data.workRequestId,
			});
		}
		if (summedTimes.totalShopDuration > 0) {
			timeAllocationEntries.push({
				time: TimeUtils.roundTimeDurationToInterval(summedTimes.totalShopDuration, DEFAULT_TIME_DURATION_MINUTE_ROUNDING_INTERVAL),
				workType: TimeSheetEntryWorkType.SHOP,
				allocatedWorkRequestId: data.workRequestId,
			});
		}
		if (summedTimes.totalTravelDuration > 0) {
			timeAllocationEntries.push({
				time: TimeUtils.roundTimeDurationToInterval(summedTimes.totalTravelDuration, DEFAULT_TIME_DURATION_MINUTE_ROUNDING_INTERVAL),
				workType: TimeSheetEntryWorkType.TRAVEL,
				allocatedWorkRequestId: data.workRequestId,
			});
		}
		return timeAllocationEntries;
	}, [data.workRequestId]);

	const handleApprove = React.useCallback(async (form: SignatureForm) => {
		if (isForSubmit) {
			let reviewLevel = data.reviewLevel + 1;
			if (data.reviewStatus === WorkOrderReviewStatus.DRAFT) {
				reviewLevel = WorkOrderReviewLevel.LEVEL_2;
			} else if (data.reviewLevel === WorkOrderReviewLevel.LEVEL_3) {
				reviewLevel = WorkOrderReviewLevel.LEVEL_3;
			}
			await submitForReview(data.id, SignatureForm.toSubmitForReviewRM(form, reviewLevel, revertOutdatedOnApprove));
		} else {
			await approveWorkOrder(data.id, SignatureForm.toApproveRM(form, null, revertOutdatedOnApprove));
		}
	}, [isForSubmit, data.reviewLevel, data.reviewStatus, data.id, submitForReview, revertOutdatedOnApprove, approveWorkOrder]);

	const handleFinalize = React.useCallback(async (form: SignatureRequestModel) => {
		await finalizeWorkOrder(data.id, form);
	}, [finalizeWorkOrder, data]);

	const handleReject = React.useCallback(async (form: RejectReviewRM) => {
		await rejectWorkOrder(data.id, form);
		refetch();
	}, [rejectWorkOrder, data, refetch]);

	const handleUpdateEquipment = React.useCallback((accountId: number, equipment: TimeSplitEntryRM[]) => {
		updateEquipment(data.id, accountId, equipment);
	}, [data, updateEquipment]);

	const handleUpdateSheet = React.useCallback(async (accountId: number, sheets: TimeSheetEntryRM[]) => {
		const allocations = updateTimeAllocations(sheets);
		await updateTimeSheet(data.id, accountId, { timeSheetEntries: sheets, timeAllocationEntries: allocations });
		loadOccupiedSlotsAction();
		refetch();
	}, [updateTimeAllocations, updateTimeSheet, data.id, loadOccupiedSlotsAction, refetch]);

	const handleRejectSheet = React.useCallback(() => {
		refreshData();
	}, [refreshData]);

	const handleApproveFinalize = React.useCallback(async () => {
		if (isFinalizing) {
			const canCompleteWorkSummaries = await checkIfCanCompleteWorkSummaries(data.id);
			if (!canCompleteWorkSummaries && data.reviewLevel === WorkOrderReviewLevel.LEVEL_3) {
				openCheckWorkSummaryModal();
			} else if (hasWorkOrderInvalidEquipmentTime(accountData)) {
				openFinalizeWarningModal();
			} else {
				openFinalizeModal();
			}
			return;
		}
		openApproveModal();
	}, [
		isFinalizing,
		openApproveModal,
		checkIfCanCompleteWorkSummaries,
		data.id,
		data.reviewLevel,
		accountData,
		openCheckWorkSummaryModal,
		openFinalizeWarningModal,
		openFinalizeModal,
	]);

	const handleSubmit = React.useCallback(async (revertOutdated?: boolean) => {
		const { hasOverlaps } = await hasWorkOrderOverlaps(data.id);
		if (hasOverlaps) {
			openSubmitWarningModal();
			return;
		}

		if (data.isPartOfProject && data.reviewLevel === WorkOrderReviewLevel.LEVEL_3) {
			const hasErrorInAllocations = hasWorkOrderInvalidAllocationTime(accountData);
			if (hasErrorInAllocations) {
				openAllocationsWarningModal();
				return;
			}
		}

		const trackedEntries = await findTrackedEntriesForWorkOrder(data.id);
		if (!!trackedEntries && trackedEntries.entries.length) {
			openApproveActiveShiftWarningModal();
			return;
		}

		if (revertOutdated) {
			enableRevert();
		} else {
			disableRevert();
		}

		handleApproveFinalize();
	}, [
		hasWorkOrderOverlaps,
		data.id,
		data.isPartOfProject,
		data.reviewLevel,
		findTrackedEntriesForWorkOrder,
		handleApproveFinalize,
		openSubmitWarningModal,
		openApproveActiveShiftWarningModal,
		enableRevert,
		disableRevert,
		accountData,
		openAllocationsWarningModal,
	]);

	const checkOutdatedAndApprove = React.useCallback(() => {
		if (data.status === WorkOrderStatus.OUTDATED) {
			openOutdatedModal();
			return;
		}
		handleSubmit();
	}, [data.status, handleSubmit, openOutdatedModal]);

	const handleConfirmEndAllShifts = React.useCallback(async () => {
		await endShiftsForWorkOrder(data.id);
		setUpdateRequiredToTrue();
		handleApproveFinalize();
	}, [endShiftsForWorkOrder, data, handleApproveFinalize, setUpdateRequiredToTrue]);

	const goToFullView = React.useCallback(() => {
		history.push(CLIENT.COMPANY.FIELD_REPORT.ALL_REPORTS(data.id.toString(), orgAlias, companyName));
	}, [history, data, orgAlias, companyName]);

	const startEditing = React.useCallback(async (accountId: number) => {
		setEditingMap({ ...editingMap, [accountId]: true });
		const slots = await getOccupiedSlots(data.id, accountId);
		setOccupiedSlotsMap({ ...occupiedSlotsMap, [accountId]: slots });
	}, [setEditingMap, getOccupiedSlots, editingMap, data, setOccupiedSlotsMap, occupiedSlotsMap]);

	const endEditing = React.useCallback((accountId: number) => {
		setEditingMap({ ...editingMap, [accountId]: false });
	}, [setEditingMap, editingMap]);

	const renderApproveFinalize = React.useCallback(() => {
		if (isFinalizing && hasPermissionToFinalize) {
			return (
				<Button
					disabled={updateRequired}
					onClick={checkOutdatedAndApprove}
					variant="primary"
				>
					{isFinalizing ? 'Finalize' : 'Approve'}
				</Button>
			);
		} else if (!isFinalizing) {
			return (
				<Button
					disabled={updateRequired}
					onClick={checkOutdatedAndApprove}
					variant="primary"
				>
					Approve
				</Button>
			);
		}
	}, [isFinalizing, hasPermissionToFinalize, updateRequired, checkOutdatedAndApprove]);

	const renderEmployeeRow = (employee: CondensedTableVM['employees'][0]) => {

		const isReadOnly = shouldReportForAccountBeReadOnly(
			data.reviewStatus,
			data.reviewLevel,
			data.status,
			false,
			isManagement ? FieldReportAccessRole.MANAGEMENT : fieldReportAccessRole,
			hasPermissionToEdit,
			isAccountingOrAdmin,
			false
		);

		return (
			<EmployeeRow
				canEdit={!isDone && hasPermissionToEdit && hasPermissionsForLevel && !updateRequired}
				change={change}
				classificationCodeList={classificationCodeList}
				data={employee}
				dueDate={data.dueDate}
				equipment={equipmentOptions}
				isReportReadonly={isReadOnly}
				isTemporaryEmployee={employee.isTemporaryEmployee}
				key={employee.accountId}
				occupiedSlots={occupiedSlotsMap[employee.accountId]}
				onEditEnd={endEditing}
				onEditStart={startEditing}
				onReject={handleRejectSheet}
				reviewStatus={data.reviewStatus}
				updateEquipment={handleUpdateEquipment}
				updateTimeSheet={handleUpdateSheet}
				workOrderId={data.id}
				workRequestId={data.workRequestId}
			/>
		);
	};

	const cardClassName = bemBlock('condensed-table__card', {
		disabled: !hasPermissionsForLevel || isDone,
		warning: updateRequired,
	});

	return (
		<>
			<div className={cardClassName}>
				{updateRequired && (
					<Tooltip message="Changes have been made" placement="right" >
						<span
							className="icon-clock condensed-table__work-order-refresh-indicator"
							onClick={refreshData}
						/>
					</Tooltip>
				)}
				<div className="condensed-table__work-order-details">
					<div className="condensed-table__work-order-details__code">{data.workOrderCode}</div>
					<div>
						<span className="condensed-table__work-order-details__state">{data.location.aa1}</span>
						<span className="condensed-table__work-order-details__address">{data.location.street}</span>
					</div>
					<div className="bold">{TimeUtils.formatDate(data.dueDate, TimeFormat.DATE_ONLY, TimeFormat.DB_DATE_ONLY)}</div>
				</div>
				<div className="condensed-table__review-details">
					{data.lastApprovedAt &&
						<div className="condensed-table__review-details__approved-by">
							Last approved {TimeUtils.formatDate(data.lastApprovedAt, TimeFormat.FULL_DATE_LONG, TimeFormat.ISO_DATETIME)}
							&nbsp;by {data.lastApprovedBy ?? UPDATED_BY_FAKE_USER.fullName}
						</div>
					}
					<DisplayReviewStatusLabel
						calculatedReportDisplayStatus={data.calculatedReportDisplayStatus}
						isCondensedView={true}
						isOfInterest={isOfInterest}
					/>
				</div>
				<div className="condensed-table__employee-list">
					<div className="condensed-table__employee-list-header">
						<div className="condensed-table__employee-list-employee">
							<div />
							<div>Name</div>
							<div />
							<div>Signature status</div>
							<div>Review status</div>
						</div>
						<div className="condensed-table__employee-list-sheet">
							<div className="bold condensed-table__employee-list-sheet__total">Total</div>
							<div className="label-with-icon condensed-table__employee-list-sheet__job"><span className="icon-equipment" /> <span>Job</span></div>
							<div className="label-with-icon condensed-table__employee-list-sheet__shop"><span className="icon-home" /> <span>Shop</span></div>
							<div className="label-with-icon condensed-table__employee-list-sheet__travel"><span className="icon-travel" /> <span>Travel</span></div>
							<div className="label-with-icon condensed-table__employee-list-sheet__break"><span className="icon-break" /> <span>Break</span></div>
						</div>
						<div className="condensed-table__employee-list-equipment-row">
							<div className="condensed-table__employee-list-equipment-row__equipmentName">Equipment</div>
							<div className="condensed-table__employee-list-equipment-row__time">Time</div>
							<div className="condensed-table__employee-list-equipment-row__classificationCode">Classification Code</div>
						</div>
					</div>
					{data.employees?.map(renderEmployeeRow)}
				</div>
				<div className="condensed-table__details">
					{!isDone && hasPermissionsForLevel && (
						<Button
							className="btn btn--link"
							onClick={goToFullView}
						>
							View details
						</Button>
					)}
				</div>
				<div className="condensed-table__actions">
					{!isDone && hasPermissionsForLevel && (
						<>
							{renderApproveFinalize()}
							{data.reviewLevel !== WorkOrderReviewLevel.LEVEL_0 && data.reviewStatus !== WorkOrderReviewStatus.REJECTED && (
								<Button
									disabled={updateRequired}
									onClick={openRejectModal}
									variant="danger"
								>
									Reject
								</Button>
							)}
						</>
					)}
				</div>
			</div>
			<ApproveModal
				closeModal={closeApproveModal}
				descriptionClassName="condensed-table__approve-description"
				isDigitalSignatureEnabled={isDigitalSignatureEnabled}
				isReadOnly={false}
				onSubmit={handleApprove}
				showModal={showApproveModal}
				unsignedTimeSheets={unsignedTimeSheets}
			/>
			<RejectModal
				accountants={accountingOptions}
				closeModal={closeRejectModal}
				employees={employees}
				managers={managementOptions}
				onReject={handleReject}
				reviewLevel={data.reviewLevel}
				reviewStatus={data.reviewStatus}
				showModal={showRejectModal}
				superintendents={superintendents}
				workOrderCode={data.workOrderCode}
			/>
			<FinalizeModal
				closeModal={closeFinalizeModal}
				dueDate={data.dueDate}
				hasUnsignedTimeSheet={hasUnsignedTimeSheet}
				onSubmit={handleFinalize}
				showModal={showFinalizeModal}
				workOrderId={data.id}
			/>
			<OutdatedChangesModal
				approve={handleSubmit}
				closeModal={closeOutdatedModal}
				isSubmit={false}
				revision={data.revision}
				revisionCreatedAt={data.revisionCreatedAt}
				showModal={showOutdatedModal}
				statusBasedRevision={data.statusBasedRevision}
				woCode={data.workOrderCode}
				workOrderId={data.id}
				workOrderOutdatedDifferences={workOrderOutdatedDifferences}
				woUpdatedAt={data.woUpdatedAt}
			/>
			<WarningModal
				body={SYNC_WARNING_BODY}
				closeModal={refreshData}
				confirmAction={refreshData}
				confirmText="Refresh"
				modalStyle="info"
				showCancel={false}
				showModal={showWarningModal}
				title={SYNC_WARNING_HEADER}
			/>
			<WarningModal
				body={APPROVE_WARNING_BODY}
				closeModal={closeSubmitWarningModal}
				confirmAction={closeSubmitWarningModal}
				confirmText="Close"
				modalStyle="info"
				showCancel={false}
				showModal={showSubmitWarningModal}
				title={APPROVE_WARNING_HEADER}
			/>
			<WarningModal
				body={FINALIZE_WARNING_BODY}
				closeModal={closeFinalizeWarningModal}
				confirmAction={closeFinalizeWarningModal}
				confirmText="Close"
				modalStyle="info"
				showCancel={false}
				showModal={showFinalizeWarningModal}
				title={FINALIZE_WARNING_HEADER}
			/>
			<WarningModal
				body={ALLOCATIONS_WARNING_BODY}
				closeModal={closeAllocationsWarningModal}
				confirmAction={closeAllocationsWarningModal}
				confirmText="Close"
				modalStyle="info"
				showCancel={false}
				showModal={showAllocationsWarningModal}
				title={ALLOCATIONS_WARNING_HEADER}
			/>
			<WarningModal
				body={APPROVE_ACTIVE_SHIFT_WARNING_BODY}
				closeModal={closeApproveActiveShiftWarningModal}
				confirmAction={handleConfirmEndAllShifts}
				confirmText="Continue"
				modalStyle="warning"
				showCancel={true}
				showModal={showApproveActiveShiftWarningModal}
				title={APPROVE_ACTIVE_SHIFT_WARNING_HEADER}
			/>
			<ConfirmationModal
				body={CHECK_WORK_SUMMARY_MODAL_BODY}
				closeModal={closeCheckWorkSummaryModal}
				confirmAction={closeCheckWorkSummaryModal}
				confirmText="Close"
				modalStyle="warning"
				showCancel={false}
				showModal={showCheckWorkSummaryModal}
				title="Check Work Summary"
			/>
		</>
	);
};

function mapStateToProps(state: RootState, props: OwnProps) {
	const { data } = props;
	const { user: { companyData, userData } } = state;

	if (!userData || !companyData) {
		throw new Error('User not logged in');
	}

	const { isCompanyAdmin, permissions } = companyData;

	const workOrderMap: FormModel['workOrderMap'] = formValueSelector(FORMS.CONDENSED_TABLE_EMPLOYEE)(state, 'workOrderMap');
	const workOrderData = workOrderMap?.[data.id];
	const accountData: EmployeeDataFM[] = Object.values(workOrderData ?? {});

	return {
		accountData,
		isDigitalSignatureEnabled: userData.isDigitalSignatureEnabled,
		isAccountingOrAdmin: isAllowed(PagePermissions.COMPANY.FIELD_REPORT.MANAGE.ACCOUNTING, permissions, isCompanyAdmin, userData.role),
	};
}

function mapDispatchToProps() {
	return {
		fetchOutdatedWorkOrderDifferences: WorkOrderActions.fetchOutdatedWorkOrderDifferences,
		checkIfCanCompleteWorkSummaries: WorkOrderActions.checkIfCanCompleteWorkSummaries,
	};
}

const connector = connect(mapStateToProps, mapDispatchToProps());

const enhance = compose(connector, withRouter);

export default enhance(CondensedRow);
