import * as React from 'react';
import type { ResolveThunks } from 'react-redux';
import { connect } from 'react-redux';
import { useNavigate } from 'react-router-dom-v5-compat';

import CLIENT from 'af-constants/routes/client';

import * as ScheduleBoardActions from 'af-actions/scheduleBoard';

import ScheduleBoardView from 'ab-enums/scheduleBoardView.enum';

import * as ScheduleBoardUtil from 'af-utils/scheduleBoard.util';

import MultipleOptionsButton from 'af-components/MultipleOptionsButton';
import RectangleButton from 'af-components/MultipleOptionsButton/RectangleButton';

import { SCREEN_BREAKPOINT_M } from 'af-constants/values';

import type { RootState } from 'af-reducers';

interface OwnProps {
	refreshConnectionCount: (dates: string[]) => void;
	view: Nullable<ScheduleBoardView>;
	history: Metadata;
	orgAlias: string;
	companyName: string;
}

interface StateProps {
	date: string | undefined;
	dates: string[];
}

interface DispatchProps {
	clearDailyView: typeof ScheduleBoardActions.clearDailyView;
	clearWeeklyView: typeof ScheduleBoardActions.clearWeeklyView;
}

type Props = OwnProps & StateProps & ResolveThunks<DispatchProps>;

const ViewSwitcher: React.FC<Props> = (props) => {

	const {
		view,
		clearDailyView,
		clearWeeklyView,
		dates,
		date,
		refreshConnectionCount,
		orgAlias,
		companyName,
	} = props;

	const [isTimeFormatShort, setIsTimeFormatShort] = React.useState(window.innerWidth < SCREEN_BREAKPOINT_M);

	const navigate = useNavigate();

	const updateWindowDimensions = () => {
		setIsTimeFormatShort(window.innerWidth < SCREEN_BREAKPOINT_M);
	};
	React.useEffect(() => {
		updateWindowDimensions();
		window.addEventListener('resize', updateWindowDimensions);

		return () => {
			window.removeEventListener('resize', updateWindowDimensions);
		};
	}, []);

	const clearRedux = React.useCallback(() => {

		ScheduleBoardUtil.clearScheduleBoardRedux(
			view,
			clearDailyView,
			clearWeeklyView
		);
	}, [clearDailyView, clearWeeklyView, view]);

	const refreshConnectionsCount = React.useCallback(() => {
		switch (view) {
			case ScheduleBoardView.DAILY_VIEW:
				if (!date) {
					throw new Error('Date is required on daily view');
				}
				refreshConnectionCount([date]);
				break;
			case ScheduleBoardView.WEEKLY_VIEW:
				refreshConnectionCount(dates);
				break;
		}
	}, [date, dates, refreshConnectionCount, view]);

	const redirectToDaily = React.useCallback(() => {

		if (view !== ScheduleBoardView.DAILY_VIEW) {
			refreshConnectionsCount();
			clearRedux();
			navigate(CLIENT.COMPANY.SCHEDULE_BOARD.DAILY_VIEW(orgAlias, companyName));
		}
	}, [clearRedux, companyName, navigate, orgAlias, refreshConnectionsCount, view]);

	const redirectToWeekly = React.useCallback(() => {

		if (view !== ScheduleBoardView.WEEKLY_VIEW) {
			refreshConnectionsCount();
			clearRedux();
			navigate(CLIENT.COMPANY.SCHEDULE_BOARD.WEEKLY_VIEW(orgAlias, companyName));
		}
	}, [clearRedux, companyName, navigate, orgAlias, refreshConnectionsCount, view]);

	return (
		<MultipleOptionsButton>
			<RectangleButton
				action={redirectToDaily}
				isActive={view === ScheduleBoardView.DAILY_VIEW}
				isSquare={isTimeFormatShort}
				label={isTimeFormatShort ? 'D' : 'Daily'}
				tooltipMessage="Switch to Daily View"
				tooltipPlacement="bottom"
			/>
			<RectangleButton
				action={redirectToWeekly}
				isActive={view === ScheduleBoardView.WEEKLY_VIEW}
				isSquare={isTimeFormatShort}
				label={isTimeFormatShort ? 'W' : 'Weekly'}
				tooltipMessage="Switch to Weekly View"
				tooltipPlacement="bottom"
			/>
		</MultipleOptionsButton>
	);
};

function mapStateToProps(state: RootState): StateProps {
	return {
		date: state.scheduleBoard.date ?? undefined,
		dates: state.scheduleBoard.dates,
	};
}

function mapDispatchToProps(): DispatchProps {
	return {
		clearDailyView: ScheduleBoardActions.clearDailyView,
		clearWeeklyView: ScheduleBoardActions.clearWeeklyView,
	};
}

export default connect<StateProps, DispatchProps, OwnProps>(mapStateToProps, mapDispatchToProps())(ViewSwitcher);
