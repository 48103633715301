import * as React from 'react';
import { nanoid } from 'nanoid';

import Tooltip from 'af-components/Tooltip';

import Tab from './Tab';

const NAVIGATION_ID_PREFIX = 'navigation-component-id';
const TABS_ID_PREFIX = 'tabs-id';
const SCROLL_OFFSET = 500;
const SCROLL_ICON_WIDTH = 24;
const ID_SIZE = 7;

interface TabProps {
	label: string;
	id: number;
	resolveCustomClassName?: (active: boolean) => string;
	generateTabKey?: () => string;
	hide?: boolean;
}

interface OwnProps {
	tabs: TabProps[];
	active: Nullable<number>;
	onClick: (id: number) => void;
	className?: string;
	navigationClassName?: string;
	spreadTabs?: boolean;
	renderLabel?: (id: number) => JSX.Element;
	onEditTabs?: () => void;
}

type Props = OwnProps;

interface State {
	showScrollLeftIcon: boolean;
	showScrollRightIcon: boolean;
}

class TabNavigation extends React.PureComponent<Props, State> {
	state: State = {
		showScrollLeftIcon: false,
		showScrollRightIcon: false,
	};

	private readonly _navigationId = `${NAVIGATION_ID_PREFIX}-${nanoid(ID_SIZE)}`;
	private readonly _tabsId = `${TABS_ID_PREFIX}-${nanoid(ID_SIZE)}`;

	componentDidMount() {
		this.updateShowScrollRightIcon();
	}

	componentDidUpdate(prevProps: Props) {
		if (prevProps.tabs.length !== this.props.tabs.length) {
			this.updateShowScrollRightIcon();
		}
	}

	updateShowScrollRightIcon = () => {
		const navigationNode = document.getElementById(this._navigationId);
		if (!navigationNode) {
			throw new Error('navigationNode not found');
		}
		const tabsNode = document.getElementById(this._tabsId);
		if (!tabsNode) {
			throw new Error('tabsNode not found');
		}

		const visibleWidth = this.props.onEditTabs ? navigationNode.scrollWidth - SCROLL_ICON_WIDTH : navigationNode.scrollWidth;
		this.setState(() => ({ showScrollRightIcon: tabsNode.scrollWidth > visibleWidth }));
	};

	scrollLeft = () => {
		const tabsNode = document.getElementById(this._tabsId);
		if (!tabsNode) {
			throw new Error('tabsNode not found');
		}

		tabsNode.scrollTo({ left: tabsNode.scrollLeft - SCROLL_OFFSET, behavior: 'smooth' });
		const positionAfterScroll = tabsNode.scrollLeft - SCROLL_OFFSET;

		this.setState(() => ({ showScrollLeftIcon: positionAfterScroll > 0, showScrollRightIcon: true }));
	};

	scrollRight = () => {
		const tabsNode = document.getElementById(this._tabsId);
		if (!tabsNode) {
			throw new Error('tabsNode not found');
		}
		const navigationNode = document.getElementById(this._navigationId);
		if (!navigationNode) {
			throw new Error('navigationNode not found');
		}

		tabsNode.scrollTo({ left: tabsNode.scrollLeft + SCROLL_OFFSET, behavior: 'smooth' });
		const positionAfterScroll = tabsNode.scrollLeft + SCROLL_OFFSET;
		const overflowWidth = tabsNode.scrollWidth - navigationNode.scrollWidth;

		this.setState(() => ({ showScrollLeftIcon: true, showScrollRightIcon: positionAfterScroll <= overflowWidth }));
	};

	renderTab = (tab: TabProps) => {
		const { active, onClick, renderLabel } = this.props;

		if (tab.hide) {
			return null;
		}

		return (
			<Tab
				id={tab.id}
				isActive={active === tab.id}
				key={tab?.generateTabKey?.() ?? tab.id}
				label={tab.label}
				onClick={onClick}
				renderLabel={renderLabel}
				resolveCustomClassName={tab.resolveCustomClassName}
			/>
		);
	};

	render() {
		const { tabs, className: _className, navigationClassName: _navigationClassName, onEditTabs, spreadTabs } = this.props;
		const { showScrollRightIcon, showScrollLeftIcon } = this.state;

		let className = 'tabs-navigation';
		className = _className ? `tabs-navigation ${_className}` : className;

		if (spreadTabs) {
			className = `${className} tabs-navigation-uniform`;
		}

		let navigationClassName = 'tabs-navigation__wrapper';
		navigationClassName = _navigationClassName ? `${navigationClassName} ${_navigationClassName}` : navigationClassName;

		return (
			<div className={navigationClassName} id={this._navigationId}>
				{showScrollLeftIcon && <span className="icon-left" onClick={this.scrollLeft} />}
				<div className={className} id={this._tabsId}>
					{tabs.map(this.renderTab)}
				</div>
				{showScrollRightIcon && <span className="icon-right" onClick={this.scrollRight} />}
				{onEditTabs && (
					<Tooltip message="Edit">
						<span className="icon-edit" onClick={onEditTabs} />
					</Tooltip>
				)}
			</div>
		);
	}
}

export default TabNavigation;
