import TimeFormat from 'acceligent-shared/enums/timeFormat';
import type Priority from 'acceligent-shared/enums/priority';
import type WorkRequestBidStatus from 'acceligent-shared/enums/workRequestBidStatus';

import { TableContent } from 'ab-common/dataStructures/tableContent';

import { FAKE_ACCOUNT_DATA } from 'acceligent-shared/constants/value';

import { getUserName } from 'acceligent-shared/utils/user';
import { formatDate } from 'acceligent-shared/utils/time';

import type WorkRequestBase from 'ab-domain/models/workRequest/base';
import type UserBase from 'ab-domain/models/user/base';
import type AccountBase from 'ab-domain/models/account/base';

export class W_WorkRequest_FindWorkRequestsTable_VM extends TableContent<W_WorkRequest_FindWorkRequestsTable_VM_Row>{
	constructor(workRequests: WorkRequestBase[], pages: number, totalCount: number) {
		super(
			W_WorkRequest_FindWorkRequestsTable_VM_Row.bulkConstructor(workRequests),
			pages,
			totalCount
		);
	}
}

class W_WorkRequest_FindWorkRequestsTable_VM_Row {
	id: number;
	bidStatus: WorkRequestBidStatus;
	jobCode: string;
	title: string;
	customerCompanyName: Nullable<string>;
	city: Nullable<string>;
	state: Nullable<string>;
	office: Nullable<string>;
	projectManager: Nullable<string>;
	/** YYYY-MM-DDTHH:mm:ss.SSSZ */
	updatedAt: Nullable<string>;
	priority: Priority;
	updatedBy: W_WorkRequest_FindWorkRequestsTable_VM_UpdatedBy;
	/** YYYY-MM-DD */
	startDate: Nullable<string>;
	/** YYYY-MM-DD */
	targetCompletionDate: Nullable<string>;
	/** YYYY-MM-DD */
	guaranteedCompletionDate: Nullable<string>;
	estimateTotal: Nullable<number>;
	convertedToJobId: Nullable<number>;

	constructor(workRequest: WorkRequestBase) {
		const _projectManager: Nullable<UserBase> = workRequest?.projectManager?.account?.user ?? null;

		this.id = workRequest.id;
		this.jobCode = workRequest.jobCode;
		this.title = workRequest.title;
		this.startDate = workRequest.startDate;
		this.updatedBy = new W_WorkRequest_FindWorkRequestsTable_VM_UpdatedBy(workRequest.updatedBy);
		this.updatedAt = workRequest.updatedAt ? formatDate(workRequest.updatedAt, TimeFormat.ISO_DATETIME, TimeFormat.ISO_DATETIME) : null;
		this.office = workRequest?.office?.nickname ?? null;

		this.targetCompletionDate = workRequest.targetCompletionDate;
		this.guaranteedCompletionDate = workRequest.guaranteedCompletionDate;
		this.estimateTotal = workRequest?.estimateTotal;

		this.projectManager = _projectManager ? `${_projectManager.firstName} ${_projectManager.lastName}` : null;
		this.customerCompanyName = workRequest.customerCompanyName;
		this.bidStatus = workRequest.bidStatus;

		this.city = workRequest.travelLocation?.locality ?? null;
		this.state = workRequest.travelLocation?.aa1 ?? null;
		this.priority = workRequest.priority;

		this.convertedToJobId = workRequest.convertedToJobId;

	}

	static bulkConstructor = (_workRequests: WorkRequestBase[]) => _workRequests.map((_wr) => new W_WorkRequest_FindWorkRequestsTable_VM_Row(_wr));
}

class W_WorkRequest_FindWorkRequestsTable_VM_UpdatedBy {
	accountId: number;
	userId: number;
	firstName: string;
	lastName: string;
	fullName: string;
	fullNameShort: string;

	constructor(account: Nullable<AccountBase>) {
		const accountObject = account ?? { id: FAKE_ACCOUNT_DATA.ACCOUNT_ID } as unknown as AccountBase;
		const accountUser = accountObject.user || { id: accountObject.userId || FAKE_ACCOUNT_DATA.USER_ID } as UserBase;

		this.accountId = accountObject.id;
		this.userId = accountObject.userId;
		this.firstName = accountUser.firstName;
		this.lastName = accountUser.lastName;
		this.fullName = getUserName(accountUser);
		this.fullNameShort = getUserName(accountUser, true);
	}
}
