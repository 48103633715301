import * as React from 'react';

import { chunkArray } from 'ab-utils/array.util';
import type { EmployeeNightShiftAssignmentMapVM, TemporaryEmployeeNightShiftAssignmentMapVM } from 'ab-utils/nightShift.util';

import type { DisplayViewResourceViewModel } from 'ab-viewModels/scheduleBoardDisplayView.viewModel';

import { DISPLAY_VIEW_NUMBER_OF_RESOURCES_IN_WORK_ORDER_COLUMN } from 'af-constants/values';

import ResourceColumn from './ResourceColumn';

interface OwnProps {
	employeeNightShiftAssignments: EmployeeNightShiftAssignmentMapVM;
	hideMultipleAssignmentBadges: boolean;
	resources: DisplayViewResourceViewModel[];
	temporaryEmployeeNightShiftAssignments: TemporaryEmployeeNightShiftAssignmentMapVM;
}

type Props = OwnProps;

const Resources: React.FC<Props> = (props: Props) => {
	const { hideMultipleAssignmentBadges, employeeNightShiftAssignments, temporaryEmployeeNightShiftAssignments, resources } = props;

	const renderResourceColumn = React.useCallback((columnResources: DisplayViewResourceViewModel[], index: number) => {
		return (
			<ResourceColumn
				employeeNightShiftAssignments={employeeNightShiftAssignments}
				hideMultipleAssignmentBadges={hideMultipleAssignmentBadges}
				key={index}
				resources={columnResources}
				temporaryEmployeeNightShiftAssignments={temporaryEmployeeNightShiftAssignments}
			/>
		);
	}, [hideMultipleAssignmentBadges, employeeNightShiftAssignments, temporaryEmployeeNightShiftAssignments]);

	const resourceGroups = React.useMemo(
		() => chunkArray<DisplayViewResourceViewModel>(resources, DISPLAY_VIEW_NUMBER_OF_RESOURCES_IN_WORK_ORDER_COLUMN),
		[resources]
	);

	return (
		<div className="display-view-card-resources">
			<div className="resource-columns-wrapper">
				{resourceGroups.map(renderResourceColumn)}
			</div>
		</div>
	);
};

export default Resources;
