import * as React from 'react';
import { Row, Col, Button } from 'react-bootstrap';
import type { WrappedFieldArrayProps } from 'redux-form';
import { Field } from 'redux-form';

import type ContactRM from 'ab-requestModels/contact/upsert';
import { PhoneTypeNames, PhoneTypes } from 'acceligent-shared/enums/contactMethodType';
import UpsertContactStatusEnum from 'acceligent-shared/enums/upsertContactStatus';
import CountryCode from 'acceligent-shared/enums/countryCode';

import Input from 'af-fields/Input';
import Dropdown from 'af-fields/Dropdown';

import Tooltip from 'af-components/Tooltip';

import { phoneNormalizer } from 'ab-utils/reduxForms.util';

interface PhoneTypeOption {
	id: PhoneTypes;
	label: string;
}

export interface OwnProps {
	customError?: string;
	onRemoveContactMethod: (email: ContactRM['phones'][0]) => void;
	change: (fieldName: string, value: UpsertContactStatusEnum) => void;
}

type Props = OwnProps & WrappedFieldArrayProps<ContactRM['phones'][0]>;

class PhoneContactMethods extends React.Component<Props> {

	static _phoneTypeOptions: PhoneTypeOption[] = Object.keys(PhoneTypes)
		.map((_key: PhoneTypes) => ({ id: _key, label: PhoneTypeNames[_key] }));

	addContactMethod = () => {
		const { fields } = this.props;

		fields.push({
			type: PhoneTypes.PHONE_DIRECT,
			countryCode: CountryCode.US,
			status: UpsertContactStatusEnum.ADDED,
		} as ContactRM['phones'][0]);
	};

	updateContactMethod = (index: number, fieldName: string) => {
		const { change, fields } = this.props;
		const field = fields.get(index);
		if (field.status === UpsertContactStatusEnum.OLD) {
			change(`${fieldName}.status`, UpsertContactStatusEnum.EDITED);
		}
	};

	removeContactMethod = (index: number) => {
		const { onRemoveContactMethod, fields } = this.props;

		const removedMethod = fields.get(index);
		fields.remove(index);
		if (removedMethod.status !== UpsertContactStatusEnum.ADDED) {
			onRemoveContactMethod({ ...removedMethod, status: UpsertContactStatusEnum.REMOVED });
		}
	};

	renderMethodTypes = () => {
		const { fields } = this.props;

		return fields.map((_field: string, _index: number) => (
			<Row key={_index}>
				<Col sm={6}>
					<Field
						component={Input}
						name={`${_field}.value`}
						normalize={phoneNormalizer}
						onValueChange={this.updateContactMethod.bind(this, _index, _field)}
						placeholder="Number"
						type="text"
					/>
				</Col>
				<Col sm={6}>
					<Field
						component={Dropdown}
						id={_field}
						labelKey="label"
						name={`${_field}.type`}
						onValueChange={this.updateContactMethod.bind(this, _index, _field)}
						options={PhoneContactMethods._phoneTypeOptions}
						placeholder="Type"
						valueKey="id"
						withCaret={true}
					/>
				</Col>
				<Col sm={6}>
					<Field
						component={Input}
						id={_field}
						name={`${_field}.extension`}
						onValueChange={this.updateContactMethod.bind(this, _index, _field)}
						placeholder="Extension"
					/>
				</Col>
				<Col sm={1}>
					<Tooltip message="Delete">
						<Button
							className="btn btn--flat btn--icon"
							onClick={this.removeContactMethod.bind(this, _index)}
						>
							<span className="icon-delete" />
						</Button>
					</Tooltip>
				</Col>
			</Row>
		));
	};

	render() {
		const { meta: { error, submitFailed }, customError } = this.props;

		return (
			<div className="contact-method contact-method--non-padded">
				<div className="contact-method__header">
					Mobile Phone
				</div>
				{this.renderMethodTypes()}
				<Row>
					<Col sm={12}>
						<Button className="btn btn--link" onClick={this.addContactMethod}>
							<span className="icon-plus" />
							<span>Add Mobile Phone</span>
						</Button>
						{submitFailed && error && <span>{error}</span>}
						{customError && <span className="help-block"><span className="icon-info"></span>{customError}</span>}
					</Col>
				</Row>
			</div>
		);
	}
}

export default PhoneContactMethods;
