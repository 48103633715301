import * as React from 'react';
import { useOutletContext } from 'react-router-dom-v5-compat';

import type { JobPreviewOutletContext } from 'af-root/scenes/Company/Jobs/Preview/types';

import JobHazardSummarySection from './Sections/JobHazardSummarySection';
import PermitsRequiredSection from './Sections/PermitsRequiredSection';
import SafetySection from './Sections/SafetySection';
import EnvironmentalProtectionSection from './Sections/EnvironmentalProtectionSection';
import PublicSafetySection from './Sections/PublicSafetySection';
import JobHazardAssessmentStatusSection from './Sections/JobHazardAssessmentStatusSection';
import styles from '../styles.module.scss';

const JobHazardAssesmentPreviewTab: React.FC = () => {
	const { jobHazardAssessment } = useOutletContext<JobPreviewOutletContext>();
	return (
		<div className={styles['work-request-preview__tab-content']}>
			<JobHazardSummarySection jobHazardSummary={jobHazardAssessment.hazardSummary} />
			<PermitsRequiredSection permitsRequired={jobHazardAssessment.permitsRequired} />
			<SafetySection
				dangerousAnimalsOrPlants={jobHazardAssessment.dangerousAnimalsOrPlants}
				dangerousAnimalsOrPlantsDescription={jobHazardAssessment.dangerousAnimalsOrPlantsDescription}
				electricalHazards={jobHazardAssessment.electricalHazards}
				electricalHazardsDescription={jobHazardAssessment.electricalHazardsDescription}
				environmentalExposure={jobHazardAssessment.environmentalExposure}
				environmentalExposureDescription={jobHazardAssessment.environmentalExposureDescription}
				hazardousMaterials={jobHazardAssessment.hazardousMaterials}
				hazardousMaterialsDescription={jobHazardAssessment.hazardousMaterialsDescription}
				heightWork={jobHazardAssessment.heightWork}
				heightWorkDescription={jobHazardAssessment.heightWorkDescription}
				mechanicalHazards={jobHazardAssessment.mechanicalHazards}
				mechanicalHazardsDescription={jobHazardAssessment.mechanicalHazardsDescription}
				overheadHazards={jobHazardAssessment.overheadHazards}
				overheadHazardsDescription={jobHazardAssessment.overheadHazardsDescription}
				respiratoryProtection={jobHazardAssessment.respiratoryProtection}
				respiratoryProtectionDescription={jobHazardAssessment.respiratoryProtectionDescription}
			/>
			<EnvironmentalProtectionSection
				bypassPumping={jobHazardAssessment.bypassPumping}
				bypassPumpingDescription={jobHazardAssessment.bypassPumpingDescription}
				sensitiveEnvironment={jobHazardAssessment.sensitiveEnvironment}
				sensitiveEnvironmentDescription={jobHazardAssessment.sensitiveEnvironmentDescription}
				stormDrain={jobHazardAssessment.stormDrain}
				stormDrainDescription={jobHazardAssessment.stormDrainDescription}
				waterOrWetlands={jobHazardAssessment.waterOrWetlands}
				waterOrWetlandsDescription={jobHazardAssessment.waterOrWetlandsDescription}
			/>
			<PublicSafetySection
				pedestrianCrossing={jobHazardAssessment.pedestrianCrossing}
				pedestrianCrossingDescription={jobHazardAssessment.pedestrianCrossingDescription}
				vehicleTraffic={jobHazardAssessment.vehicleTraffic}
				vehicleTrafficDescription={jobHazardAssessment.vehicleTrafficDescription}
			/>
			<JobHazardAssessmentStatusSection status={jobHazardAssessment.jobHazardAssessmentStatus} />
		</div>
	);
};

export default React.memo(JobHazardAssesmentPreviewTab);
