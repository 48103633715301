import type { TableQuery } from 'ab-common/dataStructures/tableQuery';

import baseRoute from '../baseRoute';

export * from './viewModel';
export * from './queryParams';

type RouteDefinition = `${typeof baseRoute}/${number | ':equipmentId'}/relatedWorkOrders${`?query=${string}&startDate=${string}&endDate=${string}` | ''}`;

export const ROUTE: RouteDefinition = `${baseRoute}/:equipmentId/relatedWorkOrders`;

export const URL = (equipmentId: number, startDate: string, endDate: string, tableQuery: TableQuery): RouteDefinition =>
	`${baseRoute}/${equipmentId}/relatedWorkOrders?query=${encodeURIComponent((JSON.stringify(tableQuery)))}&startDate=${startDate}&endDate=${endDate}`;
