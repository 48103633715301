import type WorkOrderCopyRM from 'ab-requestModels/workOrder/copyWorkOrders.requestModel';

export default (values: WorkOrderCopyRM) => {
	const errors: ValidationErrors = {};

	if (values.intervalSelection) {
		if (!values.startDate) {
			errors.startDate = 'Start Date is required.';
		}
		if (!values.endDate) {
			errors.endDate = 'End Date is required.';
		}

		if (
			!values.monday &&
			!values.tuesday &&
			!values.wednesday &&
			!values.thursday &&
			!values.friday &&
			!values.saturday &&
			!values.sunday
		) {
			errors._error = 'At least one day has to be selected.';
		}
	} else if (!values.startDate) {
		errors.startDate = 'Copy Date is required.';
	}

	return errors;
};
