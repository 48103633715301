import * as React from 'react';
import { Row, Col } from 'react-bootstrap';

import type ContactVM from 'ab-viewModels/contact/contact';
import { PhoneTypes } from 'acceligent-shared/enums/contactMethodType';

import PhonePreview from 'af-components/ContactMethodPreview/PhonePreview';

interface PhoneState {
	value: string;
	type: PhoneTypes;
	isNew: boolean;
}

interface OwnProps {
	selectedContactPhones: ContactVM['phones'];
	unsavedContactPhone: string;
}

interface State {
	phones: PhoneState[];
}

type Props = OwnProps;

class PhoneList extends React.PureComponent<Props, State> {
	static defaultProps: Partial<Props> = {
		selectedContactPhones: [],
	};

	state: State = {
		phones: [],
	};

	static getDerivedStateFromProps(props: Props): State {
		const { selectedContactPhones, unsavedContactPhone } = props;

		const phones: PhoneState[] = [];
		let isPhoneDuplicate = false;

		selectedContactPhones.forEach((_phone) => {
			if (_phone.value === unsavedContactPhone) {
				isPhoneDuplicate = true;
			}
			phones.push({
				value: _phone.value,
				type: _phone.type as PhoneTypes,
				isNew: false,
			});
		});

		if (unsavedContactPhone && !isPhoneDuplicate) {
			phones.push({
				value: unsavedContactPhone,
				type: PhoneTypes.PHONE_DIRECT,
				isNew: !!selectedContactPhones.length,
			});
		}

		return { phones };
	}
	render() {
		const { phones } = this.state;

		if (!phones.length) {
			return null;
		}

		return (
			<>
				<Row>
					<Col sm={24}>Phone</Col>
				</Row>
				<Row className="row--padded-bottom">
					{phones.map((_phone, _index) => (
						<Col key={_index} sm={24}>
							<PhonePreview
								isNew={_phone.isNew}
								type={_phone.type}
								value={_phone.value}
							/>
						</Col>
					))}
				</Row>
			</>
		);
	}
}

export default PhoneList;
