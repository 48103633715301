import * as React from 'react';

import * as ReportBlockFieldEnum from 'acceligent-shared/enums/reportBlockField';
import { ExtendedColorPalette } from 'acceligent-shared/enums/color';
import type RepeatableBlockType from 'acceligent-shared/enums/repeatableBlockType';

import type { PCRBlockFieldVM } from 'ab-viewModels/report/publicConfirmation.viewModel';

import Field from './Field';

interface OwnProps {
	index: number;
	repeatableBlockType: Nullable<RepeatableBlockType>;
	value?: string | (string | undefined)[];
	reportBlockField: PCRBlockFieldVM;
	renderLabel: boolean;
	renderValue: boolean;
	inverse?: boolean;
}

type Props = OwnProps;

const RepeatingFieldReportBlockField: React.FC<Props> = (props: Props) => {
	const {
		index,
		value,
		reportBlockField: {
			unit,
			name,
			dimension,
			descriptiveTextColor,
			fieldType,
			isDescriptiveTextBold,
		},
		repeatableBlockType,
		renderLabel,
		renderValue,
		inverse = false,
	} = props;

	const _value = value?.[index] ?? undefined;
	const isImageField = fieldType === ReportBlockFieldEnum.Type.IMAGE;

	return (
		<Field
			descriptiveTextColor={descriptiveTextColor ?? ExtendedColorPalette.BLACK}
			dimension={dimension}
			fieldType={fieldType}
			inverse={inverse}
			isDescriptiveTextBold={isDescriptiveTextBold}
			isImage={isImageField}
			name={name}
			renderLabel={renderLabel}
			renderValue={renderValue}
			repeatableBlockType={repeatableBlockType}
			unit={unit}
			value={_value}
		/>
	);
};

export default React.memo(RepeatingFieldReportBlockField);
