import * as React from 'react';
import type { HandleThunkActionCreator } from 'react-redux';
import type { InjectedFormProps } from 'redux-form';
import { reduxForm } from 'redux-form';
import { Button } from 'react-bootstrap';

import type * as CompanyActions from 'af-actions/companies';

import SubmitButton from 'af-components/SubmitButton';
import CustomModal from 'af-components/CustomModal';

import { AUTOMATIC_NOTIFICATIONS } from 'af-constants/reduxForms';

import AutomaticNotificationsForm from './Form';
import type { AutomaticNotifications } from '../formModel';

interface OwnProps {
	isVisible: boolean;
	toggleEdit: () => void;
	editAutomaticNotifications: HandleThunkActionCreator<typeof CompanyActions.editAutomaticNotifications>;
}

type Props = OwnProps & InjectedFormProps<AutomaticNotifications, OwnProps>;

class AutomaticNotificationsEdit extends React.PureComponent<Props> {

	editCompanyFeatureFlags = async (form: AutomaticNotifications) => {
		const { toggleEdit, editAutomaticNotifications } = this.props;

		await editAutomaticNotifications(form);
		toggleEdit();
	};

	onClose = () => {
		const { toggleEdit, reset } = this.props;
		reset();
		toggleEdit();
	};

	render() {
		const { handleSubmit, isVisible, invalid, submitting } = this.props;

		return (
			<CustomModal
				closeModal={this.onClose}
				modalStyle="info"
				showModal={isVisible}
				size="lg"
			>
				<CustomModal.Header
					closeModal={this.onClose}
					title="Edit Automatic Notifications"
				/>
				<CustomModal.Body padding="none">
					<AutomaticNotificationsForm
						formName={AUTOMATIC_NOTIFICATIONS}
						handleSubmit={handleSubmit}
						onSubmit={this.editCompanyFeatureFlags}
					/>
				</CustomModal.Body>
				<CustomModal.Footer>
					<Button
						id="accqa__notification-settings__automatic__cancel"
						onClick={this.onClose}
						variant="info"
					>
						Cancel
					</Button>
					<SubmitButton
						disabled={invalid}
						id="accqa__notification-settings__automatic__save"
						label="Save"
						onClick={handleSubmit(this.editCompanyFeatureFlags)}
						reduxFormSubmitting={submitting}
					/>
				</CustomModal.Footer>
			</CustomModal>
		);
	}
}

export default reduxForm<AutomaticNotifications, OwnProps>({ form: AUTOMATIC_NOTIFICATIONS, enableReinitialize: true })(AutomaticNotificationsEdit);
