import type CountryCode from 'acceligent-shared/enums/countryCode';

import { toRawPhoneNumber, formatPhoneNumber } from './phone.util';

/**
 * Transforms JS object into FormData object. removes the File props of the object
 * @example { id: 1, someProp: 'abcdef', someFile: ..... } => someFile=....., data='{"id":3,"someProp":"ABCDEF"}'
 * @param form form data from redux form
 * @param fileProps prop names which contain File
 */
export const getMultipartFormData = <T>(form: T, ...fileProps: string[]) => {
	const fd = new FormData();
	fileProps.forEach((prop: string) => form[prop] && fd.append(prop, form[prop]));

	const json = JSON.stringify(form, (key, value) => fileProps.includes(key) ? undefined : value);
	fd.append('data', json);

	return fd;
};

/**
 * Formats raw phone number in given object
 * @param data request model we want to transform
 * @param phoneNumberProps array of (phoneNumber, countryCode) tuples
 * @example
 * toRequestModelWithFormattedPhoneNumbers(sale, ['phone', 'phoneCountryCode'], ['fax', 'faxCountryCode'])
 */
export const toRequestModelWithFormattedPhoneNumbers = <T, K extends keyof T>(
	data: T,
	phoneNumberProp: [K, K],// to make sure typing fires an error if at least one prop is not provided
	...phoneNumberProps: [K, K][]
) => {
	const obj: T = { ...data };
	[phoneNumberProp, ...phoneNumberProps].forEach(([_phoneNumberPropName, _countryCodePropName]) => {
		obj[_phoneNumberPropName] = formatPhoneNumber(
			obj[_phoneNumberPropName] as unknown as string,
			obj[_countryCodePropName] as unknown as CountryCode
		) as unknown as T[K];
	});
	return obj;
};

/**
 * Formats raw phone number in given object
 * @param data request model we want to transform
 * @param phoneNumberProps array of phone number prop names we want to convert to raw phone number
 * @example
 * toRequestModelWithRawPhoneNumbers(sale, 'phone', 'fax')
 */
export const toRequestModelWithRawPhoneNumbers = <T, K extends keyof T>(
	data: T,
	phoneNumberProp: K, // to make sure typing fires an error if at least one prop is not provided
	...phoneNumberProps: K[]
): T => {
	const obj: T = { ...data };
	[phoneNumberProp, ...phoneNumberProps].forEach((_phoneNumberPropName) => {
		obj[_phoneNumberPropName] = toRawPhoneNumber(obj[_phoneNumberPropName] as unknown as string) as unknown as T[K];
	});
	return obj;
};
