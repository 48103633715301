import * as React from 'react';
import * as qs from 'query-string';
import { Row, Col, Form } from 'react-bootstrap';
import type { ConnectedProps } from 'react-redux';
import { connect } from 'react-redux';
import type { RouteComponentProps } from 'react-router';
import { compose } from 'redux';
import type { InjectedFormProps, FormErrors} from 'redux-form';
import { reduxForm, Field, formValueSelector, getFormSyncErrors } from 'redux-form';

import { COMPANY_NOTIFY } from 'af-constants/reduxForms';

import { NotificationPriorityOptions } from 'ab-enums/notificationPriority.enum';

import type NotifyCompanyRequestModel from 'ab-requestModels/companyNotify.requestModel';

import type { RootState } from 'af-reducers';

import * as CompanyActions from 'af-actions/companies';
import * as CommunicationActions from 'af-actions/communication';

import type { BreadcrumbItem } from 'af-components/Breadcrumbs';
import Breadcrumbs from 'af-components/Breadcrumbs';
import NotifyUsersFields from 'af-components/SharedForms/NotifyUsers';
import SubmitButton from 'af-components/SubmitButton';
import UserGroupsSelection from 'af-components/UserGroupsSelection';
import SegmentLabel from 'af-components/SegmentLabel';

import Dropdown from 'af-fields/Dropdown';
import Checkbox from 'af-fields/Checkbox';

import InfoModal from './InfoModal';
import { validate } from './validations';

type NotifyCompanyValidationErrors = NotifyCompanyRequestModel & { userGroups: string; };

type OwnProps = RouteComponentProps<void>;

type FromProps = InjectedFormProps<NotifyCompanyRequestModel, FormOwnProps>;

interface State {
	isNotifyAllChecked: boolean;
	notificationSent: boolean;
}

type FormOwnProps = OwnProps & ConnectedProps<typeof connector>;

type Props = FormOwnProps & FromProps;

class NotifyEmployees extends React.PureComponent<Props, State> {

	state: State = {
		isNotifyAllChecked: false,
		notificationSent: false,
	};

	componentDidMount() {
		const { getCompany, initialize, company } = this.props;
		const { isNotifyAllChecked } = this.state;

		if (!!company && !Object.keys(company).length) {
			getCompany();
		}

		initialize({
			notificationPriority: company?.notification?.notificationPriority,
			userGroups: undefined,
			notifyAll: isNotifyAllChecked,
		} as NotifyCompanyRequestModel);

	}

	componentDidUpdate(prevProps: Props) {
		const { company, change } = this.props;

		const notificationPriority = company?.notification?.notificationPriority;
		if (!prevProps.company?.notification?.notificationPriority && notificationPriority) {
			change('notificationPriority', notificationPriority);
		}
	}

	submit = async (form: NotifyCompanyRequestModel) => {
		const { notify } = this.props;
		const { isNotifyAllChecked } = this.state;

		const data: NotifyCompanyRequestModel = {
			...form,
			userGroups: isNotifyAllChecked ? undefined : form.userGroups,
			excludedAccounts: isNotifyAllChecked ? undefined : form.excludedAccounts,
			includedAccounts: isNotifyAllChecked ? undefined : form.includedAccounts,
		};
		await notify(data);
		this.setState(() => ({ notificationSent: true }));
	};

	reset = () => {
		const { reset, change, notificationPriority } = this.props;
		const { isNotifyAllChecked } = this.state;

		reset();
		change('notificationPriority', notificationPriority);	// because reset clears all to initial and not default values
		change('notifyAll', isNotifyAllChecked);
	};

	onNotificationSentModalClose = () => this.setState(() => ({ notificationSent: false }), () => {
		const { history, originUrl } = this.props;
		if (originUrl) {
			history.push(originUrl);
		} else {
			this.reset();
		}
	});

	toggleNotifyAll = () => this.setState(({ isNotifyAllChecked }) => ({ isNotifyAllChecked: !isNotifyAllChecked }));

	onClearAllUserGroups = () => {
		this.setState(() => ({ isNotifyAllChecked: true }));
	};

	onUserGroupRemove = (userGroupName: string) => {
		const { change } = this.props;
		change(`excludedAccounts.${userGroupName}`, []);
	};

	getBackLabel = (): string => {
		const { originUrl = '' } = this.props;

		if (originUrl.includes('/userGroups')) {
			return 'User Groups Table';
		} else if (originUrl.includes('/users')) {
			return 'User Table';
		} else {
			return '';
		}
	};

	getBreadcrumbs = (): BreadcrumbItem[] => {
		const { originUrl, companyData: { name: companyName } } = this.props;
		const breadcrumbItems: BreadcrumbItem[] = [];

		const backLabel = this.getBackLabel();

		if (backLabel) {
			breadcrumbItems.push({ label: backLabel, url: originUrl });
		}

		breadcrumbItems.push({ label: `Notification Center (Notify Employees in ${companyName})` });

		return breadcrumbItems;
	};

	render() {
		const {
			handleSubmit,
			submitting,
			invalid,
			pristine,
			change,
			array,
			notificationPriority,
			anyTouched,
			userGroupsError,
			location: { search },
		} = this.props;
		const { isNotifyAllChecked, notificationSent } = this.state;
		const { userGroupId } = qs.parse(search) as { userGroupId: string; };

		const usersError = anyTouched ? userGroupsError : undefined;
		const disableSubmit = pristine || invalid || submitting || !!usersError;
		return (
			<div className="form-segment">
				<InfoModal
					onClose={this.onNotificationSentModalClose}
					showModal={notificationSent}
				/>
				<Breadcrumbs items={this.getBreadcrumbs()} />
				<Form
					className="form-box"
					onSubmit={handleSubmit(this.submit)}
				>
					<Row>
						<Col sm={24}>
							<SegmentLabel label="Notify users" />
						</Col>
					</Row>
					<Row>
						<Col md={12}>
							<Field
								component={Dropdown}
								id="notificationPriority"
								label="Choose notification priority"
								labelKey="name"
								name="notificationPriority"
								options={NotificationPriorityOptions}
								valueKey="id"
								withCaret={true}
							/>
						</Col>
						<Col md={12}>
							<Field
								component={Checkbox}
								label="Notify All"
								name="notifyAll"
								onValueChange={this.toggleNotifyAll}
							/>
						</Col>
					</Row>
					{!isNotifyAllChecked &&
						<>
							<UserGroupsSelection
								array={array}
								change={change}
								formName={COMPANY_NOTIFY}
								initialGroupId={userGroupId}
								userGroupError={usersError}
							/>
							<br />
						</>
					}
					<NotifyUsersFields
						notificationPriority={notificationPriority}
					/>
					<Row className="row--submit">
						<SubmitButton
							disabled={disableSubmit}
							label="Send"
							reduxFormSubmitting={submitting}
						/>
					</Row>
				</Form>
			</div>
		);
	}
}

const getErrors = getFormSyncErrors(COMPANY_NOTIFY);

function mapStateToProps(state: RootState, ownProps: OwnProps) {
	const { location: { search } } = ownProps;
	const { user: { companyData }, company: { company } } = state;
	if (!companyData) {
		throw new Error('User not logged in');
	}

	const { originUrl } = qs.parse(search) as { userGroups: string; originUrl: string; };

	const {
		userGroups: userGroupsError,
	} = getErrors(state) as FormErrors<NotifyCompanyValidationErrors, string>;

	return {
		companyData,
		notificationPriority: formValueSelector(COMPANY_NOTIFY)(state, 'notificationPriority'),
		originUrl,
		company,
		userGroupsError: userGroupsError as string,
	};
}

function mapDispatchToProps() {
	return {
		getCompany: CompanyActions.getCompany,
		notify: CommunicationActions.notifyAllForCompany,
	};
}

const connector = connect(mapStateToProps, mapDispatchToProps());

const enhance = compose<React.ComponentClass<OwnProps>>(
	connector,
	reduxForm<NotifyCompanyRequestModel, FormOwnProps>({ form: COMPANY_NOTIFY, validate })
);

export default enhance(NotifyEmployees);
