import * as React from 'react';
import { Field } from 'redux-form';
import type { ConnectedProps } from 'react-redux';
import { connect } from 'react-redux';

import type FieldReportBlockFieldImageVM from 'ab-viewModels/fieldReport/fieldReportBlockFieldImage.viewModel';

import * as FieldReportActions from 'af-actions/fieldReport';

import type { OwnProps as TooltipProps } from 'af-components/Tooltip';

import Input from './ImageField';

interface OwnProps {
	disabled: boolean;
	name: string;
	formName: string;
	tooltipMessage?: TooltipProps['message'];
	fieldId: number;
	fieldReportId: number;
	fieldReportTypeId: number;
	blockId: number;
	isEditable: boolean;
	onSave: (imageUrl: string) => void;
	onFocus?: () => void;
}

type Props = OwnProps & ConnectedProps<typeof connector>;

const ImageField: React.FC<Props> = (props) => {
	const {
		name,
		formName,
		disabled,
		tooltipMessage,
		uploadImage,
		fieldId,
		fieldReportId,
		fieldReportTypeId,
		blockId,
		isEditable,
		onSave,
		onFocus,
	} = props;

	const onUpload = React.useCallback(async (image: File) => {
		let uploadResult: FieldReportBlockFieldImageVM = {
			imageUrl: '',
		};
		uploadResult = await uploadImage({ imageUrl: image }, fieldReportId, fieldReportTypeId, blockId, fieldId);
		onSave(uploadResult.imageUrl);
	}, [onSave, uploadImage, fieldReportId, fieldReportTypeId, blockId, fieldId]);

	const onClear = React.useCallback(() => {
		onSave('');
	}, [onSave]);

	return (
		<Field
			component={Input}
			disabled={disabled}
			isEditable={isEditable}
			label={name}
			name={formName}
			onClear={onClear}
			onFocus={onFocus}
			onSave={onUpload}
			placeholder={name}
			tooltipMessage={tooltipMessage}
		/>
	);
};

function mapDispatchToProps() {
	return {
		uploadImage: FieldReportActions.uploadBlockFieldImage,
	};
}

const connector = connect(null, mapDispatchToProps());

export default React.memo(connector(ImageField));
