import * as React from 'react';
import { Field } from 'redux-form';

import { ColorHex } from 'acceligent-shared/enums/color';

import * as TimeUtils from 'acceligent-shared/utils/time';

import type { EquipmentListItemVM } from 'ab-viewModels/timeSplitEquipment/timeSplitEquipment';
import type TimeSplitEquipmentVM from 'ab-viewModels/timeSplitEquipment/timeSplitEquipment';
import type FieldWorkClassificationCodeListItemVM from 'ab-viewModels/fieldWorkClassificationCode/listItem';

import Dropdown from 'af-fields/Dropdown';

import { bemBlock } from 'ab-utils/bem.util';

import TimeIntervalDropdown from '../../Shared/TimeIntervalDropdown';

import type { TimeSplitEntryFM } from './formModel';

interface OwnProps {
	canEdit: boolean;
	field: string;
	index: number;
	inEditMode: boolean;
	entry: TimeSplitEntryFM;
	classificationCodeList: FieldWorkClassificationCodeListItemVM[];
	equipment: TimeSplitEquipmentVM;
	remove: (index: number) => void;
	onToggleEdit: () => void;
	onValueChange: (index: number, entry: TimeSplitEntryFM) => void;
}

type Props = OwnProps;

const FILTER_CLASSIFICATION_CODE_BY = ['code'];
const FILTER_EQUIPMENT_BY = ['code', 'specification'];

const getSections = (equipmentOptions: TimeSplitEquipmentVM) => {
	if (!equipmentOptions) {
		return null;
	}
	return [
		{
			title: 'Suggested',
			options: equipmentOptions?.suggested,
		},
		{
			title: 'All',
			options: equipmentOptions?.all,
		},
	];
};

const renderEquipment = (entry: EquipmentListItemVM) => {
	return (
		<div className="equipment">
			<span className="condensed-table__employee-list-equipment-row__equipment-color" style={{ backgroundColor: ColorHex[entry.color] }} />
			<span>{entry.code ?? ''}</span>
			{!!entry.specification && (
				<span className="condensed-table__employee-list-equipment-row__specification">({entry.specification})</span>
			)}
		</div>
	);
};

const renderClassificationCode = (code: FieldWorkClassificationCodeListItemVM) => <div>{code.code}</div>;

const checkEntry = (id: number, equipmentId: number) => !!id || !!equipmentId;

const TimeSplitEntry: React.FC<Props> = (props) => {
	const {
		index,
		field,
		remove,
		inEditMode,
		onToggleEdit,
		equipment,
		classificationCodeList,
		entry,
		onValueChange,
		canEdit,
	} = props;

	const removeEntry = React.useCallback(() => {
		remove(index);
	}, [remove, index]);

	const handleEquipmentChange = React.useCallback((value: EquipmentListItemVM) => {
		if (checkEntry(entry.id, value.id)) {
			onValueChange(index, { ...entry, equipment: value });
		}
	}, [index, entry, onValueChange]);

	const handleTimeChange = React.useCallback((value: number) => {
		onValueChange(index, { ...entry, time: value });
	}, [index, entry, onValueChange]);

	const handleClassificationCodeChange = React.useCallback((value: FieldWorkClassificationCodeListItemVM) => {
		onValueChange(index, { ...entry, classificationCode: value });
	}, [index, entry, onValueChange]);

	const className = bemBlock('icon-edit', { active: inEditMode });

	const renderEditMode = () => {
		return (
			<>
				<Field
					className="condensed-table__input-wrapper"
					component={Dropdown}
					filterable={true}
					filterBy={FILTER_EQUIPMENT_BY}
					inputClassName="condensed-table__input"
					isStandalone={true}
					name={`${field}.equipmentId`}
					onValueChange={handleEquipmentChange}
					propName={`${field}.equipment`}
					renderMenuItem={renderEquipment}
					sectionOptionsKey="options"
					sections={getSections(equipment)}
					sectionTitleKey="title"
					useSectionList={true}
					valueKey="id"
					withCaret={true}
				/>
				<TimeIntervalDropdown
					className="condensed-table__input-wrapper"
					fixed={false}
					inputClassName="condensed-table__input"
					isStandalone={true}
					name={`${field}.time`}
					onChange={handleTimeChange}
				/>
				<Field
					className="condensed-table__input-wrapper"
					component={Dropdown}
					filterable={true}
					filterBy={FILTER_CLASSIFICATION_CODE_BY}
					hasBlankOption={true}
					inputClassName="condensed-table__input"
					isStandalone={true}
					name={`${field}.classificationCodeId`}
					onValueChange={handleClassificationCodeChange}
					options={classificationCodeList}
					propName={`${field}.classificationCode`}
					renderMenuItem={renderClassificationCode}
					valueKey="id"
					withCaret={true}
				/>
				<div className="icon-delete" onClick={removeEntry} />
			</>
		);
	};

	const renderReadOnlyMode = () => {
		return (
			<>
				<div className="equipment">
					<span className="condensed-table__employee-list-equipment-row__equipment-color" style={{ backgroundColor: ColorHex[entry.equipment?.color] }} />
					<span>{entry.equipment?.code ?? ''}</span>
					{!!entry.equipment?.specification && (
						<span>&nbsp;({entry.equipment?.specification})</span>
					)}
				</div>
				<div className="time">{TimeUtils.minutesToHoursAndMinutes(entry.time)}</div>
				<div className="classificationCode">{entry.classificationCode?.code}</div>
			</>
		);
	};

	return (
		<div className="condensed-table__employee-list-equipment-row">
			{canEdit && !index ? <div className={className} onClick={onToggleEdit} /> : <div />}
			{inEditMode ? renderEditMode() : renderReadOnlyMode()}
		</div>
	);
};

export default TimeSplitEntry;
