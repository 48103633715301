import type { Dispatch, AnyAction } from 'redux';

import TimeFormat from 'acceligent-shared/enums/timeFormat';

import * as TimeUtils from 'acceligent-shared/utils/time';

import type { GetRootState } from 'af-reducers';

import { http } from 'af-utils/http.util';
import { errorHandler } from 'af-utils/actions.util';

import { TableQuery } from 'ab-common/dataStructures/tableQuery';

import * as InvoicesTableAPI from 'ab-api/web/accounting/findInvoicesTable';
import * as PayrollTableAPI from 'ab-api/web/accounting/findJobPayrollTable';
import * as PayrollCsvAPI from 'ab-api/web/accounting/findJobPayrollCsv';
import * as WorkSummaryTable from 'ab-api/web/accounting/findWorkSummaryTable';
import * as WorkSummaryCsv from 'ab-api/web/accounting/findWorkSummaryCsv';
import * as PrevailingWagesTableWithClassificationCodeAPI from 'ab-api/web/accounting/findPrevailingWagesTableWithClassificationCode';
import * as PrevailingWagesTableWithoutClassificationCodeAPI from 'ab-api/web/accounting/findPrevailingWagesTableWithoutClassificationCode';

/**
 * @param startDate `YYYY-MM-DD`
 * @param endDate `YYYY-MM-DD`
 */
export function findJobPayrollTableNew(tableRM: TableQuery, startDate: string, endDate: string) {
	return async (dispatch: Dispatch<AnyAction>, getState: GetRootState, { redirectTo }) => {
		const action = async () => {
			const result = await http.get<PayrollTableAPI.W_Accounting_FindJobPayrollTable_VM>(
				PayrollTableAPI.URL(startDate, endDate)
			);
			return result;
		};
		return await errorHandler(action, dispatch, redirectTo);
	};
}

/**
 * @param startDate `YYYY-MM-DD`
 * @param endDate `YYYY-MM-DD`
 */
export function findJobPayrollRowsNew(startDate: string, endDate: string) {
	return async (dispatch: Dispatch<AnyAction>, getState: GetRootState, { redirectTo }) => {
		const action = async () => {
			const result = await http.get<PayrollCsvAPI.W_Accounting_FindJobPayrollCsv_VM>(
				PayrollCsvAPI.URL(startDate, endDate)
			);

			return result.rows;
		};
		return await errorHandler(action, dispatch, redirectTo);
	};
}

/**
 * @param startDate `YYYY-MM-DD`
 * @param endDate `YYYY-MM-DD`
 */
export function findPrevailingWagesTableWithClassificationCodes(unionTypeId: number, tableRM: TableQuery, startDate: string, endDate: string) {
	return async (dispatch: Dispatch<AnyAction>, getState: GetRootState, { redirectTo }) => {
		const action = async () => {
			const data = new TableQuery(tableRM);
			return await http.get<PrevailingWagesTableWithClassificationCodeAPI.W_Accounting_FindPrevailingWagesTableWithClassificationCode_VM>(
				PrevailingWagesTableWithClassificationCodeAPI.URL(unionTypeId, startDate, endDate, data)
			);
		};
		return await errorHandler(action, dispatch, redirectTo);
	};
}

/**
 * @param startDate `YYYY-MM-DD`
 * @param endDate `YYYY-MM-DD`
 */
export function findCompanyWorkSummaryTable(tableRM: TableQuery, startDate: string, endDate: string) {
	return async (dispatch: Dispatch<AnyAction>, getState: GetRootState, { redirectTo }) => {
		const action = async () => {
			const data = new TableQuery(tableRM);
			return await http.get<WorkSummaryTable.W_Accounting_FindWorkSummaryTable_VM>(
				WorkSummaryTable.URL(startDate, endDate, data)
			);
		};
		return await errorHandler(action, dispatch, redirectTo);
	};
}

/**
 * @param startDate `YYYY-MM-DD`
 * @param endDate `YYYY-MM-DD`
 */
export function findCompanyWorkSummaryTableCSV(startDate: string, endDate: string, filterByText: string) {
	return async (dispatch: Dispatch<AnyAction>, getState: GetRootState, { redirectTo }) => {
		const action = async () => {
			const result = await http.get<WorkSummaryCsv.W_Accounting_FindWorkSummaryCsv_VM>(
				WorkSummaryCsv.URL(startDate, endDate, filterByText)
			);
			return result.rows;
		};
		return await errorHandler(action, dispatch, redirectTo);
	};
}

/**
 * @param startDate `YYYY-MM-DD`
 * @param endDate `YYYY-MM-DD`
 */
export function findPrevailingWagesTableWithoutClassificationCodes(unionTypeId: number, tableRM: TableQuery, startDate: string, endDate: string) {
	return async (dispatch: Dispatch<AnyAction>, getState: GetRootState, { redirectTo }) => {
		const action = async () => {
			const data = new TableQuery(tableRM);
			return await http.get<PrevailingWagesTableWithoutClassificationCodeAPI.W_Accounting_FindPrevailingWagesTableWithoutClassificationCode_VM>(
				PrevailingWagesTableWithoutClassificationCodeAPI.URL(unionTypeId, startDate, endDate, data)
			);
		};
		return await errorHandler(action, dispatch, redirectTo);
	};
}

export function getInvoicesTable(tableRequestModel: TableQuery, startDate: Date, endDate: Date) {
	return async (dispatch: Dispatch<AnyAction>, getState: GetRootState, { redirectTo }) => {

		const action = async () => {
			const startDateString = TimeUtils.formatDate(startDate, TimeFormat.DB_DATE_ONLY);
			const endDateString = TimeUtils.formatDate(endDate, TimeFormat.DB_DATE_ONLY);
			return await http.get<InvoicesTableAPI.W_Accounting_FindInvoicesTable_VM>(InvoicesTableAPI.URL(
				startDateString, endDateString, tableRequestModel
			));
		};

		return await errorHandler(action, dispatch, redirectTo);
	};
}

