import * as TimeUtils from 'acceligent-shared/utils/time';

import type { ScheduleBoardStoreState } from 'af-models/scheduleBoard.models';

import type { ScheduleBoardAction } from 'af-actions/scheduleBoard';

import * as ActionTypes from 'af-constants/actionTypes';
import { DEFAULT_SCHEDULE_BOARD_ZOOM_LEVEL } from 'ab-common/constants/scheduleBoard';

import type ScheduleBoardEmployee from 'ab-viewModels/scheduleBoardEmployee.viewModel';
import type ScheduleBoardTemporaryEmployee from 'ab-viewModels/scheduleBoardTemporaryEmployee.viewModel';
import type ScheduleBoardEquipment from 'ab-viewModels/scheduleBoardEquipment.viewModel';
import type ScheduleBoardTemporaryEmployeeVM from 'ab-viewModels/scheduleBoardTemporaryEmployee.viewModel';

import ScheduleBoardSortType from 'ab-enums/scheduleBoardSortType.enum';
import { SortByEnum, OrderByEnum } from 'ab-enums/mechanicView.enum';

import * as ScheduleBoardUtils from 'af-utils/scheduleBoard.util';
import * as NumberUtils from 'ab-utils/number.util';
import * as MechanicViewUtils from 'af-utils/mechanicView.util';
import TimeFormat from 'acceligent-shared/enums/timeFormat';

const initialResourceGroups = {
	available: [],
	unavailable: [],
};

let cache: (ScheduleBoardAction)[] = [];

const initialState: ScheduleBoardStoreState = {
	displayViewDate: '',
	workOrdersByDateDictionary: {},
	dates: [],
	locations: null,
	employees: null,
	equipment: null,
	temporaryEmployees: null,
	toolbarEmployeeGroupTitles: initialResourceGroups,
	toolbarEquipmentGroupTitles: initialResourceGroups,
	idleConnections: null,
	activeConnections: null,
	date: null,
	startDate: null,
	endDate: null,
	query: '',
	activeSearchItemIndex: 0,
	searchResultItems: [],
	draggedWorkOrderCode: null,
	draggedEmployeeId: null,
	draggedEquipmentId: null,
	draggedPlaceholderId: null,
	draggedTemporaryEmployeeId: null,
	draggedResourceId: null,
	draggingLaborPlaceholder: false,
	draggingEquipmentPlaceholder: false,
	zoomLevel: DEFAULT_SCHEDULE_BOARD_ZOOM_LEVEL,
	availableEmployeesNotificationModal: {
		employees: {},
		employeeIds: [],
		groupTitle: null,
		dueDate: null,
		isOpen: false,
		hasAlreadyNotifiedEmployees: false,
		groupId: null,
	},
	laborModal: {
		workOrderId: null,
		workOrderCode: null,
		index: null,
		isOpen: false,
		dueDate: null,
	},
	equipmentPlaceholderModal: {
		workOrderId: null,
		workOrderCode: null,
		index: null,
		isOpen: false,
	},
	scheduleBoardView: null,
	isFilterApplied: false,
	isMultiSelectModeActive: false,
	showRefreshModal: false,
	isShowNotesActive: true,
	selectedWorkOrders: [],
	copiedEmployeeId: null,
	copiedEquipmentId: null,
	copiedPlaceholderId: null,
	copiedTemporaryEmployeeId: null,
	weeklyViewHorizontalScrollingPercentage: 0,
	metricsHorizontalScrollingPercentage: 0,
	copiedWorkOrderCode: null,
	weeklyViewDateWithToolbar: null,
	weeklyViewSelectMultiple: {},
	resourcesLoaded: false,
	workOrdersSort: ScheduleBoardSortType.FREE_REORDERING,
	mechanicView: {
		availableEquipment: [],
		orderEquipmentBy: OrderByEnum.DESCENDING,
		searchQuery: '',
		sortEquipmentBy: SortByEnum.PRIORITY,
		unavailableEquipment: [],
		matchedEquipmentIdMap: {},
		focusedSearchIndex: 0,
		matchedEquipmentIds: [],
	},
};

function reducer(
	state: ScheduleBoardStoreState = initialState,
	action: ScheduleBoardAction
): ScheduleBoardStoreState {

	/**
	 * Block scoped variable that stores the state change for reducer cases.
	 *
	 * NOTE: do not rely on this variable being set to state in the beginning of the reducer
	 *  set it inside the case so the logic is easier to extract.
	*/
	let updatedState: ScheduleBoardStoreState = state;

	switch (action.type) {
		case ActionTypes.GET_SCHEDULE_BOARD:
		case ActionTypes.GET_WEEKLY_VIEW_SCHEDULE_BOARD:
			const _boardBeforeCache = ScheduleBoardUtils.setScheduleBoard(state, action.payload);
			// once we store data to redux, check if there are any update events that ocurred between fetching data form DB
			// and storing it to redux
			// if yes, then update store to correct state
			if (cache.length) {
				const _boardAfterCache = cache.reduce<ScheduleBoardStoreState>(reducer, _boardBeforeCache);
				cache = [];
				return _boardAfterCache;
			}
			return _boardBeforeCache;
		case ActionTypes.GET_SCHEDULE_BOARD_REJOIN:
			const { canceledWorkOrderResourceLookups, workOrderResourceLookups, availableNotificationStatuses } = action.payload;
			const workOrdersByDateDictionary = Object.keys(state.workOrdersByDateDictionary).reduce((_acc, _date) => {
				_acc[_date] = {
					..._acc[_date],
					workOrderResourceLookups: {
						..._acc[_date].workOrderResourceLookups,
						...(workOrderResourceLookups?.[_date] ?? {}),
					},
					canceledWorkOrderResourceLookups: {
						..._acc[_date].canceledWorkOrderResourceLookups,
						...(canceledWorkOrderResourceLookups?.[_date] ?? {}),
					},
					availableNotificationStatuses: {
						..._acc[_date].availableNotificationStatuses,
						...(availableNotificationStatuses?.[_date] ?? {}),
					},
				};
				return _acc;
			}, { ...state.workOrdersByDateDictionary });
			updatedState = {
				...state,
				dates: action.payload.dates,
				workOrdersByDateDictionary,
			};
			return ScheduleBoardUtils.updateRowDistributionForDates(updatedState, updatedState.zoomLevel);
		case ActionTypes.GET_SCHEDULE_BOARD_RESOURCES:
			updatedState = ScheduleBoardUtils.sortToolbarEquipmentIfNeeded(state, action.payload);
			return { ...updatedState, ...action.payload, resourcesLoaded: true };
		case ActionTypes.RELOAD_SCHEDULE_BOARD_RESOURCES:
			return { ...state, resourcesLoaded: false };
		case ActionTypes.RELOAD_SCHEDULE_BOARD:
			return { ...initialState, zoomLevel: state.zoomLevel };
		case ActionTypes.SET_SCHEDULE_BOARD_VIEW:
			return { ...state, scheduleBoardView: action.payload };
		case ActionTypes.UPDATE_SCHEDULE_BOARD_DROPPABLE_LIST:
			updatedState = ScheduleBoardUtils.updateScheduleBoardDroppableList(state, action.payload);
			return ScheduleBoardUtils.updateRowDistributionForDates(updatedState, updatedState.zoomLevel);
		case ActionTypes.ADD_TO_SCHEDULE_BOARD_DROPPABLE_LIST:
			return ScheduleBoardUtils.addToDroppableList(state, action.payload);
		case ActionTypes.UPDATE_SCHEDULE_BOARD_WORK_ORDER_INDICES:
			return ScheduleBoardUtils.reorderWorkOrders(state, action.payload);
		case ActionTypes.UPDATE_SCHEDULE_BOARD_WORK_ORDERS:
			return ScheduleBoardUtils.updateWorkOrders(state, action.payload);
		case ActionTypes.UPDATE_SCHEDULE_BOARD_WORK_ORDER:
			return ScheduleBoardUtils.updateWorkOrder(state, action.payload);
		case ActionTypes.ADD_BLANK_WORK_ORDER:
			return ScheduleBoardUtils.addBlankWorkOrder(state, action.payload);
		case ActionTypes.REMOVE_BLANK_WORK_ORDER:
			return ScheduleBoardUtils.removeBlankWorkOrder(state, action.payload);
		case ActionTypes.REMOVE_SCHEDULE_BOARD_WORK_ORDER:
			return ScheduleBoardUtils.removeWorkOrder(state, action.payload);
		case ActionTypes.CANCEL_SCHEDULE_BOARD_WORK_ORDER:
			return ScheduleBoardUtils.cancelWorkOrder(state, action.payload);
		case ActionTypes.PAUSE_SCHEDULE_BOARD_WORK_ORDER:
			return ScheduleBoardUtils.pauseWorkOrder(state, action.payload);
		case ActionTypes.RESUME_SCHEDULE_BOARD_WORK_ORDER:
			return ScheduleBoardUtils.resumeWorkOrder(state, action.payload);
		case ActionTypes.REVERT_SCHEDULE_BOARD_WORK_ORDER:
			return ScheduleBoardUtils.revertWorkOrder(state, action.payload);
		case ActionTypes.LOCK_SCHEDULE_BOARD_WORK_ORDER:
			return ScheduleBoardUtils.lockOnApproveWorkOrder(state, action.payload);
		case ActionTypes.UNLOCK_SCHEDULE_BOARD_WORK_ORDER:
			return ScheduleBoardUtils.unlockOnApproveWorkOrder(state, action.payload);
		case ActionTypes.COPY_MULTIPLE_WORK_ORDERS:
			return ScheduleBoardUtils.copyMultipleWorkOrder(state, action.payload);
		case ActionTypes.GET_SOCKET_CONNECTION_COUNT:
			return { ...state, idleConnections: action.payload.idleConnections, activeConnections: action.payload.activeConnections };
		case ActionTypes.SET_SCHEDULE_BOARD_LABOR_STATISTICS:
			return ScheduleBoardUtils.setLaborStatistics(state, action.payload);
		case ActionTypes.CLEAR_SCHEDULE_BOARD_DAILY_VIEW:
			return { ...state, date: initialState.date };
		case ActionTypes.CLEAR_SCHEDULE_BOARD_WEEKLY_VIEW:
			return {
				...state,
				startDate: initialState.startDate,
				endDate: initialState.endDate,
				dates: initialState.dates,
				weeklyViewHorizontalScrollingPercentage: 0,
			};
		case ActionTypes.UPDATE_SCHEDULE_BOARD_DATE:
			return { ...state, date: action.payload };
		case ActionTypes.UPDATE_DISPLAY_VIEW_DATE:
			return { ...state, displayViewDate: action.payload };
		case ActionTypes.UPDATE_SCHEDULE_BOARD_WEEK:
			return {
				...state,
				startDate: action.payload.startDate,
				endDate: action.payload.endDate,
				dates: TimeUtils.datesBetween(action.payload.startDate, action.payload.endDate).map((_date) => TimeUtils.formatDate(_date)),
			};
		case ActionTypes.UPDATE_SCHEDULE_BOARD_QUERY:
			return ScheduleBoardUtils.updateScheduleBoardSearchResults(state, action.payload);
		case ActionTypes.SET_SB_ACTIVE_SEARCH_ITEM_INDEX:
			return { ...state, activeSearchItemIndex: Math.max(Math.min(action.payload, state.searchResultItems.length - 1), 0) };
		case ActionTypes.DISABLE_SCHEDULE_BOARD_EQUIPMENT_ITEM:
			return {
				...state,
				equipment:
				{
					...state.equipment,
					[action.payload]: {
						...state.equipment![action.payload],
						isDisabled: true,
					} as ScheduleBoardEquipment,
				},
			};
		case ActionTypes.ENABLE_SCHEDULE_BOARD_EQUIPMENT_ITEM:
			return {
				...state,
				equipment: {
					...state.equipment,
					[action.payload]: {
						...state.equipment![action.payload],
						isDisabled: false,
					} as ScheduleBoardEquipment,
				},
			};
		case ActionTypes.DISABLE_SCHEDULE_BOARD_EMPLOYEE_ITEM:
			return {
				...state,
				employees: {
					...state.employees,
					[action.payload]: {
						...state.employees![action.payload],
						isDisabled: true,
					} as ScheduleBoardEmployee,
				},
			};
		case ActionTypes.ENABLE_SCHEDULE_BOARD_EMPLOYEE_ITEM:
			return {
				...state,
				employees: {
					...state.employees,
					[action.payload]: {
						...state.employees![action.payload],
						isDisabled: false,
					} as ScheduleBoardEmployee,
				},
			};
		case ActionTypes.DISABLE_SCHEDULE_BOARD_TEMPORARY_EMPLOYEE_ITEM:
			return {
				...state,
				temporaryEmployees: {
					...state.temporaryEmployees,
					[action.payload]: {
						...state.temporaryEmployees![action.payload],
						isDisabled: true,
					} as ScheduleBoardTemporaryEmployeeVM,
				},
			};
		case ActionTypes.ENABLE_SCHEDULE_BOARD_TEMPORARY_EMPLOYEE_ITEM:
			return {
				...state,
				temporaryEmployees: {
					...state.temporaryEmployees,
					[action.payload]: {
						...state.temporaryEmployees![action.payload],
						isDisabled: false,
					} as ScheduleBoardTemporaryEmployeeVM,
				},
			};
		case ActionTypes.DISABLE_SCHEDULE_BOARD_WORK_ORDER_ITEM:
			return ScheduleBoardUtils.toggleDisableWorkOrder(state, action.payload, true);
		case ActionTypes.ENABLE_SCHEDULE_BOARD_WORK_ORDER_ITEM:
			return ScheduleBoardUtils.toggleDisableWorkOrder(state, action.payload, false);
		case ActionTypes.REMOVE_EMPLOYEE_ASSIGNMENT:
		case ActionTypes.REMOVE_EQUIPMENT_ASSIGNMENT:
		case ActionTypes.REMOVE_PLACEHOLDER_ASSIGNMENT:
		case ActionTypes.REMOVE_TEMPORARY_EMPLOYEE_ASSIGNMENT:
			return ScheduleBoardUtils.updateReducerOnRemoveResourceLookupWorkAssignment(state, action.payload);
		case ActionTypes.ADD_EMPLOYEE_ASSIGNMENT:
		case ActionTypes.ADD_EQUIPMENT_ASSIGNMENT:
		case ActionTypes.ADD_PLACEHOLDER_ASSIGNMENT:
			return ScheduleBoardUtils.updateReducerOnAddResourceLookupWorkAssignment(state, action.payload);
		case ActionTypes.ADD_TEMPORARY_EMPLOYEE_ASSIGNMENT:
			return ScheduleBoardUtils.updateReducerOnAddTemporaryEmployeeResourceLookupWorkAssignment(state, action.payload);
		case ActionTypes.ADD_TOOLBAR_EMPLOYEE:
			return ScheduleBoardUtils.addToolbarEmployee(state, action.payload);
		case ActionTypes.ADD_TOOLBAR_EMPLOYEE_FROM_DATE:
			return ScheduleBoardUtils.addToolbarEmployeeFromDate(state, action.payload);
		case ActionTypes.REMOVE_TOOLBAR_EMPLOYEE:
			return ScheduleBoardUtils.removeToolbarEmployee(state, action.payload);
		case ActionTypes.REMOVE_TOOLBAR_EMPLOYEE_FROM_DATE:
			return ScheduleBoardUtils.removeToolbarEmployeeFromDate(state, action.payload);
		case ActionTypes.REMOVE_MULTIPLE_TOOLBAR_EMPLOYEE:
			return ScheduleBoardUtils.removeMultipleToolbarEmployee(state, action.payload);
		case ActionTypes.ADD_TOOLBAR_EQUIPMENT:
			return ScheduleBoardUtils.addToolbarEquipment(state, action.payload);
		case ActionTypes.ADD_EQUIPMENT_DOWN_DETAILS:
			return ScheduleBoardUtils.addEquipmentDownDetails(state, action.payload);
		case ActionTypes.ADD_EMPLOYEE_DOWN_DETAILS:
			return ScheduleBoardUtils.addEmployeeDownDetails(state, action.payload);
		case ActionTypes.REMOVE_TOOLBAR_EQUIPMENT:
			return ScheduleBoardUtils.removeToolbarEquipment(state, action.payload);
		case ActionTypes.REMOVE_MULTIPLE_TOOLBAR_EQUIPMENT:
			return ScheduleBoardUtils.removeMultipleToolbarEquipment(state, action.payload);
		case ActionTypes.ADD_TOOLBAR_EMPLOYEE_FOR_ALL_DAYS:
			return ScheduleBoardUtils.addToolbarEmployeeForAllDays(state, action.payload);
		case ActionTypes.REMOVE_TOOLBAR_EMPLOYEE_FOR_ALL_DAYS:
			return ScheduleBoardUtils.removeToolbarEmployeeForAllDays(state, action.payload);
		case ActionTypes.ADD_TOOLBAR_EQUIPMENT_FOR_ALL_DAYS:
			return ScheduleBoardUtils.addToolbarEquipmentForAllDays(state, action.payload);
		case ActionTypes.REMOVE_TOOLBAR_EQUIPMENT_FOR_ALL_DAYS:
			return ScheduleBoardUtils.removeToolbarEquipmentForAllDays(state, action.payload);
		case ActionTypes.UPDATE_EMPLOYEE:
			return {
				...state,
				employees: {
					...state.employees,
					[action.payload.id]: {
						...state.employees![action.payload.id],
						...action.payload,
					} as ScheduleBoardEmployee,
				},
			};
		case ActionTypes.UPDATE_TEMPORARY_EMPLOYEE:
			return {
				...state,
				temporaryEmployees: {
					...state.temporaryEmployees,
					[action.payload.id]: {
						...state.temporaryEmployees![action.payload.id],
						...action.payload,
					} as ScheduleBoardTemporaryEmployee,
				},
			};
		case ActionTypes.UPDATE_EQUIPMENT:
			return {
				...state,
				equipment: {
					...state.equipment,
					[action.payload.id]: {
						...state.equipment![action.payload.id],
						...action.payload,
					} as ScheduleBoardEquipment,
				},
			};
		case ActionTypes.ADD_WORK_ORDER_RESOURCE_LOOKUP_TO_DICTIONARY:
			return ScheduleBoardUtils.addWorkOrderResourceLookupToDictionary(state, action.payload);
		case ActionTypes.ADD_TEMPORARY_EMPLOYEE:
			return ScheduleBoardUtils.addTemporaryEmployee(state, action.payload);
		case ActionTypes.ADD_EMPLOYEE_NIGHT_SHIFT_ASSIGNMENT:
			return ScheduleBoardUtils.addEmployeeNightShiftAssignment(state, action.payload);
		case ActionTypes.REMOVE_EMPLOYEE_NIGHT_SHIFT_ASSIGNMENT:
			return ScheduleBoardUtils.removeEmployeeNightShiftAssignment(state, action.payload);
		case ActionTypes.ADD_TEMPORARY_EMPLOYEE_NIGHT_SHIFT_ASSIGNMENT:
			return ScheduleBoardUtils.addTemporaryEmployeeNightShiftAssignment(state, action.payload);
		case ActionTypes.REMOVE_TEMPORARY_EMPLOYEE_NIGHT_SHIFT_ASSIGNMENT:
			return ScheduleBoardUtils.removeTemporaryEmployeeNightShiftAssignment(state, action.payload);
		case ActionTypes.REMOVE_ALL_WORK_ORDER_EMPLOYEES:
			return ScheduleBoardUtils.removeAllWorkOrderEmployees(state, action.payload);
		case ActionTypes.REMOVE_ALL_WORK_ORDER_EMPLOYEE_ON_DRAFTS:
			return ScheduleBoardUtils.removeWorkOrderEmployeeOnDrafts(state, action.payload);
		case ActionTypes.REMOVE_ALL_WORK_ORDER_EQUIPMENT:
			return ScheduleBoardUtils.removeAllWorkOrderEquipment(state, action.payload);
		case ActionTypes.REMOVE_ALL_WORK_ORDER_EQUIPMENT_ON_DRAFTS:
			return ScheduleBoardUtils.removeWorkOrderEquipmentOnDrafts(state, action.payload);
		case ActionTypes.CLEAR_UNAVAILABILITY_REASON:
			return ScheduleBoardUtils.clearUnavailabilityReason(state, action.payload.itemId, action.payload.scope, action.payload.dueDate);
		case ActionTypes.ASSIGN_UNAVAILABILITY_REASON:
			return ScheduleBoardUtils.assignUnavailabilityReason(state, action.payload);
		case ActionTypes.CHANGE_RETURN_DATE:
			return ScheduleBoardUtils.changeReturnDate(state, action.payload);
		case ActionTypes.UPDATE_EMPLOYEE_PER_DIEM:
			return ScheduleBoardUtils.updateEmployeePerDiem(state, action.payload);
		case ActionTypes.SET_PER_DIEM_FOR_WORK_ORDERS:
			return ScheduleBoardUtils.setPerDiemForWorkOrders(state, action.payload);
		case ActionTypes.SET_SCHEDULE_BOARD_DRAGGED_WORK_ORDER_CODE:
			return { ...state, draggedWorkOrderCode: action.payload };
		case ActionTypes.SET_SCHEDULE_BOARD_DRAGGED_EMPLOYEE:
			return { ...state, draggedEmployeeId: action.payload };
		case ActionTypes.SET_SCHEDULE_BOARD_DRAGGED_EQUIPMENT:
			return { ...state, draggedEquipmentId: action.payload };
		case ActionTypes.SET_SCHEDULE_BOARD_DRAGGED_WORK_ORDER_EMPLOYEE:
			return { ...state, draggedResourceId: action.payload.resourceId, draggedEmployeeId: action.payload.employeeId };
		case ActionTypes.SET_SCHEDULE_BOARD_DRAGGED_WORK_ORDER_EQUIPMENT:
			return { ...state, draggedResourceId: action.payload.resourceId, draggedEquipmentId: action.payload.equipmentId };
		case ActionTypes.SET_SCHEDULE_BOARD_DRAGGED_WORK_ORDER_PLACEHOLDER:
			return { ...state, draggedResourceId: action.payload.resourceId, draggedPlaceholderId: action.payload.placeholderId };
		case ActionTypes.SET_SCHEDULE_BOARD_DRAGGED_WORK_ORDER_TEMPORARY_EMPLOYEE:
			return { ...state, draggedResourceId: action.payload.resourceId, draggedTemporaryEmployeeId: action.payload.temporaryEmployeeId };
		case ActionTypes.SET_SCHEDULE_BOARD_DRAGGED_RESOURCE_ID:
			return { ...state, draggedResourceId: action.payload };
		case ActionTypes.CLEAR_SCHEDULE_BOARD_DRAGGED_WORK_ORDER:
			updatedState = state;
			if (!action.payload.isCopying) {
				updatedState = ScheduleBoardUtils.reorderScheduleBoardWorkOrders(state, action.payload);
			}
			return { ...updatedState, draggedWorkOrderCode: null, copiedWorkOrderCode: null };
		case ActionTypes.CLEAR_SCHEDULE_BOARD_DRAGGED_RESOURCE:
			updatedState = state;
			if (!action.payload.isCopying) {
				updatedState = ScheduleBoardUtils.updateScheduleBoardDroppableList(state, action.payload);
				updatedState = ScheduleBoardUtils.updateRowDistributionForDates(updatedState, updatedState.zoomLevel);
			}
			return {
				...updatedState,
				draggedEmployeeId: null, copiedEmployeeId: null,
				draggedEquipmentId: null, copiedEquipmentId: null,
				draggedPlaceholderId: null, copiedPlaceholderId: null,
				draggedTemporaryEmployeeId: null, copiedTemporaryEmployeeId: null,
				draggedResourceId: null,
			};
		case ActionTypes.SET_DRAGGING_LABOR_PLACEHOLDER:
			return ScheduleBoardUtils.setDraggingLaborPlaceholder(state, action.payload.dragging, action.payload.destinationData);
		case ActionTypes.SET_DRAGGING_EQUIPMENT_PLACEHOLDER:
			return ScheduleBoardUtils.setDraggingEquipmentPlaceholder(state, action.payload.dragging, action.payload.destinationData);
		case ActionTypes.SET_SCHEDULE_BOARD_ZOOM_LEVEL:
			if (state.zoomLevel === action.payload) {
				return state;
			}
			updatedState = { ...state, zoomLevel: action.payload };
			return ScheduleBoardUtils.updateRowDistributionForDates(updatedState, updatedState.zoomLevel);
		case ActionTypes.LOCK_WORK_ORDER_ON_SCHEDULE_BOARD:
			return ScheduleBoardUtils.toggleLockOnWorkOrder(state, action.payload, true);
		case ActionTypes.UNLOCK_WORK_ORDER_ON_SCHEDULE_BOARD:
			return ScheduleBoardUtils.toggleLockOnWorkOrder(state, action.payload, false);
		case ActionTypes.UPDATE_WORK_ORDER_NOTE:
			return ScheduleBoardUtils.updateWorkOrderNote(state, action.payload);
		case ActionTypes.SET_LABOR_MODAL_VISIBILITY:
			return { ...state, laborModal: { ...state.laborModal, isOpen: action.payload } };
		case ActionTypes.SET_LABOR_MODAL_DATA:
			return { ...state, laborModal: { ...state.laborModal, ...action.payload } };
		case ActionTypes.SET_EQUIPMENT_PLACEHOLDER_MODAL_VISIBILITY:
			return { ...state, equipmentPlaceholderModal: { ...state.equipmentPlaceholderModal, isOpen: action.payload } };
		case ActionTypes.SET_EQUIPMENT_PLACEHOLDER_MODAL_DATA:
			return { ...state, equipmentPlaceholderModal: { ...state.equipmentPlaceholderModal, ...action.payload } };
		case ActionTypes.OPEN_AVAILABLE_EMPLOYEES_NOTIFICATION_MODAL:
			return ScheduleBoardUtils.openAvailableEmployeesNotificationModal(state, action.payload);
		case ActionTypes.REFRESH_AVAILABLE_EMPLOYEES_NOTIFICATION_MODAL:
			return ScheduleBoardUtils.refreshAvailableEmployeesNotificationModal(state);
		case ActionTypes.CLOSE_AVAILABLE_EMPLOYEES_NOTIFICATION_MODAL:
			return { ...state, availableEmployeesNotificationModal: initialState.availableEmployeesNotificationModal };
		case ActionTypes.CHECK_EMPLOYEE_IN_AVAILABLE_EMPLOYEE_MODAL:
			return ScheduleBoardUtils.checkEmployeeAvailableEmployeesNotificationModal(state, action.payload);
		case ActionTypes.CHECK_ALL_IN_AVAILABLE_EMPLOYEE_MODAL:
			return ScheduleBoardUtils.checkAllAvailableEmployeesNotificationModal(state, action.payload);
		case ActionTypes.RESET_ALL_IN_AVAILABLE_EMPLOYEE_MODAL:
			return ScheduleBoardUtils.resetAllAvailableEmployeesNotificationModal(state, action.payload);
		case ActionTypes.CLEAR_ALL_SCHEDULE_BOARD_FILTERS:
			return { ...state, isFilterApplied: false };
		case ActionTypes.APPLY_SCHEDULE_BOARD_FILTERS:
			return ScheduleBoardUtils.applyFilters(state, action.payload);
		case ActionTypes.ACTIVATE_SCHEDULE_BOARD_MULTI_SELECT_MODE:
			return { ...state, isMultiSelectModeActive: true };
		case ActionTypes.DEACTIVATE_SCHEDULE_BOARD_MULTI_SELECT_MODE:
			return { ...state, isMultiSelectModeActive: false };
		case ActionTypes.SET_SHOW_NOTES_MODE:
			return { ...state, isShowNotesActive: action.payload };
		case ActionTypes.ADD_ORDER_TO_SCHEDULE_BOARD_MULTI_SELECT:
			return { ...state, selectedWorkOrders: [...state.selectedWorkOrders, action.payload] };
		case ActionTypes.REMOVE_ORDER_FROM_SCHEDULE_BOARD_MULTI_SELECT:
			return { ...state, selectedWorkOrders: state.selectedWorkOrders.filter((_wo) => _wo.id !== action.payload.id) };
		case ActionTypes.SET_SCHEDULE_BOARD_MULTI_SELECT_ORDERS:
			return { ...state, selectedWorkOrders: action.payload };
		case ActionTypes.SET_COPIED_EMPLOYEE:
			return { ...state, copiedEmployeeId: action.payload };
		case ActionTypes.SET_COPIED_EQUIPMENT:
			return { ...state, copiedEquipmentId: action.payload };
		case ActionTypes.SET_COPIED_PLACEHOLDER:
			return { ...state, copiedPlaceholderId: action.payload };
		case ActionTypes.SET_COPIED_TEMPORARY_EMPLOYEE:
			return { ...state, copiedTemporaryEmployeeId: action.payload };
		case ActionTypes.SET_WEEKLY_VIEW_HORIZONTAL_SCROLLING_PERCENTAGE:
			return { ...state, weeklyViewHorizontalScrollingPercentage: NumberUtils.boundToInterval(action.payload) };
		case ActionTypes.SET_METRICS_HORIZONTAL_SCROLLING_PERCENTAGE:
			return { ...state, metricsHorizontalScrollingPercentage: NumberUtils.boundToInterval(action.payload) };
		case ActionTypes.SET_COPIED_WORK_ORDER:
			return { ...state, copiedWorkOrderCode: action.payload };
		case ActionTypes.SET_COPIED_RESOURCE_PLACEHOLDER:
			return ScheduleBoardUtils.setCopiedResourcePlaceholder(
				state,
				action.payload.dueDate,
				action.payload.code,
				action.payload.index,
				action.payload.clearPlaceholder
			);
		case ActionTypes.SET_COPIED_WORK_ORDER_PLACEHOLDER:
			return ScheduleBoardUtils.setCopiedWorkOrderPlaceholder(state, action.payload.dueDate, action.payload.code, action.payload.index);
		case ActionTypes.SET_WEEKLY_VIEW_DATE_WITH_TOOLBAR:
			return { ...state, weeklyViewDateWithToolbar: action.payload };
		case ActionTypes.TOGGLE_WEEKLY_VIEW_SELECT_MULTIPLE:
			return ScheduleBoardUtils.toggleWeeklyViewSelectMultiple(state, action.payload);
		case ActionTypes.TOGGLE_DAY_ON_WEEKLY_VIEW:
			return ScheduleBoardUtils.toggleDayOnWeeklyView(state, action.payload.dueDate, action.payload.selectValue);
		case ActionTypes.TOGGLE_WORK_ORDER_ON_WEEKLY_VIEW:
			return ScheduleBoardUtils.toggleWorkOrderOnWeeklyView(state, action.payload.dueDate, action.payload.workOrder);
		case ActionTypes.SYNC_NOTIFICATION_STATUS:
			return ScheduleBoardUtils.syncNotificationStatus(state, action.payload);
		case ActionTypes.SET_REFRESH_MODAL_VISIBILITY:
			return { ...state, showRefreshModal: action.payload };
		case ActionTypes.UPDATE_WORK_ORDER_HAS_ATTACHMENTS:
			return ScheduleBoardUtils.updateHasAttachmentsOnWorkOrder(state, action.payload);
		case ActionTypes.CLEAR_WORK_ORDERS_BY_DATE:
			return { ...state, workOrdersByDateDictionary: initialState.workOrdersByDateDictionary };
		case ActionTypes.CHANGE_WORK_ORDERS_SORT:
			return ScheduleBoardUtils.changeWorkOrdersSort(state, action.payload);
		case ActionTypes.UPDATE_DAILY_TIP:
			return ScheduleBoardUtils.updateDailyTip(state, action.payload);
		case ActionTypes.UPDATE_DAILY_PER_DIEM_TIP:
			return ScheduleBoardUtils.updateDailyPerDiemTip(state, action.payload);
		case ActionTypes.SET_MECHANIC_VIEW_SORT_BY:
			return {
				...state,
				mechanicView: {
					...state.mechanicView,
					orderEquipmentBy: action.payload.orderBy,
					sortEquipmentBy: action.payload.sortBy,
				},
			};
		case ActionTypes.SET_MECHANIC_VIEW_AVAILABLE_EQUIPMENT: {
			const nextState = { ...state, mechanicView: { ...state.mechanicView, availableEquipment: action.payload } };
			nextState.mechanicView = {
				...nextState.mechanicView,
				...MechanicViewUtils.calculateMatchedEquipmentData(nextState.mechanicView),
			};
			return nextState;
		}
		case ActionTypes.SET_MECHANIC_VIEW_UNAVAILABLE_EQUIPMENT: {
			const nextState = { ...state, mechanicView: { ...state.mechanicView, unavailableEquipment: action.payload } };
			nextState.mechanicView = {
				...nextState.mechanicView,
				...MechanicViewUtils.calculateMatchedEquipmentData(nextState.mechanicView),
			};
			return nextState;
		}
		case ActionTypes.SET_MECHANIC_VIEW_SEARCH_QUERY: {
			const nextState = { ...state, mechanicView: { ...state.mechanicView, searchQuery: action.payload, focusedSearchIndex: 0 } };
			nextState.mechanicView = {
				...nextState.mechanicView,
				...MechanicViewUtils.calculateMatchedEquipmentData(nextState.mechanicView),
			};
			return nextState;
		}
		case ActionTypes.SET_MECHANIC_VIEW_FOCUSED_SEARCH_INDEX: {
			let index = action.payload;
			if (index < 0 || index >= state.mechanicView.matchedEquipmentIds.length) {
				index = 0;
			}
			return {
				...state,
				mechanicView: {
					...state.mechanicView,
					focusedSearchIndex: index,
				},
			};
		}
		case ActionTypes.UPDATE_CREW_TYPE: {
			const newDateMap = Object.keys(state.workOrdersByDateDictionary).reduce((_dateMap, _date) => {

				let didDayChanged = false;
				const newWOMap = Object.keys(state.workOrdersByDateDictionary[_date].workOrders).reduce((_woMap, _woCode) => {
					if (state.workOrdersByDateDictionary[_date].workOrders[_woCode].crewTypeId === action.payload.id) {
						didDayChanged = true;
						_woMap[_woCode] = {
							..._woMap[_woCode],
							crewTypeName: action.payload.name,
							crewTypeColor: action.payload.color,
						};
					}
					return _woMap;
				}, { ...state.workOrdersByDateDictionary[_date].workOrders });

				if (didDayChanged) {
					_dateMap[_date] = { ..._dateMap[_date], workOrders: newWOMap };
				}
				return _dateMap;
			}, { ...state.workOrdersByDateDictionary });

			return {
				...state,
				workOrdersByDateDictionary: newDateMap,
			};
		}
		default:
			return state;
	}
}

export default function ScheduleBoardReducer(
	state: ScheduleBoardStoreState = initialState,
	action: ScheduleBoardAction
): ScheduleBoardStoreState {

	if (action.payload && typeof action.payload === 'object' && 'dueDate' in (action.payload)) {
		const { date, startDate, endDate, workOrdersByDateDictionary } = state;
		const { dueDate } = action.payload;
		if (!workOrdersByDateDictionary[dueDate as string]
			&& (
				dueDate === date
				|| (startDate && endDate && TimeUtils.isDateInPeriod(dueDate as string, startDate, endDate, TimeFormat.DATE_ONLY)
				)
			)
		) {
			// this covers case when user loads specific day and, while loading he receives update event
			// since, data is already fetched from DB, but not stored in redux, wrong state will be presented
			// and there will be errors in console because redux will try to update state that is not stored yet
			cache.push(action);
			return state;
		}
	}
	return reducer(state, action);
}
