import { isValidTextInput } from 'acceligent-shared/utils/text';

import type CreateTemporaryEmployeeAssignmentForm from './createTemporaryEmployeeAssignmentForm';

export function validate(values: CreateTemporaryEmployeeAssignmentForm, props) {
	const errors: ValidationErrors = {};

	if (values.index < 0) {
		errors.index = 'Index is required';
	}

	if (!values.workOrderId) {
		errors.workOrderId = 'Work order id is required';
	}

	if (values.temporaryEmployee && values.newTemporaryEmployee) {
		errors.temporaryEmployee = 'Can not select an existing employee and create new one';
	}

	if (!values.temporaryEmployee && !values.newTemporaryEmployee) {
		errors.temporaryEmployee = `Select existing Temporary employee ${props.isAllowedToCreate ? 'or create a new one' : ''}`;

	}

	if (!values.temporaryEmployee && values.newTemporaryEmployee) {
		const newTemporaryEmployee: ValidationErrors = {};

		if (!isValidTextInput(values.newTemporaryEmployee.firstName)) {
			newTemporaryEmployee.firstName = 'First name is required';
		}
		if (!isValidTextInput(values.newTemporaryEmployee.lastName)) {
			newTemporaryEmployee.lastName = 'Last name is required';
		}
		if (!values.newTemporaryEmployee.agencyId) {
			newTemporaryEmployee.agencyId = 'Agency is required';
		}
		if (!!Object.keys(newTemporaryEmployee).length) {
			errors.newTemporaryEmployee = newTemporaryEmployee;
		}
	}

	return errors;
}
