import * as React from 'react';
import type { FinalState, RowInfo, TypedRowInfo } from 'react-table-6';
import type { History } from 'history';

import { URL_REGEX } from 'acceligent-shared/constants/regex';

import type { TableContent } from 'ab-common/dataStructures/tableContent';

import { DeliverableReviewTypeLabelEnum } from 'acceligent-shared/enums/deliverableReviewType';

import Tooltip from 'af-components/Tooltip';
import type { Column, ExpandedDefinition, ReactTableRef } from 'af-components/Table6';
import EmptyCell from 'af-components/Table6/Cells/EmptyCell';
import DeliverableStatusLabel from 'af-components/DeliverableStatusLabel';
import StatusHistoryModal from 'af-components/DeliverableHistoryModal';
import DeliverableSubmissionHeader from 'af-components/DeliverableHistoryModal/DeliverableSubmissionHeader';
import type { TabProps } from 'af-components/Table6/IndentTable';
import IndentTable from 'af-components/Table6/IndentTable';
import CommentCell from 'af-components/Table6/Cells/CommentCell';
import Checkbox from 'af-components/Controls/Checkbox';
import ResourceTooltipList from 'af-components/Table6/Cells/ResourceCell/ResourceTooltipList';

import type { DeliverableSubmissionTableViewModel } from 'ab-viewModels/deliverableTable.viewModel';

import CLIENT from 'af-constants/routes/client';

import TableNameEnum from 'ab-enums/tableName.enum';

import DeliverableGrandchildTable from './GrandchildTable';

interface OwnProps {
	data: TableContent<DeliverableSubmissionTableViewModel>;
	companyName: string;
	orgAlias: string;
	history: History;
	expandedDeliverableAssignmentId: number;
	expandedDeliverableSubmissionId: number;
	deliverableId: number;
	isAllSelected: boolean;
	selected: { [submissionId: number]: true; };
	selectSubmission: (deliverableId: number, deliverableSubmissionId: number) => void;
	selectAllSubmissions: (deliverableId: number) => void;
	clearExpandedState: () => void;
}

type Props = OwnProps;

interface State {
	showStatusHistoryModal: boolean;
	submission: Nullable<DeliverableSubmissionTableViewModel>;
	expanded: ExpandedDefinition;
	showExpander: boolean;
	expandedCleared: boolean;
}

class DeliverableChildTable extends React.Component<Props, State> {
	state: State = {
		showStatusHistoryModal: false,
		submission: {} as DeliverableSubmissionTableViewModel,
		expanded: {},
		showExpander: false,
		expandedCleared: !this.props.expandedDeliverableSubmissionId,
	};

	columns: Column<DeliverableSubmissionTableViewModel>[] = [
		{
			Header: () => {
				const { expanded, showExpander } = this.state;

				if (!showExpander) {
					return null;
				}
				const isAnyExpanded = Object.keys(expanded).some((_key) => !!expanded[_key]);
				return (
					<a
						className={`table-container__expander ${isAnyExpanded ? 'icon-collapse' : 'icon-expand'}`}
						onClick={isAnyExpanded ? this.collapseAll : this.expandAll}
						role="submit"
					/>
				);
			},
			accessor: 'expander',
			expander: true,
			sortable: false,
			Expander: ({ isExpanded, original }) => {
				if (!original.assignments?.rows?.length) {
					return null;
				}
				if (original.infiniteScroll) {
					return (
						<div className="table-container__expander-container">
							{isExpanded ? 'Hide' : 'Show'} Assignments
							{isExpanded
								? <span className="table-container__expander icon-collapse" />
								: <span className="table-container__expander icon-expand" />
							}
						</div>
					);
				}
				return (
					<div>
						{isExpanded
							? <span className="table-container__expander icon-collapse" />
							: <span className="table-container__expander icon-expand" />}
					</div>
				);
			},
			className: 'expander unresizable',
			headerClassName: 'unresizable',
			resizable: false,
			Cell: () => null,
		},
		{
			Header: () => {
				const { selectAllSubmissions, isAllSelected, deliverableId } = this.props;
				return (
					<Checkbox
						handleChange={selectAllSubmissions.bind(this, deliverableId)}
						isChecked={isAllSelected}
					/>
				);
			},
			sortable: false,
			accessor: 'id',
			Cell: ({ original }) => {
				const { selectSubmission, selected } = this.props;
				return (
					<Checkbox
						handleChange={selectSubmission.bind(this, original.deliverableId, original.id)}
						isChecked={selected?.[original.id] ?? false}
					/>
				);
			},
			getProps: () => ({ onClick: () => { return; } }),
			resizable: false,
			className: 'expander unresizable',
			headerClassName: 'unresizable',
		},
		{
			Header: 'Status',
			accessor: 'status',
			sortable: false,
			Cell: ({ original }) => (
				original.status
					? (
						<DeliverableStatusLabel
							abbreviation={original.status.abbreviation}
							history={original.history}
							onClick={this.onStatusClick(original)}
							statusColor={original.status.statusColor}
							textColor={original.status.textColor}
						/>
					)
					: <EmptyCell />
			),
		},
		{
			Header: 'Date of work',
			sortable: false,
			accessor: 'dueDate',
			className: 'rt-td--inverted',
			Cell: ({ original }) => (
				original.dueDate
					? (
						<Tooltip
							className="job-preview__popover"
							message={
								<ResourceTooltipList
									workOrderEmployees={original.employees}
									workOrderEquipment={original.equipment}
									workOrderTemporaryEmployees={original.temporaryEmployees}
								/>}
						>
							<div>
								{original.dueDate}
							</div>
						</Tooltip>
					)
					: <EmptyCell />
			),
		},
		{
			Header: 'Work Order Code',
			sortable: false,
			accessor: 'workOrderCode',
			className: 'rt-td--inverted',
			Cell: ({ original }) => (
				original.workOrderCode
					? (
						<Tooltip
							className="job-preview__popover"
							message={
								<ResourceTooltipList
									workOrderEmployees={original.employees}
									workOrderEquipment={original.equipment}
									workOrderTemporaryEmployees={original.temporaryEmployees}
								/>
							}
						>
							<div>
								{original.workOrderCode}
							</div>
						</Tooltip>
					)
					: <EmptyCell />
			),
		},
		{
			Header: 'Submission Date',
			sortable: false,
			accessor: 'submissionDate',
			className: 'rt-td--inverted',
			Cell: ({ original }) => (
				original.submissionDate
					? (
						<Tooltip
							className="job-preview__popover"
							message={
								<ResourceTooltipList
									workOrderEmployees={original.employees}
									workOrderEquipment={original.equipment}
									workOrderTemporaryEmployees={original.temporaryEmployees}
								/>}
						>
							<div>
								{original.submissionDate}
							</div>
						</Tooltip>
					)
					: <EmptyCell />
			),
		},
		{
			Header: 'Delivery Type',
			accessor: 'deliveryType',
			sortable: false,
			className: 'rt-td--inverted',
			Cell: ({ original }) => (
				<Tooltip
					className="job-preview__popover"
					message={
						<ResourceTooltipList
							workOrderEmployees={original.employees}
							workOrderEquipment={original.equipment}
							workOrderTemporaryEmployees={original.temporaryEmployees}
						/>}
				>
					<div>
						{(original.trackingLink && URL_REGEX.test(original.trackingLink))
							? <a className="link" href={original.trackingLink} rel="noopener noreferrer" target="_blank">{original.deliverableDeliveryMethod}</a>
							: <>{original.deliverableDeliveryMethod}</>
						}
					</div>
				</Tooltip>
			),
		},
		{
			Header: 'Review Type',
			accessor: 'reviewType',
			sortable: false,
			className: 'rt-td--inverted',
			Cell: ({ original }) => (
				original.reviewType
					? (
						<Tooltip
							className="job-preview__popover"
							message={
								<ResourceTooltipList
									workOrderEmployees={original.employees}
									workOrderEquipment={original.equipment}
									workOrderTemporaryEmployees={original.temporaryEmployees}
								/>}
						>
							<div>
								{DeliverableReviewTypeLabelEnum[original.reviewType]}
							</div>
						</Tooltip>
					)
					: <EmptyCell />
			),
		},
		{
			Header: 'Comments',
			sortable: false,
			accessor: 'comments',
			Cell: ({ original }) => <CommentCell comments={original.comments} />,
		},
		{
			Header: 'Notes',
			accessor: 'notes',
			sortable: false,
			Cell: ({ original }) => (
				original.notes
					? (
						<Tooltip message={original.notes}>
							<span className="icon-display_view notes-icon" />
						</Tooltip>
					)
					: <EmptyCell />
			),
		},
	];

	private _tableRef: Nullable<ReactTableRef<DeliverableSubmissionTableViewModel>> = null;

	static getDerivedStateFromProps(props: Props) {
		const { data } = props;
		return {
			showExpander: data?.rows?.some((_row) => _row.assignments.rows.length) ?? false,
		};
	}

	static getRowClassModifiers({ expanded }: FinalState<DeliverableSubmissionTableViewModel>, row: TypedRowInfo<DeliverableSubmissionTableViewModel>) {
		const modifiers = ['grey'];

		if (row && expanded[row.index]) {
			modifiers.push('expanded');
		}

		return modifiers;
	}

	static getRowGroupClassModifiers({ expanded }: FinalState<DeliverableSubmissionTableViewModel>, row: TypedRowInfo<DeliverableSubmissionTableViewModel>) {
		if (row && expanded[row.index]) {
			return ['blue'];
		}
		return [];
	}

	static getHeaderClassModifiers() {
		return ['grey'];
	}

	tab = (): TabProps<DeliverableSubmissionTableViewModel> => {
		return {
			columns: this.columns,
			fetch: this.fetch,
			onExpandedChange: this.onExpandedChange,
			getRowClassModifiers: DeliverableChildTable.getRowClassModifiers,
			getRowGroupClassModifiers: DeliverableChildTable.getRowGroupClassModifiers,
			getHeaderClassModifiers: DeliverableChildTable.getHeaderClassModifiers,
			onRowClick: this.onRowClick,
			rowActions: [
				{
					label: 'Edit',
					action: this.edit,
					shouldRefresh: false,
				},
				{
					label: 'Assign employee',
					action: this.addChild,
					shouldRefresh: false,
					hide: (original) => original?.isCanceled ?? false,
				},
			],
		};
	};

	onTableMount = (table: ReactTableRef<DeliverableSubmissionTableViewModel>) => {
		this._tableRef = table;
	};

	onExpandedChange = (expanded: ExpandedDefinition) => {
		this.setState(() => ({ expanded }));
	};

	expandAll = () => {
		const allRows = this._tableRef?.props?.data;
		const newExpanded = allRows
			? allRows.reduce((_acc: ExpandedDefinition, _row: DeliverableSubmissionTableViewModel, _index: number) => {
				if (_row.assignments?.rows?.length) {
					_acc[_index] = true;
				}
				return _acc;
			}, {})
			: {};
		this.onExpandedChange(newExpanded);
	};

	collapseAll = () => {
		this.onExpandedChange({});
	};

	onRowClick = (rowInfo: RowInfo) => {
		this.edit(rowInfo.original);
	};

	edit = (original: DeliverableSubmissionTableViewModel) => {
		const { orgAlias, companyName, history } = this.props;
		history.push(CLIENT.COMPANY.DELIVERABLE.SUBMISSION.EDIT(orgAlias, companyName, original.deliverableId.toString(), original.id.toString()));
	};

	addChild = (original: DeliverableSubmissionTableViewModel) => {
		const { orgAlias, companyName, history } = this.props;
		history.push(CLIENT.COMPANY.DELIVERABLE.ASSIGNMENT.CREATE(orgAlias, companyName, original.deliverableId.toString(), original.id.toString()));
	};

	fetch = async () => {
		const { data } = this.props;
		return data;
	};

	renderChildTable = ({ original }: TypedRowInfo<DeliverableSubmissionTableViewModel>) => {
		const { orgAlias, companyName, history, expandedDeliverableAssignmentId, clearExpandedState } = this.props;
		return (
			<DeliverableGrandchildTable
				clearExpandedState={clearExpandedState}
				companyName={companyName}
				data={original.assignments}
				expandedDeliverableAssignmentId={expandedDeliverableAssignmentId}
				history={history}
				orgAlias={orgAlias}
			/>
		);
	};

	onStatusClick = (original: DeliverableSubmissionTableViewModel) => {
		return (event: React.MouseEvent) => {
			event.stopPropagation();
			event.preventDefault();
			this.setState(() => ({ showStatusHistoryModal: true, submission: original }));
		};
	};

	closeModal = () => {
		this.setState(() => ({ showStatusHistoryModal: false, submission: null }));
	};

	isRowExpanded = (row: DeliverableSubmissionTableViewModel, index: number): boolean => {
		const { expandedDeliverableSubmissionId, expandedDeliverableAssignmentId } = this.props;
		const { expanded } = this.state;
		return (row.id === expandedDeliverableSubmissionId || expanded[index]) && !!row.assignments.rows?.length && !!expandedDeliverableAssignmentId;
	};

	isRowHighlighted = (row: DeliverableSubmissionTableViewModel): boolean => {
		const { expandedDeliverableSubmissionId, expandedDeliverableAssignmentId } = this.props;
		const { expandedCleared } = this.state;
		return !expandedCleared && row.id === expandedDeliverableSubmissionId && !expandedDeliverableAssignmentId;
	};

	clearExpandedState = () => {
		const { clearExpandedState } = this.props;
		this.setState(() => ({ expandedCleared: true }));
		clearExpandedState();
	};

	render() {
		const { data, expandedDeliverableSubmissionId } = this.props;
		const { showStatusHistoryModal, submission, expanded } = this.state;

		return (
			<>
				<IndentTable
					expanded={expanded}
					getRowExpanded={expandedDeliverableSubmissionId ? this.isRowExpanded : undefined}
					getRowHighlighted={expandedDeliverableSubmissionId ? this.isRowHighlighted : undefined}
					indent={1}
					isLoaded={true}
					onMount={this.onTableMount}
					onScrollIntoNode={this.clearExpandedState}
					SubComponent={this.renderChildTable}
					tab={this.tab()}
					table={data}
					tableName={TableNameEnum.DELIVERABLE_CHILD_TABLE}
				/>
				{(showStatusHistoryModal && submission) &&
					<StatusHistoryModal
						closeModal={this.closeModal}
						history={submission.history}
						showModal={showStatusHistoryModal}
						statusInfo={
							<DeliverableSubmissionHeader
								comments={submission.comments}
								notes={submission.notes}
								submissionCode={submission.submissionId}
							/>
						}
					/>
				}
			</>
		);
	}
}

export default DeliverableChildTable;
