import { connect, type ConnectedProps } from 'react-redux';
import { useHistory, useLocation } from 'react-router';
import * as React from 'react';
import { useOutletContext } from 'react-router-dom-v5-compat';
import { compose } from 'redux';
import type { InjectedFormProps } from 'redux-form';
import { FieldArray, getFormValues } from 'redux-form';

import PagePermissions from 'ab-enums/pagePermissions.enum';

import type { RootState } from 'af-reducers';

import withOutletContext from 'af-components/outletContextWrapper';

import CLIENT from 'af-routes/client';

import type { FormJobOutletContext } from 'af-root/scenes/Company/Jobs/Form/types';

import type ImportBillingCodeRM from 'ab-requestModels/billingCode/importBillingCode.requestModel';

import { useToggle } from 'af-utils/react.util';
import { isAllowed } from 'ab-utils/auth.util';

import { formSectionClass } from '../helpers';
import BulkImport from './BulkImport';
import type JobUpsertFM from '../formModel';
import styles from '../styles.module.scss';
import type { OwnProps as BillingCodeArrayProps } from './BillingCodes/BillingCodeArray';
import BillingCodeArray from './BillingCodes/BillingCodeArray';

type OwnProps = FormJobOutletContext;

type Props = ConnectedProps<typeof connector> & InjectedFormProps<JobUpsertFM>;

const BillingCodesTab: React.FC<Props> = (props) => {
	const {
		billingCodes,
		change,
		hasPermissionsToManageBillingCodes,
	} = props;

	const [importedBillingCodes, setImportedBillingCodes] = React.useState<ImportBillingCodeRM[]>([]);
	const history = useHistory();
	const location = useLocation<{ orgAlias: string; }>();
	const { initialized, billingCodesSubmitErrors, billingCodesSyncErrors } = useOutletContext<FormJobOutletContext>();

	const {
		value: showBulkImportModal,
		setToTrue: openBulkImportModal,
		setToFalse: hideBulkImportModal,
	} = useToggle(false);

	if (!hasPermissionsToManageBillingCodes) {
		history.push(CLIENT.ERROR.ERR403(location.state.orgAlias));
	}

	const { lineItemNumberCounter, customerIdCounter } = React.useMemo(() => {
		const _lineItemNumberCounter: Record<number, number> = {};
		const _customerIdCounter: Record<string, number> = {};
		let _hasDuplicates = false;
		if (billingCodes?.length) {
			for (const billingCode of billingCodes) {
				if (billingCode.lineItemNumber) {
					_lineItemNumberCounter[billingCode.lineItemNumber] = (_lineItemNumberCounter[billingCode.lineItemNumber] ?? 0) + 1;
					if (_lineItemNumberCounter[billingCode.lineItemNumber] > 1) {
						_hasDuplicates = true;
					}
				}
				if (billingCode.customerId) {
					_customerIdCounter[billingCode.customerId] = (_customerIdCounter[billingCode.customerId] ?? 0) + 1;

					if (_customerIdCounter[billingCode.customerId] > 1) {
						_hasDuplicates = true;
					}
				}
			}
		}

		return {
			lineItemNumberCounter: _lineItemNumberCounter,
			customerIdCounter: _customerIdCounter,
			hasDuplicates: _hasDuplicates,
		};
	}, [billingCodes]);

	return (
		<div className={styles['job-form__tab-content']} >
			<div className={formSectionClass}>
				<FieldArray<BillingCodeArrayProps>
					billingCodeSubmitErrors={billingCodesSubmitErrors}
					billingCodeSyncErrors={billingCodesSyncErrors}
					change={change}
					component={BillingCodeArray}
					customerIdCounter={customerIdCounter}
					importedBillingCodes={importedBillingCodes}
					initialized={initialized}
					lineItemNumberCounter={lineItemNumberCounter}
					name="billingCodes"
					openBulkImportModal={openBulkImportModal}
				/>
				<BulkImport
					addImportedBillingCodes={setImportedBillingCodes}
					closeModal={hideBulkImportModal}
					showModal={showBulkImportModal}
				/>
			</div>
		</div>
	);
};

function mapStateToProps(state: RootState, ownProps: OwnProps) {
	const { user: { companyData, userData } } = state;
	if (!userData || !companyData) {
		throw new Error('User not logged in');
	}

	const { isCompanyAdmin, permissions } = companyData;
	const { role } = userData;

	const hasPermissionsToManageBillingCodes = isAllowed(
		PagePermissions.COMPANY.JOBS.MANAGE_BILLING_CODES,
		permissions,
		isCompanyAdmin,
		role
	);

	const currentFormValues = getFormValues(ownProps.formName)(state) as JobUpsertFM;

	return {
		hasPermissionsToManageBillingCodes,
		billingCodes: currentFormValues?.billingCodes ?? [],
	};
}

const connector = connect(mapStateToProps);

const enhance = compose<React.ComponentType>(
	withOutletContext<FormJobOutletContext, undefined>,
	connector,
	React.memo
);

export default enhance(BillingCodesTab);
