import * as React from 'react';
import { Field } from 'redux-form';
import { Row, Col } from 'react-bootstrap';

import FieldReportAccessRole, { FieldReportAccessRoleLabel } from 'acceligent-shared/enums/fieldReportAccessRole';
import FormFieldArrayStatus from 'acceligent-shared/enums/formFieldArrayStatus';
import TimeFormat from 'acceligent-shared/enums/timeFormat';

import type AccessRM from 'ab-requestModels/fieldReport/fieldReportAccess.requestModel';

import type { FieldReportAccessOptionVM } from 'ab-viewModels/employee/fieldReportAccessOption.viewModel';

import Checkbox from 'af-fields/Checkbox';
import DateInput from 'af-fields/DateInput';
import Dropdown from 'af-fields/Dropdown';
import Text from 'af-fields/Text';

import { bemBlock, bemElement } from 'ab-utils/bem.util';

interface OwnProps {
	/** YYYY-MM-DD */
	defaultDateValue: string;
	field: string;
	index: number;
	superintendents: FieldReportAccessOptionVM[];
	read: (index: number) => AccessRM['accessList'][0];
	update: (index: number, value: AccessRM['accessList'][0]) => void;
	remove: (index: number) => void;
}

type Props = OwnProps;

const CALENDAR_SETTINGS = { minDate: new Date() };

const FILTER_SI_BY: (keyof FieldReportAccessOptionVM)[] = ['fullName'];

const TOOLTIP_MESSAGE = 'User will lose access to the report at midnight of selected date. This is automatically calculated using the automatic access removal setting in Work Order settings, but can also be set to another value';

const renderOption = (option: FieldReportAccessOptionVM) => <span>{option.fullName} ({option.uniqueId})</span>;
const renderSelectedOption = (option: FieldReportAccessOptionVM) => <span>{option.fullName}</span>;

const ListElement: React.FC<Props> = (props: Props) => {
	const {
		field,
		defaultDateValue,
		index,
		superintendents,
		read,
		update,
		remove,
	} = props;

	const [showCalendar, setShowCalendar] = React.useState(false);
	const [employee, setEmployee] = React.useState({} as AccessRM['accessList'][0]);

	React.useEffect(() => {
		const _employee = read(index);
		setShowCalendar(_employee.role === FieldReportAccessRole.SUPERINTENDENT ? false : _employee.isAllowed);
		setEmployee(_employee);
	}, [index, read]);

	const handleValueChange = React.useCallback((value: string) => {
		update(index, { removeAccessAt: value } as AccessRM['accessList'][0]);
	}, [index, update]);

	const handleRemove = React.useCallback(() => {
		remove(index);
	}, [index, remove]);

	const handleAccessChange = React.useCallback((value: boolean) => {
		if (employee.role !== FieldReportAccessRole.SUPERINTENDENT) {
			update(index, { isAllowed: value, removeAccessAt: value ? defaultDateValue : null } as AccessRM['accessList'][0]);
		}
	}, [employee.role, index, update, defaultDateValue]);

	if (employee.status === FormFieldArrayStatus.REMOVED) {
		return null;
	}

	return (
		<Row className="field-report-access-modal__row row--flex">
			<Col className={bemElement('field-report-access-modal', employee.status !== FormFieldArrayStatus.ADDED ? 'name-cell' : 'dropdown-cell')} sm={6}>
				{employee.status !== FormFieldArrayStatus.ADDED ?
					<Field
						component={Text}
						name={`${field}.employee.fullName`}
						onlyValue={true}
					/>
					:
					<Field
						component={Dropdown}
						filterable
						filterBy={FILTER_SI_BY}
						fixed
						id={index}
						labelKey="fullName"
						name={`${field}.employee`}
						options={superintendents}
						propName={`${field}.employee`}
						renderMenuItem={renderOption}
						renderSelected={renderSelectedOption}
						valueKey="accountId"
						withCaret={true}
					/>
				}
			</Col>
			<Col className="field-report-access-modal__role-cell" sm={6}>
				{FieldReportAccessRoleLabel[employee.role ?? FieldReportAccessRole.FIELD_WORKER]}
			</Col>
			<Col className={bemElement('field-report-access-modal', 'access-cell', { added: employee.added || employee.status === FormFieldArrayStatus.ADDED })} sm={12}>
				<Field
					checked={false}
					component={Checkbox}
					isDisabled={employee.role === FieldReportAccessRole.SUPERINTENDENT}
					isStandalone={true}
					label={showCalendar ? 'Access Until:' : undefined}
					name={`${field}.isAllowed`}
					onValueChange={handleAccessChange}
					tooltipMessage={TOOLTIP_MESSAGE}
				/>
				{showCalendar &&
					<Field
						calendarSettings={CALENDAR_SETTINGS}
						component={DateInput}
						dateFormat={TimeFormat.DATE_ONLY}
						fixed={true}
						id={`${field}.removeAccessAt`}
						isStandalone={true}
						name={`${field}.removeAccessAt`}
						onValueChange={handleValueChange}
						originalDateFormat={TimeFormat.DB_DATE_ONLY}
						placeholderText={TimeFormat.DATE_ONLY}
					/>
				}
				{(employee.added || employee.status === FormFieldArrayStatus.ADDED) &&
					<span className={bemBlock('btn', ['link'])} onClick={handleRemove}>
						<span className="icon-delete" /> Remove
					</span>
				}
			</Col>
		</Row>
	);
};

export default React.memo(ListElement);
