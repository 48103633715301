import { TWILIO_MESSAGE_TOO_LONG_ERROR, TWILIO_MESSAGE_TIMEOUT } from 'ab-common/constants/value';

import NotificationErrorType from 'acceligent-shared/enums/notificationErrorType';

export * from 'acceligent-shared/enums/notifyType';

export const getErrorMessage = (errorType: Nullable<NotificationErrorType>, errorMessage: Nullable<string>, isSMS: boolean = false) => {
	switch (errorType) {
		case NotificationErrorType.SPAM:
			return 'Notification was marked as spam';
		case NotificationErrorType.BOUNCED:
			return 'Notification was bounced by receiver';
		case NotificationErrorType.INVALID:
			return 'Destination is invalid';
		case NotificationErrorType.BLOCKED:
			return 'Notification was blocked by user.';
		case NotificationErrorType.UNKNOWN:
			if (errorMessage === TWILIO_MESSAGE_TOO_LONG_ERROR) {
				return 'SMS message exceeds 1600 characters and it can not be sent. Try reducing daily tip or work order notes.';
			}
			if (errorMessage === TWILIO_MESSAGE_TIMEOUT) {
				return 'Something went wrong while sending SMS. Please try again.';
			}
			if (errorMessage?.includes('The \'To\' phone number') && errorMessage.includes('is not currently reachable using the \'From\' phone number')) {
				return 'The destination is currently unreachable.';
			}
			if (errorMessage?.includes('"ErrorCode":"30003"')) {
				return 'The destination handset is switched off or otherwise unavailable.';
			}
			if (errorMessage?.includes('"ErrorCode":"30004"')) {
				return 'Number is blocked from receiving this message.';
			}
			if (errorMessage?.includes('"ErrorCode":"30005"')) {
				return 'Unknown destination.';
			}
			if (errorMessage?.includes('"ErrorCode":"30008"')) {
				return 'Notification failed for unknown reasons.';
			}
			if (isSMS) {
				return 'Carrier not responding.';
			}
		default:
			return;
	}
};
