import * as React from 'react';
import { compose } from 'redux';
import type { ConnectedProps } from 'react-redux';
import { connect } from 'react-redux';
import { useOutletContext } from 'react-router-dom-v5-compat';

import * as DivisionActions from 'af-actions/division';
import * as EmployeeActions from 'af-actions/employee';
import * as JobStatusActions from 'af-actions/jobStatus';
import * as LocationActions from 'af-actions/location';
import * as SaleActions from 'af-actions/sale';

import ContactsSection from './Sections/Contacts';
import DeliverableSection from './Sections/DeliverableSection';
import DisposalSection from './Sections/DisposalSection';
import JobDetailSection from './Sections/JobDetailSection';
import JobSummarySection from './Sections/JobSummarySection';
import RequierementsSection from './Sections/RequierementsSection';
import SalesSection from './Sections/SalesSection';
import ScheduleInfo from './Sections/ScheduleInfo';
import SummaryOfWorkSection from './Sections/SummaryOfWorkSection';
import SurveyDataSection from './Sections/SurveyDataSection';
import TrucksAndEquipmentSection from './Sections/TrucksAndEquipment';
import WorkLocationSection from './Sections/WorkLocation';

import styles from '../styles.module.scss';
import type { WorkRequestFormOutletContext } from '../types';

type Props = ConnectedProps<typeof connector>;

const DetailsFormTab: React.FC<Props> = (props) => {
	const salesRef = React.useRef<HTMLDivElement>(null);

	React.useEffect(() => {
		if (salesRef.current) {
			salesRef.current.scrollIntoView({ block: 'end' });
		}
	}, []);

	const {
		findSales,
		findJobStatuses,
		findAllProjectManagers,
		findAllLocations,
		findAllDivisions,
		findAllSuperintendentsAndProjectManagers,
	} = props;

	const { change, formName } = useOutletContext<WorkRequestFormOutletContext>();

	return (
		<div className={styles['work-request-form__tab-content']}>
			<SalesSection findSales={findSales} salesRef={salesRef} />
			<JobSummarySection
				findAllDivisions={findAllDivisions}
				findAllLocations={findAllLocations}
				findAllProjectManagers={findAllProjectManagers}
				findJobStatuses={findJobStatuses}
			/>
			<JobDetailSection />
			<RequierementsSection />
			<ScheduleInfo change={change} formName={formName} />
			<ContactsSection formName={formName} />
			<DeliverableSection change={change} formName={formName} />
			<WorkLocationSection formName={formName} />
			<SummaryOfWorkSection />
			<SurveyDataSection findAllSuperintendentsAndProjectManagers={findAllSuperintendentsAndProjectManagers} />
			<TrucksAndEquipmentSection />
			<DisposalSection change={change} />
		</div >
	);
};

function mapDispatchToProps() {
	return {
		findSales: SaleActions.findAllForForm,
		findJobStatuses: JobStatusActions.findList,
		findAllProjectManagers: EmployeeActions.findAllProjectManagers,
		findAllLocations: LocationActions.findList,
		findAllDivisions: DivisionActions.findAllForCompany,
		findAllSuperintendentsAndProjectManagers: EmployeeActions.findAllSuperintendentsAndProjectManagers,
	};
}

const connector = connect(null, mapDispatchToProps());

const enhance = compose<React.ComponentType>(
	connector,
	React.memo
);

export default enhance(DetailsFormTab);
