import * as React from 'react';
import type { ConnectedProps } from 'react-redux';
import { connect } from 'react-redux';

import ScheduleBoardProperty from 'ab-enums/scheduleBoardProperty.enum';

import type { RootState } from 'af-reducers';

import EmployeeDraggable from 'af-root/scenes/Company/ScheduleBoard/Shared/EmployeeDraggable';
import EquipmentDraggable from 'af-root/scenes/Company/ScheduleBoard/Shared/EquipmentDraggable';
import PlaceholderDraggable from 'af-root/scenes/Company/ScheduleBoard/Shared/PlaceholderDraggable';
import TemporaryEmployeeDraggable from 'af-root/scenes/Company/ScheduleBoard/Shared/TemporaryEmployeeDraggable';

interface OwnProps {
	droppableId: string;
	draggablePrefix: string; // to distinguish if it's board or toolbar dragged employee
	resourceId: number;
	/** `MM-DD-YYYY` */
	dueDate: string;
	index: number;
	isCardDisabled: boolean;
	isCalculationsView: boolean;
	isWorkOrderCanceled: boolean;
	isCopyPlaceholder: boolean;
	isDragAndDropDisabled: boolean;
	hasPermissionsToEditScheduleBoard: boolean;
	ignorePlaceholders: boolean;
	workOrderCode: string;
	isWOLocked: boolean;
}

type Props = OwnProps & ConnectedProps<typeof connector>;

const ResourceList: React.FC<Props> = (props: Props) => {
	const {
		draggablePrefix,
		droppableId,
		dueDate,
		isCardDisabled,
		isCalculationsView,
		isWorkOrderCanceled,
		isCopyPlaceholder,
		resource,
		index,
		resourceId,
		isDragAndDropDisabled,
		hasPermissionsToEditScheduleBoard,
		ignorePlaceholders,
		workOrderCode,
		isWOLocked,
	} = props;

	// if resource not found or is placeholder but should be ignored (on calculations page)
	if (!resource || (resource.workOrderPlaceholderId && ignorePlaceholders)) {
		return null;
	}

	if (resource.workOrderEmployeeId && resource.employeeId) {
		return (
			<EmployeeDraggable
				draggableId={`${draggablePrefix}#${ScheduleBoardProperty.EMPLOYEE}#${dueDate}#${resourceId}`}
				droppableId={droppableId}
				dueDate={dueDate}
				employeeId={resource.employeeId}
				hasPermissionsToEditScheduleBoard={hasPermissionsToEditScheduleBoard}
				index={index}
				isCalculationsView={isCalculationsView}
				isCardDisabled={isCardDisabled}
				isCopyPlaceholder={isCopyPlaceholder}
				isDragAndDropDisabled={isDragAndDropDisabled}
				isPerDiem={resource.perDiem}
				isToolbar={false}
				isWOLocked={isWOLocked}
				isWorkOrderCanceled={isWorkOrderCanceled}
				key={`${draggablePrefix}#${ScheduleBoardProperty.EMPLOYEE}#${dueDate}#${resourceId}`}
				resourceId={resourceId}
				workOrderCode={workOrderCode}
				workOrderEmployeeId={resource.workOrderEmployeeId}
			/>
		);
	} else if (resource.workOrderEquipmentId && resource.equipmentId) {
		return (
			<EquipmentDraggable
				draggableId={`${draggablePrefix}#${ScheduleBoardProperty.EQUIPMENT}#${dueDate}#${resourceId}`}
				droppableId={droppableId}
				dueDate={dueDate}
				equipmentId={resource.equipmentId}
				hasPermissionsToEditScheduleBoard={hasPermissionsToEditScheduleBoard}
				index={index}
				isCalculationsView={isCalculationsView}
				isCardDisabled={isCardDisabled}
				isCopyPlaceholder={isCopyPlaceholder}
				isDragAndDropDisabled={isDragAndDropDisabled}
				isToolbar={false}
				isWOLocked={isWOLocked}
				isWorkOrderCanceled={isWorkOrderCanceled}
				key={`${draggablePrefix}#${ScheduleBoardProperty.EQUIPMENT}#${dueDate}#${resourceId}`}
				resourceId={resourceId}
				workOrderEquipmentId={resource.workOrderEquipmentId}
			/>
		);
	} else if (resource.workOrderTemporaryEmployeeId && resource.temporaryEmployeeId) {
		return (
			<TemporaryEmployeeDraggable
				draggableId={`${draggablePrefix}#${ScheduleBoardProperty.TEMPORARY_EMPLOYEE}#${dueDate}#${resourceId}`}
				droppableId={droppableId}
				dueDate={dueDate}
				index={index}
				isCalculationsView={isCalculationsView}
				isCardDisabled={isCardDisabled}
				isCopyPlaceholder={isCopyPlaceholder}
				isDragAndDropDisabled={isDragAndDropDisabled}
				isWOLocked={isWOLocked}
				isWorkOrderCanceled={isWorkOrderCanceled}
				key={`${draggablePrefix}#${ScheduleBoardProperty.TEMPORARY_EMPLOYEE}#${dueDate}#${resourceId}`}
				resourceId={resourceId}
				temporaryEmployeeId={resource.temporaryEmployeeId}
				workOrderCode={workOrderCode}
				workOrderTemporaryEmployeeId={resource.workOrderTemporaryEmployeeId}
			/>
		);
	} else if (resource.workOrderPlaceholderId) {
		return (
			<PlaceholderDraggable
				draggableId={`${draggablePrefix}#${ScheduleBoardProperty.PLACEHOLDER}#${dueDate}#${resourceId}`}
				droppableId={droppableId}
				dueDate={dueDate}
				hasPermissionsToEditScheduleBoard={hasPermissionsToEditScheduleBoard}
				index={index}
				isCardDisabled={isCardDisabled}
				isCopyPlaceholder={isCopyPlaceholder}
				isDragAndDropDisabled={isDragAndDropDisabled}
				isWorkOrderCanceled={isWorkOrderCanceled}
				key={`${draggablePrefix}#${ScheduleBoardProperty.PLACEHOLDER}#${dueDate}#${resourceId}`}
				resourceId={resourceId}
				workOrderPlaceholderId={resource.workOrderPlaceholderId}
			/>
		);
	} else {
		return null;
	}
};

function mapStateToProps(state: RootState, ownProps: OwnProps) {
	const { dueDate, isWorkOrderCanceled, resourceId } = ownProps;
	const { workOrderResourceLookups, canceledWorkOrderResourceLookups } = state.scheduleBoard.workOrdersByDateDictionary[dueDate];
	const resource = isWorkOrderCanceled ?
		canceledWorkOrderResourceLookups[resourceId] :
		workOrderResourceLookups[resourceId];

	return {
		resource,
	};
}

const connector = connect(mapStateToProps);

export default connector(ResourceList);
