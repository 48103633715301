import * as React from 'react';
import { compose } from 'redux';
import type { ConnectedProps } from 'react-redux';
import { connect } from 'react-redux';

import type { RootState } from 'af-reducers';

import * as ScheduleBoardActions from 'af-actions/scheduleBoard';

import Card from 'af-root/scenes/Company/ScheduleBoard/Shared/Card';
import BlankCard from 'af-root/scenes/Company/ScheduleBoard/Shared/Card/BlankCard';

import * as ScheduleBoardUtil from 'af-utils/scheduleBoard.util';

import type { SelectedWorkOrderModel } from 'af-models/scheduleBoard.models';

interface OwnProps {
	addBlankWorkOrder: (dueDate: string, index: number) => Promise<void>;
	/** `MM-DD-YYYY` */
	dueDate: string;
	forceUnlockOrder: (workOrderId: string) => void;
	hasPermissionsToEditScheduleBoard: boolean;
	isDragAndDropDisabled: boolean;
	lastOpenedOrderCode: string;
	removeBlankWorkOrder: (dueDate: string, index: number) => Promise<void>;
}

type Props = OwnProps & ConnectedProps<typeof connector>;

const DayViewCards: React.FC<Props> = (props) => {
	const {
		addBlankWorkOrder,
		dueDate,
		forceUnlockOrder,
		hasPermissionsToEditScheduleBoard,
		isDragAndDropDisabled,
		lastOpenedOrderCode,
		removeBlankWorkOrder,
		workOrdersOrdering = [],
		toggleOrder,
	} = props;

	const onToggle = React.useCallback((workOrder: SelectedWorkOrderModel) => {
		toggleOrder(dueDate, { id: workOrder.id, code: workOrder.code, isCancelled: workOrder.isCancelled });
	}, [dueDate, toggleOrder]);

	return workOrdersOrdering.map((_jobCode: string, _index: number) => {
		const isBlank = ScheduleBoardUtil.isBlankWorkOrderId(_jobCode);
		const isPlaceholder = ScheduleBoardUtil.isLoadingPlaceholderDroppableId(_jobCode);
		return isBlank || isPlaceholder ?
			(
				<BlankCard
					draggableId={_jobCode}
					dueDate={dueDate}
					hasPermissionsToEditScheduleBoard={hasPermissionsToEditScheduleBoard}
					index={_index}
					isDragAndDropDisabled={isDragAndDropDisabled}
					isPlaceholder={isPlaceholder}
					key={_jobCode}
					removeBlankWorkOrder={removeBlankWorkOrder}
				/>
			) : (
				<Card
					addBlankWorkOrder={addBlankWorkOrder}
					deselectOrder={onToggle}
					dueDate={dueDate}
					forceUnlockOrder={forceUnlockOrder}
					hasPermissionsToEditScheduleBoard={hasPermissionsToEditScheduleBoard}
					index={_index}
					isDragAndDropDisabled={isDragAndDropDisabled}
					key={_jobCode}
					lastOpenedOrderCode={lastOpenedOrderCode}
					selectOrder={onToggle}
					workOrderCode={_jobCode}
				/>
			);
	});
};

const mapStateToProps = (state: RootState, ownProps: OwnProps) => {
	const { dueDate } = ownProps;

	return {
		workOrdersOrdering: state?.scheduleBoard?.workOrdersByDateDictionary?.[dueDate]?.workOrdersOrdering,
	};
};

function mapDispatchToProps() {
	return {
		toggleOrder: ScheduleBoardActions.toggleWorkOrderWeeklyView,
	};
}

const connector = connect(mapStateToProps, mapDispatchToProps());

const enhance = compose<React.ComponentType<OwnProps>>(
	React.memo,
	connector
);

export default enhance(DayViewCards);
