import type { Dispatch, AnyAction } from 'redux';

import type UnavailabilityReasonScope from 'acceligent-shared/enums/unavailabilityReasonScope';

import type { GetRootState } from 'af-reducers';

import API from 'af-constants/routes/api';

import { http } from 'af-utils/http.util';
import { errorHandler } from 'af-utils/actions.util';

import type { UnavailabilityReasonViewModel } from 'ab-viewModels/unavailabilityReason.viewModel';

export function findUnavailabilityReasons(query: string, scope: UnavailabilityReasonScope) {
	return async (dispatch: Dispatch<AnyAction>, getState: GetRootState, { redirectTo }) => {

		const action = async () => {
			return await http.get<UnavailabilityReasonViewModel[]>(API.V1.RESOURCES.UNAVAILABILITY_REASON.LIST(query, scope));
		};

		return await errorHandler(action, dispatch, redirectTo);
	};
}
