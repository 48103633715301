import * as React from 'react';
import type { ResolveThunks } from 'react-redux';
import { connect } from 'react-redux';
import type Scrollbars from 'react-custom-scrollbars';

import type { RootState } from 'af-reducers';

import * as ScheduleBoardActions from 'af-actions/scheduleBoard';

import HorizontalScrollbar from 'af-components/HorizontalScrollbar';

interface OwnProps {
	maxWidth: number;
	verticalScrollRef: Nullable<React.RefObject<Scrollbars>>;
	position: 'top' | 'bottom';
	onHorizontalScroll: (scrollLeft: number, width: number, force?: boolean) => void;
}

interface StateProps {
	showCurtain: boolean;
	scrollPercentage: number;
	draggedWorkOrderCode: Nullable<string>;
}

interface DispatchProps {
	setWeeklyViewHorizontalScrollingPercentage: typeof ScheduleBoardActions.setWeeklyViewHorizontalScrollingPercentage;
}

type Props = OwnProps & StateProps & ResolveThunks<DispatchProps>;

class WeeklyHorizontalScrollbar extends React.PureComponent<Props> {
	_verticalScrollTimer: Nullable<NodeJS.Timer> = null;
	_lastVerticalScrollValue: number = 0;
	_scrollingVertically: boolean = false;

	componentWillUnmount() {
		clearInterval(this._verticalScrollTimer ?? undefined);
		this._scrollingVertically = false;
	}

	onVerticalScroll = (elementClassName: string) => {
		const { draggedWorkOrderCode, verticalScrollRef } = this.props;
		const classNames = ['scrollbar-anchor', 'horizontal-scrollbar', 'scrollbar-container'];

		if (classNames.some((className) => elementClassName.includes(className)) && draggedWorkOrderCode) {
			const diff = elementClassName.includes('top') ? -1 : 1;
			if (!verticalScrollRef?.current) {
				return;
			}
			const { scrollTop } = verticalScrollRef.current.getValues();
			this._lastVerticalScrollValue = scrollTop;

			if (!this._scrollingVertically) {
				this._verticalScrollTimer = setInterval(() => {
					this._lastVerticalScrollValue = Math.max(0, this._lastVerticalScrollValue + diff);
					verticalScrollRef.current!.scrollTop(this._lastVerticalScrollValue);
				}, 10);
			}
			this._scrollingVertically = true;
		} else if (this._scrollingVertically) {
			this._scrollingVertically = false;
			clearInterval(this._verticalScrollTimer ?? undefined);
		}
	};

	onRelease = () => clearInterval(this._verticalScrollTimer ?? undefined);

	render() {
		const {
			position, scrollPercentage, maxWidth, setWeeklyViewHorizontalScrollingPercentage, onHorizontalScroll, showCurtain,
		} = this.props;

		return (
			<HorizontalScrollbar
				className="schedule-board-week-row"
				maxWidth={maxWidth}
				onHorizontalScroll={onHorizontalScroll}
				onRelease={this.onRelease}
				onVerticalScroll={this.onVerticalScroll}
				position={position}
				scrollPercentage={scrollPercentage}
				setScrollingPercentage={setWeeklyViewHorizontalScrollingPercentage}
				showCurtain={showCurtain}
			/>
		);
	}
}

function mapStateToProps(state: RootState): StateProps {
	const { weeklyViewDateWithToolbar } = state.scheduleBoard;

	return {
		showCurtain: !!weeklyViewDateWithToolbar,
		scrollPercentage: state.scheduleBoard.weeklyViewHorizontalScrollingPercentage,
		draggedWorkOrderCode: state.scheduleBoard.draggedWorkOrderCode,
	};
}

function mapDispatchToProps(): DispatchProps {
	return {
		setWeeklyViewHorizontalScrollingPercentage: ScheduleBoardActions.setWeeklyViewHorizontalScrollingPercentage,
	};
}

export default connect<StateProps, DispatchProps, OwnProps>(mapStateToProps, mapDispatchToProps())(WeeklyHorizontalScrollbar);
