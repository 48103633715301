import * as React from 'react';

import * as UserUtils from 'acceligent-shared/utils/user';

import type { EmployeeAccountVM } from 'ab-viewModels/toolRepair/preview.viewModel';

import styles from './styles.module.scss';

interface OwnProps {
	label: string;
	employee: Nullable<EmployeeAccountVM>;
}

type Props = OwnProps;

const EmployeeData: React.FC<Props> = (props) => {
	const { label, employee } = props;

	const fullName = employee ? UserUtils.getUserName(employee) : null;

	return (
		<>
			<div className={styles['grey-text']}>{label}</div>
			<div className={styles['field-value']}>{fullName ?? 'N/A'}</div>
		</>
	);
};

export default EmployeeData;
