import * as qs from 'query-string';

import TimePeriodSpan from 'acceligent-shared/enums/timePeriodSpan';
import type UnavailabilityReasonScope from 'acceligent-shared/enums/unavailabilityReasonScope';
import type BlobStorageContainer from 'acceligent-shared/enums/blobStorageContainer';

import type { TableQuery } from 'ab-common/dataStructures/tableQuery';

import type UserTypeFilter from 'ab-enums/userTypeFilter.enum';
import type ResourcesFilterType from 'ab-enums/resourcesFilterType.enum';
import type { OrderCompletionStatusFilter } from 'ab-enums/orderTypeFilter.enum';

const apiVersion = '/api/1.0';

const API = {
	GOOGLE: {
		MAP: `https://maps.googleapis.com/maps/api/js?libraries=geometry,drawing,places&key=${process.env.GOOGLE_MAPS_API_KEY}`,
		GEOCODE: (lat, lng) => `https://maps.googleapis.com/maps/api/geocode/json?latlng=${lat},${lng}&key=${process.env.GOOGLE_MAPS_API_KEY}`,
	},
	V1: {
		WORKER_JOB: {
			HEAVY_DATA_LOAD: (jobId: string) => `${apiVersion}/worker/heavyData/${jobId}`,
		},
		ATTACHMENT: {
			SIGNED_URL: (attachmentId: Nullable<number> = null, directory?: string, filename?: string) => `${apiVersion}/attachment/signedUrl?${qs.stringify({ attachmentId, directory, filename })}`,
			RESIZE_IMAGE: () => `${apiVersion}/attachment/resizeImage`,
			DOWNLOAD: (id: number) => `${apiVersion}/attachment/${id}`,
			DOWNLOAD_AS_ZIP: (attachmentIds: number[]) => `${apiVersion}/attachment/zip?attachmentIds=${encodeURIComponent((JSON.stringify(attachmentIds)))}`,
			DOWNLOAD_FOR_VIEWER: (id: number) => `${apiVersion}/attachment/viewer/${id}`,
			UPLOAD_BULK: (directoryId: number) => `${apiVersion}/attachment/upload/directory/${directoryId}`,
			COPY_TO_WO: (attachmentId: number) => `${apiVersion}/attachment/${attachmentId}/copyToWorkOrder`,
			RENAME: (attachmentId: number) => `${apiVersion}/attachment/${attachmentId}`,
			ADD_PENDING: (storageContainer: BlobStorageContainer) => `${apiVersion}/attachment/pending?storageContainer=${encodeURIComponent(storageContainer)}`,
			DELETE_PENDING: `${apiVersion}/attachment/pending`,
			CHANGE_PARENT: (attachmentId: number) => `${apiVersion}/attachment/${attachmentId}/move`,
		},
		ACCOUNT: {
			BY_ID: (accountId: number) => `${apiVersion}/account/${accountId}`,
			ACTIVE: `${apiVersion}/account/active`,
			SAVE_DIGITAL_SIGNATURE: `${apiVersion}/account/digitalSignature`,
			COMPANY_TABLE: (tableRequestModel: TableQuery) => `${apiVersion}/account/table?query=${encodeURIComponent((JSON.stringify(tableRequestModel)))}`,
			COMPANY_TABLE_DELETED: (tableRequestModel: TableQuery) => `${apiVersion}/account/table/deleted?query=${encodeURIComponent((JSON.stringify(tableRequestModel)))}`,
			ACCOUNTS_FOR_COMPANY: `${apiVersion}/account/list/preview`,
			ACTIVATE: (accountId: number) => `${apiVersion}/account/${accountId}/activate`,
			FIND_FOR_DELIVERABLE: `${apiVersion}/account/deliverable`,
			FIND_MANAGEMENT_OPTIONS: `${apiVersion}/account/management`,
			FIND_ACCOUNTING_OPTIONS: `${apiVersion}/account/accounting`,
			FIND_TECHNICIAN_OPTIONS: `${apiVersion}/account/technician`,
			CSV_VALIDATE: `${apiVersion}/account/csv/validate`,
		},
		TABLE_SETTINGS: {
			FIND: (tableName: string) => `${apiVersion}/tableSettings/${encodeURIComponent(tableName)}`,
			UPDATE: (tableName: string) => `${apiVersion}/tableSettings/${encodeURIComponent(tableName)}`,
		},
		LOGS: {
			FIND_BY_ID: (logId: string) => `${apiVersion}/log/${logId}`,
			COMPANY_TABLE: (tableRequestModel: TableQuery) => `${apiVersion}/log/company/table?query=${encodeURIComponent(JSON.stringify(tableRequestModel))}`,
			ORGANIZATION_TABLE: (tableRequestModel: TableQuery) => `${apiVersion}/log/organization/table?query=${encodeURIComponent(JSON.stringify(tableRequestModel))}`,
			COMPANY_DOWNLOAD: (/** `YYYY-MM-DD` */ startDate: string,/** `YYYY-MM-DD` */ endDate: string) => `${apiVersion}/log/company/download?startDate=${startDate}&endDate=${endDate}`,
			ORGANIZATION_DOWNLOAD: `${apiVersion}/log/organization/download`,
			FIND_LAST_BUCKET_ENTRY: `${apiVersion}/log/company/lastBucketEntry`,
		},
		COMMUNICATION: {
			SHORTEN_URL: `${apiVersion}/communication/shortenUrl`,
			COMPANY_NOTIFY_ALL: `${apiVersion}/communication/company/notify`,
			ORGANIZATION_NOTIFY_ALL: (orgAlias: string) => `${apiVersion}/communication/organization/notify/${orgAlias}`,
		},
		MESSAGE: {
			FIND_FOR_TABLE: (tableRequestModel: TableQuery, /** 'YYYY-MM-DDTHH:mm:ss.SSSZ' */ startDate: string, /** 'YYYY-MM-DDTHH:mm:ss.SSSZ' */ endDate: string) => `${apiVersion}/message?query=${encodeURIComponent((JSON.stringify(tableRequestModel)))}&startDate=${startDate}&endDate=${endDate}`,
			FIND_BY_ID: (id: number) => `${apiVersion}/message/${id}`,
			FIND_MESSAGE_STATUS_HISTORY_FOR_TABLE: (messageId: string, tableRequestModel: TableQuery) => `${apiVersion}/message/${messageId}/status?query=${encodeURIComponent((JSON.stringify(tableRequestModel)))}`,
		},
		COMPANY: {
			EDIT: `${apiVersion}/company`,
			EDIT_WORK_DAYS: `${apiVersion}/company/workdays`,
			EDIT_PRINTOUT: `${apiVersion}/company/printout`,
			EDIT_SCHEDULE_BOARD_SETTINGS: `${apiVersion}/company/scheduleBoardSettings`,
			EDIT_WORK_ORDER_SETTINGS: `${apiVersion}/company/workOrderSettings`,
			EDIT_FIELD_REPORT_SETTINGS: `${apiVersion}/company/fieldReportSettings`,
			EDIT_EQUIPMENT_SETTINGS: `${apiVersion}/company/equipmentSettings`,
			EDIT_LABOR_SETTINGS: `${apiVersion}/company/laborSettings`,
			UPDATE_PURCHASE_ORDER_NUMBER_PREFIX: `${apiVersion}/company/purchaseOrderNumberPrefix`,
			FIND_BY_ID: `${apiVersion}/company`,
			FIND_USERS: `${apiVersion}/company/users`,
			EDIT_EHS: `${apiVersion}/company/ehs`,
			EDIT_ENABLE_WORK_REQUESTS: `${apiVersion}/company/workRequests`,
			EDIT_ENABLE_INVOICE_NOTIFICATIONS: `${apiVersion}/company/invoiceNotifications`,
			USER_GROUPS: {
				CREATE: () => `${apiVersion}/userGroup`,
				EDIT: (userGroupId: number) => `${apiVersion}/userGroup/${userGroupId}`,
				DELETE: (userGroupId: number, clearOnly: boolean = false) => `${apiVersion}/userGroup/${userGroupId}${clearOnly ? `?clearOnly=${clearOnly}` : ''}`,
				FIND_BY_ID: (userGroupId: number) => `${apiVersion}/userGroup/${userGroupId}`,
				LIST: (includeAccounts: boolean = false) => `${apiVersion}/userGroup/multiple${includeAccounts ? `?includeAccounts=${includeAccounts}` : ''}`,
				TABLE: (tableRequestModel: TableQuery) => `${apiVersion}/userGroup/multiple/table?query=${encodeURIComponent(JSON.stringify(tableRequestModel))}`,
				ACCOUNTS_TABLE: (tableRequestModel: TableQuery) => `${apiVersion}/userGroup/multiple/table/accounts?query=${encodeURIComponent(JSON.stringify(tableRequestModel))}`,
			},
			REPORT_BLOCK: {
				FIND_BY_ID: (id: number) => `${apiVersion}/reportBlock/${id}`,
				FIND_TABLE: (tableRequestModel: TableQuery) => `${apiVersion}/reportBlock/table?query=${encodeURIComponent(JSON.stringify(tableRequestModel))}`,
				FIND_LIST: `${apiVersion}/reportBlock/list`,
				IS_BLOCK_NAME_AVAILABLE: `${apiVersion}/reportBlock/uniqueIdAvailable`,
				CREATE: `${apiVersion}/reportBlock/create`,
				EDIT: (id: number) => `${apiVersion}/reportBlock/${id}`,
				DELETE: (id: number) => `${apiVersion}/reportBlock/${id}`,
				UPLOAD_IMAGE: `${apiVersion}/reportBlock/image`,
			},
			REPORT_TYPE: {
				FIND_TABLE: (tableRequestModel: TableQuery) => `${apiVersion}/reportType/table?query=${encodeURIComponent(JSON.stringify(tableRequestModel))}`,
				CREATE: `${apiVersion}/reportType`,
				FIND_SHALLOW_ACTIVE_LIST: `${apiVersion}/reportType/active/shallow`,
				FIND_SHALLOW_ACTIVE_REQUIRED_LIST: `${apiVersion}/reportType/active/required`,
				FIND_BY_ID: (id: number) => `${apiVersion}/reportType/${id}`,
				EDIT: (id: number) => `${apiVersion}/reportType/${id}`,
				EDIT_STATUS: (id: number) => `${apiVersion}/reportType/${id}/status`,
				DELETE: (id: number) => `${apiVersion}/reportType/${id}`,
			},
		},
		CONTACT: {
			ADD_UNSAVED: (id: number) => `${apiVersion}/contact/addUnsaved/${id}`,
			IMPORT: `${apiVersion}/contact/import`,
			EDIT: (id: number) => `${apiVersion}/contact/${id}`,
			FIND_ALL: `${apiVersion}/contact`,
			FIND_FOR_INVOICES: `${apiVersion}/contact/findForInvoices`,
			FIND_BY_ID: (id: number) => `${apiVersion}/contact/${id}`,
			FIND_HISTORY_BY_ID: (id: number, tableRequestModel: TableQuery) => `${apiVersion}/contact/${id}/history?query=${encodeURIComponent((JSON.stringify(tableRequestModel)))}`,
			SOFT_DELETE: (id: number) => `${apiVersion}/contact/${id}`,
			BULK_SOFT_DELETE: `${apiVersion}/contact/bulk`,
			TABLE_SAVED: (tableRequestModel: TableQuery) => `${apiVersion}/contact/saved/table?query=${encodeURIComponent((JSON.stringify(tableRequestModel)))}`,
			TABLE_UNSAVED: (tableRequestModel: TableQuery) => `${apiVersion}/contact/unsaved/table?query=${encodeURIComponent((JSON.stringify(tableRequestModel)))}`,
		},
		ORGANIZATION: {
			CHECK_ALIAS: (alias: string) => `${apiVersion}/organization/checkAlias/${alias}`,
			ORGANIZATION_FORGOTTEN: `${apiVersion}/organization/forgotten`,
			CREATE: `${apiVersion}/organization/create`,
			MEMBERS_TABLE: (tableRequestModel: TableQuery) => `${apiVersion}/organization/members/table?query=${encodeURIComponent((JSON.stringify(tableRequestModel)))}`,
			MEMBERS_TABLE_CSV: (tableRequestModel: TableQuery) => `${apiVersion}/organization/members/tableCSV?query=${encodeURIComponent((JSON.stringify(tableRequestModel)))}`,
			MEMBERS_LIST: () => `${apiVersion}/organization/members`,
			MULTIPLE_PUBLIC_DETAILS: (organizationAliases: string[]) => `${apiVersion}/organization/multiplePublicDetails?aliases=${organizationAliases}`,
			PUBLIC_DETAILS: (orgAlias: string) => `${apiVersion}/organization/publicDetails?orgAlias=${orgAlias}`,
			TABLE: (tableRequestModel: TableQuery) => `${apiVersion}/organization/all?query=${encodeURIComponent((JSON.stringify(tableRequestModel)))}`,
			USERS: {
				LIST: (tableRequestModel: TableQuery, alias: string) => `${apiVersion}/organization/${alias}/members/table?query=${encodeURIComponent((JSON.stringify(tableRequestModel)))}`,
				UPDATE_ADMIN_PRIVILEGES: (userId: string, alias: string, giveAdminPrivileges: boolean) => `${apiVersion}/organization/${alias}/member/${userId}?giveAdminPrivileges=${giveAdminPrivileges}`,
			},
		},
		PURCHASE_ORDER: {
			CREATE_OR_UPDATE: () => `${apiVersion}/purchaseOrder`,
		},
		SYSTEM_NOTIFICATIONS: {
			ROOT: `${apiVersion}/systemNotifications`,
			DELETE: (id: number) => `${apiVersion}/systemNotification/${id}`,
		},
		USERS: {
			DELETE: (userId: number) => `${apiVersion}/user/${userId}`,
			MISSING_MEMBERS_LIST: `${apiVersion}/user/missing`,
		},
		JOB: {
			CREATE_JOB: () => `${apiVersion}/workRequest/job`,
			EDIT_JOB: (jobId: number) => `${apiVersion}/workRequest/job/${jobId}`,
			COPY_JOB: (jobId: number) => `${apiVersion}/workRequest/job/${jobId}/copy`,
			REPORTS_LIST_FOR_BULK_SEND: (workRequestId: number, /** `YYYY-MM-DD` */ startDate: string, /** `YYYY-MM-DD` */ endDate: string) => `${apiVersion}/workRequest/${workRequestId}/reports/listForBulkSend?startDate=${startDate}&endDate=${endDate}`,
			FIND_FOR_CSV_EXPORT: () => `${apiVersion}/workRequest/job/csvExport`,
			TABLE: (tableRequestModel: TableQuery) => `${apiVersion}/workRequest/job/list/table?query=${encodeURIComponent((JSON.stringify(tableRequestModel)))}`,
			TABLE_WORK_SUMMARY: (jobId: number, tableRequestModel: TableQuery, startDate: string, endDate: string, subJobId: Nullable<number>) => `${apiVersion}/workRequest/job/${jobId}/workSummary/table?query=${encodeURIComponent((JSON.stringify(tableRequestModel)))}&startDate=${startDate}&endDate=${endDate}&subJobId=${subJobId}`,
			TABLE_WORK_SUMMARY_CSV: (jobId: number, startDate: string, endDate: string, subJobId: Nullable<number>, filterByText: string) => `${apiVersion}/workRequest/job/${jobId}/workSummary/table/csv?startDate=${startDate}&endDate=${endDate}&subJobId=${subJobId}&filterByText=${filterByText}`,
			GET_WORK_SUMMARY_WORK_ORDERS: (jobId: number) => `${apiVersion}/workRequest/job/${jobId}/workSummary/workOrders`,
			CREATE_WORK_SUMMARY: () => `${apiVersion}/workRequest/job/workSummary`,
			EDIT_WORK_SUMMARY: () => `${apiVersion}/workRequest/job/workSummary/edit`,
			RESTORE_ORIGINAL_WORK_SUMMARY: (jobId: number, jobWorkSummaryId: number) => `${apiVersion}/workRequest/job/${jobId}/workSummary/${jobWorkSummaryId}/restoreOriginal`,
			FIND_BY_ID_FOR_PREVIEW: (jobId: number) => `${apiVersion}/workRequest/job/${jobId}/preview`,
			IN_PROGRESS_CODES: () => `${apiVersion}/workRequest/job/list/jobCodes`,
			FIND_FOR_ORDER_FORM: () => `${apiVersion}/workRequest/job/order`,
			JOB_CODES_FOR_INVOICES: () => `${apiVersion}/workRequest/job/list/jobCodesForInvoices`,
			FINISH: (workRequestId: number) => `${apiVersion}/workRequest/${workRequestId}/job/finish`,
			UNFINISH: (workRequestId: number) => `${apiVersion}/workRequest/${workRequestId}/job/unfinish`,
			DELETE_DIRECTORY: (workRequestId: number, directoryId: number) => `${apiVersion}/workRequest/${workRequestId}/directoryId/${directoryId}`,
			ATTACHMENTS: {
				DIRECTORIES_ATTACHMENTS: (workRequestId: number) =>
					`${apiVersion}/workRequest/${workRequestId}/attachments/directoriesAttachments`,
			},
			USER_GROUP_AND_PM: (jobId: number) => `${apiVersion}/workRequest/job/${jobId}/userGroupAndPm`,
			TABLE_INVOICES: (jobId: number, tableRequestModel: TableQuery, startDate: string, endDate: string) => `${apiVersion}/workRequest/job/${jobId}/invoices/table?query=${encodeURIComponent((JSON.stringify(tableRequestModel)))}&startDate=${startDate}&endDate=${endDate}}`,
			GET_INVOICES_TOTALS: (jobId: number) => `${apiVersion}/workRequest/job/${jobId}/invoices/totals`,
			CREATE_INVOICE: (jobId: number) => `${apiVersion}/workRequest/job/${jobId}/invoices/create`,
			EDIT_INVOICED_INVOICE: (jobId: number, invoiceId: number) => `${apiVersion}/workRequest/job/${jobId}/invoices/${invoiceId}/editInvoiced`,
			DELETE_INVOICE: (jobId: number, invoiceId: number) => `${apiVersion}/workRequest/job/${jobId}/invoices/${invoiceId}/delete`,
			GET_INVOICE: (jobId: number, invoiceId: number) => `${apiVersion}/workRequest/job/${jobId}/invoices/${invoiceId}`,
			GET_ALL_INVOICE_CODES_FOR_JOB: (jobId: number) => `${apiVersion}/workRequest/job/${jobId}/invoiceCodes`,
			SEND_MANUAL_REMINDER: (jobId: number, invoiceId: number) => `${apiVersion}/workRequest/job/${jobId}/invoices/${invoiceId}/sendManualReminder`,
			ARE_AUTOMATIC_REMINDERS_ENABLED: () => `${apiVersion}/workRequest/invoices/areAutomaticRemindersEnabled`,
		},
		WORK_REQUEST: {
			CREATE: `${apiVersion}/workRequest`,
			EDIT: (id: number) => `${apiVersion}/workRequest/${id}`,
			EDIT_BID_STATUS: (id: number) => `${apiVersion}/workRequest/${id}/bidStatus`,
			CONVERT_TO_JOB: (id: number) => `${apiVersion}/workRequest/${id}/convertToJob`,
			COPY: (id: number) => `${apiVersion}/workRequest/${id}/copy`,
			FIND_BY_ID: (id: number) => `${apiVersion}/workRequest/${id}`,
			FIND_ALL_AVAILABLE_SUBJOBS: (id?: number) => `${apiVersion}/workRequest/subjobs?${id ? `workRequestId=${id}` : ''}`,
			FIND_ALL_SUBJOBS: (id: number) => `${apiVersion}/workRequest/${id}/subjobs`,
			FIND_ALL_SUBJOBS_TABLE: (id: number, tableRequestModel: TableQuery) => `${apiVersion}/workRequest/${id}/subjobs/table?query=${encodeURIComponent((JSON.stringify(tableRequestModel)))}`,
			FIND_BY_ID_FOR_EDIT: (id: number) => `${apiVersion}/workRequest/${id}/edit`,
			FIND_BY_ID_FOR_PREVIEW: (id: number) => `${apiVersion}/workRequest/${id}/preview`,
			FIND_ALL_BILLING_CODES_TABLE: (id: number, tableRequestModel: TableQuery) => `${apiVersion}/workRequest/${id}/billingCodes/table?query=${encodeURIComponent((JSON.stringify(tableRequestModel)))}`,
			FIND_REPORTS_LIST_FOR_BULK_SEND: (/** `YYYY-MM-DD` */ startDate: string, /** `YYYY-MM-DD` */ endDate: string) => `${apiVersion}/workRequest/job/reports/list?startDate=${startDate}&endDate=${endDate}`,
			FIND_OR_CREATE_JOBS_REPORT_BULK_GROUP_CODE: `${apiVersion}/workRequest/job/reports/findOrCreateBulkGroupCode`,
			DOWNLOAD_JOB_REPORTS_PDF: (bulkGroupCode: string) => `${apiVersion}/workRequest/job/reports/${bulkGroupCode}/pdf`,
			FIND_FOR_ASSOCIATED_SUBJOB_DATA: (workRequestsIds: number[]) => `${apiVersion}/workRequest/reports/associatedSubjobData?workRequestsIds=${encodeURIComponent((JSON.stringify(workRequestsIds)))}`,
			FIND_SIBLING_SUBJOBS: (jobId: number) => `${apiVersion}/workRequest/job/${jobId}/siblingSubjobs`,
			FIND_WITH_PROJECT_INFO: (jobId: number) => `${apiVersion}/workRequest/${jobId}/projectInfo`,
			FIND_FOR_PURCHASE_ORDER_OPTIONS: () => `${apiVersion}/workRequest/purchaseOrder`,
		},
		KPI: {
			EQUIPMENT_UTILIZATION: (timePeriod: TimePeriodSpan = TimePeriodSpan.YEAR) => `${apiVersion}/kpi/equipmentUtilization/${timePeriod}`,
			EQUIPMENT_COST_UTILIZATION_CHARTS: (equipmentCostId: number) => `${apiVersion}/kpi/equipmentUtilization/equipmentCosts/${equipmentCostId}/charts`,
			LABOR_UTILIZATION: (timePeriod: TimePeriodSpan = TimePeriodSpan.YEAR) => `${apiVersion}/kpi/laborUtilization/${timePeriod}`,
			LABOR_UTILIZATION_CHARTS: () => `${apiVersion}/kpi/laborUtilization/charts`,
		},
		KNOWLEDGE_CENTER: {
			FIND_NOTION_RESPONSE: () => `${apiVersion}/knowledgeCenter/notion/sso`,
			GET_NOTION_PAGE: (pageId: string) => `${apiVersion}/knowledgeCenter/notion/${pageId}`,
		},
		LEARNING_MANAGEMENT_SYSTEM: {
			FIND_CORNERSTONE_RESPONSE: () => `${apiVersion}/lms/sso`,
		},
		TIME_SHEET: {
			FIND_ALL_FOR_WORK_ORDER: (workOrderId: number) => `${apiVersion}/timeSheets/workOrder/${workOrderId}`,
			FIND_TIME_SHEET_ENTRIES_FOR_WORK_ORDER: (workOrderId: number) => `${apiVersion}/timeSheets/workOrder/${workOrderId}/entries`,
			FIND_TIME_SHEET_ENTRIES_FOR_WORK_ORDER_AND_ACCOUNT: (workOrderId: number, accountId: number) =>
				`${apiVersion}/timeSheets/workOrder/${workOrderId}/account/${accountId}/entries`,
			FIND_TIME_SHEET_ENTRIES_HISTORY_FOR_WORK_ORDER_AND_ACCOUNT: (workOrderId: number, accountId: number) =>
				`${apiVersion}/timeSheets/workOrder/${workOrderId}/account/${accountId}/history`,
			FIND_TIME_SHEET_NOTE_FOR_WORK_ORDER_AND_ACCOUNT: (workOrderId: number, accountId: number) =>
				`${apiVersion}/timeSheets/workOrder/${workOrderId}/account/${accountId}/note`,
			FIND_TRACKED_ENTRIES_FOR_WORK_ORDER: (workOrderId: number) => `${apiVersion}/timeSheets/workOrder/${workOrderId}/trackedEntries`,
			FIND_CURRENT_TRACKED_ENTRY: (accountId: number) => `${apiVersion}/timeSheets/account/${accountId}/entries/tracked/current`,
			FIND_ALL_ENTRIES_WITH_ACCESS: (accountId: number) => `${apiVersion}/timeSheets/account/${accountId}/entries/all`,
			HAS_WORK_ORDER_OVERLAPS: (workOrderId: number) => `${apiVersion}/timeSheets/workOrder/${workOrderId}/hasOverlaps`,
			HAS_WORK_ORDER_OVERLAPS_FOR_ACCOUNT: (workOrderId: number, accountId: number) => `${apiVersion}/timeSheets/workOrder/${workOrderId}/account/${accountId}/hasOverlaps`,
			HAS_TIME_SHEET_OVERLAPS: (timeSheetId: number) => `${apiVersion}/timeSheets/${timeSheetId}/hasOverlaps`,
			UPDATE_TIME_SHEET_NOTE_FOR_WORK_ORDER_AND_ACCOUNT: (workOrderId: number, accountId: number) =>
				`${apiVersion}/timeSheets/workOrder/${workOrderId}/account/${accountId}/note`,
			BULK_UPDATE_WORK_ORDER_TIME_SHEETS: (workOrderId: number) =>
				`${apiVersion}/timeSheets/workOrder/${workOrderId}`,
			UPDATE_WORK_ORDER_TIME_SHEET_FOR_ACCOUNT: (workOrderId: number, accountId: number) =>
				`${apiVersion}/timeSheets/workOrder/${workOrderId}/account/${accountId}`,
			SIGN: (workOrderId: number, accountId: number) => `${apiVersion}/timeSheets/workOrder/${workOrderId}/account/${accountId}/sign`,
			REJECT: (workOrderId: number, accountId: number) => `${apiVersion}/timeSheets/workOrder/${workOrderId}/account/${accountId}/status`,
			END_SHIFTS_FOR_WORK_ORDER: (workOrderId: number) => `${apiVersion}/timeSheets/workOrder/${workOrderId}/endShifts`,
			END_SHIFT: (accountId: number) => `${apiVersion}/timeSheets/account/${accountId}/entries/endShift`,
			FIND_OCCUPIED_SLOTS: (accountId: number, workOrderId: number) => `${apiVersion}/timeSheets/workOrder/${workOrderId}/account/${accountId}/occupiedSlots`,
			FIND_OCCUPIED_SLOTS_WITH_INTERVAL: (accountId: number, workOrderId: number, startTime: string, endTime: string) => `${apiVersion}/timeSheets/workOrder/${workOrderId}/account/${accountId}/occupiedSlots?startTime=${encodeURIComponent(startTime)}&endTime=${encodeURIComponent(endTime)}`,
			FIND_WORK_ORDER_TIME_SHEET_AND_SPLIT_ENTRIES_FOR_ACCOUNT: (workOrderId: number, accountId: number) =>
				`${apiVersion}/timeSheets/workOrder/${workOrderId}/account/${accountId}/sheetAndSplitEntries`,
		},
		TIME_SPLIT_ENTRY: {
			FIND_WORK_ORDER_TIME_SPLIT_ENTRIES_FOR_ACCOUNT: (workOrderId: number, accountId: number) =>
				`${apiVersion}/timeSplitEntries/workOrder/${workOrderId}/account/${accountId}`,
			UPDATE_WORK_ORDER_TIME_SPLIT_ENTRIES_FOR_ACCOUNT: (workOrderId: number, accountId: number) =>
				`${apiVersion}/timeSplitEntries/workOrder/${workOrderId}/account/${accountId}`,
		},
		WORK_ORDER: {
			SAVE_METRICS: `${apiVersion}/workOrder/metrics`,
			NOTE: (workOrderId: number) => `${apiVersion}/workOrder/${workOrderId}/note`,
			CANCEL: (workOrderId: number) => `${apiVersion}/workOrder/${workOrderId}/cancel`,
			PAUSE: (workOrderId: number) => `${apiVersion}/workOrder/${workOrderId}/pause`,
			RESUME: (workOrderId: number) => `${apiVersion}/workOrder/${workOrderId}/resume`,
			FIND_BY_ID: (workOrderId: number) => `${apiVersion}/workOrder/${workOrderId}/full`,
			FIND_CARD_FOR_FIELD_REPORT_BY_ID: (workOrderId: number) => `${apiVersion}/workOrder/${workOrderId}/fieldReportCard`,
			LAZY_LOAD_MODAL_BY_ID: (workOrderId: number) => `${apiVersion}/workOrder/${workOrderId}/modal`,
			LIST: (dueDate: string, query = '') => `${apiVersion}/workOrder/list?dueDate=${dueDate}&query=${query}`,
			LIST_BY_TEMPORARY_EMPLOYEE_ID: (temporaryEmployeeId: number, tableRequestModel: TableQuery) => `${apiVersion}/workOrder/list/temporaryEmployee/${temporaryEmployeeId}?query=${encodeURIComponent((JSON.stringify(tableRequestModel)))}`,
			LIST_BLANK: (dueDate: string) => `${apiVersion}/workOrder/list/blanks?dueDate=${dueDate}`,
			ATTACHMENTS: {
				DIRECTORIES_ATTACHMENTS: (workOrderId: number) =>
					`${apiVersion}/workOrder/${workOrderId}/attachments/directoriesAttachments`,
			},
			CONFIRMATION: (workOrderId: number) => `${apiVersion}/workOrder/${workOrderId}/confirmation`,
			CONFIRMATION_FILE: (workOrderId: number) => `${apiVersion}/workOrder/${workOrderId}/confirmationFile`,
			DOWNLOAD_CUSTOMER_REPORT: (workOrderId: number) => `${apiVersion}/workOrder/reports/${workOrderId}/customer/pdf`,
			GET_NOTIFICATION_TEMPLATE: (workOrderId: number) => `${apiVersion}/workOrder/${workOrderId}/notification/template`,
			PUBLIC: {
				REPORT: (orgAlias: string, companyName: string, publicLink: string) => `${apiVersion}/workOrder/public/organization/${orgAlias}/company/${companyName}/reports/${publicLink}`,
				REPORT_CUSTOMER_SIGNATURE: (orgAlias: string, companyName: string, publicLink: string) => `${apiVersion}/workOrder/public/organization/${orgAlias}/company/${companyName}/reports/${publicLink}/customerSignatures`,
			},
			REVISIONS: {
				LAZY_LOAD_MODAL_BY_ID: (workOrderRevisionId: number) => `${apiVersion}/workOrder/revision/${workOrderRevisionId}/modal`,
				LAZY_LOAD_REVISIONS: (workOrderId: number) => `${apiVersion}/workOrder/${workOrderId.toString()}/revisions`,
				ATTACHMENT_DOWNLOAD: (storageName: string, name: string) => `${apiVersion}/workOrder/revision/attachment?storageName=${encodeURIComponent(storageName)}&name=${encodeURIComponent(name)}`,
			},
			SEND_CUSTOMER_SIGNATURE_REQUEST: `${apiVersion}/workOrder/reports/sendCustomerSignatureRequest`,
			SEND_REPORTS_FOR_CUSTOMER: `${apiVersion}/workOrder/reports/sendReportsForCustomer`,
			LIST_REPORTS: (tableRequestModel: TableQuery, startDate: string, endDate: string, assignmentFilter: UserTypeFilter) => `${apiVersion}/workOrder/list/reports?query=${encodeURIComponent((JSON.stringify(tableRequestModel)))}&startDate=${startDate}&endDate=${endDate}&assignmentFilter=${assignmentFilter}`,
			REPORTS_CSV_DATA: (workOrderIds: number[]) => `${apiVersion}/workOrder/reports/data/csv?workOrderIds=${encodeURIComponent((JSON.stringify(workOrderIds)))}`,
			FIELD_REPORT: {
				FINALIZE: (workOrderId: number) => `${apiVersion}/workOrder/${workOrderId}/finalizeReview`,
				SUBMIT_FOR_REVIEW: (workOrderId: number) => `${apiVersion}/workOrder/${workOrderId}/submitForReview`,
				FIND_REVIEW_RECAP: (workOrderId: number) => `${apiVersion}/workOrder/${workOrderId}/reviewRecap`,
			},
			CREATE_TEMPORARY_EMPLOYEE_ASSIGNMENT: (workOrderId: number) => `${apiVersion}/workOrder/${workOrderId}/workOrderResourceLookups`,
			DELETE_TEMPORARY_EMPLOYEE_ASSIGNMENT: (workOrderId: number, workOrderResourceLookupId: number) => `${apiVersion}/workOrder/${workOrderId}/workOrderResourceLookups/${workOrderResourceLookupId}`,
			CAN_COMPLETE_WORK_SUMMARIES: (workOrderId: number) => `${apiVersion}/workOrder/${workOrderId}/canCompleteWorkSummaries`,
			OUTDATED_CHANGES: (workOrderId: number) => `${apiVersion}/workOrder/${workOrderId}/outdatedChanges`,
			FIND_OR_CREATE_COMPLETE_GROUP_CODE: `${apiVersion}/workOrder/reports/complete/findOrCreateGroupCode`,
			FIND_OR_CREATE_PUBLIC_GROUP_CODE: `${apiVersion}/workOrder/reports/public/findOrCreateGroupCode`,
			DOWNLOAD_COMPLETE_REPORT: (code: string) => `${apiVersion}/workOrder/reports/complete/${code}/download`,
			REVIEW_VIRTUAL_REPORTS: (workOrderId: number, workRequestId: number) => `${apiVersion}/workOrder/${workOrderId}/reviewVirtualReports/${workRequestId}`,
			FIND_ASSOCIATED_SUBJOBS: (workOrderId: number) => `${apiVersion}/workOrder/${workOrderId}/associatedSubJobs`,
		},
		JOB_WORK_SUMMARY: {
			FIND_WORK_REQUEST_IDS: (fieldReportIds: number[]) => `${apiVersion}/jobWorkSummary/workRequestIds?fieldReportIds=${encodeURIComponent((JSON.stringify(fieldReportIds)))}`,
		},
		WORK_ORDER_EMPLOYEE: {
			PER_DIEM: {
				TABLE: (tableRequestModel: TableQuery, startDate: string, endDate: string) => `${apiVersion}/workOrderEmployee/perDiem/table?query=${encodeURIComponent(JSON.stringify(tableRequestModel))}&startDate=${startDate}&endDate=${endDate}`,
				LIST: (startDate: string, endDate: string) => `${apiVersion}/workOrderEmployee/perDiem/list?startDate=${startDate}&endDate=${endDate}`,
			},
			PUBLIC: {
				WORK_ORDER_LINK: (personalPublicLink: string) => `${apiVersion}/workOrderEmployee/public/${personalPublicLink}/workOrderPublicLink`,
				HAS_OPENED_PERSONAL_LINK: (personalPublicLink: string) => `${apiVersion}/workOrderEmployee/public/${personalPublicLink}/hasOpenedPersonalPublicLink`,
			},
		},
		ORDERS: {
			CREATE: `${apiVersion}/orders`,
			DELETE: (id: number) => `${apiVersion}/orders/${id}`,
			EDIT: (id: number) => `${apiVersion}/orders/${id}`,
			FIND_FOR_EDIT: (id: number) => `${apiVersion}/orders/${id}`,
			FIND_FOR_TABLE: (tableRequestModel: TableQuery, completionStatus: OrderCompletionStatusFilter, startDate: Nullable<string>, endDate: Nullable<string>) => `${apiVersion}/orders/table?query=${encodeURIComponent((JSON.stringify(tableRequestModel)))}&completionStatus=${completionStatus}${startDate && endDate ? `&startDate=${startDate}&endDate=${endDate}` : ''}`,
		},
		TOOL_REPAIR: {
			CREATE: `${apiVersion}/toolRepair`,
			FIND_BY_ID: (id: number) => `${apiVersion}/toolRepair/${id}`,
			FIND_TABLE: (tableRequestModel: TableQuery) => `${apiVersion}/toolRepair/table?query=${encodeURIComponent((JSON.stringify(tableRequestModel)))}`,
			REPAIR_LOCATION: {
				LIST: `${apiVersion}/repairLocation/list`,
				CREATE: `${apiVersion}/repairLocation`,
			},
		},
		RESOURCES: {
			AGENCY: {
				TABLE: (tableRequestModel: TableQuery) => `${apiVersion}/agency/table?query=${encodeURIComponent((JSON.stringify(tableRequestModel)))}`,
				TABLE_DELETED: (tableRequestModel: TableQuery) => `${apiVersion}/agency/table/deleted?query=${encodeURIComponent((JSON.stringify(tableRequestModel)))}`,
				LIST_ALL: `${apiVersion}/agency`,
				LIST: `${apiVersion}/agency/list`,
				FIND_BY_ID: (id: number) => `${apiVersion}/agency/${id}`,
				ACTIVATE: (id: number) => `${apiVersion}/agency/${id}/activate`,
				GET_WORKERS: (id: number, tableRequestModel: TableQuery) => `${apiVersion}/agency/${id}/workers/table?query=${encodeURIComponent((JSON.stringify(tableRequestModel)))}`,
			},
			WAGE_RATE: {
				TABLE: (tableRequestModel: TableQuery) => `${apiVersion}/wageRates/table?query=${encodeURIComponent((JSON.stringify(tableRequestModel)))}`,
				LIST: (query: string, type: Nullable<ResourcesFilterType>) => `${apiVersion}/wageRates/list?query=${encodeURIComponent(query)}${!!type ? `&type=${encodeURIComponent(type)}` : ''}`,
				CREATE: () => `${apiVersion}/wageRates`,
				FIND_BY_ID: (id: string = ':wageRateId') => `${apiVersion}/wageRates/${id}`,
				DELETE: (id: string = ':wageRateId') => `${apiVersion}/wageRates/${id}`,
				EDIT: (id: string = ':wageRateId') => `${apiVersion}/wageRates/${id}`,
				IMPORT_BATCH: () => `${apiVersion}/wageRates/importBatch`,
				BULK_DELETE: `${apiVersion}/wageRates`,
			},
			FIELD_WORK_CLASSIFICATION_CODE: {
				CREATE: `${apiVersion}/fieldWorkClassificationCodes`,
				LIST: (query?: string) => `${apiVersion}/fieldWorkClassificationCodes/list${query ? `?query=${encodeURIComponent(query)}` : ''}`,
				TABLE: (tableRequestModel: TableQuery) => `${apiVersion}/fieldWorkClassificationCodes/table?query=${encodeURIComponent((JSON.stringify(tableRequestModel)))}`,
				FIND_BY_ID: (id: string) => `${apiVersion}/fieldWorkClassificationCodes/${id}`,
				DELETE: (id: string) => `${apiVersion}/fieldWorkClassificationCodes/${id}`,
				BULK_DELETE: (ids: number[]) => `${apiVersion}/fieldWorkClassificationCodes?ids=${encodeURIComponent((JSON.stringify(ids)))}`,
			},
			EMPLOYEE: {
				TABLE: (tableRequestModel: TableQuery) => `${apiVersion}/employee/table?query=${encodeURIComponent((JSON.stringify(tableRequestModel)))}`,
				LIST: () => `${apiVersion}/employee/list`,
				WORK_ORDER_EMPLOYEES: () => `${apiVersion}/employee/order`,
				WORK_ORDER_EMPLOYEES_BY_DUE_DATE: (dueDate: string) => `${apiVersion}/employee/order/${dueDate}`,
				ALL_STATUSES_FOR_EMPLOYEE: (id: string = ':employeeId') => `${apiVersion}/employee/${id}/allStatuses`,
				STATUS_HISTORY: (id: string = ':employeeId', tableRequestModel: TableQuery, /** YYYY-MM-DD */ startDate: string, /** YYYY-MM-DD */ endDate: string, filterByStatusId: number) => `${apiVersion}/employee/${id}/statusHistory/table?query=${encodeURIComponent((JSON.stringify(tableRequestModel)))}&startDate=${startDate}&endDate=${endDate}&employeeStatusId=${filterByStatusId}`,
				FIND_BY_ID: (id: string = ':employeeId') => `${apiVersion}/employee/${id}`,
				SUPERINTENDENTS: () => `${apiVersion}/employee/superintendent`,
				FIND_FOR_SHIPPING: () => `${apiVersion}/employee/shipping`,
				SUPERINTENDENTS_FOR_FIELD_REPORT: (workOrderId: number) => `${apiVersion}/employee/fieldReportAccess/workOrder/${workOrderId}`,
				PROJECT_MANAGERS: () => `${apiVersion}/employee/projectManager`,
				SUPERINTENDENTS_AND_PROJECT_MANAGERS: () => `${apiVersion}/employee/superintendentsAndProjectManagers`,
				EDIT: (id: string = ':employeeId', date: string) => `${apiVersion}/employee/${id}?date=${date}`,
				FIND_LATEST_NOTIFICATIONS_FOR_WORK_ORDER: (employeeIds: number[], workOrderId: number) => `${apiVersion}/employee/notification/workOrder/${workOrderId}/latest?employeeIds=${encodeURIComponent(JSON.stringify(employeeIds))}`,
			},
			EMPLOYEE_STATUS: {
				LIST: () => `${apiVersion}/employeeStatus`,
				UNAVAILABLE: () => `${apiVersion}/employeeStatus/unavailable`,
			},
			EQUIPMENT: {
				TABLE: (tableRequestModel: TableQuery) => `${apiVersion}/equipment/table?query=${encodeURIComponent((JSON.stringify(tableRequestModel)))}`,
				LIST: () => `${apiVersion}/equipment/list`,
				LIST_FOR_TIME_SPLITS_BY_WORK_ORDER: (workOrderId: number) => `${apiVersion}/equipment/list/timeSplits/workOrder/${workOrderId}`,
				LIST_FOR_FIELD_REPORT_FIELD: (fieldReportId: number) => `${apiVersion}/equipment/list/fieldReport/${fieldReportId}`,
				CREATE: () => `${apiVersion}/equipment/create`,
				ALL_STATUSES_FOR_EQUIPMENT: (id: string = ':equipmentId') => `${apiVersion}/equipment/${id}/allStatuses`,
				STATUS_HISTORY: (id: string = ':equipmentId', tableRequestModel: TableQuery, /** YYYY-MM-DD */ startDate: string, /** YYYY-MM-DD */ endDate: string, filterByStatusId: number) => `${apiVersion}/equipment/${id}/statusHistory/table?query=${encodeURIComponent((JSON.stringify(tableRequestModel)))}&startDate=${startDate}&endDate=${endDate}&equipmentStatusId=${filterByStatusId}`,
				FIND_BY_ID: (id: string = ':equipmentId') => `${apiVersion}/equipment/${id}`,
				DELETE: (id: string = ':equipmentId') => `${apiVersion}/equipment/${id}`,
				EDIT: (id: string = ':equipmentId') => `${apiVersion}/equipment/${id}`,
				IMPORT_BATCH: () => `${apiVersion}/equipment/importBatch`,
				BULK_DELETE: `${apiVersion}/equipment/bulk/soft`,
				WORK_ORDER_EQUIPMENT: (dueDate: string) => `${apiVersion}/equipment/order/${dueDate}`,
				DOWN: (equipmentId: number) => `${apiVersion}/equipment/${equipmentId}/down`,
				/** @param dueDate `YYYY-MM-DD` */
				MECHANIC_VIEW_AVAILABLE: (dueDate: string) => `${apiVersion}/equipment/mechanicView/${dueDate}/available`,
				/** @param dueDate `YYYY-MM-DD` */
				MECHANIC_VIEW_UNAVAILABLE: (dueDate: string) => `${apiVersion}/equipment/mechanicView/${dueDate}/unavailable`,
				FIND_FOR_PURCHASE_ORDER_OPTIONS: `${apiVersion}/equipment/purchaseOrder`,
				FIND_FOR_TOOL_LOCATION_OPTIONS: `${apiVersion}/equipment/toolLocationOptions`,
			},
			ITEM: {
				TABLE: (tableRequest: TableQuery) => `${apiVersion}/item/table?query=${encodeURIComponent((JSON.stringify(tableRequest)))}`,
				FIND_BY_ID: (id: number) => `${apiVersion}/item/${id}`,
				CREATE: `${apiVersion}/item`,
				FIND_ITEM_DEPARTMENTS: `${apiVersion}/item/itemDepartments`,
				FIND_FOR_PURCHASE_ORDER_OPTIONS: `${apiVersion}/item/purchaseOrderOptions`,
				FIND_NAME_LIST: `${apiVersion}/item/name/list`,
				BULK_DELETE: `${apiVersion}/item/bulk/soft`,
			},
			VENDOR: {
				FIND_LIST: `${apiVersion}/vendor/list`,
				CREATE: `${apiVersion}/vendor`,
			},
			TOOL_TYPE: {
				FIND_LIST: `${apiVersion}/toolType/list`,
				CREATE: `${apiVersion}/toolType`,
			},
			ITEM_CATEGORY: {
				FIND_LIST: `${apiVersion}/itemCategory/list`,
				CREATE: `${apiVersion}/itemCategory`,
				EDIT: (id: string = ':itemCategoryId') => `${apiVersion}/itemCategory/${id}`,
				REMOVE: (id: string = ':itemCategoryId') => `${apiVersion}/itemCategory/${id}`,
			},
			EQUIPMENT_STATUS: {
				LIST: () => `${apiVersion}/equipmentStatus/list`,
				UPDATE_LIST: () => `${apiVersion}/equipmentStatus/list`,
				UNAVAILABLE: () => `${apiVersion}/equipmentStatus/unavailable`,
			},
			UNAVAILABILITY_REASON: {
				LIST: (query: string, scope: UnavailabilityReasonScope) => `${apiVersion}/unavailabilityReason/list?query=${encodeURIComponent(query)}&scope=${scope}`,
			},
			EQUIPMENT_COST: {
				TABLE: (tableRequestModel: TableQuery) => `${apiVersion}/equipmentCost/table?query=${encodeURIComponent((JSON.stringify(tableRequestModel)))}`,
				TREE: () => `${apiVersion}/equipmentCost/tree`,
				TREE_EDITABLE: () => `${apiVersion}/equipmentCost/tree/editable`,
				LIST: (query: string, type: Nullable<string>) => `${apiVersion}/equipmentCost/list?query=${encodeURIComponent(query)}${!!type ? `&type=${encodeURIComponent(type)}` : ''}`,
				FIND_BY_ID: (id: string = ':equipmentCostId') => `${apiVersion}/equipmentCost/${id}`,
				CATEGORY_DELETE: (id: string = ':equipmentCostCategoryId') => `${apiVersion}/equipmentCost/category/${id}`,
				IMPORT_BATCH: () => `${apiVersion}/equipmentCost/importBatch`,
				TREE_NODE_DRAG_END: () => `${apiVersion}/equipmentCost/treeActions/dragEnd`,
				CREATE_CATEGORY: `${apiVersion}/equipmentCost/category`,
				CREATE_GROUP: `${apiVersion}/equipmentCost/group`,
			},
			SKILL: {
				LIST: (query: string) => `${apiVersion}/skills/list?query=${encodeURIComponent(query)}`,
				OPTIONS: () => `${apiVersion}/skills/options`,
				SEARCH: (query: string) => `${apiVersion}/skills/search?query=${encodeURIComponent(query)}`,
				CREATE: () => `${apiVersion}/skills`,
				DELETE: (id: string = ':skillId') => `${apiVersion}/skills/${id}`,
				EDIT: (id: string = ':skillId') => `${apiVersion}/skills/${id}`,
			},
			CREW_TYPE: {
				LIST: (query: string) => `${apiVersion}/crewTypes/list?query=${encodeURIComponent(query)}`,
				CREATE: () => `${apiVersion}/crewTypes`,
				DELETE: (id: string = ':crewTypeId') => `${apiVersion}/crewTypes/${id}`,
				EDIT: (id: string = ':crewTypeId') => `${apiVersion}/crewTypes/${id}`,
			},
			DIVISION: {
				LIST: (query: string) => `${apiVersion}/divisions/list?query=${encodeURIComponent(query)}`,
				CREATE: () => `${apiVersion}/divisions`,
				DELETE: (id: string = ':divisionId') => `${apiVersion}/divisions/${id}`,
				EDIT: (id: string = ':divisionId') => `${apiVersion}/divisions/${id}`,
			},
			SALE: {
				TABLE: (tableRequestModel: TableQuery) => `${apiVersion}/sales/table?query=${encodeURIComponent((JSON.stringify(tableRequestModel)))}`,
				FIND_BY_ID: (id: string = ':saleId') => `${apiVersion}/sales/${id}`,
			},
			LOCATION: {
				TABLE: (tableRequestModel: TableQuery) => `${apiVersion}/locations/table?query=${encodeURIComponent((JSON.stringify(tableRequestModel)))}`,
				LIST: `${apiVersion}/locations/list`,
				DEPARTMENT_LIST: (includeDeleted: boolean) => `${apiVersion}/locations/department?includeDeleted=${includeDeleted}`,
				EDIT_LIST: `${apiVersion}/locations/list`,
				CREATE: () => `${apiVersion}/locations`,
				FIND_BY_ID: (id: string = ':locationId') => `${apiVersion}/locations/${id}`,
				DELETE: (id: string = ':locationId') => `${apiVersion}/locations/${id}`,
				EDIT: (id: string = ':locationId') => `${apiVersion}/locations/${id}`,
				BULK_DELETE: `${apiVersion}/locations`,
				TOOL_LOCATION_OPTIONS: `${apiVersion}/locations/toolLocationOptions`,
			},
			DELIVERABLE: {
				STATUS: {
					DATA_LIST: `${apiVersion}/deliverableStatus/data/list`,
					DATA_TABLE: (tableRequestModel: TableQuery) => `${apiVersion}/deliverableStatus/data/table?query=${encodeURIComponent((JSON.stringify(tableRequestModel)))}`,
					JOB_LIST: `${apiVersion}/deliverableStatus/job/list`,
					JOB_TABLE: (tableRequestModel: TableQuery) => `${apiVersion}/deliverableStatus/job/table?query=${encodeURIComponent((JSON.stringify(tableRequestModel)))}`,
					CREATE: `${apiVersion}/deliverableStatus`,
					DELETE: (id: string) => `${apiVersion}/deliverableStatus/${id}`,
					BULK_DELETE: `${apiVersion}/deliverableStatus/bulk`,
					EDIT: (id: string) => `${apiVersion}/deliverableStatus/${id}`,
				},
				DATA: {
					LIST: `${apiVersion}/deliverableData/list`,
					CREATE: `${apiVersion}/deliverableData`,
					DELETE: (id: string) => `${apiVersion}/deliverableData/${id}`,
					EDIT: (id: string) => `${apiVersion}/deliverableData/${id}`,
				},
			},
			DOCUMENT: {
				LIST: (tableRequestModel: TableQuery) => `${apiVersion}/document/list?query=${encodeURIComponent((JSON.stringify(tableRequestModel)))}`,
				UPLOAD: () => `${apiVersion}/document/upload`,
				DOCUMENTS: (documentIds: number[]) => `${apiVersion}/document/all?documentIds=${encodeURIComponent((JSON.stringify(documentIds)))}`,
				DELETE: (documentId: number, storageName: string) => `${apiVersion}/document/${documentId}/${storageName}`,	// FIXME: storage name never used
			},
			SHIFT: {
				LIST: (query) => `${apiVersion}/shift/list?query=${encodeURIComponent(query)}`,
				CREATE: () => `${apiVersion}/shift`,
			},
			JOB_STATUS: {
				TABLE: (tableRequestModel: TableQuery) => `${apiVersion}/jobStatuses/table?query=${encodeURIComponent((JSON.stringify(tableRequestModel)))}`,
				LIST: `${apiVersion}/jobStatuses/list`,
				CREATE: () => `${apiVersion}/jobStatuses`,
				FIND_BY_ID: (id: string = ':jobStatusId') => `${apiVersion}/jobStatuses/${id}`,
				DELETE: (id: string = ':jobStatusId') => `${apiVersion}/jobStatuses/${id}`,
				EDIT: (id: string = ':jobStatusId') => `${apiVersion}/jobStatuses/${id}`,
				BULK_DELETE: `${apiVersion}/jobStatuses`,
			},
			TEMPORARY_EMPLOYEE: {
				COMPANY_TABLE: (tableRequestModel: TableQuery) => `${apiVersion}/temporaryEmployee/table?query=${encodeURIComponent((JSON.stringify(tableRequestModel)))}`,
				COMPANY_TABLE_DELETED: (tableRequestModel: TableQuery) => `${apiVersion}/temporaryEmployee/table/deleted?query=${encodeURIComponent((JSON.stringify(tableRequestModel)))}`,
				FIND_BY_ID: (employeeId: number) => `${apiVersion}/temporaryEmployee/${employeeId}`,
				FIND_WORK_ORDER_TEMPORARY_EMPLOYEES_BY_DUE_DATE: (/** @param dueDate 'YYYY-MM-DD' **/dueDate: string) => `${apiVersion}/temporaryEmployee/order/${dueDate}`,
				CREATE: `${apiVersion}/temporaryEmployee`,
				EDIT: (employeeId: number) => `${apiVersion}/temporaryEmployee/${employeeId}`,
				DELETE: (employeeId: number) => `${apiVersion}/temporaryEmployee/${employeeId}`,
				REACTIVATE: (employeeId: number) => `${apiVersion}/temporaryEmployee/${employeeId}/reactivate`,
				BULK_DELETE: `${apiVersion}/temporaryEmployee/bulk/soft`,
				IS_NAME_AVAILABLE: `${apiVersion}/temporaryEmployee/isNameAvailable`,
			},
		},
		DELIVERABLE: {
			DASHBOARD: (tableRequestModel: TableQuery, statusId: number, startDate: string, endDate: string, completed: boolean) => `${apiVersion}/deliverable/table?query=${encodeURIComponent((JSON.stringify(tableRequestModel)))}&startDate=${startDate}&endDate=${endDate}&statusId=${statusId || ''}&completed=${completed}`,
			DELIVERABLE: (id: number) => `${apiVersion}/deliverable/${id}`,
			DELIVERABLE_COMMENTS: (id: number) => `${apiVersion}/deliverable/${id}/comments`,
			EDIT_DELIVERABLE: (id: number) => `${apiVersion}/deliverable/${id}`,
			SUBMISSION: (id: number) => `${apiVersion}/deliverable/submission/${id}`,
			SUBMISSION_COMMENTS: (id: number) => `${apiVersion}/deliverable/submission/${id}/comments`,
			BULK_EDIT_SUBMISSION: () => `${apiVersion}/deliverable/submission/bulk`,
			EDIT_SUBMISSION: (id: number) => `${apiVersion}/deliverable/submission/${id}`,
			ASSIGNMENT: (id: number) => `${apiVersion}/deliverable/assignment/${id}`,
			CREATE_ASSIGNMENT: `${apiVersion}/deliverable/assignment`,
			EDIT_ASSIGNMENT: (id: number) => `${apiVersion}/deliverable/assignment/${id}`,
			CREATE_DELIVERABLE_COMMENT: (id: number) => `${apiVersion}/deliverable/${id}/comments`,
			CREATE_SUBMISSION_COMMENT: (id: number) => `${apiVersion}/deliverable/submission/${id}/comments`,
		},
		FIELD_REPORT: {
			UPDATE_BLOCK_COMPLETED_STATUS: (id: number, fieldReportBlockId: number) => `${apiVersion}/fieldReport/${id}/fieldReportBlocks/${fieldReportBlockId}/completed`,
			REPEATABLE_FIELDS: (id: number, fieldReportBlockId: string) => `${apiVersion}/fieldReport/${id}/fieldReportBlocks/${fieldReportBlockId}/fields`,
			UPDATE_FIELD_VALUE: (id: number, fieldReportTypeId: number) => `${apiVersion}/fieldReport/${id}/fieldReportTypes/${fieldReportTypeId}/field`,
			FIND_BY_ID: (id: number) => `${apiVersion}/fieldReport/${id}`,
			EDIT_REPORT_TYPES: (id: number) => `${apiVersion}/fieldReport/${id}/fieldReportTypes`,
			ADD_INSTANCE: (id: number, fieldReportTypeId: number) => `${apiVersion}/fieldReport/${id}/fieldReportTypes/${fieldReportTypeId}`,
			ADD_SEGMENT: (id: number, fieldReportTypeId: number, instanceIndex: number) => `${apiVersion}/fieldReport/${id}/fieldReportTypes/${fieldReportTypeId}/instances/${instanceIndex}`,
			CREATE_FIELD_REPORT: `${apiVersion}/fieldReport`,
			DELETE_REPORT_BY_ID: (id: number) => `${apiVersion}/fieldReport/${id}`,
			UPLOAD_FIELD_IMAGE: (id: number, fieldReportTypeId: number, fieldReportBlockId: number, fieldReportBlockFieldId: number) => `${apiVersion}/fieldReport/${id}/fieldReportTypes/${fieldReportTypeId}/blocks/${fieldReportBlockId}/fields/${fieldReportBlockFieldId}`,
			FIELD_SIGNATURE: (id: number) => `${apiVersion}/fieldReport/${id}/signature`,
			FIND_WORK_SUMMARY_DETAILS: (fieldReportId: number) => `${apiVersion}/fieldReport/${fieldReportId}/workSummaryDetails`,
			FIND_WORK_SUMMARY: (fieldReportId: number) => `${apiVersion}/fieldReport/${fieldReportId}/workSummary`,
			CHECK_IF_BILLABLE_WORK_EXISTS: (fieldReportId: number) => `${apiVersion}/fieldReport/${fieldReportId}/billableWork/exists`,
			GET_WORK_SUMMARY_STATUS: (fieldReportId: number) => `${apiVersion}/fieldReport/${fieldReportId}/workSummaryStatus`,
			EDIT_WORK_SUMMARY_STATUS: (fieldReportId: number) => `${apiVersion}/fieldReport/${fieldReportId}/workSummaryStatus`,
			RESET_JOB_WORK_SUMMARY: (fieldReportId: number) => `${apiVersion}/fieldReport/${fieldReportId}/resetJobWorkSummary`,
			GET_EQUIPMENT_LIST: (fieldReportId: number) => `${apiVersion}/fieldReport/${fieldReportId}/equipmentList`,
		},
		FIELD_REPORT_ACCESS: {
			FIND_BY_WORK_ORDER_ID: (workOrderId: number) => `${apiVersion}/fieldReportAccess/workOrder/${workOrderId}`,
			BULK_UPDATE: (workOrderId: number) => `${apiVersion}/fieldReportAccess/workOrder/${workOrderId}`,
		},
		SCHEDULE_BOARD: {
			EXPORT: {
				XLSX: (dueDate: string, withCalculations: boolean, onlyCurrentDay: boolean) => `${apiVersion}/scheduleBoard/export/xlsx?dueDate=${dueDate}${withCalculations ? `&withCalculations=${withCalculations}` : ''}${onlyCurrentDay ? `&onlyCurrentDay=${onlyCurrentDay}` : ''}`,
				PDF: (dueDate: string, withCalculations: boolean, onlyCurrentDay: boolean) => `${apiVersion}/scheduleBoard/export/pdf?dueDate=${dueDate}${withCalculations ? `&withCalculations=${withCalculations}` : ''}${onlyCurrentDay ? `&onlyCurrentDay=${onlyCurrentDay}` : ''}`,
				TEMPLATE: (dueDate: string) => `${apiVersion}/scheduleBoard/export/template?dueDate=${dueDate}`,
			},
			FILTERS: () => `${apiVersion}/scheduleBoard/filters`,
			FIND_TOOLBAR_EMPLOYEE: (employeeId: number, dueDate: string) => `${apiVersion}/scheduleBoard/toolbarEmployee/${employeeId.toString()}?dueDate=${dueDate}`,
			FIND_WORK_ORDER_EMPLOYEE: (workOrderEmployeeId: number, dueDate: string) => `${apiVersion}/scheduleBoard/workOrderEmployee/${workOrderEmployeeId.toString()}?dueDate=${dueDate}`,
			FIND_WORK_ORDER_TEMPORARY_EMPLOYEE: (workOrderTemporaryEmployeeId: number, /** @param dueDate 'YYYY-MM-DD' **/ dueDate: string) => `${apiVersion}/scheduleBoard/workOrderTemporaryEmployee/${workOrderTemporaryEmployeeId.toString()}?dueDate=${dueDate}`,
			FIND_TOOLBAR_EQUIPMENT: (equipmentId: number, dueDate: string) => `${apiVersion}/scheduleBoard/toolbarEquipment/${equipmentId.toString()}?dueDate=${dueDate}`,
			FIND_WORK_ORDER_EQUIPMENT: (workOrderEquipmentId: number, dueDate: string) => `${apiVersion}/scheduleBoard/workOrderEquipment/${workOrderEquipmentId.toString()}?dueDate=${dueDate}`,
			ADD_BLANK_WORK_ORDER: () => `${apiVersion}/scheduleBoard/addBlankWorkOrder`,
			REMOVE_BLANK_WORK_ORDER: () => `${apiVersion}/scheduleBoard/removeBlankWorkOrder`,
			FIND_NIGHT_SHIFT_WORK_ORDERS: (/** format: YYYY-MM-DD */dueDate: string) => `${apiVersion}/scheduleBoard/nightShiftWorkOrders?dueDate=${dueDate}`,
			SMS_NOTIFICATION_CONFIRMATION: (personalPublicLink: string) => `${apiVersion}/hook/availableEmployeeSmsNotification/confirmation/${personalPublicLink}`,
		},
		DAILY_TIP: {
			LAST_FIVE: `${apiVersion}/dailyTip/lastFive`,
			CURRENT: (dueDate) => `${apiVersion}/dailyTip/${dueDate}`,
		},
		DAILY_PER_DIEM_TIP: {
			LAST_FIVE: `${apiVersion}/dailyPerDiemTip/lastFive`,
		},
		NOTIFY: {
			SEND_TEMPLATE_NOTIFICATION_SINGLE: `${apiVersion}/notify/single`,
			SEND_TEMPLATE_NOTIFICATION_MULTIPLE: `${apiVersion}/notify/multiple`,
			SEND_SB_TEMPLATE_NOTIFICATION: `${apiVersion}/notify/template`,
			SCHEDULE: `${apiVersion}/notify/schedule`,
			SCHEDULE_SB: `${apiVersion}/notify/schedule/template`,
			EDIT_GENERAL: `${apiVersion}/notify/general`,
			EDIT_DEFAULT_TIMES: `${apiVersion}/notify/defaultTimes`,
			EDIT_SPECIAL_EMAILS: `${apiVersion}/notify/specialEmails`,
			EDIT_AUTOMATIC: `${apiVersion}/notify/automatic`,
			EDIT_TEMPORARY_LABOR: `${apiVersion}/notify/temporaryLabor`,
		},
		UNION_TYPE: {
			LIST: `${apiVersion}/unionTypes/list`,
		},
		BILLING_CODE: {
			FIND_BY_JOB_ID: (jobId: number) => `${apiVersion}/billingCode/job/${jobId}`,
		},
	},
};

export default API;
