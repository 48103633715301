import * as React from 'react';
import { compose } from 'redux';
import type { InjectedFormProps } from 'redux-form';
import { reduxForm, Field, SubmissionError, formValueSelector } from 'redux-form';
import { Button, Row } from 'react-bootstrap';
import { nanoid } from 'nanoid';
import type { ConnectedProps } from 'react-redux';
import { connect } from 'react-redux';

import RepeatableBlockType from 'acceligent-shared/enums/repeatableBlockType';
import * as ReportBlockFieldEnums from 'acceligent-shared/enums/reportBlockField';

import Input from 'af-fields/Input';
import Checkbox from 'af-fields/Checkbox';
import Dropdown from 'af-fields/Dropdown';

import { REPORT_BLOCK } from 'af-constants/reduxForms';

import type { RootState } from 'af-reducers';

import * as ReportBlockActions from 'af-actions/reportBlock';

import type { ReportBlockUniqueIdAvailableRM } from 'ab-requestModels/reportBlock/reportBlock.requestModel';

import SubmitButton from 'af-components/SubmitButton';
import CustomModal from 'af-components/CustomModal';

import { UNIQUE_ID_SIZE } from 'ab-common/constants/value';

import { validateReportBlock } from './validation';

import type { ReportBlockFieldFormModel, ReportBlockFormModel } from '../../Shared/formModel';

const BLOCK_TYPE_OPTIONS = [
	{ id: true, label: 'Primary Block' },
	{ id: false, label: 'Secondary Block' },
];

const REPEATABLE_BLOCK_TYPE_OPTIONS = [
	{ id: RepeatableBlockType.DEFAULT, label: 'Repeatable block' },
	{ id: RepeatableBlockType.INLINE_TABLE, label: 'Inline table' },
	{ id: RepeatableBlockType.APPENDIX_TABLE, label: 'Appendix table' },
];

const MAX_APPENDIX_TABLE_COLUMNSPAN = 16;
const MAX_INLINE_TABLE_COLUMNSPAN = 8;

const TABLE_COMPATIBLE_FIELD_LIST = Object.keys(ReportBlockFieldEnums.TableCompatibleFields);

interface OwnProps {
	showModal: boolean;
	closeModal: () => void;
	onSubmit: (form: ReportBlockFormModel) => void;
	firstInitialValues?: ReportBlockFormModel;
	disableBlockTypeDropdown?: boolean;
	title: string;
	fieldIds?: string[];
	fieldsByIdMap?: { [fieldId: string]: ReportBlockFieldFormModel; };
}

type Props = OwnProps & ConnectedProps<typeof connector> & InjectedFormProps<ReportBlockFormModel>;

const ReportBlockModal: React.FC<Props> = (props: Props) => {
	const {
		change,
		closeModal,
		destroy,
		disableBlockTypeDropdown,
		fieldIds,
		fieldsByIdMap,
		firstInitialValues,
		handleSubmit,
		initialize,
		invalid,
		isBlockUniqueIdAvailable,
		onSubmit,
		repeatableBlockType,
		showModal,
		submitting,
		title,
	} = props;

	const [isRepeating, setIsRepeating] = React.useState<boolean>(firstInitialValues?.isRepeating ?? false);

	React.useEffect(() => {
		if (!showModal) {
			destroy();
		} else {
			initialize(firstInitialValues ?? { isMain: true });
			setIsRepeating(firstInitialValues?.isRepeating ?? false);
		}
		// eslint-disable-next-line react-hooks/exhaustive-deps
	}, [showModal]);

	const submit = React.useCallback(async (form: ReportBlockFormModel) => {
		// if creating block check if block name available
		const { available } = await isBlockUniqueIdAvailable({ uniqueId: form.uniqueId, id: form.id } as ReportBlockUniqueIdAvailableRM);
		if (!available) {
			throw new SubmissionError({ uniqueId: 'Unique ID already taken' });
		}

		// Generate completion field id
		if (form.hasCompletionStatus && !form.completionFieldVirtualId) {
			form.completionFieldVirtualId = nanoid(UNIQUE_ID_SIZE);
		} else if (!form.hasCompletionStatus && form.completionFieldVirtualId) {
			form.completionFieldVirtualId = null;
		}

		onSubmit(form);
		closeModal();
	}, [closeModal, isBlockUniqueIdAvailable, onSubmit]);

	const handleIsRepeatingChange = React.useCallback((newIsRepeating: boolean) => {
		setIsRepeating(newIsRepeating);
		if (newIsRepeating) {
			change('repeatableBlockType', RepeatableBlockType.DEFAULT);
		} else {
			change('repeatableBlockType', null);
		}
		// eslint-disable-next-line react-hooks/exhaustive-deps
	}, []);

	const saveDisabled = React.useMemo(() => {
		if (!fieldIds || !isRepeating || repeatableBlockType === RepeatableBlockType.DEFAULT) {
			return invalid;
		} else {

			const currentMaxColspan = repeatableBlockType === RepeatableBlockType.INLINE_TABLE ? MAX_INLINE_TABLE_COLUMNSPAN : MAX_APPENDIX_TABLE_COLUMNSPAN;
			const fieldColumnSpan = fieldIds.reduce<number>((_acc, _fieldId) => {
				const _field = fieldsByIdMap?.[_fieldId];
				if (!_field) {
					throw new Error('Missing field');
				}

				switch (_field.dimension) {
					case ReportBlockFieldEnums.Dimension.VERY_SMALL:
					case ReportBlockFieldEnums.Dimension.SMALL:
						return _acc += 1;
					case ReportBlockFieldEnums.Dimension.MEDIUM:
						return _acc += 2;
					case ReportBlockFieldEnums.Dimension.LARGE:
						return _acc += 3;
					default:
						return _acc += currentMaxColspan;
				}
			}, 0);

			const maxColspanReached = fieldColumnSpan > currentMaxColspan;
			const fieldTypes = fieldIds.reduce<ReportBlockFieldEnums.Type[]>((_acc, _id) => {
				const field = fieldsByIdMap?.[_id];
				if (!field) {
					return _acc;
				}
				_acc.push(field.fieldType);
				return _acc;
			}, []);

			const hasIncompatibleFields = !fieldTypes.every((_fieldType) => TABLE_COMPATIBLE_FIELD_LIST.includes(_fieldType));

			return invalid || maxColspanReached || hasIncompatibleFields;
		}
	}, [fieldIds, fieldsByIdMap, invalid, isRepeating, repeatableBlockType]);

	return (
		<CustomModal
			closeModal={closeModal}
			modalStyle="info"
			showModal={showModal}
			size="md"
		>
			<CustomModal.Header
				closeModal={closeModal}
				title={title}
			/>
			<CustomModal.Body>
				<Row className="row--non-padded">
					<Field
						component={Input}
						label="Name *"
						name="name"
						placeholder="Enter Block Name"
					/>
				</Row>
				<Row className="row--non-padded">
					<Field
						component={Input}
						label="Unique ID *"
						name="uniqueId"
						placeholder="Enter Block Name"
					/>
				</Row>
				<Row className="row--non-padded">
					<Field
						component={Dropdown}
						disabled={disableBlockTypeDropdown}
						id="isMain"
						label="Block Type * "
						labelKey="label"
						name="isMain"
						options={BLOCK_TYPE_OPTIONS}
						valueKey="id"
						withCaret={true}
					/>
				</Row>
				<Row className="row--non-padded">
					<Field
						component={Checkbox}
						inline={true}
						isStandalone={true}
						label="Is Repeating block"
						name="isRepeating"
						onValueChange={handleIsRepeatingChange}
						value={isRepeating}
					/>
					{
						isRepeating &&
						<Field
							component={Dropdown}
							defaultValue={REPEATABLE_BLOCK_TYPE_OPTIONS[0]}
							id="repeatableBlockType"
							label="Repeatable Block Type "
							labelKey="label"
							name="repeatableBlockType"
							options={REPEATABLE_BLOCK_TYPE_OPTIONS}
							valueKey="id"
							withCaret={true}
						/>
					}
				</Row>
				<Row className="row--non-padded">
					<Field
						component={Checkbox}
						inline={true}
						isStandalone={true}
						label="Has Completion Status"
						name="hasCompletionStatus"
					/>
				</Row>
			</CustomModal.Body>
			<CustomModal.Footer>
				<Button
					onClick={closeModal}
					variant="info"
				>
					Cancel
				</Button>
				<SubmitButton
					disabled={saveDisabled}
					label="Save"
					onClick={handleSubmit(submit)}
					reduxFormSubmitting={submitting}
					submitKey={REPORT_BLOCK}
				/>
			</CustomModal.Footer>
		</CustomModal>
	);
};

function mapDispatchToProps() {
	return {
		isBlockUniqueIdAvailable: ReportBlockActions.isBlockUniqueIdAvailable,
	};
}

function mapStateToProps(state: RootState) {
	const selector = formValueSelector(REPORT_BLOCK);
	return {
		repeatableBlockType: selector(state, 'repeatableBlockType'),
	};
}

const connector = connect(mapStateToProps, mapDispatchToProps());

const enhance = compose<React.ComponentClass<OwnProps>>(
	React.memo,
	connector,
	reduxForm<ReportBlockFormModel, OwnProps>({
		form: REPORT_BLOCK,
		validate: validateReportBlock,
	})
);

export default enhance(ReportBlockModal);
