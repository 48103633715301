import * as React from 'react';

import type { TimeSplitEntryVM } from 'ab-viewModels/timeSheet/timeSheet.viewModel';

import * as TimeUtils from 'acceligent-shared/utils/time';

interface OwnProps {
	timeSplitEntries: TimeSplitEntryVM[];
}

type Props = OwnProps;

const TimeSplitTimes: React.FC<Props> = (props) => {
	const { timeSplitEntries } = props;

	const renderTimeSplitTime = React.useCallback((timeSplitEntry: TimeSplitEntryVM) => {
		const { id, time } = timeSplitEntry;

		return (
			<div className="time-sheet-list__row__cell__time-split" key={id}>
				{TimeUtils.minutesToHoursAndMinutes(time)}
			</div>
		);
	}, []);

	return (
		<>{timeSplitEntries.map(renderTimeSplitTime)}</>
	);
};

export default React.memo(TimeSplitTimes);
