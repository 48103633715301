import * as React from 'react';
import { Button } from 'react-bootstrap';
import { connect } from 'react-redux';

import type * as User from 'ab-viewModels/user.viewModel';

import CustomModal from 'af-components/CustomModal';

import type { RootState } from 'af-reducers';

import EmployeeCardLegend from './EmployeeCardLegend';
import EquipmentCardLegend from './EquipmentCardLegend';
import WorkOrderCardLegend from './WorkOrderCardLegend';

interface OwnProps {
	modalVisible: boolean;
	closeModal: () => void;
}

interface StateProps {
	userData: User.UserData;
	companyData: User.CompanyData;
	organizationData: User.OrganizationData;
}

type Props = OwnProps & StateProps;

class ColorLegendModal extends React.PureComponent<Props> {

	render() {
		const { closeModal, userData, companyData, organizationData, modalVisible } = this.props;

		return (
			<CustomModal
				className="legend-modal"
				closeModal={closeModal}
				modalStyle="info"
				showModal={modalVisible}
				size="xl"
			>
				<CustomModal.Header
					closeModal={closeModal}
					title="Legend"
				/>
				<CustomModal.Body className="legend-modal__body">
					<WorkOrderCardLegend
						accountPermissions={companyData.permissions}
						companyName={companyData.name}
						isAdmin={companyData.isCompanyAdmin}
						orgAlias={organizationData.alias}
						userPermission={userData.role}
					/>
					<EmployeeCardLegend
						accountPermissions={companyData.permissions}
						companyName={companyData.name}
						isAdmin={companyData.isCompanyAdmin}
						orgAlias={organizationData.alias}
						userPermission={userData.role}
					/>
					<EquipmentCardLegend
						accountPermissions={companyData.permissions}
						companyName={companyData.name}
						isAdmin={companyData.isCompanyAdmin}
						orgAlias={organizationData.alias}
						userPermission={userData.role}
					/>
				</CustomModal.Body>
				<CustomModal.Footer>
					<Button
						onClick={closeModal}
						variant="info"
					>
						Close
					</Button>
				</CustomModal.Footer>
			</CustomModal>
		);
	}
}

const mapStateToProps = (state: RootState): StateProps => {
	const { userData, companyData, organizationData } = state.user;
	if (!userData || !companyData || !organizationData) {
		throw new Error('User not logged in');
	}

	return {
		userData,
		companyData,
		organizationData,
	};
};

export default connect<StateProps, null, OwnProps>(mapStateToProps)(ColorLegendModal);
