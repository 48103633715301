import * as React from 'react';
import type { WrappedFieldProps, WrappedFieldInputProps } from 'redux-form';
import { FormGroup, FormControl } from 'react-bootstrap';
import { nanoid } from 'nanoid';

import { TEXT_AREA_LINE_HIGHT } from 'af-constants/values';

import Label from 'af-components/LockedValue/Label';
import type { OwnProps as TooltipProps } from 'af-components/Tooltip';

import { UNIQUE_ID_SIZE } from 'ab-common/constants/value';

interface OwnProps {
	name: string;
	label: string;
	placeholder: string;
	disabled?: boolean;
	onValueChange?: (value: string) => void;
	showMaxCharactersLabel?: boolean;
	maxCharacters?: number;
	tooltipMessage?: TooltipProps['message'];
	rows?: number;
	className?: string;
	dynamicRows?: boolean;
	/** Container ID */
	id?: string;
}

type Props = OwnProps & WrappedFieldProps & WrappedFieldInputProps;

interface State {
	rows: number | undefined;
}

class Textarea extends React.Component<Props, State> {

	static defaultProps: OptionalObject<OwnProps> = {
		showMaxCharactersLabel: true,
		maxCharacters: 750,
		className: '',
		rows: 4,
	};

	state: State = {
		rows: this.props.rows,
	};

	private inputId = nanoid(UNIQUE_ID_SIZE);

	componentDidMount() {
		const { dynamicRows } = this.props;
		const { rows } = this.state;
		if (dynamicRows && rows) {
			this.calculateRows();
		}
	}

	componentDidUpdate(prevProps: Props) {
		const { input, dynamicRows } = this.props;

		if (dynamicRows && input.value !== prevProps.input.value) {
			this.setState(() => ({ rows: 1 }), this.calculateRows);
		}
	}

	onChange = async (event) => {
		const { onValueChange, input } = this.props;

		const value: string = event.currentTarget.value as string;
		input.onChange(value);

		onValueChange?.(value);
	};

	calculateRows = () => {
		const input = document.getElementById(this.inputId);
		const lineHeight = TEXT_AREA_LINE_HIGHT;
		const scrollHeight = input?.scrollHeight ?? 0;
		const rows = Math.floor(scrollHeight / lineHeight);
		if (this.state.rows !== rows) {
			this.setState(() => ({ rows }));
		}
	};

	render() {
		const {
			disabled,
			input,
			label,
			placeholder, meta: { touched, error, warning },
			showMaxCharactersLabel,
			maxCharacters,
			tooltipMessage,
			className,
			id,
		} = this.props;
		const { rows } = this.state;

		return (
			<FormGroup
				id={id}
			>
				<div className="input-header">
					<Label
						label={label}
						tooltipMessage={tooltipMessage}
						tooltipPlacement="top"
						withMargin={true}
					/>
					{showMaxCharactersLabel && maxCharacters && <span className="input-description pull-right">{input.value.length}/{maxCharacters}</span>}
				</div>
				<FormControl
					{...input}
					as="textarea"
					autoComplete="off"
					className={`${className} ${(touched && error) ? 'with-error' : ''}`}
					disabled={disabled}
					id={this.inputId}
					maxLength={maxCharacters}
					onChange={this.onChange}
					placeholder={placeholder}
					rows={rows}
				/>
				<FormControl.Feedback />
				{touched &&
					(
						(error && <span className="help-block"><span className="icon-info" /> {error}</span>) ||
						(warning && <span className="help-block text-orange"><span className="icon-info" /> {warning}</span>)
					)
				}
			</FormGroup>
		);
	}
}

export default Textarea;
