import * as React from 'react';

import type { AdditionalColors, ColorPalette } from 'acceligent-shared/enums/color';

import LockedValue from 'af-components/LockedValue';
import ColorSquare from 'af-components/ColorSquare';

interface OwnProps {
	label: string;
	color: ColorPalette | AdditionalColors;
}

export default class LockedColorValue extends React.PureComponent<OwnProps> {
	render() {
		const { label, color } = this.props;
		return (
			<LockedValue
				label={label}
				value={
					<div className="locked-input__color">
						<ColorSquare color={color} />
						<span className="locked-input__addition">
							{color}
						</span>
					</div>
				}
			/>
		);
	}
}
