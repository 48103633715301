import * as React from 'react';

import type JobPreviewViewModel from 'ab-viewModels/jobPreview.viewModel';

import LockedValue from 'af-components/LockedValue';
import SegmentLabel from 'af-components/SegmentLabel';

import styles from './styles.module.scss';

interface OwnProps {
	allowCustomerSignature: JobPreviewViewModel['allowCustomerSignature'];
}

type Props = OwnProps;

class JobAdditionalInfoPreview extends React.PureComponent<Props> {
	render() {
		const { allowCustomerSignature } = this.props;

		return (
			<div className={styles['job-preview__additional-info']}>
				<div>
					<SegmentLabel label="Customer Signature" />
				</div>
				<div>
					<LockedValue label="Allow Customer Signature" value={allowCustomerSignature ? 'YES' : 'NO'} />
				</div>
			</div>
		);
	}

}

export default React.memo(JobAdditionalInfoPreview);
