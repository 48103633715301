import type { AxiosRequestConfig, AxiosResponse, AxiosError } from 'axios';
import axios from 'axios';

import AppStore from 'af-root/store';

import { SUBMIT_START, SUBMIT_END } from 'af-reducers/http.reducer';

import { FETCH_START, FETCH_END } from 'af-constants/actionTypes';
import { USE_WILDCARDS, USE_SSL } from 'af-constants/values';

import socket from 'af-utils/socket.util';
import { getSignInForOrg } from 'af-utils/localStorage.util';
import * as WindowUtil from 'af-utils/window.util';

import API from 'af-routes/api';

// Interfaces

export type HttpError = AxiosError;

interface Config extends AxiosRequestConfig {
	spinner?: boolean;
	requireAuthentication?: boolean;
	submitting?: string | string[];
}

interface HeavyLoadConfig extends Config {
	/** How often client PINGS server for the result. Milliseconds. */
	delay?: number;
}

interface HttpHeaders {
	token?: string;
	company_id?: number | string;
	authorization?: string;
	socket_id?: string;
}

// Constants and variables

const START = { type: FETCH_START };

const END = { type: FETCH_END };

let source: Nullable<ReturnType<typeof axios.CancelToken.source>>;

// Private Functions

function _startSpinner(spinner: boolean): void {
	if (spinner) {
		AppStore.getStore().dispatch(START);
	}
}

function _stopSpinner(spinner: boolean): void {
	if (spinner) {
		AppStore.getStore().dispatch(END);
	}
}

function _startSubmitting(submitting: string | string[] | undefined): void {
	if (submitting) {
		AppStore.getStore().dispatch(SUBMIT_START(submitting));
	}
}

function _stopSubmitting(submitting: string | string[] | undefined): void {
	if (submitting) {
		AppStore.getStore().dispatch(SUBMIT_END(submitting));
	}
}

function _prepareHeaders(): { headers: HttpHeaders; } | undefined {
	const currentOrgAlias: Nullable<string> = WindowUtil.getCurrentOrgAlias();

	if (!currentOrgAlias) {
		return;
	}

	const currentSignIn = getSignInForOrg(currentOrgAlias, true);

	if (!currentSignIn) {
		return;
	}

	return {
		headers: {
			authorization: `Bearer ${currentSignIn.token}`,
			// eslint-disable-next-line @typescript-eslint/prefer-nullish-coalescing
			company_id: currentSignIn.companyId || '',
			socket_id: socket.connection?.getId() ?? '',
		} as HttpHeaders,
	};
}

const _getAction = async <T>(url: string, { requireAuthentication = true, spinner = true, ...config }: Config = {}): Promise<T> => {
	try {
		_startSpinner(spinner);
		_startSubmitting(config.submitting);

		const options = _prepareHeaders() as HttpHeaders;

		const conf = requireAuthentication ? { ...config, ...options } : config;
		const res: AxiosResponse = await axios.get(url, conf);

		_stopSpinner(spinner);
		_stopSubmitting(config.submitting);
		return res.data as T;
	} catch (error) {
		_stopSpinner(spinner);
		_stopSubmitting(config.submitting);
		throw error;
	}
};

const _getHeavyDataAction = async <T>(
	url: string,
	{ delay = 1000, requireAuthentication = true, spinner = true, ...config }: HeavyLoadConfig = {}
): Promise<T> => {
	try {
		_startSpinner(spinner);
		_startSubmitting(config.submitting);

		const options = _prepareHeaders() as HttpHeaders;

		const conf = requireAuthentication ? { ...config, ...options } : config;
		const res: AxiosResponse = await axios.get(url, conf);

		let result;
		if (res.status === 206) {
			const sleep = () => new Promise((resolve) => setTimeout(resolve, delay));

			const betterAction = async () => {
				const value = await axios.get(API.V1.WORKER_JOB.HEAVY_DATA_LOAD(res.data.id), conf);
				if (value.status === 204) {
					await sleep();
					return await betterAction();
				}
				return value.data;
			};

			result = await betterAction();
		}

		_stopSpinner(spinner);
		_stopSubmitting(config.submitting);
		return result as T;
	} catch (error) {
		_stopSpinner(spinner);
		_stopSubmitting(config.submitting);
		throw error;
	}
};

const _postAction = async <T>(url: string, data?: unknown, { requireAuthentication = true, spinner = true, ...config }: Config = {}): Promise<T> => {
	try {
		_startSpinner(spinner);
		_startSubmitting(config.submitting);

		source = axios.CancelToken.source();
		const options = _prepareHeaders() as HttpHeaders;
		const conf = requireAuthentication ? { ...config, ...options, cancelToken: source.token } : config;
		const res: AxiosResponse = await axios.post(url, data, conf);

		_stopSpinner(spinner);
		_stopSubmitting(config.submitting);
		return res.data as T;
	} catch (error) {
		_stopSpinner(spinner);
		_stopSubmitting(config.submitting);
		throw error;
	}
};

const _putAction = async <T>(url: string, data?: unknown, { requireAuthentication = true, spinner = true, ...config }: Config = {}): Promise<T> => {
	try {
		_startSpinner(spinner);
		_startSubmitting(config.submitting);

		source = axios.CancelToken.source();
		const options = _prepareHeaders() as HttpHeaders;
		const conf = requireAuthentication ? { ...config, ...options, cancelToken: source.token } : config;
		const res: AxiosResponse = await axios.put(url, data, conf);

		_stopSpinner(spinner);
		_stopSubmitting(config.submitting);
		return res.data as T;
	} catch (error) {
		_stopSpinner(spinner);
		_stopSubmitting(config.submitting);
		throw error;
	}
};

const _patchAction = async <T>(url: string, data?: Metadata, { requireAuthentication = true, spinner = true, ...config }: Config = {}): Promise<T> => {
	try {
		_startSpinner(spinner);
		_startSubmitting(config.submitting);

		source = axios.CancelToken.source();
		const options = _prepareHeaders() as HttpHeaders;
		const conf = requireAuthentication ? { ...config, ...options, cancelToken: source.token } : config;
		const res: AxiosResponse = await axios.patch(url, data, conf);

		_stopSpinner(spinner);
		_stopSubmitting(config.submitting);
		return res.data as T;
	} catch (error) {
		_stopSpinner(spinner);
		_stopSubmitting(config.submitting);
		throw error;
	}
};

const _deleteAction = async <T>(url: string, data?: Metadata, { requireAuthentication = true, spinner = true, ...config }: Config = {}): Promise<T> => {
	try {
		_startSpinner(spinner);
		_startSubmitting(config.submitting);

		source = axios.CancelToken.source();
		const options = _prepareHeaders() as HttpHeaders;
		let conf = requireAuthentication ? { ...config, ...options, cancelToken: source.token } : config;
		conf = data ? { ...conf, data } : conf;
		const res: AxiosResponse = await axios.delete(url, conf);

		_stopSpinner(spinner);
		_stopSubmitting(config.submitting);
		return res.data as T;
	} catch (error) {
		_stopSpinner(spinner);
		_stopSubmitting(config.submitting);
		throw error;
	}
};

const _cancelRequest = async (message: string = 'Request is canceled by user.'): Promise<void> => {
	source?.cancel(message);
};

// Public Functions

export const http = {
	get: _getAction,
	getFromWorker: _getHeavyDataAction,
	post: _postAction,
	put: _putAction,
	patch: _patchAction,
	delete: _deleteAction,
	cancel: _cancelRequest,
	prepareHeaders: _prepareHeaders,
	startSubmitting: SUBMIT_START,
	endSubmitting: SUBMIT_END,
};

export function getProtocol(): string {
	return USE_SSL ? 'https' : 'http';
}

function _getAppUrl(): string {
	if (process.env.REVIEW_APP === 'true') {
		return `${process.env.HEROKU_APP_NAME!}.herokuapp.com`;
	}
	return process.env.APP_URL!;
}

export function getUrlWithAlias(orgAlias: string): string {
	return USE_WILDCARDS ? `//${orgAlias}.${WindowUtil.getHostname()}` : `//${_getAppUrl()}/${orgAlias}/`;
}

export function getFullClientUrl(orgAlias: string, clientRoute: string) {
	return USE_WILDCARDS ? `${getProtocol()}://${orgAlias}.${WindowUtil.getHostname()}${clientRoute}` : `${getProtocol()}://${_getAppUrl()}/${clientRoute}`;
}
