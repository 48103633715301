import * as React from 'react';
import type { InjectedFormProps} from 'redux-form';
import { Field } from 'redux-form';
import { Form, Row, Col } from 'react-bootstrap';

import CompanyFeatureFlagsEnum from 'ab-enums/companyFeatureFlags.enum';

import Dropdown from 'af-fields/Dropdown';

import * as TimeOptionUtils from 'ab-utils/timeOption.util';

import type { DefaultNotificationTimesForm } from '../formModel';

interface OwnProps extends InjectedFormProps<DefaultNotificationTimesForm> {
	onSubmit: (form: DefaultNotificationTimesForm) => Promise<void>;
}

type Props = OwnProps;

class NotificationTimesForm extends React.PureComponent<Props> {

	onSubmit = async (form: DefaultNotificationTimesForm) => {
		await this.props.onSubmit(form);
	};

	renderNotifyOnDay = (name: string) => {
		return (
			<Col sm={6}>
				<Field
					component={Dropdown}
					fixed={true}
					id={name}
					isStandalone={true}
					label={CompanyFeatureFlagsEnum[name]}
					labelKey="name"
					name={name}
					options={TimeOptionUtils.enumWithoutNullValue()}
					valueKey="id"
					withCaret={true}
				/>
			</Col>
		);
	};

	render() {
		const { handleSubmit } = this.props;

		return (
			<Form onSubmit={handleSubmit(this.onSubmit)}>
				<Row className="row--padded">
					{this.renderNotifyOnDay('notifyOnMonday')}
					{this.renderNotifyOnDay('notifyOnTuesday')}
					{this.renderNotifyOnDay('notifyOnWednesday')}
					{this.renderNotifyOnDay('notifyOnThursday')}
				</Row>
				<Row className="row--padded">
					{this.renderNotifyOnDay('notifyOnFriday')}
					{this.renderNotifyOnDay('notifyOnSaturday')}
					{this.renderNotifyOnDay('notifyOnSunday')}
					<Col sm={6} />
				</Row>
			</Form>
		);
	}
}

export default NotificationTimesForm;
