import * as React from 'react';

import { filterMap } from 'acceligent-shared/utils/array';
import * as TimeSheetUtils from 'acceligent-shared/utils/timeSheet';

import TimeSheetApprovalStatus from 'acceligent-shared/enums/timeSheetApprovalStatus';

import type { TimeSheetInfoVM, TimeSheetEntryInfoVM } from 'ab-viewModels/workOrder/submitForReviewRecap';

import TimeSheetEmployee from '../Shared/ReportsSummary/TimeSheets/TimeSheetEmployee';

interface OwnProps {
	currentUserTimeSheet: Nullable<TimeSheetInfoVM>;
	timeSheetEntriesByAccountIdMap: Nullable<{ [accountId: number]: TimeSheetEntryInfoVM[]; }>;
	timeSheets: TimeSheetInfoVM[];
	timezone: Nullable<string>;
}

type Props = OwnProps;

const TimeSheets: React.FC<Props> = (props) => {
	const { timeSheets, timeSheetEntriesByAccountIdMap, currentUserTimeSheet, timezone } = props;

	const isCurrentUserTimeSheetUnsigned = currentUserTimeSheet && TimeSheetUtils.isTimeSheetSigned(
		currentUserTimeSheet.signatureStatus,
		!!currentUserTimeSheet.isSignedBySI,
		currentUserTimeSheet.approvalStatus
	) && currentUserTimeSheet.approvalStatus !== TimeSheetApprovalStatus.APPROVED;

	const renderEmployee = React.useCallback((timeSheet: TimeSheetInfoVM) => {
		return (
			<TimeSheetEmployee
				key={timeSheet.accountId}
				timeSheet={timeSheet}
				timeSheetEntries={timeSheetEntriesByAccountIdMap?.[timeSheet.accountId] ?? []}
				timezone={timezone}
			/>
		);
	}, [timeSheetEntriesByAccountIdMap, timezone]);

	// If current user time sheet is unsigned do not show it in section
	const filterTimeSheets = React.useCallback((timeSheet: TimeSheetInfoVM) => {
		return isCurrentUserTimeSheetUnsigned ? timeSheet.accountId !== currentUserTimeSheet.accountId : true;
	}, [currentUserTimeSheet?.accountId, isCurrentUserTimeSheetUnsigned]);

	if (!timeSheets.length) {
		return null;
	}

	return (
		<div className="report-submit-modal__section">
			<div className="report-submit-modal__section__title">TIME SHEETS</div>
			{isCurrentUserTimeSheetUnsigned &&
				<div className="report-submit-modal__time-sheet-section">
					<div className="report-submit-modal__time-sheet-section__title">Sign my time sheet:</div>
					<div className="report-submit-modal__section__list">
						{renderEmployee(currentUserTimeSheet)}
					</div>
				</div>
			}
			{!!timeSheets?.length &&
				<div className="report-submit-modal__time-sheet-section">
					{isCurrentUserTimeSheetUnsigned && <div className="report-submit-modal__time-sheet-section__title">Submit time sheets:</div>}
					<div className="report-submit-modal__section__list">
						{filterMap(timeSheets, filterTimeSheets, renderEmployee)}
					</div>
				</div>
			}
		</div>
	);
};

export default React.memo(TimeSheets);
