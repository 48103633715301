import * as React from 'react';
import type { FormAction } from 'redux-form';

import Radio from 'af-components/Controls/Radio';

interface OwnProps {
	change: (formName: string, field: string, value: string | string[]) => FormAction;
	disabled?: boolean;
	fieldName: string;
	formName: string;
	select: (field: string) => number[];
}

type Props = OwnProps;

export default class EmptyContactMethodPicker extends React.PureComponent<Props> {

	onDeselectAll = async () => {
		const { fieldName, change, formName, disabled } = this.props;
		if (disabled) {
			return;
		}
		change(formName, fieldName, []);
	};

	isSelected = () => {
		const { select, fieldName } = this.props;
		return !select(fieldName)?.length;
	};

	render() {
		const isSelected = this.isSelected();
		let rowClassName = 'contact__methods-row';
		rowClassName = !isSelected ? `${rowClassName} contact__methods-row--not-selected` : rowClassName;

		return (
			<div className={rowClassName}>
				<div className="contact__method-control">
					<Radio
						checked={isSelected}
						onCheck={this.onDeselectAll}
					/>
				</div>
				<div>
					<strong>None</strong>
				</div>
			</div>
		);
	}
}
