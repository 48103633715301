import * as React from 'react';
import type { TypedRowInfo, TypedColumn, SortingRule, Resize, FinalState, ComponentPropsGetterC } from 'react-table-6';
import ReactTable from 'react-table-6';
import type { ConnectedProps } from 'react-redux';
import { connect } from 'react-redux';

import type { TableContent } from 'ab-common/dataStructures/tableContent';

import type { RootState } from 'af-reducers';

import * as TableSettingsActions from 'af-actions/tableSettings';

import type TableSettingsVM from 'ab-viewModels/tableSettings.viewModel';

import LoadingIndicator from 'af-components/LoadingIndicator';
import InfiniteScroll from 'af-components/ScrollToLoad';

import { RESIZE_DELAY } from 'ab-common/constants/value';

import type TableNameEnum from 'ab-enums/tableName.enum';

import { debounce } from 'af-utils/actions.util';
import { getDefaultTable6Settings, areColumnNamesEqual, updateTableSettingsColumns } from 'af-utils/table.utils';

import { bemBlock, bemElement } from 'ab-utils/bem.util';

import type { ExpandedDefinition, ReactTableRef, ItemBlueprint } from './types';
import { DEFAULT_REACT_TABLE_PROPS, SCROLL_TO_ROW_ID } from './constants';
import * as Helpers from './helpers';
import TableTypeEnum, { getTableType } from './tableType.enum';
import ScrollToLoadItem from './ScrollToLoadItem';
import TableSettingsModal from './Settings/TableSettingsModal';

interface OwnProps<T> {
	indent: number;
	tab: TabProps<T>;
	table: TableContent<T>;
	isLoaded: boolean;
	tableName: TableNameEnum;
	SubComponent?: (rowInfo: TypedRowInfo<T>) => React.ReactNode;
	onMount?: (table: ReactTableRef<T>) => void;
	expanded?: ExpandedDefinition;
	getRowExpanded?: (row: T, index: number) => boolean;
	getRowHighlighted?: (row: T) => boolean;
	onScrollIntoNode?: () => void;
}

export interface TabProps<T> {
	className?: string;
	columns: TypedColumn<T>[];
	fetch: () => Promise<TableContent<T>>;

	onRowClick?: (rowInfo: TypedRowInfo<T>) => void;

	getRowClassModifiers?: (state: FinalState<T>, rowInfo: TypedRowInfo<T>) => string[];	// can be used to override `inactive` class
	getRowGroupClassModifiers?: (state: FinalState<T>, rowInfo: TypedRowInfo<T>) => string[];
	getHeaderClassModifiers?: (state: FinalState<T>, rowInfo?: TypedRowInfo<T>) => string[];

	onExpandedChange?: (newExpanded: ExpandedDefinition) => void;

	rowActions?: ItemBlueprint<T>[];
}

type Props<T> = OwnProps<T> & ConnectedProps<typeof connector>;

interface State {
	tableType: TableTypeEnum;
	tableSettings: Nullable<TableSettingsVM>;
	isTableSettingsLoaded: boolean;
	showTableSettingsModal: boolean;
	expanded: ExpandedDefinition | undefined;
}
class IndentTable<T> extends React.PureComponent<Props<T>, State> {
	state: State = {
		tableType: getTableType(window.innerWidth),
		tableSettings: null,
		isTableSettingsLoaded: false,
		showTableSettingsModal: false,
		expanded: undefined,
	};

	static getDerivedStateFromProps<T2>(props: Props<T2>): Partial<State> {
		const { table, getRowExpanded, expanded } = props;

		if (getRowExpanded) {
			const expandedState = table?.rows?.reduce((_acc, _row, _index) => {
				if (getRowExpanded(_row, _index)) {
					_acc[_index] = true;
				}
				return _acc;
			}, {} as ExpandedDefinition) ?? {};
			return { expanded: { ...expandedState, ...expanded } };
		}
		return { expanded };
	}

	static scrollIntoRow = () => {
		const row = document.getElementById(SCROLL_TO_ROW_ID);
		row?.scrollIntoView({ block: 'center', behavior: 'smooth' });
	};

	async componentDidMount() {
		const {
			findTableSettings,
			updateTableSettings,
			tableName,
			accountId,
			tab: { columns },
		} = this.props;

		this.updateWindowDimensions();
		window.addEventListener('resize', this.updateWindowDimensions);

		const defaultTableSettings = getDefaultTable6Settings(tableName, accountId, columns);
		if (accountId && defaultTableSettings?.accountId) {
			let tableSettings = await findTableSettings(tableName);
			if (!tableSettings) {
				tableSettings = { ...defaultTableSettings, accountId: defaultTableSettings.accountId };
				await updateTableSettings(tableSettings);
			} else if (!areColumnNamesEqual(defaultTableSettings, tableSettings)) {
				// table component has been updates since last settings
				tableSettings = updateTableSettingsColumns(tableSettings, defaultTableSettings.columnSettings);
				await updateTableSettings(tableSettings);
			}

			this.setState(() => ({
				tableSettings,
				isTableSettingsLoaded: true,
			}));
		} else {
			this.setState(() => ({
				isTableSettingsLoaded: true,
				tableSettings: defaultTableSettings as TableSettingsVM,
			}));
		}
	}

	componentWillUnmount() {
		window.removeEventListener('resize', this.updateWindowDimensions);
	}

	openTableSettingsModal = () => this.setState(() => ({ showTableSettingsModal: true }));

	closeTableSettingsModal = () => this.setState(() => ({ showTableSettingsModal: false }));

	refreshTable = async () => {
		const { tab } = this.props;
		await tab.fetch();
	};

	updateWindowDimensions = () => {
		const updatedTableType = getTableType(window.innerWidth);
		const { tableType } = this.state;

		if (tableType !== updatedTableType) {
			if (updatedTableType !== TableTypeEnum.SCROLLABLE) {
				this.setState(() => ({ tableType: updatedTableType }));
			} else {
				this.refreshStateForScrollingTable();
			}
		}
	};

	refreshStateForScrollingTable = () => this.setState(() => ({ tableType: TableTypeEnum.SCROLLABLE }));

	// React Table Props:

	getTrProps = (state: FinalState<T>, rowInfo: TypedRowInfo<T>) => {
		const { getRowHighlighted, onScrollIntoNode } = this.props;
		const { onRowClick, getRowClassModifiers } = this.props.tab;

		const modifiers = getRowClassModifiers ? getRowClassModifiers(state, rowInfo) : [];
		const isHighlighted = getRowHighlighted?.(rowInfo.original) ?? false;
		if (isHighlighted) {
			// we need to set id before calling scrollIntoRow
			// currently, isHighlighted is true only for one row so setTimeout cb is fine like this
			// if isHighlighted can be true for multiple rows extract it
			setTimeout(() => {
				IndentTable.scrollIntoRow();
				onScrollIntoNode?.();
			});
		}

		// onRowClick and onExpandChange don't like each other
		// add onRowClick event to the row only if don't have expander
		const addOnClickEvent = onRowClick && !state.ExpanderComponent;

		return {
			id: isHighlighted ? SCROLL_TO_ROW_ID : undefined,
			onClick: addOnClickEvent ? onRowClick.bind(this, rowInfo) : undefined,
			className: bemBlock('indent-table', modifiers),
		};
	};

	getTdProps = (state: FinalState<T>, rowInfo: TypedRowInfo<T>, column: TypedColumn<T>) => {
		const { onRowClick } = this.props.tab;

		// onRowClick and onExpandChange don't like each other
		// add onRowClick event to the cell only if we have expander
		// add onRowClickEvent on every cell except expanders
		const addOnClickEvent = onRowClick && !!state.ExpanderComponent && !column.expander;

		return {
			onClick: addOnClickEvent
				? (event: React.MouseEvent<HTMLElement>) => {
					// ignore the row click action if clicked on settings-cell
					if (!event.currentTarget.className.includes('settings-cell')) {
						onRowClick(rowInfo);
					}
				}
				: undefined,
		};
	};

	getTheadThProps: ComponentPropsGetterC = (state: FinalState<T>, rowInfo: TypedRowInfo<T> | undefined) => {
		const { getHeaderClassModifiers: getHeaderClassName } = this.props.tab;

		const modifiers = getHeaderClassName ? getHeaderClassName(state, rowInfo) : [];

		return {
			className: bemBlock('indent-table', modifiers),
		};
	};

	onResizedChanged = (columns: Resize[]) => {
		const { accountId } = this.props;
		if (accountId) {
			this.setState(
				(state: State) => {
					if (!state.tableSettings) {
						return state;
					}
					const columnSettings = [...state.tableSettings.columnSettings];
					columns.forEach((_column) => {
						const index = state.tableSettings!.columnSettings.findIndex((_col) => _col.name === _column.id);
						columnSettings[index].width = _column.value;
					});
					return { tableSettings: { ...state.tableSettings, columnSettings } };
				},
				async () => {
					const { updateTableSettings } = this.props;
					await updateTableSettings(this.state.tableSettings!);
				}
			);
		}
	};

	// eslint-disable-next-line @typescript-eslint/member-ordering
	onResizedChangedDebounced = debounce(this.onResizedChanged, RESIZE_DELAY);

	onSortChanged = (sort: SortingRule[]): void => {
		const { accountId } = this.props;
		if (accountId) {
			this.setState(
				(state: State) => {
					if (!state.tableSettings) {
						return state;
					}
					return { tableSettings: { ...state.tableSettings, sort } };
				},
				async () => {
					const { updateTableSettings } = this.props;
					await updateTableSettings(this.state.tableSettings!);
				}
			);
		}
	};

	onPageSizeChange = (pageSize: number) => {
		const { accountId } = this.props;
		if (accountId) {
			this.setState(
				(state: State) => {
					if (!state.tableSettings) {
						return state;
					}
					return { tableSettings: { ...state.tableSettings, pageSize } };
				},
				async () => {
					const { updateTableSettings } = this.props;
					await updateTableSettings(this.state.tableSettings!);
				}
			);
		}
	};

	saveTableSettings = async (tableSettings: TableSettingsVM) => {
		const { accountId, updateTableSettings } = this.props;
		if (accountId) {
			this.setState(
				() => ({ tableSettings }),
				async () => await updateTableSettings(this.state.tableSettings!)
			);
		}
	};

	getTrGroupProps = (state: FinalState<T>, rowInfo: TypedRowInfo<T>) => {
		const { getRowGroupClassModifiers: getRowGroupClassModifiers } = this.props.tab;

		const modifiers = getRowGroupClassModifiers ? getRowGroupClassModifiers(state, rowInfo) : [];

		return {
			className: bemElement('indent-table', 'row-group', modifiers),
		};
	};
	// renders:

	refreshAction = async (action: (rowElement?: T) => Promise<void>, rowElement: T, shouldRefresh: boolean) => {
		await action(rowElement);
		if (shouldRefresh) {
			await this.refreshTable();
		}
	};

	renderLoadingIndicator = () => (
		<div className="infinite-scroll-container">
			<div className="loading-item">
				<LoadingIndicator color="orange" />
			</div>
		</div>
	);

	renderTable = () => {
		const {
			table,
			isLoaded,
			tab,
			SubComponent,
			indent,
			accountId,
			tableName,
			onMount,
		} = this.props;

		const {
			className,
			columns,
			rowActions,
			onExpandedChange,
		} = tab;

		const { tableSettings, showTableSettingsModal, isTableSettingsLoaded, expanded } = this.state;

		if (!isTableSettingsLoaded) {
			return null;
		}

		const reactTableProps = {
			className,
			ref: onMount,
			columns: Helpers.renderColumns(
				columns,
				table?.rows || undefined,
				isLoaded,
				null,
				rowActions,
				this.refreshAction,
				accountId,
				tableSettings!,
				this.openTableSettingsModal
			),
			data: Helpers.renderRows(table?.rows || [], isLoaded),
			getTrProps: this.getTrProps,
			getTdProps: this.getTdProps,
			showPagination: false,
			getTbodyProps: () => ({ className: bemElement('indent-table', 'body') }),
			getTheadThProps: this.getTheadThProps,
			getTrGroupProps: this.getTrGroupProps,
			SubComponent: SubComponent!,
			pages: table?.pages,
			defaultPageSize: tableSettings!.pageSize,
			onPageSizeChange: this.onPageSizeChange,
			onResizedChange: this.onResizedChangedDebounced,
			defaultSorted: tableSettings!.sort,
			onSortedChange: this.onSortChanged,
			onExpandedChange,
			expanded,
		};

		return (
			<div className={bemBlock('indent-table', [`indent-${indent}`])}>
				<ReactTable
					{...DEFAULT_REACT_TABLE_PROPS}
					{...reactTableProps}
				/>
				{showTableSettingsModal &&
					<TableSettingsModal<T>
						accountId={accountId}
						closeTableSettings={this.closeTableSettingsModal}
						columns={columns}
						onSave={this.saveTableSettings}
						show={showTableSettingsModal}
						tableName={tableName}
						tableSettings={tableSettings!}
					/>
				}
			</div>
		);
	};

	// eslint-disable-next-line @typescript-eslint/no-empty-function
	mockLoadNext = () => { };

	renderListRow = (_item: T, _index: number) => {
		const {
			tab: { columns, rowActions, onRowClick },
			SubComponent,
		} = this.props;

		return (
			<ScrollToLoadItem<T>
				columns={columns}
				index={_index}
				item={_item}
				key={_index}
				onRowClick={onRowClick}
				refreshList={this.refreshTable}
				rowActions={rowActions}
				SubComponent={SubComponent}
			/>
		);
	};

	renderList = () => {
		const {
			table: { rows },
			indent,
		} = this.props;

		return (
			<InfiniteScroll
				autoHeight={true}
				className={`indent indent--${indent}`}
				data={rows}
				fetch={this.mockLoadNext}
				renderItem={this.renderListRow}
				totalCount={rows.length}
			/>
		);
	};

	render() {
		const { tableType } = this.state;

		return tableType === TableTypeEnum.SCROLLABLE ? this.renderList() : this.renderTable();
	}
}

function mapStateToProps(state: RootState) {
	const { companyData } = state.user;
	if (!companyData) {
		throw new Error('User not logged in');
	}

	return {
		accountId: companyData.accountId,
	};
}

function mapDispatchToProps() {
	return {
		findTableSettings: TableSettingsActions.findTableSettings,
		updateTableSettings: TableSettingsActions.updateTableSettings,
	};
}
const connector = connect(mapStateToProps, mapDispatchToProps());

export default connector(IndentTable);
