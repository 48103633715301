import * as React from 'react';

import TimeFormat from 'acceligent-shared/enums/timeFormat';
import type SystemNotificationType from 'acceligent-shared/enums/systemNotificationType';

import * as TimeUtils from 'acceligent-shared/utils/time';

import Tooltip from 'af-components/Tooltip';

interface OwnProps {
	content: string;
	type: SystemNotificationType;
	startsAt: Date;
	endsAt: Date;
	id: number;
	deleteSystemNotification: (id: number) => void;
}

type Props = OwnProps;

class NotificationItem extends React.PureComponent<Props> {

	deleteSystemNotification = () => {
		const { id, deleteSystemNotification } = this.props;
		deleteSystemNotification(id);
	};

	render() {
		const { content, type, startsAt, endsAt } = this.props;
		return (
			<div className={`notification-${type.toLowerCase()}`}>
				<div className="notification-time">{TimeUtils.formatDate(startsAt, TimeFormat.FULL_DATE)} - {TimeUtils.formatDate(endsAt, TimeFormat.FULL_DATE)}</div>
				<div>
					{content}
				</div>
				<Tooltip message="Delete">
					<span className="icon-delete" onClick={this.deleteSystemNotification} />
				</Tooltip>
			</div>
		);
	}
}

export default NotificationItem;
