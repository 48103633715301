import * as React from 'react';
import type { CustomRouteComponentProps } from 'react-router-dom';
import { withRouter } from 'react-router-dom';
import type { ConnectedProps } from 'react-redux';
import { connect } from 'react-redux';
import { compose } from 'redux';

import BlobStorageImageSizeContainer from 'acceligent-shared/enums/blobStorageImageSizeContainer';

import type { RootState } from 'af-reducers';

import * as ItemActions from 'af-actions/item';

import type ItemTableViewModel from 'ab-viewModels/item/table.viewModel';

import CLIENT from 'af-constants/routes/client';

import { DEFAULT_EQUIPMENT_IMAGE } from 'ab-common/constants/value';

import TableNameEnum from 'ab-enums/tableName.enum';
import TableButtonType from 'ab-enums/tableButtonType.enum';
import PagePermissions from 'ab-enums/pagePermissions.enum';

import { isAllowed } from 'ab-utils/auth.util';

import type { TabProps, Column, ButtonData } from 'af-components/Table6';
import Table from 'af-components/Table6';
import ImageTag from 'af-components/Image';

import { VendorPackageTypeLabel } from 'acceligent-shared/enums/vendorPackageType';

import MonetaryCell from 'af-components/Table6/Cells/MonetaryCell';
import EmptyCell from 'af-components/Table6/Cells/EmptyCell';
import LabelWithColor from 'af-components/LabelWithColor';

type Props = CustomRouteComponentProps & ConnectedProps<typeof connector>;

const IMAGE_COLUMN_WIDTH = 100;

const ItemList = (props: Props) => {
	const {
		history,
		location: { state: { orgAlias } },
		companyName,
		findAllItems,
		deleteItem,
		hasManageAccessoriesPermission,
		bulkDelete,
	} = props;

	const COLUMNS: Column<ItemTableViewModel>[] = [
		{
			accessor: 'imageUrl',
			sortable: false,
			width: IMAGE_COLUMN_WIDTH,
			className: 'rt-thumbnail',
			Cell: ({ original }) => (
				<ImageTag
					fallbackSrc={DEFAULT_EQUIPMENT_IMAGE}
					minSize={BlobStorageImageSizeContainer.SIZE_50X50}
					src={original.imageUrl}
					tryOriginal={true}
					tryRoot={true}
				/>
			),
		},
		{
			Header: 'Model Number',
			accessor: 'modelNumber',
			Cell: ({ original }) => original.modelNumber,
		},
		{
			Header: 'Name',
			accessor: 'name',
			Cell: ({ original }) => original.name,
		},
		{
			Header: 'Type',
			accessor: 'packageType',
			Cell: ({ original }) => original.packageType ? VendorPackageTypeLabel[original.packageType] : 'N/A',
		},
		{
			Header: 'Crew Type',
			accessor: 'crewType',
			Cell: ({ original }) => original.crewType ? <LabelWithColor color={original.crewColor} text={original.crewType} /> : <EmptyCell />,
		},
		{
			Header: 'Price',
			accessor: 'price',
			Cell: MonetaryCell,
		},
		{
			Header: 'Current Stock',
			accessor: 'currentStock',
			Cell: ({ original }) => original.currentStock,
			sortable: false,
		},
		{
			Header: 'Category',
			accessor: 'itemCategory',
			sortable: false,
			Cell: ({ original }) => original.itemCategory,
		},
	];

	const deleteItemModalTitle = () => 'Delete Item?';
	const deleteItemModalBody = (original: ItemTableViewModel) => `Are you sure you want to delete Item (${original.name})?`;
	const deleteItemModalText = () => 'Delete Item';

	const removeItem = async (original: ItemTableViewModel) => {
		await deleteItem(original.id);
	};

	const editItem = async (original: ItemTableViewModel) => {
		history.push(CLIENT.COMPANY.EQUIPMENT.ITEM.EDIT(original.id.toString(), orgAlias, companyName));
	};

	const onRowClick = ({ original }: { original: ItemTableViewModel; }) => {
		if (original.id && hasManageAccessoriesPermission) {
			editItem(original);
		}
	};

	const tabs = (): TabProps<ItemTableViewModel>[] => {
		const buttons: ButtonData[] = hasManageAccessoriesPermission ? [
			{
				type: TableButtonType.PRIMARY,
				hasPermission: true,
				label: 'New Accessory',
				icon: 'plus',
				onClick: async () => history.push(CLIENT.COMPANY.EQUIPMENT.ITEM.CREATE(orgAlias, companyName)),
			},
		] : [];

		return [
			{
				label: 'Item',
				columns: COLUMNS,
				selectable: hasManageAccessoriesPermission,
				hasSearchInput: true,
				searchLabel: 'Item',
				fetch: findAllItems,
				buttons,
				bulkDelete: hasManageAccessoriesPermission ? bulkDelete : undefined,
				onRowClick: onRowClick,
				rowActions: hasManageAccessoriesPermission ? [
					{
						label: 'Edit',
						action: editItem,
						shouldRefresh: false,
					},
					{
						label: 'Delete',
						action: removeItem,
						hasModal: true,
						modalTitle: deleteItemModalTitle,
						modalBody: deleteItemModalBody,
						modalText: deleteItemModalText,
						shouldRefresh: true,
					},
				] : undefined,
			},
		];
	};

	return (
		<Table
			tableName={TableNameEnum.ITEM}
			tabs={tabs()}
		/>
	);
};

function mapStateToProps(state: RootState) {
	const { userData, companyData } = state.user;
	if (!userData || !companyData) {
		throw new Error('User not logged in');
	}

	const hasManageAccessoriesPermission: boolean = isAllowed(
		PagePermissions.COMPANY.EQUIPMENT.ACCESSORIES.MANAGE,
		companyData.permissions,
		companyData.isCompanyAdmin,
		userData.role
	);

	return {
		companyName: companyData.name,
		hasManageAccessoriesPermission,
	};
}

function mapDispatchToProps() {
	return {
		findAllItems: ItemActions.findAllForTable,
		deleteItem: ItemActions.deleteItem,
		bulkDelete: ItemActions.bulkDeleteItems,
	};
}

const connector = connect(mapStateToProps, mapDispatchToProps());

const enhance = compose(withRouter, connector);

export default enhance(ItemList);
