import * as React from 'react';
import type { FieldIterate, WrappedFieldArrayProps } from 'redux-form';

import { MAX_BILLABLE_WORK_DEFINITION_FIELDS } from 'ab-common/constants/value';

import DefinitionFieldField from './DefinitionFieldField';
import { BillableWorkDefinitionFieldFormModel } from './FormModel';
import type { BillableWorkDropdownOption } from './types';

interface OwnProps {
	options: BillableWorkDropdownOption[];
	selectedDefinitionFields: Set<Nullable<string>>;
	onChange: (fieldName: string) => (field: BillableWorkDropdownOption) => void;
}

export type BillableWorkDefinitionFieldFieldProps = OwnProps;

type Props = OwnProps & WrappedFieldArrayProps<BillableWorkDefinitionFieldFormModel>;

const BillableWorkDefinitionFieldsSection: React.FC<Props> = (props: Props) => {
	const { options, fields, selectedDefinitionFields, onChange } = props;

	const handleAddDefinitionField = React.useCallback(() => {
		fields.push(BillableWorkDefinitionFieldFormModel.constructorMap());
	}, [fields]);

	const handleDeleteField = React.useCallback((index: number) => {
		fields.remove(index);
	}, [fields]);

	const renderField = React.useCallback<FieldIterate<BillableWorkDefinitionFieldFormModel>>((field, index) => {
		const currentField = fields.get(index).reportBlockFieldVirtualId;
		const adjustedOptions: BillableWorkDropdownOption[] = [];
		if (selectedDefinitionFields.has(currentField!)) {
			for (const _rbf of options) {
				if (selectedDefinitionFields.has(_rbf.virtualId) && currentField !== _rbf.virtualId) {
					continue;
				}
				adjustedOptions.push(_rbf);
			}
		}

		return (
			<DefinitionFieldField
				fieldName={field}
				index={index}
				key={field}
				onChange={onChange}
				onDelete={handleDeleteField}
				options={adjustedOptions.length ? adjustedOptions : options}
				valueKey="virtualId"
			/>
		);
	}, [fields, handleDeleteField, onChange, options, selectedDefinitionFields]);

	return (
		<div className="billable-work-modal__definition-fields-section">
			<div className="billable-work-modal__definition-fields-section__title">DEFINITION FIELDS</div>
			<>{fields.map(renderField)}</>
			{fields.length < MAX_BILLABLE_WORK_DEFINITION_FIELDS && (
				<div className="billable-work-modal__definition-fields-section__add-action" onClick={handleAddDefinitionField}>
					<span className="icon-plus" />
					<span>Add Definition Field</span>
				</div>
			)}
		</div>
	);
};

export default React.memo(BillableWorkDefinitionFieldsSection);
