import type { Dispatch, AnyAction } from 'redux';

import type { FieldWorkClassificationCodeCreateRM } from 'ab-requestModels/fieldWorkClassificationCode/upsert';

import type FieldWorkClassificationCodePreviewVM from 'ab-viewModels/fieldWorkClassificationCode/preview';
import type FieldWorkClassificationCodeTableVM from 'ab-viewModels/fieldWorkClassificationCode/table';
import type FieldWorkClassificationCodeListItemVM from 'ab-viewModels/fieldWorkClassificationCode/listItem';

import * as UpdateFieldWorkClassificationCodeAPI from 'ab-api/web/fieldWorkClassificationCode/update';

import { TableQuery } from 'ab-common/dataStructures/tableQuery';

import type { GetRootState } from 'af-reducers';

import API from 'af-constants/routes/api';

import { http } from 'af-utils/http.util';
import { errorHandler } from 'af-utils/actions.util';

export function create(requestModel: FieldWorkClassificationCodeCreateRM) {
	return async (dispatch: Dispatch<AnyAction>, getState: GetRootState, { redirectTo }): Promise<void> => {
		const action = async () => {
			await http.post<void>(API.V1.RESOURCES.FIELD_WORK_CLASSIFICATION_CODE.CREATE, requestModel);
		};
		return await errorHandler(action, dispatch, redirectTo);
	};
}

export function findById(id: number) {
	return async (dispatch: Dispatch<AnyAction>, getState: GetRootState, { redirectTo }): Promise<FieldWorkClassificationCodePreviewVM | undefined> => {
		const action = async () => {
			return await http.get<FieldWorkClassificationCodePreviewVM>(API.V1.RESOURCES.FIELD_WORK_CLASSIFICATION_CODE.FIND_BY_ID(id.toString()));
		};
		return await errorHandler(action, dispatch, redirectTo);
	};
}

export function findAllForCompanyTable(tableRequestModel: TableQuery) {
	return async (dispatch: Dispatch<AnyAction>, getState: GetRootState, { redirectTo }): Promise<FieldWorkClassificationCodeTableVM | undefined> => {
		const action = async () => {
			return await http.get<FieldWorkClassificationCodeTableVM>(
				API.V1.RESOURCES.FIELD_WORK_CLASSIFICATION_CODE.TABLE(new TableQuery(tableRequestModel))
			);
		};
		return await errorHandler(action, dispatch, redirectTo);
	};
}

export function findAllForCompanyList() {
	return async (dispatch: Dispatch<AnyAction>, getState: GetRootState, { redirectTo }): Promise<FieldWorkClassificationCodeListItemVM[] | undefined> => {
		const action = async () => {
			return await http.get<FieldWorkClassificationCodeListItemVM[]>(
				API.V1.RESOURCES.FIELD_WORK_CLASSIFICATION_CODE.LIST()
			);
		};
		return await errorHandler(action, dispatch, redirectTo);
	};
}

export function update(id: number, requestModel: UpdateFieldWorkClassificationCodeAPI.W_FieldWorkClassificationCode_Update_RM) {
	return async (dispatch: Dispatch<AnyAction>, getState: GetRootState, { redirectTo }): Promise<void> => {
		const action = async () => {
			await http.put<void>(UpdateFieldWorkClassificationCodeAPI.URL(id), requestModel);
		};
		return await errorHandler(action, dispatch, redirectTo);
	};
}

export function deleteSingle(id: number) {
	return async (dispatch: Dispatch<AnyAction>, getState: GetRootState, { redirectTo }): Promise<void> => {
		const action = async () => {
			await http.delete<void>(API.V1.RESOURCES.FIELD_WORK_CLASSIFICATION_CODE.DELETE(id.toString()));
		};
		return await errorHandler(action, dispatch, redirectTo);
	};
}

export function deleteMultiple(ids: number[]) {
	return async (dispatch: Dispatch<AnyAction>, getState: GetRootState, { redirectTo }): Promise<void> => {
		const action = async () => {
			await http.delete<void>(API.V1.RESOURCES.FIELD_WORK_CLASSIFICATION_CODE.BULK_DELETE(ids));
		};
		return await errorHandler(action, dispatch, redirectTo);
	};
}
