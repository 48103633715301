import * as React from 'react';
import { useOutletContext } from 'react-router-dom-v5-compat';

import Table from 'af-components/Table6';

import TableNameEnum from 'ab-enums/tableName.enum';

import type { JobPreviewOutletContext } from './types';

const RelatedWorkOrdersTab: React.FC = () => {
	const { onTableMount, relatedWorkOrdersTableTabs } = useOutletContext<JobPreviewOutletContext>();
	return (
		<Table
			hideTabs={true}
			onMount={onTableMount}
			tableName={TableNameEnum.JOB_WORK_ORDERS}
			tabs={relatedWorkOrdersTableTabs}
		/>
	);
};

export default React.memo(RelatedWorkOrdersTab);
