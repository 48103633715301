import * as React from 'react';

import TimeFormat from 'acceligent-shared/enums/timeFormat';

import * as TimeUtils from 'acceligent-shared/utils/time';

import type { FieldReportListItemVM, FieldReportTypeVM } from 'ab-viewModels/workOrder/workOrderFieldReportCard.viewModel';

import FieldReportType from './FieldReportType';

interface OwnProps {
	fieldReport: FieldReportListItemVM;
	index: number;
}

type Props = OwnProps;

const FieldReport: React.FC<Props> = (props) => {
	const { fieldReport: { code, updatedAt, updateByFullName, fieldReportTypes }, index } = props;

	const renderFieldReportType = (fieldReportType: FieldReportTypeVM, fieldReportIndex: number) => {
		return (
			<FieldReportType
				fieldReportType={fieldReportType}
				index={fieldReportIndex}
				key={fieldReportIndex}
			/>
		);
	};

	return (
		<div className="report-submit-modal__section__list-item" key={index}>
			<div className="report-submit-modal__field-report-title">
				{code}
			</div>
			<div className="report-submit-modal__field-report-info">
				{`Updated: ${TimeUtils.formatDate(updatedAt, TimeFormat.FULL_DATE_LONG, TimeFormat.ISO_DATETIME)} by: ${updateByFullName}`}
			</div>
			<div className="report-submit-modal__field-report-type-list">
				{fieldReportTypes.map(renderFieldReportType)}
			</div>
		</div>
	);
};

export default React.memo(FieldReport);
