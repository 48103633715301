import * as React from 'react';
import type { ConnectedProps } from 'react-redux';
import { connect } from 'react-redux';
import type { CellContext } from '@tanstack/react-table';

import BlobStorageImageSizeContainer from 'acceligent-shared/enums/blobStorageImageSizeContainer';
import TimeFormatEnum from 'acceligent-shared/enums/timeFormat';
import MemberInviteEnum from 'acceligent-shared/enums/memberInvite';

import type { RootState } from 'af-reducers';

import * as CompanyActions from 'af-actions/companies';

import type { OrganizationMemberVM } from 'ab-viewModels/organization/member.viewModel';

import { DEFAULT_EMPLOYEE_IMAGE } from 'ab-common/constants/value';

import TableNameEnum from 'ab-enums/tableName.enum';
import TableButtonType from 'ab-enums/tableButtonType.enum';

import DateCell from 'af-components/Table6/Cells/DateCell';
import type { TableRef } from 'af-components/Table';
import TableNew from 'af-components/Table';
import ImageTag from 'af-components/Image';
import Breadcrumbs from 'af-components/Breadcrumbs';
import StatusCell from 'af-components/Table6/Cells/StatusCell';
import CompanyStatusCell from 'af-components/Table6/Cells/CompanyStatusCell';
import EmptyCell from 'af-components/Table/Cells/EmptyCell';
import type { TableProps } from 'af-components/Table/types';
import ActionsCell from 'af-components/Table/Cells/ActionsCell';

import { formatPhoneNumber } from 'ab-utils/phone.util';

import { downloadCSV } from 'af-utils/csv.utils';

type Props = ConnectedProps<typeof connector>;

const BREADCRUMBS = [{ label: 'Users' }];

const deleteUserModalTitle = (original: OrganizationMemberVM) => (`Are you sure you want to delete this User for organization (${original.fullName})?`);
const deleteUserModalBody = () => (<>This action cannot be undone. </>);
const deleteUserModalText = () => ('Delete User');

const OrganizationMembers: React.FC<Props> = (props) => {
	const {
		getAllOrganizationMembersCSV,
		organizationName,
		deleteUserAction,
		getMembersForOrganization,
	} = props;

	const tableRef = React.useRef<TableRef<OrganizationMemberVM>>(null);

	const deleteUser = React.useCallback(async (original: OrganizationMemberVM) => {
		await deleteUserAction(original.userId);
	}, [deleteUserAction]);

	const resolveActionsButton = React.useCallback((_cell: CellContext<OrganizationMemberVM, unknown>) => {
		const actions = [
			{
				onClick: () => deleteUser(_cell.row.original),
				label: 'Deactivate for Organization',
				hasModal: true,
				modalTitle: deleteUserModalTitle,
				modalBody: deleteUserModalBody,
				modalText: deleteUserModalText,
				shouldRefresh: true,
				hide: (original: OrganizationMemberVM) => original.status === MemberInviteEnum.DELETED,
			},
		];

		return (
			<ActionsCell
				id="actions"
				isActionDropdown={true}
				labelKey="label"
				options={actions}
				valueKey="label"
			/>
		);
	}, [deleteUser]);

	const columns: TableProps<OrganizationMemberVM>['columns'] = React.useMemo(() => [
		{
			id: 'image',
			accessor: 'imageUrl',
			enableSorting: false,
			header: () => <EmptyCell isHeader />,
			size: 100,
			cell: ({ cell }) => (
				<div className="rt-thumbnail">
					<ImageTag
						fallbackSrc={DEFAULT_EMPLOYEE_IMAGE}
						minSize={BlobStorageImageSizeContainer.SIZE_50X50}
						src={cell.row.original.imageUrl}
						tryOriginal={true}
						tryRoot={true}
					/>
				</div>
			),
		},
		{
			id: 'uniqueId',
			header: 'Unique ID',
			accessor: 'uniqueId',
			enableSorting: true,
			cell: ({ cell }) => <div className="text-strong">
				{cell.row.original.uniqueId}
			</div>,
		},
		{
			id: 'fullName',
			header: 'Full Name',
			accessor: 'fullName',
			enableSorting: true,
			cell: ({ cell }) => <div className="text-strong">{cell.row.original.fullName}</div>,
		},
		{
			id: 'email',
			header: 'Email Address',
			accessor: 'email',
			enableSorting: true,
			cell: ({ cell }) => cell.row.original.email ?? <EmptyCell />,
		},
		{
			id: 'phone',
			header: 'Mobile Phone',
			accessor: 'phoneNumber',
			enableSorting: true,
			cell: ({ cell }) => cell.row.original.phoneNumber
				? formatPhoneNumber(cell.row.original.phoneNumber, cell.row.original.countryCode)
				: <EmptyCell />,
		},
		{
			id: 'lastLoginAt',
			header: 'Last Login',
			accessor: 'lastLoginAt',
			enableSorting: true,
			cell: ({ cell }) => <DateCell date={cell.row.original.lastLoginAt} format={TimeFormatEnum.DATE_ONLY} isLeftAligned={true} withTime={true} />,
		},
		{
			id: 'status',
			header: 'Status',
			accessor: 'status',
			enableSorting: true,
			cell: ({ cell }) => <StatusCell {...cell.row.original} />,
		},
		{
			id: 'companyStatus',
			header: 'Company Status',
			accessor: 'companyStatus',
			enableSorting: false,
			cell: ({ cell }) => <CompanyStatusCell companyStatus={cell.row.original.companyStatus} />,
		},
		{
			id: 'activeInLMS',
			header: 'LMS access',
			accessor: 'activeInLMS',
			enableSorting: true,
			cell: ({ cell }) => {
				if (cell.row.original.activeInLMS) {
					return (<span className="text-green">Yes</span>);
				}
				return (<span className="text-grey">No</span>);
			},
		},
		{
			id: 'actions',
			isDisplayColumn: true,
			header: () => <EmptyCell isHeader />,
			cell: resolveActionsButton,
		},
	], [resolveActionsButton]);

	const onDownloadCSVClick = React.useCallback(async () => {
		const tableQuery = tableRef.current!.getTableQuery();
		const members = await getAllOrganizationMembersCSV(tableQuery);
		const data = members.map((_member) => [
			_member.uniqueId,
			_member.fullName,
			_member.email ?? '',
			_member.phoneNumber ?? '',
			_member.role,
			_member.status,
		]);
		data.unshift([
			'uniqueId',
			'fullName',
			'email',
			'phoneNumber',
			'role',
			'status',
		]);
		downloadCSV(data, `${organizationName}_users.csv`);
	}, [getAllOrganizationMembersCSV, organizationName]);

	const buttons = React.useMemo(() => {
		return [
			{
				type: TableButtonType.EXPORT,
				hasPermission: true,
				onClick: onDownloadCSVClick,
				label: 'Export as CSV',
			},
		];
	}, [onDownloadCSVClick]);

	return (
		<div className="form-segment form-segment--maxi">
			<Breadcrumbs items={BREADCRUMBS} />
			<TableNew
				buttons={buttons}
				columns={columns}
				fetch={getMembersForOrganization}
				hasSearchInput={true}
				hasSubRows={false}
				ref={tableRef}
				searchLabel="Members"
				selectable={true}
				tableName={TableNameEnum.ORGANIZATION_MEMBERS}
			/>
		</div>
	);
};

function mapStateToProps(state: RootState) {
	const { organizationData } = state.user;
	if (!organizationData) {
		throw new Error('User not logged in');
	}

	return {
		organizationName: organizationData.name,
	};
}

function mapDispatchToProps() {
	return {
		getAllOrganizationMembersCSV: CompanyActions.getMembersForOrganizationCSV,
		deleteUserAction: CompanyActions.deleteUser,
		getMembersForOrganization: CompanyActions.getMembersForOrganization,
	};
}

const connector = connect(mapStateToProps, mapDispatchToProps());

export default connector(OrganizationMembers);
