import * as React from 'react';
import { Row, Col } from 'react-bootstrap';

import { formatPhoneNumber } from 'acceligent-shared/utils/phone';

import type AgencyVM from 'ab-viewModels/agency/agency.viewModel';
import type TemporaryEmployeeVM from 'ab-viewModels/temporaryEmployee/temporaryEmployee.viewModel';

import LockedValue from 'af-components/LockedValue';

import CLIENT from 'af-routes/client';

import ResourcePreview from '../../Shared/ResourcePreview';

interface OwnProps {
	orgAlias: string;
	companyName: string;
	agency: Nullable<AgencyVM>;
	employee: Nullable<TemporaryEmployeeVM>;
}

type Props = OwnProps;

const TemporaryEmployee: React.FC<Props> = ({ orgAlias, companyName, agency, employee }) => {

	const breadcrumbs = React.useMemo(() => {
		return [
			{ label: 'Temporary Labor', url: CLIENT.COMPANY.RESOURCES.TEMPORARY_EMPLOYEE.LIST(orgAlias, companyName) },
			{ label: 'Preview' },
		];
	}, [orgAlias, companyName]);

	if (!employee || !agency) return null;

	return (
		<ResourcePreview
			backToListLabel="Back to Temporary Labor List"
			backToListUrl={CLIENT.COMPANY.RESOURCES.TEMPORARY_EMPLOYEE.LIST(orgAlias, companyName)}
			breadcrumbs={breadcrumbs}
			editUrl={
				agency && !agency.isDeleted && employee
					? CLIENT.COMPANY.RESOURCES.TEMPORARY_EMPLOYEE.EDIT(orgAlias, companyName, `${employee.id}`)
					: null
			}
		>
			<Row className="row--padded-top">
				<Col md={4} ><LockedValue label="First Name" value={employee.firstName} /></Col>
				<Col md={4} ><LockedValue label="Last Name" value={employee.lastName} /></Col>
			</Row>
			<Row className="row--padded-top">
				<Col md={4} ><LockedValue label="Email" value={employee.email} /></Col>
				<Col md={4} ><LockedValue label="Phone Number" value={employee.phoneNumber ? formatPhoneNumber(employee.phoneNumber) : '-'} /></Col>
			</Row>
			<Row className="row--padded-top">
				<Col md={4} ><LockedValue label="Agency" value={agency.name} /></Col>
			</Row>
		</ResourcePreview>
	);
};

export default React.memo(TemporaryEmployee);
