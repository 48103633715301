import AccountPermission from 'acceligent-shared/enums/accountPermission';

import { LABEL, IMPLICATIONS } from '../constants';

type DescriptionCategory = 'GENERAL' | 'MOBILE' | 'WEB';
type DescriptionSubCategory = { [K in DescriptionCategory]: readonly string[] };
type DescriptionsLookup = { [permission in AccountPermission]: DescriptionSubCategory };

const _getLabel = (permission: AccountPermission) => LABEL[permission];

function _getImplicationDescription(permission: AccountPermission): string[] {
	const implication = IMPLICATIONS[permission];

	if (!implication?.length) {
		return [];
	}
	if (implication.length === 1) {
		return [`Includes "${LABEL[implication[0]]}"`];
	}
	if (implication.length === 2) {
		return [`Includes "${LABEL[implication[0]]}" and "${LABEL[implication[1]]}"`];
	}
	const allLabelsButLast = implication.slice(0, implication.length - 1).map(_getLabel);
	const lastLabel = LABEL[implication[implication.length - 1]];

	return [`Includes "${allLabelsButLast.join('", "')}" and "${lastLabel}"`];
}

function _getFTDDescription(description: string, ftdKey: string) {
	const isDisabled = process.env[ftdKey] === 'true';
	return `${description}${isDisabled ? ' [DISABLED]' : ''}`;
}

const descriptionsWithoutImplications: Readonly<DescriptionsLookup> = {
	[AccountPermission.UA_MANAGE]: {
		GENERAL: [],
		MOBILE: [],
		WEB: [
			'Invite, add, or reactivate users to company',
			'Edit account data (excluding password) of any company user',
			'Edit permissions of any company user',
			'Delete any user from company',
		],
	},
	[AccountPermission.UA_READ]: {
		GENERAL: [],
		MOBILE: [],
		WEB: [
			'Access Users table',
		],
	},
	[AccountPermission.UG_MANAGE]: {
		GENERAL: [],
		MOBILE: [],
		WEB: [
			'Access User Groups table',
			'Create, edit, or delete user groups',
		],
	},
	[AccountPermission.LG_VIEW]: {
		GENERAL: [],
		MOBILE: [],
		WEB: [
			'Access and download Company Logs',
		],
	},
	[AccountPermission.NTFY_MANAGE]: {
		GENERAL: [],
		MOBILE: [],
		WEB: [
			'Access the Notifications (Settings) page',
			'Change notification settings',
		],
	},
	[AccountPermission.RS_WAGERATE_MANAGE]: {
		GENERAL: [],
		MOBILE: [],
		WEB: [
			'Access the Wage Rate page',
			'Create, edit, or delete wage rates',
		],
	},
	[AccountPermission.RS_FIELD_WORK_CLASSIFICATION_CODE_MANAGE]: {
		GENERAL: [],
		MOBILE: [],
		WEB: [
			'Access the Classification Code page',
			'Create, edit, or delete classification codes',
		],
	},
	[AccountPermission.RS_EMPLOYEE_MANAGE]: {
		GENERAL: [],
		MOBILE: [],
		WEB: [
			'Access the Labor page',
			'Create, edit, or delete employees',
			'View employee Status History',
		],
	},
	[AccountPermission.RS_EMPLOYEE_STATUS_MANAGE]: {
		GENERAL: [],
		MOBILE: [],
		WEB: [
			'Access the Employee Status page',
			'Create, edit, or delete employee statuses',
		],
	},
	[AccountPermission.RS_EQUIPMENTCOST_MANAGE]: {
		GENERAL: [],
		MOBILE: [],
		WEB: [
			'Access the Equipment Cost page',
			'Create, edit, or delete equipment costs (groups, categories and subcategories)',
		],
	},
	[AccountPermission.RS_EQUIPMENT_MANAGE]: {
		GENERAL: [],
		MOBILE: [],
		WEB: [
			'Access the Equipment page',
			'Create, edit, or delete equipment',
			'View equipment Status History',
		],
	},
	[AccountPermission.RS_SMALL_TOOL_MANAGE]: {
		GENERAL: [],
		MOBILE: [],
		WEB: [
			'Access the Small Tools page',
			'Create, edit, or delete small tools',
		],
	},
	[AccountPermission.RS_SMALL_TOOL_VIEW]: {
		GENERAL: [],
		MOBILE: [],
		WEB: [
			'Access the Small Tools page',
		],
	},
	[AccountPermission.RS_ACCESSORY_MANAGE]: {
		GENERAL: [],
		MOBILE: [],
		WEB: [
			'Access the Accessories page',
			'Create, edit, or delete accessories',
		],
	},
	[AccountPermission.RS_ACCESSORY_VIEW]: {
		GENERAL: [],
		MOBILE: [],
		WEB: [
			'Access the Accessories page',
		],
	},
	[AccountPermission.TR_MANAGE]: {
		GENERAL: [],
		MOBILE: [],
		WEB: [
			'Access the Tool Repair Page',
			'Manage Tool Repair requests',
		],
	},
	[AccountPermission.TR_CREATE]: {
		GENERAL: [],
		MOBILE: [],
		WEB: [
			'Access the Tool Repair Page',
			'Create Tool Repair requests',
		],
	},
	[AccountPermission.OR_CREATE]: {
		GENERAL: [],
		MOBILE: [],
		WEB: [
			'Access the Order Page',
			'Create orders',
		],
	},
	[AccountPermission.OR_MANAGE]: {
		GENERAL: [],
		MOBILE: [],
		WEB: [
			'Access the Order Page',
			'Manage orders',
		],
	},
	[AccountPermission.PO_CREATE]: {
		GENERAL: [],
		MOBILE: [],
		WEB: [
			'Access the Purchase Order Page',
			'Create Purchase Order',
			'View Purchase Orders you created',
			'Submit Purchase Orders you created',
		],
	},
	[AccountPermission.PO_MANAGE]: {
		GENERAL: [],
		MOBILE: [],
		WEB: [
			'Access the Purchase Order Page',
			'Create Purchase Order',
			'View Purchase Orders you created',
			'Manage Purchase Orders you created',
		],
	},
	[AccountPermission.PO_VIEW_AND_MANAGE_ALL]: {
		GENERAL: [],
		MOBILE: [],
		WEB: [
			'Access the Purchase Order Page',
			'Create Purchase Order',
			'Manage All Purchase Order',
			'View All Purchase Orders',
		],
	},
	[AccountPermission.RS_EQUIPMENT_STATUS_MANAGE]: {
		GENERAL: [],
		MOBILE: [],
		WEB: [
			'Access the Equipment Status page',
			'Create, edit, or delete equipment statuses',
		],
	},
	[AccountPermission.RS_SKILL_MANAGE]: {
		GENERAL: [],
		MOBILE: [],
		WEB: [
			'Access the Skill page',
			'Create, edit, or delete skills',
		],
	},
	[AccountPermission.RS_CREWTYPE_MANAGE]: {
		GENERAL: [],
		MOBILE: [],
		WEB: [
			'Access the Crew Type page',
			'Create, edit, or delete crew types',
		],
	},
	[AccountPermission.RS_LOCATION_MANAGE]: {
		GENERAL: [],
		MOBILE: [],
		WEB: [
			'Access the Location page',
			'Create, edit, or delete locations',
		],
	},
	[AccountPermission.RS_DOCUMENT_MANAGE]: {
		GENERAL: [],
		MOBILE: [],
		WEB: [
			'Access the Document page',
			'Create, edit, or delete documents',
		],
	},
	[AccountPermission.RS_DIVISION_MANAGE]: {
		GENERAL: [],
		MOBILE: [],
		WEB: [
			'Access the Division page',
			'Create, edit, or delete divisions',
		],
	},
	[AccountPermission.RS_SALE_MANAGE]: {
		GENERAL: [],
		MOBILE: [],
		WEB: [
			'Access the Sale page',
			'Create, edit, or delete sales',
		],
	},
	[AccountPermission.RS_DELIVERABLE_MANAGE]: {
		GENERAL: [],
		MOBILE: [],
		WEB: [
			'Access the Deliverables page',
			'View and edit deliverables',
			'View and edit deliverable submissions',
			'Assign employees to deliverable submissions',
			'Add comments to deliverables and deliverable submissions',
			'Access the Deliverable Resource page',
			'Create, edit, or delete data statuses',
			'Create, edit, or delete job statuses',
			'Create, edit, or delete deliverable data (software, code standard, file format, delivery method, delivery timeline)',
		],
	},
	[AccountPermission.MV_VIEW]: {
		GENERAL: [
			'Access the Mechanic View page',
		],
		MOBILE: [],
		WEB: [],
	},
	[AccountPermission.JB_MANAGE]: {
		GENERAL: [
			'View, edit, or create job',
		],
		MOBILE: [],
		WEB: [
			'Access the Jobs page',
			'Finish and unfinish jobs',
		],
	},
	[AccountPermission.JB_MANAGE_DELIVERABLE]: {
		GENERAL: [],
		MOBILE: [],
		WEB: [
			'Access the Jobs page',
			'Access Job Preview page',
			'Edit deliverable segment of Job Form',
		],
	},
	[AccountPermission.JB_MANAGE_BILLING_CODES]: {
		GENERAL: [],
		MOBILE: [],
		WEB: [
			'Access the Jobs page',
			'Access Job Preview page',
			'Edit billing codes segment of Job Form',
		],
	},
	[AccountPermission.JB_MANAGE_PROJECTS]: {
		GENERAL: [],
		MOBILE: [],
		WEB: [
			'Turn Jobs to Projects',
			'Associate Projects and Sub-jobs',
		],
	},
	[AccountPermission.JB_MANAGE_WORK_SUMMARY]: {
		GENERAL: [],
		MOBILE: [],
		WEB: [
			'Access the Work Summary tab on Job',
		],
	}, [AccountPermission.JB_MANAGE_INVOICES]: {
		GENERAL: [],
		MOBILE: [],
		WEB: [
			'Access the Invoices tab on Job',
		],
	},
	[AccountPermission.WO_MANAGE]: {
		GENERAL: [
			'View, edit or create work orders',
			'Delete draft work orders',
			'Publish or cancel work orders',
			'Copy work orders',
			'Pause and resume work orders',
			'Notify work order participants (if notifications enabled)',
			'View and create Daily Tip Message (if notifications enabled)',
		],
		MOBILE: [],
		WEB: [
			'View and download Work Order Confirmation',
			'View and create Daily Per Diem Tip Message (if notifications enabled)',
		],
	},
	[AccountPermission.SB_VIEW]: {
		GENERAL: [
			'Access the Schedule Board page',
			'Send Schedule Board template',
		],
		MOBILE: [],
		WEB: [
			'View and download Work Order Confirmation',
		],
	},
	[AccountPermission.PROD_DATA_VIEW]: {
		GENERAL: [],
		MOBILE: [],
		WEB: [
			'View Production Data on Work Order',
			'Enables "Export week as XSLS (calculations)" menu option on Schedule Board',
			'Enables "Export week as PDF (calculations)" menu option on Schedule Board',
		],
	},
	[AccountPermission.PROD_DATA_EDIT]: {
		GENERAL: [],
		MOBILE: [],
		WEB: [
			'Edit Production Data on Work Order',
			'Enables "Edit Production Data" menu option on Schedule Board',
		],
	},
	[AccountPermission.PD_MANAGE]: {
		GENERAL: [
			'Edit Per Diem of Work Order Employee',
		],
		MOBILE: [],
		WEB: [
			'Enables "Add Per Diem to WOs" menu option on Schedule Board',
			'Access the Accounting page',
		],
	},
	[AccountPermission.WO_SETTINGS]: {
		GENERAL: [],
		MOBILE: [],
		WEB: [
			'Access the Work Order (Settings) page',
		],
	},
	[AccountPermission.SB_SETTINGS]: {
		GENERAL: [],
		MOBILE: [],
		WEB: [
			'Access the Schedule Board (Settings) page',
		],
	},
	[AccountPermission.WR_MANAGE]: {
		GENERAL: [],
		MOBILE: [],
		WEB: [
			_getFTDDescription('Access the Work Request page', 'FTD_WORK_REQUEST'),
			'Manage work requests',
		],
	},
	[AccountPermission.FR_ACCOUNTING_MANAGE]: {
		GENERAL: [
			'Manage Field Report accounting approval process',
		],
		MOBILE: [],
		WEB: [
			'Access the Accounting page',
			'Export Report data as CSV',
		],
	},
	[AccountPermission.FR_MANAGEMENT_MANAGE]: {
		GENERAL: [
			'Manage Field Report management approval process',
		],
		MOBILE: [],
		WEB: [],
	},
	[AccountPermission.FR_SUPERINTENDENT_UNASSIGNED_MANAGE]: {
		GENERAL: [
			'Manage Field Report superintendent approval process on all Field reports',
		],
		MOBILE: [],
		WEB: [],
	},
	[AccountPermission.FR_ORDER]: {
		GENERAL: [
			'Reorder Report Types on Field Report',
		],
		MOBILE: [],
		WEB: [],
	},
	[AccountPermission.FR_FILL]: {
		GENERAL: [
			'Access the Field Reports page',
			'See all assigned work orders on Field Reports page',
			'Users with Superintendent role can create new field reports',
			'Add, remove, or reorder report types on field report',
			'Submit field report for approval',
			'Fill out time sheets',
			'Users with Superintendent role can delete field reports',
			'Users with Superintendent role can approve or reject field report',
		],
		MOBILE: [],
		WEB: [
			'Submit field report to the customer',
			'Sign customer printout locally',
			'Leave and resolve comments',
		],
	},
	[AccountPermission.FR_VIEW_ALL]: {
		GENERAL: [
			'Access the Field Reports page',
			'View all work orders on Field Reports page, even if not assigned',
		],
		MOBILE: [],
		WEB: [],
	},
	[AccountPermission.FR_SI_SKIP_MANAGEMENT]: {
		GENERAL: [
			'Allow Superintendents to skip management level when submitting reports for review',
		],
		MOBILE: [],
		WEB: [],
	},
	[AccountPermission.FR_FINALIZE]: {
		GENERAL: [
			'Allow user to finalize reports',
		],
		MOBILE: [],
		WEB: [],
	},
	[AccountPermission.FR_EDIT_IN_FINALIZING]: {
		GENERAL: [
			'Allow User to edit Field Report and Work Summary until Final State',
		],
		MOBILE: [],
		WEB: [],
	},
	[AccountPermission.RB_MANAGE]: {
		GENERAL: [],
		MOBILE: [],
		WEB: [
			'Access the Field Report Elements (Settings) page',
			'Create, edit, or delete report blocks',
		],
	},
	[AccountPermission.RT_MANAGE]: {
		GENERAL: [],
		MOBILE: [],
		WEB: [
			'Access the Field Report Elements (Settings) page',
			'Create, edit, or delete custom report types',
			'Edit, activate, or deactivate shared report types',
		],
	},
	[AccountPermission.CT_MANAGE]: {
		GENERAL: [],
		MOBILE: [],
		WEB: [
			'Access the Contacts page',
			'Create, edit, delete or import contacts',
			'Edit Job contacts',
			'Edit Work Order contacts',
		],
	},
	[AccountPermission.CT_VIEW]: {
		GENERAL: [],
		MOBILE: [],
		WEB: [
			'Access the Contacts page',
			'View Job Contacts',
			'View Work Order Contacts',
		],
	},
	[AccountPermission.CR_ALL]: {
		GENERAL: [],
		MOBILE: [],
		WEB: [
			_getFTDDescription('Access the Dashboard page', 'FTD_DASHBOARD'),
		],
	},
	[AccountPermission.CR_EQUIPMENT_UTILIZATION]: {
		GENERAL: [],
		MOBILE: [],
		WEB: [
			'Access the Analytics page',
			'View Equipment Utilization in Analytics',
		],
	},
	[AccountPermission.CR_EMPLOYEE_UTILIZATION]: {
		GENERAL: [],
		MOBILE: [],
		WEB: [
			'Access the Analytics page',
			'View Employee Utilization in Analytics',
		],
	},
	[AccountPermission.COMM_MANAGE]: {
		GENERAL: [],
		MOBILE: [],
		WEB: [
			'Access the Communications page',
			'Access and use the Notification Center',
			'Access and use the URL Shortener',
			'Notify user groups on the User Groups table',
		],
	},
	[AccountPermission.COMM_HISTORY_VIEW]: {
		GENERAL: [],
		MOBILE: [],
		WEB: [
			'Access the Communications page',
			'Access and use View Communications history page',
		],
	},
	[AccountPermission.EQ_MANAGE]: {
		GENERAL: [
			'Manage Equipment Status Automatic Return date',
		],
		MOBILE: [],
		WEB: [],
	},
	[AccountPermission.EM_MANAGE]: {
		GENERAL: [
			'Manage Labor Status Automatic Return date',
		],
		MOBILE: [],
		WEB: [],
	},
	[AccountPermission.TEM_MANAGE]: {
		GENERAL: [
			'See and manipulate temporary labor agencies in resources',
			'See and manipulate temporary employees in resources',
			'Have the option to add new temp labor on the Schedule Board',
		],
		MOBILE: [],
		WEB: [],
	},
	[AccountPermission.WS_MANAGE]: {
		GENERAL: [],
		MOBILE: [],
		WEB: [
			'Access the Work Summary tab on a Field Report',
		],
	},
	[AccountPermission.AC_ALL]: {
		GENERAL: [],
		MOBILE: [],
		WEB: [
			'Access the Per Diem Report',
			'Access the Prevailing Wage Report',
			'Access the Exception Report For Prevailing Wage',
			'Access the Job Payroll Report',
		],
	},
	[AccountPermission.AC_JOB_PAYROLL]: {
		GENERAL: [],
		MOBILE: [],
		WEB: ['Access the Job Payroll Report'],
	},
	[AccountPermission.AC_WORK_SUMMARY]: {
		GENERAL: [],
		MOBILE: [],
		WEB: ['Access the Job Work Summary Report'],
	},
	[AccountPermission.AC_MANAGE_INVOICES]: {
		GENERAL: [],
		MOBILE: [],
		WEB: ['Access Invoices Table And Create New Invoices'],
	},
	[AccountPermission.AC_PER_DIEM]: {
		GENERAL: [],
		MOBILE: [],
		WEB: ['Access the Per Diem Report'],
	},
	[AccountPermission.AC_PREVAILING]: {
		GENERAL: [],
		MOBILE: [],
		WEB: [
			'Access the Prevailing Wage Report',
			'Access the Exception Report For Prevailing Wage',
		],
	},
};

export const permissionDescriptions: Readonly<DescriptionsLookup> = Object.keys(descriptionsWithoutImplications).reduce(
	(_descriptions: DescriptionsLookup, _permission: AccountPermission) => {
		_descriptions[_permission] = {
			GENERAL: [
				..._getImplicationDescription(_permission),
				...descriptionsWithoutImplications[_permission].GENERAL,
			],
			MOBILE: descriptionsWithoutImplications[_permission].MOBILE,
			WEB: descriptionsWithoutImplications[_permission].WEB,
		};
		return _descriptions;
	},
	{} as DescriptionsLookup
);
