import * as React from 'react';
import type { ResolveThunks } from 'react-redux';
import { connect } from 'react-redux';
import { FormGroup } from 'react-bootstrap';

import UnavailabilityReasonScope from 'acceligent-shared/enums/unavailabilityReasonScope';

import type { RootState } from 'af-reducers';

import type { DefaultOptionType } from 'af-components/Controls/AsyncSelect';
import AsyncSelect from 'af-components/Controls/AsyncSelect';

import type ScheduleBoardUnavailabilityReasonRequestModel from 'ab-requestModels/scheduleBoardUnavailabilityReason.requestModel';

import SocketEvent from 'ab-enums/socketEvent.enum';

import * as UnavailabilityActions from 'af-actions/unavailabilityReason';

import socket from 'af-utils/socket.util';

interface OwnProps {
	itemId: number;
	dueDate: string;
	isDragging: boolean;
	scope: UnavailabilityReasonScope;
	hasPermissionsToEditScheduleBoard: boolean;
}

interface DispatchProps {
	findUnavailabilityReasons: typeof UnavailabilityActions.findUnavailabilityReasons;
}

interface StateProps {
	unavailabilityReasonName: string;
	unavailabilityReasonId: number | undefined;
}

type Props = OwnProps & StateProps & ResolveThunks<DispatchProps>;

interface State {
	value: DefaultOptionType;
}

class UnavailabilityReason extends React.PureComponent<Props, State> {
	state: State = {
		value: {
			value: this.props.unavailabilityReasonId,
			label: this.props.unavailabilityReasonName,
		},
	};

	static getDerivedStateFromProps(props: Props, oldState: State) {
		const oldValue = oldState?.value;

		if (oldValue?.label !== props.unavailabilityReasonName || oldValue?.value !== props.unavailabilityReasonId) {
			return {
				value: {
					value: props.unavailabilityReasonId,
					label: props.unavailabilityReasonName,
				},
			};
		}
		return null;
	}

	onNewReason = (label: string) => {
		const { itemId, dueDate, scope } = this.props;

		if (!label) {
			return;
		}

		const event: ScheduleBoardUnavailabilityReasonRequestModel = {
			itemId,
			unavailabilityReasonName: label,
			scope,
			dueDate,
		};
		socket.connection?.emit(SocketEvent.V2.FE.SCHEDULE_BOARD.CREATE_UNAVAILABILITY_REASON, event);
	};

	onReasonChange = async (option: DefaultOptionType) => {
		const { itemId, dueDate, scope } = this.props;

		if (!option) {
			return;
		}
		const event: ScheduleBoardUnavailabilityReasonRequestModel = {
			itemId,
			unavailabilityReasonId: option.value,
			scope,
			dueDate,
		};
		socket.connection?.emit(SocketEvent.V2.FE.SCHEDULE_BOARD.ASSIGN_UNAVAILABILITY_REASON, event);
	};

	onSearch = async (query: string) => {
		const { findUnavailabilityReasons, scope } = this.props;
		const options = await findUnavailabilityReasons(query, scope);
		return options.map((_option) => ({ label: _option.name, value: _option.id }));
	};

	clearReason = async () => {
		const { itemId, dueDate, scope } = this.props;
		const event: ScheduleBoardUnavailabilityReasonRequestModel = {
			itemId,
			scope,
			dueDate,
		};
		socket.connection?.emit(SocketEvent.V2.FE.SCHEDULE_BOARD.CLEAR_UNAVAILABILITY_REASON, event);
	};

	formatOptionLabel = (option) => option.label;

	render() {
		const { isDragging, hasPermissionsToEditScheduleBoard } = this.props;
		const { value } = this.state;

		return (
			<FormGroup className={isDragging ? 'sb-resource-item--opaque' : ''}>
				<div className="sb-resource-item__unavailability-reason">
					<AsyncSelect
						allowNew={true}
						disabled={!hasPermissionsToEditScheduleBoard}
						formatOptionLabel={this.formatOptionLabel}
						onClear={this.clearReason}
						onCreateNew={this.onNewReason}
						onSearch={this.onSearch}
						onValueChange={this.onReasonChange}
						value={value}
					/>
				</div>
			</FormGroup>
		);
	}
}

function mapStateToProps(state: RootState, ownProps: OwnProps): StateProps {
	const { workOrdersByDateDictionary } = state.scheduleBoard;
	const { scope, itemId, dueDate } = ownProps;

	const attribute = scope === UnavailabilityReasonScope.EMPLOYEE ? 'employeeUnavailabilityReason' : 'equipmentUnavailabilityReason';
	const unavailabilityReasonName = workOrdersByDateDictionary?.[dueDate]?.[attribute]?.[itemId]?.name ?? '';
	const unavailabilityReasonId = workOrdersByDateDictionary?.[dueDate]?.[attribute]?.[itemId]?.id;

	return {
		unavailabilityReasonName,
		unavailabilityReasonId,
	};
}

function mapDispatchToProps(): DispatchProps {
	return {
		findUnavailabilityReasons: UnavailabilityActions.findUnavailabilityReasons,
	};
}

export default connect<StateProps, DispatchProps, OwnProps>(mapStateToProps, mapDispatchToProps())(UnavailabilityReason);
