import type AddressBase from 'ab-domain/models/address/base';
import type WorkRequestBase from 'ab-domain/models/workRequest/base';

export const getJobDescriptionForLogs = (job: WorkRequestBase): string => {
	const { customerCompanyName, jobCode, title, travelLocation = {} as AddressBase } = job;

	const description = [
		jobCode,
		customerCompanyName,
		title,
		travelLocation?.street,
	].filter(Boolean).join(', ');

	return description;
};
