import * as React from 'react';
import { compose } from 'redux';
import type { ConnectedProps } from 'react-redux';
import { connect } from 'react-redux';
import type { InjectedFormProps } from 'redux-form';
import { reduxForm } from 'redux-form';
import type { CustomRouteComponentProps } from 'react-router-dom';

import type { EquipmentCostRequestModel } from 'ab-requestModels/equipmentCost.requestModel';

import { EQUIPMENT_COST_EDIT } from 'af-constants/reduxForms';
import CLIENT from 'af-constants/routes/client';

import * as EquipmentCostActions from 'af-actions/equipmentCost';

import type { RootState } from 'af-reducers';

import EquipmentCostForm from 'af-components/SharedForms/Resources/EquipmentCost';
import { validate } from 'af-components/SharedForms/Resources/EquipmentCost/validation';
import Breadcrumbs from 'af-components/Breadcrumbs';

interface PathParams {
	id: string;
}

type OwnProps = CustomRouteComponentProps<PathParams>;

type FormOwnProps = OwnProps & ConnectedProps<typeof connector>;

type Props = FormOwnProps & InjectedFormProps<EquipmentCostRequestModel, FormOwnProps>;

class Edit extends React.PureComponent<Props> {

	async componentDidMount() {
		const { findById, match: { params: { id } }, initialize } = this.props;
		const equipmentCost = await findById(id);
		initialize(equipmentCost);
	}

	editEquipmentCost = async (form: EquipmentCostRequestModel) => {
		const { update, location: { state: { orgAlias } }, companyData } = this.props;
		await update(form, companyData.name, orgAlias);
	};

	render() {
		const { location: { state: { orgAlias } }, companyData: { name: companyName } } = this.props;

		return (
			<div className="form-segment">
				<Breadcrumbs
					items={[
						{ label: 'Equipment Costs', url: CLIENT.COMPANY.RESOURCES.EQUIPMENT_COST.TREE(orgAlias, companyName) },
						{ label: 'Edit' },
					]}
				/>
				<EquipmentCostForm
					{...this.props}
					formName={EQUIPMENT_COST_EDIT}
					onSubmit={this.editEquipmentCost}
				/>
			</div>
		);
	}
}

function mapStateToProps(state: RootState) {
	const { companyData } = state.user;
	if (!companyData) {
		throw new Error('User not logged in');
	}

	return {
		companyData,
	};
}

function mapDispatchToProps() {
	return {
		update: EquipmentCostActions.update,
		findById: EquipmentCostActions.findById,
	};
}

const connector = connect(mapStateToProps, mapDispatchToProps());

const enhance = compose<React.ComponentClass<OwnProps>>(
	connector,
	reduxForm<EquipmentCostRequestModel, FormOwnProps>({
		form: EQUIPMENT_COST_EDIT,
		validate,
	})
);

export default enhance(Edit);
