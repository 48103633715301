import * as React from 'react';
import { visit } from 'unist-util-visit';
import Markdown from 'react-markdown';

import { createHeadingLink, findFirstInExistingHeading, rangeContainsIndex, shouldShowLine, shouldStopAtHeading } from 'af-utils/userGuideModal.util';

import { ModalLink } from 'af-components/ModalNavigation/ModalRouter';

import type { RawPages, SearchPageResults, SearchablePageText, TextData, TreeElement } from './types';

interface Props {
	rawPages?: RawPages;
	searchablePages?: SearchablePageText;
	searchResults?: SearchPageResults;
	scrollToTop?: () => void;
}

interface FirstLine {
	line: number | undefined;
	text: string;
	startOffset: number | undefined;
	page: string;
}

interface SearchRenderData {
	searchResult: TextData;
	firstLine: FirstLine;
}

interface Indents {
	[line: number]: number;
}

interface ResultIndents {
	[lirstLine: number]: Indents;
}

const SHOWLINES = 5;
const SHOWHEADINGS = 5;
const BULLETOFFSET = 25;

const SearchPage: React.FC<Props> = (props) => {

	const { rawPages, searchablePages, searchResults, scrollToTop } = props;

	const searchPage = React.useRef<HTMLDivElement>(null);
	const resultIndents = React.useRef<ResultIndents>({});

	React.useEffect(() => {
		if (scrollToTop) {
			scrollToTop();
		}
	}, [scrollToTop]);

	const transformTextToSpan = React.useCallback(() => {
		return (tree) => {
			visit(tree, 'text', (node: TreeElement, index, parent) => {
				if (parent && index !== undefined && node.value.trim() !== '') {
					parent.children[index] = {
						type: 'element',
						tagName: 'span',
						position: node.position,
						properties: {},
						children: [{ type: 'text', value: node.value }],
					};
				}
			});
		};
	}, []);

	const getFirstLineDataForSearch = React.useCallback((data: TextData): FirstLine | undefined => {
		if (!data.depth || data.depth >= SHOWHEADINGS) {
			return {
				line: data.linePosition,
				text: data.text,
				startOffset: data.startOffset,
				page: data.page,
			};
		}

		if (!searchablePages) {
			throw new Error('Searchable data not loaded');
		}

		const searchablePage = searchablePages[data.page];
		const index = searchablePage.findIndex((el) => {
			return el.position?.start.line === data.linePosition;
		});

		if (index === searchablePage.length - 1) {
			throw new Error('A heading 1 or 2 was the last element');
		}

		const element = searchablePage[index + 1];
		// If the element is found and it is not the last element and it is not one of the main headings
		if (index !== -1 &&
			(!element.depth || element.depth >= SHOWHEADINGS)) {

			if (!element.position) {
				throw new Error('First element from line has no position.');
			}

			const originalOffset = element.position.start.offset;
			const columnOffset = element.position.start.column === 1 ? 0 : element.position?.start.column;
			const startOffset = originalOffset - columnOffset - 1;
			return {
				line: element.position.start.line,
				text: element.value,
				startOffset,
				page: data.page,
			};
		}

		return undefined; // Should never happen, beacause that would mean that a heading 1 or heading 2 was the last element or it was not found in the file
	}, [searchablePages]);

	const prepareBulletIndents = React.useCallback((firstLine: number, fileSectionToRender: string) => {
		resultIndents.current[firstLine] = {};
		const lines = fileSectionToRender.split('\n').slice(1);

		for (let line = 0; line < lines.length; line++) {
			let charIndex = 0;

			if (lines[line].length <= 2) {
				continue;
			}

			let indentCounter = 0;
			let foundBulletIndentStop = false;
			while (charIndex < lines[line].length) {
				if (lines[line].charCodeAt(charIndex) === 45) {
					foundBulletIndentStop = true;
					break;
				}
				if (lines[line].charCodeAt(charIndex) === 32 || lines[line].charCodeAt(charIndex) === 9) {
					indentCounter++;
				}
				charIndex++;
			}
			if (foundBulletIndentStop && indentCounter) {
				resultIndents.current[firstLine][line] = indentCounter;
			}
		}
	}, []);

	/**
	 * Cuts the original raw file so only the selected section can be rendered.
	 */
	const getSelectedLines = React.useCallback((firstLine: FirstLine) => {
		if (!rawPages) {
			throw new Error('Raw files not loaded');
		}
		if (!searchablePages) {
			throw new Error('Searchable data not loaded');
		}
		if (firstLine.startOffset === undefined || firstLine.line === undefined) {
			throw new Error('Data position is undefined.');
		}

		const rawPage = rawPages[firstLine.page];
		const searchablePage = searchablePages[firstLine.page];

		let startingOffset = 0; // This offset is used to slice just before the first selected line and is needed in order to capture searches that start with a list element
		let selectedOffset = firstLine.startOffset + firstLine.text.length; // Starts off by selecting the first line by itself

		for (const textElement of searchablePage) {
			if (!textElement.position) {
				throw new Error('Element position undefined.');
			}
			if (shouldStopAtHeading(textElement, SHOWHEADINGS, firstLine.line, firstLine.startOffset)) {
				break;
			}
			if (textElement.position.end.line >= firstLine.line + SHOWLINES) {
				break;
			}
			if (shouldShowLine(textElement, SHOWLINES, firstLine.line, firstLine.startOffset)) {
				selectedOffset = textElement.position.end.offset;
			} else {
				startingOffset = textElement.position.end.offset;
			}
		}

		while (rawPage[selectedOffset] !== '\n' && rawPage.length < selectedOffset) {
			selectedOffset++;
		}
		const fileSectionToRender = rawPage.slice(startingOffset, selectedOffset);
		prepareBulletIndents(firstLine.line, fileSectionToRender);

		return fileSectionToRender.trim();

	}, [prepareBulletIndents, rawPages, searchablePages]);

	/**
	 * Filters out search results that should not be shown in a heading section.
	 */
	const prepareForRender = React.useCallback((results: TextData[]): SearchRenderData[] => {

		const renderData: { [line: number]: SearchRenderData; } = {};

		for (const result of results) {
			const lineData = getFirstLineDataForSearch(result);
			if (!lineData?.line) {
				continue;
			}

			const firstInSection = findFirstInExistingHeading(results, SHOWHEADINGS, result.page, result.subPage);
			if (firstInSection && firstInSection.linePosition !== result.linePosition) {
				continue;
			}

			if (lineData.line in renderData) {
				continue;
			}
			renderData[lineData.line] = {
				searchResult: result,
				firstLine: lineData,
			};
		}

		return Object.values(renderData);

	}, [getFirstLineDataForSearch]);

	/**
	 * Render functions
	 */
	const em = React.useCallback((_props) => {
		const { children, ...rest } = _props;
		return <span className="user-guide__blue" {...rest}>{children}</span>;
	}, []);
	const code = React.useCallback((_props) => {
		const { children, ...rest } = _props;
		return <span className="user-guide__orange" {...rest}>{children}</span>;
	}, []);
	const li = React.useCallback((_props, firstLine: FirstLine) => {
		const { children, ...rest } = _props;

		const node = _props.node;

		if (!node?.position) {
			throw new Error('ul node nas no position.');
		}

		if (!firstLine.line) {
			throw new Error('First line has no line.');
		}

		if (resultIndents.current[firstLine.line][node.position.start.line - 1]) {
			const offset = (resultIndents.current[firstLine.line][node.position.start.line - 1] / 2) * BULLETOFFSET;
			return <li className="user-guide__li user-guide__li-search" style={{ '--first-line-indent': `${offset}px` }} {...rest}>{children}</li>;
		}

		return <li className="user-guide__li" {...rest}>{children}</li>;
	}, []);

	const h5 = React.useCallback((_props) => {
		const { children, ...rest } = _props;
		if (!children) {
			throw new Error('Heading 5 - subtitle 2, has no text');
		}
		return <div className="user-guide__sub-title-2" {...rest}>{children}</div>;
	}, []);

	const a = React.useCallback((_props) => {
		const { href, children } = _props;
		return <ModalLink className="user-guide__link" link={href}>{children}</ModalLink>;
	}, []);

	const img = React.useCallback(() => {
		return <span></span>;
	}, []);

	// Handles highlighting searched text
	const span = React.useCallback((_props, _searchResult: TextData, _firstLine: FirstLine) => {
		const { children, ...rest } = _props;
		const node = _props.node;

		if (!node?.position) {
			throw new Error('Span node nas no position.');
		}
		if (!children) {
			throw new Error('Span node nas no children to render.');
		}
		if (_searchResult.startOffset === undefined) {
			throw new Error('Value has no start.');
		}

		const fullText: string = children.toString();
		let indexOffsetAfterCut = 0; // Used in case the text has multiple highlighted parts
		let afterSelect: string = ''; // Used to render rest of text after everything was highlighted

		const result: React.JSX.Element[] = [];

		if (!searchResults || !searchablePages) {
			throw new Error('No search results.');
		}

		// For every line that is displayed we find the appropriate search data
		const lineResult = searchResults[_searchResult.page]
			.find((sr) =>
				(sr.linePosition ?? 0) - (_firstLine.line ?? 0) + 1 === node.position.start.line);

		const line = searchablePages[_searchResult.page]
			.find((sr) =>
				(sr.position?.start.line ?? 0) - (_firstLine.line ?? 0) + 1 === node.position.start.line);

		if (!line?.position) {
			throw new Error('No matching line found.');
		}

		const startOffset = line.position.start.column - 1;

		for (const indexData of lineResult?.occuranceIndices ?? []) {

			let nodeStart = node.position.start.column - startOffset - 1;
			let nodeEnd = node.position.end.column - startOffset - 1;

			if (nodeStart < 0) {
				nodeEnd -= nodeStart;
				nodeStart = 0;
			}

			if (!rangeContainsIndex(nodeStart, nodeEnd, indexData)) {
				continue;
			}

			const elementPositionSpecialCharactersOffset = Math.ceil(((nodeEnd - nodeStart) - fullText.length) / 2);
			const cutStart = indexData.start - nodeStart - elementPositionSpecialCharactersOffset;
			const cutEnd = cutStart + (indexData.end - indexData.start);

			const beforeSelect = fullText.substring(indexOffsetAfterCut, cutStart);
			const select = fullText.substring(cutStart, cutEnd);
			afterSelect = fullText.substring(cutEnd, fullText.length);
			indexOffsetAfterCut = cutEnd;

			result.push((
				<span key={Math.random()}>
					<span>{beforeSelect}</span>
					<span className="user-guide__search-result-highlight">{select}</span>
				</span>)
			);
		}

		if (result.length > 0) {
			return (
				<span>
					{result.map((v) => v)}
					{afterSelect && <span>{afterSelect}</span>}
				</span>
			);
		}

		return <span {...rest}>{children}</span>;
	}, [searchResults, searchablePages]);

	const renderSectionLink = React.useCallback((sectionData: TextData) => {
		if (sectionData.subTitle) {
			const link = createHeadingLink(sectionData.page, sectionData.subTitle, 'subtitle');
			return <ModalLink link={link}>{sectionData.subTitle}</ModalLink>;
		}
		if (sectionData.subPage) {
			const link = createHeadingLink(sectionData.page, sectionData.subPage, 'subpage');
			return <ModalLink link={link}>{sectionData.subPage}</ModalLink>;
		}
		const link = createHeadingLink(sectionData.page, sectionData.page, 'page');
		return <ModalLink link={link}>{sectionData.page.split('_').join(' ')}</ModalLink>;
	}, []);

	const results = React.useMemo(() => {
		if (!searchResults || Object.keys(searchResults).length === 0) {
			return <div>No search results found.</div>;
		}

		const renderData = prepareForRender(Object.values(searchResults).flat());

		if (renderData.length === 0) {
			return <div>No search results found.</div>;
		}

		return renderData.map(
			({ searchResult: _searchResult, firstLine: _firstLine }, _index) => {
				return (
					<div className="user-guide__search-result" key={_index}>
						<div className="user-guide__sub-page-title">{renderSectionLink(_searchResult)}</div>
						<div className="user-guide__search-page-text">
							<Markdown
								components={{
									h5, em, code, a, img,
									li(_props) {
										// Calls local cached function
										return li(_props, _firstLine);
									},
									span(_props) {
										// Calls local cached function
										return span(_props, _searchResult, _firstLine);
									},
								}}
								rehypePlugins={[transformTextToSpan]}
							>
								{getSelectedLines(_firstLine)}
							</Markdown>
						</div >
						<div className="user-guide__search-breadcrumbs">
							{_searchResult.page.split('_').join(' ')}
							{_searchResult.subPage && ` > ${_searchResult.subPage}`}
						</div>
					</div >
				);
			}
		);

	}, [h5, a, code, em, getSelectedLines, img, li, prepareForRender, renderSectionLink, searchResults, span, transformTextToSpan]);

	return (
		<div className="user-guide__search-page-container" ref={searchPage}>{results}</div>
	);
};

export default SearchPage;
