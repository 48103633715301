import type { Dispatch, AnyAction } from 'redux';

import type CornerstoneResponse from 'ab-viewModels/learningManagementSystem/cornerstoneResponse.viewModel';

import API from 'af-constants/routes/api';

import { http } from 'af-utils/http.util';
import { errorHandler } from 'af-utils/actions.util';

import type { GetRootState } from 'af-reducers';

export function findCornerstoneResponse() {
	return async (dispatch: Dispatch<AnyAction>, getState: GetRootState, { redirectTo }) => {

		const action = async () => {
			return await http.put<CornerstoneResponse>(API.V1.LEARNING_MANAGEMENT_SYSTEM.FIND_CORNERSTONE_RESPONSE());
		};

		return await errorHandler(action, dispatch, redirectTo);
	};
}

