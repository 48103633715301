import type QuantityUnitType from 'acceligent-shared/enums/quantityUnit';
import { CompoundUnitEnum, PlainUnitEnum, QuantityUnitMap } from 'acceligent-shared/enums/quantityUnit';
import * as ReportBlockFieldEnum from 'acceligent-shared/enums/reportBlockField';

import type AddressBase from 'ab-domain/models/address/base';
import type JobWorkSummaryBase from 'ab-domain/models/jobWorkSummary/base';

import { MAX_BILLABLE_WORK_DEFINITION_FIELDS } from 'ab-common/constants/value';

import { convertUnits } from 'ab-utils/unitConversion.util';

enum WORK_SUMMARY_BILLABLE_WORK_TABLE_HEADERS {
	WORK = 'Work',
	TYPE = 'Type',
	DEFINITION_FIELD = 'Definition',
	QUANTITY = 'Quantity',
	UNIT = 'Unit',
	BILLING_CODE = 'Billing code',
	BILLING_GROUP = 'Billing group',
	UNIT_PRICE = 'Unit Price per billingCode Unit ($)',
	QUANTITY_UNIT_PRICE = 'Unit Price per quantity Unit ($)',
	DESCRIPTION = 'Description',
	REVENUE = 'Revenue ($)',
	JOB_NAME = 'Job Name'
}

export interface WorkSummaryDefinitionFieldVM {
	value: string;
	fieldName: string;
	fieldType: ReportBlockFieldEnum.Type;
	fieldUnit: Nullable<QuantityUnitType>;
	index: number;
}

function _resolveUnit(unit: QuantityUnitType) {
	switch (unit) {
		case CompoundUnitEnum.FT_IN: return PlainUnitEnum.IN;
		case CompoundUnitEnum.CFT_CIN: return PlainUnitEnum.CIN;
		case CompoundUnitEnum.HH_MM: return PlainUnitEnum.MIN;
		case CompoundUnitEnum.LFT_LIN: return PlainUnitEnum.LIN;
		case CompoundUnitEnum.SFT_SIN: return PlainUnitEnum.SIN;
		case CompoundUnitEnum.VFT_VIN: return PlainUnitEnum.VIN;
	}
	return unit;
}

function _resolveFieldValue(value: string, fieldType: ReportBlockFieldEnum.Type) {
	let resolvedValue = value;

	if (fieldType === ReportBlockFieldEnum.Type.BOOLEAN) {
		resolvedValue = new Boolean(value) ? 'Yes' : 'No';
	}

	if (fieldType === ReportBlockFieldEnum.Type.ADDRESS) {
		return (value as unknown as AddressBase)?.street ?? null;
	}

	return resolvedValue;
}

const _calculateUnitPricePerQuantityUnit = (ws: WorkSummaryVM) => {
	if (!ws.unitPrice || !ws.unit || !ws?.billingCodeUnit) {
		return '';
	}

	if (QuantityUnitMap[ws.unit] === QuantityUnitMap[ws.billingCodeUnit]) {
		return `${parseFloat(ws.unitPrice.toFixed(4))}`;
	}

	const convertedUnit = convertUnits(ws.unitPrice, QuantityUnitMap[ws.unit], QuantityUnitMap[ws.billingCodeUnit]);

	return convertedUnit ? `${parseFloat(convertedUnit.toFixed(8))}` : '';
};

class WorkSummaryVM {
	subJobId: number;
	billableWorkId: number;
	billingCodeId: Nullable<number>;
	billingCodeUnit: Nullable<QuantityUnitType>;
	billingGroup: Nullable<string>;
	work: string;
	isBillable: boolean;
	quantity: number;
	total: Nullable<number>;
	unit: Nullable<string>;
	type: string;
	typeFieldName: string;
	typeFieldType: ReportBlockFieldEnum.Type;
	definitionFields: WorkSummaryDefinitionFieldVM[];
	customerId: Nullable<string>;
	unitPrice: Nullable<number>;
	description: Nullable<string>;
	group: Nullable<string>;
	workSummaryGroup: Nullable<string>;
	jobCode: Nullable<string>;

	constructor(jws: JobWorkSummaryBase) {
		this.subJobId = jws.workRequestId;
		if (!jws.billableWorkId) {
			throw new Error('Only use JWS with dataType ORIGINAL for this VM');
		}
		this.billableWorkId = jws.billableWorkId;
		this.billingCodeId = jws.billingCodeId;
		this.billingCodeUnit = jws.billingCode?.unit ?? null;
		this.billingGroup = jws.group;
		this.work = jws.work;
		this.isBillable = jws.isBillable;
		this.quantity = jws.quantity ?? 0;
		this.unit = jws.unit ? QuantityUnitMap[_resolveUnit(jws.unit)] ?? null : null;
		this.total = jws.revenue;
		this.type = jws.typeValue ?? '';
		this.typeFieldName = jws.type;
		if (!jws.typeFieldType) {
			throw new Error('Only use JWS with dataType ORIGINAL for this VM');
		}
		this.typeFieldType = jws.typeFieldType;
		this.definitionFields = [];
		for (let i = 1; i <= MAX_BILLABLE_WORK_DEFINITION_FIELDS; i++) {
			const fieldName = jws[`definitionField${i}Name`];
			if (fieldName) {
				this.definitionFields.push({
					index: i - 1,
					value: jws[`definitionField${i}Value`],
					fieldName: fieldName,
					fieldType: jws[`definitionField${i}Type`],
					fieldUnit: jws[`definitionField${i}Unit`] ? _resolveUnit(jws[`definitionField${i}Unit`] as QuantityUnitType) : null,
				});
			}
		}

		this.customerId = jws.customerId;
		this.unitPrice = jws.unitPrice;
		this.description = jws.description;
		this.group = jws.group;
		this.workSummaryGroup = jws.workSummaryGroup;
		this.jobCode = jws.workRequest?.jobCode ?? null;
	}

	private static _constructorMap = (_workSummary: JobWorkSummaryBase) => new WorkSummaryVM(_workSummary);

	private static maxDefinitionFieldsReducer = (_max: number, _currentWS: WorkSummaryVM) => {
		if (_currentWS.definitionFields?.length > _max) {
			_max = _currentWS.definitionFields?.length;
		}

		return _max;
	};

	static resolveWorkSummaryBillableWorkTableHeaders = (maxNumberOfDefinitionFields: number, belongsToProject: boolean) => {
		const headers: string[] = [];
		const headerTemplate = WORK_SUMMARY_BILLABLE_WORK_TABLE_HEADERS;
		const headerTemplateKeys = Object.keys(headerTemplate);
		for (const headerTemplateKey of headerTemplateKeys) {
			if (headerTemplate[headerTemplateKey] === headerTemplate.DEFINITION_FIELD) {
				for (let i = 0; i < maxNumberOfDefinitionFields; i++) {
					headers.push(`${headerTemplate[headerTemplateKey]} ${i + 1}`);
				}
			} else {
				if (belongsToProject || headerTemplate[headerTemplateKey] !== WORK_SUMMARY_BILLABLE_WORK_TABLE_HEADERS.JOB_NAME) {
					headers.push(headerTemplate[headerTemplateKey]);
				}
			}
		}

		return headers;
	};

	static bulkConstructor = (workSummaries: JobWorkSummaryBase[]) => workSummaries.map(WorkSummaryVM._constructorMap);

	static toCSVData = (workSummaries: WorkSummaryVM[], belongsToProject: boolean): string[][] => {
		const maxNumberOfDefinitionFields = workSummaries.reduce(WorkSummaryVM.maxDefinitionFieldsReducer, 0);
		const headers = WorkSummaryVM.resolveWorkSummaryBillableWorkTableHeaders(maxNumberOfDefinitionFields, belongsToProject);

		const rows = workSummaries.map((_ws) => {

			const adjustedDefFields: string[] = [];
			for (let i = 0; i < maxNumberOfDefinitionFields; i++) {
				const defFieldName = _ws.definitionFields[i]?.fieldName ? `${_ws.definitionFields[i]?.fieldName}:` : '';
				const defFieldValue = _ws.definitionFields[i] ? _resolveFieldValue(_ws.definitionFields[i].value, _ws.definitionFields[i].fieldType) : 'N/A';
				const defValue = `${defFieldName} ${defFieldValue}`;
				adjustedDefFields.push(defValue);
			}

			const workTypeAdjustedName = _ws.typeFieldType === ReportBlockFieldEnum.Type.NUMERIC_ATTRIBUTE
				? _ws.typeFieldName
				: `${_ws.typeFieldName}: ${_resolveFieldValue(_ws.type, _ws.typeFieldType)}`;

			const row = [
				_ws.work,
				_ws.type ? workTypeAdjustedName : 'N/A',
				...adjustedDefFields,
				_ws.quantity + '',
				_ws.unit ?? '',
				_ws.customerId ?? 'N/A',
				_ws.billingGroup ?? 'N/A',
				_ws.unitPrice ? `${parseFloat(_ws.unitPrice.toFixed(4))}` : 'N/A',
				_calculateUnitPricePerQuantityUnit(_ws),
				_ws.description ?? 'N/A',
				_ws.total ? (_ws.total + '') : 'N/A',
			];
			if (belongsToProject) {
				row.push(_ws.jobCode ?? 'N/A');
			}
			return row;
		});

		return [headers, ...rows];
	};
}

export default WorkSummaryVM;
