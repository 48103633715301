import type { Dispatch, AnyAction } from 'redux';

import API from 'af-constants/routes/api';

import type UserOptionVM from 'ab-viewModels/user/option.viewModel';

import { http } from 'af-utils/http.util';
import { errorHandler } from 'af-utils/actions.util';

import type { GetRootState } from 'af-reducers';

export function fetchMissingUsers() {
	return async (dispatch: Dispatch<AnyAction>, getState: GetRootState, { redirectTo }) => {

		const action = async () => {
			return await http.get<UserOptionVM[]>(API.V1.USERS.MISSING_MEMBERS_LIST);
		};

		return await errorHandler(action, dispatch, redirectTo);
	};
}
