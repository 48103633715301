import * as React from 'react';
import { compose } from 'redux';
import { Button, Row } from 'react-bootstrap';
import type { InjectedFormProps} from 'redux-form';
import { Field, reduxForm, destroy } from 'redux-form';

import TimeFormatEnum from 'acceligent-shared/enums/timeFormat';

import * as TimeUtils from 'acceligent-shared/utils/time';

import CustomModal from 'af-components/CustomModal';
import SubmitButton from 'af-components/SubmitButton';

import Signature from 'af-fields/Signature';

import type * as CustomerSignatureRequestModel from 'ab-requestModels/signature.requestModel';

import { SIGNATURE_FIELD_REPORT } from 'af-constants/reduxForms';

interface OwnProps {
	closeModal: () => void;
	onSubmit: (form: CustomerSignatureRequestModel.SignatureForm) => Promise<void>;
	showModal: boolean;
}

type Props = OwnProps & InjectedFormProps<CustomerSignatureRequestModel.SignatureForm, OwnProps>;

const SignatureModal: React.FC<Props> = (props: Props) => {
	const {
		closeModal,
		handleSubmit,
		onSubmit,
		showModal,
		initialValues,
		initialize,
	} = props;

	React.useEffect(() => {
		if (showModal) {
			initialize(initialValues);
		} else {
			destroy();
		}
		// eslint-disable-next-line react-hooks/exhaustive-deps
	}, [showModal]);

	const submit = React.useCallback(async (form: CustomerSignatureRequestModel.SignatureForm) => {
		if (!form.signatureImage) {
			return;
		}

		const data: CustomerSignatureRequestModel.SignatureForm = {
			...form,
			signedAt: TimeUtils.formatDate(new Date(), TimeFormatEnum.ISO_DATETIME),
		};

		await onSubmit(data);
		closeModal();
	}, [closeModal, onSubmit]);

	return (
		<CustomModal
			closeModal={closeModal}
			modalStyle="info"
			showModal={showModal}
			size="md"
		>
			<CustomModal.Header
				closeModal={closeModal}
				title="Sign"
			/>
			<CustomModal.Body>
				<Row className="row--padded-bottom">
					Use a stylus or a mouse to sign:
				</Row>
				<Row>
					<Field
						component={Signature}
						name="signatureName"
					/>
				</Row>
			</CustomModal.Body>
			<CustomModal.Footer>
				<Button
					onClick={closeModal}
					variant="info"
				>
					Cancel
				</Button>
				<SubmitButton
					label="Sign & Submit"
					onClick={handleSubmit(submit)}
				/>
			</CustomModal.Footer>
		</CustomModal>
	);
};

const enhance = compose<React.ComponentType<OwnProps>>(
	React.memo,
	reduxForm<CustomerSignatureRequestModel.SignatureForm, OwnProps>({ form: SIGNATURE_FIELD_REPORT })
);

export default enhance(SignatureModal);
