import * as React from 'react';
import type { Option } from 'react-select/src/filters';

import type { SkillViewModel } from 'ab-viewModels/skill.viewModel';
import type { WageRateVM } from 'ab-viewModels/wageRate/wageRate.viewModel';
import type * as ResourceStatusesViewModel from 'ab-viewModels/resources/resourceStatuses.viewModel';

import StatusLabel from 'af-components/StatusLabel';

// Wage rate

export const formatWageRateOptionLabel = (option: WageRateVM) => <span>{option.type}, {option.wageClassification}</span>;

export const getWageRateOptionValue = (option: WageRateVM) => `${option.id}`;

export const getWageRateOptionLabel = (option: WageRateVM) => `${option.type}, ${option.wageClassification}`;

export const getTextForWageRate = (data: WageRateVM) => {
	const { type, wageClassification } = data;
	return `${type} ${wageClassification}`;
};

export const filterWageRates = (option: Option, text: string) => {
	const loweredText = text ? text.toLowerCase() : '';
	const searchableText = getTextForWageRate(option.data);
	return searchableText.toLowerCase().includes(loweredText);
};

// Skill

export const getSkillOptionValue = (option: SkillViewModel) => option.id?.toString();

export const getSkillOptionLabel = (option: SkillViewModel) => option.name;

// Employee status

export const renderResourceStatusMenuItem = ({ available, name }: ResourceStatusesViewModel.Item) => <StatusLabel isAvailable={available} label={name} />;

export const renderSelectedResourceStatus = (companyEmployeeStatuses: ResourceStatusesViewModel.List, selectedId: number) => {
	const selectedItem = companyEmployeeStatuses.find((_status) => _status.id === selectedId);
	return selectedItem && renderResourceStatusMenuItem(selectedItem);
};

export const convertStatusId = (employeeStatusId: Nullable<number>) => {
	return employeeStatusId === -1 ? null : employeeStatusId;
};
