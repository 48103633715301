import * as React from 'react';
import { compose } from 'redux';
import { connect } from 'react-redux';
import { Button } from 'react-bootstrap';
import type { InjectedFormProps} from 'redux-form';
import { Field, reduxForm, formValueSelector } from 'redux-form';

import type { RootState } from 'af-reducers';

import type { WorkOrderPauseForm } from 'ab-requestModels/workOrder.requestModel';

import CustomModal from 'af-components/CustomModal';
import SubmitButton from 'af-components/SubmitButton';

import Textarea from 'af-fields/Textarea';

import { ORDER_PAUSE_FORM } from 'af-constants/reduxForms';

interface OwnProps {
	showPauseModal: boolean;
	workOrderCode: string;
	closePauseModal: () => void;
	onSubmit: (form: WorkOrderPauseForm) => Promise<void>;
}

interface StateProps {
	pauseReason: string;
}

type FormOwnProps = StateProps & OwnProps;
type Props = FormOwnProps & InjectedFormProps<WorkOrderPauseForm, FormOwnProps>;

const PauseModal: React.FC<Props> = (props) => {

	const { showPauseModal, workOrderCode, pauseReason, handleSubmit, submitting, onSubmit, closePauseModal, destroy } = props;

	React.useEffect(() => {
		if (!showPauseModal) {
			destroy();
		}
	}, [showPauseModal, destroy]);

	return (
		<CustomModal
			closeModal={closePauseModal}
			modalStyle="info"
			showModal={showPauseModal}
			size="md"
		>
			<CustomModal.Header
				closeModal={closePauseModal}
				title={`Work Order - ${workOrderCode}`}
			/>
			<CustomModal.Body>
				<Field
					component={Textarea}
					disabled={submitting}
					label="Pause Reason *"
					name="pauseReason"
					placeholder="Please enter pause reason"
				/>
			</CustomModal.Body>
			<CustomModal.Footer>
				<Button onClick={closePauseModal} variant="info">Back</Button>
				<SubmitButton
					disabled={!pauseReason}
					label="Pause Work Order"
					onClick={handleSubmit(onSubmit)}
					reduxFormSubmitting={submitting}
					submitKey={ORDER_PAUSE_FORM}
				/>
			</CustomModal.Footer>
		</CustomModal>
	);
};

const selector = formValueSelector(ORDER_PAUSE_FORM);

function mapStateToProps(state: RootState) {
	return {
		pauseReason: selector(state, 'pauseReason'),
	};
}

const enhance = compose<React.ComponentClass<OwnProps>>(
	connect<StateProps, null, OwnProps>(mapStateToProps),
	reduxForm<WorkOrderPauseForm, FormOwnProps>({ form: ORDER_PAUSE_FORM, enableReinitialize: true, destroyOnUnmount: true })
);
export default enhance(PauseModal);
