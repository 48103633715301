import * as React from 'react';
import { Row, Col, Button } from 'react-bootstrap';

import type { DeliverableComment } from 'ab-viewModels/deliverableTable.viewModel';

import { bemElement } from 'ab-utils/bem.util';

interface OwnProps {
	comments: DeliverableComment[];
	onSubmit: (comment: string) => void;
	disabled?: boolean;
}

type Props = OwnProps;

interface State {
	newComment: string;
}

class DeliverableCommentForm extends React.PureComponent<Props, State> {
	static defaultProps: Partial<Props> = {
		disabled: false,
	};

	state: State = {
		newComment: '',
	};

	createComment = async () => {
		const { onSubmit } = this.props;
		await onSubmit(this.state.newComment);
		this.setState(() => ({ newComment: '' }));
	};

	onChange = (event: React.ChangeEvent<HTMLInputElement>) => {
		event.persist();
		const value = event.target.value;
		this.setState(() => ({ newComment: value }));
	};

	render() {
		const { comments, disabled } = this.props;
		const { newComment } = this.state;

		return (
			<>
				<hr />
				<Row className="row--padded">
					<Col md={12}><h4>Comments</h4></Col>
				</Row>
				{!comments.length && disabled
					? <div className={bemElement('deliverable-form', 'no-comments-message')}>No available comments</div>
					: comments.map((_comment, _index) =>
						<Row key={_index}>
							<Col className={bemElement('deliverable-form', 'comment')} md={13}>
								{_comment.text}
							</Col>
						</Row>
					)}
				{!disabled && (
					<Row className="row--padded">
						<Col className={bemElement('deliverable-form', 'comment-input')} sm={21}>
							<input
								className="form-control"
								onChange={this.onChange}
								placeholder="New comment"
								type="text"
								value={newComment}
							/>
						</Col>
						<Col className={bemElement('deliverable-form', 'comment-submit')} sm={3}>
							<Button
								disabled={!newComment.length}
								onClick={this.createComment}
								variant="primary"
							>
								Add
							</Button>
						</Col>
					</Row>
				)}
				<hr />
			</>
		);
	}
}

export default DeliverableCommentForm;
