import * as React from 'react';
import { FormCheck } from 'react-bootstrap';

import type { OwnProps as TooltipProps } from 'af-components/Tooltip';
import Label from 'af-components/LockedValue/Label';

import { bemBlock, bemElement } from 'ab-utils/bem.util';

interface OwnProps {
	isChecked: boolean;
	label?: string | JSX.Element;
	id?: string;
	inline?: boolean;
	bsClass?: string;
	extraClass?: string;
	capitalize?: boolean;
	isCondensed?: boolean;
	isDisabled?: boolean;
	isSmall?: boolean;
	isStandalone?: boolean;
	isStandaloneRow?: boolean;
	labelId?: string;
	tooltipMessage?: TooltipProps['message'];
	handleChange?: (event: React.ChangeEvent<HTMLInputElement>) => void;
}

export type Props = OwnProps;

const Checkbox = (props: Props) => {
	const {
		isChecked,
		label = '',
		id,
		inline = true,
		bsClass = '',
		extraClass = '',
		capitalize = true,
		isCondensed = false,
		isDisabled = false,
		isSmall = false,
		isStandalone = false,
		isStandaloneRow = false,
		labelId,
		tooltipMessage,
		handleChange,
	} = props;

	const className = bemBlock('input-checkbox', {
		desaturated: isDisabled,
		disabled: isDisabled,
		standalone: isStandalone,
		'standalone-row': isStandaloneRow,
		condensed: isCondensed,
		checked: isChecked,
		'no-label': !label,
		inline,
	});

	const labelClassName = React.useMemo(() => {
		return (
			bemElement('input-checkbox', 'label', {
				small: isSmall,
				disabled: isDisabled,
				capitalize,
			})
		);
	}, [isSmall, isDisabled, capitalize]);

	return (
		<div id={`form-check-${id}`}>
			<FormCheck
				bsPrefix={bsClass}
				className={`${className} ${extraClass} icon-checkbox_${isChecked ? 'checked' : 'unchecked'}`}
				disabled={isDisabled}
				id={id}
				inline={inline}
			>
				<FormCheck.Label id={labelId}>
					<FormCheck.Input
						checked={isChecked}
						disabled={isDisabled}
						onChange={handleChange}
						type="checkbox"
					/>
					<Label
						className={labelClassName}
						label={label}
						tooltipMessage={tooltipMessage}
					/>
				</FormCheck.Label>
			</FormCheck>
		</div>

	);
};

export default React.memo(Checkbox);
