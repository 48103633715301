import type { Dispatch, AnyAction } from 'redux';

import type { TableContent } from 'ab-common/dataStructures/tableContent';

import API from 'af-constants/routes/api';
import CLIENT from 'af-constants/routes/client';

import { http } from 'af-utils/http.util';
import type { ErrorOverride, ResponseDetails } from 'af-utils/actions.util';
import { errorHandler } from 'af-utils/actions.util';

import { TableQuery } from 'ab-common/dataStructures/tableQuery';

import type { DeliverableStatusRequestModel } from 'ab-requestModels/deliverableStatus.requestModel';

import type { DeliverableStatusViewModel } from 'ab-viewModels/deliverableStatusTable.viewModel';

import type { GetRootState } from 'af-reducers';

export function create(form: DeliverableStatusRequestModel, companyName: string, orgAlias: string) {
	return async (dispatch: Dispatch<AnyAction>, getState: GetRootState, { redirectTo }): Promise<ResponseDetails<string> | undefined> => {
		const action = async () => {
			await http.post(API.V1.RESOURCES.DELIVERABLE.STATUS.CREATE, form);
			redirectTo(CLIENT.COMPANY.RESOURCES.DELIVERABLE.LIST(orgAlias, companyName));
			return { success: true };
		};

		const error = {
			err400: ({ response }): ResponseDetails<string> => {
				return { success: false, details: response?.data?.name };
			},
		};
		return await errorHandler<ResponseDetails<string>>(action, dispatch, redirectTo, error);
	};
}

export function bulkDelete(ids: number[]) {
	return async (dispatch: Dispatch<AnyAction>, getState: GetRootState, { redirectTo }) => {
		const action = async () => {
			await http.delete(API.V1.RESOURCES.DELIVERABLE.STATUS.BULK_DELETE, { deliverableStatusIds: ids });
		};
		return await errorHandler(action, dispatch, redirectTo);
	};
}

export function deleteStatus(id: number) {
	return async (dispatch: Dispatch<AnyAction>, getState: GetRootState, { redirectTo }) => {
		const action = async () => {
			await http.delete(API.V1.RESOURCES.DELIVERABLE.STATUS.DELETE(id.toString()));
		};
		return await errorHandler(action, dispatch, redirectTo);
	};
}

export function findAllDataStatusesForCompanyTable(tableRequestModel: TableQuery) {
	return async (dispatch: Dispatch<AnyAction>, getState: GetRootState, { redirectTo }) => {
		const action = async () => {
			const data = new TableQuery(tableRequestModel);
			return await http.get<TableContent<DeliverableStatusViewModel>>(API.V1.RESOURCES.DELIVERABLE.STATUS.DATA_TABLE(data));
		};
		return await errorHandler(action, dispatch, redirectTo);
	};
}

export function findAllDataStatusesForCompany() {
	return async (dispatch: Dispatch<AnyAction>, getState: GetRootState, { redirectTo }) => {
		const action = async () => {
			return await http.get<DeliverableStatusViewModel[]>(API.V1.RESOURCES.DELIVERABLE.STATUS.DATA_LIST);
		};
		return await errorHandler(action, dispatch, redirectTo);
	};
}

export function findAllJobStatusesForCompanyTable(tableRequestModel: TableQuery) {
	return async (dispatch: Dispatch<AnyAction>, getState: GetRootState, { redirectTo }) => {
		const action = async () => {
			const data = new TableQuery(tableRequestModel);
			return await http.get<TableContent<DeliverableStatusViewModel>>(API.V1.RESOURCES.DELIVERABLE.STATUS.JOB_TABLE(data));
		};
		return await errorHandler(action, dispatch, redirectTo);
	};
}

export function findAllJobStatusesForCompany() {
	return async (dispatch: Dispatch<AnyAction>, getState: GetRootState, { redirectTo }) => {
		const action = async () => {
			return await http.get<DeliverableStatusViewModel[]>(API.V1.RESOURCES.DELIVERABLE.STATUS.JOB_LIST);
		};
		return await errorHandler(action, dispatch, redirectTo);
	};
}

export function update(form: DeliverableStatusRequestModel, companyName: string, orgAlias: string) {
	return async (dispatch: Dispatch<AnyAction>, getState: GetRootState, { redirectTo }): Promise<ResponseDetails<string> | undefined> => {
		const action = async () => {
			if (!form.id) {
				throw new Error('Deliverable ID not provided');
			}

			await http.put(API.V1.RESOURCES.DELIVERABLE.STATUS.EDIT(form.id.toString()), form);
			redirectTo(CLIENT.COMPANY.RESOURCES.DELIVERABLE.LIST(orgAlias, companyName));
			return { success: true };
		};
		const error: ErrorOverride = {
			err400: ({ response }) => {
				return { success: false, details: response?.data?.name };
			},
		};
		return await errorHandler(action, dispatch, redirectTo, error);
	};
}
