import * as React from 'react';
import { compose } from 'redux';
import type { ConnectedProps } from 'react-redux';
import { connect } from 'react-redux';
import { Droppable } from 'react-beautiful-dnd';

import type { RootState } from 'af-reducers';

import * as ScheduleBoardActions from 'af-actions/scheduleBoard';

import BlankCard from 'af-root/scenes/Company/ScheduleBoard/Shared/Card/BlankCard';
import Card from 'af-root/scenes/Company/ScheduleBoard/Shared/Card';

import * as ScheduleBoardUtil from 'af-utils/scheduleBoard.util';

interface OwnProps {
	addBlankWorkOrder: (dueDate: string, index: number) => Promise<void>;
	className: string;
	droppableId: string;
	/** `MM-DD-YYYY` */
	dueDate: string;
	forceUnlockOrder: (workOrderId: string) => void;
	hasPermissionsToEditScheduleBoard: boolean;
	isDragAndDropDisabled: boolean;
	lastOpenedOrderCode: string;
	removeBlankWorkOrder: (dueDate: string, index: number) => Promise<void>;
	rowIndex: number;
}

type Props = OwnProps & ConnectedProps<typeof connector>;

const DroppableComponent: React.FC<Props> = (props) => {
	const {
		addBlankWorkOrder,
		deselectOrder,
		dueDate,
		forceUnlockOrder,
		hasPermissionsToEditScheduleBoard,
		isDragAndDropDisabled,
		lastOpenedOrderCode,
		removeBlankWorkOrder,
		selectOrder,
		startIndex,
		workOrderCodes = [],
		droppableId,
		isDisabled,
		className,
	} = props;

	const renderCards = React.useCallback(() => {

		return (
			<div className="schedule-board-cards-container">
				{workOrderCodes.map((workOrderCode: string, index: number) => {
					const isBlank = ScheduleBoardUtil.isBlankWorkOrderId(workOrderCode);
					const isPlaceholder = ScheduleBoardUtil.isLoadingPlaceholderDroppableId(workOrderCode);
					return isBlank || isPlaceholder ?
						(
							<BlankCard
								draggableId={ScheduleBoardUtil.generateBlankWorkOrderId(dueDate, startIndex + index)}
								dueDate={dueDate}
								hasPermissionsToEditScheduleBoard={hasPermissionsToEditScheduleBoard}
								index={startIndex + index}
								isDragAndDropDisabled={isDragAndDropDisabled}
								isPlaceholder={isPlaceholder}
								key={index}
								removeBlankWorkOrder={removeBlankWorkOrder}
							/>
						) : (
							<Card
								addBlankWorkOrder={addBlankWorkOrder}
								deselectOrder={deselectOrder}
								dueDate={dueDate}
								forceUnlockOrder={forceUnlockOrder}
								hasPermissionsToEditScheduleBoard={hasPermissionsToEditScheduleBoard}
								index={startIndex + index}
								isDragAndDropDisabled={isDragAndDropDisabled}
								key={index}
								lastOpenedOrderCode={lastOpenedOrderCode}
								selectOrder={selectOrder}
								workOrderCode={workOrderCode}
							/>
						);
				})}
			</div>
		);
	}, [
		addBlankWorkOrder,
		deselectOrder,
		dueDate,
		forceUnlockOrder,
		hasPermissionsToEditScheduleBoard,
		isDragAndDropDisabled,
		lastOpenedOrderCode,
		removeBlankWorkOrder,
		selectOrder,
		startIndex,
		workOrderCodes,
	]);

	return (
		<Droppable
			direction="horizontal"
			droppableId={droppableId}
			isDropDisabled={isDisabled}
		>
			{(provided, snapshot) => {
				return (
					<div
						{...(provided.droppableProps)}
						className={`${className} ${snapshot.isDraggingOver ? 'drag-over' : ''}`}
						ref={provided.innerRef}
					>
						{renderCards()}
						{provided.placeholder}
					</div>
				);
			}}
		</Droppable>
	);
};

function mapStateToProps(state: RootState, ownProps: OwnProps) {
	const { dueDate, rowIndex, isDragAndDropDisabled, hasPermissionsToEditScheduleBoard } = ownProps;
	const workOrdersRowDistribution = state?.scheduleBoard?.workOrdersByDateDictionary?.[dueDate]?.workOrdersRowDistribution ?? [];
	const workOrderCodes = workOrdersRowDistribution?.[rowIndex];
	const startIndex = ScheduleBoardUtil.getStartIndexForRow(workOrdersRowDistribution, rowIndex);

	return {
		isDisabled: !state.scheduleBoard.draggedWorkOrderCode || isDragAndDropDisabled || !hasPermissionsToEditScheduleBoard,
		workOrderCodes,
		startIndex,
	};
}

function mapDispatchToProps() {
	return {
		selectOrder: ScheduleBoardActions.selectWorkOrder,
		deselectOrder: ScheduleBoardActions.deselectWorkOrder,
	};
}

const connector = connect(mapStateToProps, mapDispatchToProps());

const enhance = compose<React.ComponentType<OwnProps>>(
	React.memo,
	connector
);

export default enhance(DroppableComponent);
