import * as React from 'react';
import { compose } from 'redux';
import type { ConnectedProps } from 'react-redux';
import { connect } from 'react-redux';
import type { InjectedFormProps } from 'redux-form';
import { reduxForm, formValueSelector, getFormValues } from 'redux-form';
import type { CustomRouteComponentProps } from 'react-router-dom';

import type { RootState } from 'af-reducers';

import { ITEM_UPDATE } from 'af-constants/reduxForms';
import CLIENT from 'af-constants/routes/client';

import * as ItemActions from 'af-actions/item';

import Breadcrumbs from 'af-components/Breadcrumbs';
import LoadingIndicator from 'af-components/LoadingIndicator';

import FormModel from '../Shared/formModel';
import Form from '../Shared/form';
import { validate } from '../Shared/validation';

interface PathParams {
	id: string;
}

type OwnProps = CustomRouteComponentProps<PathParams>;

type Props = OwnProps & ConnectedProps<typeof connector> & InjectedFormProps<FormModel>;

const Edit = (props: Props) => {
	const {
		findById,
		match: { params: { id } },
		initialize: _initialize,
		update,
		history,
		companyName,
		location: { state: { orgAlias } },
	} = props;

	const initialize = React.useCallback((form: FormModel) => {
		_initialize(form);
		// eslint-disable-next-line react-hooks/exhaustive-deps
	}, []); // Initialize changes itself once called, we don't want that behavior

	const [loading, setLoading] = React.useState(false);
	const [location, setLocation] = React.useState<FormModel['location']>({});
	const [imageUrl, setImageUrl] = React.useState<FormModel['imageUrl']>(null);

	const loadStuff = React.useCallback(async () => {
		setLoading(true);
		const item = await findById(+id);
		const form = FormModel.fromVM(item);
		initialize(form);
		setLocation(form.location);
		setImageUrl(form.imageUrl);
		setLoading(false);
	}, [findById, id, initialize]);

	React.useEffect(() => {
		loadStuff();
	}, [loadStuff]);

	const updateItem = React.useCallback(async (form: FormModel) => {
		await update(form.id, FormModel.toRequestModel(form));
		history.push(CLIENT.COMPANY.EQUIPMENT.ITEM.LIST(orgAlias, companyName));
	}, [companyName, history, orgAlias, update]);

	return (
		<div className="form-segment">
			<Breadcrumbs
				items={[
					{ label: 'Equipment', url: CLIENT.COMPANY.EQUIPMENT.ITEM.LIST(orgAlias, companyName) },
					{ label: 'Accessory' },
				]}
			/>
			{loading ? (
				<div className="form-box form-box--loading-only">
					<LoadingIndicator color="orange" size="big" />
				</div>
			) : (
				<Form
					{...props}
					existingImageUrl={imageUrl}
					initialLocation={location}
					onSubmit={updateItem}
				/>
			)}
		</div>
	);
};

const formSelector = formValueSelector(ITEM_UPDATE);

const getForm = getFormValues(ITEM_UPDATE);

interface FormActionWrapper {
	selector: <T extends keyof FormModel>(fieldName: T) => FormModel[T];
}
const formActionWrapper: Partial<FormActionWrapper> = {
	selector: undefined,
};

function mapStateToProps(state: RootState) {
	const { companyData } = state.user;
	if (!companyData) {
		throw new Error('User not logged in');
	}

	formActionWrapper.selector = (fieldName: keyof FormModel) => formSelector(state, fieldName);

	return {
		formActionWrapper: formActionWrapper as FormActionWrapper, // selector is no longer undefined
		companyName: companyData.name,
		formValues: getForm(state) as Record<string, string>[], // Not directly used, but if missing component won't rerender when it should because of specific way this form works
	};
}

function mapDispatchToProps() {
	return {
		findById: ItemActions.findById,
		update: ItemActions.update,
	};
}

const connector = connect(mapStateToProps, mapDispatchToProps());

const enhance = compose<React.ComponentClass<OwnProps>>(
	connector,
	reduxForm({
		form: ITEM_UPDATE,
		validate,
		enableReinitialize: true,
		keepDirtyOnReinitialize: true,
	})
);

export default enhance(Edit);
