import * as React from 'react';
import type { RouteComponentProps} from 'react-router-dom';
import { Redirect } from 'react-router-dom';
import { connect } from 'react-redux';

import { LoginMethod } from 'ab-enums/auth.enum';

import type { RootState } from 'af-reducers';

import CLIENT from 'af-constants/routes/client';

import type * as User from 'ab-viewModels/user.viewModel';

import { defaultRedirectUrl } from 'af-utils/actions.util';

declare const window;

interface Props extends RouteComponentProps<void> {
	userData: User.UserData;
	companyData: User.CompanyData;
	organizationData: User.OrganizationData;
}

// eslint-disable-next-line @typescript-eslint/no-explicit-any
export default function (ComposedComponent: any) {
	class RestrictLoggedIn extends React.PureComponent<Props> {

		static defaultProps: Partial<Props> = {
			userData: {} as User.UserData,
			companyData: {} as User.CompanyData,
			organizationData: {} as User.OrganizationData,
		};

		render() {
			const { userData, companyData, organizationData, location } = this.props;

			// Previous location if user needs to be redirected
			window.redirectToLocation = location;

			if (userData?.isFinalized || (userData?.loginMethod === LoginMethod.PHONE)) {
				if (organizationData.isPlatformAdmin && location.pathname !== CLIENT.COMPANY.CREATE(organizationData.alias)) {
					// If we are using Acceligent Platform Admin account
					return React.createElement(Redirect, { to: CLIENT.PLATFORM.ALL_ORGANIZATIONS(organizationData.alias) });
				} else if (companyData?.name) {
					// If we are not using Platform Admin but have an Company we can redirect to
					return React.createElement(Redirect, {
						to: defaultRedirectUrl(organizationData.alias, companyData.name, companyData.permissions, companyData.isCompanyAdmin, userData.role),
					});
				} else {
					// We need to create a company first
					return React.createElement(Redirect, { to: CLIENT.COMPANY.CREATE(organizationData.alias) });
				}
			} else if (userData?.id) {
				// User has not yet been finalized or his password expired (after PASSWORD_MAX_AGE)
				if (location.pathname !== CLIENT.AUTH.FINALIZE(organizationData.alias) &&
					location.pathname !== CLIENT.AUTH.PASS_OUTDATED(organizationData.alias)) {
					if (userData?.isPasswordExpired) {
						return React.createElement(Redirect, { to: CLIENT.AUTH.PASS_OUTDATED(organizationData.alias) });
					}
					return React.createElement(Redirect, { to: CLIENT.AUTH.FINALIZE(organizationData.alias) });
				} else {
					return React.createElement(ComposedComponent, this.props);
				}
			} else {
				return React.createElement(ComposedComponent, this.props);
			}
		}

	}

	function mapStateToProps(state: RootState) {
		return {
			userData: state?.user?.userData,
			companyData: state?.user?.companyData,
			organizationData: state?.user?.organizationData,
		};
	}

	return connect(mapStateToProps, null)(RestrictLoggedIn);
}
