import * as React from 'react';

import type { OwnProps as TooltipProps } from 'af-components/Tooltip';

import Label from './Label';
import Value from './Value';

interface OwnProps {
	id?: string;
	label: string | JSX.Element;
	value: React.ReactNode;
	defaultValue?: React.ReactNode;
	renderLabel?: boolean;
	renderValue?: boolean;
	valueClassName?: Nullable<string>;
	labelClassName?: string;
	inverse?: boolean;
	tooltipMessage?: TooltipProps['message'];
	tooltipPlacement?: TooltipProps['placement'];
}

const LockedInput = ({
	id,
	label,
	value,
	defaultValue,
	valueClassName,
	labelClassName,
	inverse = false,
	tooltipMessage,
	tooltipPlacement,
	renderLabel = true,
	renderValue = true,
}: OwnProps) => {

	return (
		<>
			{
				renderLabel &&
				<Label
					className={labelClassName}
					inverse={inverse}
					label={label}
					tooltipMessage={tooltipMessage}
					tooltipPlacement={tooltipPlacement}
				/>
			}
			{
				renderValue &&
				<Value
					defaultValue={defaultValue}
					id={id}
					inverse={inverse}
					value={value}
					valueClassName={valueClassName}
				/>
			}
		</>
	);
};

export default React.memo(LockedInput);
