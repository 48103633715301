import * as React from 'react';

import DisabledFeatures from 'ab-common/environment/disabledFeatures';

import styles from './styles.module.scss';

interface OwnProps {
	className?: string;
	isProject: boolean;
	isSubJob: boolean;
}

type Props = OwnProps;

const ProjectSubJobIndicator: React.FC<Props> = (props) => {
	const { isProject, isSubJob, className } = props;

	const classNameModifier = isProject ? 'project' : 'subjob';
	const resolvedClassName = `${styles['project-sub-job-indicator']} ${styles[classNameModifier]} ${className ?? ''}`;
	const text = isProject ? 'Project' : 'Sub-Job';

	if ((!isProject && !isSubJob) || DisabledFeatures.Project) {
		return null;
	}

	return (
		<div className={resolvedClassName}>{text}</div>
	);
};

export default ProjectSubJobIndicator;
