import * as React from 'react';

import type { BillableWorkDefinitionFieldRM, BillableWorkRM } from 'ab-requestModels/reportType/reportType.requestModel';

import { bemElement } from 'ab-utils/bem.util';

import { useToggle } from 'af-utils/react.util';

interface Props {
	billableWork: BillableWorkRM;
	currentlyHighlightedBillableWorkIndex: Nullable<number>;
	index: number;
	onDelete: (index: number) => void;
	onEdit: (index: number) => void;
	setCurrentlyHighlightedBillableWorkIndex: (index: number) => void;
	setCurrentlyHighlightedFields?: (currentlyHighlightedBillableWork: BillableWorkRM) => void;
	definitionFields: BillableWorkDefinitionFieldRM[];
}

const resolveReportBlockFieldId = (id: string) => `report-block-field-${id}`;

function findFieldElementReducer(acc: HTMLElement[], field: BillableWorkRM['definitionFields' | 'informationFields'][0]) {
	const element = document.getElementById(resolveReportBlockFieldId(field.reportBlockFieldVirtualId));
	if (element) {
		acc.push(element);
	}
	return acc;
}

function fieldsToHighlightSort(elementA: HTMLElement, elementB: HTMLElement) {
	return elementA.getBoundingClientRect().top - elementB.getBoundingClientRect().top;
}

const definitionFieldsMapper = (df: BillableWorkRM['definitionFields'][0]) => (
	<span
		className={bemElement('report-block-form__billable-work__item__work-info__row', 'definition-field')}
		key={df.reportBlockFieldVirtualId}
	>
		{df.name}
	</span>
);

const BillableWorkListItem: React.FC<Props> = (props) => {
	const {
		billableWork,
		currentlyHighlightedBillableWorkIndex,
		index,
		onDelete,
		onEdit,
		setCurrentlyHighlightedBillableWorkIndex,
		setCurrentlyHighlightedFields,
		definitionFields: currentDefinitionFields,
	} = props;
	const { definitionFields, informationFields, workName, workQuantityReportBlockFieldVirtualId, workTypeReportBlockFieldVirtualId } = billableWork;

	const relatedPreviewFields = React.useRef<HTMLElement[]>([]);
	const currentlyScrolledToPreviewFieldIndex = React.useRef(0);

	const {
		value: isExpanded,
		setToTrue: expand,
		setToFalse: shrink,
	} = useToggle(false);

	const onHighlightClick = React.useCallback(() => {
		let fieldsToHighlight = [...relatedPreviewFields.current];
		if (!fieldsToHighlight.length) {
			const workQuantityReportBlockField = workQuantityReportBlockFieldVirtualId
				? document.getElementById(resolveReportBlockFieldId(workQuantityReportBlockFieldVirtualId))
				: null;
			const workTypeReportBlockField = document.getElementById(resolveReportBlockFieldId(workTypeReportBlockFieldVirtualId));
			const definitionFieldFields = definitionFields.reduce(findFieldElementReducer, []);
			const informationFieldFields = informationFields.reduce(findFieldElementReducer, []);

			if (!workTypeReportBlockField) {
				return;
			}
			fieldsToHighlight = [workTypeReportBlockField, ...definitionFieldFields, ...informationFieldFields];
			if (workQuantityReportBlockField && workTypeReportBlockFieldVirtualId !== workQuantityReportBlockFieldVirtualId) {
				fieldsToHighlight.push(workQuantityReportBlockField);
			}
			fieldsToHighlight = fieldsToHighlight.filter(Boolean);
			// sort block fields by their top position in review to make scrolling sequential
			fieldsToHighlight.sort(fieldsToHighlightSort);
		}

		relatedPreviewFields.current = fieldsToHighlight;
		setCurrentlyHighlightedFields?.(billableWork);
		setCurrentlyHighlightedBillableWorkIndex(index);

		let currentIndex = currentlyScrolledToPreviewFieldIndex.current;
		fieldsToHighlight[currentIndex].scrollIntoView({ behavior: 'smooth', block: 'nearest' });

		currentlyScrolledToPreviewFieldIndex.current = ++currentIndex;
		if (currentIndex >= fieldsToHighlight.length) {
			currentlyScrolledToPreviewFieldIndex.current = 0;
		}

	}, [
		billableWork,
		definitionFields,
		index,
		informationFields,
		setCurrentlyHighlightedBillableWorkIndex,
		setCurrentlyHighlightedFields,
		workQuantityReportBlockFieldVirtualId,
		workTypeReportBlockFieldVirtualId,
	]);

	const handleDelete = React.useCallback(() => {
		onDelete(index);
	}, [index, onDelete]);

	const handleEdit = React.useCallback(() => {
		onEdit(index);
	}, [index, onEdit]);

	const isCurrentlyHighlighted = index === currentlyHighlightedBillableWorkIndex;
	const expandIconName = isExpanded ? 'icon-up' : 'icon-down';
	const showExpandIcon = currentDefinitionFields.length > 0;

	return (
		<div className={bemElement('report-block-form', 'billable-work__item')}>
			<div className={bemElement('report-block-form__billable-work__item', 'work-info')}>
				<div className={bemElement('report-block-form__billable-work__item__work-info', 'row')}>
					{workName}
					{showExpandIcon && <span className={expandIconName} onClick={isExpanded ? shrink : expand} />}
				</div>
				{isExpanded && (
					<div className={bemElement('report-block-form__billable-work__item__work-info', 'row')}>
						{currentDefinitionFields.map(definitionFieldsMapper)}
					</div>
				)}
			</div>
			<span
				className={bemElement('report-block-form__billable-work__item', 'highlight-button', { 'highlighted': isCurrentlyHighlighted })}
				onClick={onHighlightClick}
			>
				Find on Form
			</span>
			<span className={`icon-delete ${bemElement('report-block-form', 'icon')}`} onClick={handleDelete} />
			<span className={`icon-edit ${bemElement('report-block-form', 'icon')}`} onClick={handleEdit} />
		</div>
	);
};

export default React.memo(BillableWorkListItem);
