import * as React from 'react';

import type ItemCategoryVM from 'ab-viewModels/itemCategory/itemCategory.viewModel';

import Pill from 'af-components/Pills/Pill';

interface OwnProps {
	category: ItemCategoryVM;
	edit: (id: number) => void;
	remove: (id: number) => void;
}

type Props = OwnProps;

const Preview = (props: Props) => {
	const {
		category,
		edit,
		remove,
	} = props;

	const onClick = React.useCallback(() => {
		edit(category.id);
	}, [edit, category.id]);

	const onRemove = React.useCallback(() => {
		remove(category.id);
	}, [remove, category.id]);

	const modalBody = React.useCallback(() => (
		<>
			You are about to delete category <b>{category.name}</b>.
			Are you sure you want to continue?
		</>
	), [category.name]);

	return (
		<Pill
			item={category}
			labelKey="name"
			modalBody={modalBody}
			modalTitle="Delete Category"
			onClick={onClick}
			removeItem={onRemove}
		/>
	);
};

export default Preview;
