import type { CustomFormErrors, FormErrors, FormErrorsWithArray } from 'redux-form';

import TimeFormat from 'acceligent-shared/enums/timeFormat';

import * as TimeUtils from 'acceligent-shared/utils/time';
import { isValidEmail } from 'acceligent-shared/utils/email';

import type InvoiceStatusDisplay from 'ab-enums/invoiceStatusDisplay.enum';

import type InstallmentRM from 'ab-requestModels/invoice/installment/installment.requestModel';

import type InvoiceVM from 'ab-viewModels/workRequest/invoice.viewModel';
import type InvoicedInvoiceRM from 'ab-requestModels/invoice/invoicedInvoiceUpdate.requestModel';
import type FileType from 'acceligent-shared/enums/fileType';
import type ResourceStatus from 'acceligent-shared/enums/resourceStatus';
import InvoiceBillingLookupType from 'acceligent-shared/enums/invoiceBillingLookupType';
import type { PhoneTypes } from 'acceligent-shared/enums/contactMethodType';
import { EmailTypes, EmailTypesArray } from 'acceligent-shared/enums/contactMethodType';
import { filterContactMethod } from 'acceligent-shared/utils/contact';

class InstallmentFM {
	id: number | string;
	invoiceId: number;
	instNumber: number;
	amount: Nullable<string>;
	/** YYYY-MM-DD */
	datePaid: Nullable<string>;
	note: Nullable<string>;

	static validate = (form: InstallmentFM): FormErrors<InstallmentFM> => {

		const errors: FormErrors<InstallmentFM> = {};

		if (form.amount === null || form.amount === undefined) {
			errors.amount = 'Amount is required';
		}

		if (!form.datePaid) {
			errors.datePaid = 'Date paid is required';
		} else if (form.datePaid && !TimeUtils.isDateInCorrectFormat(form.datePaid, TimeFormat.DB_DATE_ONLY)) {
			errors.datePaid = 'Date paid is not in YYYY-MM-DD format';
		}

		return errors;
	};

	static fromFMtoRM(fm: InstallmentFM): InstallmentRM {

		if (fm.invoiceId === null || fm.invoiceId === undefined || !fm.datePaid || fm.amount === undefined || fm.amount === null) {
			throw new Error('Installment is not filled out correctly');
		}

		return {
			invoiceId: fm.invoiceId,
			amount: fm.amount,
			datePaid: fm.datePaid,
			note: fm.note,
		};
	}
}

interface UserInfoFM {
	accountId?: Nullable<number>;
	userId?: number;
	firstName?: string;
	lastName?: string;
	fullName: string;
}

export class AttachmentFM {
	id: number;
	size: number;
	fileName: string;
	type: FileType;
	status: ResourceStatus;
	storageName: string;
	storageContainer: string;
	/** original version with presigned url */
	originalSrc: string;
	lastModifiedAt: Date;
	uploadedBy: Nullable<UserInfoFM>;
}

const validateBillingContact = (form: Nullable<InvoiceBillingContactFM>): FormErrors<InvoiceBillingContactFM> => {
	const errors: Record<string, unknown> = {};

	switch (form?.type) {
		case InvoiceBillingLookupType.CONTACT: {
			if (!form.contact.contactEmailIds.length) {
				errors.type = InvoiceBillingLookupType.CONTACT;
				errors.contact = {
					contactEmailIds: ['At least one Email must be selected.'],
				};
			}
			break;
		}
		case InvoiceBillingLookupType.EMAIL_ONLY: {
			if (!form.email.includes('@')) {
				errors.type = InvoiceBillingLookupType.EMAIL_ONLY;
				errors.email = 'Please use email format: user.name@email.com';
			} else if (!isValidEmail(form.email)) {
				errors.type = InvoiceBillingLookupType.EMAIL_ONLY;
				errors.email = 'Invalid Email.';
			}
			break;
		}
		default: {
			errors.email = 'No Contact selected';
			break;
		}
	}

	return errors as FormErrors<InvoiceBillingContactFM>;
};

class ContactMethodFM {
	id: number;
	contactId: number;
	type: PhoneTypes | EmailTypes;
	value: string;

	constructor(contactMethod) {
		this.id = contactMethod.id;
		this.contactId = contactMethod.contactId;
		this.type = contactMethod.type;
		this.value = contactMethod.value;
	}

	static toList(contactMethods, type: typeof EmailTypes | typeof PhoneTypes): ContactMethodFM[] {
		return contactMethods
			.filter((_method) => _method && Object.keys(type).includes(_method.type))
			.map((_method) => new ContactMethodFM(_method));
	}
}

class ContactFM {
	id?: number;
	contactId: number;
	title: Nullable<string>;
	fullName: string;
	companyName: Nullable<string>;
	emails: ContactMethodFM[];
	contactEmailIds: number[];

	constructor(_contactLookup) {
		const _contact = _contactLookup?.contact;

		this.id = _contactLookup.id;
		this.contactId = _contactLookup.contactId;
		this.title = _contact?.title;
		this.fullName = _contact?.fullName;
		this.companyName = _contact?.companyName;

		this.emails = ContactMethodFM.toList(_contact?.contactMethods ?? [], EmailTypes);

		this.contactEmailIds = filterContactMethod(_contactLookup.contactLookupMethods, EmailTypesArray);
	}

	static bulkConstructor = (_contactLookups) => _contactLookups.map((__contactLookup) => new ContactFM(__contactLookup));
}

type BillingEmail = {
	type: InvoiceBillingLookupType.EMAIL_ONLY;
	email: string;
};

export type BillingContact = {
	contact: ContactFM;
	type: InvoiceBillingLookupType.CONTACT;
};

type InvoiceBillingContactFM = { id?: number; } & (BillingEmail | BillingContact);

export class InvoiceFM {
	id: number;
	invoiceCode: string;
	/** YYYY-MM-DD */
	dateCreated: string;
	/** YYYY-MM-DD */
	dueDate: string;
	status: InvoiceStatusDisplay;
	note: Nullable<string>;
	totalAmount: number;
	paidAmount: number;
	outstandingDebt: number;
	retainedAmount: number;
	billingContacts: Nullable<InvoiceBillingContactFM>[];
	sendReminderOnInvoice: boolean;
	excludeFromAutomaticReminders: boolean;
	installments: InstallmentFM[];
	attachments: AttachmentFM[];
	uploadedAttachmentIds?: number[];
	uploadingAttachments?: Record<string, true>;

	static getAttributeName = (attribute: keyof InvoiceFM) => attribute;

	static recalculatePaidAmount = (installments: InstallmentFM[]) => {
		return installments.reduce((_sum, _inst) => {
			_sum += (+(_inst.amount ?? 0));
			return _sum;
		}, 0);
	};

	static fromVMtoFM(vm: InvoiceVM): InvoiceFM {
		return {
			id: vm.id,
			invoiceCode: vm.invoiceCode,
			status: vm.status,
			dateCreated: vm.dateCreated,
			dueDate: vm.dueDate,
			totalAmount: vm.totalAmount,
			paidAmount: vm.paidAmount,
			outstandingDebt: vm.outstandingDebt,
			retainedAmount: vm.retainedAmount,
			sendReminderOnInvoice: vm.sendReminderOnInvoice,
			excludeFromAutomaticReminders: vm.excludeFromAutomaticReminders,
			billingContacts: vm.billingContacts?.map((_lookup) => {
				switch (_lookup.type) {
					case InvoiceBillingLookupType.EMAIL_ONLY: {
						return {
							id: _lookup.id,
							type: InvoiceBillingLookupType.EMAIL_ONLY,
							email: _lookup.email,
						};
					}
					case InvoiceBillingLookupType.CONTACT: {
						return {
							id: _lookup.id,
							type: InvoiceBillingLookupType.CONTACT,
							contact: _lookup.contact,
						};
					}
				}
			}) ?? [],
			installments: vm.installments?.reverse().map((_inst, _ind) => {
				return { ..._inst, invoiceId: vm.id, editable: false, instNumber: (_ind + 1) };
			}) ?? [],
			note: vm.note,
			attachments: vm.attachments.map((_att) => { return { ..._att, fileName: _att.name }; }) ?? [],
			uploadedAttachmentIds: vm.uploadedAttachmentIds ?? [],
		};
	}

	static fromFMtoRM(fm: InvoiceFM): InvoicedInvoiceRM {
		return {
			id: fm.id,
			retainedAmount: fm.retainedAmount?.toString() ?? '0',
			excludeFromAutomaticReminders: fm.excludeFromAutomaticReminders,
			installments: fm.installments?.map(InstallmentFM.fromFMtoRM) ?? null,
			uploadedAttachmentIds: fm.uploadedAttachmentIds ?? [],
			billingContacts: fm.billingContacts.reduce<InvoicedInvoiceRM['billingContacts']>((_acc, _invoiceFM) => {
				if (!_invoiceFM) {
					return _acc;
				}

				switch (_invoiceFM.type) {
					case InvoiceBillingLookupType.EMAIL_ONLY: {
						_acc.push({
							id: _invoiceFM.id,
							type: InvoiceBillingLookupType.EMAIL_ONLY,
							email: _invoiceFM.email,
						});
						break;
					}
					case InvoiceBillingLookupType.CONTACT: {
						_acc.push({
							id: _invoiceFM.id,
							type: InvoiceBillingLookupType.CONTACT,
							contact: {
								id: _invoiceFM.contact.id,
								contactId: _invoiceFM.contact.contactId,
								contactEmailIds: _invoiceFM.contact.contactEmailIds,
							},
						});
						break;
					}
				}

				return _acc;
			}, [] as InvoicedInvoiceRM['billingContacts']) ?? [],
			totalAmount: fm.totalAmount,
		};
	}

	static _checkBillingContactErrors = (_error) => !Object.keys(_error ?? {}).length;

	static validate = (form: InvoiceFM): FormErrors<InvoiceFM> => {

		const errors: CustomFormErrors<InvoiceFM> = {};

		errors.installments = form?.installments?.reduce((_acc, _entry) => {
			const validatedEntry = InstallmentFM.validate(_entry);
			_acc.push(validatedEntry);
			return _acc;
		}, [] as FormErrors<InstallmentFM>[]);

		errors.billingContacts = form?.billingContacts?.reduce((_acc, _entry) => {
			const validatedEntry = validateBillingContact(_entry);
			_acc.push(validatedEntry);
			return _acc;
		}, [] as FormErrors<InvoiceBillingContactFM>[]);

		// redux-form is not recognizing empty object as no error
		if (errors.billingContacts?.every(InvoiceFM._checkBillingContactErrors)) {
			errors.billingContacts = undefined;
		}

		if (!!form.uploadingAttachments && Object.keys(form.uploadingAttachments).length) {
			errors.uploadingAttachments = 'Attachment upload in progress.';
		}

		return errors as FormErrors<InvoiceFM>;
	};

	static _hasInvalidBillingContact = (_billingContactError) => {
		return !!_billingContactError ? !!Object.keys(_billingContactError).length : false;
	};

	static saveFormEnabled = (form: InvoiceFM, errors: FormErrorsWithArray<InvoiceFM, string>, areThereInstallmentsInEditMode: boolean): boolean => {
		if (!form) {
			return false;
		}
		if (!form.invoiceCode || !form.dateCreated || !form.dueDate || form.totalAmount === undefined || form.totalAmount === null) {
			return false;
		}

		const areBillingContactsInvalid = errors.billingContacts?.some(InvoiceFM._hasInvalidBillingContact);

		if (areBillingContactsInvalid) {
			return false;
		}

		const thereAreAttachmentsInUpload = !!form.uploadingAttachments && Object.keys(form.uploadingAttachments).length;

		return !areThereInstallmentsInEditMode && !thereAreAttachmentsInUpload;
	};

}
