import * as React from 'react';
import { compose } from 'redux';
import type { ConnectedProps } from 'react-redux';
import { connect } from 'react-redux';
import { Button, Table } from 'react-bootstrap';

import SubscriptionStatus from 'acceligent-shared/enums/subscriptionStatus';
import AutoNotifyOption from 'acceligent-shared/enums/autoNotifyOption';
import type { ExtendedColorValue} from 'acceligent-shared/enums/color';
import { DefaultColor } from 'acceligent-shared/enums/color';
import NotificationStatusEnum from 'acceligent-shared/enums/notificationStatus';
import WorkOrderStatus from 'acceligent-shared/enums/workOrderStatus';
import TimeFormatEnum from 'acceligent-shared/enums/timeFormat';

import * as TimeUtils from 'acceligent-shared/utils/time';
import * as TimeOptionUtils from 'acceligent-shared/utils/timeOption';

import * as EmployeeActions from 'af-actions/employee';

import Loading from 'af-root/scenes/Company/ScheduleBoard/Shared/LoadingModal';

import { NOTIFY_EMPLOYEE_TYPE, DAILY_TIP_MAX_LENGTH } from 'af-constants/values';

import type { LatestNotificationsByEmployeeViewModel } from 'ab-viewModels/latestNotificationsForEmployees.viewModel';
import type { NotificationStatusByEmployee, NotificationStatusByTemporaryEmployee } from 'ab-viewModels/notification.viewModel';
import type ScheduleBoardEmployee from 'ab-viewModels/scheduleBoardEmployee.viewModel';
import type ScheduleBoardTemporaryEmployee from 'ab-viewModels/scheduleBoardTemporaryEmployee.viewModel';
import type { WorkOrderResourceLookupModel } from 'ab-viewModels/scheduleBoardWorkOrderModal.viewModel';
import type WorkOrderModalViewModel from 'ab-viewModels/scheduleBoardWorkOrderModal.viewModel';
import type { WorkOrderTemporaryEmployeeVM } from 'ab-viewModels/workOrder.viewModel';
import { WorkOrderViewModel } from 'ab-viewModels/workOrder.viewModel';
import type WorkOrderEmployeeVM from 'ab-viewModels/workOrderEmployee.viewModel';
import type { WorkOrderRevisionEmployee, WorkOrderRevisionTemporaryEmployee } from 'ab-viewModels/workOrderRevisionItem.viewModel';

import type { EmployeeNotifyModalState, TempEmployeeNotifyModalState, EmployeeNotificationStatusDict, TemporaryEmployeeNotificationStatusDict} from 'af-models/employeeNotifyModal.model';
import { getNotifyModalEmployeeFromEmployee, getNotifyModalTemporaryEmployeeFromTemporaryEmployee } from 'af-models/employeeNotifyModal.model';

import Checkbox from 'af-components/Controls/Checkbox';
import CustomModal from 'af-components/CustomModal';
import Textarea from 'af-components/Fields/PlainTextArea';
import EmployeeList from 'af-components/NotifyModal/EmployeeList';
import TemporaryEmployeeList from 'af-components/NotifyModal/TemporaryEmployeeList';
import TableHeader from 'af-components/NotifyModal/TableHeader';
import SubmitButton from 'af-components/SubmitButton';

import * as ColorUtils from 'ab-utils/color.util';
import { createExistsMap } from 'ab-utils/array.util';
import { useToggle } from 'af-utils/react.util';

const SMS_LENGTH_LIMIT = 1550; // it's 1600 but just to be safe we'll set it to 1550

interface OwnProps {
	order: WorkOrderModalViewModel | WorkOrderViewModel;
	participantsEmailAndSmsStatus: NotificationStatusByEmployee;
	temporaryParticipantsEmailAndSmsStatus: Nullable<NotificationStatusByTemporaryEmployee>;
	scheduleBoardEmployees: Nullable<{ [employeeId: number]: ScheduleBoardEmployee; }>;
	scheduleBoardTemporaryEmployees: Nullable<{ [employeeId: number]: ScheduleBoardTemporaryEmployee; }>;
	revisionEmployees: { [revisionId: number]: WorkOrderRevisionEmployee[]; };
	revisionTemporaryEmployees: { [revisionId: number]: WorkOrderRevisionTemporaryEmployee[]; };
	workOrderEmployees: { [workOrderEmployeeId: number]: number; };
	workOrderTemporaryEmployees: { [workOrderTemporaryEmployeeId: number]: number; };
	showModal: boolean;
	isLoading?: boolean;
	autoNotifyOnPublish: Nullable<AutoNotifyOption>;
	autoNotifyAt: Nullable<number>;
	notifyTemporaryLabor: boolean;
	dailyTip: string;
	notificationTemplate: string;
	closeModal: () => void;
	notifyParticipants: (
		workOrderEmployeeIdsForSms: number[],
		workOrderEmployeeIdsForEmail: number[],
		workOrderTemporaryEmployeeIdsForSms: number[],
		workOrderEmployeeTemporaryIdsForEmail: number[],
		workOrderId: number,
		message?: string,
		isEdited?: boolean
	) => Promise<void>;
	scheduleAutoNotify: (
		workOrderEmployeeIdsForSms: number[],
		workOrderEmployeeIdsForEmail: number[],
		workOrderTemporaryEmployeeIdsForSms: number[],
		workOrderEmployeeTemporaryIdsForEmail: number[],
		message?: string,
		/** Map of employee ids -> work order ids removed from WO */
		removedEmployeeIdsMap?: { [employeeId: number]: number; },
		/** Map of employee ids -> work order ids removed from WO */
		removedTemporaryEmployeeIdsMap?: { [temporaryEmployeeId: number]: number; },
	) => Promise<void>;
}

type Props = OwnProps & ConnectedProps<typeof connector>;

interface NotificationIdsReducer {
	emailIds: number[];
	smsIds: number[];
	removedEmployeeIdsMap: { [employeeId: number]: number; };
}

const _getNotificationIdReducer = (workOrderId: number) => {
	return (acc: NotificationIdsReducer, employee: EmployeeNotifyModalState | TempEmployeeNotifyModalState) => {
		if (employee.shouldSendEmail) {
			acc.emailIds.push(employee.id);
			if (!employee.isAssignedToWO) {
				acc.removedEmployeeIdsMap[employee.id] = workOrderId;
			}
		}
		if (employee.shouldSendSms) {
			acc.smsIds.push(employee.id);
			if (!employee.isAssignedToWO) {
				acc.removedEmployeeIdsMap[employee.id] = workOrderId;
			}
		}
		return acc;
	};
};

type NotificationIdsMap = { sendEmailToIds: number[]; sendSMSToIds: number[]; };

const _notificationIdReducer = (acc: NotificationIdsMap, employee: EmployeeNotifyModalState | TempEmployeeNotifyModalState) => {
	if (employee.shouldSendEmail) {
		acc.sendEmailToIds.push(employee.id);
	}
	if (employee.shouldSendSms) {
		acc.sendSMSToIds.push(employee.id);
	}
	return acc;
};

const NotifyParticipantsModal: React.FC<Props> = (props) => {

	const {
		order,
		participantsEmailAndSmsStatus,
		temporaryParticipantsEmailAndSmsStatus,
		dailyTip,
		scheduleBoardEmployees,
		scheduleBoardTemporaryEmployees = {},
		workOrderEmployees,
		workOrderTemporaryEmployees,
		closeModal,
		notifyParticipants,
		scheduleAutoNotify,
		notificationTemplate,
		autoNotifyAt,
		autoNotifyOnPublish,
		notifyTemporaryLabor,
		showModal,
		isLoading,
		revisionEmployees,
		revisionTemporaryEmployees,
		findNotificationStatusForRevisionEmployees,
	} = props;

	const [
		employees,
		setEmployees,
	] = React.useState<EmployeeNotificationStatusDict>({});

	const [
		temporaryEmployees,
		setTemporaryEmployees,
	] = React.useState<TemporaryEmployeeNotificationStatusDict>({});

	const [
		message,
		setMessage,
	] = React.useState<string>('');

	const [
		revisionEmployeesNotifications,
		setRevisionEmployeesNotifications,
	] = React.useState<Nullable<LatestNotificationsByEmployeeViewModel>>(null);

	const {
		value: isSending,
		setToTrue: beginSending,
		setToFalse: endSending,
	} = useToggle(false);

	const {
		value: isResend,
		setToTrue: setIsResend,
		setToFalse: setIsNotResend,
	} = useToggle(false);

	const {
		value: includeTip,
		toggle: toggleIncludeTip,
	} = useToggle(true);

	const {
		value: isMessageEdited,
		setToTrue: setMessageIsEdited,
		setToFalse: setMessageIsNotEdited,
	} = useToggle(false);

	const workOrderId = React.useMemo(() => (order instanceof WorkOrderViewModel
		? order.id
		: order.workOrderId ?? order.id
	), [order]);

	// these employees are the ones that only appear in previous revisions of work order, and are not currently assigned to it
	// and we only take info from last revision they appear in
	const revisionEmployeesLastVersion = React.useMemo(() => {
		const workOrderEmployeesIds = order instanceof WorkOrderViewModel
			? order.workOrderEmployees.map((_emp) => _emp.id)
			: order.workOrderResourceLookups.map((_worl) => _worl.employeeId);

		const employeeExistsInWorkOrder = createExistsMap(workOrderEmployeesIds, (_id) => _id!);

		return (
			Object.keys(revisionEmployees).reduce<{ [employeeId: number]: WorkOrderRevisionEmployee & { revisionId: number; }; }>(
				(_relv, _revisionId) => {
					const currentRevisionEmployees: WorkOrderRevisionEmployee[] = revisionEmployees[+_revisionId];

					currentRevisionEmployees.forEach((_revEmp) => {
						// if user is not currently in Work Order
						// and current revision of employee is greater than one in dict
						if (
							!employeeExistsInWorkOrder[_revEmp.employeeId] && // emp not on current wo version
							(
								!_relv[_revEmp.employeeId] // we haven't added to accumulator
								|| _relv[_revEmp.employeeId].revisionId < +_revisionId // we have add it but there is newer revision for that emp
							)
						) {
							_relv[_revEmp.employeeId] = {
								..._revEmp,
								revisionId: +_revisionId,
							};
						}
					});
					return _relv;
				}, {})
		);
	}, [order, revisionEmployees]);

	// these temp employees are the ones that only appear in previous revisions of work order, and are not currently assigned to it
	// and we only take info from last revision they appear in
	const revisionTemporaryEmployeesLastVersion = React.useMemo(() => {
		const workOrderTemporaryEmployeesIds = order instanceof WorkOrderViewModel
			? order.workOrderTemporaryEmployees.map((_emp) => _emp.id)
			: order.workOrderResourceLookups.map((_worl) => _worl.temporaryEmployeeId);

		const temporaryEmployeeExistsInWorkOrder = createExistsMap(workOrderTemporaryEmployeesIds, (_id) => _id!);

		return (
			Object.keys(revisionTemporaryEmployees).reduce<{ [temporaryEmployeeId: number]: WorkOrderRevisionTemporaryEmployee & { revisionId: number; }; }>(
				(_relv, _revisionId) => {
					const currentRevisionTemporaryEmployees: WorkOrderRevisionTemporaryEmployee[] = revisionTemporaryEmployees[+_revisionId];

					currentRevisionTemporaryEmployees.forEach((_revEmp) => {
						// if user is not currently in Work Order
						// and current revision of temporary employee is greater than one in dict
						if (
							!temporaryEmployeeExistsInWorkOrder[_revEmp.temporaryEmployeeId] && // emp not on current wo version
							(
								!_relv[_revEmp.temporaryEmployeeId] // we haven't added to accumulator
								|| _relv[_revEmp.temporaryEmployeeId].revisionId < +_revisionId // we have add it but there is newer revision for that emp
							)
						) {
							_relv[_revEmp.temporaryEmployeeId] = {
								..._revEmp,
								revisionId: +_revisionId,
							};
						}
					});
					return _relv;
				}, {})
		);
	}, [order, revisionTemporaryEmployees]);

	React.useEffect(() => {
		let isMounted = true;

		const getRevisionEmployeesNotifications = async () => {
			const employeesIds = Object.values(revisionEmployeesLastVersion).map((_emp) => _emp.employeeId);

			const _notifications = await findNotificationStatusForRevisionEmployees(employeesIds, workOrderId);

			if (isMounted) {
				setRevisionEmployeesNotifications(_notifications);
			}
		};

		getRevisionEmployeesNotifications();

		return () => {
			isMounted = false;
		};
	}, [revisionEmployeesLastVersion, order, findNotificationStatusForRevisionEmployees, workOrderId]);

	React.useEffect(() => {
		if (!showModal) {
			return;
		}
		let employeeMap: Nullable<EmployeeNotificationStatusDict> = null;
		let tempEmployeeMap: Nullable<TemporaryEmployeeNotificationStatusDict> = null;

		let newIsResend = false;
		if (order instanceof WorkOrderViewModel) {
			const shouldNotifyTempLabor = notifyTemporaryLabor && !order.excludeTempLaborFromNotify;

			if (!order?.workOrderEmployees && (shouldNotifyTempLabor && !order?.workOrderTemporaryEmployees)) {
				return;
			}

			employeeMap = order.workOrderEmployees.reduce((_acc: EmployeeNotificationStatusDict, _woe: WorkOrderEmployeeVM) => {
				const existingEmployee = employees?.[_woe.employeeId];
				if (
					(existingEmployee?.shouldSendEmail && existingEmployee?.lastEmailSentAt)
					|| (existingEmployee?.shouldSendSms && existingEmployee?.lastSmsSentAt)
				) {
					newIsResend = true;
				}
				const employee = scheduleBoardEmployees?.[workOrderEmployees?.[_woe.id]];
				if (!employee) {
					return _acc;
				}
				_acc[_woe.employeeId] = {
					...getNotifyModalEmployeeFromEmployee(
						_woe.employeeId,
						employee,
						participantsEmailAndSmsStatus?.[_woe.id]
					),
					shouldSendEmail: existingEmployee?.email ? existingEmployee.shouldSendEmail : false,
					shouldSendSms: existingEmployee?.phoneNumber ? existingEmployee.shouldSendSms : false,
				};
				return _acc;
			}, {} as EmployeeNotificationStatusDict);

			if (shouldNotifyTempLabor) {
				tempEmployeeMap = order.workOrderTemporaryEmployees.reduce<TemporaryEmployeeNotificationStatusDict>(
					(_acc, _wote: WorkOrderTemporaryEmployeeVM) => {
						const existingEmployee = temporaryEmployees?.[_wote.temporaryEmployeeId];
						if (
							(existingEmployee?.shouldSendEmail && existingEmployee?.lastEmailSentAt)
							|| (existingEmployee?.shouldSendSms && existingEmployee?.lastSmsSentAt)
						) {
							newIsResend = true;
						}
						const tempEmployee = scheduleBoardTemporaryEmployees?.[workOrderTemporaryEmployees?.[_wote.id]];
						if (!tempEmployee) {
							return _acc;
						}
						_acc[_wote.temporaryEmployeeId] = {
							...getNotifyModalTemporaryEmployeeFromTemporaryEmployee(
								_wote.temporaryEmployeeId,
								tempEmployee,
								temporaryParticipantsEmailAndSmsStatus?.[_wote.id] ?? null
							),
							shouldSendEmail: existingEmployee?.email ? existingEmployee.shouldSendEmail : false,
							shouldSendSms: existingEmployee?.phoneNumber ? existingEmployee.shouldSendSms : false,
						};
						return _acc;
					}, {});
			}

		} else {
			if (!order?.workOrderResourceLookups) {
				return;
			}

			const {
				employeeMap: _employeeMap,
				tempEmployeeMap: _tempEmployeeMap,
			} = order.workOrderResourceLookups.reduce((_acc, _worl: WorkOrderResourceLookupModel) => {
				if (_worl.workOrderEmployeeId && _worl.employeeId) {
					const existingEmployee = employees?.[_worl.employeeId];
					if (
						(existingEmployee?.shouldSendEmail && existingEmployee?.lastEmailSentAt)
						|| (existingEmployee?.shouldSendSms && existingEmployee?.lastSmsSentAt)
					) {
						newIsResend = true;
					}
					const employee = scheduleBoardEmployees?.[workOrderEmployees?.[_worl.workOrderEmployeeId]];
					if (!employee) {
						return _acc;
					}
					_acc.employeeMap[_worl.employeeId] = {
						...getNotifyModalEmployeeFromEmployee(
							_worl.workOrderEmployeeId,
							employee,
							participantsEmailAndSmsStatus?.[_worl.workOrderEmployeeId]
						),
						shouldSendEmail: existingEmployee?.email ? existingEmployee.shouldSendEmail : false,
						shouldSendSms: existingEmployee?.phoneNumber ? existingEmployee.shouldSendSms : false,
					};
				} else if (notifyTemporaryLabor && (_worl.workOrderTemporaryEmployeeId && _worl.temporaryEmployeeId)) {
					const existingEmployee = temporaryEmployees?.[_worl.temporaryEmployeeId];
					if (
						(existingEmployee?.shouldSendEmail && existingEmployee?.lastEmailSentAt)
						|| (existingEmployee?.shouldSendSms && existingEmployee?.lastSmsSentAt)
					) {
						newIsResend = true;
					}
					const tempEmployee = scheduleBoardTemporaryEmployees?.[workOrderTemporaryEmployees?.[_worl.workOrderTemporaryEmployeeId]];
					if (!tempEmployee) {
						return _acc;
					}
					_acc.tempEmployeeMap[_worl.temporaryEmployeeId] = {
						...getNotifyModalTemporaryEmployeeFromTemporaryEmployee(
							_worl.workOrderTemporaryEmployeeId,
							tempEmployee,
							temporaryParticipantsEmailAndSmsStatus?.[_worl.workOrderTemporaryEmployeeId] ?? null
						),
						shouldSendEmail: existingEmployee?.email ? existingEmployee.shouldSendEmail : false,
						shouldSendSms: existingEmployee?.phoneNumber ? existingEmployee.shouldSendSms : false,
					};
				}
				return _acc;
			}, {
				employeeMap: {},
				tempEmployeeMap: {},
			} as {
				employeeMap: EmployeeNotificationStatusDict;
				tempEmployeeMap: TemporaryEmployeeNotificationStatusDict;
			});
			employeeMap = _employeeMap;
			tempEmployeeMap = _tempEmployeeMap;
		}

		// include employees removed from latest revisions
		Object.keys(revisionEmployeesLastVersion).forEach((employeeId) => {
			const newEmployee: WorkOrderRevisionEmployee & { revisionId: number; } = revisionEmployeesLastVersion[+employeeId];
			if (employeeMap && !employeeMap[employeeId] && newEmployee) {
				const _employeeNotificationStatus = revisionEmployeesNotifications?.[+employeeId];

				employeeMap[employeeId] = {
					id: +employeeId,
					fullName: newEmployee.fullName,
					phoneNumber: newEmployee.phoneNumber,
					email: newEmployee.email,
					officeColor: newEmployee.officeColor,
					officeNickname: newEmployee.officeNickname,
					lastEmailSentStatus: _employeeNotificationStatus?.emailStatus,
					lastSmsSentStatus: _employeeNotificationStatus?.smsStatus,
					lastEmailSentAt: _employeeNotificationStatus?.emailSentAt,
					lastSmsSentAt: _employeeNotificationStatus?.smsSentAt,
					emailInvalidatedAt: undefined,
					phoneInvalidatedAt: undefined,
					subscriptionStatus: newEmployee.subscriptionStatus ?? SubscriptionStatus.SUBSCRIBED,
					subscribedForEmail: newEmployee.emailStatus === NotificationStatusEnum.SCHEDULED,
					subscribedForSms: newEmployee.smsStatus === NotificationStatusEnum.SCHEDULED,
					emailErrorMessage: _employeeNotificationStatus?.emailErrorMessage,
					smsErrorMessage: _employeeNotificationStatus?.smsErrorMessage,
					shouldSendEmail: false,
					shouldSendSms: false,
					isAssignedToWO: false,
				} as EmployeeNotifyModalState;
			}
		});

		// include temporary employees removed from latest revisions
		Object.keys(revisionTemporaryEmployeesLastVersion).forEach((temporaryEmployeeId) => {
			const newTemporaryEmployee: WorkOrderRevisionTemporaryEmployee & { revisionId: number; }
				= revisionTemporaryEmployeesLastVersion[+temporaryEmployeeId];
			if (tempEmployeeMap && !tempEmployeeMap[temporaryEmployeeId] && newTemporaryEmployee) {
				const _employeeNotificationStatus = revisionEmployeesNotifications?.[+temporaryEmployeeId];

				tempEmployeeMap[temporaryEmployeeId] = {
					id: +temporaryEmployeeId,
					fullName: newTemporaryEmployee.fullName,
					phoneNumber: newTemporaryEmployee.phoneNumber,
					email: newTemporaryEmployee.email,
					lastEmailSentStatus: _employeeNotificationStatus?.emailStatus,
					lastSmsSentStatus: _employeeNotificationStatus?.smsStatus,
					lastEmailSentAt: _employeeNotificationStatus?.emailSentAt,
					lastSmsSentAt: _employeeNotificationStatus?.smsSentAt,
					emailInvalidatedAt: undefined,
					phoneInvalidatedAt: undefined,
					subscriptionStatus: newTemporaryEmployee.subscriptionStatus ?? SubscriptionStatus.SUBSCRIBED,
					subscribedForEmail: newTemporaryEmployee.emailStatus === NotificationStatusEnum.SCHEDULED,
					subscribedForSms: newTemporaryEmployee.smsStatus === NotificationStatusEnum.SCHEDULED,
					emailErrorMessage: _employeeNotificationStatus?.emailErrorMessage,
					smsErrorMessage: _employeeNotificationStatus?.smsErrorMessage,
					shouldSendEmail: false,
					shouldSendSms: false,
					isAssignedToWO: false,
				} as TempEmployeeNotifyModalState;
			}
		});

		setEmployees(employeeMap);
		if (tempEmployeeMap) {
			setTemporaryEmployees(tempEmployeeMap);
		}
		newIsResend ? setIsResend() : setIsNotResend();
		setMessage(isMessageEdited ? message : dailyTip);
		// eslint-disable-next-line react-hooks/exhaustive-deps
	}, [
		dailyTip,
		isMessageEdited,
		order,
		participantsEmailAndSmsStatus,
		temporaryParticipantsEmailAndSmsStatus,
		revisionEmployees,
		scheduleBoardEmployees,
		workOrderEmployees,
		revisionEmployeesNotifications,
		revisionEmployeesLastVersion,
		setIsResend,
		setIsNotResend,
	]); // do not add employees and message to dependency array as linter suggests

	const resetState = React.useCallback(async () => {
		setEmployees({});
		setTemporaryEmployees({});
		endSending();
		setIsNotResend();
		setMessage(dailyTip);
	}, [dailyTip, endSending, setIsNotResend]);

	const onCancel = React.useCallback(() => {
		resetState();
		closeModal();
	}, [closeModal, resetState]);

	const onSubmit = React.useCallback(async () => {
		beginSending();
		const {
			sendEmailToIds: employeeEmailIds,
			sendSMSToIds: employeeSmsIds,
		} = Object.values(employees).reduce(_notificationIdReducer, { sendEmailToIds: [], sendSMSToIds: [] });

		const {
			sendEmailToIds: tempEmployeeEmailIds,
			sendSMSToIds: tempEmployeeSmsIds,
		} = Object.values(temporaryEmployees).reduce(_notificationIdReducer, { sendEmailToIds: [], sendSMSToIds: [] });

		const _message = includeTip && message.length ? message : undefined;
		await notifyParticipants(employeeSmsIds, employeeEmailIds, tempEmployeeSmsIds, tempEmployeeEmailIds, workOrderId, _message, isMessageEdited);
		onCancel();
	}, [beginSending, employees, temporaryEmployees, includeTip, message, notifyParticipants, workOrderId, isMessageEdited, onCancel]);

	const checkAll = React.useCallback(async (value: boolean, type: NOTIFY_EMPLOYEE_TYPE) => {
		let newIsResend = false;
		const newEmployees = Object.values(employees).reduce((_acc, _e) => {
			if (
				(NOTIFY_EMPLOYEE_TYPE.EMAIL && value && _e.email && _e.lastEmailSentAt)
				|| (NOTIFY_EMPLOYEE_TYPE.SMS && value && _e.phoneNumber && _e.lastSmsSentAt)
			) {
				newIsResend = true;
			}
			_acc[_e.id] = {
				..._e,
				shouldSendEmail: type === NOTIFY_EMPLOYEE_TYPE.EMAIL ? (_e.email && value) : _e.shouldSendEmail,
				shouldSendSms: type === NOTIFY_EMPLOYEE_TYPE.SMS ? (_e.phoneNumber && value) : _e.shouldSendSms,
			};
			return _acc;
		}, {});

		const newTempEmployees = Object.values(temporaryEmployees).reduce((_acc, _e) => {
			if (
				(NOTIFY_EMPLOYEE_TYPE.EMAIL && value && _e.email && _e.lastEmailSentAt)
				|| (NOTIFY_EMPLOYEE_TYPE.SMS && value && _e.phoneNumber && _e.lastSmsSentAt)
			) {
				newIsResend = true;
			}
			_acc[_e.id] = {
				..._e,
				shouldSendEmail: type === NOTIFY_EMPLOYEE_TYPE.EMAIL ? (_e.email && value) : _e.shouldSendEmail,
				shouldSendSms: type === NOTIFY_EMPLOYEE_TYPE.SMS ? (_e.phoneNumber && value) : _e.shouldSendSms,
			};
			return _acc;
		}, {});

		setEmployees(newEmployees);
		setTemporaryEmployees(newTempEmployees);
		newIsResend ? setIsResend() : setIsNotResend();
	}, [employees, setIsNotResend, setIsResend, temporaryEmployees]);

	const onEmployeeCheck = React.useCallback(async (workOrderEmployeeId: number, type: NOTIFY_EMPLOYEE_TYPE) => {

		const employeeId = Object.keys(employees).find((_employeeId) => employees[_employeeId]?.id === workOrderEmployeeId);
		if (employeeId) {
			setEmployees({
				...employees,
				[employeeId]: {
					...employees[employeeId],
					shouldSendEmail: type === NOTIFY_EMPLOYEE_TYPE.EMAIL
						? !employees[employeeId].shouldSendEmail
						: employees[employeeId].shouldSendEmail,
					shouldSendSms: type === NOTIFY_EMPLOYEE_TYPE.SMS
						? !employees[employeeId].shouldSendSms
						: employees[employeeId].shouldSendSms,
				},
			});

			!!Object.values(employees).find((_e) => (_e.shouldSendEmail && !!_e.lastEmailSentAt) || (_e.shouldSendEmail && !!_e.lastSmsSentAt))
				? setIsResend()
				: setIsNotResend();
		}
	}, [employees, setIsNotResend, setIsResend]);

	const onTempEmployeeCheck = React.useCallback(async (workOrderTemporaryEmployeeId: number, type: NOTIFY_EMPLOYEE_TYPE) => {

		const temporaryEmployeeId = Object.keys(temporaryEmployees)
			.find((_temporaryEmployeeId) => temporaryEmployees[_temporaryEmployeeId]?.id === workOrderTemporaryEmployeeId);
		if (temporaryEmployeeId) {
			setTemporaryEmployees({
				...temporaryEmployees,
				[temporaryEmployeeId]: {
					...temporaryEmployees[temporaryEmployeeId],
					shouldSendEmail: type === NOTIFY_EMPLOYEE_TYPE.EMAIL
						? !temporaryEmployees[temporaryEmployeeId].shouldSendEmail
						: temporaryEmployees[temporaryEmployeeId].shouldSendEmail,
					shouldSendSms: type === NOTIFY_EMPLOYEE_TYPE.SMS
						? !temporaryEmployees[temporaryEmployeeId].shouldSendSms
						: temporaryEmployees[temporaryEmployeeId].shouldSendSms,
				},
			});

			!!Object.values(temporaryEmployees).find((_e) => (_e.shouldSendEmail && !!_e.lastEmailSentAt) || (_e.shouldSendEmail && !!_e.lastSmsSentAt))
				? setIsResend()
				: setIsNotResend();
		}
	}, [temporaryEmployees, setIsNotResend, setIsResend]);

	const onSchedule = React.useCallback(async () => {
		beginSending();

		const {
			emailIds: employeeEmailIds,
			smsIds: employeeSmsIds,
			removedEmployeeIdsMap,
		} = Object.values(employees).reduce<NotificationIdsReducer>(
			_getNotificationIdReducer(workOrderId),
			{ emailIds: [], smsIds: [], removedEmployeeIdsMap: {} }
		);

		const {
			emailIds: tempEmployeeEmailIds,
			smsIds: tempEmployeeSmsIds,
			removedEmployeeIdsMap: removedTemporaryEmployeeIdsMap,
		} = Object.values(temporaryEmployees).reduce<NotificationIdsReducer>(
			_getNotificationIdReducer(workOrderId),
			{ emailIds: [], smsIds: [], removedEmployeeIdsMap: {} }
		);

		const _message = includeTip && isMessageEdited && message.length ? message : undefined;
		await scheduleAutoNotify(
			employeeSmsIds,
			employeeEmailIds,
			tempEmployeeSmsIds,
			tempEmployeeEmailIds,
			_message,
			removedEmployeeIdsMap,
			removedTemporaryEmployeeIdsMap
		);
		onCancel();
	}, [beginSending, employees, temporaryEmployees, includeTip, isMessageEdited, message, onCancel, scheduleAutoNotify, workOrderId]);

	const editMessage = React.useCallback((newMessage: string) => {
		if (newMessage.length < DAILY_TIP_MAX_LENGTH) {
			setMessage(newMessage);
			newMessage !== dailyTip ? setMessageIsEdited() : setMessageIsNotEdited();
		}
	}, [dailyTip, setMessageIsEdited, setMessageIsNotEdited]);

	const renderLoadingModalContent = React.useCallback(() => (
		<Loading onHide={onCancel} />
	), [onCancel]);

	const renderOrderHeader = React.useCallback((code: string, title: string, customer: string, color: ExtendedColorValue) => {
		return (
			<tr className={`order-header ${ColorUtils.getColorBackgroundClass(color)}`}>
				<td colSpan={100}>
					<span>{title} | {code} | {customer}</span>
				</td>
			</tr>
		);
	}, []);

	const getCrewTypeInfo = React.useCallback(() => {
		return (order instanceof WorkOrderViewModel) ? order.crewType.color : order.crewTypeColor;
	}, [order]);

	const submitDisabled = React.useMemo(() => {
		return [...Object.values(employees), ...Object.values(temporaryEmployees)].every(((_e) => !_e.shouldSendEmail && !_e.shouldSendSms));
	}, [employees, temporaryEmployees]);

	const {
		emailNumber,
		smsNumber,
	} = React.useMemo(() => {
		return [...Object.values(employees), ...Object.values(temporaryEmployees)].reduce((_acc, _emp) => {
			if (_emp.shouldSendEmail) {
				_acc.emailNumber += 1;
			}
			if (_emp.shouldSendSms) {
				_acc.smsNumber += 1;
			}
			return _acc;
		}, { emailNumber: 0, smsNumber: 0 });
	}, [employees, temporaryEmployees]);

	const renderDailyTipForm = React.useCallback((): JSX.Element => (
		<div className="content-form">
			<Checkbox
				handleChange={toggleIncludeTip}
				inline={true}
				isChecked={includeTip}
				label={<span className="m-l-xs">Include Custom Message with Notification (daily tip is used by default)</span>}
			/>
			<Textarea
				disabled={!includeTip}
				maxCharacters={DAILY_TIP_MAX_LENGTH}
				onValueChange={editMessage}
				placeholder="EnterMessage"
				rows={2}
				showMaxCharactersLabel={true}
				value={message}
			/>
		</div>
	), [editMessage, includeTip, message, toggleIncludeTip]);

	const renderWarning = React.useCallback(() => {
		const tip = message.length ? message : dailyTip;
		const _message = includeTip ? `${notificationTemplate} ${tip}` : notificationTemplate;

		if (_message?.length <= SMS_LENGTH_LIMIT) {
			return null;
		}

		return (
			<div className="text-red">
				Message could exceed 1600 characters and SMS may not be sent. Try reducing daily tip or work order notes.
			</div>
		);
	}, [dailyTip, includeTip, message, notificationTemplate]);

	const renderDataModalContent = React.useCallback(() => {
		const {
			title,
			code,
			dueDate,
			customerName,
			customCrewType,
			status: workOrderStatus,
		} = order;

		const sendLabel = isResend ? 'Resend' : 'Send';
		const dateBefore = TimeUtils.formatDate(TimeUtils.parseMoment(dueDate, TimeFormatEnum.DATE_ONLY)?.subtract(1, 'day'), TimeFormatEnum.DAY_OF_WEEK_WITH_DATE_AND_YEAR);

		const now = new Date();
		const currentDate = TimeUtils.formatDate(now, TimeFormatEnum.DAY_OF_WEEK_WITH_DATE_AND_YEAR);
		const currentTime = now.getHours() * 60 + now.getMinutes();

		const timeToNotifyPassed = !!TimeUtils.isBefore(dateBefore, currentDate, TimeFormatEnum.DAY_OF_WEEK_WITH_DATE_AND_YEAR)
			|| (currentDate === dateBefore && autoNotifyAt && currentTime > autoNotifyAt);

		const enableNotify = autoNotifyOnPublish !== AutoNotifyOption.DEFAULT_TIME
			&& !timeToNotifyPassed;

		return (
			<>
				<CustomModal.Header
					closeModal={onCancel}
					title="Notify Participants"
				/>
				<CustomModal.Body>
					<Table>
						<TableHeader checkAll={checkAll} showSubscribedColumns={autoNotifyOnPublish !== AutoNotifyOption.DEFAULT_TIME} />
						<tbody>
							{renderOrderHeader(code, title ?? '', customerName ?? '', !customCrewType ? getCrewTypeInfo() ?? DefaultColor.INTERNAL_JOB : DefaultColor.INTERNAL_JOB)}
							<EmployeeList
								employees={employees}
								isSending={isSending}
								onCheck={onEmployeeCheck}
								showSubscribedColumns={autoNotifyOnPublish !== AutoNotifyOption.DEFAULT_TIME}
								subscribedAt={`${dateBefore}, ${TimeOptionUtils.format(autoNotifyAt)}`}
							/>
							<TemporaryEmployeeList
								employees={temporaryEmployees}
								isSending={isSending}
								onCheck={onTempEmployeeCheck}
								showSubscribedColumns={autoNotifyOnPublish !== AutoNotifyOption.DEFAULT_TIME}
								subscribedAt={`${dateBefore}, ${TimeOptionUtils.format(autoNotifyAt)}`}
							/>
						</tbody>
					</Table>
				</CustomModal.Body>
				<div className="daily-tip-modal resizable-container">
					{workOrderStatus !== WorkOrderStatus.CANCELED && renderDailyTipForm()}
					<div className="content-footer">
						<div className="question">
							Send <b><span className="text-orange">{emailNumber}</span></b> e-mail notifications
							and <b><span className="text-orange">{smsNumber}</span></b> SMS notifications
							to laborers of work order <b>{code}</b> of job <b>{title}</b>?
						</div>
						{autoNotifyOnPublish === AutoNotifyOption.DEFAULT_TIME &&
							<div>Automatic notifications will be sent at {TimeOptionUtils.format(autoNotifyAt)}, {dateBefore}</div>
						}
						{renderWarning()}
					</div>
				</div>
				<CustomModal.Footer>
					<Button
						id="accqa__schedule-board__notify__cancel"
						onClick={onCancel}
						variant="info"
					>
						Close
					</Button>
					<SubmitButton
						disabled={submitDisabled || isSending}
						id="accqa__schedule-board__notify__send"
						label={isSending ? 'Sending' : sendLabel}
						onClick={onSubmit}
					/>
					{enableNotify &&
						<SubmitButton
							disabled={submitDisabled || isSending}
							id="accqa__schedule-board__notify__schedule-send"
							label={`Notify at ${dateBefore}, ${TimeOptionUtils.format(autoNotifyAt)}`}
							onClick={onSchedule}
						/>
					}
				</CustomModal.Footer>
			</>
		);
	}, [
		autoNotifyAt,
		autoNotifyOnPublish,
		checkAll,
		employees,
		getCrewTypeInfo,
		isResend,
		isSending,
		onCancel,
		onEmployeeCheck,
		onTempEmployeeCheck,
		onSchedule,
		onSubmit,
		order,
		renderDailyTipForm,
		renderOrderHeader,
		renderWarning,
		temporaryEmployees,
		submitDisabled,
		emailNumber,
		smsNumber,
	]);

	if (!order || !showModal) {
		return null;
	}

	return (
		<CustomModal
			closeModal={onCancel}
			modalStyle="info"
			showModal={showModal}
			size="xl"
		>
			{(isLoading && !isSending) ? renderLoadingModalContent() : renderDataModalContent()}
		</CustomModal>
	);
};

const mapDispatchToProps = () => ({
	findNotificationStatusForRevisionEmployees: EmployeeActions.findLatestNotificationsForEmployeesOnWorkOrder,
});

const connector = connect(null, mapDispatchToProps());

const enhance = compose<React.ComponentClass<OwnProps>>(
	connector,
	React.memo
);

export default enhance(NotifyParticipantsModal);
