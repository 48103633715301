import * as React from 'react';

interface TasksWithCount {
	task: string;
	count: number;
}

export interface TaskRecoveryContextType {
	addErrors: (taskGroup: string, ids: string[]) => void;
	getTasksWithinThreshold: (taskGroup: string, threshold: number) => TasksWithCount[];
	getTasksAboveThreshold: (taskGroup: string, threshold: number) => TasksWithCount[];
	clearGroupErrors: (taskGroup: string) => void;
}

const defaultValue: TaskRecoveryContextType = {
	// eslint-disable-next-line @typescript-eslint/no-empty-function
	addErrors: () => { },
	// eslint-disable-next-line @typescript-eslint/no-empty-function
	getTasksWithinThreshold: () => [],
	// eslint-disable-next-line @typescript-eslint/no-empty-function
	getTasksAboveThreshold: () => [],
	// eslint-disable-next-line @typescript-eslint/no-empty-function
	clearGroupErrors: () => { },
};

// eslint-disable-next-line @typescript-eslint/no-explicit-any
export const TaskRecoveryContext = React.createContext<TaskRecoveryContextType>(defaultValue);

interface Props {
	// eslint-disable-next-line @typescript-eslint/no-explicit-any
	children: any;
}

interface ErrorMap {
	[key: string]: number;
}

interface TaskGroupErrors {
	[task: string]: ErrorMap;
}

export const NotificationSnackbarContextProvider: React.FC<Props> = (props) => {

	const {
		children,
	} = props;

	const errors = React.useRef<TaskGroupErrors>({});

	const addErrors = React.useCallback((taskGroup: string, ids: string[]) => {
		ids.forEach((id) => {
			if (!(taskGroup in errors.current)) {
				errors.current[taskGroup] = {};
			}
			errors.current[taskGroup][id] = (errors.current[taskGroup][id] || 0) + 1;
		});
	}, []);

	const getTasksWithinThreshold = React.useCallback((taskGroup: string, threshold: number) => {
		return Object.entries(errors.current[taskGroup]).reduce((_acc, [_task, _count]) => {
			if (_count > threshold) {
				return _acc;
			}
			_acc.push({ task: _task, count: _count });
			return _acc;
		}, [] as { task: string; count: number; }[]);
	}, []);

	const getTasksAboveThreshold = React.useCallback((taskGroup: string, threshold: number) => {
		return Object.entries(errors.current[taskGroup]).reduce((_acc, [_task, _count]) => {
			if (_count <= threshold) {
				return _acc;
			}
			_acc.push({ task: _task, count: _count });
			return _acc;
		}, [] as { task: string; count: number; }[]);
	}, []);

	const clearGroupErrors = React.useCallback((taskGroup: string) => {
		if (taskGroup in errors.current) {
			errors.current[taskGroup] = {};
		}
	}, []);

	const value: TaskRecoveryContextType = React.useMemo(() => ({
		addErrors,
		getTasksWithinThreshold,
		getTasksAboveThreshold,
		clearGroupErrors,
	}), [addErrors, clearGroupErrors, getTasksAboveThreshold, getTasksWithinThreshold]);

	return (
		<TaskRecoveryContext.Provider value={value} >
			{children}
		</TaskRecoveryContext.Provider >
	);
};

export function withTaskRecovery<P extends object>(
	WrappedComponent: React.ComponentType<P>
): React.FC<P> {
	const WithTaskRecovery: React.FC<P> = (props: P) => {

		return (
			<NotificationSnackbarContextProvider>
				<WrappedComponent {...props as P} />
			</NotificationSnackbarContextProvider>
		);

	};
	return WithTaskRecovery;
}
