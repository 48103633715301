import * as React from 'react';
import { Row, Col } from 'react-bootstrap';

import { MessageTypeLabels } from 'acceligent-shared/enums/messageType';
import { MessageDestinationTypeLabel } from 'acceligent-shared/enums/messageDestinationType';
import TimeFormat from 'acceligent-shared/enums/timeFormat';
import * as TimeUtils from 'acceligent-shared/utils/time';

import type MessageVM from 'ab-viewModels/message/message.viewModel';

import LockedValue from 'af-components/LockedValue';

import CLIENT from 'af-routes/client';

import ResourcePreview from './ResourcePreview';

interface OwnProps {
	orgAlias: string;
	companyName: string;
	message: Nullable<MessageVM>;
}

type Props = OwnProps;

const Message: React.FC<Props> = ({ orgAlias, companyName, message }) => {

	const breadcrumbs = React.useMemo(() => {
		return [
			{ label: 'Communication History', url: CLIENT.COMPANY.COMMUNICATION.COMMUNICATION_HISTORY(orgAlias, companyName) },
			{ label: 'Preview' },
		];
	}, [orgAlias, companyName]);

	const shouldShowSubject = React.useCallback(() => {
		return message && MessageDestinationTypeLabel[message.destinationType] === MessageDestinationTypeLabel.EMAIL;
	}, [message]);

	const subject = React.useMemo(() => {
		if (shouldShowSubject() && message) {
			return <Row className="row--padded-top">
				<Col>
					<LockedValue label="Subject" value={message.subject} />
				</Col>
			</Row>;
		}
	}, [message, shouldShowSubject]);

	const shouldShowHeading = React.useCallback(() => {
		return message && MessageDestinationTypeLabel[message.destinationType] === MessageDestinationTypeLabel.EMAIL;
	}, [message]);

	const heading = React.useMemo(() => {
		if (shouldShowHeading() && message) {
			return <Row className="row--padded-top">
				<Col>
					<LockedValue label="Heading" value={message.heading} />
				</Col>
			</Row>;
		}
	}, [message, shouldShowHeading]);

	const shouldShowFooter = React.useCallback(() => {
		return message && MessageDestinationTypeLabel[message.destinationType] === MessageDestinationTypeLabel.EMAIL;
	}, [message]);

	const footer = React.useMemo(() => {
		if (shouldShowFooter() && message) {
			return <Row className="row--padded-top">
				<Col>
					<LockedValue label="Footer" value={message.footer} />
				</Col>
			</Row>;
		}
	}, [message, shouldShowFooter]);

	if (!message) {
		return null;
	}

	return (
		<ResourcePreview
			backToListLabel="Back to Communication History"
			backToListUrl={CLIENT.COMPANY.COMMUNICATION.COMMUNICATION_HISTORY(orgAlias, companyName)}
			breadcrumbs={breadcrumbs}
		>
			<Row className="row--padded-top">
				<Col md={4} >
					<LockedValue label="SentAt" value={TimeUtils.formatDate(message.sentAt, TimeFormat.FULL_DATE, TimeFormat.ISO_DATETIME)} />
				</Col>
				<Col md={4} >
					<LockedValue label="Recipient" value={message.recipients?.join(',\n') ?? 'N/A'} />
				</Col>
				<Col md={4} >
					<LockedValue label="Sender" value={message.senderFullName} />
				</Col>
				<Col md={4} >
					<LockedValue label="Destination Type" value={MessageDestinationTypeLabel[message.destinationType]} />
				</Col>
				<Col md={4} >
					<LockedValue
						label="Destination"
						value={message.destination ?? 'N/A'}
					/>
				</Col>
				<Col md={4} >
					<LockedValue label="User Group" value={message.userGroupName} />
				</Col>
			</Row>
			<Row className="row--padded-top">
				<Col md={4} >
					<LockedValue label="Status" value={message.currentStatus} />
				</Col>
				<Col md={4} >
					<LockedValue label="Message Type" value={MessageTypeLabels[message.messageType]} />
				</Col>
			</Row>
			<Row className="row--padded-top">
				<Col>
					<LockedValue label="Vendor Specific Fields" value={message.vendorSpecificFields} />
				</Col>
			</Row>
			{subject}
			{heading}
			<Row className="row--padded-top">
				<Col>
					<LockedValue label="Content" value={message.content} />
				</Col>
			</Row>
			{footer}
		</ResourcePreview>
	);
};

export default React.memo(Message);
