import type { ColorPalette, ExtendedColorValue } from 'acceligent-shared/enums/color';
import { DefaultColor } from 'acceligent-shared/enums/color';
import type Priority from 'acceligent-shared/enums/priority';

import { FAKE_ACCOUNT_DATA } from 'acceligent-shared/constants/value';

import type { SearchableModel } from 'ab-common/dataStructures/scheduleBoardQuery';

import { getUserName } from 'acceligent-shared/utils/user';

import * as TextUtil from 'ab-utils/text.util';

import type UserBase from 'ab-domain/models/user/base';

import type MechanicViewAvailableEquipmentWebBase from 'ab-domain/views/mechanicViewAvailableEquipmentWeb/base';
import type MechanicViewUnavailableEquipmentWebBase from 'ab-domain/views/mechanicViewUnavailableEquipmentWeb/base';

import type { ScheduleBoardQueryMatchingFunction } from 'ab-common/dataStructures/scheduleBoardQuery';

class MechanicViewEquipmentDataVM implements SearchableModel {
	static matches: ScheduleBoardQueryMatchingFunction<MechanicViewEquipmentDataVM> = TextUtil.matches;

	categoryColor: ColorPalette;
	code: string;
	id: number;
	specification: Nullable<string>;

	_desc: string;

	constructor(equipment: MechanicViewUnavailableEquipmentWebBase | MechanicViewAvailableEquipmentWebBase) {
		this.categoryColor = equipment.categoryColor;
		this.code = equipment.code;
		this.id = equipment.id;
		this.specification = equipment.specification;

		this._desc = [this.code, this.specification].filter(Boolean).join(' ');
	}
}

export class MechanicViewAvailableEquipmentDataVM extends MechanicViewEquipmentDataVM {
	assignmentWorkOrderCodes: string[];
	location: Nullable<string>;
	locationColor: ExtendedColorValue;
	locationId: Nullable<number>;
	type: string;

	constructor(equipment: MechanicViewAvailableEquipmentWebBase) {
		super(equipment);

		this.assignmentWorkOrderCodes = equipment.assignmentWorkOrderCodes ?? [];
		this.location = equipment.location;
		this.locationColor = equipment.locationColor ?? DefaultColor.LOCATION;
		this.locationId = equipment.locationId;
		this.type = equipment.type;

		this._desc = [this._desc, this.type, this.location, ...this.assignmentWorkOrderCodes].filter(Boolean).join(' ');
	}

	static bulkConstructor = (_equipment: MechanicViewAvailableEquipmentWebBase[]) => _equipment.map(MechanicViewAvailableEquipmentDataVM._constructorMap);

	private static _constructorMap = (_equipment: MechanicViewAvailableEquipmentWebBase) => new MechanicViewAvailableEquipmentDataVM(_equipment);
}

export class MechanicViewUnavailableEquipmentDataVM extends MechanicViewEquipmentDataVM {
	returnDate: string;
	failureDetails: string;
	lastUpdatedBy: {
		name: string;
		officeColor: ExtendedColorValue;
	};
	/** ISO 8601 */
	lastUpdatedOn: string;
	priority: Priority;
	statusId: number;
	statusIndex: number;
	unavailabilityReason: Nullable<string>;

	constructor(equipment: MechanicViewUnavailableEquipmentWebBase) {
		super(equipment);

		const accountUser = equipment.lastUpdatedById
			? {
				firstName: equipment.lastUpdatedByFirstName,
				lastName: equipment.lastUpdatedByLastName,
				id: equipment.lastUpdatedById,
			}
			: { id: FAKE_ACCOUNT_DATA.USER_ID } as UserBase;

		this.returnDate = equipment.returnDate;
		this.failureDetails = equipment.failureDetails;
		this.lastUpdatedBy = {
			name: getUserName(accountUser),
			officeColor: equipment.lastUpdatedByOfficeColor ?? DefaultColor.LOCATION,
		};
		this.lastUpdatedOn = equipment.lastUpdatedOn.toISOString();
		this.priority = equipment.priority;
		this.statusId = equipment.statusId;
		this.statusIndex = equipment.statusIndex;
		this.unavailabilityReason = equipment.unavailabilityReason;

		this._desc = [this._desc, this.failureDetails, this.unavailabilityReason].filter(Boolean).join(' ');
	}

	static bulkConstructor = (_equipment: MechanicViewUnavailableEquipmentWebBase[]) => _equipment.map(MechanicViewUnavailableEquipmentDataVM._constructorMap);

	private static _constructorMap = (_equipment: MechanicViewUnavailableEquipmentWebBase) => new MechanicViewUnavailableEquipmentDataVM(_equipment);
}
