import * as React from 'react';

import DirectoryNames from 'ab-enums/directoryNames.enum';

import DirectoryAttachmentTableRowComponent from './DirectoryAttachmentTableRowComponent';

import styles from './styles.module.scss';

import type { AttachmentEntity, DirectoryEntity } from '../types';

const ROOT_DIRECTORY_ORDER: { [name in DirectoryNames]: number } = {
	[DirectoryNames.JOB_EHS]: 0,
	[DirectoryNames.WORK_ORDER_EHS]: 1,
	[DirectoryNames.WORK_ORDER_INFO]: 2,
	[DirectoryNames.JOB_INFO]: 3,
	[DirectoryNames.WAGE_INFO]: 4,
};

function sortDirectories(d1: DirectoryEntity, d2: DirectoryEntity) {
	return ROOT_DIRECTORY_ORDER[d1.name] - ROOT_DIRECTORY_ORDER[d2.name];
}

interface RowActionOptions {
	actionName: string;
	actionFunction: (original: DirectoryEntity | AttachmentEntity) => void;
	disabled?: boolean;
	show?: boolean;
}

interface RowActions {
	parentDirectory: RowActionOptions[];
	childDirectory: RowActionOptions[];
	attachmentLevel: RowActionOptions[];
}

interface TypedColumn {
	header: string;
	accessor?: string;
	id?: string;
	className?: string;
	width?: number;
}

interface TemporaryType {
	dataTree: DirectoryEntity[];
	rowActions?: RowActions;
	columns: TypedColumn[];
	fetchData?: () => void;
	id: number;
	toggleRowVisibility: (arg0: number[]) => void;
	visibleRows: number[];
	onFileUpload?: (directoryId: number, files: File[]) => void;
	onCopyToWOChange?: (attachmentId: number, copyToWorkOrder: boolean) => void;
	isReadOnly?: boolean;
}

const TreeTableBody = (props: TemporaryType) => {
	const {
		dataTree,
		rowActions,
		columns,
		onFileUpload,
		fetchData,
		id,
		toggleRowVisibility,
		visibleRows,
		onCopyToWOChange,
		isReadOnly,
	} = props;
	const [dirIndex, setDirIndex] = React.useState(-1);
	const sortedDataTree = React.useMemo(() => [...dataTree].sort(sortDirectories), [dataTree]);

	return (
		sortedDataTree.map((_directory, index) => {
			return (
				<React.Fragment key={`${_directory.id}`}>
					<div className={`${styles['tree-table']} ${dirIndex === index ? styles['drag-drop-style'] : ''}`}
						key={index}
					>
						<DirectoryAttachmentTableRowComponent
							columns={columns}
							directory={_directory}
							fetchData={fetchData}
							id={id}
							isReadOnly={isReadOnly}
							level={0}
							mainIndex={index}
							onCopyToWOChange={onCopyToWOChange}
							onFileUpload={onFileUpload}
							rowActions={rowActions}
							setDirIndex={setDirIndex}
							toggleRowVisibility={toggleRowVisibility}
							visibleRows={visibleRows}
						/>
					</div>
				</React.Fragment>
			);
		})
	);
};

export default TreeTableBody;
