import * as React from 'react';

import type WorkOrderModalViewModel from 'ab-viewModels/scheduleBoardWorkOrderModal.viewModel';

import LockedValue from 'af-components/LockedValue';
import SegmentLabel from 'af-components/SegmentLabel';

import { renderWorkOrderInfoData } from '../';
import Changes from '../Changes';

interface Props {
	workOrderDataToShow: WorkOrderModalViewModel;
	currentWorkOrderData: WorkOrderModalViewModel;
}

interface State {
	notesVisible: boolean;
}

class NotesSection extends React.PureComponent<Props> {
	state: State = {
		notesVisible: false,
	};

	toggleNotes = () => {
		this.setState((prevState: State) => ({
			notesVisible: !prevState.notesVisible,
		}));
	};

	sectionHasChanges = () => {
		const { workOrderDataToShow, currentWorkOrderData } = this.props;

		return workOrderDataToShow?.notes !== currentWorkOrderData?.notes
			|| workOrderDataToShow?.scopeOfWork !== currentWorkOrderData?.scopeOfWork
			|| workOrderDataToShow?.jobNotes !== currentWorkOrderData?.jobNotes;
	};

	render() {
		const { workOrderDataToShow, currentWorkOrderData } = this.props;
		const { notesVisible } = this.state;

		const sectionHasChanges = this.sectionHasChanges();

		let toggleClassName = 'work-order-info-modal__toggle';
		toggleClassName = notesVisible ? `${toggleClassName} work-order-info-modal__toggle--open` : toggleClassName;

		return (
			<div className="work-order-info-modal__notes-container">
				<Changes modifiedData={sectionHasChanges} />
				<SegmentLabel label="Notes" />
				<div className="work-order-info-modal__notes">
					<div>
						<LockedValue
							label="Work Order Notes"
							value={renderWorkOrderInfoData(workOrderDataToShow?.notes ?? '-', currentWorkOrderData?.notes ?? '-')}
						/>
					</div>
					<div>
						<LockedValue
							label="Scope of Work"
							value={renderWorkOrderInfoData(workOrderDataToShow?.scopeOfWork ?? '-', currentWorkOrderData?.scopeOfWork ?? '-')}
						/>
					</div>
				</div>
				<>
					<div className={toggleClassName} onClick={this.toggleNotes}>
						{notesVisible
							? <>Hide Notes <span className="icon-collapse" /></>
							: <>Show All Notes <span className="icon-expand" /></>
						}
					</div>
					{notesVisible &&
						<div>
							<LockedValue
								label="Job Notes"
								value={renderWorkOrderInfoData(workOrderDataToShow?.jobNotes ?? '-', currentWorkOrderData?.jobNotes ?? '-')}
							/>
						</div>
					}
				</>
			</div>
		);
	}
}

export default NotesSection;
