import TimeSheetEntryWorkType from 'acceligent-shared/enums/timeSheetEntryWorkType';
import TimeFormat from 'acceligent-shared/enums/timeFormat';

import { DEFAULT_TIME_DURATION_MINUTE_ROUNDING_INTERVAL } from 'acceligent-shared/constants/value';

import * as TimeUtils from 'acceligent-shared/utils/time';

import type { TimeSplitEntryFM, TimeSheetEntryFM, EmployeeDataFM, TimeAllocationEntryFM } from './formModel';

export const sumTotalEquipmentTime = (sum: number, entry: TimeSplitEntryFM) => sum + (entry?.time ?? 0);

export const sumTotalJobTime = (sum: number, entry: TimeSheetEntryFM) => {
	if (entry.workType === TimeSheetEntryWorkType.JOB && !!entry.startTime && !!entry.endTime) {
		const duration = TimeUtils.getDiff(entry.endTime, entry.startTime, 'minutes', TimeFormat.ISO_DATETIME);
		return sum + duration;
	}
	return sum;
};

type TotalTimes = {
	totalJobDuration: number;
	totalShopDuration: number;
	totalTravelDuration: number;
	totalBreakDuration: number;
};
export const calculateTotalTimes = (result: TotalTimes, entry: TimeSheetEntryFM) => {
	if (!entry.startTime || (!entry.id && !entry.endTime)) {
		return result;
	}

	// if end time is null and it has an id this is a tracked entry
	const duration = TimeUtils.getDiff(entry.endTime ?? new Date(), entry.startTime, 'minutes', TimeFormat.ISO_DATETIME);

	switch (entry.workType) {
		case TimeSheetEntryWorkType.BREAK:
			result.totalBreakDuration += duration;
			break;
		case TimeSheetEntryWorkType.JOB:
			result.totalJobDuration += duration;
			break;
		case TimeSheetEntryWorkType.SHOP:
			result.totalShopDuration += duration;
			break;
		case TimeSheetEntryWorkType.TRAVEL:
			result.totalTravelDuration += duration;
			break;
	}
	return result;
};

const mapEntriesToTimesPerWorkType = (
	timeAllocationEntries: TimeAllocationEntryFM[]
) => {
	return timeAllocationEntries.reduce((_acc, _entry) => {
		if (_entry.workType === TimeSheetEntryWorkType.JOB) {
			_acc.jobAllocationsTime += _entry.time;
		} else if (_entry.workType === TimeSheetEntryWorkType.BREAK) {
			_acc.breakAllocationsTime += _entry.time;
		} else if (_entry.workType === TimeSheetEntryWorkType.SHOP) {
			_acc.shopAllocationsTime += _entry.time;
		} else if (_entry.workType === TimeSheetEntryWorkType.TRAVEL) {
			_acc.travelAllocationsTime += _entry.time;
		}
		return _acc;
	}, {
		jobAllocationsTime: 0,
		breakAllocationsTime: 0,
		shopAllocationsTime: 0,
		travelAllocationsTime: 0,
	});
};

export const hasWorkOrderInvalidAllocationTime = (accountData: EmployeeDataFM[]) => {
	return accountData.some(({ timeSheetEntries, timeAllocationEntries }) => {
		const totalTimes = timeSheetEntries.reduce(calculateTotalTimes, {
			totalJobDuration: 0,
			totalShopDuration: 0,
			totalTravelDuration: 0,
			totalBreakDuration: 0,
		});
		const totalJobDuration = TimeUtils.roundTimeDurationToInterval(totalTimes.totalJobDuration, DEFAULT_TIME_DURATION_MINUTE_ROUNDING_INTERVAL);
		const totalShopDuration = TimeUtils.roundTimeDurationToInterval(totalTimes.totalShopDuration, DEFAULT_TIME_DURATION_MINUTE_ROUNDING_INTERVAL);
		const totalTravelDuration = TimeUtils.roundTimeDurationToInterval(totalTimes.totalTravelDuration, DEFAULT_TIME_DURATION_MINUTE_ROUNDING_INTERVAL);
		const totalBreakDuration = TimeUtils.roundTimeDurationToInterval(totalTimes.totalBreakDuration, DEFAULT_TIME_DURATION_MINUTE_ROUNDING_INTERVAL);

		const totalAllocationsTimes = mapEntriesToTimesPerWorkType(timeAllocationEntries);

		return totalJobDuration !== totalAllocationsTimes.jobAllocationsTime
			|| totalShopDuration !== totalAllocationsTimes.shopAllocationsTime
			|| totalTravelDuration !== totalAllocationsTimes.travelAllocationsTime
			|| totalBreakDuration !== totalAllocationsTimes.breakAllocationsTime;
	});
};

export const hasWorkOrderInvalidEquipmentTime = (accountData: EmployeeDataFM[]) => {
	return accountData.some(({ timeSheetEntries, timeSplitEntries }) => {
		const totalJobTime = timeSheetEntries.reduce(sumTotalJobTime, 0);
		const roundedTotalJobTime = TimeUtils.roundTimeDurationToInterval(totalJobTime, DEFAULT_TIME_DURATION_MINUTE_ROUNDING_INTERVAL);
		const totalEquipmentTime = timeSplitEntries.reduce(sumTotalEquipmentTime, 0);

		return totalEquipmentTime > roundedTotalJobTime;
	});
};
