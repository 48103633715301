import * as React from 'react';

import type { OverlapMeta } from 'acceligent-shared/utils/timeSheetEntry';

import TimelineEntityType from 'acceligent-shared/enums/timelineEntityType';

import type { TimeSheetVM } from 'ab-viewModels/timeSheet/timeSheet.viewModel';
import type { TimelineEntitesForAccount } from 'ab-viewModels/timeSheet/timeSheetEntry.viewModel';

import Tooltip from 'af-components/Tooltip';

import { bemElement } from 'ab-utils/bem.util';

import TimeSheetInfoWarningMessage from './TimeSheetInfoWarning';
import TimeSheetSignature from './TimeSheetSignature';

interface OwnProps {
	userFullName: string;
	active: boolean;
	isApproved: boolean;
	isOwner: boolean;
	isTemporaryEmployee: boolean;
	employeeApprovalStatus: TimeSheetVM['employeeApprovalStatus'];
	superintendentApprovalStatus: TimeSheetVM['employeeApprovalStatus'];
	overlaps: Nullable<Record<string, OverlapMeta>>;
	timeSheetEntities: TimelineEntitesForAccount[];
	showOverlaps: boolean;
	userUniqueId: Nullable<string>;
	isDuplicateName: boolean;
}

type Props = OwnProps;

const TimeSheetInfo: React.FC<Props> = (props: Props) => {
	const {
		userFullName,
		active,
		isApproved,
		isOwner,
		isTemporaryEmployee,
		employeeApprovalStatus,
		superintendentApprovalStatus,
		overlaps,
		timeSheetEntities,
		showOverlaps,
		userUniqueId,
		isDuplicateName,
	} = props;

	const [overlappedEntriesByWO, setOverlappedEntriesByWo] = React.useState<Record<string, TimelineEntitesForAccount[]>>({});

	React.useEffect(() => {
		const tempOverlappingEntities: Record<string, TimelineEntitesForAccount[]> = {};
		for (const _key of Object.keys(overlaps ?? {})) {
			const tse = timeSheetEntities.find((_tse) => {
				if (_tse.type !== TimelineEntityType.GAP && _tse.entry.id === +_key) {
					return _tse;
				}
			});
			if (tse && tse.type !== TimelineEntityType.GAP) {
				const code = overlaps?.[_key]?.workOrderCode ?? 'Unassociated WO';
				if (tempOverlappingEntities[code]) {
					tempOverlappingEntities[code].push(tse);
				} else {
					tempOverlappingEntities[code] = [tse];
				}
			}
		}
		setOverlappedEntriesByWo(tempOverlappingEntities);
	}, [overlaps, timeSheetEntities]);

	const hasWarning = !active || Object.keys(overlappedEntriesByWO).length > 0;

	const showUniqueId = isTemporaryEmployee && isDuplicateName;

	return (
		<>
			<div className={bemElement('time-sheet-list__row__info', 'user-name', { owner: isOwner })}>
				{hasWarning &&
					<Tooltip message={
						<TimeSheetInfoWarningMessage
							overlappedEntriesByWO={overlappedEntriesByWO}
							showNotAssignedWarning={!active}
							showOverlaps={showOverlaps}
						/>
					}
					>
						<span className={`icon-warning ${bemElement('time-sheet-list__row__info__user-name', 'warning-icon', { 'not-approved': !isApproved })}`} />
					</Tooltip>
				}
				{
					isTemporaryEmployee &&
					<span className="icon-temp_labor time-sheet-list__icon" />
				}
				{userFullName}
				{
					showUniqueId &&
					<div className="time-sheet-list__uniqueId">
						({userUniqueId})
					</div>
				}
			</div>
			<TimeSheetSignature
				approvalStatus={superintendentApprovalStatus}
				isOwner={isOwner}
				isTemporaryEmployee={isTemporaryEmployee}
				signatureStatus={employeeApprovalStatus}
			/>
		</>
	);
};

export default React.memo(TimeSheetInfo);
