import type ReportBlockVM from 'ab-viewModels/reportBlock/reportBlock.viewModel';

import type { ReportBlockRM, ReportBlockFieldRM, CalculatedFieldOptionRM } from 'ab-requestModels/reportBlock/reportBlock.requestModel';

import type { ReportBlockFieldVM, CalculatedFieldOptionsVM } from 'ab-viewModels/reportBlock/reportBlock.viewModel';

export const fromVMtoRM = (vm: ReportBlockVM): ReportBlockRM => {
	return {
		id: vm.id,
		virtualId: vm.virtualId,
		name: vm.name,
		uniqueId: vm.uniqueId,
		isMain: vm.isMain,
		isRepeating: vm.isRepeating,
		completionFieldVirtualId: vm.completionFieldVirtualId ?? null,
		repeatableBlockType: vm.repeatableBlockType,
	};
};

const _fromCalculatedFieldOptionsVMtoRMBulk = (calculatedFieldLookups: CalculatedFieldOptionsVM[]): CalculatedFieldOptionRM[] => {
	return calculatedFieldLookups.map((_lookup) => {
		if (!_lookup.virtualId) {
			throw new Error('Option is missing field id');
		}
		return {
			id: _lookup.virtualId,
			constant: _lookup.constant,
			type: _lookup.type,
			index: _lookup.index,
		};
	});
};

const _fromReportBlockFieldVMtoRM = (field: ReportBlockFieldVM): ReportBlockFieldRM => {
	return {
		id: field.id,
		virtualId: field.virtualId,
		name: field.name,
		index: field.index,
		valueType: field.valueType,
		unit: field.unit,
		dimension: field.dimension,
		defaultValue: field.defaultValue,
		isKeyParameter: field.isKeyParameter,
		isRequired: field.isRequired,
		isUnique: field.isUnique,
		fieldType: field.fieldType,
		isVisibleToCustomer: field.isVisibleToCustomer,
		hasTooltip: field.hasTooltip,
		tooltipText: field.tooltipText,
		options: field.options,
		allowCustomDropdownListValue: field.allowCustomDropdownListValue,
		operationType: field.operationType,
		calculatedFieldOptions: field.calculatedFieldOptions ? _fromCalculatedFieldOptionsVMtoRMBulk(field.calculatedFieldOptions) : null,
		isDescriptiveTextBold: field.isDescriptiveTextBold,
		descriptiveTextColor: field.descriptiveTextColor ?? undefined,
		icon: field.icon,
	};
};

export const fromReportBlockFieldVMtoRMBulk = (fields: ReportBlockFieldVM[]): ReportBlockFieldRM[] => {
	return fields.map(_fromReportBlockFieldVMtoRM);
};
