import * as React from 'react';
import { connect } from 'react-redux';

import type { RootState } from 'af-reducers';

import { setDailyTipCollapsed } from 'af-utils/settings.util';

interface OwnProps {
	dueDate: string;
	isCollapsed: boolean;
	setDailyTipsSectionCollapsed: (value: boolean) => void;
}

interface StateProps {
	dailyTip: string;
	perDiemMessage: string;
}

type Props = OwnProps & StateProps;

class DailyTipSection extends React.PureComponent<Props> {

	toggleDailyTipSection = () => {
		const { isCollapsed, setDailyTipsSectionCollapsed } = this.props;
		setDailyTipsSectionCollapsed(!isCollapsed);
		setDailyTipCollapsed(!isCollapsed);
	};

	render() {
		const {
			dailyTip,
			perDiemMessage,
			isCollapsed,
		} = this.props;

		return (
			<div className={`tips ${isCollapsed ? 'tips--collapsed' : ''}`}>
				<div className="tip">
					<div className="tip__title">DAILY TIP MESSAGE</div>
					<div>{dailyTip}</div>
				</div>
				<div className="tip">
					<div className="tip__title">PER DIEM MESSAGE</div>
					<div>{perDiemMessage}</div>
				</div>
				<span className={`btn btn--icon btn-primary tips__toggle-icon icon-${isCollapsed ? 'up' : 'down'}`} onClick={this.toggleDailyTipSection} />
			</div>
		);
	}
}

function mapStateToProps(state: RootState, ownProps: OwnProps): StateProps {
	const { dueDate } = ownProps;

	const stateOnDate = state?.scheduleBoard?.workOrdersByDateDictionary?.[dueDate];

	return {
		dailyTip: stateOnDate?.dailyTip?.message ?? '',
		perDiemMessage: stateOnDate?.dailyPerDiemTip?.message ?? '',
	};
}

export default connect<StateProps, void, OwnProps>(mapStateToProps)(DailyTipSection);
