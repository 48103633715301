import * as React from 'react';
import type { CustomRouteComponentProps } from 'react-router-dom';
import type { ConnectedProps } from 'react-redux';
import { connect } from 'react-redux';

import BlobStorageImageSizeContainer from 'acceligent-shared/enums/blobStorageImageSizeContainer';

import * as EmployeeActions from 'af-actions/employee';

import type { RootState } from 'af-reducers';

import type { EmployeeTableVM } from 'ab-viewModels/employee/table.viewModel';
import { EmployeeListViewModel } from 'ab-viewModels/employee/list.viewModel';

import CLIENT from 'af-constants/routes/client';

import { DEFAULT_EMPLOYEE_IMAGE } from 'ab-common/constants/value';

import ImageTag from 'af-components/Image';
import type { TabProps, Column, RowInfo, ButtonData } from 'af-components/Table6';
import Table from 'af-components/Table6';
import SkillsCell from 'af-components/Table6/Cells/SkillsCell';
import EmptyCell from 'af-components/Table6/Cells/EmptyCell';
import Breadcrumbs from 'af-components/Breadcrumbs';

import TableNameEnum from 'ab-enums/tableName.enum';
import TableButtonType from 'ab-enums/tableButtonType.enum';
import { AccountPermissionTemplateLabel } from 'ab-enums/accountPermissionTemplates.enum';
import PagePermissions from 'ab-enums/pagePermissions.enum';

import { downloadCSV } from 'af-utils/csv.utils';

import { isAllowed } from 'ab-utils/auth.util';

type OwnProps = CustomRouteComponentProps;

type Props = OwnProps & ConnectedProps<typeof connector>;

class Employees extends React.Component<Props> {
	columns: Column<EmployeeTableVM>[] = [
		{
			accessor: 'imageUrl',
			sortable: false,
			width: 100,
			className: 'rt-thumbnail',
			Cell: ({ original }) => (
				<ImageTag
					fallbackSrc={DEFAULT_EMPLOYEE_IMAGE}
					minSize={BlobStorageImageSizeContainer.SIZE_50X50}
					src={original.imageUrl ?? null}
					tryOriginal={true}
					tryRoot={true}
				/>
			),
		},
		{
			Header: 'ID',
			accessor: 'account.user.uniqueId',
			Cell: ({ original }) => original.code,
		},
		{
			Header: 'Full Name',
			accessor: 'fullName',
			Cell: ({ original }) => <strong>{original.fullName}</strong>,
		},
		{
			Header: 'User Role',
			accessor: 'account.accountTemplate',
			Cell: ({ original }) => <strong>{AccountPermissionTemplateLabel[original.accountTemplate]}</strong>,
		},
		{
			Header: 'Labor Type',
			accessor: 'wageRate.type',
			Cell: ({ original }) => original.wageRate.type,
		},
		{
			Header: 'Wage Classification',
			accessor: 'wageRate.wageClassification',
			Cell: ({ original }) => original.wageRate.wageClassification,
		},
		{
			Header: 'Home Office',
			accessor: 'account.location.nickname',
			Cell: ({ original }) => original?.location?.nickname ?? <EmptyCell />,
		},
		{
			Header: 'Skills',
			accessor: 'skills',
			sortable: false,
			Cell: ({ original }) => <SkillsCell skills={original.skills} />,
		},
	];

	onRowClick = ({ original }: RowInfo<EmployeeTableVM>) => {
		const { companyName, history, location: { state: { orgAlias } } } = this.props;
		if (original.id) {
			history.push(CLIENT.COMPANY.RESOURCES.EMPLOYEE.PREVIEW(original.id.toString(), orgAlias, companyName));
		}
	};

	editEmployee = async (original: EmployeeTableVM) => {
		const { history, location: { state: { orgAlias } }, companyName } = this.props;
		history.push(CLIENT.COMPANY.RESOURCES.EMPLOYEE.EDIT(original.id.toString(), orgAlias, companyName));
	};

	previewEmployee = async (original: EmployeeTableVM) => {
		const { history, location: { state: { orgAlias } }, companyName } = this.props;
		history.push(CLIENT.COMPANY.RESOURCES.EMPLOYEE.PREVIEW(original.id.toString(), orgAlias, companyName));
	};

	statusHistoryEmployee = async (original: EmployeeTableVM) => {
		const { history, location: { state: { orgAlias } }, companyName } = this.props;
		history.push(CLIENT.COMPANY.RESOURCES.EMPLOYEE.STATUS_HISTORY(original.id.toString(), orgAlias, companyName));
	};

	onDownloadCSVClick = async () => {
		const { findAllForCompanyList, companyName } = this.props;
		const listViewModel = await findAllForCompanyList();
		downloadCSV(EmployeeListViewModel.toCSVData(listViewModel), `${companyName}_employees.csv`);
	};

	onRegisterUserClick = async () => {
		const {
			history,
			location: { state: { orgAlias } },
			companyName,
		} = this.props;

		history.push(CLIENT.COMPANY.SETTINGS.MEMBERS.INVITE(orgAlias, companyName), { from: CLIENT.COMPANY.RESOURCES.EMPLOYEE.LIST(), orgAlias });
	};

	tabs = (): TabProps<EmployeeTableVM>[] => {
		const {
			findAllForCompanyTable,
			hasManagePermission,
		} = this.props;

		const buttons: ButtonData[] = [
			{
				type: TableButtonType.EXPORT,
				hasPermission: true,
				onClick: this.onDownloadCSVClick,
			},
			{
				type: TableButtonType.PRIMARY,
				hasPermission: hasManagePermission,
				label: 'Register User',
				icon: 'plus',
				onClick: this.onRegisterUserClick,
			},
		];

		return [
			{
				label: 'Labor',
				columns: this.columns,
				hasSearchInput: true,
				searchLabel: 'Labor',
				buttons,
				fetch: findAllForCompanyTable,
				onRowClick: this.onRowClick,
				rowActions: [
					{
						label: 'Preview',
						action: this.previewEmployee,
						shouldRefresh: false,
					},
					{
						label: 'Edit',
						action: this.editEmployee,
						shouldRefresh: false,
					},
					{
						label: 'Status History',
						action: this.statusHistoryEmployee,
						shouldRefresh: false,
					},
				],
			},

		];
	};

	breadcrumbs = () => [{ label: 'Labor' }];

	render() {
		return (
			<div className="form-segment form-segment--maxi">
				<Breadcrumbs items={this.breadcrumbs()} />
				<Table
					tableName={TableNameEnum.LABOR}
					tabs={this.tabs()}
				/>
			</div>
		);
	}
}

function mapStateToProps(state: RootState) {
	const { userData, companyData } = state.user;
	if (!userData || !companyData) {
		throw new Error('User not logged in');
	}

	const hasManagePermission: boolean = isAllowed(
		PagePermissions.COMPANY.SETTINGS.MEMBERS.MANAGE,
		companyData.permissions,
		companyData.isCompanyAdmin,
		userData.role
	);

	return {
		hasManagePermission,
		companyName: companyData.name,
	};
}

function mapDispatchToProps() {
	return {
		findAllForCompanyTable: EmployeeActions.findAllForCompanyTable,
		findAllForCompanyList: EmployeeActions.findAllForCompanyList,
	};
}

const connector = connect(mapStateToProps, mapDispatchToProps());

export default connector(Employees);
