import * as React from 'react';
import type { ConnectedProps } from 'react-redux';
import { connect } from 'react-redux';
import { compose } from 'redux';
import type { CellContext } from '@tanstack/react-table';
import { useNavigate, useOutletContext } from 'react-router-dom-v5-compat';

import CLIENT from 'af-routes/client';

import SimpleTable from 'af-components/Controls/SimpleTable/index';
import type { SimpleTableProps } from 'af-components/Controls/SimpleTable/types';

import * as WorkRequestActions from 'af-actions/workRequests';

import type { BillingCodeVM } from 'ab-viewModels/jobPreview.viewModel';

import styles from './styles.module.scss';
import type { JobPreviewOutletContext } from './types';

type Props = ConnectedProps<typeof connector>;

const BillingCodesTab: React.FC<Props> = ({ findAllBillingCodes }) => {
	const navigate = useNavigate();
	const { jobId, hasPermissionToManageBillingCodes } = useOutletContext<JobPreviewOutletContext>();

	const [billingCodes, setBillingCodes] = React.useState<Nullable<BillingCodeVM[]>>(null);

	const renderTextCell = React.useCallback((cell: CellContext<BillingCodeVM, string>) => {
		return (
			cell.getValue() ?? 'N/A'
		);
	}, []);

	const fetchAndInitializeBillingCodes = React.useCallback(async () => {
		if (!hasPermissionToManageBillingCodes) {
			navigate(CLIENT.ERROR.ERR403());
		}
		const fetchedBillingCodes = await findAllBillingCodes(jobId, { page: 0, pageSize: 10000 });
		setBillingCodes(fetchedBillingCodes.rows);
	}, [findAllBillingCodes, hasPermissionToManageBillingCodes, jobId, navigate]);

	React.useEffect(() => {
		fetchAndInitializeBillingCodes();
	}, [fetchAndInitializeBillingCodes]);

	const columns: SimpleTableProps<BillingCodeVM>['columns'] = React.useMemo(() => [
		{
			id: 'lineItemNumber',
			accessor: 'lineItemNumber',
			header: 'Line Item',
			cell: renderTextCell,
			enableSorting: true,
		},
		{
			id: 'customerNumber',
			accessor: 'customerNumber',
			header: 'Customer No.',
			cell: renderTextCell,
			enableSorting: true,
		},
		{
			id: 'customerId',
			accessor: 'customerId',
			header: 'Customer ID',
			cell: renderTextCell,
			enableSorting: true,
		},
		{
			id: 'ownerNumber',
			accessor: 'ownerNumber',
			header: 'Owner Number',
			cell: renderTextCell,
			enableSorting: true,
		},
		{
			id: 'ownerId',
			accessor: 'ownerId',
			header: 'Owner ID',
			cell: renderTextCell,
			enableSorting: true,
		},
		{
			id: 'bidQuantity',
			accessor: 'bidQuantity',
			header: 'Bid Qty',
			cell: renderTextCell,
			enableSorting: true,
		},
		{
			id: 'unit',
			accessor: 'unit',
			header: 'Unit',
			cell: renderTextCell,
			enableSorting: true,
		},
		{
			id: 'unitPrice',
			accessor: 'unitPrice',
			header: 'Unit Price',
			cell: renderTextCell,
			enableSorting: true,
		},
		{
			id: 'group',
			accessor: 'group',
			header: 'Group',
			cell: renderTextCell,
			enableSorting: true,
		},
		{
			id: 'description',
			accessor: 'description',
			header: 'Description',
			cell: renderTextCell,
			enableSorting: true,
		},
	], [renderTextCell]);

	if (!billingCodes) {
		return null;
	}

	return (
		<div className={styles['job-preview__tab-content']}>
			<div className={styles['job-preview__tab-content__section']}>
				<SimpleTable
					columns={columns}
					rows={billingCodes}
				/>
			</div>
		</div>
	);
};

function mapDispatchToProps() {
	return {
		findAllBillingCodes: WorkRequestActions.findAllBillingCodesTable,
	};
}

const connector = connect(null, mapDispatchToProps());

const enhance = compose<React.ComponentType>(
	connector,
	React.memo
);

export default enhance(BillingCodesTab);
