import * as React from 'react';
import { Button, Row, Col } from 'react-bootstrap';
import type { ConnectedProps } from 'react-redux';
import { connect } from 'react-redux';
import { compose } from 'redux';

import CountryCode from 'acceligent-shared/enums/countryCode';
import WorkOrderStatusEnum from 'acceligent-shared/enums/workOrderStatus';

import AssignmentsModalSection from 'af-root/scenes/Company/ScheduleBoard/Shared/AssignmentsModalSection';
import Loading from 'af-root/scenes/Company/ScheduleBoard/Shared/LoadingModal';

import CustomModal from 'af-components/CustomModal';
import LockedValue from 'af-components/LockedValue';
import SegmentLabel from 'af-components/SegmentLabel';
import LabelWithColor from 'af-components/LabelWithColor';

import type ScheduleBoardWorkOrderViewModel from 'ab-socketModels/viewModels/scheduleBoard/scheduleBoardWorkOrder.viewModel';

import type CreateTemporaryEmployeeAssignmentRM from 'ab-requestModels/workOrderTemporaryEmployee/createTemporaryEmployeeAssignment';

import { MAX_EMPLOYEE_ASSIGNMENTS_PER_DAY } from 'ab-common/constants/scheduleBoard';

import type { RootState } from 'af-reducers';

import PagePermissions from 'ab-enums/pagePermissions.enum';

import { formatPhoneNumber } from 'ab-utils/phone.util';
import { isAllowed } from 'ab-utils/auth.util';

import type ScheduleBoardViewModel from 'ab-viewModels/scheduleBoard.viewModel';

import { useScheduleBoardModals } from 'af-root/hooks/useScheduleBoardModal';

interface OwnProps {
	createAssignment: (workOrderId: number, data: CreateTemporaryEmployeeAssignmentRM) => void;
	hasPermissionsToEditScheduleBoard: boolean;
	removeAssignment: (workOrderId: number, workOrderResourceLookupId: number) => void;
}

type Props = OwnProps & ConnectedProps<typeof connector>;

const _getStateFromProps = (
	id: Nullable<number>,
	date: Nullable<string>,
	workOrdersByDateDictionary: ScheduleBoardViewModel['workOrdersByDateDictionary']
) => {
	if (!id || !date || !workOrdersByDateDictionary) {
		return {
			assignedWorkOrders: [],
			assignableWorkOrders: [],
		};
	}

	const workOrders = workOrdersByDateDictionary[date];

	const assignedWorkOrderCodes = workOrders?.temporaryEmployeeAssignments?.[id] ?? [];
	const assignedWorkOrders: ScheduleBoardWorkOrderViewModel[] = assignedWorkOrderCodes
		.map((workOrderCode) => workOrders?.workOrders?.[workOrderCode])
		.filter((_wo): _wo is ScheduleBoardWorkOrderViewModel => (!!_wo));

	return {
		assignedWorkOrders,
		assignableWorkOrders: Object.keys(workOrders?.workOrders ?? {})
			.reduce((_acc, _woCode) => {
				if (
					workOrders?.workOrders?.[_woCode]
					&& !assignedWorkOrderCodes.includes(_woCode)
					&& workOrders.workOrders[_woCode].status !== WorkOrderStatusEnum.CANCELED
				) {
					_acc.push(workOrders.workOrders[_woCode]);
				}
				return _acc;
			}, [] as ScheduleBoardWorkOrderViewModel[]),
	};
};

const TemporaryEmployeeModal: React.FC<Props> = (props: Props) => {
	const {
		createAssignment,
		hasPermissionsToEditScheduleBoard,
		removeAssignment,
		workOrdersByDateDictionary,
		isAllowedToManagePerDiems,
	} = props;

	const { temporaryEmployee, temporaryEmployeeModalDate, setTemporaryEmployeeModalData } = useScheduleBoardModals();

	const {
		assignedWorkOrders,
		assignableWorkOrders,
	} = React.useMemo(
		() => _getStateFromProps(temporaryEmployee?.id ?? null, temporaryEmployeeModalDate, workOrdersByDateDictionary),
		[workOrdersByDateDictionary, temporaryEmployee, temporaryEmployeeModalDate]
	);

	const closeModal = React.useCallback(() => {
		setTemporaryEmployeeModalData(null, null);
	}, [setTemporaryEmployeeModalData]);

	const onCreateAssignment = React.useCallback(async (workOrderId: number) => {
		if (!hasPermissionsToEditScheduleBoard || !temporaryEmployee) {
			return false;
		}
		createAssignment(workOrderId, { temporaryEmployeeId: temporaryEmployee.id });
		return true;
	}, [hasPermissionsToEditScheduleBoard, temporaryEmployee, createAssignment]);

	const onRemoveAssignment = React.useCallback((workOrderId: number, workOrderCode: string, itemId: number, resourceLookupId?: number) => {
		if (!hasPermissionsToEditScheduleBoard || !resourceLookupId) {
			return;
		}
		removeAssignment(workOrderId, resourceLookupId);
	}, [hasPermissionsToEditScheduleBoard, removeAssignment]);

	const renderLoadingModalContent = React.useCallback(() => {
		return (<Loading onHide={closeModal} />);
	}, [closeModal]);

	const renderDataModalContent = React.useCallback(() => {
		if (!temporaryEmployee) {
			throw new Error('Employee not loaded');
		}

		const {
			email,
			firstName,
			id,
			jobCode,
			lastName,
			phoneNumber,
			agency,
			agencyColor,
			workOrderIdResourceLookupIdLookup,
		} = temporaryEmployee;

		const assignedInOffices = assignedWorkOrders.filter((wo) => !!wo?.officeNickname);

		return (
			<>
				<CustomModal.Header className="schedule-board-modal__resource__header">
					<div>
						<span className="icon-temp_labor schedule-board-modal__resource__header__icon"></span>
						<span>{firstName} {lastName}</span>
					</div>
					<LabelWithColor color={agencyColor} text={agency} />
				</CustomModal.Header>
				<CustomModal.Body>
					<div className="schedule-board-modal__resource__row">
						<SegmentLabel label="Agency" />
						<Row className="row--non-padded">
							<Col md={6}>
								<LockedValue
									label="Name"
									value={<LabelWithColor color={agencyColor} text={agency} />}
								/>
							</Col>
						</Row>
					</div>
					<hr />
					<div className="schedule-board-modal__resource__row">
						<SegmentLabel label="Contact Information" />
						<Row className="row--non-padded">
							<Col md={6}>
								<LockedValue
									label="Mobile Phone"
									value={formatPhoneNumber(phoneNumber, CountryCode.US)}
								/>
							</Col>
							<Col md={6}>
								<LockedValue
									label="Email"
									value={email}
								/>
							</Col>
							<Col md={12} />
						</Row>
					</div>
					<hr />
					<div className="schedule-board-modal__resource__row">
						<SegmentLabel label="Current Status" />
						<Row className="row--non-padded">
							<Col md={6}>
								<LockedValue
									label="Job"
									value={jobCode}
								/>
							</Col>
							<Col md={6}>
								<LockedValue
									label="Job Locations"
									value={assignedInOffices.length > 0
										? assignedInOffices.map((wo) => wo?.officeNickname).join(', ')
										: '-'
									}
								/>
							</Col>
						</Row>
					</div>
					<hr />
					<AssignmentsModalSection
						assignableWorkOrders={assignableWorkOrders}
						assignedWorkOrders={assignedWorkOrders}
						createAssignment={onCreateAssignment}
						isAllowedToManagePerDiems={isAllowedToManagePerDiems}
						isEditable={hasPermissionsToEditScheduleBoard}
						itemId={id}
						maxItems={MAX_EMPLOYEE_ASSIGNMENTS_PER_DAY}
						removeAssignment={onRemoveAssignment}
						resourceLookupIds={workOrderIdResourceLookupIdLookup}
						showPerDiem={false}
					/>
				</CustomModal.Body>
				<CustomModal.Footer>
					<Button
						onClick={closeModal}
						variant="info"
					>
						Close
					</Button>
				</CustomModal.Footer>
			</>
		);
	}, [
		assignableWorkOrders,
		assignedWorkOrders,
		closeModal,
		hasPermissionsToEditScheduleBoard,
		isAllowedToManagePerDiems,
		onCreateAssignment,
		onRemoveAssignment,
		temporaryEmployee,
	]);

	return (
		<CustomModal
			className="schedule-board-modal__resource"
			closeModal={closeModal}
			modalStyle="info"
			showModal={!!temporaryEmployee}
			size="xl"
		>
			{temporaryEmployee ? renderDataModalContent() : renderLoadingModalContent()}
		</CustomModal>
	);
};

function mapStateToProps(state: RootState) {
	const { companyData, userData } = state.user;
	if (!userData || !companyData) {
		throw new Error('User not logged in');
	}

	const isAllowedToManagePerDiems: boolean = isAllowed(PagePermissions.COMPANY.PER_DIEMS, companyData.permissions, companyData.isCompanyAdmin, userData.role);

	return {
		isAllowedToManagePerDiems,
		workOrdersByDateDictionary: state?.scheduleBoard?.workOrdersByDateDictionary,
	};
}

const connector = connect(mapStateToProps);

const enhance = compose<React.ComponentType<OwnProps>>(
	connector,
	React.memo
);

export default enhance(TemporaryEmployeeModal);
