import * as React from 'react';
import { compose } from 'redux';
import type { ResolveThunks } from 'react-redux';
import { connect } from 'react-redux';
import type { InjectedFormProps } from 'redux-form';
import { reduxForm } from 'redux-form';
import type { CustomRouteComponentProps } from 'react-router-dom';

import type { FieldWorkClassificationCodeCreateRM } from 'ab-requestModels/fieldWorkClassificationCode/upsert';

import { FIELD_WORK_CLASSIFICATION_CODE_CREATE } from 'af-constants/reduxForms';
import CLIENT from 'af-constants/routes/client';

import type { RootState } from 'af-reducers';

import * as FieldWorkClassificationCodeActions from 'af-actions/fieldWorkClassificationCode';

import type { BreadcrumbItem } from 'af-components/Breadcrumbs';
import Breadcrumbs from 'af-components/Breadcrumbs';

import FieldWorkClassificationCodeForm from '../Shared/Form';
import { validate } from '../Shared/Form/validation';

type OwnProps = CustomRouteComponentProps;

interface StateProps {
	companyName: string;
}

interface DispatchProps {
	create: typeof FieldWorkClassificationCodeActions.create;
}

type FormOwnProps = OwnProps & StateProps & ResolveThunks<DispatchProps>;

type Props = FormOwnProps & InjectedFormProps<FieldWorkClassificationCodeCreateRM, FormOwnProps>;

class FieldWorkClassificationCodeCreate extends React.PureComponent<Props> {

	static getBreadcrumbs = (orgAlias: string, companyName: string): BreadcrumbItem[] => {
		return [
			{ label: 'Classification Codes', url: CLIENT.COMPANY.RESOURCES.FIELD_WORK_CLASSIFICATION_CODE.LIST(orgAlias, companyName) },
			{ label: 'New Classification Code' },
		];
	};

	handleSubmit = async (form: FieldWorkClassificationCodeCreateRM) => {
		const { history, create, location: { state: { orgAlias } }, companyName } = this.props;
		await create(form);
		history.push(CLIENT.COMPANY.RESOURCES.FIELD_WORK_CLASSIFICATION_CODE.LIST(orgAlias, companyName));
	};

	render() {
		const { location: { state: { orgAlias } }, companyName } = this.props;

		return (
			<div className="form-segment">
				<Breadcrumbs items={FieldWorkClassificationCodeCreate.getBreadcrumbs(orgAlias, companyName)} />
				<FieldWorkClassificationCodeForm {...this.props} onSubmit={this.handleSubmit} />
			</div>
		);
	}
}

function mapStateToProps(state: RootState): StateProps {
	const { companyData } = state.user;
	if (!companyData) {
		throw new Error('User not logged in');
	}

	return {
		companyName: companyData.name,
	};
}

function mapDispatchToProps(): DispatchProps {
	return {
		create: FieldWorkClassificationCodeActions.create,
	};
}

const enhance = compose<React.ComponentClass<OwnProps>>(
	connect<StateProps, DispatchProps, OwnProps>(mapStateToProps, mapDispatchToProps()),
	reduxForm<FieldWorkClassificationCodeCreateRM, FormOwnProps>({
		form: FIELD_WORK_CLASSIFICATION_CODE_CREATE,
		validate,
	})
);

export default enhance(FieldWorkClassificationCodeCreate);
