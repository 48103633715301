import * as TimeUtils from 'acceligent-shared/utils/time';
import * as UserUtils from 'acceligent-shared/utils/user';

import TimeFormat from 'acceligent-shared/enums/timeFormat';
import type WorkOrderStatus from 'acceligent-shared/enums/workOrderStatus';

import type WorkOrderBase from 'ab-domain/models/workOrder/base';
import type EmployeeBase from 'ab-domain/models/employee/base';
import type WorkOrderEmployeeBase from 'ab-domain/models/workOrderEmployee/base';
import type WorkOrderEquipmentBase from 'ab-domain/models/workOrderEquipment/base';
import type WorkOrderTemporaryEmployeeBase from 'ab-domain/models/workOrderTemporaryEmployee/base';

import * as CodeUtils from 'ab-utils/codes.util';

class WorkOrderTemporaryEmployeeVM {
	fullName: string;
	uniqueId: string;

	constructor(workOrderTemporaryEmployee: WorkOrderTemporaryEmployeeBase) {
		const user = workOrderTemporaryEmployee.temporaryEmployee.account.user;

		this.fullName = UserUtils.getUserName(user);
		this.uniqueId = user.uniqueId;
	}

	static bulkConstructor = (temporaryEmployees: WorkOrderTemporaryEmployeeBase[]) => temporaryEmployees.map(WorkOrderTemporaryEmployeeVM._constructorMap);
	private static _constructorMap = (temporaryEmployees: WorkOrderTemporaryEmployeeBase) => new WorkOrderTemporaryEmployeeVM(temporaryEmployees);
}

class WorkOrderEquipmentVM {
	code: string;
	specification: Nullable<string>;

	constructor(workOrderEquipment: WorkOrderEquipmentBase) {
		this.code = workOrderEquipment.equipment.code;
		this.specification = workOrderEquipment.equipment.specification;
	}

	static bulkConstructor = (employees: WorkOrderEquipmentBase[]) => employees.map(WorkOrderEquipmentVM._constructorMap);
	private static _constructorMap = (employee: WorkOrderEquipmentBase) => new WorkOrderEquipmentVM(employee);
}

class WorkOrderEmployeeVM {
	fullName: string;
	uniqueId: string;

	constructor(workOrderEmployee: WorkOrderEmployeeBase) {
		const user = workOrderEmployee.employee.account.user;

		this.fullName = UserUtils.getUserName(user);
		this.uniqueId = user.uniqueId;
	}

	static bulkConstructor = (employees: WorkOrderEmployeeBase[]) => employees.map(WorkOrderEmployeeVM._constructorMap);
	private static _constructorMap = (employee: WorkOrderEmployeeBase) => new WorkOrderEmployeeVM(employee);
}

class SupervisingEmployeeVM {
	fullName: string;

	constructor(employee: EmployeeBase) {
		this.fullName = UserUtils.getUserName(employee.account.user);
	}
}

const WORK_ORDER_TABLE_CSV_HEADER = [
	'Work Order',
	'Job title',
	'Date of work',
	'Crew',
	'Crew Type',
	'Superintendent',
	'Project Manager',
	'Status',
	'Production',
	'Revenue',
	'Man Hours',
	'Notes',
	'Equip./Labor/Temp.Labor',
];

export class WorkOrderTableViewModel {
	id: number;
	code: string;
	/** MM-DD-YYYY */
	dueDate: string;
	crewNumber: number;

	revision: string;

	projectManager: Nullable<SupervisingEmployeeVM>;
	supervisor: SupervisingEmployeeVM;

	workOrderEmployees: WorkOrderEmployeeVM[];
	workOrderEquipment: WorkOrderEquipmentVM[];
	workOrderTemporaryEmployees: WorkOrderTemporaryEmployeeVM[];

	revenue: Nullable<string>;
	manHourAverage: Nullable<string>;

	notes: Nullable<string>;
	jobTitle: string;
	status: WorkOrderStatus;
	crewType: Nullable<string>;
	woLink: Nullable<string>;
	crewColor: Nullable<string>;

	constructor(workOrder: WorkOrderBase) {
		this.id = workOrder.id;
		this.code = CodeUtils.workOrderCode(workOrder, workOrder.workRequest);
		this.crewNumber = workOrder.code;

		this.dueDate = TimeUtils.formatDate(workOrder.dueDate, TimeFormat.DATE_ONLY, TimeFormat.DB_DATE_ONLY);

		this.revision = CodeUtils.revisionCode(workOrder.revision);

		this.projectManager = workOrder.projectManager && new SupervisingEmployeeVM(workOrder.projectManager);

		if (!workOrder.supervisor) {
			throw new Error('WO supervisor not defined');
		}
		this.supervisor = workOrder.supervisor && new SupervisingEmployeeVM(workOrder.supervisor);

		this.workOrderEmployees = workOrder.workOrderEmployees && WorkOrderEmployeeVM.bulkConstructor(workOrder.workOrderEmployees);
		this.workOrderEquipment = workOrder.workOrderEquipment && WorkOrderEquipmentVM.bulkConstructor(workOrder.workOrderEquipment);
		this.workOrderTemporaryEmployees = workOrder.workOrderTemporaryEmployees
			&& WorkOrderTemporaryEmployeeVM.bulkConstructor(workOrder.workOrderTemporaryEmployees);

		this.revenue = workOrder.revenue;
		this.manHourAverage = workOrder.manHourAverage;

		this.notes = workOrder.notes;

		this.jobTitle = workOrder.workRequest.title;
		this.status = workOrder.status;
		this.crewType = workOrder.crewType?.name ?? null;
		this.crewColor = workOrder.crewType?.color ?? null;
	}

	static bulkConstructor(workOrders: WorkOrderBase[] = []): WorkOrderTableViewModel[] {
		return workOrders.map(WorkOrderTableViewModel._constructorMap);
	}

	static toCSVData(viewModels: WorkOrderTableViewModel[]): string[][] {
		const header: string[] = [...WORK_ORDER_TABLE_CSV_HEADER];
		const rows: string[][] = [];
		let maxEquipmentCount = 0;
		const equipmentData: string[][] = [];
		let maxEmployeeCount = 0;
		const employeesData: string[][] = [];
		let maxTempEmployeeCount = 0;
		const tempEmployeesData: string[][] = [];

		viewModels.forEach((_workOrder) => {
			rows.push([
				`${_workOrder.code}${_workOrder.revision ? `, REV ${_workOrder.revision}` : ''}`,
				_workOrder.jobTitle ? _workOrder.jobTitle : '-',
				`${TimeUtils.getShortDayName(_workOrder.dueDate)}, ${_workOrder.dueDate}`,
				_workOrder.crewNumber.toString(),
				_workOrder.crewType ? _workOrder.crewType : '-',
				_workOrder.supervisor ? _workOrder.supervisor.fullName : '-',
				_workOrder.projectManager ? _workOrder.projectManager.fullName : '-',
				_workOrder.status ? _workOrder.status : '-',
				'-',
				_workOrder.revenue! || '-',
				_workOrder.manHourAverage! || '-',
				_workOrder.notes ? _workOrder.notes : '-',
				`${_workOrder.workOrderEquipment.length}/${_workOrder.workOrderEmployees.length}/${_workOrder.workOrderTemporaryEmployees.length}`,
			]);
			maxEquipmentCount = Math.max(maxEquipmentCount, _workOrder.workOrderEquipment.length);
			equipmentData.push(_workOrder.workOrderEquipment.map(WorkOrderTableViewModel._toEquipmentCSVCell));
			maxEmployeeCount = Math.max(maxEmployeeCount, _workOrder.workOrderEmployees.length);
			employeesData.push(_workOrder.workOrderEmployees.map(WorkOrderTableViewModel._toEmployeeCSVCell));
			maxTempEmployeeCount = Math.max(maxTempEmployeeCount, _workOrder.workOrderTemporaryEmployees.length);
			tempEmployeesData.push(_workOrder.workOrderTemporaryEmployees.map(WorkOrderTableViewModel._toTempEmployeeCSVCell));
		});

		for (let _index = 0; _index < maxEquipmentCount; _index++) {
			header.push(`equipment.${_index}`);
			viewModels.forEach((_workOrder, _woIndex) =>
				rows[_woIndex].push(WorkOrderTableViewModel._toEquipmentCSVCell(_workOrder.workOrderEquipment[_index]))
			);
		}
		for (let _index = 0; _index < maxEmployeeCount; _index++) {
			header.push(`labor.${_index}`);
			viewModels.forEach((_workOrder, _woIndex) =>
				rows[_woIndex].push(WorkOrderTableViewModel._toEmployeeCSVCell(_workOrder.workOrderEmployees[_index]))
			);
		}
		for (let _index = 0; _index < maxTempEmployeeCount; _index++) {
			header.push(`tempLabor.${_index}`);
			viewModels.forEach((_workOrder, _woIndex) =>
				rows[_woIndex].push(WorkOrderTableViewModel._toTempEmployeeCSVCell(_workOrder.workOrderTemporaryEmployees[_index]))
			);
		}

		return [header, ...rows];
	}

	private static _constructorMap = (_workOrder: WorkOrderBase) => new WorkOrderTableViewModel(_workOrder);

	/**
	 * Might not be available in scenarios where we're exporting multiple WOs with different number of temporary employees on each
	 */
	private static _toTempEmployeeCSVCell(woTempEmployee: WorkOrderTemporaryEmployeeVM | undefined) {
		return woTempEmployee
			? `${woTempEmployee.fullName} ${woTempEmployee.uniqueId ? ` (${woTempEmployee.uniqueId})` : ''}`
			: '-';
	}

	/**
	 * Might not be available in scenarios where we're exporting multiple WOs with different number of employees on each
	 */
	private static _toEmployeeCSVCell(woEmployee: WorkOrderEmployeeVM | undefined) {
		return woEmployee
			? `${woEmployee.fullName} ${woEmployee.uniqueId ? ` (${woEmployee.uniqueId})` : ''}`
			: '-';
	}

	/**
	 * Might not be available in scenarios where we're exporting multiple WOs with different number of equipment on each
	 */
	private static _toEquipmentCSVCell(woEquipment: WorkOrderEquipmentVM | undefined) {
		return woEquipment
			? `${woEquipment.code}${woEquipment.specification ? ` (${woEquipment.specification})` : ''}`
			: '-';
	}
}
