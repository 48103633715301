import type { Dispatch, AnyAction } from 'redux';

import type { TableContent } from 'ab-common/dataStructures/tableContent';

import { TableQuery } from 'ab-common/dataStructures/tableQuery';

import type AgencyTableItemVM from 'ab-viewModels/agency/tableItem.viewModel';
import type AgencyVM from 'ab-viewModels/agency/agency.viewModel';
import type AgencyAssociatedWorkersTableVM from 'ab-viewModels/agency/associatedWorkersTable.viewModel';

import API from 'af-constants/routes/api';

import type { GetRootState } from 'af-reducers';

import { errorHandler } from 'af-utils/actions.util';
import { http } from 'af-utils/http.util';

import * as CreateAgencyAPI from 'ab-api/web/agency/create';
import * as UpdateAgencyAPI from 'ab-api/web/agency/update';
import * as DeleteAgencyAPI from 'ab-api/web/agency/softDelete';
import * as BulkDeleteAgencyAPI from 'ab-api/web/agency/bulkSoftDelete';
import * as ActivateAgencyAPI from 'ab-api/web/agency/activate';

export function findAllForCompanyTable(tableRequestModel: TableQuery) {
	return async (dispatch: Dispatch<AnyAction>, getState: GetRootState, { redirectTo }) => {

		const action = async () => {
			const data = new TableQuery(tableRequestModel);
			return await http.get<TableContent<AgencyTableItemVM>>(API.V1.RESOURCES.AGENCY.TABLE(data));
		};

		return await errorHandler(action, dispatch, redirectTo);
	};
}

export function findDeletedForCompanyActions(tableRequestModel: TableQuery) {
	return async (dispatch: Dispatch<AnyAction>, getState: GetRootState, { redirectTo }) => {

		const action = async () => {
			const data = new TableQuery(tableRequestModel);
			return await http.get<TableContent<AgencyTableItemVM>>(API.V1.RESOURCES.AGENCY.TABLE_DELETED(data));
		};

		return await errorHandler(action, dispatch, redirectTo);
	};
}

export function findList() {
	return async (dispatch: Dispatch<AnyAction>, getState: GetRootState, { redirectTo }) => {
		const action = async () => {
			return await http.get<AgencyVM[]>(API.V1.RESOURCES.AGENCY.LIST);
		};
		return await errorHandler(action, dispatch, redirectTo);
	};
}

export function findAll() {
	return async (dispatch: Dispatch<AnyAction>, getState: GetRootState, { redirectTo }) => {
		const action = async () => {
			return await http.get<AgencyVM[]>(API.V1.RESOURCES.AGENCY.LIST_ALL);
		};
		return await errorHandler(action, dispatch, redirectTo);
	};
}

export function create(data: CreateAgencyAPI.W_Agency_Create_RM) {
	return async (dispatch: Dispatch<AnyAction>, getState: GetRootState, { redirectTo }) => {

		const action = async () => {
			return await http.post<void>(CreateAgencyAPI.URL(), data);
		};

		return await errorHandler(action, dispatch, redirectTo);
	};
}

export function findById(agencyId: number) {
	return async (dispatch: Dispatch<AnyAction>, getState: GetRootState, { redirectTo }) => {

		const action = async () => {
			return await http.get<AgencyVM>(API.V1.RESOURCES.AGENCY.FIND_BY_ID(agencyId));
		};

		return await errorHandler(action, dispatch, redirectTo);
	};
}

export function update(agencyId: number, data: UpdateAgencyAPI.W_Agency_Update_RM) {
	return async (dispatch: Dispatch<AnyAction>, getState: GetRootState, { redirectTo }) => {

		const action = async () => {
			return await http.put<void>(UpdateAgencyAPI.URL(agencyId), data);
		};

		return await errorHandler(action, dispatch, redirectTo);
	};
}

export function softDelete(id: number) {
	return async (dispatch: Dispatch<AnyAction>, getState: GetRootState, { redirectTo }) => {

		const action = async () => {
			return await http.delete<void>(DeleteAgencyAPI.URL(id));
		};

		return await errorHandler(action, dispatch, redirectTo);
	};
}

export function bulkSoftDelete(agencyIds: number[]) {
	return async (dispatch: Dispatch<AnyAction>, getState: GetRootState, { redirectTo }) => {

		const action = async () => {
			const restBody: BulkDeleteAgencyAPI.W_Agency_BulkSoftDelete_RM = { agencyIds };
			await http.delete<void>(BulkDeleteAgencyAPI.URL(), restBody);
		};

		return await errorHandler(action, dispatch, redirectTo);
	};
}

export function activate(agencyId: number) {
	return async (dispatch: Dispatch<AnyAction>, getState: GetRootState, { redirectTo }) => {

		const action = async () => {
			return await http.put<void>(ActivateAgencyAPI.URL(agencyId));
		};

		return await errorHandler(action, dispatch, redirectTo);
	};
}

export function findAssociatedWorkers(agencyId: number, tableRequestModel: TableQuery) {
	return async (dispatch: Dispatch<AnyAction>, getState: GetRootState, { redirectTo }) => {

		const action = async () => {
			const tableData = new TableQuery(tableRequestModel);
			return await http.get<TableContent<AgencyAssociatedWorkersTableVM>>(API.V1.RESOURCES.AGENCY.GET_WORKERS(agencyId, tableData));
		};

		return await errorHandler(action, dispatch, redirectTo);
	};
}
