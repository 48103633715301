import * as React from 'react';

import type WorkOrderStatus from 'acceligent-shared/enums/workOrderStatus';

import type { WorkOrderResourceLookupModel } from 'ab-viewModels/scheduleBoardWorkOrderModal.viewModel';

import EmployeeItem from './EmployeeItem';
import EquipmentItem from './EquipmentItem';
import LaborPlaceholderItem from './LaborPlaceholderItem';
import EquipmentPlaceholderItem from './EquipmentPlaceholderItem';
import TemporaryEmployeeItem from './TemporaryEmployeeItem';

interface Props {
	/** 'MM-DD-YYYY' */
	dueDate: string;
	removedWorkOrderResourceLookupsDictionary?: { [ workOrderResourceLookupId: string]: boolean; };
	workOrderResourceLookups: WorkOrderResourceLookupModel[];
	workOrderStatus: WorkOrderStatus;
}

const ResourcesList: React.FC<Props> = (props: Props) => {
	const { dueDate, workOrderStatus, workOrderResourceLookups = [],  removedWorkOrderResourceLookupsDictionary = {} } = props;

	const renderResourceLookup = (resourceLookup: WorkOrderResourceLookupModel, key: number ) => {

		const removed = removedWorkOrderResourceLookupsDictionary[resourceLookup.id] ?? false;

		if (resourceLookup.workOrderEmployeeId) {
			return (
				<EmployeeItem
					dueDate={dueDate}
					key={`modal-resource-lookup-item-${key}`}
					removed={removed}
					resourceLookup={resourceLookup}
					workOrderStatus={workOrderStatus}
				/>
			);
		} else if (resourceLookup.workOrderEquipmentId) {
			return (
				<EquipmentItem
					key={`modal-resource-lookup-item-${key}`}
					removed={removed}
					resourceLookup={resourceLookup}
				/>
			);
		} else if (resourceLookup.workOrderTemporaryEmployeeId) {
			return (
				<TemporaryEmployeeItem
					dueDate={dueDate}
					key={`modal-resource-lookup-item-${key}`}
					removed={removed}
					resourceLookup={resourceLookup}
					workOrderStatus={workOrderStatus}
				/>
			);
		} else if (resourceLookup.wageRateId) {
			return (
				<LaborPlaceholderItem
					key={`modal-resource-lookup-item-${key}`}
					removed={removed}
					resourceLookup={resourceLookup}
				/>
			);
		} else if (resourceLookup.equipmentCostId) {
			return (
				<EquipmentPlaceholderItem
					key={`modal-resource-lookup-item-${key}`}
					removed={removed}
					resourceLookup={resourceLookup}
				/>
			);
		}
	};

	if (!workOrderResourceLookups.length) {
		return (
			<div className="no-data-label">
					No Labor/Equipment Assigned
			</div>
		);
	}

	return (
		<div className="work-order-info-modal__resource-lookup-group">
			{workOrderResourceLookups.map(renderResourceLookup)}
		</div>
	);
};

export default React.memo(ResourcesList);
