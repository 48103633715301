import * as React from 'react';
import type { ConnectedProps } from 'react-redux';
import { connect } from 'react-redux';

import type WorkSummaryDetailVM from 'ab-viewModels/fieldReport/workSummaryDetails.viewModel';

import * as WorkSummaryDetailsActions from 'af-actions/workSummaryDetails';

import { createNewQueryParams } from 'af-utils/window.util';
import type { CellWidthsMap } from 'af-utils/react.util';

import { WorkSummaryTableSortKey, WORK_SUMMARY_TABLE_SORT_META } from '../Table/values';
import WorkSummaryDetailsTableRow from './WorkSummaryDetailsTableRow';
import WorkSummaryTable from '../Table';
import { resolveHighlightGroupKey } from '../helpers';
import type { WorkSummaryColumnMeta} from '../values';
import { replicateColumnArray, WORK_SUMMARY_DETAILS_TABLE_COLUMNS, WorkSummaryColumns } from '../values';

interface OwnProps {
	workSummaryDetails: WorkSummaryDetailVM[];
	sortBy: WorkSummaryTableSortKey;
	onBillingStatusToggle: (workSummaryDetail: WorkSummaryDetailVM) => Promise<void>;
	onRowHighlight: (groupToHighlight: Nullable<string>, groupKeyToHighlight: Nullable<string>) => void;
	highlightedGroup: Nullable<string>;
	highlightedGroupKey: Nullable<string>;
	isReadOnly: boolean;
	fieldReportId: number;
	workSummaryDetailsByGroupKey: Record<string, WorkSummaryDetailVM[]>;
	pathName: string;
	alternativeWorkSummaryDetails: Set<WorkSummaryDetailVM>;
}

type Props = ConnectedProps<typeof connector> & OwnProps;

const _maxDefinitionFieldsReducer = (_max: number, _currentWSD: WorkSummaryDetailVM) => {
	return _currentWSD.definitionFields.length > _max ? _currentWSD.definitionFields.length : _max;
};

const _maxInformationalFieldsReducer = (_max: number, _currentWSD: WorkSummaryDetailVM) => {
	return _currentWSD.informationalFields.length > _max ? _currentWSD.informationalFields.length : _max;
};

const _resolveWorkSummaryDetailsTableHeaders = (maxNumberOfDefinitionFields: number, maxNumberOfInformationalFields: number) => {
	const headers: WorkSummaryColumnMeta[] = [];

	for (const column of WORK_SUMMARY_DETAILS_TABLE_COLUMNS) {
		if (column.id === WorkSummaryColumns.DEFINITION_FIELD) {
			if (maxNumberOfDefinitionFields > 0) {
				headers.push(...replicateColumnArray(column, maxNumberOfDefinitionFields));
			}
		} else if (column.id === WorkSummaryColumns.INFORMATIONAL_FIELD) {
			if (maxNumberOfInformationalFields > 0) {
				headers.push(...replicateColumnArray(column, maxNumberOfInformationalFields));
			}
		} else {
			headers.push(column);
		}
	}

	return headers;
};

const _getIds = ({ id }: { id: number; }) => id;

const _excludeDetailsFromCalculation = (_wsd: WorkSummaryDetailVM) => ({ ..._wsd, isBillable: false });

const _findFirstWithGroupName = ({ workSummaryGroup }: WorkSummaryDetailVM) => !!workSummaryGroup;

const _getGroupKey = (item: WorkSummaryDetailVM) => resolveHighlightGroupKey(item);

const WorkSummaryDetailsTable: React.FC<Props> = (props) => {
	const {
		sortBy,
		workSummaryDetails,
		onBillingStatusToggle,
		onRowHighlight,
		highlightedGroup,
		isReadOnly,
		updateBillingStatuses,
		updateGroupNames,
		highlightedGroupKey,
		workSummaryDetailsByGroupKey,
		pathName,
		fieldReportId,
		alternativeWorkSummaryDetails,
	} = props;
	const [checkedWorkSummaryDetails, setCheckedWorkSummaryDetails] = React.useState<WorkSummaryDetailVM[]>([]);

	const maxNumberOfDefinitionFields = workSummaryDetails?.reduce(_maxDefinitionFieldsReducer, 0);
	const maxNumberOfInformationalFields = workSummaryDetails?.reduce(_maxInformationalFieldsReducer, 0);
	const tableHeaders = _resolveWorkSummaryDetailsTableHeaders(maxNumberOfDefinitionFields, maxNumberOfInformationalFields);

	const setNewWorkSummaryCheckedList = React.useCallback(
		(wsds: WorkSummaryDetailVM[]) =>
			setCheckedWorkSummaryDetails(wsds),
		[]);

	const onGroupTogetherClick = React.useCallback(async () => {
		if (!highlightedGroupKey) {
			return;
		}

		const newCheckedWorkSummaryDetails = checkedWorkSummaryDetails.map(_getIds);

		await updateGroupNames({ toBeReset: false, workSummaryDetailIdsToGroup: newCheckedWorkSummaryDetails, fieldReportId });
		setCheckedWorkSummaryDetails([]);

	}, [highlightedGroupKey, checkedWorkSummaryDetails, updateGroupNames, fieldReportId]);

	const onExcludeFromCalculationGroupClick = React.useCallback(
		async () => {
			const wsdToUpdate = checkedWorkSummaryDetails.map(_excludeDetailsFromCalculation);
			await updateBillingStatuses({ fieldReportId, workSummaryDetails: wsdToUpdate });
			setCheckedWorkSummaryDetails([]);
		}, [checkedWorkSummaryDetails, fieldReportId, updateBillingStatuses]);

	const onRestoreOriginalClick = React.useCallback(async () => {
		if (!highlightedGroupKey) {
			return;
		}

		const _wsdsToResetGroupName = workSummaryDetailsByGroupKey[highlightedGroupKey].map(_getIds);

		await updateGroupNames({ toBeReset: true, workSummaryDetailIdsToGroup: _wsdsToResetGroupName, fieldReportId });
	}, [highlightedGroupKey, workSummaryDetailsByGroupKey, updateGroupNames, fieldReportId]);

	const renderWorkSummaryDetailsTableRow = React.useCallback((data: WorkSummaryDetailVM, cellWidthsMap: CellWidthsMap) => {
		const _wsdGroupKey = resolveHighlightGroupKey(data);

		const isFirstOfItsGroupKey = workSummaryDetailsByGroupKey[_wsdGroupKey][0].id === data.id;
		const isOfCurrentlyHighlightedGroup = _wsdGroupKey === highlightedGroupKey;
		const hasPreviouslyBeenSplit = !!workSummaryDetailsByGroupKey[_wsdGroupKey].find(_findFirstWithGroupName);
		const isGroupAndExcludeActionDisabled = checkedWorkSummaryDetails.length === 0;

		const indexInsideOfGroupKey = workSummaryDetailsByGroupKey[_wsdGroupKey].findIndex((_wsd) => _wsd.id === data.id);
		let isFirstOfItsGroupName = false;
		let isLastOfItsGroupName = false;

		if (data.workSummaryGroup !== workSummaryDetailsByGroupKey[_wsdGroupKey][indexInsideOfGroupKey - 1]?.workSummaryGroup) {
			isFirstOfItsGroupName = true;
		}
		if (data.workSummaryGroup !== workSummaryDetailsByGroupKey[_wsdGroupKey][indexInsideOfGroupKey + 1]?.workSummaryGroup
			&& !!workSummaryDetailsByGroupKey[_wsdGroupKey][indexInsideOfGroupKey + 1]) {
			isLastOfItsGroupName = true;
		}

		const jumpToQuantityFieldQueryString = createNewQueryParams({
			fieldReportId: `${fieldReportId}`,
			reportTypeId: `${data.fieldReportTypeId}`,
			fieldReportBlockId: `${data.quantityFieldId}`,
		});

		const jumpToQuantityFieldPathName = `${pathName}?${jumpToQuantityFieldQueryString}`;
		const isAlternativeOption = alternativeWorkSummaryDetails.has(data);
		const isCustomSorted = sortBy !== WorkSummaryTableSortKey.TYPE;

		return (
			<WorkSummaryDetailsTableRow
				cellWidthsMap={cellWidthsMap}
				checkedWorkSummaryDetails={checkedWorkSummaryDetails}
				currentlyHighlightedGroupKey={highlightedGroupKey}
				data={data}
				hasPreviouslyBeenSplit={hasPreviouslyBeenSplit}
				highlightedGroup={highlightedGroup}
				isAlternativeOption={isAlternativeOption}
				isCustomSorted={isCustomSorted}
				isFirstOfItsGroupKey={isFirstOfItsGroupKey}
				isFirstOfItsGroupName={isFirstOfItsGroupName}
				isGroupAndExcludeActionDisabled={isGroupAndExcludeActionDisabled}
				isLastOfItsGroupName={isLastOfItsGroupName}
				isOfCurrentlyHighlightedGroup={isOfCurrentlyHighlightedGroup}
				isReadOnly={isReadOnly}
				jumpToQuantityFieldPathName={jumpToQuantityFieldPathName}
				key={data.id}
				maxNumberOfDefinitionFields={maxNumberOfDefinitionFields}
				maxNumberOfInformationalFields={maxNumberOfInformationalFields}
				onBillingStatusToggle={onBillingStatusToggle}
				onExcludeFromCalculationGroupClick={onExcludeFromCalculationGroupClick}
				onGroupTogetherClick={onGroupTogetherClick}
				onRestoreOriginalClick={onRestoreOriginalClick}
				onRowHighlight={onRowHighlight}
				setNewWorkSummaryCheckedList={setNewWorkSummaryCheckedList}
			/>
		);
	}, [setNewWorkSummaryCheckedList,
		checkedWorkSummaryDetails,
		highlightedGroup,
		isReadOnly,
		maxNumberOfDefinitionFields,
		maxNumberOfInformationalFields,
		onBillingStatusToggle,
		onRowHighlight,
		onGroupTogetherClick,
		onExcludeFromCalculationGroupClick,
		workSummaryDetailsByGroupKey,
		highlightedGroupKey,
		onRestoreOriginalClick,
		pathName,
		fieldReportId,
		alternativeWorkSummaryDetails,
		sortBy,
	]);

	const sort = WORK_SUMMARY_TABLE_SORT_META[sortBy].sort;

	return (
		<WorkSummaryTable<WorkSummaryDetailVM>
			data={workSummaryDetails}
			getGroupKey={_getGroupKey}
			headers={tableHeaders}
			highlightedGroupKey={highlightedGroupKey}
			renderRow={renderWorkSummaryDetailsTableRow}
			sort={sort}
			useGroups={sortBy === WorkSummaryTableSortKey.TYPE}
		/>
	);

};

function mapDispatchToProps() {
	return {
		updateBillingStatuses: WorkSummaryDetailsActions.updateBillingStatuses,
		updateGroupNames: WorkSummaryDetailsActions.updateGroupNames,
	};
}

const connector = connect(null, mapDispatchToProps());

export default connector(WorkSummaryDetailsTable);
