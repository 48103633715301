import WorkRequestStatus from 'acceligent-shared/enums/workRequestStatus';
import TimeFormat from 'acceligent-shared/enums/timeFormat';
import type Priority from 'acceligent-shared/enums/priority';
import type { ColorPalette } from 'acceligent-shared/enums/color';
import type QuantityUnitType from 'acceligent-shared/enums/quantityUnit';
import type DeliverableDataType from 'acceligent-shared/enums/deliverableDataType';
import { PhoneTypesArray, EmailTypesArray } from 'acceligent-shared/enums/contactMethodType';
import type { PhoneTypes, EmailTypes } from 'acceligent-shared/enums/contactMethodType';

import * as TimeUtils from 'acceligent-shared/utils/time';
import * as UserUtils from 'acceligent-shared/utils/user';
import { getShortAddress } from 'acceligent-shared/utils/address';

import type WorkOrderUpsertRM from 'ab-dtos/requestModels/workOrder/upsert';

import type WorkRequestBase from 'ab-domain/models/workRequest/base';
import type BillingCodeBase from 'ab-domain/models/billingCode/base';
import type JobStatusBase from 'ab-domain/models/jobStatus/base';
import type AccountBase from 'ab-domain/models/account/base';
import type EmployeeBase from 'ab-domain/models/employee/base';
import type AddressBase from 'ab-domain/models/address/base';
import type ContactLookupBase from 'ab-domain/models/contactLookup/base';
import type DeliverableDataBase from 'ab-domain/models/deliverableData/base';

import * as CodeUtils from 'ab-utils/codes.util';
import * as ConverterUtil from 'ab-utils/converter.util';
import { getStreetAddress } from 'ab-utils/formatting.util';
import * as ContactUtils from 'ab-utils/contact.util';
import type ContactBase from 'ab-domain/models/contact/base';
import type ContactMethodBase from 'ab-domain/models/contactMethod/base';
import type CountryCode from 'acceligent-shared/enums/countryCode';
import type ContactAddressBase from 'ab-domain/models/contactAddress/base';

class ContactAddressVM {
	id: number;
	contactId: number;
	addressId: number;
	address: AddressVM;

	constructor(contactAddress: ContactAddressBase) {
		this.id = contactAddress.id;
		this.contactId = contactAddress.contactId;
		this.addressId = contactAddress.addressId;
		this.address = contactAddress.address && new AddressVM(contactAddress.address);
	}

	static bulkConstructor(contactAddresses: ContactAddressBase[]): ContactAddressVM[] {
		return contactAddresses.map(ContactAddressVM._mapConstructor);
	}

	private static _mapConstructor(address: ContactAddressBase): ContactAddressVM {
		return new ContactAddressVM(address);
	}
}

type GroupedContactMethods = {
	emails: ContactMethodVM[];
	phones: ContactMethodVM[];
};

class ContactMethodVM {
	id: number;
	contactId: number;
	type: EmailTypes | PhoneTypes;
	value: string;
	countryCode: Nullable<CountryCode>;
	extension: Nullable<string>;

	constructor(contactMethod: ContactMethodBase) {
		this.id = contactMethod.id;
		this.contactId = contactMethod.contactId;
		this.type = contactMethod.type;
		this.value = contactMethod.value;
		this.countryCode = contactMethod.countryCode;
		this.extension = contactMethod.extension;
	}

	static groupedBulkConstructor(contactMethods: ContactMethodBase[]): GroupedContactMethods {
		const result: GroupedContactMethods = {
			phones: [],
			emails: [],
		};

		for (const _cm of contactMethods) {
			if (PhoneTypesArray.indexOf(_cm.type) !== -1) {
				result.phones.push(new ContactMethodVM(_cm));
			} else if (EmailTypesArray.indexOf(_cm.type) !== -1) {
				result.emails.push(new ContactMethodVM(_cm));
			}
		}

		return result;
	}
}

class ContactVM {
	id: number;
	fullName: string;
	companyName: Nullable<string>;
	title: Nullable<string>;
	addresses: ContactAddressVM[];
	emails: ContactMethodVM[];
	phones: ContactMethodVM[];
	isUnsaved: boolean;

	constructor(contact: ContactBase) {
		this.id = contact.id;
		this.companyName = contact.companyName;
		this.fullName = contact.fullName;
		this.title = contact.title;
		this.addresses = ContactAddressVM.bulkConstructor(contact.addresses ?? []);

		const groupedMethods = ContactMethodVM.groupedBulkConstructor(contact.contactMethods ?? []);
		this.emails = groupedMethods.emails;
		this.phones = groupedMethods.phones;

		this.isUnsaved = contact.isUnsaved;
	}
}

class DeliverableDataSingleViewModel {
	id: number;
	name: string;
	type: DeliverableDataType;

	constructor(status: DeliverableDataBase) {
		this.id = status.id;
		this.name = status.name;
		this.type = status.type;
	}
}

class AccountVM {
	fullName: string;

	constructor(account: AccountBase) {
		this.fullName = UserUtils.getUserName(account.user);
	}
}

class EmployeeVM {
	id: number;
	formattedCode: string;
	firstName: string;
	lastName: string;
	fullName: string;
	accountId: number;

	constructor(employee: EmployeeBase) {
		this.id = employee.id;
		this.formattedCode = employee.account.user.uniqueId;
		this.firstName = employee.account.user.firstName;
		this.lastName = employee.account.user.lastName;
		this.fullName = UserUtils.getUserName(employee.account.user);
		this.accountId = employee.accountId;
	}
}

export class BillingCodeVM {
	id: number;
	workRequestId: number;
	lineItemNumber: number;
	customerNumber: Nullable<number>;
	customerId: string;
	ownerNumber: Nullable<string>;
	ownerId: Nullable<string>;
	unit: QuantityUnitType;
	unitPrice: string;
	bidQuantity: Nullable<string>;
	group: Nullable<string>;
	description: string;

	constructor(billingCode: BillingCodeBase) {
		this.id = billingCode.id;
		this.workRequestId = billingCode.workRequestId;
		this.lineItemNumber = billingCode.lineItemNumber;
		this.customerNumber = billingCode.customerNumber;
		this.customerId = billingCode.customerId;
		this.ownerNumber = billingCode.ownerNumber;
		this.ownerId = billingCode.ownerId;
		this.unit = billingCode.unit;
		this.unitPrice = billingCode.unitPrice;
		this.bidQuantity = billingCode.bidQuantity;
		this.group = billingCode.group ?? null;
		this.description = billingCode.description;
	}

	private static _constructorMap = (billingCode: BillingCodeBase) => new BillingCodeVM(billingCode);

	static bulkConstructor = (billingCodes: BillingCodeBase[]) => billingCodes.map(BillingCodeVM._constructorMap);
}

class JobStatusVM {
	id: number;
	name: string;
	description: Nullable<string>;
	color: ColorPalette;

	constructor(jobStatus: JobStatusBase) {
		this.id = jobStatus.id;
		this.name = jobStatus.name;
		this.description = jobStatus.description;
		this.color = jobStatus.color;
	}
}

class AddressVM {
	street: string;
	route: Nullable<string>;
	suite: Nullable<string>;
	city: Nullable<string>;
	state: Nullable<string>;
	zip: Nullable<string>;
	postalOfficeBoxCode: Nullable<string>;
	country: Nullable<string>;
	shortAddress: string;

	constructor(address: AddressBase) {
		this.street = getStreetAddress(address);
		this.route = address.route;
		this.suite = address.suite ?? null;
		this.city = address.locality;
		this.state = address.aa1;
		this.zip = address.postalCode;
		this.postalOfficeBoxCode = address.postalOfficeBoxCode ?? null;
		this.country = address.country;
		this.shortAddress = getShortAddress(address);
	}
}
/** @deprecated overused and doesn't fit in new architecture, you probably want to create a new VM instead of reusing this one */
export class JobViewModel {
	id: number;
	/** @deprecated */
	code: Nullable<string>;
	jobCode: string;
	isInternal: boolean;
	customer: Nullable<RequestContactViewModel>;
	siteContact: Nullable<RequestContactViewModel>;
	customerCompany: Nullable<string>;
	customerFormatted: Nullable<string>;
	customerFullName: Nullable<string>;
	office: Nullable<string>;
	division: Nullable<string>;
	guaranteedCompletionDate: Nullable<string>;
	projectManager: Nullable<EmployeeVM>;
	supervisor: RequestContactViewModel;
	targetCompletionDate: Nullable<string>;
	guaranteedDaysFromStart: Nullable<number>;
	targetedDaysFromStart: Nullable<number>;
	title: Nullable<string>;
	workLocation: Nullable<AddressVM>;
	travelDistance: Nullable<string>;
	travelDuration: Nullable<number>;
	travelLocation: Nullable<string>;
	travelLocationShort: Nullable<string>;
	hseRequirementsNote: Nullable<string>;
	jobNote: Nullable<string>;
	scheduleNote: Nullable<string>;
	startDate: Nullable<string>;
	actualStartDate: Nullable<Date>;
	status: WorkRequestStatus;
	jobStatus: Nullable<JobStatusVM>;
	year: Nullable<number>;
	codeRaw: Nullable<number>;
	companyId: Nullable<number>;
	organizationId: Nullable<number>;
	isShortCircuited: Nullable<boolean>;
	deliverableAssigneeId: Nullable<number>;
	deliverableAssignee: Nullable<AccountVM>;
	deliverableSoftwareId: Nullable<number>;
	deliverableSoftware: Nullable<DeliverableDataSingleViewModel>;
	deliverableFileFormatId: Nullable<number>;
	deliverableFileFormat: Nullable<DeliverableDataSingleViewModel>;
	deliverableCodeStandardId: Nullable<number>;
	deliverableCodeStandard: Nullable<DeliverableDataSingleViewModel>;
	deliverableDeliveryMethodId: Nullable<number>;
	deliverableDeliveryMethod: Nullable<DeliverableDataSingleViewModel>;
	deliverableDeliveryTimelineId: Nullable<number>;
	deliverableDeliveryTimeline: Nullable<DeliverableDataSingleViewModel>;
	deliverableNotes: Nullable<string>;
	isDeliverable: boolean;
	allowCustomerSignature: boolean;
	estimateTotal: Nullable<number>;
	priority: Priority;

	constructor(workRequest: WorkRequestBase) {
		this.id = workRequest?.id;
		this.code = workRequest?.year && workRequest?.code ? CodeUtils.workRequestCode(workRequest.year, workRequest.code) : null;
		const jobCode = workRequest?.jobCode ? workRequest?.jobCode : this.code;
		if (!jobCode) {
			// Job code must be either of the things
			throw new Error('Job code not valid');
		}
		this.jobCode = jobCode;
		this.isInternal = workRequest?.isInternal ?? false;

		this.projectManager = workRequest.projectManager && new EmployeeVM(workRequest.projectManager);

		this.customer = workRequest.customerContactId && workRequest.customerContact ? new RequestContactViewModel(workRequest.customerContact) : null;
		this.customerCompany = workRequest.customerCompanyName ?? this.customer?.contact?.companyName ?? null;
		this.customerFullName = this.customer?.contact?.fullName ?? null;
		this.customerFormatted = this.customerFullName && `${this.customerFullName}${this.customerCompany ? `, ${this.customerCompany}` : ''}`;

		this.siteContact = workRequest.supervisorContactId && workRequest.supervisorContact ? new RequestContactViewModel(workRequest.supervisorContact) : null;

		this.office = workRequest?.office?.nickname ?? null;
		this.division = workRequest?.division?.name ?? null;
		this.guaranteedCompletionDate = workRequest?.guaranteedCompletionDate
			? TimeUtils.formatDate(workRequest?.guaranteedCompletionDate, TimeFormat.DATE_ONLY, TimeFormat.DB_DATE_ONLY)
			: null;

		this.targetCompletionDate = workRequest?.targetCompletionDate
			? TimeUtils.formatDate(workRequest?.targetCompletionDate, TimeFormat.DATE_ONLY, TimeFormat.DB_DATE_ONLY)
			: null;
		this.guaranteedDaysFromStart = workRequest.startDate && workRequest.guaranteedCompletionDate
			? TimeUtils.daysBetween(workRequest.startDate, workRequest.guaranteedCompletionDate)
			: null;
		this.targetedDaysFromStart = workRequest.startDate && workRequest.targetCompletionDate
			? TimeUtils.daysBetween(workRequest.startDate, workRequest.targetCompletionDate)
			: null;
		this.title = workRequest?.title;

		this.travelDistance = workRequest?.travelDistance !== null
			? ConverterUtil.metersToMiles(workRequest?.travelDistance).toFixed(2)
			: null;
		this.travelDuration = workRequest?.travelDuration;
		const travelLocation = workRequest?.travelLocation && new AddressVM(workRequest?.travelLocation);
		this.workLocation = travelLocation;
		this.travelLocation = travelLocation?.street ?? null;
		this.travelLocationShort = travelLocation?.shortAddress ?? null;

		this.hseRequirementsNote = workRequest?.requestHSERequirementsNote;
		this.jobNote = workRequest?.requestJobNote;
		this.scheduleNote = workRequest?.scheduleNote;
		this.startDate = workRequest?.startDate;

		// published work orders with completed field reports sorted by dueDate DESC
		const workOrders = workRequest?.workOrders || [];
		const dateToUseAsStartDate = workOrders[0]?.dueDate || workRequest?.startDate;
		this.actualStartDate = dateToUseAsStartDate
			? TimeUtils.toUtcDate(dateToUseAsStartDate, TimeFormat.DB_DATE_ONLY)
			: null;

		this.year = workRequest?.year;
		this.codeRaw = workRequest?.code;
		this.companyId = workRequest?.companyId;
		this.organizationId = workRequest?.company?.organizationId;

		this.status = workRequest?.status;
		this.jobStatus = workRequest?.jobStatus ? new JobStatusVM(workRequest.jobStatus) : null;
		this.isShortCircuited = workRequest?.status === WorkRequestStatus.SHORT_CIRCUITED;

		this.deliverableAssigneeId = workRequest.deliverableAssigneeId;
		this.deliverableAssignee = workRequest.deliverableAssignee && new AccountVM(workRequest.deliverableAssignee);
		this.deliverableSoftwareId = workRequest.deliverableSoftwareId;
		this.deliverableSoftware = workRequest.deliverableSoftware && new DeliverableDataSingleViewModel(workRequest.deliverableSoftware);
		this.deliverableFileFormatId = workRequest.deliverableFileFormatId;
		this.deliverableFileFormat = workRequest.deliverableFileFormat && new DeliverableDataSingleViewModel(workRequest.deliverableFileFormat);
		this.deliverableCodeStandardId = workRequest.deliverableCodeStandardId;
		this.deliverableCodeStandard = workRequest.deliverableCodeStandard && new DeliverableDataSingleViewModel(workRequest.deliverableCodeStandard);
		this.deliverableDeliveryMethodId = workRequest.deliverableDeliveryMethodId;
		this.deliverableDeliveryMethod = workRequest.deliverableDeliveryMethod && new DeliverableDataSingleViewModel(workRequest.deliverableDeliveryMethod);
		this.deliverableDeliveryTimelineId = workRequest.deliverableDeliveryTimelineId;
		this.deliverableDeliveryTimeline = workRequest.deliverableDeliveryTimeline &&
			new DeliverableDataSingleViewModel(workRequest.deliverableDeliveryTimeline);
		this.deliverableNotes = workRequest.deliverableNotes;

		this.isDeliverable = !!this.deliverableAssigneeId;

		this.allowCustomerSignature = workRequest.allowCustomerSignature;
		this.estimateTotal = workRequest.estimateTotal;
		this.priority = workRequest.priority;
	}

	static toWorkOrderJobRequestModel(vm: JobViewModel): WorkOrderUpsertRM['job'] {
		return {
			id: vm.id,
			jobCode: vm.jobCode,
			isInternal: vm.isInternal,
			customerCompany: vm.customerCompany,
			customerFormatted: vm.customerFormatted,
			customerFullName: vm.customerFullName,
			status: vm.status,
			travelLocationShort: vm.travelLocationShort,
			title: vm.title,
			office: vm.office,
			isDeliverable: vm.isDeliverable,
			deliverableSoftware: vm.deliverableSoftware?.name ?? null,
			deliverableFileFormat: vm.deliverableFileFormat?.name ?? null,
			deliverableCodeStandard: vm.deliverableCodeStandard?.name ?? null,
			projectManager: vm.projectManager,
		};
	}
}

export function JobViewModels(workRequests: WorkRequestBase[]) {
	return workRequests.map((_job) => new JobViewModel(_job));
}

class RequestContactViewModel {
	contact: ContactVM;
	contactId: number;
	contactAddressIds: number[];
	contactEmailIds: number[];
	contactPhoneIds: number[];
	companyName: Nullable<string>;

	constructor(contact: ContactLookupBase) {
		this.contact = new ContactVM(contact.contact);
		this.contactId = contact.contact?.id;
		this.contactAddressIds = contact.contactLookupAddresses?.map((_wrcAdr) => _wrcAdr.contactAddressId) ?? [];
		this.contactEmailIds = ContactUtils.filterContactMethod(contact.contactLookupMethods, EmailTypesArray);
		this.contactPhoneIds = ContactUtils.filterContactMethod(contact.contactLookupMethods, PhoneTypesArray);
		this.companyName = contact.contact?.companyName;
	}
}
