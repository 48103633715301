import * as React from 'react';
import type { ResolveThunks } from 'react-redux';
import { connect } from 'react-redux';
import type { InjectedFormProps} from 'redux-form';
import { Field, change, formValueSelector } from 'redux-form';
import { Form, Row, Col } from 'react-bootstrap';

import AutoNotifyOption from 'acceligent-shared/enums/autoNotifyOption';

import type { RootState } from 'af-reducers';

import Checkbox from 'af-fields/Checkbox';

import CompanyFeatureFlagsEnum, { CompanyFeatureFlagsTips } from 'ab-enums/companyFeatureFlags.enum';

import RadioGroup from 'af-fields/RadioGroup';

import type { AutomaticNotifications } from '../formModel';

interface OwnProps {
	handleSubmit: InjectedFormProps<AutomaticNotifications>['handleSubmit'];
	formName: string;
	onSubmit: (form: AutomaticNotifications) => Promise<void>;
}

interface StateProps {
	selectFormFieldValue: (field: string) => number | string | boolean;
}

interface DispatchProps {
	changeField: typeof change;
}

type Props = OwnProps & StateProps & ResolveThunks<DispatchProps>;

class AutomaticNotificationsForm extends React.PureComponent<Props> {

	static autoNotifyOptions = [
		{ value: AutoNotifyOption.DEFAULT_TIME, label: 'Default Time' },
		{ value: AutoNotifyOption.IMMEDIATE, label: 'Immediate' },
	];

	onSubmit = async (form: AutomaticNotifications) => {
		await this.props.onSubmit(form);
	};

	changeField = (field: string, value: string | number) => {
		const { changeField, formName } = this.props;
		return changeField(formName, field, value);
	};

	selectPublishWorkOrder = (isChecked: boolean) => {
		const { changeField, formName } = this.props;
		changeField(formName, 'enableAutoNotifyDailyTips', isChecked);
	};

	render() {
		const { handleSubmit, selectFormFieldValue } = this.props;

		const enableAutoNotifyOnPublish = selectFormFieldValue('enableAutoNotifyOnPublish') as boolean;
		const enableAutoNotifyOnCancel = selectFormFieldValue('enableAutoNotifyOnCancel') as boolean;

		return (
			<Form onSubmit={handleSubmit(this.onSubmit)}>
				<Row className="row--padded-top">
					<Col sm={24}>
						<Field
							component={Checkbox}
							inline={true}
							isStandalone={true}
							label={CompanyFeatureFlagsEnum.notifyOnPublish}
							labelId="accqa__notification-settings__automatic__publish"
							name="enableAutoNotifyOnPublish"
							onValueChange={this.selectPublishWorkOrder}
							tooltipMessage={CompanyFeatureFlagsTips.notifyOnPublish}
							type="text"
						/>
						{
							enableAutoNotifyOnPublish &&
							<div className="row--flex">
								<RadioGroup
									changeField={this.changeField}
									field="notifyOnPublish"
									inline={true}
									items={AutomaticNotificationsForm.autoNotifyOptions}
									padded={true}
									selector={selectFormFieldValue}
								/>
								<Field
									component={Checkbox}
									extraClass="company-settings__auto-notify-checkbox"
									inline={true}
									label="Enable Daily Tips"
									name="enableAutoNotifyDailyTips"
									type="text"
								/>
							</div>
						}
					</Col>
				</Row>
				<Row>
					<Col sm={24}>
						<Field
							component={Checkbox}
							inline={true}
							label={CompanyFeatureFlagsEnum.notifyOnCancel}
							labelId="accqa__notification-settings__automatic__cancel"
							name="enableAutoNotifyOnCancel"
							tooltipMessage={CompanyFeatureFlagsTips.notifyOnCancel}
							type="text"
						/>
						{
							enableAutoNotifyOnCancel &&
							<RadioGroup
								changeField={this.changeField}
								field="notifyOnCancel"
								initialValue={AutoNotifyOption.DEFAULT_TIME}
								inline={true}
								items={AutomaticNotificationsForm.autoNotifyOptions}
								padded={true}
								selector={selectFormFieldValue}
							/>
						}
					</Col>
				</Row>
				<Row>
					<Col sm={24}>
						<Field
							component={Checkbox}
							inline={true}
							label={`${CompanyFeatureFlagsEnum.notifyOnPause} (Immediate only)`}
							labelId="accqa__notification-settings__automatic__pause"
							name="notifyOnPause"
							tooltipMessage={CompanyFeatureFlagsTips.notifyOnPause}
							type="text"
						/>
					</Col>
				</Row>
				<Row>
					<Col sm={24}>
						<Field
							component={Checkbox}
							inline={true}
							label={`${CompanyFeatureFlagsEnum.notifyOnResume} (Immediate only)`}
							labelId="accqa__notification-settings__automatic__resume"
							name="notifyOnResume"
							tooltipMessage={CompanyFeatureFlagsTips.notifyOnResume}
							type="text"
						/>
					</Col>
				</Row>
				<Row>
					<Col md={24}>
						<Field
							component={Checkbox}
							inline={true}
							label={`${CompanyFeatureFlagsEnum.notifyMultipleAssignments} (Default Time only)`}
							labelId="accqa__notification-settings__automatic__multiple"
							name="notifyMultipleAssignments"
							tooltipMessage={CompanyFeatureFlagsTips.notifyMultipleAssignments}
							type="text"
						/>
					</Col>
				</Row>
				<Row>
					<Col md={24}>
						<Field
							component={Checkbox}
							inline={true}
							label={`${CompanyFeatureFlagsEnum.notifyUnassignedEmployees} (Default Time only)`}
							labelId="accqa__notification-settings__automatic__unassigned"
							name="notifyUnassignedEmployees"
							tooltipMessage={CompanyFeatureFlagsTips.notifyUnassignedEmployees}
							type="text"
						/>
					</Col>
				</Row>
				<Row>
					<Col md={24}>
						<Field
							component={Checkbox}
							inline={true}
							label={`${CompanyFeatureFlagsEnum.notifySupervisors} (30 min before Default Time only)`}
							labelId="accqa__notification-settings__automatic__supervisors"
							name="notifySupervisors"
							tooltipMessage={CompanyFeatureFlagsTips.notifySupervisors}
							type="text"
						/>
					</Col>
				</Row>
				<Row>
					<Col sm={24}>
						<Field
							component={Checkbox}
							label={`${CompanyFeatureFlagsEnum.notifyQAQC} (Default Time only)`}
							labelId="accqa__notification-settings__automatic__deliverable-qaqc"
							name="enableNotifyQAQC"
							tooltipMessage={CompanyFeatureFlagsTips.notifyQAQC}
							type="text"
						/>
					</Col>
				</Row>
				<Row>
					<Col sm={24}>
						<Field
							component={Checkbox}
							label={`${CompanyFeatureFlagsEnum.notifyDeliverableAssignee} (Immediate only)`}
							labelId="accqa__notification-settings__automatic__deliverable-assignee"
							name="enableNotifyDeliverableAssignee"
							tooltipMessage={CompanyFeatureFlagsTips.notifyDeliverableAssignee}
							type="text"
						/>
					</Col>
				</Row>
				<Row>
					<Col sm={24}>
						<Field
							component={Checkbox}
							label={`${CompanyFeatureFlagsEnum.notifyOnTimeSheetReject} (Immediate only)`}
							labelId="accqa__notification-settings__automatic__time-sheet-reject"
							name="notifyOnTimeSheetReject"
							tooltipMessage={CompanyFeatureFlagsTips.notifyOnTimeSheetReject}
							type="text"
						/>
					</Col>
				</Row>
				<Row className="row--padded-bottom">
					<Col sm={24}>
						<Field
							component={Checkbox}
							label={`${CompanyFeatureFlagsEnum.notifyOnReportReject} (Immediate only)`}
							labelId="accqa__notification-settings__automatic__report-reject"
							name="notifyOnReportReject"
							tooltipMessage={CompanyFeatureFlagsTips.notifyOnReportReject}
							type="text"
						/>
					</Col>
				</Row>
			</Form>
		);
	}
}

function mapStateToProps(state: RootState, ownProps: OwnProps): StateProps {
	return {
		selectFormFieldValue: (field: string) => formValueSelector(ownProps.formName)(state, field),
	};
}

function mapDispatchToProps(): DispatchProps {
	return {
		changeField: change,
	};
}

export default connect<StateProps, DispatchProps, OwnProps>(mapStateToProps, mapDispatchToProps())(AutomaticNotificationsForm);
