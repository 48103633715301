import * as React from 'react';
import * as ReactDOM from 'react-dom';
import type { DraggableStateSnapshot, DraggableProvided } from 'react-beautiful-dnd';

import type { ColorPalette } from 'acceligent-shared/enums/color';

import LabelWithColor from 'af-components/LabelWithColor';

interface OwnProps {
	provided: DraggableProvided;
	snapshot: DraggableStateSnapshot;
	nickname: string;
	color: ColorPalette;
}

type Props = OwnProps;

export default class DraggableLocation extends React.Component<Props> {

	_portal: Nullable<HTMLDivElement> = null;

	constructor(props: Props) {
		super(props);
		const portal = document.getElementById('draggable-location-portal') as HTMLDivElement;
		this._portal = portal || document.createElement('div');
		this._portal.id = this._portal.id || 'draggable-location-portal';
		if (!portal) {
			document.body.appendChild(this._portal);
		}
	}

	render() {
		const { provided, snapshot, nickname, color } = this.props;
		const usePortal: boolean = snapshot.isDragging;

		const child = (
			<div
				ref={provided.innerRef}
				{...provided.draggableProps}
				{...provided.dragHandleProps}
			>
				<div className="locations-table__draggable-container">
					<span className="icon-drag_indicator" />
					<LabelWithColor color={color} text={nickname} />
				</div>
			</div>
		);

		if (!usePortal) {
			return child;
		}

		return ReactDOM.createPortal(child, this._portal!);
	}
}
