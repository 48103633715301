import * as React from 'react';
import { Route, Routes } from 'react-router-dom-v5-compat';

import Error404 from 'af-root/scenes/Error/error404';

import WorkRequestPreviewTab from 'af-components/SharedPreviews/WorkRequest/Details';
import JobHazardAssessmentTab from 'af-components/SharedPreviews/WorkRequest/JobHazardAssessment';
import DirectoriesAttachments from 'af-components/SharedPreviews/WorkRequest/DirectoriesAttachments';

import WorkRequestPreview from './index';
import { WorkRequestPreviewTabs } from './types';

const WorkRequestPreviewRouter: React.FC = () => {
	return (
		<Routes>
			<Route
				element={<WorkRequestPreview />}
			>
				<Route element={<WorkRequestPreviewTab />} index />
				<Route element={<WorkRequestPreviewTab />} path={`/${WorkRequestPreviewTabs.DETAILS}`} />
				<Route element={<JobHazardAssessmentTab />} path={`/${WorkRequestPreviewTabs.JOB_HAZARD_ASSESSMENT}`} />
				<Route element={<DirectoriesAttachments />} path={`/${WorkRequestPreviewTabs.ATTACHMENTS}`} />
			</Route>
			<Route element={<Error404 />} path="*" />
		</Routes>
	);
};

export default React.memo(WorkRequestPreviewRouter);
