import * as React from 'react';
import { Row, Col } from 'react-bootstrap';
import type { ConnectedProps } from 'react-redux';
import { connect } from 'react-redux';
import type { CustomRouteComponentProps } from 'react-router-dom';
import { NotionRenderer } from 'react-notion-x';
import { Pdf } from 'react-notion-x/build/third-party/pdf';
import type { ExtendedRecordMap } from 'notion-types';

import * as KnowledgeCenterActionsActions from 'af-actions/knowledgeCenter';

import SegmentLabel from 'af-components/SegmentLabel';

import type { RootState } from 'af-reducers';

import CLIENT from 'af-routes/client';

import NotionLink from './NotionLink';
import Loading from './Loading';

import styles from './styles.module.scss';

const mapStateToProps = (state: RootState) => {
	const companyName = state.user.companyData?.name;
	const complianceEhsUrl = state.user.companyData?.complianceEhsUrl ?? null;
	return {
		companyName,
		complianceEhsUrl,
	};
};

const mapDispatchToProps = () => {
	return {
		getNotionPage: KnowledgeCenterActionsActions.getNotionPage,
	};
};

const connector = connect(mapStateToProps, mapDispatchToProps());

interface PathParams {
	orgAlias: string;
	pageId?: string;
}

type Props = ConnectedProps<typeof connector> & CustomRouteComponentProps<PathParams>;

const CompliancePage: React.FC<Props> = (props) => {
	const { getNotionPage, match: { params: { orgAlias, pageId } }, complianceEhsUrl, companyName } = props;

	const [page, setPage] = React.useState<Nullable<ExtendedRecordMap>>(null);

	const loadPage = React.useCallback(async () => {
		if (!complianceEhsUrl) {
			return;
		}
		let resolvedPageId: Nullable<string> = null;
		if (!pageId) {
			const parts = complianceEhsUrl?.split('/');
			const lastPart = parts.pop();
			if (!lastPart) {
				return;
			}
			resolvedPageId = lastPart.split('-')?.pop() ?? null;
			if (!resolvedPageId) {
				return;
			}

		} else {
			resolvedPageId = pageId;
		}
		setPage(null);
		const response = await getNotionPage(resolvedPageId);
		setPage(response);
	}, [complianceEhsUrl, getNotionPage, pageId]);

	const mapPageUrl = React.useCallback((requestedPageId: string) => {
		return CLIENT.COMPANY.COMPLIANCE.PAGE(orgAlias, companyName, requestedPageId);
	}, [companyName, orgAlias]);

	const mapImageUrl = React.useCallback((url: string) => {
		return url;
	}, []);

	React.useEffect(() => {
		loadPage();
	}, [loadPage]);

	if (!page) {
		return <Loading />;
	}

	return (
		<div className={styles.companyCompliance}>
			<div className="form-segment">
				<div className="form-box form-box--standalone">
					<Row>
						<Col sm={24}>
							<SegmentLabel label="Compliance" />
							<NotionRenderer
								components={{ PageLink: NotionLink, Pdf }}
								fullPage={true}
								mapImageUrl={mapImageUrl}
								mapPageUrl={mapPageUrl}
								recordMap={page}
							/>
						</Col>
					</Row>
				</div>
			</div>
		</div>
	);
};

export default connector(CompliancePage);
