import * as React from 'react';
import { Row, Col } from 'react-bootstrap';

import ToolStatus, { ToolStatusLabel } from 'acceligent-shared/enums/toolStatus';
import ToolState, { ToolStateLabel } from 'acceligent-shared/enums/toolState';

import type ToolVM from 'ab-viewModels/tool/edit.viewModel';

import Label from 'af-components/LockedValue/Label';
import StatusLabel from 'af-components/StatusLabel';

import styles from './styles.module.scss';
interface OwnProps {
	item: ToolVM;
}

type Props = OwnProps;

const ToolInfo = (props: Props) => {
	const {
		item,
	} = props;

	return (
		<div className="form-box">
			<Row className="row--flex">
				<Col md={4}>
					<Label className={styles.label} label={'Serial Number'} />
					<div className={styles.value}>{item.serialNumber}</div>
					<Label className={styles.label} label={'Manufacturer'} />
					<div className={styles.value}>{item.manufacturer}</div>
				</Col>
				<Col md={4}>
					<Label className={styles.label} label={'Tool Type'} />
					<div className={styles.value}>{item.toolType.name}</div>
					<Label className={styles.label} label={'Model Number'} />
					<div className={styles.value}>{item.modelNumber}</div>
				</Col>
				<Col md={4}>
					<Label className={styles.label} label={'Status'} />
					<div className={styles.value}>
						<StatusLabel
							grey={item.state === ToolState.INACTIVE}
							isAvailable={item.state === ToolState.ACTIVE}
							label={ToolStateLabel[item.state]}
						/>
					</div>
					<Label className={styles.label} label={'Home Location'} />
					<div className={styles.value}>{item.location.name}</div>
				</Col>
				<Col md={4}>
					<Label className={styles.label} label={'Repair Status'} />
					<div className={styles.value}>
						<StatusLabel
							isAvailable={item.status === ToolStatus.AVAILABLE}
							label={ToolStatusLabel[item.status]}
						/>
					</div>
				</Col>
				<Col md={12}>
					<Label className={styles.label} label={'Note'} />
					<div className={styles.value}>{item.note}</div>
				</Col>
			</Row>
		</div>
	);
};

export default React.memo(ToolInfo);
