import { Button } from 'react-bootstrap';
import * as React from 'react';
import type { ConnectedProps } from 'react-redux';
import { connect } from 'react-redux';
import type { CustomLocationState } from 'react-router-dom';
import type { v6Props } from 'react-router-dom-v5-compat';
import { Link, Outlet } from 'react-router-dom-v5-compat';
import { compose } from 'redux';

import * as TimeUtils from 'acceligent-shared/utils/time';

import WorkOrderStatus from 'acceligent-shared/enums/workOrderStatus';
import TimePeriodRecurrence from 'acceligent-shared/enums/timePeriodRecurrence';
import WorkRequestStatus from 'acceligent-shared/enums/workRequestStatus';

import * as JobActions from 'af-actions/jobs';
import * as WorkRequestActions from 'af-actions/workRequests';

import type { RootState } from 'af-reducers';

import CLIENT from 'af-constants/routes/client';
import * as SettingsKeys from 'af-constants/settingsKeys';

import { WorkOrderTableViewModel } from 'ab-viewModels/workOrderTable.viewModel';
import type WorkRequestDirectoryVM from 'ab-viewModels/directory/workRequestDirectory.viewModel';
import type JobPreviewViewModel from 'ab-viewModels/jobPreview.viewModel';

import { TableQuery } from 'ab-common/dataStructures/tableQuery';

import withRouterV6 from 'af-components/V6Wrapper';
import type { Column, TabProps } from 'af-components/Table6';
import Breadcrumbs from 'af-components/Breadcrumbs';
import EmptyCell from 'af-components/Table/Cells/EmptyCell';
import ResourceCell from 'af-components/Table6/Cells/ResourceCell';
import LabelWithColor from 'af-components/LabelWithColor';
import type TableComponent from 'af-components/Table6/Table';
import type ScrollToLoad from 'af-components/ScrollToLoad';
import type InfiniteScroll from 'af-components/ScrollToLoad';
import DateFilter from 'af-components/DateFilter';
import TabNavigation from 'af-components/TabNavigation';
import ProjectSubJobIndicator from 'af-components/ProjectSubJobIndicator';

import { downloadCSV } from 'af-utils/csv.utils';
import { setItemWithFormatter, setItem } from 'af-utils/settings.util';
import * as SettingsUtils from 'af-utils/settings.util';

import TableButtonType from 'ab-enums/tableButtonType.enum';
import PagePermissions from 'ab-enums/pagePermissions.enum';
import BrowserStorageEnum from 'ab-enums/browserStorage.enum';

import { formatDecimalNumber } from 'ab-utils/formatting.util';
import { isAllowed } from 'ab-utils/auth.util';

import Loading from './Loading';

import styles from './styles.module.scss';

import CopyModal from '../Shared/CopyModal';
import { JobPreviewTabRoutes, type JobPreviewOutletContext } from './types';
import { JobEditTabRoutes } from '../Form/Edit/types';

interface SettingProps {
	startDate: Date;
	endDate: Date;
	period: TimePeriodRecurrence;
}

interface State {
	job: Nullable<JobPreviewViewModel>;
	startDate: Date;
	endDate: Date;
	period: TimePeriodRecurrence;
	directoriesAndAttachments: Nullable<WorkRequestDirectoryVM>;
	showCopyModal: boolean;
}

type Props = v6Props<CustomLocationState> & SettingProps & ConnectedProps<typeof connector>;

function setWOStartDate(date: Date = new Date()): void {
	setItemWithFormatter(SettingsKeys.WORK_ORDERS_REPORTS_START_DATE(), date, TimeUtils.formatDate, BrowserStorageEnum.LOCAL_STORAGE);
}

function setWOEndDate(date: Date = new Date()): void {
	setItemWithFormatter(SettingsKeys.WORK_ORDERS_REPORTS_END_DATE(), date, TimeUtils.formatDate, BrowserStorageEnum.LOCAL_STORAGE);
}

function setWOPeriod(period: TimePeriodRecurrence): void {
	setItem(SettingsKeys.WORK_ORDERS_REPORTS_PERIOD(), period, BrowserStorageEnum.LOCAL_STORAGE);
}

export const PreviewTabIndexes: Record<keyof typeof JobPreviewTabRoutes, number> = {
	DETAILS: 0,
	JOB_HAZARD_ASSESSMENT: 1,
	BILLING_CODES: 2,
	ATTACHMENTS: 3,
	RELATED_WORK_ORDERS: 4,
	WORK_SUMMARY: 5,
	INVOICES: 6,
};

const PREVIEW_TABS = [
	{ id: PreviewTabIndexes.DETAILS, label: 'Details' },
	{ id: PreviewTabIndexes.JOB_HAZARD_ASSESSMENT, label: 'Job Hazard Assessment' },
	{ id: PreviewTabIndexes.BILLING_CODES, label: 'Billing Codes' },
	{ id: PreviewTabIndexes.ATTACHMENTS, label: 'Attachments' },
	{ id: PreviewTabIndexes.RELATED_WORK_ORDERS, label: 'Related Work Orders' },
	{ id: PreviewTabIndexes.WORK_SUMMARY, label: 'Work Summary' },
	{ id: PreviewTabIndexes.INVOICES, label: 'Invoices' },
];
class Preview extends React.Component<Props, State> {

	private _tableRef: Nullable<TableComponent<WorkOrderTableViewModel>> = null;
	private _list: Nullable<ScrollToLoad<WorkOrderTableViewModel>> = null;

	state: State = {
		job: null,
		startDate: this.props.startDate,
		endDate: this.props.endDate,
		period: this.props.period,
		directoriesAndAttachments: null,
		showCopyModal: false,
	};

	columns: Column<WorkOrderTableViewModel>[] = [
		{
			Header: 'Work Order',
			accessor: 'workOrderCode',
			id: 'code',
			className: 'text-strong',
			width: 220,
			Cell: ({ original }) => (
				<span>{original.code}{original.revision ? `, REV ${original.revision}` : ''}</span>
			),
		},
		{
			Header: 'Job Title',
			accessor: 'title',
			id: 'title',
			width: 140,
			Cell: ({ original }) => (
				<span>{original.jobTitle}</span>
			),
		},
		{
			Header: 'Status',
			accessor: 'status',
			id: 'status',
			width: 100,
			Cell: ({ original }) => {
				switch (original.status) {
					case WorkOrderStatus.CANCELED:
						return <span className="text-red text-strong">{original.status}</span>;
					default:
						return <span className="text-black">{original.status}</span>;
				}
			},
		},
		{
			Header: 'Date of Work',
			accessor: 'dueDate',
			width: 160,
			Cell: ({ original }) => <span>{TimeUtils.getShortDayName(original.dueDate)}, {original.dueDate}</span>,
		},
		{
			Header: 'Crew',
			accessor: 'code',
			width: 90,
			Cell: ({ original }) => original.crewNumber,
		},
		{
			Header: 'Crew Type',
			accessor: 'crewType',
			width: 120,
			Cell: ({ original }) => original.crewType ? <LabelWithColor color={original.crewColor} text={original.crewType} /> : <EmptyCell />,
		},
		{
			Header: 'Superintendent',
			accessor: 'supervisor.account.user.firstName',
			Cell: ({ original }) => original.supervisor?.fullName ?? <EmptyCell />,
		},
		{
			Header: 'Project Manager',
			accessor: 'projectManager.account.user.firstName',
			Cell: ({ original }) => original.projectManager?.fullName ?? <EmptyCell />,
		},
		{
			Header: 'Production',
			accessor: 'production',
			width: 105,
			sortable: false,
			Cell: () => <EmptyCell />,
		},
		{
			Header: 'Revenue',
			accessor: 'revenue',
			Cell: ({ original }) => original.revenue ? `$ ${formatDecimalNumber(original.revenue)}` : <EmptyCell />,
		},
		{
			Header: 'Man-hours',
			accessor: 'manHourAverage',
			Cell: ({ original }) => original.manHourAverage ? `$ ${formatDecimalNumber(original.manHourAverage)}` : <EmptyCell />,
		},
		{
			Header: 'Equip./Labor/Temp. Labor',
			accessor: 'equipmentAndLabor',
			className: 'text-center',
			sortable: false,
			Cell: ({ original }) => {
				return (
					<ResourceCell
						original={original}
					/>
				);
			},
		},
		{
			Header: 'Notes',
			accessor: 'notes',
			Cell: ({ original }) => original.notes ?? <EmptyCell />,
		},
		{
			accessor: 'woLink',
			Header: 'Field Report',
			sortable: false,
			width: 120,
			Cell: ({ original }) => {
				switch (original.status) {
					case WorkOrderStatus.DRAFT:
						return <EmptyCell />;
					default:
						return (
							<div className="work-order-info-modal__report-link" onClick={this.openWoReport.bind(null, original.id)}>
								<div className="work-order-info-modal__icon-text">View</div> < span className="icon-external link-cell__icon" />
							</div >
						);
				}
			},
		},
	];

	async componentDidMount() {
		const { findJobById, params: { jobId } } = this.props;
		const job = await findJobById(+jobId);
		this.setState(() => ({ job }));
	}

	onTableMount = (table: TableComponent<WorkOrderTableViewModel>, list: InfiniteScroll<WorkOrderTableViewModel>) => {
		this._tableRef = table;
		this._list = list;
	};

	openWoReport = (workOrderId: number) => {
		const { companyName, location: { state: { orgAlias } } } = this.props;

		window.open(CLIENT.COMPANY.FIELD_REPORT.ALL_REPORTS(workOrderId.toString(), orgAlias, companyName));
	};

	openCopyJobModal = () => {
		this.setState(() => ({ showCopyModal: true }));
	};

	closeCopyJobModal = () => {
		this.setState(() => ({ showCopyModal: false }));
	};

	tabs = (): TabProps<WorkOrderTableViewModel>[] => {
		const { params: { jobId }, findRelatedWorkOrders } = this.props;
		const { startDate, endDate } = this.state;

		return [
			{
				label: 'Work Orders',
				columns: this.columns,
				selectable: false,
				hasSearchInput: true,
				additionalFilter: this.renderFilter,
				searchLabel: 'Work Orders',
				fetch: findRelatedWorkOrders.bind(this, jobId, startDate, endDate),
				buttons: [
					{
						type: TableButtonType.EXPORT,
						hasPermission: true,
						onClick: this.onDownloadCSVClick,
					},
				],
				rowActions: [],
			},
		];
	};

	resolveActiveTabId = () => {
		const { params: { '*': tabId }, location: { state: { activeTabEnum } } } = this.props;

		if (activeTabEnum) {
			return activeTabEnum ?? PreviewTabIndexes.DETAILS;
		}

		switch (tabId) {
			case JobPreviewTabRoutes.DETAILS:
				return PREVIEW_TABS[0].id;
			case JobPreviewTabRoutes.JOB_HAZARD_ASSESSMENT:
				return PREVIEW_TABS[1].id;
			case JobPreviewTabRoutes.BILLING_CODES:
				return PREVIEW_TABS[2].id;
			case JobPreviewTabRoutes.ATTACHMENTS:
				return PREVIEW_TABS[3].id;
			case JobPreviewTabRoutes.RELATED_WORK_ORDERS:
				return PREVIEW_TABS[4].id;
			case JobPreviewTabRoutes.WORK_SUMMARY:
				return PREVIEW_TABS[5].id;
			case JobPreviewTabRoutes.INVOICES:
				return PREVIEW_TABS[6].id;
			default:
				return PREVIEW_TABS[0].id;
		}
	};

	onTabClick = (id: number) => {
		const { navigate, location: { state: { orgAlias } }, companyName, params: { jobId } } = this.props;
		let tabName = '';
		switch (id) {
			case PreviewTabIndexes.DETAILS: {
				tabName = JobPreviewTabRoutes.DETAILS;
				break;
			}
			case PreviewTabIndexes.JOB_HAZARD_ASSESSMENT: {
				tabName = JobPreviewTabRoutes.JOB_HAZARD_ASSESSMENT;
				break;
			}
			case PreviewTabIndexes.BILLING_CODES: {
				tabName = JobPreviewTabRoutes.BILLING_CODES;
				break;
			}
			case PreviewTabIndexes.ATTACHMENTS: {
				tabName = JobPreviewTabRoutes.ATTACHMENTS;
				break;
			}
			case PreviewTabIndexes.RELATED_WORK_ORDERS: {
				tabName = JobPreviewTabRoutes.RELATED_WORK_ORDERS;
				break;
			}
			case PreviewTabIndexes.WORK_SUMMARY: {
				tabName = JobPreviewTabRoutes.WORK_SUMMARY;
				break;
			}
			case PreviewTabIndexes.INVOICES: {
				tabName = JobPreviewTabRoutes.INVOICES;
				break;
			}
			default: {
				tabName = JobPreviewTabRoutes.DETAILS;
				break;
			}
		}
		navigate(CLIENT.COMPANY.JOBS.PREVIEW_TAB(orgAlias, companyName, jobId, tabName));
	};

	onDownloadCSVClick = async () => {
		const { findRelatedWorkOrders, companyName } = this.props;
		const { job, startDate, endDate } = this.state;

		if (!job) {
			throw new Error('Missing job');
		}

		const tableRequestModel = new TableQuery({
			pageSize: 0,
			page: 0,
			sortBy: [{ id: 'dueDate', desc: true }, { id: 'code', desc: false }],
			filterByText: '',
			includeAll: true,
		});
		const result = await findRelatedWorkOrders(job.details.id, startDate, endDate, tableRequestModel);
		const csvData = WorkOrderTableViewModel.toCSVData(result.rows);

		downloadCSV(csvData, `${companyName}_${job.details.jobCode}_work_orders.csv`);
	};

	refresh = () => {
		if (this._tableRef) {
			this._tableRef.refreshTable();
		} else if (this._list) {
			this._list.refreshList();
		}
	};

	filterByDate = (startDate: Date, endDate: Date) => {
		this.setState(
			() => ({ startDate, endDate }),
			() => {
				setWOStartDate(startDate);
				setWOEndDate(endDate);
				this.refresh();
			}
		);
	};

	changePeriod = (period: TimePeriodRecurrence, selected: Date) => {
		let startDate: Date, endDate: Date;

		const selectedMoment = TimeUtils.parseMoment(selected);
		if (!selectedMoment) {
			throw new Error('Failed to parse selectedMoment');
		}

		switch (period) {
			case TimePeriodRecurrence.MONTHLY:
				startDate = selectedMoment.clone().startOf('month').toDate() ?? null;
				endDate = selectedMoment.clone().endOf('month').toDate() ?? null;
				break;
			case TimePeriodRecurrence.WEEKLY:
				startDate = selectedMoment.clone().startOf('week').toDate() ?? null;
				endDate = selectedMoment.clone().endOf('week').toDate() ?? null;
				break;
			case TimePeriodRecurrence.DAILY:
			case TimePeriodRecurrence.CUSTOM:
			default:
				startDate = selected;
				endDate = selected;
				break;
		}

		this.setState(
			() => ({ period, startDate, endDate }),
			() => {
				setWOStartDate(startDate);
				setWOEndDate(endDate);
				setWOPeriod(period);
				this.refresh();
			}
		);
	};

	renderFilter = () => {
		const { endDate, startDate, period } = this.state;

		return (
			<div className="table-filter field-report-orders-table__filters">
				<DateFilter
					changePeriod={this.changePeriod}
					endDate={endDate}
					onChange={this.filterByDate}
					period={period}
					startDate={startDate}
				/>
			</div>
		);
	};

	onBackToListButtonClick = () => {
		const { companyName, location: { state: { orgAlias } }, navigate } = this.props;

		const jobListPageUrl = CLIENT.COMPANY.JOBS.TABLE(orgAlias, companyName);
		navigate(jobListPageUrl);
	};

	redirectToEditTab = (activeTabId: number) => {
		switch (activeTabId) {
			case PreviewTabIndexes.DETAILS: {
				return JobEditTabRoutes.DETAILS;
			}
			case PreviewTabIndexes.JOB_HAZARD_ASSESSMENT: {
				return JobEditTabRoutes.JOB_HAZARD_ASSESSMENT;
			}
			case PreviewTabIndexes.BILLING_CODES: {
				return JobEditTabRoutes.BILLING_CODES;
			}
			case PreviewTabIndexes.ATTACHMENTS: {
				return JobEditTabRoutes.ATTACHMENTS;
			}
			default: {
				return JobEditTabRoutes.DETAILS;
			}
		}
	};

	adjustTabsForPermissions = () => {
		const { hasPermissionToManageBillingCodes, hasPermissionsToAccessWorkSummary, hasPermissionsToManageInvoices } = this.props;

		const tabsAdjustedForPermissions: typeof PREVIEW_TABS = [];
		for (const _tab of PREVIEW_TABS) {
			if (!hasPermissionToManageBillingCodes && _tab.id === PreviewTabIndexes.BILLING_CODES
				|| !hasPermissionsToAccessWorkSummary && _tab.id === PreviewTabIndexes.WORK_SUMMARY
				|| (process.env.FTD_INVOICES === 'true' || !hasPermissionsToManageInvoices) && _tab.id === PreviewTabIndexes.INVOICES
			) {
				continue;
			}

			tabsAdjustedForPermissions.push(_tab);
		}

		return tabsAdjustedForPermissions;
	};

	resolveOutletContext = (job: JobPreviewViewModel): JobPreviewOutletContext => {
		const {
			companyName,
			isSendingInvoiceNotificationsEnabled,
			location: { state: { orgAlias } },
			params: { jobId },
			hasPermissionToManageBillingCodes,
			hasPermissionsToAccessWorkSummary,
		} = this.props;

		return {
			allowCustomerSignature: job.allowCustomerSignature,
			billingCodes: job.billingCodes,
			companyName,
			hasPermissionToManageBillingCodes,
			isJobPreview: true,
			orgAlias,
			workRequest: job.details,
			billingContact: job.details.billingContact,
			isSendingInvoiceNotificationsEnabled,
			jobCode: job.details.jobCode,
			jobHazardAssessment: job.jobHazardAssessment,
			jobId: +jobId,
			onTableMount: this.onTableMount,
			relatedWorkOrdersTableTabs: this.tabs(),
			hasPermissionsToAccessWorkSummary,
		};
	};

	render() {
		const { companyName, location: { state: { orgAlias } }, params: { jobId } } = this.props;
		const { job, showCopyModal } = this.state;

		if (!job) {
			return <Loading />;
		}

		const showEditButton = job.status !== WorkRequestStatus.FINISHED;
		const activeTabId = this.resolveActiveTabId();
		const redirectToEditTab = this.redirectToEditTab(activeTabId);
		const editPageUrl = redirectToEditTab
			? CLIENT.COMPANY.JOBS.EDIT_TAB(orgAlias, companyName, jobId, redirectToEditTab)
			: CLIENT.COMPANY.JOBS.EDIT(orgAlias, companyName, jobId);
		const tabsAdjustedForPermissions = this.adjustTabsForPermissions();

		const areActionsAndNavigationSticky = (activeTabId === PREVIEW_TABS[0].id || activeTabId === PREVIEW_TABS[1].id);
		const navigationClassName = areActionsAndNavigationSticky ? styles['job-preview__tabs-navigation'] : undefined;
		const actionClasses = [styles['job-preview__submit-section']];
		areActionsAndNavigationSticky && actionClasses.push(styles['job-preview__submit-section-sticky']);

		const breadcrumbItems = [
			{ label: 'Jobs', url: CLIENT.COMPANY.JOBS.TABLE(orgAlias, companyName) },
			{ label: job?.details.jobCode ?? '' },
			{ label: 'Preview' },
		];

		return (
			<>
				<Breadcrumbs
					items={
						breadcrumbItems
					}
				/>
				<Button className={styles['job-preview__back-to-list']} onClick={this.onBackToListButtonClick} variant="info">
					<span className="icon-left" />
					Back to List
				</Button>
				<div className={actionClasses.join(' ')}>
					<div className={styles['title-container']}>
						{!!job && (
							<>
								<div className={styles.title}>{job.details.jobCode}</div>
								<ProjectSubJobIndicator
									isProject={job.details.isProject}
									isSubJob={job.details.isProjectSubjob}
								/>
								{(!job.details.isProject && !!job.details.projectMainJobCode) && (
									<div className={styles['main-job-title']}>Associated Project: {job.details.projectMainJobCode}</div>
								)}
							</>
						)}
					</div>
					<div className={styles.actions}>
						<div>
							{showEditButton && (
								<Link className="btn btn-info" to={editPageUrl}>
									Edit
								</Link>
							)}
						</div>
						<Button
							onClick={this.openCopyJobModal}
							variant="info"
						>
							Copy
						</Button>
					</div>
				</div>
				<TabNavigation
					active={activeTabId}
					navigationClassName={navigationClassName}
					onClick={this.onTabClick}
					tabs={tabsAdjustedForPermissions}
				/>
				{<Outlet context={this.resolveOutletContext(job)} />}
				<CopyModal
					close={this.closeCopyJobModal}
					jobToCopyCode={job.details.jobCode}
					jobToCopyId={job.details.id}
					showModal={showCopyModal}
				/>
			</>
		);
	}
}

function mapStateToProps(state: RootState) {
	const { user: { userData, companyData } } = state;
	if (!userData || !companyData) {
		throw new Error('User not logged in');
	}

	const hasViewWorkOrderPermission = isAllowed(
		PagePermissions.COMPANY.WORK_ORDERS.MANAGE,
		companyData.permissions,
		companyData.isCompanyAdmin,
		userData.role
	);

	const hasPermissionToManageBillingCodes = isAllowed(
		PagePermissions.COMPANY.JOBS.MANAGE_BILLING_CODES,
		companyData.permissions,
		companyData.isCompanyAdmin,
		userData.role
	);

	const hasPermissionsToManageInvoices = isAllowed(
		PagePermissions.COMPANY.JOBS.MANAGE_INVOICES,
		companyData.permissions,
		companyData.isCompanyAdmin,
		userData.role
	);

	const hasPermissionsToAccessWorkSummary = isAllowed(
		PagePermissions.COMPANY.JOBS.MANAGE_WORK_SUMMARY,
		companyData.permissions,
		companyData.isCompanyAdmin,
		userData.role
	);

	return {
		companyName: companyData.name,
		isSendingInvoiceNotificationsEnabled: companyData.isSendingInvoiceNotificationsEnabled,
		hasViewWorkOrderPermission,
		hasPermissionToManageBillingCodes,
		hasPermissionsToManageInvoices,
		hasPermissionsToAccessWorkSummary,
	};
}

function mapDispatchToProps() {
	return {
		findJobById: JobActions.findByIdForPreview,
		findAllBillingCodes: WorkRequestActions.findAllBillingCodesTable,
		findRelatedWorkOrders: JobActions.findRelatedWorkOrders,
	};
}

const connector = connect(mapStateToProps, mapDispatchToProps());

const enhance = compose<React.ComponentClass>(
	withRouterV6,
	SettingsUtils.withSettings<SettingProps>(() => ([
		{
			key: SettingsKeys.WORK_ORDERS_REPORTS_START_DATE(),
			mappedName: 'startDate',
			normalize: TimeUtils.normalizeDateToDate,
			defaultValue: new Date(),
			source: BrowserStorageEnum.LOCAL_STORAGE,
		},
		{
			key: SettingsKeys.WORK_ORDERS_REPORTS_END_DATE(),
			mappedName: 'endDate',
			normalize: TimeUtils.normalizeDateToDate,
			defaultValue: new Date(),
			source: BrowserStorageEnum.LOCAL_STORAGE,
		},
		{
			key: SettingsKeys.WORK_ORDERS_REPORTS_PERIOD(),
			mappedName: 'period',
			defaultValue: TimePeriodRecurrence.DAILY,
			source: BrowserStorageEnum.LOCAL_STORAGE,
		},
	])),
	connector
);

export default enhance(Preview);
