import * as React from 'react';

import type { ExtendedColorPalette } from 'acceligent-shared/enums/color';

import Tooltip from 'af-components/Tooltip';

import * as TextUtil from 'ab-utils/text.util';

import ColorSquare from './ColorSquare';

interface Props {
	color: ExtendedColorPalette;
	onClick?: (color: ExtendedColorPalette) => void;
	showTooltip?: boolean;
	selected?: boolean;
}

export default class SelectableColorSquare extends React.PureComponent<Props> {
	static defaultProps: Partial<Props> = {
		showTooltip: false,
		selected: false,
	};

	onClick = () => {
		const { color, onClick } = this.props;

		if (onClick) {
			onClick(color);
		}
	};

	render() {
		const { showTooltip, color, selected } = this.props;

		let className = 'selectable-color-square';
		className = selected ? `${className} selectable-color-square--selected` : className;
		className = color ? `${className} ${color.toLowerCase()}` : className;

		return (
			<div
				className={className}
				onClick={this.onClick}
			>
				{showTooltip
					? (
						<Tooltip
							message={TextUtil.capitalize(color)}
							placement="top"
						>
							<ColorSquare
								color={color}
							/>
						</Tooltip>
					) : (
						<ColorSquare
							color={color}
						/>
					)
				}
			</div>
		);
	}
}
