import * as React from 'react';
import type { ConnectedProps } from 'react-redux';
import { connect } from 'react-redux';
import { Link } from 'react-router-dom';

import WorkSummaryStatusEnum from 'acceligent-shared/enums/workSummaryStatus';

import { bemBlock } from 'ab-utils/bem.util';
import * as ArrayUtils from 'ab-utils/array.util';

import * as FieldReportActions from 'af-actions/fieldReport';
import * as BillingCodeActions from 'af-actions/billingCode';
import * as WorkSummaryDetailsActions from 'af-actions/workSummaryDetails';
import * as WorkOrderActions from 'af-actions/workOrder';
import * as WorkRequestActions from 'af-actions/workRequests';

import socket from 'af-utils/socket.util';

import SocketEvent from 'ab-enums/socketEvent.enum';

import type WorkSummaryDetailsAssignBillingCodeRM from 'ab-requestModels/workSummaryDetails/assignBillingCode.requestModel';

import type { WorkSummaryUpdatedBillingCodesVM } from 'ab-socketModels/viewModels/workOrder/workSummaryUpdatedBillingCodes.viewModel';

import type WorkSummaryVM from 'ab-viewModels/fieldReport/workSummary.viewModel';
import type { BillingCodeVM } from 'ab-viewModels/job.viewModel';
import type WorkSummaryDetailVM from 'ab-viewModels/fieldReport/workSummaryDetails.viewModel';
import type WorkSummaryStatusVM from 'ab-viewModels/workOrder/workSummaryStatus.viewModel';
import type SiblingSubjobVM from 'ab-viewModels/workRequest/siblingSubjob.viewModel';

import type { RootState } from 'af-reducers';

import CLIENT from 'af-routes/client';

import * as FieldReportUtil from 'af-utils/fieldReport.util';
import * as UserUtils from 'af-utils/user.util';

import { resolveHighlightGroupKey } from './helpers';
import WorkSummaryBillableWork from './BillableAndNonBillableWork/BillableWork';
import WorkSummaryDetails from './Details';
import NonBillableWork from './BillableAndNonBillableWork/NonBillableWork';
import WorkSummaryStatus from './Status';

interface OwnProps {
	jobId: number;
	fieldReportId: number;
	workOrderId: number;
	invalidateWorkSummaryStatus: () => Promise<void>;
	workSummaryStatus: WorkSummaryStatusVM;
	workOrderCode: string;
	canCompleteWorkSummary: boolean;
	hasPermissionToManageWS: boolean;
	areFRsReadOnly: boolean;
	pathName: string;
	isAbleToReject: boolean;
	belongsToProject: boolean;
	workOrderIsInProgess: boolean;
}

type Props = ConnectedProps<typeof connector> & OwnProps;

function definitionFieldSort(df1: { index: number; }, df2: { index: number; }) {
	return df1.index - df2.index;
}

function definitionFieldValue(df: { value: string; }) {
	return df.value;
}

function workSummarySortKey(ws: WorkSummaryVM) {
	return JSON.stringify([
		ws.billableWorkId,
		ws.type,
		[...ws.definitionFields].sort(definitionFieldSort).map(definitionFieldValue),
	]);
}

const _sortWorkSummaries = (_wsa: WorkSummaryVM, _wsb: WorkSummaryVM) => {
	const _wsaGroupKey = workSummarySortKey(_wsa);
	const _wsbGroupKey = workSummarySortKey(_wsb);

	return _wsaGroupKey.localeCompare(_wsbGroupKey);
};

const _sortWorkSummaryDetails = (_wsda: WorkSummaryDetailVM, _wsdb: WorkSummaryDetailVM) => {
	const _wsaGroupKey = resolveHighlightGroupKey(_wsda);
	const _wsbGroupKey = resolveHighlightGroupKey(_wsdb);

	return _wsaGroupKey.localeCompare(_wsbGroupKey);
};

const _isEveryWorkSummaryBilled = (_ws: WorkSummaryVM) => !!_ws.billingCodeId;

const WorkSummary: React.FC<Props> = (props) => {
	const {
		findBillingCodesForJobId,
		findWorkSummaryDetails,
		jobId,
		fieldReportId,
		findWorkSummary,
		updateBillingStatuses,
		assignBillingCode,
		workOrderId,
		invalidateWorkSummaryStatus,
		workSummaryStatus,
		editWorkSummaryStatus,
		canCompleteWorkSummary,
		hasPermissionToManageWS,
		areFRsReadOnly,
		workOrderCode,
		pathName,
		checkIfBillableWorkExists,
		isAbleToReject,
		companyData,
		organizationData,
		allowToMarkAllAsNonBillable,
		bulkUpdateBillingStatuses,
		isPlatformAdmin,
		resetJobWorkSummary,
		findSiblingSubjobs,
		belongsToProject,
		reallocateToSubJob,
		reallocateAllBillableToSubJob,
		reallocateAllNonBillableToSubJob,
		workOrderIsInProgess,
	} = props;

	const [billingCodesByJob, setBillingCodesByJob] = React.useState<Nullable<Record<string, BillingCodeVM[]>>>(null);
	const [workSummaryDetails, setWorkSummaryDetails] = React.useState<Nullable<WorkSummaryDetailVM[]>>(null);
	const [workSummaries, setWorkSummaries] = React.useState<Nullable<WorkSummaryVM[]>>(null);
	const [subJobs, setSubJobs] = React.useState<SiblingSubjobVM[]>([]);

	const [billableWorkSummaries, setBillableWorkSummaries] = React.useState<Nullable<WorkSummaryVM[]>>(null);
	const [nonBillableWorkSummaries, setNonBillableWorkSummaries] = React.useState<Nullable<WorkSummaryVM[]>>(null);

	const [alternativeWorkSummaryDetails, setAlternativeWorkSummaryDetails] = React.useState<Set<WorkSummaryDetailVM>>(new Set());
	const [alternativeWorkSummary, setAlternativeWorkSummary] = React.useState<Set<WorkSummaryVM>>(new Set());

	const [highlightedGroup, setHighlightedGroup] = React.useState<Nullable<string>>(null);
	const [highlightedGroupKey, setHighlightedGroupKey] = React.useState<Nullable<string>>(null);

	const [noWorkSummaryMessage, setNoWorkSummaryMessage] = React.useState<Nullable<string>>(null);

	const isWorkSummaryReadOnly = (workSummaryStatus.status === WorkSummaryStatusEnum.COMPLETED) || areFRsReadOnly;

	const fetchAndInitializeData = React.useCallback(
		async () => {
			const [
				fetchedBillingCodes,
				fetchedWorkSummaryDetails,
				fetchedWorkSummaries,
				fetchedSubJobs,
			] = await Promise.all([
				findBillingCodesForJobId(jobId),
				findWorkSummaryDetails(fieldReportId),
				findWorkSummary(fieldReportId),
				findSiblingSubjobs(jobId),
			]);

			if (fetchedWorkSummaryDetails.length === 0) {
				const billableWorkExists = await checkIfBillableWorkExists(fieldReportId);
				const emptyWorkSummaryMessage = billableWorkExists
					? 'Fill in the Report Type to generate Work Summary.'
					: 'No Report Types are generating Work Summary.';
				setNoWorkSummaryMessage(emptyWorkSummaryMessage);
			}

			fetchedWorkSummaryDetails.sort(_sortWorkSummaryDetails);
			fetchedWorkSummaries.sort(_sortWorkSummaries);

			setWorkSummaryDetails(fetchedWorkSummaryDetails);
			setBillingCodesByJob(ArrayUtils.groupBy(fetchedBillingCodes, (_bc) => _bc.workRequestId));
			setWorkSummaries(fetchedWorkSummaries);
			setSubJobs(fetchedSubJobs);
		}, [checkIfBillableWorkExists, fieldReportId, findBillingCodesForJobId, findSiblingSubjobs, findWorkSummary, findWorkSummaryDetails, jobId]);

	const loadWorkSummaries = React.useCallback(async () => {
		const [fetchedWorkSummaries, fetchedWorkSummaryDetails] = await Promise.all([
			findWorkSummary(fieldReportId),
			findWorkSummaryDetails(fieldReportId),
		]);

		fetchedWorkSummaries.sort(_sortWorkSummaries);
		fetchedWorkSummaryDetails.sort(_sortWorkSummaryDetails);

		setWorkSummaries(fetchedWorkSummaries);
		setWorkSummaryDetails(fetchedWorkSummaryDetails);
	}, [fieldReportId, findWorkSummary, findWorkSummaryDetails]);

	React.useEffect(() => {
		FieldReportUtil.joinFieldReportWorkSummary(fieldReportId);

		socket.connection?.subscribe(SocketEvent.V2.BE.FIELD_REPORT.SYNC_WORK_SUMMARY, loadWorkSummaries);

		return () => {
			FieldReportUtil.leaveFieldReportWorkSummary(fieldReportId);

			socket.connection?.unsubscribe(SocketEvent.V2.BE.FIELD_REPORT.SYNC_WORK_SUMMARY);
		};
	}, [fieldReportId, loadWorkSummaries]);

	const workSummaryDetailsByGroupKey = React.useMemo(() => {
		return workSummaryDetails ? ArrayUtils.groupBy<WorkSummaryDetailVM>(workSummaryDetails, resolveHighlightGroupKey) : {};
	}, [workSummaryDetails]);

	const onBillingStatusToggle = React.useCallback(async (workSummaryDetail: WorkSummaryDetailVM) => {
		const { id, isBillable } = workSummaryDetail;

		if (isWorkSummaryReadOnly) {
			return;
		}

		await updateBillingStatuses({ fieldReportId, workSummaryDetails: [{ isBillable: !isBillable, id }] });
		await invalidateWorkSummaryStatus();
	}, [fieldReportId, invalidateWorkSummaryStatus, isWorkSummaryReadOnly, updateBillingStatuses]);

	const onBulkStatusToFalse = React.useCallback(async () => {
		await bulkUpdateBillingStatuses({ fieldReportId });
		await invalidateWorkSummaryStatus();
	}, [invalidateWorkSummaryStatus, bulkUpdateBillingStatuses, fieldReportId]);

	const onBillingCodeSelect = React.useCallback(async (
		billingCode: Nullable<BillingCodeVM>,
		workSummaryDetailIds: number[]
	) => {
		if (isWorkSummaryReadOnly) {
			return;
		}

		const assignBillingCodeRM: WorkSummaryDetailsAssignBillingCodeRM = {
			billingCodeId: billingCode?.id ?? null,
			fieldReportId,
			workOrderId,
			workSummaryDetailIds,
		};

		await assignBillingCode(assignBillingCodeRM);
		await invalidateWorkSummaryStatus();
	}, [assignBillingCode, fieldReportId, invalidateWorkSummaryStatus, isWorkSummaryReadOnly, workOrderId]);

	React.useEffect(() => {
		if (!workSummaryDetails) {
			return;
		}
		const currHighlightedGroup = highlightedGroup ?? highlightedGroupKey;
		const currHighlightedGroupWsd = workSummaryDetails.find((_wsd) => {
			const _wsdGroup = _wsd.workSummaryGroup ?? resolveHighlightGroupKey(_wsd);
			if (currHighlightedGroup === _wsdGroup) {
				return true;
			}
		});

		if (!currHighlightedGroupWsd) {
			return;
		}

		const newAlternativeWorkSummaryDetails = workSummaryDetails.reduce<Set<WorkSummaryDetailVM>>((_acc, _wsd) => {
			if (_wsd.billableWorkId !== currHighlightedGroupWsd.billableWorkId &&
				ArrayUtils.areEqualByKeys(_wsd.definitionFields, currHighlightedGroupWsd.definitionFields, ['value', 'fieldType', 'name']) &&
				_wsd.workType === currHighlightedGroupWsd.workType) {
				_acc.add(_wsd);
			}
			return _acc;
		}, new Set());

		setAlternativeWorkSummaryDetails(newAlternativeWorkSummaryDetails);
	}, [highlightedGroup, highlightedGroupKey, workSummaries, workSummaryDetails]);

	React.useEffect(() => {
		if (!workSummaries) {
			return;
		}

		const currHighlightedGroup = highlightedGroup ?? highlightedGroupKey;

		const currHighlightedGroupWs = workSummaries?.find((_wsd) => {
			const _wsdGroup = _wsd.workSummaryGroup ?? resolveHighlightGroupKey({
				subJobId: _wsd.subJobId,
				billableWorkId: _wsd.billableWorkId,
				workType: _wsd.type,
				definitionFields: _wsd.definitionFields,
			});
			if (currHighlightedGroup === _wsdGroup) {
				return true;
			}
		});

		if (!currHighlightedGroupWs) {
			return;
		}

		const newAlternativeWorkSummary = workSummaries.reduce<Set<WorkSummaryVM>>((_acc, _ws) => {
			if (_ws.billableWorkId !== currHighlightedGroupWs.billableWorkId &&
				ArrayUtils.areEqualByKeys(_ws.definitionFields, currHighlightedGroupWs.definitionFields, ['value', 'fieldType', 'fieldName']) &&
				_ws.type === currHighlightedGroupWs.type) {
				_acc.add(_ws);
			}
			return _acc;
		}, new Set());

		setAlternativeWorkSummary(newAlternativeWorkSummary);
	}, [highlightedGroup, highlightedGroupKey, workSummaries]);

	const onRowHighlight = React.useCallback((groupToHighlight: Nullable<string>, groupKeyToHighlight: Nullable<string>) => {
		setHighlightedGroup(groupToHighlight);
		setHighlightedGroupKey(groupKeyToHighlight);
	}, []);

	const onWorkSummaryStatusChange = React.useCallback((wss: WorkSummaryStatusEnum) => {
		return async () => {
			if (isWorkSummaryReadOnly && wss !== WorkSummaryStatusEnum.OUTDATED) {
				return;
			}

			await editWorkSummaryStatus(fieldReportId, wss);
			await invalidateWorkSummaryStatus();
		};
	}, [editWorkSummaryStatus, fieldReportId, invalidateWorkSummaryStatus, isWorkSummaryReadOnly]);

	React.useEffect(() => {
		fetchAndInitializeData();
	}, [fetchAndInitializeData]);

	React.useEffect(() => {
		if (workSummaries) {
			const { filteredBillableWS, filteredNonBillableWS } = workSummaries.reduce((_acc, _curr) => {
				if (_curr.isBillable) {
					_acc.filteredBillableWS.push(_curr);
				} else if (!_curr.isBillable) {
					_acc.filteredNonBillableWS.push(_curr);
				}
				return _acc;
			}, { filteredBillableWS: [] as WorkSummaryVM[], filteredNonBillableWS: [] as WorkSummaryVM[] });

			setBillableWorkSummaries(filteredBillableWS);
			setNonBillableWorkSummaries(filteredNonBillableWS);
		}
	}, [workSummaries]);

	const handleReallocateToSubJob = React.useCallback(async (subJob: SiblingSubjobVM, workSummaryDetailIds: number[]) => {
		if (isWorkSummaryReadOnly) {
			return;
		}
		await reallocateToSubJob({ fieldReportId, workOrderId, subJobId: subJob.id, workSummaryDetailIds });
		await invalidateWorkSummaryStatus();
	}, [fieldReportId, invalidateWorkSummaryStatus, isWorkSummaryReadOnly, reallocateToSubJob, workOrderId]);

	const handleBillingCodeUpdate = React.useCallback(async (data: WorkSummaryUpdatedBillingCodesVM, { socketId }: { socketId: string; }) => {
		if (socketId === socket.getConnection()?.getId() || fieldReportId !== data.fieldReportId) {
			return;
		}
		const updatedBillingCodes = await findBillingCodesForJobId(jobId);
		setBillingCodesByJob(ArrayUtils.groupBy(updatedBillingCodes, (_bc) => _bc.workRequestId));

		const fetchedWorkSummaries = await findWorkSummary(fieldReportId);
		setWorkSummaries(fetchedWorkSummaries.sort(_sortWorkSummaries));
	}, [fieldReportId, findBillingCodesForJobId, findWorkSummary, jobId]);

	const handleReallocatedToSubJob = React.useCallback(async (data: WorkSummaryUpdatedBillingCodesVM, { socketId }: { socketId: string; }) => {
		if (socketId === socket.getConnection()?.getId() || fieldReportId !== data.fieldReportId) {
			return;
		}
		const fetchedWorkSummaries = await findWorkSummary(fieldReportId);
		setWorkSummaries(fetchedWorkSummaries.sort(_sortWorkSummaries));
	}, [fieldReportId, findWorkSummary]);

	const reallocateAllBillable = React.useCallback(async (subJob: SiblingSubjobVM) => {
		if (isWorkSummaryReadOnly) {
			return;
		}
		await reallocateAllBillableToSubJob({ fieldReportId, workOrderId, subJobId: subJob.id });
		await invalidateWorkSummaryStatus();
	}, [fieldReportId, invalidateWorkSummaryStatus, isWorkSummaryReadOnly, reallocateAllBillableToSubJob, workOrderId]);

	const reallocateAllNonBillable = React.useCallback(async (subJob: SiblingSubjobVM) => {
		if (isWorkSummaryReadOnly) {
			return;
		}
		await reallocateAllNonBillableToSubJob({ fieldReportId, workOrderId, subJobId: subJob.id });
		await invalidateWorkSummaryStatus();
	}, [fieldReportId, invalidateWorkSummaryStatus, isWorkSummaryReadOnly, reallocateAllNonBillableToSubJob, workOrderId]);

	React.useEffect(() => {
		socket.connection?.subscribe(SocketEvent.V2.BE.FIELD_REPORT_LIST.WORK_SUMMARY_UPDATE_BILLING_CODES, handleBillingCodeUpdate);
		socket.connection?.subscribe(SocketEvent.V2.BE.FIELD_REPORT_LIST.WORK_SUMMARY_REALLOCATED_TO_SUB_JOB, handleReallocatedToSubJob);

		return () => {
			socket.connection?.unsubscribe(SocketEvent.V2.BE.FIELD_REPORT_LIST.WORK_SUMMARY_UPDATE_BILLING_CODES);
			socket.connection?.unsubscribe(SocketEvent.V2.BE.FIELD_REPORT_LIST.WORK_SUMMARY_REALLOCATED_TO_SUB_JOB);
		};
	}, [handleBillingCodeUpdate, handleReallocatedToSubJob]);

	if (!billingCodesByJob || !workSummaryDetails || !billableWorkSummaries || !nonBillableWorkSummaries || !workSummaryDetailsByGroupKey) {
		return null;
	}

	if (!workSummaryDetails.length && noWorkSummaryMessage) {
		return (
			<div className={bemBlock('field-report__work-summary', { empty: true })}>
				{noWorkSummaryMessage}
			</div>
		);
	}

	const areAllWorkSummariesBilled = !!billableWorkSummaries?.every(_isEveryWorkSummaryBilled);

	return (
		<div className="field-report__work-summary">
			{
				areFRsReadOnly && isAbleToReject &&
				<div className="field-report-type__status-information">
					In order to edit Report Types, please reject Field Report, which will bring it back to edit mode.
				</div>
			}
			{workOrderIsInProgess ? (
				<div className="field-report-type__status-information">
					Work Summary will be generated after Submitting for Review.
				</div>
			) : (
				<div className="field-report-type__status-information">
					This information is based on true, original data that was gathered in the field report.
					Some overrides might have been applied on job level.
					{
						hasPermissionToManageWS &&
						<Link className="m-l-s" to={CLIENT.COMPANY.JOBS.PREVIEW(organizationData.alias, companyData.name, jobId.toString())}>
							See here
						</Link>
					}
				</div>
			)}
			{!workOrderIsInProgess && (
				<>
					<WorkSummaryStatus status={workSummaryStatus} />
					<WorkSummaryBillableWork
						alternativeWorkSummary={alternativeWorkSummary}
						areAllWorkSummariesBilled={areAllWorkSummariesBilled}
						areFRsReadOnly={areFRsReadOnly}
						belongsToProject={belongsToProject}
						billingCodes={billingCodesByJob}
						canCompleteWorkSummary={canCompleteWorkSummary}
						handleJobReallocation={handleReallocateToSubJob}
						highlightedGroup={highlightedGroup}
						highlightedGroupKey={highlightedGroupKey}
						isWorkSummaryStatusCompleted={workSummaryStatus.status === WorkSummaryStatusEnum.COMPLETED}
						isWorkSummaryStatusReviewed={workSummaryStatus.status === WorkSummaryStatusEnum.REVIEWED}
						onBillingCodeSelect={onBillingCodeSelect}
						onReallocateAll={reallocateAllBillable}
						onRowHighlight={onRowHighlight}
						onWorkSummaryStatusChange={onWorkSummaryStatusChange}
						subJobs={subJobs}
						workOrderCode={workOrderCode}
						workSummary={billableWorkSummaries}
						workSummaryDetails={workSummaryDetails}
						workSummaryDetailsByGroupKey={workSummaryDetailsByGroupKey}
					/>
					<NonBillableWork
						alternativeWorkSummary={alternativeWorkSummary}
						areFRsReadOnly={areFRsReadOnly}
						belongsToProject={belongsToProject}
						billingCodes={billingCodesByJob}
						handleJobReallocation={handleReallocateToSubJob}
						highlightedGroup={highlightedGroup}
						highlightedGroupKey={highlightedGroupKey}
						isWorkSummaryStatusCompleted={workSummaryStatus.status === WorkSummaryStatusEnum.COMPLETED}
						isWorkSummaryStatusReviewed={workSummaryStatus.status === WorkSummaryStatusEnum.REVIEWED}
						onReallocateAll={reallocateAllNonBillable}
						onRowHighlight={onRowHighlight}
						subJobs={subJobs}
						workOrderCode={workOrderCode}
						workSummary={nonBillableWorkSummaries}
						workSummaryDetails={workSummaryDetails}
						workSummaryDetailsByGroupKey={workSummaryDetailsByGroupKey}
					/>
				</>
			)}
			<WorkSummaryDetails
				allowToMarkAllAsNonBillable={allowToMarkAllAsNonBillable && !isWorkSummaryReadOnly}
				alternativeWorkSummaryDetails={alternativeWorkSummaryDetails}
				canResetJobWorkSummary={isPlatformAdmin}
				fieldReportId={fieldReportId}
				highlightedGroup={highlightedGroup}
				highlightedGroupKey={highlightedGroupKey}
				isReadOnly={isWorkSummaryReadOnly}
				onBillingStatusToggle={onBillingStatusToggle}
				onBulkStatusToFalse={onBulkStatusToFalse}
				onRowHighlight={onRowHighlight}
				pathName={pathName}
				resetJobWorkSummary={resetJobWorkSummary}
				workOrderCode={workOrderCode}
				workSummaryDetails={workSummaryDetails}
				workSummaryDetailsByGroupKey={workSummaryDetailsByGroupKey}
			/>
		</div>
	);
};

const mapStateToProps = (state: RootState) => {
	const { companyData, organizationData, userData } = state.user;
	const { company } = state.company;
	if (!companyData || !organizationData || !userData) {
		throw new Error('User not logged in');
	}

	return {
		companyData,
		organizationData,
		allowToMarkAllAsNonBillable: company?.allowAllNonBillableShortcut ?? false,
		isPlatformAdmin: UserUtils.isAdmin(userData),
	};
};

function mapDispatchToProps() {
	return {
		findBillingCodesForJobId: BillingCodeActions.findAllForProjectByJobId,
		findWorkSummaryDetails: FieldReportActions.findWorkSummaryDetails,
		findWorkSummary: FieldReportActions.findWorkSummary,
		updateBillingStatuses: WorkSummaryDetailsActions.updateBillingStatuses,
		bulkUpdateBillingStatuses: WorkSummaryDetailsActions.bulkUpdateBillingStatuses,
		assignBillingCode: WorkSummaryDetailsActions.assignBillingCode,
		editWorkSummaryStatus: WorkOrderActions.editWorkSummaryStatus,
		checkIfBillableWorkExists: FieldReportActions.checkIfBillableWorkExists,
		resetJobWorkSummary: FieldReportActions.resetJobWorkSummary,
		findSiblingSubjobs: WorkRequestActions.findSiblingSubjobs,
		reallocateToSubJob: WorkSummaryDetailsActions.reallocateToSubJob,
		reallocateAllBillableToSubJob: WorkSummaryDetailsActions.reallocateAllBillableToSubJob,
		reallocateAllNonBillableToSubJob: WorkSummaryDetailsActions.reallocateAllNonBillableToSubJob,
	};
}

const connector = connect(mapStateToProps, mapDispatchToProps());

export default connector(WorkSummary);
