import * as React from 'react';
import { compose } from 'redux';
import type { ConnectedProps } from 'react-redux';
import { connect } from 'react-redux';
import type { CustomRouteComponentProps} from 'react-router-dom';
import { Redirect, withRouter } from 'react-router-dom';

import { LoginMethod } from 'ab-enums/auth.enum';

import CLIENT from 'af-constants/routes/client';

import * as AuthenticationActions from 'af-actions/authentication';

import type * as User from 'ab-viewModels/user.viewModel';

import type { RootState } from 'af-reducers';

declare let window;

type Props = ConnectedProps<typeof connector> & CustomRouteComponentProps;

function mapStateToProps(state: RootState) {
	return {
		userData: state?.user?.userData,
	};
}

function mapDispatchToProps() {
	return {
		isAuthenticated: AuthenticationActions.isAuthenticated,
	};
}

const connector = connect(mapStateToProps, mapDispatchToProps());

// eslint-disable-next-line @typescript-eslint/no-explicit-any
const enhance = compose<React.ComponentClass<any>>(
	withRouter,
	connector
);

// eslint-disable-next-line @typescript-eslint/no-explicit-any
export default function (ComposedComponent: React.ComponentType<any>): React.ComponentType<unknown> {
	class Authentication extends React.PureComponent<Props> {

		static defaultProps: Partial<Props> = {
			userData: {} as User.UserData,
		};

		async componentDidMount() {
			const { isAuthenticated, location: { state: { orgAlias } } } = this.props;

			await isAuthenticated(orgAlias);
		}

		render() {
			const { userData, location } = this.props;
			// previous location if user needs to be redirected
			window.redirectToLocation = location;
			const { orgAlias } = location.state;

			if ((!userData?.isPasswordExpired && userData?.isFinalized) || userData?.loginMethod === LoginMethod.PHONE) {
				return React.createElement(ComposedComponent, this.props);
			} else if (userData?.id) {
				// redirect user to update password only after the next login
				if (userData?.passwordResetRequired) {
					// User has not yet been finalized or his password expired (after PASSWORD_MAX_AGE)
					if (location.pathname !== CLIENT.AUTH.FINALIZE(orgAlias) && location.pathname !== CLIENT.AUTH.PASS_OUTDATED(orgAlias)) {
						if (userData?.isPasswordExpired) {
							return React.createElement(Redirect, { to: CLIENT.AUTH.PASS_OUTDATED(orgAlias) });
						}
						return React.createElement(Redirect, { to: CLIENT.AUTH.FINALIZE(orgAlias) });
					}
				}
				return React.createElement(ComposedComponent, this.props);
			} else {
				return React.createElement(Redirect, { to: `${CLIENT.AUTH.LOGIN(orgAlias)}?redirect` });
			}

		}
	}
	return enhance(Authentication);
}
