import * as React from 'react';
import { Row } from 'react-bootstrap';

import { weekDays } from 'acceligent-shared/enums/weekDay';

import type { CompanyViewModel } from 'ab-viewModels/company.viewModel';

import LockedValue from 'af-components/LockedValue';

import SettingPreview from '../../Shared/SettingPreview';

interface Props {
	toggleEdit: () => void;
	company: CompanyViewModel;
}

export default class CompanyPreview extends React.Component<Props> {
	render() {
		const { toggleEdit, company } = this.props;
		const workingWeekDays = company.workDays?.map((_wd) => _wd.weekDay);

		return (
			<SettingPreview toggleEdit={toggleEdit}>
				<div className="company-settings__info-segment">
					<Row className="row--padded">
						<LockedValue
							label="Working week days"
							value={company.workDays && company.workDays.length > 0
								? weekDays.map((_d, _index) => (
									<span className={`company-settings__selected-day ${workingWeekDays.includes(_d) ? 'company-settings__selected-day--active' : ''}`} key={_index}>
										{_d?.[0]}
									</span>
								))
								: <div className="company-settings__empty">There are no selected working days.</div>
							}
						/>
					</Row>
					<Row className="row--padded">
						<LockedValue
							label="Holidays"
							value={
								company.holidays && company.holidays.length > 0
									? company.holidays.map((_h, _index) => (<div key={_index}>{_h.name}</div>))
									: <div className="company-settings__empty">There are no selected holidays.</div>
							}
						/>
					</Row>
				</div>
			</SettingPreview>
		);
	}
}
