import * as React from 'react';

import type { WorkOrderRevisionContactModel } from 'ab-viewModels/scheduleBoardWorkOrderModal.viewModel';

import SegmentLabel from 'af-components/SegmentLabel';

import { getRemovedSiteContactEmailDictionary, getRemovedSiteContactPhoneDictionary, getRemovedSiteContactAddressDictionary } from 'af-utils/workOrderModal.util';

import SiteContactEmailList from './SiteContactEmailList';
import SiteContactPhoneNumberList from './SiteContactPhoneNumberList';
import SiteContactAddressList from './SiteContactAddressList';

import { renderWorkOrderInfoData } from '../../';
import Changes from '../../Changes';

interface Props {
	siteContactToShow: Nullable<WorkOrderRevisionContactModel>;
	currentSiteContact: Nullable<WorkOrderRevisionContactModel>;
}

class SiteContactSection extends React.PureComponent<Props> {
	sectionHasChanges = () => {
		const { siteContactToShow, currentSiteContact } = this.props;

		const removedEmailDictionary = getRemovedSiteContactEmailDictionary(siteContactToShow, currentSiteContact);
		const removedPhoneDictionary = getRemovedSiteContactPhoneDictionary(siteContactToShow, currentSiteContact);
		const removedAddressDictionary = getRemovedSiteContactAddressDictionary(siteContactToShow, currentSiteContact);

		return siteContactToShow?.name !== currentSiteContact?.name
			|| siteContactToShow?.companyName !== currentSiteContact?.companyName
			|| siteContactToShow?.companyRole !== currentSiteContact?.companyRole
			|| Object.keys(removedEmailDictionary).length > 0
			|| Object.keys(removedPhoneDictionary).length > 0
			|| Object.keys(removedAddressDictionary).length > 0;
	};

	render() {
		const { siteContactToShow, currentSiteContact } = this.props;

		const sectionHasChanges = this.sectionHasChanges();

		const removedEmailDictionary = getRemovedSiteContactEmailDictionary(siteContactToShow, currentSiteContact);
		const removedPhoneDictionary = getRemovedSiteContactPhoneDictionary(siteContactToShow, currentSiteContact);
		const removedAddressDictionary = getRemovedSiteContactAddressDictionary(siteContactToShow, currentSiteContact);

		return (
			siteContactToShow &&
			<div className="work-order-info-modal__site-contact">
				<Changes modifiedData={sectionHasChanges} />
				<SegmentLabel label="Site Contact" />
				<div>

					{
						siteContactToShow?.name
							? (
								<>
									<span className="work-order-info-modal__site-contact-name">
										{renderWorkOrderInfoData(siteContactToShow.name, currentSiteContact?.name ?? 'There is no Site Contact')}
									</span>
									<span className="work-order-info-modal__site-contact-description">
										{siteContactToShow.companyName}
										{
											siteContactToShow.companyName && siteContactToShow.companyRole &&
											' | '
										}
										{siteContactToShow.companyRole}
									</span>
								</>
							)
							: (
								<span className="no-data-label">
									{renderWorkOrderInfoData('There is no Site Contact', currentSiteContact?.name ?? 'There is no Site Contact')}
								</span>
							)
					}
					<span>

					</span>
				</div>
				<SiteContactEmailList
					emails={siteContactToShow.emails}
					removedEmailDictionary={removedEmailDictionary}
				/>
				<SiteContactPhoneNumberList
					phones={siteContactToShow.phones}
					removedPhoneDictionary={removedPhoneDictionary}
				/>
				<SiteContactAddressList
					addresses={siteContactToShow.addresses}
					removedAddressDictionary={removedAddressDictionary}
				/>
			</div>
		);
	}
}

export default SiteContactSection;
