import * as React from 'react';

import type { SkillViewModel } from 'ab-viewModels/skill.viewModel';

import TextHighlight from 'af-components/TextHighlight';
import ColorSquare from 'af-components/ColorSquare';

interface OwnProps extends SkillViewModel {
	searchText: string;
}

type Props = OwnProps;

export default class LaborPlaceholderSkillOptionItem extends React.PureComponent<Props> {

	render() {
		const { name, color, searchText } = this.props;
		return (
			<span className="resource-lookup__skill-option">
				<ColorSquare color={color} />
				<TextHighlight searchText={searchText} text={name} />
			</span>
		);
	}

}
