import * as React from 'react';

import type { DisplayViewEquipmentViewModel } from 'ab-viewModels/scheduleBoardDisplayView.viewModel';

import * as ColorUtils from 'ab-utils/color.util';

interface OwnProps {
	equipment: DisplayViewEquipmentViewModel;
}

type Props = OwnProps;

const UnavailableEquipmentItem: React.FC<Props> = (props: Props) => {
	const { specification, code, color, returnDate, unavailabilityReason } = props.equipment;

	return (
		<div className="display-view__unavailable-item">
			<div className="display-view__card-and-date">
				<div className={`sb-resource-item sb-resource-item--equipment ${ColorUtils.getColorBackgroundClass(color)}`}>
					<div className="sb-resource-item__content">
						<span className="sb-resource-item__full-name sb-resource-item__full-name--transparent-bg">{code}</span>
						{specification && <span className="sb-resource-item__specification">{specification}</span>}
					</div>
				</div>
				<div className="display-view__card-and-date__date">{returnDate}</div>
			</div>
			<span className="display-view__card-and-date__reason">{unavailabilityReason}</span>
		</div>
	);
};

export default React.memo(UnavailableEquipmentItem);
