import * as React from 'react';
import { Row } from 'react-bootstrap';
import type { WrappedFieldArrayProps } from 'redux-form';

import Tooltip from 'af-components/Tooltip';

import ReportBlock from './Block';

import type { ReportBlockFieldFormModel } from '../formModel';
import type { HighlightedBillableWorkBlockFields } from '../../ReportType/Shared/values';
import type { EquipmentListViewModel } from 'ab-viewModels/equipmentList.viewModel';

export interface OwnProps {
	currentlyHighlightedFields?: HighlightedBillableWorkBlockFields;
	filterVisibleToCustomer: boolean;
	formValues?: Record<string, string>[];
	highlightVisibleToCustomer?: boolean;
	onCalculatedFieldChange: (id: string) => void;
	onFieldValueChange?: (id: string, value: string, index: number) => void;
	onRemoveRepeatableFields?: (index: number) => void;
	reportBlockFieldIds: Nullable<string[]>;
	reportFieldsByIdMap: { [id: string]: ReportBlockFieldFormModel; };
	listOfEquipment?: Nullable<EquipmentListViewModel>;
}

type Props = OwnProps & WrappedFieldArrayProps;

const BlockArray: React.FC<Props> = (props: Props) => {

	const {
		fields,
		onRemoveRepeatableFields,
		reportBlockFieldIds,
		highlightVisibleToCustomer,
		onFieldValueChange,
		onCalculatedFieldChange,
		formValues,
		filterVisibleToCustomer,
		reportFieldsByIdMap,
		currentlyHighlightedFields,
		listOfEquipment,
	} = props;

	const add = React.useCallback(() => fields.push({}), [fields]);

	const getRemoveFor = React.useCallback((index: number) => {
		return () => {
			onRemoveRepeatableFields?.(index);
			fields.remove(index);
		};
	}, [fields, onRemoveRepeatableFields]);

	const renderField = React.useCallback((_field: string, _index: number) => {
		return (
			<div className="report-block__array" key={_field}>
				<ReportBlock
					currentlyHighlightedFields={currentlyHighlightedFields}
					filterVisibleToCustomer={filterVisibleToCustomer}
					formField={_field}
					formValues={formValues}
					highlightVisibleToCustomer={highlightVisibleToCustomer}
					index={_index}
					listOfEquipment={listOfEquipment}
					onCalculatedFieldChange={onCalculatedFieldChange}
					onFieldValueChange={onFieldValueChange}
					reportBlockFieldIds={reportBlockFieldIds}
					reportFieldsByIdMap={reportFieldsByIdMap}
				/>
				<Tooltip message="Delete">
					<span className="icon-delete report-block__array-icon" onClick={getRemoveFor(_index)} />
				</Tooltip>
			</div>
		);
	}, [
		reportBlockFieldIds,
		filterVisibleToCustomer,
		currentlyHighlightedFields,
		reportFieldsByIdMap,
		getRemoveFor,
		highlightVisibleToCustomer,
		onCalculatedFieldChange,
		onFieldValueChange,
		formValues,
		listOfEquipment,
	]);

	React.useEffect(() => {
		if (!fields.length) {
			add();
		}
	}, [add, fields]);

	return (
		<>
			<Row className="row--non-padded">
				{fields.map(renderField)}
			</Row>
			<div className="report-block__add-new" onClick={add}>
				<span className="icon-plus" />
				<span>Add</span>
			</div>
		</>
	);
};

export default React.memo(BlockArray);
