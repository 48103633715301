import type { Dispatch, AnyAction } from 'redux';

import type { TableContent } from 'ab-common/dataStructures/tableContent';

import API from 'af-constants/routes/api';
import { USER_GROUP_FORM } from 'af-constants/reduxForms';

import { http } from 'af-utils/http.util';
import { errorHandler } from 'af-utils/actions.util';

import type { MemberViewModel } from 'ab-viewModels/member.viewModel';

import { TableQuery } from 'ab-common/dataStructures/tableQuery';

import type UserGroupRequestModel from 'ab-requestModels/userGroup.requestModel';

import type { GetRootState } from 'af-reducers';

export function findAllForCompany(includeAccounts: boolean | undefined = false) {
	return async (dispatch: Dispatch<AnyAction>, getState: GetRootState, { redirectTo }) => {

		const action = async () => {
			return await http.get<UserGroupRequestModel[]>(API.V1.COMPANY.USER_GROUPS.LIST(includeAccounts));
		};

		return await errorHandler(action, dispatch, redirectTo);
	};
}

export function findAllForCompanyTable(tableRequestModel?: TableQuery) {
	return async (dispatch: Dispatch<AnyAction>, getState: GetRootState, { redirectTo }) => {

		const action = async () => {
			const data = new TableQuery(tableRequestModel);
			return await http.get<TableContent<UserGroupRequestModel>>(API.V1.COMPANY.USER_GROUPS.TABLE(data));
		};

		return await errorHandler(action, dispatch, redirectTo);
	};
}

export function findAllAccountsWithUserGroupsForCompanyTable(tableRequestModel: TableQuery) {
	return async (dispatch: Dispatch<AnyAction>, getState: GetRootState, { redirectTo }) => {

		const action = async () => {
			const data = new TableQuery(tableRequestModel);
			return await http.get<TableContent<MemberViewModel>>(API.V1.COMPANY.USER_GROUPS.ACCOUNTS_TABLE(data));
		};

		return await errorHandler(action, dispatch, redirectTo);
	};
}

export function createUserGroup(data: UserGroupRequestModel) {
	return async (dispatch: Dispatch<AnyAction>, getState: GetRootState, { redirectTo }) => {

		const action = async () => {
			await http.post(API.V1.COMPANY.USER_GROUPS.CREATE(), data, { submitting: [USER_GROUP_FORM] });
		};

		return await errorHandler(action, dispatch, redirectTo);
	};
}

export function editUserGroup(data: UserGroupRequestModel) {
	return async (dispatch: Dispatch<AnyAction>, getState: GetRootState, { redirectTo }) => {

		const action = async () => {
			await http.put(API.V1.COMPANY.USER_GROUPS.EDIT(data.id), data, { submitting: [USER_GROUP_FORM] });
		};

		return await errorHandler(action, dispatch, redirectTo);
	};
}

export function removeUserGroup(id: number, clearOnly: boolean = false) {
	return async (dispatch: Dispatch<AnyAction>, getState: GetRootState, { redirectTo }) => {

		const action = async () => {
			await http.delete(API.V1.COMPANY.USER_GROUPS.DELETE(id, clearOnly));
		};

		return await errorHandler(action, dispatch, redirectTo);
	};
}

export function findById(userGroupId: number) {
	return async (dispatch: Dispatch<AnyAction>, getState: GetRootState, { redirectTo }) => {

		const action = async () => {
			return await http.get<UserGroupRequestModel>(API.V1.COMPANY.USER_GROUPS.FIND_BY_ID(userGroupId));
		};

		return await errorHandler(action, dispatch, redirectTo);
	};
}
