import * as React from 'react';
import type { WrappedFieldArrayProps } from 'redux-form';

import type ContactVM from 'ab-viewModels/contact/contact';

import SelectableAddress from 'af-components/SharedForms/Contacts/ExpandableAddress/SelectableAddress';
import type { Props as AddressPickerProps } from '.';

interface Props extends AddressPickerProps, WrappedFieldArrayProps<number> { }

export default class SelectableAddressesContainer extends React.PureComponent<Props> {
	async componentDidMount() {
		const { contact: { addresses }, fields } = this.props;

		if (fields.length > 1) {
			const addressId = addresses[0].id;
			fields.removeAll();
			fields.push(addressId);
		}
	}

	onSelect = async (address: ContactVM['addresses'][0]) => {
		const { fields } = this.props;
		fields.removeAll();
		fields.push(address.id);
	};

	render() {
		const { contact: { addresses }, fields, meta: { error }, disabled } = this.props;
		const selectedAddressId = fields.length > 0 && fields.get(0);

		return (
			<>
				{
					addresses.map((_address) => (
						<SelectableAddress
							address={_address}
							disabled={disabled}
							isSelected={_address.id === selectedAddressId}
							key={_address.id}
							onSelect={this.onSelect.bind(this, _address)}
						/>
					))
				}
				{error && <span className="help-block"><span className="icon-info" /> {error}</span>}
			</>
		);
	}
}
