import * as React from 'react';
import { Row, Col, Form } from 'react-bootstrap';
import type { ConnectedProps } from 'react-redux';
import { connect } from 'react-redux';
import type { RouteComponentProps } from 'react-router';
import { compose } from 'redux';
import type { InjectedFormProps} from 'redux-form';
import { reduxForm, Field } from 'redux-form';

import Input from 'af-fields/Input';

import * as CommunicationActions from 'af-actions/communication';

import type { ShortenUrlRequestModel } from 'ab-requestModels/shortenUrl.requestModel';

import { SHORTEN_URL } from 'af-constants/reduxForms';

import type { BreadcrumbItem } from 'af-components/Breadcrumbs';
import Breadcrumbs from 'af-components/Breadcrumbs';
import SubmitButton from 'af-components/SubmitButton';
import PreviewLink from 'af-components/PreviewLink';
import SegmentLabel from 'af-components/SegmentLabel';

import { validate } from './validations';

import { bemElement } from 'ab-utils/bem.util';

type OwnProps = RouteComponentProps<void>;

type FromProps = InjectedFormProps<ShortenUrlRequestModel, FormOwnProps>;

type FormOwnProps = OwnProps & ConnectedProps<typeof connector>;
type Props = FormOwnProps & FromProps;

interface State {
	shortUrl: Nullable<string>;
}

class UrlShortener extends React.PureComponent<Props, State> {
	state: State = {
		shortUrl: null,
	};

	submit = async (form: ShortenUrlRequestModel) => {
		const { shortenUrl } = this.props;

		const shortUrl = await shortenUrl(form) ?? null;
		this.setState(() => ({ shortUrl }));
	};

	getBreadcrumbs = (): BreadcrumbItem[] => [
		{ label: 'Url shortener' },
	];

	render() {
		const { handleSubmit, invalid, submitting, pristine } = this.props;
		const { shortUrl } = this.state;

		return (
			<div className="form-segment">
				<Breadcrumbs items={this.getBreadcrumbs()} />
				<Form
					className="form-box"
					onSubmit={handleSubmit(this.submit)}
				>
					<Row>
						<Col sm={24}>
							<SegmentLabel label="Url shortener" />
						</Col>
					</Row>
					<Row>
						<Col sm={21}>
							<Field
								component={Input}
								name="url"
								placeholder="Enter url"
							/>
						</Col>
						<Col className="submit-button" sm={3}>
							<SubmitButton
								disabled={invalid || submitting || pristine}
								label="Submit"
								reduxFormSubmitting={submitting}
								submitKey={SHORTEN_URL}
							/>
						</Col>
					</Row>
					<div className="short-url-container">
						{shortUrl &&
							<>
								<Row>
									<Col sm={21}>
										<PreviewLink link={shortUrl} />
									</Col>
								</Row>
								<Row>
									<Col className={bemElement('short-url-container', 'expiration-message')} sm={24}>
										<small>This link is valid for 30 days.</small>
									</Col>
								</Row>
							</>
						}
					</div>
				</Form>
			</div>
		);
	}
}

function mapDispatchToProps() {
	return {
		shortenUrl: CommunicationActions.shortenUrl,
	};
}

const connector = connect(null, mapDispatchToProps());

const enhance = compose<React.ComponentClass<OwnProps>>(
	connector,
	reduxForm<ShortenUrlRequestModel, FormOwnProps>({ form: SHORTEN_URL, validate, enableReinitialize: true })
);

export default enhance(UrlShortener);
