import * as React from 'react';

import { filterMap } from 'acceligent-shared/utils/array';

import type WorkSummaryVM from 'ab-viewModels/fieldReport/workSummary.viewModel';
import type { BillingCodeVM } from 'ab-viewModels/job.viewModel';
import type WorkSummaryDetailVM from 'ab-viewModels/fieldReport/workSummaryDetails.viewModel';
import type SiblingSubjobVM from 'ab-viewModels/workRequest/siblingSubjob.viewModel';

import type { CellWidthsMap } from 'af-utils/react.util';

import WorkSummaryTable from '../../Table/index';
import type { BillableWorkTableRowProps, NonBillableWorkTableRowProps } from './BillableAndNonBillableWorkTableRow';
import WorkSummaryBillableWorkTableRow from './BillableAndNonBillableWorkTableRow';
import { WorkSummaryTableSortKey, WORK_SUMMARY_TABLE_SORT_META } from '../../Table/values';
import type { WorkSummaryColumnMeta } from '../../values';
import { WORK_SUMMARY_BILLABLE_WORK_TABLE_COLUMNS, WORK_SUMMARY_NON_BILLABLE_WORK_TABLE_COLUMNS, WorkSummaryColumns, replicateColumnArray } from '../../values';
import { resolveHighlightGroupKey } from '../../helpers';
import AssignAllToSubJobModal from './AssignAllToSubJobModal';

interface BillableWorkSummaryProps {
	isBillable: true;
	onBillingCodeSelect: (billingCode: Nullable<BillingCodeVM>, workSummaryDetailIds: number[]) => Promise<void>;
}

interface NonBillableWorkSummaryProps {
	isBillable: false;
	onBillingCodeSelect?: never;
}
interface OwnPropsBase {
	workSummaryDetails: WorkSummaryDetailVM[];
	workSummary: WorkSummaryVM[];
	billingCodes: Record<string, BillingCodeVM[]>;
	isReadOnly: boolean;
	onRowHighlight: (groupToHighlight: Nullable<string>, groupKeyToHighlight: Nullable<string>) => void;
	highlightedGroup: Nullable<string>;
	highlightedGroupKey: Nullable<string>;
	workSummaryDetailsByGroupKey: Record<string, WorkSummaryDetailVM[]>;
	alternativeWorkSummary: Set<WorkSummaryVM>;
	belongsToProject: boolean;
	handleJobReallocation: (subJob: SiblingSubjobVM, workSummaryDetailIds: number[]) => Promise<void>;
	onReallocateAll: (subJob: SiblingSubjobVM) => void;
	subJobs: SiblingSubjobVM[];
}

type Props = OwnPropsBase & (BillableWorkSummaryProps | NonBillableWorkSummaryProps);

const _maxDefinitionFieldsReducer = (_max: number, _currentWSD: { definitionFields: unknown[]; }) => {
	if (_currentWSD.definitionFields?.length > _max) {
		_max = _currentWSD.definitionFields?.length;
	}

	return _max;
};

const _resolveWorkSummaryBillableWorkTableHeaders = (maxNumberOfDefinitionFields: number, isBillable: boolean, belongsToProject: boolean) => {
	const headers: WorkSummaryColumnMeta[] = [];
	const headerTemplates = isBillable ? WORK_SUMMARY_BILLABLE_WORK_TABLE_COLUMNS : WORK_SUMMARY_NON_BILLABLE_WORK_TABLE_COLUMNS;
	for (const column of headerTemplates) {
		if (column.id === WorkSummaryColumns.DEFINITION_FIELD) {
			if (maxNumberOfDefinitionFields > 0) {
				headers.push(...replicateColumnArray(column, maxNumberOfDefinitionFields));
			}
		} else if (column.id === WorkSummaryColumns.REALLOCATE) {
			if (belongsToProject) {
				headers.push(column);
			}
		} else {
			headers.push(column);
		}
	}

	return headers;
};

const _findFirstWithGroupName = ({ workSummaryGroup }) => !!workSummaryGroup;

const _getGroupKey = (item: WorkSummaryVM) => resolveHighlightGroupKey({
	billableWorkId: item.billableWorkId,
	workType: item.type,
	definitionFields: item.definitionFields,
	subJobId: item.subJobId,
});

function _workSummariesAreEqual(
	detail: WorkSummaryDetailVM,
	summary: WorkSummaryVM
): boolean {
	// Check basic properties
	if (
		detail.billableWorkId !== summary.billableWorkId
		|| detail.subJobId !== summary.subJobId
		|| detail.billingCodeId !== summary.billingCodeId
		|| detail.isBillable !== summary.isBillable
		|| detail.workName !== summary.work
		|| detail.workType !== summary.type
		|| detail.workSummaryGroup !== summary.workSummaryGroup
	) {
		return false;
	}

	// Check definition fields
	if (detail.definitionFields.length !== summary.definitionFields.length) {
		return false;
	}

	for (let i = 0; i < detail.definitionFields.length; i++) {
		const detailField = detail.definitionFields[i];
		const summaryField = summary.definitionFields[i];

		if (detailField.fieldType !== summaryField.fieldType ||
			detailField.name !== summaryField.fieldName ||
			detailField.value !== summaryField.value) {
			return false;
		}
	}

	return true;
}

const BillableAndNonBillableWorkTable: React.FC<Props> = (props) => {
	const {
		workSummaryDetails,
		workSummary,
		billingCodes,
		isBillable,
		onBillingCodeSelect,
		onRowHighlight,
		highlightedGroup,
		isReadOnly,
		highlightedGroupKey,
		workSummaryDetailsByGroupKey,
		alternativeWorkSummary,
		belongsToProject,
		subJobs,
		onReallocateAll,
		handleJobReallocation,
	} = props;

	const [showAssignAllModal, setShowAssignAllModal] = React.useState(false);

	const closeAssignAllModal = React.useCallback(() => setShowAssignAllModal(false), []);

	const openAssignAllModal = React.useCallback(() => setShowAssignAllModal(true), []);

	const maxNumberOfDefinitionFieldsFromDetails = workSummaryDetails?.reduce(_maxDefinitionFieldsReducer, 0) ?? 0;
	const maxNumberOfDefinitionFieldsFromSummary = workSummary?.reduce(_maxDefinitionFieldsReducer, 0) ?? 0;
	const maxNumberOfDefinitionFields = Math.max(maxNumberOfDefinitionFieldsFromDetails, maxNumberOfDefinitionFieldsFromSummary);

	const tableColumns = React.useMemo(
		() => {
			const columns = _resolveWorkSummaryBillableWorkTableHeaders(maxNumberOfDefinitionFields, isBillable, belongsToProject ?? false);
			return columns.map((_header) => {
				if (_header.id === WorkSummaryColumns.REALLOCATE && !!workSummary.length && !isReadOnly) {
					return { ..._header, additionalColumnAction: { text: 'Assign All', icon: 'edit', action: openAssignAllModal } };
				}
				return _header;
			});
		},
		[belongsToProject, isBillable, isReadOnly, maxNumberOfDefinitionFields, openAssignAllModal, workSummary.length]
	);

	const renderWorkSummaryBillableWorkTableRow = React.useCallback((data: WorkSummaryVM, cellWidths: CellWidthsMap) => {
		const billableDependentProps = isBillable
			? { isBillable: true, onBillingCodeSelect } as BillableWorkTableRowProps
			: { isBillable: false } as NonBillableWorkTableRowProps;

		const groupKey = resolveHighlightGroupKey({
			billableWorkId: data.billableWorkId,
			workType: data.type,
			definitionFields: data.definitionFields,
			subJobId: data.subJobId,
		});
		const hasPreviouslyBeenSplit = !!workSummaryDetailsByGroupKey[groupKey]?.find(_findFirstWithGroupName);
		const isOfCurrentlyHighlightedGroupKey = highlightedGroupKey === groupKey;
		const isAlternativeOption = alternativeWorkSummary.has(data);

		const ids = filterMap(
			workSummaryDetails,
			(_wsd) => _workSummariesAreEqual(_wsd, data),
			(_wsd) => _wsd.id
		);

		const subJobBillingCodes = billingCodes[data.subJobId] ?? [];

		return (
			<WorkSummaryBillableWorkTableRow
				belongsToProject={belongsToProject}
				billingCodes={subJobBillingCodes}
				cellWidths={cellWidths}
				handleJobReallocation={handleJobReallocation}
				hasPreviouslyBeenSplit={hasPreviouslyBeenSplit}
				highlightedGroup={highlightedGroup}
				isAlternativeOption={isAlternativeOption}
				isOfCurrentlyHighlightedGroupKey={isOfCurrentlyHighlightedGroupKey}
				isReadOnly={isReadOnly}
				key={data.workSummaryGroup ?? groupKey}
				maxNumberOfDefinitionFields={maxNumberOfDefinitionFields}
				onRowHighlight={onRowHighlight}
				rowData={data}
				subJobs={subJobs}
				workSummaryDetailIds={ids}
				{...billableDependentProps}
			/>
		);
	}, [
		isBillable,
		onBillingCodeSelect,
		belongsToProject,
		workSummaryDetailsByGroupKey,
		highlightedGroupKey,
		alternativeWorkSummary,
		workSummaryDetails,
		billingCodes,
		highlightedGroup,
		isReadOnly,
		maxNumberOfDefinitionFields,
		onRowHighlight,
		subJobs,
		handleJobReallocation,
	]
	);

	const sort = WORK_SUMMARY_TABLE_SORT_META[WorkSummaryTableSortKey.TYPE].sort;

	return (
		<>
			<WorkSummaryTable<WorkSummaryVM>
				data={workSummary}
				getGroupKey={_getGroupKey}
				headers={tableColumns}
				highlightedGroupKey={highlightedGroupKey}
				renderRow={renderWorkSummaryBillableWorkTableRow}
				sort={sort}
				useGroups={true}
			/>
			<AssignAllToSubJobModal
				closeModal={closeAssignAllModal}
				onSubmit={onReallocateAll}
				showModal={showAssignAllModal}
				subJobs={subJobs}
			/>
		</>
	);
};

export default React.memo(BillableAndNonBillableWorkTable);
