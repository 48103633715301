import * as React from 'react';
import { compose } from 'redux';
import type { ConnectedProps } from 'react-redux';
import { connect } from 'react-redux';

import type { RootState } from 'af-reducers';

import { SCHEDULE_BOARD_MAX_ITEMS_IN_ROW, SCHEDULE_BOARD_MAX_TOOLBAR_AVAILABLE_EQUIPMENT_COLUMNS } from 'ab-common/constants/scheduleBoard';

import ScheduleBoardView from 'ab-enums/scheduleBoardView.enum';

import DroppableComponent from './Droppable';

interface OwnProps {
	draggablePrefix: string;
	equipmentIds: number[];
	droppableId: string;
	isToolbar: boolean;
	isDragAndDropDisabled: boolean;
	hasPermissionsToEditScheduleBoard: boolean;
	dueDate: string;
	title?: string;
	hasReason?: boolean;
	wrappedColumns?: boolean;
	isWorkOrderCanceled?: boolean;
	isCopyPlaceholder?: boolean;
	isCardDisabled?: boolean;
	disabled?: boolean;
}

type Props = OwnProps & ConnectedProps<typeof connector>;

const EquipmentDroppable: React.FC<Props> = (props) => {
	const {
		dueDate,
		title,
		droppableId,
		equipmentIds = [],
		draggablePrefix,
		wrappedColumns = false,
		isDragAndDropDisabled = false,
		hasPermissionsToEditScheduleBoard = true,
		isToolbar = false,
		hasReason = false,
		isDisabled,
		isCardDisabled,
		isWorkOrderCanceled,
		isCopyPlaceholder = false,
	} = props;

	let className = 'equipment-droppable';
	className += !equipmentIds.length ? ' empty-droppable' : '';
	className += wrappedColumns ? ' wrapped-columns' : '';

	if (!isToolbar) {
		// case for equipment within card
		const numberOfColumns = Math.max(1, Math.ceil(equipmentIds.length / SCHEDULE_BOARD_MAX_ITEMS_IN_ROW));
		return (
			<div className="equipment-droppable-columns-wrapper">
				{Array(numberOfColumns || 1).fill(1).map((i, index) => (
					<DroppableComponent
						className={`${className} ${index > 0 ? ' multiple-droppable-column' : ''}`}
						draggablePrefix={draggablePrefix}
						droppableId={`${droppableId}#${index}`}
						dueDate={dueDate}
						endIndex={(index + 1) * SCHEDULE_BOARD_MAX_ITEMS_IN_ROW}
						equipmentIds={equipmentIds}
						hasPermissionsToEditScheduleBoard={hasPermissionsToEditScheduleBoard}
						hasReason={hasReason}
						isCardDisabled={isCardDisabled}
						isCopyPlaceholder={isCopyPlaceholder}
						isDisabled={isDisabled}
						isDragAndDropDisabled={isDragAndDropDisabled}
						isToolbar={isToolbar}
						isWorkOrderCanceled={isWorkOrderCanceled}
						key={`${droppableId}#${index}`}
						startIndex={index * SCHEDULE_BOARD_MAX_ITEMS_IN_ROW}
					/>
				))}
			</div>
		);
	} else if (!hasReason) {
		// case for available equipment in toolbar,
		// number of columns defined in SCHEDULE_BOARD_MAX_TOOLBAR_AVAILABLE_EQUIPMENT_COLUMNS
		const numberOfColumns = SCHEDULE_BOARD_MAX_TOOLBAR_AVAILABLE_EQUIPMENT_COLUMNS;
		const maxItemsPerRow = Math.max(1, Math.ceil(equipmentIds.length / 2));
		return (
			<div className="equipment-toolbar-multicolumn-wrapper">
				{Array(numberOfColumns).fill(1).map((i, index) => (
					<DroppableComponent
						className={`${className} equipment-toolbar-multicolumn`}
						draggablePrefix={draggablePrefix}
						droppableId={`${droppableId}#${index}`}
						dueDate={dueDate}
						endIndex={(index + 1) * maxItemsPerRow}
						equipmentIds={equipmentIds}
						hasPermissionsToEditScheduleBoard={hasPermissionsToEditScheduleBoard}
						hasReason={hasReason}
						isCardDisabled={isCardDisabled}
						isCopyPlaceholder={isCopyPlaceholder}
						isDisabled={isDisabled}
						isDragAndDropDisabled={isDragAndDropDisabled}
						isToolbar={isToolbar}
						isWorkOrderCanceled={isWorkOrderCanceled}
						key={`${droppableId}#${index}`}
						startIndex={index * maxItemsPerRow}
					/>
				))}
			</div>
		);
	}

	// case for unavailable equipment in toolbar, single column
	return (
		<DroppableComponent
			className={className}
			draggablePrefix={draggablePrefix}
			droppableId={droppableId}
			dueDate={dueDate}
			endIndex={equipmentIds.length}
			equipmentIds={equipmentIds}
			hasPermissionsToEditScheduleBoard={hasPermissionsToEditScheduleBoard}
			hasReason={hasReason}
			isCopyPlaceholder={isCopyPlaceholder}
			isDisabled={isDisabled}
			isDragAndDropDisabled={isDragAndDropDisabled}
			isToolbar={isToolbar}
			isWorkOrderCanceled={isWorkOrderCanceled}
			startIndex={0}
			title={title}
		/>
	);
};

function mapStateToProps(state: RootState, ownProps: OwnProps) {
	const { equipmentIds, dueDate, disabled } = ownProps;
	const {
		draggedEquipmentId,
		copiedEquipmentId,
		weeklyViewDateWithToolbar,
		workOrdersByDateDictionary,
		draggedResourceId,
		scheduleBoardView,
	} = state.scheduleBoard;

	const isIncluded: boolean = equipmentIds.some((_equipmentId: number) => _equipmentId === draggedEquipmentId || _equipmentId === copiedEquipmentId);

	const disableDraggingWhenToolbarOpened = scheduleBoardView === ScheduleBoardView.WEEKLY_VIEW &&
		(!weeklyViewDateWithToolbar || weeklyViewDateWithToolbar !== dueDate);

	const isEmployee = draggedResourceId !== null && !!workOrdersByDateDictionary?.[dueDate]?.workOrderResourceLookups?.[draggedResourceId]?.employeeId;

	const isDisabled: boolean = disableDraggingWhenToolbarOpened ||
		!!disabled ||
		isIncluded ||
		isEmployee ||
		!!copiedEquipmentId;

	return {
		isDisabled,
	};
}

const connector = connect(mapStateToProps);

const enhance = compose<React.ComponentType<OwnProps>>(
	React.memo,
	connector
);

export default enhance(EquipmentDroppable);
