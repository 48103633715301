import * as React from 'react';

import ImageUpload from 'af-components/ImageUpload';

import ReportBlockFieldModal from './ReportBlockFieldModal';

import type { ReportBlockFieldFormModel, CalculatedFieldOptionFormModel } from '../../../Shared/formModel';

interface OwnProps {
	closeModal: () => void;
	fields: CalculatedFieldOptionFormModel[];
	fieldsByIdMap: { [fieldId: string]: ReportBlockFieldFormModel; };
	hideIdField: boolean;
	initialValues?: Partial<ReportBlockFieldFormModel> & { index?: number; };
	isPrimaryBlock: boolean;
	onSubmit: (form: ReportBlockFieldFormModel, fieldIndex: Nullable<number>) => void;
	showModal: boolean;
}

type Props = OwnProps;

const CreateReportBlockField: React.FC<Props> = (props: Props) => {
	const {
		onSubmit,
		initialValues,
		hideIdField,
		isPrimaryBlock,
		fields,
		fieldsByIdMap,
		showModal,
		closeModal,
	} = props;

	const [showReportBlockFieldModal, setShowReportBlockFieldModal] = React.useState<boolean>(showModal);
	const [showImageUploadModal, setShowImageUploadModal] = React.useState<boolean>(false);
	const [tempFormValues, setTempFormValues] = React.useState<Nullable<Partial<ReportBlockFieldFormModel>>>(null);

	React.useEffect(() => {
		setShowReportBlockFieldModal(showModal);
	}, [showModal]);

	const handleClose = React.useCallback(() => {
		closeModal();
		setTempFormValues(null);
	}, [closeModal]);

	const onImageUploadSave = React.useCallback((imageFile: File, imageUrl: string) => {
		const newTempFormValues = { ...tempFormValues, defaultValue: imageUrl, imageFile };
		setShowImageUploadModal(false);
		setTempFormValues(newTempFormValues);
		setShowReportBlockFieldModal(true);
	}, [tempFormValues]);

	const openImageUploadModal = React.useCallback((form: Partial<ReportBlockFieldFormModel>) => {
		setShowImageUploadModal(true);
		setShowReportBlockFieldModal(false);
		setTempFormValues(form);
	}, []);

	const closeUploadModal = React.useCallback(() => {
		setShowImageUploadModal(false);
		setShowReportBlockFieldModal(true);
	}, []);

	return (
		<>
			<ReportBlockFieldModal
				closeModal={handleClose}
				fields={fields}
				fieldsByIdMap={fieldsByIdMap}
				firstInitialValues={tempFormValues ?? initialValues}
				hideIdField={hideIdField}
				isPrimaryBlock={isPrimaryBlock}
				onImageUpload={openImageUploadModal}
				onSubmit={onSubmit}
				showModal={showReportBlockFieldModal}
			/>
			<ImageUpload
				closeModal={closeUploadModal}
				disableActions={true}
				freeAspectRatio={true}
				onSave={onImageUploadSave}
				showModal={showImageUploadModal}
			/>
		</>
	);
};

export default React.memo(CreateReportBlockField);
