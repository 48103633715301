import * as React from 'react';
import { FormControl } from 'react-bootstrap';
import type { WrappedFieldMetaProps} from 'redux-form';
import { Field } from 'redux-form';

import type { CompoundUnitEnum, PlainUnitEnum } from 'acceligent-shared/enums/quantityUnit';
import { CompoundUnitValueLimits } from 'acceligent-shared/enums/quantityUnit';

import { roundUnitValue } from 'acceligent-shared/utils/unit';

import type { Sizes } from 'af-fields/Input';
import Input from 'af-fields/Input';

import type { OwnProps as TooltipProps } from 'af-components/Tooltip';

import Label from 'af-components/LockedValue/Label';

interface Props extends WrappedFieldMetaProps {
	name: string;
	unit: CompoundUnitEnum;
	id?: string;
	label?: string | JSX.Element;
	disabled?: boolean;
	onValueChange: (elementUnit: string, value: number) => void;
	inputSize?: Sizes;
	tooltipMessage?: TooltipProps['message'];
	controlCursor?: boolean;
	className?: string;
	disableErrors?: boolean;
	hideErrorText?: boolean;
	forceShowError?: boolean;
	onKeyDown?: (event: Metadata) => void;
	onFocus?: (event: Metadata) => void;
	onBlur?: (event: Metadata) => void;
}

class CompoundInput extends React.Component<Props> {
	static defaultProps: Partial<Props> = {
		controlCursor: false,
	};

	onValueChange = (elementUnit: string, value: string) => {
		const { onValueChange } = this.props;
		onValueChange?.(elementUnit, roundUnitValue(+value, elementUnit as PlainUnitEnum));
	};

	static normalizeFieldValue = (min: number, max: number) =>{
		return (value: string) => {
			const normalizedValue = Number(value);
			if (normalizedValue < min) {
				return min;
			  } else if (normalizedValue > max) {
				return max;
			  } else {
				return normalizedValue;
			  }
		};
	};

	renderInput = (elementUnit: string, index: number) => {
		const { name, disabled, unit, onFocus, onBlur, onKeyDown, inputSize, className } = this.props;

		const limits = CompoundUnitValueLimits[unit][elementUnit];
		return (
			<Field
				addonAfter={elementUnit}
				className={className}
				component={Input}
				disabled={disabled}
				disableErrors={true}
				hideErrorText={true}
				inputSize={inputSize}
				key={index}
				max={limits.max}
				min={limits.min}
				name={`${name}.${elementUnit}`}
				normalize={CompoundInput.normalizeFieldValue(limits.min, limits.max)}
				onBlur={onBlur}
				onFocus={onFocus}
				onKeyDown={onKeyDown}
				onValueChange={this.onValueChange.bind(this, elementUnit)}
				placeholder="0"
				type="number"
			/>
		);
	};

	render() {
		const { label, unit, tooltipMessage, touched, error, warning, disableErrors, hideErrorText, forceShowError, id } = this.props;

		const elements = Object.keys(CompoundUnitValueLimits[unit]);
		return (
			<div className="compound-field">
				<Label
					label={label}
					tooltipMessage={tooltipMessage}
					tooltipPlacement="top"
					withMargin={true}
				/>
				<div className="compound-field__inputs" id={id}>
					{elements.map(this.renderInput)}
				</div>
				<FormControl.Feedback />
				{!!(!disableErrors && !hideErrorText && (touched || forceShowError)) &&
					(
						(error && <span className="help-block"><span className="icon-info" /> {error}</span>) ||
						(warning && <span className="help-block text-orange"><span className="icon-info" /> {warning}</span>)
					)
				}
			</div>
		);
	}
}

export default CompoundInput;
