import type TemporaryEmployeeBase from 'ab-domain/models/temporaryEmployee/base';

const WORKERS_TABLE_CSV_HEADER = [
	'Name',
	'Total Wo',
];

export default class AgencyAssociatedWorkersTableVM {
	id: number;
	uniqueId: string;
	firstName: string;
	lastName: string;
	agencyId: number;
	totalWorkOrders: number;

	constructor(employee: TemporaryEmployeeBase) {
		if (!employee.account.user) {
			throw new Error('Can\'t construct AgencyAssociatedWorkersTableVM from employee with no associated user');
		}

		this.id = employee.id;
		this.uniqueId = employee.uniqueId;
		this.firstName = employee.account.user.firstName;
		this.lastName = employee.account.user.lastName;
		this.agencyId = employee.agencyId;
		this.totalWorkOrders = employee.workOrderTemporaryEmployees?.length ?? 0;
	}

	private static _constructorMap = (employee: TemporaryEmployeeBase) => new AgencyAssociatedWorkersTableVM(employee);

	static bulkConstructor(employees: TemporaryEmployeeBase[]): AgencyAssociatedWorkersTableVM[] {
		return employees.map(AgencyAssociatedWorkersTableVM._constructorMap);
	}

	static toCSVData(viewModels: AgencyAssociatedWorkersTableVM[]): string[][] {
		const header: string[] = [...WORKERS_TABLE_CSV_HEADER];
		const rows: string[][] = [];

		viewModels.forEach((_employee) => {
			rows.push([
				`${_employee.lastName},${_employee.firstName}`,
				_employee.totalWorkOrders.toString(),
			]);
		});

		return [header, ...rows];
	}

}
