import type { FormErrors, CustomFormErrors } from 'redux-form';

import type DefaultBillableWorkQuantity from 'acceligent-shared/enums/defaultBillableWorkQuantity';
import ReportTypeBlockType from 'acceligent-shared/enums/reportTypeBlockType';

import { isValidTextInput } from 'acceligent-shared/utils/text';

import type { BillableWorkDefinitionFieldRM, BillableWorkInformationFieldRM, BillableWorkRM } from 'ab-requestModels/reportType/reportType.requestModel';
import type { BillableWorkVM } from 'ab-viewModels/reportType/reportType.viewModel';

import type { ReportBlockFormModel, ReportTypeBlockFormModel } from '../../../Shared/formModel';

import type { BillableWorkDropdownOption } from './types';

const _isValidBoolean = (value: Nullable<boolean> | undefined): value is boolean => {
	return value !== null && value !== undefined;
};

function isSecondaryNonMainBlock(isPrimary: boolean, isMain: boolean) {
	return !isPrimary && !isMain;
}

interface ValidationContext {
	reportTypeBlocksByIdMap: Record<string, ReportTypeBlockFormModel>;
	reportBlocksByIdMap: { [id: string]: ReportBlockFormModel; };
	reservedBillableWorkNames: Nullable<Record<string, true>>;
	workTypeFieldIds: string[];
	workQuantityFieldIds: string[];
	definitionFieldFieldIds: string[];
	informationFieldFieldIds: string[];
	billableWorkFieldsByIdMap: { [id: string]: BillableWorkDropdownOption; };
}

export class BillableWorkInformationFieldFormModel {
	reportBlockFieldVirtualId: Nullable<string>;
	isInPrimarySegment: Nullable<boolean>;

	constructor(initial?: BillableWorkRM['informationFields'][0]) {
		this.reportBlockFieldVirtualId = initial?.reportBlockFieldVirtualId ?? null;
		this.isInPrimarySegment = _isValidBoolean(initial?.isInPrimarySegment)
			? initial!.isInPrimarySegment
			: null;
	}

	static field(name: keyof BillableWorkInformationFieldFormModel) {
		return name;
	}

	static constructorMap(initial?: BillableWorkRM['informationFields'][0]): BillableWorkInformationFieldFormModel {
		return new BillableWorkInformationFieldFormModel(initial);
	}

	static bulkConstructor = (informationFields: BillableWorkRM['informationFields']) => informationFields.map(BillableWorkInformationFieldFormModel.constructorMap);

	static toRequestModel(informationField: BillableWorkInformationFieldFormModel): BillableWorkRM['informationFields'][0] {
		if (!informationField.reportBlockFieldVirtualId || !_isValidBoolean(informationField.isInPrimarySegment)) {
			throw new Error('Missing report block field id');
		}
		return {
			reportBlockFieldVirtualId: informationField.reportBlockFieldVirtualId,
			isInPrimarySegment: informationField.isInPrimarySegment!,
		};
	}

	static fromVMtoRM(informationField: BillableWorkVM['informationFields'][0]): BillableWorkInformationFieldRM {
		return {
			reportBlockFieldVirtualId: informationField.reportBlockFieldVirtualId,
			isInPrimarySegment: informationField.isInPrimarySegment,
		};
	}

	static validate(
		form: BillableWorkInformationFieldFormModel,
		workQuantityFieldId: Nullable<string>,
		billableWorkFieldsByIdMap: { [id: string]: BillableWorkDropdownOption; }
	): FormErrors<BillableWorkInformationFieldFormModel> {
		const errors: FormErrors<BillableWorkInformationFieldFormModel> = {};
		if (!form.reportBlockFieldVirtualId) {
			errors.reportBlockFieldVirtualId = 'Information Field is required.';
		}
		if (!_isValidBoolean(form.isInPrimarySegment)) {
			errors.isInPrimarySegment = 'Information Field segment must be defined';
		}
		const quantityField = workQuantityFieldId ? billableWorkFieldsByIdMap[workQuantityFieldId] : null;
		const field = form.reportBlockFieldVirtualId ? billableWorkFieldsByIdMap[form.reportBlockFieldVirtualId] : null;
		if (!field) {
			errors.reportBlockFieldVirtualId = 'Information field could not be found';
		}
		if (quantityField) {
			if (field && quantityField.isTotalBlock && !field.isTotalBlock) {
				errors.reportBlockFieldVirtualId = 'Information field must be in total block';
			}
			if (field && quantityField.isInPrimarySegment && !field.isInPrimarySegment) {
				errors.reportBlockFieldVirtualId = 'When Quantity field in Primary segment Information field must not be in secondary segment';
			}
		}
		return errors;
	}
}

export class BillableWorkDefinitionFieldFormModel {
	reportBlockFieldVirtualId: Nullable<string>;
	name: Nullable<string>;
	isInPrimarySegment: Nullable<boolean>;

	constructor(initial?: BillableWorkRM['definitionFields'][0]) {
		this.reportBlockFieldVirtualId = initial?.reportBlockFieldVirtualId ?? null;
		this.name = initial?.name ?? null;
		this.isInPrimarySegment = _isValidBoolean(initial?.isInPrimarySegment) ? initial!.isInPrimarySegment : null;
	}

	static field(name: keyof BillableWorkDefinitionFieldFormModel) {
		return name;
	}

	static constructorMap(initial?: BillableWorkRM['definitionFields'][0]): BillableWorkDefinitionFieldFormModel {
		return new BillableWorkDefinitionFieldFormModel(initial);
	}

	static bulkConstructor = (definitionFields: BillableWorkRM['definitionFields']) => definitionFields.map(BillableWorkDefinitionFieldFormModel.constructorMap);

	static toRequestModel(_acc: BillableWorkRM['definitionFields'], _curr: BillableWorkRM['definitionFields'][0]): BillableWorkRM['definitionFields'] {
		if (!_curr.name || !_curr.reportBlockFieldVirtualId || !_isValidBoolean(_curr.isInPrimarySegment)) {
			return _acc;
		}

		_acc.push({ name: _curr.name, reportBlockFieldVirtualId: _curr.reportBlockFieldVirtualId, isInPrimarySegment: _curr.isInPrimarySegment });
		return _acc;
	}

	static fromVMToRM(definitionField: BillableWorkVM['definitionFields'][0]): BillableWorkDefinitionFieldRM {
		return {
			reportBlockFieldVirtualId: definitionField.reportBlockFieldVirtualId,
			name: definitionField.name,
			isInPrimarySegment: definitionField.isInPrimarySegment,
		};
	}

	static validate(
		form: BillableWorkDefinitionFieldFormModel,
		workQuantityFieldId: Nullable<string>,
		billableWorkFieldsByIdMap: { [id: string]: BillableWorkDropdownOption; }
	): FormErrors<BillableWorkDefinitionFieldFormModel> {
		const errors: FormErrors<BillableWorkDefinitionFieldFormModel> = {};
		if (!form.reportBlockFieldVirtualId) {
			errors.reportBlockFieldVirtualId = 'Definition Field is required.';
		}
		if (!_isValidBoolean(form.isInPrimarySegment === null)) {
			errors.reportBlockFieldVirtualId = 'Definition Field segment must be defined';
		}
		const quantityField = workQuantityFieldId ? billableWorkFieldsByIdMap[workQuantityFieldId] : null;
		const field = form.reportBlockFieldVirtualId ? billableWorkFieldsByIdMap[form.reportBlockFieldVirtualId] : null;
		if (!field) {
			errors.reportBlockFieldVirtualId = 'Definition field could not be found';
		}
		if (quantityField) {
			if (field && quantityField.isTotalBlock && !field.isTotalBlock) {
				errors.reportBlockFieldVirtualId = 'Definition field must be in total block';
			}
			if (field && quantityField.isInPrimarySegment && !field.isInPrimarySegment) {
				errors.reportBlockFieldVirtualId = 'When Quantity field in Primary segment Definition field must not be in secondary segment';
			}
		}
		return errors;
	}
}

class BillableWorkFormModel {
	workName: string;
	isInPrimarySegment: Nullable<boolean>;
	workQuantityReportBlockFieldVirtualId: Nullable<string>;
	workTypeReportBlockFieldVirtualId: Nullable<string>;
	defaultQuantity: Nullable<DefaultBillableWorkQuantity>;
	definitionFields: BillableWorkDefinitionFieldFormModel[];
	informationFields: BillableWorkInformationFieldFormModel[];

	constructor(initial: Nullable<BillableWorkRM>) {
		this.workName = initial?.workName ?? '';
		this.isInPrimarySegment = _isValidBoolean(initial?.isInPrimarySegment) ? initial!.isInPrimarySegment : null;
		this.workQuantityReportBlockFieldVirtualId = initial?.workQuantityReportBlockFieldVirtualId ?? null;
		this.workTypeReportBlockFieldVirtualId = initial?.workTypeReportBlockFieldVirtualId ?? null;
		this.defaultQuantity = initial?.defaultQuantity ?? null;
		this.definitionFields = BillableWorkDefinitionFieldFormModel.bulkConstructor(initial?.definitionFields ?? []);
		this.informationFields = BillableWorkInformationFieldFormModel.bulkConstructor(initial?.informationFields ?? []);
	}

	static create(initial: Nullable<BillableWorkRM>): BillableWorkFormModel {
		return new BillableWorkFormModel(initial);
	}

	static field(name: keyof BillableWorkFormModel) {
		return name;
	}

	static toRequestModel(form: BillableWorkFormModel): BillableWorkRM {
		if (!form.workTypeReportBlockFieldVirtualId || !_isValidBoolean(form.isInPrimarySegment)) {
			throw new Error('Missing workTypeReportBlockField');
		}
		return {
			workName: form.workName,
			isInPrimarySegment: form.isInPrimarySegment!,
			workTypeReportBlockFieldVirtualId: form.workTypeReportBlockFieldVirtualId,
			workQuantityReportBlockFieldVirtualId: form.workQuantityReportBlockFieldVirtualId,
			defaultQuantity: form.defaultQuantity,
			definitionFields: form.definitionFields.reduce(BillableWorkDefinitionFieldFormModel.toRequestModel, []),
			informationFields: form.informationFields.map(BillableWorkInformationFieldFormModel.toRequestModel),
		};
	}

	static fromVMToRM(billableWork: BillableWorkVM): BillableWorkRM {
		return {
			workName: billableWork.workName,
			isInPrimarySegment: billableWork.isInPrimarySegment,
			workTypeReportBlockFieldVirtualId: billableWork.workTypeReportBlockFieldVirtualId,
			workQuantityReportBlockFieldVirtualId: billableWork.workQuantityReportBlockFieldVirtualId,
			defaultQuantity: billableWork.defaultQuantity,
			definitionFields: billableWork.definitionFields.map(BillableWorkDefinitionFieldFormModel.fromVMToRM),
			informationFields: billableWork.informationFields.map(BillableWorkInformationFieldFormModel.fromVMtoRM),
		};
	}

	static validate(
		values: BillableWorkFormModel,
		context: ValidationContext
	): FormErrors<BillableWorkFormModel> {
		const errors: CustomFormErrors<BillableWorkFormModel> = {};

		const {
			reservedBillableWorkNames,
			reportTypeBlocksByIdMap,
			reportBlocksByIdMap,
			billableWorkFieldsByIdMap,
		} = context;

		if (!isValidTextInput(values.workName)) {
			errors.workName = 'Work Name is required.';
		} else {
			if (reservedBillableWorkNames?.[values.workName.toLowerCase()]) {
				errors.workName = 'Work Name already taken.';
			}
		}
		if (!_isValidBoolean(values.isInPrimarySegment)) {
			errors.isInPrimarySegment = 'Segment is required';
		}
		if (!values.workQuantityReportBlockFieldVirtualId && !values.defaultQuantity) {
			errors.workQuantityReportBlockFieldVirtualId = 'Work Quantity is required.';
		}
		if (values.workQuantityReportBlockFieldVirtualId && values.defaultQuantity) {
			errors.workQuantityReportBlockFieldVirtualId = 'Work Quantity must be a field or a default value.';
		}
		if (!values.workTypeReportBlockFieldVirtualId) {
			errors.workTypeReportBlockFieldVirtualId = 'Work Type is required.';
		}
		if (values.workTypeReportBlockFieldVirtualId && values.workQuantityReportBlockFieldVirtualId) {
			const workQuantityReportTypeBlock =
				reportTypeBlocksByIdMap[billableWorkFieldsByIdMap[values.workQuantityReportBlockFieldVirtualId].reportTypeBlockVirtualId];
			const workTypeReportTypeBlock =
				reportTypeBlocksByIdMap[billableWorkFieldsByIdMap[values.workTypeReportBlockFieldVirtualId].reportTypeBlockVirtualId];
			const workTypeReportBlock = reportBlocksByIdMap[workTypeReportTypeBlock.reportBlockVirtualId];
			const workQuantityReportBlock = reportBlocksByIdMap[workQuantityReportTypeBlock.reportBlockVirtualId];

			if (isSecondaryNonMainBlock(workTypeReportTypeBlock.isPrimary, workTypeReportBlock.isMain)
				&& !isSecondaryNonMainBlock(workQuantityReportTypeBlock.isPrimary, workQuantityReportBlock.isMain)
			) {
				errors.workQuantityReportBlockFieldVirtualId = 'Work Quantity must also be in a secondary non-main block due to the selected Work Type.';
			}
			if (
				!errors.workQuantityReportBlockFieldVirtualId
				&& workTypeReportBlock.isRepeating
				&& workQuantityReportTypeBlock.reportBlockId !== workTypeReportTypeBlock.reportBlockId
			) {
				errors.workQuantityReportBlockFieldVirtualId = 'Since the selected Work Type is in a repeatable block, Work Quantity must be picked from the same block.';
			}
		}

		if (values.definitionFields?.length) {
			const definitionFieldErrors = values.definitionFields.map(
				(_field) => BillableWorkDefinitionFieldFormModel.validate(_field, values.workQuantityReportBlockFieldVirtualId, billableWorkFieldsByIdMap)
			);
			if (definitionFieldErrors.some((_error) => Object.keys(_error).length)) {
				errors.definitionFields = definitionFieldErrors;
			}

			if (!errors.workQuantityReportBlockFieldVirtualId && values.workQuantityReportBlockFieldVirtualId) {
				const workQuantityReportTypeBlock =
					reportTypeBlocksByIdMap[billableWorkFieldsByIdMap[values.workQuantityReportBlockFieldVirtualId].reportTypeBlockVirtualId];
				if (workQuantityReportTypeBlock.isPrimary && workQuantityReportTypeBlock.type === ReportTypeBlockType.BLOCK) {
					const definitionFieldsInSecondarySegment = values.definitionFields.reduce<BillableWorkDropdownOption[]>((_acc, _df) => {
						if (!_df.reportBlockFieldVirtualId) {
							return _acc;
						}
						const dropdownOption = billableWorkFieldsByIdMap[_df.reportBlockFieldVirtualId];
						const reportTypeBlock = reportTypeBlocksByIdMap[dropdownOption.reportTypeBlockVirtualId];
						if (!reportTypeBlock.isPrimary && reportTypeBlock.type === ReportTypeBlockType.BLOCK) {
							_acc.push(dropdownOption);
						}
						return _acc;
					}, []);

					if (definitionFieldsInSecondarySegment.length) {
						const definitionFieldsFormatted = definitionFieldsInSecondarySegment.map((_df) => _df.name).join(', ');
						errors.workQuantityReportBlockFieldVirtualId = `Work Quantity must also be in a primary block because of the following definition fields: ${definitionFieldsFormatted}.`;
					}
				} else if (workQuantityReportTypeBlock.type === ReportTypeBlockType.TOTAL) {
					const definitionFieldsInOtherSegments = values.definitionFields.reduce<BillableWorkDropdownOption[]>((_acc, _df) => {
						if (!_df.reportBlockFieldVirtualId) {
							return _acc;
						}
						const dropdownOption = billableWorkFieldsByIdMap[_df.reportBlockFieldVirtualId];
						const reportTypeBlock = reportTypeBlocksByIdMap[dropdownOption.reportTypeBlockVirtualId];
						if (reportTypeBlock.type === ReportTypeBlockType.BLOCK) {
							_acc.push(dropdownOption);
						}
						return _acc;
					}, []);

					if (definitionFieldsInOtherSegments.length) {
						const definitionFieldsFormatted = definitionFieldsInOtherSegments.map((_df) => _df.name).join(', ');
						errors.workQuantityReportBlockFieldVirtualId = `Work Quantity must also be in total block because of the following definition fields: ${definitionFieldsFormatted}.`;
					}
				}
			}

			if (!errors.workQuantityReportBlockFieldVirtualId && values.workQuantityReportBlockFieldVirtualId) {
				const workQuantityReportTypeBlock =
					reportTypeBlocksByIdMap[billableWorkFieldsByIdMap[values.workQuantityReportBlockFieldVirtualId].reportTypeBlockVirtualId];

				const definitionFieldInRepeatableBlock = values.definitionFields.find((_df) => {
					if (!_df.reportBlockFieldVirtualId) {
						return false;
					}
					const dropdownOption = billableWorkFieldsByIdMap[_df.reportBlockFieldVirtualId];
					const reportTypeBlock = reportTypeBlocksByIdMap[dropdownOption.reportTypeBlockVirtualId];
					const reportBlock = reportBlocksByIdMap[reportTypeBlock.reportBlockVirtualId];

					return reportBlock.isRepeating && reportTypeBlock.reportBlockId !== workQuantityReportTypeBlock.reportBlockId;
				}, []);

				if (definitionFieldInRepeatableBlock) {
					const definitionFieldName = billableWorkFieldsByIdMap[definitionFieldInRepeatableBlock.reportBlockFieldVirtualId ?? ''].name ?? '';
					errors.workQuantityReportBlockFieldVirtualId = `The Definition Field '${definitionFieldName}' is in a repeatable block, Work Quantity must be from the same block.`;
				}
			}
		}
		if (values.informationFields?.length) {
			const informationFieldErrors = values.informationFields.map(
				(_field) => BillableWorkInformationFieldFormModel.validate(_field, values.workQuantityReportBlockFieldVirtualId, billableWorkFieldsByIdMap)
			);
			if (informationFieldErrors.some((_error) => Object.keys(_error).length)) {
				errors.informationFields = informationFieldErrors;
			}

			if (!errors.workQuantityReportBlockFieldVirtualId && values.workQuantityReportBlockFieldVirtualId) {
				const workQuantityReportTypeBlock =
					reportTypeBlocksByIdMap[billableWorkFieldsByIdMap[values.workQuantityReportBlockFieldVirtualId].reportTypeBlockVirtualId];
				const workQuantityReportBlock = reportBlocksByIdMap[workQuantityReportTypeBlock.reportBlockVirtualId];
				if (!isSecondaryNonMainBlock(workQuantityReportTypeBlock.isPrimary, workQuantityReportBlock.isMain)) {
					const definitionFieldsInSecondaryNonMainBlocks = values.informationFields.reduce<BillableWorkDropdownOption[]>((_acc, _df) => {
						if (!_df.reportBlockFieldVirtualId) {
							return _acc;
						}
						const dropdownOption = billableWorkFieldsByIdMap[_df.reportBlockFieldVirtualId];
						const reportTypeBlock = reportTypeBlocksByIdMap[dropdownOption.reportTypeBlockVirtualId];
						const reportBlock = reportBlocksByIdMap[reportTypeBlock.reportBlockVirtualId];
						if (isSecondaryNonMainBlock(reportTypeBlock.isPrimary, reportBlock.isMain)) {
							_acc.push(dropdownOption);
						}
						return _acc;
					}, []);

					if (definitionFieldsInSecondaryNonMainBlocks.length) {
						const informationFieldsFormatted = definitionFieldsInSecondaryNonMainBlocks.map((_df) => _df.name).join(', ');
						errors.workQuantityReportBlockFieldVirtualId = `Work Quantity must also be in a secondary non-main block because of the following information fields: ${informationFieldsFormatted}.`;
					}
				}
			}

			if (!errors.workQuantityReportBlockFieldVirtualId && values.workQuantityReportBlockFieldVirtualId) {
				const workQuantityReportTypeBlock =
					reportTypeBlocksByIdMap[billableWorkFieldsByIdMap[values.workQuantityReportBlockFieldVirtualId].reportTypeBlockVirtualId];

				const informationFieldInRepeatableBlock = values.informationFields.find((_df) => {
					if (!_df.reportBlockFieldVirtualId) {
						return false;
					}
					const dropdownOption = billableWorkFieldsByIdMap[_df.reportBlockFieldVirtualId];
					const reportTypeBlock = reportTypeBlocksByIdMap[dropdownOption.reportTypeBlockVirtualId];
					const reportBlock = reportBlocksByIdMap[reportTypeBlock.reportBlockVirtualId];

					return reportBlock.isRepeating && reportTypeBlock.reportBlockId !== workQuantityReportTypeBlock.reportBlockId;
				}, []);

				if (informationFieldInRepeatableBlock?.reportBlockFieldVirtualId) {
					const informationFieldName = billableWorkFieldsByIdMap[informationFieldInRepeatableBlock.reportBlockFieldVirtualId].name;
					errors.workQuantityReportBlockFieldVirtualId = `The Information Field '${informationFieldName}' is in a repeatable block, Work Quantity must be from the same block.`;
				}
			}
		}

		return errors as FormErrors<BillableWorkFormModel>; // FIXME: Casting not entirely accurate
	}
}

export default BillableWorkFormModel;
