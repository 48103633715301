import * as React from 'react';
import type { FormSubmitHandler, InjectedFormProps } from 'redux-form';
import { Form, Field, reduxForm } from 'redux-form';
import { Row, Col } from 'react-bootstrap';

import CountryCode from 'acceligent-shared/enums/countryCode';

import type * as UserRequestModel from 'ab-requestModels/users.requestModel';

import SubmitButton from 'af-components/SubmitButton';

import Input from 'af-fields/Input';

import { phoneNormalizer } from 'ab-utils/reduxForms.util';

import { USER_PHONE_LOGIN } from 'af-constants/reduxForms';

import { phoneValidate } from './validations';

interface OwnProps {
	onSubmit: FormSubmitHandler;
}

type Props = OwnProps & InjectedFormProps<UserRequestModel.UserPhoneCredentials, OwnProps>;

class PhoneNumberForm extends React.PureComponent<Props> {
	render() {
		const { onSubmit, handleSubmit, submitting } = this.props;

		return (
			<Form onSubmit={handleSubmit(onSubmit)}>
				<Row>
					<Col sm={24}>
						<Field
							component={Input}
							id="phoneNumber"
							label="Mobile phone"
							name="phoneNumber"
							normalize={phoneNormalizer}
							type="text"
						/>
					</Col>
				</Row>
				<Row className="row--padded">
					<Col sm={24}>
						<SubmitButton
							className="btn--full"
							label="Log in"
							reduxFormSubmitting={submitting}
						/>
					</Col>
				</Row>
			</Form>
		);
	}
}

export default reduxForm<UserRequestModel.UserPhoneCredentials, OwnProps>({
	form: USER_PHONE_LOGIN,
	validate: phoneValidate,
	initialValues: {
		countryCode: CountryCode.US,
	},
})(PhoneNumberForm);
