import * as React from 'react';
import * as moment from 'moment';
import { nanoid } from 'nanoid';

import type TimePeriodRecurrence from 'acceligent-shared/enums/timePeriodRecurrence';

import * as TimeUtils from 'acceligent-shared/utils/time';

import * as SettingsKeys from 'af-constants/settingsKeys';

import BrowserStorageEnum from 'ab-enums/browserStorage.enum';
import ScheduleBoardSortType from 'ab-enums/scheduleBoardSortType.enum';

import { UNIQUE_ID_SIZE } from 'ab-common/constants/value';

interface SettingsOption {
	key: string;
	mappedName: string;
	// eslint-disable-next-line @typescript-eslint/no-explicit-any
	defaultValue: number | string | boolean | Date | moment.Moment | any[] | null | undefined;
	normalize?: (item: unknown) => unknown;
	source?: BrowserStorageEnum;
}

type GetOptions<P> = (props: P) => SettingsOption[];

const defaultNormalizer = (item) => item;

export const getItem = (
	key: string,
	sourceStorage: BrowserStorageEnum | undefined = BrowserStorageEnum.LOCAL_STORAGE
): Nullable<string> => {
	switch (sourceStorage) {
		case BrowserStorageEnum.SESSION_STORAGE:
			return sessionStorage.getItem(key);
		default:
			return localStorage.getItem(key);
	}
};

export const setItem = (
	key: string,
	value: string,
	sourceStorage: BrowserStorageEnum = BrowserStorageEnum.LOCAL_STORAGE
): void => {
	switch (sourceStorage) {
		case BrowserStorageEnum.SESSION_STORAGE:
			return sessionStorage.setItem(key, value);
		default:
			return localStorage.setItem(key, value);
	}
};

export const setItemWithFormatter = <T, K>(
	key: string,
	value: T,
	formatter: (value: T, format?: K) => string,
	sourceStorage: BrowserStorageEnum = BrowserStorageEnum.LOCAL_STORAGE,
	format?: K
): void => {
	const formattedValue = formatter(value, format);
	setItem(key, formattedValue, sourceStorage);
};

type MappedItem = ReturnType<NonNullable<SettingsOption['normalize']>>;

/** creates object with mappedName as property name and as item from localStorage with given key */
export function getMappedItems(options: SettingsOption[] = []): Record<string, MappedItem> {
	return options.reduce((sett: Record<string, MappedItem>, opt: SettingsOption) => {
		const {
			key,
			mappedName,
			normalize = defaultNormalizer,
			defaultValue,
			source,
		} = opt;
		sett[mappedName] = normalize(getItem(key, source)) || defaultValue;
		return sett;
	}, {});
}

/** FIXME: used in only one component, move definition there */
export function setDailyTipCollapsed(value: boolean): void {
	setItem(SettingsKeys.DAILY_TIP_SECTION_COLLAPSED(), `${value}`, BrowserStorageEnum.LOCAL_STORAGE);
}

/** FIXME: used in only one component, move definition there */
export function setScheduleBoardSort(sort: ScheduleBoardSortType = ScheduleBoardSortType.FREE_REORDERING): void {
	setItem(SettingsKeys.WORK_ORDER_SORT(), sort, BrowserStorageEnum.SESSION_STORAGE);
}

/** FIXME: used in only one component, move definition there */
export function setDeliverableDataActiveTabId(activeTabId: number): void {
	setItem(SettingsKeys.DELIVERABLE_RESOURCE_ACTIVE_TAB_ID(), activeTabId.toString(), BrowserStorageEnum.LOCAL_STORAGE);
}

/** FIXME: used in only one component, move definition there */
export function setDeliverablesPeriod(period: TimePeriodRecurrence): void {
	setItem(SettingsKeys.DELIVERABLES_PERIOD(), period, BrowserStorageEnum.LOCAL_STORAGE);
}

/** FIXME: used in only one component, move definition there */
export function setDeliverablesStartDate(date: Date = new Date()): void {
	setItemWithFormatter(SettingsKeys.DELIVERABLES_START_DATE(), date, TimeUtils.formatDate, BrowserStorageEnum.LOCAL_STORAGE);
}

/** FIXME: used in only one component, move definition there */
export function setDeliverablesEndDate(date: Date = new Date()): void {
	setItemWithFormatter(SettingsKeys.DELIVERABLES_END_DATE(), date, TimeUtils.formatDate, BrowserStorageEnum.LOCAL_STORAGE);
}

/** FIXME: used in only one component, move definition there */
export function setPayrollReportStartDate(date: Date = new Date()): void {
	setItemWithFormatter(SettingsKeys.PAYROLL_REPORT_START_DATE(), date, TimeUtils.formatDate, BrowserStorageEnum.LOCAL_STORAGE);
}

/** FIXME: used in only one component, move definition there */
export function setPayrollReportEndDate(date: Date = new Date()): void {
	setItemWithFormatter(SettingsKeys.PAYROLL_REPORT_END_DATE(), date, TimeUtils.formatDate, BrowserStorageEnum.LOCAL_STORAGE);
}

/** FIXME: used in only one component, move definition there */
export function setPayrollReportPeriod(period: TimePeriodRecurrence): void {
	setItem(SettingsKeys.PAYROLL_REPORT_PERIOD(), period, BrowserStorageEnum.LOCAL_STORAGE);
}

/** FIXME: used in only one component, move definition there */
export function setWorkOrderReportsStartDate(date: Date = new Date()): void {
	setItemWithFormatter(SettingsKeys.WORK_ORDERS_REPORTS_START_DATE(), date, TimeUtils.formatDate, BrowserStorageEnum.LOCAL_STORAGE);
}

/** FIXME: used in only one component, move definition there */
export function setWorkOrderReportsEndDate(date: Date = new Date()): void {
	setItemWithFormatter(SettingsKeys.WORK_ORDERS_REPORTS_END_DATE(), date, TimeUtils.formatDate, BrowserStorageEnum.LOCAL_STORAGE);
}

/** FIXME: used in only one component, move definition there */
export function setWorkOrderReportsPeriod(period: TimePeriodRecurrence): void {
	setItem(SettingsKeys.WORK_ORDERS_REPORTS_PERIOD(), period, BrowserStorageEnum.LOCAL_STORAGE);
}

/** FIXME: remove moment usage */
export function setWorkOrderSelectedDueDate(defaultDueDate: moment.Moment = moment(Date.now())): void {
	setItem(SettingsKeys.WORK_ORDER_SELECTED_DUE_DATE(), defaultDueDate.format(), BrowserStorageEnum.SESSION_STORAGE);
}

/** FIXME: remove moment usage */
export function setWeeklyViewStartDueDate(defaultDueDate: moment.Moment = moment(Date.now())): void {
	setItem(SettingsKeys.WEEKLY_VIEW_START_DUE_DATE(), defaultDueDate.format(), BrowserStorageEnum.SESSION_STORAGE);
}

/** FIXME: remove moment usage */
export function setWeeklyViewEndDueDate(defaultDueDate: moment.Moment = moment(Date.now())): void {
	setItem(SettingsKeys.WEEKLY_VIEW_END_DUE_DATE(), defaultDueDate.format(), BrowserStorageEnum.SESSION_STORAGE);
}

export function setExpandedDeliverableIds(
	deliverableId: Nullable<string>,
	deliverableSubmissionId?: string,
	deliverableAssignmentId?: string
): void {
	setItem(SettingsKeys.EXPANDED_DELIVERABLE_ID(), `${deliverableId}`, BrowserStorageEnum.SESSION_STORAGE);

	if (deliverableSubmissionId) {
		setItem(SettingsKeys.EXPANDED_DELIVERABLE_SUBMISSION_ID(), deliverableSubmissionId, BrowserStorageEnum.SESSION_STORAGE);
	}

	if (deliverableAssignmentId) {
		setItem(SettingsKeys.EXPANDED_DELIVERABLE_ASSIGNMENT_ID(), deliverableAssignmentId, BrowserStorageEnum.SESSION_STORAGE);
	}
}

/** FIXME: used in only one component, move definition there */
export function setExpandedWorkOrderId(workOrderId: string): void {
	setItem(SettingsKeys.EXPANDED_WORK_ORDER_ID(), workOrderId, BrowserStorageEnum.LOCAL_STORAGE);
}

export function getWorkOrderSelectedDueDate(): Nullable<string> {
	return getItem(SettingsKeys.WORK_ORDER_SELECTED_DUE_DATE(), BrowserStorageEnum.SESSION_STORAGE);
}

export function setToastAsSeen(toastId: string): void {
	const allSeenToasts = JSON.parse(getItem(SettingsKeys.TOAST()) ?? '{}') || {};
	setItem(SettingsKeys.TOAST(), JSON.stringify({ ...allSeenToasts, [toastId]: true }));
}

export function getCustomerSupportUniqueId(): string {
	let customerSupportUniqueId = getItem(SettingsKeys.CUSTOMER_SUPPORT_UNIQUE_ID(), BrowserStorageEnum.LOCAL_STORAGE);
	if (!customerSupportUniqueId) {
		customerSupportUniqueId = `${nanoid(UNIQUE_ID_SIZE)}${nanoid(UNIQUE_ID_SIZE)}${nanoid(UNIQUE_ID_SIZE)}${nanoid(UNIQUE_ID_SIZE)}`;
		setItem(SettingsKeys.CUSTOMER_SUPPORT_UNIQUE_ID(), customerSupportUniqueId, BrowserStorageEnum.LOCAL_STORAGE);
	}
	return customerSupportUniqueId;
}

/** HOC which injects props from storage to element */
export function withSettings<P>(getOptions: GetOptions<P>) {
	return <TComponent extends React.ComponentClass<P>>(WrappedComponent: TComponent) => {
		return class extends React.Component<P> {
			constructor(props: P) {
				super(props);
				this.state = getMappedItems(getOptions(props));
			}

			render() {
				// eslint-disable-next-line @typescript-eslint/no-explicit-any
				const InnerComponent = WrappedComponent as any;
				return <InnerComponent {...this.props} {...this.state} />;
			}
		} as TComponent;
	};
}
