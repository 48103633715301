import * as React from 'react';

import type { AddressModel } from 'ab-viewModels/scheduleBoardWorkOrderModal.viewModel';

import Tooltip from 'af-components/Tooltip';

import { generateWorkLocationAddressStringIdentifier } from 'af-utils/workOrderModal.util';

import Address from './Address';

interface OwnProps {
	listOfAddresses: AddressModel[];
	removedAddressesDictionary?: { [ addressStringIdentifier: string]: boolean; };
}

type Props = OwnProps;

class AddressList extends React.PureComponent<Props> {
	static defaultProps: Partial<Props> = {
		listOfAddresses: [],
		removedAddressesDictionary: {},
	};

	render() {
		const { listOfAddresses, removedAddressesDictionary } = this.props;

		return (
			listOfAddresses.map((_address, _index) => {
				const removed = removedAddressesDictionary?.[generateWorkLocationAddressStringIdentifier(_address)];

				return (
					removed ? (
						<Tooltip key={`workLocationTooltip#${_index}`} message="Removed" placement="right">
							<div> {/* empty div is necessary for tooltip to work properly */}
								<Address
									address={_address}
									isRemoved={removed}
								/>
							</div>
						</Tooltip>
					) : (
						<Address
							address={_address}
							key={`address-${_index}`}
						/>
					)
				);
			})
		);
	}
}

export default AddressList;
