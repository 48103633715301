import type { ColorPalette } from 'acceligent-shared/enums/color';
import type AccountPermissionTemplate from 'acceligent-shared/enums/accountPermissionTemplate';
import SubscriptionStatus from 'acceligent-shared/enums/subscriptionStatus';

import type EmployeeBase from 'ab-domain/models/employee/base';
import type SkillBase from 'ab-domain/models/skill/base';

import * as UserUtils from 'ab-utils/user.util';

const CSV_REQUIRED_KEYS = [
	'ID',
	'fullName',
	'userType',
	'laborType',
	'wageClassification',
	'homeOffice',
	'SMSSubscribedStatus',
	'phoneNumber',
	'email',
];

const COMPANY_CSV_REQUIRED_KEYS = [
	...CSV_REQUIRED_KEYS,
	'showOnScheduleBoard',
	'skill.0.name',
	'skill.0.color',
];

const ORGANIZATION_CSV_REQUIRED_KEYS = [
	...CSV_REQUIRED_KEYS,
	'company',
];

class SkillVM {
	id: number;
	name: string;
	color: ColorPalette;

	constructor(skill: SkillBase) {
		this.id = skill.id;
		this.name = skill.name;
		this.color = skill.color;
	}

	private static _constructorMap(skill: SkillBase) {
		return new SkillVM(skill);
	}

	static bulkConstructor(skills: SkillBase[]) {
		return skills.map(SkillVM._constructorMap);
	}
}

const EMPTY_SKILL = {
	id: null,
	name: '',
	color: null,
};

class EmployeeVM {
	employeeId: number;
	accountId: number;
	userId: number;
	wageRateId: number;
	locationId: Nullable<number>;

	uniqueId: string;
	fullName: string;
	accountTemplate: AccountPermissionTemplate;
	wageRateType: string;
	wageRateClassification: string;
	locationNickname: string;
	showOnScheduleBoard: boolean;
	subscriptionStatus: SubscriptionStatus;
	phoneNumber: string;
	email: string;

	skills: SkillVM[];
	companyName: string;

	constructor(employee: EmployeeBase) {
		const accountProxy = employee.account;
		const userProxy = accountProxy.user;

		this.employeeId = employee.id;
		this.accountId = employee.accountId;
		this.userId = accountProxy.userId;
		this.wageRateId = employee.wageRateId;
		this.locationId = accountProxy.locationId;

		this.uniqueId = userProxy.uniqueId ?? '';
		this.fullName = UserUtils.getFormattedFullName(userProxy.firstName, userProxy.lastName);
		this.accountTemplate = accountProxy.accountTemplate;
		this.wageRateType = employee.wageRate.type ?? '';
		this.wageRateClassification = employee.wageRate.wageClassification ?? '';
		this.locationNickname = accountProxy.location?.nickname ?? '';
		this.showOnScheduleBoard = employee.showOnScheduleBoard;
		this.subscriptionStatus = userProxy.subscriptionStatus ?? SubscriptionStatus.SUBSCRIBED;
		this.phoneNumber = userProxy.phoneNumber ?? '';
		this.email = userProxy.email ?? '';

		this.skills = SkillVM.bulkConstructor(employee.skills ?? []);
		this.companyName = employee.company.name ?? '';
	}
}

export class EmployeeListViewModel {
	list: EmployeeVM[];

	constructor(employees: EmployeeBase[]) {
		this.list = employees.map((_employee: EmployeeBase) => new EmployeeVM(_employee));
	}

	static toCSVData(viewModel: EmployeeListViewModel, isOrganizationLevel: boolean = false): string[][] {
		let maxNumberOfSkills = 1;	// if skills are able to be shown on export, the first group of columns should always be visible
		const header: string[] = isOrganizationLevel ? [...ORGANIZATION_CSV_REQUIRED_KEYS] : [...COMPANY_CSV_REQUIRED_KEYS];

		if (!isOrganizationLevel) {
			viewModel.list.forEach((_item: EmployeeVM) => {
				if (_item.skills.length > maxNumberOfSkills) {
					for (let _index = maxNumberOfSkills; _index < _item.skills.length; _index++) {
						header.push(`skill.${_index}.name`, `skill.${_index}.color`);
					}
					maxNumberOfSkills = _item.skills.length;
				}
			});
		}

		const rows: string[][] = viewModel.list.map((_item: EmployeeVM) => {
			const _row: string[] = [
				_item.uniqueId,
				_item.fullName,
				_item.accountTemplate,
				_item.wageRateType,
				_item.wageRateClassification,
				_item.locationNickname,
				_item.subscriptionStatus,
				_item.phoneNumber,
				_item.email,
			];
			if (isOrganizationLevel) {
				_row.push(_item.companyName);
			} else {
				_row.push(_item.showOnScheduleBoard ? 'Yes' : 'No');
				for (let _index = 0; _index < maxNumberOfSkills; _index++) {
					const _skill = _item.skills[_index] || EMPTY_SKILL;
					_row.push(_skill.name, _skill.color);
				}
			}
			return _row;
		});

		return [header, ...rows];
	}
}
