import * as React from 'react';
import type { ConnectedProps } from 'react-redux';
import { connect } from 'react-redux';
import { useLocation, useHistory } from 'react-router';

import type { RootState } from 'af-reducers';

import ToolStatus, { ToolStatusLabel } from 'acceligent-shared/enums/toolStatus';
import ToolState, { ToolStateLabel } from 'acceligent-shared/enums/toolState';

import * as ToolActions from 'af-actions/tool';

import type ToolTableViewModel from 'ab-viewModels/tool/table.viewModel';

import { isAllowed } from 'ab-utils/auth.util';

import CLIENT from 'af-constants/routes/client';

import TableNameEnum from 'ab-enums/tableName.enum';
import TableButtonType from 'ab-enums/tableButtonType.enum';
import PagePermissions from 'ab-enums/pagePermissions.enum';

import type { TabProps, Column, ButtonData, ItemBlueprint } from 'af-components/Table6';
import Table from 'af-components/Table6';
import StatusLabel from 'af-components/StatusLabel';

import type { TypedCellInfo } from 'af-root/scenes/Company/ScheduleBoard/Shared/OrderInfoModal/DirectoriesAndAttachments';

import styles from './styles.module.scss';

type Props = ConnectedProps<typeof connector>;

const ToolList = (props: Props) => {
	const {
		deleteTool,
		findAll,
		companyName,
		changeToolState,
		hasManageSmallToolsPermission,
		bulkDelete,
	} = props;

	const history = useHistory();
	const location = useLocation<{ orgAlias: string; }>();

	const { orgAlias } = location.state;

	const renderStatusCell = React.useCallback(({ original }: TypedCellInfo<ToolTableViewModel>) => {
		const status = original.status;

		return (
			<div className={styles.value}>
				<StatusLabel
					isAvailable={status === ToolStatus.AVAILABLE}
					label={ToolStatusLabel[status]}
				/>
			</div>
		);
	}, []);

	const renderStateCell = React.useCallback(({ original }: TypedCellInfo<ToolTableViewModel>) => {
		const state = original.state;

		return (
			<div className={styles.value}>
				<StatusLabel
					grey={state === ToolState.INACTIVE}
					isAvailable={state === ToolState.ACTIVE}
					label={ToolStateLabel[state]}
				/>
			</div>
		);
	}, []);

	const COLUMNS: Column<ToolTableViewModel>[] = [
		{
			Header: 'Serial number',
			accessor: 'serialNumber',
			Cell: ({ original }) => original.serialNumber,
		},
		{
			Header: 'Model',
			accessor: 'modelNumber',
			Cell: ({ original }) => original.modelNumber,
		},
		{
			Header: 'Manufacturer',
			accessor: 'manufacturer',
			Cell: ({ original }) => original.manufacturer,
		},
		{
			Header: 'Type',
			accessor: 'toolTypeId',
			Cell: ({ original }) => original.toolType.name,
		},
		{
			Header: 'Home Location',
			accessor: 'locationId',
			Cell: ({ original }) => original.location.name,
		},
		{
			Header: 'Current Location',
			accessor: 'currentLocation',
			Cell: ({ original }) => original.currentLocation,
			sortable: false,
		},
		{
			Header: 'Status',
			accessor: 'state',
			Cell: renderStateCell,
		},
		{
			Header: 'Repair Status',
			accessor: 'status',
			Cell: renderStatusCell,
		},
	];

	const deleteToolModalTitle = () => 'Delete Tool?';
	const deleteToolModalBody = (original: ToolTableViewModel) => `Are you sure you want to delete Tool (${original.serialNumber})?`;
	const deleteToolModalText = () => 'Delete Tool';

	const removeTool = async (original: ToolTableViewModel) => {

		await deleteTool(original.id);
	};

	const editTool = async (original: ToolTableViewModel) => {
		history.push(CLIENT.COMPANY.EQUIPMENT.TOOL.EDIT(original.id.toString(), orgAlias, companyName));
	};

	const previewTool = async (original: ToolTableViewModel) => {
		history.push(CLIENT.COMPANY.EQUIPMENT.TOOL.PREVIEW(original.id.toString(), orgAlias, companyName));
	};

	const onRowClick = ({ original }: { original: ToolTableViewModel; }) => {
		if (original.id) {
			previewTool(original);
		}
	};

	const changeStatus = async (original: ToolTableViewModel) => {
		const nextState = original.state === ToolState.ACTIVE ? ToolState.INACTIVE : ToolState.ACTIVE;
		await changeToolState(original.id, nextState);
	};

	const tabs = (): TabProps<ToolTableViewModel>[] => {
		const buttons: ButtonData[] = hasManageSmallToolsPermission ? [
			{
				type: TableButtonType.PRIMARY,
				hasPermission: true,
				label: 'New Small Tool',
				icon: 'plus',
				onClick: async () => history.push(CLIENT.COMPANY.EQUIPMENT.TOOL.CREATE(orgAlias, companyName)),
			},
		] : [];

		const rowActions: ItemBlueprint<ToolTableViewModel>[] = [
			{
				label: 'Preview',
				action: previewTool,
				shouldRefresh: false,
			},
		];

		if (hasManageSmallToolsPermission) {
			rowActions.push(...[
				{
					label: 'Edit',
					action: editTool,
					shouldRefresh: false,

				},
				{
					label: 'Delete',
					action: removeTool,
					hasModal: true,
					modalTitle: deleteToolModalTitle,
					modalBody: deleteToolModalBody,
					modalText: deleteToolModalText,
					shouldRefresh: true,
				},
				{
					label: (_tool) => _tool!.state === ToolState.INACTIVE ? 'Set as Active' : 'Set as Inactive',
					action: changeStatus,
					shouldRefresh: true,
				},
			]);
		}

		return [
			{
				label: 'Small Tool',
				columns: COLUMNS,
				selectable: hasManageSmallToolsPermission,
				hasSearchInput: true,
				searchLabel: 'Small tool',
				bulkDelete: hasManageSmallToolsPermission ? bulkDelete : undefined,
				fetch: findAll,
				buttons,
				onRowClick: onRowClick,
				rowActions,
			},
		];
	};

	return (
		<Table
			tableName={TableNameEnum.TOOL}
			tabs={tabs()}
		/>
	);
};

function mapStateToProps(state: RootState) {
	const { userData, companyData } = state.user;
	if (!userData || !companyData) {
		throw new Error('User not logged in');
	}

	const hasManageSmallToolsPermission: boolean = isAllowed(
		PagePermissions.COMPANY.EQUIPMENT.SMALL_TOOLS.MANAGE,
		companyData.permissions,
		companyData.isCompanyAdmin,
		userData.role
	);

	return {
		companyName: companyData.name,
		hasManageSmallToolsPermission,
	};
}

function mapDispatchToProps() {
	return {
		findAll: ToolActions.findAllForTable,
		deleteTool: ToolActions.deleteTool,
		changeToolState: ToolActions.changeToolState,
		bulkDelete: ToolActions.bulkDeleteTools,
	};
}

const connector = connect(mapStateToProps, mapDispatchToProps());

export default connector(ToolList);
