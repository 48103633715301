import * as React from 'react';
import { type FormatOptionLabelMeta } from 'react-select';
import AsyncSelect from 'react-select/async';
import AsyncCreateableSelect from 'react-select/async-creatable';
import type { SelectComponentsConfig } from 'react-select/src/components';
import type { ValueType, OptionsType, OptionTypeBase, Theme } from 'react-select/src/types';
import type { StylesConfig } from 'react-select/src/styles';
import type { WrappedFieldMetaProps } from 'redux-form';
import type {
	getOptionLabel as getOptionLabelType,
	getOptionValue as getOptionValueType,
} from 'react-select/src/builtins';

import Label from 'af-components/LockedValue/Label';

export type DefaultOptionType = {
	label: string;
	value: number | null | undefined;
};

interface Props<OptionType extends OptionTypeBase = DefaultOptionType> {
	placeholder?: string;
	className?: string;
	isClearable?: boolean;
	allowNew?: boolean;
	value: ValueType<OptionType, boolean>;
	label?: string;
	disabled?: boolean;
	fixed?: boolean;
	menuStyle?: React.CSSProperties;
	getOptionLabel?: getOptionLabelType<OptionType>;
	getOptionValue?: getOptionValueType<OptionType>;
	onSearch: (
		inputValue: string,
		callback: Nullable<(options: OptionsType<OptionType>) => void>
	) => Promise<OptionType[]>;
	onClear?: (selectedOption: ValueType<OptionType, boolean>) => Promise<void> | void;
	onValueChange: (selectedOption: ValueType<OptionType, boolean>) => Promise<void> | void;
	onBlur?: (selectedOption: ValueType<OptionType, boolean>) => Promise<void> | void;
	onCreateNew?: (option: string) => Promise<void> | void;
	formatOptionLabel?: (option: OptionType, labelMeta: FormatOptionLabelMeta<OptionType, boolean>) => React.ReactNode;
	isValidNewOption?: (inputValue: string, value: OptionType, options: OptionType[]) => boolean;
	meta?: WrappedFieldMetaProps;
}

const COLOR_ORANGE_BORDER = '#FFA726';
const COLOR_PRIMARY_25 = '#FEF4E9';
const COLOR_WHITE = '#FFFFFF';

const BORDER_RADIUS = 2;
const BOX_SHADOW = '0 !important';

const BASE_SPACING = 5;

const customStyles: StylesConfig<OptionTypeBase, boolean> = {
	control: (base) => {
		return {
			...base,
			boxShadow: BOX_SHADOW,
			'&:hover': {
				borderColor: COLOR_ORANGE_BORDER,
			},
		};
	},
	menu: (base, state) => {
		return {
			...base,
			...state.selectProps.menuStyle,
		};
	},
};

const theme = {
	borderRadius: BORDER_RADIUS,
	colors: {
		primary25: COLOR_PRIMARY_25,
		primary: COLOR_ORANGE_BORDER,
		natural: COLOR_PRIMARY_25,
		neutral0: COLOR_WHITE,
	},
	spacing: {
		baseUnit: BASE_SPACING,
		controlHeight: 0,
		menuGutter: 0,
	},
} as Theme;

const components: SelectComponentsConfig<OptionTypeBase, false> = {
	IndicatorSeparator: null,
};

const formatCreateLabel = (input: string) => `Create: ${input}`;

const SelectField = <OptionType extends OptionTypeBase = DefaultOptionType>(props: Props<OptionType>): JSX.Element => {
	const {
		placeholder,
		className,
		isClearable = true,
		allowNew = false,
		value,
		label,
		disabled = false,
		fixed,
		menuStyle,
		getOptionLabel,
		getOptionValue,
		onSearch,
		onClear,
		onValueChange,
		onBlur,
		onCreateNew,
		formatOptionLabel: _formatOptionLabel,
		isValidNewOption,
		meta,
	} = props;

	const [isLoading, setIsLoading] = React.useState(false);
	const [optionsLoaded, setOptionsLoaded] = React.useState(false);
	const [defaultOptions, setDefaultOptions] = React.useState<OptionType[] | null>(null);
	const [options, setOptions] = React.useState<OptionType[]>([]);

	const onChange = React.useCallback((item: ValueType<OptionType, boolean>, { action }) => {
		switch (action) {
			case 'clear':
				if (onClear) {
					onClear(item);
				}
				break;
			case 'select-option':
				if (onValueChange) {
					onValueChange(item);
				}
				break;
		}
	}, [onClear, onValueChange]);

	const blurAction = React.useCallback(() => {
		if (onBlur) {
			onBlur(value);
		}
	}, [onBlur, value]);

	const formatOptionLabel = React.useCallback((option: OptionType & { __isNew__?: boolean; }, labelMeta: FormatOptionLabelMeta<OptionType, boolean>) => {

		if (option.__isNew__) {
			return <span>{formatCreateLabel(labelMeta.inputValue)}</span>;
		} else {
			if (_formatOptionLabel) {
				return _formatOptionLabel(option, labelMeta);
			}
		}
	}, [_formatOptionLabel]);

	const handleFocus = React.useCallback(async () => {
		if (!optionsLoaded) {
			setIsLoading(true);
			const _defaultOptions = await onSearch('', null);
			setIsLoading(false);
			setDefaultOptions(_defaultOptions);
			setOptionsLoaded(true);
			setOptions(_defaultOptions);
		}
	}, [onSearch, optionsLoaded]);

	const loadOptions = React.useCallback(async (inputValue: string, callback) => {
		const results = await onSearch(inputValue, callback);
		setOptions(results);
		return results;
	}, [onSearch]);

	const onCreateOption = React.useCallback(async (inputValue: string) => {
		const newOption = { label: inputValue, value: inputValue } as unknown as OptionType;
		if (onCreateNew) {
			await onCreateNew(inputValue);
		}
		setOptions((prevOptions) => [...prevOptions, newOption]);
		setDefaultOptions((prevOptions) => [...(prevOptions ?? []), newOption]);
	}, [onCreateNew]);

	const customClassName = React.useMemo(() => {
		let classname = 'react-select-input';
		classname = className ? `${classname} ${className}` : classname;
		classname = fixed ? `${classname} react-select-input--fixed` : classname;
		return classname;
	}, [className, fixed]);

	// Brute forcing the typing of AsyncSelect and AsyncCreateableSelect because it's not strongly typed
	const sharedProps = React.useMemo(() => ({
		theme: meta?.touched && !!meta?.error ? {
			...theme, colors: {
				...theme.colors,
				neutral0: '#FDEDED',
				neutral50: 'transparent',
			},
		} : theme,
		styles: customStyles,
		className: customClassName,
		classNamePrefix: className,
		components,
		getOptionLabel,
		getOptionValue,

		isClearable,
		disabled,

		placeholder,
		value,
		onChange: onChange,
		onBlur: blurAction,
		onFocus: handleFocus,

		formatOptionLabel: formatOptionLabel,
		loadOptions: loadOptions,
		options,

		defaultOptions: defaultOptions!,
		isLoading,
		menuStyle: meta?.touched && !!meta?.error ? {
			...menuStyle,
			backgroundColor: '#FFFFFF',
		} : menuStyle,
	}), [
		blurAction,
		className,
		customClassName,
		defaultOptions,
		disabled,
		formatOptionLabel,
		getOptionLabel,
		getOptionValue,
		handleFocus,
		isClearable,
		isLoading,
		loadOptions,
		menuStyle,
		meta?.error,
		meta?.touched,
		onChange,
		options,
		placeholder,
		value,
	]);

	const creatableProps = {
		onCreateOption: onCreateOption,
		isValidNewOption: isValidNewOption,
	};

	return (
		<div className="async-select-input">
			<Label
				label={label}
				withMargin={true}
			/>
			{allowNew
				? <AsyncCreateableSelect {...sharedProps} {...creatableProps} />
				: <AsyncSelect {...sharedProps} />}
			{!!meta?.error && meta?.touched &&
				(
					<span className="help-block"><span className="icon-info" /> {meta.error}</span>
				)
			}
		</div>
	);
};

export default React.memo(SelectField);
