import * as React from 'react';
import type { ResolveThunks} from 'react-redux';
import { connect } from 'react-redux';

import * as KnowledgeCenterActions from 'af-actions/knowledgeCenter';

interface DispatchProps {
	findNotionResponse: typeof KnowledgeCenterActions.findNotionResponse;
}

type Props = ResolveThunks<DispatchProps>;

const KnowledgeCenter: React.FC<Props> = (props) => {
	const { findNotionResponse } = props;
	const [acs, setAcs] = React.useState<Nullable<string>>(null);
	const [samlResponse, setSamlResponse] = React.useState<Nullable<string>>(null);

	React.useEffect(() => {
		const load = async () => {
			const res = await findNotionResponse();
			setAcs(res.acs);
			setSamlResponse(res.SAMLResponse);
		};
		load();
	}, [findNotionResponse]);

	const login = React.useCallback(() => {
		document.forms[0].submit();
	}, []);

	return (
		<div className="form-segment">
			Hello
			{!!acs &&
				<>
					<form action={`${acs}`} autoComplete="off" id="sso" method="post">
						<input id="resp" name="SAMLResponse" type="hidden" value={`${samlResponse}`} />
					</form>
					<div onClick={login}>log in to notion</div>
				</>
			}
		</div>
	);
};

function mapDispatchToProps(): DispatchProps {
	return {
		findNotionResponse: KnowledgeCenterActions.findNotionResponse,
	};
}

export default connect<void, DispatchProps>(null, mapDispatchToProps())(KnowledgeCenter);
