import { isValidEmail } from 'acceligent-shared/utils/email';

import type { SpecialEmailsForm } from '../formModel';

const validateDomain = (email: string, acceptableDomains: string[]) => {
	const match = email.match(/^\w+@(\w+[.-]?\w+).com/);
	return match !== null && acceptableDomains.includes(match[1]);
};

export function validate(values: SpecialEmailsForm) {
	const errors: ValidationErrors = {};

	if (values.enableScheduleBoardEmail && !values.scheduleBoardEmailUserGroup?.id) {
		errors.scheduleBoardEmailUserGroup = 'User group is required';
	}

	if (values.enablePastWorkOrdersNotification && !values.pastWorkOrdersNotificationUserGroup?.id) {
		errors.pastWorkOrdersNotificationUserGroup = 'User group is required';
	}

	if (values.enableNotifyYesterdaysBoard && !values.notifyYesterdaysBoardUserGroup?.id) {
		errors.notifyYesterdaysBoardUserGroup = 'User group is required';
	}

	if (values.enableNotifyFailed && !values.notifyFailedUserGroup?.id) {
		errors.notifyFailedUserGroup = 'User group is required';
	}

	if (values.enableInvoiceRemindersNotification && !values.notifyInvoiceRemindersFrom) {
		errors.notifyInvoiceRemindersFrom = 'Email is required';
	} else if (values.enableInvoiceRemindersNotification && !!values.notifyInvoiceRemindersFrom) {
		if (!isValidEmail(values.notifyInvoiceRemindersFrom)) {
			errors.notifyInvoiceRemindersFrom = 'This is not valid email format.';
			return errors;
		}
		if (!validateDomain(values.notifyInvoiceRemindersFrom, ['acceligent', 'nwmcc-bos'])) {
			errors.notifyInvoiceRemindersFrom = 'This email domain is not whitelisted. Please contact Acceligent support team for assistance.';
		}
	}

	return errors;
}
