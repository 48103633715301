import baseRoute from '../baseRoute';

export * from './requestModel';
export * from './viewModel';

type RouteDefinition = `${typeof baseRoute}/login/phone/lms`;

export const ROUTE: RouteDefinition = `${baseRoute}/login/phone/lms`;

export const URL = (): RouteDefinition => `${baseRoute}/login/phone/lms`;
