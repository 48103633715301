import * as React from 'react';
import type { CustomRouteComponentProps } from 'react-router-dom';
import type { ConnectedProps } from 'react-redux';
import { connect } from 'react-redux';

import BlobStorageImageSizeContainer from 'acceligent-shared/enums/blobStorageImageSizeContainer';
import TimeFormatEnum from 'acceligent-shared/enums/timeFormat';
import MemberInviteEnum from 'acceligent-shared/enums/memberInvite';

import type { RootState } from 'af-reducers';

import * as AccountActions from 'af-actions/accounts';

import type { TableVM } from 'ab-viewModels/account/table.viewModel';

import { DEFAULT_EMPLOYEE_IMAGE } from 'ab-common/constants/value';

import CLIENT from 'af-constants/routes/client';

import TableNameEnum from 'ab-enums/tableName.enum';
import PagePermissions from 'ab-enums/pagePermissions.enum';
import TableButtonType from 'ab-enums/tableButtonType.enum';
import { AccountPermissionTemplateLabel } from 'ab-enums/accountPermissionTemplates.enum';

import DateCell from 'af-components/Table6/Cells/DateCell';
import type { TabProps, Column, ButtonData, ItemBlueprint } from 'af-components/Table6';
import Table from 'af-components/Table6';
import EmptyCell from 'af-components/Table6/Cells/EmptyCell';
import ImageTag from 'af-components/Image';
import Breadcrumbs from 'af-components/Breadcrumbs';
import StatusCell from 'af-components/Table6/Cells/StatusCell';

import { isAllowed } from 'ab-utils/auth.util';
import { formatPhoneNumber } from 'ab-utils/phone.util';
import { downloadCSV } from 'af-utils/csv.utils';

type OwnProps = CustomRouteComponentProps;

type Props = OwnProps & ConnectedProps<typeof connector>;

const IMAGE_COLUMN_WIDTH = 100;

class CompanyMembersList extends React.PureComponent<Props> {

	static readonly BREADCRUMBS = [{ label: 'Users' }];

	static readonly SHARED_COLUMNS: Column<TableVM>[] = [
		{
			accessor: 'user.imageUrl',
			sortable: false,
			width: IMAGE_COLUMN_WIDTH,
			className: 'rt-thumbnail',
			Cell: ({ original }) => (
				<ImageTag
					fallbackSrc={DEFAULT_EMPLOYEE_IMAGE}
					minSize={BlobStorageImageSizeContainer.SIZE_50X50}
					src={original.imageUrl}
					tryOriginal={true}
					tryRoot={true}
				/>
			),
		},
		{
			Header: 'Unique ID',
			accessor: 'uniqueId',
			className: 'text-strong',
			Cell: ({ original }) => original.uniqueId,
		},
		{
			Header: 'Full Name',
			accessor: 'fullName',
			className: 'text-strong',
			Cell: ({ original }) => original.fullName,
		},
		{
			Header: 'Email Address',
			accessor: 'email',
			Cell: ({ original }) => original.email ?? <EmptyCell />,
		},
		{
			Header: 'Mobile Phone',
			accessor: 'phoneNumber',
			Cell: ({ original }) => original.phoneNumber
				? formatPhoneNumber(original.phoneNumber, original.countryCode)
				: <EmptyCell />,
		},
		{
			Header: 'User Role',
			accessor: 'accountTemplate',
			Cell: ({ original }) => <span>{AccountPermissionTemplateLabel[original.accountTemplate]}</span>,
		},
		{
			Header: 'Home Office',
			accessor: 'location.nickname',
			Cell: ({ original }) => original.location ?? <EmptyCell />,
		},
		{
			Header: 'Last Login',
			accessor: 'lastLoginAt',
			Cell: ({ original }) => original.lastLoginAt
				? <DateCell date={original.lastLoginAt} format={TimeFormatEnum.DATE_ONLY} isLeftAligned={true} withTime={true} />
				: <EmptyCell />,
		},
	];

	static readonly COLUMN_LMS: Column<TableVM> =
		{
			Header: 'LMS access',
			accessor: 'activeInLMS',
			filterable: false,
			Cell: ({ original }) => {
				if (original.activeInLMS) {
					return (<span className="text-green">Yes</span>);
				}
				return (<span className="text-grey">No</span>);
			},
		};

	static readonly COLUMNS_FOR_DELETED: Column<TableVM>[] = [
		...CompanyMembersList.SHARED_COLUMNS,
		CompanyMembersList.COLUMN_LMS,
	];

	static readonly COLUMNS_FOR_ACTIVE: Column<TableVM>[] = [
		...CompanyMembersList.SHARED_COLUMNS,
		{
			Header: 'Status',
			accessor: 'status',
			filterable: false,
			Cell: ({ original }) => <StatusCell {...original}/>,
		},
		{
			Header: 'SMS Subscribed Status',
			accessor: 'subscriptionStatus',
			sortable: false,
			Cell: ({ original }) => original.subscriptionStatus,
		},
		CompanyMembersList.COLUMN_LMS,
	];

	static deleteAccountModalTitle = (original: TableVM) => `Are you sure you want to delete this User for company (${original.fullName})?`;
	static deleteAccountModalBody = () => <>This action cannot be undone.</>;
	static deleteAccountModalText = () => 'Delete User';

	deleteAccount = async (original: TableVM) => {
		const { deleteAccount } = this.props;
		await deleteAccount(original.id);
	};

	editAccount = async (original: TableVM) => {
		const { history, location: { state: { orgAlias } }, companyName } = this.props;
		history.push(CLIENT.COMPANY.SETTINGS.MEMBERS.EDIT(orgAlias, companyName, `${original.id}`));
	};

	static activateAccountModalTitle = (original: TableVM) => `Activate ${original.fullName}?`;
	static activateAccountModalBody = () => <>Activating this user will allow them to log into the platform, however, they will not be notified of this change.
		Do you want to proceed?</>;
	static activateAccountModalText = () => 'Confirm';

	activateAccount = async (original: TableVM) => {
		const { activateAccount } = this.props;
		await activateAccount(original.id);
	};

	resendEmailInvite = async (original: TableVM) => {
		const { resendEmailInvite, location: { state: { orgAlias } }, companyName, history } = this.props;
		await resendEmailInvite(original.id);
		history.push(CLIENT.COMPANY.SETTINGS.MEMBERS.TABLE(orgAlias, companyName));
	};

	resendPhoneInvite = async (original: TableVM) => {
		const { resendPhoneInvite, location: { state: { orgAlias } }, companyName, history } = this.props;
		await resendPhoneInvite(original.id);
		history.push(CLIENT.COMPANY.SETTINGS.MEMBERS.TABLE(orgAlias, companyName));
	};

	goToNotificationScreen = async (): Promise<void> => {
		const { history, location: { state: { orgAlias }, pathname }, companyName } = this.props;
		history.push(CLIENT.COMPANY.COMMUNICATION.NOTIFY_EMPLOYEES(orgAlias, companyName, undefined, pathname));
	};

	onDownloadActiveCSVClick = async () => {
		await this.onDownloadClick(false, 'users');
	};

	onDownloadDeactivatedCSVClick = async () => {
		await this.onDownloadClick(true, 'deactivated_users');
	};

	onDownloadClick = async (isDeleted: boolean, filename: string) => {
		const { findForCSV, companyName } = this.props;
		const members = await findForCSV(isDeleted);
		const data = members.map((_member) => [
			_member.uniqueId,
			_member.fullName,
			_member.email,
			formatPhoneNumber(_member.phoneNumber, _member.countryCode),
			_member.status === MemberInviteEnum.DELETED ? MemberInviteEnum.INACTIVE : _member.status,
		]);
		data.unshift([
			'uniqueId',
			'fullName',
			'email',
			'phoneNumber',
			'status',
		]);
		downloadCSV(data, `${companyName}_${filename}.csv`);
	};

	onRowClick = ({ original }: { original: TableVM; }) => {
		const { companyName, history, location: { state: { orgAlias } } } = this.props;
		history.push(CLIENT.COMPANY.SETTINGS.MEMBERS.EDIT(orgAlias, companyName, `${original.id}`));
	};

	tabs = (): TabProps<TableVM>[] => {
		const {
			fetchActive,
			fetchDeleted,
			location: { state: { orgAlias } },
			companyName,
			hasManagePermission,
			history,
		} = this.props;

		const activeUsersRowActions: ItemBlueprint<TableVM>[] = hasManagePermission ? [
			{
				label: 'Edit',
				action: this.editAccount,
				shouldRefresh: false,
			},
			{
				label: 'Activate',
				action: this.activateAccount,
				hasModal: true,
				modalTitle: CompanyMembersList.activateAccountModalTitle,
				modalBody: CompanyMembersList.activateAccountModalBody,
				modalText: CompanyMembersList.activateAccountModalText,
				shouldRefresh: true,
				hide: (original: TableVM) => original.status !== MemberInviteEnum.INACTIVE,
			},
			{
				label: (original: TableVM) => original.status === MemberInviteEnum.INACTIVE ? 'Send Email Invite' : 'Resend Email Invite',
				action: this.resendEmailInvite,
				shouldRefresh: true,
				hide: (original: TableVM) => !original.email || original.status === MemberInviteEnum.ACTIVE,
			},
			{
				label: (original: TableVM) => original.status === MemberInviteEnum.INACTIVE ? 'Send Phone Invite' : 'Resend Phone Invite',
				action: this.resendPhoneInvite,
				shouldRefresh: true,
				hide: (original: TableVM) => !original.phoneNumber || original.status === MemberInviteEnum.ACTIVE,
			},
			{
				label: 'Deactivate for Company',
				action: this.deleteAccount,
				hasModal: true,
				modalTitle: CompanyMembersList.deleteAccountModalTitle,
				modalBody: CompanyMembersList.deleteAccountModalBody,
				modalText: CompanyMembersList.deleteAccountModalText,
				shouldRefresh: true,
			},
		] : [];

		const deletedUsersRowActions: ItemBlueprint<TableVM>[] = hasManagePermission ? [
			{
				label: 'Reactivate User',
				action: this.editAccount,
				shouldRefresh: false,
			},
		] : [];

		const sharedButtons: ButtonData[] = [
			{
				type: TableButtonType.LINK,
				hasPermission: hasManagePermission,
				label: 'CSV Import',
				icon: 'upload',
				onClick: async () => history.push(CLIENT.COMPANY.SETTINGS.MEMBERS.BULK_UPLOAD(orgAlias, companyName)),
			},
			{
				type: TableButtonType.LINK,
				hasPermission: hasManagePermission,
				label: 'Notify Users',
				icon: 'notifications_active',
				onClick: this.goToNotificationScreen,
			},
			{
				type: TableButtonType.PRIMARY,
				hasPermission: hasManagePermission,
				label: 'Register User',
				icon: 'plus',
				onClick: async () => history.push(CLIENT.COMPANY.SETTINGS.MEMBERS.INVITE(orgAlias, companyName)),
			},
		];

		const activeUserButtons: ButtonData[] = sharedButtons.toSpliced(2, 0,
			{
				type: TableButtonType.EXPORT,
				hasPermission: hasManagePermission,
				onClick: this.onDownloadActiveCSVClick,
			}
		);

		const deletedUserButtons: ButtonData[] = sharedButtons.toSpliced(2, 0,
			{
				type: TableButtonType.EXPORT,
				hasPermission: hasManagePermission,
				onClick: this.onDownloadDeactivatedCSVClick,
			}
		);

		return [
			{
				label: 'All',
				columns: CompanyMembersList.COLUMNS_FOR_ACTIVE,
				selectable: false,
				hasSearchInput: true,
				searchLabel: 'Users',
				buttons: activeUserButtons,
				fetch: fetchActive,
				onRowClick: this.onRowClick,
				rowActions: activeUsersRowActions,
			},
			{
				label: 'Recently Deactivated',
				columns: CompanyMembersList.COLUMNS_FOR_DELETED,
				selectable: false,
				hasSearchInput: true,
				searchLabel: 'Users',
				buttons: deletedUserButtons,
				fetch: fetchDeleted,
				rowActions: deletedUsersRowActions,
			},
		];
	};

	render() {
		return (
			<div className="form-segment form-segment--maxi">
				<Breadcrumbs items={CompanyMembersList.BREADCRUMBS} />
				<Table
					tableName={TableNameEnum.USER}
					tabs={this.tabs()}
				/>
			</div>
		);
	}
}

function mapStateToProps(state: RootState) {
	const { userData, companyData } = state.user;
	if (!userData || !companyData) {
		throw new Error('User not logged in');
	}

	const hasManagePermission: boolean = isAllowed(
		PagePermissions.COMPANY.SETTINGS.MEMBERS.MANAGE,
		companyData.permissions,
		companyData.isCompanyAdmin,
		userData.role
	);

	return {
		hasManagePermission,
		companyName: companyData.name,
	};
}

function mapDispatchToProps() {
	return {
		deleteAccount: AccountActions.remove,
		activateAccount: AccountActions.activate,
		fetchActive: AccountActions.findAllForCompanyTable,
		fetchDeleted: AccountActions.findAllDeletedForCompanyTable,
		resendEmailInvite: AccountActions.resendEmailInvite,
		resendPhoneInvite: AccountActions.resendPhoneInvite,
		findForCSV: AccountActions.findAllForCompanyList,
	};
}

const connector = connect(mapStateToProps, mapDispatchToProps());

export default connector(CompanyMembersList);
