import * as React from 'react';
import type { WrappedFieldArrayProps } from 'redux-form';
import { change } from 'redux-form';
import { FormGroup, FormControl } from 'react-bootstrap';
import Select from 'react-select';
import type { ConnectedProps } from 'react-redux';
import { connect } from 'react-redux';
import type { SelectComponentsConfig } from 'react-select/src/components';
import type { ThemeConfig } from 'react-select/src/theme';
import type { ValueType, FocusEventHandler, OptionTypeBase } from 'react-select/src/types';
import type { OptionProps } from 'react-select/src/components/Option';
import { nanoid } from 'nanoid';

import Label from 'af-components/LockedValue/Label';

import { UNIQUE_ID_SIZE } from 'ab-common/constants/value';

export interface OwnProps<T extends OptionTypeBase> {
	addonComponent?: () => JSX.Element | string;
	className?: string;
	containerClassName?: string;
	disableErrorMessage?: boolean;
	getMultiValueLabelClassName?: (data: T) => string;
	getOptionLabel?: (option: T) => string; // generaly option.label
	getOptionValue?: (option: T) => string; // generaly option.id
	isDisabled?: boolean;
	label?: string;
	MultiValueLabel?: React.ComponentType<OptionProps<T, boolean>>;
	onBlur?: FocusEventHandler;
	onChange?: (items: T[]) => void;
	onValueChange?: (selectedOption: ValueType<T, boolean>) => Promise<void>;
	Option?: React.ComponentType<OptionProps<T, boolean>>;
	options: T[];
	menuPlacement?: 'auto' | 'top' | 'bottom';
	placeholder?: string;
	valueKey?: string; // use only when selecting string options
	fixed?: boolean;
}

// type Props<T> = OwnProps<T> & WrappedFieldArrayProps<T>;

type ConnectOwnProps<T extends OptionTypeBase> = OwnProps<T> & WrappedFieldArrayProps<T>;

type Props<T extends OptionTypeBase> = ConnectOwnProps<T> & ConnectedProps<typeof connector>;

interface DropdownStyle {
	top: number | 'auto';
	bottom: number | 'auto';
	left: number;
	width: number;
}

interface State {
	id: string;
	dropdownStyle: DropdownStyle | undefined;
}

export interface MultiTagOptionProps<T extends OptionTypeBase> extends OptionProps<T, boolean> {
	data: T;
	onRemove?: (event: React.MouseEvent<HTMLElement, MouseEvent>) => void;
}

const getTheme: ThemeConfig = (theme) => ({
	...theme,
	borderRadius: 2,
	colors: {
		...theme.colors,
		primary25: '#FEF4E9',
		primary: '#FFA726',
	},
});

const customStyles = {
	control: (base) => {
		return {
			...base,
			boxShadow: '0 !important',
			'&:focus': {
				borderColor: '#FFA726',
			},
			'&:active': {
				borderColor: '#FFA726',
			},
		};
	},
};

class MultiTagSelect<OptionType extends OptionTypeBase> extends React.PureComponent<Props<OptionType>, State> {

	// eslint-disable-next-line @typescript-eslint/no-explicit-any
	static defaultProps: Partial<Props<any>> = {
		options: [],
		disableErrorMessage: false,
		isDisabled: false,
		className: '',
		menuPlacement: 'auto',
	};

	state: State = {
		id: nanoid(UNIQUE_ID_SIZE),
		dropdownStyle: undefined,
	};

	components: SelectComponentsConfig<OptionType, boolean> = {
		IndicatorSeparator: null,
		DropdownIndicator: () => <span className="icon-down" />,
		ClearIndicator: (props) => {
			const {
				getStyles,
				innerProps: { ref, ...restInnerProps },
			} = props;
			return (
				<div
					{...restInnerProps}
					ref={ref}
					style={getStyles('clearIndicator', props)}
				>
					<span className="icon-close" />
				</div>
			);
		},
		Option: this.props.Option ?? undefined,
		MultiValueLabel: this.props.MultiValueLabel ?
			(props) => {
				const { MultiValueLabel } = this.props;
				if (!MultiValueLabel) {
					throw new Error('Label not defined');
				}
				return <MultiValueLabel {...props} onRemove={this.onRemove.bind(this, props.data)} />;
			} : undefined,
		MultiValueRemove: this.props.MultiValueLabel ? () => null : undefined,
	};

	onRemove = (data: OptionType, event: React.MouseEvent<MouseEvent>) => {
		event.stopPropagation();

		const { fields } = this.props;
		const options = fields.getAll();
		const optionIndex = options.findIndex((_option) => _option === data);
		fields.remove(optionIndex);
	};

	onChange = (items: OptionType[]) => {
		const { changeField, meta, fields, onChange } = this.props;
		changeField?.(meta.form, fields.name, items);

		if (onChange) {
			onChange(items);
		}
	};

	onOpen = () => {
		const { fixed } = this.props;

		if (!fixed) {
			return;
		}

		const { id } = this.state;
		const {
			x: inputPositionX = 0,
			bottom: inputBottom = 0,
			width: inputWidth = 0,
		} = document.getElementById(id)?.getClientRects?.()?.[0] ?? {};

		this.setState(() => ({
			dropdownStyle: {
				top: inputBottom,
				bottom: 'auto',
				left: inputPositionX,
				width: inputWidth,
			},
		}));
	};

	onBlur = (event: React.FocusEvent<HTMLElement>) => {
		const { onBlur } = this.props;

		if (onBlur) {
			onBlur(event);
		}
	};

	stopPropagation = (event) => event.stopPropagation();

	render() {
		const {
			placeholder,
			options,
			label,
			fields,
			containerClassName,
			className,
			getOptionValue,
			getOptionLabel,
			disableErrorMessage,
			meta: { error, warning },
			addonComponent,
			isDisabled,
			menuPlacement,
			fixed,
		} = this.props;
		const { id, dropdownStyle } = this.state;
		// TODO: add touched
		let formGroupClassName = 'react-select-container';
		formGroupClassName = containerClassName ? `${formGroupClassName} ${containerClassName}` : formGroupClassName;
		formGroupClassName = addonComponent ? `${formGroupClassName} react-select-container--with-addon` : formGroupClassName;

		let selectClassName = 'react-select-field react-select-field--multi-tag';
		selectClassName = className ? `${selectClassName} ${className}` : selectClassName;
		selectClassName = error ? `${selectClassName} react-select-field--error` : selectClassName;
		selectClassName = fixed ? `${selectClassName} react-select-field--fixed` : selectClassName;

		const styles = fixed ? { ...customStyles, menu: (base) => ({ ...base, ...dropdownStyle }) } : customStyles;

		return (
			<FormGroup className={formGroupClassName}>
				{label && <Label label={label} withMargin={true} />}
				<Select
					className={selectClassName}
					components={this.components}
					getOptionLabel={getOptionLabel}
					getOptionValue={getOptionValue}
					id={id}
					isClearable={!isDisabled}
					isDisabled={isDisabled}
					isMulti={true}
					isSearchable={false}
					menuPlacement={menuPlacement}
					onBlur={this.onBlur}
					onChange={this.onChange}
					onMenuOpen={this.onOpen}
					options={options}
					placeholder={placeholder}
					styles={styles}
					theme={getTheme}
					value={fields.getAll()}
				/>
				{addonComponent?.()}
				<FormControl.Feedback />
				{(!disableErrorMessage) &&
					(
						(error && <span className="help-block"><span className="icon-info" /> {error}</span>) ||
						(warning && <span className="help-block text-orange"><span className="icon-info" /> {warning}</span>)
					)
				}
			</FormGroup>
		);
	}
}

const connector = connect(null, { changeField: change });

// eslint-disable-next-line @typescript-eslint/no-explicit-any
export default connector(MultiTagSelect) as React.FunctionComponent<WrappedFieldArrayProps<any> & OwnProps<any>>;
