import { isValidTextInput } from 'acceligent-shared/utils/text';

import type { CompanyRequestModel } from 'ab-requestModels/company.requestModel';

export function validate(values: CompanyRequestModel) {
	const errors: ValidationErrors = {};

	if (!isValidTextInput(values.name)) {
		errors.name = 'Company name is required';
	}

	if (!isValidTextInput(values.street)) {
		errors.street = 'Location is required';
	}

	if (!values.latitude) {
		errors.latitude = 'Latitude is required';
	}

	if (!values.longitude) {
		errors.longitude = 'Longitude is required';
	}

	if (
		values?.latitude
		&& (values.latitude > 90 || values.latitude < -90)
	) {
		errors.latitude = 'Latitude is not valid';
	}

	if (
		values?.longitude
		&& (values.longitude > 180 || values.longitude < -180)
	) {
		errors.longitude = 'Longitude is not valid';
	}

	return errors;
}
