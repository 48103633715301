import * as React from 'react';
import type { CustomRouteComponentProps } from 'react-router-dom';
import { withRouter } from 'react-router-dom';
import type { ResolveThunks } from 'react-redux';
import { connect } from 'react-redux';
import { compose } from 'redux';
import { Dropdown } from 'react-bootstrap';

import CLIENT from 'af-constants/routes/client';

import PagePermissions from 'ab-enums/pagePermissions.enum';
import type ScheduleBoardView from 'ab-enums/scheduleBoardView.enum';

import * as AuthUtils from 'ab-utils/auth.util';

import ViewMenuItem from 'af-components/Menu/MenuItem';

import * as ScheduleBoardActions from 'af-actions/scheduleBoard';

import type { RootState } from 'af-reducers';

import * as ScheduleBoardUtil from 'af-utils/scheduleBoard.util';

interface StateProps {
	companyName: string;
	canViewMechanicView: boolean;
	canViewScheduleBoard: boolean;
	scheduleBoardView: Nullable<ScheduleBoardView>;
}

interface DispatchProps {
	clearDailyView: typeof ScheduleBoardActions.clearDailyView;
	clearWeeklyView: typeof ScheduleBoardActions.clearWeeklyView;
}

type ConnectOwnProps = CustomRouteComponentProps;

type Props = ConnectOwnProps & StateProps & ResolveThunks<DispatchProps>;

const MenuItemIcon = ({ icon }: { icon: string; }) => (
	<div className="btn btn--icon btn-primary m-r-m">
		<span className={icon} />
	</div>
);

const scheduleBoardIcon = <MenuItemIcon icon="icon-schedule_board" />;
// TODO: Change table view to actual tabular format
// const tableViewIcon = <MenuItemIcon icon="icon-wo_table" />;
const displayViewIcon = <MenuItemIcon icon="icon-display_view" />;
const displayViewNextDayIcon = <MenuItemIcon icon="icon-display_view_next_day" />;
const mechanicViewIcon = <MenuItemIcon icon="icon-equipment" />;

class NavigationItem extends React.PureComponent<Props> {
	goToScheduleBoardView = () => {
		const { history, location: { state: { orgAlias } }, companyName } = this.props;
		history.push({
			pathname: CLIENT.COMPANY.SCHEDULE_BOARD.DAILY_VIEW(orgAlias, companyName),
		});
	};

	goToTableView = () => {
		const {
			scheduleBoardView,
			clearDailyView,
			clearWeeklyView,
			history,
			location: { state: { orgAlias } },
			companyName,
		} = this.props;

		ScheduleBoardUtil.clearScheduleBoardRedux(
			scheduleBoardView,
			clearDailyView,
			clearWeeklyView
		);

		history.push({ pathname: CLIENT.COMPANY.WORK_ORDERS.LIST(orgAlias, companyName) });
	};

	goToDisplayView = () => {
		const { history, location: { state: { orgAlias } }, companyName } = this.props;
		history.push({
			pathname: CLIENT.COMPANY.DISPLAY_VIEW.ROOT(orgAlias, companyName),
			search: 'offset=false',
		});
	};

	goToDisplayViewWithOneDayOffsetView = () => {
		const { history, location: { state: { orgAlias } }, companyName } = this.props;
		history.push({
			pathname: CLIENT.COMPANY.DISPLAY_VIEW.ROOT(orgAlias, companyName),
			search: 'offset=true',
		});
	};

	goToMechanicView = () => {
		const { history, location: { state: { orgAlias } }, companyName } = this.props;
		history.push({
			pathname: CLIENT.COMPANY.MECHANIC_VIEW.ROOT(orgAlias, companyName),
		});
	};

	getActiveIconName = () => {
		const { location: { state: { orgAlias } }, companyName } = this.props;
		const isScheduleBoardView = location.pathname.includes(CLIENT.COMPANY.SCHEDULE_BOARD.DAILY_VIEW(orgAlias, companyName))
			|| location.pathname.includes(CLIENT.COMPANY.SCHEDULE_BOARD.WEEKLY_VIEW(orgAlias, companyName));

		if (isScheduleBoardView) {
			return 'icon-schedule_board';
		} else if (location.pathname.includes(CLIENT.COMPANY.WORK_ORDERS.LIST(orgAlias, companyName))) {
			return 'icon-wo_table';
		} else if (location.pathname === CLIENT.COMPANY.DISPLAY_VIEW.ROOT(orgAlias, companyName)) {
			if (location.search === '?offset=true') {
				return 'icon-display_view_next_day';
			}
			return 'icon-display_view';
		} else if (location.pathname === CLIENT.COMPANY.MECHANIC_VIEW.ROOT(orgAlias, companyName)) {
			return 'icon-equipment';
		} else {
			return '';
		}
	};

	render() {
		const {
			canViewMechanicView,
			canViewScheduleBoard,
			location: { state: { orgAlias } },
			companyName,
		} = this.props;

		if (!canViewScheduleBoard && canViewMechanicView) {
			return null;
		}

		return (
			<Dropdown className="schedule-board-view-switcher" id="accqa__table-and-display-view-dropdown-menu">
				<Dropdown.Toggle className="schedule-board-view-switcher__toggle">
					<span className={this.getActiveIconName()} />
				</Dropdown.Toggle>
				<Dropdown.Menu className="schedule-board-view-switcher__menu">
					{
						canViewScheduleBoard
							? (
								<>
									<ViewMenuItem
										action={this.goToScheduleBoardView}
										icon={scheduleBoardIcon}
										isActive={location.pathname.includes(CLIENT.COMPANY.SCHEDULE_BOARD.DAILY_VIEW(orgAlias, companyName))}
										label="Schedule Board View"
									/>
									{/* <ViewMenuItem
										action={this.goToTableView}
										icon={tableViewIcon}
										isActive={location.pathname.includes(CLIENT.COMPANY.WORK_ORDERS.LIST(orgAlias, companyName))}
										label="Table View"
									/> */}
									<ViewMenuItem
										action={this.goToDisplayView}
										icon={displayViewIcon}
										isActive={location.pathname === CLIENT.COMPANY.DISPLAY_VIEW.ROOT(orgAlias, companyName) && location.search !== '?offset=true'}
										label="Display View"
									/>
									<ViewMenuItem
										action={this.goToDisplayViewWithOneDayOffsetView}
										icon={displayViewNextDayIcon}
										isActive={location.pathname === CLIENT.COMPANY.DISPLAY_VIEW.ROOT(orgAlias, companyName) && location.search === '?offset=true'}
										label="Next Day Display View"
									/>
								</>
							) : null
					}
					{
						canViewMechanicView
							? (
								<ViewMenuItem
									action={this.goToMechanicView}
									icon={mechanicViewIcon}
									isActive={location.pathname === CLIENT.COMPANY.MECHANIC_VIEW.ROOT(orgAlias, companyName)}
									label="Mechanic View"
								/>
							) : null
					}
				</Dropdown.Menu>
			</Dropdown>
		);
	}
}

function mapStateToProps(state: RootState): StateProps {
	const { user: { userData, companyData }, scheduleBoard: { scheduleBoardView } } = state;
	if (!userData || !companyData) {
		throw new Error('User not logged in');
	}

	const { role } = userData;
	const { permissions, isCompanyAdmin, name } = companyData;

	return {
		companyName: name,
		canViewMechanicView: AuthUtils.isAllowed(PagePermissions.COMPANY.EQUIPMENT.MECHANIC_VIEW, permissions, isCompanyAdmin, role),
		canViewScheduleBoard: AuthUtils.isAllowed(PagePermissions.COMPANY.WORK_ORDERS.SCHEDULE_BOARD, permissions, isCompanyAdmin, role),
		scheduleBoardView,
	};
}

function mapDispatchToProps(): DispatchProps {
	return {
		clearDailyView: ScheduleBoardActions.clearDailyView,
		clearWeeklyView: ScheduleBoardActions.clearWeeklyView,
	};
}

const enhance = compose<React.ComponentClass>(
	withRouter,
	connect<StateProps, DispatchProps, ConnectOwnProps>(mapStateToProps, mapDispatchToProps())
);

export default enhance(NavigationItem);
