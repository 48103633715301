import * as React from 'react';
import { Field } from 'redux-form';

import type { PriorityItem} from 'acceligent-shared/enums/priority';
import { PriorityList } from 'acceligent-shared/enums/priority';

import { getAttributeName } from 'ab-viewModels/workRequest/workRequestDetailsUpsert.viewModel';
import type JobStatusVM from 'ab-viewModels/jobStatus/option.viewModel';
import type { EmployeeOptionVM } from 'ab-viewModels/employee/option.viewModel';
import type LocationVM from 'ab-viewModels/location/option.viewModel';
import type { DivisionViewModel, GroupedDivisions } from 'ab-viewModels/division.viewModel';

import * as ColorUtils from 'ab-utils/color.util';

import PriorityLabel from 'af-components/PriorityLabel';

import Input from 'af-fields/Input';
import Checkbox from 'af-fields/Checkbox';
import Dropdown from 'af-fields/Dropdown';

import { formSectionTitleClass, formSectionClass, formSectionClassRow, formSectionClassColumn2, formSectionClassColumn4, formSectionDropdownItem, formSectionDropdownItemMain, formSectionDropdownItemSecondary } from '../../helpers';
import styles from '../../styles.module.scss';

type Props = {
	findJobStatuses: () => Promise<JobStatusVM[]>;
	findAllProjectManagers: () => Promise<EmployeeOptionVM[]>;
	findAllLocations: () => Promise<LocationVM[]>;
	findAllDivisions: () => Promise<GroupedDivisions>;
	isDisabled: boolean;
};

const filterOption = (option: EmployeeOptionVM, rawInput: string) => {
	const { firstName, lastName, uniqueId } = option;
	return `${firstName} ${lastName} ${uniqueId}`.toLowerCase().includes(rawInput.toLowerCase());
};

const renderJobStatus = (option: JobStatusVM) =>
	<div className={formSectionDropdownItem} key={option.id}>
		<div className={[formSectionDropdownItemMain, ColorUtils.getColorTextClass(option.color)].join(' ')}>
			{option.name}
		</div>
		<div className={formSectionDropdownItemSecondary}>
			{option.description}
		</div>
	</div>;

const renderSelectedJobStatus = (option: JobStatusVM) =>
	<div className={formSectionDropdownItem} key={option.id}>
		<div className={[formSectionDropdownItemMain, ColorUtils.getColorTextClass(option.color)].join(' ')}>
			{option.name}
		</div>
	</div>;

const renderEmployeeOption = (option: EmployeeOptionVM) =>
	<div className={formSectionDropdownItem} key={option.id}>
		<div className={formSectionDropdownItemMain}>
			{`${option.firstName} ${option.lastName}`}
		</div>
	</div>;

const renderSelectedProjectManager = (option: EmployeeOptionVM) =>
	<div className={formSectionDropdownItem} key={option.id}>
		<div className={formSectionDropdownItemMain}>
			{`${option.firstName} ${option.lastName}`}
		</div>
	</div>;

const renderOffice = (option: LocationVM) => <div key={option.id}>{option.nickname}</div>;

const renderDivision = (option: DivisionViewModel) => <div key={option.id}>{option.name}</div>;

const JobSummarySection: React.FC<Props> = ({ findJobStatuses, findAllProjectManagers, findAllLocations, findAllDivisions, isDisabled }) => {
	const renderPriorityItem = React.useCallback((option: PriorityItem) => <PriorityLabel priority={option.id} />, []);
	const [jobStatuses, setJobStatuses] = React.useState<JobStatusVM[]>([]);
	const [projectManagers, setProjectManagers] = React.useState<EmployeeOptionVM[]>([]);
	const [offices, setOffices] = React.useState<LocationVM[]>([]);
	const [divisions, setDivisions] = React.useState<DivisionViewModel[]>([]);

	const lazyLoadJobStatuses = React.useCallback(async (isLazyLoaded: boolean) => {
		if (!isLazyLoaded) {
			const fetchedJobStatuses = await findJobStatuses();
			setJobStatuses(fetchedJobStatuses ?? []);
		}
	}, [findJobStatuses]);

	const lazyLoadOffices = React.useCallback(async (isLazyLoaded: boolean) => {
		if (!isLazyLoaded) {
			const fetchedOffices = await findAllLocations();
			setOffices(fetchedOffices ?? []);
		}
	}, [findAllLocations]);

	const lazyLoadProjectManagers = React.useCallback(async (isLazyLoaded: boolean) => {
		if (!isLazyLoaded) {
			const fetchedProjectManagers = await findAllProjectManagers();
			setProjectManagers(fetchedProjectManagers ?? []);
		}
	}, [findAllProjectManagers]);

	const lazyLoadDivisions = React.useCallback(async (isLazyLoaded: boolean) => {
		if (!isLazyLoaded) {
			const fetchedDivisions = await findAllDivisions();
			setDivisions(Object.values<DivisionViewModel[]>(fetchedDivisions).flat());
		}
	}, [findAllDivisions]);

	const checkboxStyle = React.useMemo(() => [formSectionClassColumn2, styles['job-form__tab-content__form-section__row__centered-column']].join(' '), []);

	return (
		<div className={formSectionClass}>
			<div className={formSectionTitleClass}>
				Job Summary
			</div>
			<div className={formSectionClassRow}>
				<div className={formSectionClassColumn2}>
					<Field
						component={Input}
						disabled={isDisabled}
						id="jobCode"
						label="Job ID *"
						name={getAttributeName('jobCode')}
						placeholder="Enter Job ID"
						type="text"
					/>
				</div>
				<div className={formSectionClassColumn4}>
					<Field
						component={Input}
						disabled={isDisabled}
						id="title"
						label="Job Title *"
						name={getAttributeName('title')}
						placeholder="Enter Job Title"
						type="text"
					/>
				</div>
				<div className={checkboxStyle}>
					<Field
						component={Checkbox}
						disabled={isDisabled}
						id="isInternal"
						label="Internal Job"
						name={getAttributeName('isInternal')}
					/>
				</div>
			</div>
			<div className={formSectionClassRow}>
				<div className={formSectionClassColumn2}>
					<Field
						component={Input}
						disabled={isDisabled}
						id="customerCompany"
						label="Customer Company *"
						name={getAttributeName('customerCompanyName')}
						placeholder="Enter Customer Company"
						type="text"
					/>
				</div>
				<div className={formSectionClassColumn2}>
					<Field
						component={Input}
						disabled={isDisabled}
						id="customerReferencePO"
						label="Customer Reference/PO"
						name={getAttributeName('customerReferencePO')}
						placeholder="Enter Customer Reference/PO"
						type="text"
					/>
				</div>
				<div className={checkboxStyle}>
					<Field
						component={Checkbox}
						disabled={isDisabled}
						id="isEmergencyJob"
						label="Emergency Job"
						name={getAttributeName('isEmergencyJob')}
					/>
				</div>
			</div>
			<div className={formSectionClassRow}>
				<div className={formSectionClassColumn2}>
					<Field
						component={Dropdown}
						disabled={isDisabled}
						id="priority"
						label="Priority *"
						name="priority"
						options={PriorityList}
						renderMenuItem={renderPriorityItem}
						valueKey="id"
						withCaret={true}
					/>
				</div>
				<div className={formSectionClassColumn2}>
					<Field
						component={Dropdown}
						disabled={isDisabled}
						hasBlankOption={true}
						id="jobStatusId"
						label="Job Status"
						name={getAttributeName('jobStatusId')}
						onLazyLoad={lazyLoadJobStatuses}
						options={jobStatuses}
						placeholder="Select Status"
						propName="jobStatus"
						renderMenuItem={renderJobStatus}
						renderSelected={renderSelectedJobStatus}
						valueKey="id"
						withCaret={true}
					/>
				</div>
			</div>
			<div className={formSectionClassRow}>
				<div className={formSectionClassColumn2}>
					<Field
						component={Dropdown}
						disabled={isDisabled}
						filterable={true}
						filterBy={filterOption}
						id="projectManagerId"
						label="Project Manager"
						name="projectManagerId"
						onLazyLoad={lazyLoadProjectManagers}
						options={projectManagers}
						placeholder="Select Project Manager"
						propName="projectManager"
						renderMenuItem={renderEmployeeOption}
						renderSelected={renderSelectedProjectManager}
						valueKey="id"
						withCaret={true}
					/>
				</div>
				<div className={formSectionClassColumn2}>
					<Field
						component={Dropdown}
						disabled={isDisabled}
						id="officeId"
						label="Office *"
						name="officeId"
						onLazyLoad={lazyLoadOffices}
						options={offices}
						placeholder="Select Office"
						propName="office"
						renderMenuItem={renderOffice}
						valueKey="id"
						withCaret={true}
					/>
				</div>
				<div className={formSectionClassColumn2}>
					<Field
						component={Dropdown}
						disabled={isDisabled}
						hasBlankOption={true}
						id="divisionId"
						label="Division"
						name="divisionId"
						onLazyLoad={lazyLoadDivisions}
						options={divisions}
						placeholder="Select Division"
						propName="division"
						renderMenuItem={renderDivision}
						valueKey="id"
						withCaret={true}
					/>
				</div>
			</div>
		</div>
	);
};

export default React.memo(JobSummarySection);
