import * as React from 'react';
import { compose } from 'redux';
import type { CustomRouteComponentProps } from 'react-router-dom';
import type { ConnectedProps } from 'react-redux';
import { connect } from 'react-redux';
import { Button } from 'react-bootstrap';

import * as WorkOrderActions from 'af-actions/workOrder';

import type { RootState } from 'af-reducers/index';

import { DOWNLOAD_ORDER_CONFIRMATION } from 'af-constants/reduxForms';

import { CircleLoadingIndicator } from 'af-components/CircleLoadingIndicator';

import type WorkOrderConfirmationViewModel from 'ab-viewModels/workOrderConfirmation.viewModel';

import Loading from 'af-components/DocumentViewer/Loading';

import { useNotificationSnackbar } from 'af-root/hooks/useNotificationSnackbar';

import WorkOrderConfirmation from '../Shared/WorkOrderConfirmation';

interface PathParams {
	publicLink: string;
}

type OwnProps = CustomRouteComponentProps<PathParams>;

type Props = OwnProps & ConnectedProps<typeof connector>;

const ConfirmationPublic: React.FC<Props> = (props) => {
	const {
		isDownloading,
		downloadWorkOrderConfirmationPdfPublic,
		findForConfirmationByPublicLink,
		match: { params: { publicLink } },
	} = props;

	const [workOrder, setWorkOrder] = React.useState<Nullable<WorkOrderConfirmationViewModel>>(null);

	const notificationSnackbar = useNotificationSnackbar();

	React.useEffect(() => {
		const initialize = async () => {
			const _workOrder = await findForConfirmationByPublicLink(publicLink);
			setWorkOrder(_workOrder);
		};
		initialize();
		// Disabling cause we only want to do this once
		// eslint-disable-next-line react-hooks/exhaustive-deps
	}, []);

	const downloadPdf = React.useCallback(async () => {
		const msg = notificationSnackbar.info('Started preparing for download');
		notificationSnackbar.removeNotificationSnackbar(msg);
		await downloadWorkOrderConfirmationPdfPublic(publicLink, `WO_confirmation_${publicLink}.pdf`, notificationSnackbar);
	}, [downloadWorkOrderConfirmationPdfPublic, notificationSnackbar, publicLink]);

	if (!workOrder) {
		return <Loading />;
	}

	return (
		<div className="form-segment form-segment--midi">
			<div className="form-box form-box--full-padded">
				<WorkOrderConfirmation workOrder={workOrder} />
			</div>
			<Button
				className="btn btn-primary btn--floating btn--big-icon"
				onClick={!isDownloading ? downloadPdf : undefined}
				variant="primary"
			>
				{isDownloading ?
					<CircleLoadingIndicator /> :
					<span className="icon-print" />
				}
			</Button>
		</div>
	);
};

function mapStateToProps(state: RootState) {
	return {
		isDownloading: state.http.submitting.includes(DOWNLOAD_ORDER_CONFIRMATION),
	};
}

function mapDispatchToProps() {
	return {
		findForConfirmationByPublicLink: WorkOrderActions.findForConfirmationByPublicLink,
		downloadWorkOrderConfirmationPdfPublic: WorkOrderActions.downloadWorkOrderConfirmationPdfPublic,
	};
}

const connector = connect(mapStateToProps, mapDispatchToProps());

const enhance = compose<React.ComponentType<OwnProps>>(
	React.memo,
	connector
);

export default enhance(ConfirmationPublic);
