import * as React from 'react';
import type { Header} from '@tanstack/react-table';
import { flexRender } from '@tanstack/react-table';

import styles from './styles.module.scss';

type Props<T> = {
	setHeaderReference: (headerId: string) => (_ref: HTMLSpanElement) => HTMLSpanElement;
	header: Header<T, unknown>;
	onColumnDrag: (header: string) => () => void;
	areAllSortsDisabled: boolean;
};

function BaseHeaderCell<T,>(props: React.PropsWithChildren<Props<T>>) {
	const { header, onColumnDrag, setHeaderReference, areAllSortsDisabled } = props;

	const renderSortIcon = React.useCallback(() => {
		const isSorted = header.column.getIsSorted();
		const isSortable = header.column.getCanSort();

		const isDisabled = areAllSortsDisabled;

		if (!isSortable) {
			return null;
		}

		if (!isSorted) {
			return (
				<span className={isDisabled ? styles['simple-table__sort-disabled'] : undefined}>
					<span className="icon-table_sort" />
				</span>
			);
		}

		if (isSorted === 'asc') {
			return (
				<span className={isDisabled ? styles['simple-table__sort-disabled'] : undefined}>
					<span className="icon-up" />
				</span>
			);
		} else {
			return (
				<span className={isDisabled ? styles['simple-table__sort-disabled'] : undefined}>
					<span className="icon-down" />
				</span>
			);
		}
	}, [areAllSortsDisabled, header.column]);

	const isDisplayColumn = !header.column.accessorFn;
	const isSortingEnabled = !areAllSortsDisabled && !!header.column.columnDef.enableSorting;

	return (
		<span
			className={styles['simple-table__header-cell']}
			key={header.id}
			ref={setHeaderReference(header.id)}
		>
			<div
				className={styles['simple-table__header-cell__name-with-icon']}
				onClick={isSortingEnabled ? header.column.getToggleSortingHandler() : undefined}
			>
				{flexRender(header.column.columnDef.header, header.getContext())}
				{header.column.columnDef.enableSorting && renderSortIcon()}
			</div>
			<span onMouseDown={onColumnDrag(header.id)} />
			{!isDisplayColumn &&
				<span
					className={styles['simple-table__resizer']}
					onMouseDown={onColumnDrag(header.id)}
				/>
			}
		</span>
	);
}

export default BaseHeaderCell as typeof BaseHeaderCell;
