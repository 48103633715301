import * as React from 'react';

import type { ReportConfirmationVM } from 'ab-viewModels/report/publicConfirmation.viewModel';

import PrintoutSegmentHeader from 'af-components/PrintoutSegmentHeader';

import Signature from './Signature';

interface OwnProps {
	report: ReportConfirmationVM;
	allowCustomerSignature: boolean;
	hasMultipleReports: boolean;
	onReportSigning: (_workOrderId: number) => () => void;
}

type Props = OwnProps;

const Signatures: React.FC<Props> = (props: Props) => {
	const {
		report: { approvalSignatures, customerSignatures, workOrder: { timeZone, id } },
		onReportSigning,
		allowCustomerSignature,
		hasMultipleReports,
	} = props;

	const signatureMapper = React.useCallback((_sig, _index) => (
		<Signature
			key={_index}
			signature={_sig}
			timeZone={timeZone}
		/>
	), [timeZone]);

	if (approvalSignatures.length === 0 && customerSignatures.length === 0 && !(hasMultipleReports && allowCustomerSignature)) {
		return null;
	}

	const hasCustomerSignatures = (customerSignatures.length > 0);

	return (
		<>
			<PrintoutSegmentHeader style="primary" title="Signatures" type="segment" />
			<div className="public-report__signatures">
				<div className="public-report__signatures__column">
					{approvalSignatures.map(signatureMapper)}
				</div>
				<div className="public-report__signatures__column">
					{hasCustomerSignatures
						? <>
							{customerSignatures.map(signatureMapper)}
							{allowCustomerSignature &&
								<div className="public-report__signatures__add-signature" >
									<div className="btn btn--link" onClick={onReportSigning(id)}>
										<span className="icon-plus" />
										<span>Add Additional Signature</span>
									</div>
								</div>
							}
						</>
						: (allowCustomerSignature &&
							<div className="public-report__signatures__no-signature"
								onClick={onReportSigning(id)}
							>
								Click to Sign
							</div>
						)
					}
				</div>
			</div>
		</>
	);
};

export default React.memo(Signatures);
