import * as React from 'react';
import type { InjectedFormProps} from 'redux-form';
import { reduxForm } from 'redux-form';

import { validate } from './validation';
import type { OwnProps } from './TagForm';
import TagForm from './TagForm';

type Props<T> = OwnProps<T> & InjectedFormProps<T, OwnProps<T>, T>;

class EditTag<T> extends React.PureComponent<Props<T>> {
	updateTag = async (form: T) => {
		const { toggleEdit, onSubmit } = this.props;
		await onSubmit(form);
		toggleEdit?.();
	};

	close = () => this.props.toggleEdit?.();

	render() {
		return (
			<TagForm<T>
				{...this.props}
				close={this.close}
				onSubmit={this.updateTag}
			/>
		);
	}
}

export default function <T>(props: OwnProps<T>) {
	const _validate = (form: T) => validate<T>(form, props);
	const Form = reduxForm<T, OwnProps<T>>({ validate: _validate, enableReinitialize: true })(EditTag);
	return <Form {...props} />;
}
