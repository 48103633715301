import * as React from 'react';
import type { ConnectedProps } from 'react-redux';
import { connect } from 'react-redux';
import type { InjectedFormProps } from 'redux-form';
import { Field } from 'redux-form';
import type { CustomRouteComponentProps } from 'react-router-dom';
import { Row, Col, Button } from 'react-bootstrap';

import BlobStorageImageSizeContainer from 'acceligent-shared/enums/blobStorageImageSizeContainer';

import { DEFAULT_EQUIPMENT_IMAGE } from 'ab-common/constants/value';

import type LocationDepartmentVM from 'ab-viewModels/location/locationDepartment.viewModel';
import type ListVM from 'ab-viewModels/item/list.viewModel';
import type { CrewTypeViewModel } from 'ab-viewModels/crewType.viewModel';

import * as ItemCategoryActions from 'af-actions/itemCategory';
import * as LocationActions from 'af-actions/location';
import * as VendorActions from 'af-actions/vendor';
import * as ItemActions from 'af-actions/item';
import * as CrewTypeActions from 'af-actions/crewType';

import Input from 'af-fields/Input';
import Textarea from 'af-fields/Textarea';
import Dropdown from 'af-fields/Dropdown';
import MultiTagSelect from 'af-fields/MultiTagSelectAsField';

import SubmitButton from 'af-components/SubmitButton';
import ImageUpload from 'af-components/ImageUpload';
import ImageTag from 'af-components/Image';
import BackButton from 'af-components/BackButton';

import { bemElement } from 'ab-utils/bem.util';

import type { DepartmentFM } from './formModel';
import type FormModel from './formModel';
import Inventory from './Inventory';
import ItemCategoryOption from './ItemCategoryOption';
import ItemCategory from './ItemCategory';
import LabelWithColor from 'af-components/LabelWithColor';
import Loading from 'af-components/Loading';

interface PathParams {
	id?: string;
}

type FormProps = InjectedFormProps<FormModel>;

interface OwnProps {
	onSubmit: (form: FormModel) => Promise<void>;
	initialLocation?: FormModel['location'];
	existingImageUrl?: FormModel['imageUrl'];
	formActionWrapper: {
		selector: <T extends keyof FormModel>(fieldName: T) => FormModel[T];
	};
}

type Props = OwnProps & ConnectedProps<typeof connector> & CustomRouteComponentProps<PathParams> & FormProps;

const getCategoryValue = (option: FormModel['itemCategories'][0]) => `${option.itemCategoryId}`;
const getCategoryLabel = (option: FormModel['itemCategories'][0]) => option.name;

const renderCrewTypeOption = (option: CrewTypeViewModel) => <LabelWithColor color={option.color} text={option.name} />;

const ItemForm = (props: Props) => {
	const {
		findVendors,
		findAllDepartments,
		findCategories,
		change,
		onSubmit,
		handleSubmit,
		createVendor,
		findTakenNames,
		submitting,
		invalid,
		initialLocation,
		existingImageUrl,
		match: { params: { id } },
		loadCrewTypes,
	} = props;

	const [image, setImage] = React.useState<Nullable<string | File>>(null);
	const [imageUrl, setImageUrl] = React.useState<Nullable<string>>(null);
	const [deleteImage, setDeleteImage] = React.useState(false);
	const [showImageUpload, setShowImageUpload] = React.useState(false);
	const [categories, setCategories] = React.useState<FormModel['itemCategories']>([]);
	const [vendors, setVendors] = React.useState<{ label: string; value: number; }[]>([]);
	const [locations, setLocations] = React.useState<LocationDepartmentVM[]>([]);
	const [loading, setLoading] = React.useState(false);
	const [takenNames, setTakenNames] = React.useState<ListVM[]>([]);
	const [crewTypes, setCrewTypes] = React.useState<CrewTypeViewModel[]>([]);

	const loadStuff = React.useCallback(async () => {
		setLoading(true);
		const [
			_categories,
			_vendors,
			_locations,
			_takenNames,
			_crewTypes,
		] = await Promise.all([
			findCategories(),
			findVendors(),
			findAllDepartments(true),
			findTakenNames(),
			loadCrewTypes(),
		]);

		const reduceDepartment = (_acc: Record<DepartmentFM['id'], DepartmentFM>, _department: DepartmentFM) => {
			_acc[_department.id] = _department;
			return _acc;
		};
		const usedDepartmentsMap = Object.keys(initialLocation ?? {}).reduce((_acc, _location) => {
			_acc[_location] = initialLocation![_location].reduce(reduceDepartment, {});
			return _acc;
		}, {} as Record<string, Record<DepartmentFM['id'], DepartmentFM>>);

		const initialInventory = _locations.reduce((_acc, _loc) => {
			_acc[_loc.id] = _loc.departments.map((_dep) => ({
				id: _dep.id,
				name: _dep.name,
				includeDepartment: usedDepartmentsMap[_loc.id]?.[_dep.id]?.includeDepartment ?? false,
				currentStock: usedDepartmentsMap[_loc.id]?.[_dep.id]?.currentStock ?? 0,
				minimumStock: usedDepartmentsMap[_loc.id]?.[_dep.id]?.minimumStock ?? 0,
				maximumStock: usedDepartmentsMap[_loc.id]?.[_dep.id]?.maximumStock ?? 0,
			}));
			return _acc;
		}, {});

		Object.keys(initialInventory).forEach((_loc) => {
			change(`location.${_loc}`, initialInventory[_loc]);
		});
		setCategories(_categories.map((_category) => ({ itemCategoryId: _category.id, name: _category.name })));
		setVendors(_vendors.map((_vendor) => ({ label: _vendor.name, value: _vendor.id })));
		// Filter out locations with no active departments
		// Department is active if its not deleted or if it's checked in form
		// Department can be soft deleted but still be checked in form if it was checked before deletion
		const _validLocations = _locations.reduce((_acc, _loc) => {
			const _validLocationDepartmentIds = _loc.departments.reduce((_reduceDepartment, _dep) => {
				if (!_dep.deletedAt || initialInventory[_loc.id].find((_inv) => _inv.id === _dep.id)?.includeDepartment) {
					_reduceDepartment.push(_dep.id);
				}
				return _reduceDepartment;
			}, [] as number[]);

			if (_validLocationDepartmentIds.length) {
				_acc.push(_loc);
			}
			return _acc;
		}, [] as LocationDepartmentVM[]);
		setLocations(_validLocations);
		setImageUrl(existingImageUrl ?? null);
		setImage(existingImageUrl ?? null);
		setTakenNames(_takenNames);
		setCrewTypes(_crewTypes);
		setLoading(false);
	}, [change, existingImageUrl, findAllDepartments, findCategories, findTakenNames, findVendors, initialLocation, loadCrewTypes]);

	React.useEffect(() => {
		loadStuff();
		// Only on mount
		// eslint-disable-next-line react-hooks/exhaustive-deps
	}, []);

	const submitHandler = async (form: FormModel) => {
		form.imageUrl = deleteImage ? null : image as string;
		await onSubmit(form);
	};

	// Image

	const setImageState = (
		file: Nullable<File>,
		url: Nullable<string>,
		isDelete: boolean
	) => {
		setImage(file);
		setImageUrl(url);
		setDeleteImage(isDelete);
	};

	const clearImage = React.useCallback(() => setImageState(null, null, true), []);

	const openImageUpload = React.useCallback(() => setShowImageUpload(true), []);
	const closeImageUpload = React.useCallback(() => setShowImageUpload(false), []);

	const onImageUploadSave = React.useCallback((frontImage: File, frontImageUrl: string) => setImageState(frontImage, frontImageUrl, false), []);

	const onVendorChange = React.useCallback(async (name: Nullable<string>) => {
		if (name) {
			const newVendor = await createVendor({ name });
			setVendors([...vendors, { label: newVendor.name, value: newVendor.id }]);
			change('vendor', { label: newVendor.name, value: newVendor.id });
		} else {
			change('vendor', null);
		}
	}, [change, createVendor, vendors]);

	const checkUniqueName = React.useCallback((value: string) => {
		if (takenNames.some((_item) => _item.name === value && (!id || +id !== _item.id))) {
			return 'Name already taken';
		}
	}, [takenNames, id]);

	const compareOptions = React.useCallback((optionA: FormModel['itemCategories'][0], optionB: FormModel['itemCategories'][0]) => {
		return optionA.itemCategoryId === optionB.itemCategoryId;
	}, []);
	const imageUploadButtonClassName = bemElement('image-upload', 'button');

	const onCrewTypeClear = React.useCallback(() => {
		change('crewTypeId', null);
		change('crewType', null);
	}, [change]);

	if (loading) {
		return <Loading />;
	}

	return (
		<form onSubmit={handleSubmit(submitHandler)}>
			<div className="form-box">
				<Row className="row--flex">
					<div className="avatar__container">
						<ImageUpload
							closeModal={closeImageUpload}
							onSave={onImageUploadSave}
							showModal={showImageUpload}
						/>
						<div className="avatar">
							<span className="avatar__label">Image</span>
							<ImageTag
								fallbackSrc={DEFAULT_EQUIPMENT_IMAGE}
								minSize={BlobStorageImageSizeContainer.SIZE_200X200}
								src={imageUrl}
								tryOriginal={true}
								tryRoot={true}
							/>
							<div className={bemElement('image-upload', 'buttons')}>
								<Button
									className={imageUploadButtonClassName}
									onClick={openImageUpload}
									variant="info"
								>
									<strong className="icon-upload" />
								</Button>
								<Button
									className={imageUploadButtonClassName}
									disabled={!image && !imageUrl}
									onClick={clearImage}
									variant="danger"
								>
									<strong className="icon-delete" />
								</Button>
							</div>
						</div>
					</div>
					<div className="avatar__neighbour">
						<Row>
							<Col md={12}>
								<Row className="row--padded">
									<Col md={12}>
										<Field
											component={Input}
											label="Name *"
											name="name"
											placeholder="Enter Item Name"
											type="text"
											validate={checkUniqueName}
										/>
									</Col>
									<Col md={12}>
										<Field
											component={Input}
											label="Manufacturer *"
											name="manufacturer"
											placeholder="Enter Manufacturer Name"
											type="text"
										/>
									</Col>
									<Col md={12}>
										<Field
											component={Input}
											label="Model Number *"
											name="modelNumber"
											placeholder="Enter Model Number"
											type="text"
										/>
									</Col>
									<Col md={12}>
										<Field
											component={MultiTagSelect}
											disableErrorMessage={false}
											getOptionLabel={getCategoryLabel}
											getOptionValue={getCategoryValue}
											label="Category *"
											MultiValueLabel={ItemCategory}
											name="itemCategories"
											Option={ItemCategoryOption}
											optionEqual={compareOptions}
											options={categories}
											placeholder="Choose Categories"
										/>
									</Col>
									<Col md={12}>
										<Field
											component={Dropdown}
											id="crewTypeId"
											label="Crew Type"
											name="crewTypeId"
											onClear={onCrewTypeClear}
											options={crewTypes}
											placeholder="Select Crew Type"
											propName="crewType"
											renderMenuItem={renderCrewTypeOption}
											renderSelected={renderCrewTypeOption}
											valueKey="id"
											withCaret={true}
										/>
									</Col>
								</Row>
							</Col>
							<Col md={12}>
								<Row className="row--padded">
									<Col md={24}>
										<Field
											component={Textarea}
											controlCursor={true}
											label="Description"
											name="description"
											rows={6}
										/>
									</Col>
								</Row>
							</Col>
						</Row>
					</div>
				</Row>
			</div>
			<div className="form-box">
				<Inventory
					locations={locations}
					onVendorChange={onVendorChange}
					vendors={vendors}
				/>
				<Row className="row--submit">
					<BackButton />
					<SubmitButton
						disabled={invalid}
						reduxFormSubmitting={submitting}
						variant="primary"
						variantDisabled="info"
					/>
				</Row>
			</div>
		</form>
	);
};

function mapDispatchToProps() {
	return {
		findCategories: ItemCategoryActions.findList,
		createCategory: ItemCategoryActions.create,
		findAllLocations: LocationActions.findList,
		findAllDepartments: LocationActions.findDepartmentList,
		findVendors: VendorActions.findList,
		createVendor: VendorActions.create,
		findTakenNames: ItemActions.findTakenNameList,
		loadCrewTypes: CrewTypeActions.findAllForCompanyList,
	};
}

const connector = connect(null, mapDispatchToProps());

export default connector(ItemForm);
